<ng-container *appTranslate="let t">
  <ng-container [ngSwitch]="definition?.type">
    <ng-container *ngSwitchCase="'text'">
      <input
        pInputText
        [ngModel]="value"
        [disabled]="disabled"
        [placeholder]="placeholder"
        maxlength="1000"
        (ngModelChange)="onValueChanged($event)"
      />
    </ng-container>

    <ng-container *ngSwitchCase="'integer'">
      <p-inputNumber
        [ngModel]="value"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [maxFractionDigits]="0"
        [min]="0"
        (ngModelChange)="onValueChanged($event)"
      ></p-inputNumber>
    </ng-container>

    <ng-container *ngSwitchCase="'boolean'">
      <p-dropdown
        [options]="[
          { label: t('boolean.true'), value: true },
          { label: t('boolean.false'), value: false },
        ]"
        optionLabel="label"
        optionValue="value"
        [filter]="false"
        [ngModel]="value"
        [disabled]="disabled"
        (ngModelChange)="onValueChanged($event)"
      ></p-dropdown>
    </ng-container>

    <ng-container *ngSwitchCase="'float'">
      <p-inputNumber
        [ngModel]="value"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [maxFractionDigits]="2"
        [min]="0"
        (ngModelChange)="onValueChanged($event)"
      ></p-inputNumber>
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      <p-calendar
        [ngModel]="value"
        [disabled]="disabled"
        [placeholder]="placeholder"
        (ngModelChange)="onValueChanged($event)"
      ></p-calendar>
    </ng-container>

    <ng-container *ngSwitchCase="'multiselect'">
      <p-multiSelect
        [options]="$any(definition).options"
        [required]="true"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [ngModel]="value"
        (ngModelChange)="onValueChanged($event)"
      ></p-multiSelect>
    </ng-container>

    <ng-container *ngSwitchCase="'select'">
      <p-dropdown
        [options]="$any(definition)?.options"
        [required]="true"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [ngModel]="value"
        (ngModelChange)="onValueChanged($event)"
      ></p-dropdown>
    </ng-container>

    <ng-container *ngSwitchCase="'profession'">
      <app-profession-selector
        [multi]="true"
        [ngModel]="value"
        [disabled]="disabled"
        [placeholder]="placeholder"
        (ngModelChange)="onValueChanged($event)"
      ></app-profession-selector>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <input pInputText [ngModel]="value" [disabled]="true" readonly [placeholder]="placeholder" />
    </ng-container>
  </ng-container>
</ng-container>
