import { Injectable } from "@angular/core";
import {
  CandidateFragment,
  CandidateId,
  CandidateMultiEditConfiguration,
  CandidateMultiEditPreviewFragment,
  CandidateUpdateInput,
  CandidateUpdateInputSharing,
  ExecuteCandidateMultiEditGQL,
  GetCandidateMultiEditConfigurationGQL,
  PreviewCandidateMultiEditGQL,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class CandidateMultiEditService {
  constructor(
    private readonly configurationGql: GetCandidateMultiEditConfigurationGQL,
    private readonly previewGql: PreviewCandidateMultiEditGQL,
    private readonly executeGql: ExecuteCandidateMultiEditGQL
  ) {}

  getConfiguration(organizationId: string): Observable<CandidateMultiEditConfiguration> {
    return this.configurationGql
      .fetch({ input: { organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getCandidateMultiEditConfiguration));
  }

  preview(
    candidate: CandidateUpdateInput,
    sharing: CandidateUpdateInputSharing,
    candidateIds: CandidateId[]
  ): Observable<CandidateMultiEditPreviewFragment[]> {
    delete candidate["creationDate"];
    delete candidate["deletionDate"];
    delete candidate["displayId"];
    delete candidate["talentId"];
    return this.previewGql
      .fetch({ input: { candidate, sharing, candidateIds } })
      .pipe(map((x) => x.data.previewCandidateMultiEdit));
  }

  execute(
    candidate: CandidateUpdateInput,
    sharing: CandidateUpdateInputSharing,
    candidateIds: CandidateId[]
  ): Observable<CandidateFragment[]> {
    delete candidate["creationDate"];
    delete candidate["deletionDate"];
    delete candidate["displayId"];
    delete candidate["talentId"];
    return this.executeGql
      .mutate({ input: { candidate, sharing, candidateIds } })
      .pipe(map((x) => x.data.executeCandidateMultiEdit));
  }
}
