import { Injectable } from "@angular/core";
import {
  CopySharingPresetGQL,
  CreateSharingPresetGQL,
  DeleteSharingPresetGQL,
  EditSharingPresetGQL,
  ListSelectableSharingPresetsGQL,
  ListSharingPresetsGQL,
  PrefillSharingPresetGQL,
  SharingPresetCreateInput,
  SharingPresetDeleteInput,
  SharingPresetForEditFragment,
  SharingPresetForListFragment,
  SharingPresetForListFragmentDoc,
  SharingPresetForPrefillFragment,
  SharingPresetForSelectionFragment,
  SharingPresetUpdateInput,
  UpdateSharingPresetGQL,
} from "@ankaadia/graphql";
import { omit, pick } from "lodash";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class SharingPresetService {
  constructor(
    private readonly presetList: ListSharingPresetsGQL,
    private readonly presetSelectableList: ListSelectableSharingPresetsGQL,
    private readonly presetEdit: EditSharingPresetGQL,
    private readonly presetPrefill: PrefillSharingPresetGQL,
    private readonly presetCreate: CreateSharingPresetGQL,
    private readonly presetUpdate: UpdateSharingPresetGQL,
    private readonly presetCopy: CopySharingPresetGQL,
    private readonly presetDelete: DeleteSharingPresetGQL
  ) {}

  list(organizationId: string): Observable<SharingPresetForListFragment[]> {
    return this.presetList.watch({ input: { organizationId } }).valueChanges.pipe(map((x) => x.data.getSharingPresets));
  }

  selectable(organizationId: string): Observable<SharingPresetForSelectionFragment[]> {
    return this.presetSelectableList.fetch({ input: { organizationId } }).pipe(map((x) => x.data.getSharingPresets));
  }

  edit(id: string, organizationId: string): Observable<SharingPresetForEditFragment> {
    return this.presetEdit.fetch({ input: { id, organizationId } }).pipe(map((x) => x.data.getSharingPreset));
  }

  prefill(id: string, organizationId: string): Observable<SharingPresetForPrefillFragment> {
    return this.presetPrefill.fetch({ input: { id, organizationId } }).pipe(map((x) => x.data.getSharingPreset));
  }

  create(preset: SharingPresetCreateInput): Observable<SharingPresetForListFragment> {
    return this.presetCreate
      .mutate(
        { input: omit(preset, "id", "_etag", "changedAt", "changedBy") },
        {
          update: (cache, x) =>
            cache.modify({
              fields: {
                getSharingPresets: (refs) => {
                  const ref = cache.writeFragment({
                    data: x.data.createSharingPreset,
                    fragment: SharingPresetForListFragmentDoc,
                  });
                  return [...(refs ?? []), ref];
                },
              },
            }),
        }
      )
      .pipe(map((x) => x.data.createSharingPreset));
  }

  update(preset: SharingPresetUpdateInput): Observable<SharingPresetForListFragment> {
    return this.presetUpdate
      .mutate({ input: omit(preset, "changedAt", "changedBy") })
      .pipe(map((x) => x.data.updateSharingPreset));
  }

  copy(id: string, organizationId: string): Observable<SharingPresetForListFragment> {
    return this.presetCopy
      .mutate(
        { input: { id, organizationId } },
        {
          update: (cache, x) =>
            cache.modify({
              fields: {
                getSharingPresets: (refs) => {
                  const ref = cache.writeFragment({
                    data: x.data.copySharingPreset,
                    fragment: SharingPresetForListFragmentDoc,
                  });
                  return [...(refs ?? []), ref];
                },
              },
            }),
        }
      )
      .pipe(map((x) => x.data.copySharingPreset));
  }

  delete(preset: SharingPresetDeleteInput): Observable<void> {
    return this.presetDelete
      .mutate(
        { input: pick(preset, "id", "_etag", "organizationId") },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getSharingPresets: (refs, helper) => {
                  return refs.filter((ref) => helper.readField("id", ref) !== preset.id);
                },
              },
            }),
        }
      )
      .pipe(map((x) => x.data.deleteSharingPreset));
  }
}
