import { Injectable } from "@angular/core";
import { toRealImmigrationCountry } from "@ankaadia/ankaadia-shared";
import { SupportedImmigrationCountry } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { BehaviorSubject, map, Observable, of, switchMap, take } from "rxjs";
import { UpdatedConfirmation } from "../../../shared/primeng/confirm-popup-fix/confirm-popup-fix.directive";
import { CandidateFormModule } from "../../candidate-form/candidate-form.module";
import { CandidateFormComponent } from "./candidate-form.component";

/**
 * Controls clearing immigration country specific fields on immigration country changes.
 *
 * Keeping the form component smaller, y'know.
 */
@Injectable({ providedIn: CandidateFormModule })
export class CandidateFormImmigrationCountryChangeService {
  constructor(private readonly confirmationService: ConfirmationService) {}

  setup(component: CandidateFormComponent): BehaviorSubject<SupportedImmigrationCountry> {
    const subject = new BehaviorSubject<SupportedImmigrationCountry>(null);
    component.form.controls.immigrationCountry.valueChanges
      .pipe(
        switchMap((immigrationCountry) => {
          const previous = toRealImmigrationCountry(component.candidate.immigrationCountry);
          const current = toRealImmigrationCountry(immigrationCountry);
          // double equal sign so that it treats `null` and `undefined` as equivalent values
          return previous == current || component.isMultiEditMode
            ? of(current)
            : this.confirm(immigrationCountry, component).pipe(map((c) => toRealImmigrationCountry(c)));
        })
      )
      .subscribe(subject);
    return subject;
  }

  private confirm(
    immigrationCountry: SupportedImmigrationCountry,
    component: CandidateFormComponent
  ): Observable<SupportedImmigrationCountry> {
    return new Observable<SupportedImmigrationCountry>((observer) => {
      const accept = (): void => {
        const controlsToReset = [
          "os.profile.preferredPathOfRecognition",
          "os.profile.preferredLocationState",
          "os.profile.preferredCity",
          immigrationCountry !== SupportedImmigrationCountry.De ? "migration.de" : null,
          immigrationCountry !== SupportedImmigrationCountry.At ? "migration.at" : null,
        ];

        controlsToReset
          .filter((control) => control !== null)
          .forEach((control) => component.form.get(control)?.patchValue(null));

        component.setShowMigrationTab(immigrationCountry);

        component.candidate.immigrationCountry = immigrationCountry;
        component.collectionComponent?.reload();
        observer.next(immigrationCountry);
        observer.complete();
      };

      const reject = (): void => {
        component.form.controls.immigrationCountry.setValue(component.candidate.immigrationCountry);
        component.collectionComponent?.reload();
        observer.next(component.candidate.immigrationCountry);
        observer.complete();
      };

      this.confirmationService.confirm({
        target: component.immigrationCountryContainer.nativeElement,
        message: translate("candidate.immigrationCountryWarning"),
        icon: PrimeIcons.EXCLAMATION_TRIANGLE,
        accept: () => accept(),
        reject: () => reject(),
        close: () => reject(),
      } as UpdatedConfirmation);
    }).pipe(take(1));
  }
}
