import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from "@angular/forms";
import { CandidateScholarship } from "@ankaadia/graphql";
import { ScholarshipForm } from "../candidate-form.model";

export const candidateScholarshipsValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const scholarships = (control as FormArray<ScholarshipForm>).value ?? [];

  const overlappingIndices = scholarships
    .map((testedScholarship, outerIndex) =>
      scholarships
        .filter((_, innerIndex) => innerIndex !== outerIndex)
        .some((comparedScholarship) => areTimeframesOverlapping(testedScholarship, comparedScholarship))
    )
    .map((doesOverlap, index) => (doesOverlap ? index : null))
    .filter((index) => index !== null);

  return overlappingIndices.length ? { overlappingIndices } : null;
};

function areTimeframesOverlapping(
  scholarShip1: Partial<CandidateScholarship>,
  scholarShip2: Partial<CandidateScholarship>
): boolean {
  const startDate1 = scholarShip1.startDate;
  const endDate1 = scholarShip1.endDate;

  const startDate2 = scholarShip2.startDate;
  const endDate2 = scholarShip2.endDate;

  if (!startDate2 || !endDate2) {
    return false;
  }

  const isStartDateContained = startDate1 && startDate1 >= startDate2 && startDate1 <= endDate2;
  const isEndDateContained = endDate1 && endDate1 >= startDate2 && endDate1 <= endDate2;
  return isStartDateContained || isEndDateContained;
}
