import { Pipe, PipeTransform } from "@angular/core";
import { CandidateData } from "../candidate-data.model";

export enum FeedbackStatusMeta {
  New = "New",
}

@Pipe({ name: "candidateFilter", standalone: false })
export class CandidateFilterPipe implements PipeTransform {
  transform(data: CandidateData[], search: string, sharingTypes: string[]): CandidateData[] {
    return data
      ?.filter((d) => this.filterBySharingTypes(d, sharingTypes))
      ?.filter((d) => this.filterBySearch(d, search));
  }

  private filterBySharingTypes(data: CandidateData, sharingTypes: string[]): boolean {
    return (
      sharingTypes.length === 0 ||
      sharingTypes.includes(data.feedback.statusPhaseOne) ||
      sharingTypes.includes(data.feedback.statusPhaseTwo) ||
      (sharingTypes.includes(FeedbackStatusMeta.New) && !data.feedback.statusPhaseOne && !data.feedback.statusPhaseTwo)
    );
  }

  private filterBySearch(data: CandidateData, search: string): boolean {
    return (
      search === "" ||
      data.candidate.displayName.toLowerCase().includes(search.toLowerCase()) ||
      data.candidate.displayId.toLowerCase().includes(search.toLowerCase())
    );
  }
}
