import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { PriceRow, SupportedBillingCurrency } from "@ankaadia/graphql";
import { PriceComponent } from "../price/price.component";
import { TranslateDirective } from "../../../../../shared/transloco/translate.directive";

type CapFloor = Pick<PriceRow, "cap" | "floor">;

@Component({
  standalone: true,
  selector: "app-plan-price-cap-floor",
  templateUrl: "./plan-price-cap-floor.component.html",
  imports: [PriceComponent, TranslateDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanPriceCapFloorComponent {
  capFloor = input.required<CapFloor>();

  currency = input.required<SupportedBillingCurrency>();
}
