<ng-container *appTranslate="let t">
  <div>
    <ng-container *ngIf="events()?.length > 1; else SingleEvent">
      <p class="m-0 text-sm">{{ t("billing.billingEvent.paymentDue") }}:</p>
      <p class="m-0 text-sm" *ngFor="let event of events()">
        {{ event.share / 100.0 | translocoPercent: { maximumFractionDigits: 0 } }} {{ t($any("billing.billingEvent.enum." + event.event)) }}
      </p>
    </ng-container>
    <ng-template #SingleEvent>
    <span class="text-sm">
      {{ t("billing.billingEvent.paymentDue") }} {{ t($any("billing.billingEvent.enum." + events()?.at(0).event)) }}
    </span>
    </ng-template>
  </div>
</ng-container>
