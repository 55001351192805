import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OrganizationProfileLocationVacancy } from "@ankaadia/graphql";

@Injectable({ providedIn: "root" })
export class OrganizationProfileFormsService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createVacancyFormGroup(vacancy?: OrganizationProfileLocationVacancy): FormGroup {
    return this.formBuilder.group({
      requiredStaff: [vacancy?.requiredStaff, Validators.required],
      interviewLanguageLevel: [vacancy?.interviewLanguageLevel],
      transferLanguageLevel: [vacancy?.transferLanguageLevel],
      pathOfRecognition: [vacancy?.pathOfRecognition],
      function: [vacancy?.function],
      countryOfOrigin: [vacancy?.countryOfOrigin],
      employmentType: [vacancy?.employmentType],
      temporalScope: [vacancy?.temporalScope],
      benefits: [vacancy?.benefits],
    });
  }
}
