import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { AssignCollectionInput, Collection, Organization } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { finalize } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { TableColumn, TableOperation } from "../../../shared/table/table.model";
import { MessageService } from "../../message/message.service";
import { OrganizationsService } from "../../organizations/organizations.service";
import { CollectionService } from "../collection.service";

@Component({
  selector: "app-collection-assigned-collections",
  templateUrl: "./collection-assigned-collections.component.html",
  standalone: false,
})
export class CollectionAssignedCollectionsComponent implements OnInit, OnChanges {
  readonly captionOperations: TableOperation[] = [
    {
      label: translate("common.new"),
      testId: "add-new-assigned-collection",
      icon: PrimeIcons.PLUS,
      operation: (): void => this.openNewCollectionDialog(),
    },
  ];

  tableOperations: TableOperation[] = [
    {
      label: translate("common.delete"),
      testId: "delete-assigned-collection",
      icon: PrimeIcons.TRASH,
      operation: (x: Collection, e: Event): void => this.deleteCollection(x, e),
    },
  ];

  readonly columns: TableColumn[] = [
    { header: translate("name.title"), fieldname: "name", sortable: true, includeInGlobalFilter: true },
    {
      header: translate("organization.title"),
      fieldname: "organizationName",
      sortable: true,
      includeInGlobalFilter: true,
    },
  ];

  @Input()
  collection: Collection;

  @Output()
  readonly reload = new EventEmitter<void>();

  organizations: Partial<Organization>[];
  collections: (Collection & { organizationName: string })[];
  selectedCollection: AssignCollectionInput;
  isAdding: boolean;
  showSidebar: boolean;

  constructor(
    private readonly organizationService: OrganizationsService,
    private readonly collectionService: CollectionService,
    private readonly settings: SettingsService,
    private readonly messageService: MessageService,
    private readonly confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.organizationService.getSelectableOrganizations().subscribe((x) => {
      this.organizations = x;
      this.updateCollections(this.collection, this.organizations);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.collection) {
      this.updateCollections(changes.collection.currentValue, this.organizations);
    }
  }

  openNewCollectionDialog(): void {
    this.selectedCollection = {
      id: this.collection.id,
      _etag: this.collection._etag,
      organizationId: this.collection.organizationId,
      assignedId: null,
      assignedOrganizationId: this.settings.organizationId,
    };
    this.showSidebar = true;
  }

  closeSidebar(): void {
    this.selectedCollection = null;
    this.showSidebar = false;
  }

  addCollection(collection: AssignCollectionInput): void {
    this.isAdding = true;
    this.collectionService
      .addAssignedCollection(collection)
      .pipe(finalize(() => (this.isAdding = false)))
      .subscribe(() => {
        this.messageService.add({ severity: "success", summary: translate("collection.assigned") });
        this.closeSidebar();
        this.reload.emit();
      });
  }

  deleteCollection(collection: Collection, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("collection.confirmUnassign"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () =>
        this.collectionService
          .removeAssignedCollection({
            id: this.collection.id,
            organizationId: this.collection.organizationId,
            _etag: this.collection._etag,
            assignedId: collection.id,
            assignedOrganizationId: collection.organizationId,
          })
          .subscribe(() => {
            this.messageService.add({ severity: "success", summary: translate("collection.unassigned") });
            this.reload.emit();
          }),
    });
  }

  private updateCollections(collection: Collection, organizations: Partial<Organization>[]): void {
    this.collections = collection?.assignedCollections?.map((c) => ({
      ...c,
      organizationName: organizations?.find((o) => o.id === c.organizationId)?.name,
    }));
  }
}
