<button
  *appTranslate="let t"
  pButton
  type="button"
  icon="pi pi-user-edit"
  tabindex="-1"
  class="toggle p-button-rounded p-button-text ml-2"
  [pTooltip]="t('candidateMultiEdit.title')"
  [class.p-button-active]="isActive"
  (click)="toggle()"
></button>
