import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { nameofFactory, UserPermission } from "@ankaadia/ankaadia-shared";
import {
  CopyDataTypeEnum,
  OrganizationForListFragment,
  OrganizationLinkFragment,
  StaticDataModel,
  StaticDataType,
  SupportedImmigrationCountry,
  XlsxImportResult,
} from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { clone, noop } from "lodash";
import { ConfirmationService, Message, PrimeIcons } from "primeng/api";
import { OverlayPanel } from "primeng/overlaypanel";
import { map, Observable, of, Subscription, switchMap } from "rxjs";
import { MessageDialogService } from "../../shared/message-dialog/message-dialog.service";
import { BooleanPipe } from "../../shared/pipes/boolean.pipe";
import { EnumPipe } from "../../shared/pipes/enum.pipe";
import { DownloadService } from "../../shared/services/download.service";
import { SettingsService } from "../../shared/services/settings.service";
import { StaticDataPipe } from "../../shared/static-data/static-data.pipe";
import { StaticDataService } from "../../shared/static-data/static-data.service";
import { TableComponent } from "../../shared/table/table.component";
import {
  PipeDescription,
  TableColumn,
  TableMenuItem,
  TableOperation,
  TableOperationMode,
} from "../../shared/table/table.model";
import { DocumentsService } from "../documents/documents.service";
import { ImportComponentData, SaveResult, Template } from "../import/import.component";
import { ImportType, XlsxImportExportService } from "../import/xlsx-import-export.service";
import { MessageService } from "../message/message.service";
import { OrganizationDataService } from "./organization-data.service";
import { OrganizationEditDialogComponent } from "./organization-edit-dialog/organization-edit-dialog.component";
import { OrganizationLinkService } from "./organization-link.service";
import { OrganizationsService } from "./organizations.service";

type OrganizationForList = OrganizationForListFragment & { isManageable: boolean; isMatchingEnabled: boolean };

enum LinkMode {
  LinkByOrganization = "LinkByOrganization",
  LinkToOrganization = "LinkToOrganization",
}

const nameOf = nameofFactory<OrganizationForList>();
const nameOfLink = nameofFactory<OrganizationLinkFragment>();

@Component({
  selector: "app-organizations",
  templateUrl: "./organizations.component.html",
  styleUrl: "./organizations.component.scss",
  standalone: false,
})
export class OrganizationsComponent implements OnInit, OnDestroy {
  @ViewChild(OrganizationEditDialogComponent) editDialog: OrganizationEditDialogComponent;
  orgs: OrganizationForList[] | OrganizationLinkFragment[] = [];
  protected organizationId: string;
  footerOperations: TableOperation[] = [
    {
      label: translate("common.back"),
      icon: PrimeIcons.ANGLE_LEFT,
      operation: (): void => this.back(),
    },
  ];

  private readonly tableMenuItemOrganizationImport: TableMenuItem = {
    label: translate("organizations.import"),
    icon: PrimeIcons.UPLOAD,
    operation: (): void =>
      this.onImport(
        ImportType.Organizations,
        translate("organizations.importTo", { organization: this.organizationName }),
        translate("organizations.confirmImport", { organization: this.organizationName }),
        [
          {
            template: (lang: string, addTestData: boolean) =>
              this.importService
                .getOrganizationImportTemplate(this.organizationId, lang, addTestData)
                .subscribe(({ downloadUrl, fileName }) => {
                  this.downloadService.downloadFile(fileName, downloadUrl);
                }),
            templateName: translate("organizations.downloadImportTemplate"),
          },
        ]
      ),
  };

  private readonly tableMenuItemContactsImport: TableMenuItem = {
    label: translate("contacts.import"),
    icon: PrimeIcons.UPLOAD,
    operation: (): void =>
      this.onImport(
        ImportType.Contacts,
        translate("contacts.importTo", { organization: this.organizationName }),
        translate("contacts.confirmImport", { organization: this.organizationName }),
        [
          {
            template: (lang: string, addTestData: boolean) =>
              this.importService
                .getOrganizationContactImportTemplate(this.import.organizationId, lang, addTestData)
                .subscribe(({ downloadUrl, fileName }) => {
                  this.downloadService.downloadFile(fileName, downloadUrl);
                }),
            templateName: translate("contacts.downloadImportTemplate"),
          },
        ]
      ),
  };

  private readonly tableMenuItemContactSettingsImport: TableMenuItem = {
    label: translate("contactSettings.import"),
    icon: PrimeIcons.UPLOAD,
    operation: (): void =>
      this.onImport(
        ImportType.ContactSettings,
        translate("contactSettings.importTo", { organization: this.organizationName }),
        translate("contactSettings.confirmImport", { organization: this.organizationName }),
        [
          {
            template: (lang: string, addTestData: boolean) =>
              this.importService
                .getOrganizationContactSettingsImportTemplate(this.import.organizationId, lang, addTestData)
                .subscribe(({ downloadUrl, fileName }) => {
                  this.downloadService.downloadFile(fileName, downloadUrl);
                }),
            templateName: translate("contactSettings.downloadImportTemplate"),
          },
        ],
        [
          {
            severity: "info",
            closable: false,
            summary: translate("contactSettings.hint.createOnly"),
          },
          {
            severity: "info",
            closable: false,
            summary: translate("contactSettings.hint.restrictions"),
          },
        ]
      ),
  };

  private readonly tableMenuItemContractTemplatesImport: TableMenuItem = {
    label: translate("contractTemplates.import"),
    icon: PrimeIcons.UPLOAD,
    operation: (): void =>
      this.onImport(
        ImportType.ContractTemplates,
        translate("contractTemplates.importToPartners", {
          organization: this.organizationName,
        }),
        translate("contractTemplates.confirmImport", { organization: this.organizationName }),
        [
          ...(this.isImmigrationCountrySupported(SupportedImmigrationCountry.De)
            ? [
                {
                  template: (lang: string, addTestData: boolean) =>
                    this.importService
                      .getContractTemplateImportTemplate(
                        this.import.organizationId,
                        SupportedImmigrationCountry.De,
                        false,
                        lang,
                        addTestData
                      )
                      .subscribe(({ downloadUrl, fileName }) => {
                        this.downloadService.downloadFile(fileName, downloadUrl);
                      }),
                  templateName: translate("contractTemplates.downloadImportTemplate", {
                    country: this.getCountryName(SupportedImmigrationCountry.De),
                  }),
                },
              ]
            : []),
          ...(this.isImmigrationCountrySupported(SupportedImmigrationCountry.At)
            ? [
                {
                  template: (lang: string, addTestData: boolean) =>
                    this.importService
                      .getContractTemplateImportTemplate(
                        this.import.organizationId,
                        SupportedImmigrationCountry.At,
                        false,
                        lang,
                        addTestData
                      )
                      .subscribe(({ downloadUrl, fileName }) => {
                        this.downloadService.downloadFile(fileName, downloadUrl);
                      }),
                  templateName: translate("contractTemplates.downloadImportTemplate", {
                    country: this.getCountryName(SupportedImmigrationCountry.At),
                  }),
                },
              ]
            : []),
        ]
      ),
  };

  private readonly tableMenuItemUserGroupsImport: TableMenuItem = {
    label: translate("importUserGroups.titleShort"),
    icon: PrimeIcons.UPLOAD,
    operation: (): void => {
      this.onImport(
        ImportType.UserGroups,
        translate("importUserGroups.title", { organization: this.organizationName }),
        translate("importUserGroups.confirm", { organization: this.organizationName }),
        [
          {
            template: (lang: string, addTestData: boolean): void => {
              this.importService
                .getOrganizationUserGroupImportTemplate(this.import.organizationId, lang, addTestData)
                .subscribe(({ downloadUrl, fileName }) => this.downloadService.downloadFile(fileName, downloadUrl));
            },
            templateName: translate("candidates.downloadImportTemplate"),
          },
        ]
      );
    },
  };

  private readonly tableMenuItemUsersImport: TableMenuItem = {
    label: translate("importUsers.titleShort"),
    icon: PrimeIcons.UPLOAD,
    operation: (): void => {
      this.onImport(
        ImportType.Users,
        translate("importUsers.title", { organization: this.organizationName }),
        translate("importUsers.confirm", { organization: this.organizationName }),
        [
          {
            template: (lang: string, addTestData: boolean): void => {
              this.importService
                .getOrganizationUserImportTemplate(this.import.organizationId, lang, addTestData)
                .subscribe(({ downloadUrl, fileName }) => this.downloadService.downloadFile(fileName, downloadUrl));
            },
            templateName: translate("candidates.downloadImportTemplate"),
          },
        ]
      );
    },
  };

  private readonly tableMenuItemUserMembershipsImport: TableMenuItem = {
    label: translate("importUserMemberships.titleShort"),
    icon: PrimeIcons.UPLOAD,
    operation: (): void => {
      this.onImport(
        ImportType.UserMemberships,
        translate("importUserMemberships.title", { organization: this.organizationName }),
        translate("importUserMemberships.confirm", { organization: this.organizationName }),
        [
          {
            template: (lang: string, addTestData: boolean): void => {
              this.importService
                .getOrganizationUserMembershipImportTemplate(this.import.organizationId, lang, addTestData)
                .subscribe(({ downloadUrl, fileName }) => this.downloadService.downloadFile(fileName, downloadUrl));
            },
            templateName: translate("candidates.downloadImportTemplate"),
          },
        ]
      );
    },
  };

  private readonly tableMenuItemCRMDataImport: TableMenuItem = {
    label: translate("crm.import.title"),
    icon: PrimeIcons.UPLOAD,
    operation: (): void =>
      this.onImport(
        ImportType.CrmData,
        translate("crm.import.importTo", { organization: this.organizationName }),
        translate("crm.import.confirmImport", { organization: this.organizationName }),
        [
          {
            template: (lang: string, addTestData: boolean) =>
              this.importService
                .getOrganizationCrmImportTemplate(this.import.organizationId, lang, addTestData)
                .subscribe(({ downloadUrl, fileName }) => {
                  this.downloadService.downloadFile(fileName, downloadUrl);
                }),
            templateName: translate("crm.import.downloadImportTemplate"),
          },
        ]
      ),
  };

  private readonly tableMenuItemOrganizationExport: TableMenuItem = {
    label: translate("organization.export"),
    icon: PrimeIcons.DOWNLOAD,
    mode: TableOperationMode.Button,
    operation: (): void => this.generateAndOrganisationDataExport(),
  };

  private readonly tableOperationImportExport: TableOperation = {
    label: translate("organization.importExport"),
    icon: PrimeIcons.SORT_ALT,
    mode: TableOperationMode.SplitButton,
    disabled: (): boolean => !this.isLicensed,
    operation: (): void => noop(),
    items: [
      this.tableMenuItemOrganizationImport,
      this.tableMenuItemContactsImport,
      this.tableMenuItemContactSettingsImport,
      this.tableMenuItemContractTemplatesImport,
      this.tableMenuItemUserGroupsImport,
      this.tableMenuItemUsersImport,
      this.tableMenuItemUserMembershipsImport,
      this.tableMenuItemCRMDataImport,
      this.tableMenuItemOrganizationExport,
    ],
  };

  private readonly linkByNewOperations: TableOperation[] = [
    {
      label: translate("common.new"),
      icon: PrimeIcons.PLUS,
      disabled: (): boolean => !this.isLicensed,
      operation: (): void => this.onAdd(),
    },
    {
      label: translate("organization.link"),
      icon: PrimeIcons.LINK,
      operation: (): void => this.onLink(),
    },
    ...(this.settings.isLicensed ? [this.tableOperationImportExport] : []),
  ];

  private readonly linkByTableOperations: TableOperation[] = [
    {
      label: translate("common.edit"),
      icon: PrimeIcons.BARS,
      styleClass: "p-button-rounded p-button-text",
      mode: TableOperationMode.Menu,
      operation: (): void => noop(),
      items: [
        ...(this.settings.userPermissions.some(
          (x) =>
            x === UserPermission.Administrator || x === UserPermission.PartnerAdministrator || x === UserPermission.User
        )
          ? [
              {
                label: translate("organization.title"),
                icon: PrimeIcons.BUILDING,
                operation: (x: OrganizationForList): void => this.onEdit(x),
                canOperate: (x: OrganizationForList): boolean => this.canOperate(x),
              },
            ]
          : []),
        ...(this.settings.userPermissions.some(
          (x) => x === UserPermission.Administrator || x === UserPermission.PartnerAdministrator
        )
          ? [
              {
                label: translate("contacts.title"),
                icon: PrimeIcons.ID_CARD,
                operation: (x: OrganizationForList): void => this.onEditContacts(x),
                canOperate: (x: OrganizationForList): boolean => this.canOperate(x),
              },
              {
                label: translate("organizationProfile.title"),
                icon: PrimeIcons.IMAGE,
                operation: (x: OrganizationForList): void => this.onEditProfile(x),
                canOperate: (x: OrganizationForList): boolean => this.canOperate(x),
              },
              {
                label: translate("laborAgreements.title"),
                icon: PrimeIcons.CLIPBOARD,
                operation: (x: OrganizationForList): void => this.onEditLaborAgreements(x),
                canOperate: (x: OrganizationForList): boolean => this.canOperate(x),
              },
              {
                label: translate("contractTemplate.title"),
                icon: PrimeIcons.FILE,
                operation: (x: OrganizationForList): void => this.onEditContractTemplates(x),
                canOperate: (x: OrganizationForList): boolean => this.canOperate(x),
              },
              {
                label: translate("EZBData.title"),
                icon: PrimeIcons.DATABASE,
                operation: (x: OrganizationForList): void => this.onEditEZBData(x),
                canOperate: (x: OrganizationForList): boolean => this.canOperate(x),
              },
            ]
          : []),
        ...(this.settings.userPermissions.some(
          (x) =>
            x === UserPermission.Administrator ||
            x === UserPermission.PartnerAdministrator ||
            x === UserPermission.CrmContributor
        )
          ? [
              {
                label: translate("crm.title"),
                icon: PrimeIcons.SITEMAP,
                operation: (x: OrganizationForList): void => this.onCRMEdit(x),
              },
            ]
          : []),
        ...(this.settings.userPermissions.some(
          (x) => x === UserPermission.Administrator || x === UserPermission.PartnerAdministrator
        )
          ? [
              {
                label: translate("organization.editUsers"),
                icon: PrimeIcons.USER,
                operation: (x: OrganizationForList): void => this.onUsersEdit(x),
                canOperate: (x: OrganizationForList): boolean => this.canOperate(x),
              },
              {
                label: translate("organization.editUserGroups"),
                icon: PrimeIcons.USERS,
                operation: (x: OrganizationForList): void => this.onUserGroupsEdit(x),
                canOperate: (x: OrganizationForList): boolean => this.canOperate(x),
              },
              {
                label: translate("organization.manage"),
                icon: PrimeIcons.TH_LARGE,
                operation: (x: OrganizationForList): void => this.onOrganizationEdit(x),
                canOperate: (x: OrganizationForList): boolean => this.canOperate(x),
              },
              {
                label: translate("organization.editLink"),
                icon: PrimeIcons.KEY,
                operation: (x: OrganizationForList): void => this.onLink(x),
              },
              {
                label: translate("organization.unlink"),
                icon: PrimeIcons.UNDO,
                operation: (x: OrganizationForList, e: Event): void => this.onUnlink(x, e),
              },
              {
                label: translate("common.delete"),
                icon: PrimeIcons.TRASH,
                operation: (x: OrganizationForList, e: Event): void => this.onDelete(x, e),
                canOperate: (x: OrganizationForList): boolean => this.canOperate(x),
              },
              ...(this.settings.additionalModules?.organizationProfile
                ? [
                    {
                      label: translate("matching.enable"),
                      icon: PrimeIcons.LINK,
                      operation: (x: OrganizationForList): void => this.setMatching(x, true),
                      canOperate: (x: OrganizationForList): boolean => !x.isMatchingEnabled,
                    },
                    {
                      label: translate("matching.disable"),
                      icon: PrimeIcons.LINK,
                      operation: (x: OrganizationForList): void => this.setMatching(x, false),
                      canOperate: (x: OrganizationForList): boolean => x.isMatchingEnabled,
                    },
                  ]
                : []),
            ]
          : []),

        ...(this.settings.isMasterOrganization
          ? [
              {
                label: translate("demo.copy"),
                icon: PrimeIcons.CLONE,
                operation: (x: OrganizationForList, e: Event): void => this.onDemo(x, e),
              },
              {
                label: translate("license.remove"),
                icon: PrimeIcons.MINUS,
                operation: (x: OrganizationForList, e: Event): void => this.removeLicense(x, e),
              },
            ]
          : []),
      ],
    },
  ];

  private readonly linkByColumns: TableColumn[] = [
    { header: translate("name.title"), fieldname: nameOf("name"), sortable: true, includeInGlobalFilter: true },
    { header: translate("code.title"), fieldname: nameOf("code"), sortable: true, includeInGlobalFilter: true },
    {
      header: translate("cooperationType.title"),
      fieldname: nameOf("cooperationType"),
      sortable: true,
      pipeDescription: new PipeDescription(StaticDataPipe, StaticDataType.CooperationType),
    },
    {
      header: translate("organizationType.title"),
      fieldname: nameOf("type"),
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(StaticDataPipe, StaticDataType.OrganizationType),
    },
    {
      header: translate("organization.parent.title"),
      fieldname: nameOf("parentOrganizationName"),
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("manageable.title"),
      fieldname: nameOf("isManageable"),
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(BooleanPipe),
    },
  ];

  private readonly linkToColumns: TableColumn[] = [
    {
      header: translate("name.title"),
      fieldname: nameOfLink("organizationName"),
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("code.title"),
      fieldname: nameOfLink("organizationCode"),
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("organizationType.title"),
      fieldname: nameOfLink("organizationType"),
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(StaticDataPipe, StaticDataType.OrganizationType),
    },
    {
      header: translate("shareContacts.title"),
      fieldname: nameOfLink("shareContacts"),
      sortable: true,
      includeInGlobalFilter: false,
      pipeDescription: new PipeDescription(EnumPipe, "ContactSharingMode", null, "normal", "noAccess"),
    },
    {
      header: translate("shareContractTemplates.title"),
      fieldname: nameOfLink("shareContractTemplates"),
      sortable: true,
      includeInGlobalFilter: false,
      pipeDescription: new PipeDescription(EnumPipe, "ContractTemplateSharingMode", null, "normal", "noAccess"),
    },
    {
      header: translate("shareEmploymentRelationship.title"),
      fieldname: nameOfLink("shareEZBData"),
      sortable: true,
      includeInGlobalFilter: false,
      pipeDescription: new PipeDescription(EnumPipe, "EmploymentRelationshipSharingMode", null, "normal", "noAccess"),
    },
    {
      header: translate("sharePartners.title"),
      fieldname: nameOfLink("sharePartners"),
      sortable: true,
      includeInGlobalFilter: false,
      pipeDescription: new PipeDescription(EnumPipe, "PartnerSharingMode", null, "normal", "noAccess"),
    },
  ];

  columns: TableColumn[];
  tableOperations: TableOperation[];
  newOperations: TableOperation[];
  showDialog = false;
  editOrg = false;
  import: ImportComponentData;
  xlsxImportResult: XlsxImportResult;
  link: OrganizationLinkFragment;
  organizationName: string;

  private dataSubScription: Subscription = null;

  @ViewChild(TableComponent)
  tableComponent: TableComponent;

  @ViewChild("deleteConfirmation")
  deleteConfirmation: OverlayPanel;

  @ViewChild("selectCopyData")
  selectCopyData: OverlayPanel;

  protected isLicensed: boolean;

  orgToDelete: OrganizationForList;
  orgCodeConfirmation: string;
  elementToCopy: OrganizationForList;
  dataToCopy: CopyDataTypeEnum;
  dataToCopyOptions = [
    { label: "Documents", value: CopyDataTypeEnum.Documents },
    { label: "Email templates", value: CopyDataTypeEnum.Emails },
    { label: "Required documents", value: CopyDataTypeEnum.RequiredDocuments },
    { label: "Document templates", value: CopyDataTypeEnum.Templates },
    { label: "Send out rules", value: CopyDataTypeEnum.SendOutRules },
    { label: "Auto list templates", value: CopyDataTypeEnum.AutoListTemplates },
    { label: "All", value: CopyDataTypeEnum.All },
  ];

  documentTemplateOptions$: Observable<string[]>;
  selectedDocumentTemplates: string[] = [];

  protected modeOptions = [
    {
      label: this.transloco.translate("organization.linkByMe"),
      value: LinkMode.LinkByOrganization,
    },
    {
      label: this.transloco.translate("organization.linkToMe"),
      value: LinkMode.LinkToOrganization,
    },
  ];

  protected selectedModeOption: LinkMode = LinkMode.LinkByOrganization;

  protected countries: StaticDataModel[];

  constructor(
    private readonly orgSvc: OrganizationsService,
    private readonly importExportService: XlsxImportExportService,
    private readonly linkService: OrganizationLinkService,
    private readonly dataSvc: OrganizationDataService,
    private readonly confirmationService: ConfirmationService,
    private readonly settings: SettingsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly messageService: MessageService,
    private readonly messageDialogService: MessageDialogService,
    private readonly transloco: TranslocoService,
    private readonly documentService: DocumentsService,
    private readonly downloadService: DownloadService,
    private readonly staticDataService: StaticDataService,
    private readonly importService: XlsxImportExportService
  ) {}

  readonly language = this.transloco.getActiveLang();

  selectedModeOptionChanged(value: LinkMode): void {
    if (value == LinkMode.LinkByOrganization) {
      this.columns = this.linkByColumns;
      this.tableOperations = this.linkByTableOperations;
      this.newOperations = this.linkByNewOperations;
    } else {
      this.columns = this.linkToColumns;
      this.tableOperations = null;
      this.newOperations = null;
    }
    this.reload();
  }

  ngOnInit(): void {
    this.columns = this.linkByColumns;
    this.tableOperations = this.linkByTableOperations;
    this.newOperations = this.linkByNewOperations;
    this.route.params.subscribe((params) => this.routerParamsChanged(params));
    this.staticDataService
      .getStaticData(StaticDataType.SupportedImmigrationCountries, this.language)
      .subscribe((x) => (this.countries = x));
  }

  canOperate(selectedOrganisation: OrganizationForListFragment): boolean {
    return (
      this.settings.organizationId == selectedOrganisation.creatorOrganizationId &&
      (selectedOrganisation.allowOrganizationManagementbyCreator ||
        selectedOrganisation.allowOrganizationManagementbyCreator == null)
    );
  }

  onDemo(element: OrganizationForList, event: Event): void {
    this.elementToCopy = element;
    this.documentTemplateOptions$ = this.documentService.getAllTemplateDisplayNamesOfDemoTenant();
    this.selectCopyData.show(event);
  }

  makeDemoTenant(): void {
    this.orgSvc.makeTenantToDemoTenant(this.elementToCopy.id).subscribe((x) => {
      if (x) {
        this.messageDialogService.showMessage(
          this.transloco.translate("demo.success"),
          `${this.transloco.translate("demo.successful")}:\n\n${(x.successfulCopies ?? []).join(
            "\n"
          )}\n\n${this.transloco.translate("demo.failed")}:\n\n${(x.failedCopies ?? []).join("\n")}`
        );
      }
    });
  }

  copyData(): void {
    if (this.dataToCopy != CopyDataTypeEnum.Templates && this.dataToCopy != CopyDataTypeEnum.All) {
      this.selectedDocumentTemplates = [];
    }
    this.orgSvc.copyDataToOrg(this.elementToCopy.id, this.dataToCopy, this.selectedDocumentTemplates).subscribe((x) => {
      if (x) {
        this.messageDialogService.showMessage(
          this.transloco.translate("demo.dataCopied"),
          `${this.transloco.translate("demo.successful")}:\n\n${(x.successfulCopies ?? []).join(
            "\n"
          )}\n\n${this.transloco.translate("demo.failed")}:\n\n${(x.failedCopies ?? []).join("\n")}`
        );
      }
    });
  }

  removeLicense(orgRow: OrganizationForList, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("license.confirm"),
      header: translate("license.confirm"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.orgSvc.removeLicense(orgRow.id).subscribe(() => {
          this.messageService.add({
            severity: "success",
            summary: this.transloco.translate("license.removed"),
          });
        });
      },
    });
  }

  ngOnDestroy(): void {
    this.dataSubScription.unsubscribe();
  }

  routerParamsChanged(params: Params): void {
    this.organizationId = params["orgId"];
    this.orgSvc.getOrganizationWithLicenseInfo(this.organizationId).subscribe({
      next: (x) => {
        this.organizationName = x.name;
        this.isLicensed = x.isLicensed;
      },
      error: () => this.settings.navigateToNotFound(),
    });
    this.reload();
    this.tableComponent?.resetPagination();
  }

  onAdd(): void {
    this.editOrg = true;
    this.link = null;
    this.showDialog = true;
    this.editDialog.initCreate(this.organizationId, this.organizationName);
  }

  onEdit(element: OrganizationForList): void {
    this.editOrg = true;
    this.link = null;
    this.editDialog.initEdit(element.id, element.creatorOrganizationId).subscribe(() => (this.showDialog = true));
  }

  onEditContacts(organization: OrganizationForList): void {
    void this.router.navigate([`/app/settings/organizations/${organization.id}/contacts`]);
  }

  onEditProfile(organization: OrganizationForList): void {
    void this.router.navigate([`/app/settings/organizations/${organization.id}/profile`]);
  }

  onEditLaborAgreements(organization: OrganizationForList): void {
    void this.router.navigate([`/app/labor-agreements/${organization.id}/`]);
  }

  onEditContractTemplates(organization: OrganizationForList): void {
    void this.router.navigate([`/app/settings/organizations/${organization.id}/contractTemplates`]);
  }

  onEditEZBData(organization: OrganizationForList): void {
    void this.router.navigate([`/app/settings/organizations/${organization.id}/ezb-data`]);
  }

  onCRMEdit(organization: OrganizationForList): void {
    void this.router.navigate([`/app/settings/organizations/${this.organizationId}/${organization.id}/crm`]);
  }

  onUsersEdit(element: OrganizationForList): void {
    void this.router.navigate([`/app/settings/users/${element.id}`]);
  }

  onUserGroupsEdit(element: OrganizationForList): void {
    void this.router.navigate([`/app/settings/user-groups/${element.id}`]);
  }

  onOrganizationEdit(element: OrganizationForList): void {
    void this.router.navigate([`/app/settings/organizations/${element.id}`]);
  }

  closeSidebar(): void {
    this.showDialog = false;
    this.editOrg = false;
    this.link = null;
    this.import = null;
  }

  onLink(organization?: OrganizationForList): void {
    this.editOrg = false;
    (organization
      ? this.linkService.get(this.organizationId, organization.id)
      : of<OrganizationLinkFragment>({ organizationId: this.organizationId, linkedOrganizationId: null })
    ).subscribe((x) => {
      this.link = x;
      this.showDialog = true;
    });
  }

  onDelete(org: OrganizationForList, event: Event): void {
    this.orgToDelete = org;
    this.orgCodeConfirmation = null;
    this.deleteConfirmation.show(event);
  }

  onImport(
    importType: ImportType,
    header: string,
    submitMessage: string,
    templates: Template[],
    messages?: Message[]
  ): void {
    this.import = this.importService.createImportInput(
      importType,
      this.organizationId,
      header,
      submitMessage,
      templates,
      messages
    );
    this.showDialog = true;
  }

  importData($event: SaveResult): void {
    switch (this.import.importType) {
      case ImportType.Organizations:
        this.importOrganizations($event);
        break;
      case ImportType.Contacts:
        this.importContacts($event);
        break;
      case ImportType.ContractTemplates:
        this.importContractTemplates($event);
        break;
      case ImportType.ContactSettings:
        this.importContactSettings($event);
        break;
      case ImportType.CrmData:
        this.importCRMData($event);
        break;
      case ImportType.UserGroups:
        this.importUserGroups($event);
        break;
      case ImportType.Users:
        this.importUsers($event);
        break;
      case ImportType.UserMemberships:
        this.importUserMemberships($event);
        break;
    }
  }

  importOrganizations($event: SaveResult): void {
    this.importService.importOrganization($event).subscribe((x) => {
      this.xlsxImportResult = x;
      this.reload();
      this.closeSidebar();
    });
  }

  // onContactImport(): void {
  //   this.organizationContactImportInput = {
  //     organizationId: this.organizationId,
  //     blob: uuidv4(),
  //     fileName: null,
  //     language: this.language,
  //   };
  //   this.showDialog = true;
  // }

  importContacts($event: SaveResult): void {
    this.importService.importOrganizationContact($event).subscribe((x) => {
      this.xlsxImportResult = x;
      this.closeSidebar();
    });
  }

  // onContractTemplateImport(): void {
  //   this.contractTemplateImportInput = {
  //     organizationId: this.organizationId,
  //     blob: uuidv4(),
  //     fileName: null,
  //     language: this.language,
  //   };
  //   this.showDialog = true;
  // }

  importContractTemplates($event: SaveResult): void {
    this.importService.importContractTemplate($event).subscribe((x) => {
      this.xlsxImportResult = x;
      this.closeSidebar();
    });
  }

  // onContactSettingsImport(): void {
  //   this.organizationContactSettingsImportInput = {
  //     organizationId: this.organizationId,
  //     blob: uuidv4(),
  //     fileName: null,
  //     language: this.language,
  //   };
  //   this.showDialog = true;
  // }

  importContactSettings($event: SaveResult): void {
    this.importService.importOrganizationContactSettings($event).subscribe((x) => {
      this.xlsxImportResult = x;
      this.closeSidebar();
    });
  }

  // onCRMDataImport(): void {
  //   this.crmdDataImportInput = {
  //     organizationId: this.organizationId,
  //     blob: uuidv4(),
  //     fileName: null,
  //     language: this.language,
  //   };
  //   this.showDialog = true;
  // }

  importCRMData($event: SaveResult): void {
    this.importService.importOrganizationCrm($event).subscribe((x) => {
      this.xlsxImportResult = x;
      this.closeSidebar();
    });
  }

  setMatching(org: OrganizationForList, enabled: boolean): void {
    this.dataSvc.setMatchingEnabled(org.id, enabled).subscribe(() => {
      this.orgs = this.orgs.map((x) => {
        if (x.id === org.id) {
          x.isMatchingEnabled = enabled;
        }
        return x;
      });
    });
  }

  delete(): void {
    this.deleteConfirmation.hide();
    if (this.orgCodeConfirmation === this.orgToDelete.code) {
      const orgName = this.orgToDelete.name;
      this.orgSvc.removeOrganization(this.orgToDelete).subscribe(() => {
        this.messageService.add({
          severity: "success",
          summary: translate("organization.deleted.title"),
          detail: translate("organization.deleted.message", { name: orgName }),
        });
      });
    }
    this.orgToDelete = null;
  }

  doNotDelete(): void {
    this.deleteConfirmation.hide();
    this.orgToDelete = null;
  }

  onUnlink(element: OrganizationForList, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("organization.confirmUnlink.message", element),
      header: translate("organization.confirmUnlink.title"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.linkService
          .delete({ organizationId: this.organizationId, linkedOrganizationId: element.id })
          .subscribe(() => {
            this.messageService.add({
              severity: "success",
              summary: translate("organization.unlinked.title"),
              detail: translate("organization.unlinked.message", { name: element.name }),
            });
          });
      },
    });
  }

  reload(): void {
    this.dataSubScription?.unsubscribe();
    this.dataSubScription =
      this.selectedModeOption == LinkMode.LinkByOrganization
        ? this.orgSvc
            .watchLinkedOrganizations(this.organizationId)
            .pipe(
              switchMap((orgs) =>
                orgs?.length
                  ? this.dataSvc.isMatchingEnabled(orgs.map((x) => x.id)).pipe(
                      map((ms) =>
                        orgs.map((x) => ({
                          ...x,
                          isMatchingEnabled: ms.find((m) => m.destinationOrganizationId === x.id)?.enabled ?? false,
                          isManageable: this.canOperate(x),
                        }))
                      )
                    )
                  : of<OrganizationForList[]>([])
              )
            )
            .subscribe((xs) => (this.orgs = clone(xs)))
        : this.linkService
            .getAllOrganizationWhichLinkTo(this.organizationId)
            .subscribe((xs) => (this.orgs = clone(xs)));
  }

  back(): void {
    void this.router.navigate(["/app/settings/organizations", this.settings.organizationId]);
  }

  generateAndOrganisationDataExport(): void {
    this.importExportService
      .exportOrganizationsData(this.organizationId, this.language)
      .subscribe(({ downloadUrl, fileName }) => {
        this.downloadService.downloadFile(fileName, downloadUrl);
      });
  }

  protected readonly CopyDataTypeEnum = CopyDataTypeEnum;

  private getCountryName(immigrationCountry: SupportedImmigrationCountry): string {
    return this.countries.find((x) => x.value === immigrationCountry)?.label ?? immigrationCountry;
  }

  private isImmigrationCountrySupported(immigrationCountry: SupportedImmigrationCountry): boolean {
    return this.settings.supportedImmigrationCountries.includes(immigrationCountry);
  }

  private importUserGroups($event: SaveResult): void {
    this.importService.importUserGroups($event).subscribe((x) => {
      this.xlsxImportResult = x;
      this.closeSidebar();
    });
  }

  private importUsers($event: SaveResult): void {
    this.importService.importUsers($event).subscribe((x) => {
      this.xlsxImportResult = x;
      this.closeSidebar();
    });
  }

  private importUserMemberships($event: SaveResult): void {
    this.importService.importUserMemberships($event).subscribe((x) => {
      this.xlsxImportResult = x;
      this.closeSidebar();
    });
  }
}
