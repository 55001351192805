<ng-container *appTranslate="let t">
  <form *ngIf="form" [formGroup]="form" (ngSubmit)="saved.emit(form.getRawValue())">
    <p-card [header]="form.value.id ? t('familyMember.edit') : t('familyMember.create')">
      <div class="p-fluid">
        <div class="p-fluid">
          <div class="field">
            <div class="field-checkbox">
              <p-checkbox formControlName="reunification" [binary]="true" inputId="reunification"></p-checkbox>
              <label for="reunification">{{ t("reunification.title") }}</label>
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-info-circle"
                class="p-button-rounded p-button-text"
                (click)="reunification.show($event)"
              ></button>
            </div>
          </div>
          <div class="field">
            <div class="field-checkbox" [pTooltip]="t('emergencyContact.info')">
              <p-checkbox
                formControlName="isEmergencyContact"
                [binary]="true"
                inputId="isEmergencyContact"
              ></p-checkbox>
              <label for="isEmergencyContact">{{ t("emergencyContact.title") }}</label>
            </div>
          </div>
          <p-overlayPanel [style]="{ width: '50vw' }" #reunification (onShow)="displayHint = true">
            <ng-template pTemplate>
              <p class="m-0" [innerHTML]="t('reunification.message') | safe: 'html'"></p>

              <p-button
                submitOnEnter
                [label]="t('common.ok')"
                icon="pi pi-times"
                type="submit"
                [style]="{ float: 'right' }"
                class="mb-2 mr-2"
                (onClick)="reunification.hide()"
              ></p-button>
            </ng-template>
          </p-overlayPanel>
        </div>
        <p-fieldset [legend]="t('personalData.title')">
          <div class="grid">
            <div class="field col-4">
              <label for="relation">{{ t("relation.title") }}</label>
              <p-dropdown
                inputId="relation"
                formControlName="relation"
                [options]="relations"
                [placeholder]="t('relation.placeholder')"
              ></p-dropdown>
              <small class="p-error" *ngIf="form.controls.relation.errors?.required">
                {{ t("relation.required") }}
              </small>
            </div>
            <div class="field col-4">
              <label for="salutation">{{ t("salutation.title") }}</label>
              <p-dropdown
                inputId="salutation"
                formControlName="salutation"
                [options]="salutations | async"
                [placeholder]="t('salutation.placeholder')"
              ></p-dropdown>
              <small class="p-error" *ngIf="form.controls.salutation.errors?.required">
                {{ t("salutation.required") }}
              </small>
            </div>
            <div class="col-4"></div>

            <div class="field col-4">
              <label for="firstName">{{ t("firstName.title") }}</label>
              <input id="firstName" pInputText formControlName="firstName" maxlength="100" />
              <small class="p-error" *ngIf="form.controls.firstName.errors?.required">
                {{ t("firstName.required") }}
              </small>
            </div>

            <div class="field col-4">
              <label for="lastName">{{ t("lastName.title") }}</label>
              <input id="lastName" pInputText formControlName="lastName" maxlength="100" />
              <small class="p-error" *ngIf="form.controls.lastName.errors?.required">
                {{ t("lastName.required") }}
              </small>
            </div>

            <div class="field col-4">
              <label for="birthName">{{ t("birthName.title") }}</label>
              <input id="birthName" pInputText formControlName="birthName" maxlength="100" />
            </div>

            <div class="field col-4">
              <label for="birthDate">{{ t("birthDate.title") }}</label>
              <p-calendar
                inputId="birthDate"
                formControlName="birthDate"
                [minDate]="minBirthDate"
                [maxDate]="maxBirthDate"
              ></p-calendar>
              <small class="p-error" *ngIf="form.controls.birthDate.errors?.required">
                {{ t("birthDate.required") }}
              </small>
            </div>

            <div class="field col-4">
              <label for="cityOfBirth">{{ t("cityOfBirth.title") }}</label>
              <input id="cityOfBirth" pInputText formControlName="cityOfBirth" maxlength="100" />
              <small class="p-error" *ngIf="form.controls.cityOfBirth.errors?.required">
                {{ t("cityOfBirth.required") }}
              </small>
            </div>

            <div class="field col-4">
              <label for="countryOfBirth">{{ t("countryOfBirth.title") }}</label>
              <p-dropdown
                inputId="countryOfBirth"
                formControlName="countryOfBirth"
                [options]="countries | async"
                [placeholder]="t('countryOfBirth.placeholder')"
              ></p-dropdown>
              <small class="p-error" *ngIf="form.controls.countryOfBirth.errors?.required">
                {{ t("countryOfBirth.required") }}
              </small>
            </div>

            <div class="field col-4">
              <label for="email">{{ t("email.title") }}</label>
              <input id="email" pInputText formControlName="email" />
              <small class="p-error" *ngIf="form.controls.email.errors?.pattern">
                {{ t("email.invalid") }}
              </small>
            </div>

            <div class="field col-4">
              <label for="phone">{{ t("phone.title") }}</label>
              <input id="phone" pInputText formControlName="phone" maxlength="50" />
            </div>

            <div class="field col-4">
              <label for="citizenship">{{ t("citizenship.title") }}</label>
              <p-dropdown
                inputId="citizenship"
                formControlName="citizenship"
                [options]="countries | async"
                [placeholder]="t('citizenship.placeholder')"
              ></p-dropdown>
              <small class="p-error" *ngIf="form.controls.citizenship.errors?.required">
                {{ t("citizenship.required") }}
              </small>
            </div>
            <div class="field col-4">
              <label for="germanLanguageSkill">{{ t("germanLanguageSkills.title") }}</label>
              <p-dropdown
                inputId="germanLanguageSkill"
                formControlName="germanLanguageSkill"
                [options]="languageSkills | async"
                [showClear]="true"
              ></p-dropdown>
            </div>
          </div>
        </p-fieldset>

        <p-fieldset [legend]="t('address.title')">
          <div class="grid">
            <div class="field col-12">
              <div class="field-checkbox">
                <p-checkbox
                  [formControl]="$any(form.controls.sameAddressAsCandidate)"
                  [binary]="true"
                  inputId="sameAddressAsCandidate"
                ></p-checkbox>
                <label for="sameAddressAsCandidate">{{ t("sameAddressAsCandidate.title") }}</label>
              </div>
            </div>
            <ng-container *ngIf="!form.controls.sameAddressAsCandidate.value">
              <div class="field col-12">
                <label for="address">{{ t("address.title") }}</label>
                <textarea id="address" pInputTextarea rows="6" formControlName="address" maxlength="500"></textarea>
                <small class="p-error" *ngIf="form.controls.address.errors?.required">
                  {{ t("address.required") }}
                </small>
              </div>
              <div class="field col-4">
                <label for="zipCode">{{ t("zipCode.title") }}</label>
                <input id="zipCode" pInputText formControlName="zipCode" maxlength="100" />
                <small class="p-error" *ngIf="form.controls.zipCode.errors?.required">
                  {{ t("zipCode.required") }}
                </small>
              </div>
              <div class="field col-4">
                <label for="city">{{ t("city.title") }}</label>
                <input id="city" pInputText formControlName="city" maxlength="100" />
                <small class="p-error" *ngIf="form.controls.city.errors?.required">
                  {{ t("city.required") }}
                </small>
              </div>
              <div class="field col-4">
                <label for="country">{{ t("country.title") }}</label>
                <p-dropdown
                  inputId="country"
                  formControlName="country"
                  [options]="countries | async"
                  [placeholder]="t('country.placeholder')"
                ></p-dropdown>
                <small class="p-error" *ngIf="form.controls.country.errors?.required">
                  {{ t("country.required") }}
                </small>
              </div>
            </ng-container>
          </div>
        </p-fieldset>

        <ng-container *ngIf="form.controls.relation.value === CandidateRelationEnum.Child">
          <p-fieldset [legend]="t('custody.title')">
            <div class="grid">
              <div class="field col-12">
                <div class="field-checkbox">
                  <p-checkbox
                    [formControl]="$any(form.controls.hasSharedCustody)"
                    [binary]="true"
                    inputId="hasSharedCustody"
                  ></p-checkbox>
                  <label for="hasSharedCustody">{{ t("hasSharedCustody.title") }}</label>
                </div>
              </div>
              <ng-container *ngIf="form.controls.hasSharedCustody.value">
                <div class="field">
                  <label for="otherCustodian">{{ t("otherCustodian.title") }}</label>
                  <p-dropdown
                    inputId="otherCustodian"
                    formControlName="otherCustodian"
                    [placeholder]="t('otherCustodian.placeholder')"
                    filterBy="name"
                    [showClear]="true"
                    [options]="custodians"
                  ></p-dropdown>
                </div>
              </ng-container>
            </div>
          </p-fieldset>
        </ng-container>
      </div>

      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
          <p-button
            [disabled]="!form.valid || !form.dirty"
            [label]="t('common.save')"
            icon="pi pi-check"
            class="mr-2"
            type="submit"
          ></p-button>
          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            type="reset"
            (onClick)="closed.emit()"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  </form>
</ng-container>
