import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Document, DocumentMode, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { FieldType } from "@ngx-formly/core";
import { MenuItem } from "primeng/api";
import { map, Observable, of, switchMap } from "rxjs";
import { DocumentsService } from "../../features/documents/documents.service";
import { CandidateProfileDialogService } from "../../organization-specific/base-candidate-profile/candidate-profile-dialog.service";
import { StaticDataService } from "../../shared/static-data/static-data.service";

@Component({
  selector: "app-formly-document-upload",
  templateUrl: "./formly-document-upload.component.html",
  styleUrl: "./formly-document-upload.component.scss",
  standalone: false,
})
export class FormlyDocumentUploadComponent extends FieldType implements OnInit {
  readonly language = this.transloco.getActiveLang();

  menuItems: MenuItem[];

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly documentService: DocumentsService,
    private readonly profileDialogService: CandidateProfileDialogService,
    private readonly transloco: TranslocoService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.formState.candidateId) {
      this.staticDataService
        .getStaticData(StaticDataType.AllowedUploadFileTypes, this.language, {
          candidateId: this.formState.candidateId,
          organizationId: this.formState.organizationId,
        })
        .subscribe((data) => {
          this.menuItems = this.props.types?.map((type) => ({
            label: data.find((d) => d.value == type)?.label,
            command: (): void => this.openDialog(type),
          }));
          this.changeDetector.detectChanges();
        });
    } else {
      this.menuItems = null;
    }
  }

  openDialog(type: string): void {
    this.documentService
      .getByType(type, this.formState.organizationId, this.formState.candidateId)
      .pipe(
        switchMap((document) => (document ? of(document) : this.createDocument(type))),
        map((document) => ({ document, candidateForm: this.form }))
      )
      .subscribe((event) => this.profileDialogService.documentDialog.next(event));
  }

  private createDocument(type: string): Observable<Document> {
    return this.documentService.createEmptyDocument(
      DocumentMode.Candidate,
      this.formState.organizationId,
      this.formState.candidateId,
      null,
      type
    );
  }
}
