import { isEmpty, isNil } from "lodash";
import { MergedContractTemplate } from "./contract-template-helper";
import { validateAllowances, validateBenefits, validateNumbers } from "./labor-contract-validation";
import { isTechnicallyEmpty } from "./lodash-extra";
import { TranslatableValidationIssue, TranslatedValidationIssue } from "./model/translatable-validation-issue";

export function validateContractTemplate(
  contractTemplate: MergedContractTemplate,
  translateError: (error: string) => string
): void {
  const issues = getTranslatableContractValidationIssues(contractTemplate);
  if (isEmpty(issues)) {
    return;
  }

  throw new Error(
    issues
      .map(({ propertyName, message }) => new TranslatedValidationIssue(propertyName, translateError(message)))
      .map(({ propertyName, message }) => `${propertyName}: ${message}`)
      .join("\n")
  );
}

export function getTranslatableContractValidationIssues(
  contractTemplate: MergedContractTemplate
): TranslatableValidationIssue[] {
  return [
    ...validateBasics(contractTemplate),
    ...validateOvertimeRegulation(contractTemplate),
    ...validateMinMaxDates(contractTemplate),
    ...validateLaborAgreementFields(contractTemplate),
    ...validateCompensation(contractTemplate),
    ...validateAllowances(contractTemplate.allowances ?? []),
    ...validateBenefits(contractTemplate.benefits ?? []),
  ];
}

// Validation for basic fields
function validateBasics(template: MergedContractTemplate): TranslatableValidationIssue[] {
  const issues: TranslatableValidationIssue[] = [];

  const numberValidationIssues = validateNumbers(template, [
    "probationPeriod",
    "noticePeriod",
    "workingHoursPerWeek",
    "numberOfSalaries",
    "holidayEntitlement",
    "holidayEntitlement",
  ]);

  return [...issues, ...numberValidationIssues];
}

function validateOvertimeRegulation(template: MergedContractTemplate): TranslatableValidationIssue[] {
  const issues: TranslatableValidationIssue[] = [];

  if (template.overtimeWithinLimitsPermittedByLaw) {
    if (!isNil(template.overtimeHours)) {
      issues.push(new TranslatableValidationIssue("overtimeHours", "contractTemplate.errors.overTimeHoursForbidden"));
    }
  }

  return issues;
}

function validateMinMaxDates(template: Partial<MergedContractTemplate>): TranslatableValidationIssue[] {
  const issues: TranslatableValidationIssue[] = [];

  const { validFrom, validUntil } = template;
  if (validFrom && validUntil && validFrom > validUntil) {
    issues.push(new TranslatableValidationIssue("validFrom", "validFrom.errors.isSmallerThanValidUntil"));
  }

  return issues;
}

function validateLaborAgreementFields(template: MergedContractTemplate): TranslatableValidationIssue[] {
  const issues: TranslatableValidationIssue[] = [];
  const { contractType, laborAgreementSelection, customLaborAgreement } = template;

  const hasLaborAgreement = !isTechnicallyEmpty(laborAgreementSelection);
  const hasCustomAgreement = !isTechnicallyEmpty(customLaborAgreement);
  if (isNil(contractType) || contractType === "AgreementByContract") {
    if (hasLaborAgreement) {
      issues.push(
        new TranslatableValidationIssue(
          "laborAgreementSelection",
          "contractTemplate.errors.contractTypeForbidsLaborAgreement"
        )
      );
    }

    if (hasCustomAgreement) {
      issues.push(
        new TranslatableValidationIssue(
          "laborAgreementSelection",
          "contractTemplate.errors.contractTypeForbidsLaborAgreement"
        )
      );
    }
  } else if (hasLaborAgreement && hasCustomAgreement) {
    issues.push(
      new TranslatableValidationIssue(
        "laborAgreementSelection",
        "contractTemplate.errors.agreementSelectionAndCustomEntry"
      )
    );
  }

  return issues;
}

function validateCompensation(template: MergedContractTemplate): TranslatableValidationIssue[] {
  const issues: TranslatableValidationIssue[] = [];

  if (!isNil(template.numberOfSalaries) && template.numberOfSalaries < 0) {
    issues.push(new TranslatableValidationIssue("numberOfSalaries", "class-validator.isPositive"));
  }

  return issues;
}
