import { Injectable } from "@angular/core";
import {
  AddAssignedCollectionGQL,
  AssignCollectionInput,
  Candidate,
  CandidateAssignmentException,
  CandidateId,
  Collection,
  CollectionAndSharingInput,
  CollectionCreateInput,
  CollectionFragment,
  CollectionFragmentDoc,
  CollectionInput,
  CollectionTransferInput,
  CollectionTransferResult,
  CollectionUpdateInput,
  CreateCollectionGQL,
  CreateCollectionMutation,
  CreateCollectionWithSharingGQL,
  EffectiveSharingOptionsInput,
  EffectiveSharingOutput,
  GetAllCollectionsGQL,
  GetAllCollectionsQuery,
  GetAllCollectionsWithCandidatesGQL,
  GetAllCollectionsWithCandidatesQuery,
  GetCandidatesForAssignmentGQL,
  GetCandidatesForAssignmentQuery,
  GetCollectionGQL,
  GetCollectionsForAssignmentGQL,
  GetCollectionsForAssignmentQuery,
  GetCollectionsForDelegationGQL,
  GetCollectionsForDelegationQuery,
  GetCollectionsSharedToOrganizationGQL,
  GetEffectiveSharingGQL,
  GetFavoriteCollectionsGQL,
  GetImmigrationCountriesGQL,
  GetSelectableCollectionsGQL,
  GetSelectableCollectionsQuery,
  GetSharingsForCollectionGQL,
  Organization,
  RemoveAssignedCollectionGQL,
  RemoveCollectonGQL,
  Sharing,
  SharingTypeEnum,
  TransferCollectionGQL,
  UpdateAssignedCandidatesGQL,
  UpdateAssignedCandidatesInput,
  UpdateAssignmentExceptionsGQL,
  UpdateAssignmentExceptionsInput,
  UpdateCollectionGQL,
} from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { clone } from "lodash";
import { Observable, forkJoin, map, of } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";
import { ALL_CANDIDATES } from "../candidates/candidates.service";
import { OrganizationsService } from "../organizations/organizations.service";
import { CustomLazyLoadEvent } from "./collection-edit-assigned-candidates/custom-filter.model";

export type CollectionRows = GetAllCollectionsQuery["getAllCollections"];
export type CollWithCandRows = GetAllCollectionsWithCandidatesQuery["getAllCollections"];
export type CandidateForAssignment = Candidate & Pick<CandidateId, "originalColId" | "originalOrgId">;
export type CollectionAndSharing = GetSelectableCollectionsQuery["getSelectableCollections"][0];

@Injectable({ providedIn: "root" })
export class CollectionService {
  readonly language = this.transloco.getActiveLang();
  constructor(
    private readonly createCol: CreateCollectionGQL,
    private readonly createColWithSharing: CreateCollectionWithSharingGQL,
    private readonly allColl: GetAllCollectionsGQL,
    private readonly allCollWithCand: GetAllCollectionsWithCandidatesGQL,
    private readonly upColl: UpdateCollectionGQL,
    private readonly getColl: GetCollectionGQL,
    private readonly removeColl: RemoveCollectonGQL,
    private readonly allCandidates: GetCandidatesForAssignmentGQL,
    private readonly assignedCandidates: UpdateAssignedCandidatesGQL,
    private readonly settings: SettingsService,
    private readonly orgService: OrganizationsService,
    private readonly getSharingforCollection: GetSharingsForCollectionGQL,
    private readonly sharedToOrg: GetCollectionsSharedToOrganizationGQL,
    private readonly favoriteCollections: GetFavoriteCollectionsGQL,
    private readonly collectionsForDelegation: GetCollectionsForDelegationGQL,
    private readonly collectionsForAssignment: GetCollectionsForAssignmentGQL,
    private readonly selectable: GetSelectableCollectionsGQL,
    private readonly colTransfer: TransferCollectionGQL,
    private readonly colAddAssigned: AddAssignedCollectionGQL,
    private readonly colRemoveAssigned: RemoveAssignedCollectionGQL,
    private readonly transloco: TranslocoService,
    private readonly effectiveSharing: GetEffectiveSharingGQL,
    private readonly immigrationCountries: GetImmigrationCountriesGQL,
    private readonly updateAssignmentExceptionsGQL: UpdateAssignmentExceptionsGQL
  ) {}

  getAllAssignableCandidates(
    collectionId: string,
    organizationId: string,
    event: CustomLazyLoadEvent = { rows: ALL_CANDIDATES },
    excludeAssignmentExceptions = false
  ): Observable<GetCandidatesForAssignmentQuery["getCandidatesForAssignment"]> {
    return this.allCandidates
      .fetch({
        input: {
          organizationId: organizationId,
          collectionId: collectionId,
          first: event.first,
          rows: event.rows,
          filters: event.filters,
          language: this.language,
          excludeAssignmentExceptions,
        },
      })
      .pipe(map((x) => x.data.getCandidatesForAssignment));
  }

  getCollection(organizationId: string, collectionId: string, useCache = false): Observable<Collection> {
    return this.getColl
      .fetch(
        { input: { collectionId: collectionId, organizationId: organizationId } },
        { fetchPolicy: useCache ? "cache-first" : undefined }
      )
      .pipe(map((x) => x.data.getCollection as Collection));
  }

  removeCollection(coll: Collection): Observable<boolean> {
    const proxy = { id: coll.id, __typename: coll.__typename }; // creating the dummy object here in order to create id with cache.identiy method
    return this.removeColl
      .mutate(
        { input: { collectionId: coll.id, organizationId: coll.organizationId, _etag: coll._etag } },
        {
          update: (cache, result) =>
            result?.data.deleteCollection.status && (<any>cache).data.delete(cache.identify(proxy)),
        }
      )
      .pipe(map((x) => x.data.deleteCollection.status));
  }

  updateCollection(collection: CollectionUpdateInput): Observable<Collection> {
    const input: CollectionUpdateInput = clone(collection);
    delete input["__typename"];
    delete input["assignedCandidates"];
    delete input["assignedCollections"];
    delete input["assignedCandidateIds"];
    delete input["assignedCollectionIds"];
    delete input["assignmentExceptionIds"];
    delete input["assignmentExceptions"];
    delete input["totalCandidatesFromAssignedCollections"];
    delete input["sharings"];
    delete input["processes"];
    const autoCollectionSettings = input.autoCollectionSettings;
    if (autoCollectionSettings) {
      const autoCollectionSettingsCopy = clone(autoCollectionSettings);
      delete autoCollectionSettingsCopy["__typename"];
      input.autoCollectionSettings = autoCollectionSettingsCopy;
    }
    return this.upColl.mutate({ input: input }).pipe(map((x) => x.data.updateCollection as Collection));
  }

  updateAssignedCandidates(collection: Collection, candidates: CandidateForAssignment[]): Observable<Collection> {
    const input: UpdateAssignedCandidatesInput = {
      collectionId: collection.id,
      organizationId: collection.organizationId,
      assignedCandidatesIds: candidates.map((x) => ({
        id: x.id,
        orgId: x.organizationId,
        originalColId: x.originalColId,
        originalOrgId: x.originalOrgId,
      })),
      _etag: collection._etag,
    };
    return this.assignedCandidates
      .mutate({ input: input })
      .pipe(map((x) => x.data.updateAssignedCandidates as Collection));
  }

  updateCandidateAssignmentExceptions(
    collection: Collection,
    assignmentExceptions: CandidateAssignmentException[]
  ): Observable<Collection> {
    const input: UpdateAssignmentExceptionsInput = {
      collectionId: collection.id,
      organizationId: collection.organizationId,
      assignmentExceptions: assignmentExceptions.map((x) => ({
        id: x.id,
        orgId: x.organizationId,
        assignmentExceptionType: x.assignmentExceptionType,
      })),
      _etag: collection._etag,
    };
    return this.updateAssignmentExceptionsGQL
      .mutate(
        { input: input },
        {
          update: (cache, mutationResult) =>
            cache.modify({
              fields: {
                getAllCollections(refs, helper) {
                  updateApolloCache(collection, refs, helper, cache, mutationResult.data.updateAssignmentExceptions);
                },
                getCollectionsWithSharingToOrganization(refs, helper) {
                  updateApolloCache(collection, refs, helper, cache, mutationResult.data.updateAssignmentExceptions);
                },
              },
            }),
        }
      )
      .pipe(map((x) => x.data.updateAssignmentExceptions as Collection));
  }

  createCollection(input: CollectionCreateInput): Observable<CreateCollectionMutation["createCollection"]> {
    const collection = new CollectionCreateInput();
    collection.name = input.name;
    collection.organizationId = input.organizationId;
    collection.description = input.description;
    collection.type = input.type;
    collection.targetSize = input.targetSize;
    return this.createCol
      .mutate(
        { input: collection },
        {
          update: (cache, mutationResult) =>
            cache.modify({
              fields: {
                getAllCollections(refs, helper) {
                  updateApolloCache(collection, refs, helper, cache, mutationResult.data.createCollection);
                },
                getCollectionsWithSharingToOrganization(refs, helper) {
                  updateApolloCache(collection, refs, helper, cache, mutationResult.data.createCollection);
                },
              },
            }),
        }
      )
      .pipe(map((x) => x.data.createCollection));
  }

  createCollectionWithSharing(input: CollectionAndSharingInput): Observable<CollectionFragment> {
    return this.createColWithSharing
      .mutate(
        { input: input },
        {
          update: (cache, x) =>
            cache.modify({
              fields: {
                getAllCollections: (refs, helper) =>
                  updateApolloCache(input.collection, refs, helper, cache, x.data.createCollectionWithSharing),
                getCollectionsWithSharingToOrganization: (refs, helper) =>
                  updateApolloCache(input.collection, refs, helper, cache, x.data.createCollectionWithSharing),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.createCollectionWithSharing));
  }

  getCollections(): Observable<CollectionRows> {
    return this.allColl
      .watch({ input: { organizationId: this.settings.organizationId } })
      .valueChanges.pipe(map((x) => x.data.getAllCollections));
  }

  getCollectionsOnce(): Observable<CollectionRows> {
    return this.allColl
      .fetch({ input: { organizationId: this.settings.organizationId } })
      .pipe(map((x) => x.data.getAllCollections));
  }

  getCollectionsWithCandidates(): Observable<CollWithCandRows> {
    return this.allCollWithCand
      .watch({ input: { organizationId: this.settings.organizationId } })
      .valueChanges.pipe(map((x) => x.data.getAllCollections));
  }

  getCollectionsWithCandidatesOnce(): Observable<CollWithCandRows> {
    return this.allCollWithCand
      .fetch({ input: { organizationId: this.settings.organizationId } })
      .pipe(map((x) => x.data.getAllCollections));
  }

  getCollectionsSharedToOrganization(
    organizationId: string,
    sharedToOrganizationId: string,
    sharingTypes: SharingTypeEnum[]
  ): Observable<CollectionRows> {
    // vz: if you understand what that comment means, please rephrase it so that we all do |
    //                                                                                     |
    // watch does not work. Because cache update is not recognized!    <-------------------|
    return this.sharedToOrg
      .fetch({ input: { organizationId, sharedToOrganizationId, sharingTypes } })
      .pipe(map((x) => x.data.getCollectionsSharedToOrganization));
  }

  getFavoriteCollections(destOrganizationId?: string): Observable<CollectionRows> {
    return this.favoriteCollections
      .fetch({ input: { organizationId: this.settings.organizationId, sharedToOrganizationId: destOrganizationId } })
      .pipe(map((x) => x.data.getFavoriteCollections));
  }

  getCollectionsForDelegation(
    collectionId: string,
    collectionOrganizationId: string
  ): Observable<GetCollectionsForDelegationQuery["getCollectionsForDelegation"]> {
    return this.collectionsForDelegation
      .fetch({
        input: {
          collectionId: collectionId,
          collectionOrganizationId: collectionOrganizationId,
          organizationId: this.settings.organizationId,
        },
      })
      .pipe(map((x) => x.data.getCollectionsForDelegation));
  }

  getCollectionsForAssignment(
    collectionId: string,
    organizationId: string
  ): Observable<GetCollectionsForAssignmentQuery["getCollectionsForAssignment"]> {
    return this.collectionsForAssignment
      .fetch({
        input: {
          collectionId: collectionId,
          fromOrganizationId: organizationId,
          organizationId: this.settings.organizationId,
        },
      })
      .pipe(map((x) => x.data.getCollectionsForAssignment));
  }

  getSelectableCollections(
    sharingTypes: SharingTypeEnum[],
    organizationId: string,
    sharedTabs?: string[]
  ): Observable<CollectionAndSharing[]> {
    return organizationId
      ? this.selectable
          .watch({
            input: {
              sharedToOrganizationId: this.settings.organizationId,
              organizationId: organizationId,
              sharingTypes: sharingTypes,
              sharedTabs: sharedTabs,
            },
          })
          .valueChanges.pipe(map((result) => result.data.getSelectableCollections))
      : of([]);
  }

  getAssignableOrgs(collection: Pick<Collection, "id" | "organizationId">): Observable<Organization[]> {
    const sharings = collection.id
      ? this.getSharingforCollection.fetch({
          input: { collectionId: collection.id, organizationId: collection.organizationId },
        })
      : of({ data: { getSharingsForCollection: <Sharing[]>[] } });
    const orgs = this.orgService.getLinkedOrganizations(collection.organizationId) as Observable<Organization[]>;
    return forkJoin([sharings, orgs]).pipe(
      map((x) => {
        const orgs = x[1];
        const sharings = x[0];
        return orgs.filter(
          (org) =>
            !sharings.data.getSharingsForCollection.find((sharing) => sharing.destinationOrganizationId == org.id)
        );
      })
    );
  }

  getSharingsForCollection(collectionId: string, organizationId: string): Observable<Sharing[]> {
    return this.getSharingforCollection
      .fetch({ input: { collectionId: collectionId, organizationId: organizationId } })
      .pipe(map((x) => x.data.getSharingsForCollection));
  }

  getEffectiveSharing(
    candidateId: string,
    candidateOrganizationId: string,
    options?: EffectiveSharingOptionsInput
  ): Observable<EffectiveSharingOutput> {
    const { organizationId } = this.settings;
    return this.effectiveSharing
      .fetch({ input: { candidateId, candidateOrganizationId, organizationId, options } })
      .pipe(map((x) => x.data.getEffectiveSharing));
  }

  transferCollection(input: CollectionTransferInput): Observable<CollectionTransferResult> {
    return this.colTransfer.mutate({ input: input }).pipe(map((x) => x.data.transferCollection));
  }

  addAssignedCollection(input: AssignCollectionInput): Observable<CollectionFragment> {
    return this.colAddAssigned.mutate({ input: input }).pipe(map((x) => x.data.addAssignedCollection));
  }

  removeAssignedCollection(input: AssignCollectionInput): Observable<CollectionFragment> {
    return this.colRemoveAssigned.mutate({ input: input }).pipe(map((x) => x.data.removeAssignedCollection));
  }

  getImmigrationCountries(input: CollectionInput): Observable<string[]> {
    return this.immigrationCountries.fetch({ input: input }).pipe(map((x) => x.data.getImmigrationCountries));
  }
}

function updateApolloCache(input, refs, { storeFieldName }, cache, data): any {
  if (!storeFieldName.includes(input.organizationId)) return refs;
  const ref = cache.writeFragment({
    data: data,
    fragment: CollectionFragmentDoc,
    fragmentName: "Collection",
  });
  if (!refs?.length) return [ref];
  const index = refs.findIndex((x) => x.__ref == ref.__ref);
  if (index > -1) {
    return [...refs.slice(0, index), ref, ...refs.slice(index + 1)];
  } else {
    return [...refs, ref];
  }
}
