<p-card [header]="t('candidates.import')" *appTranslate="let t">
  <div class="p-fluid flex flex-column gap-2">
    <p-fieldset [legend]="t('importSheet.title')">
      <div class="flex flex-column gap-1">
        <div>
          <label for="importLanguage">{{ t("importSheet.language") }}</label>
          <app-language-dropdown
            id="importLanguage"
            [options]="languageOptions$ | async"
            [(selectedLanguage)]="selectedLanguage"
          ></app-language-dropdown>
        </div>

        <div class="field mt-3" *ngIf="isMasterUser">
          <p-inputSwitch id="addTestData" [(ngModel)]="addTestData"></p-inputSwitch>
          <label for="addTestData" class="ml-2">{{ t("addTestData.title") }}</label>
        </div>

        <div class="field">
          <a [href]="legacyTemplateDownloadUrl" *ngIf="legacyTemplateDownloadUrl">
            <i class="pi pi-download mr-2"></i>
            <span>
              {{ t("candidates.downloadLegacyImportTemplate") }}
            </span>
          </a>
        </div>

        <ng-container *ngFor="let country of immigrationCountries$ | async">
          <div class="field">
            <a (click)="generateAndDownloadTemplate(CandidateSheetModeEnum.Simple, country.value)" class="clickable">
              <i class="pi pi-download mr-2"></i>
              <span *ngIf="country.value === defaultImmigrationCountry">
                {{ t("candidates.downloadImportTemplate") }}
              </span>
              <span *ngIf="country.value !== defaultImmigrationCountry">
                {{ t("candidates.downloadImportTemplate") }} ({{ country.label }})
              </span>
            </a>
          </div>

          <div class="field" *ngIf="isMasterUser">
            <a (click)="generateAndDownloadTemplate(CandidateSheetModeEnum.Full, country.value)" class="clickable">
              <i class="pi pi-download mr-2"></i>
              <span *ngIf="country.value === defaultImmigrationCountry">
                {{ t("candidates.downloadMigrationTemplate") }}
              </span>
              <span *ngIf="country.value !== defaultImmigrationCountry">
                {{ t("candidates.downloadMigrationTemplate") }} ({{ country.label }})
              </span>
            </a>
          </div>
        </ng-container>
      </div>
    </p-fieldset>
    <div class="field">
      <p-fileUpload
        #upload
        [maxFileSize]="64 * 1024 * 1024"
        [showUploadButton]="false"
        [showCancelButton]="false"
        accept=".xlsx"
        [chooseLabel]="t('file.choose')"
        [invalidFileSizeMessageSummary]="t('primeng.invalidFileSizeMessageSummary')"
        [invalidFileSizeMessageDetail]="t('primeng.invalidFileSizeMessageDetail')"
        [invalidFileTypeMessageSummary]="t('primeng.invalidFileTypeMessageSummary')"
        [invalidFileTypeMessageDetail]="t('primeng.invalidFileTypeMessageDetail')"
        [invalidFileLimitMessageDetail]="t('primeng.invalidFileLimitMessageDetail')"
        [invalidFileLimitMessageSummary]="t('primeng.invalidFileLimitMessageSummary')"
        [customUpload]="true"
        (onSelect)="selectFile($event.currentFiles[0])"
        (uploadHandler)="uploadFile($event.files[0])"
      >
        <ng-template pTemplate="file"></ng-template>
        <ng-template let-files pTemplate="content">
          <div class="p-fileupload-prompt-wrapper" *ngIf="!files || files.length === 0">
            <div class="p-fileupload-prompt">
              {{ t("file.drop") }}
            </div>
          </div>
          <table>
            <tr *ngFor="let file of files; let i = index">
              <td>
                {{ file.name }}
              </td>
              <td>
                {{
                  (file.size > 1024 * 1024 ? file.size / 1024 / 1024 : file.size / 1024)
                    | translocoDecimal: { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }
                }}
                {{ file.size > 1024 * 1024 ? t("common.unit.mb") : t("common.unit.kb") }}
              </td>
            </tr>
          </table>
        </ng-template>
      </p-fileUpload>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex flex-row justify-content-end">
      <p-button
        [disabled]="isUploading || !fileSelected"
        [label]="t('common.save')"
        icon="pi pi-check"
        (onClick)="submit()"
        class="mr-2"
      ></p-button>
      <p-button
        closeOnEscape
        [label]="t('common.cancel')"
        icon="pi pi-times"
        class="p-button-secondary"
        type="reset"
        (onClick)="closed.emit()"
      ></p-button>
    </div>
  </ng-template>
</p-card>
