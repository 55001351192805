import { Injectable } from "@angular/core";
import {
  CollectionFilterPresetChangeStateInput,
  CollectionFilterPresetCreateInput,
  CollectionFilterPresetDeleteInput,
  CollectionFilterPresetGetInput,
  CollectionFilterPresetListInput,
  CollectionFilterPresetUpdateInput,
  CreateCollectionFilterPresetGQL,
  CreateCollectionFilterPresetMutation,
  DeleteCollectionFilterPresetGQL,
  DeleteCollectionFilterPresetMutation,
  GetCollectionFilterPresetGQL,
  GetCollectionFilterPresetQuery,
  GetCollectionFilterPresetsGQL,
  GetCollectionFilterPresetsQuery,
  MakeCollectionFilterPresetPrivateGQL,
  MakeCollectionFilterPresetPrivateMutation,
  MakeCollectionFilterPresetPublicGQL,
  MakeCollectionFilterPresetPublicMutation,
  UpdateCollectionFilterPresetGQL,
  UpdateCollectionFilterPresetMutation,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class CandidateFilterPresetService {
  constructor(
    private readonly getAllGql: GetCollectionFilterPresetsGQL,
    private readonly getGql: GetCollectionFilterPresetGQL,
    private readonly createGql: CreateCollectionFilterPresetGQL,
    private readonly updateGql: UpdateCollectionFilterPresetGQL,
    private readonly makePublicGql: MakeCollectionFilterPresetPublicGQL,
    private readonly makePrivateGql: MakeCollectionFilterPresetPrivateGQL,
    private readonly deleteGql: DeleteCollectionFilterPresetGQL
  ) {}

  getAll(
    input: CollectionFilterPresetListInput
  ): Observable<GetCollectionFilterPresetsQuery["getCollectionFilterPresets"]> {
    return this.getAllGql.fetch({ input: input }).pipe(map((result) => result.data.getCollectionFilterPresets));
  }

  get(input: CollectionFilterPresetGetInput): Observable<GetCollectionFilterPresetQuery["getCollectionFilterPreset"]> {
    return this.getGql.fetch({ input: input }).pipe(map((result) => result.data.getCollectionFilterPreset));
  }

  create(
    input: CollectionFilterPresetCreateInput
  ): Observable<CreateCollectionFilterPresetMutation["createCollectionFilterPreset"]> {
    return this.createGql.mutate({ input: input }).pipe(map((result) => result.data.createCollectionFilterPreset));
  }

  update(
    input: CollectionFilterPresetUpdateInput
  ): Observable<UpdateCollectionFilterPresetMutation["updateCollectionFilterPreset"]> {
    return this.updateGql.mutate({ input: input }).pipe(map((result) => result.data.updateCollectionFilterPreset));
  }

  makePublic(
    input: CollectionFilterPresetChangeStateInput
  ): Observable<MakeCollectionFilterPresetPublicMutation["makeCollectionFilterPresetPublic"]> {
    return this.makePublicGql
      .mutate({ input: input })
      .pipe(map((result) => result.data.makeCollectionFilterPresetPublic));
  }

  makePrivate(
    input: CollectionFilterPresetChangeStateInput
  ): Observable<MakeCollectionFilterPresetPrivateMutation["makeCollectionFilterPresetPrivate"]> {
    return this.makePrivateGql
      .mutate({ input: input })
      .pipe(map((result) => result.data.makeCollectionFilterPresetPrivate));
  }

  delete(
    input: CollectionFilterPresetDeleteInput
  ): Observable<DeleteCollectionFilterPresetMutation["deleteCollectionFilterPreset"]> {
    return this.deleteGql.mutate({ input: input }).pipe(map((result) => result.data.deleteCollectionFilterPreset));
  }
}
