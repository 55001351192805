<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <app-candidate-aligned-label
    labelFor="pathOfRecognition"
    [labelText]="t('pathOfRecognition.title')"
    [useAlignmentCheckBox]="renderLabelUsingAlignmentCheckBox"
  >
  </app-candidate-aligned-label>

  <p-dropdown
    inputId="pathOfRecognition"
    [options]="pathsOfRecognition"
    [formControl]="control"
    [showClear]="true"
    [readonly]="readonly"
    [placeholder]="t('pathOfRecognition.placeholder')"
  ></p-dropdown>

  <small class="p-error" *ngIf="control.errors?.required">
    {{ t("pathOfRecognition.required") }}
  </small>
</ng-container>
