<ng-container *appTranslate="let t">
  <p-fieldset [legend]="t('organizationProfile.location.vacancies.title')">
    <ng-container *ngFor="let form of forms; index as i">
      <div class="flex flex-row">
        <app-organization-profile-location-vacancy
          class="flex-1 mr-2"
          [style.min-width]="0"
          [form]="form"
          [fields]="fields"
          [formIndex]="i"
          [organizationId]="organizationId"
        >
        </app-organization-profile-location-vacancy>
        <p-button class="p-element align-self-center ml-2" icon="pi pi-times" (click)="removeVacancy(i)"></p-button>
      </div>
      <hr />
    </ng-container>

    <div class="flex">
      <p-button
        icon="pi pi-plus"
        [label]="t('organizationProfile.location.vacancies.add')"
        (click)="addVacancy()"
      ></p-button>
    </div>
  </p-fieldset>
</ng-container>
