import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { CandidateDataSourceOption } from "@ankaadia/ankaadia-shared";
import { isEmpty } from "lodash";
import { FilterForm } from "../candidate-filter-form.model";
import { TranslocoService } from "@jsverse/transloco";

@Component({
  selector: "app-candidate-filter-data-source",
  templateUrl: "./candidate-filter-data-source.component.html",
  standalone: false,
})
export class CandidateFilterDataSourceComponent implements OnChanges {
  @Input({ required: true }) dataSource: CandidateDataSourceOption[];
  @Input({ required: true }) form: FilterForm;

  options: CandidateDataSourceOption[] = this.getDefaultOptions();

  constructor(private readonly transloco: TranslocoService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource) this.setDataSourcesOptionsDefaults();
  }

  private setDataSourcesOptionsDefaults(): void {
    if (isEmpty(this.dataSource)) this.setDefaultValues();
    else this.setDataSourceOptions();
  }

  private setDefaultValues(): void {
    this.options = this.getDefaultOptions();
    this.form.controls.dataSource.setValue("owner");
  }

  private setDataSourceOptions(): void {
    this.options = [...this.dataSource.filter((option) => option.value !== "owner"), ...this.getDefaultOptions()];
    if (!this.form.controls.dataSource.value) {
      this.form.controls.dataSource.setValue("owner");
    }
  }

  private getDefaultOptions(): CandidateDataSourceOption[] {
    return [{ value: "owner", label: this.transloco.translate("candidate.dataSource.owner") }];
  }
}
