import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { CandidateRole, getAllResidenceRoles } from "@ankaadia/ankaadia-shared";
import {
  ResidenceCandidateDataDe,
  ResidencePermit,
  StaticDataModel,
  StaticDataType,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { assign } from "lodash";
import { Observable } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { StaticDataContextEntry, StaticDataService } from "../../../../../shared/static-data/static-data.service";
import { ResponsibleRolePurpose } from "../../../../candidate-reusables/candidate-responsible-representative-group/candidate-responsible-representative-group.service";
import { CandidateWorkStartDateComponent } from "../../../../candidate-reusables/candidate-work-start-date/candidate-work-start-date.component";
import { ImmigrationAuthorityService } from "../../../../immigration-authorities/immigration-authority.service";
import { ResidenceFormDe } from "../../../candidate-form.model";
import { CandidateFormService } from "../../../candidate-form.service";

@Component({
  selector: "app-candidate-residence-de",
  templateUrl: "./candidate-residence-de.component.html",
  standalone: false,
})
export class CandidateResidenceDEComponent implements OnInit, OnChanges {
  readonly ResponsibleRolePurposeType = ResponsibleRolePurpose;
  readonly today = new Date();
  protected readonly responsibleRoles = getAllResidenceRoles();
  protected readonly certificateOfConducts$ = this.staticDataService.getStaticData(StaticDataType.CertificateOfConduct);
  protected readonly suitabilityForWork$ = this.staticDataService.getStaticData(StaticDataType.SuitabilityForWork);

  immigrationAuthorities$: Observable<StaticDataModel[]>;

  @Input({ required: true })
  form: ResidenceFormDe;

  @Input({ required: true })
  residence: ResidenceCandidateDataDe;

  @Input({ required: true })
  candidateId: string;

  @Input({ required: true })
  candidateOrganizationId: string;

  @Input({ required: true })
  readonly: boolean;

  @ViewChild(CandidateWorkStartDateComponent)
  protected workStartDate: CandidateWorkStartDateComponent;

  protected staticDataContext: StaticDataContextEntry;

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly immigrationAuthorityService: ImmigrationAuthorityService,
    private readonly candidateFormService: CandidateFormService
  ) {}

  ngOnInit(): void {
    this.immigrationAuthorities$ = this.immigrationAuthorityService.getImmigrationAuthorities(
      this.candidateOrganizationId,
      SupportedImmigrationCountry.De
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateOrganizationId) {
      this.staticDataContext = {
        immigrationCountry: SupportedImmigrationCountry.De,
        organizationId: this.candidateOrganizationId,
      };
    }
  }

  protected addResidencePermit(): void {
    this.form.controls.residencePermits.push(
      this.candidateFormService.createResidencePermitGroup(assign(new ResidencePermit(), { id: uuidv4() }))
    );
  }

  protected removeResidencePermit(index: number): void {
    this.form.controls.residencePermits.removeAt(index);
  }

  protected readonly ResponsiblePurpose = ResponsibleRolePurpose;

  protected get canHaveADeputyOrRepresentative(): boolean {
    const selectedRole = this.form.controls?.responsibleRoleResidence?.value;
    return selectedRole && selectedRole !== CandidateRole;
  }
}
