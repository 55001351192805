import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import {
  defaultImmigrationCountry,
  ensure2LetterIsoLanguage,
  isRealImmigrationCountry,
  sortImmigrationCountries,
  supportedUserInterfaceLanguages4LetterISO,
} from "@ankaadia/ankaadia-shared";
import { CandidateSheetModeEnum, StaticDataModel, StaticDataType, XlsxCandidateImportInput } from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { FileUpload } from "primeng/fileupload";
import { Observable, map } from "rxjs";
import { OrganizationFactoryService } from "../../../organization-specific/organization-specific-factory.service";
import { MessageDialogService } from "../../../shared/message-dialog/message-dialog.service";
import { DownloadService } from "../../../shared/services/download.service";
import { FileUploadService } from "../../../shared/services/file-upload.service";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { XlsxImportExportService } from "../xlsx-import-export.service";

@Component({
  selector: "app-candidate-import",
  templateUrl: "./candidate-import.component.html",
  providers: [FileUploadService],
  standalone: false,
})
export class CandidateImportComponent implements OnInit {
  private readonly language = this.transloco.getActiveLang();

  readonly CandidateSheetModeEnum = CandidateSheetModeEnum;
  readonly defaultImmigrationCountry = defaultImmigrationCountry();
  readonly immigrationCountries$ = this.getImmigrationCountries();

  @Input()
  import: XlsxCandidateImportInput;

  @Output()
  readonly saved = new EventEmitter<any>();

  @Output()
  readonly closed = new EventEmitter<void>();

  @ViewChild("upload")
  upload: FileUpload;

  isUploading: boolean;
  fileSelected: boolean;
  legacyTemplateDownloadUrl: string;
  addTestData: boolean;

  languageOptions$ = this.staticDataService
    .getStaticData(StaticDataType.Languages, this.language)
    .pipe(
      map((langs) =>
        langs.filter((lang) =>
          ensure2LetterIsoLanguage(supportedUserInterfaceLanguages4LetterISO()).includes(lang.value)
        )
      )
    );

  selectedLanguage: string = ensure2LetterIsoLanguage(this.language);

  get isMasterUser(): boolean {
    return this.settings.isMasterUser;
  }

  constructor(
    private readonly fileUploadService: FileUploadService,
    private readonly downloadService: DownloadService,
    private readonly errorService: MessageDialogService,
    private readonly orgFactory: OrganizationFactoryService,
    private readonly xlsxImportService: XlsxImportExportService,
    private readonly transloco: TranslocoService,
    private readonly staticDataService: StaticDataService,
    private readonly settings: SettingsService
  ) {}

  ngOnInit(): void {
    this.fileUploadService.onProgress.subscribe((x) => (this.upload.progress = x));
    this.fileUploadService.onUpload.subscribe(() => this.onUpload());
    this.fileUploadService.onError.subscribe((x) => this.onError(x));
    this.orgFactory.getOrganizationSpecifics(this.import.organizationId).subscribe((x) => {
      this.legacyTemplateDownloadUrl = x.getCandidateImporterTemplateUrl();
    });
  }

  generateAndDownloadTemplate(mode: CandidateSheetModeEnum, immigrationCountry: string): void {
    this.xlsxImportService
      .getCandidateImportTemplate(
        this.import.organizationId,
        this.import.collectionId,
        this.import.organizationId,
        this.selectedLanguage ?? this.language,
        mode,
        immigrationCountry,
        this.addTestData ?? false
      )
      .subscribe(({ downloadUrl, fileName }) => {
        this.downloadService.downloadFile(fileName, downloadUrl);
      });
  }

  selectFile(file: File): void {
    this.fileSelected = !!file;
  }

  uploadFile(file: File): void {
    this.isUploading = true;
    this.import.fileName = file.name;
    this.xlsxImportService
      .getImportUploadUrl({
        blob: this.import.blob,
        organizationId: this.import.organizationId,
      })
      .subscribe((url) => this.fileUploadService.upload(file, url));
  }

  submit(): void {
    this.upload.upload();
  }

  private getImmigrationCountries(): Observable<StaticDataModel[]> {
    return this.staticDataService.getStaticData(StaticDataType.SupportedImmigrationCountries, this.language).pipe(
      map((xs) =>
        sortImmigrationCountries(
          xs
            .filter((x) => this.settings.supportedImmigrationCountries.includes(x.value))
            .filter((x) => isRealImmigrationCountry(x.value)),
          this.language
        )
      )
    );
  }

  private onUpload(): void {
    this.saved.emit(this.import);
    this.isUploading = false;
    this.fileSelected = false;
    this.upload.clear();
  }

  private onError(response: any): void {
    this.isUploading = false;
    this.errorService.showMessage(translate("file.uploadFailed"), response.message);
  }
}
