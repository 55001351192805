import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import {
  Candidate,
  CandidateAssignmentException,
  CandidateAssignmentExceptionType,
  Collection,
  SharingTypeEnum,
  StaticDataType,
} from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { clone, cloneDeep } from "lodash";
import { FilterService, PrimeIcons, SortEvent } from "primeng/api";
import { Sidebar } from "primeng/sidebar";
import { catchError, of, switchMap } from "rxjs";
import { Dictionary } from "ts-essentials";
import { EnumPipe } from "../../../shared/pipes/enum.pipe";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataPipe } from "../../../shared/static-data/static-data.pipe";
import { PipeDescription, TableCache, TableColumn } from "../../../shared/table/table.model";
import { TableService } from "../../../shared/table/table.service";
import { CandidatesService } from "../../candidates/candidates.service";
import { MessageService } from "../../message/message.service";
import {
  CustomLazyLoadEvent,
  IsSingleFilterMetaData,
} from "../collection-edit-assigned-candidates/custom-filter.model";
import { CandidateForAssignment, CollectionService } from "../collection.service";
import { CandidateFlexibleEditService } from "../../candidates/candidate-flexible-edit/candidate-flexible-edit.service";

@Component({
  selector: "app-collection-assigned-exceptions",
  templateUrl: "./collection-assigned-exceptions.component.html",
  standalone: false,
})
export class CollectionAssignedExceptionsComponent implements OnChanges, OnInit {
  protected readonly StaticDataType = StaticDataType;
  readonly candidatesPerPage = 200;
  protected searchInput: string;
  protected language: string = this.transloco.getActiveLang();
  private allCandidatesRestore: CandidateForAssignment[];

  protected showSidebar: boolean;

  @Input() collection: Collection;
  @Output() readonly reload = new EventEmitter<void>();
  protected assignmentExceptions: CandidateAssignmentException[];
  protected assignmentExceptionsForEdit: CandidateForAssignment[];
  protected allCandidates: CandidateForAssignment[];
  protected allCandidatesCount: number;
  protected globalFilterFields: string[];

  @ViewChild("sidebar")
  sidebar: Sidebar;

  private cache: TableCache;
  readonly filterName = `custom-${Date.now()}`;
  readonly captionOp = { id: "caption" };
  readonly assignmentExceptionOptions = Object.values(CandidateAssignmentExceptionType).map((x) => ({
    label: translate(`enum.CandidateAssignmentExceptionType.${x}`),
    value: x,
  }));

  protected columns: TableColumn[] = [
    {
      header: translate("number.title"),
      fieldname: "displayId",
      routeLink: (x: Candidate): any[] => this.getRouteLink(x),
      sortable: true,
      includeInGlobalFilter: true,
      includeFlag: true,
      canIconClick: () => true,
      icon: () => PrimeIcons.USER_EDIT,
      iconClick: (candidate: Candidate) =>
        this.candidateFlexibleEditService.openCandidate(candidate.id, candidate.organizationId),
      iconClass: "ml-2",
    },
    { header: translate("name.title"), fieldname: "displayName", sortable: true, includeInGlobalFilter: true },
    {
      header: translate("candidates.assignmentExceptionOperation"),
      fieldname: "assignmentExceptionType",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(EnumPipe, "CandidateAssignmentExceptionType"),
    },
    {
      header: translate("candidates.implicitOperation"),
      fieldname: "implicitType",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(EnumPipe, "CandidateAssignmentExceptionType"),
    },
    {
      header: translate("country.title"),
      fieldname: "country",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(StaticDataPipe, StaticDataType.Countries),
    },
  ];

  protected showDialog = false;

  clonedRowData: Dictionary<CandidateAssignmentException> = {};

  constructor(
    private readonly settings: SettingsService,
    private readonly collService: CollectionService,
    private readonly candidateService: CandidatesService,
    private readonly messageService: MessageService,
    private readonly transloco: TranslocoService,
    private readonly filterService: FilterService,
    private readonly tableService: TableService,
    private readonly candidateFlexibleEditService: CandidateFlexibleEditService
  ) {}

  ngOnInit(): void {
    this.globalFilterFields = this.columns?.filter((col) => col.includeInGlobalFilter).map((col) => col.fieldname);
    this.filterService.register(this.filterName, (value, filter, locale) =>
      this.tableService.filter(value, filter, locale, this.cache)
    );
  }

  ngOnChanges(): void {
    if (this.collection != null) {
      this.assignmentExceptions = clone(this.collection.assignmentExceptions);
      this.cache = this.tableService.buildPipeCache(this.columns, this.assignmentExceptions, this.language);
    } else {
      this.assignmentExceptions = null;
      this.cache = this.tableService.buildPipeCache(this.columns, this.assignmentExceptions, this.language);
    }
  }

  sort(event: SortEvent): void {
    this.tableService.sort(event, this.cache);
  }

  editAssignedCandidates(): void {
    this.collService
      .getCollection(this.collection.organizationId, this.collection.id)
      .subscribe((x) => (this.collection = x)); // mare sure that eTag is updated

    this.collService
      .getAllAssignableCandidates(
        this.collection.id,
        this.collection.organizationId,
        {
          first: 0,
          rows: this.candidatesPerPage,
        },
        true
      )
      .subscribe((x) => {
        this.allCandidates = cloneDeep(x.nodes) as CandidateForAssignment[];
        this.allCandidatesRestore = clone(this.allCandidates);
        this.allCandidatesCount = x.totalCount;
        this.assignmentExceptionsForEdit = clone(this.collection.assignmentExceptions).map((a) =>
          this.convertToAssignmentData(a)
        );
        this.showDialog = true;
      });
  }

  onRowEditInit(rowData: CandidateAssignmentException): void {
    this.clonedRowData[rowData.id] = clone(rowData);
  }

  onRowEditSave(rowData: CandidateAssignmentException): void {
    delete this.clonedRowData[rowData.id];
    const assignmentExceptions = clone(this.assignmentExceptions);
    const idx = assignmentExceptions.findIndex((x) => x.id === rowData.id);
    const row = assignmentExceptions[idx];
    const newRow = { ...row, assignmentExceptionType: rowData.assignmentExceptionType };
    assignmentExceptions.splice(idx, 1, newRow);
    this.assignmentExceptions = clone(assignmentExceptions);
    this.saveAssignmentExceptions(assignmentExceptions);
  }

  onRowEditCancel(rowData: CandidateAssignmentException, index: number): void {
    this.assignmentExceptions[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
  }

  displayCandidateLinks(): boolean {
    if (this.settings.organizationId === this.collection.organizationId) {
      return true;
    }
    return this.collection.sharings.some(
      (s) =>
        [SharingTypeEnum.Collaboration, SharingTypeEnum.ReadOnlyCollaboration].includes(s.sharingType) &&
        s.destinationOrganizationId === this.settings.organizationId
    );
  }

  getRouteLink(candidate: Candidate): any[] | null {
    return this.displayCandidateLinks()
      ? this.candidateService.getCandidateEditLink(candidate.id, candidate.organizationId)
      : null;
  }

  updateSource(event: CustomLazyLoadEvent): void {
    this.collService
      .getAllAssignableCandidates(this.collection.id, this.collection.organizationId, event, true)
      .subscribe((x) => {
        const collection = event.filters?.custom?.collection;
        const { collectionId = null, organizationId = null } = IsSingleFilterMetaData(collection)
          ? (collection?.value ?? {})
          : {};

        this.allCandidates = clone(x.nodes).map((c: CandidateForAssignment) => ({
          ...c,
          originalColId: this.settings.organizationId !== organizationId ? collectionId : null,
          originalOrgId: this.settings.organizationId !== organizationId ? organizationId : null,
        }));
        this.allCandidatesRestore = clone(this.allCandidates);
        this.allCandidatesCount = x.totalCount;
      });
  }

  closeSidebar(event: {
    success: boolean;
    collection?: Collection;
    targetCandidateList?: CandidateForAssignment[];
  }): void {
    this.showDialog = false;
    if (!event.success) {
      this.assignmentExceptionsForEdit = clone(this.assignmentExceptions).map((a) => this.convertToAssignmentData(a));
      this.allCandidates = clone(this.allCandidatesRestore);
      this.reload.emit();
    } else {
      const assignmentExceptions = event.targetCandidateList.map((c) => this.convertToAssignmentException(c));
      this.saveAssignmentExceptions(assignmentExceptions);
    }
  }

  private saveAssignmentExceptions(assignmentExceptions: CandidateAssignmentException[]): void {
    this.collService
      .updateCandidateAssignmentExceptions(this.collection, assignmentExceptions)
      .pipe(
        switchMap((_) => {
          this.messageService.add({ severity: "success", summary: translate("candidates.assigned2") });
          this.assignmentExceptions = clone(this.assignmentExceptionsForEdit).map((a) =>
            this.convertToAssignmentException(a)
          );
          this.allCandidatesRestore = clone(this.allCandidates);
          return of(null);
        }),
        catchError((_) => {
          this.assignmentExceptionsForEdit = clone(this.assignmentExceptions).map((a) =>
            this.convertToAssignmentData(a)
          );
          this.allCandidates = clone(this.allCandidatesRestore);
          return of(null);
        })
      )
      .subscribe(() => {
        this.reload.emit();
      });
  }

  private convertToAssignmentData(assignmentException: CandidateAssignmentException): CandidateForAssignment {
    const original = this.collection.assignedCandidateIds?.find(
      (i) => i.id === assignmentException.id && i.orgId === assignmentException.organizationId
    );
    return {
      ...assignmentException,
      __typename: "Candidate",
      originalColId: original?.originalColId,
      originalOrgId: original?.originalOrgId,
    };
  }

  private convertToAssignmentException(candidate: CandidateForAssignment): CandidateAssignmentException {
    const original = this.collection.assignmentExceptions.find(
      (i) => i.id === candidate.id && i.organizationId === candidate.organizationId
    );
    const isAlreadyContained = this.collection.assignedCandidateIds.some(
      (x) => x.id === candidate.id && x.orgId === candidate.organizationId
    );
    return {
      ...candidate,
      __typename: "CandidateAssignmentException",
      assignmentExceptionType:
        original?.assignmentExceptionType ??
        (isAlreadyContained ? CandidateAssignmentExceptionType.Exclude : CandidateAssignmentExceptionType.Include),
      implicitType:
        original?.implicitType ??
        (isAlreadyContained ? CandidateAssignmentExceptionType.Include : CandidateAssignmentExceptionType.Exclude),
    };
  }
}
