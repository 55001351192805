import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import {
  CascadedOrganizationFragment,
  CommentData,
  FeedbackData,
  FeedbackIterationData,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { clone, cloneDeep, sortBy } from "lodash";
import { SortEvent } from "primeng/api";
import { Table } from "primeng/table";
import { v4 as uuidv4 } from "uuid";
import { SettingsService } from "../../../../shared/services/settings.service";
import { OrganizationsService } from "../../../organizations/organizations.service";
import { CandidateInterviewFeedbacksService } from "../candidate-interviews-feedback.service";
import {
  InterviewIterationDetailsCommentForm,
  InterviewIterationDetailsFeedbackForm,
  InterviewIterationDetailsForm,
} from "./interview-iteration-details-form.model";
import { InterviewIterationDetailsFormService } from "./interview-iteration-details-form.service";

enum ItemType {
  Feedback = "feedbacks",
  Comment = "comments",
  Employer = "employer",
}

@Component({
  selector: "app-interview-iteration-details",
  templateUrl: "./interview-iteration-details.component.html",
  standalone: false,
})
export class InterviewIterationDetailsComponent implements OnInit, OnChanges {
  protected employers: CascadedOrganizationFragment[];
  readonly language = this.transloco.getActiveLang();
  readonly formId = uuidv4();

  @Input()
  selectedIteration: FeedbackIterationData;

  @Input()
  show: boolean;

  @Input()
  interviewId: string;

  @Input()
  candidateId: string;

  @Input()
  candidateOrganizationId: string;

  @Input()
  immigrationCountry: SupportedImmigrationCountry;

  @Input()
  readonly: boolean;

  @Output()
  readonly update = new EventEmitter<{ id: string; values: FeedbackIterationData }>();

  @Output()
  readonly refresh = new EventEmitter<{ id: string }>();

  @ViewChild("feedbackstable")
  feedbackTable: Table;

  @ViewChild("commentstable")
  commentsTable: Table;

  get feedbacks(): FormArray<InterviewIterationDetailsFeedbackForm> {
    return this.form?.controls?.feedbacks;
  }

  get comments(): FormArray<InterviewIterationDetailsCommentForm> {
    return this.form?.controls?.comments;
  }

  get hasEmployer(): boolean {
    return !!this.form?.controls?.employerId?.value;
  }

  get tables(): object {
    return { feedbacks: this.feedbackTable, comments: this.commentsTable };
  }

  form: InterviewIterationDetailsForm;
  isEditing = false;
  ItemTypeEnum = ItemType;
  editedItem: any;

  constructor(
    private readonly formService: InterviewIterationDetailsFormService,
    private readonly settings: SettingsService,
    private readonly transloco: TranslocoService,
    private readonly organizationService: OrganizationsService,
    private readonly service: CandidateInterviewFeedbacksService
  ) {}

  ngOnInit(): void {
    this.loadEmployers();
    this.form = this.formService.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedIteration) {
      this.selectedIteration = changes.selectedIteration.currentValue;
      this.setForm(changes.selectedIteration.currentValue);
      this.resetTables();
    }
  }

  setForm(feedbackIteration: FeedbackIterationData): void {
    this.form?.reset(undefined, { emitEvent: false });
    this.form?.patchValue(feedbackIteration, { emitEvent: false });
    this.form?.controls?.id.setValue(feedbackIteration?.id ?? uuidv4());
    this.form?.controls?.employerId.setValue(feedbackIteration?.employer?.id ?? null);

    this.feedbacks?.clear({ emitEvent: false });
    for (const feedback of sortBy(feedbackIteration?.feedbacks ?? [], "interviewDate")) {
      this.feedbacks?.push(this.formService.createFeedbackForm(feedback), { emitEvent: false });
    }
    this.comments?.clear({ emitEvent: false });
    for (const comment of feedbackIteration?.comments ?? []) {
      this.comments?.push(this.formService.createCommentForm(comment), { emitEvent: false });
    }
  }

  addItem(type: ItemType): void {
    let formGroup: InterviewIterationDetailsFeedbackForm | InterviewIterationDetailsCommentForm;
    if (type === ItemType.Feedback) {
      const feedback = new FeedbackData();
      feedback.interviewDate = new Date();
      formGroup = this.formService.createFeedbackForm(feedback);
    } else {
      const comment = new CommentData();
      formGroup = this.formService.createCommentForm(comment);
    }

    this[type]?.push(formGroup, { emitEvent: false });
    this.tables[type]?.initRowEdit(formGroup);
    this.editedItem = cloneDeep(formGroup);
    this.isEditing = true;
  }

  onRowEditInit(row: FormGroup): void {
    this.feedbackTable?.cancelRowEdit(this.editedItem);
    this.commentsTable?.cancelRowEdit(this.editedItem);

    this.isEditing = true;
    this.editedItem = cloneDeep(row);
  }

  onRowEditSave(row: FormGroup, i: number, type: ItemType): void {
    this.isEditing = false;

    const updatedValue = row.value;
    if (type === ItemType.Comment) {
      updatedValue.changedAt = new Date();
      updatedValue.changedBy = this.settings.userDisplayName;
    }
    updatedValue.id = updatedValue.id ?? uuidv4();
    this[type]?.at(i).patchValue(updatedValue, { emitEvent: false });
    this.saveItem(updatedValue, type);
  }

  onRowEditCancel(i: number, type: ItemType): void {
    this.isEditing = false;
    if (this.editedItem?.value?.id) {
      this[type]?.at(i).patchValue(this.editedItem?.value, { emitEvent: false });
    } else {
      this.removeAt(this.editedItem, type);
    }
  }

  removeAt(row: { controls: Record<string, FormGroup> }, type: ItemType): void {
    this[type]?.removeAt(
      this[type].controls?.findIndex((x: FormGroup) => x.controls.id.value == row.controls.id.value)
    );
  }

  removeRow(row: { controls: Record<string, FormGroup> }, type: ItemType): void {
    this.removeAt(row, type);
    this.service
      .deleteItem(
        this.interviewId,
        this.candidateOrganizationId,
        this.selectedIteration.id,
        row.controls.id.value,
        type
      )
      .subscribe(() => this.resetTables(true));
  }

  saveItem(event: any, type: ItemType): void {
    if (type === ItemType.Employer) {
      const { __typename, ...employer } = this.employers.find((e) => e.id == event.value);
      this.service
        .upsertEmployer(
          this.interviewId,
          this.candidateId,
          this.candidateOrganizationId,
          this.selectedIteration.id,
          employer
        )
        .subscribe(() => this.resetTables(true));
    } else if (type === ItemType.Feedback || type === ItemType.Comment) {
      this.service
        .upsertIteration(
          this.interviewId,
          this.candidateId,
          this.candidateOrganizationId,
          this.selectedIteration.id,
          event,
          type
        )
        .subscribe(() => this.resetTables(true));
    }
  }

  resetTables(refresh = false): void {
    this.isEditing = false;
    this.feedbackTable?.cancelRowEdit(this.editedItem);
    this.commentsTable?.cancelRowEdit(this.editedItem);
    this.editedItem = null;
    if (refresh && this.selectedIteration) {
      this.refresh.emit({ id: this.selectedIteration.id });
    }
  }

  customSort(event: SortEvent): any {
    event.data.sort((data1, data2) => {
      const value1 = data1.value[event.field];
      const value2 = data2.value[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === "string" && typeof value2 === "string") result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  private loadEmployers(): void {
    this.organizationService
      .getCascadedLinkedOrganizationsFromSourceOrganization(
        this.candidateOrganizationId,
        this.settings.organizationId,
        this.immigrationCountry
      )
      .subscribe((xs) => {
        const employers = clone(xs);
        const employer = this.selectedIteration?.employer;
        if (employer && employers.every((x) => x.id !== employer.id)) {
          employers.push({
            id: employer.id,
            name: employer.name,
            region: employer.region,
            zipcode: employer.zipcode,
            country: employer.country,
          });
        }
        this.employers = employers;
      });
  }
}
