<ng-container *appTranslate="let t">
  @if (typeof capFloor().cap === 'number') {
    <span class="mr-2 align-self-baseline">{{ t("billing.cap.title") }}</span>
    <app-price class="align-self-baseline" [price]="capFloor().cap" [currency]="currency()" unit="year"></app-price>
  }

  @if (typeof capFloor().floor === 'number') {
    <span class="mr-2 align-self-baseline">{{ t("billing.floor.title") }}</span>
    <app-price class="align-self-baseline" [price]="capFloor().floor" [currency]="currency()" unit="year"></app-price>
  }
</ng-container>
