import { map, Observable } from "rxjs";
import { CandidateProfileField, CandidateProfileFieldGroup } from "./candidate-profile-field-configuration.model";
import { CandidateProfileFieldConfigurationService } from "./candidate-profile-field-configuration.service";

export abstract class CandidateProfileFieldPipe {
  protected constructor(private readonly fieldConfigurationService: CandidateProfileFieldConfigurationService) {}

  protected getPropertyDefinition(path: string): Observable<{
    group: CandidateProfileFieldGroup;
    field: CandidateProfileField;
  }> {
    return this.fieldConfigurationService.getFields().pipe(map((fields) => this.findDefinition(fields, path)));
  }

  private findDefinition(
    fields: CandidateProfileFieldGroup[],
    path: string
  ): {
    group: CandidateProfileFieldGroup;
    field: CandidateProfileField;
  } | null {
    for (const group of fields) {
      for (const field of group.fields) {
        if (field.path === path) {
          return { group, field };
        }
      }
    }

    return { group: null, field: null };
  }
}
