import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  EducationExamResultFullDataFragment,
  EducationExamResultUpdateResultFragment,
  EducationExamResultUpsertInput,
  EducationModuleForSelectionFragment,
  ExamModuleType,
  OrganizationForSelectionFragment,
  StaticDataModel,
  StaticDataType,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { uniq } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { combineLatest, forkJoin, map, Observable, tap } from "rxjs";
import { DeepPartial } from "ts-essentials";
import { v4 as uuidv4 } from "uuid";
import { HasChanges } from "../../../../shared/guards/confirm-deactivate.guard";
import { valuesOf } from "../../../../shared/services/form.helper";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";
import { MessageService } from "../../../message/message.service";
import { ExamFragment } from "../../education-exams/education-exam-table/education-exam-table.component";
import { EducationExamResultsForm } from "../education-exam-results-form.model";
import { EducationExamResultFormService } from "../education-exam-results-form.service";
import { EducationExamResultService } from "../education-exam-results.service";
import { EducationExamResultTableColumn, EducationExamResultTableRowData } from "./education-exam-results-table.model";

@Component({
  selector: "app-education-exam-results-table",
  templateUrl: "./education-exam-results-table.component.html",
  styleUrl: "./education-exam-results-table.component.scss",
  standalone: false,
})
export class EducationExamResultsTableComponent implements OnChanges, HasChanges {
  readonly StaticDataType = StaticDataType;
  readonly ExamModuleType = ExamModuleType;
  readonly languageModules$ = this.staticDataService.getStaticData(StaticDataType.LanguageModules);
  readonly examResultStates$ = this.staticDataService.getStaticData(StaticDataType.ExamResultState);
  readonly allExamModules = this.staticDataService.transformEnumToStaticDataModel("ExamModuleType", ExamModuleType);

  @Input({ required: true })
  exams: ExamFragment[];

  @Input()
  modules: EducationModuleForSelectionFragment[];

  @Input()
  educationProviders: OrganizationForSelectionFragment[];

  @Input({ required: true })
  examResults: EducationExamResultFullDataFragment[];

  @Input()
  isEditable: boolean;

  @Input()
  title: string;

  @Input()
  shownColumns: EducationExamResultTableColumn[];

  @Input()
  onlyClearOnDeleteIfParticipant = false;

  @ContentChild("caption", { read: TemplateRef })
  captionTemplate: TemplateRef<unknown>;

  @Output()
  readonly examResultDeleted = new EventEmitter<EducationExamResultFullDataFragment>();

  examResultData: EducationExamResultTableRowData[] = [];
  displayModules: ExamModuleType[] = [];

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly examResultService: EducationExamResultService,
    private readonly formService: EducationExamResultFormService,
    private readonly messageService: MessageService,
    private readonly confirmationService: ConfirmationService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  hasChanges(): boolean {
    return this.examResultData.some((x) => x.form.dirty);
  }

  getDetailsControl(rowData: EducationExamResultTableRowData, moduleType: ExamModuleType): FormControl<string> {
    return rowData.form.controls.examModuleInformation.controls.find((x) => x.controls.examModule.value === moduleType)
      ?.controls.resultDetails;
  }

  updatePassedExamModules(rowData: EducationExamResultTableRowData, examResult: string): void {
    if (examResult === "PASSED") {
      rowData.form.controls.passedExamModules.setValue(rowData.exam?.examModules ?? []);
    } else {
      rowData.form.controls.passedExamModules.setValue([]);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.exams) {
      this.updateDisplayModules(changes.exams.currentValue);
      this.updateExamResultData(changes.exams.currentValue, this.modules, this.educationProviders, this.examResults);
    }

    if (changes.modules) {
      this.updateExamResultData(this.exams, changes.modules.currentValue, this.educationProviders, this.examResults);
    }

    if (changes.educationProviders) {
      this.updateExamResultData(this.exams, this.modules, changes.educationProviders.currentValue, this.examResults);
    }

    if (changes.examResults) {
      this.updateExamResultData(this.exams, this.modules, this.educationProviders, changes.examResults.currentValue);
    }
  }

  saveExamResult(rowData: EducationExamResultTableRowData): void {
    this.saveExamResult$(rowData).subscribe(() => this.changeDetectorRef.detectChanges());
  }

  saveExamResult$(rowData: EducationExamResultTableRowData): Observable<EducationExamResultUpdateResultFragment> {
    return this.examResultService.upsert(<EducationExamResultUpsertInput>rowData.form.value).pipe(
      tap((examResult: EducationExamResultUpdateResultFragment) => {
        this.messageService.add({
          severity: "success",
          summary: translate("educationExamResult.updated.title"),
          detail: translate("educationExamResult.updated.message", examResult),
        });
        rowData.form.controls._etag.setValue(examResult._etag);
        rowData.form.markAsUntouched();
        rowData.form.markAsPristine();
      })
    );
  }

  cancelExamResult(rowData: EducationExamResultTableRowData): void {
    const originalData = this.examResults.find((x) => x.id === rowData.form.value.id);
    if (!originalData) {
      this.removeRow(rowData);
    } else {
      this.cancelExamResultEditing(rowData.form, originalData);
    }
  }

  deleteExamResult(event: MouseEvent, rowData: EducationExamResultTableRowData): void {
    const examResult = <EducationExamResultUpsertInput>rowData.form.value;
    this.confirmationService.confirm({
      target: event.target,
      message: translate("educationExamResult.confirmDelete.title"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.examResultService.delete(examResult).subscribe(() => {
          this.messageService.add({
            detail: translate("educationExamResult.deleted.message"),
            severity: "success",
            summary: translate("educationExamResult.deleted.title"),
          });
          this.removeRow(rowData);
        });
      },
    });
  }

  saveAll(): Observable<EducationExamResultUpdateResultFragment[]> {
    return combineLatest(
      this.examResultData.filter((x) => x.form.dirty && x.form.valid).map((x) => this.saveExamResult$(x))
    ).pipe(tap(() => this.changeDetectorRef.detectChanges()));
  }

  private removeRow(rowData: EducationExamResultTableRowData): void {
    if (this.onlyClearOnDeleteIfParticipant && rowData.data.participantId) {
      const data = this.examResultData.find((x) => x.form.value.id === rowData.form.value.id);
      const rowControls = data.form.controls;
      rowControls.examResult.reset();
      rowControls.passedExamModules.reset();
      rowControls._etag.reset();
      rowControls.id.setValue(uuidv4());
      for (const control of rowControls.examModuleInformation.controls) {
        control.controls.resultDetails.reset();
      }
    } else {
      this.examResultData = this.examResultData.filter((x) => x.form.value.id !== rowData.form.value.id);
    }
    this.examResultDeleted.emit(rowData.data);
  }

  private cancelExamResultEditing(
    form: EducationExamResultsForm,
    originalData: DeepPartial<EducationExamResultUpsertInput>
  ): void {
    const examModuleInfoControlsTypes = form.controls.examModuleInformation.value.map((x) => x.examModule);
    const examModuleInfos =
      originalData?.examModuleInformation?.filter((x) => examModuleInfoControlsTypes.includes(x.examModule)) ?? [];
    const _etag = form.controls._etag.value;
    form.patchValue({ ...originalData, examModuleInformation: examModuleInfos }, { emitEvent: false });
    form.controls._etag.setValue(_etag);
    form.markAsPristine();
    this.changeDetectorRef.detectChanges();
  }

  private updateDisplayModules(exams: ExamFragment[]): void {
    this.displayModules = uniq((exams ?? []).flatMap((x) => x.examModules ?? []));
  }

  private updateExamResultData(
    exams: ExamFragment[],
    modules: EducationModuleForSelectionFragment[],
    educationProviders: OrganizationForSelectionFragment[],
    examResults: EducationExamResultFullDataFragment[]
  ): void {
    forkJoin([this.languageModules$, this.examResultStates$]).subscribe(([languageModules, examResultStates]) => {
      this.examResultData =
        examResults?.map((x) =>
          this.toRowData(exams, modules, educationProviders, x, languageModules, examResultStates)
        ) ?? [];
      this.changeDetectorRef.detectChanges(); // Course Exam Overview not correctly updated...
    });
  }

  private toRowData(
    exams: ExamFragment[],
    modules: EducationModuleForSelectionFragment[],
    educationProviders: OrganizationForSelectionFragment[],
    examResult: EducationExamResultFullDataFragment,
    languageModules: StaticDataModel[],
    examResultStates: StaticDataModel[]
  ): EducationExamResultTableRowData {
    const exam = exams?.find((exam) => exam.id == examResult.examId);
    const module = modules?.find((mod) => exam?.__typename === "EducationExam" && mod.id === exam.educationModuleId);
    const provider = educationProviders?.find((org) => org.id === module?.providerOrganizationId);
    const form = this.formService.createForm(exam, examResult);
    return {
      exam: exam,
      provider: provider,
      form: form,
      data: examResult,
      localized: {
        languageLevel: languageModules.find((x) => x.value === examResult.languageLevel)?.label,
        examResult: examResultStates.find((x) => x.value === examResult.examResult)?.label,
        passedExamModules: examResult.passedExamModules
          ?.map((x) => this.allExamModules.find((y) => y.value === x)?.label)
          .filter((x) => x)
          .join(", "),
      },
      examModules$: valuesOf(form.controls.examResult).pipe(
        map((result) =>
          result !== "NOT_TAKEN" && result !== "FAILED"
            ? this.allExamModules.filter((x) => (exam?.examModules ?? []).includes(<ExamModuleType>x.value))
            : []
        )
      ),
      examResultStates$: this.examResultStates$.pipe(
        map((results) => results.filter((x) => x.value !== "PARTIALLYPASSED" || !(exam?.examModules?.length <= 1)))
      ),
    };
  }
}
