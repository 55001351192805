<ng-container *appTranslate="let t">
  <form [formGroup]="form" *ngIf="form">
    <div class="p-fluid formgrid">
      <div class="field">
        <label for="examLocation">{{ t("examLocation.title") }}</label>
        <input type="text" pInputText id="examLocation" formControlName="examLocation" />
        <small class="p-error" *ngIf="form.controls.examLocation?.errors?.required">{{
          t("examLocation.required")
        }}</small>
      </div>

      <div class="field">
        <label for="plannedExamDateAndTime">{{ t("plannedExamDateAndTime.title") }}</label>
        <!-- doesn't work without `$any`; the form types aren't compatible but mine is right tho -->
        <app-date-time
          inputId="plannedExamDateAndTime"
          [form]="$any(form.controls.plannedExamDateAndTime)"
        ></app-date-time>
        <small class="p-error" *ngIf="form.controls.plannedExamDateAndTime.errors?.required">
          {{ t("plannedExamDateAndTime.required") }}
        </small>
      </div>

      <div class="field">
        <div class="field-checkbox">
          <p-checkbox
            inputId="plannedExamDateAndTimeConfirmed"
            [binary]="true"
            formControlName="plannedExamDateAndTimeConfirmed"
          ></p-checkbox>
          <label for="plannedExamDateAndTimeConfirmed">{{ t("plannedExamDateAndTimeConfirmed.title") }}</label>
        </div>
        <small class="p-error" *ngIf="form.controls.plannedExamDateAndTimeConfirmed.errors?.required">
          {{ t("plannedExamDateAndTimeConfirmed.required") }}
        </small>
      </div>

      <div class="field">
        <label for="examDateAndTime">{{ t("examDateAndTime.title") }}</label>
        <!-- doesn't work without `$any`; the form types aren't compatible but mine is right tho -->
        <app-date-time inputId="examDateAndTime" [form]="$any(form.controls.examDateAndTime)"></app-date-time>
        <small class="p-error" *ngIf="form.controls.examDateAndTime.errors?.required">
          {{ t("examDateAndTime.required") }}
        </small>
      </div>

      <div class="field">
        <label for="plannedExamResultDate">{{ t("plannedExamResultDate.title") }}</label>
        <p-calendar inputId="plannedExamResultDate" formControlName="plannedExamResultDate"></p-calendar>
        <small class="p-error" *ngIf="form.controls.plannedExamResultDate.errors?.required">
          {{ t("plannedExamResultDate.required") }}
        </small>
      </div>

      <div class="field">
        <label for="responsibleRolePayment">{{ t("responsibleRolePayment.title") }}</label>
        <p-dropdown
          inputId="responsibleRolePayment"
          formControlName="responsibleRolePayment"
          [options]="paymentRoles$ | async"
          [placeholder]="t('responsibleRolePayment.placeholder')"
        ></p-dropdown>
        <small class="p-error" *ngIf="form.controls.responsibleRolePayment.errors?.required">
          {{ t("responsibleRolePayment.required") }}
        </small>
      </div>
    </div>
  </form>
</ng-container>
