import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { VisaRelocationLanguageLevels } from "@ankaadia/ankaadia-shared";
import { StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { Observable, combineLatest, map, startWith } from "rxjs";
import { StaticDataService } from "../../../../../shared/static-data/static-data.service";

const NoCertificateRequired = "NOT_NEEDED";

@Component({
  selector: "app-candidate-relocation-requirements",
  templateUrl: "./candidate-relocation-requirements.component.html",
  standalone: false,
})
export class CandidateRelocationRequirementsComponent implements OnInit {
  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService
  ) {}

  @Input()
  form: FormGroup;

  @Input() readonly: boolean;

  protected shownRelocationRequirements$: Observable<StaticDataModel[]>;

  private readonly language = this.transloco.getActiveLang();

  get control(): FormControl {
    return <FormControl>this.form.get("visaRelocationRequirements");
  }

  ngOnInit(): void {
    this.shownRelocationRequirements$ = combineLatest([
      this.relocationRequirements$,
      this.form.controls.visaRelocationRequirements.valueChanges,
    ]).pipe(
      map(([options, currentSelection]) => this.getVisibleRelocationRequirementOptions(currentSelection, options))
    );
  }

  private getVisibleRelocationRequirementOptions(
    currentSelection: string[],
    options: StaticDataModel[]
  ): StaticDataModel[] {
    if (currentSelection?.includes(NoCertificateRequired)) {
      return options.filter((option) => option.value === NoCertificateRequired);
    }
    if (currentSelection?.length > 0 && !currentSelection?.includes(NoCertificateRequired)) {
      return options.filter((option) => option.value !== NoCertificateRequired);
    }
    return options;
  }

  private readonly relocationRequirements$ = this.staticDataService
    .getStaticData(StaticDataType.RelocationRequirements, this.language)
    .pipe(
      startWith(<StaticDataModel[]>[]), // multi-select does not work without this
      map((options) => options.filter((option) => VisaRelocationLanguageLevels.includes(option.value))),
      map((options) =>
        options
          .map((option) => ({ ...option, prio: option.value === NoCertificateRequired ? 0 : 1 })) //NoCertificateRequired should be always on top
          .sort((a, b) => a.prio - b.prio)
      )
    );
}
