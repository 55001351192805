import { Component, DestroyRef, Input, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { getAllEducationExamPaymentRoles } from "@ankaadia/ankaadia-shared";
import { StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { filter, map, Observable, tap } from "rxjs";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";
import { AdHocEducationExamForm } from "../education-exam-ad-hoc-dialog/education-exam-ad-hoc-form.model";
import { EducationExamForm } from "../education-exam-dialog/education-exam-form.model";

@Component({
  selector: "app-education-exam-dialog-general-fields",
  templateUrl: "./education-exam-dialog-general-fields.component.html",
  standalone: false,
})
export class EducationExamDialogGeneralFieldsComponent implements OnInit {
  @Input({ required: true }) form: EducationExamForm | AdHocEducationExamForm;

  private readonly allowedRoles: string[] = getAllEducationExamPaymentRoles();

  protected paymentRoles$: Observable<StaticDataModel[]> = this.staticDataService
    .getStaticData(StaticDataType.ProcessRole)
    .pipe(map((x) => x.filter((y) => this.allowedRoles.includes(y.value))));

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.form.controls.plannedExamDateAndTimeConfirmed.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((x) => x && !!this.form.controls.plannedExamDateAndTime.value),
        tap((_) =>
          this.form.controls.examDateAndTime.setValue({
            date: this.form.controls.plannedExamDateAndTime.value.date,
            hasTime: this.form.controls.plannedExamDateAndTime.value.hasTime,
          })
        )
      )
      .subscribe();
  }
}
