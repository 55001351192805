import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from "@angular/core";
import { Candidate, Document, Sharing } from "@ankaadia/graphql";
import { OrganizationFactoryService } from "../../../organization-specific/organization-specific-factory.service";
import { SettingsService } from "../../../shared/services/settings.service";
import {
  CandidateCardComponent as CandidateCardComponentInterface,
  CompleteCandidateForView,
  FilePreview,
} from "../candidate-card-component.model";
import { CandidatesService } from "../candidates.service";

@Component({
  selector: "app-candidate-card",
  templateUrl: "./candidate-card.component.html",
  standalone: false,
})
export class CandidateCardComponent implements OnInit {
  private cardComponentInstance: CandidateCardComponentInterface;
  @ViewChild("host", { static: true, read: ViewContainerRef }) host: ViewContainerRef;

  private _sharing: Sharing;
  @Input() set sharing(val: Sharing) {
    this._sharing = val;
    if (this.cardComponentInstance != null) this.cardComponentInstance.sharing = val;
  }

  get sharing(): Sharing {
    return this._sharing;
  }

  @Input()
  collectionId: string;

  @Input()
  collectionOrganizationId: string;

  private _candidateData: CompleteCandidateForView;
  private _candidate: Candidate;

  @Input() set candidateData(val: CompleteCandidateForView | Candidate) {
    if (isCandidateForView(val)) {
      this._candidateData = val;
      if (this.cardComponentInstance != null) {
        this.cardComponentInstance.data = val;
      }
    } else {
      this._candidate = val;
    }
  }

  get candidateData(): CompleteCandidateForView {
    return this._candidateData;
  }

  private _candidateFiles: Document[];
  @Input()
  set candidateFiles(files: Document[]) {
    this._candidateFiles = files;
    if (this.cardComponentInstance != null) {
      this.cardComponentInstance.files = files;
    }
  }

  get candidateFiles(): Document[] {
    return this._candidateFiles;
  }

  private _candidateTag: string;
  @Input()
  set candidateTag(tag: string) {
    this._candidateTag = tag;
    if (this.cardComponentInstance) {
      this.cardComponentInstance.tag = tag;
    }
  }

  get candidateTag(): string {
    return this._candidateTag;
  }

  private _isPresentation;
  @Input()
  set isPresentation(value: boolean) {
    this._isPresentation = value;
    if (this.cardComponentInstance) {
      this.cardComponentInstance.isPresentation = value;
    }
  }

  get isPresentation(): boolean {
    return this._isPresentation;
  }

  @Output()
  readonly filePreview = new EventEmitter<FilePreview>();

  constructor(
    private readonly settings: SettingsService,
    private readonly orgFactory: OrganizationFactoryService,
    private readonly candidatesService: CandidatesService
  ) {}

  ngOnInit(): void {
    if (this.candidateData) {
      this.createHost();
      return;
    }
    if (this._candidate.id != null) {
      this.candidatesService
        .getCandidateForView({
          id: this._candidate.id,
          orgId: this._candidate.organizationId,
          originalColId: this._sharing?.sharedCollectionId,
          originalOrgId: this._sharing?.organizationId,
        })
        .subscribe((candidate) => {
          this.candidateData = candidate;
          this.createHost();
        });
    }
  }

  private createHost(): void {
    this.orgFactory.getOrganizationSpecifics(this.settings.organizationId).subscribe((x) => {
      this.host.clear();
      const componentRef = this.host.createComponent(x.getCandidateCardViewComponent());
      this.cardComponentInstance = componentRef.instance;
      this.cardComponentInstance.data = this.candidateData;
      this.cardComponentInstance.sharing = this.sharing;
      this.cardComponentInstance.files = this.candidateFiles;
      this.cardComponentInstance.tag = this.candidateTag;
      this.cardComponentInstance.isPresentation = this.isPresentation;
      this.cardComponentInstance.selectedCollectionId = this.collectionId;
      this.cardComponentInstance.selectedOrganizationId = this.collectionOrganizationId;

      this.cardComponentInstance.filePreview.subscribe((preview) => this.filePreview.emit(preview));
    });
  }
}

function isCandidateForView(candidate: CompleteCandidateForView | Candidate): candidate is CompleteCandidateForView {
  return (candidate as CompleteCandidateForView).__typename === "CandidateForView";
}
