import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { UserPermission } from "@ankaadia/ankaadia-shared";
import { CollectionAndSharingInput, SupportedImmigrationCountry } from "@ankaadia/graphql";
import { groupBy, toPairs } from "lodash";
import { TreeNode } from "primeng/api";
import { forkJoin, Subscription } from "rxjs";
import { CandidateFilterFormService } from "../../candidate-filter/candidate-filter-form.service";
import { CandidateFilterPresetSelectorComponent } from "../../candidate-filter/candidate-filter-preset-selector/candidate-filter-preset-selector.component";
import { CandidateFilterField } from "../../candidate-filter/candidate-filter.model";
import { CandidateFilterService } from "../../candidate-filter/candidate-filter.service";
import { FilterMetadataMap } from "../../collections/collection-edit-assigned-candidates/custom-filter.model";

@Component({
  selector: "app-candidate-filter",
  templateUrl: "./candidate-filter.component.html",
  standalone: false,
})
export class CandidateFilterComponent implements OnInit, OnChanges, OnDestroy {
  protected readonly form = this.formService.createFiltersForm();
  protected readonly UserPermission = UserPermission;

  private subscription?: Subscription;

  protected fields: TreeNode<CandidateFilterField>[] = [];
  protected showSidebar = false;
  protected isActive = false;

  @Input()
  filter: FilterMetadataMap = {};

  @Output()
  readonly filterChange = new EventEmitter<FilterMetadataMap>();

  @Output()
  readonly collectionCreate = new EventEmitter<CollectionAndSharingInput>();

  @ViewChild(CandidateFilterPresetSelectorComponent)
  presetSelector: CandidateFilterPresetSelectorComponent;

  get isDisabled(): boolean {
    return this.fields.length === 0;
  }

  constructor(
    private readonly formService: CandidateFilterFormService,
    private readonly candidateFilterService: CandidateFilterService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscription = forkJoin([
      this.candidateFilterService.immigrationCountries,
      this.candidateFilterService.getFilterFields(),
    ]).subscribe(([countries, groups]) => {
      this.fields = groups.map((group) => ({
        icon: group.country ? "flag flag-small flag-" + group.country.toLowerCase() : null,
        label: group.label,
        selectable: false,
        children: toPairs(groupBy(group.fields, (field) => field.country ?? SupportedImmigrationCountry.Unknown))
          .map(([country, fields]) => {
            const treenodes = fields.map((field) => ({ label: field.label, data: field }));
            if (country === SupportedImmigrationCountry.Unknown) {
              return treenodes;
            }

            const icon = country ? "flag flag-small flag-" + country.toLowerCase() : null;
            const label = countries.find((c) => c.value === country)?.label ?? country;
            return [{ icon, label, selectable: false, children: treenodes }];
          })
          .flat(),
      }));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter) {
      this.isActive = Object.keys(this.filter ?? {}).length > 0;
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  onFilterChange(filter: FilterMetadataMap): void {
    this.filter = filter;
    this.changeDetector.detectChanges();
    this.search();
  }

  clearFilter(): void {
    this.filter = {};
    this.changeDetector.detectChanges();
    this.search();
  }

  search(): void {
    const filterMetadata = this.candidateFilterService.buildFilterMetadataMap(this.form);
    this.isActive = Object.keys(filterMetadata).length > 0;
    this.filterChange.emit(filterMetadata);
  }

  addPreset(): void {
    const filterMetadata = this.candidateFilterService.buildFilterMetadataMap(this.form);
    this.presetSelector.add(filterMetadata);
  }

  saveCollection(collection: CollectionAndSharingInput): void {
    this.collectionCreate.emit(collection);
    this.closeCollectionCreateDialog();
  }

  openCollectionCreateDialog(): void {
    this.showSidebar = true;
    this.changeDetector.detectChanges();
  }

  closeCollectionCreateDialog(): void {
    this.showSidebar = false;
    this.changeDetector.detectChanges();
  }
}
