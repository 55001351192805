<ng-container *appTranslate="let t">
  <form [formGroup]="form">
    <div class="flex flex-row" *ngIf="!evaluationsDisabled">
      <div class="p-fluid formgrid grid align-items-center">
        <div class="field col-12 lg:col-12">
          <label for="evaluatedQualification">{{ t("evaluatedQualification.title") }}</label>

          <textarea
            id="evaluatedQualification"
            pInputTextarea
            rows="3"
            formControlName="evaluatedQualification"
            [readonly]="readonly"
            maxlength="300"
          ></textarea>
        </div>

        <div class="field col-12 lg:col-3">
          <app-candidate-federal-state
            [form]="form"
            [staticDataContext]="staticDataContext"
            [readonly]="readonly"
            class="field"
          ></app-candidate-federal-state>
        </div>

        <div class="field col-12 lg:col-6">
          <label for="responsibleAuthorityForQualification">{{
            t("responsibleAuthorityForQualification.title")
          }}</label>
          <p-dropdown
            id="responsibleAuthorityForQualification"
            [options]="certificateValuationAuthorities"
            formControlName="responsibleAuthorityForQualification"
            [placeholder]="t('responsibleAuthorityForQualification.placeholder')"
            [showClear]="true"
            [readonly]="readonly"
          ></p-dropdown>
        </div>
        <div class="field col-12 lg:col-3">
          <label for="responsibleAuthorityForQualificationInOrigin">{{
            t("responsibleAuthorityForQualificationInOrigin.title")
          }}</label>
          <input
            pInputText
            id="responsibleAuthorityForQualificationInOrigin"
            formControlName="responsibleAuthorityForQualificationInOrigin"
            [placeholder]="t('responsibleAuthorityForQualificationInOrigin.placeholder')"
            [readonly]="readonly"
            maxlength="300"
          />
        </div>

        <app-candidate-document-date
          [inputId]="'qualificationEvaluationRequested#' + index"
          [candidateId]="candidateId"
          [candidateOrganizationId]="candidateOrganizationId"
          [candidateForm]="form"
          [readonly]="readonly"
          [label]="t('qualificationEvaluationRequested.title')"
          documentType="APPVALEDU"
          [selectedSet]="{ foreignKey: form.controls.id.value }"
          dateField="issueDate"
          class="field col-12 lg:col-3"
        ></app-candidate-document-date>

        <div class="field col-12 lg:col-3">
          <app-candidate-aligned-label
            labelFor="fileNumber"
            [labelText]="t('qualificationEvaluationFileNumber.title')"
            [useAlignmentCheckBox]="true"
          >
          </app-candidate-aligned-label>
          <input
            pInputText
            id="fileNumber"
            formControlName="fileNumber"
            [placeholder]="t('qualificationEvaluationFileNumber.placeholder')"
            [readonly]="readonly"
            maxlength="300"
          />
        </div>

        <div class="field col-12 lg:col-3 field-with-info-icon">
          <div class="field-checkbox">
            <p-checkbox class="alignment-checkbox"></p-checkbox>
            <label for="evaluationNoticeKind">{{ t("qualificationEvaluationNoticeKind.title") }}</label>
          </div>

          <p-dropdown
            inputId="evaluationNoticeKind"
            [options]="evaluationNotices$ | async"
            formControlName="evaluationNoticeKind"
            [showClear]="true"
            [placeholder]="t('qualificationEvaluationNoticeKind.placeholder')"
            [readonly]="readonly"
          ></p-dropdown>
        </div>

        <app-candidate-document-date
          *ngIf="!form.controls.evaluationNoticeKind?.value || form.controls.evaluationNoticeKind?.value === 'CERTZAB'"
          [inputId]="'qualificationEvaluationByCentralDepartment#' + index"
          [candidateId]="candidateId"
          [candidateOrganizationId]="candidateOrganizationId"
          [candidateForm]="form"
          [readonly]="readonly"
          [disabled]="!form.controls.evaluationNoticeKind?.value"
          [label]="t('qualificationEvaluationByCentralDepartmentReceived.title')"
          documentType="CERTZAB"
          [selectedSet]="{ foreignKey: form.controls.id.value }"
          dateField="issueDate"
          class="field col-12 lg:col-3"
        ></app-candidate-document-date>

        <app-candidate-document-date
          *ngIf="form.controls.evaluationNoticeKind?.value === 'CERTLAB'"
          [inputId]="'qualificationEvaluationByFederalStateAuthority#' + index"
          [candidateId]="candidateId"
          [candidateOrganizationId]="candidateOrganizationId"
          [candidateForm]="form"
          [readonly]="readonly"
          [label]="t('qualificationEvaluationByFederalStateAuthorityReceived.title')"
          documentType="CERTLAB"
          [selectedSet]="{ foreignKey: form.controls.id.value }"
          dateField="issueDate"
          class="field col-12 lg:col-3"
        ></app-candidate-document-date>

        <app-candidate-document-date
          *ngIf="form.controls.evaluationNoticeKind?.value === 'REJECTCERTAB'"
          [inputId]="'qualificationEvaluationRejection#' + index"
          [candidateId]="candidateId"
          [candidateOrganizationId]="candidateOrganizationId"
          [candidateForm]="form"
          [readonly]="readonly"
          [label]="t('qualificationEvaluationRejectionReceived.title')"
          documentType="REJECTCERTAB"
          [selectedSet]="{ foreignKey: form.controls.id.value }"
          dateField="issueDate"
          class="field col-12 lg:col-3"
        ></app-candidate-document-date>
      </div>

      <p-button
        class="align-self-center ml-2"
        icon="pi pi-times"
        (onClick)="removeEvaluation.emit(index)"
        [disabled]="readonly || evaluationsDisabled"
      ></p-button>
    </div>
  </form>
</ng-container>
