import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ENABLE_TALENT_POOL, UserPermission } from "@ankaadia/ankaadia-shared";
import { Collection, CollectionTransferInput, CollectionType, FavoriteFragment } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { Subscription, finalize, forkJoin } from "rxjs";
import { MessageDialogService } from "../../../shared/message-dialog/message-dialog.service";
import { SettingsService } from "../../../shared/services/settings.service";
import { DeepLinkAccessService } from "../../deep-link-access/deep-link-access.service";
import { FavoriteService } from "../../favorite/favorite.service";
import { MessageService } from "../../message/message.service";
import { CollectionTransferResultComponent } from "../collection-transfer-dialog/collection-transfer-result/collection-transfer-result.component";
import { CollectionService } from "../collection.service";
import { CollectionsComponent } from "../collections.component";

export interface AssignmentException {
  organizationId: string;
  candidateId: string;
  displayName: string;
  displayId: string;
  countryOfOrigin: string;
  assigmentExceptionType: string;
  implicitType: string;
}

@Component({
  selector: "app-collection-form",
  templateUrl: "./collection-form.component.html",
  styleUrl: "./collection-form.component.scss",
  standalone: false,
})
export class CollectionFormComponent implements OnInit, OnDestroy {
  readonly ENABLE_TALENT_POOL = ENABLE_TALENT_POOL;

  collection: Collection;
  assignmentExceptions: AssignmentException[];
  collectionForTransfer: Collection;
  allCollections: Partial<Collection>[];
  selectableCollections: Partial<Collection>[];
  showSidebar: boolean;
  totalAssignmentExceptions = 0;
  totalAssignedCandidates = 0;
  totalAssignedCollections = 0;
  totalAssignedCollectionCandidates = 0;
  isTransferring: boolean;
  favorite: FavoriteFragment;
  private routeSubscription: Subscription;
  protected readonly maxCandidateCountForOverviews = 200;

  @Output() readonly editModeChanged = new EventEmitter<boolean>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly collService: CollectionService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly errorService: MessageDialogService,
    private readonly collections: CollectionsComponent,
    protected readonly settings: SettingsService,
    private readonly favoriteService: FavoriteService,
    private readonly dialogService: DialogService,
    private readonly deepLinkAccessService: DeepLinkAccessService
  ) {}

  protected get isLicensed(): boolean {
    return this.settings.isLicensed;
  }

  protected get isStandard(): boolean {
    return (this.collection?.type ?? CollectionType.Standard) === CollectionType.Standard;
  }

  protected get isAuto(): boolean {
    return this.collection?.type === CollectionType.Auto;
  }

  protected get isFromTemplate(): boolean {
    return this.collection?.type === CollectionType.FromTemplate;
  }

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe((params) => this.routeChanged(params));
    this.collService.getCollections().subscribe((x) => {
      this.allCollections = x;
      this.setSelectableCollections();
    });
  }

  setSelectableCollections(): void {
    this.selectableCollections = this.allCollections?.filter((c) => c.id !== this.collection?.id);
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  reloadCollection(): void {
    this.collService
      .getCollection(this.collection.organizationId, this.collection.id)
      .subscribe((x) => this.updateCollection(x));
  }

  editCollection(): void {
    this.collService.getCollection(this.collection.organizationId, this.collection.id).subscribe((x) => {
      this.updateCollection(x);
      this.showSidebar = true;
    });
  }

  closeSidebar(): void {
    this.showSidebar = false;
    this.collService.getCollection(this.collection.organizationId, this.collection.id).subscribe((x) => {
      this.updateCollection(x);
      this.collectionForTransfer = null;
    });
  }

  deleteCollection(event: Event): void {
    this.collService.getCollection(this.collection.organizationId, this.collection.id).subscribe((x) => {
      if (x.processes?.length > 0) {
        this.errorService.showMessage(
          translate("collection.deleteFailed.title"),
          translate("collection.deleteFailed.message", this.collection)
        );
        return;
      }
      this.confirmationService.confirm({
        target: event.target,
        message: translate("collection.confirmDelete", this.collection),
        icon: PrimeIcons.EXCLAMATION_TRIANGLE,
        accept: () => {
          const index = this.allCollections.findIndex((c) => c.id === this.collection.id);
          const nextOrPrev = index > -1 ? (this.allCollections[index + 1] ?? this.allCollections[index - 1]) : null;
          this.collService.removeCollection(x).subscribe(() => {
            this.messageService.add({
              severity: "success",
              summary: translate("collection.deleted.title"),
              detail: translate("collection.deleted.message", this.collection),
            });
            if (nextOrPrev) {
              void this.router.navigate(["../", nextOrPrev.id], { relativeTo: this.route });
            } else {
              void this.router.navigate(["../.."], { relativeTo: this.route });
            }
          });
        },
      });
    });
  }

  canTransfer(): boolean {
    const allowedTypes = [CollectionType.Standard];
    return (
      this.settings.isLicensed &&
      this.settings.hasAnyPermission([UserPermission.PartnerAdministrator]) &&
      this.collection &&
      this.collection.organizationId === this.settings.organizationId &&
      this.collection.assignedCandidates?.length > 0 &&
      this.collection.assignedCandidates?.every((c) => c.organizationId === this.settings.organizationId) &&
      allowedTypes.includes(this.collection.type)
    );
  }

  openTransferCollection(): void {
    this.collectionForTransfer = this.collection;
    this.showSidebar = true;
  }

  transferCollection(collection: CollectionTransferInput): void {
    this.isTransferring = true;
    this.collService
      .transferCollection(collection)
      .pipe(finalize(() => (this.isTransferring = false)))
      .subscribe((result) => {
        this.reloadCollection();
        this.dialogService.open(CollectionTransferResultComponent, { modal: true, data: result });
        this.showSidebar = !result.success;
      });
  }

  private routeChanged({ collectionId, orgId }: Params): void {
    const { checkAccess } = this.route.snapshot.queryParams;
    if (checkAccess) {
      // it's a link from the emails, gonna check the access
      this.deepLinkAccessService.canAccessCollectionEdit(collectionId, orgId).subscribe((access) => {
        if (access.canAccess) {
          this.loadCollection(collectionId, orgId);
        } else {
          if (access.redirectToOrganization) {
            this.settings.switchOrganizationKeepingCurrentUrl(access.redirectToOrganization);
          } else {
            this.settings.navigateToAccessDenied();
          }
        }
      });
    } else {
      // carry on, it's probably just navigation within the app
      if (orgId === this.settings.organizationId) {
        // can only edit your own org's collections
        this.loadCollection(collectionId, orgId);
      } else {
        this.settings.navigateToAccessDenied();
      }
    }
  }

  private loadCollection(collectionId: string, orgId: string): void {
    forkJoin([
      // caching is not allowed here, since candidates might be removed/added to the collection
      this.collService.getCollection(orgId, collectionId, false),
      this.favoriteService.isCollectionFavorite(collectionId, orgId),
    ]).subscribe(([collection, favorite]) => {
      this.updateCollection(collection);
      this.collections.selectedCollection = collection;
      this.favorite = favorite;
      this.setSelectableCollections();
    });
  }

  private updateCollection(collection: Collection): void {
    this.collection = collection;
    this.totalAssignedCandidates = collection.assignedCandidates?.length ?? 0;
    this.totalAssignedCollections = collection.assignedCollections?.length ?? 0;
    this.totalAssignedCollectionCandidates = collection.totalCandidatesFromAssignedCollections ?? 0;
    this.totalAssignmentExceptions = collection.assignmentExceptions?.length ?? 0;
  }
}
