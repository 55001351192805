import { Injectable } from "@angular/core";
import {
  EditEducationCourseTotalPerformanceGQL,
  EditEducationCourseWeeklyPerformanceGQL,
  EducationCoursePerformanceByCandidate,
  EducationCoursePerformanceByCandidateGetInput,
  EducationCoursePerformanceForListFragment,
  EducationCourseTotalPerformanceForEditFragment,
  EducationCourseTotalPerformanceGetInput,
  EducationCourseTotalPerformanceStateUpdateInput,
  EducationCourseWeeklyAttendanceUpdateInput,
  EducationCourseWeeklyCommentUpdateInput,
  EducationCourseWeeklyPerformanceForEditFragment,
  EducationCourseWeeklyPerformanceGetInput,
  EducationCourseWeeklyPerformanceUpdateInput,
  EducationCourseWeekPrefillInput,
  ListEducationCoursePerformanceCandidateGQL,
  ListEducationCoursePerformanceGQL,
  PrefillEducationCourseWeekGQL,
  UpdateEducationCourseTotalPerformanceStateGQL,
  UpdateEducationCourseWeeklyAttendanceGQL,
  UpdateEducationCourseWeeklyCommentGQL,
  UpdateEducationCourseWeeklyPerformanceGQL,
} from "@ankaadia/graphql";
import { pick } from "lodash";
import { map, Observable, switchMap } from "rxjs";

@Injectable({ providedIn: "root" })
export class EducationCoursePerformanceService {
  constructor(
    private readonly performanceList: ListEducationCoursePerformanceGQL,
    private readonly performanceCandidateList: ListEducationCoursePerformanceCandidateGQL,
    private readonly totalEdit: EditEducationCourseTotalPerformanceGQL,
    private readonly weeklyEdit: EditEducationCourseWeeklyPerformanceGQL,
    private readonly candidateStateUpdate: UpdateEducationCourseTotalPerformanceStateGQL,
    private readonly attendanceUpdate: UpdateEducationCourseWeeklyAttendanceGQL,
    private readonly performanceUpdate: UpdateEducationCourseWeeklyPerformanceGQL,
    private readonly commentUpdate: UpdateEducationCourseWeeklyCommentGQL,
    private readonly weekPrefill: PrefillEducationCourseWeekGQL
  ) {}

  list(courseId: string, organizationId: string): Observable<EducationCoursePerformanceForListFragment> {
    return this.performanceList
      .watch({ input: { courseId, organizationId } })
      .valueChanges.pipe(map((x) => x.data.getEducationCoursePerformance));
  }

  listForCandidate(
    input: EducationCoursePerformanceByCandidateGetInput
  ): Observable<EducationCoursePerformanceByCandidate> {
    return this.performanceCandidateList
      .fetch({ input: input }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getEducationCoursePerformanceByCandidate));
  }

  updateCandidateState(candidateState: EducationCourseTotalPerformanceStateUpdateInput): Observable<void> {
    return this.editTotal(
      pick(candidateState, ["candidateId", "candidateOrganizationId", "courseId", "organizationId"])
    ).pipe(
      switchMap(({ _etag }) =>
        this.candidateStateUpdate.mutate({
          input: {
            ...pick(candidateState, [
              "candidateId",
              "candidateOrganizationId",
              "courseId",
              "organizationId",
              "candidateState",
            ]),
            _etag: _etag,
          },
        })
      ),
      map((x) => x.data.updateEducationCourseTotalPerformanceState)
    );
  }

  updateAttendance(attendance: EducationCourseWeeklyAttendanceUpdateInput): Observable<void> {
    return this.editWeekly(
      pick(attendance, ["candidateId", "candidateOrganizationId", "courseId", "organizationId", "week"])
    ).pipe(
      switchMap(({ _etag }) =>
        this.attendanceUpdate.mutate({
          input: {
            ...pick(attendance, [
              "candidateId",
              "candidateOrganizationId",
              "courseId",
              "organizationId",
              "week",
              "attendance",
            ]),
            _etag: _etag,
          },
        })
      ),
      map((x) => x.data.updateEducationCourseWeeklyAttendance)
    );
  }

  updatePerformance(performance: EducationCourseWeeklyPerformanceUpdateInput): Observable<void> {
    return this.editWeekly(
      pick(performance, ["candidateId", "candidateOrganizationId", "courseId", "organizationId", "week"])
    ).pipe(
      switchMap(({ _etag }) =>
        this.performanceUpdate.mutate({
          input: {
            ...pick(performance, [
              "candidateId",
              "candidateOrganizationId",
              "courseId",
              "organizationId",
              "week",
              "performance",
            ]),
            _etag: _etag,
          },
        })
      ),
      map((x) => x.data.updateEducationCourseWeeklyPerformance)
    );
  }

  updateComment(comment: EducationCourseWeeklyCommentUpdateInput): Observable<void> {
    return this.editWeekly(
      pick(comment, ["candidateId", "candidateOrganizationId", "courseId", "organizationId", "week"])
    ).pipe(
      switchMap(({ _etag }) =>
        this.commentUpdate.mutate({
          input: {
            ...pick(comment, [
              "candidateId",
              "candidateOrganizationId",
              "courseId",
              "organizationId",
              "week",
              "comment",
            ]),
            _etag: _etag,
          },
        })
      ),
      map((x) => x.data.updateEducationCourseWeeklyComment)
    );
  }

  prefillWeek(week: EducationCourseWeekPrefillInput): Observable<void> {
    return this.weekPrefill
      .mutate({ input: pick(week, ["courseId", "organizationId", "week"]) })
      .pipe(map((x) => x.data.prefillEducationCourseWeek));
  }

  private editTotal(
    input: EducationCourseTotalPerformanceGetInput
  ): Observable<EducationCourseTotalPerformanceForEditFragment> {
    return this.totalEdit.fetch({ input: input }).pipe(map((x) => x.data.getEducationCourseTotalPerformance));
  }

  private editWeekly(
    input: EducationCourseWeeklyPerformanceGetInput
  ): Observable<EducationCourseWeeklyPerformanceForEditFragment> {
    return this.weeklyEdit.fetch({ input: input }).pipe(map((x) => x.data.getEducationCourseWeeklyPerformance));
  }
}
