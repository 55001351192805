import { ChangeDetectionStrategy, Component, computed, input } from "@angular/core";
import { TranslateDirective } from "../../../../../shared/transloco/translate.directive";
import { TranslocoCurrencyPipe } from "@jsverse/transloco-locale";
import { SupportedBillingCurrency } from "@ankaadia/graphql";

@Component({
  standalone: true,
  selector: "app-price",
  templateUrl: "./price.component.html",
  imports: [TranslateDirective, TranslocoCurrencyPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceComponent {
  private readonly styleClasses = {
    normal: {
      price: ["text-xl"],
      unit: ["ml-1", "text-sm"],
    },
    large: {
      price: ["text-2xl"],
      unit: ["ml-1", "text-base"],
    },
  };

  price = input.required<number>();

  unit = input.required<"month" | "year" | "candidate">();

  currency = input<SupportedBillingCurrency>(SupportedBillingCurrency.Eur);

  size = input<"normal" | "large">("normal");

  styleClass = computed(() => this.styleClasses[this.size()]);
}
