import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { CandidateStatus, TerminationEmploymentActor } from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { Observable, distinctUntilChanged, filter, map, of, switchMap } from "rxjs";
import { notNullValuesOf, valuesOf } from "../../../../shared/services/form.helper";
import { ResponsibleRolePurpose } from "../../../candidate-reusables/candidate-responsible-representative-group/candidate-responsible-representative-group.service";
import { getMigrationForm } from "../../candidate-form.helper";
import { CandidateForm, HrForm as HumanResourcesForm } from "../../candidate-form.model";

@Component({
  selector: "app-candidate-internal-hr",
  templateUrl: "./candidate-internal-hr.component.html",
  standalone: false,
})
export class CandidateInternalHrComponent implements OnChanges {
  readonly language = this.transloco.getActiveLang();
  protected ResponsibleRolePurpose = ResponsibleRolePurpose;
  protected TerminationEmploymentActor = [
    { label: translate("enum.TerminationEmploymentActor.Employee"), value: TerminationEmploymentActor.Employee },
    { label: translate("enum.TerminationEmploymentActor.Employer"), value: TerminationEmploymentActor.Employer },
  ];

  protected employerOrganizationId$: Observable<string>;

  @Input({ required: true })
  candidateId: string;

  @Input({ required: true })
  candidateOrganizationId: string;

  @Input({ required: true })
  form: HumanResourcesForm;

  @Input({ required: true })
  candidateForm: CandidateForm;

  @Input()
  readonly: boolean;

  constructor(private readonly transloco: TranslocoService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form) {
      this.initEmployerOrganizationObservable();
    }
  }

  private initEmployerOrganizationObservable(): void {
    this.employerOrganizationId$ = valuesOf(this.candidateForm.controls.immigrationCountry).pipe(
      filter((country) => !!country),
      map((country) => getMigrationForm(this.candidateForm, country)),
      map((migration) => migration.controls.employmentRelationship),
      switchMap((employmentRelationship) =>
        valuesOf(employmentRelationship.controls.hasDifferentRelationships).pipe(
          switchMap((hasDifferentRelationships) =>
            hasDifferentRelationships
              ? notNullValuesOf(this.candidateForm.controls.status).pipe(
                  map((status) => status === CandidateStatus.LabourMigrationFinished)
                )
              : of(false)
          ),
          map((useAfterRecognition) =>
            useAfterRecognition
              ? employmentRelationship?.controls.afterRecognition
              : employmentRelationship?.controls.beforeRecognition
          )
        )
      ),
      map((employmentRelationshipData) => employmentRelationshipData?.controls.employerId),
      filter((employerId) => !!employerId),
      switchMap((employerId) => valuesOf(employerId)),
      distinctUntilChanged()
    );
  }
}
