import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { EzbInfoInput, Organization, OrganizationInput } from "@ankaadia/graphql";
import { SettingsService } from "../../../../shared/services/settings.service";

@Component({
  selector: "app-organization-ezb-data-dialog",
  templateUrl: "./organization-EZB-data-dialog.component.html",
  standalone: false,
})
export class OrganizationEZBDataDialogComponent implements OnInit {
  readonly maxEstablishmentDate = new Date();
  form: FormGroup;

  @Input() organization: Organization;
  @Input() isEdit = false;
  @Input() displayBackButton? = false;

  @Output() readonly closed = new EventEmitter<boolean>();
  @Output() readonly saved = new EventEmitter<OrganizationInput>();

  get myOrganizationId(): string {
    return this.settings.organizationId;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly settings: SettingsService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      yearOfEstablishment: this.organization.yearOfEstablishment,
      operatingNumber: this.organization.operatingNumber,
      companyRegistrationAuthority: this.organization.companyRegistrationAuthority,
      registerNo: this.organization.registerNo,
      numberOfEmployers: this.organization.numberOfEmployers,
      hasPendingSecurityPayments: this.organization.EZBData?.hasPendingSecurityPayments,
      hasSocialSecurityIssuesInLast5Years: this.organization.EZBData?.hasSocialSecurityIssuesInLast5Years,
      detailsOfSocialSecurityIssues: this.organization.EZBData?.detailsOfSocialSecurityIssues,
      hasInsolvencyProceedingInLast5Years: this.organization.EZBData?.hasInsolvencyProceedingInLast5Years,
      hasInsolvencyProceedingLackedAssets: this.organization.EZBData?.hasInsolvencyProceedingLackedAssets,
    });
    this.form.controls.hasPendingSecurityPayments.disable();
    this.form.controls.hasSocialSecurityIssuesInLast5Years.disable();
    this.form.controls.detailsOfSocialSecurityIssues.disable();
    this.form.controls.hasInsolvencyProceedingInLast5Years.disable();
    this.form.controls.hasInsolvencyProceedingLackedAssets.disable();
  }

  onSubmit(): void {
    if (this.form.dirty && this.form.valid) {
      const orgInput = new OrganizationInput();
      Object.assign(orgInput, this.organization);
      delete orgInput["__typename"];
      delete orgInput["organizationId"];
      delete orgInput["parentOrganizationName"];
      this.saved.emit(this.setModelValue(orgInput));
      this.form.markAsPristine();
    }
  }

  private setModelValue(orgInput: OrganizationInput): OrganizationInput {
    orgInput.yearOfEstablishment = this.form.value.yearOfEstablishment;
    orgInput.operatingNumber = this.form.value.operatingNumber;
    orgInput.companyRegistrationAuthority = this.form.value.companyRegistrationAuthority;
    orgInput.registerNo = this.form.value.registerNo;
    orgInput.numberOfEmployers = this.form.value.numberOfEmployers;

    orgInput.EZBData = new EzbInfoInput();
    orgInput.EZBData.hasPendingSecurityPayments = this.form.controls.hasPendingSecurityPayments.value;
    orgInput.EZBData.hasSocialSecurityIssuesInLast5Years = this.form.controls.hasSocialSecurityIssuesInLast5Years.value;
    orgInput.EZBData.detailsOfSocialSecurityIssues = this.form.controls.detailsOfSocialSecurityIssues.value;
    orgInput.EZBData.hasInsolvencyProceedingInLast5Years = this.form.controls.hasInsolvencyProceedingInLast5Years.value;
    orgInput.EZBData.hasInsolvencyProceedingLackedAssets = this.form.controls.hasInsolvencyProceedingLackedAssets.value;
    return orgInput;
  }
}
