<ng-container *appTranslate="let t">
  @if (form) {
    <p-card [formGroup]="form">
      <ng-template pTemplate="title">
        <h2>{{ t('billing.contracts.title') }}</h2>
      </ng-template>

      <div>
        <p-fieldset [legend]="t('billing.contract.title')" styleClass="p-fieldset-sm">
          <div class="p-fluid grid">
            <div class="field col-12 md:col-6">
              <label for="contractStartDate" class="pl-2">{{ t("billing.contract.startDate.title") }}</label>
              <app-contract-date
                id="contractStartDate"
                formControlName="startDate"></app-contract-date>
              <small class="p-error" *ngIf="form.get('startDate').errors?.required">
                {{ t("billing.contract.startDate.required") }}
              </small>
            </div>
            <div class="field col-12 md:col-6">
              <label for="currency" class="pl-2">{{ t("billing.contract.currency.title") }}</label>
              <p-dropdown id="currency" formControlName="currency" [options]="currencies"></p-dropdown>
              <small class="p-error" *ngIf="form.get('currency').errors?.required">
                {{ t("billing.contract.currency.required") }}
              </small>
            </div>
          </div>
        </p-fieldset>
        <p-fieldset [legend]="t('billing.plan.title')" styleClass="p-fieldset-sm" formArrayName="phases">
          <ng-container formGroupName="0">
            <div class="p-fluid grid">
              <div class="field col-12 md:col-6">
                <label for="plan" class="pl-2">{{ t("billing.plan.title") }}</label>
                <p-dropdown id="plan" formControlName="planId" [options]="plans | async" optionLabel="labelKey"
                            optionValue="id"></p-dropdown>
                <small class="p-error" *ngIf="form.get('phases.0.planId').errors?.required">
                  {{ t("billing.plan.required") }}
                </small>
              </div>
              <div class="field col-12 md:col-6">
                <label for="phaseStartDate" class="pl-2">{{ t("billing.contract.phase.startDate.title") }}</label>
                <app-contract-date
                  id="phaseStartDate"
                  formControlName="startDate"></app-contract-date>
                <small class="p-error" *ngIf="form.get('startDate').errors?.required">
                  {{ t("billing.contract.phase.startDate.required") }}
                </small>
                <small class="p-error" *ngIf="form.get('startDate').errors?.sameOrAfter">
                  {{ t("billing.contract.phase.startDate.sameOrAfter") }}
                </small>
              </div>

              @if (form.get('phases.0.planId').value) {
                <app-plan-summary class="field col-12" [planId]="form.get('phases.0.planId').value"></app-plan-summary>
              }
            </div>
          </ng-container>
        </p-fieldset>
        <p-fieldset [legend]="t('billing.paymentSettings.candidate.title')">
          <app-payment-settings-form class="col-12"
                                     [form]="$any(form.get('candidatePaymentSettings.0'))"></app-payment-settings-form>
        </p-fieldset>
        <p-fieldset [legend]="t('billing.paymentSettings.license.title')">
          <app-payment-settings-form class="col-12"
                                     [form]="$any(form.get('licensePaymentSettings.0'))"></app-payment-settings-form>
        </p-fieldset>

        <p-fieldset [legend]="t('billing.discounts.candidate.title')" [toggleable]="true" [collapsed]="true">
          <app-discounts [form]="$any(form.get('phases.0'))" target="candidateDiscounts"
                         [currency]="form.get('currency').value"></app-discounts>
        </p-fieldset>

        <p-fieldset [legend]="t('billing.discounts.license.title')" [toggleable]="true" [collapsed]="true">
          <app-discounts [form]="$any(form.get('phases.0'))" target="licenseDiscounts"
                         [currency]="form.get('currency').value"></app-discounts>
        </p-fieldset>
      </div>

      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-between mt-3">
          <p-button
            [disabled]="!form.valid || !form.dirty"
            [label]="t('common.save')"
            icon="pi pi-check"
            type="button"
            (onClick)="save.emit(form.getRawValue())"
          ></p-button>
          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            type="button"
            (onClick)="cancelCreate.emit()"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  }
</ng-container>
