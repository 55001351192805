<ng-container *appTranslate="let t">
  <p-dialog
    #importResultDialog
    [header]="caption"
    [visible]="true"
    [modal]="true"
    [style]="{ width: '70vw'}"
    [draggable]="false"
    [resizable]="false"
    [closable]="true"
    (onHide)="closed.emit()"
  >
    <p-messages [value]="generalMessages" [closable]="true" [enableService]="false"></p-messages>
    <div *ngIf="!xlsxImportResult?.failed">{{t("import-result-dialog.import-success", xlsxImportResult)}}</div>
    <p-table
      *ngIf="xlsxImportResult?.issues?.length > 0"
      dataKey="id"
      [value]="xlsxImportResult?.issues"
      [totalRecords]="xlsxImportResult?.issues?.length"
      class="p-overflow-auto w-full h-full"
      styleClass="p-datatable p-datatable-sm app-form"
      sortField="lineNumber"
    >
      <ng-template pTemplate="header">
        <tr>
          <th class="white-space-nowrap" pSortableColumn="lineNumber">
            {{ t("import-result-dialog.column.lineNumber") }} <p-sortIcon field="lineNumber"></p-sortIcon>
          </th>
          <th class="white-space-nowrap" pSortableColumn="name">
            {{ t("import-result-dialog.column.name") }}<p-sortIcon field="name"></p-sortIcon>
          </th>
          <th class="white-space-nowrap" pSortableColumn="property">
            {{ t("import-result-dialog.column.property") }}<p-sortIcon field="property"></p-sortIcon>
          </th>
          <th class="white-space-nowrap" pSortableColumn="message">
            {{ t("import-result-dialog.column.message") }} <p-sortIcon field="message"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-issue>
        <tr>
          <td class="white-space-nowrap">{{ issue.lineNumber }}</td>
          <td class="white-space-nowrap">{{ issue.name }}</td>
          <td class="white-space-nowrap">{{ issue.property }}</td>
          <td>{{ issue.message }}</td>
        </tr>
      </ng-template>
    </p-table>

    <ng-template pTemplate="footer">
      <div class="flex justify-content-end mt-4">
        <p-button
          [label]="t('common.close')"
          icon="pi pi-times"
          class="p-button-warning"
          (onClick)="importResultDialog.visible=false"
        ></p-button>
      </div>
    </ng-template>
  </p-dialog>
</ng-container>
