import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { RecognitionPathEntryDe } from "@ankaadia/graphql";
import { Subscription } from "rxjs";
import { RecognitionPathFormDe } from "../../../candidate-form.model";

@Component({
  selector: "app-candidate-recognition-path-de",
  templateUrl: "./candidate-recognition-path-de.component.html",
  styleUrl: "./candidate-recognition-path-de.component.scss",
  standalone: false,
})
export class CandidateRecognitionPathDEComponent implements OnInit, OnDestroy {
  @Input()
  form: RecognitionPathFormDe;

  @Input()
  candidateId: string;

  @Input()
  candidateOrganizationId: string;

  @Input()
  candidateProfession: string;

  @Input()
  currentRecognitionPath: RecognitionPathEntryDe;

  @Input()
  alternativeRecognitionPath: RecognitionPathEntryDe;

  @Input()
  readonly: boolean;

  private currentSub: Subscription;
  private alternativeSub: Subscription;

  ngOnInit(): void {
    let skipNextUpdate = false;
    this.currentSub = this.form.controls.current.controls.isCurrent.valueChanges.subscribe((isCurrent) => {
      if (!skipNextUpdate) {
        skipNextUpdate = true;
        this.form.controls.alternative.controls.isCurrent.setValue(!isCurrent);
      } else {
        skipNextUpdate = false;
      }
    });
    this.alternativeSub = this.form.controls.alternative.controls.isCurrent.valueChanges.subscribe((isCurrent) => {
      if (!skipNextUpdate) {
        skipNextUpdate = true;
        this.form.controls.current.controls.isCurrent.setValue(!isCurrent);
      } else {
        skipNextUpdate = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.currentSub?.unsubscribe();
    this.alternativeSub?.unsubscribe();
  }
}
