import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { EducationCourseForCandidateListFragment } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { AppDatePipe } from "../../../../shared/pipes/date.pipe";
import { PipeDescription, TableColumn } from "../../../../shared/table/table.model";

const nameOf = nameofFactory<EducationCourseForCandidateListFragment>();

@Component({
  selector: "app-education-course-table",
  templateUrl: "./education-course-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class EducationCourseTableComponent {
  readonly columns = this.getColumns();

  @Input()
  courses: EducationCourseForCandidateListFragment[];

  private getColumns(): TableColumn[] {
    return [
      {
        header: translate("name.title"),
        fieldname: nameOf("name"),
        sortable: true,
        includeInGlobalFilter: true,
      },
      {
        header: translate("startDate.title"),
        fieldname: nameOf("startDate"),
        sortable: true,
        pipeDescription: new PipeDescription(AppDatePipe, { dateStyle: "short" }),
      },
      {
        header: translate("endDate.title"),
        fieldname: nameOf("endDate"),
        sortable: true,
        pipeDescription: new PipeDescription(AppDatePipe, { dateStyle: "short" }),
      },
    ];
  }
}
