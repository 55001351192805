<div class="flex flex-column app-form" *appTranslate="let t">
  <p-toolbar>
    <ng-template pTemplate="left">
      <p-dropdown
        rememberState
        stateKey="ankaadia_candidate_organization"
        [options]="organizations"
        [(ngModel)]="selectedOrganizationId"
        (onChange)="onOrganizationChange()"
        filterBy="name"
        optionLabel="name"
        optionValue="id"
        [showClear]="true"
        [placeholder]="t('organization.placeholder')"
        class="mr-2"
        [disabled]="topControlsDisabled"
      ></p-dropdown>
      <p-dropdown
        [options]="collections | orderBy: ['isFavorite', 'name'] : ['desc', 'asc']"
        [(ngModel)]="selectedCollectionId"
        (onChange)="onCollectionChange()"
        filterBy="name"
        optionLabel="name"
        optionValue="id"
        [showClear]="true"
        [placeholder]="t('collection.placeholder')"
        class="mr-2"
        [disabled]="topControlsDisabled"
      >
        <ng-template pTemplate="selectedItem" let-item>
          <span>{{ item.name }}</span>
          <ng-container *ngIf="selectedOrganizationId !== organizationId">
            <span *ngIf="item.organizationId === organizationId"> (->)</span>
            <span *ngIf="item.organizationId !== organizationId"> (<-)</span>
          </ng-container>
        </ng-template>

        <ng-template pTemplate="item" let-item>
          <div class="flex justify-content-between">
            <span>
              <span>{{ item.name }}</span>
              <ng-container *ngIf="selectedOrganizationId !== organizationId">
                <span *ngIf="item.organizationId === organizationId"> (->)</span>
                <span *ngIf="item.organizationId !== organizationId"> (<-)</span>
              </ng-container>
            </span>
            <i class="pi pi-star{{ item.isFavorite ? '-fill' : '' }} text-lg ml-1"></i>
          </div>
        </ng-template>
      </p-dropdown>
      <p-dropdown
        rememberState
        stateKey="ankaadia_candidate_filter_function"
        *ngIf="showFunctionsFilter"
        [options]="availableFunctions"
        [(ngModel)]="selectedFunction"
        (onChange)="selectedFunctionChange()"
        [showClear]="true"
        [placeholder]="t('function.placeholder')"
        class="mr-2"
        [disabled]="topControlsDisabled"
      >
      </p-dropdown>
      <app-favorite
        *ngIf="selectedFavorite"
        [(favorite)]="selectedFavorite"
        [collection]="{ collectionId: selectedCollectionId, organizationId: selectedCollectionOrganizationId }"
        (favoriteChange)="onSelectedCollectionFavoriteChange($event.isFavorite)"
        class="mr-2"
      ></app-favorite>
      <div>{{ t("candidate.profiles") }}: {{ totalCandidates ?? 0 }}</div>
    </ng-template>
    <ng-template pTemplate="right">
      <p-button
        [label]="t('common.new')"
        icon="pi pi-plus"
        class="p-button-success mr-2 mb-2"
        [disabled]="
          topControlsDisabled ||
          (!isLicensed && !selectedCollectionId) ||
          isReadOnlyCollaboration ||
          !isGeneralTabVisible ||
          isUneditableCollaboration ||
          !selectedCollectionTypeIsStandard
        "
        (onClick)="newClicked($event)"
        data-testid="btn-add-new"
      ></p-button>
      <p-button
        [label]="t('common.import')"
        icon="pi pi-upload"
        class="p-button-success mr-2 mb-2"
        [disabled]="
          !selectedCollectionId ||
          topControlsDisabled ||
          isReadOnlyCollaboration ||
          !isGeneralTabVisible ||
          isUneditableCollaboration ||
          !selectedCollectionTypeIsStandard
        "
        (onClick)="openImport()"
        data-testid="btn-import"
      >
      </p-button>
      <p-confirmPopup />

      <app-document-template-reports-button
        class="p-button-success mr-2 mb-2"
        [collectionId]="selectedCollectionId"
        [sharingId]="selectedSharing?.id"
        [sharingOrganizationId]="selectedSharing?.organizationId"
        [selectedOrganizationId]="selectedOrganizationId"
        [organizationId]="selectedCollectionOrganizationId"
        [customLazyLoadEvent]="customLazyLoadEvent"
      ></app-document-template-reports-button>
    </ng-template>
  </p-toolbar>
  <p-splitter
    class="app-form"
    styleClass="mb-5 app-form candidate-splitter"
    [panelSizes]="[20, 80]"
    [minSizes]="[10, 50]"
    stateKey="ankaadia_candidate_splitter"
  >
    <ng-template pTemplate>
      <app-document-dropzone
        poolId="candidate-list"
        class="h-full w-full"
        [candidateOrCollection]="{
          selectedCollectionId,
          selectedCollectionOrganizationId,
          selectedSharing,
          event: customLazyLoadEvent,
        }"
      >
        <app-candidate-selection
          class="w-full"
          [candidates]="candidates"
          [totalCandidates]="totalCandidates"
          [(isMultiEditActive)]="isMultiEditActive"
          (selectedCandidatesChange)="candidateSelectionChanged($event)"
          [selectedCandidateIds]="selectedCandidateIds$ | async"
          [candidatesPerPage]="candidatesPerPage"
          [deletionRequestsSet]="deletionRequestsSet"
          (loadCandidates)="updateCandidates($event)"
          (collectionCreate)="createCollectionWithSharing($event)"
        ></app-candidate-selection>
      </app-document-dropzone>
    </ng-template>
    <ng-template pTemplate>
      <app-candidate-edit
        class="w-full"
        [selectedCandidates]="selectedCandidates$ | async"
        [selectedCollectionId]="selectedCollectionId"
        [selectedOrganizationId]="selectedOrganizationId"
        [selectedCollectionOrganizationId]="selectedCollectionOrganizationId"
        [sharings]="sharings"
        [selectedSharing]="selectedSharing"
        [(isEditing)]="isEditing"
        [standardImmigrationCountry]="selectedStandardImmigrationCountry"
        (candidateAddedOrDeleted)="updateCandidates()"
        [(isMultiEditMode)]="isMultiEditActive"
        (isGeneralTabVisibleChange)="isGeneralTabVisible = $event"
        [candidates]="candidates"
        [isUneditableCollaboration]="isUneditableCollaboration"
        [isReadOnlyCollaboration]="isReadOnlyCollaboration"
      ></app-candidate-edit>
    </ng-template>
  </p-splitter>
</div>

<p-sidebar [(visible)]="showSidebar" styleClass="p-sidebar-md">
  <app-candidate-import
    *ngIf="showSidebar && selectedImport"
    [import]="selectedImport"
    (saved)="import($event)"
    (closed)="close()"
  ></app-candidate-import>
</p-sidebar>

<app-import-result-dialog
  *ngIf="xlsxImportResult"
  [xlsxImportResult]="xlsxImportResult"
  (closed)="xlsxImportResult = null"
>
</app-import-result-dialog>
