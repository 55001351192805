import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { VisaCandidateDataDeInput } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { DateTimeForm } from "../../../shared/date-time/date-time.component";

const nameOf = nameofFactory<VisaCandidateDataDeInput>();

@Component({
  selector: "app-candidate-flight-date",
  templateUrl: "./candidate-flight-date.component.html",
  standalone: false,
})
export class CandidateFlightDateComponent implements OnInit, OnDestroy {
  private readonly language = this.transloco.getActiveLang();

  @Input()
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  readonly: boolean;

  get control(): DateTimeForm | null {
    return this.form.get(nameOf("flightDate")) as DateTimeForm | null;
  }

  set control(value: DateTimeForm | null) {
    this.form.setControl(nameOf("flightDate"), value);
  }

  constructor(
    private readonly transloco: TranslocoService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    if (!this.control)
      this.control = this.formBuilder.group<DateTimeForm["controls"]>({
        date: this.formBuilder.control(null),
        hasTime: this.formBuilder.control(false),
      });
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("flightDate"), { emitEvent: false });
  }
}
