<ng-container *appTranslate="let t">
  <p-button (onClick)="openSidebar()" icon="pi pi-cog" [label]="t('documentRequirements.settings')"></p-button>
  <p-sidebar [visible]="showSidebar" styleClass="p-sidebar-md" *ngIf="form">
    <p-card [header]="t('documentRequirements.settings')">
      <p-fieldset [legend]="t('documentRequirements.mappingCaption')">
        <div class="p-fluid grid">
          <ng-container *ngFor="let mappingGroup of form.controls.documentFormatEqualityMapping.controls; index as i">
            <div class="field col-5">
              <label for="sourceDocumentFormat">{{ t("documentRequirements.sourceFormat") }}</label>
              <p-dropdown
                inputId="sourceDocumentFormat"
                [formControl]="mappingGroup.controls.sourceDocumentFormat"
                [options]="documentFormats"
              ></p-dropdown>
              <small class="p-error" *ngIf="mappingGroup.controls.sourceDocumentFormat.errors?.required">
                {{ t("field.required") }}
              </small>
            </div>
            <div class="field col-5">
              <label for="equalDocumentFormats">{{ t("documentRequirements.mappingFormat") }}</label>
              <p-multiSelect
                inputId="equalDocumentFormats"
                [formControl]="mappingGroup.controls.equalDocumentFormats"
                [options]="documentFormats"
              ></p-multiSelect>
              <small class="p-error" *ngIf="mappingGroup.controls.equalDocumentFormats.errors?.required">
                {{ t("field.required") }}
              </small>
            </div>
            <div class="field col-2">
              <div class="pt-5">
                <p-button class="" icon="pi pi-plus" (onClick)="addMapping(null)"></p-button>
                <p-button class="ml-2" icon="pi pi-minus" (onClick)="deleteMapping(mappingGroup)"></p-button>
              </div>
            </div>
          </ng-container>
          <div class="field col-12" *ngIf="form.errors?.documentFormatEqualityMappingEqual">
            <small class="p-error">
              {{ t("documentRequirements.multipleFormatsError") }}
            </small>
          </div>
        </div>
      </p-fieldset>
      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
          <p-button
            [disabled]="form.invalid"
            [label]="t('common.save')"
            icon="pi pi-check"
            (onClick)="submit()"
            class="mr-2"
          />
          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            type="reset"
            (onClick)="cancel()"
          />
        </div>
      </ng-template>
    </p-card>
  </p-sidebar>
</ng-container>
