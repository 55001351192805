<ng-container *appTranslate="let t">
  <p-card [header]="t('employerHistory.title')">
    <p-table #table [value]="items" editMode="row" dataKey="id">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ t("employer.title") }}</th>
          <th>{{ t("startDate.title") }}</th>
          <th>{{ t("endDate.title") }}</th>
          <th>{{ t("common.createdAt") }}</th>
          <th class="text-right">
            <p-button
              icon="pi pi-plus"
              styleClass="p-button-rounded p-button-text"
              [pTooltip]="t('common.add')"
              tooltipPosition="left"
              (onClick)="add()"
            ></p-button>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-item let-editing="editing">
        <tr [pEditableRow]="item">
          <td class="white-space-nowrap">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown
                  [options]="employers"
                  optionValue="id"
                  optionLabel="name"
                  [required]="true"
                  [noAutoFocus]="true"
                  [placeholder]="t('employer.placeholder')"
                  [(ngModel)]="item.employerId"
                  (onChange)="updateEmployerName(item, $event.value)"
                ></p-dropdown>

                <small class="p-error" *ngIf="!item.employerId">
                  {{ t("employer.required") }}
                </small>
              </ng-template>

              <ng-template pTemplate="output">
                {{ item.employerName }}
              </ng-template>
            </p-cellEditor>
          </td>

          <td class="white-space-nowrap">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-calendar [(ngModel)]="item.startDate"></p-calendar>

                <ng-container *ngTemplateOutlet="errorPadding; context: { $implicit: item }"></ng-container>
              </ng-template>

              <ng-template pTemplate="output">
                {{ item.startDate | appDateTime: { dateStyle: "short" } }}
              </ng-template>
            </p-cellEditor>
          </td>

          <td class="white-space-nowrap">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-calendar [minDate]="item.startDate" [(ngModel)]="item.endDate"></p-calendar>

                <ng-container *ngTemplateOutlet="errorPadding; context: { $implicit: item }"></ng-container>
              </ng-template>

              <ng-template pTemplate="output">
                {{ item.endDate | appDateTime: { dateStyle: "short" } }}
              </ng-template>
            </p-cellEditor>
          </td>

          <td class="white-space-nowrap">
            <div>
              {{ item.createdAt | appDateTime: { dateStyle: "short", timeStyle: "short" } }}
            </div>

            <ng-container *ngTemplateOutlet="errorPadding; context: { $implicit: item }"></ng-container>
          </td>

          <td class="text-right white-space-nowrap">
            <div>
              <!-- buttons instead of p-buttons because pWhateverEditableRow is so shitty -->
              <button
                *ngIf="!editing"
                pButton
                pInitEditableRow
                icon="pi pi-pencil"
                class="p-button-rounded p-button-text"
                [pTooltip]="t('common.edit')"
                tooltipPosition="left"
                (click)="init(item)"
              ></button>

              <button
                *ngIf="!editing"
                pButton
                icon="pi pi-trash"
                class="p-button-rounded p-button-text"
                [pTooltip]="t('common.delete')"
                tooltipPosition="left"
                (click)="delete(item)"
              ></button>

              <button
                *ngIf="editing"
                pButton
                pSaveEditableRow
                [disabled]="!item.employerId"
                icon="pi pi-check"
                class="p-button-rounded p-button-text"
                [pTooltip]="t('common.ok')"
                tooltipPosition="left"
                (click)="save(item)"
              ></button>

              <button
                *ngIf="editing"
                pButton
                pCancelEditableRow
                icon="pi pi-times"
                class="p-button-rounded p-button-text"
                [pTooltip]="t('common.cancel')"
                tooltipPosition="left"
                (click)="cancel(item)"
              ></button>
            </div>

            <ng-container *ngTemplateOutlet="errorPadding; context: { $implicit: item }"></ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <ng-template pTemplate="footer">
      <div class="flex flex-row justify-content-end gap-2">
        <p-button [label]="t('common.save')" icon="pi pi-check" (onClick)="submit()"></p-button>

        <p-button closeOnEscape [label]="t('common.cancel')" icon="pi pi-times" (onClick)="closed.emit()"></p-button>
      </div>
    </ng-template>
  </p-card>
</ng-container>

<ng-template #errorPadding let-item>
  <small class="p-error" *ngIf="!item.employerId">&nbsp;</small>
</ng-template>
