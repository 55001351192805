export const StaticDataTypeDocumentDescriptionValues = [
  "AGREEMENTGUETEZEICHEN",
  "DECISIONADAPTIONCOURSE",
  "COSTABSORPTIONVALUATION",
  "POWEROFATTORNEYVALUATION",
  "SUPPLEMENTARYSHEETC",
  "REGISTRATIONCERTIFICATETRAINING",
  "CURRICULUMFRAMEWORK",
  "DECLARATIONEXEMPTIONMEASURE",
  "APPLICATIONRESIDENCEAUTHORITY",
  "APPLICATIONHEALTHINCURANCE",
  "DECLARATIONCANCELLATIONJOBTITLE",
  "ANNEXSUPPORTQUALIFICATIONEMPLOYEE",
  "APPLICATIONPAYMENTPROTECTION",
  "DECLARATIONPAYMENTPROTECTION",
  "CERTIFICATEEMPLOYERSUPPORTEMPLOYEES",
  "QUESTIONAREEDUCATIONEMPLOYEES",
  "CONFIRMATIONNOMATCHING",
  "PROTOKOLLFINALADAPTATIONMEETING",
  "PROTOKOLLPRAXISGUIDE",
  "DOKUMENTATIONINTERVIEW",
  "GUARANTEEWORKOFFER",
  "FIRSTCONTRACTEXTENSIONPRE",
  "SECONDCONTRACTEXTENSIONPRE",
  "FIRSTCONTRACTEXTENSIONAFTER",
  "SECONDCONTRACTEXTENSIONAFTER",
  "AGREEMENTSPONSORSHIP",
  "RECOGNITIONSPONSORSHIPCANDIDATE",
  "RECOGNITIONSPONSORSHIPEMPLOYER",
  "SERVICEAGREEMENT",
  "COMPANYAGREEMENT",
  "COLLECTIVEAGREEMENT",
  "INFOEMPLOYER",
  "WRITTENWORKOFFER",
  "POWEROFATTORNEYRELOCATION",
  "PROOFOFENTRY",
  "POWEROFATORNEYWORKRECOGNITION",
  "CONSTITUTIONSCIENTOLOGY",
  "CONSTITUTIONEXPLANATION",
  "CONSTITUTIONINSTRUCTION",
  "APPLICATIONFORMKNOWLEDGEEXAM",
  "APPLICATIONLANGUAGEEXAM",
  "ACCEPTDOCGB",
  "SIZETABLE",
  "PROVEOFPAYRECOGNITIONJOB",
  "POACANDIDATE",
  "CONSENTBENEFIT",
  "ACCEPTOFHOUSING",
  "TAXNOLETTER",
  "CERTIFICATEFURTHERTRAINING",
  "INTERNCONTRACT",
  "APPLICATIONCERTGOODCONDUCT",
  "FORMHEALTHCERTIFICATE",
  "CONFIRMATIONFINALTALK",
  "NOTICEEQUIVALENCE",
  "DRIVINGLICENSE",
  "LINGUSTIKAUDIT",
  "DIPLOMAMASTER",
  "DIPLOMAENGINEER",
  "LISTOFGRADESENGINEER",
  "CERTOFSTUDY",
  "TRAININGPLAN",
  "TRAININGCONTRACT",
  "TRAININGCOMMITMENT",
  "GDPRMED",
  "SIGNATURE",
  "INTERNCERT",
  "CURRICULUMTRANSLATED",
  "WORKVISA",
  "CURRICULUM",
  "THESIS",
  "TOCTHESIS",
  "DIPLOMTRAINER",
  "MEDCET",
  "WORKCONF",
  "PRIVUNIRECOG",
  "ANABINDB",
  "SSCARD",
  "APPVALEDU",
  "CVSIGNED",
  "CVAPP",
  "CV",
  "PASSPORT",
  "BIRTHCERT",
  "MARRIAGECERT",
  "DEEDOFDIVORCE",
  "EXTRACTFAMILIYBOOK",
  "DIPLOMA",
  "LISTOFGRADESMASTER",
  "LISTOFGRADES",
  "LISTOFGRADESFURTHEREDU",
  "DIPLOMATRAINEE",
  "LISTOFGRADESTRAINEE",
  "DIPLOMASCHOOL",
  "PROOFOFINTERNSHIP",
  "DIPLOMANURSING",
  "NURSINGLICENSE",
  "WORKCERT",
  "LANGCERT",
  "STATEEXAMINATION",
  "POAMAIN81A",
  "POASUB81A",
  "APPFORM",
  "RECDOC",
  "DEFICITNOTE",
  "PREAPPROVAL",
  "PREVRECOC",
  "VISASTATEMENT",
  "APPPERMPRO",
  "DECINENT",
  "QUALPLAN",
  "DECOFEMPLY",
  "DECOFEMPLYBEFORE",
  "DECOFEMPLYAFTER",
  "SUPPSHEETA",
  "CONTRACTLANG",
  "POLICERECORD",
  "POLICERECORDENHANCED",
  "POLICERECORDIMMIGRATION",
  "POLICERECORDENHANCEDIMMIGRATION",
  "CLEARANCECERT",
  "CERTOFHEALTH",
  "CERTOFVACC",
  "CERTOFHOUSING",
  "PRECONTRACT",
  "CONTRACTWORK",
  "RETBEN",
  "SUBEXAM",
  "INSTRUC",
  "CERTOFEDU",
  "APPSTATE",
  "REGCERT",
  "VISA",
  "NOTARIZATIONPHRAE",
  "TRANSLATIONSET",
  "OTHER1",
  "OTHER2",
  "OTHER3",
  "OTHER4",
  "OTHER5",
  "OTHER6",
  "OTHER7",
  "OTHER8",
  "OTHER9",
  "DECIMPUNITY",
  "EXTRACTCRIMRECORD",
  "PROOFHEALTHINSUR",
  "PASSPICTURE",
  "IDENTITYCARD",
  "FULLBIRTHCERT",
  "AVAILABILITY",
  "CONFOQUAL",
  "MOTIVATIONLETTER",
  "CONFOLANGSCHOOL",
  "APPRESIDENCEPERMIT",
  "AGENCYAGREEMENT",
  "POAFAMILIY81A",
  "POAPARTNER81A",
  "POACHILDREN81A",
  "RESIDENCEPERMIT",
  "PERMRESIDENCEPERMIT",
  "FICTIONALCERT",
  "PERMRESIDENCEPERMITEU",
  "NATASSURANCE",
  "NATCERT",
  "DOCCERT",
  "SUBJECTGRADE",
  "CONFIMMIGRAGENCY",
  "CONFACCEPTAGENCY",
  "CONFEMPLOYAGENCY",
  "CONFVISA",
  "ADDREQMIGRAGENCY",
  "ADDREQACCPTAGENCY",
  "ADDREQBA",
  "ADDREQVISA",
  "ADDREQCERTAB",
  "CONFFULLMIGRAGENCY",
  "CONFFULLACCEPTAGENCY",
  "FEESACCMIGRATION",
  "COSTWORKPERMIT",
  "BILLVISAFEE",
  "APPOINTVISA",
  "APPOINTMIGRAGENCY",
  "ADDPAPERA",
  "AGGREEACC81A",
  "AUTHACCEPTAGENCY",
  "WAIVERFORGOREF",
  "PROMWORKPERM",
  "CERTZAB",
  "CERTLAB",
  "CONFIRMCERTZAB",
  "FEESCERTZAB",
  "FORMFINSUPPCERTZAB",
  "CONFIRMCFINSUPPCERTZAB",
  "ACCEPTDOC",
  "FLYBOOKING",
  "DATAPRIVAGENCY",
  "LANGCERTGERA1",
  "LANGCERTGERA2",
  "LANGCERTGERB1",
  "LANGCERTGERB2",
  "LANGCERTGERC1",
  "FURTHERTRAINWORKER",
  "CONFOLANGSCHOOLGER",
  "CONFOTECHLANGEXAMGER",
  "PROOFHEALTHINSUREMPLOY",
  "PHOTO",
  "TECHLANGEXAMGERB1",
  "TECHLANGEXAMGERB2",
  "TECHLANGEXAMGERC1",
  "GENERALPOA",
  "REJECTREC",
  "REJECTVISA",
  "REJECTWORK",
  "REJECTCERTAB",
  "ADOPTIONCERT",
  "MEDCERT",
  "TECHSCHOOLCERT",
  "DECLOFCOSTACCREC",
  "MEALALLOW",
  "HEALTHMEMBERCERT",
  "COVERLETTERPROFRECOG",
  "COVERLETTERPROFACCEL",
  "RECEIPTVISA",
  "RECEIPTLANGUAGETRAINING",
  "PHOTOPERMISSION",
  "PERSONALDATASHEET",
  "OPERATINGPRINCIPLES",
  "NOTICEOFSELECTION",
  "ILLEGALSTREAMING",
  "LIABILITYINSURANCE",
  "WORKINGCOND",
  "WARDALLOWANCE",
  "WAIVEROFEQUITEST",
  "COVERLETTERLANG,",
  "EDUCATIONMEASURECONTRACT",
  "RENTALAGREEMENT",
  "OVERVIEWINTERVIEW",
  "CONFIRMATIONRENUMERATION",
  "DECLARATIONOFCORRESPONDENCE",
  "TRAVELINSURANCE",
  "AFFIDAVIT",
  "ENROLLMENTQUALIFICATION",
  "DECLARATIONCUSTODY",
  "APPLICATIONACCELERATEDRECOG",
  "CANCELLATIONEMPLOYMENTCONTRACT",
  "CANCELLATIONTRAININGCONTRACT",
  "CERTIFICATEOFRESIDENCE",
] as const;
export type StaticDataTypeDocumentDescription = (typeof StaticDataTypeDocumentDescriptionValues)[number];
export function isStaticDataTypeDocumentDescription(value: any): value is StaticDataTypeDocumentDescription {
  return StaticDataTypeDocumentDescriptionValues.includes(value);
}
export const StaticDataTypeFileType2FileNameMappingValues = [
  "AGREEMENTGUETEZEICHEN",
  "DECISIONADAPTIONCOURSE",
  "COSTABSORPTIONVALUATION",
  "POWEROFATTORNEYVALUATION",
  "SUPPLEMENTARYSHEETC",
  "REGISTRATIONCERTIFICATETRAINING",
  "CURRICULUMFRAMEWORK",
  "DECLARATIONEXEMPTIONMEASURE",
  "APPLICATIONRESIDENCEAUTHORITY",
  "APPLICATIONHEALTHINCURANCE",
  "DECLARATIONCANCELLATIONJOBTITLE",
  "ANNEXSUPPORTQUALIFICATIONEMPLOYEE",
  "APPLICATIONPAYMENTPROTECTION",
  "DECLARATIONPAYMENTPROTECTION",
  "CERTIFICATEEMPLOYERSUPPORTEMPLOYEES",
  "QUESTIONAREEDUCATIONEMPLOYEES",
  "CONFIRMATIONNOMATCHING",
  "PROTOKOLLFINALADAPTATIONMEETING",
  "PROTOKOLLPRAXISGUIDE",
  "DOKUMENTATIONINTERVIEW",
  "GUARANTEEWORKOFFER",
  "FIRSTCONTRACTEXTENSIONPRE",
  "SECONDCONTRACTEXTENSIONPRE",
  "FIRSTCONTRACTEXTENSIONAFTER",
  "SECONDCONTRACTEXTENSIONAFTER",
  "AGREEMENTSPONSORSHIP",
  "RECOGNITIONSPONSORSHIPCANDIDATE",
  "RECOGNITIONSPONSORSHIPEMPLOYER",
  "SERVICEAGREEMENT",
  "COMPANYAGREEMENT",
  "COLLECTIVEAGREEMENT",
  "INFOEMPLOYER",
  "WRITTENWORKOFFER",
  "POWEROFATTORNEYRELOCATION",
  "PROOFOFENTRY",
  "POWEROFATORNEYWORKRECOGNITION",
  "CONSTITUTIONSCIENTOLOGY",
  "CONSTITUTIONEXPLANATION",
  "CONSTITUTIONINSTRUCTION",
  "APPLICATIONFORMKNOWLEDGEEXAM",
  "APPLICATIONLANGUAGEEXAM",
  "ACCEPTDOCGB",
  "SIZETABLE",
  "PROVEOFPAYRECOGNITIONJOB",
  "POACANDIDATE",
  "CONSENTBENEFIT",
  "ACCEPTOFHOUSING",
  "CERTIFICATEFURTHERTRAINING",
  "INTERNCONTRACT",
  "APPLICATIONCERTGOODCONDUCT",
  "FORMHEALTHCERTIFICATE",
  "CONFIRMATIONFINALTALK",
  "TAXNOLETTER",
  "NOTICEEQUIVALENCE",
  "DRIVINGLICENSE",
  "LINGUSTIKAUDIT",
  "DIPLOMAMASTER",
  "DIPLOMAENGINEER",
  "LISTOFGRADESENGINEER",
  "CERTOFSTUDY",
  "TRAININGPLAN",
  "TRAININGCONTRACT",
  "TRAININGCOMMITMENT",
  "GDPRMED",
  "SIGNATURE",
  "INTERNCERT",
  "CURRICULUMTRANSLATED",
  "WORKVISA",
  "CURRICULUM",
  "THESIS",
  "TOCTHESIS",
  "DIPLOMTRAINER",
  "MEDCET",
  "WORKCONF",
  "PRIVUNIRECOG",
  "ANABINDB",
  "SSCARD",
  "APPVALEDU",
  "CVSIGNED",
  "CVAPP",
  "CV",
  "PASSPORT",
  "BIRTHCERT",
  "MARRIAGECERT",
  "DEEDOFDIVORCE",
  "EXTRACTFAMILIYBOOK",
  "DIPLOMA",
  "LISTOFGRADES",
  "LISTOFGRADESMASTER",
  "LISTOFGRADESFURTHEREDU",
  "DIPLOMATRAINEE",
  "LISTOFGRADESTRAINEE",
  "DIPLOMASCHOOL",
  "PROOFOFINTERNSHIP",
  "DIPLOMANURSING",
  "NURSINGLICENSE",
  "WORKCERT",
  "LANGCERT",
  "STATEEXAMINATION",
  "POAMAIN81A",
  "POASUB81A",
  "APPFORM",
  "RECDOC",
  "DEFICITNOTE",
  "PREAPPROVAL",
  "PREVRECOC",
  "VISASTATEMENT",
  "APPPERMPRO",
  "DECINENT",
  "QUALPLAN",
  "DECOFEMPLY",
  "DECOFEMPLYBEFORE",
  "DECOFEMPLYAFTER",
  "SUPPSHEETA",
  "CONTRACTLANG",
  "POLICERECORD",
  "POLICERECORDENHANCED",
  "POLICERECORDIMMIGRATION",
  "POLICERECORDENHANCEDIMMIGRATION",
  "CLEARANCECERT",
  "CERTOFHEALTH",
  "CERTOFVACC",
  "CERTOFHOUSING",
  "PRECONTRACT",
  "CONTRACTWORK",
  "RETBEN",
  "SUBEXAM",
  "INSTRUC",
  "CERTOFEDU",
  "APPSTATE",
  "REGCERT",
  "VISA",
  "NOTARIZATIONPHRAE",
  "TRANSLATIONSET",
  "OTHER1",
  "OTHER2",
  "OTHER3",
  "OTHER4",
  "OTHER5",
  "OTHER6",
  "OTHER7",
  "OTHER8",
  "OTHER9",
  "OTHER10",
  "OTHER11",
  "OTHER12",
  "OTHER13",
  "OTHER14",
  "OTHER15",
  "OTHER16",
  "OTHER17",
  "OTHER18",
  "OTHER19",
  "OTHER20",
  "DECIMPUNITY",
  "EXTRACTCRIMRECORD",
  "PROOFHEALTHINSUR",
  "PASSPICTURE",
  "IDENTITYCARD",
  "FULLBIRTHCERT",
  "AVAILABILITY",
  "CONFOQUAL",
  "MOTIVATIONLETTER",
  "CONFOLANGSCHOOL",
  "APPRESIDENCEPERMIT",
  "AGENCYAGREEMENT",
  "POAFAMILIY81A",
  "POAPARTNER81A",
  "POACHILDREN81A",
  "RESIDENCEPERMIT",
  "PERMRESIDENCEPERMIT",
  "FICTIONALCERT",
  "PERMRESIDENCEPERMITEU",
  "NATASSURANCE",
  "NATCERT",
  "DOCCERT",
  "SUBJECTGRADE",
  "CONFIMMIGRAGENCY",
  "CONFACCEPTAGENCY",
  "CONFEMPLOYAGENCY",
  "CONFVISA",
  "ADDREQMIGRAGENCY",
  "ADDREQACCPTAGENCY",
  "ADDREQBA",
  "ADDREQVISA",
  "ADDREQCERTAB",
  "CONFFULLMIGRAGENCY",
  "CONFFULLACCEPTAGENCY",
  "FEESACCMIGRATION",
  "COSTWORKPERMIT",
  "BILLVISAFEE",
  "APPOINTVISA",
  "APPOINTMIGRAGENCY",
  "ADDPAPERA",
  "AGGREEACC81A",
  "AUTHACCEPTAGENCY",
  "WAIVERFORGOREF",
  "PROMWORKPERM",
  "CERTZAB",
  "CERTLAB",
  "CONFIRMCERTZAB",
  "FEESCERTZAB",
  "FORMFINSUPPCERTZAB",
  "CONFIRMCFINSUPPCERTZAB",
  "ACCEPTDOC",
  "FLYBOOKING",
  "DATAPRIVAGENCY",
  "LANGCERTGERA1",
  "LANGCERTGERA2",
  "LANGCERTGERB1",
  "LANGCERTGERB2",
  "LANGCERTGERC1",
  "FURTHERTRAINWORKER",
  "CONFOLANGSCHOOLGER",
  "CONFOTECHLANGEXAMGER",
  "PROOFHEALTHINSUREMPLOY",
  "PHOTO",
  "TECHLANGEXAMGERB1",
  "TECHLANGEXAMGERB2",
  "TECHLANGEXAMGERC1",
  "GENERALPOA",
  "REJECTREC",
  "REJECTVISA",
  "REJECTWORK",
  "REJECTCERTAB",
  "ADOPTIONCERT",
  "MEDCERT",
  "TECHSCHOOLCERT",
  "DECLOFCOSTACCREC",
  "MEALALLOW",
  "HEALTHMEMBERCERT",
  "RESIDENCEPERMIT18",
  "SUBSISTENCEPROOF",
  "COVERLETTERPROFRECOG",
  "COVERLETTERPROFACCEL",
  "RECEIPTVISA",
  "RECEIPTLANGUAGETRAINING",
  "PHOTOPERMISSION",
  "PERSONALDATASHEET",
  "OPERATINGPRINCIPLES",
  "NOTICEOFSELECTION",
  "ILLEGALSTREAMING",
  "LIABILITYINSURANCE",
  "WORKINGCOND",
  "WARDALLOWANCE",
  "WAIVEROFEQUITEST",
  "COVERLETTERLANG",
  "EDUCATIONMEASURECONTRACT",
  "RENTALAGREEMENT",
  "OVERVIEWINTERVIEW",
  "CONFIRMATIONRENUMERATION",
  "DECLARATIONOFCORRESPONDENCE",
  "TRAVELINSURANCE",
  "AFFIDAVIT",
  "ENROLLMENTQUALIFICATION",
  "DECLARATIONCUSTODY",
  "APPLICATIONACCELERATEDRECOG",
  "CANCELLATIONEMPLOYMENTCONTRACT",
  "CANCELLATIONTRAININGCONTRACT",
  "CERTIFICATEOFRESIDENCE",
] as const;
export type StaticDataTypeFileType2FileNameMapping = (typeof StaticDataTypeFileType2FileNameMappingValues)[number];
export function isStaticDataTypeFileType2FileNameMapping(value: any): value is StaticDataTypeFileType2FileNameMapping {
  return StaticDataTypeFileType2FileNameMappingValues.includes(value);
}
export const StaticDataTypeAllowedUploadFileTypesValues = [
  "AGREEMENTGUETEZEICHEN",
  "DECISIONADAPTIONCOURSE",
  "COSTABSORPTIONVALUATION",
  "POWEROFATTORNEYVALUATION",
  "SUPPLEMENTARYSHEETC",
  "REGISTRATIONCERTIFICATETRAINING",
  "CURRICULUMFRAMEWORK",
  "DECLARATIONEXEMPTIONMEASURE",
  "APPLICATIONRESIDENCEAUTHORITY",
  "APPLICATIONHEALTHINCURANCE",
  "DECLARATIONCANCELLATIONJOBTITLE",
  "ANNEXSUPPORTQUALIFICATIONEMPLOYEE",
  "APPLICATIONPAYMENTPROTECTION",
  "DECLARATIONPAYMENTPROTECTION",
  "CERTIFICATEEMPLOYERSUPPORTEMPLOYEES",
  "QUESTIONAREEDUCATIONEMPLOYEES",
  "CONFIRMATIONNOMATCHING",
  "PROTOKOLLFINALADAPTATIONMEETING",
  "PROTOKOLLPRAXISGUIDE",
  "DOKUMENTATIONINTERVIEW",
  "GUARANTEEWORKOFFER",
  "FIRSTCONTRACTEXTENSIONPRE",
  "SECONDCONTRACTEXTENSIONPRE",
  "FIRSTCONTRACTEXTENSIONAFTER",
  "SECONDCONTRACTEXTENSIONAFTER",
  "AGREEMENTSPONSORSHIP",
  "RECOGNITIONSPONSORSHIPCANDIDATE",
  "RECOGNITIONSPONSORSHIPEMPLOYER",
  "SERVICEAGREEMENT",
  "COMPANYAGREEMENT",
  "COLLECTIVEAGREEMENT",
  "INFOEMPLOYER",
  "WRITTENWORKOFFER",
  "POWEROFATTORNEYRELOCATION",
  "PROOFOFENTRY",
  "POWEROFATORNEYWORKRECOGNITION",
  "CONSTITUTIONSCIENTOLOGY",
  "CONSTITUTIONEXPLANATION",
  "CONSTITUTIONINSTRUCTION",
  "APPLICATIONFORMKNOWLEDGEEXAM",
  "APPLICATIONLANGUAGEEXAM",
  "ACCEPTDOCGB",
  "SIZETABLE",
  "PROVEOFPAYRECOGNITIONJOB",
  "POACANDIDATE",
  "CONSENTBENEFIT",
  "ACCEPTOFHOUSING",
  "CERTIFICATEFURTHERTRAINING",
  "INTERNCONTRACT",
  "APPLICATIONCERTGOODCONDUCT",
  "FORMHEALTHCERTIFICATE",
  "CONFIRMATIONFINALTALK",
  "TAXNOLETTER",
  "NOTICEEQUIVALENCE",
  "DRIVINGLICENSE",
  "LINGUSTIKAUDIT",
  "DIPLOMAMASTER",
  "DIPLOMAENGINEER",
  "LISTOFGRADESENGINEER",
  "CERTOFSTUDY",
  "TRAININGCONTRACT",
  "TRAININGCOMMITMENT",
  "TRAININGPLAN",
  "GDPRMED",
  "SIGNATURE",
  "INTERNCERT",
  "CURRICULUMTRANSLATED",
  "WORKVISA",
  "CURRICULUM",
  "THESIS",
  "TOCTHESIS",
  "DIPLOMTRAINER",
  "MEDCET",
  "WORKCONF",
  "PRIVUNIRECOG",
  "ANABINDB",
  "SSCARD",
  "APPVALEDU",
  "CVSIGNED",
  "CVAPP",
  "CV",
  "PASSPORT",
  "BIRTHCERT",
  "MARRIAGECERT",
  "DEEDOFDIVORCE",
  "EXTRACTFAMILIYBOOK",
  "DIPLOMA",
  "LISTOFGRADES",
  "LISTOFGRADESMASTER",
  "LISTOFGRADESFURTHEREDU",
  "DIPLOMATRAINEE",
  "LISTOFGRADESTRAINEE",
  "DIPLOMASCHOOL",
  "PROOFOFINTERNSHIP",
  "DIPLOMANURSING",
  "NURSINGLICENSE",
  "WORKCERT",
  "LANGCERT",
  "STATEEXAMINATION",
  "POAMAIN81A",
  "POASUB81A",
  "APPFORM",
  "RECDOC",
  "DEFICITNOTE",
  "PREAPPROVAL",
  "PREVRECOC",
  "VISASTATEMENT",
  "APPPERMPRO",
  "DECINENT",
  "QUALPLAN",
  "EDUCATIONVOUCHER",
  "DECOFEMPLY",
  "DECOFEMPLYBEFORE",
  "DECOFEMPLYAFTER",
  "SUPPSHEETA",
  "CONTRACTLANG",
  "POLICERECORD",
  "POLICERECORDENHANCED",
  "POLICERECORDIMMIGRATION",
  "POLICERECORDENHANCEDIMMIGRATION",
  "CLEARANCECERT",
  "CERTOFHEALTH",
  "CERTOFVACC",
  "CERTOFHOUSING",
  "PRECONTRACT",
  "CONTRACTWORK",
  "RETBEN",
  "SUBEXAM",
  "INSTRUC",
  "CERTOFEDU",
  "APPSTATE",
  "REGCERT",
  "VISA",
  "NOTARIZATIONPHRAE",
  "TRANSLATIONSET",
  "OTHER1",
  "OTHER2",
  "OTHER3",
  "OTHER4",
  "OTHER5",
  "OTHER6",
  "OTHER7",
  "OTHER8",
  "OTHER9",
  "OTHER10",
  "OTHER11",
  "OTHER12",
  "OTHER13",
  "OTHER14",
  "OTHER15",
  "OTHER16",
  "OTHER17",
  "OTHER18",
  "OTHER19",
  "OTHER20",
  "OTHER21",
  "OTHER22",
  "OTHER23",
  "OTHER24",
  "OTHER25",
  "OTHER26",
  "OTHER27",
  "OTHER28",
  "OTHER29",
  "OTHER30",
  "OTHER31",
  "OTHER32",
  "OTHER33",
  "OTHER34",
  "OTHER35",
  "OTHER36",
  "OTHER37",
  "OTHER38",
  "OTHER39",
  "OTHER40",
  "DECIMPUNITY",
  "EXTRACTCRIMRECORD",
  "PROOFHEALTHINSUR",
  "PASSPICTURE",
  "IDENTITYCARD",
  "FULLBIRTHCERT",
  "AVAILABILITY",
  "CONFOQUAL",
  "MOTIVATIONLETTER",
  "CONFOLANGSCHOOL",
  "APPRESIDENCEPERMIT",
  "AGENCYAGREEMENT",
  "POAFAMILIY81A",
  "POAPARTNER81A",
  "POACHILDREN81A",
  "RESIDENCEPERMIT",
  "PERMRESIDENCEPERMIT",
  "FICTIONALCERT",
  "PERMRESIDENCEPERMITEU",
  "NATASSURANCE",
  "NATCERT",
  "DOCCERT",
  "SUBJECTGRADE",
  "CONFIMMIGRAGENCY",
  "CONFACCEPTAGENCY",
  "CONFEMPLOYAGENCY",
  "CONFVISA",
  "ADDREQMIGRAGENCY",
  "ADDREQACCPTAGENCY",
  "ADDREQBA",
  "ADDREQVISA",
  "ADDREQCERTAB",
  "CONFFULLMIGRAGENCY",
  "CONFFULLACCEPTAGENCY",
  "FEESACCMIGRATION",
  "COSTWORKPERMIT",
  "BILLVISAFEE",
  "APPOINTVISA",
  "APPOINTMIGRAGENCY",
  "ADDPAPERA",
  "AGGREEACC81A",
  "AUTHACCEPTAGENCY",
  "WAIVERFORGOREF",
  "PROMWORKPERM",
  "CERTZAB",
  "CERTLAB",
  "CONFIRMCERTZAB",
  "FEESCERTZAB",
  "FORMFINSUPPCERTZAB",
  "CONFIRMCFINSUPPCERTZAB",
  "ACCEPTDOC",
  "FLYBOOKING",
  "DATAPRIVAGENCY",
  "DSGVO",
  "LANGCERTGERA1",
  "LANGCERTGERA2",
  "LANGCERTGERB1",
  "LANGCERTGERB2",
  "LANGCERTGERC1",
  "LANGCERTGERC2",
  "FURTHERTRAINWORKER",
  "CONFOLANGSCHOOLGER",
  "CONFOTECHLANGEXAMGER",
  "PROOFHEALTHINSUREMPLOY",
  "PHOTO",
  "TECHLANGEXAMGERB1",
  "TECHLANGEXAMGERB2",
  "TECHLANGEXAMGERC1",
  "GENERALPOA",
  "REJECTREC",
  "REJECTVISA",
  "REJECTWORK",
  "REJECTCERTAB",
  "ADOPTIONCERT",
  "MEDCERT",
  "TECHSCHOOLCERT",
  "DECLOFCOSTACCREC",
  "MEALALLOW",
  "HEALTHMEMBERCERT",
  "RESIDENCEPERMIT18",
  "SUBSISTENCEPROOF",
  "COVERLETTERPROFRECOG",
  "COVERLETTERPROFACCEL",
  "RECEIPTVISA",
  "RECEIPTLANGUAGETRAINING",
  "PHOTOPERMISSION",
  "PERSONALDATASHEET",
  "OPERATINGPRINCIPLES",
  "NOTICEOFSELECTION",
  "ILLEGALSTREAMING",
  "LIABILITYINSURANCE",
  "WORKINGCOND",
  "WARDALLOWANCE",
  "WAIVEROFEQUITEST",
  "COVERLETTERLANG",
  "EDUCATIONMEASURECONTRACT",
  "RENTALAGREEMENT",
  "OVERVIEWINTERVIEW",
  "CONFIRMATIONRENUMERATION",
  "DECLARATIONOFCORRESPONDENCE",
  "TRAVELINSURANCE",
  "AFFIDAVIT",
  "ENROLLMENTQUALIFICATION",
  "DECLARATIONCUSTODY",
  "APPLICATIONACCELERATEDRECOG",
  "CANCELLATIONEMPLOYMENTCONTRACT",
  "CANCELLATIONTRAININGCONTRACT",
  "CERTIFICATEOFRESIDENCE",
] as const;
export type StaticDataTypeAllowedUploadFileTypes = (typeof StaticDataTypeAllowedUploadFileTypesValues)[number];
export function isStaticDataTypeAllowedUploadFileTypes(value: any): value is StaticDataTypeAllowedUploadFileTypes {
  return StaticDataTypeAllowedUploadFileTypesValues.includes(value);
}
export const StaticDataTypeProcessRoleValues = [
  "Candidate",
  "Employer",
  "LocalPartner",
  "LanguageSchool",
  "PSA",
  "CountryManager",
  "StatusReader",
  "EducationProviderRole",
  "IntegrationProviderRole",
  "RelocationProviderRole",
  "CandidateManagerRole",
  "ProjectManagerRole",
  "HRDepartmentRole",
  "SupervisorRole",
] as const;
export type StaticDataTypeProcessRole = (typeof StaticDataTypeProcessRoleValues)[number];
export function isStaticDataTypeProcessRole(value: any): value is StaticDataTypeProcessRole {
  return StaticDataTypeProcessRoleValues.includes(value);
}
export const StaticDataTypeMartialStatusValues = [
  "SINGLE",
  "ENGAGED",
  "MARRIED",
  "MARRIEDSEPERATED",
  "DIVORCED",
  "WIDOWED",
  "MARRIAGECANCELLED",
  "CIVILPARTNERSHIPCANCELLED",
  "CIVILPARTNERSHIPCANCELLEDBYDEAD",
  "REGISTERED",
  "REGISTEREDSEPERATED",
  "REGISTEREDDEAD",
  "UNKNOWN",
] as const;
export type StaticDataTypeMartialStatus = (typeof StaticDataTypeMartialStatusValues)[number];
export function isStaticDataTypeMartialStatus(value: any): value is StaticDataTypeMartialStatus {
  return StaticDataTypeMartialStatusValues.includes(value);
}
export const StaticDataTypeGenderValues = ["FEMALE", "MALE", "OTHER"] as const;
export type StaticDataTypeGender = (typeof StaticDataTypeGenderValues)[number];
export function isStaticDataTypeGender(value: any): value is StaticDataTypeGender {
  return StaticDataTypeGenderValues.includes(value);
}
export const StaticDataTypeSalutationValues = ["MR", "MRS", "MX"] as const;
export type StaticDataTypeSalutation = (typeof StaticDataTypeSalutationValues)[number];
export function isStaticDataTypeSalutation(value: any): value is StaticDataTypeSalutation {
  return StaticDataTypeSalutationValues.includes(value);
}
export const StaticDataTypeTitleValues = [
  "DR",
  "MD",
  "DMD",
  "DVM",
  "DRP",
  "QE",
  "PHD",
  "MA",
  "MS",
  "BA",
  "BS",
  "MG",
  "PROFDR",
] as const;
export type StaticDataTypeTitle = (typeof StaticDataTypeTitleValues)[number];
export function isStaticDataTypeTitle(value: any): value is StaticDataTypeTitle {
  return StaticDataTypeTitleValues.includes(value);
}
export const StaticDataTypeCountriesValues = [
  "AF",
  "AX",
  "AL",
  "DZ",
  "AS",
  "AD",
  "AO",
  "AI",
  "AG",
  "AR",
  "AM",
  "AW",
  "AU",
  "AT",
  "AZ",
  "BS",
  "BH",
  "BD",
  "BB",
  "BY",
  "BE",
  "BZ",
  "BJ",
  "BM",
  "BT",
  "BO",
  "BA",
  "BW",
  "BR",
  "BN",
  "BG",
  "BF",
  "BI",
  "KH",
  "CM",
  "CA",
  "CV",
  "KY",
  "XK",
  "CF",
  "TD",
  "CL",
  "CN",
  "CO",
  "KM",
  "CG",
  "CD",
  "CK",
  "CR",
  "CI",
  "HR",
  "CU",
  "CY",
  "CZ",
  "DK",
  "DJ",
  "DM",
  "DO",
  "EC",
  "EG",
  "SV",
  "GQ",
  "ER",
  "EE",
  "ET",
  "FK",
  "FO",
  "FJ",
  "FI",
  "FR",
  "GF",
  "PF",
  "GA",
  "GM",
  "GE",
  "DE",
  "GH",
  "GI",
  "GR",
  "GL",
  "GD",
  "GP",
  "GT",
  "GN",
  "GW",
  "GY",
  "HT",
  "VA",
  "HN",
  "HK",
  "HU",
  "IS",
  "IN",
  "ID",
  "IR",
  "IQ",
  "IE",
  "IM",
  "IL",
  "IT",
  "JM",
  "JP",
  "JO",
  "KZ",
  "KE",
  "KI",
  "KP",
  "KR",
  "KW",
  "KG",
  "LA",
  "LV",
  "LB",
  "LS",
  "LR",
  "LY",
  "LI",
  "LT",
  "LU",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MT",
  "MH",
  "MQ",
  "MR",
  "MU",
  "MX",
  "FM",
  "MD",
  "MC",
  "MN",
  "MA",
  "MZ",
  "MM",
  "NA",
  "NR",
  "NP",
  "NL",
  "NZ",
  "NI",
  "NE",
  "NG",
  "NU",
  "NO",
  "MK",
  "OM",
  "PK",
  "PW",
  "PS",
  "PA",
  "PG",
  "PY",
  "PE",
  "PH",
  "PL",
  "PT",
  "PR",
  "QA",
  "RE",
  "RO",
  "RU",
  "RW",
  "KN",
  "LC",
  "VC",
  "WS",
  "SM",
  "ST",
  "SA",
  "SN",
  "RS",
  "ME",
  "MO",
  "SC",
  "SL",
  "SG",
  "SK",
  "SI",
  "SB",
  "SO",
  "ZA",
  "ES",
  "LK",
  "SD",
  "SS",
  "SZ",
  "SE",
  "CH",
  "SY",
  "TC",
  "TW",
  "TJ",
  "TZ",
  "TH",
  "TL",
  "TG",
  "TO",
  "TT",
  "TN",
  "TR",
  "TM",
  "TV",
  "UG",
  "UA",
  "AE",
  "GB",
  "US",
  "UY",
  "UZ",
  "VU",
  "VE",
  "VN",
  "YE",
  "ZM",
  "ZW",
] as const;
export type StaticDataTypeCountries = (typeof StaticDataTypeCountriesValues)[number];
export function isStaticDataTypeCountries(value: any): value is StaticDataTypeCountries {
  return StaticDataTypeCountriesValues.includes(value);
}
export const StaticDataTypeCurrenciesValues = [
  "AFN",
  "ALL",
  "DZD",
  "USD",
  "EUR",
  "AOA",
  "XCD",
  "ARS",
  "AMD",
  "AWG",
  "AZN",
  "AUD",
  "BSD",
  "BHD",
  "BDT",
  "BBD",
  "BZD",
  "XOF",
  "BMD",
  "BTN",
  "INR",
  "BOB",
  "BOV",
  "BAM",
  "BWP",
  "NOK",
  "BRL",
  "BND",
  "BGN",
  "BIF",
  "CLF",
  "CLP",
  "CNY",
  "NZD",
  "CRC",
  "ANG",
  "LAK",
  "DOP",
  "DJF",
  "DKK",
  "SVC",
  "ERN",
  "FKP",
  "FJD",
  "XPF",
  "XAF",
  "GMD",
  "GEL",
  "GHS",
  "GIP",
  "GTQ",
  "GBP",
  "GNF",
  "GYD",
  "HTG",
  "HNL",
  "HKD",
  "IDR",
  "XDR",
  "IQD",
  "IRR",
  "ISK",
  "ILS",
  "JMD",
  "JPY",
  "JOD",
  "KYD",
  "KHR",
  "CAD",
  "CVE",
  "KZT",
  "QAR",
  "KES",
  "KGS",
  "COP",
  "COU",
  "KMF",
  "CDF",
  "KPW",
  "KRW",
  "HRK",
  "CUC",
  "CUP",
  "KWD",
  "LSL",
  "ZAR",
  "LBP",
  "LRD",
  "LYD",
  "CHF",
  "MOP",
  "MGA",
  "MWK",
  "MYR",
  "MVR",
  "MAD",
  "MRO",
  "MUR",
  "MKD",
  "MXN",
  "MXV",
  "XUA",
  "MDL",
  "MNT",
  "MZN",
  "MMK",
  "NAD",
  "NPR",
  "NIO",
  "NGN",
  "OMR",
  "PKR",
  "PAB",
  "PGK",
  "PYG",
  "PEN",
  "PHP",
  "PLN",
  "RON",
  "RUB",
  "RWF",
  "SBD",
  "ZMW",
  "WST",
  "STD",
  "SAR",
  "SEK",
  "CHE",
  "CHW",
  "RSD",
  "SCR",
  "SLL",
  "ZWL",
  "SGD",
  "XSU",
  "SOS",
  "LKR",
  "SHP",
  "SDG",
  "SRD",
  "SZL",
  "SYP",
  "SSP",
  "TWD",
  "TJS",
  "TZS",
  "THB",
  "TOP",
  "TTD",
  "CZK",
  "TND",
  "TMT",
  "TRY",
  "UGX",
  "UAH",
  "HUF",
  "UYI",
  "UYU",
  "UZS",
  "VUV",
  "VEF",
  "AED",
  "USN",
  "VND",
  "BYR",
  "YER",
  "EGP",
  "ETB",
] as const;
export type StaticDataTypeCurrencies = (typeof StaticDataTypeCurrenciesValues)[number];
export function isStaticDataTypeCurrencies(value: any): value is StaticDataTypeCurrencies {
  return StaticDataTypeCurrenciesValues.includes(value);
}
export const StaticDataTypeFederalStatesValues = [
  "DE-BW",
  "DE-BY",
  "DE-BE",
  "DE-BB",
  "DE-HB",
  "DE-HH",
  "DE-HE",
  "DE-MV",
  "DE-NI",
  "DE-NW",
  "DE-RP",
  "DE-SL",
  "DE-SN",
  "DE-ST",
  "DE-SH",
  "DE-TH",
] as const;
export type StaticDataTypeFederalStates = (typeof StaticDataTypeFederalStatesValues)[number];
export function isStaticDataTypeFederalStates(value: any): value is StaticDataTypeFederalStates {
  return StaticDataTypeFederalStatesValues.includes(value);
}
export const StaticDataTypeKnownCitiesValues = [
  "BERLIN",
  "HAMBURG",
  "MUENCHEN",
  "KOELN",
  "FRANKFURT-AM-MAIN",
  "STUTTGART",
  "DUESSELDORF",
  "LEIPZIG",
  "DORTMUND",
  "ESSEN",
  "BREMEN",
  "DRESDEN",
  "HANNOVER",
  "NUERNBERG",
  "DUISBURG",
  "BOCHUM",
  "WUPPERTAL",
  "BIELEFELD",
  "BONN",
  "MUENSTER",
  "MANNHEIM",
  "KARLSRUHE",
  "AUGSBURG",
  "WIESBADEN",
  "MOENCHENGLADBACH",
  "GELSENKIRCHEN",
  "AACHEN",
  "BRAUNSCHWEIG",
  "KIEL",
  "CHEMNITZ",
  "HALLE-SAALE",
  "MAGDEBURG",
  "FREIBURG-IM-BREISGAU",
  "KREFELD",
  "MAINZ",
  "LUEBECK",
  "ERFURT",
  "OBERHAUSEN",
  "ROSTOCK",
  "KASSEL",
  "HAGEN",
  "POTSDAM",
  "SAARBRUECKEN",
  "HAMM",
  "LUDWIGSHAFEN-AM-RHEIN",
  "MUELHEIM-AN-DER-RUHR",
  "OLDENBURG",
  "OSNABRUECK",
  "LEVERKUSEN",
  "SOLINGEN",
  "DARMSTADT",
  "HEIDELBERG",
  "HERNE",
  "NEUSS",
  "REGENSBURG",
  "PADERBORN",
  "INGOLSTADT",
  "OFFENBACH-AM-MAIN",
  "FUERTH",
  "WUERZBURG",
  "HEILBRONN",
  "ULM",
  "PFORZHEIM",
  "WOLFSBURG",
  "BOTTROP",
  "GOETTINGEN",
  "REUTLINGEN",
  "BREMERHAVEN",
  "KOBLENZ",
  "ERLANGEN",
  "BERGISCH-GLADBACH",
  "REMSCHEID",
  "JENA",
  "RECKLINGHAUSEN",
  "TRIER",
  "SALZGITTER",
  "MOERS",
  "SIEGEN",
  "HILDESHEIM",
  "GUETERSLOH",
  "KAISERSLAUTERN",
  "COTTBUS",
  "HANAU",
  "WITTEN",
  "SCHWERIN",
  "LUDWIGSBURG",
  "ESSLINGEN-AM-NECKAR",
  "GERA",
  "ISERLOHN",
  "DUEREN",
  "TUEBINGEN",
  "GIESSEN",
  "FLENSBURG",
  "ZWICKAU",
  "RATINGEN",
  "LUENEN",
  "VILLINGEN-SCHWENNINGEN",
  "KONSTANZ",
  "MARL",
  "WORMS",
  "MINDEN",
  "VELBERT",
  "NEUMUENSTER",
  "DESSAU-ROSSLAU",
  "NORDERSTEDT",
  "DELMENHORST",
  "VIERSEN",
  "BAMBERG",
  "MARBURG",
  "RHEINE",
  "GLADBECK",
  "LUENEBURG",
  "WILHELMSHAVEN",
  "TROISDORF",
  "DORSTEN",
  "DETMOLD",
  "BAYREUTH",
  "ARNSBERG",
  "CASTROP-RAUXEL",
  "LANDSHUT",
  "BRANDENBURG-AN-DER-HAVEL",
  "LUEDENSCHEID",
  "BOCHOLT",
  "ASCHAFFENBURG",
  "CELLE",
  "KEMPTEN-ALLGAEU",
  "AALEN",
  "FULDA",
  "LIPPSTADT",
  "DINSLAKEN",
  "HERFORD",
  "RUESSELSHEIM-AM-MAIN",
  "KERPEN",
  "WEIMAR",
  "NEUWIED",
  "SINDELFINGEN",
  "DORMAGEN",
  "PLAUEN",
  "GREVENBROICH",
  "ROSENHEIM",
  "NEUBRANDENBURG",
  "HERTEN",
  "BERGHEIM",
  "FRIEDRICHSHAFEN",
  "SCHWAEBISCH-GMUEND",
  "GARBSEN",
  "OFFENBURG",
  "WESEL",
  "HUERTH",
  "GREIFSWALD",
  "NEU-ULM",
  "EUSKIRCHEN",
  "UNNA",
  "LANGENFELD-RHEINLAND",
  "STRALSUND",
  "GOEPPINGEN",
  "FRANKFURT-ODER",
  "HAMELN",
  "MEERBUSCH",
  "BADEN-BADEN",
  "LINGEN-EMS",
  "GOERLITZ",
  "SANKT-AUGUSTIN",
  "STOLBERG-RHEINLAND",
  "PULHEIM",
  "ESCHWEILER",
  "BAD-HOMBURG-VOR-DER-HOEHE",
  "WAIBLINGEN",
  "LANGENHAGEN",
  "HILDEN",
  "NORDHORN",
  "SCHWEINFURT",
  "WETZLAR",
  "HATTINGEN",
  "BAD-SALZUFLEN",
  "PASSAU",
  "NEUSTADT-AN-DER-WEINSTRASSE",
  "KLEVE",
  "AHLEN",
  "FRECHEN",
  "WOLFENBUETTEL",
  "BAD-KREUZNACH",
  "IBBENBUEREN",
  "MENDEN-SAUERLAND",
  "BOEBLINGEN",
  "GUMMERSBACH",
  "RASTATT",
  "RAVENSBURG",
  "PEINE",
  "SPEYER",
  "ELMSHORN",
  "LOERRACH",
  "EMDEN",
  "GOSLAR",
  "WILLICH",
  "GRONAU-WESTF",
  "ERFTSTADT",
  "FREISING",
  "HEIDENHEIM-AN-DER-BRENZ",
  "RHEDA-WIEDENBRUECK",
  "LEONBERG",
  "STRAUBING",
  "BAD-OEYNHAUSEN",
  "SINGEN-HOHENTWIEL",
  "BERGKAMEN",
  "LAHR-SCHWARZWALD",
  "FRANKENTHAL-PFALZ",
  "BORNHEIM",
  "CUXHAVEN",
  "STADE",
  "ALSDORF",
  "ORANIENBURG",
  "LANDAU-IN-DER-PFALZ",
  "DACHAU",
  "SOEST",
  "HENNEF-SIEG",
  "DUELMEN",
  "MELLE",
  "OBERURSEL-TAUNUS",
  "NEUNKIRCHEN",
  "HERZOGENRATH",
  "BRUCHSAL",
  "HOF",
  "ALBSTADT",
  "RODGAU",
  "SCHWERTE",
  "KAUFBEUREN",
  "GOTHA",
  "FILDERSTADT",
  "FELLBACH",
  "MEMMINGEN",
  "BUENDE",
  "LUTHERSTADT-WITTENBERG",
  "BRUEHL",
  "WEINHEIM",
  "NEUSTADT-AM-RUEBENBERGE",
  "LEHRTE",
  "FALKENSEE",
] as const;
export type StaticDataTypeKnownCities = (typeof StaticDataTypeKnownCitiesValues)[number];
export function isStaticDataTypeKnownCities(value: any): value is StaticDataTypeKnownCities {
  return StaticDataTypeKnownCitiesValues.includes(value);
}
export const StaticDataTypeLanguagesValues = [
  "AL",
  "AR",
  "BG",
  "BS",
  "HR",
  "TR",
  "DA",
  "DE",
  "EN",
  "ES",
  "FA",
  "FI",
  "FR",
  "GA",
  "HE",
  "HI",
  "IT",
  "JA",
  "ME",
  "MK",
  "ML",
  "NL",
  "NO",
  "PT",
  "BR",
  "RO",
  "RU",
  "SR",
  "SV",
  "TL",
  "CS",
  "UK",
  "VI",
  "ZH",
  "ET",
  "FP",
  "ID",
  "KU",
  "UR",
  "PL",
  "SY",
  "TA",
  "BN",
  "HU",
  "EL",
  "CM",
  "PA",
  "KO",
  "TH",
  "KA",
  "UZ",
  "HY",
  "LT",
] as const;
export type StaticDataTypeLanguages = (typeof StaticDataTypeLanguagesValues)[number];
export function isStaticDataTypeLanguages(value: any): value is StaticDataTypeLanguages {
  return StaticDataTypeLanguagesValues.includes(value);
}
export const StaticDataTypeProfessionValues = [
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "49",
  "53",
  "54",
  "55",
  "56",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "74",
  "75",
  "76",
  "78",
  "79",
  "80",
  "81",
  "82",
  "83",
  "84",
  "85",
  "86",
  "87",
  "88",
  "89",
  "91",
  "92",
  "93",
  "94",
  "95",
  "96",
  "97",
  "98",
  "99",
  "100",
  "101",
  "102",
  "104",
  "105",
  "106",
  "107",
  "108",
  "109",
  "110",
  "112",
  "114",
  "115",
  "116",
  "117",
  "118",
  "119",
  "120",
  "122",
  "123",
  "124",
  "125",
  "126",
  "127",
  "128",
  "130",
  "131",
  "133",
  "134",
  "135",
  "136",
  "137",
  "139",
  "142",
  "143",
  "144",
  "145",
  "146",
  "149",
  "150",
  "151",
  "152",
  "153",
  "154",
  "155",
  "156",
  "157",
  "158",
  "159",
  "160",
  "161",
  "162",
  "163",
  "164",
  "165",
  "166",
  "167",
  "168",
  "169",
  "170",
  "172",
  "173",
  "174",
  "175",
  "176",
  "177",
  "178",
  "179",
  "180",
  "181",
  "182",
  "183",
  "184",
  "185",
  "186",
  "187",
  "188",
  "189",
  "191",
  "192",
  "193",
  "194",
  "195",
  "196",
  "197",
  "198",
  "199",
  "200",
  "201",
  "203",
  "204",
  "205",
  "206",
  "207",
  "208",
  "209",
  "210",
  "211",
  "212",
  "213",
  "215",
  "216",
  "217",
  "219",
  "220",
  "221",
  "222",
  "223",
  "224",
  "226",
  "227",
  "228",
  "229",
  "232",
  "233",
  "234",
  "235",
  "236",
  "238",
  "239",
  "240",
  "241",
  "242",
  "243",
  "244",
  "245",
  "246",
  "248",
  "249",
  "250",
  "253",
  "255",
  "256",
  "257",
  "258",
  "259",
  "260",
  "262",
  "263",
  "264",
  "265",
  "266",
  "267",
  "268",
  "269",
  "270",
  "271",
  "272",
  "273",
  "274",
  "275",
  "276",
  "277",
  "278",
  "279",
  "280",
  "281",
  "282",
  "283",
  "284",
  "285",
  "287",
  "288",
  "289",
  "290",
  "291",
  "292",
  "293",
  "296",
  "297",
  "299",
  "300",
  "301",
  "302",
  "303",
  "304",
  "305",
  "306",
  "308",
  "309",
  "310",
  "311",
  "312",
  "313",
  "315",
  "316",
  "317",
  "320",
  "321",
  "322",
  "323",
  "324",
  "326",
  "327",
  "328",
  "329",
  "330",
  "331",
  "332",
  "333",
  "334",
  "335",
  "336",
  "337",
  "338",
  "339",
  "340",
  "341",
  "342",
  "343",
  "344",
  "345",
  "346",
  "349",
  "351",
  "352",
  "353",
  "354",
  "355",
  "356",
  "357",
  "358",
  "359",
  "360",
  "361",
  "362",
  "363",
  "364",
  "365",
  "368",
  "371",
  "372",
  "373",
  "374",
  "377",
  "378",
  "380",
  "382",
  "383",
  "385",
  "386",
  "388",
  "389",
  "391",
  "392",
  "393",
  "396",
  "397",
  "398",
  "399",
  "400",
  "401",
  "402",
  "403",
  "404",
  "407",
  "409",
  "412",
  "413",
  "415",
  "416",
  "417",
  "418",
  "419",
  "420",
  "421",
  "422",
  "423",
  "424",
  "425",
  "426",
  "427",
  "429",
  "430",
  "431",
  "432",
  "433",
  "434",
  "435",
  "436",
  "437",
  "438",
  "439",
  "440",
  "441",
  "442",
  "443",
  "444",
  "445",
  "447",
  "448",
  "450",
  "451",
  "452",
  "453",
  "454",
  "455",
  "456",
  "457",
  "458",
  "459",
  "460",
  "461",
  "462",
  "464",
  "465",
  "466",
  "467",
  "468",
  "469",
  "470",
  "471",
  "472",
  "473",
  "474",
  "475",
  "476",
  "477",
  "478",
  "479",
  "480",
  "481",
  "482",
  "483",
  "484",
  "485",
  "486",
  "487",
  "488",
  "489",
  "490",
  "491",
  "492",
  "493",
  "494",
  "495",
  "496",
  "497",
  "498",
  "499",
  "500",
  "501",
  "502",
  "503",
  "504",
  "505",
  "506",
  "507",
  "509",
  "511",
  "512",
  "513",
  "514",
  "517",
  "518",
  "519",
  "521",
  "523",
  "524",
  "526",
  "529",
  "530",
  "531",
  "532",
  "534",
  "540",
  "541",
  "542",
  "543",
  "544",
  "545",
  "546",
  "547",
  "548",
  "549",
  "550",
  "551",
  "553",
  "554",
  "555",
  "556",
  "557",
  "558",
  "559",
  "560",
  "564",
  "565",
  "568",
  "571",
  "575",
  "576",
  "577",
  "581",
  "582",
  "583",
  "584",
  "585",
  "586",
  "588",
  "589",
  "590",
  "591",
  "592",
  "594",
  "595",
  "596",
  "597",
  "598",
  "599",
  "600",
  "601",
  "607",
  "608",
  "609",
  "610",
  "615",
  "617",
  "618",
  "619",
  "620",
  "621",
  "622",
  "623",
  "624",
  "625",
  "626",
  "627",
  "628",
  "629",
  "630",
  "631",
  "632",
  "633",
  "634",
  "635",
  "636",
  "637",
  "638",
  "639",
  "641",
  "642",
  "643",
  "644",
  "645",
  "646",
  "647",
  "648",
  "649",
  "650",
  "651",
  "652",
  "654",
  "655",
  "656",
  "657",
  "658",
  "659",
  "660",
  "661",
  "662",
  "663",
  "664",
  "665",
  "667",
  "668",
  "669",
  "670",
  "673",
  "674",
  "675",
  "676",
  "677",
  "679",
  "680",
  "681",
  "682",
  "683",
  "684",
  "685",
  "686",
  "687",
  "688",
  "689",
  "690",
  "692",
  "694",
  "695",
  "696",
  "697",
  "699",
  "700",
  "701",
  "702",
  "703",
  "704",
  "705",
  "706",
  "707",
  "708",
  "709",
  "710",
  "711",
  "712",
  "713",
  "714",
  "715",
  "717",
  "718",
  "719",
  "722",
  "723",
  "724",
  "725",
  "726",
  "728",
  "729",
  "730",
  "731",
  "732",
  "733",
  "734",
  "735",
  "736",
  "737",
  "739",
  "740",
  "741",
  "743",
  "746",
  "748",
  "749",
  "750",
  "751",
  "752",
  "753",
  "754",
  "755",
  "757",
  "758",
  "759",
  "760",
  "771",
  "773",
  "775",
  "788",
  "789",
  "790",
  "791",
  "796",
  "797",
  "799",
  "800",
  "801",
  "802",
  "808",
  "811",
  "812",
  "814",
  "817",
  "819",
  "820",
  "824",
  "825",
  "826",
  "827",
  "893",
  "925",
  "926",
  "928",
  "929",
  "930",
  "931",
  "933",
  "934",
  "935",
  "937",
  "939",
  "1013",
  "1014",
  "1023",
  "1024",
  "1025",
  "1033",
  "1034",
  "1035",
  "1042",
  "1049",
  "1050",
  "1051",
  "1052",
  "1053",
  "1054",
  "1055",
  "1056",
  "1060",
  "1064",
  "1086",
  "1089",
  "1090",
  "1091",
  "1092",
  "1104",
  "1105",
  "1106",
  "1108",
  "1109",
  "1112",
  "1127",
  "1130",
  "1131",
  "1132",
  "1133",
  "1134",
  "1135",
  "1137",
  "1138",
  "1139",
  "1140",
  "1141",
  "1142",
  "1143",
  "1144",
  "1145",
  "1146",
  "1147",
  "1148",
  "1149",
  "1151",
  "1152",
  "1153",
  "1154",
  "1155",
  "1156",
  "1157",
  "1158",
  "1159",
  "1167",
  "1168",
  "1184",
  "1185",
  "1186",
  "1187",
  "1188",
  "1189",
  "1190",
  "1191",
  "1192",
  "1209",
  "1210",
  "1211",
  "1212",
  "1213",
  "1214",
  "1216",
  "1219",
  "1220",
  "1224",
  "1225",
  "1226",
  "1227",
  "1229",
  "1230",
  "1231",
  "1232",
  "1233",
  "1234",
  "1235",
  "1236",
  "1249",
  "1250",
  "1251",
  "1252",
  "1253",
  "1254",
  "1255",
  "1256",
  "1265",
  "1268",
  "1271",
  "1272",
  "1273",
  "1274",
  "1275",
  "1277",
  "1278",
  "1279",
  "1280",
  "1283",
  "1285",
  "1286",
  "1295",
  "1296",
  "1298",
  "1299",
  "1300",
  "1302",
  "1303",
  "1304",
  "1305",
  "1306",
  "1307",
  "1308",
  "1310",
  "1311",
  "1312",
  "1313",
  "1314",
  "1315",
  "1318",
  "1319",
  "1320",
  "1321",
  "1322",
  "1324",
  "1327",
  "1328",
  "1329",
  "1330",
  "1331",
  "1332",
  "1333",
  "1334",
  "1335",
  "1336",
  "1339",
  "1340",
  "1342",
  "1343",
  "1345",
  "1347",
  "1348",
  "1350",
  "1352",
  "1353",
  "1356",
  "1359",
  "1360",
  "1361",
  "1363",
  "1364",
  "1370",
  "1371",
  "1375",
  "1376",
  "1377",
  "1378",
  "1379",
  "1380",
  "1381",
  "1383",
  "1386",
  "1390",
  "1391",
  "1392",
  "1397",
  "1399",
  "1400",
  "1402",
  "1406",
  "1408",
  "1411",
  "1419",
  "1424",
  "1437",
  "1439",
  "1440",
  "1443",
  "1444",
  "1446",
  "1447",
  "1448",
  "1449",
  "1450",
  "1452",
  "1453",
  "1454",
  "1455",
  "1456",
  "1457",
  "1458",
  "1459",
  "1460",
  "1461",
  "1462",
  "1463",
  "1464",
  "1465",
  "1466",
  "1467",
  "1468",
  "1469",
  "1470",
  "1472",
  "1473",
  "1474",
  "1475",
  "1476",
  "1477",
  "1478",
  "1479",
  "1480",
  "1482",
  "1483",
  "1484",
  "1485",
  "1486",
  "1487",
  "1488",
  "1491",
  "1492",
  "1493",
  "1494",
  "1495",
  "1496",
  "1497",
  "1498",
  "1499",
  "1500",
  "1502",
  "1503",
  "1504",
  "1505",
  "1506",
  "1507",
  "1512",
  "1513",
  "1514",
  "1515",
  "1516",
  "1517",
  "1518",
  "1519",
  "1520",
  "1521",
  "1522",
  "1523",
  "1524",
  "1525",
  "1527",
  "1529",
  "1530",
  "1532",
  "1533",
  "1534",
  "1535",
  "1536",
  "1537",
  "1538",
  "1539",
  "1540",
  "1541",
  "1542",
  "1543",
  "1544",
  "1545",
  "1546",
  "1549",
  "1550",
  "1551",
  "1552",
  "1555",
  "1556",
  "1558",
  "1560",
  "1561",
  "1563",
  "1565",
  "1566",
  "1567",
  "1568",
  "1569",
  "1570",
  "1573",
  "1574",
  "1575",
  "1576",
  "1577",
  "1580",
  "1582",
  "1583",
  "1584",
  "1585",
  "1586",
  "1587",
  "1588",
  "1589",
  "1591",
  "1592",
  "1593",
  "1594",
  "1595",
  "1596",
  "1597",
  "1598",
  "1599",
  "1600",
  "1601",
  "1602",
  "1603",
  "1604",
  "1606",
  "1607",
  "1608",
  "1609",
  "1610",
  "1611",
  "1612",
  "1614",
  "1615",
  "1616",
  "1617",
  "1619",
  "1620",
  "1621",
  "1622",
  "1625",
  "1626",
  "1628",
  "1629",
  "1630",
  "1631",
  "1632",
  "1633",
  "1634",
  "1635",
  "1636",
  "1637",
  "1638",
  "1639",
  "1640",
  "1641",
  "1643",
  "1645",
  "1647",
  "1648",
  "1649",
  "1650",
  "1651",
  "1652",
  "1653",
  "1654",
  "1655",
  "1657",
  "1658",
  "1659",
  "1660",
  "1661",
  "1662",
  "1663",
  "1664",
  "1665",
  "1666",
  "1667",
  "1668",
  "1669",
  "1670",
  "1671",
  "1672",
  "1673",
  "1674",
  "1675",
  "1676",
  "1677",
  "1678",
  "1679",
  "1680",
  "1681",
  "1683",
  "1684",
  "1685",
  "1686",
  "1687",
  "1688",
  "1695",
  "1698",
  "1701",
  "1703",
  "1706",
  "1709",
  "1712",
  "1715",
  "1718",
  "1721",
  "1724",
  "1728",
  "1731",
  "1737",
  "1740",
  "1743",
  "1746",
  "1749",
  "1752",
  "1755",
  "1758",
  "1761",
  "1764",
  "1767",
  "1770",
  "1773",
  "1782",
  "1785",
  "1788",
  "1797",
  "1806",
  "1809",
  "1815",
  "1818",
  "1821",
  "1823",
  "1826",
  "1829",
  "1832",
  "1835",
  "1838",
  "1841",
  "1847",
  "1850",
  "1853",
  "1856",
  "1860",
  "1863",
  "1866",
  "1867",
  "1870",
  "1873",
  "1876",
  "1882",
  "1885",
  "1886",
  "1890",
  "1893",
  "1900",
  "1903",
  "1906",
  "1909",
  "1912",
  "1918",
  "1921",
  "1929",
  "1932",
  "9999",
  "i1",
  "i2",
  "i3",
  "i4",
  "i5",
  "i7",
  "i8",
  "i9",
  "i10",
  "i11",
  "i12",
  "i13",
  "i14",
  "i15",
  "i16",
  "i17",
  "i18",
  "i19",
  "i20",
  "i21",
  "i22",
  "i23",
  "i24",
] as const;
export type StaticDataTypeProfession = (typeof StaticDataTypeProfessionValues)[number];
export function isStaticDataTypeProfession(value: any): value is StaticDataTypeProfession {
  return StaticDataTypeProfessionValues.includes(value);
}
export const StaticDataTypeProfessionAreaValues = [
  "1",
  "11",
  "12",
  "2",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "3",
  "31",
  "32",
  "33",
  "34",
  "4",
  "41",
  "42",
  "43",
  "5",
  "51",
  "52",
  "53",
  "54",
  "6",
  "61",
  "62",
  "63",
  "7",
  "71",
  "72",
  "73",
  "8",
  "81",
  "82",
  "83",
  "84",
  "9",
  "91",
  "92",
  "93",
  "94",
  "999",
  "9999",
] as const;
export type StaticDataTypeProfessionArea = (typeof StaticDataTypeProfessionAreaValues)[number];
export function isStaticDataTypeProfessionArea(value: any): value is StaticDataTypeProfessionArea {
  return StaticDataTypeProfessionAreaValues.includes(value);
}
export const StaticDataTypeEducationValues = [
  "IA",
  "VT",
  "HS",
  "GS",
  "SS",
  "BA",
  "MS",
  "DIPLOMA",
  "PHD",
  "PS",
  "MSCRAFT",
  "BACRAFT",
  "TECH",
] as const;
export type StaticDataTypeEducation = (typeof StaticDataTypeEducationValues)[number];
export function isStaticDataTypeEducation(value: any): value is StaticDataTypeEducation {
  return StaticDataTypeEducationValues.includes(value);
}
export const StaticDataTypeCareFacilitiesValues = [
  "UNVHOSPITAL",
  "MILTHOSPITAL",
  "PRIVHOSPITAL",
  "PUBHOSPITAL",
  "HOSPITAL",
  "COMPNURSE",
  "REHA",
  "HCA",
  "HCC",
  "ECC",
  "POLICLINIC",
  "DOCOFFICE",
  "OTHER",
  "OUTCARE",
  "INTENCARE",
  "HCARE",
  "PHARMACY",
  "PHARMA",
  "HOSPITALPHARMA",
  "MAILORDER",
  "PHARMAWHOLESALE",
  "PHYSIO",
  "PODIATRY",
  "LAB",
  "MEDICALCARE",
  "ERGO",
  "HOSPIZ",
] as const;
export type StaticDataTypeCareFacilities = (typeof StaticDataTypeCareFacilitiesValues)[number];
export function isStaticDataTypeCareFacilities(value: any): value is StaticDataTypeCareFacilities {
  return StaticDataTypeCareFacilitiesValues.includes(value);
}
export const StaticDataTypeLanguageLevelsValues = [
  "A1",
  "A2",
  "B1",
  "B1_NURSE",
  "B2",
  "B2_NURSE",
  "B2_DOCTOR",
  "C1",
  "C1_DOCTOR",
  "C2",
  "NV",
] as const;
export type StaticDataTypeLanguageLevels = (typeof StaticDataTypeLanguageLevelsValues)[number];
export function isStaticDataTypeLanguageLevels(value: any): value is StaticDataTypeLanguageLevels {
  return StaticDataTypeLanguageLevelsValues.includes(value);
}
export const StaticDataTypeLanguageModulesValues = [
  "A1",
  "A1.1",
  "A1.2",
  "A1.3",
  "A2",
  "A2.1",
  "A2.2",
  "A2.3",
  "B1",
  "B1_NURSE",
  "B1.1",
  "B1.2",
  "B1.3",
  "B1+",
  "B2",
  "B2_NURSE",
  "B2_DOCTOR",
  "B2.1",
  "B2.2",
  "B2.3",
  "B2+",
  "C1",
  "C1.1",
  "C1.2",
  "C1.3",
  "C2",
  "C2.1",
  "C2.2",
  "C2.3",
] as const;
export type StaticDataTypeLanguageModules = (typeof StaticDataTypeLanguageModulesValues)[number];
export function isStaticDataTypeLanguageModules(value: any): value is StaticDataTypeLanguageModules {
  return StaticDataTypeLanguageModulesValues.includes(value);
}
export const StaticDataTypeProfessionalFieldsValues = [
  "AK",
  "AM",
  "AN",
  "AU",
  "BU",
  "CH",
  "DE",
  "DI",
  "GA",
  "GE",
  "GY",
  "HA",
  "IC",
  "IN",
  "IF",
  "KA",
  "KIC",
  "KIP",
  "MU",
  "NEP",
  "NER",
  "NEC",
  "NI",
  "NU",
  "ON",
  "HE",
  "OR",
  "OT",
  "OP",
  "PA",
  "PE",
  "PT",
  "PH",
  "PI",
  "PL",
  "PN",
  "PS",
  "PSM",
  "RA",
  "RAO",
  "SN",
  "ST",
  "UN",
  "UR",
  "ZA",
  "ZNA",
  "LAB",
  "ZYTO",
  "BER",
  "HER",
  "PRUE",
  "VAR",
  "CCU",
  "NA",
  "HL",
  "NE",
  "EN",
  "BM",
  "NM",
  "OC",
  "UC",
  "NC",
  "HTC",
  "REHA",
  "AUI",
  "REA",
  "NOTC",
  "WAK",
  "COV",
  "BIR",
  "BIRG",
  "GYN",
  "INTE",
  "PÄDINT",
  "IMC",
  "VIZC",
] as const;
export type StaticDataTypeProfessionalFields = (typeof StaticDataTypeProfessionalFieldsValues)[number];
export function isStaticDataTypeProfessionalFields(value: any): value is StaticDataTypeProfessionalFields {
  return StaticDataTypeProfessionalFieldsValues.includes(value);
}
export const StaticDataTypeAllowedSharedProfileTabsValues = [
  "customFields",
  "general",
  "qualifications",
  "furtherEducations",
  "experiences",
  "languageSkills",
  "preferences",
  "vaccinations",
  "insuranceAndTax",
  "bankDetails",
  "other",
  "qualificationEvaluation",
  "healthProfessionsRegister",
  "complementaryMeasure",
  "recognitionPath",
  "laborMarketAdmission",
  "qualificationMeasure",
  "vocationalSchool",
  "visa",
  "rwrCard",
  "residence",
  "housingAcquisition",
  "processStatus",
  "internal",
  "events",
  "notes",
  "family",
  "presentation",
  "attachments",
  "requiredDocument",
  "templates",
  "cardView",
  "governance",
  "emails",
  "interviews",
  "employers",
  "education",
  "competencies",
] as const;
export type StaticDataTypeAllowedSharedProfileTabs = (typeof StaticDataTypeAllowedSharedProfileTabsValues)[number];
export function isStaticDataTypeAllowedSharedProfileTabs(value: any): value is StaticDataTypeAllowedSharedProfileTabs {
  return StaticDataTypeAllowedSharedProfileTabsValues.includes(value);
}
export const StaticDataTypeWorkAreaValues = ["STATION", "OP"] as const;
export type StaticDataTypeWorkArea = (typeof StaticDataTypeWorkAreaValues)[number];
export function isStaticDataTypeWorkArea(value: any): value is StaticDataTypeWorkArea {
  return StaticDataTypeWorkAreaValues.includes(value);
}
export const StaticDataTypeOrganizationTypeValues = [
  "NURSING",
  "HOSPITAL",
  "CLINIC",
  "AMBULATORY",
  "UNIVHOSPITAL",
  "REHAB",
  "PSA",
  "EDUCATION",
  "Other",
  "AUTHORITY",
  "PHARMACY",
  "PHARMA",
  "HOSPITALPHARMA",
  "MAILORDER",
  "PHARMAWHOLESALE",
  "PHYSIO",
  "PODIATRY",
  "LAB",
  "MEDICALCARE",
  "ERGO",
  "EDUCATION",
  "HEALTH_SOCIAL",
  "FINANCIAL",
  "TECH",
  "CONSULTING",
  "IT",
  "RETAIL",
  "HOSPITALITYTOURISM",
  "NONPROFIT",
  "CONSTRUCTION",
  "REALESTATE",
  "CRAFTS",
  "AGRI_FOOD",
  "MEDIAENTERTAINMENT",
  "TRANSPORTLOGISTICS",
  "ENERGYUTILITIES",
  "AUTOMOTIVE",
  "AEROSPACE",
  "RESEARCH",
  "ARTSCULTURE",
  "INSURANCE",
  "INDUSTRYMANUFACTURING",
  "SENIORCARE",
  "INTEGRATION",
  "TRANSLATIONSERVICEPROVIDER",
] as const;
export type StaticDataTypeOrganizationType = (typeof StaticDataTypeOrganizationTypeValues)[number];
export function isStaticDataTypeOrganizationType(value: any): value is StaticDataTypeOrganizationType {
  return StaticDataTypeOrganizationTypeValues.includes(value);
}
export const StaticDataTypeDocumentFormatsValues = [
  "ORIGINAL",
  "ORIGINAL+APOSTILLE",
  "CERTIFIEDCOPY",
  "CERTIFIEDCOPY+APOSTILLE",
  "LEGALIZATION",
  "TRANSLATION",
  "TRANSLATION+CERTIFICATION",
  "COPY",
  "COPYTRANS",
  "PREAPPROVALDOC",
] as const;
export type StaticDataTypeDocumentFormats = (typeof StaticDataTypeDocumentFormatsValues)[number];
export function isStaticDataTypeDocumentFormats(value: any): value is StaticDataTypeDocumentFormats {
  return StaticDataTypeDocumentFormatsValues.includes(value);
}
export const StaticDataTypeDocumentFormats2FileNameMappingValues = [
  "ORIGINAL",
  "ORIGINAL+APOSTILLE",
  "CERTIFIEDCOPY",
  "CERTIFIEDCOPY+APOSTILLE",
  "LEGALIZATION",
  "TRANSLATION",
  "TRANSLATION+CERTIFICATION",
  "COPY",
  "PREAPPROVALDOC",
  "COPYTRANS",
] as const;
export type StaticDataTypeDocumentFormats2FileNameMapping =
  (typeof StaticDataTypeDocumentFormats2FileNameMappingValues)[number];
export function isStaticDataTypeDocumentFormats2FileNameMapping(
  value: any
): value is StaticDataTypeDocumentFormats2FileNameMapping {
  return StaticDataTypeDocumentFormats2FileNameMappingValues.includes(value);
}
export const StaticDataTypeDocumentFormatsAppreviationValues = [
  "ORIGINAL",
  "ORIGINAL+APOSTILLE",
  "CERTIFIEDCOPY",
  "CERTIFIEDCOPY+APOSTILLE",
  "LEGALIZATION",
  "TRANSLATION",
  "TRANSLATION+CERTIFICATION",
  "COPY",
  "PREAPPROVALDOC",
  "COPYTRANS",
] as const;
export type StaticDataTypeDocumentFormatsAppreviation =
  (typeof StaticDataTypeDocumentFormatsAppreviationValues)[number];
export function isStaticDataTypeDocumentFormatsAppreviation(
  value: any
): value is StaticDataTypeDocumentFormatsAppreviation {
  return StaticDataTypeDocumentFormatsAppreviationValues.includes(value);
}
export const StaticDataTypeAllValues = ["ALL"] as const;
export type StaticDataTypeAll = (typeof StaticDataTypeAllValues)[number];
export function isStaticDataTypeAll(value: any): value is StaticDataTypeAll {
  return StaticDataTypeAllValues.includes(value);
}
export const StaticDataTypePathOfRecognitionValues = [
  "ADAPTATION",
  "EXAMINATION",
  "RECOGNITION",
  "LATERCHOICE",
] as const;
export type StaticDataTypePathOfRecognition = (typeof StaticDataTypePathOfRecognitionValues)[number];
export function isStaticDataTypePathOfRecognition(value: any): value is StaticDataTypePathOfRecognition {
  return StaticDataTypePathOfRecognitionValues.includes(value);
}
export const StaticDataTypeFunctionsValues = [
  "TRAINEE",
  "PROFESSIONAL",
  "ASSISTANT",
  "INTERN",
  "STUDENT",
  "BFD",
] as const;
export type StaticDataTypeFunctions = (typeof StaticDataTypeFunctionsValues)[number];
export function isStaticDataTypeFunctions(value: any): value is StaticDataTypeFunctions {
  return StaticDataTypeFunctionsValues.includes(value);
}
export const StaticDataTypePositionsValues = [
  "MANAGEMENT",
  "PROFESSIONAL",
  "ASSISTANT",
  "INTERN",
  "MASTER",
  "FELLOW",
  "OTHER",
] as const;
export type StaticDataTypePositions = (typeof StaticDataTypePositionsValues)[number];
export function isStaticDataTypePositions(value: any): value is StaticDataTypePositions {
  return StaticDataTypePositionsValues.includes(value);
}
export const StaticDataTypeExamInstitutionsValues = ["GOETHE", "TELC", "OESD", "TESTDAF", "ECL", "OTHER"] as const;
export type StaticDataTypeExamInstitutions = (typeof StaticDataTypeExamInstitutionsValues)[number];
export function isStaticDataTypeExamInstitutions(value: any): value is StaticDataTypeExamInstitutions {
  return StaticDataTypeExamInstitutionsValues.includes(value);
}
export const StaticDataTypeDrivingLicensesValues = ["NONE", "MOTORCYCLE", "CAR", "MEDIUM", "LARGE", "MINIBUS"] as const;
export type StaticDataTypeDrivingLicenses = (typeof StaticDataTypeDrivingLicensesValues)[number];
export function isStaticDataTypeDrivingLicenses(value: any): value is StaticDataTypeDrivingLicenses {
  return StaticDataTypeDrivingLicensesValues.includes(value);
}
export const StaticDataTypeBenefitsValues = [
  "FLEXIBLEWORKINGTIMES",
  "SUPPORTINFINDINGACCOMDATION",
  "FURNISHEDAPARTMENT",
  "PERFORMANCEBASEDPAY",
  "ADVANCEONSALARY",
  "WELCOMEBONUS",
  "ONEOFFPAYMENT",
  "TRAINIGHEALPOSSIBILITIES",
  "CORPORATEHELTHCAREPROGRAME",
  "WORKLIFEBALANCE",
  "SUPPORTWITFAMILYRENUION",
  "COMPANYPERSION",
  "INTERGATIONPROGRAMBUDDYPROGRAM",
  "MENTOR",
  "FREELANGUAGECLASSES",
  "ONTHEJOBTRAINING",
  "COMPANYCARE",
  "COMPANYKINDERGARDEB",
  "TRAVELALLOWANCE",
  "RELOCATIONALLOWANCE",
  "EMPLOYEEDISCOUNT",
  "NONMONETARY",
  "GIFTS",
  "MEALS",
  "ITEQUIPMENT",
  "WORKCLOTHES",
  "EMPLOYERLOANS",
  "EMPLOYERSHARES",
  "UNIVERSITYALLOWANCE",
  "SHIFTALLOWANCE",
  "METROPOLITANALLOWANCE",
] as const;
export type StaticDataTypeBenefits = (typeof StaticDataTypeBenefitsValues)[number];
export function isStaticDataTypeBenefits(value: any): value is StaticDataTypeBenefits {
  return StaticDataTypeBenefitsValues.includes(value);
}
export const StaticDataTypeExamTypeValues = ["WRITTENEXAM", "ORALEXAM"] as const;
export type StaticDataTypeExamType = (typeof StaticDataTypeExamTypeValues)[number];
export function isStaticDataTypeExamType(value: any): value is StaticDataTypeExamType {
  return StaticDataTypeExamTypeValues.includes(value);
}
export const StaticDataTypeCategoryValues = ["CAT1", "CAT2", "CAT3"] as const;
export type StaticDataTypeCategory = (typeof StaticDataTypeCategoryValues)[number];
export function isStaticDataTypeCategory(value: any): value is StaticDataTypeCategory {
  return StaticDataTypeCategoryValues.includes(value);
}
export const StaticDataTypeBedsValues = [
  "<150",
  "150-200",
  "201-300",
  "301-500",
  "501-700",
  "701-1000",
  ">1000",
] as const;
export type StaticDataTypeBeds = (typeof StaticDataTypeBedsValues)[number];
export function isStaticDataTypeBeds(value: any): value is StaticDataTypeBeds {
  return StaticDataTypeBedsValues.includes(value);
}
export const StaticDataTypeQualificationMeasuresValues = ["ADAPTATION", "KNOWLEDGE", "ELIGIBILITY"] as const;
export type StaticDataTypeQualificationMeasures = (typeof StaticDataTypeQualificationMeasuresValues)[number];
export function isStaticDataTypeQualificationMeasures(value: any): value is StaticDataTypeQualificationMeasures {
  return StaticDataTypeQualificationMeasuresValues.includes(value);
}
export const StaticDataTypeQualificationTypeValues = ["THEORETICAL", "PRACTICAL"] as const;
export type StaticDataTypeQualificationType = (typeof StaticDataTypeQualificationTypeValues)[number];
export function isStaticDataTypeQualificationType(value: any): value is StaticDataTypeQualificationType {
  return StaticDataTypeQualificationTypeValues.includes(value);
}
export const StaticDataTypeResidencePermitsValues = [
  "RESIDENCEACT6",
  "RESIDENCEACT7",
  "RESIDENCEACT9",
  "RESIDENCEACT16A",
  "RESIDENCEACT16B",
  "RESIDENCEACT16D",
  "RESIDENCEACT16D-ABS1",
  "RESIDENCEACT16D-ABS3",
  "RESIDENCEACT16F",
  "RESIDENCEACT16G",
  "RESIDENCEACT17",
  "RESIDENCEACT18A",
  "RESIDENCEACT18B",
  "RESIDENCEACT18C-ABS1-S1",
  "RESIDENCEACT18C-ABS1-S2",
  "RESIDENCEACT18C-ABS3",
  "RESIDENCEACT18D",
  "RESIDENCEACT19D",
  "RESIDENCEACT19C",
  "RESIDENCEACT19C-ABS1",
  "RESIDENCEACT19C-ABS2",
  "RESIDENCEACT20",
  "RESIDENCEACT20-2",
] as const;
export type StaticDataTypeResidencePermits = (typeof StaticDataTypeResidencePermitsValues)[number];
export function isStaticDataTypeResidencePermits(value: any): value is StaticDataTypeResidencePermits {
  return StaticDataTypeResidencePermitsValues.includes(value);
}
export const StaticDataTypeRecognitionTypeValues = ["ACCELERATED", "NORMAL"] as const;
export type StaticDataTypeRecognitionType = (typeof StaticDataTypeRecognitionTypeValues)[number];
export function isStaticDataTypeRecognitionType(value: any): value is StaticDataTypeRecognitionType {
  return StaticDataTypeRecognitionTypeValues.includes(value);
}
export const StaticDataTypeEquivalenceTestValues = ["DETAILED", "PARTIAL"] as const;
export type StaticDataTypeEquivalenceTest = (typeof StaticDataTypeEquivalenceTestValues)[number];
export function isStaticDataTypeEquivalenceTest(value: any): value is StaticDataTypeEquivalenceTest {
  return StaticDataTypeEquivalenceTestValues.includes(value);
}
export const StaticDataTypeRecognitionNoticeKindValues = [
  "DEFICITNOTICE",
  "FULLRECOGNITIONNOTICE",
  "REJECTIONNOTICE",
] as const;
export type StaticDataTypeRecognitionNoticeKind = (typeof StaticDataTypeRecognitionNoticeKindValues)[number];
export function isStaticDataTypeRecognitionNoticeKind(value: any): value is StaticDataTypeRecognitionNoticeKind {
  return StaticDataTypeRecognitionNoticeKindValues.includes(value);
}
export const StaticDataTypeQualificationEvaluationNoticeKindValues = ["CERTZAB", "CERTLAB", "REJECTCERTAB"] as const;
export type StaticDataTypeQualificationEvaluationNoticeKind =
  (typeof StaticDataTypeQualificationEvaluationNoticeKindValues)[number];
export function isStaticDataTypeQualificationEvaluationNoticeKind(
  value: any
): value is StaticDataTypeQualificationEvaluationNoticeKind {
  return StaticDataTypeQualificationEvaluationNoticeKindValues.includes(value);
}
export const StaticDataTypeRecognitionStateValues = [
  "DEFICITNOTICE",
  "FULLRECOGNITIONNOTICE",
  "REJECTIONNOTICE",
  "NOTICERECEIVED",
  "RECOGNITIONSTARTED",
  "NONOTICE",
] as const;
export type StaticDataTypeRecognitionState = (typeof StaticDataTypeRecognitionStateValues)[number];
export function isStaticDataTypeRecognitionState(value: any): value is StaticDataTypeRecognitionState {
  return StaticDataTypeRecognitionStateValues.includes(value);
}
export const StaticDataTypeRelocationRequirementsValues = [
  "NOT_NEEDED",
  "A1",
  "A2",
  "B1",
  "B1_NURSE",
  "B2",
  "B2_NURSE",
  "B2_DOCTOR",
  "C1",
  "C2",
  "A1_CERT",
  "A2_CERT",
  "B1_CERT",
  "B1_NURSE_CERT",
  "B2_CERT",
  "B2_NURSE_CERT",
  "B2_DOCTOR_CERT",
  "C1_CERT",
  "C2_CERT",
] as const;
export type StaticDataTypeRelocationRequirements = (typeof StaticDataTypeRelocationRequirementsValues)[number];
export function isStaticDataTypeRelocationRequirements(value: any): value is StaticDataTypeRelocationRequirements {
  return StaticDataTypeRelocationRequirementsValues.includes(value);
}
export const StaticDataTypeWayToUsValues = [
  "RECOMM",
  "GOOGLE",
  "INSTAGRAM",
  "FACEBOOK",
  "PRESS",
  "FAIR",
  "BROWSER",
  "OTHER",
] as const;
export type StaticDataTypeWayToUs = (typeof StaticDataTypeWayToUsValues)[number];
export function isStaticDataTypeWayToUs(value: any): value is StaticDataTypeWayToUs {
  return StaticDataTypeWayToUsValues.includes(value);
}
export const StaticDataTypeVaccinationValues = [
  "DIPHTHERIA",
  "HIB",
  "HEPATITISA",
  "HEPATITISB",
  "MEASLES",
  "MENINGOCOCCUS",
  "MUMPS",
  "PERTUSSIS",
  "PNEUMOCOCCUS",
  "POLIO",
  "ROTAVIRUSES",
  "RUBELLA",
  "TETANUS",
  "VARICELLA",
  "HPV",
  "SHINGLES",
  "COVID",
  "OTHER",
] as const;
export type StaticDataTypeVaccination = (typeof StaticDataTypeVaccinationValues)[number];
export function isStaticDataTypeVaccination(value: any): value is StaticDataTypeVaccination {
  return StaticDataTypeVaccinationValues.includes(value);
}
export const StaticDataTypeVaccinationStatusValues = ["FULL", "PARTIAL", "NONE"] as const;
export type StaticDataTypeVaccinationStatus = (typeof StaticDataTypeVaccinationStatusValues)[number];
export function isStaticDataTypeVaccinationStatus(value: any): value is StaticDataTypeVaccinationStatus {
  return StaticDataTypeVaccinationStatusValues.includes(value);
}
export const StaticDataTypeSupportedImmigrationCountriesValues = ["UNKNOWN", "DE", "AT"] as const;
export type StaticDataTypeSupportedImmigrationCountries =
  (typeof StaticDataTypeSupportedImmigrationCountriesValues)[number];
export function isStaticDataTypeSupportedImmigrationCountries(
  value: any
): value is StaticDataTypeSupportedImmigrationCountries {
  return StaticDataTypeSupportedImmigrationCountriesValues.includes(value);
}
export const StaticDataTypeOtherActivitiesValues = [
  "PARENTALLEAVE",
  "FAMILYCARELEAVE",
  "JOBSEEKING",
  "SABBATICAL",
  "SOCIALSERVICE",
  "MILITARYSERVICE",
  "OTHER",
] as const;
export type StaticDataTypeOtherActivities = (typeof StaticDataTypeOtherActivitiesValues)[number];
export function isStaticDataTypeOtherActivities(value: any): value is StaticDataTypeOtherActivities {
  return StaticDataTypeOtherActivitiesValues.includes(value);
}
export const StaticDataTypeWorkLocationsValues = ["FIXED", "CHANGEABLE"] as const;
export type StaticDataTypeWorkLocations = (typeof StaticDataTypeWorkLocationsValues)[number];
export function isStaticDataTypeWorkLocations(value: any): value is StaticDataTypeWorkLocations {
  return StaticDataTypeWorkLocationsValues.includes(value);
}
export const StaticDataTypeExamResultStateValues = ["PASSED", "FAILED", "PARTIALLYPASSED", "NOT_TAKEN"] as const;
export type StaticDataTypeExamResultState = (typeof StaticDataTypeExamResultStateValues)[number];
export function isStaticDataTypeExamResultState(value: any): value is StaticDataTypeExamResultState {
  return StaticDataTypeExamResultStateValues.includes(value);
}
export const StaticDataTypeDocumentAvailabilityStateValues = ["AVAILABLE", "NOT_AVAILABLE", "NOT_NECESSARY"] as const;
export type StaticDataTypeDocumentAvailabilityState = (typeof StaticDataTypeDocumentAvailabilityStateValues)[number];
export function isStaticDataTypeDocumentAvailabilityState(
  value: any
): value is StaticDataTypeDocumentAvailabilityState {
  return StaticDataTypeDocumentAvailabilityStateValues.includes(value);
}
export const StaticDataTypeTravelVehiclesValues = ["PLANE", "TRAIN", "BUS", "OTHER"] as const;
export type StaticDataTypeTravelVehicles = (typeof StaticDataTypeTravelVehiclesValues)[number];
export function isStaticDataTypeTravelVehicles(value: any): value is StaticDataTypeTravelVehicles {
  return StaticDataTypeTravelVehiclesValues.includes(value);
}
export const StaticDataTypeAwardFrequencyValues = ["ONETIME", "MONTHLY"] as const;
export type StaticDataTypeAwardFrequency = (typeof StaticDataTypeAwardFrequencyValues)[number];
export function isStaticDataTypeAwardFrequency(value: any): value is StaticDataTypeAwardFrequency {
  return StaticDataTypeAwardFrequencyValues.includes(value);
}
export const StaticDataTypeCandidateEventValues = [
  "DocumentExpiry",
  "DocumentResubmission",
  "Interview",
  "VisaRequest",
  "VisaExpiry",
  "ResidencePermitExpiry",
  "FictionalCertificateExpiry",
  "LanguageExam",
  "QualificationMeasureExam",
  "QualificationMeasureInterview",
  "ReadinessForOccupancy",
  "MovingIn",
  "MovingOut",
  "KeyHandoverDateTime",
  "PersonalOnboardingDateTime",
  "EstimatedWorkStartDate",
  "EstimatedArrivalDate",
  "ArrivalDate",
  "CourseExam",
  "CourseExamPlanned",
  "CourseExamResultsExpected",
  "MedicalExaminationDate",
  "PoliceRecordDate",
  "RegistrationOfficeDate",
  "CompanyDoctorDate",
  "BankAppointmentDate",
  "HealthDepartmentDate",
  "FlightDate",
] as const;
export type StaticDataTypeCandidateEvent = (typeof StaticDataTypeCandidateEventValues)[number];
export function isStaticDataTypeCandidateEvent(value: any): value is StaticDataTypeCandidateEvent {
  return StaticDataTypeCandidateEventValues.includes(value);
}
export const StaticDataTypeCandidateEventCategoryValues = [
  "DocumentExpiry",
  "DocumentResubmission",
  "Permit",
  "Exam",
  "Interview",
  "Housing",
  "Occupation",
  "Residence",
] as const;
export type StaticDataTypeCandidateEventCategory = (typeof StaticDataTypeCandidateEventCategoryValues)[number];
export function isStaticDataTypeCandidateEventCategory(value: any): value is StaticDataTypeCandidateEventCategory {
  return StaticDataTypeCandidateEventCategoryValues.includes(value);
}
export const StaticDataTypeCandidateEventTimeframeValues = [
  "Expired",
  "007-DAYS",
  "030-DAYS",
  "060-DAYS",
  "090-DAYS",
  "120-DAYS",
  "150-DAYS",
  "180-DAYS",
  "210-DAYS",
  "365-DAYS",
] as const;
export type StaticDataTypeCandidateEventTimeframe = (typeof StaticDataTypeCandidateEventTimeframeValues)[number];
export function isStaticDataTypeCandidateEventTimeframe(value: any): value is StaticDataTypeCandidateEventTimeframe {
  return StaticDataTypeCandidateEventTimeframeValues.includes(value);
}
export const StaticDataTypePreferredTypeOfImmigrationValues = [
  "INTERN",
  "TRAINEE",
  "CONTRACT",
  "FSJ",
  "RECOGPARTNER",
  "RECOGNITION",
  "CHANCE",
] as const;
export type StaticDataTypePreferredTypeOfImmigration = (typeof StaticDataTypePreferredTypeOfImmigrationValues)[number];
export function isStaticDataTypePreferredTypeOfImmigration(
  value: any
): value is StaticDataTypePreferredTypeOfImmigration {
  return StaticDataTypePreferredTypeOfImmigrationValues.includes(value);
}
export const StaticDataTypePreferredFamilyReunionValues = [
  "REUNION",
  "REUNIONSHORT",
  "REUNIONLONG",
  "REUNIONAFTERRECOG",
  "NOREUNION",
] as const;
export type StaticDataTypePreferredFamilyReunion = (typeof StaticDataTypePreferredFamilyReunionValues)[number];
export function isStaticDataTypePreferredFamilyReunion(value: any): value is StaticDataTypePreferredFamilyReunion {
  return StaticDataTypePreferredFamilyReunionValues.includes(value);
}
export const StaticDataTypeVaccineFunctionValues = ["Immigration", "StartOfEmployment"] as const;
export type StaticDataTypeVaccineFunction = (typeof StaticDataTypeVaccineFunctionValues)[number];
export function isStaticDataTypeVaccineFunction(value: any): value is StaticDataTypeVaccineFunction {
  return StaticDataTypeVaccineFunctionValues.includes(value);
}
export const StaticDataTypeWorkingTimeTypeValues = ["FULLTIME", "PARTIME"] as const;
export type StaticDataTypeWorkingTimeType = (typeof StaticDataTypeWorkingTimeTypeValues)[number];
export function isStaticDataTypeWorkingTimeType(value: any): value is StaticDataTypeWorkingTimeType {
  return StaticDataTypeWorkingTimeTypeValues.includes(value);
}
export const StaticDataTypeRecognitionCenterValues = [
  "IHKHANNOVER",
  "IHKBRAUNSCHWEIG",
  "IHKWUPPERTAL",
  "IHKFOSA",
  "HWK",
] as const;
export type StaticDataTypeRecognitionCenter = (typeof StaticDataTypeRecognitionCenterValues)[number];
export function isStaticDataTypeRecognitionCenter(value: any): value is StaticDataTypeRecognitionCenter {
  return StaticDataTypeRecognitionCenterValues.includes(value);
}
export const StaticDataTypeSignatureAdditionValues = ["PERPROCURA", "POA", "REPRESENT", "CONTRACT"] as const;
export type StaticDataTypeSignatureAddition = (typeof StaticDataTypeSignatureAdditionValues)[number];
export function isStaticDataTypeSignatureAddition(value: any): value is StaticDataTypeSignatureAddition {
  return StaticDataTypeSignatureAdditionValues.includes(value);
}
export const StaticDataTypeCooperationTypeValues = ["PARTNER", "CUSTOMER"] as const;
export type StaticDataTypeCooperationType = (typeof StaticDataTypeCooperationTypeValues)[number];
export function isStaticDataTypeCooperationType(value: any): value is StaticDataTypeCooperationType {
  return StaticDataTypeCooperationTypeValues.includes(value);
}
export const StaticDataTypeCertificateOfConductValues = [
  "SIMPLE_AGENCY_OB",
  "ADVANCED_AGENCY_OE",
  "SIMPLE_PRIVAT_NB",
  "ADVANCED_PRIVAT_NE",
] as const;
export type StaticDataTypeCertificateOfConduct = (typeof StaticDataTypeCertificateOfConductValues)[number];
export function isStaticDataTypeCertificateOfConduct(value: any): value is StaticDataTypeCertificateOfConduct {
  return StaticDataTypeCertificateOfConductValues.includes(value);
}
export const StaticDataTypeSuitabilityForWorkValues = ["SUITABLE", "UNSUITABLE"] as const;
export type StaticDataTypeSuitabilityForWork = (typeof StaticDataTypeSuitabilityForWorkValues)[number];
export function isStaticDataTypeSuitabilityForWork(value: any): value is StaticDataTypeSuitabilityForWork {
  return StaticDataTypeSuitabilityForWorkValues.includes(value);
}
export const StaticDataTypeVocationalSchoolTrainingTimeTotalValues = [
  "1",
  "1,5",
  "2",
  "2,5",
  "3",
  "3,5",
  "4",
  "4,5",
] as const;
export type StaticDataTypeVocationalSchoolTrainingTimeTotal =
  (typeof StaticDataTypeVocationalSchoolTrainingTimeTotalValues)[number];
export function isStaticDataTypeVocationalSchoolTrainingTimeTotal(
  value: any
): value is StaticDataTypeVocationalSchoolTrainingTimeTotal {
  return StaticDataTypeVocationalSchoolTrainingTimeTotalValues.includes(value);
}
export const StaticDataTypeVocationalSchoolTrainingTimeCurrentValues = ["1", "2", "3", "4"] as const;
export type StaticDataTypeVocationalSchoolTrainingTimeCurrent =
  (typeof StaticDataTypeVocationalSchoolTrainingTimeCurrentValues)[number];
export function isStaticDataTypeVocationalSchoolTrainingTimeCurrent(
  value: any
): value is StaticDataTypeVocationalSchoolTrainingTimeCurrent {
  return StaticDataTypeVocationalSchoolTrainingTimeCurrentValues.includes(value);
}
export const StaticDataTypeQualificationExamsValues = ["ADAPTATION", "KNOWLEDGE", "ELIGIBILITY"] as const;
export type StaticDataTypeQualificationExams = (typeof StaticDataTypeQualificationExamsValues)[number];
export function isStaticDataTypeQualificationExams(value: any): value is StaticDataTypeQualificationExams {
  return StaticDataTypeQualificationExamsValues.includes(value);
}
export const StaticDataTypeLaborAgreementTypeValues = [
  "CollectiveAgreement",
  "WorksOrService",
  "WageCollective",
  "FrameworkOrGeneralCollective",
  "InHouseCollective",
  "AssociationCollective",
] as const;
export type StaticDataTypeLaborAgreementType = (typeof StaticDataTypeLaborAgreementTypeValues)[number];
export function isStaticDataTypeLaborAgreementType(value: any): value is StaticDataTypeLaborAgreementType {
  return StaticDataTypeLaborAgreementTypeValues.includes(value);
}
export const StaticDataTypeLaborContractTypeValues = [
  "CollectiveAgreement",
  "WorksOrService",
  "WageCollective",
  "FrameworkOrGeneralCollective",
  "InHouseCollective",
  "AssociationCollective",
  "AgreementByContract",
] as const;
export type StaticDataTypeLaborContractType = (typeof StaticDataTypeLaborContractTypeValues)[number];
export function isStaticDataTypeLaborContractType(value: any): value is StaticDataTypeLaborContractType {
  return StaticDataTypeLaborContractTypeValues.includes(value);
}
export const StaticDataTypeLaborContractAllowanceValues = ["OVERTIME", "NIGHTSHIFT", "SUNDAY", "HOLIDAY"] as const;
export type StaticDataTypeLaborContractAllowance = (typeof StaticDataTypeLaborContractAllowanceValues)[number];
export function isStaticDataTypeLaborContractAllowance(value: any): value is StaticDataTypeLaborContractAllowance {
  return StaticDataTypeLaborContractAllowanceValues.includes(value);
}
export const StaticDataTypeLaborContractWorkScheduleValues = [
  "FIXED_WORKING_HOURS",
  "ROTATING_SHIFTS",
  "SHIFT_WORK",
] as const;
export type StaticDataTypeLaborContractWorkSchedule = (typeof StaticDataTypeLaborContractWorkScheduleValues)[number];
export function isStaticDataTypeLaborContractWorkSchedule(
  value: any
): value is StaticDataTypeLaborContractWorkSchedule {
  return StaticDataTypeLaborContractWorkScheduleValues.includes(value);
}
