import {
  Component,
  ContentChild,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router } from "@angular/router";
import { UserPermission } from "@ankaadia/ankaadia-shared";
import {
  AdHocEducationExamCreateInput,
  AdHocEducationExamFragment,
  AdHocEducationExamUpdateInput,
  EducationExamCreateInput,
  EducationExamFragment,
  EducationExamUpdateInput,
  EducationModuleForSelectionFragment,
  ExamType,
  StaticDataType,
} from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { isEmpty } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { finalize, Observable, tap } from "rxjs";
import { AppDatePipe, AppDateWithTimePipe } from "../../../../shared/pipes/date.pipe";
import { EnumPipe } from "../../../../shared/pipes/enum.pipe";
import { SettingsService } from "../../../../shared/services/settings.service";
import { StaticDataPipe } from "../../../../shared/static-data/static-data.pipe";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";
import { PipeDescription, TableColumn, TableOperation, TableOperationMode } from "../../../../shared/table/table.model";
import { Message, MessageService } from "../../../message/message.service";
import { EducationCourseService } from "../../education-courses/education-course.service";
import { EducationExamService } from "../education-exam.service";

const noSelectedExam: NoEducationExamData = { kind: "none" };

@Component({
  selector: "app-education-exam-table",
  templateUrl: "./education-exam-table.component.html",
  styleUrl: "./education-exam-table.component.scss",
  standalone: false,
})
export class EducationExamTableComponent implements OnChanges {
  @Input()
  exams: ExamFragment[] = [];

  @Input()
  modules: EducationModuleForSelectionFragment[] = [];

  @Input()
  isReadOnly = false;

  @Input()
  search = true;

  @Input()
  title: string;

  @Input()
  shownColumns: EducationExamTableColumnName[];

  @Input()
  shownNewOperations: "all" | "ad-hoc" | "none" = "none";

  @Input()
  shownTableOperations: TableOperationId[];

  @Input()
  additionalNewOperations: TableOperation[];

  @Input()
  additionalTableOperations: TableOperation[];

  @Input()
  createAdHocOrganizationId: string = this.settingsService.organizationId;

  @Input()
  virtualScroll = false;

  @Output()
  readonly examCreated = new EventEmitter<ExamFragment>();

  @Output()
  readonly examUpdated = new EventEmitter<ExamFragment>();

  @ContentChild("caption", { read: TemplateRef })
  captionTemplate: TemplateRef<any>;

  private readonly language = this.transloco.getActiveLang();

  private readonly allTableOperations: (TableOperation & { tableId: TableOperationId })[] = [
    {
      tableId: "edit",
      label: translate("common.edit"),
      icon: PrimeIcons.PENCIL,
      operation: (exam: ExamFragment): void => this.editOperation(exam),
      disabled: (): boolean => !this.hasFullAccess || this.isBusy || this.isReadOnly,
    },
    {
      tableId: "users",
      label: translate("educationExams.showParticipants"),
      icon: PrimeIcons.USER_EDIT,
      operation: (exam: ExamFragment): void => this.manageParticipants(exam),
      disabled: (): boolean => this.isBusy || this.isReadOnly,
    },
    {
      tableId: "results",
      label: translate("educationExams.showResults"),
      icon: PrimeIcons.CALCULATOR,
      operation: (exam: ExamFragment): void => this.manageResults(exam),
      disabled: (): boolean => this.isBusy || this.isReadOnly,
    },
    {
      tableId: "delete",
      label: translate("common.delete"),
      icon: PrimeIcons.TRASH,
      operation: (exam: ExamFragment, e: Event): void => this.deleteOperation(exam, e),
      disabled: (): boolean => !this.hasFullAccess || this.isBusy || this.isReadOnly,
    },
  ];

  private readonly allColumns: EducationExamTableColumn[] = [
    {
      header: translate("name.title"),
      fieldname: "name",
      sortable: true,
      includeInGlobalFilter: true,
      className: (_): string => "width-20",
    },
    {
      header: translate("examType.title"),
      fieldname: "examType",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(EnumPipe, "ExamType"),
    },
    {
      header: translate("examInstitution.title"),
      fieldname: "examInstitution",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(StaticDataPipe, StaticDataType.ExamInstitutions),
    },
    {
      header: translate("examModules.title"),
      fieldname: "examModules",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(EnumPipe, "ExamModuleType"),
    },
    {
      header: translate("examLocation.title"),
      fieldname: "examLocation",
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("plannedExamDateAndTime.title"),
      fieldname: "plannedExamDateAndTime",
      pipeDescription: new PipeDescription(
        AppDateWithTimePipe,
        { dateStyle: "medium" },
        { dateStyle: "medium", timeStyle: "short" },
        this.language
      ),
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("examDateAndTime.title"),
      fieldname: "examDateAndTime",
      pipeDescription: new PipeDescription(
        AppDateWithTimePipe,
        { dateStyle: "medium" },
        { dateStyle: "medium", timeStyle: "short" },
        this.language
      ),
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("plannedExamResultDate.title"),
      fieldname: "plannedExamResultDate",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(AppDatePipe, { dateStyle: "medium" }, this.language),
    },
    {
      header: translate("responsibleRolePayment.title"),
      fieldname: "responsibleRolePayment",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(StaticDataPipe, StaticDataType.ProcessRole),
    },
  ];

  private readonly hasFullAccess = this.settingsService.hasAnyPermission([
    UserPermission.Administrator,
    UserPermission.CourseAdministrator,
  ]);

  protected readonly courses$ = this.courseService.fetch(this.settingsService.organizationId);
  protected readonly examTypes = this.staticDataService.transformEnumToStaticDataModel("ExamType", ExamType);

  protected columns: TableColumn[] = this.allColumns;

  protected newOperations: TableOperation[] = [];

  protected tableOperations: TableOperation[] = [];

  selectedExam: EducationExamData | AdHocEducationExamData | NoEducationExamData = noSelectedExam;
  showSidebar: boolean;
  isBusy: boolean;

  constructor(
    private readonly courseService: EducationCourseService,
    private readonly educationExamService: EducationExamService,
    private readonly settingsService: SettingsService,
    private readonly messageService: MessageService,
    private readonly confirmationService: ConfirmationService,
    private readonly transloco: TranslocoService,
    private readonly staticDataService: StaticDataService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isReadOnly || changes.overrideTableOperations || changes.shownNewOperations || changes.modules)
      this.setTableOperations();
    if (changes.shownColumns) this.setColumns();
  }

  save(
    exam:
      | EducationExamCreateInput
      | EducationExamUpdateInput
      | AdHocEducationExamCreateInput
      | AdHocEducationExamUpdateInput
  ): void {
    if (this.selectedExam.kind === "none") return;
    if (this.selectedExam.data.id) this.updateExam(exam);
    else this.createExam(exam);
  }

  saveAdHoc(exam: AdHocEducationExamCreateInput | AdHocEducationExamUpdateInput): void {
    if (this.selectedExam.kind === "none") return;
    if (this.selectedExam.data.id) this.updateExam(exam);
    else this.createExam(exam);
  }

  close(): void {
    this.selectedExam = noSelectedExam;
    this.showSidebar = false;
  }

  private createOperation(kind: "education" | "ad-hoc"): void {
    this.openSideBar({
      kind: kind,
      data: {
        organizationId: this.createAdHocOrganizationId,
      },
    });
  }

  private editOperation(exam: ExamFragment): void {
    if (exam.__typename === "EducationExam") this.openSideBar({ kind: "education", data: exam });
    else this.openSideBar({ kind: "ad-hoc", data: exam });
  }

  private openSideBar(exam: EducationExamData | AdHocEducationExamData): void {
    this.selectedExam = exam;
    this.showSidebar = true;
  }

  private deleteOperation(exam: ExamFragment, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("educationExam.confirmDelete", exam),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        const delete$ =
          exam.__typename === "EducationExam"
            ? this.educationExamService.delete(exam)
            : this.educationExamService.deleteAdHoc(exam);

        delete$
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            tap(() => this.messageService.add(this.getSuccessMessage(exam, "delete")))
          )
          .subscribe();
      },
    });
  }

  private createExam(exam: EducationExamCreateInput): void {
    this.isBusy = true;

    const create$: Observable<EducationExamFragment | AdHocEducationExamFragment> =
      this.selectedExam.kind === "education"
        ? this.educationExamService.create(exam)
        : this.educationExamService.createAdHoc(<AdHocEducationExamCreateInput>exam);

    create$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((exam) => {
          this.messageService.add(this.getSuccessMessage(exam, "create"));
          this.close();
        }),
        tap((exam) => this.examCreated.emit(exam)),
        finalize(() => (this.isBusy = false))
      )
      .subscribe();
  }

  private updateExam(exam: EducationExamUpdateInput | AdHocEducationExamUpdateInput): void {
    this.isBusy = true;

    const update$: Observable<EducationExamFragment | AdHocEducationExamFragment> =
      this.selectedExam.kind === "education"
        ? this.educationExamService.update(exam)
        : this.educationExamService.updateAdHoc(<AdHocEducationExamUpdateInput>exam);

    update$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((exam) => {
          this.messageService.add(this.getSuccessMessage(exam, "update"));
          this.close();
        }),
        tap((exam) => this.examUpdated.emit(exam)),
        finalize(() => (this.isBusy = false))
      )
      .subscribe();
  }

  private getSuccessMessage(
    exam: EducationExamFragment | AdHocEducationExamFragment,
    type: "create" | "update" | "delete"
  ): Message {
    switch (type) {
      case "create":
        return {
          severity: "success",
          summary: translate("educationModule.created.title"),
          detail: translate("educationModule.created.message", exam),
        };
      case "update":
        return {
          severity: "success",
          summary: translate("educationExam.updated.title"),
          detail: translate("educationExam.updated.message", exam),
        };
      case "delete":
        return {
          severity: "success",
          summary: translate("educationExam.deleted.title"),
          detail: translate("educationExam.deleted.message", exam),
        };
    }
  }

  private setTableOperations(): void {
    this.tableOperations = this.getTableOperations();
    this.newOperations = this.getAllNewOperations();
  }

  private getAllNewOperations(): TableOperation[] {
    return [...this.getSelectedNewOperations(), ...(this.additionalNewOperations ?? [])];
  }

  private getSelectedNewOperations(): TableOperation[] {
    if (this.shownNewOperations === "none") return [];
    if (this.shownNewOperations === "ad-hoc")
      return [
        {
          label: translate("educationExams.addExam"),
          icon: PrimeIcons.PLUS,
          operation: (): void => this.createOperation("ad-hoc"),
          disabled: (): boolean => !this.hasFullAccess || this.isBusy || this.isReadOnly,
        },
      ];

    const isAdHocDefaultOperation = isEmpty(this.modules);

    return [
      {
        label: translate(isAdHocDefaultOperation ? "educationExams.addExam" : "educationExams.addModuleExam"),
        icon: PrimeIcons.PLUS,
        mode: TableOperationMode.SplitButton,
        operation: (): void => this.createOperation(isAdHocDefaultOperation ? "ad-hoc" : "education"),
        disabled: (): boolean => !this.hasFullAccess || this.isBusy || this.isReadOnly,
        items: [
          {
            label: translate(!isAdHocDefaultOperation ? "educationExams.addExam" : "educationExams.addModuleExam"),
            icon: PrimeIcons.PLUS,
            operation: (): void => this.createOperation(!isAdHocDefaultOperation ? "ad-hoc" : "education"),
          },
        ],
      },
    ];
  }

  private getTableOperations(): TableOperation[] {
    return [
      ...this.allTableOperations.filter((x) => this.shownTableOperations?.includes(x.tableId)),
      ...(this.additionalTableOperations ?? []),
    ];
  }

  private setColumns(): void {
    if (isEmpty(this.shownColumns)) this.columns = this.allColumns;
    else this.columns = this.shownColumns.map((column) => this.allColumns.find((x) => x.fieldname === column));
  }

  private manageParticipants(exam: ExamFragment): void {
    void this.router.navigate([`../exam-participants/${exam.organizationId}/${exam.id}`], { relativeTo: this.route });
  }

  private manageResults(exam: ExamFragment): void {
    void this.router.navigate([`../exam-results/${exam.id}`], { relativeTo: this.route });
  }
}

type TableOperationId = "edit" | "users" | "results" | "delete";

type EducationExamTableColumn = TableColumn & { fieldname: EducationExamTableColumnName };

export type EducationExamTableColumnName = keyof ExamFragment;

interface EducationExamData {
  kind: "education";
  data: Partial<EducationExamFragment>;
}

interface AdHocEducationExamData {
  kind: "ad-hoc";
  data: Partial<AdHocEducationExamFragment>;
}

interface NoEducationExamData {
  kind: "none";
}

export type ExamFragment = EducationExamFragment | AdHocEducationExamFragment;
