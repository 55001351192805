import { Injectable } from "@angular/core";
import { AbstractControl, FormArray } from "@angular/forms";
import { FieldActivityDiffFragment } from "@ankaadia/graphql";
import { entries } from "lodash";
import { finalize, Subject, Subscription } from "rxjs";
import { validate as uuidValidate } from "uuid";
import { ClickCaptureService } from "../../../shared/click-capture/click-capture.service";
import { ActivityDiffService } from "../../activity/activity-diff/activity-diff.service";
import { CandidateFormComponent } from "../../candidates/candidate-form/candidate-form.component";

@Injectable({ providedIn: "root" })
export class CandidateFieldDiffService {
  private readonly fieldDiff$ = new Subject<FieldActivityDiffFragment[]>();
  private isLoading: boolean;
  private subscription: Subscription;
  private _canBeActivated = true;
  // we need to maintain our own state because the clickCapturingService is used by other components as well
  private _isOn = false;

  get canBeActivated(): boolean {
    return this._canBeActivated;
  }

  candidateFormComponent: CandidateFormComponent;

  readonly fieldDiff = this.fieldDiff$.asObservable();

  get isOn(): boolean {
    return this._isOn;
  }

  constructor(
    private readonly clickCaptureService: ClickCaptureService,
    private readonly activityDiffService: ActivityDiffService
  ) {
    // In order to disallow the double usage of the service
    this.clickCaptureService.onStateChanged.subscribe((x) => (this._canBeActivated = !x));
  }

  toggle(event: MouseEvent): void {
    if (this.isOn) {
      this.disable();
    } else {
      this.enable(event);
    }
  }

  enable(event: MouseEvent): void {
    if (this._canBeActivated) {
      this.clickCaptureService.clickTroughForDropdown = false;
      this.clickCaptureService.enableAllFormControlsAtHovering = true;
      this.clickCaptureService.tabClickEnabled = true;
      this.clickCaptureService.buttonClickEnabled = true;
      this.clickCaptureService.turnOn(event.target);
      this._isOn = true;
      this.subscription?.unsubscribe();
      this.subscription = this.clickCaptureService.formControlClicked.subscribe((x) => this.clicked(x));
    }
  }

  disable(): void {
    if (this._canBeActivated || this.isOn) {
      this.clickCaptureService.turnOff();
      this._isOn = false;
      this.subscription?.unsubscribe();
      this.subscription = null;
    }
  }

  resume(): void {
    this.clickCaptureService.resume();
  }

  suspend(): void {
    this.clickCaptureService.suspend();
  }

  private clicked(control: AbstractControl): void {
    if (!this.isLoading) {
      this.isLoading = true;
      const [parentPath, parentEntryId, path] = this.getPath(control);
      this.activityDiffService
        .getFieldActivityDiff(
          this.candidateFormComponent.candidate.id,
          this.candidateFormComponent.candidate.__typename,
          this.candidateFormComponent.candidate.organizationId,
          parentPath,
          parentEntryId,
          path
        )
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe((diff) => this.fieldDiff$.next(diff));
    }
  }

  /**
   * Given a form control, return full path to it from the root form in a format `[ parentPath, parentEntryId, path ]`.
   * The path is supposed to be sent to the activity diff endpoints.
   *
   * Example:
   * ``` ts
   * const [parentPath, parentEntryId, path] = getPath(formControl);
   * expect(parentPath).toEqual("os.profile.experiences");
   * expect(parentEntryId).toEqual("4903649c-fd8d-4283-b260-7dd77133ce04");
   * expect(path).toEqual("additionalData.startDate");
   * ```
   */
  private getPath(control: AbstractControl): [string, string, string] {
    function internal(control: AbstractControl, path: string[]): string[] {
      if (control.parent) {
        if (control.parent instanceof FormArray) {
          return internal(control.parent, [control.get("id").value, ...path]);
        } else {
          for (const [key, child] of entries(control.parent.controls)) {
            if (child === control) {
              return internal(control.parent, [key, ...path]);
            }
          }
        }
      }
      return path;
    }

    const path = internal(control, []);
    const entryIdIndex = path.findIndex((x) => uuidValidate(x));
    return entryIdIndex > -1
      ? [path.slice(0, entryIdIndex).join("."), path[entryIdIndex], path.slice(entryIdIndex + 1).join(".")]
      : [null, null, path.join(".")];
  }
}
