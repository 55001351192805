import { Pipe, PipeTransform } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { map, Observable } from "rxjs";
import { DateFormatterService } from "../../../shared/services/date-formatter.service";
import { DecimalFormatterService } from "../../../shared/services/decimal-formatter.service";
import { CandidateProfileFieldConfigurationService } from "./candidate-profile-field-configuration.service";
import { CandidateProfileFieldPipe } from "./candidate-profile-field.pipe";

@Pipe({
  name: "candidateProfileFieldValue",
  standalone: false,
})
export class CandidateProfileFieldValuePipe extends CandidateProfileFieldPipe implements PipeTransform {
  constructor(
    fieldConfigurationService: CandidateProfileFieldConfigurationService,
    private readonly transloco: TranslocoService,
    private readonly dateFormatter: DateFormatterService,
    private readonly decimalFormatter: DecimalFormatterService
  ) {
    super(fieldConfigurationService);
  }

  transform(value: any, path: string): Observable<string> {
    return this.getPropertyDefinition(path).pipe(
      map(({ field }) => {
        if (!field) {
          return value;
        }

        switch (field.type) {
          case "boolean":
            return Array.isArray(value)
              ? value.map((x) => this.transloco.translate(`enum.boolean.${x}`)).join(", ")
              : this.transloco.translate(`enum.boolean.${value}`);
          case "date":
            return Array.isArray(value)
              ? value.map((x) => this.dateFormatter.transformDate(x, {})).join(", ")
              : this.dateFormatter.transformDate(value, {});
          case "integer":
            return Array.isArray(value)
              ? value.map((x) => this.decimalFormatter.transform(x, {})).join("; ")
              : this.decimalFormatter.transform(value, {});
          case "float":
            return Array.isArray(value)
              ? value.map((x) => this.decimalFormatter.transform(x, { minimumFractionDigits: 2 })).join("; ")
              : this.decimalFormatter.transform(value, { minimumFractionDigits: 2 });
          case "select":
            return Array.isArray(value)
              ? value.map((x) => (x ? (field.options.find((opt) => opt.value === x)?.label ?? value) : "")).join(", ")
              : value
                ? (field.options.find((x) => x.value === value)?.label ?? value)
                : "";
          case "multiselect":
            return value
              ? field.options
                  .filter((x) => value.includes(x.value))
                  .map((x) => x.label)
                  .join(", ")
              : "";
          default:
            return value;
        }
      })
    );
  }
}
