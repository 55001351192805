import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors } from "@angular/forms";
import { Predicate, WithoutField } from "@ankaadia/ankaadia-shared";
import { forIn, isEmpty, isNil, stubFalse } from "lodash";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { FormModel } from "ngx-mf";
import { Observable, defer, filter, startWith } from "rxjs";

export type GraphQLFormModel<TModel, TAnnotations extends Record<string, any> | null = null> = FormModel<
  WithoutField<TModel, "__typename">,
  TAnnotations
>;

export type NestedForms<T> = T extends FormGroup
  ? T["controls"][keyof T["controls"]]
  : T extends FormArray
    ? T["controls"] extends (infer U)[]
      ? U
      : never
    : never;

export type AllFormGroups<T> = T extends FormGroup | FormArray ? T | AllFormGroups<NestedForms<T>> : never;

/**
 * Returns the raw value of a form control.
 * Workaround for breaking change in ngx-formly >= 6.0.0, which returns undefined instead of null for empty values.
 */
export function getRawValue(form: AbstractControl): any {
  if (form instanceof FormGroup) {
    const rawValue: Record<string, any> = {};

    for (const key in form.controls) {
      rawValue[key] = getRawValue(form.controls[key]);
    }

    return rawValue;
  }

  if (form instanceof FormArray) {
    const rawValue: any[] = [];

    for (const control of form.controls) {
      rawValue.push(getRawValue(control));
    }

    return rawValue;
  }

  const rawValue = form.getRawValue();

  return rawValue === undefined ? null : rawValue;
}

/**
 * Triggers `valueChanges` et al. events top-down, like {@link AbstractControl.updateValueAndValidity} but the other way around.
 *
 * Use this at the end of a code block that uses `{ emitEvent: false }`.
 */
export function emitEvents(form: AbstractControl): void {
  form.setValue(getRawValue(form));
}

/**
 * Adds a single error to the control.
 */
export function addError(control: AbstractControl, error: string): void {
  control.setErrors({ ...control.errors, [error]: true });
}

/**
 * Removes a single error from the control.
 */
export function removeError(control: AbstractControl, error: string): void {
  if (control.errors) {
    delete control.errors[error];
    if (!Object.keys(control.errors).length) {
      control.setErrors(null);
    } else {
      control.setErrors(control.errors);
    }
  }
}

/**
 * Sets a single error on the control.
 */
export function setError(control: AbstractControl, error: string, set: boolean): void {
  if (set) {
    addError(control, error);
  } else {
    removeError(control, error);
  }
}

/**
 * Creates an Observable that emits every time the value of the control changes, starting with the initial value of the control.
 * If you want `null` and `undefined` values to be filtered out, see {@link notNullValuesOf}.
 */
export function valuesOf<T>(control: AbstractControl<any, T>): Observable<T | null> {
  return defer(() => control.valueChanges.pipe(startWith(control.value)));
}

/**
 * Creates an Observable that emits every time the value of the control changes, starting with the initial value of the control.
 * `null` and `undefined` values are filtered out. If this is not the behavior you want, see {@link valuesOf}.
 */
export function notNullValuesOf<T>(control: AbstractControl<any, T>): Observable<T> {
  return defer(() =>
    control.valueChanges.pipe(
      startWith(control.value),
      filter((x) => !isNil(x))
    )
  );
}

/**
 * Recursively updates the value and validity of a form and all its children.
 * @param form The form to update.
 */
export function updateValueAndValidity<T extends FormGroup | FormArray>(form: T): void {
  const controls = form.controls ?? [];
  if (isEmpty(controls)) {
    form.updateValueAndValidity();
  } else {
    forIn(controls, (_, key) => updateValueAndValidity(controls[key]));
  }
}

type FixedTypeFormArray<TControl extends AbstractControl> = Omit<FormArray<TControl>, "controls"> & {
  controls: TControl[];
};

/**
 * Removes all elements of the FormArray based on a predicate.
 * @param formArray The FormArray to remove controls from.
 * @param selector The control(s) to remove. Can be a single control, an array of controls, or a predicate.
 */
export function removeControls<TControl extends AbstractControl>(
  formArray: FixedTypeFormArray<TControl>,
  selector: TControl | TControl[] | Predicate<ReturnType<TControl["getRawValue"]>>
): void {
  const predicate = (control: TControl): boolean => {
    if (typeof selector === "function") {
      return selector(control.getRawValue());
    }

    if (Array.isArray(selector)) {
      return selector.includes(control);
    }

    return selector === control;
  };

  formArray.controls
    .map((control, index) => ({ control, index }))
    .filter(({ control }) => predicate(control))
    .reverse()
    .forEach(({ index }) => formArray.removeAt(index));
}

/**
 * Validates the uniqueness of a property in a FormArray.
 * @param formArray The FormArray to validate.
 * @param getValue A function that returns the value to compare.
 * @param ignoreEntry A function that returns true if the entry should be ignored.
 */
export function validatePropertyUniqueness<TControl extends FormGroup>(
  formArray: FixedTypeFormArray<TControl>,
  getValue: (control: TControl) => string | number | null,
  ignoreEntry?: (control: TControl) => boolean
): ValidationErrors | null {
  const ignore = ignoreEntry ?? stubFalse;
  const indices = formArray.controls
    .map((currentControl) => {
      if (ignore(currentControl)) {
        return false;
      }

      const currentValue = getValue(currentControl);
      if (!currentValue) {
        return false;
      }

      return formArray.controls
        .filter((control) => control !== currentControl)
        .some((control) => getValue(control) === currentValue);
    })
    .map((isDuplicate, index) => (isDuplicate ? index : null))
    .filter((index) => index !== null);

  return indices.length > 0 ? { unique: indices } : null;
}

/**
 * Synchronizes the values of two controls, ensuring that the minimum value is not greater than the maximum value.
 * @param minControl The control that should be the minimum.
 * @param maxControl The control that should be the maximum.
 */
export function syncMinMax<T>(minControl: FormControl<T>, maxControl: FormControl<T>): void {
  valuesOf(minControl).subscribe(() => {
    if (!isNil(maxControl.value) && minControl.value > maxControl.value) {
      maxControl.setValue(minControl.value);
    }
  });

  valuesOf(maxControl).subscribe(() => {
    if (!isNil(minControl.value) && minControl.value > maxControl.value) {
      minControl.setValue(maxControl.value);
    }
  });
}

export function syncMaxToMin<T>(minControl: FormControl<T>, maxControl: FormControl<T>): void {
  valuesOf(minControl).subscribe(() => {
    if (!isNil(maxControl.value) && minControl.value > maxControl.value) {
      maxControl.setValue(minControl.value);
    }
  });
}
