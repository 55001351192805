import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MAX_SELECTED_MULTI_EDIT_CANDIDATES } from "@ankaadia/ankaadia-shared";
import { CollectionAndSharingInput, StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { FilterMetadata } from "primeng/api";
import { Table, TableLazyLoadEvent } from "primeng/table";
import { Observable } from "rxjs";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import {
  CustomLazyLoadEvent,
  FilterMetadataMap,
} from "../../collections/collection-edit-assigned-candidates/custom-filter.model";
import { CandidatePageEntry } from "../candidates.service";
import { CurrentCollectionService } from "../current-collection.service";

@Component({
  selector: "app-candidate-selection",
  templateUrl: "./candidate-selection.component.html",
  standalone: false,
})
export class CandidateSelectionComponent implements OnInit, OnChanges {
  readonly StaticDataType = StaticDataType;
  protected filterCountryOptions: Observable<StaticDataModel[]>;

  @Input()
  candidates: CandidatePageEntry[];

  @Input()
  totalCandidates: number;

  @Input()
  candidatesPerPage: number;

  @Input()
  deletionRequestsSet: Set<string>;

  @Output()
  readonly loadCandidates = new EventEmitter<CustomLazyLoadEvent>();

  @Output()
  readonly collectionCreate = new EventEmitter<CollectionAndSharingInput>();

  private _selectedCandidates: CandidatePageEntry[];

  @Input()
  set selectedCandidates(candidates: CandidatePageEntry | CandidatePageEntry[]) {
    if (Array.isArray(candidates)) {
      this._selectedCandidates = candidates;
    } else {
      this._selectedCandidates = [candidates];
    }

    this.selectedCandidatesChange.emit(this._selectedCandidates);
  }

  get selectedCandidates(): CandidatePageEntry[] {
    return this._selectedCandidates;
  }

  @Input()
  selectedCandidateIds: readonly string[] = [];

  @Output()
  readonly selectedCandidatesChange = new EventEmitter<readonly CandidatePageEntry[]>();

  private _isMultiEditActive: boolean;

  @Input()
  set isMultiEditActive(enabled: boolean) {
    if (this._isMultiEditActive !== enabled) {
      this._isMultiEditActive = enabled;
      this.onMultiEditToggle(enabled);
      this.isMultiEditActiveChange.emit(enabled);
    }
  }

  get isMultiEditActive(): boolean {
    return this._isMultiEditActive;
  }

  @Output()
  readonly isMultiEditActiveChange = new EventEmitter<boolean>();

  @ViewChild(Table)
  tableComponent: Table;

  protected filterState: CustomLazyLoadEvent;
  protected searchFilter: string;
  protected turnOffTable: boolean;

  @Input()
  isSelectAllActive: boolean;

  @Input()
  showMultiEditToggle: boolean;

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly transloco: TranslocoService,
    private readonly currentCollectionService: CurrentCollectionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedCandidateIds || changes.candidates) {
      const exitingIds = this.selectedCandidates?.filter((x) => !!x).map((x) => x.id) ?? [];
      const newIds = this.selectedCandidateIds ?? [];
      const hasChanges = exitingIds.length !== newIds.length || exitingIds.some((x) => !newIds.includes(x));

      if (hasChanges) {
        this.selectedCandidates = this.candidates?.filter((x) => newIds.includes(x.id)) ?? [];
      }
    }
  }

  ngOnInit(): void {
    this.filterCountryOptions = this.staticDataService.getStaticData(
      StaticDataType.Countries,
      this.transloco.getActiveLang()
    );
  }

  protected onRowSelect(candidate: CandidatePageEntry): void {
    if (this.isMultiEditActive) {
      const selectedCandidates = this.selectedCandidates;
      if (selectedCandidates.length) {
        this.selectMultiEdit(candidate);
      }
    } else {
      this.selectSingleEdit(candidate);
    }
  }

  protected onRowUnselect(): void {
    if (this.isMultiEditActive) {
      const selectedCandidates = this.selectedCandidates;
      if (!selectedCandidates.length) {
        this.unselectCandidate();
        this.isSelectAllActive = false;
      }
    } else {
      this.unselectCandidate();
    }
  }

  protected onLazyLoad(filter: TableLazyLoadEvent): void {
    filter.filters.custom = this.filterState?.filters?.custom;
    filter.filters.global = this.filterState?.filters?.global;
    filter.filters.favorite = this.filterState?.filters?.favorite;

    this.filterState = filter;
    this.loadCandidates.emit(this.filterState);
  }

  protected onSearchChanged(filter: string): void {
    this.searchFilter = filter;
    this.tableComponent.filterGlobal(filter, "contains");
  }

  protected onFilterChanged(custom: FilterMetadataMap): void {
    this.filterState ??= { filters: {} };
    this.filterState.filters.custom = custom;
    this.filterState.first = 0;
    this.tableComponent.first = 0;
    this.loadCandidates.emit(this.filterState);
  }

  protected onFavoriteChanged(favorite: FilterMetadata | null): void {
    this.filterState ??= { filters: {} };
    this.filterState.filters.favorite = favorite;
    this.loadCandidates.emit(this.filterState);
  }

  protected onMultiEditToggle(isActive: boolean): void {
    if (this.selectedCandidates) {
      this.unselectCandidate();
    }

    if (isActive) {
      this.selectedCandidates = [];
    } else {
      this.selectedCandidates = null;
      this.isSelectAllActive = false;
    }

    // force table re-render because just changing the selection mode does not work
    this.turnOffTable = true;
    setTimeout(() => {
      this.isMultiEditActive = isActive;
      setTimeout(() => {
        this.turnOffTable = false;
      }, 10);
    }, 10);
  }

  protected onMultiAllSelect(isSelected: boolean): void {
    this.selectedCandidates = isSelected
      ? this.candidates
          .filter(
            (x) =>
              x.organizationId === this.candidates[0].organizationId &&
              x.immigrationCountry === this.candidates[0].immigrationCountry
          )
          .slice(0, MAX_SELECTED_MULTI_EDIT_CANDIDATES)
      : [];
    this.isSelectAllActive = isSelected;
    if (isSelected) {
      this.onRowSelect(this.selectedCandidates[0]);
    } else {
      this.onRowUnselect();
    }
  }

  protected canSelectForMultiEdit(candidate: CandidatePageEntry): boolean {
    const selectedCandidates = this.selectedCandidates;
    if (!selectedCandidates?.length) {
      return true;
    }
    if (selectedCandidates.some((x) => x.id === candidate.id)) {
      return true;
    }
    if (selectedCandidates.length >= MAX_SELECTED_MULTI_EDIT_CANDIDATES) {
      return false;
    }
    return !(
      candidate.organizationId !== selectedCandidates[0].organizationId ||
      candidate.immigrationCountry !== selectedCandidates[0].immigrationCountry
    );
  }

  protected canSelectAllForMultiEdit(): boolean {
    const selectedCandidates = this.selectedCandidates;
    if (this.isSelectAllActive) {
      return true;
    }
    if (!selectedCandidates) {
      return true;
    }
    return selectedCandidates.length < MAX_SELECTED_MULTI_EDIT_CANDIDATES;
  }

  protected onCollectionCreate(collection: CollectionAndSharingInput): void {
    this.filterState ??= { filters: {} };
    this.collectionCreate.emit({
      ...collection,
      existing:
        this.currentCollectionService.collectionId != null
          ? {
              colId: this.currentCollectionService.collectionId,
              orgId: this.currentCollectionService.organizationId,
              selectedSharingId: this.currentCollectionService.sharingId,
            }
          : null,
      filters: this.filterState.filters,
    });
  }

  resetFilters(): void {
    this.filterState = { filters: {} };
    this.tableComponent.reset();
  }

  private unselectCandidate(): void {
    void this.router.navigate(["edit"], { relativeTo: this.route.parent });
  }

  private selectMultiEdit(candidate: CandidatePageEntry): void {
    void this.router.navigate([`edit/${candidate.organizationId}/multi`], { relativeTo: this.route.parent });
  }

  private selectSingleEdit(candidate: CandidatePageEntry): void {
    void this.router.navigate([`edit/${candidate.organizationId}/${candidate.id}`], { relativeTo: this.route.parent });
  }
}
