import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import {
  Candidate,
  Collection,
  CollectionAutoFilterTemplate,
  SharingTypeEnum,
  StaticDataType,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { EnumPipe } from "apps/frontend/src/app/shared/pipes/enum.pipe";
import { clone } from "lodash";
import { Message, PrimeIcons, TreeNode } from "primeng/api";
import { Subscription } from "rxjs";
import { SettingsService } from "../../../../shared/services/settings.service";
import { StaticDataPipe } from "../../../../shared/static-data/static-data.pipe";
import { PipeDescription, TableColumn } from "../../../../shared/table/table.model";
import { CandidateFilterField } from "../../../candidate-filter/candidate-filter.model";
import { CandidatesService } from "../../../candidates/candidates.service";
import { CollectionAutoFilterTemplateService } from "../../collection-auto-template/collection-auto-filter-template.service";
import { FilterMetadataMap } from "../../collection-edit-assigned-candidates/custom-filter.model";
import { CollectionAutoFilterService } from "../collection-auto-filter.service";
import { CollectionFromTemplateService } from "./collection-from-template.service";
import { CandidateFlexibleEditService } from "../../../candidates/candidate-flexible-edit/candidate-flexible-edit.service";

@Component({
  selector: "app-collection-from-template",
  templateUrl: "./collection-from-template.component.html",
  standalone: false,
})
export class CollectionFromTemplateComponent implements OnInit, OnDestroy, OnChanges {
  @Input({ required: true }) collection: Collection;
  @Input() isEditable: boolean;
  @Output() readonly reload = new EventEmitter<void>();

  protected assignedCandidates: Candidate[] | null;
  private subscription?: Subscription;

  protected columns: TableColumn[] = [
    {
      header: translate("number.title"),
      fieldname: "displayId",
      routeLink: (x: Candidate): any[] | null => this.getCandidateRouteLink(x),
      sortable: true,
      includeInGlobalFilter: true,
      includeFlag: true,
      canIconClick: () => true,
      icon: () => PrimeIcons.USER_EDIT,
      iconClick: (candidate: Candidate) =>
        this.candidateFlexibleEditService.openCandidate(candidate.id, candidate.organizationId),
      iconClass: "ml-2",
    },
    { header: translate("name.title"), fieldname: "displayName", sortable: true, includeInGlobalFilter: true },
    {
      header: translate("status.title"),
      fieldname: "status",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(EnumPipe, "CandidateStatus"),
    },
    {
      header: translate("country.title"),
      fieldname: "country",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(StaticDataPipe, StaticDataType.Countries),
    },
  ];

  protected showDialog = false;
  protected messages: Message[] | undefined;
  private _filter: FilterMetadataMap;
  protected fields: TreeNode<CandidateFilterField>[] = [];
  protected autoCollectionTemplate: CollectionAutoFilterTemplate;

  constructor(
    private readonly settings: SettingsService,
    private readonly candidateService: CandidatesService,
    private readonly collectionAutoTemplateService: CollectionAutoFilterTemplateService,
    private readonly collectionAutoFilterService: CollectionAutoFilterService,
    private readonly collectionAutoFilterFieldsService: CollectionFromTemplateService,
    private readonly candidateFlexibleEditService: CandidateFlexibleEditService
  ) {}

  protected get filter(): FilterMetadataMap {
    return this._filter;
  }

  protected set filter(value: FilterMetadataMap) {
    this._filter = value;
  }

  protected get includeSharedCandidates(): boolean {
    return this.autoCollectionTemplate?.includeSharedCandidates ?? false;
  }

  ngOnInit(): void {
    this.subscription = this.collectionAutoFilterFieldsService.getFields().subscribe((fields) => {
      this.fields = fields;
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnChanges(): void {
    if (this.collection != null) {
      if (this.collection.autoCollectionSettings?.filterId) {
        this.collectionAutoFilterService
          .get({
            id: this.collection.autoCollectionSettings.filterId,
            organizationId: this.collection.organizationId,
          })
          .subscribe((x) => {
            this.filter = x.filters;
          });
      } else {
        this.filter = {};
      }
      if (this.collection.autoCollectionSettings?.templateMapping?.templateId) {
        this.collectionAutoTemplateService
          .get({
            id: this.collection.autoCollectionSettings.templateMapping.templateId,
            organizationId: this.collection.organizationId,
          })
          .subscribe((x) => {
            this.autoCollectionTemplate = x;
          });
      } else {
        this.autoCollectionTemplate = null;
      }

      this.assignedCandidates = clone(this.collection.assignedCandidates);
    } else {
      this.assignedCandidates = null;
    }
  }

  editAutoFilter(): void {
    this.showDialog = true;
  }

  displayCandidateLinks(): boolean {
    if (this.settings.organizationId === this.collection.organizationId) {
      return true;
    }
    return this.collection.sharings.some(
      (s) =>
        [SharingTypeEnum.Collaboration, SharingTypeEnum.ReadOnlyCollaboration].includes(s.sharingType) &&
        s.destinationOrganizationId === this.settings.organizationId
    );
  }

  getCandidateRouteLink(candidate: Candidate): any[] | null {
    return this.displayCandidateLinks()
      ? this.candidateService.getCandidateEditLink(candidate.id, candidate.organizationId)
      : null;
  }

  getTemplateRouteLink(template: CollectionAutoFilterTemplate): any[] | null {
    return !template
      ? null
      : ["/app/collections/collection-auto-filter-templates", template.organizationId, template.id];
  }
}
