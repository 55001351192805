<ng-container *appTranslate="let t">
  <form *ngIf="form && laborAgreements" [formGroup]="form" (ngSubmit)="onSubmit()" class="user-form">
    <!--<pre>{{ form | formErrors | json }}</pre>-->
    <p-card [header]="isEdit ? t('contractTemplate.edit') : t('contractTemplate.create')">
      <div class="p-fluid col-12 grid">
        <div class="col-12 lg:col-4 flex flex-column gap-3">
          <p-fieldset [legend]="t('contractTemplate.details')">
            <div class="grid">
              <div class="field col-12" *ngIf="!hideName">
                <label for="name">{{ t("contractTemplate.name") }}</label>
                <input
                  id="name"
                  [formControl]="form.controls.name"
                  [placeholder]="t('name.placeholder')"
                  type="text"
                  pInputText
                />
                <small class="p-error" *ngIf="form.controls.name.errors?.required">
                  {{ t("name.required") }}
                </small>
                <small class="p-error" *ngIf="form.controls.name.errors?.unique">
                  {{ t("name.unique") }}
                </small>
              </div>

              <div class="field col-12">
                <label for="contractType">{{ t("contractTemplate.contractType.title") }}</label>
                <p-dropdown
                  inputId="contractType"
                  [formControl]="form.controls.contractType"
                  [placeholder]="t('contractTemplate.contractType.placeholder')"
                  [options]="contractTypes"
                  [showClear]="true"
                />
                <small class="p-error" *ngIf="form.controls.contractType.errors?.required">
                  {{ t("required") }}
                </small>
              </div>

              <div class="field col-12 md:col-6">
                <label for="collectiveAgreement">
                  {{
                    isForAustria
                      ? t("contractTemplate.collectiveAgreementAT")
                      : t("contractTemplate.collectiveAgreement")
                  }}
                </label>
                <p-autoComplete
                  inputId="laborAgreement"
                  [dropdown]="true"
                  [suggestions]="filteredLaborAgreements"
                  [placeholder]="t('laborAgreement.placeholder')"
                  [disabled]="form.controls.laborAgreementSelection.disabled"
                  [ngModel]="{
                    id: laborAgreementId,
                    name: getAutoCompleteLabel(),
                  }"
                  [ngModelOptions]="{ standalone: true }"
                  (ngModelChange)="updateLaborAgreement($event)"
                  (completeMethod)="cloneLaborAgreements()"
                  dataKey="id"
                  optionLabel="name"
                />
                <small
                  class="p-error"
                  *ngIf="
                    form.controls.laborAgreementSelection.controls.laborAgreementId.errors?.required ||
                    form.controls.customLaborAgreement.controls.collectiveAgreement.errors?.required
                  "
                >
                  {{ t("required") }}
                </small>
              </div>

              <div class="field col-12 md:col-6">
                <ng-container *ngIf="isForAustria; else defaultSalaryGroupLabel">
                  <label for="salaryGroup">{{ t("contractTemplate.salaryGroupAU") }}</label>
                </ng-container>
                <ng-template #defaultSalaryGroupLabel>
                  <label for="salaryGroup">{{ t("contractTemplate.salaryGroup") }}</label>
                </ng-template>
                <ng-container *ngIf="laborAgreementId; else customLaborAgreement">
                  <p-dropdown
                    inputId="payGradeId"
                    [formControl]="form.controls.laborAgreementSelection.controls.payGradeId"
                    [placeholder]="
                      isForAustria ? t('contractTemplate.salaryGroupAU') : t('contractTemplate.salaryGroup')
                    "
                    [options]="payGrades"
                    [showClear]="true"
                  />
                  <small
                    class="p-error"
                    *ngIf="form.controls.laborAgreementSelection.controls.payGradeId.errors?.required"
                  >
                    {{ t("required") }}
                  </small>
                </ng-container>
                <ng-template #customLaborAgreement>
                  <input
                    id="salaryGroup"
                    [formControl]="form.controls.customLaborAgreement.controls.salaryGroup"
                    [placeholder]="
                      isForAustria ? t('contractTemplate.salaryGroupAU') : t('contractTemplate.salaryGroup')
                    "
                    type="text"
                    pInputText
                  />
                  <small
                    class="p-error"
                    *ngIf="form.controls.customLaborAgreement.controls.salaryGroup.errors?.required"
                  >
                    {{ t("required") }}
                  </small>
                </ng-template>
              </div>

              <div class="field col-12 md:col-6 lg:col-6">
                <label for="employmentRelationshipType">
                  {{ t("contractTemplate.employmentRelationshipType.title") }}
                </label>
                <p-dropdown
                  inputId="employmentRelationshipType"
                  [formControl]="form.controls.employmentRelationshipType"
                  [placeholder]="t('contractTemplate.employmentRelationshipType.placeholder')"
                  [options]="employmentRelationshipTypes"
                  [showClear]="true"
                />
                <small class="p-error" *ngIf="form.controls.employmentRelationshipType.errors?.required">
                  {{ t("required") }}
                </small>
              </div>

              <div class="field col-12 md:col-6 lg:col-6">
                <label for="workingTimeType">
                  {{ t("contractTemplate.workingTimeType.title") }}
                </label>
                <p-dropdown
                  inputId="workingTimeType"
                  [formControl]="form.controls.workingTimeType"
                  [placeholder]="t('contractTemplate.workingTimeType.placeholder')"
                  [options]="workingTimeTypes"
                  [showClear]="true"
                />
                <small class="p-error" *ngIf="form.controls.workingTimeType.errors?.required">
                  {{ t("required") }}
                </small>
              </div>

              <div class="field col-12 md:col-6 lg:col-6">
                <label for="workSchedule">
                  {{ t("contractTemplate.workSchedule.title") }}
                </label>
                <p-dropdown
                  inputId="workSchedule"
                  [formControl]="form.controls.workSchedule"
                  [placeholder]="t('contractTemplate.workSchedule.placeholder')"
                  [options]="workSchedules$ | async"
                  [showClear]="true"
                />
                <small class="p-error" *ngIf="form.controls.workSchedule.errors?.required">
                  {{ t("required") }}
                </small>
              </div>

              <div class="field col-12 md:col-6 lg:col-6">
                <label for="workingHoursPerWeek">
                  {{ t("contractTemplate.workingHoursPerWeek") }}
                </label>
                <p-inputNumber
                  inputId="workingHoursPerWeek"
                  [formControl]="form.controls.workingHoursPerWeek"
                  [placeholder]="t('contractTemplate.workingHoursPerWeek')"
                  [maxFractionDigits]="1"
                  [min]="0"
                />
                <small class="p-info" *ngIf="getOverride('workingHoursPerWeek') as override">
                  <i class="pi pi-link"></i>
                  {{
                    getOverrideLabel(override, {
                      minimumIntegerDigits: 1,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })
                  }}
                </small>
                <small class="p-error" *ngIf="form.controls.workingHoursPerWeek.errors?.required">
                  {{ t("required") }}
                </small>
              </div>

              <div class="field col-12">
                <label for="holidayEntitlement">{{ t("contractTemplate.holidayEntitlement") }}</label>
                <p-inputNumber
                  inputId="holidayEntitlement"
                  [formControl]="form.controls.holidayEntitlement"
                  [placeholder]="t('contractTemplate.holidayDaysPerYear')"
                  [maxFractionDigits]="1"
                  [min]="0"
                />
                <small class="p-error" *ngIf="form.controls.holidayEntitlement.errors?.required">
                  {{ t("required") }}
                </small>
              </div>

              <div class="field col-12 md:col-6 lg:col-6">
                <label for="probationPeriod">
                  {{ t("laborAgreement.probationPeriod.title") }}
                </label>
                <p-inputNumber
                  inputId="probationPeriod"
                  [formControl]="form.controls.probationPeriod"
                  [placeholder]="t('laborAgreement.probationPeriod.placeholder')"
                  [maxFractionDigits]="0"
                  [min]="0"
                />
                <small class="p-info" *ngIf="getOverride('probationPeriod') as override">
                  <i class="pi pi-link"></i>
                  {{
                    getOverrideLabel(override, {
                      minimumIntegerDigits: 1,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })
                  }}
                </small>
                <small class="p-error" *ngIf="form.controls.probationPeriod.errors?.required">
                  {{ t("required") }}
                </small>
              </div>

              <div class="field col-12 md:col-6 lg:col-6">
                <label for="probationPeriodUnit">
                  {{ t("enumNames.ContractTermTimePeriod") }}
                </label>
                <p-selectButton
                  [options]="timePeriods"
                  [formControl]="form.controls.probationPeriodUnit"
                  [allowEmpty]="false"
                  optionLabel="label"
                  optionValue="value"
                />
                <small class="p-info" *ngIf="getOverride('probationPeriodUnit') as override">
                  <i class="pi pi-link"></i> {{ getOverrideLabel(override, { staticData: timePeriods }) }}
                </small>
                <small class="p-error" *ngIf="form.controls.probationPeriodUnit.errors?.required">
                  {{ t("required") }}
                </small>
              </div>

              <div class="field col-12 md:col-6 lg:col-6">
                <label for="noticePeriod">
                  {{ t("laborAgreement.noticePeriod.title") }}
                </label>
                <p-inputNumber
                  inputId="noticePeriod"
                  [formControl]="form.controls.noticePeriod"
                  [placeholder]="t('laborAgreement.noticePeriod.placeholder')"
                  [maxFractionDigits]="0"
                  [min]="0"
                />
                <small class="p-info" *ngIf="getOverride('noticePeriod') as override">
                  <i class="pi pi-link"></i>
                  {{
                    getOverrideLabel(override, {
                      minimumIntegerDigits: 1,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })
                  }}
                </small>
                <small class="p-error" *ngIf="form.controls.noticePeriod.errors?.required">
                  {{ t("required") }}
                </small>
              </div>

              <div class="field col-12 md:col-6 lg:col-6">
                <label for="noticePeriodUnit">
                  {{ t("enumNames.ContractTermTimePeriod") }}
                </label>
                <p-selectButton
                  [options]="timePeriods"
                  [formControl]="form.controls.noticePeriodUnit"
                  [allowEmpty]="false"
                  optionLabel="label"
                  optionValue="value"
                />
                <small class="p-info" *ngIf="getOverride('noticePeriodUnit') as override">
                  <i class="pi pi-link"></i> {{ getOverrideLabel(override, { staticData: timePeriods }) }}
                </small>
                <small class="p-error" *ngIf="form.controls.noticePeriodUnit.errors?.required">
                  {{ t("required") }}
                </small>
              </div>

              <div class="field col-12 md:col-6 lg:col-6">
                <label for="overtimeHours">{{ t("contractTemplate.overtimeRegulation") }}</label>
                <p-inputNumber
                  inputId="overtimeHours"
                  [formControl]="form.controls.overtimeHours"
                  [placeholder]="t('contractTemplate.overtimeHours')"
                  [maxFractionDigits]="1"
                  [min]="0"
                />
                <small class="p-error" *ngIf="form.controls.overtimeHours.errors?.required">
                  {{ t("required") }}
                </small>
              </div>

              <div class="field col-12 md:col-6 lg:col-6">
                <label for="overtimeCompensation">{{ t("contractTemplate.overtimeCompensation") }}</label>
                <input
                  id="overtimeCompensation"
                  [formControl]="form.controls.overtimeCompensation"
                  [placeholder]="t('contractTemplate.overtimeCompensation')"
                  type="text"
                  pInputText
                />
              </div>

              <div class="field-checkbox col-12" *ngIf="!isForAustria">
                <p-checkbox
                  inputId="overtimeWithinLimitsPermittedByLaw"
                  [formControl]="form.controls.overtimeWithinLimitsPermittedByLaw"
                  [binary]="true"
                />
                <label for="overtimeWithinLimitsPermittedByLaw">
                  {{ t("contractTemplate.overtimeWithinLimitsPermittedByLaw") }}
                </label>
                <small class="p-error" *ngIf="form.controls.overtimeWithinLimitsPermittedByLaw.errors?.required">
                  {{ t("required") }}
                </small>
              </div>

              <div class="field col-12 md:col-6 lg:col-6">
                <label for="validFrom">{{ t("validFrom.title") }}</label>
                <p-calendar inputId="validFrom" [formControl]="form.controls.validFrom" #calendar [showClear]="true" />
                <small class="p-error" *ngIf="form.controls.validFrom.errors?.required">
                  {{ t("required") }}
                </small>
              </div>

              <div class="field col-12 md:col-6 lg:col-6">
                <label for="validUntil">{{ t("validUntil.title") }}</label>
                <p-calendar
                  inputId="validUntil"
                  [formControl]="form.controls.validUntil"
                  #calendar
                  [showClear]="true"
                />
                <small class="p-error" *ngIf="form.controls.validUntil.errors?.required">
                  {{ t("required") }}
                </small>
              </div>
            </div>
          </p-fieldset>

          <p-fieldset [legend]="t('contractTemplate.professionDescription')">
            <div class="grid">
              <div class="field col-12">
                <label for="professionTitle">{{ t("contractTemplate.professionTitle") }}</label>
                <input
                  id="professionTitle"
                  [formControl]="form.controls.professionTitle"
                  [placeholder]="t('contractTemplate.professionTitle')"
                  type="text"
                  pInputText
                />
                <small class="p-error" *ngIf="form.controls.professionTitle.errors?.required">
                  {{ t("required") }}
                </small>
              </div>
              <div class="field col-12">
                <label for="professionDescription">{{ t("contractTemplate.professionDescription") }}</label>
                <textarea
                  id="professionDescription"
                  [formControl]="form.controls.professionDescription"
                  [placeholder]="t('contractTemplate.professionDescription')"
                  pInputTextarea
                  maxlength="5000"
                  rows="3"
                ></textarea>
                <small class="p-error" *ngIf="form.controls.professionDescription.errors?.required">
                  {{ t("required") }}
                </small>
              </div>
              <div class="field col-12">
                <label for="ezbAdditionalInformation">{{ t("contractTemplate.ezbAdditionalInformation") }}</label>
                <input
                  id="ezbAdditionalInformation"
                  type="text"
                  pInputText
                  formControlName="ezbAdditionalInformation"
                />
              </div>
            </div>
          </p-fieldset>
        </div>
        <!-- column-break -->
        <div class="col-12 lg:col-8 flex flex-column gap-3">
          <p-fieldset [legend]="t('contractTemplate.compensationRate.title')">
            <div class="grid">
              <div *ngIf="userShouldCheckCompensation" class="field col-12">
                <p-message severity="warn" [text]="t('contractTemplate.compensationRate.warning')" />
                <!--<small class="p-warning">{{ t("contractTemplate.compensationRate.warning") }}</small>-->
              </div>
              <div class="field col-12" [ngClass]="{ 'md:col-4': isForAustria, 'md:col-6': !isForAustria }">
                <label for="compensationType">
                  {{ t("contractTemplate.compensationType.title") }}
                </label>
                <p-dropdown
                  inputId="compensationType"
                  [formControl]="form.controls.compensationType"
                  [placeholder]="t('contractTemplate.compensationType.placeholder')"
                  [options]="compensationTypes"
                  [showClear]="true"
                />
                <small class="p-info" *ngIf="getOverride('compensationType') as override">
                  <i class="pi pi-link"></i> {{ getOverrideLabel(override, { staticData: compensationTypes }) }}
                </small>
                <small class="p-error" *ngIf="form.controls.compensationType.errors?.required">
                  {{ t("required") }}
                </small>
              </div>
              <div class="field col-12" [ngClass]="{ 'md:col-4': isForAustria, 'md:col-6': !isForAustria }">
                <label for="compensationRate">
                  {{ t("contractTemplate.compensationRate.title") }}
                </label>
                <p-inputNumber
                  inputId="compensationRate"
                  [formControl]="form.controls.compensationRate"
                  [placeholder]="t('contractTemplate.compensationRate.placeholder')"
                  [minFractionDigits]="2"
                  [min]="0"
                />
                <small class="p-info" *ngIf="getOverride('compensationRate') as override">
                  <i class="pi pi-link"></i> {{ getOverrideLabel(override) }}
                </small>
                <small class="p-error" *ngIf="form.controls.compensationRate.errors?.required">
                  {{ t("required") }}
                </small>
              </div>
              <div class="field col-12 md:col-4" *ngIf="isForAustria">
                <label for="numberOfSalaries">{{ t("contractTemplate.numberOfSalaries.title") }}</label>
                <p-inputNumber
                  inputId="numberOfSalaries"
                  [formControl]="form.controls.numberOfSalaries"
                  [placeholder]="t('contractTemplate.numberOfSalaries.placeholder')"
                  [maxFractionDigits]="2"
                  [min]="0"
                  [max]="24"
                />
                <small class="p-error" *ngIf="form.controls.numberOfSalaries.errors?.required">
                  {{ t("required") }}
                </small>
              </div>
              <div class="field col-12">
                <label for="otherPaymentRegulation">{{ t("contractTemplate.otherPaymentRegulation") }}</label>
                <input
                  id="otherPaymentRegulation"
                  [formControl]="form.controls.otherPaymentRegulation"
                  [placeholder]="t('contractTemplate.otherPaymentRegulationExplanation')"
                  type="text"
                  pInputText
                />
                <small class="p-error" *ngIf="form.controls.otherPaymentRegulation.errors?.required">
                  {{ t("required") }}
                </small>
              </div>
            </div>
          </p-fieldset>
          <p-fieldset [legend]="t('laborContract.allowances.title')">
            <app-contract-template-allowances
              [organizationId]="form.controls.organizationId.value"
              [immigrationCountry]="form.controls.country.value"
              [form]="form.controls.allowances"
              [laborAgreement]="laborAgreement"
            />
          </p-fieldset>
          <p-fieldset [legend]="t('laborContract.benefits.title')">
            <app-contract-template-benefits
              [organizationId]="form.controls.organizationId.value"
              [immigrationCountry]="form.controls.country.value"
              [form]="form.controls.benefits"
              [laborAgreement]="laborAgreement"
            />
          </p-fieldset>
        </div>
      </div>

      <ng-template pTemplate="footer">
        <div class="flex flex-row gap-2 align-items-center justify-content-end">
          <small *ngIf="form.errors?.generic" class="p-error mr-2">{{ t("contractTemplate.errors.generic") }}</small>
          <p-button
            [disabled]="!form.valid || !form.dirty"
            [label]="t('common.save')"
            icon="pi pi-check"
            type="submit"
            class="mr-2"
          />
          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            type="reset"
            (onClick)="closed.emit()"
          />
        </div>
      </ng-template>
    </p-card>
  </form>
</ng-container>
