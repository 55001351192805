<app-table
  [columns]="columns"
  [items]="collections"
  [tableOperations]="tableOperations"
  [captionOperations]="captionOperations"
  [(showSidebar)]="showDialog"
>
  <app-collection-auto-template-attach-dialog
    *ngIf="showDialog"
    [visible]="true"
    [template]="collectionAutoTemplate"
    (closed)="dialogClosed()"
  ></app-collection-auto-template-attach-dialog>
</app-table>
