import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { SharedModule } from "../../../../shared/shared.module";
import { CandidateFlexibleEditService } from "../candidate-flexible-edit.service";
import { CandidatesService } from "../../candidates.service";
import { map } from "rxjs";

@Component({
  selector: "app-candidate-flexible-edit-button",
  templateUrl: "./candidate-flexible-edit-button.component.html",
  styleUrl: "./candidate-flexible-edit-button.component.scss",
  standalone: true,
  imports: [SharedModule],
})
export class CandidateFlexibleEditButtonComponent implements OnChanges {
  @Input({ required: true }) candidateId: string;
  @Input({ required: true }) candidateOrganizationId: string;
  @Input({ required: true }) label: string;

  @Input() country: string;
  @Input() showFlag = true;
  @Input() showLink = true;

  link: string[];

  isDialogClosed$ = this.service.isDialogOpen$.pipe(map((x) => !x));

  constructor(
    private readonly service: CandidateFlexibleEditService,
    private readonly candidateService: CandidatesService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateId || changes.candidateOrganizationId) {
      this.link = this.getRouteLink();
    }
  }

  protected showCandidateInDialog(): void {
    this.service.openCandidate(this.candidateId, this.candidateOrganizationId);
  }

  private getRouteLink(): string[] | null {
    if (!this.candidateId || !this.candidateOrganizationId) return null;
    return this.candidateService.getCandidateEditLink(this.candidateId, this.candidateOrganizationId);
  }
}
