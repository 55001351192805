<ng-container *ngIf="showControl">
  <ng-container *ngIf="translations$ | async as trans">
    <app-candidate-aligned-label
      class="field"
      [labelFor]="controlName"
      [labelText]="trans.caption"
      [useAlignmentCheckBox]="false"
    >
    </app-candidate-aligned-label>

    <p-dropdown
      [inputId]="controlName"
      optionValue="id"
      optionLabel="name"
      [options]="organizations"
      [formControl]="control"
      [showClear]="true"
      [placeholder]="trans.placeholder"
      [readonly]="readonly"
      appCandidateFieldNotShared
      [isShared]="
        sharingState != null &&
        (sharingState.targetOrganizationShared || control.value == null) &&
        sharingState.organizationsSharedByCandidateOwner
      "
      formControlValueAlwaysInOptions
      (onChange)="valueChanged.emit($event.value)"
    >
    </p-dropdown>

    <small class="p-error" *ngIf="control.errors?.required">
      {{ trans.required }}
    </small>
  </ng-container>
</ng-container>
