import { Component, Input } from "@angular/core";
import { FormArray } from "@angular/forms";
import { QualificationModule } from "@ankaadia/graphql";
import { assign } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { QualificationMeasureModuleFormDe } from "../../candidate-form.model";
import { CandidateFormService } from "../../candidate-form.service";

@Component({
  selector: "app-candidate-qualification-modules",
  templateUrl: "./candidate-qualification-modules.component.html",
  standalone: false,
})
export class CandidateQualificationModulesComponent {
  @Input()
  form: FormArray<QualificationMeasureModuleFormDe>;

  @Input()
  readonly: boolean;

  constructor(private readonly formService: CandidateFormService) {}

  add(): void {
    this.form.push(
      this.formService.createQualificationModuleGroup(assign(new QualificationModule(), { id: uuidv4() }))
    );
  }

  remove(index: number): void {
    this.form.removeAt(index);
  }
}
