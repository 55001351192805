<p-card>
  <app-table
    [controlRef]="form.controls.members"
    [columns]="columns"
    [items]="form.controls.members.value"
    [captionOperations]="captionOperations"
    [tableOperations]="tableOperations"
    [(showSidebar)]="showDialog"
    sidebarSize="large"
  >
    <app-candidate-family-member-edit-dialog
      *ngIf="showDialog"
      [member]="selectedMember"
      [emergencyContactIsSet]="checkIfEmergencyContactIsSet()"
      [allMembers]="form.controls.members.value"
      (closed)="close()"
      (saved)="onSave($event)"
    ></app-candidate-family-member-edit-dialog>
  </app-table>
</p-card>
