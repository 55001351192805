import { Directive, OnInit, SimpleChanges } from "@angular/core";
import { isEqual, partition } from "lodash";
import { MultiSelect } from "primeng/multiselect";
import { Subject } from "rxjs";
import { overrideHook } from "../../services/hackers";

const subject = new Subject<string>();

@Directive({ selector: "p-multiSelect", standalone: false })
export class MultiSelectSortSelectedValuesOnTopDirective implements OnInit {
  private readonly writeValue: MultiSelect["writeValue"];

  selectedValues: any[] = [];
  options: any[] = [];

  constructor(private readonly multiSelect: MultiSelect) {
    this.writeValue = this.multiSelect.writeValue;
    this.multiSelect.writeValue = (value: any): void => this.overwrittenWriteValue(this.multiSelect, value);
  }

  ngOnInit(): void {
    subject.subscribe((value) => {
      if (this.multiSelect.el.nativeElement.isSameNode(value)) {
        this.options = this.multiSelect.options;
        this.sortSelectedValuesOnTop();
      }
    });
  }

  private overwrittenWriteValue(multiSelect: MultiSelect, value: any): void {
    this.selectedValues = value;
    this.writeValue.call(multiSelect, value);
    return this.sortSelectedValuesOnTop();
  }

  private sortSelectedValuesOnTop(): void {
    if (this.options?.length > 0 && this.selectedValues?.length > 0) {
      const [selected, unselected] = partition(this.options, (x) => this.selectedValues.find((y) => y === x.value));
      const sortedOptions = [...selected, ...unselected];
      if (isEqual(this.multiSelect.options, sortedOptions)) {
        return;
      }
      this.multiSelect.options = sortedOptions;
      this.multiSelect.cd.detectChanges();
    }
  }
}

overrideHook(MultiSelect, "ngOnChanges", function (this, changes: SimpleChanges) {
  if (changes.options && !isEqual(changes.options.currentValue, changes.options.previousValue)) {
    subject.next(this.el.nativeElement);
  }
});
