import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { emailPattern, nameofFactory } from "@ankaadia/ankaadia-shared";
import { GuetegemeinschaftOrganizationReCertificationInput, StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { Observable } from "rxjs";
import { StaticDataService } from "../../shared/static-data/static-data.service";

const nameOF = nameofFactory<GuetegemeinschaftOrganizationReCertificationInput>();

@Component({
  selector: "app-guetegemeinschaft-organization-creation",
  templateUrl: "./gueteGemeinschaftOrganizationCreation.component.html",
  standalone: false,
})
export class OrganizationCreationComponent implements OnInit {
  form: FormGroup;
  salutations$: Observable<StaticDataModel[]>;
  organizationTypes$: Observable<StaticDataModel[]>;
  nameOf = nameOF;

  @Input()
  message: string;

  @Input()
  buttonLabel: string;

  @Output()
  readonly organizationCreated = new EventEmitter<GuetegemeinschaftOrganizationReCertificationInput>();

  @Output()
  readonly navigatedBack = new EventEmitter<void>();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly staticDataService: StaticDataService
  ) {}

  ngOnInit(): void {
    this.salutations$ = this.staticDataService.getStaticData(StaticDataType.Salutation);
    this.organizationTypes$ = this.staticDataService.getStaticData(StaticDataType.OrganizationType);
    this.form = this.formBuilder.group({
      [nameOF("organizationName")]: [null, Validators.compose([Validators.required, Validators.maxLength(200)])],
      [nameOF("organizationType")]: [null, Validators.required],
      [nameOF("salutation")]: [null, Validators.required],
      [nameOF("firstName")]: [null, Validators.compose([Validators.required, Validators.maxLength(200)])],
      [nameOF("lastName")]: [null, [Validators.compose([Validators.required, Validators.maxLength(200)])]],
      [nameOF("emailAddress")]: [
        null,
        Validators.compose([Validators.pattern(emailPattern), Validators.maxLength(300)]),
      ],
      [nameOF("emailAddress2")]: [
        null,
        Validators.compose([Validators.pattern(emailPattern), Validators.maxLength(300)]),
      ],
    });
    this.form.addValidators((form) => {
      return (form.get(nameOF("emailAddress")).value as string)?.toLocaleLowerCase() ===
        (form.get(nameOF("emailAddress2")).value as string)?.toLocaleLowerCase()
        ? null
        : { emailAddressMismatch: true };
    });
  }

  submit(_event: any): void {
    if (this.form.valid) {
      this.organizationCreated.emit(this.form.value);
    }
  }

  back(): void {
    this.form.reset();
    this.navigatedBack.emit();
  }
}
