<ng-container *appTranslate="let t">
  <p-card [header]="t('adHocExamResults.add')">
    <div class="p-fluid formgrid">
      <div class="field">
        <label for="name">{{ t("educationExam.title") }}</label>
        <p-dropdown
          inputId="relation"
          [(ngModel)]="exam"
          [options]="options"
          [placeholder]="t('educationExam.placeholder')"
          optionLabel="name"
        >
        </p-dropdown>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <app-edit-tool-bar
        class="flex flex-row justify-content-end"
        [showBtn]="{ cancel: true, save: true }"
        [saveBtn]="{ disabled: !exam }"
        (canceled)="cancel()"
        (saved)="save()"
      ></app-edit-tool-bar>
    </ng-template>
  </p-card>
</ng-container>
