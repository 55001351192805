<ng-container *appTranslate="let t">
  <ng-container *ngIf="readonly">
    <div
      *ngIf="control.value as value"
      class="p-tag p-component text-base app-tag-success white-space-nowrap text-overflow-ellipsis overflow-hidden"
    >
      <span class="p-tag-value">{{ value | staticData: StaticDataType.Functions | async }}</span>
    </div>
  </ng-container>
  <div class="field" *ngIf="!readonly">
    <label for="function">{{ t("function.title") }}</label>
    <p-dropdown
      styleClass="w-full"
      [formControl]="control"
      [options]="functions$ | async"
      [placeholder]="t('function.placeholder')"
      id="function"
    ></p-dropdown>
  </div>
</ng-container>
