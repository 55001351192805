import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { DateTimeForm } from "../../../../shared/date-time/date-time.component";
import { EducationExamFormService } from "../education-exam-dialog/education-exam-form.service";
import { AdHocEducationExamForm } from "./education-exam-ad-hoc-form.model";

@Injectable({ providedIn: "root" })
export class AdHocEducationExamFormService {
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly educationExamFormService: EducationExamFormService
  ) {}

  createForm(): AdHocEducationExamForm {
    return this.formBuilder.group<AdHocEducationExamForm["controls"]>(
      {
        id: this.formBuilder.control(null),
        organizationId: this.formBuilder.control(null),
        name: this.formBuilder.control(null, [Validators.required]),
        languageLevel: this.formBuilder.control(null, [Validators.required]),
        examType: this.formBuilder.control(null, [Validators.required]),
        examInstitution: this.formBuilder.control(null),
        examModules: this.formBuilder.control(null),
        examLocation: this.formBuilder.control(null),
        plannedExamDateAndTime: this.formBuilder.group<DateTimeForm["controls"]>({
          date: this.formBuilder.control(null),
          hasTime: this.formBuilder.control(false),
        }),
        plannedExamDateAndTimeConfirmed: this.formBuilder.control(false),
        examDateAndTime: this.formBuilder.group<DateTimeForm["controls"]>({
          date: this.formBuilder.control(null),
          hasTime: this.formBuilder.control(false),
        }),
        plannedExamResultDate: this.formBuilder.control(null),
        responsibleRolePayment: this.formBuilder.control(null),
        moduleType: this.formBuilder.control(null),
        _etag: this.formBuilder.control(null),
      },
      { validators: this.educationExamFormService.validateExamModules }
    );
  }
}
