<div class="layout-wrapper p-input-filled layout-overlay-menu" [class.layout-wrapper-menu-active]="sidebarActive">
  <app-topbar></app-topbar>

  <app-sidebar #sidebar></app-sidebar>

  <div class="layout-main">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>

  <p-confirmPopup></p-confirmPopup>
  <p-confirmDialog key="confirmDeactivate"></p-confirmDialog>

  <app-user-feedback></app-user-feedback>
  <app-notifications></app-notifications>
  <app-message-error></app-message-error>
  <div #candidateEditDialogProxy></div>
</div>
