<app-document-templates
  *ngIf="!isReportMode"
  [templates]="templates"
  [collectionId]="collection?.id"
  [organizationId]="collection?.organizationId"
  mode="collection"
  (reload)="loadTemplates($event)"
></app-document-templates>

<app-report-templates
  *ngIf="isReportMode"
  [collectionId]="collection?.id"
  [collectionOrganizationId]="collection?.organizationId"
></app-report-templates>
