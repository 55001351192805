import { Pipe, PipeTransform } from "@angular/core";
import { PrimeIcons } from "primeng/api";
import { EnumPipe } from "./enum.pipe";

@Pipe({ name: "bool", standalone: false })
export class BooleanPipe implements PipeTransform {
  constructor(private readonly enumPipe: EnumPipe) {}

  transform(value: boolean, language?: string, type: "String" | "Icon" | "Color" = "String"): string {
    if (type === "Icon") {
      return value ? PrimeIcons.CHECK_CIRCLE : PrimeIcons.TIMES_CIRCLE;
    }
    if (type === "Color") {
      switch (value) {
        case false:
          return "red";
        case true:
          return "green";
        default:
          return "yellow";
      }
    }
    return this.enumPipe.transform(!!value, "boolean", language);
  }
}
