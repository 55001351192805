import { Component } from "@angular/core";
import { SharedModule } from "../../../../shared/shared.module";
import { InputGroupModule } from "primeng/inputgroup";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { clone, noop } from "lodash";
import { TranslocoService } from "@jsverse/transloco";

interface DayOfYear {
  month: number;
  day: number;
}

@Component({
  standalone: true,
  selector: "app-contract-day-of-year",
  templateUrl: "./contract-day-of-year.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ContractDayOfYearComponent,
      multi: true,
    },
  ],
  imports: [SharedModule, InputGroupModule],
})
export class ContractDayOfYearComponent implements ControlValueAccessor {
  dayOfYear: DayOfYear = { day: null, month: null };

  onChange = (_: DayOfYear): void => noop();

  onTouched = (): void => noop();

  touched = false;

  disabled = false;

  readonly months: { label: string; value: number }[];

  constructor(transloco: TranslocoService) {
    const monthNames = transloco.translateObject("primeng.monthNames");

    this.months = Array.from({ length: 12 }, (_, i) => i).map((month) => ({
      label: monthNames.at(month) ?? `${month + 1}`,
      value: month,
    }));
  }

  writeValue(dayOfYear: DayOfYear): void {
    this.dayOfYear = clone(dayOfYear) ?? { day: null, month: null };
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setMonth(month: number): void {
    this.dayOfYear.month = month;
    this.dayOfYear.day = this.dayOfYear.day > this.getMaxDaysInMonth(month) ? null : this.dayOfYear.day;

    this.markAsTouched();
    this.triggerOnChange();
  }

  setDay(day: number | string): void {
    this.dayOfYear.day = typeof day === "string" ? null : day;
    this.markAsTouched();
    this.triggerOnChange();
  }

  private triggerOnChange(): void {
    if (typeof this.dayOfYear.day !== "number" && typeof this.dayOfYear.month !== "number") {
      this.onChange(null);
      return;
    }

    this.onChange(clone(this.dayOfYear));
  }

  getMaxDaysInMonth(month: number): number {
    if (typeof month !== "number") {
      return 30;
    }

    return new Date(2022, month + 1, 0).getDate();
  }
}
