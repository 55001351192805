import { CollectionAutoTemplateVariableEnum, getCollectionAutoTemplateVariableValue } from "@ankaadia/ankaadia-shared";
import { translate } from "@jsverse/transloco";
import { StaticDataModelGroup } from "../../candidate-filter/candidate-filter.model";

export interface CollectionAutoTemplateVariable {
  value: string;
  label: string;
  description: string;
}

export interface CollectionAutoTemplateFieldOptions {
  field: string;
  options: StaticDataModelGroup;
}

const buildOptions = (
  field: string,
  ...variables: CollectionAutoTemplateVariable[]
): CollectionAutoTemplateFieldOptions => ({
  field,
  options: {
    label: translate("autoCollectionTemplate.variables"),
    items: variables.map((variable) => ({ value: variable.value, label: variable.label })),
  },
});

// const formatVariableName = (translationKey: string): string => translationKey;
const formatVariableName = (translationKey: string): string => (translate ? `{{${translate(translationKey)}}}` : "");

function TemplateVariableSourceOrganization(): CollectionAutoTemplateVariable {
  return {
    value: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.Source),
    label: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.Source),
    description: formatVariableName("partner.source"),
  };
}

function TemplateVariableEmployerBeforeRecognition(): CollectionAutoTemplateVariable {
  return {
    value: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.EmployerBeforeRecognition),
    label: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.EmployerBeforeRecognition),
    description: formatVariableName("beforeRecognition.shortTitle"),
  };
}

function TemplateVariableEmployerAfterRecognition(): CollectionAutoTemplateVariable {
  return {
    value: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.EmployerAfterRecognition),
    label: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.EmployerAfterRecognition),
    description: formatVariableName("afterRecognition.shortTitle"),
  };
}

function TemplateVariableLanguageSchool(): CollectionAutoTemplateVariable {
  return {
    value: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.LanguageSchool),
    label: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.LanguageSchool),
    description: formatVariableName("partner.languageSchool"),
  };
}

function TemplateVariableCountryOfOrigin(): CollectionAutoTemplateVariable {
  return {
    value: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.CountryOfOrigin),
    label: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.CountryOfOrigin),
    description: formatVariableName("countryOfOrigin.title"),
  };
}
function TemplateVariableFunction(): CollectionAutoTemplateVariable {
  return {
    value: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.Function),
    label: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.Function),
    description: formatVariableName("function.title"),
  };
}

function TemplateVariableImmigrationCountry(): CollectionAutoTemplateVariable {
  return {
    value: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.ImmigrationCountry),
    label: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.ImmigrationCountry),
    description: formatVariableName("immigrationCountry.title"),
  };
}

function TemplateVariableEmployerApplication(): CollectionAutoTemplateVariable {
  return {
    value: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.EmployerApplication),
    label: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.EmployerApplication),
    description: formatVariableName("application.shortTitle"),
  };
}

function TemplateVariableEmployerInterview(): CollectionAutoTemplateVariable {
  return {
    value: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.EmployerInterview),
    label: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.EmployerInterview),
    description: formatVariableName("interview.shortTitle"),
  };
}

function TemplateVariableProfession(): CollectionAutoTemplateVariable {
  return {
    value: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.Profession),
    label: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.Profession),
    description: formatVariableName("profession.title"),
  };
}

function TemplateVariableQualificationMeasure(): CollectionAutoTemplateVariable {
  return {
    value: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.QualificationMeasure),
    label: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.QualificationMeasure),
    description: formatVariableName("qualificationMeasure.title"),
  };
}

function TemplateVariableCourseNumber(): CollectionAutoTemplateVariable {
  return {
    value: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.CourseNumber),
    label: getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum.CourseNumber),
    description: formatVariableName("courseNumber.title"),
  };
}

export function getOrganizationVariables(): CollectionAutoTemplateVariable[] {
  return [
    TemplateVariableSourceOrganization(),
    TemplateVariableEmployerBeforeRecognition(),
    TemplateVariableEmployerAfterRecognition(),
    TemplateVariableLanguageSchool(),
    TemplateVariableEmployerApplication(),
    TemplateVariableEmployerInterview(),
  ];
}

export function getCollectionAutoTemplateFieldVariables(): CollectionAutoTemplateFieldOptions[] {
  return [
    buildOptions("internal.sourceOrganizationId", TemplateVariableSourceOrganization()),
    buildOptions(
      "migration.employmentRelationship.beforeRecognition.employerId",
      TemplateVariableEmployerBeforeRecognition()
    ),
    buildOptions(
      "migration.employmentRelationship.afterRecognition.employerId",
      TemplateVariableEmployerAfterRecognition()
    ),
    buildOptions("internal.languageSchooldId", TemplateVariableLanguageSchool()),
    buildOptions("os.profile.countryOfOrigin", TemplateVariableCountryOfOrigin()),
    buildOptions("immigrationCountry", TemplateVariableImmigrationCountry()),
    buildOptions("function", TemplateVariableFunction()),
    buildOptions("profession", TemplateVariableProfession()),
    buildOptions("migration.de.qualificationMeasure.qualificationMeasure", TemplateVariableQualificationMeasure()),
    buildOptions("migration.de.qualificationMeasure.courseNumber", TemplateVariableCourseNumber()),
  ];
}
