import {
  BillingSettingsFragment,
  CreateStripeCustomerGQL,
  CustomerFragment,
  GetBillingInformationGQL,
  GetCustomerPortalUrlGQL,
} from "@ankaadia/graphql";
import { map, Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class BillingSettingsService {
  constructor(
    private readonly getBillingInformationGql: GetBillingInformationGQL,
    private readonly getCustomerPortalUrlGql: GetCustomerPortalUrlGQL,
    private readonly createStripeCustomerMutation: CreateStripeCustomerGQL
  ) {}

  getBillingSettings(organizationId: string): Observable<
    BillingSettingsFragment & {
      customer: CustomerFragment;
      isLicensed: boolean;
    }
  > {
    return this.getBillingInformationGql.fetch({ organizationId }).pipe(
      map((x) => ({
        ...x.data.getBillingSettings,
        customer: x.data.getCustomer,
        isLicensed: x.data.isOrganizationLicensed,
      }))
    );
  }

  getCustomerPortalUrl(_organizationId: string): Observable<string> {
    return this.getCustomerPortalUrlGql
      .fetch({
        input: {
          organizationId: _organizationId,
          returnUrl: window.location.origin,
        },
      })
      .pipe(map((x) => x.data.getCustomerPortalUrl));
  }

  createStripeCustomer(organizationId: string): Observable<boolean> {
    return this.createStripeCustomerMutation
      .mutate(
        { organizationId: organizationId },
        {
          update: (cache) => {
            cache.evict({ fieldName: "getBillingSettings", id: organizationId });
            cache.evict({ fieldName: "getCustomer", id: organizationId });
          },
        }
      )
      .pipe(map((_) => true));
  }
}
