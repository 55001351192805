import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import {
  Candidate,
  CandidateStatus,
  Collection,
  FeedbackStatusPhaseOne,
  FeedbackStatusPhaseTwo,
  Organization,
  StaticDataModel,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { Paginator, PaginatorState } from "primeng/paginator";
import { Subject, Subscription, debounceTime, distinctUntilChanged } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { getSortedCandidateStatusList } from "../../candidate-form/candidate-status/candidate-status-enum-helper";
import { OrganizationsService } from "../../organizations/organizations.service";
import { CandidateForAssignment, CollWithCandRows, CollectionService } from "../collection.service";
import { CandidateFilters, CustomLazyLoadEvent } from "./custom-filter.model";

interface FeedbackStatusGroup {
  organizationName: string;
  states: FeedbackStatus[];
}

interface FeedbackStatus {
  sharingId: string;
  organizationName: string;
  label: string;
  state: string;
}

@Component({
  selector: "app-collection-edit-assigned-candidates",
  templateUrl: "./collection-edit-assigned-candidates.component.html",
  styleUrl: "./collection-edit-assigned-candidates.component.scss",
  standalone: false,
})
export class CollectionEditAssignedCandidatesComponent implements OnInit, OnDestroy {
  @Output() readonly closed = new EventEmitter<{
    success: boolean;
    collection?: Collection;
    targetCandidateList?: CandidateForAssignment[];
  }>();

  @Output() readonly sourceUpdate = new EventEmitter<CustomLazyLoadEvent>();

  @Input() sourceCandidateList: Candidate[];
  @Input() targetCandidateList: CandidateForAssignment[];
  @Input() candidatesPerPage: number;
  @Input() sourceCandidateCount: number;
  @Input() collection: Collection;
  @Input() caption: string;
  disableSave = false;

  @ViewChild("sourcePgr") sourcePaginator: Paginator;
  sourceFilters: CandidateFilters = { custom: {} };
  searchSubject = new Subject<string>();
  searchSubscription: Subscription;

  statusOptions: StaticDataModel[] = [];
  feedbacks: FeedbackStatusGroup[] = [];
  organizations: Partial<Organization>[] = [];
  collections: Partial<Collection>[] = [];
  selectedStatus: CandidateStatus[] = [];
  selectedCollection: CollWithCandRows[0] = null;
  selectedFeedback: FeedbackStatus = null;
  selectedOrganization: Partial<Organization> = null;

  constructor(
    private readonly organizationService: OrganizationsService,
    private readonly collectionService: CollectionService,
    private readonly settings: SettingsService,

    private readonly staticDataService: StaticDataService
  ) {}

  ngOnInit(): void {
    this.searchSubscription = this.searchSubject
      .asObservable()
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((search) => this.globalSourceFilter(search));

    this.organizationService.getOrganizationsForCollectionSelection().subscribe((x) => {
      this.organizations = x;
      this.selectedOrganization = x[0];
      this.updateCollections(this.selectedOrganization);
    });

    this.statusOptions = getSortedCandidateStatusList(this.staticDataService);
  }

  ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe();
  }

  filterCandidates(status: CandidateStatus[], collection: CollWithCandRows[0], feedback: FeedbackStatus): void {
    this.sourceFilters.status = status?.length ? [{ matchMode: "in", operator: "and", value: status }] : [];
    this.sourceFilters.custom.collection = collection
      ? {
          matchMode: "custom",
          operator: "and",
          value: { collectionId: collection.id, organizationId: collection.organizationId },
        }
      : null;
    this.sourceFilters.custom.feedback = feedback ? { matchMode: "custom", operator: "and", value: feedback } : null;

    this.refetchData();
  }

  globalSourceFilter(search: string): void {
    this.sourceFilters.global =
      search?.trim().length > 0 ? { matchMode: "contains", operator: "and", value: search.trim() } : null;
    this.refetchData();
  }

  updateCollections(organization: Partial<Organization>): void {
    this.selectedCollection = null;

    if (!organization) {
      this.collections = [];
      return;
    }

    if (organization.id === this.settings.organizationId) {
      this.collectionService.getCollections().subscribe((x) => {
        this.collections = x;
      });
    } else {
      this.collectionService
        .getCollectionsForAssignment(this.collection.id, organization.id)
        .subscribe((x) => (this.collections = x));
    }
  }

  updateFeedbacks(collection: CollWithCandRows[0]): void {
    this.selectedFeedback = null;

    if (!collection) {
      this.feedbacks = [];
      this.selectedOrganization = this.organizations[0];
      this.updateCollections(this.selectedOrganization);
      return;
    }

    const statusOptions = [
      { label: translate("enum.FeedbackStatusPhaseOne.Rejected"), value: FeedbackStatusPhaseOne.Rejected },
      { label: translate("enum.FeedbackStatusPhaseOne.Backlogged"), value: FeedbackStatusPhaseOne.Backlogged },
      {
        label: translate("enum.FeedbackStatusPhaseOne.InterviewRequested"),
        value: FeedbackStatusPhaseOne.InterviewRequested,
      },
      { label: translate("enum.FeedbackStatusPhaseTwo.Selected"), value: FeedbackStatusPhaseTwo.Selected },
    ];
    this.collectionService.getSharingsForCollection(collection.id, collection.organizationId).subscribe((sharings) => {
      this.feedbacks = sharings.map((s) => ({
        organizationName: s.destinationOrganizationName,
        states: statusOptions.map((o) => ({
          sharingId: s.id,
          organizationName: s.destinationOrganizationName,
          label: o.label,
          state: o.value,
        })),
      }));
    });
  }

  updateSource(event: PaginatorState): void {
    this.sourceUpdate.emit({ first: event.first, rows: event.rows, filters: this.sourceFilters });
  }

  cancel(): void {
    this.selectedStatus = [CandidateStatus.Approved];
    this.selectedCollection = null;
    this.selectedFeedback = null;
    this.closed.emit({ success: false });
  }

  save(): void {
    this.closed.emit({ success: true, collection: this.collection, targetCandidateList: this.targetCandidateList });
  }

  private refetchData(): void {
    if (!this.sourcePaginator) return;
    if (this.sourcePaginator.getPage() > 0) {
      this.sourcePaginator.changePage(0);
    } else {
      this.updateSource({ first: this.sourcePaginator.first, rows: this.sourcePaginator.rows });
    }
  }
}
