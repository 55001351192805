import { Injectable } from "@angular/core";
import {
  CreateLaborAgreementGQL,
  DeleteLaborAgreementGQL,
  GetLaborAgreementGQL,
  GetLaborAgreementNamesGQL,
  GetLaborAgreementNamesQuery,
  GetLaborAgreementsForOverviewGQL,
  GetLaborAgreementsGQL,
  LaborAgreement,
  LaborAgreementCreateInput,
  LaborAgreementDeleteInput,
  LaborAgreementForOverviewFragment,
  LaborAgreementForSelectionFragment,
  LaborAgreementGetInput,
  LaborAgreementListInput,
  LaborAgreementUpdateInput,
  SupportedImmigrationCountry,
  UpdateLaborAgreementGQL,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class LaborAgreementService {
  constructor(
    private readonly getGql: GetLaborAgreementGQL,
    private readonly getAllGql: GetLaborAgreementsGQL,
    private readonly getAllForOverviewGql: GetLaborAgreementsForOverviewGQL,
    private readonly laborAgreementForSelection: GetLaborAgreementsForOverviewGQL,
    private readonly getAllNamesGql: GetLaborAgreementNamesGQL,
    private readonly createGql: CreateLaborAgreementGQL,
    private readonly updateGql: UpdateLaborAgreementGQL,
    private readonly deleteGql: DeleteLaborAgreementGQL
  ) {}

  get(input: LaborAgreementGetInput): Observable<LaborAgreement> {
    return this.getGql.fetch({ input: input }).pipe(map((result) => result.data.getLaborAgreement));
  }

  getAll(input: LaborAgreementListInput): Observable<LaborAgreement[]> {
    return this.getAllGql.fetch({ input: input }).pipe(map((result) => result.data.getLaborAgreements));
  }

  getAllForOverview(input: LaborAgreementListInput): Observable<LaborAgreementForOverviewFragment[]> {
    return this.getAllForOverviewGql.fetch({ input: input }).pipe(map((result) => result.data.getLaborAgreements));
  }

  getForSelection(
    organizationId: string,
    immigrationCountry: SupportedImmigrationCountry,
    useCache = true
  ): Observable<LaborAgreementForSelectionFragment[]> {
    return this.laborAgreementForSelection
      .fetch(
        { input: { organizationId, country: immigrationCountry } },
        { fetchPolicy: useCache ? "cache-first" : "network-only" }
      )
      .pipe(map((result) => result.data.getLaborAgreements));
  }

  getAllNames(input: LaborAgreementListInput): Observable<GetLaborAgreementNamesQuery["getLaborAgreements"]> {
    return this.getAllNamesGql.fetch({ input: input }).pipe(map((result) => result.data.getLaborAgreements));
  }

  create(input: LaborAgreementCreateInput): Observable<LaborAgreement> {
    return this.createGql.mutate({ input: input }).pipe(map((result) => result.data.createLaborAgreement));
  }

  update(input: LaborAgreementUpdateInput): Observable<LaborAgreement> {
    return this.updateGql.mutate({ input: input }).pipe(map((result) => result.data.updateLaborAgreement));
  }

  delete(input: LaborAgreementDeleteInput): Observable<boolean> {
    return this.deleteGql.mutate({ input: input }).pipe(map((result) => result.data.deleteLaborAgreement.status));
  }
}
