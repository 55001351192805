import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { OrganizationContactEditDialogComponent } from "./organization-contact-edit-dialog/organization-contact-edit-dialog.component";
import { OrganizationContactPageComponent } from "./organization-contact-page/organization-contact-page.component";
import { OrganizationContactsComponent } from "./organization-contacts.component";

@NgModule({
  imports: [SharedModule],
  declarations: [
    OrganizationContactEditDialogComponent,
    OrganizationContactsComponent,
    OrganizationContactPageComponent,
  ],
  exports: [OrganizationContactsComponent, OrganizationContactPageComponent],
})
export class OrganizationContactsModule {}
