<ng-container *appTranslate="let t">
  <ng-container *ngIf="!candidate?.id; else candidatePanel">
    <p-blockUI [target]="panel" [blocked]="true">
      <span class="block-ui-message">{{ t("candidate.interviewWarning") }}</span>
    </p-blockUI>
    <p-card #panel></p-card>
  </ng-container>

  <ng-template #candidatePanel>
    <p-splitter
      *ngIf="candidate?.id"
      class="app-form"
      styleClass="mb-5 app-form candidate-splitter"
      [panelSizes]="[30, 75]"
      [minSizes]="[30, 50]"
      stateKey="candidate_feedbacks_splitter"
      [panelStyle]="{ 'overflow-y': 'auto' }"
    >
      <ng-template pTemplate>
        <app-interview-iteration-selector
          [candidateId]="candidate?.id"
          [iterations]="interviews?.interviewsFeedback"
          [highlightedRowId]="highlightedRowId"
          [readonly]="readonly"
          (showFeedback)="selectFeedback($event)"
          (delete)="deleteIteration($event.id, $event.event)"
          [style]="{ width: '100%' }"
        ></app-interview-iteration-selector>
      </ng-template>

      <ng-template pTemplate>
        <app-interview-iteration-details
          [selectedIteration]="selectedIteration"
          [interviewId]="interviews?.id"
          [candidateId]="candidate?.id"
          [candidateOrganizationId]="candidate?.organizationId"
          [immigrationCountry]="candidate?.immigrationCountry"
          [show]="showDetails"
          [readonly]="readonly"
          (refresh)="refresh($event.id)"
          class="intervew-details"
        ></app-interview-iteration-details>
      </ng-template>
    </p-splitter>
  </ng-template>
</ng-container>
