<app-collection-auto-filter-info
  [filter]="filter"
  [fields]="fields"
  [includeSharedCandidates]="includeSharedCandidates"
></app-collection-auto-filter-info>
<div>
  <h4>
    {{ "autoCollection.fromTemplate" | transloco }}
    <a href="javascript:void(0)" [routerLink]="getTemplateRouteLink(autoCollectionTemplate)">
      {{ autoCollectionTemplate?.name }}
    </a>
  </h4>
</div>
<app-table
  [columns]="columns"
  [items]="assignedCandidates"
  [(showSidebar)]="showDialog"
  sidebarSize="large"
  [numberOfRows]="27"
  [paginator]="true"
>
</app-table>
