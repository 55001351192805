import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  AdHocEducationExamFragment,
  EducationExamFragment,
  ExamStatus,
  ExamType,
  StaticDataType,
} from "@ankaadia/graphql";
import { sortBy } from "lodash";
import { combineLatest, map, Observable, startWith, switchMap } from "rxjs";
import { DateFormatterService } from "../../../../shared/services/date-formatter.service";
import { valuesOf } from "../../../../shared/services/form.helper";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";
import { ExamFragment } from "../../../education/education-exams/education-exam-table/education-exam-table.component";
import { EducationExamService } from "../../../education/education-exams/education-exam.service";

@Component({
  selector: "app-candidate-education-exams-add-to-exam-dialog",
  templateUrl: "./candidate-education-exams-add-to-exam-dialog.component.html",
  styleUrl: "./candidate-education-exams-add-to-exam-dialog.component.scss",
  standalone: false,
})
export class CandidateEducationExamsAddToExamDialogComponent implements OnChanges {
  @Input({ required: true })
  currentExams: ExamFragment[];

  @Input({ required: true })
  candidateData: {
    candidateId: string;
    candidateOrganizationId: string;
  };

  protected selectedExamType = new FormControl<string>(undefined);
  protected selectedExamInstitution = new FormControl<string>(undefined);
  protected selectedStatus = new FormControl<ExamStatus>(ExamStatus.Active);

  protected readonly examStatus = this.staticDataService.transformEnumToStaticDataModel("ExamStatus", ExamStatus);
  protected readonly examTypes = this.staticDataService.transformEnumToStaticDataModel("ExamType", ExamType);
  protected readonly examInstitutions$ = this.staticDataService
    .getStaticData(StaticDataType.ExamInstitutions)
    .pipe(map((data) => sortBy(data, (entry) => (entry.value === "OTHER" ? 1 : 0))));

  protected exam: ExamFragment;

  protected options$: Observable<SelectableExamOption[]>;

  @Output()
  readonly saved = new EventEmitter<ExamFragment>();

  @Output()
  readonly closed = new EventEmitter<void>();

  constructor(
    private readonly examService: EducationExamService,
    private readonly staticDataService: StaticDataService,
    private readonly dateFormatterService: DateFormatterService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateData && this.candidateData) this.options$ = this.getOptions();
  }

  save(): void {
    this.saved.emit(this.exam);
  }

  cancel(): void {
    this.closed.emit();
  }

  clearAllFilters(): void {
    [this.selectedStatus, this.selectedExamType, this.selectedExamInstitution].forEach((control) =>
      control.setValue(null)
    );
  }

  get areAllFiltersDeSelected(): boolean {
    return !this.selectedExamType?.value && !this.selectedExamInstitution?.value && !this.selectedStatus?.value;
  }

  private getOptions(): Observable<SelectableExamOption[]> {
    const allExams$ = this.selectedStatus.valueChanges.pipe(
      startWith(this.selectedStatus.value),
      switchMap((status) => this.getAllExams(this.candidateData.candidateOrganizationId, status))
    );

    return combineLatest([allExams$, valuesOf(this.selectedExamType), valuesOf(this.selectedExamInstitution)]).pipe(
      map(([exams, examType, examInstitution]) => this.filterExams(exams, examType, examInstitution)),
      map((exams) => exams.map((exam) => this.toSelectableExamOption(exam))),
      map((exams) => sortBy(exams, "name"))
    );
  }

  private getAllExams(
    organizationId: string,
    status: ExamStatus
  ): Observable<(EducationExamFragment | AdHocEducationExamFragment)[]> {
    return combineLatest([
      this.examService.getAll(organizationId, status),
      this.examService.getAllAdHoc(organizationId, status),
    ]).pipe(map(([exams, adHocExams]) => [...exams, ...adHocExams]));
  }

  private filterExams(
    exams: (EducationExamFragment | AdHocEducationExamFragment)[],
    examType: string,
    examInstitution: string
  ): (EducationExamFragment | AdHocEducationExamFragment)[] {
    return exams
      .filter((exam) => !this.currentExams.some((currentExam) => currentExam.id === exam.id))
      .filter((exam) => !examType || exam.examType === examType)
      .filter((exam) => !examInstitution || exam.examInstitution === examInstitution);
  }

  private toSelectableExamOption(exam: EducationExamFragment | AdHocEducationExamFragment): SelectableExamOption {
    const examDateAndTime = exam.examDateAndTime ?? exam.plannedExamDateAndTime;

    let formattedExamDate: string;
    if (!examDateAndTime) {
      formattedExamDate = null;
    } else if (examDateAndTime.hasTime) {
      formattedExamDate = this.dateFormatterService.transformDateTime(examDateAndTime.date, {
        dateStyle: "short",
        timeStyle: "short",
      });
    } else {
      formattedExamDate = this.dateFormatterService.transformDate(examDateAndTime.date, { dateStyle: "short" });
    }

    return {
      ...exam,
      examDate: formattedExamDate,
      filterText: [exam.name, formattedExamDate, exam.examLocation].join("").toLowerCase(),
    };
  }
}

type SelectableExamOption = ExamFragment & { filterText: string; examDate: string };
