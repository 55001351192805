<ng-container [formGroup]="form" *appTranslate="let t">
  <div class="p-fluid">
    <div *ngIf="!isEditMode && !isPresetMode" class="field">
      <label for="org">{{ t("organization.sharedWith") }}</label>
      <p-multiSelect
        [options]="organizations$ | async"
        optionLabel="name"
        optionValue="id"
        [group]="additionalOrganizations?.length > 0"
        formControlName="destinationOrganizationId"
        filterBy="name"
        [placeholder]="t('organization.placeholder')"
      >
        <ng-template let-group pTemplate="group">
          <div class="flex align-items-center gap-2">
            <div>{{ group.label }}</div>
          </div>
        </ng-template>
      </p-multiSelect>
      <small
        class="p-error"
        *ngIf="
          form.controls.destinationOrganizationId.touched && form.controls.destinationOrganizationId.errors?.required
        "
      >
        {{ t("organization.required") }}
      </small>
    </div>

    <div *ngIf="isEditMode" class="field">
      <label for="org">{{ t("organization.sharedWith") }}</label>
      <input pInputText type="text" formControlName="destinationOrganizationId" [readonly]="true" />
    </div>

    <p-fieldset [legend]="t('sharingType.title')" class="py-0">
      <div class="flex flex-row justify-content-between">
        <div
          *ngIf="!isEditMode || form.controls.sharingType.value === SharingType.View"
          class="field-radiobutton mb-0"
          data-testid="sharing-type-presentation-of-candidate"
        >
          <p-radioButton
            name="sharingType"
            [value]="SharingType.View"
            inputId="view"
            formControlName="sharingType"
          ></p-radioButton>
          <label for="view">{{ t("enum.SharingTypeEnum.View") }}</label>
        </div>

        <div class="field-radiobutton mb-0" data-testid="sharing-type-read-and-write">
          <p-radioButton
            name="sharingType"
            [value]="SharingType.Collaboration"
            inputId="collaboration"
            formControlName="sharingType"
          ></p-radioButton>
          <label for="collaboration">{{ t("enum.SharingTypeEnum.Collaboration") }}</label>
        </div>

        <div class="field-radiobutton mb-0" data-testid="sharing-type-read-only">
          <p-radioButton
            name="sharingType"
            [value]="SharingType.ReadOnlyCollaboration"
            inputId="readonlyCollaboration"
            formControlName="sharingType"
          ></p-radioButton>
          <label for="readonlyCollaboration">{{ t("enum.SharingTypeEnum.ReadOnlyCollaboration") }}</label>
        </div>
      </div>

      <small class="p-error" *ngIf="form.controls.sharingType.touched && form.controls.sharingType.errors?.required">
        {{ t("sharingType.required") }}
      </small>
    </p-fieldset>

    <p-fieldset [legend]="t('options.title')">
      <div class="field">
        <label for="fileType">{{ t("fileTypesToShare.title") }}</label>
        <p-multiSelect
          [options]="fileTypes$ | async"
          display="chip"
          [placeholder]="t('fileTypesToShare.placeholder')"
          id="fileType"
          class="p-2"
          name="File Types"
          formControlName="sharedFileTypes"
        ></p-multiSelect>
      </div>

      <div class="field">
        <label for="fileTabs">{{ t("tabsToShare.title") }}</label>
        <p-multiSelect
          [options]="shareableTabs$ | async"
          display="chip"
          [placeholder]="t('tabsToShare.placeholder')"
          id="fileTabs"
          class="p-2"
          name="File Tabs"
          formControlName="sharedTabs"
        >
          <ng-template let-tab pTemplate="item">
            <div class="flex align-items-center gap-2">
              <app-flag
                *ngIf="germanTabs.includes(tab.value) || austrianTabs.includes(tab.value)"
                [small]="true"
                [country]="germanTabs.includes(tab.value) ? 'DE' : austrianTabs.includes(tab.value) ? 'AT' : null"
              ></app-flag>
              <div>{{ tab.label }}</div>
            </div>
          </ng-template>
        </p-multiSelect>
        <small class="p-error" *ngIf="form.controls.sharedTabs.errors?.required">
          {{ t("tabsToShare.required") }}
        </small>
        <small class="p-error" *ngIf="form.controls.sharedTabs.errors?.internalRequired">
          {{ t("tabsToShare.internalRequired") }}
        </small>
      </div>

      <div class="field">
        <label for="profileAccessId">{{ t("sharing.profileAccess") }}</label>
        <i class="ml-1 pi pi-info-circle" [pTooltip]="t('sharing.profileAccessToolTip')"></i>
        <p-dropdown
          id="profileAccessId"
          formControlName="profileAccessId"
          optionValue="id"
          optionLabel="setName"
          [placeholder]="t('sharing.profileAccessPlaceholder')"
          [showClear]="true"
          [options]="profileAccessOptions$ | async"
        ></p-dropdown>
      </div>

      <div class="field mt-3">
        <label for="expiryDate">{{ t("sharing.expiryDate") }}</label>
        <p-calendar #date inputId="expiryDate" formControlName="expiryDate" [minDate]="tomorrow"></p-calendar>
      </div>

      <div class="field mt-3">
        <div class="field-checkbox">
          <p-checkbox [binary]="true" inputId="enabled" formControlName="enabled"></p-checkbox>
          <label for="enabled">{{ t("sharing.enabled") }}</label>
        </div>

        <div class="field-checkbox" *ngIf="autoCVConfigured">
          <p-checkbox [binary]="true" inputId="autoCVRequested" formControlName="autoCVRequested"></p-checkbox>
          <label for="autoCVRequested">{{ t("sharing.autoCVRequest") }}</label>
        </div>

        <div class="field mt-3">
          <label for="providedTemplates">{{ t("providedTemplates.title") }}</label>
          <p-multiSelect
            inputId="providedTemplates"
            formControlName="providedTemplates"
            [options]="templates$ | async"
            [placeholder]="t('providedTemplates.placeholder')"
            class="p-2"
          ></p-multiSelect>
        </div>

        <div class="field-checkbox">
          <p-checkbox
            [binary]="true"
            inputId="disableSubmitFeedback"
            formControlName="disableSubmitFeedback"
          ></p-checkbox>
          <label for="disableSubmitFeedback">{{ t("sharing.disableSubmitFeedback") }}</label>
        </div>

        <div class="field-checkbox">
          <p-checkbox [binary]="true" inputId="isAnonymous" formControlName="isAnonymous"></p-checkbox>
          <label for="isAnonymous">{{ t("sharing.isAnonymous") }}</label>
        </div>

        <div class="field mt-3">
          <label for="anonymousType">{{ t("anonymization.options") }}</label>
          <p-multiSelect
            [options]="anonymizationTypes"
            display="chip"
            [placeholder]="t('anonymization.placeholder')"
            id="anonymousType"
            class="p-2"
            name="Anonymization options"
            formControlName="anonymizationOptions"
          ></p-multiSelect>
        </div>

        <div class="field-checkbox">
          <p-checkbox [binary]="true" inputId="isCandidateEditable" formControlName="isCandidateEditable"></p-checkbox>
          <label for="isCandidateEditable">{{ t("sharing.isCandidateEditable") }}</label>
        </div>

        <div class="field-checkbox">
          <p-checkbox
            [binary]="true"
            inputId="shareInternalDocuments"
            formControlName="shareInternalDocuments"
          ></p-checkbox>
          <label for="shareInternalDocuments">{{ t("sharing.shareInternalDocuments") }}</label>
        </div>

        <div class="field-checkbox">
          <p-checkbox
            [binary]="true"
            inputId="showCompletionStateAtDocuments"
            formControlName="showCompletionStateAtDocuments"
          ></p-checkbox>
          <label for="showCompletionStateAtDocuments">{{ t("sharing.showCompletionStateAtDocuments") }}</label>
        </div>

        <div class="field-checkbox">
          <p-checkbox
            [binary]="true"
            inputId="enableNotificationEmails"
            formControlName="enableNotificationEmails"
          ></p-checkbox>
          <label for="enableNotificationEmails">{{ t("sharing.enableNotificationEmails") }}</label>
        </div>

        <ng-container *ngIf="form.controls.enableNotificationEmails.value">
          <ng-container *ngIf="missingEmailTemplates$ | async as missingEmailTemplates">
            <p-message
              *ngIf="missingEmailTemplates?.length"
              severity="warn"
              [text]="
                t('missing.templates', {
                  templates:
                    getMissingTemplatesForSharingType(form.controls.sharingType.value, missingEmailTemplates) | join,
                })
              "
            ></p-message>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!isPresetMode && form.controls.enableNotificationEmails.value">
          <ng-container *ngIf="userGroupsWithNoUsers$ | async as userGroupsWithNoUsers">
            <p-message
              *ngIf="userGroupsWithNoUsers?.length"
              severity="warn"
              [text]="t('missing.users', { userGroups: userGroupsWithNoUsers | join })"
            ></p-message>
          </ng-container>

          <div class="field" *ngIf="isEditMode">
            <label for="notificationEmailUserGroupIds">{{ t("sharing.notificationEmailUserGroups") }}</label>
            <p-multiSelect
              [options]="userGroups$[destinationOrganizationId] | async"
              optionLabel="name"
              optionValue="id"
              formControlName="notificationEmailUserGroupIds"
              filterBy="name"
              [placeholder]="t('userGroups.placeholder')"
            ></p-multiSelect>
          </div>

          <ng-container *ngIf="form.controls.destinationOrganizationId.value?.length">
            <p-fieldset [legend]="t('sharing.notificationEmailUserGroups')" *ngIf="!isEditMode">
              <ng-container *ngIf="organizations$ | async as organizations">
                <div
                  class="field grid"
                  formArrayName="notificationEmailUserGroups"
                  *ngFor="let userGroupControl of form.controls.notificationEmailUserGroups.controls; index as i"
                >
                  <ng-container
                    *ngIf="userGroupControl.controls.organizationId.value as organizationId"
                    [formGroupName]="i"
                  >
                    <label for="org + {{ organizationId }}" class="col-12 mb-0">
                      {{ organizationNameMap[organizationId] }}
                    </label>

                    <div class="col-12 mt-2">
                      <p-multiSelect
                        [options]="userGroups$[organizationId] | async"
                        optionLabel="name"
                        optionValue="id"
                        filterBy="name"
                        inputId="org + {{ organizationId }}"
                        formControlName="userGroupIds"
                        [placeholder]="t('userGroups.placeholder')"
                      ></p-multiSelect>
                      <small class="p-error" *ngIf="userGroupControl.controls.userGroupIds.errors?.required">
                        {{ t("sharing.userGroupMustBeSelected") }}
                      </small>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </p-fieldset>
          </ng-container>
        </ng-container>
      </div>
    </p-fieldset>
  </div>
</ng-container>
