import { defaultImmigrationCountry, toRealImmigrationCountry } from "./migration-specific/immigration-country";

const CountryCityStateMapping: Record<string, Record<string, string>> = {
  DE: {
    BERLIN: "DE-BE",
    HAMBURG: "DE-HH",
    MUENCHEN: "DE-BY",
    KOELN: "DE-NW",
    "FRANKFURT-AM-MAIN": "DE-HE",
    STUTTGART: "DE-BW",
    DUESSELDORF: "DE-NW",
    LEIPZIG: "DE-SN",
    DORTMUND: "DE-NW",
    ESSEN: "DE-NW",
    BREMEN: "DE-HB",
    DRESDEN: "DE-SN",
    HANNOVER: "DE-NI",
    NUERNBERG: "DE-BY",
    DUISBURG: "DE-NW",
    BOCHUM: "DE-NW",
    WUPPERTAL: "DE-NW",
    BIELEFELD: "DE-NW",
    BONN: "DE-NW",
    MUENSTER: "DE-NW",
    MANNHEIM: "DE-BW",
    KARLSRUHE: "DE-BW",
    AUGSBURG: "DE-BY",
    WIESBADEN: "DE-HE",
    MOENCHENGLADBACH: "DE-NW",
    GELSENKIRCHEN: "DE-NW",
    AACHEN: "DE-NW",
    BRAUNSCHWEIG: "DE-NI",
    KIEL: "DE-SH",
    CHEMNITZ: "DE-SN",
    "HALLE-SAALE": "DE-ST",
    MAGDEBURG: "DE-ST",
    "FREIBURG-IM-BREISGAU": "DE-BW",
    KREFELD: "DE-NW",
    MAINZ: "DE-RP",
    LUEBECK: "DE-SH",
    ERFURT: "DE-TH",
    OBERHAUSEN: "DE-NW",
    ROSTOCK: "DE-MV",
    KASSEL: "DE-HE",
    HAGEN: "DE-NW",
    POTSDAM: "DE-BB",
    SAARBRUECKEN: "DE-SL",
    HAMM: "DE-NW",
    "LUDWIGSHAFEN-AM-RHEIN": "DE-RP",
    "MUELHEIM-AN-DER-RUHR": "DE-NW",
    OLDENBURG: "DE-NI",
    OSNABRUECK: "DE-NI",
    LEVERKUSEN: "DE-NW",
    SOLINGEN: "DE-NW",
    DARMSTADT: "DE-HE",
    HEIDELBERG: "DE-BW",
    HERNE: "DE-NW",
    NEUSS: "DE-NW",
    REGENSBURG: "DE-BY",
    PADERBORN: "DE-NW",
    INGOLSTADT: "DE-BY",
    "OFFENBACH-AM-MAIN": "DE-HE",
    FUERTH: "DE-BY",
    WUERZBURG: "DE-BY",
    HEILBRONN: "DE-BW",
    ULM: "DE-BW",
    PFORZHEIM: "DE-BW",
    WOLFSBURG: "DE-NI",
    BOTTROP: "DE-NW",
    GOETTINGEN: "DE-NI",
    REUTLINGEN: "DE-BW",
    BREMERHAVEN: "DE-HB",
    KOBLENZ: "DE-RP",
    ERLANGEN: "DE-BY",
    "BERGISCH-GLADBACH": "DE-NW",
    REMSCHEID: "DE-NW",
    JENA: "DE-TH",
    RECKLINGHAUSEN: "DE-NW",
    TRIER: "DE-RP",
    SALZGITTER: "DE-NI",
    MOERS: "DE-NW",
    SIEGEN: "DE-NW",
    HILDESHEIM: "DE-NI",
    GUETERSLOH: "DE-NW",
    KAISERSLAUTERN: "DE-RP",
    COTTBUS: "DE-BB",
    HANAU: "DE-HE",
    WITTEN: "DE-NW",
    SCHWERIN: "DE-MV",
    LUDWIGSBURG: "DE-BW",
    "ESSLINGEN-AM-NECKAR": "DE-BW",
    GERA: "DE-TH",
    ISERLOHN: "DE-NW",
    DUEREN: "DE-NW",
    TUEBINGEN: "DE-BW",
    GIESSEN: "DE-HE",
    FLENSBURG: "DE-SH",
    ZWICKAU: "DE-SN",
    RATINGEN: "DE-NW",
    LUENEN: "DE-NW",
    "VILLINGEN-SCHWENNINGEN": "DE-BW",
    KONSTANZ: "DE-BW",
    MARL: "DE-NW",
    WORMS: "DE-RP",
    MINDEN: "DE-NW",
    VELBERT: "DE-NW",
    NEUMUENSTER: "DE-SH",
    "DESSAU-ROSSLAU": "DE-ST",
    NORDERSTEDT: "DE-SH",
    DELMENHORST: "DE-NI",
    VIERSEN: "DE-NW",
    BAMBERG: "DE-BY",
    MARBURG: "DE-HE",
    RHEINE: "DE-NW",
    GLADBECK: "DE-NW",
    LUENEBURG: "DE-NI",
    WILHELMSHAVEN: "DE-NI",
    TROISDORF: "DE-NW",
    DORSTEN: "DE-NW",
    DETMOLD: "DE-NW",
    BAYREUTH: "DE-BY",
    ARNSBERG: "DE-NW",
    "CASTROP-RAUXEL": "DE-NW",
    LANDSHUT: "DE-BY",
    "BRANDENBURG-AN-DER-HAVEL": "DE-BB",
    LUEDENSCHEID: "DE-NW",
    BOCHOLT: "DE-NW",
    ASCHAFFENBURG: "DE-BY",
    CELLE: "DE-NI",
    "KEMPTEN-ALLGAEU": "DE-BY",
    AALEN: "DE-BW",
    FULDA: "DE-HE",
    LIPPSTADT: "DE-NW",
    DINSLAKEN: "DE-NW",
    HERFORD: "DE-NW",
    "RUESSELSHEIM-AM-MAIN": "DE-HE",
    KERPEN: "DE-NW",
    WEIMAR: "DE-TH",
    NEUWIED: "DE-RP",
    SINDELFINGEN: "DE-BW",
    DORMAGEN: "DE-NW",
    PLAUEN: "DE-SN",
    GREVENBROICH: "DE-NW",
    ROSENHEIM: "DE-BY",
    NEUBRANDENBURG: "DE-MV",
    HERTEN: "DE-NW",
    BERGHEIM: "DE-NW",
    FRIEDRICHSHAFEN: "DE-BW",
    "SCHWAEBISCH-GMUEND": "DE-BW",
    GARBSEN: "DE-NI",
    OFFENBURG: "DE-BW",
    WESEL: "DE-NW",
    HUERTH: "DE-NW",
    GREIFSWALD: "DE-MV",
    "NEU-ULM": "DE-BY",
    EUSKIRCHEN: "DE-NW",
    UNNA: "DE-NW",
    "LANGENFELD-RHEINLAND": "DE-NW",
    STRALSUND: "DE-MV",
    GOEPPINGEN: "DE-BW",
    "FRANKFURT-ODER": "DE-BB",
    HAMELN: "DE-NI",
    MEERBUSCH: "DE-NW",
    "BADEN-BADEN": "DE-BW",
    "LINGEN-EMS": "DE-NI",
    GOERLITZ: "DE-SN",
    "SANKT-AUGUSTIN": "DE-NW",
    "STOLBERG-RHEINLAND": "DE-NW",
    PULHEIM: "DE-NW",
    ESCHWEILER: "DE-NW",
    "BAD-HOMBURG-VOR-DER-HOEHE": "DE-HE",
    WAIBLINGEN: "DE-BW",
    LANGENHAGEN: "DE-NI",
    HILDEN: "DE-NW",
    NORDHORN: "DE-NI",
    SCHWEINFURT: "DE-BY",
    WETZLAR: "DE-HE",
    HATTINGEN: "DE-NW",
    "BAD-SALZUFLEN": "DE-NW",
    PASSAU: "DE-BY",
    "NEUSTADT-AN-DER-WEINSTRASSE": "DE-RP",
    KLEVE: "DE-NW",
    AHLEN: "DE-NW",
    FRECHEN: "DE-NW",
    WOLFENBUETTEL: "DE-NI",
    "BAD-KREUZNACH": "DE-RP",
    IBBENBUEREN: "DE-NW",
    "MENDEN-SAUERLAND": "DE-NW",
    BOEBLINGEN: "DE-BW",
    GUMMERSBACH: "DE-NW",
    RASTATT: "DE-BW",
    RAVENSBURG: "DE-BW",
    PEINE: "DE-NI",
    SPEYER: "DE-RP",
    ELMSHORN: "DE-SH",
    LOERRACH: "DE-BW",
    EMDEN: "DE-NI",
    GOSLAR: "DE-NI",
    WILLICH: "DE-NW",
    "GRONAU-WESTF": "DE-NW",
    ERFTSTADT: "DE-NW",
    FREISING: "DE-BY",
    "HEIDENHEIM-AN-DER-BRENZ": "DE-BW",
    "RHEDA-WIEDENBRUECK": "DE-NW",
    LEONBERG: "DE-BW",
    STRAUBING: "DE-BY",
    "BAD-OEYNHAUSEN": "DE-NW",
    "SINGEN-HOHENTWIEL": "DE-BW",
    BERGKAMEN: "DE-NW",
    "LAHR-SCHWARZWALD": "DE-BW",
    "FRANKENTHAL-PFALZ": "DE-RP",
    BORNHEIM: "DE-NW",
    CUXHAVEN: "DE-NI",
    STADE: "DE-NI",
    ALSDORF: "DE-NW",
    ORANIENBURG: "DE-BB",
    "LANDAU-IN-DER-PFALZ": "DE-RP",
    DACHAU: "DE-BY",
    SOEST: "DE-NW",
    "HENNEF-SIEG": "DE-NW",
    DUELMEN: "DE-NW",
    MELLE: "DE-NI",
    "OBERURSEL-TAUNUS": "DE-HE",
    NEUNKIRCHEN: "DE-SL",
    HERZOGENRATH: "DE-NW",
    BRUCHSAL: "DE-BW",
    HOF: "DE-BY",
    ALBSTADT: "DE-BW",
    RODGAU: "DE-HE",
    SCHWERTE: "DE-NW",
    KAUFBEUREN: "DE-BY",
    GOTHA: "DE-TH",
    FILDERSTADT: "DE-BW",
    FELLBACH: "DE-BW",
    MEMMINGEN: "DE-BY",
    BUENDE: "DE-NW",
    "LUTHERSTADT-WITTENBERG": "DE-ST",
    BRUEHL: "DE-NW",
    WEINHEIM: "DE-BW",
    "NEUSTADT-AM-RUEBENBERGE": "DE-NI",
    LEHRTE: "DE-NI",
    FALKENSEE: "DE-BB",
  },
  AT: {
    WIEN: "AT-9",
    GRAZ: "AT-6",
    LINZ: "AT-4",
    SALZBURG: "AT-5",
    INNSBRUCK: "AT-7",
    KLAGENFURT: "AT-2",
    VILLACH: "AT-2",
    WELS: "AT-4",
    "ST-PÖLTEN": "AT-3",
    DORNBIRN: "AT-8",
    "WIENER-NEUSTADT": "AT-3",
    STEYR: "AT-4",
    FELDKIRCH: "AT-8",
    BREGENZ: "AT-8",
    LEONDING: "AT-4",
    KLOSTERNEUBURG: "AT-3",
    BADEN: "AT-3",
    LEOBEN: "AT-6",
    WOLFSBERG: "AT-2",
    TRAUN: "AT-4",
    EISENSTADT: "AT-1",
  },
};

export function getCityState(country: string, city: string): string {
  country = toRealImmigrationCountry(country) ?? defaultImmigrationCountry();
  country = country.toUpperCase();
  city = city.toUpperCase();
  return CountryCityStateMapping[country][city];
}
