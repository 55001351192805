import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DocumentMode, nameofFactory } from "@ankaadia/ankaadia-shared";
import {
  CandidateDataEvent,
  CandidateEvent,
  CandidateStatus,
  Document,
  DocumentDataEvent,
  SharingTypeEnum,
  StaticDataModel,
  StaticDataType,
} from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { orderBy } from "lodash";
import { PrimeIcons } from "primeng/api";
import { Observable, filter, forkJoin, map, of, startWith, switchMap, tap } from "rxjs";
import { AppDateTimePipe } from "../../shared/pipes/date.pipe";
import { StaticDataService } from "../../shared/static-data/static-data.service";
import { PipeDescription, TableColumn, TableOperation } from "../../shared/table/table.model";
import { DocumentsService } from "../documents/documents.service";
import { EventTableEntityId, isCandidateId, isCollectionId, isCourseId } from "./candidate-event.model";
import { CandidateEventService } from "./candidate-event.service";
import { CandidateFlexibleEditService } from "../candidates/candidate-flexible-edit/candidate-flexible-edit.service";

type TableRow = Omit<CandidateDataEvent, "candidateId" | "eventType"> &
  Partial<Pick<DocumentDataEvent, "documentId" | "isShared">> & {
    id: CandidateEvent["candidateId"];
    type: CandidateEvent["eventType"];
    label: string;
  };

interface Options {
  timeframes: StaticDataModel[];
  categories: StaticDataModel[];
  documentTypes: StaticDataModel[];
}

const nameOfTableRow = nameofFactory<TableRow>();
const nameOf = nameofFactory<CandidateEventsTableComponent>();
const ExcludedCandidateStates: CandidateStatus[] = [
  CandidateStatus.Rejected,
  CandidateStatus.Archived,
  CandidateStatus.DroppedOut,
];

@Component({
  selector: "app-candidate-events-table",
  templateUrl: "./candidate-events-table.component.html",
  standalone: false,
})
export class CandidateEventsTableComponent implements OnInit, OnChanges {
  protected readonly language = this.transloco.getActiveLang();
  protected readonly DocumentMode = DocumentMode;

  protected options$: Observable<Options> = of({ timeframes: [], categories: [], documentTypes: [] });
  protected rows$: Observable<TableRow[]>;
  protected operations: TableOperation[] = [];
  protected columns: TableColumn[] = [];
  protected document$: Observable<Document> = of(null);
  protected selectedEventTimeframe: string;
  protected selectedEventCategories: string[] = [];
  protected selectedDocumentTypes?: string[];

  @Input()
  showTitle = false;

  @Input({ required: true })
  organizationId: string;

  @Input()
  entityId?: EventTableEntityId;

  @Input()
  selectedCategories: string[] = [];

  @Input()
  styleClass?: string;

  protected get candidateStatus(): CandidateStatus {
    return isCandidateId(this.entityId) ? translate(`enum.CandidateStatus.${this.entityId.status}`) : null;
  }

  protected get isInactiveCandidate(): boolean {
    return isCandidateId(this.entityId) && ExcludedCandidateStates.includes(this.entityId.status);
  }

  protected get isReadonlyCollaboration(): boolean {
    return (
      isCollectionId(this.entityId) && this.entityId.sharing?.sharingType === SharingTypeEnum.ReadOnlyCollaboration
    );
  }

  protected get canSelectDocumentTypes(): boolean {
    const documentEventCategories = ["DocumentExpiry", "DocumentResubmission"];
    return documentEventCategories.some((category) => this.selectedEventCategories.includes(category));
  }

  constructor(
    private readonly transloco: TranslocoService,
    private readonly route: ActivatedRoute,
    private readonly staticData: StaticDataService,
    private readonly eventService: CandidateEventService,
    private readonly documentService: DocumentsService,
    private readonly candidateFlexibleEditService: CandidateFlexibleEditService
  ) {}

  ngOnInit(): void {
    this.options$ = forkJoin([
      this.staticData.getStaticData(StaticDataType.CandidateEventTimeframe),
      this.staticData.getStaticData(StaticDataType.CandidateEventCategory).pipe(map((x) => this.filterCategories(x))),
      this.staticData.getStaticData(StaticDataType.AllowedUploadFileTypes),
    ]).pipe(
      switchMap(([timeframes, categories, documentTypes]) =>
        this.route.params.pipe(
          filter(({ eventCategory }) => categories.map((c) => c.value).includes(eventCategory)),
          map(({ eventCategory }) => [eventCategory]),
          startWith(this.selectedCategories),
          map((selectedCategories) => ({
            timeframes: orderBy(timeframes, (t) => t.value),
            categories: categories,
            documentTypes,
            selectedCategories,
          }))
        )
      ),
      tap(({ timeframes, selectedCategories }) => {
        this.selectedEventTimeframe = timeframes.find((t) => t.value === "060-DAYS").value;
        this.selectedEventCategories = selectedCategories;
      }),
      tap(() => this.loadEvents())
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.selectedEventTimeframe &&
      this.selectedEventCategories &&
      (changes[nameOf("organizationId")] || changes[nameOf("entityId")])
    ) {
      this.loadEvents();
    }
  }

  protected loadEvents(): void {
    this.rows$ = forkJoin([
      this.staticData.getStaticData(StaticDataType.AllowedUploadFileTypes),
      this.staticData.getStaticData(StaticDataType.CandidateEvent),
    ]).pipe(
      switchMap(([fileTypes, eventTypes]) => {
        return this.getEvents().pipe(
          tap((events) => {
            const hasEditableDocumentEvents = events.some((event) => this.isDocumentDataEvent(event) && event.isShared);
            this.operations = this.createTableOperations(hasEditableDocumentEvents);

            const hasDocumentEvents = events.some((event) => this.isDocumentDataEvent(event));
            this.columns = this.createTableColumns(hasDocumentEvents);
          }),
          map((events) => this.toTargetModel(events, fileTypes, eventTypes))
        );
      })
    );
  }

  protected edit(event: TableRow): void {
    this.document$ = this.documentService
      .get(event.documentId, event.organizationId, event.id)
      .pipe(
        switchMap((document) =>
          this.documentService.canEditDocument(document).pipe(map((hasAccess) => (hasAccess ? document : null)))
        )
      );
  }

  protected onClose(): void {
    this.document$ = of(null);
  }

  protected onSave(): void {
    this.document$ = of(null);
    this.loadEvents();
  }

  private toTargetModel(
    events: CandidateEvent[],
    fileTypes: StaticDataModel[],
    eventTypes: StaticDataModel[]
  ): TableRow[] {
    return events.map<TableRow>((event) => {
      const type = eventTypes.find((type) => type.value === event.eventType)?.label ?? "";
      const label = this.isDocumentDataEvent(event) ? this.buildDocumentEventLabel(event, fileTypes) : "";

      return {
        id: event.candidateId,
        organizationId: event.organizationId,
        date: event.date,
        country: event.country,
        displayId: event.displayId,
        displayName: event.displayName,
        type,
        label,
        documentId: this.isDocumentDataEvent(event) ? event.documentId : null,
        isShared: this.isDocumentDataEvent(event) ? event.isShared : null,
      };
    });
  }

  private buildDocumentEventLabel(event: DocumentDataEvent, staticData: StaticDataModel[]): string {
    const documentSetKind = event.documentSetName ?? translate(`documentSet.types.${event.documentSetType}`);
    const documentType = staticData.find((entry) => entry.value === event.documentType)?.label ?? "";
    return [documentType, `(${documentSetKind})`].join(" ");
  }

  private getRouteLink(row: TableRow): any[] {
    return ["/app", "candidates", "edit", row.organizationId, row.id];
  }

  private createTableOperations(hasEditableDocumentEvents: boolean): TableOperation[] {
    return !hasEditableDocumentEvents
      ? []
      : [
          {
            label: translate("common.edit", null, this.language),
            icon: PrimeIcons.PENCIL,
            operation: (x: TableRow): void => this.edit(x),
            canOperate: (x: TableRow): boolean => !!x.documentId && !!x.isShared,
          },
        ];
  }

  private createTableColumns(hasDocumentEvents: boolean): TableColumn[] {
    return [
      {
        header: translate("candidateEvent.date.title"),
        fieldname: nameOfTableRow("date"),
        sortable: true,
        includeInGlobalFilter: true,
        pipeDescription: new PipeDescription(AppDateTimePipe, { dateStyle: "medium" }),
      },
      ...(isCandidateId(this.entityId)
        ? []
        : [
            {
              header: translate("number.title"),
              fieldname: nameOfTableRow("displayId"),
              sortable: true,
              includeInGlobalFilter: true,
              includeFlag: true,
              tooltip: (x: TableRow): string => `${x.displayId}, ${x.displayName} `,
              routeLink: (x: TableRow): any[] => this.getRouteLink(x),
              canIconClick: () => true,
              icon: () => PrimeIcons.USER_EDIT,
              iconClick: (row: TableRow) => this.candidateFlexibleEditService.openCandidate(row.id, row.organizationId),
              iconClass: "ml-2",
            },
            {
              header: translate("name.title"),
              fieldname: nameOfTableRow("displayName"),
              sortable: true,
              includeInGlobalFilter: true,
            },
          ]),
      {
        header: translate("candidateEvent.category.title"),
        fieldname: nameOfTableRow("type"),
        sortable: true,
        includeInGlobalFilter: true,
      },
      ...(hasDocumentEvents
        ? [
            {
              header: translate("document.title"),
              fieldname: nameOfTableRow("label"),
              sortable: true,
              includeInGlobalFilter: true,
            },
          ]
        : []),
    ];
  }

  private getEvents(): Observable<CandidateEvent[]> {
    if (!this.entityId) {
      return this.eventService.getAll({
        organizationId: this.organizationId,
        eventTimeframe: this.selectedEventTimeframe,
        eventCategories: this.selectedEventCategories,
        documentTypes: this.selectedDocumentTypes,
      });
    }

    if (isCandidateId(this.entityId) && this.entityId.id) {
      return this.eventService.get({
        organizationId: this.entityId.organizationId,
        candidateId: this.entityId.id,
        eventTimeframe: this.selectedEventTimeframe,
        eventCategories: this.selectedEventCategories,
        documentTypes: this.selectedDocumentTypes,
      });
    }

    if (isCollectionId(this.entityId)) {
      return this.eventService.getAllForCollection({
        organizationId: this.entityId.organizationId,
        collectionId: this.entityId.collectionId,
        eventTimeframe: this.selectedEventTimeframe,
        eventCategories: this.selectedEventCategories,
        documentTypes: this.selectedDocumentTypes,
        sharingId: this.entityId.sharing?.id,
      });
    }

    if (isCourseId(this.entityId)) {
      return this.eventService.getForCourse({
        organizationId: this.organizationId,
        courseId: this.entityId.courseId,
        eventTimeframe: this.selectedEventTimeframe,
        eventCategories: this.selectedEventCategories,
        documentTypes: this.selectedDocumentTypes,
      });
    }

    return of([]);
  }

  private isDocumentDataEvent(event: CandidateEvent): event is DocumentDataEvent {
    return typeof (event as DocumentDataEvent)?.documentId === "string";
  }

  private filterCategories(categories: StaticDataModel[]): StaticDataModel[] {
    if (isCourseId(this.entityId)) {
      return categories.filter((x) => x.value === "Exam");
    }
    return categories;
  }
}
