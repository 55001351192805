import { Component } from "@angular/core";
import { CollectionAutoFilterTemplateFragment, CollectionAutoFilterTemplateSetInput } from "@ankaadia/graphql";
import { v4 as uuidv4 } from "uuid";
import { SettingsService } from "../../../../shared/services/settings.service";

@Component({
  selector: "app-collection-auto-filter-templates",
  templateUrl: "./collection-auto-filter-templates.component.html",
  styleUrl: "./collection-auto-filter-templates.component.scss",
  standalone: false,
})
export class CollectionAutoFilterTemplatesComponent {
  eventTarget: EventTarget;
  newButtonDisabled = false;
  showSidebar = false;
  newTemplate: CollectionAutoFilterTemplateSetInput;
  selectedTemplate: CollectionAutoFilterTemplateFragment;

  constructor(private readonly settings: SettingsService) {}

  newClicked(): void {
    this.showSidebar = true;
    this.newTemplate = {
      organizationId: this.settings.organizationId,
      id: uuidv4(),
      name: "",
      description: "",
      filters: [],
      templateName: "",
    };
  }

  closeSidebar(template: CollectionAutoFilterTemplateFragment): void {
    this.selectedTemplate = template;
    this.showSidebar = false;
  }
}
