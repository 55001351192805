import { Directive, OnInit } from "@angular/core";
import { Confirmation } from "primeng/api";
import { ConfirmPopup } from "primeng/confirmpopup";

export interface UpdatedConfirmation extends Confirmation {
  close: () => void;
}

@Directive({ selector: "p-confirmPopup", standalone: false })
export class ConfirmPopupFixDirective implements OnInit {
  private hideSource: ConfirmPopup["hide"];

  constructor(private readonly confirmPopup: ConfirmPopup) {}

  ngOnInit(): void {
    this.confirmPopup.confirmation = {};
    this.hideSource = this.confirmPopup.hide;
    this.confirmPopup.hide = (): void => this.hide(this.confirmPopup);
  }

  hide(confirmPopup: ConfirmPopup): void {
    const confirm = <UpdatedConfirmation>confirmPopup.confirmation;
    if (confirm?.close) {
      confirm.close();
    }
    this.hideSource.call(confirmPopup);
  }
}
