import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AdHocEducationExam, AdHocEducationExamCreateInput, AdHocEducationExamUpdateInput } from "@ankaadia/graphql";
import { isEmpty } from "lodash";
import { AdHocEducationExamForm } from "./education-exam-ad-hoc-form.model";
import { AdHocEducationExamFormService } from "./education-exam-ad-hoc-form.service";

@Component({
  selector: "app-education-exam-ad-hoc-dialog",
  templateUrl: "./education-exam-ad-hoc-dialog.component.html",
  standalone: false,
})
export class EducationExamAdHocDialogComponent implements OnInit, OnChanges {
  @Input({ required: true })
  exam: Partial<AdHocEducationExam>;

  protected form: AdHocEducationExamForm;

  @Output()
  readonly saved = new EventEmitter<AdHocEducationExamCreateInput | AdHocEducationExamUpdateInput>();

  @Output()
  readonly closed = new EventEmitter<void>();

  constructor(
    private readonly formService: AdHocEducationExamFormService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form = this.formService.createForm();
    this.patchForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.exam) this.patchForm();
  }

  private patchForm(): void {
    if (!this.form) return;
    this.form.patchValue(this.exam, { emitEvent: true });
    this.form.markAsPristine();
    this.changeDetector.detectChanges();
  }

  save(): void {
    this.saved.emit(this.form.getRawValue());
  }

  cancel(): void {
    this.closed.emit();
  }

  protected readonly isEmpty = isEmpty;
}
