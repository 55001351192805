import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { TalentPoolSharingForm } from "./talent-pool-sharing-form.model";

@Injectable({ providedIn: "root" })
export class TalentPoolSharingFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createForm(): TalentPoolSharingForm {
    return this.formBuilder.group<TalentPoolSharingForm["controls"]>({
      id: this.formBuilder.control(null),
      organizationId: this.formBuilder.control(null),
      _etag: this.formBuilder.control(null),
      collectionId: this.formBuilder.control(null, [Validators.required]),
      talentPoolId: this.formBuilder.control(null, [Validators.required]),
      talentPoolOrganizationId: this.formBuilder.control(null, [Validators.required]),
      expiryDate: this.formBuilder.control(null),
      enabled: this.formBuilder.control(null),
      useCv: this.formBuilder.control(null),
      cvKind: this.formBuilder.control(null),
    });
  }
}
