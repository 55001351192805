<ng-container *appTranslate="let t">
  <div>
    <p-chip
      *ngFor="let benefit of form.value"
      [label]="benefit | staticData: StaticDataType.Benefits | async"
      styleClass="mr-2 mb-2"
    ></p-chip>

    <button
      pButton
      [pTooltip]="t('common.edit')"
      type="button"
      icon="pi pi-pencil"
      class="p-button-rounded p-button-text vertical-align-middle"
      (click)="showSidebar = true"
    ></button>
  </div>

  <p-sidebar [(visible)]="showSidebar" styleClass="p-sidebar-md">
    <p-card *ngIf="showSidebar" [header]="t('benefits.edit')">
      <div class="p-fluid">
        <div class="field">
          <p-listbox
            [options]="benefits | async"
            [formControl]="form"
            [multiple]="true"
            [checkbox]="true"
            [filter]="true"
          ></p-listbox>
        </div>
      </div>

      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
          <p-button
            [label]="t('common.save')"
            icon="pi pi-check"
            (onClick)="showSidebar = false"
            class="mr-2"
          ></p-button>
          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            (onClick)="showSidebar = false"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  </p-sidebar>
</ng-container>
