import { Component, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  DocumentSetType,
  DocumentSetTypeNames,
  declaredDocumentSetTypes,
  hasDeclaredDocumentSetTypes,
} from "@ankaadia/ankaadia-shared";
import {
  DocumentConfigurationInputType,
  DocumentDeliveryFormat,
  RequiredDocumentFragment,
  StaticDataModel,
  StaticDataType,
} from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { clone, cloneDeep, isArray, isEmpty, keys, uniq } from "lodash";
import { ConfirmationService, FilterService, PrimeIcons, SortEvent } from "primeng/api";
import { Table } from "primeng/table";
import { forkJoin, mergeMap, startWith, tap } from "rxjs";
import { MigrationSpecificsFactory } from "../../../migration-specific/migration-specifics.factory";
import { ICountrySpecificRequiredDocumentsFields } from "../../../migration-specific/migration-specifics.model";
import { valuesOf } from "../../../shared/services/form.helper";
import { StaticDataContext, StaticDataService } from "../../../shared/static-data/static-data.service";
import { TableColumn } from "../../../shared/table/table.model";
import { MessageService } from "../../message/message.service";
import { OrganizationsService } from "../../organizations/organizations.service";
import { RequiredDocumentService } from "../required-document.service";
import { DocumentConfigurationInputTypeForm, DocumentSetItemForm } from "./document-set-item-form.model";
import { DocumentSetItemFormService } from "./document-set-item-form.service";

@Component({
  selector: "app-document-set-item",
  templateUrl: "./document-set-item.component.html",
  styleUrl: "./document-set-item.component.scss",
  standalone: false,
})
export class DocumentSetItemComponent implements OnInit {
  private readonly language = this.transloco.getActiveLang();
  private readonly knownDocumentSetTypes: StaticDataModel[] = DocumentSetTypeNames.map((type) => ({
    value: type,
    label: translate(`documentSet.types.${type}`),
  }));

  protected readonly staticDataMap: Partial<Record<StaticDataType, StaticDataModel[]>> = {};
  protected errorMessage: string;

  protected StaticDataTypeEnum: typeof StaticDataType = StaticDataType;
  protected requiredDocuments: RequiredDocumentFragment;

  @ViewChild("table")
  protected table: Table;

  protected countryStaticDataType = StaticDataType.Countries;

  protected cols: (TableColumn & {
    options?: any;
    type?: string;
    staticDataType?: StaticDataType;
    includeAll?: boolean;
    matchMode?: string;
    filterFormControl?: FormControl;
    isArrayValue?: boolean;
    frozen?: boolean;
  })[];

  protected form: DocumentSetItemForm;

  protected customFilterName = "custom-in";

  protected rows: AbstractControl<any, any>[];
  protected immigrationCountrySpecificFields: ICountrySpecificRequiredDocumentsFields[] = [];
  protected knownDeliveryFormats: StaticDataModel[] = [
    {
      value: DocumentDeliveryFormat.Paper,
      label: translate("enum.DocumentDeliveryFormat.Paper"),
    },
    {
      value: DocumentDeliveryFormat.Digital,
      label: translate("enum.DocumentDeliveryFormat.Digital"),
    },
  ];

  protected staticDataContext: StaticDataContext;
  linkedOrganizations: StaticDataModel[];
  linkedOrganizationsMap: Record<string, string> = {};

  constructor(
    private readonly organizationsService: OrganizationsService,
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly route: ActivatedRoute,
    private readonly requiredDocService: RequiredDocumentService,
    private readonly messageService: MessageService,
    private readonly formService: DocumentSetItemFormService,
    private readonly router: Router,
    private readonly filterService: FilterService,
    private readonly migrationSpecificsFactory: MigrationSpecificsFactory,
    private readonly confirmationService: ConfirmationService
  ) {}

  get documents(): FormArray<DocumentConfigurationInputTypeForm> {
    return this.form.controls.documents;
  }

  protected getEmployers(ids: string[]): string {
    return isEmpty(ids) ? "" : ids.map((id) => this.linkedOrganizationsMap[id]).join(", ");
  }

  private newDocument(value: DocumentConfigurationInputType): FormGroup {
    const row = this.formService.createRowForm(value, this.immigrationCountrySpecificFields);
    row.controls.notNecessary.valueChanges
      .pipe(startWith(row.controls.notNecessary.value))
      .subscribe((notNecessary) => {
        if (notNecessary) {
          row.controls.isOptional.disable();
        } else {
          row.controls.isOptional.enable();
        }
      });
    this.staticDataMap[StaticDataType.DocumentFormats].forEach((x) => {
      const rows = row.controls.documentFormat.controls ?? [];
      if (!rows.find((y) => y.controls.value.value == x.value)) {
        row.controls.documentFormat.push(this.formService.createDocumentFormatForm({ value: x.value, count: 0 }));
      }

      const rowsOther = row.controls.documentFormatOther.controls ?? [];
      if (!rowsOther.find((y) => y.controls.value.value == x.value)) {
        row.controls.documentFormatOther.push(this.formService.createDocumentFormatForm({ value: x.value, count: 0 }));
      }
    });
    row.controls.documentType.valueChanges
      .pipe(startWith(row.controls.documentType.value))
      .subscribe((documentType) => {
        const control = row.controls.documentSetType;
        if (hasDeclaredDocumentSetTypes(documentType)) {
          if (!declaredDocumentSetTypes(documentType).includes(control.value as DocumentSetType)) {
            control.setValue(null);
          }
          control.enable();
        } else {
          control.setValue(null);
          control.disable();
        }
      });
    row.controls.documentFormat.controls.forEach((x) =>
      x.controls.requiredDeliveryFormats.valueChanges
        .pipe(startWith(x.controls.requiredDeliveryFormats.value))
        .subscribe((y) =>
          (y ?? []).includes(DocumentDeliveryFormat.Paper)
            ? (x.controls.count.enable(), x.controls.count.value < 1 ? x.controls.count.setValue(1) : null)
            : (x.controls.count.disable(), x.controls.count.setValue(0))
        )
    );
    row.controls.documentFormatOther.controls.forEach((x) =>
      x.controls.requiredDeliveryFormats.valueChanges
        .pipe(startWith(x.controls.requiredDeliveryFormats.value))
        .subscribe((y) =>
          (y ?? []).includes(DocumentDeliveryFormat.Paper)
            ? (x.controls.count.enable(), x.controls.count.value < 1 ? x.controls.count.setValue(1) : null)
            : (x.controls.count.disable(), x.controls.count.setValue(0))
        )
    );
    row.controls.documentFormatOther.controls.sort((a, b) =>
      a.controls.value.value.localeCompare(b.controls.value.value)
    );
    row.controls.documentFormat.controls.sort((a, b) => a.controls.value.value.localeCompare(b.controls.value.value));
    // make sure that only one immigrationCountrySpecificFields field is enabled
    valuesOf(row).subscribe((value) => {
      if (this.immigrationCountrySpecificFields.some((x) => !isEmpty(value[x.name]))) {
        this.immigrationCountrySpecificFields
          .filter((x) => isEmpty(value[x.name]))
          .forEach((x) => row.controls[x.name].disable({ emitEvent: false }));
      } else {
        this.immigrationCountrySpecificFields.forEach((x) => row.controls[x.name].enable({ emitEvent: false }));
      }
    });
    return row;
  }

  addNewDocument(): void {
    const newRowValues = {};
    // Add new row with filter values as default
    keys(this.table.filters).forEach((filterField) => {
      if (
        isArray(this.table.filters[filterField]) &&
        (<[]>this.table.filters[filterField]).length > 0 &&
        this.table.filters[filterField][0]?.value
      ) {
        const controlName = filterField.split(".")[1];
        if (
          isArray(this.table.filters[filterField][0]?.value) &&
          !this.cols.find((c) => c.fieldname == filterField)?.isArrayValue
        ) {
          newRowValues[controlName] = this.table.filters[filterField][0]?.value[0];
        } else {
          newRowValues[controlName] = this.table.filters[filterField][0]?.value;
        }
      }
    });
    const newRow = this.newDocument(newRowValues as DocumentConfigurationInputType);
    this.documents.push(newRow);
    setTimeout(() => {
      this.table.selection = this.documents.controls[this.documents.controls.length - 1];
      const list = document.querySelectorAll("tr");
      const index = list.length - 2;
      const targetElement = list.item(index);
      targetElement.scrollIntoView();
    }, 0);
  }

  canAddNewItem(): boolean {
    return this.form.valid;
  }

  removeRowDocument(event: Event, row: { controls: Record<string, FormGroup> }): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("requiredDocument.removeRowMessage"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.documents.removeAt(
          this.documents.controls.findIndex((x: FormGroup) => x.controls.id.value == row.controls.id.value)
        );
        this.form.markAsDirty();
      },
    });
  }

  duplicateRow(row: { controls: Record<string, FormGroup> }): void {
    const index = this.documents.controls.findIndex((x: FormGroup) => x.controls.id.value == row.controls.id.value);
    const copiedValues = cloneDeep(<DocumentConfigurationInputType>this.documents.at(index).value);
    copiedValues.id = null;
    this.documents.insert(index, this.newDocument(copiedValues));
    this.form.markAsDirty();
  }

  setTableColumnsText(): void {
    this.cols = [
      { header: "", includeInGlobalFilter: false, sortable: false, fieldname: "", frozen: true }, // Delete/Copy Button
      {
        fieldname: "controls.documentType.value",
        header: translate("documentType.title"),
        includeInGlobalFilter: true,
        sortable: true,
        staticDataType: StaticDataType.AllowedUploadFileTypes,
        options: this.staticDataMap[StaticDataType.AllowedUploadFileTypes],
        type: "staticData",
        includeAll: false,
        matchMode: this.customFilterName,
        filterFormControl: new FormControl(),
        frozen: true,
      },
      {
        fieldname: "controls.documentSetType.value",
        header: translate("documentSet.title"),
        includeInGlobalFilter: true,
        sortable: true,
        options: this.knownDocumentSetTypes,
        includeAll: false,
        matchMode: this.customFilterName,
        filterFormControl: new FormControl(),
      },
      {
        fieldname: "controls.countryOfOrigin.value",
        header: translate("countryOfOrigin.title"),
        includeInGlobalFilter: true,
        sortable: true,
        staticDataType: StaticDataType.Countries,
        options: this.staticDataMap[StaticDataType.Countries],
        type: "staticData",
        includeAll: true,
        matchMode: this.customFilterName,
        filterFormControl: new FormControl(),
      },
      {
        fieldname: "controls.federalStateDestination.value",
        header: translate("federalState.title"),
        includeInGlobalFilter: true,
        sortable: true,
        staticDataType: StaticDataType.FederalStates,
        options: this.staticDataMap[StaticDataType.FederalStates],
        type: "staticData",
        includeAll: true,
        matchMode: this.customFilterName,
        filterFormControl: new FormControl(),
      },
      {
        fieldname: "controls.profession.value",
        header: translate("occupation.title"),
        includeInGlobalFilter: true,
        sortable: true,
        staticDataType: StaticDataType.Profession,
        options: this.staticDataMap[StaticDataType.Profession],
        type: "staticData",
        includeAll: true,
        matchMode: this.customFilterName,
        filterFormControl: new FormControl(),
      },
      {
        fieldname: "controls.employerBeforeRecognitionIds.value",
        header: translate("beforeRecognition.shortTitle"),
        includeInGlobalFilter: true,
        sortable: true,
        options: this.linkedOrganizations,
        type: "options",
        includeAll: true,
        matchMode: this.customFilterName,
        filterFormControl: new FormControl(),
      },
      {
        fieldname: "controls.employerAfterRecognitionIds.value",
        header: translate("afterRecognition.shortTitle"),
        includeInGlobalFilter: true,
        sortable: true,
        options: this.linkedOrganizations,
        type: "options",
        includeAll: true,
        matchMode: this.customFilterName,
        filterFormControl: new FormControl(),
      },
      ...this.immigrationCountrySpecificFields.map((x) => ({
        fieldname: `controls.${x.name}.value`,
        header: translate(x.labelKey),
        includeInGlobalFilter: true,
        sortable: true,
        staticDataType: x.staticDataType,
        options: this.staticDataMap[x.staticDataType],
        type: "",
        includeAll: false,
        matchMode: this.customFilterName,
        filterFormControl: new FormControl(),
        isArrayValue: true,
      })),
      {
        fieldname: "controls.notNecessary.value",
        header: translate("requiredDocument.notNecessary"),
        includeInGlobalFilter: true,
        sortable: true,
        type: "boolean",
        includeAll: false,
        matchMode: "equals",
        filterFormControl: new FormControl(),
      },
      {
        fieldname: "controls.documentFormat.value",
        header: translate("candidate2.title"),
        includeInGlobalFilter: false,
        sortable: false,
        staticDataType: StaticDataType.DocumentFormats,
        options: this.staticDataMap[StaticDataType.DocumentFormats],
        type: "staticData",
        includeAll: false,
        matchMode: null,
        filterFormControl: new FormControl(),
      },
      {
        fieldname: "controls.documentFormatOther.value",
        header: translate("other.title"),
        includeInGlobalFilter: false,
        sortable: false,
        staticDataType: StaticDataType.DocumentFormats,
        options: this.staticDataMap[StaticDataType.DocumentFormats],
        type: "staticData",
        includeAll: false,
        matchMode: null,
        filterFormControl: new FormControl(),
      },
      {
        fieldname: "controls.isOptional.value",
        header: translate("optional.title"),
        sortable: true,
        includeInGlobalFilter: true,
        type: "boolean",
        includeAll: false,
        matchMode: "equals",
        filterFormControl: new FormControl(),
      },
    ];
  }

  ngOnInit(): void {
    this.form = this.formService.createForm(this.immigrationCountrySpecificFields);
    this.form.markAsPristine();
    this.form.statusChanges.subscribe((status) => {
      const context = {
        migrationColumns: this.immigrationCountrySpecificFields.map((x) => translate(x.labelKey)).join(", "),
      };
      const errorState = status == "INVALID" ? uniq(this.getNestedFormValidationErrors(this.form)) : [];
      this.errorMessage = "";
      this.errorMessage += errorState.includes("duplicate")
        ? translate("requiredDocument.duplicate", context) + " "
        : "";
      this.errorMessage += errorState.includes("onlyAll") ? translate("requiredDocument.onlyAll", context) + " " : "";
      this.errorMessage += errorState.includes("onlyOne") ? translate("requiredDocument.onlyOne", context) + " " : "";
      this.errorMessage += errorState.includes("atLeastOne")
        ? translate("requiredDocument.atLeastOne", context) + " "
        : "";
    });
    this.route.params
      .pipe(
        mergeMap((data) =>
          this.requiredDocService.get(data.id, data.orgId).pipe(
            tap((x) => {
              this.requiredDocuments = clone(x);
              this.requiredDocuments.configData = this.requiredDocuments?.configData?.slice() ?? []; // in order to unfreeze the array
              this.staticDataContext = { immigrationCountry: x.immigrationCountry, organizationId: x.organizationId };
              this.immigrationCountrySpecificFields = this.migrationSpecificsFactory
                .getSpecifics(this.requiredDocuments.immigrationCountry)
                .getMigrationCountrySpecificRequiredDocumentsFields();
            })
          )
        ),
        mergeMap(() => this.staticDataService.getStaticData(StaticDataType.All, this.language, this.staticDataContext))
      )
      .subscribe((allOptions) => {
        forkJoin([
          this.staticDataService.getStaticData(StaticDataType.Countries, this.language, this.staticDataContext).pipe(
            tap((countryOptions) => {
              this.staticDataMap[StaticDataType.Countries] = allOptions.concat(countryOptions);
            })
          ),
          this.staticDataService.getStaticData(StaticDataType.Profession, this.language, this.staticDataContext).pipe(
            tap((professions) => {
              this.staticDataMap[StaticDataType.Profession] = allOptions.concat(professions);
            })
          ),
          this.staticDataService
            .getStaticData(StaticDataType.FederalStates, this.language, this.staticDataContext)
            .pipe(
              tap((federalStatesOptions) => {
                this.staticDataMap[StaticDataType.FederalStates] = allOptions.concat(federalStatesOptions);
              })
            ),
          this.staticDataService
            .getStaticData(StaticDataType.DocumentFormats, this.language, this.staticDataContext)
            .pipe(tap((formats) => (this.staticDataMap[StaticDataType.DocumentFormats] = formats))),
          this.staticDataService
            .getStaticData(StaticDataType.AllowedUploadFileTypes, this.language, this.staticDataContext)
            .pipe(
              tap((types) => {
                this.staticDataMap[StaticDataType.AllowedUploadFileTypes] = types;
              })
            ),
          this.organizationsService.getCascadedLinkedOrganizations(this.requiredDocuments.organizationId).pipe(
            tap((x) => {
              this.linkedOrganizations = allOptions.concat(x.map((org) => ({ value: org.id, label: org.name })));
              this.linkedOrganizationsMap = x.reduce((prev, cur) => ({ ...prev, [cur.id]: cur.name }), {});
              this.linkedOrganizationsMap = allOptions.reduce(
                (prev, cur) => ({ ...prev, [cur.value]: cur.label }),
                this.linkedOrganizationsMap
              );
            })
          ),
          ...this.immigrationCountrySpecificFields.map((x) =>
            this.staticDataService.getStaticData(x.staticDataType, this.language, this.staticDataContext).pipe(
              tap((staticData) => {
                this.staticDataMap[x.staticDataType] = staticData;
              })
            )
          ),
        ]).subscribe(() => {
          this.filterService.register(this.customFilterName, (value: any, filter: string[]): boolean => {
            return value != null && Array.isArray(value)
              ? value.some((x) => this.filterService.filters.in(x, filter))
              : this.filterService.filters.in(value, filter);
          });
          ((this.requiredDocuments.configData as DocumentConfigurationInputType[]) ?? [])
            .map((x: DocumentConfigurationInputType) => this.newDocument(x))
            .forEach((x) => this.documents.push(x));
          this.setTableColumnsText();
          this.rows = this.documents.controls;
        });
      });
  }

  getNestedFormValidationErrors(control: AbstractControl): string[] {
    const errors: string[] = [];
    const controlErrors = control.errors;
    if (controlErrors) {
      Object.keys(controlErrors).forEach((keyError) => {
        errors.push(keyError);
      });
    }
    if (control instanceof FormGroup) {
      Object.keys(control.controls).forEach((key) => {
        errors.push(...this.getNestedFormValidationErrors(control.controls[key]));
      });
    }
    if (control instanceof FormArray) {
      for (let i = 0; i < control.length; i++) {
        errors.push(...this.getNestedFormValidationErrors(control.at(i)));
      }
    }
    return errors;
  }

  save(): void {
    this.requiredDocuments.configData = this.documents.getRawValue();
    this.requiredDocuments.configData.forEach((el) => {
      if (el.notNecessary) {
        el.isOptional = false;
        el.documentFormat.forEach((format) => {
          format.count = 0;
        });
        el.documentFormatOther.forEach((format) => {
          format.count = 0;
        });
      }
    });
    this.form.markAsPending();
    this.requiredDocService.update(this.requiredDocuments).subscribe((x) => {
      this.requiredDocuments = clone(x);
      this.form.markAsPristine();
      this.form.updateValueAndValidity();
      this.messageService.add({
        severity: "success",
        summary: translate("requiredDocument.updated.title"),
        detail: translate("requiredDocument.updated.message", document),
      });
    });
  }

  clear(table: Table): void {
    table.clear();
  }

  saveAndClose(): void {
    this.save();
    void this.router.navigate([".."], { relativeTo: this.route });
  }

  cancel(): void {
    void this.router.navigate([".."], { relativeTo: this.route });
  }

  getFilterControl<T>(control: FormControl, value: T): FormControl<T> {
    control.setValue(value);
    return control;
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const rawValue1 = data1.value[event.field.split(".")[1]];
      const rawValue2 = data2.value[event.field.split(".")[1]];
      const col = this.cols.find((x) => x.fieldname === event.field);
      const value1 =
        col?.type === "staticData" && !Array.isArray(rawValue1)
          ? this.staticDataMap[col.staticDataType].find((x) => x.value == rawValue1)?.label
          : col?.type === "staticData" && Array.isArray(rawValue1) && rawValue1.length > 0
            ? this.staticDataMap[col.staticDataType].find((x) => x.value == rawValue1[0])?.label
            : rawValue1;
      const value2 =
        col?.type === "staticData" && !Array.isArray(rawValue2)
          ? this.staticDataMap[col.staticDataType].find((x) => x.value == rawValue2)?.label
          : col?.type === "staticData" && Array.isArray(rawValue2) && rawValue2.length > 0
            ? this.staticDataMap[col.staticDataType].find((x) => x.value == rawValue2[0])?.label
            : rawValue2;
      let result = null;
      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === "string" && typeof value2 === "string") result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      return event.order * result;
    });
  }

  getDocumentSetTypes(documentType: string): StaticDataModel[] {
    const declatedTypes = declaredDocumentSetTypes(documentType) ?? [];
    return declatedTypes.map((setType) => ({ value: setType, label: translate(`documentSet.types.${setType}`) }));
  }
}
