<ng-container *appTranslate="let t" [formGroup]="form">
  <p-dropdown
    [options]="options"
    [required]="true"
    [placeholder]="t('dataSource.placeholder')"
    [filter]="false"
    formControlName="dataSource"
  ></p-dropdown>
  <small class="p-error" *ngIf="form.controls.dataSource.errors?.required">
    {{ t("dataSource.required") }}
  </small>
</ng-container>
