import { Injectable } from "@angular/core";
import {
  CreateEducationExamParticipantGQL,
  CreateManyEducationExamParticipantsGQL,
  DeleteEducationExamParticipantGQL,
  EducationExamParticipantCreateInput,
  EducationExamParticipantCreateManyInput,
  EducationExamParticipantDeleteInput,
  EducationExamParticipantFragment,
  EducationExamParticipantGetInput,
  GetEducationExamParticipantsGQL,
} from "@ankaadia/graphql";
import { pick } from "lodash";
import { Observable, map } from "rxjs";
import {
  removeAdHocExamsByCandidateFromCache,
  removeExamsByCandidateFromCache,
} from "../education-exams/education-exam.service";

@Injectable({
  providedIn: "root",
})
export class EducationExamParticipantsService {
  constructor(
    private readonly get: GetEducationExamParticipantsGQL,
    private readonly create: CreateEducationExamParticipantGQL,
    private readonly createMany: CreateManyEducationExamParticipantsGQL,
    private readonly remove: DeleteEducationExamParticipantGQL
  ) {}

  getAll(input: EducationExamParticipantGetInput): Observable<EducationExamParticipantFragment[]> {
    return this.get
      .fetch({
        input: input,
      })
      .pipe(map((x) => x.data.getEducationExamParticipants));
  }

  createExamParticipant(input: EducationExamParticipantCreateInput): Observable<EducationExamParticipantFragment> {
    return this.create
      .mutate(
        { input: input },
        {
          update: (cache) => {
            removeExamsByCandidateFromCache(cache, input.candidateId);
            removeAdHocExamsByCandidateFromCache(cache, input.candidateId);
          },
        }
      )
      .pipe(map((x) => x.data.createEducationExamParticipant));
  }

  createManyExamParticipants(
    input: EducationExamParticipantCreateManyInput
  ): Observable<EducationExamParticipantFragment[]> {
    return this.createMany
      .mutate(
        { input: input },
        {
          update: (cache) => {
            removeExamsByCandidateFromCache(cache, "");
            removeAdHocExamsByCandidateFromCache(cache, "");
          },
        }
      )
      .pipe(map((x) => x.data.createManyEducationExamParticipants));
  }

  deleteExamParticipant(input: EducationExamParticipantDeleteInput, candidateId: string): Observable<boolean> {
    return this.remove
      .mutate(
        { input: pick(input, "organizationId", "candidateOrganizationId", "candidateId", "examId") },
        {
          update: (cache) => {
            removeExamsByCandidateFromCache(cache, candidateId);
            removeAdHocExamsByCandidateFromCache(cache, candidateId);
          },
        }
      )
      .pipe(map((x) => x.data.deleteEducationExamParticipant.status));
  }
}
