import { Component, EventEmitter, Output } from "@angular/core";
import { TranslateDirective } from "../../../../shared/transloco/translate.directive";
import { ContractCreateForm, ContractFormService } from "../contract-form.service";
import { AbstractControl, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../../../../shared/shared.module";
import { ContractDateComponent } from "../contract-date/contract-date.component";
import { ContractCreateInput, PlanListFragment, StaticDataModel, SupportedBillingCurrency } from "@ankaadia/graphql";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";
import { PlanService } from "../../plan/plan.service";
import { Observable } from "rxjs";
import { PaymentSettingsFormComponent } from "../payment-settings/payment-settings-form.component";
import { PlanSummaryComponent } from "../../plan/plan-summary/plan-summary.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DiscountsComponent } from "../discounts/discounts.component";

@Component({
  standalone: true,
  selector: "app-create-contract",
  templateUrl: "./create-contract.component.html",
  providers: [ContractFormService, PlanService],
  imports: [
    TranslateDirective,
    ReactiveFormsModule,
    SharedModule,
    ContractDateComponent,
    PaymentSettingsFormComponent,
    PlanSummaryComponent,
    DiscountsComponent,
  ],
})
export class CreateContractComponent {
  form: ContractCreateForm;

  currencies: StaticDataModel[] = [];

  plans: Observable<PlanListFragment[]>;

  @Output()
  readonly save = new EventEmitter<ContractCreateInput>();

  @Output()
  readonly cancelCreate = new EventEmitter<void>();

  constructor(
    contractFormService: ContractFormService,
    staticDataService: StaticDataService,
    planService: PlanService
  ) {
    this.form = contractFormService.createContractCreateForm();
    this.form.updateValueAndValidity();

    this.currencies = staticDataService.transformEnumToStaticDataModel(
      "SupportedBillingCurrency",
      SupportedBillingCurrency
    );
    this.plans = planService.getAllPlans();

    this.configureAutoFill();
  }

  private configureAutoFill(): void {
    const contractStartDate = this.form.get("startDate");
    const phaseStartDate = this.form.get("phases.0.startDate");
    const candidatePaymentStartDate = this.form.get("candidatePaymentSettings.0.startDate");
    const licensePaymentStartDate = this.form.get("licensePaymentSettings.0.startDate");

    function setIfPristine(control: AbstractControl<Date>, value: Date): void {
      if (control.pristine) control.setValue(value, { emitEvent: false });
    }

    contractStartDate.valueChanges.pipe(takeUntilDestroyed()).subscribe((startDate) => {
      setIfPristine(phaseStartDate, startDate);
      setIfPristine(candidatePaymentStartDate, startDate);
      setIfPristine(licensePaymentStartDate, startDate);
    });

    phaseStartDate.valueChanges.pipe(takeUntilDestroyed()).subscribe((startDate) => {
      setIfPristine(candidatePaymentStartDate, startDate);
      setIfPristine(licensePaymentStartDate, startDate);
    });
  }
}
