<ng-container *appTranslate="let t">
  <p-toolbar>
    <p-button
      [label]="t('adHocExamResults.add')"
      icon="pi pi-plus"
      class="p-button-success mr-2"
      (onClick)="showSidebar = true"
      [disabled]="readonly || !hasFullAccess"
    ></p-button>
  </p-toolbar>

  <app-education-exam-results-table
    [exams]="exams"
    [modules]="modules"
    [educationProviders]="educationProviders"
    [examResults]="examResults"
    [isEditable]="isEditable"
    [shownColumns]="[
      'actions',
      'name',
      'examType',
      'educationProvider',
      'examDateAndTime',
      'languageLevel',
      'examResult',
      'passedExamModules',
      'examModuleInformation',
    ]"
    (examResultDeleted)="onExamResultDeleted($event)"
  >
  </app-education-exam-results-table>

  <p-sidebar [visible]="showSidebar" styleClass="p-sidebar-md">
    <app-candidate-education-exam-results-dialog
      *ngIf="showSidebar"
      [exams]="exams"
      [examResults]="examResults"
      (saved)="addExamResult($event)"
      (closed)="showSidebar = false"
    ></app-candidate-education-exam-results-dialog>
  </p-sidebar>
</ng-container>
