<form [formGroup]="form" (ngSubmit)="save()" *appTranslate="let t">
  <p-card [header]="header">
    <app-collection-edit [form]="form"></app-collection-edit>

    <ng-template pTemplate="footer">
      <div class="flex flex-row justify-content-end">
        <p-button
          [disabled]="!form.valid || !form.dirty || disableSave"
          [label]="t('common.save')"
          icon="pi pi-check"
          type="submit"
          class="mr-2"
          data-testid="btn-save-new-collection"
        ></p-button>
        <p-button
          closeOnEscape
          [label]="t('common.cancel')"
          icon="pi pi-times"
          class="p-button-secondary"
          type="reset"
          (onClick)="cancel()"
          data-testid="btn-cancel-new-collection"
        ></p-button>
      </div>
    </ng-template>
  </p-card>
</form>
