import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from "@angular/core";
import { Candidate, CandidateNote, CandidateNoteForListFragment } from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { clone, cloneDeep, noop } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { Table } from "primeng/table";
import Quill from "quill";
import { Subscription } from "rxjs";
import { HasChanges } from "../../shared/guards/confirm-deactivate.guard";
import { MessageService } from "../message/message.service";
import { CandidateNotesService } from "./candidate-notes.service";

interface notEditingNoteState {
  state: "read";
}

interface addNoteState {
  state: "add";
  editedNote: CandidateNote;
}

interface editingNoteState {
  state: "edit";
  unmodifiedNote: CandidateNote;
  editedNote?: CandidateNote;
}

type noteComponentState = notEditingNoteState | addNoteState | editingNoteState;

@Component({
  selector: "app-candidate-notes",
  templateUrl: "./candidate-notes.component.html",
  styleUrl: "./candidate-notes.component.scss",
  standalone: false,
})
export class CandidateNotesComponent implements OnChanges, OnDestroy, HasChanges {
  readonly language = this.transloco.getActiveLang();

  private subscription: Subscription;
  private editor: Quill;

  @Input()
  model: Candidate;

  @Input()
  readonly: boolean;

  @ViewChild(Table)
  table: Table;

  notes: CandidateNoteForListFragment[] = [];

  editState: noteComponentState = { state: "read" };

  get isEditing(): boolean {
    return this.editState.state !== "read";
  }

  constructor(
    private readonly notesService: CandidateNotesService,
    private readonly messageService: MessageService,
    private readonly confirmationService: ConfirmationService,
    private readonly transloco: TranslocoService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model) {
      this.loadNotes(changes.model.currentValue);
      this.cancelNote();
    }
  }

  ngOnDestroy(): void {
    this.loadNotes(null);
  }

  updateEditor(editor: Quill, note: CandidateNote): void {
    this.editor = editor;
    if (note.id) {
      this.notesService.get(note.id, note.candidateId, note.organizationId).subscribe((x) => {
        this.editState = { state: "edit", unmodifiedNote: clone(x), editedNote: note };
        note.changedAt = x.changedAt;
        note.changedBy = x.changedBy;
        note._etag = x._etag;
        note.contents = x.contents;
        note.delta = x.delta;
        this.editor.setContents(note.delta);
      });
    } else {
      this.editState = { state: "add", editedNote: note };
      this.editor.setContents(null);
    }
  }

  updateHtml(contents: string, source: string, note: CandidateNote): void {
    if (source === "user") {
      note.contents = contents;
      note.delta = this.editor.getContents();
    }
  }

  addNote(): void {
    const note = new CandidateNote();
    note.candidateId = this.model.id;
    note.organizationId = this.model.organizationId;
    this.table.value.unshift(note);
    this.table.initRowEdit(note);
    this.editState = { state: "add", editedNote: note };
  }

  initNote(note: CandidateNote): void {
    if (this.editState.state === "edit") {
      this.editState = {
        ...this.editState,
        editedNote: note,
      };
    }
  }

  saveNote(note: CandidateNote): void {
    if (note.id) {
      this.notesService.update(note).subscribe(() => {
        this.messageService.add({ severity: "success", summary: translate("note.updated") });
        this.loadNotes(this.model);
        this.resetEditingState(note);
      });
    } else {
      this.notesService.create(note).subscribe(() => {
        this.messageService.add({ severity: "success", summary: translate("note.created") });
        this.loadNotes(this.model);
        this.resetEditingState(note);
      });
    }
  }

  cancelNote(
    event: Event = null,
    note: CandidateNote = null,
    triggerConfirmationCallback: CallableFunction = null,
    acceptCallBack: CallableFunction = null
  ): void {
    if (!event) {
      this.resetEditingState(note);
      return;
    }

    const editState = this.editState;

    if (editState.state === "edit" || editState.state === "add") {
      triggerConfirmationCallback?.();

      this.confirmationService.confirm({
        target: event.target,
        message: translate("note.confirmCancel"),
        icon: PrimeIcons.EXCLAMATION_TRIANGLE,
        accept: () => {
          if (editState.state === "edit") {
            this.notes = this.notes.map((x) => (x.id === editState.unmodifiedNote.id ? editState.unmodifiedNote : x));
          }
          if (editState.state === "add") {
            this.notes = this.notes.filter((x) => x.id);
          }
          this.resetEditingState(note);
          acceptCallBack?.();
        },
        reject: () => noop(),
      });
    }
  }

  deleteNote(note: CandidateNote, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("note.confirmDelete"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () =>
        this.notesService.delete(note).subscribe(() => {
          this.messageService.add({ severity: "success", summary: translate("note.deleted") });
          this.loadNotes(this.model);
        }),
    });
  }

  hasChanges(): boolean {
    return this.isEditing;
  }

  private loadNotes(model: Candidate): void {
    this.subscription?.unsubscribe();
    this.subscription = null;
    if (model?.id && model?.organizationId) {
      this.subscription = this.notesService
        .getAll(model.id, model.organizationId)
        .subscribe((xs) => (this.notes = cloneDeep(xs)));
    } else {
      this.notes = [];
    }
  }

  private resetEditingState(note: CandidateNote): void {
    this.editState = { state: "read" };
    if (note) this.table?.cancelRowEdit(note);
  }
}
