import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Validators } from "@angular/forms";
import { CollectionAndSharingInput } from "@ankaadia/graphql";
import { startWith } from "rxjs";
import { CollectionAndSharingFormService } from "./collection-and-sharing-form.service";

@Component({
  selector: "app-collection-and-sharing-create-dialog",
  templateUrl: "./collection-and-sharing-create-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CollectionAndSharingCreateDialogComponent implements OnInit {
  readonly form = this.formService.createForm();

  @Output()
  readonly saved = new EventEmitter<CollectionAndSharingInput>();

  @Output()
  readonly closed = new EventEmitter<void>();

  constructor(private readonly formService: CollectionAndSharingFormService) {}

  ngOnInit(): void {
    this.form.controls.createSharing.valueChanges
      .pipe(startWith(this.form.controls.createSharing.value))
      .subscribe((createSharing) => this.updateSharingValidators(createSharing));
  }

  submit(): void {
    const value = this.form.getRawValue();
    if (!value.createSharing) {
      delete value.sharing;
    }
    delete value.createSharing;
    delete value.sharing?.anonymizationOptions;
    delete value.sharing?.notificationEmailUserGroupIds;
    this.saved.emit(<CollectionAndSharingInput>value);
  }

  private updateSharingValidators(createSharing: boolean): void {
    const requiredSharingFields: (keyof typeof this.form.controls.sharing.controls)[] = [
      "sharingType",
      "destinationOrganizationId",
      "notificationEmailUserGroups",
    ];
    for (const field of requiredSharingFields) {
      const control = this.form.controls.sharing.controls[field];
      if (createSharing) {
        control.setValidators(Validators.required);
      } else {
        control.clearValidators();
        control.setErrors(null);
      }
      control.updateValueAndValidity({ onlySelf: true });
    }
  }
}
