import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Validators } from "@angular/forms";
import {
  DocumentSetType,
  INDIVIDUAL_CONTRACT_ID,
  RegulatedProfessions,
  areRepresentativesAllowedForRecognitionRole,
  getAllRecognitionRoles,
} from "@ankaadia/ankaadia-shared";
import {
  ContractTemplate,
  RecognitionPathEntryDe,
  StaticDataType,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { Subject, Subscription, startWith } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { valuesOf } from "../../../../../../shared/services/form.helper";
import { StaticDataContextEntry, StaticDataService } from "../../../../../../shared/static-data/static-data.service";
import { CandidateEquivalenceTestComponent } from "../../../../../candidate-reusables/candidate-equivalence-test/candidate-equivalence-test.component";
import { CandidateFederalStateComponent } from "../../../../../candidate-reusables/candidate-federal-state/candidate-federal-state.component";
import { CandidateImmigrationAuthorityComponent } from "../../../../../candidate-reusables/candidate-immigration-authority/candidate-immigration-authority.component";
import { CandidatePathOfRecognitionComponent } from "../../../../../candidate-reusables/candidate-path-of-recognition/candidate-path-of-recognition.component";
import { CandidateRecognitionAuthorityComponent } from "../../../../../candidate-reusables/candidate-recognition-authority/candidate-recognition-authority.component";
import { CandidateRecognitionCenterComponent } from "../../../../../candidate-reusables/candidate-recognition-center/candidate-recognition-center.component";
import { CandidateRecognitionFileNumberAcceleratedProcedureComponent } from "../../../../../candidate-reusables/candidate-recognition-file-number-accelerated-procedure/candidate-recognition-file-number-accelerated-procedure.component";
import { CandidateRecognitionFileNumberComponent } from "../../../../../candidate-reusables/candidate-recognition-file-number/candidate-recognition-file-number.component";
import { CandidateRecognitionTypeComponent } from "../../../../../candidate-reusables/candidate-recognition-type/candidate-recognition-type.component";
import { ResponsibleRolePurpose } from "../../../../../candidate-reusables/candidate-responsible-representative-group/candidate-responsible-representative-group.service";
import { CandidateResponsibleRoleComponent } from "../../../../../candidate-reusables/candidate-responsible-role/candidate-responsible-role.component";
import { OrganizationLinkService } from "../../../../../organizations/organization-link.service";
import { RecognitionPathEntryFormDe } from "../../../../candidate-form.model";

@Component({
  selector: "app-candidate-recognition-path-entry-de",
  templateUrl: "./candidate-recognition-path-entry-de.component.html",
  styleUrl: "./candidate-recognition-path-entry-de.component.scss",
  standalone: false,
})
export class CandidateRecognitionPathEntryDEComponent implements AfterViewInit, OnChanges, OnDestroy {
  readonly ResponsibleRolePurposeType = ResponsibleRolePurpose;
  readonly IndividualContract = { id: INDIVIDUAL_CONTRACT_ID, name: translate("contractTemplate.individual") };
  readonly today = new Date();
  readonly formId = uuidv4();

  protected readonly responsibleRoles = getAllRecognitionRoles();

  private readonly language = this.transloco.getActiveLang();

  @Input()
  form: RecognitionPathEntryFormDe;

  @Input()
  candidateId: string;

  @Input()
  candidateOrganizationId: string;

  @Input()
  candidateProfession: string;

  @Input()
  recognitionPath: RecognitionPathEntryDe;

  @Input()
  readonly: boolean;

  @Input()
  documentSetType: DocumentSetType;

  get canHaveADeputyOrRepresentative(): boolean {
    return areRepresentativesAllowedForRecognitionRole(this.form.controls?.responsibleRoleRecognition?.value);
  }

  readonly determinationNotices$ = this.staticDataService.getStaticData(
    StaticDataType.RecognitionNoticeKind,
    this.language
  );

  protected displayHint = false;
  protected federalStateOfEmployer: Subject<string> = new Subject<string>();

  @ViewChild(CandidateRecognitionAuthorityComponent)
  protected recognitionAuthority: CandidateRecognitionAuthorityComponent;

  @ViewChild(CandidateRecognitionCenterComponent)
  protected recognitionCenter: CandidateRecognitionCenterComponent;

  @ViewChild(CandidateFederalStateComponent)
  protected federalState: CandidateFederalStateComponent;

  readonly federalStateChanged: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(CandidateRecognitionTypeComponent)
  protected recognitionType: CandidateRecognitionTypeComponent;

  readonly recognitionTypeChanged: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(CandidateEquivalenceTestComponent)
  protected equivalenceTest: CandidateEquivalenceTestComponent;

  @ViewChild(CandidatePathOfRecognitionComponent)
  protected pathOfRecognition: CandidatePathOfRecognitionComponent;

  @ViewChild(CandidateResponsibleRoleComponent)
  protected responsibleRole: CandidateResponsibleRoleComponent;

  @ViewChild(CandidateImmigrationAuthorityComponent)
  protected immigrationAuthority: CandidateImmigrationAuthorityComponent;

  @ViewChild(CandidateRecognitionFileNumberComponent)
  protected fileNumber: CandidateRecognitionFileNumberComponent;

  @ViewChild(CandidateRecognitionFileNumberAcceleratedProcedureComponent)
  protected fileNumberAcceleratedProcedure: CandidateRecognitionFileNumberAcceleratedProcedureComponent;

  selectedContractTemplate: Partial<ContractTemplate>;

  areOrganizationsLinked: boolean;

  isRegulatedProfession: boolean;

  staticDataContext: StaticDataContextEntry;
  protected readonly ResponsiblePurpose = ResponsibleRolePurpose;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly transloco: TranslocoService,
    private readonly staticDataService: StaticDataService,
    private readonly linkService: OrganizationLinkService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
    this.subscriptions = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateProfession) {
      const regulatedProfession = RegulatedProfessions.includes(this.candidateProfession);
      if (regulatedProfession) {
        this.form.controls.recognitionCenter?.setValue(null);
      } else {
        this.form.controls.recognitionAuthority?.setValue(null);
      }
      this.isRegulatedProfession = regulatedProfession;
    }

    if (changes.candidateOrganizationId) {
      this.staticDataContext = {
        immigrationCountry: SupportedImmigrationCountry.De,
        organizationId: this.candidateOrganizationId,
      };
      this.linkService
        .isOrganizationLinked(this.candidateOrganizationId)
        .subscribe((x) => (this.areOrganizationsLinked = x));
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      valuesOf(this.form.controls.isCurrent).subscribe((x) => {
        if (x) {
          this.form.controls.recognitionStarted.enable();
          this.responsibleRole?.control?.enable();
          this.pathOfRecognition?.control.enable();
          this.recognitionType?.control.enable();
          this.equivalenceTest?.control.enable();
          this.fileNumber?.control.enable();
          this.fileNumberAcceleratedProcedure?.control.enable();
          this.federalState?.control.enable();
          this.recognitionAuthority?.control.enable();
          this.recognitionCenter?.control.enable();
          this.form.controls.certificateRequested.enable();
          this.form.controls.recognitionNoticeKind.enable();
          this.immigrationAuthority?.control.enable();
        } else {
          this.form.controls.recognitionStarted.disable();
          this.responsibleRole?.control?.disable();
          this.pathOfRecognition?.control.disable();
          this.recognitionType?.control.disable();
          this.equivalenceTest?.control.disable();
          this.fileNumber?.control.disable();
          this.fileNumberAcceleratedProcedure.control.disable();
          this.federalState?.control.disable();
          this.recognitionAuthority?.control.disable();
          this.recognitionCenter?.control.disable();
          this.form.controls.certificateRequested.disable();
          this.form.controls.recognitionNoticeKind.disable();
          this.immigrationAuthority?.control.disable();
        }
      })
    );
    this.subscriptions.push(
      this.form.controls.recognitionStarted.valueChanges
        .pipe(startWith(this.form.controls.recognitionStarted.value))
        .subscribe((x) =>
          x && this.form.controls.isCurrent.value
            ? this.form.controls.recognitionStartDate.enable()
            : this.form.controls.recognitionStartDate.disable()
        )
    );
    this.subscriptions.push(
      this.form.controls.certificateRequested.valueChanges
        .pipe(startWith(this.form.controls.certificateRequested.value))
        .subscribe((x) =>
          x && this.form.controls.isCurrent.value
            ? this.form.controls.certificateRequestDate.enable()
            : this.form.controls.certificateRequestDate.disable()
        )
    );
    this.subscriptions.push(
      this.recognitionType.isAccelerated.subscribe((x) => {
        this.immigrationAuthority.isEnabled = x && this.form.controls.isCurrent.value;
        if (x) {
          this.immigrationAuthority.control.addValidators(Validators.required);
        } else {
          this.immigrationAuthority.control.removeValidators(Validators.required);
        }
        this.immigrationAuthority.control.updateValueAndValidity();
      })
    );
  }

  isHwk(): boolean {
    return this.form?.value?.recognitionCenter === "HWK";
  }
}
