import { Pipe, PipeTransform } from "@angular/core";
import { Observable, of } from "rxjs";
import { CandidatesService } from "../../features/candidates/candidates.service";

@Pipe({ name: "thumbnail", standalone: false })
export class ThumbnailPipe implements PipeTransform {
  constructor(private readonly candService: CandidatesService) {}

  transform(value: { id?: string; organizationId: string }): Observable<string> {
    return value?.organizationId != null && value.id != null
      ? this.candService.getThumbnailDownloadUrl(value.organizationId, value.id)
      : of("");
  }
}
