import { Injectable } from "@angular/core";
import { FormBuilder, ValidationErrors, Validators } from "@angular/forms";
import { isEmpty } from "lodash";
import { DateTimeForm } from "../../../../shared/date-time/date-time.component";
import { AdHocEducationExamForm } from "../education-exam-ad-hoc-dialog/education-exam-ad-hoc-form.model";
import { EducationExamForm } from "./education-exam-form.model";

@Injectable({ providedIn: "root" })
export class EducationExamFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createForm(): EducationExamForm {
    return this.formBuilder.group<EducationExamForm["controls"]>(
      {
        id: this.formBuilder.control(null),
        organizationId: this.formBuilder.control(null),
        educationModuleId: this.formBuilder.control(null, Validators.required),
        name: this.formBuilder.control(null, [Validators.required]),
        educationCourseIds: this.formBuilder.control(null),
        examType: this.formBuilder.control(null, [Validators.required]),
        examInstitution: this.formBuilder.control(null),
        examModules: this.formBuilder.control(null),
        examLocation: this.formBuilder.control(null),
        plannedExamDateAndTime: this.formBuilder.group<DateTimeForm["controls"]>({
          date: this.formBuilder.control(null),
          hasTime: this.formBuilder.control(false),
        }),
        plannedExamDateAndTimeConfirmed: this.formBuilder.control(false),
        examDateAndTime: this.formBuilder.group<DateTimeForm["controls"]>({
          date: this.formBuilder.control(null),
          hasTime: this.formBuilder.control(false),
        }),
        plannedExamResultDate: this.formBuilder.control(null),
        responsibleRolePayment: this.formBuilder.control(null),
        _etag: this.formBuilder.control(null),
      },
      { validators: this.validateExamModules }
    );
  }

  validateExamModules(form: EducationExamForm | AdHocEducationExamForm): null | ValidationErrors {
    if (form.controls.id.value) {
      form.controls.examModules.setErrors(null);
      return null;
    }
    if (isEmpty(form.controls.examModules.value)) {
      form.controls.examModules.setErrors({ required: true });
      return { required: true };
    }

    return null;
  }
}
