import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { CandidateRole, getAllResidenceRoles } from "@ankaadia/ankaadia-shared";
import { ResidenceCandidateDataAt, StaticDataType, SupportedImmigrationCountry } from "@ankaadia/graphql";
import { StaticDataContextEntry, StaticDataService } from "../../../../../shared/static-data/static-data.service";
import { ResponsibleRolePurpose } from "../../../../candidate-reusables/candidate-responsible-representative-group/candidate-responsible-representative-group.service";
import { ResidenceFormAt } from "../../../candidate-form.model";

@Component({
  selector: "app-candidate-residence-at",
  templateUrl: "./candidate-residence-at.component.html",
  standalone: false,
})
export class CandidateResidenceATComponent implements OnChanges {
  readonly ResponsibleRolePurposeType = ResponsibleRolePurpose;
  protected staticDataContext: StaticDataContextEntry;

  @Input({ required: true })
  form: ResidenceFormAt;

  @Input({ required: true })
  residence: ResidenceCandidateDataAt;

  @Input({ required: true })
  candidateId: string;

  @Input({ required: true })
  candidateOrganizationId: string;

  @Input({ required: true })
  readonly: boolean;

  protected readonly ResponsiblePurpose = ResponsibleRolePurpose;
  protected readonly responsibleRoles = getAllResidenceRoles();
  protected readonly suitabilityForWork$ = this.staticDataService.getStaticData(StaticDataType.SuitabilityForWork);

  constructor(private readonly staticDataService: StaticDataService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateOrganizationId) {
      this.staticDataContext = {
        immigrationCountry: SupportedImmigrationCountry.At,
        organizationId: this.candidateOrganizationId,
      };
    }
  }

  protected get canHaveADeputyOrRepresentative(): boolean {
    const selectedRole = this.form.controls?.responsibleRoleResidence?.value;
    return selectedRole && selectedRole !== CandidateRole;
  }
}
