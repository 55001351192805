import { Injectable } from "@angular/core";
import {
  CandidateNote,
  CandidateNoteCreateInput,
  CandidateNoteDeleteInput,
  CandidateNoteForListFragment,
  CandidateNoteFragmentDoc,
  CandidateNoteUpdateInput,
  CreateCandidateNoteGQL,
  DeleteCandidateNoteGQL,
  GetCandidateNoteGQL,
  GetCandidateNotesGQL,
  UpdateCandidateNoteGQL,
} from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class CandidateNotesService {
  constructor(
    private readonly notesGet: GetCandidateNotesGQL,
    private readonly noteGet: GetCandidateNoteGQL,
    private readonly noteCreate: CreateCandidateNoteGQL,
    private readonly noteUpdate: UpdateCandidateNoteGQL,
    private readonly noteDelete: DeleteCandidateNoteGQL
  ) {}

  getAll(candidateId: string, organizationId: string): Observable<CandidateNoteForListFragment[]> {
    return this.notesGet
      .watch({ input: { candidateId: candidateId, organizationId: organizationId } })
      .valueChanges.pipe(map((x) => x.data.getCandidateNotes));
  }

  get(id: string, candidateId: string, organizationId: string): Observable<CandidateNote> {
    return this.noteGet
      .fetch({ input: { id: id, organizationId: organizationId, candidateId: candidateId } })
      .pipe(map((x) => x.data.getCandidateNote));
  }

  create(note: CandidateNote): Observable<CandidateNote> {
    const input = new CandidateNoteCreateInput();
    input.contents = note.contents;
    input.delta = note.delta;
    input.organizationId = note.organizationId;
    input.candidateId = note.candidateId;
    return this.noteCreate
      .mutate(
        { input: input },
        {
          update: (cache, result) =>
            cache.modify({
              fields: {
                getCandidateNotes: (refs, helper) =>
                  updateApolloCache(input, refs, helper, cache, result.data.createCandidateNote),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.createCandidateNote));
  }

  update(note: CandidateNote): Observable<CandidateNote> {
    const input = new CandidateNoteUpdateInput();
    input.id = note.id;
    input._etag = note._etag;
    input.contents = note.contents;
    input.delta = note.delta;
    input.organizationId = note.organizationId;
    input.candidateId = note.candidateId;
    return this.noteUpdate.mutate({ input: input }).pipe(map((x) => x.data.updateCandidateNote));
  }

  delete(note: CandidateNote): Observable<void> {
    const input = new CandidateNoteDeleteInput();
    input.id = note.id;
    input._etag = note._etag;
    input.organizationId = note.organizationId;
    input.candidateId = note.candidateId;
    return this.noteDelete
      .mutate(
        { input: input },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getCandidateNotes: (refs, { readField }) => refs.filter((ref) => readField("id", ref) !== input.id),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.deleteCandidateNote));
  }
}

function updateApolloCache(input, refs, { storeFieldName }, cache, data): any {
  if (!storeFieldName.includes(input.organizationId)) return refs;
  const ref = cache.writeFragment({
    data: data,
    fragment: CandidateNoteFragmentDoc,
    fragmentName: "CandidateNote",
  });
  if (refs != null && refs.length > 0) {
    return [...refs, ref];
  } else {
    return [ref];
  }
}
