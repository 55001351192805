<ng-container *appTranslate="let t">
  <p-blockUI [target]="panel" [blocked]="!candidateId && !isMultiEditMode">
    <span class="block-ui-message">{{ t("candidate.onboardingWarning") }}</span>
  </p-blockUI>

  <p-panel #panel [formGroup]="form">
    <p-fieldset [legend]="t('invite.title')">
      <div class="flex flex-row align-items-baseline">
        <div class="field">
          <label>
            {{ t("invite.title") }}:
            {{ systemOnboarding?.invitedToSystem | bool }}
          </label>
        </div>
      </div>

      <div class="field">
        <label>
          {{ t("invite.at") }}:
          {{ systemOnboarding?.invitedToSystemDate | appDateTime: { dateStyle: "short", timeStyle: "short" } : locale }}
        </label>
      </div>

      <div class="field">
        <label>
          {{ t("lastLogin.title") }}:
          {{ lastLoginDate | appDateTime: { dateStyle: "short", timeStyle: "short" } : locale }}
        </label>
      </div>

      <div class="field">
        <label>
          {{ t("invite.blocked") }}:
          {{ systemOnboarding?.isBlocked | bool }}
        </label>
      </div>

      <div class="field">
        <label>
          {{ t("invite.facebookId") }}:
          {{
            systemOnboarding?.facebookId
              ? systemOnboarding.facebookPageId
                ? systemOnboarding.facebookId + "/" + systemOnboarding.facebookPageId
                : systemOnboarding.facebookId
              : t("invite.notLinked")
          }}
        </label>
      </div>

      <div class="field">
        <label>
          {{ t("invite.messernOpt-In") }}:
          {{ systemOnboarding?.messagingOpt_In | bool }}
        </label>
      </div>

      <div class="field grid">
        <label class="col-fixed" for="profileDataEditStatus">{{ t("profileDataEditStatus.title") }}:</label>
        <div class="col">
          <p-dropdown
            #candidateProfileDataOverwriteStatus
            inputId="profileDataEditStatus"
            [options]="profileDataEditStatus"
            [placeholder]="t('profileDataEditStatus.placeholder')"
            [readonly]="readonly || isMultiEditMode"
            formControlName="profileDataEditStatus"
          ></p-dropdown>
        </div>
      </div>

      <div class="field">
        <app-messages-reception
          [messagesReception]="messagesReception"
          [showDetails]="!isMultiEditMode"
          [readonly]="readonly || isMultiEditMode"
          (toggled)="toggled.emit($event.value)"
        ></app-messages-reception>
      </div>

      <div class="field grid">
        <label class="col-fixed" for="matching">{{ t("matching.title") }}:</label>
        <div class="col">
          <p-dropdown
            inputId="matching"
            [options]="matchingStates"
            [placeholder]="t('matching.placeholder')"
            [readonly]="readonly"
            formControlName="matching"
          ></p-dropdown>
        </div>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('consent.title')">
      <div class="field">
        <label for="tc">
          {{ t("consent.accepted") }}:
          {{ systemOnboarding?.termsAndConditionsAccepted | bool }}
        </label>
      </div>

      <div class="field">
        <label for="invitedToSystemDate">
          {{ t("consent.at") }}:
          {{
            systemOnboarding?.termsAndConditionsAcceptedDate
              | appDateTime: { dateStyle: "short", timeStyle: "short" } : locale
          }}
        </label>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('communication.title')">
      <div class="field">
        <label for="communicationLanguage">{{ t("userLanguage.title") }}</label>
        <p-dropdown
          inputId="communicationLanguage"
          formControlName="communicationLanguage"
          [options]="availableLanguages$ | async"
          optionLabel="label"
          optionValue="code"
          [placeholder]="t('userLanguage.placeholder')"
          [readonly]="readonly"
        ></p-dropdown>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('systemEntry.title')">
      <div class="field">
        <label>
          {{ t("common.createdAt") }}:
          {{ candidate.creationDate | appDateTime: { dateStyle: "short", timeStyle: "short" } : locale }}
        </label>
      </div>
      <div class="field">
        <label>
          {{ t("common.createdThrough") }}:
          {{ candidate.technicalSource | enum: "TechnicalSourceEnum" }}
        </label>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('candidateDeletion.automaticDelete')">
      <div class="field">
        <label>
          {{ t("candidateDeletion.candidateWillBeDeleted") }}
          {{ candidate.deletionDate | appDateTime: { dateStyle: "short" } : locale }}</label
        >
      </div>
    </p-fieldset>

    <div class="flex justify-content-end mt-2">
      <p-button
        [label]="t('password.reset')"
        (onClick)="onPasswordResetClick($event)"
        [disabled]="
          isMultiEditMode || !systemOnboarding?.invitedToSystem || buttonDisabled || readonly || buttonsDisabled
        "
      ></p-button>
      <p-button
        class="ml-2"
        [label]="
          isMultiEditMode
            ? t('candidates.invite')
            : systemOnboarding?.invitedToSystem
              ? t('candidate.reinvite')
              : t('candidate.invite')
        "
        (onClick)="onInviteClick()"
        [disabled]="buttonDisabled || readonly || buttonsDisabled"
      ></p-button>
      <p-button
        class="ml-2"
        [label]="isMultiEditMode ? t('candidates.uninvite') : t('candidate.unboard')"
        (onClick)="unBoardClick()"
        [disabled]="
          (!systemOnboarding?.invitedToSystem && !isMultiEditMode) || buttonDisabled || readonly || buttonsDisabled
        "
      ></p-button>
      <p-button
        class="ml-2"
        [label]="systemOnboarding?.isBlocked ? t('invite.unblock') : t('invite.block')"
        (onClick)="blockClick()"
        [disabled]="
          isMultiEditMode || !systemOnboarding?.invitedToSystem || buttonDisabled || readonly || buttonsDisabled
        "
      ></p-button>
    </div>
  </p-panel>
</ng-container>
