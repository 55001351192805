<ng-container *appTranslate="let t">
  <app-candidate-multi-edit-preview
    [previews]="previews"
    [totalCandidates]="totalCandidates"
  ></app-candidate-multi-edit-preview>

  <div class="flex justify-content-end mt-4">
    <p-button
      [label]="t('common.save')"
      [disabled]="!previews?.length"
      type="submit"
      icon="pi pi-check"
      class="mr-2"
      (onClick)="save()"
    ></p-button>
    <p-button [label]="t('common.cancel')" type="cancel" icon="pi pi-times" (onClick)="cancel()"></p-button>
  </div>
</ng-container>
