import { Component, Input, OnChanges, QueryList, SimpleChanges, ViewChildren } from "@angular/core";
import {
  DocumentType,
  languageLevelGermanCertificateMapping,
  languageSkillLabelMapping,
} from "@ankaadia/ankaadia-shared";
import {
  CertifiedEducationExamFragment,
  CertifiedEducationExamGroupFragment,
  ExamModuleType,
  StaticDataType,
} from "@ankaadia/graphql";
import { compact, first, orderBy } from "lodash";
import { CandidateDocumentDateComponent } from "../../features/candidate-document-metadata/metadata-wrapper-components/candidate-document-date.component";
import { AnyCandidateForm } from "../../features/candidate-form/candidate-form.model";

@Component({
  selector: "app-formly-certified-education-exams-entry",
  templateUrl: "./formly-certified-education-exams-entry.component.html",
  standalone: false,
})
export class FormlyCertifiedEducationExamsEntryComponent implements OnChanges {
  readonly StaticDataType = StaticDataType;
  readonly languageSkillLabelMapping = languageSkillLabelMapping;

  @Input() examGroup: CertifiedEducationExamGroupFragment;
  @Input() candidateId: string;
  @Input() organizationId: string;
  @Input() form: AnyCandidateForm;
  @Input() disabled: boolean;
  @Input() readonly: boolean;

  @ViewChildren(CandidateDocumentDateComponent) issueDates: QueryList<CandidateDocumentDateComponent>;

  exams: CertifiedExamData[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.examGroup) {
      this.exams = (this.examGroup?.exams ?? []).map((x) => this.toCertifiedExamData(x));
    }
  }

  get latestIssueDate(): Date | null {
    return first(
      orderBy(
        compact((this.issueDates ?? []).map((x: CandidateDocumentDateComponent) => x.shownDate)),
        (x) => x,
        "desc"
      )
    );
  }

  private toCertifiedExamData(exam: CertifiedEducationExamFragment): CertifiedExamData {
    return {
      ...exam,
      documentType: this.getSelectedSet(exam.languageLevel),
    };
  }

  private getSelectedSet(skillLevel: string): DocumentType {
    if (!skillLevel) return "LANGCERT";

    return languageLevelGermanCertificateMapping[skillLevel] ?? "LANGCERT";
  }

  protected readonly ExamModuleType = ExamModuleType;
}

interface DocumentData {
  documentType: DocumentType;
}

type CertifiedExamData = CertifiedEducationExamFragment & DocumentData;
