<ng-container *appTranslate="let t">
  <form [formGroup]="form" styleClass="relative">
    <p-fieldset [legend]="t('responsibilities.title')" class="relative">
      <app-candidate-responsible-representative-group
        [form]="form"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateId]="candidateId"
        [canHaveADeputyOrRepresentative]="canHaveADeputyOrRepresentative"
        [responsibleRolePurpose]="ResponsiblePurpose.Residence"
        [purposeData]="residence"
        [readonly]="readonly"
        [alwaysShownRoles]="responsibleRoles"
        styleRoleClass="field col-12 lg:col-3"
        styleOrganizationClass="field col-12 lg:col-3"
        styleRepresentativeClass="field col-12 lg:col-3"
        styleDeputyClass="field col-12 lg:col-3"
        class="p-fluid formgrid grid"
      ></app-candidate-responsible-representative-group>
    </p-fieldset>

    <p-fieldset [legend]="t('workplace.title')" styleClass="relative">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 lg:col-6">
          <app-candidate-work-start-date
            [form]="form"
            class="field"
            [readonly]="readonly"
          ></app-candidate-work-start-date>
        </div>
        <div class="field col-12 lg:col-6">
          <label for="personalOnboardingDateTime">{{ t("personalOnboardingDateTime.title") }}</label>

          <app-date-time
            [form]="form.controls.personalOnboardingDateTime"
            inputId="personalOnboardingDateTime"
            [readonly]="readonly"
          >
          </app-date-time>
        </div>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('bank.title')" styleClass="relative">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 lg:col-6">
          <label for="residenceBank">{{ t("residenceBank.title") }}</label>
          <input
            id="residenceBank"
            pInputText
            formControlName="residenceBank"
            [maxLength]="300"
            [readonly]="readonly"
          />
        </div>
        <div class="field col-12 lg:col-6">
          <label for="residenceBankContactPartner">{{ t("residenceBankContactPartner.title") }}</label>
          <input
            id="residenceBankContactPartner"
            pInputText
            formControlName="residenceBankContactPartner"
            [maxLength]="300"
            [readonly]="readonly"
          />
        </div>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('medicalExamination.title')" styleClass="relative">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 lg:col-4 pt-5">
          <div class="field-checkbox pt-2">
            <p-checkbox
              id="recognitionMedicalCertificate"
              inputId="recognitionMedicalCertificate"
              [binary]="true"
              [readonly]="readonly"
              formControlName="recognitionMedicalCertificate"
            ></p-checkbox>
            <label for="recognitionMedicalCertificate">{{ t("recognitionMedicalCertificate.title") }}</label>
          </div>
        </div>
        <div class="field col-12 lg:col-3">
          <label for="suitabilityForWork">{{ t("suitabilityForWork.title") }}</label>
          <p-dropdown
            inputId="suitabilityForWork"
            [options]="suitabilityForWork$ | async"
            formControlName="suitabilityForWork"
            [showClear]="true"
            [placeholder]="t('suitabilityForWork.placeholder')"
            [readonly]="readonly"
          ></p-dropdown>
        </div>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('registrationAuthority.title')" styleClass="relative">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 lg:col-4">
          <app-candidate-residence-registration-office-date
            [form]="form"
            [readonly]="readonly"
            [showClear]="true"
            class="field"
          ></app-candidate-residence-registration-office-date>
        </div>

        <div class="field col-12 lg:col-4">
          <label for="registrationAuthorityAppointmentNumber">{{
            t("registrationAuthorityAppointmentNumber.title")
          }}</label>
          <input
            id="registrationAuthorityAppointmentNumber"
            pInputText
            formControlName="registrationAuthorityAppointmentNumber"
            [maxLength]="300"
            [readonly]="readonly"
          />
        </div>

        <div class="field col-12 lg:col-4">
          <label for="registrationAuthority">{{ t("registrationAuthority.title") }}</label>
          <input
            id="registrationAuthority"
            pInputText
            formControlName="registrationAuthority"
            [maxLength]="300"
            [readonly]="readonly"
          />
        </div>
      </div>
    </p-fieldset>
  </form>
</ng-container>
