import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { RecognitionPathEntryDe, StaticDataModel } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { concatWith, EMPTY, filter, Observable, of, switchMap } from "rxjs";
import { StaticDataContextEntry } from "../../../shared/static-data/static-data.service";
import { ImmigrationAuthorityService } from "../../immigration-authorities/immigration-authority.service";

const nameOf = nameofFactory<RecognitionPathEntryDe>();

const immigrationAuthoritiesMap = {
  "DE-BY": "990002",
  "DE-BE": "990001",
  "DE-BB": "076000",
  "DE-HH": "990004",
  "DE-MV": "069505",
  "DE-NW": "051514",
  "DE-RP": "054111",
  "DE-SL": "068100",
  "DE-SH": "068403",
  "DE-HB": "030001",
};

@Component({
  selector: "app-candidate-immigration-authority",
  templateUrl: "./candidate-immigration-authority.component.html",
  standalone: false,
})
export class CandidateImmigrationAuthorityComponent implements OnInit, OnChanges, OnDestroy {
  private readonly language = this.transloco.getActiveLang();

  private readonly accelerated = "ACCELERATED";

  @Input()
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  @Input({ required: true })
  federalState: FormControl<string>;

  @Input()
  federalStateManuallyChanged: Observable<string>;

  @Input({ required: true })
  recognitionType: FormControl<string>;

  @Input()
  recognitionTypeManuallyChanged: Observable<string>;

  @Input({ required: true })
  staticDataContext: StaticDataContextEntry;

  @Input() renderLabelUsingAlignmentCheckBox = false;

  @Input()
  readonly: boolean;

  @Output()
  readonly valueChanged = new EventEmitter<string>();

  get control(): FormControl<string> {
    return this.form.get(nameOf("immigrationAuthority")) as FormControl<string>;
  }

  set control(value: FormControl<string>) {
    this.form.setControl(nameOf("immigrationAuthority"), value);
  }

  set isEnabled(value: boolean) {
    if (value) this.control.enable();
    else this.control.disable();
  }

  filteredImmigrationAuthorities: Observable<StaticDataModel[]> = EMPTY;

  constructor(
    private readonly immigrationAuthorityService: ImmigrationAuthorityService,
    private readonly transloco: TranslocoService,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.control ??= new FormControl<string>(null);
    this.isEnabled = this.recognitionType?.value === this.accelerated;

    this.recognitionTypeManuallyChanged?.subscribe((recognitionType) => {
      if (recognitionType !== this.accelerated) {
        this.control.setValue(null);
      } else {
        this.control.setValue(immigrationAuthoritiesMap[this.federalState.value] ?? null);
      }
    });

    this.federalStateManuallyChanged?.subscribe((federalState) => {
      if (!this.recognitionType) return;
      if (immigrationAuthoritiesMap[federalState] && this.recognitionType.value === this.accelerated) {
        this.control.setValue(immigrationAuthoritiesMap[federalState]);
      } else {
        this.control.setValue(null);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.recognitionType?.currentValue) {
      of(this.recognitionType.value)
        .pipe(
          concatWith(this.recognitionType.valueChanges),
          takeUntilDestroyed(this.destroyRef),
          filter((_) => !!this.control)
        )
        .subscribe((recognitionType) => (this.isEnabled = recognitionType === this.accelerated));
    }

    if (changes.federalState?.currentValue) {
      this.filteredImmigrationAuthorities = of(this.federalState.value).pipe(
        concatWith(this.federalState.valueChanges),
        takeUntilDestroyed(this.destroyRef),
        switchMap((federalState) =>
          this.immigrationAuthorityService.getImmigrationAuthorities(
            this.staticDataContext.organizationId,
            this.staticDataContext.immigrationCountry,
            federalState ? [federalState] : null
          )
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("immigrationAuthority"), { emitEvent: false });
  }
}
