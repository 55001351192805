<ng-container *appTranslate="let t">
  <form [formGroup]="form" *ngIf="form">
    <div class="p-fluid formgrid">
      <div class="field">
        <label for="moduleType">{{ t("type.title") }}</label>
        <p-dropdown
          inputId="moduleType"
          formControlName="moduleType"
          [options]="types"
          [placeholder]="t('type.placeholder')"
          [prePopulateSingleOption]="true"
        ></p-dropdown>
        <small class="p-error" *ngIf="form.controls.moduleType.errors?.required">{{ t("type.required") }}</small>
      </div>

      <div class="field" *ngIf="form.controls.moduleType.value === EducationModuleType.LanguageLearning">
        <label for="languageLevel">{{ t("skillLevel.title") }}</label>
        <p-dropdown
          inputId="languageLevel"
          formControlName="languageLevel"
          [options]="languageLevels$ | async"
          [placeholder]="t('skillLevel.placeholder')"
        ></p-dropdown>
        <small class="p-error" *ngIf="form.controls.languageLevel.errors?.required">
          {{ t("skillLevel.required") }}
        </small>
      </div>
    </div>
  </form>
</ng-container>
