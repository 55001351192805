import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { CandidateTabConfiguration, HealthRegisterProfessions } from "@ankaadia/ankaadia-shared";
import {
  EmploymentRelationship,
  HousingAcquisition,
  RecognitionPathEntryAt,
  ResidenceCandidateDataAt,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { TabView } from "primeng/tabview";
import { MigrationForm } from "../../candidate-form.model";

@Component({
  selector: "app-candidate-migration-at",
  templateUrl: "./candidate-migration-at.component.html",
  standalone: false,
})
export class CandidateMigrationATComponent implements OnChanges, OnInit {
  @Input()
  form: MigrationForm;

  @Input()
  readonly: boolean;

  @Input()
  candidateId: string;

  @Input()
  candidateOrganizationId: string;

  @Input()
  recognitionPath: RecognitionPathEntryAt;

  @Input()
  tabs: CandidateTabConfiguration;

  @Input()
  employmentRelationship: EmploymentRelationship;

  @Input()
  residence: ResidenceCandidateDataAt;

  @Input()
  housingAcquisition: HousingAcquisition;

  @Input()
  candidateFunction: string;

  @Input()
  candidateProfession: string;

  @Input()
  isMultiEditMode: boolean;

  protected SupportedImmigrationCountry: typeof SupportedImmigrationCountry = SupportedImmigrationCountry;

  @ViewChild(TabView)
  tabView: TabView;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this.changeDetector.detectChanges(); //make sure that the tabView is available
    this.setCandidateDataDependingTabs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateFunction || changes.candidateProfession) this.setCandidateDataDependingTabs();
  }

  private setCandidateDataDependingTabs(): void {
    if (!this.tabView) return;
    const isTrainee = this.candidateFunction === "TRAINEE";
    const hideHealthProfessionTab = !HealthRegisterProfessions.includes(this.candidateProfession);

    const vocationalSchoolTab = this.tabView.tabs?.find(
      (tab) => tab.header === this.transloco.translate("vocationalSchool.title")
    );
    const qualificationMeasureTab = this.tabView.tabs?.find(
      (tab) => tab.header === this.transloco.translate("complementaryMeasure.title")
    );
    const healthProfessionTab = this.tabView.tabs?.find(
      (tab) => tab.header === this.transloco.translate("healthProfessionsRegister.title")
    );

    if (vocationalSchoolTab) {
      vocationalSchoolTab.closed = !isTrainee;
      vocationalSchoolTab.disabled = !isTrainee;
    }
    if (qualificationMeasureTab) {
      qualificationMeasureTab.closed = isTrainee;
      qualificationMeasureTab.disabled = isTrainee;
    }
    if (healthProfessionTab) {
      healthProfessionTab.closed = hideHealthProfessionTab;
      healthProfessionTab.disabled = hideHealthProfessionTab;
    }

    this.tabView.updateButtonState();
  }
}
