import {
  Candidate,
  MigrationCandidateDataAt,
  MigrationCandidateDataDe,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { AnyCandidateForm, CandidateForm, MigrationFormAt, MigrationFormDe } from "./candidate-form.model";

export function getMigration(candidate: Candidate): MigrationCandidateDataDe | MigrationCandidateDataAt {
  switch (candidate.immigrationCountry) {
    case SupportedImmigrationCountry.At:
      return candidate.migration.at;
    case SupportedImmigrationCountry.De:
    case SupportedImmigrationCountry.Unknown:
      return candidate.migration.de;
  }
}

export function getMigrationForm(
  form: CandidateForm,
  country: SupportedImmigrationCountry
): MigrationFormDe | MigrationFormAt {
  switch (country) {
    case SupportedImmigrationCountry.At:
      return form.controls.migration.controls.at;
    case SupportedImmigrationCountry.De:
    case SupportedImmigrationCountry.Unknown:
      return form.controls.migration.controls.de;
  }
}

export function extractCandidateFromForm(form: AnyCandidateForm): Candidate | null {
  if (!form) {
    return null;
  }

  const testedForm = form as CandidateForm;
  const controls = testedForm?.controls;

  if (controls?.function && controls?.profession && controls?.organizationId) {
    return testedForm.getRawValue() as Candidate;
  }

  // When a candidate is logged in, we will only have the OS profile form, thats why we construct the candidate manually.
  return form.parent == null
    ? ({ os: { profile: form.getRawValue() } } as Candidate)
    : extractCandidateFromForm(form.parent);
}
