import { Component, OnInit } from "@angular/core";
import { FieldActivityDiffFragment } from "@ankaadia/graphql";
import { cloneDeep } from "lodash";
import { CandidateFieldDiffService } from "./candidate-field-diff.service";

@Component({
  selector: "app-candidate-field-diff",
  templateUrl: "./candidate-field-diff.component.html",
  standalone: false,
})
export class CandidateFieldDiffComponent implements OnInit {
  showSidebar: boolean;
  diff: FieldActivityDiffFragment[] = null;

  constructor(private readonly service: CandidateFieldDiffService) {}

  ngOnInit(): void {
    this.service.fieldDiff.subscribe((diff) => {
      this.service.suspend();
      this.diff = cloneDeep(diff);
      this.showSidebar = true;
    });
  }

  close(): void {
    this.showSidebar = false;
    this.diff = null;
    this.service.resume();
  }
}
