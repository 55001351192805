import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { OrganizationSwitchService } from "../../features/organization-switch/organization-switch.service";

@Component({
  selector: "app-org-switch",
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OrgSwitchComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly organizationSwitchService: OrganizationSwitchService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnInit(): void {
    this.organizationSwitchService.switch(this.route.snapshot.params.orgId).subscribe(() => {
      this.document.defaultView.location.href = this.route.snapshot.params.href ?? "/";
    });
  }
}
