import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  Candidate,
  CollectionForCandidateItemFragment,
  CollectionType,
  GetSelectableCollectionsQuery,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { difference } from "lodash";
import { forkJoin } from "rxjs";
import { MessageDialogService } from "../../../../shared/message-dialog/message-dialog.service";
import { CollectionService } from "../../../collections/collection.service";
import { MessageService } from "../../../message/message.service";
import { CandidatesService } from "../../candidates.service";
import { CurrentCollectionService } from "../../current-collection.service";

type Collection = GetSelectableCollectionsQuery["getSelectableCollections"][0]["collection"];

@Component({
  selector: "app-candidate-assign-collections",
  templateUrl: "./candidate-assign-collections.component.html",
  standalone: false,
})
export class CandidateAssignCollectionsComponent implements OnInit, OnChanges {
  @Input() visible: boolean;
  @Input() model: Candidate;

  @Output() readonly closed = new EventEmitter<CollectionForCandidateItemFragment[]>();
  collections: Collection[];
  disableSubmit: boolean;
  form: FormGroup;

  constructor(
    private readonly currentCollectionService: CurrentCollectionService,
    private readonly candidateService: CandidatesService,
    private readonly collectionService: CollectionService,
    private readonly messageService: MessageService,
    private readonly errorService: MessageDialogService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({ collections: [] });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visible && this.visible && this.model != null) {
      this.form.reset();
      this.disableSubmit = false;
      forkJoin([
        this.collectionService.getCollectionsOnce(),
        this.candidateService.getCollectionsForCandidate(this.model.id, this.model.organizationId),
      ]).subscribe(
        ([selectable, selected]) =>
          (this.collections = selectable
            ?.filter((x) => {
              return !x.type || x.type === CollectionType.Standard;
            })
            .filter((x) => !selected || selected.every((y) => y.collectionId !== x.id)))
      );
    }
  }

  close(): void {
    this.closed.emit(null);
  }

  submit(collections: Collection[]): void {
    if (collections?.length > 0) {
      this.disableSubmit = true;
      this.candidateService
        .addCandidateToCollections(
          this.model.id,
          this.model.organizationId,
          this.currentCollectionService.collectionId,
          this.currentCollectionService.organizationId,
          collections.map((x) => ({ collectionId: x.id, organizationId: x.organizationId }))
        )
        .subscribe((result) => {
          const mapCols = collections.map((x) => ({
            collectionId: x.id,
            organizationId: x.organizationId,
            name: x.name,
          }));
          if (!result.error) {
            this.messageService.add({
              severity: "success",
              summary: translate("candidate.assigned"),
            });

            this.closed.emit(mapCols);
          } else {
            const notAssignedLists = mapCols.filter((collection) =>
              result.notAddedCollectionIds.find(
                (notAssignedCollection) => notAssignedCollection == collection.collectionId
              )
            );
            const assignedLists = difference(mapCols, notAssignedLists);

            this.errorService.showMessage(
              translate("candidate.assignFailed"),
              translate("candidate.assignFailedToLists", { lists: notAssignedLists.map((x) => x.name).join(", ") })
            );
            this.closed.emit(assignedLists);
          }
        });
    }
  }
}
