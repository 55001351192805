<ng-container *appTranslate="let t">
  <form [formGroup]="form" class="flex flex-row gap-3 align-items-center mb-3">
    <div class="flex-1 p-fluid formgrid grid align-items-center">
      <div class="field col-12 lg:col-6">
        <label for="residencePermit">{{ t("residencePermitIssued.title") }}</label>
        <p-dropdown
          inputId="residencePermit"
          [options]="residencePermits$ | async"
          formControlName="residencePermit"
          [showClear]="true"
          [placeholder]="t('residencePermit.placeholder')"
          [readonly]="readonly"
        ></p-dropdown>
      </div>

      <div class="field col-12 lg:col-6">
        <label for="immigrationAuthority">{{ t("immigrationAuthority.title") }}</label>
        <p-dropdown
          id="immigrationAuthority"
          inputId="immigrationAuthority"
          [options]="immigrationAuthorities$ | async"
          formControlName="immigrationAuthority"
          [showClear]="true"
          [placeholder]="t('immigrationAuthority.placeholder')"
          [readonly]="readonly"
        ></p-dropdown>
      </div>

      <div class="field col-12 lg:col-2">
        <label for="residenceRequestedElectronicallyDate">{{ t("residenceRequestedElectronicallyDate.title") }}</label>
        <p-calendar
          formControlName="residenceRequestedElectronicallyDate"
          [maxDate]="today"
          [readonly]="readonly"
        ></p-calendar>
      </div>

      <div class="field col-12 lg:col-2">
        <label for="residenceRequestedByPostDate">{{ t("residenceRequestedByPostDate.title") }}</label>
        <p-calendar formControlName="residenceRequestedByPostDate" [maxDate]="today" [readonly]="readonly"></p-calendar>
      </div>

      <app-candidate-document-date
        [inputId]="'residencePermit#' + index"
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
        [label]="t('residencePermitIssueDate.title')"
        documentType="RESIDENCEPERMIT"
        [selectedSet]="{ foreignKey: form.controls.id.value }"
        dateField="issueDate"
        class="field col-12 lg:col-2"
      ></app-candidate-document-date>

      <app-candidate-document-date
        [inputId]="'residencePermit#' + index"
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
        [label]="t('residencePermitValidFromDate.title')"
        documentType="RESIDENCEPERMIT"
        [selectedSet]="{ foreignKey: form.controls.id.value }"
        dateField="validFrom"
        class="field col-12 lg:col-2"
      ></app-candidate-document-date>

      <app-candidate-document-date
        [inputId]="'residencePermit#' + index"
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
        [label]="t('residencePermitValidUntilDate.title')"
        documentType="RESIDENCEPERMIT"
        [selectedSet]="{ foreignKey: form.controls.id.value }"
        dateField="validUntil"
        class="field col-12 lg:col-4"
      ></app-candidate-document-date>

      <div class="field col-12 lg:col-5 pt-4">
        <div class="field-checkbox mb-1">
          <p-checkbox
            inputId="fictionalCertificateAvailable"
            [binary]="true"
            formControlName="fictionalCertificateAvailable"
            (onChange)="fictionalCertificateAvailableClicked($event)"
            [readonly]="readonly"
          ></p-checkbox>
          <label for="fictionalCertificateAvailable"
            >{{ t("fictionalCertificateAvailable.title")
            }}<i class="ml-1 pi pi-info-circle" [pTooltip]="t('fictionalCertificateAvailable.description')"></i
          ></label>
        </div>
        <div class="field-checkbox ml-5">
          <p-checkbox
            inputId="fictionalCertificateDoesNotAuthorisesWorking"
            [binary]="true"
            formControlName="fictionalCertificateDoesNotAuthorisesWorking"
            [readonly]="readonly"
          ></p-checkbox>
          <label for="fictionalCertificateDoesNotAuthorisesWorking"
            >{{ t("fictionalCertificateDoesNotAuthorisesWorking.title")
            }}<i
              class="ml-1 pi pi-info-circle"
              [pTooltip]="t('fictionalCertificateDoesNotAuthorisesWorking.description')"
            ></i
          ></label>
        </div>
      </div>

      <div class="field col-12 lg:col-3">
        <label for="fictionalCertificateNumber">{{ t("fictionalCertificateNumber.title") }}</label>
        <input
          pInputText
          id="fictionalCertificateNumber"
          formControlName="fictionalCertificateNumber"
          [readonly]="readonly"
        />
      </div>

      <app-candidate-document-date
        [inputId]="'fictionalCertificate#' + index"
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
        [disabled]="!form.controls.fictionalCertificateAvailable.value"
        [label]="t('fictionalCertificateValidUntilDate.title')"
        documentType="FICTIONALCERT"
        [selectedSet]="{ foreignKey: form.controls.id.value }"
        dateField="validUntil"
        class="field col-12 lg:col-4"
      ></app-candidate-document-date>

      <app-candidate-residence-permit-warnings
        class="col-12"
        styleClass="block mt-2 mb-3"
        [form]="form"
      ></app-candidate-residence-permit-warnings>
    </div>
    <div>
      <p-button icon="pi pi-times" (onClick)="deleteClicked.emit()" [disabled]="readonly"></p-button>
    </div>
  </form>
</ng-container>
