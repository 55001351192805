<div *appTranslate="let t">
  <div class="p-fluid formgrid grid">
    <div class="field col-12">
      <div *ngIf="result.success; else notSuccess">
        <label for="successfull">{{ t("collection.transfer.successfull") }}</label>
      </div>

      <ng-template #notSuccess>
        <label for="notSuccessfull">{{ t("collection.transfer.notSuccessfull") }}</label>
        <div class="data">
          <label for="errorMessage">{{ t("collection.transfer.errorMessage") }}</label>
          <p>{{ result.errorMessage }}</p>
        </div>
        <div class="data">
          <label for="errorMessage">{{ t("collection.transfer.validationMessage") }}</label>
          <ul>
            <li *ngFor="let message of result.validationMessages">{{ message }}</li>
          </ul>
        </div>
      </ng-template>
    </div>
    <div class="field {{ size }} data" *ngIf="result.transferredCandidatesDisplayIds?.length > 0">
      <p-fieldset [legend]="t('collection.transfer.transferedCandidates')" class="relative">
        <p *ngFor="let displayId of result.transferredCandidatesDisplayIds">
          {{ displayId }}
        </p>
      </p-fieldset>
    </div>
    <div class="field {{ size }} data" *ngIf="result.notTransferredCandidatesDisplayIds?.length > 0">
      <p-fieldset [legend]="t('collection.transfer.notTransferedCandidates')" class="relative">
        <p *ngFor="let displayId of result.notTransferredCandidatesDisplayIds">
          {{ displayId }}
        </p>
      </p-fieldset>
    </div>
  </div>
  <div class="footer flex flex-row justify-content-end">
    <p-button [label]="t('common.ok')" (onClick)="okClicked()"> </p-button>
  </div>
</div>
