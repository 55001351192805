import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AddressType } from "@ankaadia/ankaadia-shared";
import {
  Candidate,
  CandidateAccess,
  CandidateMultiEditPreviewFragment,
  CandidateNote,
  CandidateStatus,
  CandidateUpdateInputSharing,
  EffectiveSharingOptionsInput,
  EffectiveSharingOutput,
  Sharing,
  SharingTypeEnum,
  StaticDataType,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { unset } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { OverlayPanel } from "primeng/overlaypanel";
import { combineLatest, finalize, forkJoin, map, Observable, of, Subject, switchMap, take } from "rxjs";
import { OrganizationFactoryService } from "../../../organization-specific/organization-specific-factory.service";
import { HasChanges } from "../../../shared/guards/confirm-deactivate.guard";
import { MessageDialogService } from "../../../shared/message-dialog/message-dialog.service";
import { UpdatedConfirmation } from "../../../shared/primeng/confirm-popup-fix/confirm-popup-fix.directive";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { CandidateNotesService } from "../../candidate-notes/candidate-notes.service";
import { CollectionService } from "../../collections/collection.service";
import { DeepLinkAccessService } from "../../deep-link-access/deep-link-access.service";
import { MessageService } from "../../message/message.service";
import { MeteredTranslationsService } from "../../metered-translations/metered-translations.service";
import { CandidateTagService } from "../../organizations/candidate-tag-selector/candidate-tag.service";
import { ProfessionConfigurationService } from "../../profession/profession-configuration.service";
import { CandidateFieldDiffService } from "../candidate-field-diff/candidate-field-diff.service";
import { CandidateData, CandidateFormComponent } from "../candidate-form/candidate-form.component";
import { CandidateMultiEditPreviewDialogComponent } from "../candidate-multi-edit-preview-dialog/candidate-multi-edit-preview-dialog.component";
import { CandidateMultiEditService } from "../candidate-multi-edit/candidate-multi-edit.service";
import { CandidateMultiInviteService } from "../candidate-multi-invite/candidate-multi-invite.service";
import { CandidatePageEntry, CandidatesService } from "../candidates.service";
import { CurrentCollectionService } from "../current-collection.service";

@Component({
  selector: "app-candidate-edit",
  templateUrl: "./candidate-edit.component.html",
  styleUrl: "./candidate-edit.component.scss",
  standalone: false,
})
export class CandidateEditComponent implements HasChanges, OnChanges {
  private readonly language = this.transloco.getActiveLang();

  protected statusChangedOverlaySubject: Subject<boolean>;

  @Input()
  selectedCandidates: readonly SelectedCandidateForEdit[] = [];

  @Input({ required: true })
  sharings: readonly Sharing[];

  @Input()
  isMultiEditMode: boolean;

  @Output()
  readonly isMultiEditModeChange = new EventEmitter<boolean>();

  @Output()
  readonly candidateAddedOrDeleted = new EventEmitter<void>();

  @Input()
  isEditing = false;

  @Output()
  readonly isEditingChange = new EventEmitter<boolean>();

  @Input()
  candidates: readonly { id?: string }[] = [];

  @Input({ required: true })
  standardImmigrationCountry: SupportedImmigrationCountry;

  @Input({ required: true })
  isReadOnlyCollaboration = false;

  @Input({ required: true })
  isUneditableCollaboration = false;

  @Input({ required: true })
  selectedSharing: Sharing;

  @Input({ required: true })
  selectedOrganizationId: string;

  @Input({ required: true })
  selectedCollectionId: string;

  @Input({ required: true })
  selectedCollectionOrganizationId: string;

  @ViewChild("deleteOverlay")
  deleteOverlay: OverlayPanel;

  @ViewChild("statusChangedOverlay")
  statusChangedOverlay: OverlayPanel;

  @ViewChild(CandidateFormComponent)
  candidateFormComponent: CandidateFormComponent;

  candidateData: CandidateData;

  statusChangingReason: string;

  private _isGeneralTabVisible: boolean;
  private inCandidateCreationMode = false;
  get isGeneralTabVisible(): boolean {
    return this._isGeneralTabVisible;
  }

  set isGeneralTabVisible(isGeneralTabVisible: boolean) {
    if (this._isGeneralTabVisible !== isGeneralTabVisible) {
      this._isGeneralTabVisible = isGeneralTabVisible;
      this.isGeneralTabVisibleChange.emit(isGeneralTabVisible);
    }
  }

  @Output()
  readonly isGeneralTabVisibleChange = new EventEmitter<boolean>();

  protected deletedCandidateNr: string;
  protected isBusy = false;
  protected isCreator = false;
  protected tabHash: string;
  protected isValid = true;
  protected isPreparingCandidate = false;

  updatedStatus: CandidateStatus;

  constructor(
    private readonly collectionService: CollectionService,
    private readonly deepLinkAccessService: DeepLinkAccessService,
    private readonly multiEditService: CandidateMultiEditService,
    private readonly staticDataService: StaticDataService,
    private readonly professionConfigService: ProfessionConfigurationService,
    private readonly specificsFactory: OrganizationFactoryService,
    private readonly candidateService: CandidatesService,
    protected readonly currentCollectionService: CurrentCollectionService,
    private readonly settings: SettingsService,
    private readonly transloco: TranslocoService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly messageService: MessageService,
    private readonly messageDialogService: MessageDialogService,
    private readonly dialogService: DialogService,
    private readonly confirmationService: ConfirmationService,
    private readonly candidateTagService: CandidateTagService,
    private readonly notesService: CandidateNotesService,
    protected readonly fieldDiffService: CandidateFieldDiffService,
    private readonly translationsService: MeteredTranslationsService,
    private readonly multiInviteService: CandidateMultiInviteService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.inCandidateCreationMode && (changes.selectedCandidates || changes.isMultiEditMode)) {
      if (!this.selectedCandidates?.length) {
        this.setIsEditing(false);
        this.candidateData = null;
        return;
      }

      if (this.isMultiEditMode || this.selectedCandidates?.length > 1) {
        const multiEditModeChanged =
          changes.isMultiEditMode && changes.isMultiEditMode.currentValue !== changes.isMultiEditMode.previousValue;
        const previousOrganizationId = changes.selectedCandidates.previousValue.at(0)?.organizationId;
        const currentOrganizationId = this.selectedCandidates.at(0)?.organizationId;

        const reloadCandidateData = multiEditModeChanged || previousOrganizationId !== currentOrganizationId;

        if (reloadCandidateData) {
          this.loadMultiEditCandidate(this.selectedCandidates.at(0).organizationId).subscribe((candidateData) => {
            this.candidateData = candidateData;
            this.setIsEditing(true);
          });
        }
        return;
      }

      const selectedCandidate = this.selectedCandidates?.at(0);

      if (selectedCandidate?.id) {
        this.setIsEditing(false);
        this.loadExistingCandidate(selectedCandidate.id, selectedCandidate.organizationId).subscribe(
          (candidateData) => (this.candidateData = candidateData)
        );
        return;
      }

      this.loadNewCandidate(selectedCandidate?.organizationId).subscribe((candidateData) => {
        this.candidateData = candidateData;
        this.setIsEditing(true);
      });
    }
    if (changes.selectedCandidates?.currentValue.length > 0) {
      this.inCandidateCreationMode = false; // As soon we have a selected Candidate we are not in creation Mode anymore
    }
  }

  edit(): void {
    this.setIsEditing(true);
    this.candidateFormComponent.validateCandidateAndForm();
  }

  save(event: Event): void {
    if (!this.candidateFormComponent.form.valid) {
      this.messageDialogService.showMessage(
        this.transloco.translate("user.badInput.title"),
        this.transloco.translate("candidate.warning")
      );
      return;
    }

    this.isBusy = true;
    const candidate = this.candidateFormComponent.getFormValue();

    const sharing: CandidateUpdateInputSharing = {
      sharedTabHash: this.tabHash,
      collectionId: this.currentCollectionService.collectionId,
      collectionOrganizationId: this.currentCollectionService.organizationId,
    };

    if (this.isMultiEditMode) {
      // these fields could not be removed from the form without breaking it
      // so we're keeping them but removing here, just before sending the data to the backend
      unset(candidate, "organizationId");
      unset(candidate, "os.profile.addresses");
      unset(candidate, "immigrationCountry");
      unset(candidate, "creationDate");
      unset(candidate, "displayId");

      const selectedCandidates = this.selectedCandidates;
      const candidateIds = selectedCandidates.map((x) => ({ id: x.id, orgId: selectedCandidates[0].organizationId }));
      this.guardStatusChange(candidate, event).subscribe(({ success, wasPausedOrRejected }) => {
        if (success) {
          this.multiEditService
            .preview(candidate, sharing, candidateIds)
            .pipe(
              switchMap((previews) => this.showPreviews(previews, candidateIds.length)),
              switchMap((shouldExecute) =>
                (shouldExecute ? this.multiEditService.execute(candidate, sharing, candidateIds) : of(null)).pipe(
                  finalize(() => (this.isBusy = false))
                )
              )
            )
            .subscribe((result) => {
              if (result) {
                this.messageService.add({
                  severity: "success",
                  summary: this.transloco.translate("candidateMultiEdit.updated"),
                });
                this.setIsEditing(false);
                if (wasPausedOrRejected) {
                  const reason = this.statusChangingReason?.trim();
                  if (reason?.length) {
                    const notes = selectedCandidates.map((x) => this.createReasonNote(x, reason));
                    forkJoin(notes.map((x) => this.notesService.create(x))).subscribe(
                      () => (this.statusChangingReason = null)
                    );
                  }
                }
                void this.router.navigate(["../.."], { relativeTo: this.route });
              }
            });
        } else {
          this.isBusy = false;
        }
      });
    } else if (this.isNewCandidate()) {
      const collection = this.currentCollectionService.hasCollection
        ? {
            collectionId: this.currentCollectionService.collectionId,
            organizationId: this.currentCollectionService.organizationId,
          }
        : null;
      this.candidateService
        .createCandidate(candidate, collection)
        .pipe(
          switchMap((candidate) =>
            combineLatest([this.getEffectiveSharing(candidate.id, candidate.organizationId), of(candidate)])
          ),
          finalize(() => (this.isBusy = false))
        )
        .subscribe(([effectiveSharing, candidate]) => {
          this.inCandidateCreationMode = true;
          if (collection) {
            this.messageService.add({
              severity: "success",
              summary: this.transloco.translate("candidate.added", {
                collectionName: this.currentCollectionService.collectionName,
              }),
            });
          }
          this.candidateData.effectiveSharing = effectiveSharing;
          this.candidateData = { ...this.candidateData, candidate };
          this.setIsEditing(false);
          void this.router.navigate([`edit/${candidate.organizationId}/${candidate.id}`], {
            relativeTo: this.route.parent,
          });
          this.candidateAddedOrDeleted.next();
        });
    } else {
      this.candidateFormComponent?.saveNotes();
      this.candidateFormComponent?.saveEducationData();

      this.guardStatusChange(candidate, event).subscribe(({ success, wasDroppedOut, wasPausedOrRejected }) => {
        if (success) {
          this.translationsService.upsertCandidateFieldTranslations().subscribe();

          this.candidateService
            .updateCandidate(candidate, sharing)
            .pipe(
              // reload tags after updating the candidate
              switchMap((candidate) =>
                this.candidateTagService
                  .getTags(
                    candidate.id,
                    candidate.organizationId,
                    this.currentCollectionService.sharingId,
                    this.currentCollectionService.sharingOrganizationId
                  )
                  .pipe(map((customTags) => [candidate, customTags] as const))
              ),
              finalize(() => (this.isBusy = false))
            )
            .subscribe(([candidate, customTags]) => {
              this.messageService.add({ severity: "success", summary: this.transloco.translate("candidate.updated") });
              this.candidateData = { ...this.candidateData, candidate, customTags };
              this.setIsEditing(false);
              this.updatedStatus = null;
              if (wasDroppedOut) {
                this.candidateFormComponent.collectionComponent?.reload();
              }

              if (wasPausedOrRejected) {
                const reason = this.statusChangingReason?.trim();
                if (reason?.length) {
                  const note = this.createReasonNote(this.candidateData.candidate, reason);
                  this.notesService.create(note).subscribe(() => (this.statusChangingReason = null));
                }
              }
            });
        } else {
          this.isBusy = false;
        }
      });
    }
  }

  multiInvite(): void {
    this.isBusy = true;
    const selectedCandidates = this.selectedCandidates;
    const candidateIds = selectedCandidates.map((x) => ({ id: x.id, orgId: selectedCandidates[0].organizationId }));
    this.multiInviteService
      .previewInvite(candidateIds)
      .pipe(
        switchMap((previews) => this.showPreviews(previews, candidateIds.length)),
        switchMap((shouldExecute) =>
          (shouldExecute ? this.multiInviteService.invite(candidateIds) : of(null)).pipe(
            finalize(() => (this.isBusy = false))
          )
        )
      )
      .subscribe(() => {
        this.messageService.add({
          severity: "success",
          summary: this.transloco.translate("candidateMultiEdit.invited"),
        });
      });
  }

  multiUninvite(): void {
    this.isBusy = true;
    const selectedCandidates = this.selectedCandidates;
    const candidateIds = selectedCandidates.map((x) => ({ id: x.id, orgId: selectedCandidates[0].organizationId }));
    this.multiInviteService
      .previewUninvite(candidateIds)
      .pipe(
        switchMap((previews) => this.showPreviews(previews, candidateIds.length)),
        switchMap((shouldExecute) =>
          (shouldExecute ? this.multiInviteService.uninvite(candidateIds) : of(null)).pipe(
            finalize(() => (this.isBusy = false))
          )
        )
      )
      .subscribe(() => {
        this.messageService.add({
          severity: "success",
          summary: this.transloco.translate("candidateMultiEdit.uninvited"),
        });
      });
  }

  private guardStatusChange(
    candidate: Candidate,
    event: Event
  ): Observable<{ success: boolean; wasDroppedOut?: boolean; wasPausedOrRejected?: boolean }> {
    return new Observable((subscriber) => {
      if (this.wasDroppedOut(candidate) && this.settings.removeCandidatesFromAllListsIfDropout) {
        this.confirmationService.confirm({
          target: event.target,
          message: this.transloco.translate("candidate.droppedOutWarning"),
          icon: PrimeIcons.EXCLAMATION_TRIANGLE,
          accept: () => {
            subscriber.next({ success: true, wasDroppedOut: true });
            subscriber.complete();
          },
          reject: () => {
            subscriber.next({ success: false, wasDroppedOut: true });
            subscriber.complete();
          },
          close: () => {
            subscriber.next({ success: false, wasDroppedOut: true });
            subscriber.complete();
          },
        } as UpdatedConfirmation);
      } else if (this.wasPausedOrRejected(candidate)) {
        this.updatedStatus = candidate.status;
        this.statusChangedOverlaySubject = new Subject();
        this.statusChangedOverlay.show(event);
        this.statusChangedOverlaySubject.pipe(take(1)).subscribe((value) => {
          this.statusChangedOverlay.hide();
          subscriber.next({ success: value, wasPausedOrRejected: true });
          subscriber.complete();
        });
      } else {
        subscriber.next({ success: true });
        subscriber.complete();
      }
    });
  }

  cancel(): void {
    this.statusChangingReason = null;
    this.isPreparingCandidate = true;
    if (this.isMultiEditMode) {
      this.isMultiEditMode = false;
      this.isMultiEditModeChange.emit(false);

      this.setIsEditing(false);
    } else if (this.isNewCandidate()) {
      this.setIsEditing(false);
      void this.router.navigate(["../.."], { relativeTo: this.route });
    } else {
      this.loadExistingCandidate(
        this.candidateData.candidate.id,
        this.candidateData.candidate.organizationId
      ).subscribe((candidateData) => {
        this.candidateData = candidateData;
        this.setIsEditing(false);
        this.candidateFormComponent.form.markAsPristine();
      });
    }

    this.setIsEditing(false);
  }

  delete(): void {
    const nextOrPrev = this.getNextOrPrevId();
    this.candidateService
      .removeCandidate(this.candidateData?.candidate, this.candidateData?.selectedSharing)
      .subscribe((x) => {
        this.deleteOverlay.hide();
        if (x) {
          this.messageService.add({ severity: "success", summary: this.transloco.translate("candidate.deleted") });
          void this.router.navigate([`../${nextOrPrev}`], { relativeTo: this.route });
          this.candidateAddedOrDeleted.next();
        }
      });
  }

  hasChanges(): boolean {
    return this.isEditing && this.candidateFormComponent?.hasChanges();
  }

  private isNewCandidate(): boolean {
    return this.selectedCandidates.length === 1 && !this.selectedCandidates[0].id;
  }

  private wasDroppedOut(candidate: Candidate): boolean {
    return (
      candidate.status === CandidateStatus.DroppedOut && candidate.status !== this.candidateData?.candidate?.status
    );
  }

  private wasPausedOrRejected(candidate: Candidate): boolean {
    return (
      (candidate.status === CandidateStatus.Paused || candidate.status === CandidateStatus.Rejected) &&
      candidate.status !== this.candidateData.candidate.status
    );
  }

  private createReasonNote(candidate: Pick<Candidate, "id" | "organizationId">, reason: string): CandidateNote {
    const note = new CandidateNote();
    note.candidateId = candidate.id;
    note.organizationId = candidate.organizationId;
    note.contents = `<p>${reason}</p>`;
    note.delta = { ops: [{ insert: `${reason}\n` }] };
    return note;
  }

  openDeleteOverlay(event: Event): void {
    this.deletedCandidateNr = null;
    this.deleteOverlay.show(event);
  }

  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHandler(event: BeforeUnloadEvent): void {
    if (this.hasChanges()) {
      event.preventDefault();
    }
  }

  private setIsEditing(isEditing: boolean): void {
    this.isEditing = isEditing;
    this.isEditingChange.emit(isEditing);
  }

  private getNextOrPrevId(): string {
    const index = this.candidates.findIndex((c) => c.id === this.candidateData?.candidate?.id);
    const nextOrPrev = index > -1 ? (this.candidates[index + 1] ?? this.candidates[index - 1]) : null;
    return nextOrPrev?.id ?? "..";
  }

  protected readonly CandidateStatus = CandidateStatus;

  private loadExistingCandidate(candidateId: string, organizationId: string): Observable<CandidateData> {
    const { checkAccess } = this.route.snapshot.queryParams;
    return (
      checkAccess
        ? // it's a link from the emails, gonna check the access
          this.deepLinkAccessService.canAccessCandidateEdit(candidateId, organizationId)
        : // carry on, it's probably just navigation within the app
          of<CandidateAccess>({ canAccess: true })
    ).pipe(
      switchMap((access) => {
        if (access.canAccess) {
          return forkJoin({
            specifics: this.specificsFactory.getOrganizationSpecifics(organizationId),
            forEdit: this.candidateService.getCandidateForEdit(
              candidateId,
              organizationId,
              this.currentCollectionService.sharingId,
              this.currentCollectionService.sharingOrganizationId
            ),
            enabledTabs: this.staticDataService.getStaticData(StaticDataType.AllowedSharedProfileTabs, this.language, {
              candidateId,
              organizationId,
            }),
            effectiveSharing: this.getEffectiveSharing(candidateId, organizationId),
          }).pipe(
            map((data) => {
              this.isReadOnlyCollaboration =
                data.effectiveSharing.sharingType === SharingTypeEnum.ReadOnlyCollaboration;

              return {
                specifics: data.specifics,
                candidate: data.forEdit.candidate,
                favorite: data.forEdit.favorite,
                messagesReception: data.forEdit.messagesReception,
                deletionRequest: data.forEdit.deletionRequest,
                customTags: data.forEdit.tags,
                enabledTabs: data.enabledTabs,
                effectiveSharing: data.effectiveSharing,
                selectedSharing: this.sharings?.find((x) => x.id === this.currentCollectionService.sharingId),
              } as CandidateData;
            })
          );
        } else {
          if (access.redirectToOrganization) {
            this.settings.switchOrganizationKeepingCurrentUrl(access.redirectToOrganization);
          } else {
            this.settings.navigateToAccessDenied();
          }
          return of();
        }
      })
    );
  }

  private showPreviews(previews: CandidateMultiEditPreviewFragment[], totalCandidates: number): Observable<boolean> {
    return this.dialogService.open(CandidateMultiEditPreviewDialogComponent, {
      closeOnEscape: false,
      dismissableMask: false,
      focusOnShow: false,
      styleClass: "p-dialog-compact",
      modal: true,
      header: this.transloco.translate("candidateMultiEdit.preview"),
      data: { previews: previews, totalCandidates: totalCandidates },
    }).onClose;
  }

  private loadNewCandidate(organizationId: string): Observable<CandidateData> {
    const candidateOrganizationId = this.currentCollectionService.organizationId ?? organizationId;
    return forkJoin({
      specifics: this.specificsFactory.getOrganizationSpecifics(candidateOrganizationId),
      professionDefaults: this.professionConfigService.getDefaults(candidateOrganizationId),
      enabledTabs: this.staticDataService.getStaticData(StaticDataType.AllowedSharedProfileTabs, this.language, {
        organizationId: candidateOrganizationId,
      }),
      effectiveSharing: this.getEffectiveSharing(null, candidateOrganizationId),
    }).pipe(
      map((data) => ({
        specifics: data.specifics,
        candidate: Object.assign(<Candidate>data.specifics.newCandidate(), {
          profession: data.professionDefaults.defaultProfession,
          function: data.professionDefaults.defaultFunction,
          organizationId: candidateOrganizationId,
          immigrationCountry: this.standardImmigrationCountry,
        }),
        enabledTabs: data.enabledTabs,
        effectiveSharing: data.effectiveSharing,
        selectedSharing: this.sharings?.find((x) => x.id === this.currentCollectionService.sharingId),
      }))
    );
  }

  private loadMultiEditCandidate(organizationId: string): Observable<CandidateData> {
    const candidateOrganizationId = this.currentCollectionService.organizationId ?? organizationId;
    const selectedCandidates = <CandidatePageEntry[]>this.selectedCandidates;
    return forkJoin({
      specifics: this.specificsFactory.getOrganizationSpecifics(candidateOrganizationId),
      enabledTabs: this.staticDataService.getStaticData(StaticDataType.AllowedSharedProfileTabs, this.language, {
        organizationId: candidateOrganizationId,
      }),
      effectiveSharing: this.getEffectiveSharing(selectedCandidates[0].id, candidateOrganizationId),
      multiEdit: this.multiEditService.getConfiguration(candidateOrganizationId),
    }).pipe(
      map((data) => ({
        specifics: data.specifics,
        candidate: <Candidate>{
          os: { profile: { addresses: [{ addressType: [AddressType.IdAddress] }] } },
          immigrationCountry: selectedCandidates[0].immigrationCountry,
          organizationId: candidateOrganizationId,
        },
        enabledTabs: data.enabledTabs.filter((x) => data.multiEdit.tabs.includes(x.value)),
        enabledFields: data.multiEdit.fields,
        effectiveSharing: data.effectiveSharing,
        selectedSharing: this.sharings?.find((x) => x.id === this.currentCollectionService.sharingId),
      }))
    );
  }

  private getEffectiveSharing(
    candidateId: string,
    candidateOrganizationId: string,
    options?: EffectiveSharingOptionsInput
  ): Observable<EffectiveSharingOutput> {
    if (candidateOrganizationId === this.settings.organizationId) {
      return this.staticDataService.getStaticData(StaticDataType.AllowedSharedProfileTabs).pipe(
        map((xs) => ({
          sharedTabs: xs.map((x) => ({ key: x.value })),
          canSeeInternalDocuments: true,
          showCompletionStateAtDocuments: true,
          tabHash: null,
        }))
      );
    }

    if (candidateId) {
      return this.collectionService.getEffectiveSharing(candidateId, candidateOrganizationId, options);
    }

    if (this.currentCollectionService.hasCollection) {
      const sharing = this.sharings?.find(({ sharedCollectionId }) => {
        return sharedCollectionId === this.currentCollectionService.collectionId;
      });

      if (sharing.profileAccessId) {
        return this.specificsFactory.getOrganizationSpecifics(candidateOrganizationId).pipe(
          map((specifics) => ({
            sharedTabs: sharing?.sharedTabs
              .concat(specifics.getCandidateProfileTabs().map((x) => x.id))
              .map((x) => ({ key: x })),
            canSeeInternalDocuments: true,
            showCompletionStateAtDocuments: true,
            tabHash: null,
          }))
        );
      }

      return of({
        sharedTabs: sharing?.sharedTabs.map((x) => ({ key: x })),
        canSeeInternalDocuments: sharing?.shareInternalDocuments ?? false,
        showCompletionStateAtDocuments: sharing?.showCompletionStateAtDocuments ?? false,
        tabHash: null,
      });
    }

    return of(null);
  }
}

export interface SelectedCandidateForEdit {
  id?: string;
  organizationId: string;
  immigrationCountry?: SupportedImmigrationCountry;
}
