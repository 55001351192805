import { Component, Input } from "@angular/core";

@Component({
  selector: "app-email-messenger-message",
  templateUrl: "./email-messenger-message.component.html",
  styleUrl: "./email-messenger-message.component.scss",
  standalone: false,
})
export class EmailMessengerMessageComponent {
  @Input()
  messengerMessage: string;

  @Input()
  lastState: string;

  @Input()
  lastSentDate: Date;

  @Input()
  errorMessage: string;

  @Input()
  processLanguage?: string;
}
