import { Pipe, PipeTransform } from "@angular/core";
import { AbstractControl, FormArray, FormGroup } from "@angular/forms";
import { KeysOf } from "@ankaadia/ankaadia-shared";
import { omit } from "lodash";

// eslint-disable-next-line @angular-eslint/no-pipe-impure
@Pipe({ name: "formErrors", pure: false, standalone: false })
export class FormErrorsPipe implements PipeTransform {
  transform(form: FormGroup | FormArray): object {
    return getAllFormErrors(form);
  }
}

// eslint-disable-next-line @angular-eslint/no-pipe-impure
@Pipe({ name: "formErrorsExceptFor", pure: false, standalone: false })
export class FormErrorsExceptForPipe implements PipeTransform {
  transform<T extends FormGroup | FormArray>(form: T, excludeKeys?: KeysOf<T["controls"]>): object {
    const formErrors = getAllFormErrors(form);
    return omit(formErrors, excludeKeys ?? []);
  }
}

function getAllFormErrors(form: FormGroup | FormArray | AbstractControl): object {
  const topLevelErrors = form?.errors;
  const errors = topLevelErrors != null ? topLevelErrors : {};

  if (form instanceof FormGroup || form instanceof FormArray) {
    Object.keys(form.controls).forEach((key: string) => {
      const nestedErrors = getAllFormErrors(form.get(key));
      if (Object.keys(nestedErrors).length > 0) {
        errors[key] = nestedErrors;
      }
    });
  }

  return errors;
}
