import { Pipe, PipeTransform } from "@angular/core";
import { filterDocumentFormats } from "@ankaadia/ankaadia-shared";
import { StaticDataModel } from "@ankaadia/graphql";

@Pipe({ name: "documentSelectorFilter", pure: true, standalone: false })
export class DocumentSelectorFilterPipe implements PipeTransform {
  transform(options: StaticDataModel[], availableTags: string[]): StaticDataModel[] {
    return filterDocumentFormats(options, availableTags);
  }
}
