import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpResponse, HttpSentEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FileUpload } from "primeng/fileupload";
import { Observable, Subject } from "rxjs";
import { mapToVoid } from "../functions/rxjs-extra";

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class FileUploadService {
  onProgress: Subject<number> = new Subject<number>();
  onSent: Subject<HttpSentEvent> = new Subject<HttpSentEvent>();
  onUpload: Subject<HttpResponse<any>> = new Subject<HttpResponse<any>>();
  onError: Subject<HttpResponse<any>> = new Subject<HttpResponse<any>>();
  uploading: boolean;
  progress = 0;

  constructor(private readonly http: HttpClient) {}

  ensureValidFiles(fileUpload: FileUpload, selectedFiles: File[]): File[] {
    const partToSearchIn = fileUpload.files.splice(0, fileUpload.files.length - selectedFiles.length);
    const files = [];
    for (const file of Array.from(selectedFiles)) {
      if (
        partToSearchIn.findIndex((y) => y.name === file.name) === -1 &&
        this.checkAccept(fileUpload.accept, file) &&
        this.checkFileSize(fileUpload.maxFileSize, file)
      ) {
        files.push(file);
      }
    }
    fileUpload.files.length = 0; // https://stackoverflow.com/questions/1232040/how-do-i-empty-an-array-in-javascript
    fileUpload.files.push(...partToSearchIn, ...files);
    return files;
  }

  validate(file: File): Observable<void> {
    return this.read(file).pipe(mapToVoid());
  }

  upload(file: File | Blob, url: string, customFileName?: string, acceptedType?: "image"): void {
    if (!file.type || (acceptedType && !file.type.startsWith(acceptedType))) {
      this.uploading = false;
      this.onError.next(<any>new Error("Unsupported file type"));
      return;
    }

    if (!file.size) {
      this.uploading = false;
      this.onError.next(<any>new Error("Empty file"));
      return;
    }

    let headers = new HttpHeaders()
      .set("x-ms-blob-type", "Blockblob")
      .set("Content-Type", file.type)
      .set("Cache-Control", "no-store");
    const fileName = customFileName ?? (<File>file).name;
    if (fileName) {
      headers = headers.set("x-ms-blob-content-disposition", `attachment; filename="${encodeURIComponent(fileName)}"`);
    }

    this.read(file).subscribe((buffer) => {
      this.progress = 0;
      this.http
        .put(url, buffer, {
          headers: headers,
          reportProgress: true,
          observe: "events",
        })
        .subscribe({
          next: (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                this.onSent.next(event);
                break;
              case HttpEventType.Response:
                this.uploading = false;
                this.progress = 0;
                if (event.status >= 200 && event.status < 300) {
                  this.onUpload.next(event);
                } else {
                  this.onError.next(event);
                }
                break;
              case HttpEventType.UploadProgress: {
                if (event.loaded) {
                  this.progress = Math.round((event.loaded * 100) / event.total);
                }
                this.onProgress.next(this.progress);
                break;
              }
              default:
                break;
            }
          },
          error: (error) => {
            this.uploading = false;
            this.onError.next(error);
          },
        });
    });
  }

  uploadAsObservable(url: string, file: File, customFileName?: string): Observable<void> {
    return new Observable((subscriber) => {
      const uploadSub = this.onUpload.subscribe(() => {
        uploadSub.unsubscribe();
        errorSub.unsubscribe();
        subscriber.next();
        subscriber.complete();
      });
      const errorSub = this.onError.subscribe((x) => {
        uploadSub.unsubscribe();
        errorSub.unsubscribe();
        subscriber.error(x);
        subscriber.complete();
      });
      this.upload(file, url, customFileName);
    });
  }

  private checkFileSize(size: number, file: File): boolean {
    return file.size <= size;
  }

  private checkAccept(accept: string, file: File): boolean {
    return (
      accept.includes("." + file.name.split(".").pop()?.toLowerCase()) ||
      (file.type != "" && accept.includes(file.type)) ||
      (file.type != "" && accept.includes(file.type.split("/")[0] + "/*"))
    );
  }

  private read(file: File | Blob): Observable<ArrayBuffer> {
    return new Observable((obs) => {
      const reader = new FileReader();
      if (!(file instanceof File) && !(file instanceof Blob)) {
        obs.error(new Error(`${(<any>file).name} Not a file or blob`));
        return;
      }
      reader.onerror = reader.onabort = (e): void => obs.error((<File>file).name ? new Error((<File>file).name) : e);
      reader.onload = (): void => obs.next(<ArrayBuffer>reader.result);
      reader.onloadend = (): void => obs.complete();
      return reader.readAsArrayBuffer(file);
    });
  }
}
