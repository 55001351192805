import { NgModule } from "@angular/core";
import { FormlyModule } from "../../formly/formly.module";
import { SharedModule } from "../../shared/shared.module";
import { CandidateDocumentMetadataModule } from "../candidate-document-metadata/candidate-document-metadata.module";
import { CandidateReusablesModule } from "../candidate-reusables/candidate-reusables.module";
import { ContractTemplateModule } from "../contract-template/contract-template.module";
import { DocumentsModule } from "../documents/documents.module";
import { ProfessionModule } from "../profession/profession.module";
import { UsersModule } from "../users/users.module";
import { CandidateDocumentUploadComponent } from "./candidate-document-upload/candidate-document-upload.component";
import { CandidateEmployerHistoryDialogComponent } from "./candidate-employer-history-dialog/candidate-employer-history-dialog.component";
import { CandidateFamilyMemberEditDialogComponent } from "./candidate-family/candidate-family-member/candidate-family-member-edit-dialog.component";
import { CandidateFamilyComponent } from "./candidate-family/candidate-family.component";
import { CandidateFunctionComponent } from "./candidate-function/candidate-function.component";
import { CandidateGovernanceComponent } from "./candidate-governance/candidate-governance.component";
import { CandidateImmigrationCountryComponent } from "./candidate-immigration-country/candidate-immigration-country.component";
import { CandidateDroppedOutReplacementComponent } from "./candidate-internal/candidate-dropped-out-replacement/candidate-dropped-out-replacement.component";
import { CandidateInternalHrComponent } from "./candidate-internal/candidate-internal-hr/candidate-internal-hr.component";
import { CandidateInternalComponent } from "./candidate-internal/candidate-internal.component";
import { CandidateHealthProfessionsRegisterAtComponent } from "./candidate-migration/at/candidate-health-professions-register/candidate-health-professions-register-at.component";
import { CandidateMigrationATComponent } from "./candidate-migration/at/candidate-migration-at.component";
import { CandidateQualificationMeasureATComponent } from "./candidate-migration/at/candidate-qualification-measure/candidate-qualification-measure-at.component";
import { CandidateRecognitionPathEntryATComponent } from "./candidate-migration/at/candidate-recognition-path/candidate-recognition-path-entry-at.component";
import { CandidateResidenceATComponent } from "./candidate-migration/at/candidate-residence/candidate-residence-at.component";
import { CandidateVisaATComponent } from "./candidate-migration/at/candidate-visa/candidate-visa-at.component";
import { CandidateVocationalSchoolAtComponent } from "./candidate-migration/at/candidate-vocational-school/candidate-vocational-school-at.component";
import { CandidateDetailedEquivalenceComponent } from "./candidate-migration/candidate-detailed-equivalence/candidate-detailed-equivalence.component";
import { CandidateEmployerEntryComponent } from "./candidate-migration/candidate-employment-relationship/candidate-employment-relationship-entry/candidate-employment-relationship-entry.component";
import { CandidateEmployerComponent } from "./candidate-migration/candidate-employment-relationship/candidate-employment-relationship.component";
import { CandidateHousingAcquisitionEntryComponent } from "./candidate-migration/candidate-housing-acquisition/candidate-housing-acquisition-entry/candidate-housing-acquisition-entry.component";
import { CandidateHousingAcquisitionComponent } from "./candidate-migration/candidate-housing-acquisition/candidate-housing-acquisition.component";
import { CandidateQualificationModulesComponent } from "./candidate-migration/candidate-qualification-modules/candidate-qualification-modules.component";
import { CandidateRelocationRequirementsComponent } from "./candidate-migration/candidate-visa/candidate-relocation-requirements/candidate-relocation-requirements.component";
import { CandidateLaborMarketAdmissionDEComponent } from "./candidate-migration/de/candidate-labor-market-admission/candidate-labor-market-admission-de.component";
import { CandidateMigrationDEComponent } from "./candidate-migration/de/candidate-migration-de.component";
import { CandidateQualificationEvaluationComponent } from "./candidate-migration/de/candidate-qualification-evaluation/candidate-qualification-evaluation.component";
import { CandidateQualificationEvaluationsComponent } from "./candidate-migration/de/candidate-qualification-evaluation/qualification-evaluations/candidate-qualification-evaluations.component";
import { CandidateQualificationMeasureDEComponent } from "./candidate-migration/de/candidate-qualification-measure/candidate-qualification-measure-de.component";
import { CandidateRecognitionPathDEComponent } from "./candidate-migration/de/candidate-recognition-path/candidate-recognition-path-de.component";
import { CandidateRecognitionPathEntryDEComponent } from "./candidate-migration/de/candidate-recognition-path/candidate-recognition-path-entry/candidate-recognition-path-entry-de.component";
import { CandidateResidenceDEComponent } from "./candidate-migration/de/candidate-residence/candidate-residence-de.component";
import { CandidateResidencePermitDetailsComponent } from "./candidate-migration/de/candidate-residence/residence-permit/candidate-residence-permit-details.component";
import { CandidateVisaDEComponent } from "./candidate-migration/de/candidate-visa/candidate-visa-de.component";
import { CandidateVocationalSchoolDeComponent } from "./candidate-migration/de/candidate-vocational-school/candidate-vocational-school-de.component";
import { CandidatePresentationComponent } from "./candidate-presentation/candidate-presentation.component";
import { CandidateProcessStatusComponent } from "./candidate-process-status/candidate-process-status.component";
import { CandidateProfessionComponent } from "./candidate-profession/candidate-profession.component";
import { CandidateProfileComponent } from "./candidate-profile/candidate-profile.component";
import { CandidateScholarshipComponent } from "./candidate-scholarship/candidate-scholarship.component";
import { CandidateSpecialTagComponent } from "./candidate-special-tag/candidate-special-tag.component";
import { CandidateStatusComponent } from "./candidate-status/candidate-status.component";
import { CandidateTagsComponent } from "./candidate-tags/candidate-tags.component";
import { CandidateFlexibleEditButtonComponent } from "../candidates/candidate-flexible-edit/candidate-flexible-edit-button/candidate-flexible-edit-button.component";

@NgModule({
  imports: [
    SharedModule,
    FormlyModule,
    CandidateReusablesModule,
    CandidateDocumentMetadataModule,
    ContractTemplateModule,
    ProfessionModule,
    UsersModule,
    DocumentsModule,
    CandidateFlexibleEditButtonComponent,
  ],
  declarations: [
    CandidateFamilyMemberEditDialogComponent,
    CandidateFamilyComponent,
    CandidateFunctionComponent,
    CandidateGovernanceComponent,
    CandidateImmigrationCountryComponent,
    CandidateDroppedOutReplacementComponent,
    CandidateInternalHrComponent,
    CandidateInternalComponent,
    CandidateHealthProfessionsRegisterAtComponent,
    CandidateMigrationATComponent,
    CandidateQualificationMeasureATComponent,
    CandidateRecognitionPathEntryATComponent,
    CandidateResidenceATComponent,
    CandidateVisaATComponent,
    CandidateVocationalSchoolAtComponent,
    CandidateDetailedEquivalenceComponent,
    CandidateEmployerEntryComponent,
    CandidateEmployerComponent,
    CandidateHousingAcquisitionEntryComponent,
    CandidateHousingAcquisitionComponent,
    CandidateQualificationModulesComponent,
    CandidateRelocationRequirementsComponent,
    CandidateLaborMarketAdmissionDEComponent,
    CandidateMigrationDEComponent,
    CandidateQualificationEvaluationComponent,
    CandidateQualificationEvaluationsComponent,
    CandidateQualificationMeasureDEComponent,
    CandidateRecognitionPathDEComponent,
    CandidateRecognitionPathEntryDEComponent,
    CandidateResidenceDEComponent,
    CandidateResidencePermitDetailsComponent,
    CandidateVisaDEComponent,
    CandidateVocationalSchoolDeComponent,
    CandidatePresentationComponent,
    CandidateProcessStatusComponent,
    CandidateProfessionComponent,
    CandidateScholarshipComponent,
    CandidateStatusComponent,
    CandidateTagsComponent,
    CandidateDocumentUploadComponent,
    CandidateProfileComponent,
    CandidateSpecialTagComponent,
    CandidateEmployerHistoryDialogComponent,
  ],
  exports: [
    CandidateProfileComponent,
    CandidateFamilyComponent,
    CandidateGovernanceComponent,
    CandidateImmigrationCountryComponent,
    CandidateInternalComponent,
    CandidateMigrationATComponent,
    CandidateMigrationDEComponent,
    CandidatePresentationComponent,
    CandidateProcessStatusComponent,
    CandidateTagsComponent,
    CandidateDocumentUploadComponent,
    CandidateSpecialTagComponent,
  ],
})
export class CandidateFormModule {}
