import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  CollectionAutoTemplateVariableEnum,
  getCollectionAutoTemplateVariableTemplatePlaceholder,
} from "@ankaadia/ankaadia-shared";
import { CollectionAutoFilterTemplateSpecialPurpose, StaticDataModel } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { StaticDataService } from "apps/frontend/src/app/shared/static-data/static-data.service";
import { TreeNode } from "primeng/api";
import { Subscription, forkJoin, map, of } from "rxjs";
import { Dictionary } from "ts-essentials";
import {
  CandidateFilterCondition,
  CandidateFilterField,
  StaticDataCountryGroup,
  StaticDataModelGroup,
  isCandidateFilterCondition,
} from "../../../candidate-filter/candidate-filter.model";
import { CollectionAutoFilterFieldsService } from "../../collection-auto/collection-auto-filter/collection-auto-filter-fields.service";
import { FilterMetadataMap } from "../../collection-edit-assigned-candidates/custom-filter.model";
import { getCollectionAutoTemplateFieldVariables } from "../collection-auto-filter-template.variables";

@Component({
  selector: "app-collection-auto-template-edit",
  templateUrl: "./collection-auto-template-edit.component.html",
  standalone: false,
})
export class CollectionAutoTemplateEditComponent implements OnInit, OnDestroy {
  private subscription?: Subscription;
  protected fields: TreeNode<CandidateFilterField>[] = [];
  protected displayHint = false;
  protected specialPurposes: StaticDataModel[] = [];
  constructor(
    private readonly collectionAutoFilterFieldsService: CollectionAutoFilterFieldsService,
    private readonly staticDataService: StaticDataService
  ) {
    this.specialPurposes = [
      { label: translate("enum.CollectionAutoFilterTemplateSpecialPurpose.Default"), value: null },
      ...this.staticDataService.transformEnumToStaticDataModel(
        "CollectionAutoFilterTemplateSpecialPurpose",
        CollectionAutoFilterTemplateSpecialPurpose
      ),
    ];
  }

  @Input({ required: true })
  form: FormGroup;

  @Input({ required: true })
  filter: FilterMetadataMap;

  variables: Dictionary<StaticDataModelGroup> = null;

  ngOnInit(): void {
    this.subscription = forkJoin([
      this.collectionAutoFilterFieldsService.getFields(),
      of(getCollectionAutoTemplateFieldVariables()),
    ]).subscribe(([fields, variables]) => {
      this.variables = variables.reduce<Dictionary<StaticDataModelGroup>>((acc, variable) => {
        acc[variable.field] = variable.options;
        return acc;
      }, {});

      const flatChildren = fields.flatMap((field) => field.children);
      variables.forEach((variable) => {
        const field = flatChildren.find((child) => child.data?.value === variable.field);
        if (field) {
          field.data.conditions
            .filter((x) => x.parameter === "options" && isCandidateFilterCondition(x))
            .forEach(
              (x: CandidateFilterCondition) =>
                (x.options = forkJoin([of([variable.options]), x.options]).pipe(
                  map((x) =>
                    x.flatMap(
                      (x) =>
                        <(StaticDataModelGroup | StaticDataCountryGroup)[]>(
                          (x.length > 0 && isStaticDataModel(x[0])
                            ? [{ label: translate("values.title"), items: x }]
                            : x)
                        )
                    )
                  )
                ))
            );
        }
      });

      this.fields = fields;
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  getVariableDescription(): string {
    const variableDescriptions = Object.keys(CollectionAutoTemplateVariableEnum).map((x) => {
      const enumValue = CollectionAutoTemplateVariableEnum[x];
      return {
        key: getCollectionAutoTemplateVariableTemplatePlaceholder(enumValue),
        description: translate(`autoCollectionTemplate.variableDescriptions.${x}`),
      };
    });
    const variableItems = `<ul>${variableDescriptions.map((x) => `<li><strong>${x.key}</strong>: ${x.description}</li>`).join("")}</ul>`;
    const header = `<h4>${translate("autoCollectionTemplate.variableDescriptions.title")}</h4>`;
    return `<div>${header}${variableItems}</div>`;
  }
}

function isStaticDataModel(x: any): x is StaticDataModel {
  return (
    Object.prototype.hasOwnProperty.call(x, "label") &&
    Object.prototype.hasOwnProperty.call(x, "value") &&
    !Object.prototype.hasOwnProperty.call(x, "items")
  );
}
