<ng-container *appTranslate="let t">
  <form *ngIf="show && form" [formGroup]="form">
    <div class="grid">
      <div class="field grid align-items-baseline">
        <label for="{{ formId }}-employerId" class="ml-3 col-fixed" style="width: 100px">
          {{ t("employer.title") }}
        </label>
        <div class="col">
          <p-dropdown
            inputId="{{ formId }}-employerId"
            [options]="employers"
            optionLabel="name"
            optionValue="id"
            formControlName="employerId"
            [placeholder]="t('employer.placeholder')"
            [readonly]="readonly"
            (onChange)="saveItem($event, ItemTypeEnum.Employer)"
          ></p-dropdown>
          <small class="p-error" *ngIf="form.controls.employerId.errors?.required">
            {{ t("candidate.interviewsFeedback.required") }}
          </small>
        </div>
        <app-candidate-document-upload
          [fileTypes]="['OVERVIEWINTERVIEW']"
          [candidateId]="candidateId"
          [candidateOrganizationId]="candidateOrganizationId"
          [readonly]="readonly"
        ></app-candidate-document-upload>
      </div>

      <div class="field col-12" formArrayName="feedbacks" style="min-height: 180px">
        <p-table
          #feedbackstable
          [value]="feedbacks?.controls"
          editMode="row"
          styleClass="p-datatable p-datatable-sm app-form"
          dataKey="value.id"
          (sortFunction)="customSort($event)"
          [customSort]="true"
          [sortField]="'value.interviewDate'"
          [sortOrder]="1"
        >
          <ng-template pTemplate="header">
            <tr>
              <th width="20%" pSortableColumn="interviewDate">
                {{ t("candidate.interviewsFeedback.interviewDate") }}
                <p-sortIcon field="interviewDate"></p-sortIcon>
              </th>
              <th width="25%">{{ t("candidate.interviewsFeedback.participants") }}</th>
              <th width="25%">{{ t("candidate.interviewsFeedback.interviewerFeedback") }}</th>
              <th width="25%">{{ t("candidate.interviewsFeedback.candidateFeedback") }}</th>
              <th class="justify-content-end text-right" style="width: 8rem">
                <button
                  [disabled]="isEditing || !hasEmployer || readonly"
                  pButton
                  type="button"
                  icon="pi pi-plus"
                  class="p-button-rounded p-button-text"
                  (click)="addItem(ItemTypeEnum.Feedback)"
                ></button>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-feedback let-editing="editing" let-i="rowIndex">
            <tr [pEditableRow]="feedback" [formGroupName]="i">
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-calendar
                      inputId="interviewDate"
                      formControlName="interviewDate"
                      dataType="date"
                      [showTime]="true"
                      [readonly]="readonly"
                    ></p-calendar>
                  </ng-template>

                  <ng-template pTemplate="output">
                    {{
                      feedback.value.interviewDate | appDateTime: { dateStyle: "short", timeStyle: "short" } : language
                    }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-chips formControlName="participants" [readonly]="readonly" [addOnBlur]="true"></p-chips>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ feedback.value.participants }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td formGroupName="interviewerFeedback">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <textarea
                      pInputTextarea
                      formControlName="comment"
                      id="comment"
                      [autoResize]="true"
                      autoResizeFix
                      [rows]="3"
                      [cols]="30"
                      [readonly]="readonly"
                    ></textarea>
                    <app-feedback-voting
                      [control]="feedback.controls.interviewerFeedback?.controls.vote"
                      [readonly]="readonly"
                    ></app-feedback-voting>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ feedback.value.interviewerFeedback?.comment }}
                    <app-feedback-voting [type]="feedback.value.interviewerFeedback?.vote"></app-feedback-voting>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td formGroupName="candidateFeedback">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <textarea
                      pInputTextarea
                      formControlName="comment"
                      id="comment"
                      [autoResize]="true"
                      autoResizeFix
                      [rows]="3"
                      [cols]="30"
                      [readonly]="readonly"
                    ></textarea>
                    <app-feedback-voting
                      [control]="feedback.controls.candidateFeedback?.controls.vote"
                      [readonly]="readonly"
                    ></app-feedback-voting>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ feedback.value.candidateFeedback?.comment }}
                    <app-feedback-voting [type]="feedback.value.candidateFeedback?.vote"></app-feedback-voting>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td style="text-align: center">
                <button
                  *ngIf="!editing"
                  pButton
                  pInitEditableRow
                  [disabled]="readonly"
                  type="button"
                  icon="pi pi-pencil"
                  (click)="onRowEditInit(feedback)"
                  class="p-button-rounded p-button-text"
                ></button>
                <button
                  *ngIf="!editing"
                  [disabled]="isEditing || readonly"
                  pButton
                  type="button"
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-text p-button-danger ml-2"
                  (click)="removeRow(feedback, ItemTypeEnum.Feedback)"
                ></button>
                <button
                  *ngIf="editing"
                  pButton
                  pRipple
                  [disabled]="readonly"
                  type="button"
                  pSaveEditableRow
                  icon="pi pi-check"
                  (click)="onRowEditSave(feedback, i, ItemTypeEnum.Feedback)"
                  class="p-button-rounded p-button-text p-button-success p-mr-2"
                ></button>
                <button
                  *ngIf="editing || !hasEmployer"
                  pButton
                  pRipple
                  [disabled]="readonly"
                  type="button"
                  pCancelEditableRow
                  icon="pi pi-times"
                  (click)="onRowEditCancel(i, ItemTypeEnum.Feedback)"
                  class="p-button-rounded p-button-text p-button-danger"
                ></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="field mt-5 col-12" formArrayName="comments" style="min-height: 180px">
        <p-table
          #commentstable
          [value]="comments?.controls"
          dataKey="value.id"
          editMode="row"
          (sortFunction)="customSort($event)"
          [customSort]="true"
          [sortField]="'value.changedAt'"
          [sortOrder]="1"
        >
          <ng-template pTemplate="caption"> {{ t("candidate.interviewsFeedback.comments") }} </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="comment">
                {{ t("comment.title") }}
                <p-sortIcon field="comment"></p-sortIcon>
              </th>
              <th width="20%" pSortableColumn="changedBy">
                {{ t("changedBy.title") }}
                <p-sortIcon field="changedBy"></p-sortIcon>
              </th>
              <th width="20%" pSortableColumn="changedAt">
                {{ t("changedAt.title") }}
                <p-sortIcon field="changedAt"></p-sortIcon>
              </th>

              <th class="justify-content-end text-right" style="width: 8rem">
                <button
                  [disabled]="isEditing || readonly"
                  pButton
                  type="button"
                  icon="pi pi-plus"
                  class="p-button-rounded p-button-text"
                  (click)="addItem(ItemTypeEnum.Comment)"
                ></button>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item let-editing="editing" let-i="rowIndex">
            <tr [pEditableRow]="item" [formGroupName]="i">
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <textarea
                      pInputTextarea
                      formControlName="comment"
                      id="comment"
                      [autoResize]="true"
                      autoResizeFix
                      [rows]="3"
                      [cols]="30"
                      [readonly]="readonly"
                    ></textarea>
                  </ng-template>
                  <ng-template pTemplate="output"> {{ item.value.comment }}</ng-template>
                </p-cellEditor>
              </td>
              <td>{{ item.value.changedBy }}</td>
              <td>
                {{ item.value.changedAt | appDateTime: { dateStyle: "short", timeStyle: "short" } : language }}
              </td>
              <td style="text-align: center">
                <button
                  *ngIf="!editing"
                  pButton
                  pRipple
                  type="button"
                  [disabled]="readonly"
                  pInitEditableRow
                  icon="pi pi-pencil"
                  (click)="onRowEditInit(item)"
                  class="p-button-rounded p-button-text"
                ></button>
                <button
                  *ngIf="!editing"
                  [disabled]="isEditing || readonly"
                  pButton
                  type="button"
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-text p-button-danger ml-2"
                  (click)="removeRow(item, ItemTypeEnum.Comment)"
                ></button>
                <button
                  *ngIf="editing"
                  pButton
                  pRipple
                  type="button"
                  pSaveEditableRow
                  [disabled]="readonly"
                  icon="pi pi-check"
                  (click)="onRowEditSave(item, i, ItemTypeEnum.Comment)"
                  class="p-button-rounded p-button-text p-button-success p-mr-2"
                ></button>
                <button
                  *ngIf="editing"
                  pButton
                  pRipple
                  type="button"
                  pCancelEditableRow
                  [disabled]="readonly"
                  icon="pi pi-times"
                  (click)="onRowEditCancel(i, ItemTypeEnum.Comment)"
                  class="p-button-rounded p-button-text p-button-danger"
                ></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </form>
</ng-container>
