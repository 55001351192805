import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { LocalStorageService, StorageKey, StorageKeyValueType } from "../services/local-storage.service";

@Pipe({
  name: "observe",
  standalone: false,
})
export class ObserveLocalStoragePipe implements PipeTransform {
  constructor(private readonly storage: LocalStorageService) {}

  transform<TKey extends StorageKey>(key: TKey): Observable<StorageKeyValueType<TKey>> {
    return this.storage.observe(key);
  }
}
