<ng-container *appTranslate="let t">
  <div class="inline-block">
    <ng-container *ngIf="readonly">
      <div class="icons" [pTooltip]="readonly ? t('immigrationCountry.title') : null">
        <i class="pi pi-arrow-right mr-2"></i>
        <app-flag [country]="immigrationCountry"></app-flag>
      </div>
    </ng-container>
    <div class="field" *ngIf="!readonly && isNewCandidate">
      <label for="immigrationCountry" class="label-select">{{ t("immigrationCountry.title") }}</label>
      <p-dropdown
        inputId="immigrationCountry"
        styleClass="w-full"
        [formControl]="control"
        [options]="options"
        [placeholder]="t('immigrationCountry.placeholder')"
        [class.p-disabled]="appearDisabled"
        [readonly]="appearDisabled"
      ></p-dropdown>
    </div>
  </div>
</ng-container>
