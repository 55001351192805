import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IsEqualEntityCustomizer, objectDifference } from "@ankaadia/ankaadia-shared";
import { ApplicationInsightsService } from "@ankaadia/ankaadia-shared-frontend";
import { cloneDeep, get, isEmpty, isObject, noop, unset } from "lodash";
import { MessageService } from "../../features/message/message.service";
import { getRawValue } from "./form.helper";
import { SettingsService } from "./settings.service";

export interface ArrayPropertyPath {
  pathToArray: string;
  propertyNames: string[];
}

export interface IgnoredPropertyValues {
  pathToProperty: string;
  values: string[];
  type: "ignoreValues" | "ignoreNotInValues";
}

export class FormDataGuardConfiguration {
  ignoredPaths?: string[];
  ignoredArrayPaths?: ArrayPropertyPath[];
  ignoredProperties?: string[];
  ignoredPropertyValues?: IgnoredPropertyValues[];
  additionalInformation?: any;
}

@Injectable({ providedIn: "root" })
export class MissingFormDataGuardService {
  constructor(
    private readonly messageService: MessageService,
    private readonly settings: SettingsService
  ) {}

  guardAgainstMissingFormData<T>(original: T, patched: FormGroup, config: FormDataGuardConfiguration): void {
    const originalCopy = cloneDeep(original);
    const patchedCopy = cloneDeep(getRawValue(patched)) as T;

    this.calculateDifference(originalCopy, patchedCopy, config);

    // when having a slow client machine, formly may still be creating the form, but the user/candidate already tries to edit it
    // to work around this, we check the difference right after clicking edit, and additionally with a small delay
    setTimeout(() => {
      const diff1 = this.calculateDifference(originalCopy, patchedCopy, config);
      const diff2 = this.calculateDifference(originalCopy, cloneDeep(getRawValue(patched)), config);

      if (isEmpty(diff1) || isEmpty(diff2)) return;

      this.handleFault(diff1, diff2, config.additionalInformation);
    }, 1000);
  }

  private calculateDifference<T>(originalCopy: T, patchedCopy: T, config: FormDataGuardConfiguration): any {
    this.removeIgnoredData(
      originalCopy,
      config.ignoredPaths ?? [],
      config.ignoredArrayPaths ?? [],
      config.ignoredPropertyValues ?? [],
      config.ignoredProperties ?? []
    );

    return objectDifference(originalCopy, patchedCopy, IsEqualEntityCustomizer, true);
  }

  private removeIgnoredData<T>(
    originalCopy: T,
    ignoredPaths: string[],
    ignoredArrayPaths: ArrayPropertyPath[],
    ignoredPropertyValues: IgnoredPropertyValues[],
    ignoredProperties: string[]
  ): void {
    ignoredPaths.forEach((key) => unset(originalCopy, key));
    ignoredArrayPaths.forEach((x) =>
      x.propertyNames.forEach((prop) => this.removeIgnoredArrayProperties(originalCopy, x.pathToArray, prop))
    );
    ignoredPropertyValues.forEach((x) => this.removeIgnoredPropertiesWithValues(originalCopy, x));

    this.removeIgnoredProperties(originalCopy, ignoredProperties);
  }

  private removeIgnoredPropertiesWithValues(obj: any, ignored: IgnoredPropertyValues): void {
    const propertyValue = get(obj, ignored.pathToProperty);
    switch (ignored.type) {
      case "ignoreValues":
        if (ignored.values.includes(propertyValue)) {
          unset(obj, ignored.pathToProperty);
        }
        break;
      case "ignoreNotInValues":
        if (!ignored.values.includes(propertyValue)) {
          unset(obj, ignored.pathToProperty);
        }
        break;
    }
  }

  private removeIgnoredArrayProperties(obj: any, path: string, propertyName: string): void {
    get(obj, path)?.forEach((item: any) => unset(item, propertyName));
  }

  private removeIgnoredProperties(obj: any, properties: string[]): void {
    properties.forEach((key) => unset(obj, key));
    Object.keys(obj).forEach((key) =>
      isObject(obj[key]) ? this.removeIgnoredProperties(obj[key], properties) : noop()
    );
  }

  private handleFault(compared1: any, compared2: any, additionalInformation: any): void {
    const userData = {
      userOrCandidateId: this.settings.userOrCandidateId,
      organizationId: this.settings.organizationId,
      isCandidate: this.settings.isCandidate,
    };
    ApplicationInsightsService.bigTroubleNotifier("Form Data Initialization Guard", {
      additionalInformation: {
        currentUser: userData,
        ...additionalInformation,
      },
      compared1: compared1,
      compared2: compared2,
    });

    if (this.settings.isMasterUser) {
      this.messageService.add({
        severity: "error",
        summary: "Potential data loss detected!",
        detail: JSON.stringify({
          compared1,
          compared2,
        }),
      });
    }
  }
}
