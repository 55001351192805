import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "app-message-dialog",
  templateUrl: "./message-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MessageDialogComponent implements OnInit {
  messages: string;

  constructor(
    private readonly dialogRef: DynamicDialogRef,
    private readonly config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.messages = this.config.data.messages;
  }

  close(): void {
    this.dialogRef.close();
  }
}
