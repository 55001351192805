import { inject, Injectable } from "@angular/core";
import {
  Locale,
  LocaleConfig,
  LocaleFormatOptions,
  NumberFormatOptions,
  TRANSLOCO_LOCALE_CONFIG,
  TranslocoLocaleService,
} from "@jsverse/transloco-locale";
import { isNil } from "lodash";

@Injectable({ providedIn: "root" })
export class DecimalFormatterService {
  private readonly localeConfig: LocaleConfig = inject(TRANSLOCO_LOCALE_CONFIG);

  constructor(private readonly localeService: TranslocoLocaleService) {}

  transform(value: string | number, numberFormatOptions: NumberFormatOptions, locale?: Locale): string {
    if (isNil(value)) return "";
    locale = locale || this.localeService.getLocale();

    const options = {
      ...this.getDefaultOptions(locale, "decimal", this.localeConfig),
      ...numberFormatOptions,
    };

    // Now, this is actually wrong, but what are you gonna do?
    // Different languages default to different numbering systems. However, Transloco, PrimeNG and pretty much everything else thinks that numbers
    // are only represented using 1, 2, 3, etc. It seems way too difficult to teach e.g. PrimeNG's calendar to display Persian numbers,
    // so we'll just force our "latin" numbers everywhere.
    (<Intl.NumberFormatOptions>options).numberingSystem = "latn";

    return this.localeService.localizeNumber(value, "decimal", locale, options);
  }

  private getDefaultOptions<T extends keyof LocaleFormatOptions>(
    locale: Locale,
    style: T,
    localeConfig: LocaleConfig
  ): NonNullable<LocaleFormatOptions[T]> {
    const defaultConfig = (localeConfig.global?.[style] ?? {}) as NonNullable<LocaleFormatOptions[T]>;
    const settings = localeConfig.localeBased?.[locale] ?? {};

    return Reflect.has(settings, style) ? { ...defaultConfig, ...settings[style] } : defaultConfig;
  }
}
