<ng-container *appTranslate="let t" xmlns="http://www.w3.org/1999/html">
  <div class="grid">
    <div class="field col-12 lg:col-6" [formGroup]="form">
      <div class="field-checkbox">
        <p-checkbox
          id="isReplacementForCandidate"
          inputId="isReplacementForCandidate"
          [binary]="true"
          formControlName="isReplacementForCandidate"
          [readonly]="readonly"
          (onChange)="isReplacementForCandidateChange($event)"
        ></p-checkbox>
        <label for="isReplacementForCandidate">{{
          t("replacementForCandidate.title", { name: candidateDisplayIdAndName })
        }}</label>
      </div>

      <div class="field">
        <p-dropdown
          id="replacementForCandidateId"
          inputId="replacementForCandidateId"
          [options]="candidates$ | async"
          formControlName="replacementForCandidateId"
          optionValue="candidateId"
          filterBy="displayName,displayId"
          (onFilter)="searchCandidates($event.filter)"
          (onClick)="onCandidatesReplacementDropdownClick()"
          (onChange)="setFormData($event)"
          [showClear]="true"
          [placeholder]="t('replacementForCandidate.placeholder')"
          [readonly]="readonly"
        >
          <ng-template let-candidate pTemplate="item">
            <div class="flex align-items-center gap-2">
              <span class="white-space-nowrap text-overflow-ellipsis overflow-hidden">
                <app-flag
                  class="mr-2"
                  *ngIf="candidate.country"
                  [country]="candidate.country"
                  [small]="true"
                ></app-flag>
                {{ candidate.displayId }}-{{ candidate.displayName }}
              </span>
            </div>
          </ng-template>

          <ng-template let-candidate pTemplate="selectedItem">
            <div class="flex align-items-center gap-2">
              <span class="white-space-nowrap text-overflow-ellipsis overflow-hidden">
                <app-flag
                  class="mr-2"
                  *ngIf="candidate.country"
                  [country]="candidate.country"
                  [small]="true"
                ></app-flag>
                {{ candidate.displayId }}-{{ candidate.displayName }}
              </span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
    <div class="field col-12 lg:col-6">
      <app-candidate-aligned-label
        labelFor="replacedByCandidate"
        [labelText]="t('replacedByCandidate.title', { name: candidateDisplayIdAndName })"
        [useAlignmentCheckBox]="true"
      >
      </app-candidate-aligned-label>
      <div class="field">
        <p-chips
          class="readonly-chip"
          *ngIf="replacedByCandidates?.length > 0"
          [ngModel]="replacedByCandidates"
          [readonly]="true"
          [showClear]="false"
          [pTooltip]="replacedByCandidatesJoined"
          [disabled]="true"
        >
          <ng-template let-item pTemplate="item">
            <app-candidate-flexible-edit-button
              [label]="item.displayId + '-' + item.displayName"
              [candidateOrganizationId]="item.organizationId"
              [candidateId]="item.id"
              [showLink]="false"
            ></app-candidate-flexible-edit-button>
          </ng-template>
        </p-chips>
      </div>
    </div>
  </div>
</ng-container>
