import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { QualificationMeasureCandidateDataAt } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";

const nameOf = nameofFactory<QualificationMeasureCandidateDataAt>();

@Component({
  selector: "app-candidate-location-of-complementary-measure",
  templateUrl: "./candidate-location-of-complementary-measure.component.html",
  standalone: false,
})
export class CandidateLocationOfComplementaryMeasureComponent implements OnInit, OnDestroy {
  private readonly language = this.transloco.getActiveLang();

  @Input()
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  readonly: boolean;

  get control(): FormControl<string | null> {
    return this.form.get(nameOf("locationOfComplementaryMeasure")) as FormControl<string | null>;
  }

  set control(value: FormControl<string | null>) {
    this.form.setControl(nameOf("locationOfComplementaryMeasure"), value);
  }

  constructor(private readonly transloco: TranslocoService) {}

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<string | null>(null);
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("locationOfComplementaryMeasure"), { emitEvent: false });
  }
}
