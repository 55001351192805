import { Pipe, PipeTransform } from "@angular/core";
import { map, Observable } from "rxjs";
import { CandidateProfileFieldConfigurationService } from "./candidate-profile-field-configuration.service";
import { CandidateProfileFieldPipe } from "./candidate-profile-field.pipe";

@Pipe({
  name: "candidateProfileFieldPath",
  standalone: false,
})
export class CandidateProfileFieldPathPipe extends CandidateProfileFieldPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(fieldConfigurationService: CandidateProfileFieldConfigurationService) {
    super(fieldConfigurationService);
  }

  transform(path: string): Observable<string> {
    return this.getPropertyDefinition(path).pipe(
      map(({ group, field }) => {
        if (!field) {
          return path;
        }

        return `${group.label} > ${field.label}`;
      })
    );
  }
}
