import { Component, Input } from "@angular/core";
import { EducationModuleType, StaticDataType } from "@ankaadia/graphql";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";
import { AdHocEducationExamForm } from "../education-exam-ad-hoc-dialog/education-exam-ad-hoc-form.model";

@Component({
  selector: "app-education-exam-dialog-ad-hoc-fields",
  templateUrl: "./education-exam-dialog-ad-hoc-fields.component.html",
  standalone: false,
})
export class EducationExamDialogAdHocFieldsComponent {
  @Input({ required: true }) form: AdHocEducationExamForm;
  protected readonly EducationModuleType = EducationModuleType;

  readonly types = this.staticDataService.transformEnumToStaticDataModel("EducationModuleType", EducationModuleType);
  readonly languageLevels$ = this.staticDataService.getStaticData(StaticDataType.LanguageModules);

  constructor(private readonly staticDataService: StaticDataService) {}
}
