import { Component, ContentChild, Input, TemplateRef } from "@angular/core";

@Component({
  selector: "app-dashboard-list",
  templateUrl: "./dashboard-list.component.html",
  standalone: false,
})
export class DashboardListComponent<T> {
  @Input()
  label: string;

  @Input()
  labelIcon: string;

  @Input()
  items: T[];

  @ContentChild("item", { read: TemplateRef })
  itemTemplate: TemplateRef<any>;

  @ContentChild("empty", { read: TemplateRef })
  emptyTemplate: TemplateRef<any>;
}
