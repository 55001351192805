import { Injectable } from "@angular/core";
import {
  CreateEmailTemplateGQL,
  DeleteEmailTemplateGQL,
  DuplicateEmailTemplateGQL,
  EmailTemplateCreateInput,
  EmailTemplateDeleteInput,
  EmailTemplateForListFragment,
  EmailTemplateFragment,
  EmailTemplateFragmentDoc,
  EmailTemplateMutationResult,
  EmailTemplateTarget,
  EmailTemplateType,
  EmailTemplateUpdateInput,
  GetEmailTemplateGQL,
  GetEmailTemplatesByTypeGQL,
  GetEmailTemplatesGQL,
  GetLayoutEmailTemplateGQL,
  GetMergeTagsGQL,
  GetMissingEmailTemplatesGQL,
  GetMissingSharingNotificationEmailTemplatesGQL,
  RenderEmailTemplateGQL,
  UpdateEmailTemplateGQL,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class EmailTemplatesService {
  constructor(
    private readonly templatesGet: GetEmailTemplatesGQL,
    private readonly templatesByType: GetEmailTemplatesByTypeGQL,
    private readonly templateGet: GetEmailTemplateGQL,
    private readonly layoutGet: GetLayoutEmailTemplateGQL,
    private readonly templateRender: RenderEmailTemplateGQL,
    private readonly templateCreate: CreateEmailTemplateGQL,
    private readonly templateUpdate: UpdateEmailTemplateGQL,
    private readonly templateDelete: DeleteEmailTemplateGQL,
    private readonly templateDuplicate: DuplicateEmailTemplateGQL,
    private readonly missingTemplates: GetMissingEmailTemplatesGQL,
    private readonly missingSharingNotificationTemplates: GetMissingSharingNotificationEmailTemplatesGQL,
    private readonly mergeTags: GetMergeTagsGQL
  ) {}

  getMissingTemplates(organizationId: string): Observable<string[]> {
    return this.missingTemplates
      .fetch({ organizationId: organizationId })
      .pipe(map((result) => result.data.getMissingEmailTemplates));
  }

  getMissingSharingNotificationEmailTemplates(organizationId: string): Observable<string[]> {
    return this.missingSharingNotificationTemplates
      .fetch({ organizationId: organizationId })
      .pipe(map((result) => result.data.getMissingSharingNotificationEmailTemplates));
  }

  getMergeTags(organizationId: string, emailTarget: EmailTemplateTarget): Observable<any> {
    return this.mergeTags
      .fetch({ organizationId: organizationId, emailTarget: emailTarget })
      .pipe(map((result) => result.data.getMergeTags));
  }

  getAll(organizationId: string): Observable<EmailTemplateForListFragment[]> {
    return this.templatesGet
      .watch({ input: { organizationId: organizationId } })
      .valueChanges.pipe(map((result) => result.data.getEmailTemplates));
  }

  getByType(organizationId: string, type: EmailTemplateType): Observable<EmailTemplateForListFragment[]> {
    return this.templatesByType
      .fetch({ input: { organizationId: organizationId, type: type } })
      .pipe(map((result) => result.data.getEmailTemplatesByType));
  }

  get(templateId: string, organizationId: string): Observable<EmailTemplateFragment> {
    return this.templateGet
      .fetch({ input: { id: templateId, organizationId: organizationId } })
      .pipe(map((result) => result.data.getEmailTemplate));
  }

  getLayout(organizationId: string): Observable<EmailTemplateFragment> {
    return this.layoutGet.fetch({ input: { organizationId } }).pipe(map((x) => x.data.getLayoutEmailTemplate));
  }

  render(
    templateId: string,
    organizationId: string,
    processId: string,
    taskId: string,
    candidateId: string,
    candidateOrganizationId: string,
    language: string,
    roles: string[]
  ): Observable<string> {
    return this.templateRender
      .fetch({
        input: {
          id: templateId,
          roles: roles,
          organizationId: organizationId,
          processId: processId,
          taskId: taskId,
          candidateId: candidateId,
          candidateOrganizationId: candidateOrganizationId,
          language: language,
        },
      })
      .pipe(map((result) => result.data.renderEmailTemplate.body));
  }

  create(template: EmailTemplateFragment, language: string): Observable<EmailTemplateMutationResult> {
    const input = new EmailTemplateCreateInput();
    input.displayName = template.displayName;
    input.comment = template.comment;
    input.items = template.items;
    input.organizationId = template.organizationId;
    input.type = template.type;
    input.target = template.target;
    input.isContentTemplate = template.isContentTemplate;
    return this.templateCreate
      .mutate(
        { input, language },
        {
          update: (cache, result) =>
            cache.modify({
              fields: {
                getEmailTemplates: (refs, helper) =>
                  updateApolloCache(
                    input.organizationId,
                    refs,
                    helper,
                    cache,
                    result.data.createEmailTemplate.template
                  ),
              },
            }),
        }
      )
      .pipe(map((result) => result.data.createEmailTemplate));
  }

  duplicate(id: string, organizationId: string): Observable<EmailTemplateMutationResult> {
    return this.templateDuplicate
      .mutate(
        { input: { organizationId: organizationId, id: id } },
        {
          update: (cache, result) =>
            cache.modify({
              fields: {
                getEmailTemplates: (refs, helper) =>
                  updateApolloCache(organizationId, refs, helper, cache, result.data.duplicateEmailTemplate.template),
              },
            }),
        }
      )
      .pipe(map((result) => result.data.duplicateEmailTemplate));
  }

  update(template: EmailTemplateFragment, language: string): Observable<EmailTemplateMutationResult> {
    const input = new EmailTemplateUpdateInput();
    input.id = template.id;
    input._etag = template._etag;
    input.displayName = template.displayName;
    input.comment = template.comment;
    input.items = template.items;
    input.organizationId = template.organizationId;
    input.type = template.type;
    input.target = template.target;
    input.sendSeparateUserEmailsForEachCandidate = template.sendSeparateUserEmailsForEachCandidate;
    input.isContentTemplate = template.isContentTemplate;
    return this.templateUpdate.mutate({ input, language }).pipe(map((result) => result.data.updateEmailTemplate));
  }

  delete(template: EmailTemplateDeleteInput): Observable<boolean> {
    const input = new EmailTemplateDeleteInput();
    input.id = template.id;
    input._etag = template._etag;
    input.organizationId = template.organizationId;
    return this.templateDelete
      .mutate(
        { input: input },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getEmailTemplates: (refs, { readField }) => refs.filter((ref) => readField("id", ref) !== input.id),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.deleteEmailTemplate.status));
  }
}

function updateApolloCache(organizationId: string, refs, { storeFieldName }, cache, data: EmailTemplateFragment): any {
  if (data != null) {
    if (data.type === EmailTemplateType.Layout) return refs;
    if (!storeFieldName.includes(organizationId)) return refs;
    const ref = cache.writeFragment({
      data: data,
      fragment: EmailTemplateFragmentDoc,
      fragmentName: "EmailTemplate",
    });
    if (refs != null && refs.length > 0) {
      return [...refs, ref];
    } else {
      return [ref];
    }
  }
}
