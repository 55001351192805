import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import {
  EducationCourseCreateInput,
  EducationCourseForEditFragment,
  EducationCourseUpdateInput,
  EducationModuleForSelectionFragment,
  MinimalUserFragment,
  SharingTypeEnum,
  UserRole,
} from "@ankaadia/graphql";
import { omit } from "lodash";
import { Observable, Subscription, combineLatest, filter, map, switchMap } from "rxjs";
import { notNullValuesOf } from "../../../../shared/services/form.helper";
import { SettingsService } from "../../../../shared/services/settings.service";
import { CollectionRows, CollectionService } from "../../../collections/collection.service";
import { OrganizationsService } from "../../../organizations/organizations.service";
import { UsersService } from "../../../users/users.service";
import { EducationModuleService } from "../../education-modules/education-module.service";
import { EducationCourseFormService } from "./education-course-form.service";

type UserForSelection = MinimalUserFragment & { name: string };

@Component({
  selector: "app-education-course-dialog",
  templateUrl: "./education-course-dialog.component.html",
  standalone: false,
})
export class EducationCourseDialogComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  readonly form = this.formService.createForm();
  readonly educationModules$ = this.getEducationModules();
  readonly selectedModule$ = this.getSelectedModule();
  readonly providerOrganizationName$ = this.getProviderOrganizationName();
  readonly providerOrganizationTeachers$ = this.getProviderOrganizationTeachers();
  readonly participantCollections$ = this.getParticipantCollections();

  @Input()
  set course(course: EducationCourseForEditFragment) {
    this.form.reset(undefined, { emitEvent: false });
    this.form.patchValue(omit(course, ["participants"]));
    this.form.markAsPristine();
    this.changeDetector.detectChanges();
  }

  @Output()
  readonly created = new EventEmitter<EducationCourseCreateInput>();

  @Output()
  readonly updated = new EventEmitter<EducationCourseUpdateInput>();

  @Output()
  readonly closed = new EventEmitter<void>();

  constructor(
    private readonly formService: EducationCourseFormService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly settings: SettingsService,
    private readonly educationModuleService: EducationModuleService,
    private readonly organizationService: OrganizationsService,
    private readonly userService: UsersService,
    private readonly collectionService: CollectionService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(this.onModuleChange());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  save(): void {
    const course = this.form.getRawValue();
    if (course.id) {
      this.updated.emit(course);
    } else {
      this.created.emit(course);
    }
  }

  close(): void {
    this.closed.emit();
  }

  private onModuleChange(): Subscription {
    return this.selectedModule$.pipe(filter(() => this.form.dirty)).subscribe((module) => {
      this.form.controls.organizationId.setValue(module.organizationId);
      this.form.controls.teacherUserIds.reset();
      this.form.controls.participantCollectionId.reset();
    });
  }

  private getEducationModules(): Observable<EducationModuleForSelectionFragment[]> {
    return this.educationModuleService.listSelectable(this.settings.organizationId);
  }

  private getSelectedModule(): Observable<EducationModuleForSelectionFragment> {
    return combineLatest([notNullValuesOf(this.form.controls.moduleId), this.educationModules$]).pipe(
      map(([moduleId, modules]) => modules.find((x) => x.id === moduleId))
    );
  }

  private getProviderOrganizationName(): Observable<string> {
    return this.selectedModule$.pipe(
      switchMap((x) => this.organizationService.getOrganization(x.providerOrganizationId)),
      map((x) => x.name)
    );
  }

  private getProviderOrganizationTeachers(): Observable<UserForSelection[]> {
    return this.selectedModule$.pipe(
      switchMap((x) => this.userService.getUsersWithRoles(x.providerOrganizationId, [UserRole.CourseTeacher])),
      map((xs) => xs.map((x) => ({ ...x, name: `${x.lastname}, ${x.firstname}` })))
    );
  }

  private getParticipantCollections(): Observable<CollectionRows> {
    return this.selectedModule$.pipe(
      switchMap((x) =>
        this.collectionService.getCollectionsSharedToOrganization(x.organizationId, x.providerOrganizationId, [
          SharingTypeEnum.ReadOnlyCollaboration,
          SharingTypeEnum.Collaboration,
        ])
      )
    );
  }
}
