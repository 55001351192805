<ng-container *appTranslate="let t">
  <p-dropdown
    [ngModel]="selectedLanguage"
    [options]="options"
    optionValue="value"
    (ngModelChange)="selectedLanguageChange.emit($event)"
  >
    <ng-template let-language pTemplate="selectedItem">
        <div class="flex align-items-center gap-2">
        <span class="flag flag-{{ flags[language.value] }}"></span>
        <span class="ml-2">{{ language.label }}</span>
      </div>
    </ng-template>

    <ng-template let-language pTemplate="item">
      <div class="flex align-items-center gap-2">
        <span class="flag flag-{{ flags[language.value] }}"></span>
        <span class="ml-2">{{ language.label }}</span>
      </div>
    </ng-template>
  </p-dropdown>
</ng-container>
