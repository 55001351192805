import { Directive, Input, OnInit } from "@angular/core";
import { Chips } from "primeng/chips";
import { AnyFunction } from "ts-essentials";

/* Because p-chips does not have read-only mode. */
@Directive({ selector: "p-chips[readonly]", standalone: false })
export class ChipsReadonlyDirective implements OnInit {
  private static readonly methodSelector = <T extends keyof Chips>(xs: T[]): readonly T[] => xs;
  // those are methods used in the component's template
  // hopefully, user interactions will be rendered pointless as soon as we intercept these
  private static readonly methods = ChipsReadonlyDirective.methodSelector([
    "onWrapperClick",
    "onContainerFocus",
    "onContainerBlur",
    "onContainerKeyDown",
    "onItemClick",
    "removeItem",
    "onKeyDown",
    "onInput",
    "onPaste",
    "onInputFocus",
    "onInputBlur",
    "clear",
  ]);

  private readonly sources: Record<keyof typeof ChipsReadonlyDirective.methods, AnyFunction> = <any>{};

  @Input()
  readonly: boolean;

  constructor(private readonly chips: Chips) {}

  ngOnInit(): void {
    for (const method of ChipsReadonlyDirective.methods) {
      this.sources[method] = this.chips[method];
      this.chips[method] = (...args: any[]): any => {
        if (this.readonly) {
          args?.filter((x) => x instanceof Event).forEach((x) => x.preventDefault());
        } else {
          return this.sources[method].call(this.chips, ...args);
        }
      };
    }
  }
}
