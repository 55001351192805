import { Component } from "@angular/core";
import { Collection, CollectionCreateInput, CollectionType, Organization } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { SettingsService } from "../../shared/services/settings.service";
import { OrganizationsService } from "../organizations/organizations.service";

@Component({
  selector: "app-collection",
  templateUrl: "./collections.component.html",
  styleUrl: "./collections.component.scss",
  standalone: false,
})
export class CollectionsComponent {
  readonly availableOrgs$ = this.orgService.getLinkedOrganizations(this.settings.organizationId, true);

  eventTarget: EventTarget;
  newButtonDisabled = false;
  showSidebar = false;
  newCollection: CollectionCreateInput;
  selectedCollection: Collection;
  filteredOrg: Organization;

  operations = [
    {
      label: translate("autoCollection.new"),
      command: (): void => this.newClicked(CollectionType.Auto),
    },
  ];

  constructor(
    private readonly orgService: OrganizationsService,
    private readonly settings: SettingsService
  ) {}

  orgSelected(organization: Organization): void {
    this.filteredOrg = organization;
  }

  newClicked(collectionType = CollectionType.Standard): void {
    this.showSidebar = true;
    this.newCollection = {
      name: "",
      description: "",
      type: collectionType,
      organizationId: this.settings.organizationId,
    };
  }

  closeSidebar(col: Collection): void {
    this.selectedCollection = col;
    this.showSidebar = false;
  }
}
