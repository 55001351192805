import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Collection, CollectionTransferInput, Sharing, SharingTypeEnum } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { ConfirmationService, PrimeIcons } from "primeng/api";

import { CollectionService } from "../collection.service";

@Component({
  selector: "app-collection-transfer-dialog",
  templateUrl: "./collection-transfer-dialog.component.html",
  standalone: false,
})
export class CollectionTransferDialogComponent implements OnInit {
  @Input()
  collection: Collection;

  @Input()
  isTransferring: boolean;

  @Output()
  readonly saved = new EventEmitter<CollectionTransferInput>();

  @Output()
  readonly canceled = new EventEmitter<void>();

  form: FormGroup;
  sharings: Partial<Sharing>[];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly collectionService: CollectionService,
    private readonly confirmationService: ConfirmationService,
    private readonly translation: TranslocoService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      collectionId: [this.collection.id, Validators.required],
      organizationId: [this.collection.organizationId, Validators.required],
      targetOrganizationId: [null, Validators.required],
      keepCandidateCopy: [false, null],
      transferInternalNotes: [false, null],
      transferInternalDocuments: [false, null],
      transferInterviewData: [false, null],
    });
    this.collectionService
      .getSharingsForCollection(this.collection.id, this.collection.organizationId)
      .subscribe((x) => (this.sharings = x.filter((s) => s.sharingType == SharingTypeEnum.Collaboration)));
  }

  onSave(event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: this.translation.translate("collection.confirmTransfer", { name: this.collection.name }),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.saved.emit(this.form.value);
      },
    });
  }

  onCancel(): void {
    this.canceled.emit();
  }
}
