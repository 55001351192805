<ng-container *appTranslate="let t">
  <ng-container *ngIf="!isEditing && customTags">
    <ng-container *ngFor="let tag of customTags">
      <div
        *ngIf="tag.variable === variable && tag.value !== t('common.n/a')"
        class="p-tag p-component text-base mr-2 mt-2 mw-1"
        [ngClass]="CandidateTagColors[tag.color].tag"
        [pTooltip]="tag.label"
      >
        <span class="p-tag-value">{{ tag.value }}</span>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
