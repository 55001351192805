import { Opaque } from "ts-essentials";

export type Translatable = Opaque<string, "Translatable">;
export type Translated = Opaque<string, "Translated">;

export class TranslatableValidationIssue {
  propertyName: string;
  message: Translatable;

  constructor(propertyName: string, message: string) {
    this.propertyName = propertyName;
    this.message = message as Translatable;
  }
}

export class TranslatedValidationIssue {
  propertyName: string;
  message: Translated;

  constructor(propertyName: string, message: string) {
    this.propertyName = propertyName;
    this.message = message as Translated;
  }
}
