<app-candidate-responsible-role
  [form]="form"
  [responsibleRolePurpose]="rolePurpose"
  [candidateId]="candidateId"
  [alwaysShownRoles]="alwaysShownRoles"
  [class]="styleRoleClass"
  [readonly]="readonly"
  [modelData]="roleModelData"
  (valueChanged)="responsibleRoleChanged.next($event)"
></app-candidate-responsible-role>

<app-candidate-responsible-organization
  #responsibleOrganization
  [form]="form"
  [purpose]="organizationPurpose"
  [candidateOrganizationId]="candidateOrganizationId"
  [candidateId]="candidateId"
  [readonly]="readonly"
  [showControl]="canHaveADeputyOrRepresentative"
  [class]="styleOrganizationClass"
  [modelData]="organizationModelData"
  (valueChanged)="responsibleOrganizationChanged.next($event)"
></app-candidate-responsible-organization>

<app-candidate-responsible-representative
  #representative
  [form]="form"
  [readonly]="readonly || disabledRepresentatives"
  [showControl]="canHaveADeputyOrRepresentative"
  [candidateOrganizationId]="candidateOrganizationId"
  [contactOrganizationId]="organizationComponent?.control?.value"
  [candidateId]="candidateId"
  [modelData]="representativeModelData"
  [setDefaultOnChange]="false"
  [class]="styleRepresentativeClass"
  [responsibleRepresentativePurpose]="representativePurpose"
/>

<app-candidate-responsible-representative
  #deputy
  [form]="form"
  [readonly]="readonly || disabledRepresentatives"
  [showControl]="canHaveADeputyOrRepresentative"
  [contactOrganizationId]="organizationComponent?.control?.value"
  [candidateId]="candidateId"
  [candidateOrganizationId]="candidateOrganizationId"
  [modelData]="deputyModelData"
  [setDefaultOnChange]="false"
  [class]="styleDeputyClass"
  [responsibleRepresentativePurpose]="deputyPurpose"
/>
