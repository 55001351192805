import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { noop } from "lodash";
import { TreeNode } from "primeng/api";
import { TreeNodeSelectEvent } from "primeng/tree";
import { TreeNodeToggleClickEvent } from "../../../shared/primeng/tree-select-node-expand-fix/tree-select-node-expand-fix.directive";
import { CandidateProfileField, CandidateProfileFieldGroup } from "./candidate-profile-field-configuration.model";
import { CandidateProfileFieldConfigurationService } from "./candidate-profile-field-configuration.service";

@Component({
  selector: "app-candidate-profile-field-select",
  templateUrl: "./candidate-profile-field-select.component.html",
  styleUrl: "./candidate-profile-field-select.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CandidateProfileFieldSelectComponent,
    },
  ],
  standalone: false,
})
export class CandidateProfileFieldSelectComponent implements OnInit, ControlValueAccessor {
  @Input()
  placeholder: string;

  @Input()
  inputId: string;

  fields: TreeNode<CandidateProfileField>[] = null;

  group: TreeNode = null;

  field: TreeNode<CandidateProfileField> = null;

  constructor(
    private readonly fieldConfigurationService: CandidateProfileFieldConfigurationService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fieldConfigurationService.getFields().subscribe((fields: CandidateProfileFieldGroup[]) => {
      this.fields = fields.map(
        (group) =>
          ({
            key: group.label,
            label: group.label,
            selectable: false,
            data: null,
            children: group.fields.map(
              (field) =>
                ({
                  key: field.path,
                  data: field,
                  label: field.label,
                }) as TreeNode<CandidateProfileField>
            ),
          }) as TreeNode<CandidateProfileField>
      );
      this.updateGroup();
    });
  }

  @Input()
  path: string;

  onChange = noop;
  onTouched = noop;

  touched = false;

  disabled = false;

  onItemSelected(event: TreeNodeSelectEvent): void {
    this.markAsTouched();
    if (!this.disabled) {
      this.path = event.node.data.path;
      this.field = event.node;
      this.updateGroup();
      this.onChange(this.path);
    }
  }

  onItemToggled(event: TreeNodeToggleClickEvent): void {
    event.node.expanded = !event.node.expanded;
    this.changeDetector.markForCheck();
  }

  writeValue(path: string): void {
    this.path = path;
    this.updateGroup();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  private updateGroup(): void {
    const { group, field } = this.findGroupDefinition(this.path);
    this.group = group;
    this.field = field;
  }

  private findGroupDefinition(path: string): { field: TreeNode<CandidateProfileField>; group: TreeNode } {
    if (!this.fields) {
      return { group: null, field: null };
    }

    for (const group of this.fields) {
      for (const field of group.children) {
        if (field.data.path === path) {
          return { group, field };
        }
      }
    }

    return { group: null, field: null };
  }
}
