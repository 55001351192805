<div class="app-form" *appTranslate="let t">
  <form class="app-form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <p-card
      [header]="myOrgMode ? '' : isEditMode ? t('organization.edit') : t('organization.create')"
      styleClass="overflow-card"
    >
      <app-organization-edit
        class="block pl-2 pr-2"
        [form]="form"
        [creatorOrgName]="creatorOrgName"
        [logoDownloadUrl]="logoDownloadUrl"
        [logoForceChange]="logoForceChange"
        (logoSelect)="selectLogo($event)"
        (logoUpload)="uploadLogo($event)"
        [editOrg]="editOrg"
        [myOrgMode]="myOrgMode"
      ></app-organization-edit>

      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
          <p-button
            [disabled]="!form.valid || !form.dirty || disableSubmit"
            [label]="t('common.save')"
            icon="pi pi-check"
            type="submit"
            class="mr-2"
          ></p-button>
          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            type="reset"
            (onClick)="onCloseClick()"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  </form>
</div>
