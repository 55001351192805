import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { EducationModuleForm } from "./education-module-form.model";

@Injectable({ providedIn: "root" })
export class EducationModuleFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createForm(): EducationModuleForm {
    return this.formBuilder.group<EducationModuleForm["controls"]>({
      id: this.formBuilder.control(null),
      organizationId: this.formBuilder.control(null),
      _etag: this.formBuilder.control(null),
      name: this.formBuilder.control(null, [Validators.required, Validators.maxLength(200)]),
      description: this.formBuilder.control(null, [Validators.maxLength(200)]),
      providerOrganizationId: this.formBuilder.control(null, [Validators.required]),
      type: this.formBuilder.control(null, [Validators.required]),
      languageLevel: this.formBuilder.control(null, [Validators.required]),
      status: this.formBuilder.control(null, [Validators.required]),
      mode: this.formBuilder.control(null, [Validators.required]),
      units: this.formBuilder.control(null, [Validators.required]),
    });
  }
}
