<ng-container *appTranslate="let t">
  <app-table
    [items]="sharings$ | async"
    [columns]="columns"
    [captionOperations]="captionOperations"
    [tableOperations]="tableOperations"
    [(showSidebar)]="showSidebar"
  >
    <app-talent-pool-sharing-dialog
      *ngIf="showSidebar"
      [sharing]="selectedSharing.edit"
      [sharings]="sharings$ | async"
      (created)="create($event)"
      (updated)="update($event)"
      (closed)="close()"
    ></app-talent-pool-sharing-dialog>
  </app-table>
</ng-container>
