import { Component } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ITranslationProposal } from "../translationProposal.service";

@Component({
  selector: "app-translation-proposal-gathering",
  templateUrl: "./translationProposalGathering.component.html",
  styleUrl: "./translationProposalGathering.component.scss",
  standalone: false,
})
export class TranslationProposalGatheringComponent {
  textToCorrect = null;
  textCorrected = null;
  reasonForCorrection = null;
  comment = null;

  constructor(
    config: DynamicDialogConfig,
    private readonly dialog: DynamicDialogRef
  ) {
    this.textToCorrect = config.data.textToTranslate;
    this.textCorrected = this.textToCorrect;
  }

  cancel(_event: MouseEvent): void {
    this.dialog.close(null);
  }

  submit(_event: MouseEvent): void {
    this.dialog.close({
      textToCorrect: this.textToCorrect,
      translationProposal: this.textCorrected,
      reasonForCorrection: this.reasonForCorrection,
    } as ITranslationProposal);
  }
}
