<ng-container [formGroup]="form" *appTranslate="let t">
  <div class="p-fluid">
    <div class="field">
      <label for="name">{{ t("autoCollectionTemplate.name.title") }}</label>
      <input
        type="text"
        pInputText
        id="name"
        formControlName="name"
        [placeholder]="t('autoCollectionTemplate.name.placeholder')"
      />
      <small class="p-error" *ngIf="form.controls.name.errors?.required">
        {{ t("autoCollectionTemplate.name.required") }}
      </small>
      <small class="p-error" *ngIf="form.controls.name.errors?.unique">
        {{ t("autoCollectionTemplate.name.alreadyUsed") }}
      </small>
    </div>

    <div class="field">
      <label for="templateName">{{ t("autoCollectionTemplate.templateName.title") }}</label>
      <span class="p-input-icon-right">
        <i class="pi pi-info-circle" (click)="hintForTemplate.show($event)"></i>
        <input
          type="text"
          pInputText
          id="templateName"
          formControlName="templateName"
          [placeholder]="t('autoCollectionTemplate.templateName.placeholder')"
        />
      </span>
      <small class="p-error" *ngIf="form.controls.templateName.errors?.required">
        {{ t("autoCollectionTemplate.templateName.required") }}
      </small>

      <app-variable-description-icon #hintForTemplate></app-variable-description-icon>
    </div>
    <div class="flex flex-row justify-content-start p-2 field">
      <p-inputSwitch id="includeSharedCandidates" formControlName="includeSharedCandidates"></p-inputSwitch>
      <label for="includeSharedCandidates" class="ml-2">{{ t("autoCollection.includeSharedCandidates") }}</label>
    </div>
    <app-candidate-filter-set
      *ngIf="form && fields"
      [form]="form"
      [filter]="filter"
      [fields]="fields"
      [variables]="variables"
    ></app-candidate-filter-set>
    <div class="field mt-3">
      <label for="specialPurpose">{{ t("autoCollectionTemplate.specialPurpose.title") }}</label>
      <p-dropdown
        [options]="specialPurposes"
        id="specialPurpose"
        formControlName="specialPurpose"
        [showClear]="true"
      ></p-dropdown>
    </div>
    <div class="field">
      <label for="description">{{ t("description.title") }}</label>
      <textarea
        id="description"
        type="text"
        rows="3"
        pInputTextarea
        [autoResize]="true"
        formControlName="description"
      ></textarea>
      <small class="p-error" *ngIf="form.controls.description.errors?.required">
        {{ t("description.required") }}
      </small>
    </div>
  </div>
</ng-container>
