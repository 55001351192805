import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { ImportModule } from "../import/import.module";
import { ContractConditionsComponent } from "./contract-conditions-dialog/contract-conditions.component";
import { ContractTemplateAllowancesComponent } from "./contract-template-allowances/contract-template-allowances.component";
import { ContractTemplateBenefitsComponent } from "./contract-template-benefits/contract-template-benefits.component";
import { ContractTemplateDialogComponent } from "./contract-template-dialog/contract-template-dialog.component";
import { ContractTemplatePageComponent } from "./contract-template-page/contract-template-page.component";
import { ContractTemplatesComponent } from "./contract-templates/contract-templates.component";

@NgModule({
  imports: [SharedModule, ImportModule],
  declarations: [
    ContractTemplatesComponent,
    ContractTemplatePageComponent,
    ContractConditionsComponent,
    ContractTemplateDialogComponent,
    ContractTemplateAllowancesComponent,
    ContractTemplateBenefitsComponent,
  ],
  exports: [ContractTemplatesComponent, ContractTemplateDialogComponent, ContractTemplatePageComponent],
})
export class ContractTemplateModule {}
