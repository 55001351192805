<ng-container *appTranslate="let t">
  <div class="p-datatable">
    <table class="p-datatable-table">
      <thead class="p-datatable-thead">
        <tr>
          <th>{{ t("field.title") }}</th>
          <th>{{ t("differences.newValue") }}</th>
          <th>{{ t("candidateMultiEdit.withTheValue") }}</th>
          <th>{{ t("candidateMultiEdit.withNoValue") }}</th>
          <th>{{ t("candidateMultiEdit.withDifferentValue") }}</th>
          <th>{{ t("candidateMultiEdit.toBeUpdated") }}</th>
        </tr>
      </thead>

      <tbody class="p-datatable-tbody">
        <tr *ngFor="let preview of previews">
          <td>{{ preview.caption }}</td>
          <td>{{ preview.value }}</td>
          <td>
            <ng-container
              *ngTemplateOutlet="counter; context: { $implicit: preview.candidates.withTheValue }"
            ></ng-container>
          </td>
          <td>
            <ng-container
              *ngTemplateOutlet="counter; context: { $implicit: preview.candidates.withNoValue }"
            ></ng-container>
          </td>
          <td>
            <ng-container
              *ngTemplateOutlet="counter; context: { $implicit: preview.candidates.withDifferentValue }"
            ></ng-container>
          </td>
          <td>
            <span>{{
              (preview.candidates.withNoValue?.length ?? 0) + (preview.candidates.withDifferentValue?.length ?? 0)
            }}</span>
            <span>/</span>
            <span>{{ totalCandidates }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>

<ng-template #counter let-candidates>
  <span
    class="pi pi-id-card vertical-align-middle mr-1"
    [class.visibility-hidden]="!candidates?.length"
    [pTooltip]="candidates | multiEditCandidateTooltip"
  ></span>
  <span class="vertical-align-middle">
    <span>{{ candidates?.length ?? 0 }}</span>
    <span>/</span>
    <span>{{ totalCandidates }}</span>
  </span>
</ng-template>
