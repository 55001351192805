<ng-container *appTranslate="let t">
  <p-fieldset [legend]="t('billing.plan.ankaadiaLicense.title')">
    <app-plan-license-price-summary [price]="(plan() | async)?.licensePrice"
                                    [currency]="(plan() | async)?.currency"></app-plan-license-price-summary>
  </p-fieldset>
  <p-fieldset [legend]="t('billing.plan.candidates.title')">
    <app-plan-candidate-price-summary [price]="(plan() | async)?.candidatePrice"
                                      [currency]="(plan() | async)?.currency"></app-plan-candidate-price-summary>
  </p-fieldset>
</ng-container>
