import { Directive, OnInit } from "@angular/core";
import { Dropdown } from "primeng/dropdown";

@Directive({ selector: "p-dropdown", standalone: false })
export class DropdownReadonlyFixDirective implements OnInit {
  private clearSource: Dropdown["clear"];

  constructor(private readonly dropdown: Dropdown) {}

  ngOnInit(): void {
    this.clearSource = this.dropdown.clear;
    this.dropdown.clear = (event: Event): void => this.clear(this.dropdown, event);
  }

  clear(dropdown: Dropdown, event: Event): void {
    if (dropdown.readonly) {
      return;
    }
    this.clearSource.call(dropdown, event);
  }
}
