<p-tabView
  [class.vertical]="to.vertical != null ? to.vertical : false"
  rememberTab
  [stateKey]="to.tabStateKey ?? 'remembered-Tab'"
>
  <ng-container *ngFor="let tab of field.fieldGroup; index as i">
    <p-tabPanel
      *ngIf="!tab.hide"
      [header]="tab.props.label"
      [headerStyleClass]="invalidity != null && invalidity[i] ? 'p-error' : ''"
      [leftIcon]="to.showIcons ? tab.props.icon : null"
    >
      <ng-template pTemplate="header">
        <span [attr.data-testid]="'btn-' + tab.id">{{ tab.props.label }}</span>
        <i class="ml-1 pi pi-info-circle" [pTooltip]="tab.props.description" *ngIf="tab.props.description"></i>
      </ng-template>

      <div>
        <p-panel>
          <formly-field [field]="tab"></formly-field>
        </p-panel>
      </div>
    </p-tabPanel>
  </ng-container>
</p-tabView>
