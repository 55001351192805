<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <ng-container *ngIf="control">
    <label for="typeOfWorkLocation">{{ t("typeOfWorkLocation.title") }}</label>
    <p-dropdown
      inputId="typeOfWorkLocation"
      [options]="workLocations | async"
      [formControl]="control"
      [showClear]="true"
      [readonly]="readonly"
      [placeholder]="t('typeOfWorkLocation.placeholder')"
    ></p-dropdown>

    <small class="p-error" *ngIf="control.errors?.required">
      {{ t("common.validation.requiredShort") }}
    </small>
  </ng-container>
</ng-container>
