import { Directive, Input, OnInit, Optional } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { Button } from "primeng/button";
import { TabPanel } from "primeng/tabview";
import { FormElementMapService } from "./form-element-map.service";

@Directive({
  selector: "p-button[removeBtnRef], p-tabPanel[removeBtnRef]",
  standalone: false,
})
export class FormArrayRemoveButtonMapDirective implements OnInit {
  @Input()
  removeBtnRef: AbstractControl;

  constructor(
    @Optional() private readonly button: Button,
    @Optional() private readonly tabPanel: TabPanel,
    private readonly elementMapService: FormElementMapService
  ) {}

  ngOnInit(): void {
    if (this.removeBtnRef) {
      this.elementMapService.registerFormArrayRemoveButton(this.button ?? this.tabPanel, this.removeBtnRef);
      return;
    }
  }
}
