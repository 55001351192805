import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import {
  EducationMode,
  EducationModuleCreateInput,
  EducationModuleForEditFragment,
  EducationModuleStatus,
  EducationModuleType,
  EducationModuleUpdateInput,
  StaticDataType,
} from "@ankaadia/graphql";
import { omit } from "lodash";
import { Subscription, filter } from "rxjs";
import { notNullValuesOf } from "../../../../shared/services/form.helper";
import { SettingsService } from "../../../../shared/services/settings.service";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";
import { OrganizationsService } from "../../../organizations/organizations.service";
import { EducationModuleFormService } from "./education-module-form.service";

@Component({
  selector: "app-education-module-dialog",
  templateUrl: "./education-module-dialog.component.html",
  standalone: false,
})
export class EducationModuleDialogComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  readonly form = this.formService.createForm();
  readonly EducationModuleType = EducationModuleType;
  readonly EducationModuleStatus = EducationModuleStatus;
  readonly languageLevels$ = this.staticDataService.getStaticData(StaticDataType.LanguageModules);
  readonly educationProviders$ = this.organizationService.getLinkedOrganizations(this.settings.organizationId, true);
  readonly types = this.staticDataService.transformEnumToStaticDataModel("EducationModuleType", EducationModuleType);
  readonly modes = this.staticDataService.transformEnumToStaticDataModel("EducationMode", EducationMode);

  @Input()
  set module(module: EducationModuleForEditFragment) {
    this.form.reset();
    this.form.patchValue(omit(module));
    this.form.markAsPristine();
    this.changeDetector.detectChanges();
  }

  @Output()
  readonly created = new EventEmitter<EducationModuleCreateInput>();

  @Output()
  readonly updated = new EventEmitter<EducationModuleUpdateInput>();

  @Output()
  readonly closed = new EventEmitter<void>();

  constructor(
    private readonly formService: EducationModuleFormService,
    private readonly staticDataService: StaticDataService,
    private readonly settings: SettingsService,
    private readonly organizationService: OrganizationsService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(this.onTypeChange());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  save(): void {
    const course = this.form.getRawValue();
    if (course.id) {
      this.updated.emit(course);
    } else {
      this.created.emit(course);
    }
  }

  close(): void {
    this.closed.emit();
  }

  private onTypeChange(): Subscription {
    return notNullValuesOf(this.form.controls.type)
      .pipe(filter(() => this.form.dirty))
      .subscribe((type) => {
        if (type !== EducationModuleType.LanguageLearning) {
          this.form.controls.languageLevel.reset();
        }
      });
  }
}
