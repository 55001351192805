<ng-container *appTranslate="let t">
  <label for="visaRelocationRequirements">{{ t("visaRelocationRequirements.title") }}</label>
  <p-multiSelect
    id="visaRelocationRequirements"
    display="chip"
    [formControl]="control"
    [options]="shownRelocationRequirements$ | async"
    [showClear]="true"
    [showToggleAll]="false"
    [placeholder]="t('visaRelocationRequirements.placeholder')"
    [tooltip]="t('visaRelocationRequirements.placeholder')"
    [readonly]="readonly"
  ></p-multiSelect>
</ng-container>
