import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { SharingForm, SharingNotificationEmailUserGroupForm } from "./sharing-form.model";

@Injectable({ providedIn: "root" })
export class SharingFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createForm(): SharingForm {
    return this.formBuilder.group<SharingForm["controls"]>({
      sharedFileTypes: this.formBuilder.control(null),
      sharedTabs: this.formBuilder.control(null),
      autoCVRequested: this.formBuilder.control(null),
      providedTemplates: this.formBuilder.control(null),
      enabled: this.formBuilder.control(true),
      sharingType: this.formBuilder.control(null, [Validators.required]),
      destinationOrganizationId: this.formBuilder.control(null, [Validators.required]),
      disableSubmitFeedback: this.formBuilder.control(false),
      isAnonymous: this.formBuilder.control(false),
      anonymizationOptions: this.formBuilder.control(null),
      shareInternalDocuments: this.formBuilder.control(false),
      showCompletionStateAtDocuments: this.formBuilder.control(false),
      expiryDate: this.formBuilder.control(null),
      isCandidateEditable: this.formBuilder.control(false),
      enableNotificationEmails: this.formBuilder.control(false),
      notificationEmailUserGroupIds: this.formBuilder.control(null),
      notificationEmailUserGroups: this.formBuilder.array<SharingNotificationEmailUserGroupForm>([]),
      profileAccessId: this.formBuilder.control(null),
    });
  }

  createUserGroupForm(organizationId: string, isVariable: boolean): SharingNotificationEmailUserGroupForm {
    return this.formBuilder.group<SharingNotificationEmailUserGroupForm["controls"]>({
      organizationId: this.formBuilder.control(organizationId, [Validators.required]),
      userGroupIds: this.formBuilder.control(isVariable ? [] : null, [
        isVariable ? Validators.nullValidator : Validators.required,
      ]),
    });
  }
}
