<ng-container *appTranslate="let t">
  <p-messages *ngIf="showMessage" [value]="[message]" [enableService]="false" [closable]="false"> </p-messages>

  <ng-container *ngFor="let group of control.controls; index as i" [formGroup]="$any(group)">
    <app-qualification-exam
      [form]="$any(group)"
      [readonly]="readonly"
      [index]="i"
      [$examResult]="$examResult"
      [$qualificationMeasures]="$qualificationMeasures"
      [moduleNames]="moduleSuggestions"
      (deleteClicked)="remove(i)"
    ></app-qualification-exam>
  </ng-container>

  <div class="field col-12 lg:col-3 mt-1">
    <p-button
      icon="pi pi-plus"
      [label]="t('qualificationExam.add')"
      [disabled]="readonly || form.disabled"
      (onClick)="add()"
    ></p-button>
  </div>
</ng-container>
