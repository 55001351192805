import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { HealthProfessionsRegisterCandidateDataAt } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";

const nameOf = nameofFactory<HealthProfessionsRegisterCandidateDataAt>();

@Component({
  selector: "app-candidate-health-professions-register-appointment-date",
  templateUrl: "./candidate-health-professions-register-appointment-date.component.html",
  standalone: false,
})
export class CandidateHealthProfessionsRegisterAppointmentDateComponent implements OnInit, OnDestroy {
  private readonly language = this.transloco.getActiveLang();

  @Input({ required: true })
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  readonly: boolean;

  get control(): FormControl<Date | null> {
    return this.form.get(nameOf("appointmentDate")) as FormControl<Date | null>;
  }

  set control(value: FormControl<Date | null>) {
    this.form.setControl(nameOf("appointmentDate"), value);
  }

  constructor(private readonly transloco: TranslocoService) {}

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<Date | null>(null);
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("appointmentDate"), { emitEvent: false });
  }
}
