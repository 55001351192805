import { Injectable } from "@angular/core";
import { FormArray, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { DocumentConfigFormatInput, DocumentConfigurationInputType } from "@ankaadia/graphql";
import { intersection, isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { ICountrySpecificRequiredDocumentsFields } from "../../../migration-specific/migration-specifics.model";
import {
  DocumentConfigurationInputTypeForm,
  DocumentFormatForm,
  DocumentSetItemForm,
} from "./document-set-item-form.model";

const MAX_PROFESSIONS = 10;
const MAX_EMPLOYERS = 50;

@Injectable({ providedIn: "root" })
export class DocumentSetItemFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createForm(immigrationCountrySpecificFields: ICountrySpecificRequiredDocumentsFields[]): DocumentSetItemForm {
    return this.formBuilder.group<DocumentSetItemForm["controls"]>({
      documents: this.formBuilder.array<DocumentConfigurationInputTypeForm>(
        [],
        this.validateForDuplicateRows(immigrationCountrySpecificFields)
      ),
    });
  }

  createRowForm(
    type: DocumentConfigurationInputType,
    immigrationCountrySpecificFields: ICountrySpecificRequiredDocumentsFields[]
  ): DocumentConfigurationInputTypeForm {
    return this.formBuilder.group<DocumentConfigurationInputTypeForm["controls"]>(
      {
        id: this.formBuilder.control(type?.id ?? uuidv4()),
        documentType: this.formBuilder.control(type?.documentType ?? null, Validators.required),
        documentSetType: this.formBuilder.control(type?.documentSetType ?? null),
        countryOfOrigin: this.formBuilder.control(
          type?.countryOfOrigin ?? null,
          Validators.compose([this.onlyAllValidator, Validators.required])
        ),
        federalStateDestination: this.formBuilder.control(
          type?.federalStateDestination ?? null,
          Validators.compose([this.onlyAllValidator, Validators.required])
        ),
        profession: this.formBuilder.control(
          type?.profession ?? null,
          Validators.compose([this.onlyAllValidator, Validators.required, Validators.maxLength(MAX_PROFESSIONS)])
        ),
        employerAfterRecognitionIds: this.formBuilder.control(
          type?.employerAfterRecognitionIds ?? null,
          Validators.compose([this.onlyAllValidator, Validators.required, Validators.maxLength(MAX_EMPLOYERS)])
        ),
        employerBeforeRecognitionIds: this.formBuilder.control(
          type?.employerBeforeRecognitionIds ?? null,
          Validators.compose([this.onlyAllValidator, Validators.required, Validators.maxLength(MAX_EMPLOYERS)])
        ),
        notNecessary: this.formBuilder.control(type?.notNecessary ?? false),
        isOptional: this.formBuilder.control(type?.isOptional ?? null),
        ...immigrationCountrySpecificFields // inject migration specific fields
          .reduce((prev, cur) => ({ ...prev, [cur.name]: this.formBuilder.control(type[cur.name] ?? null) }), {}),
        documentFormat: this.formBuilder.array<DocumentFormatForm>(
          (type?.documentFormat ?? []).map((x) => this.createDocumentFormatForm(x))
        ),
        documentFormatOther: this.formBuilder.array<DocumentFormatForm>(
          (type?.documentFormatOther ?? []).map((x) => this.createDocumentFormatForm(x))
        ),
      },
      { validators: this.validateRow(immigrationCountrySpecificFields) }
    );
  }

  createDocumentFormatForm(format: DocumentConfigFormatInput): DocumentFormatForm {
    return this.formBuilder.group<DocumentFormatForm["controls"]>({
      value: this.formBuilder.control(format.value, Validators.required),
      requiredDeliveryFormats: this.formBuilder.control(format.requiredDeliveryFormats),
      count: this.formBuilder.control(format.count, Validators.required),
    });
  }

  private validateRow(immigrationCountrySpecificFields: ICountrySpecificRequiredDocumentsFields[]): ValidatorFn {
    return (row: DocumentConfigurationInputTypeForm): ValidationErrors | null => {
      const v = (immigrationCountrySpecificFields ?? []).map((x) => row.value[x.name]?.length);
      if (v.filter((x) => x != null && x > 0).length > 1) {
        return { onlyOne: true };
      } else {
        return row.controls.notNecessary.value ||
          row.controls.documentFormat.controls.some((c) => !isEmpty(c.value.requiredDeliveryFormats)) ||
          row.controls.documentFormatOther.controls.some((c) => !isEmpty(c.value.requiredDeliveryFormats))
          ? null
          : { atLeastOne: true };
      }
    };
  }

  private onlyAllValidator(allControl: FormControl<string[]>): ValidationErrors | null {
    return (allControl.value ?? []).includes("ALL") && (allControl.value ?? []).length > 1 ? { onlyAll: true } : null;
  }

  private validateForDuplicateRows(
    immigrationCountrySpecificFields: ICountrySpecificRequiredDocumentsFields[]
  ): ValidatorFn {
    return (formArray: FormArray<DocumentConfigurationInputTypeForm>): ValidationErrors | null => {
      formArray.controls.forEach((control) => {
        if (control.errors?.duplicate) {
          delete control.errors["duplicate"];
          control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        }
      });
      formArray.controls.forEach((element) => {
        formArray.controls.forEach((el) => {
          if (el.value.id !== element.value.id) {
            if (
              el.value.documentType === element.value.documentType &&
              intersection(el.value.countryOfOrigin, element.value.countryOfOrigin).length > 0 &&
              intersection(el.value.federalStateDestination, element.value.federalStateDestination).length > 0 &&
              intersection(el.value.profession, element.value.profession).length > 0 &&
              ((immigrationCountrySpecificFields ?? []).some(
                (x) => intersection(el.value[x.name], element.value[x.name]).length > 0
              ) ||
                (immigrationCountrySpecificFields ?? []).every(
                  (x) => isEmpty(el.value[x.name]) && isEmpty(element.value[x.name])
                ))
            ) {
              el.setErrors({ duplicate: true });
              element.setErrors({ duplicate: true });
            }
          }
        });
      });
      return null;
    };
  }
}
