<ng-container *appTranslate="let t">
  <p-card [header]="t('common.history')">
    <app-activity-table
      class="activities"
      [activities]="activities"
      [totalActivities]="totalActivities"
      [lazy]="true"
      [showCandidate]="false"
      [showProcess]="false"
      (pageChange)="onPageChange($event)"
    ></app-activity-table>

    <ng-template pTemplate="footer">
      <div class="flex justify-content-end">
        <p-button closeOnEscape [label]="t('common.close')" icon="pi pi-times" (onClick)="closed.emit()"></p-button>
      </div>
    </ng-template>
  </p-card>
</ng-container>
