import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ensure2LetterIsoLanguage, supportedUserInterfaceLanguages4LetterISO } from "@ankaadia/ankaadia-shared";
import { StaticDataType } from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { ConfirmationService, Message, PrimeIcons } from "primeng/api";
import { FileUpload } from "primeng/fileupload";
import { map } from "rxjs";
import { MessageDialogService } from "../../shared/message-dialog/message-dialog.service";
import { FileUploadService } from "../../shared/services/file-upload.service";
import { SettingsService } from "../../shared/services/settings.service";
import { StaticDataService } from "../../shared/static-data/static-data.service";
import { ImportType, XlsxImportExportService } from "./xlsx-import-export.service";

export interface ImportComponentData {
  importType: ImportType;
  templates: Template[];
  header: string;
  submitMessage: string;
  messages?: Message[];
  blob: string;
  fileName: string;
  organizationId: string;
}

export interface Template {
  template: (lang: string, addTestData: boolean) => void;
  templateName: string;
}

export interface SaveResult {
  blob: string;
  fileName: string;
  language: string;
  organizationId: string;
}

@Component({
  selector: "app-import",
  templateUrl: "./import.component.html",
  providers: [FileUploadService],
  standalone: false,
})
export class ImportComponent implements OnInit {
  constructor(
    private readonly importService: XlsxImportExportService,
    private readonly fileUploadService: FileUploadService,
    private readonly errorService: MessageDialogService,
    private readonly confirmationService: ConfirmationService,
    private readonly staticDataService: StaticDataService,
    private readonly transLoco: TranslocoService,
    private readonly settings: SettingsService
  ) {}

  readonly language = this.transLoco.getActiveLang();

  @Input({ required: true })
  import: ImportComponentData;

  @Output()
  readonly saved = new EventEmitter<SaveResult>();

  @Output()
  readonly closed = new EventEmitter<void>();

  @ViewChild("upload")
  upload: FileUpload;

  isUploading: boolean;

  fileSelected: boolean;

  addTestData: boolean;

  languageOptions$ = this.staticDataService
    .getStaticData(StaticDataType.Languages, this.language)
    .pipe(
      map((langs) =>
        langs.filter((lang) =>
          ensure2LetterIsoLanguage(supportedUserInterfaceLanguages4LetterISO()).includes(lang.value)
        )
      )
    );

  selectedLanguage: string = ensure2LetterIsoLanguage(this.language);

  get isMasterUser(): boolean {
    return this.settings.isMasterUser;
  }

  ngOnInit(): void {
    this.fileUploadService.onProgress.subscribe((x) => (this.upload.progress = x));
    this.fileUploadService.onUpload.subscribe(() => this.onUpload());
    this.fileUploadService.onError.subscribe((x) => this.onError(x));
  }

  selectFile(file: File): void {
    this.fileSelected = !!file;
  }

  uploadFile(file: File): void {
    this.isUploading = true;
    this.import.fileName = file.name;
    this.importService
      .getImportUploadUrl({
        blob: this.import.blob,
        organizationId: this.import.organizationId,
      })
      .subscribe((url) => this.fileUploadService.upload(file, url));
  }

  submit(event: MouseEvent): void {
    this.confirmationService.confirm({
      target: event.target,
      message: this.import.submitMessage,
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => this.upload.upload(),
    });
  }

  private onUpload(): void {
    this.saved.emit({
      blob: this.import.blob,
      fileName: this.import.fileName,
      organizationId: this.import.organizationId,
      language: this.language,
    });
    this.isUploading = false;
    this.fileSelected = false;
    this.upload.clear();
  }

  private onError(response: any): void {
    this.isUploading = false;
    this.errorService.showMessage(translate("file.uploadFailed"), response.message);
  }
}
