import { Injectable } from "@angular/core";
import { FormArray, FormBuilder, ValidationErrors, Validators } from "@angular/forms";
import { SelectedPartnerSharingConfiguration } from "@ankaadia/graphql";
import { setError } from "../../../shared/services/form.helper";
import { OrganizationLinkForm, PartnerSharingForm, SelectedPartnerForm } from "./organization-link-form.model";

@Injectable({ providedIn: "root" })
export class OrganizationLinkFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createOrganizationLinkGroup(): OrganizationLinkForm {
    return this.formBuilder.group<OrganizationLinkForm["controls"]>({
      id: this.formBuilder.control(null),
      _etag: this.formBuilder.control(null),
      linkedOrganizationId: this.formBuilder.control(null, Validators.required),
      organizationId: this.formBuilder.control(null, Validators.required),
      shareContacts: this.formBuilder.control(null),
      selectedContacts: this.formBuilder.control(null),
      shareContractTemplates: this.formBuilder.control(null),
      selectedContractTemplates: this.formBuilder.control(null),
      shareEZBData: this.formBuilder.control(null),
      sharePartners: this.formBuilder.control(null),
      partnerSharing: this.formBuilder.group<PartnerSharingForm["controls"]>({
        shareContacts: this.formBuilder.control(null),
        shareContractTemplates: this.formBuilder.control(null),
        shareEZBData: this.formBuilder.control(null),
        sharePartners: this.formBuilder.control(null),
        allowLinkingInCaseItIsManaged: this.formBuilder.control(null),
      }),
      selectedPartners: this.formBuilder.array<SelectedPartnerForm>([], { validators: this.uniquePartnerValidator }),
    });
  }

  createSelectedPartnerGroup(partner?: SelectedPartnerSharingConfiguration): SelectedPartnerForm {
    return this.formBuilder.group<SelectedPartnerForm["controls"]>({
      organizationId: this.formBuilder.control(partner?.organizationId, Validators.required),
      shareContacts: this.formBuilder.control(partner?.shareContacts),
      shareContractTemplates: this.formBuilder.control(partner?.shareContractTemplates),
      shareEZBData: this.formBuilder.control(partner?.shareEZBData),
      sharePartners: this.formBuilder.control(partner?.sharePartners),
      allowLinkingInCaseItIsManaged: this.formBuilder.control(partner?.allowLinkingInCaseItIsManaged),
    });
  }

  private uniquePartnerValidator(selectedPartners: FormArray<SelectedPartnerForm>): ValidationErrors | null {
    for (const selectedPartner of selectedPartners?.controls ?? []) {
      if (!selectedPartner.controls.organizationId.value) continue;
      let invalid = false;
      for (const otherPartner of selectedPartners?.controls ?? []) {
        if (otherPartner === selectedPartner) break;
        if (otherPartner.controls.organizationId.value === selectedPartner.controls.organizationId.value) {
          invalid = true;
          break;
        }
      }
      setError(selectedPartner.controls.organizationId, "unique", invalid);
    }
    return null;
  }
}
