<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <app-candidate-aligned-label
    labelFor="immigrationAuthority"
    [labelText]="t('immigrationAuthority.title')"
    [useAlignmentCheckBox]="renderLabelUsingAlignmentCheckBox"
  >
  </app-candidate-aligned-label>
  <p-dropdown
    inputId="immigrationAuthority"
    [options]="filteredImmigrationAuthorities | async"
    [formControl]="control"
    [showClear]="true"
    [placeholder]="t('immigrationAuthority.placeholder')"
    [readonly]="readonly"
  ></p-dropdown>

  <small class="p-error" *ngIf="control.errors?.required">
    {{ t("immigrationAuthority.required") }}
  </small>
</ng-container>
