import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { StaticDataModel } from "@ankaadia/graphql";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { Observable } from "rxjs";
import { QualificationMeasureExamFormDe } from "../../../candidate-form/candidate-form.model";

@Component({
  selector: "app-qualification-exam",
  templateUrl: "./qualification-exam.component.html",
  standalone: false,
})
export class QualificationExamComponent implements OnInit {
  @Input({ required: true })
  form: FormGroup<QualificationMeasureExamFormDe["controls"]>;

  @Input({ required: true })
  readonly: boolean;

  @Input({ required: true })
  $qualificationMeasures: Observable<StaticDataModel[]>;

  @Input({ required: true })
  $examResult: Observable<StaticDataModel[]>;

  @Input({ required: true })
  index: number;

  @Input({ required: true })
  moduleNames: string[];

  @Output()
  readonly deleteClicked = new EventEmitter<void>();

  protected moduleSuggestions: string[] = [];

  isAdaptation(): boolean {
    return this.form.controls.examType.value === "ADAPTATION";
  }

  ngOnInit(): void {
    this.form.controls.examType.valueChanges.subscribe(() => {
      this.form.controls.firstExamDate.setValue(null);
      this.form.controls.firstExamPassed.setValue(null);
      this.form.controls.secondExamDate.setValue(null);
      this.form.controls.secondExamPassed.setValue(null);
      this.form.controls.examArea.setValue(null);
      this.form.controls.moduleName.setValue(null);
    });
  }

  search($event: AutoCompleteCompleteEvent): void {
    this.moduleSuggestions =
      this.moduleNames.filter((module) => module.toLowerCase().includes($event.query.toLowerCase())) ?? null;
  }
}
