<app-table
  *appTranslate="let t"
  [newOperations]="newOperations"
  [items]="weeklyPerformance"
  [columns]="columns"
  [showSearch]="true"
  [showSidebar]="showSidebar"
  sortField
>
  <ng-template #caption>
    <div class="grid">
      <p-dropdown
        [formControl]="selectedCourse"
        [options]="courses"
        optionLabel="name"
        styleClass="mw-500 mr-2"
        class="{{ candidateState ? 'col-10' : 'col-12' }}"
        [placeholder]="t('educationCourses.placeholder')"
      ></p-dropdown>
      <p-chip
        *ngIf="candidateState"
        [icon]="EducationCourseCandidateStatusIcons[candidateState]"
        [label]="candidateState | enum: 'EducationCourseCandidateStatus'"
        [pTooltip]="t('courseStatus.title')"
        tooltipPosition="top"
        styleClass="mw-500 mr-2"
        class="align-content-center col-2"
      ></p-chip>
    </div>
  </ng-template>

  <ng-container *ngIf="!isReadOnly && showSidebar">
    <app-candidate-education-course-performance-dialog
      *ngIf="showSidebar"
      [candidateCourses]="courses"
      [saveDisabled]="saveDisabled"
      [candidateOrganizationId]="candidateId.candidateOrganizationId"
      (saved)="addToCourse($event)"
      (closed)="showSidebar = false"
    ></app-candidate-education-course-performance-dialog>
  </ng-container>
</app-table>
