import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FilterMetadata } from "primeng/api";

@Component({
  selector: "app-candidate-favorite-filter",
  templateUrl: "./candidate-favorite-filter.component.html",
  styleUrl: "./candidate-favorite-filter.component.scss",
  standalone: false,
})
export class CandidateFavoriteFilterComponent implements OnInit, OnChanges {
  isActive: boolean;

  @Input()
  meta: FilterMetadata | null;

  @Output()
  readonly metaChange = new EventEmitter<FilterMetadata | null>();

  ngOnInit(): void {
    this.isActive = this.meta?.value ?? false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.meta) {
      this.isActive = this.meta?.value ?? false;
    }
  }

  toggle(): void {
    this.isActive = !this.isActive;
    this.metaChange.emit(this.isActive ? { value: this.isActive, matchMode: "true" } : null);
  }
}
