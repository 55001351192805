import { Component, computed, input } from "@angular/core";
import { ContractFormService, DiscountForm, PhaseForm } from "../contract-form.service";
import { FormArray } from "@angular/forms";
import { DiscountType, StaticDataModel, SupportedBillingCurrency } from "@ankaadia/graphql";
import { SharedModule } from "../../../../shared/shared.module";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";

@Component({
  standalone: true,
  selector: "app-discounts",
  templateUrl: "./discounts.component.html",
  providers: [ContractFormService],
  imports: [SharedModule],
})
export class DiscountsComponent {
  readonly DiscountType = DiscountType;

  form = input.required<PhaseForm>();

  target = input.required<"candidateDiscounts" | "licenseDiscounts">();

  currency = input.required<SupportedBillingCurrency>();

  discounts = computed(() => this.form().get(this.target()) as FormArray<DiscountForm>);

  discountTypes: StaticDataModel[];

  targetRow: DiscountForm;
  editRow: DiscountForm;
  showDialog = false;

  constructor(
    private readonly contractFormService: ContractFormService,
    staticDataService: StaticDataService
  ) {
    this.discountTypes = staticDataService.transformEnumToStaticDataModel("DiscountType", DiscountType);
  }

  addDiscount(): void {
    this.targetRow = null;
    this.editRow = this.contractFormService.createDiscountForm();

    this.showDialog = true;
  }

  editDiscount(targetRow: DiscountForm): void {
    this.targetRow = targetRow;
    this.editRow = this.contractFormService.createDiscountForm();
    this.editRow.setValue(targetRow.getRawValue());

    this.showDialog = true;
  }

  saveDiscount(): void {
    if (!this.editRow.valid) return;

    if (this.targetRow) {
      this.targetRow.setValue(this.editRow.getRawValue());
      this.endEdit();
      return;
    }

    this.discounts().push(this.editRow);
    this.endEdit();
  }

  endEdit(): void {
    this.targetRow = null;
    this.editRow = null;
    this.showDialog = false;
  }

  removeDiscountAt(index: number): void {
    this.discounts().removeAt(index);
  }
}
