import { Component, Input, OnInit } from "@angular/core";
import { getAllLaborMarketRoles } from "@ankaadia/ankaadia-shared";
import { SupportedImmigrationCountry } from "@ankaadia/graphql";
import { startWith } from "rxjs";
import { ResponsibleRolePurpose } from "../../../../candidate-reusables/candidate-responsible-representative-group/candidate-responsible-representative-group.service";
import { LaborMarketAdmissionFormDe } from "../../../candidate-form.model";

@Component({
  selector: "app-candidate-labor-market-admission-de",
  templateUrl: "./candidate-labor-market-admission-de.component.html",
  standalone: false,
})
export class CandidateLaborMarketAdmissionDEComponent implements OnInit {
  readonly ResponsibleRolePurposeType = ResponsibleRolePurpose;
  protected SupportedImmigrationCountry = SupportedImmigrationCountry;
  readonly today = new Date();

  @Input()
  form: LaborMarketAdmissionFormDe;

  @Input()
  candidateId: string;

  @Input()
  candidateOrganizationId: string;

  @Input()
  readonly: boolean;

  protected readonly responsibleRoles = getAllLaborMarketRoles();

  ngOnInit(): void {
    this.form.controls.employmentApprovalRequested.valueChanges
      .pipe(startWith(this.form.controls.employmentApprovalRequested.value))
      .subscribe((x) =>
        x
          ? this.form.controls.employmentApprovalRequestDate.enable()
          : this.form.controls.employmentApprovalRequestDate.disable()
      );
  }
}
