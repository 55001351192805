<ng-container *appTranslate="let t">
  <app-candidate-form
    [isMultiEditMode]="isMultiEditMode"
    [candidateData]="candidateData"
    (isValidChange)="isValid = $event"
    [isEditing]="isEditing"
    [isBusy]="isBusy"
    [sharings]="sharings"
    [selectedCollectionId]="selectedCollectionId"
    [selectedCollectionOrganizationId]="selectedCollectionOrganizationId"
    (isGeneralTabVisibleChange)="isGeneralTabVisible = $event"
    (isPreparingCandidateChange)="isPreparingCandidate = $event"
    (tabHashChange)="tabHash = $event"
    (isCreatorChange)="isCreator = $event"
    (multiInvite)="multiInvite()"
    (multiUninvite)="multiUninvite()"
    *ngIf="candidateData"
  >
    <div toolbar>
      <p-button
        *ngIf="!isMultiEditMode"
        [label]="t('common.edit')"
        icon="pi pi-pencil"
        [disabled]="isEditing || isReadOnlyCollaboration || isPreparingCandidate"
        class="mr-2"
        (onClick)="edit()"
        data-testid="btn-edit"
      ></p-button>

      <!--!form.untouched does not work here because of certain hacks-->
      <p-button
        [label]="t('common.save')"
        icon="pi pi-check"
        [disabled]="!isEditing || isBusy || !isValid || fieldDiffService.isOn"
        class="mr-2"
        (onClick)="save($event)"
        data-testid="btn-save"
      ></p-button>

      <p-button
        [label]="t('common.cancel')"
        icon="pi pi-times"
        [disabled]="!isEditing || isBusy || fieldDiffService.isOn"
        class="mr-2"
        (onClick)="cancel()"
        data-testid="btn-cancel"
      ></p-button>

      <p-button
        *ngIf="!isMultiEditMode"
        [label]="t('common.delete')"
        icon="pi pi-trash"
        [disabled]="isEditing || isReadOnlyCollaboration || !isCreator || fieldDiffService.isOn"
        (onClick)="openDeleteOverlay($event)"
        data-testid="btn-delete"
      ></p-button>
    </div>
  </app-candidate-form>

  <p-overlayPanel #statusChangedOverlay [showCloseIcon]="true" (onHide)="statusChangedOverlaySubject.next(false)">
    <ng-template pTemplate>
      <p-message
        severity="warn"
        class="hidden lg:block text-center"
        styleClass="mb-2 text-left"
        [text]="
          updatedStatus === CandidateStatus.Paused
            ? t('candidate.pausedWarning')
            : candidateData?.candidate.email
              ? t('candidate.rejectWarning')
              : t('candidate.rejectWarningNoEmail')
        "
      ></p-message>

      <div class="field">
        <label>{{ t("candidate.statusChangingReason") }}:</label>
        <div class="p-inputgroup">
          <input type="text" data-testid="form-control-reason" pInputText [(ngModel)]="statusChangingReason" />
          <button
            submitOnEnter
            type="button"
            pButton
            [label]="t('common.ok')"
            (click)="statusChangedOverlaySubject.next(true)"
            data-testid="btn-ok"
          ></button>
        </div>
      </div>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #deleteOverlay [showCloseIcon]="true">
    <ng-template pTemplate>
      <div class="field">
        <label>{{ t("candidate.confirmNumber") }}:</label>
        <div class="p-inputgroup">
          <input data-testid="candidate-id" type="text" pInputText [(ngModel)]="deletedCandidateNr" />
          <button
            submitOnEnter
            type="button"
            pButton
            [label]="t('common.ok')"
            [disabled]="deletedCandidateNr !== candidateData?.candidate?.displayId"
            (click)="delete()"
            data-testid="btn-ok"
          ></button>
        </div>
      </div>
    </ng-template>
  </p-overlayPanel>
</ng-container>
