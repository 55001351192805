import { Injectable } from "@angular/core";
import {
  CandidateSheetModeEnum,
  ExportOrganizationsDataGQL,
  GetImportUploadUrlGQL,
  GetXlsxTemplateForCandidatesGQL,
  GetXlsxTemplateForContractTemplatesGQL,
  GetXlsxTemplateForOrganizationContactSettingsGQL,
  GetXlsxTemplateForOrganizationContactsGQL,
  GetXlsxTemplateForOrganizationCrmDataGQL,
  GetXlsxTemplateForOrganizationsGQL,
  GetXlsxTemplateForOrganizationUserGroupsGQL,
  GetXlsxTemplateForOrganizationUserMembershipsGQL,
  GetXlsxTemplateForOrganizationUsersGQL,
  GetXlsxTemplateForProcessesGQL,
  ImportCandidatesGQL,
  ImportContractTemplatesGQL,
  ImportFilledXlsxTemplateForOrganizationUserGroupsGQL,
  ImportFilledXlsxTemplateForOrganizationUserMembershipsGQL,
  ImportFilledXlsxTemplateForOrganizationUsersGQL,
  ImportOrganizationContactSettingsGQL,
  ImportOrganizationContactsGQL,
  ImportOrganizationCrmDataGQL,
  ImportOrganizationsGQL,
  ImportUrlInput,
  SupportedImmigrationCountry,
  XlsxCandidateImportInput,
  XlsxContractTemplateImportInput,
  XlsxImportResult,
  XlsxOrganizationContactImportInput,
  XlsxOrganizationContactSettingsImportInput,
  XlsxOrganizationCrmDataImportInput,
  XlsxOrganizationImportInput,
  XlsxTemplateResultFragment,
} from "@ankaadia/graphql";
import { Message } from "primeng/api";
import { map, Observable } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { ImportComponentData, Template } from "./import.component";

export enum ImportType {
  Organizations = "Organizations",
  Contacts = "Contacts",
  ContactSettings = "ContactSettings",
  ContractTemplates = "ContractTemplates",
  CrmData = "CrmData",
  UserGroups = "UserGroups",
  Users = "Users",
  UserMemberships = "UserMemberships",
}

@Injectable({ providedIn: "root" })
export class XlsxImportExportService {
  constructor(
    private readonly getImportUploadUrlGQL: GetImportUploadUrlGQL,
    private readonly organizationImportTemplate: GetXlsxTemplateForOrganizationsGQL,
    private readonly importOrganizations: ImportOrganizationsGQL,
    private readonly organizationContactImportTemplate: GetXlsxTemplateForOrganizationContactsGQL,
    private readonly importOrganizationContacts: ImportOrganizationContactsGQL,

    private readonly contractTemplateImportTemplate: GetXlsxTemplateForContractTemplatesGQL,
    private readonly importContractTemplates: ImportContractTemplatesGQL,

    private readonly organizationContactSettingsImportTemplate: GetXlsxTemplateForOrganizationContactSettingsGQL,
    private readonly importOrganizationContactSetting: ImportOrganizationContactSettingsGQL,
    private readonly processesImportTemplate: GetXlsxTemplateForProcessesGQL,
    private readonly candidateImportTemplate: GetXlsxTemplateForCandidatesGQL,
    private readonly importCandidates: ImportCandidatesGQL,

    private readonly organizationCrmTemplate: GetXlsxTemplateForOrganizationCrmDataGQL,

    private readonly exportOrganizationsDataGQL: ExportOrganizationsDataGQL,
    private readonly importOrganizationCrmDataGQL: ImportOrganizationCrmDataGQL,

    private readonly organizationUserGroupsTemplate: GetXlsxTemplateForOrganizationUserGroupsGQL,
    private readonly importOrganizationUserGroups: ImportFilledXlsxTemplateForOrganizationUserGroupsGQL,
    private readonly organizationUsersTemplate: GetXlsxTemplateForOrganizationUsersGQL,
    private readonly importOrganizationUsers: ImportFilledXlsxTemplateForOrganizationUsersGQL,
    private readonly organizationUserMembershipsTemplate: GetXlsxTemplateForOrganizationUserMembershipsGQL,
    private readonly importOrganizationUserMemberships: ImportFilledXlsxTemplateForOrganizationUserMembershipsGQL
  ) {}

  getImportUploadUrl(input: ImportUrlInput): Observable<string> {
    return this.getImportUploadUrlGQL.fetch({ input: input }).pipe(map((x) => x.data.getImportUploadUrl));
  }

  createImportInput(
    importType: ImportType,
    organizationId: string,
    header: string,
    submitMessage: string,
    templates: Template[],
    messages?: Message[]
  ): ImportComponentData {
    return {
      header,
      importType,
      submitMessage,
      templates,
      messages,
      organizationId: organizationId,
      blob: uuidv4(),
      fileName: null,
    };
  }

  getOrganizationImportTemplate(
    organizationId: string,
    language: string,
    addTestData: boolean
  ): Observable<XlsxTemplateResultFragment> {
    const input = {
      organizationId: organizationId,
      language: language,
      addTestData: addTestData,
    };
    return this.organizationImportTemplate
      .fetch({ input: input })
      .pipe(map((x) => x.data.getXLSXTemplateForOrganizations));
  }

  importOrganization(input: XlsxOrganizationImportInput): Observable<XlsxImportResult> {
    return this.importOrganizations
      .mutate({ input: input })
      .pipe(map((x) => x.data.importFilledXLSXTemplateForOrganizations));
  }

  getOrganizationContactImportTemplate(
    organizationId: string,
    language: string,
    addTestData: boolean
  ): Observable<XlsxTemplateResultFragment> {
    const input = {
      organizationId: organizationId,
      language: language,
      addTestData: addTestData,
    };
    return this.organizationContactImportTemplate
      .fetch({ input: input })
      .pipe(map((x) => x.data.getXLSXTemplateForOrganizationContacts));
  }

  importOrganizationContact(input: XlsxOrganizationContactImportInput): Observable<XlsxImportResult> {
    return this.importOrganizationContacts
      .mutate({ input: input })
      .pipe(map((x) => x.data.importFilledXLSXTemplateForOrganizationContacts));
  }

  getOrganizationContactSettingsImportTemplate(
    organizationId: string,
    language: string,
    addTestData: boolean
  ): Observable<XlsxTemplateResultFragment> {
    const input = {
      organizationId: organizationId,
      language: language,
      addTestData: addTestData,
    };
    return this.organizationContactSettingsImportTemplate
      .fetch({ input: input })
      .pipe(map((x) => x.data.getXLSXTemplateForOrganizationContactSettings));
  }

  importOrganizationContactSettings(input: XlsxOrganizationContactSettingsImportInput): Observable<XlsxImportResult> {
    return this.importOrganizationContactSetting
      .mutate({ input: input })
      .pipe(map((x) => x.data.importFilledXLSXTemplateForOrganizationContactSettings));
  }

  getProcessImportTemplate(organizationId: string, language: string): Observable<XlsxTemplateResultFragment> {
    const input = {
      organizationId: organizationId,
      language: language,
    };
    return this.processesImportTemplate.fetch({ input: input }).pipe(map((x) => x.data.getXLSXTemplateForProcesses));
  }

  getCandidateImportTemplate(
    organizationId: string,
    collectionId: string,
    collectionOrganizationId: string,
    language: string,
    mode: CandidateSheetModeEnum,
    immigrationCountry: string,
    addTestData: boolean
  ): Observable<XlsxTemplateResultFragment> {
    const input = {
      organizationId: organizationId,
      collectionId: collectionId,
      collectionOrganizationId: collectionOrganizationId,
      language: language,
      mode: mode,
      immigrationCountry: immigrationCountry,
      addTestData,
    };
    return this.candidateImportTemplate.fetch({ input: input }).pipe(map((x) => x.data.getXLSXTemplateForCandidates));
  }

  importCandidate(input: XlsxCandidateImportInput): Observable<XlsxImportResult> {
    return this.importCandidates
      .mutate({ input: input })
      .pipe(map((x) => x.data.importFilledXLSXTemplateForCandidates));
  }

  getContractTemplateImportTemplate(
    organizationId: string,
    immigrationCountry: SupportedImmigrationCountry,
    myOrganizationOnly: boolean,
    language: string,
    addTestData: boolean
  ): Observable<XlsxTemplateResultFragment> {
    const input = {
      organizationId: organizationId,
      immigrationCountry: immigrationCountry,
      myOrganizationOnly: myOrganizationOnly,
      language: language,
      addTestData: addTestData,
    };
    return this.contractTemplateImportTemplate
      .fetch({ input: input })
      .pipe(map((x) => x.data.getXLSXTemplateForContractTemplates));
  }

  importContractTemplate(input: XlsxContractTemplateImportInput): Observable<XlsxImportResult> {
    return this.importContractTemplates
      .mutate({ input: input })
      .pipe(map((x) => x.data.importFilledXLSXTemplateForContractTemplates));
  }

  getOrganizationCrmImportTemplate(
    organizationId: string,
    language: string,
    addTestData: boolean
  ): Observable<XlsxTemplateResultFragment> {
    return this.organizationCrmTemplate
      .fetch({ input: { language, organizationId, addTestData } })
      .pipe(map((x) => x.data.getXLSXTemplateForOrganizationCRMData));
  }

  importOrganizationCrm(input: XlsxOrganizationCrmDataImportInput): Observable<XlsxImportResult> {
    return this.importOrganizationCrmDataGQL
      .mutate({ input: input })
      .pipe(map((x) => x.data.importFilledXLSXTemplateForOrganizationCRMData));
  }

  exportOrganizationsData(organizationId: string, language: string): Observable<XlsxTemplateResultFragment> {
    const input = {
      organizationId: organizationId,
      language: language,
    };
    return this.exportOrganizationsDataGQL.fetch({ input: input }).pipe(map((x) => x.data.exportOrganizationsData));
  }

  getOrganizationUserGroupImportTemplate(
    organizationId: string,
    language: string,
    addTestData: boolean
  ): Observable<XlsxTemplateResultFragment> {
    return this.organizationUserGroupsTemplate
      .fetch({ input: { language, organizationId, addTestData } })
      .pipe(map((x) => x.data.getXLSXTemplateForOrganizationUserGroups));
  }

  importUserGroups(input: XlsxContractTemplateImportInput): Observable<XlsxImportResult> {
    return this.importOrganizationUserGroups
      .mutate({ input })
      .pipe(map((x) => x.data.importFilledXLSXTemplateForOrganizationUserGroups));
  }

  getOrganizationUserImportTemplate(
    organizationId: string,
    language: string,
    addTestData: boolean
  ): Observable<XlsxTemplateResultFragment> {
    return this.organizationUsersTemplate
      .fetch({ input: { language, organizationId, addTestData } })
      .pipe(map((x) => x.data.getXLSXTemplateForOrganizationUsers));
  }

  importUsers(input: XlsxContractTemplateImportInput): Observable<XlsxImportResult> {
    return this.importOrganizationUsers
      .mutate({ input })
      .pipe(map((x) => x.data.importFilledXLSXTemplateForOrganizationUsers));
  }

  getOrganizationUserMembershipImportTemplate(
    organizationId: string,
    language: string,
    addTestData: boolean
  ): Observable<XlsxTemplateResultFragment> {
    return this.organizationUserMembershipsTemplate
      .fetch({ input: { language, organizationId, addTestData } })
      .pipe(map((x) => x.data.getXLSXTemplateForOrganizationUserMemberships));
  }

  importUserMemberships(input: XlsxContractTemplateImportInput): Observable<XlsxImportResult> {
    return this.importOrganizationUserMemberships
      .mutate({ input })
      .pipe(map((x) => x.data.importFilledXLSXTemplateForOrganizationUserMemberships));
  }
}
