import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import {
  AfterRecognition,
  BeforeRecognition,
  OrganizationContactForSelectionFragment,
  StaticDataModel,
} from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { CandidateOrganizationContactsService } from "../candidate-organization-contacts.service";

const nameOf = nameofFactory<BeforeRecognition | AfterRecognition>();

@Component({
  selector: "app-candidate-employer-first-deputy",
  templateUrl: "./candidate-employer-first-deputy.component.html",
  standalone: false,
})
export class CandidateEmployerFirstDeputyComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  employerContacts: OrganizationContactForSelectionFragment[];
  employerFirstDeputyPlaceholder: string;

  private readonly language = this.transloco.getActiveLang();

  @Input()
  modelData: { id: string; firstName: string; lastName: string; organizationId: string };

  @Input()
  formId: string;

  @Input()
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  readonly: boolean;

  @Input()
  employerId: string;

  @Input()
  candidateId: string;

  @Input()
  isShared = true;

  @Output()
  readonly valueChanged = new EventEmitter<string>();

  recognitionTypes: StaticDataModel[];

  get control(): FormControl<string> {
    return this.form.get(nameOf("firstDeputyId")) as FormControl<string>;
  }

  set control(value: FormControl<string>) {
    this.form.setControl(nameOf("firstDeputyId"), value);
  }

  get firstName(): FormControl<string> {
    return this.form.get(nameOf("firstDeputyFirstName")) as FormControl<string>;
  }

  set firstName(value: FormControl<string>) {
    this.form.setControl(nameOf("firstDeputyFirstName"), value);
  }

  get lastName(): FormControl<string> {
    return this.form.get(nameOf("firstDeputyLastName")) as FormControl<string>;
  }

  set lastName(value: FormControl<string>) {
    this.form.setControl(nameOf("firstDeputyLastName"), value);
  }

  constructor(
    private readonly contactsService: CandidateOrganizationContactsService,
    private readonly transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<string>(undefined);
    if (!this.firstName) this.firstName = new FormControl<string>(undefined);
    if (!this.lastName) this.lastName = new FormControl<string>(undefined);
    this.control.valueChanges.subscribe((employerFirstDeputyId) => {
      const employerFirstDeputy = this.employerContacts?.find((x) => x.id === employerFirstDeputyId);
      this.valueChanged.emit(employerFirstDeputyId);
      if (!this.readonly) {
        this.firstName.setValue(employerFirstDeputy?.firstName ?? null);
        this.lastName.setValue(employerFirstDeputy?.lastName ?? null);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.employerId || changes.candidateId) {
      const employerId = changes.employerId?.currentValue ?? this.employerId;
      this.contactsService.getContacts(employerId).subscribe((x) => {
        this.employerContacts = x.contacts;
        this.employerFirstDeputyPlaceholder = x.firstDeputy?.placeholder;
      });
    }
  }

  ngAfterViewInit(): void {
    if (!this.control.value && this.modelData?.id) {
      this.control.setValue(this.modelData.id, { emitEvent: false });
      this.firstName.setValue(this.modelData.firstName, { emitEvent: false });
      this.lastName.setValue(this.modelData.lastName, { emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("firstDeputyId"), { emitEvent: false });
    this.form.removeControl(nameOf("firstDeputyFirstName"), { emitEvent: false });
    this.form.removeControl(nameOf("firstDeputyLastName"), { emitEvent: false });
  }
}
