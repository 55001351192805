<ng-container *appTranslate="let t">
  <p-blockUI [target]="tabView" [blocked]="!candidateId?.candidateId">
    <span class="block-ui-message">{{ t("candidate.educationWarning") }}</span>
  </p-blockUI>

  <p-tabView #tabView class="vertical" styleClass="app-form" rememberTab stateKey="candidate-vertical-tabs">
    <p-tabPanel [header]="t('performance.title')">
      <app-candidate-education-course-performance
        [courses]="courses"
        [isReadOnly]="readonly"
        [candidateId]="{
          candidateId: candidateId.candidateId,
          candidateOrganizationId: candidateId.candidateOrganizationId,
        }"
        (addedToCourse)="onAddedToCourse($event)"
      ></app-candidate-education-course-performance>
    </p-tabPanel>
    <p-tabPanel [header]="t('educationExams.title')" [cache]="false">
      <ng-template pTemplate="content">
        <app-candidate-education-exams
          [modules]="modules"
          [exams]="exams"
          [isReadOnly]="readonly"
          [candidateData]="candidateId"
          (removed)="onExamRemoved($event)"
          (created)="onExamAdded($event)"
          (updated)="onExamUpdated($event)"
        ></app-candidate-education-exams>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel [header]="t('educationExamResults.title')">
      <app-candidate-education-exam-results
        [modules]="modules"
        [educationProviders]="educationProviders"
        [exams]="exams"
        [examResults]="examResults"
        [isEditable]="!readonly && hasEducationFullAccess"
        [readonly]="readonly"
        [candidateId]="candidateId"
      ></app-candidate-education-exam-results>
    </p-tabPanel>
  </p-tabView>
</ng-container>
