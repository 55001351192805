import { Injectable } from "@angular/core";
import { StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { groupBy, isEmpty, toPairs } from "lodash";
import { forkJoin, isObservable, map, Observable, of, switchMap } from "rxjs";
import { OrganizationFactoryService } from "../../organization-specific/organization-specific-factory.service";
import { SettingsService } from "../../shared/services/settings.service";
import { StaticDataService } from "../../shared/static-data/static-data.service";
import { FilterMetadataMap } from "../collections/collection-edit-assigned-candidates/custom-filter.model";
import { FiltersForm } from "./candidate-filter-form.model";
import {
  candidateConditionFactory,
  CandidateFilter,
  CandidateFilterCondition,
  CandidateFilterField,
  CandidateFilterGroup,
  ExistingImmigrationCountries,
  ExistingImmigrationCountry,
  StaticDataCountry,
  StaticDataCountryGroup,
  StaticDataWithCountry,
} from "./candidate-filter.model";
import { CandidateFilterMetadata } from "@ankaadia/ankaadia-shared";

export type FiltersType = FiltersForm["value"]["filters"][0];

@Injectable({ providedIn: "root" })
export class CandidateFilterService {
  private readonly language = this.transloco.getActiveLang();
  readonly immigrationCountries = this.getImmigrationCountries();

  constructor(
    private readonly transloco: TranslocoService,
    private readonly specificsFactory: OrganizationFactoryService,
    private readonly settings: SettingsService,
    private readonly staticDataService: StaticDataService
  ) {}

  getFilterFields(): Observable<CandidateFilterGroup[]> {
    return forkJoin([
      this.specificsFactory.getOrganizationSpecifics(this.settings.organizationId),
      this.immigrationCountries,
    ]).pipe(
      switchMap(([specifics, countries]) =>
        forkJoin([
          this.getProfileDefinitions(specifics.getCandidateProfileFormlyFields()),
          specifics.getCustomCandidateFilters(countries),
        ]).pipe(map(([profile, custom]) => this.mergeDefinitions([profile, custom])))
      )
    );
  }

  buildFilterMetadataMap(form: FiltersForm): FilterMetadataMap {
    const filters = form.value.filters;
    return this.buildFilterMetadataMapFromFilters(filters);
  }

  buildFilterMetadataMapFromFilters(filters: FiltersType[]): FilterMetadataMap {
    const result = filters.reduce<Record<string, CandidateFilterMetadata[]>>((result, filter) => {
      const fieldName = filter.field?.data.value;
      if (fieldName) {
        result[fieldName] = result[fieldName] ?? [];
        result[fieldName].push({
          value: filter.value,
          matchMode: filter.condition,
          dataSource: filter.dataSource,
        });
      }
      return result;
    }, {});
    return result;
  }

  private getImmigrationCountries(): Observable<StaticDataCountry[]> {
    return this.staticDataService.getStaticData(StaticDataType.SupportedImmigrationCountries, this.language).pipe(
      map((countries) =>
        countries
          .filter((country) => ExistingImmigrationCountries.includes(country.value as ExistingImmigrationCountry))
          .map<StaticDataCountry>((country) => ({
            value: country.value as ExistingImmigrationCountry,
            label: country.label,
          }))
      )
    );
  }

  private mergeDefinitions(groups: CandidateFilterGroup[][]): CandidateFilterGroup[] {
    return groups
      .flat()
      .reduce<CandidateFilterGroup[]>((all, tab) => {
        const existing = all.find((x) => x.label === tab.label);
        if (existing) {
          existing.fields.push(...tab.fields);
        } else {
          all.push(tab);
        }
        return all;
      }, [])
      .map((t) => ({
        label: t.label,
        country: t.country,
        fields: t.fields
          .map((f) => ({ ...f, dataSources: isEmpty(f.dataSources) ? t.dataSources : f.dataSources }))
          .filter((f) => f.label && f.value && f.conditions?.length)
          .sort((a, b) => a.label.localeCompare(b.label, this.language)),
      }))
      .filter((t) => t.label && t.fields?.length);
  }

  private getProfileDefinitions(form: FormlyFieldConfig[]): Observable<CandidateFilterGroup[]> {
    return forkJoin(
      form[0].fieldGroup.map((fieldConfig) =>
        forkJoin(
          this.flattenWithPath(
            [fieldConfig],
            (config) => [...(config.fieldGroup ?? []), ...((config.fieldArray as FormlyFieldConfig)?.fieldGroup ?? [])],
            (config) => config.key
          ).map(({ item, path }) =>
            this.getFieldConditions(item).pipe(
              map((conditions) => ({
                label: item.props?.filterLabel ?? item.props?.label,
                value: ["os", "profile", ...path].join(".") as CandidateFilterField["value"],
                conditions: conditions,
              }))
            )
          )
        ).pipe(
          map((fields) => ({
            label: fieldConfig.props?.filterLabel ?? fieldConfig.props?.label,
            fields: fields,
          }))
        )
      )
    );
  }

  private getFieldConditions(field: FormlyFieldConfig): Observable<CandidateFilter[]> {
    if (field.type === "dropdown" && field.props?.options) {
      return this.optionConditionFactory("choice", field.props.options);
    }
    if (field.type === "multiselect" && field.props?.options) {
      return this.optionConditionFactory("multichoice", field.props.options);
    }
    if (
      (field.type === "input" && (field.props.type === "text" || field.props.type === "email")) ||
      field.type === "inputMask" ||
      field.type === "textarea"
    ) {
      return of(candidateConditionFactory("text"));
    }
    if (field.type === "input" && field.props.type === "number") {
      return of(candidateConditionFactory("number"));
    }
    if (field.type === "datepicker") {
      return of(candidateConditionFactory("date"));
    }
    if (field.type === "checkbox") {
      return of(candidateConditionFactory("boolean"));
    }
    if (field.type === "profession") {
      return this.optionConditionFactory(
        "profession",
        this.staticDataService.getStaticData(StaticDataType.Profession, this.language)
      );
    }
    if (field.type === "autocomplete" && field.props) {
      return of(
        candidateConditionFactory("autocomplete")({
          getSuggestions: (query) => field.props.getSuggestions(query, field.props),
          getFirstSuggestion: (query) => field.props.firstSuggestion(query, field.props),
        })
      );
    }
    if (field.type === "chips" && field.props?.options) {
      return this.optionConditionFactory("chips", field.props.options);
    }
    return of(null);
  }

  private flattenWithPath<T, U>(
    xs: T[],
    childSelector: (_: T) => T[],
    pathSelector: (_: T) => U
  ): { item: T; path: U[] }[] {
    const internal = (
      xs: T[],
      childSelector: (_: T) => T[],
      pathSelector: (_: T) => U,
      pathAcc: U[]
    ): { item: T; path: U[] }[] => {
      return xs.reduce<{ item: T; path: U[] }[]>((acc, x) => {
        const path = pathSelector(x);
        const currPath = [...pathAcc, ...(path ? [path] : [])];
        acc.push(
          { item: x, path: currPath },
          ...internal(childSelector(x) ?? [], childSelector, pathSelector, currPath)
        );
        return acc;
      }, []);
    };
    const result = internal(xs, childSelector, pathSelector, []);
    return result;
  }

  private optionConditionFactory(
    type: "choice" | "multichoice" | "profession" | "chips",
    options: StaticDataWithCountry[] | Observable<StaticDataWithCountry[]>
  ): Observable<CandidateFilterCondition[]> {
    return this.immigrationCountries.pipe(
      map((countries) => candidateConditionFactory(type)(this.buildOptions(options, countries)))
    );
  }

  private buildOptions(
    options: StaticDataWithCountry[] | Observable<StaticDataWithCountry[]>,
    countries: StaticDataCountry[]
  ): Observable<StaticDataModel[] | StaticDataCountryGroup[]> {
    const observable = isObservable(options) ? options : of(options);
    return observable.pipe(
      map((entries) => (entries.every((entry) => entry.country) ? this.groupByCountry(countries, entries) : entries))
    );
  }

  private groupByCountry(
    countries: StaticDataCountry[],
    entries: StaticDataWithCountry[]
  ): StaticDataWithCountry[] | StaticDataCountryGroup[] {
    return toPairs(groupBy(entries, (entry) => entry.country)).map(([country, elements]) => {
      const label = countries.find((c) => c.value === country)?.label ?? country;
      return { value: country, label: label, items: elements };
    });
  }
}
