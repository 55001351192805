import { Injectable } from "@angular/core";
import {
  CreateLocalPartnerProfileConfigGQL,
  DeleteLocalPartnerProfileConfigGQL,
  GetAllLocalPartnerProfileConfigSetsGQL,
  GetLocalPartnerProfileConfigGQL,
  GetLocalPartnerProfileConfigNameGQL,
  LocalPartnerProfileConfigSetDeleteInput,
  LocalPartnerProfileConfigSetFragment,
  LocalPartnerProfileConfigSetFragmentDoc,
  LocalPartnerProfileConfigSetUpdateInput,
  UpdateLocalPartnerProfileConfigGQL,
} from "@ankaadia/graphql";
import { ApolloCache } from "@apollo/client/cache";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class LocalPartnerProfileConfigService {
  constructor(
    private readonly localPartnerProfileConfigsGet: GetAllLocalPartnerProfileConfigSetsGQL,
    private readonly localPartnerProfileConfigGet: GetLocalPartnerProfileConfigGQL,
    private readonly localPartnerProfileConfigGetName: GetLocalPartnerProfileConfigNameGQL,
    private readonly localPartnerProfileConfigUpdate: UpdateLocalPartnerProfileConfigGQL,
    private readonly localPartnerProfileConfigCreate: CreateLocalPartnerProfileConfigGQL,
    private readonly localPartnerProfileConfigDelete: DeleteLocalPartnerProfileConfigGQL
  ) {}

  getAll(organizationId: string): Observable<Partial<LocalPartnerProfileConfigSetFragment>[]> {
    return this.localPartnerProfileConfigsGet
      .watch({ input: { organizationId: organizationId } })
      .valueChanges.pipe(map((result) => result.data.getAllLocalPartnerProfileConfigSets));
  }

  get(profileConfigId: string, organizationId: string): Observable<LocalPartnerProfileConfigSetFragment> {
    return this.localPartnerProfileConfigGet
      .fetch({
        input: { id: profileConfigId, organizationId: organizationId },
      })
      .pipe(map((result) => result.data.getLocalPartnerProfileConfigSet));
  }

  getName(profileConfigId: string, organizationId: string): Observable<string> {
    return this.localPartnerProfileConfigGetName
      .fetch({
        input: { id: profileConfigId, organizationId: organizationId },
      })
      .pipe(map((result) => result.data.getLocalPartnerProfileConfigSet.setName));
  }

  create(input: LocalPartnerProfileConfigSetFragment): Observable<LocalPartnerProfileConfigSetFragment> {
    return this.localPartnerProfileConfigCreate
      .mutate(
        { input: input },
        {
          update: (cache, result) =>
            cache.modify({
              fields: {
                getAllLocalPartnerProfileConfigSets: (refs, helper) =>
                  this.updateApolloCache(input, refs, helper, cache, result.data.createLocalPartnerProfileConfigSet),
              },
            }),
        }
      )
      .pipe(map((result) => result.data.createLocalPartnerProfileConfigSet));
  }

  update(template: LocalPartnerProfileConfigSetFragment): Observable<LocalPartnerProfileConfigSetFragment> {
    const input = new LocalPartnerProfileConfigSetUpdateInput();
    input.id = template.id;
    input._etag = template._etag;
    input.organizationId = template.organizationId;
    input.setName = template.setName;

    return this.localPartnerProfileConfigUpdate
      .mutate({ input: input })
      .pipe(map((result) => result.data.updateLocalPartnerProfileConfigSet));
  }

  delete(id: string, orgId: string, etag: string): Observable<boolean> {
    const input = new LocalPartnerProfileConfigSetDeleteInput();
    input.id = id;
    input._etag = etag;
    input.organizationId = orgId;
    return this.localPartnerProfileConfigDelete
      .mutate(
        { input: input },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getAllLocalPartnerProfileConfigSets: (refs, { readField }) =>
                  refs.filter((ref) => readField("id", ref) !== input.id),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.deleteLocalPartnerProfileConfigSet.status));
  }

  private updateApolloCache(
    input: LocalPartnerProfileConfigSetFragment,
    refs: any,
    { storeFieldName },
    cache: ApolloCache<any>,
    data: any
  ): any {
    if (!storeFieldName.includes(input.organizationId)) return refs;
    const ref = cache.writeFragment({
      data: data,
      fragment: LocalPartnerProfileConfigSetFragmentDoc,
      fragmentName: "LocalPartnerProfileConfigSet",
    });
    if (refs != null && refs.length > 0) {
      return [...refs, ref];
    } else {
      return [ref];
    }
  }
}
