import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { SettingsService } from "../../shared/services/settings.service";

@Component({
  selector: "app-logout",
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LogoutComponent implements OnInit {
  constructor(private readonly settings: SettingsService) {}

  ngOnInit(): void {
    this.settings.logout();
  }
}
