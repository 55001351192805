import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AssignCollectionInput, Collection, Organization } from "@ankaadia/graphql";
import { OrganizationsService } from "../../organizations/organizations.service";
import { CollectionService } from "../collection.service";

@Component({
  selector: "app-collection-edit-assigned-collection-dialog",
  templateUrl: "./collection-edit-assigned-collection-dialog.component.html",
  standalone: false,
})
export class CollectionEditAssignedCollectionDialogComponent implements OnInit {
  @Input()
  collection: AssignCollectionInput;

  @Input()
  disabled: boolean;

  @Output()
  readonly saved = new EventEmitter<AssignCollectionInput>();

  @Output()
  readonly closed = new EventEmitter<void>();

  form: FormGroup;
  organizations: Partial<Organization>[];
  collections: Partial<Collection>[];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly organizationService: OrganizationsService,
    private readonly collectionService: CollectionService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: [this.collection.id, Validators.required],
      _etag: [this.collection._etag, Validators.required],
      organizationId: [this.collection.organizationId, Validators.required],
      assignedId: [this.collection.assignedId, Validators.required],
      assignedOrganizationId: [this.collection.assignedOrganizationId, Validators.required],
    });
    this.updateOrganizations();
    this.updateCollections(this.collection.assignedOrganizationId, false);
  }

  updateOrganizations(): void {
    this.organizationService
      .getOrganizationsForAssignment(this.collection.id)
      .subscribe((x) => (this.organizations = x));
  }

  updateCollections(organizationId: string, clearValue = true): void {
    if (organizationId) {
      this.collectionService
        .getCollectionsForAssignment(this.collection.id, organizationId)
        .subscribe((x) => (this.collections = x));
      if (clearValue) {
        this.form.controls.assignedId.setValue(null);
      }
    } else {
      this.collections = null;
    }
  }

  onSave(): void {
    this.saved.emit(this.form.value);
  }

  onClose(): void {
    this.closed.emit();
  }
}
