import { Injectable } from "@angular/core";
import {
  CandidateCommentDeleteInput,
  CandidateCommentUpsertInput,
  CandidateEmployerUpsertInput,
  CandidateFeedbackDeleteInput,
  CandidateFeedbackUpsertInput,
  CandidateInterviewIterationDeleteInput,
  CandidateInterviewsFeedback,
  CommentData,
  DeleteInterviewCommentGQL,
  DeleteInterviewFeedbackGQL,
  DeleteInterviewIterationGQL,
  EmployerData,
  FeedbackData,
  GetCandidateInterviewIterationsGQL,
  UpsertInterviewCommentGQL,
  UpsertInterviewEmployerGQL,
  UpsertInterviewFeedbackGQL,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class CandidateInterviewFeedbacksService {
  constructor(
    private readonly iterationDelete: DeleteInterviewIterationGQL,
    private readonly feedbacksGet: GetCandidateInterviewIterationsGQL,
    private readonly employerUpsert: UpsertInterviewEmployerGQL,
    private readonly feedbackUpsert: UpsertInterviewFeedbackGQL,
    private readonly commentUpsert: UpsertInterviewCommentGQL,
    private readonly feedbackDelete: DeleteInterviewFeedbackGQL,
    private readonly commentDelete: DeleteInterviewCommentGQL
  ) {}

  get(candidateId: string, organizationId: string): Observable<CandidateInterviewsFeedback> {
    return this.feedbacksGet
      .fetch({ candidateId: candidateId, organizationId: organizationId })
      .pipe(map((x) => x.data.getCandidateInterviewIterations));
  }

  deleteIteration(
    candidateId: string,
    organizationId: string,
    feedbackId: string
  ): Observable<CandidateInterviewsFeedback> {
    const input = new CandidateInterviewIterationDeleteInput();
    input.candidateId = candidateId;
    input.organizationId = organizationId;
    input.interviewFeedbackId = feedbackId;

    return this.iterationDelete.mutate({ input: input }).pipe(map((x) => x.data.deleteInterviewIteration));
  }

  upsertEmployer(
    id: string,
    candidateId: string,
    organizationId: string,
    iterationId: string,
    employer: EmployerData
  ): Observable<CandidateInterviewsFeedback> {
    const input = new CandidateEmployerUpsertInput();
    input.id = id;
    input.candidateId = candidateId;
    input.organizationId = organizationId;
    input.feedbackIterationId = iterationId;
    input.employer = employer;

    return this.employerUpsert.mutate({ input: input }).pipe(map((x) => x.data.upsertInterviewEmployer));
  }

  upsertIteration(
    id: string,
    candidateId: string,
    organizationId: string,
    iterationId: string,
    data: FeedbackData | CommentData,
    type: string
  ): Observable<CandidateInterviewsFeedback> {
    if (type === "feedbacks") {
      const input = new CandidateFeedbackUpsertInput();
      input.id = id;
      input.candidateId = candidateId;
      input.organizationId = organizationId;
      input.feedbackIterationId = iterationId;
      input.feedback = data;

      return this.feedbackUpsert.mutate({ input: input }).pipe(map((x) => x.data.upsertInterviewFeedback));
    } else {
      const input = new CandidateCommentUpsertInput();
      input.id = id;
      input.candidateId = candidateId;
      input.organizationId = organizationId;
      input.feedbackIterationId = iterationId;
      input.comment = data;
      return this.commentUpsert.mutate({ input: input }).pipe(map((x) => x.data.upsertInterviewComment));
    }
  }

  deleteItem(
    id: string,
    organizationId: string,
    iterationId: string,
    itemId: string,
    type: string
  ): Observable<CandidateInterviewsFeedback> {
    if (type === "feedbacks") {
      const input = new CandidateFeedbackDeleteInput();
      input.id = id;
      input.organizationId = organizationId;
      input.iterationId = iterationId;
      input.feedbackId = itemId;

      return this.feedbackDelete.mutate({ input: input }).pipe(map((x) => x.data.deleteInterviewFeedback));
    } else {
      const input = new CandidateCommentDeleteInput();
      input.id = id;
      input.organizationId = organizationId;
      input.iterationId = iterationId;
      input.commentId = itemId;
      return this.commentDelete.mutate({ input: input }).pipe(map((x) => x.data.deleteInterviewComment));
    }
  }
}
