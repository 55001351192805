<form [formGroup]="form" (ngSubmit)="submit(form.value.collections, $event)" *appTranslate="let t">
  <p-card [header]="t('autoCollectionTemplate.attach.title')">
    <p-messages [(value)]="hints" [closable]="false"></p-messages>
    <div class="p-fluid">
      <div class="field">
        <label for="collections">{{ t("autoCollectionTemplate.attach.autoLists") }}</label>
        <p-multiSelect
          inputId="collections"
          [options]="collections"
          formControlName="collections"
          optionLabel="name"
          optionDisabled="disabled"
        ></p-multiSelect>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="flex flex-row justify-content-end">
        <p-button
          [disabled]="!form.valid || !form.dirty || disableSubmit || !(form.value.collections?.length > 0)"
          [label]="t('common.save')"
          icon="pi pi-plus"
          type="submit"
          class="mr-2"
        ></p-button>
        <p-button
          closeOnEscape
          [label]="t('common.cancel')"
          icon="pi pi-times"
          class="p-button-secondary"
          type="reset"
          (onClick)="close()"
        ></p-button>
      </div>
    </ng-template>
  </p-card>
</form>
