import { Directive, OnInit } from "@angular/core";
import { clone } from "lodash";
import { Splitter } from "primeng/splitter";
import { overrideHook } from "../../services/hackers";

@Directive({ selector: "p-splitter", standalone: false })
export class SplitterFixDirective implements OnInit {
  private restoreStateSource: Splitter["restoreState"];

  constructor(private readonly splitter: Splitter) {}

  ngOnInit(): void {
    this.restoreStateSource = this.splitter.restoreState;
    this.splitter.restoreState = (): boolean => this.restoreState(this.splitter);
  }

  private restoreState(splitter: Splitter): boolean {
    const value = splitter.getStorage().getItem(splitter.stateKey);
    if (!value || !JSON.parse(value)) {
      return false;
    }
    return this.restoreStateSource.call(splitter);
  }
}

// https://github.com/primefaces/primeng/issues/15940
// Basically, fixes `<ng-template pTemplate *ngIf="whatever">`.
overrideHook(Splitter, "ngAfterContentInit", function (this) {
  this.templates.changes.subscribe(() => {
    this.panels = this.templates.map((x) => x.template);

    // waiting for DOM to update
    this.cd.detectChanges();

    // updating sizes in DOM
    // this, obviously, fails if DOM was not updated in the previous step
    this.panelSizes = clone(this.panelSizes);

    this.cd.markForCheck();
  });
});
