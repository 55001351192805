import { Component, OnInit } from "@angular/core";
import { CollectionListFavoriteFragment, SharingTypeEnum } from "@ankaadia/graphql";
import { Observable, map, switchMap } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";
import { CollectionService } from "../collections/collection.service";
import { FavoriteService } from "../favorite/favorite.service";
import { OrganizationsService } from "../organizations/organizations.service";
import { CollectionId, Organization } from "./candidate-event.model";

@Component({
  selector: "app-candidate-events",
  templateUrl: "./candidate-events.component.html",
  standalone: false,
})
export class CandidateEventsComponent implements OnInit {
  protected selectedOrganization: string = null;
  protected selectedCollection: CollectionId;
  protected organizations$: Observable<Organization[]>;
  protected collections$: Observable<CollectionId[]>;

  get userOrganizationId(): string {
    return this.settingsService.organizationId;
  }

  constructor(
    private readonly settingsService: SettingsService,
    private readonly organizationService: OrganizationsService,
    private readonly collectionService: CollectionService,
    private readonly favoriteService: FavoriteService
  ) {}

  ngOnInit(): void {
    this.organizations$ = this.organizationService.getSelectableOrganizations([
      SharingTypeEnum.Collaboration,
      SharingTypeEnum.ReadOnlyCollaboration,
    ]);
  }

  protected selectOrganization(organization: string): void {
    this.selectedOrganization = organization;
    this.selectedCollection = null;
    this.updateCollections();
  }

  protected updateCollections(): void {
    this.collections$ = this.collectionService
      .getSelectableCollections(
        [SharingTypeEnum.Collaboration, SharingTypeEnum.ReadOnlyCollaboration],
        this.selectedOrganization,
        ["events"]
      )
      .pipe(
        map((collectionAndSharings) =>
          collectionAndSharings.map<CollectionId>((c) => ({
            name: c.collection.name,
            collectionId: c.collection.id,
            organizationId: c.collection.organizationId,
            sharing: c.sharing,
            isFavorite: false,
          }))
        ),
        switchMap((collections) => {
          const requests = collections.map((collection) => ({
            id: collection.collectionId,
            orgId: collection.organizationId,
          }));
          return this.favoriteService
            .areCollectionsFavorite(requests)
            .pipe(map((favorites) => ({ collections, favorites })));
        }),
        map(({ collections, favorites }) =>
          collections.map((collection) => ({
            ...collection,
            isFavorite: this.isFavorite(favorites, collection),
          }))
        )
      );
  }

  private isFavorite(favorites: CollectionListFavoriteFragment[], collection: CollectionId): boolean {
    return favorites.some((favorite) => this.collectionIsFavorite(favorite, collection));
  }

  private collectionIsFavorite(favorite: CollectionListFavoriteFragment, collection: CollectionId): unknown {
    return (
      favorite.collectionId === collection.collectionId &&
      favorite.collectionOrganizationId === collection.organizationId &&
      favorite.isFavorite
    );
  }
}
