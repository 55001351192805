import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  ensure4LetterIsoLanguage,
  ILocalizedString,
  supportedLanguageFlags4LetterISO,
  supportedLanguages,
} from "@ankaadia/ankaadia-shared";
import { StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { isEmpty, keyBy, noop } from "lodash";
import { StaticDataService } from "../../../shared/static-data/static-data.service";

@Component({
  selector: "app-localized-string",
  templateUrl: "./localized-string.component.html",
  styleUrl: "./localized-string.component.scss",
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: LocalizedStringComponent }],
  standalone: false,
})
export class LocalizedStringComponent implements ControlValueAccessor, OnInit, OnChanges {
  readonly flags = supportedLanguageFlags4LetterISO();
  private readonly allSupportedLanguages = keyBy(supportedLanguages(), (x) => x.code);
  private languageLabels: Record<string, string> = {};

  form: FormGroup;
  strings: FormArray;

  /**
   * `true` and `false` for some reason render different language selector
   * in text box and text area modes.
   *
   * `inline` and `dropdown` render the same language selector in both modes,
   * so that they look close to each other when you use both modes in a form.
   */
  @Input()
  showLanguageSelector: boolean | "inline" | "dropdown";

  @Input()
  selectedLanguage: string;

  @Input({ required: true })
  languages: string[] = [];

  @Input()
  header: string;

  @Input()
  maxLength: number;

  @Input()
  isRequired: boolean;

  @Input()
  requiredLanguages: string[] = [];

  @Input()
  rows = 1;

  /**
   * When set, only emits entries for those languages that have a value entered.
   *
   * Example:
   * ```ts
   * this.cleanModelSociety = false;
   * expect(this.form.value).toEqual([{ language: "en-GB", value: "something" }, { language: "de-DE", value: "" }]);
   *
   * this.cleanModelSociety = true;
   * expect(this.form.value).toEqual([{ language: "en-GB", value: "something" }]);
   * ```
   */
  @Input()
  cleanModelSociety: boolean;

  counter = 0;

  translatedLanguages: StaticDataModel[] = [];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly staticDataService: StaticDataService
  ) {
    this.strings = this.formBuilder.array([]);
    this.form = this.formBuilder.group({ strings: this.strings });
    this.strings.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      this.onTouched();
      this.notifyOfChange();
    });
  }

  ngOnInit(): void {
    this.staticDataService.getStaticData(StaticDataType.Languages, this.selectedLanguage).subscribe((languages) => {
      this.languageLabels = languages.reduce(
        (acc, item) => ({ ...acc, [ensure4LetterIsoLanguage(item.value)]: item.label }),
        {}
      );

      this.updateTranslatedLanguages();
    });
  }

  get requiredMissingLanguages(): string[] {
    const missingLanguages: string[] = this.strings?.value.filter((s) => !s.value).map((x) => x.language);

    return this.isRequired
      ? missingLanguages.map((language) => this.languageLabels[language])
      : missingLanguages
          .filter((language) => this.requiredLanguages.includes(language))
          .map((language) => this.languageLabels[language]);
  }

  get missingLanguages(): string[] {
    return this.strings?.value.filter((s) => !s.value).map((s) => this.languageLabels[s.language]) ?? [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.languages) {
      const oldData = keyBy(this.strings.value, "language");

      this.strings.clear();

      changes.languages.currentValue?.forEach((l: string) => {
        this.strings.push(
          this.formBuilder.group({
            language: [l, Validators.required],
            value: [oldData[l]?.value ?? "", this.getValidator(l)],
          })
        );
      });

      this.notifyOfChange();

      if (!this.selectedLanguage || !this.languages.find((l) => l === this.selectedLanguage)) {
        this.selectedLanguage = this.languages?.[0];
      }

      this.updateTranslatedLanguages();
    }
  }

  onChange = (_strings: ILocalizedString[]): void => noop();

  onTouched = (): void => noop();

  writeValue(strings: ILocalizedString[]): void {
    this.strings.controls.forEach((c) => {
      const language = c.value.language;
      const value = strings?.find((n) => n.language === language)?.value ?? "";
      c.get("value").setValue(value, { emitEvent: false });
    });
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled === this.form.disabled) return;

    if (disabled) this.form.disable();
    else this.form.enable();
  }

  private getValidator(language: string): ValidatorFn {
    return Validators.compose([
      this.isRequired || this.requiredLanguages.includes(language) ? Validators.required : Validators.nullValidator,
      this.maxLength ? Validators.maxLength(this.maxLength) : Validators.nullValidator,
    ]);
  }

  private updateTranslatedLanguages(): void {
    this.translatedLanguages =
      this.languages?.map((l) => ({
        value: l,
        label: this.languageLabels[l] ?? this.allSupportedLanguages[l].label,
      })) ?? [];
  }

  private notifyOfChange(): void {
    let value = <ILocalizedString[]>this.strings.value;
    if (this.cleanModelSociety) {
      value = value?.filter((x) => !isEmpty(x.value));
    }
    this.onChange(value);
  }
}
