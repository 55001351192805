import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { FavoriteComponent } from "./favorite.component";

@NgModule({
  imports: [SharedModule],
  declarations: [FavoriteComponent],
  exports: [FavoriteComponent],
})
export class FavoriteModule {}
