import { ChangeDetectorRef, Component, DestroyRef, Input, OnChanges, SimpleChanges } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl } from "@angular/forms";
import {
  EducationCourseForViewFragment,
  EducationExamFragment,
  EducationExamResultFullDataFragment,
} from "@ankaadia/graphql";
import { orderBy } from "lodash";
import { catchError, Observable, of, startWith, switchMap } from "rxjs";
import { EducationExamResultService } from "../../education-exam-results/education-exam-results.service";
import { EducationExamService } from "../../education-exams/education-exam.service";

@Component({
  selector: "app-education-course-exam-results",
  templateUrl: "./education-course-exam-results.component.html",
  standalone: false,
})
export class EducationCourseExamResultsComponent implements OnChanges {
  @Input({ required: true })
  course: EducationCourseForViewFragment;

  exams: EducationExamFragment[] = [];

  protected readonly selectedExam = new FormControl<EducationExamFragment>(undefined);

  protected readonly examResults$ = this.selectedExam.valueChanges.pipe(
    startWith(this.selectedExam.value),
    switchMap((exam) => this.getExamResults(exam))
  );

  constructor(
    private readonly examService: EducationExamService,
    private readonly examResultService: EducationExamResultService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.course) {
      if (this.course)
        this.examService
          .getByCourseId(this.course.id, this.course.organizationId)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((exams) => {
            this.exams = orderBy(exams, (x) => x.examDateAndTime?.date, "desc");

            if (this.exams.length === 1) this.selectedExam.setValue(this.exams[0]);
            this.changeDetectorRef.detectChanges();
          });
      else this.exams = [];
    }
  }

  private getExamResults(exam: EducationExamFragment): Observable<EducationExamResultFullDataFragment[]> {
    if (!exam) return of([]);

    return this.examResultService
      .getByExamId({ examId: exam.id, organizationId: exam.organizationId })
      .pipe(catchError(() => of([])));
  }
}
