import { Injectable } from "@angular/core";
import { DetectZipCodeGQL, GeoZipCodeFragment } from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class GeographyService {
  constructor(private readonly zipcodeDetect: DetectZipCodeGQL) {}

  detectZipCode(country: string, zipcode: string): Observable<GeoZipCodeFragment> {
    return this.zipcodeDetect.fetch({ input: { country, zipcode } }).pipe(map((x) => x.data.detectZipCode));
  }
}
