import { Injectable } from "@angular/core";
import {
  CandidateTagDescriptionFragment,
  CandidateTagFragment,
  GetCandidateTagDescriptionsGQL,
  GetCandidateTagsGQL,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class CandidateTagService {
  constructor(
    private readonly descriptions: GetCandidateTagDescriptionsGQL,
    private readonly tags: GetCandidateTagsGQL
  ) {}

  getDescriptions(): Observable<CandidateTagDescriptionFragment[]> {
    return this.descriptions.fetch().pipe(map((x) => x.data.getCandidateTagDescriptions));
  }

  getTags(
    candidateId: string,
    organizationId: string,
    sharingId: string,
    sharingOrganizationId: string
  ): Observable<CandidateTagFragment[]> {
    return this.tags
      .fetch({ input: { candidateId, organizationId, sharingId, sharingOrganizationId } })
      .pipe(map((x) => x.data.getCandidateTags));
  }
}
