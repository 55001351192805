import { isTechnicallyEmpty, KeyOf, Never, OverrideInfo } from "@ankaadia/ankaadia-shared";
import {
  ContractTemplate,
  ContractTemplateAllowance,
  ContractTemplateBenefit,
  ContractTemplateSetInput,
} from "@ankaadia/graphql";
import { FormElementGroup } from "ngx-mf";
import { GraphQLFormModel } from "../../../shared/services/form.helper";

export type CleanContractTemplateAllowance = ContractTemplateAllowance &
  Never<Pick<FormContractTemplateAllowance, KeyOf<OverrideInfo>>>;

export type CleanContractTemplateBenefit = ContractTemplateBenefit &
  Never<Pick<FormContractTemplateBenefit, KeyOf<OverrideInfo>>>;

export type CleanContractTemplateSetInput = Omit<ContractTemplateSetInput, "allowances" | "benefits"> &
  Never<Pick<FormContractTemplate, "changedBy" | "changedAt" | "unavailableNames">> & {
    allowances?: CleanContractTemplateAllowance[];
    benefits?: CleanContractTemplateBenefit[];
  };

export type FormContractTemplateAllowance = ContractTemplateAllowance & OverrideInfo;
export type FormContractTemplateBenefit = ContractTemplateBenefit & OverrideInfo;
export type FormContractTemplate = Omit<Partial<ContractTemplate>, "allowances" | "benefits"> & {
  unavailableNames?: string[];
  allowances?: FormContractTemplateAllowance[];
  benefits?: FormContractTemplateBenefit[];
};

export type ContractTemplateForm = GraphQLFormModel<
  FormContractTemplate,
  {
    laborAgreementSelection: FormElementGroup;
    customLaborAgreement: FormElementGroup;
    allowances: [FormElementGroup];
    benefits: [FormElementGroup];
  }
>;

export type LaborAgreementSelectionForm = ContractTemplateForm["controls"]["laborAgreementSelection"];
export type CustomLaborAgreementForm = ContractTemplateForm["controls"]["customLaborAgreement"];
export type ContractTemplateAllowanceForm = ContractTemplateForm["controls"]["allowances"]["controls"][number];
export type ContractTemplateBenefitForm = ContractTemplateForm["controls"]["benefits"]["controls"][number];

export function toCleanContractTemplate(contractTemplate: FormContractTemplate): CleanContractTemplateSetInput {
  const {
    changedBy: _1,
    changedAt: _2,
    unavailableNames: _3,
    canBeDeleted: _4,
    allowances,
    benefits,
    ...cleaned
  } = contractTemplate;

  if (isTechnicallyEmpty(cleaned.laborAgreementSelection)) {
    delete cleaned.laborAgreementSelection;
  } else {
    delete cleaned.customLaborAgreement;
  }

  return {
    ...cleaned,
    name: cleaned.name,
    organizationId: cleaned.organizationId,
    allowances: allowances?.map(({ isOverride: _1, isIgnored: _2, ...allowance }) => allowance),
    benefits: benefits?.map(({ isOverride: _1, isIgnored: _2, ...benefit }) => benefit),
  };
}
