import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { startWith } from "rxjs";
import { StaticDataContextEntry, StaticDataService } from "../../../../../../shared/static-data/static-data.service";
import { CertificateValuationAuthorityService } from "../../../../../certificate-valuation-authorities/certificate-valuation-authorities.service";
import { QualificationEvaluationsFormDe } from "../../../../candidate-form.model";

@Component({
  selector: "app-candidate-qualification-evaluations",
  templateUrl: "./candidate-qualification-evaluations.component.html",
  standalone: false,
})
export class CandidateQualificationEvaluationsComponent implements OnInit {
  private readonly language = this.transloco.getActiveLang();
  protected readonly evaluationNotices$ = this.staticDataService.getStaticData(
    StaticDataType.QualificationEvaluationNoticeKind,
    this.language
  );

  constructor(
    private readonly transloco: TranslocoService,
    private readonly staticDataService: StaticDataService,
    private readonly certificateValuationAuthorityService: CertificateValuationAuthorityService,
    private readonly destroyRef: DestroyRef
  ) {}

  @Input()
  form: QualificationEvaluationsFormDe;

  @Input({ required: true })
  candidateId: string;

  @Input({ required: true })
  candidateOrganizationId: string;

  @Input({ required: true })
  evaluationsDisabled: boolean;

  @Input({ required: true })
  staticDataContext: StaticDataContextEntry;

  @Input({ required: true })
  index: number;

  @Input()
  readonly = false;

  @Output()
  readonly removeEvaluation = new EventEmitter<number>();

  certificateValuationAuthorities: StaticDataModel[];

  ngOnInit(): void {
    this.form.controls.federalState.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), startWith(this.form.controls.federalState.value))
      .subscribe((state) => {
        const federalStates = state == null ? null : [state];
        this.certificateValuationAuthorityService
          .getCertificateValuationAuthorities(this.staticDataContext.immigrationCountry, federalStates)
          .subscribe((data) => {
            const value = this.form.controls.responsibleAuthorityForQualification.value;
            this.certificateValuationAuthorities = data;
            const newValue = data.length === 1 ? data[0].value : value;
            this.form.controls.responsibleAuthorityForQualification.setValue(newValue);
          });
      });
  }
}
