<ng-container *appTranslate="let t">
  <form *ngIf="form" class="app-form" [formGroup]="form" (ngSubmit)="save()">
    <p-card styleClass="vz-card" [header]="t('systemSettings.title')">
      <div class="ml-3">
        <div class="field grid">
          <p-checkbox
            class="w-300"
            [label]="t('systemSettings.isSystemLogoutEnabled')"
            inputId="isSystemLogoutEnabled"
            [binary]="true"
            formControlName="isSystemLogoutEnabled"
          ></p-checkbox>
          <div class="col">
            <p-inputNumber
              class="-mt-3"
              [readonly]="!form?.value?.isSystemLogoutEnabled"
              id="logoutPeriodMinutes"
              formControlName="logoutPeriodMinutes"
              [min]="15"
              [suffix]="' ' + t('systemSettings.logoutPeriodMinutes')"
            />
          </div>
        </div>

        <div class="field grid mt-4">
          <label class="w-300" for="accessKey">{{ t("systemSettings.apiAccessKey") }}</label>
          <div class="col">
            <p-password
              id="accessKey"
              [formControl]="apiAccessKeyControl"
              [toggleMask]="true"
              [feedback]="false"
            ></p-password>
            <p-button
              icon="pi pi-copy"
              class="ml-1"
              styleClass="p-button-text"
              [pTooltip]="t('systemSettings.copyClipboard')"
              (onClick)="copyToClipboard()"
            ></p-button>
            <p-button
              icon="pi pi-replay"
              class="ml-1"
              styleClass="p-button-text"
              [pTooltip]="t('systemSettings.regenerateApiKey')"
              (onClick)="generateAPIKey($event)"
            ></p-button>
            <a
              href="https://api.ankaadia.com/api/public/v2/swagger"
              target="_blank"
              class="ml-1"
              [pTooltip]="t('systemSettings.linktoApiDocumentation')"
            >
              <i class="pi pi-fw pi-question-circle"></i>
            </a>
          </div>
        </div>

        <div class="field grid mt-4">
          <label class="w-300" for="requiredCandidateFields">{{ t("requiredCandidateFields.title") }}</label>
          <div class="col">
            <p-multiSelect
              styleClass="required-candidate-fields"
              inputId="requiredCandidateFields"
              [options]="candidateFields$ | async"
              [placeholder]="t('requiredCandidateFields.placeholder')"
              formControlName="requiredCandidateFields"
            ></p-multiSelect>
          </div>
        </div>
        <div class="field grid mt-4">
          <p-checkbox
            class="w-300"
            [label]="t('automaticRejectionEmailsEnabled.title')"
            inputId="automaticRejectionEmailsEnabled"
            [binary]="true"
            formControlName="automaticRejectionEmailsEnabled"
          ></p-checkbox>
        </div>
        <div class="field grid mt-4">
          <label class="w-300" for="workingExperiencePrecision">{{ t("workingExperiencePrecision.title") }}</label>
          <div class="col">
            <p-dropdown
              styleClass="required-candidate-fields"
              inputId="workingExperiencePrecision"
              [options]="workingExperiencePrecisionsOptions"
              [placeholder]="t('workingExperiencePrecision.placeholder')"
              formControlName="precisionOfWorkexperience"
            ></p-dropdown>
          </div>
        </div>
        <div class="field grid mt-4">
          <label class="w-300" for="educationPrecision">{{ t("educationPrecision.title") }}</label>
          <div class="col">
            <p-dropdown
              styleClass="required-candidate-fields"
              inputId="educationPrecision"
              [options]="workingExperiencePrecisionsOptions"
              [placeholder]="t('educationPrecision.placeholder')"
              formControlName="precisionOfEducation"
            ></p-dropdown>
          </div>
        </div>
        <div class="field grid mt-4">
          <label class="w-300" for="supportedImmigrationCountries">{{
            t("systemSettings.supportedImmigrationCountries")
          }}</label>
          <div class="col">
            <p-multiSelect
              styleClass="required-candidate-fields"
              inputId="supportedImmigrationCountries"
              [options]="supportedCountries"
              [placeholder]="t('country.placeholder')"
              formControlName="supportedImmigrationCountries"
              (onChange)="updateStandardCountries($event.value)"
            ></p-multiSelect>
          </div>
        </div>
        <div class="field grid mt-4">
          <label class="w-300" for="standardImmigrationCountry">{{
            t("systemSettings.standardImmigrationCountry")
          }}</label>
          <div class="col">
            <p-dropdown
              styleClass="required-candidate-fields"
              inputId="standardImmigrationCountry"
              [options]="standardCountries"
              [placeholder]="t('country.placeholder')"
              formControlName="standardImmigrationCountry"
            ></p-dropdown>
          </div>
        </div>
        <div class="field grid mt-4">
          <p-checkbox
            class="w-300"
            [label]="t('candidateTags.title')"
            inputId="enableCustomCandidateTags"
            [binary]="true"
            formControlName="enableCustomCandidateTags"
          ></p-checkbox>
          <div class="col">
            <app-candidate-tag-selector
              styleClass="required-candidate-fields"
              inputId="candidateTags"
              [readonly]="!form.controls.enableCustomCandidateTags.value"
              formControlName="candidateTags"
            ></app-candidate-tag-selector>
          </div>
        </div>
        <div class="field grid mt-4">
          <p-checkbox
            [label]="t('systemSettings.isPubliclyVisible')"
            inputId="isPubliclyVisible"
            [binary]="true"
            formControlName="isPubliclyVisible"
          ></p-checkbox>
        </div>
        <div class="field grid mt-4">
          <p-checkbox
            [label]="t('systemSettings.removeCandidatesFromAllListsIfDropout')"
            inputId="removeCandidatesFromAllListsIfDropout"
            [binary]="true"
            formControlName="removeCandidatesFromAllListsIfDropout"
          ></p-checkbox>
        </div>
      </div>
      <p-fieldset [legend]="t('systemSettings.deletionSettings')">
        <div formArrayName="candidateDeletionSetting">
          <div
            class="field grid ml-2"
            *ngFor="let delSetting of form.controls.candidateDeletionSetting.controls; index as i"
            [formGroupName]="i"
          >
            <div class="w-300 mt-4">
              <label>{{
                t($any("enum.CandidateStatus." + delSetting.controls.candidateStatusForDeletion.value))
              }}</label>
            </div>
            <div class="col mt-4">
              <p-checkbox
                [label]="t('enabled.title')"
                formControlName="isEnabled"
                class="mx-3 mb-2"
                [binary]="true"
                (click)="toggleDeletion(i)"
              >
              </p-checkbox>
              <p-inputNumber
                class="-mt-3"
                formControlName="daysAfterDeletion"
                [min]="180"
                [suffix]="' ' + t('systemSettings.days')"
              />
              <!-- <label class="ml-4">{{ delSetting.controls.daysAfterDeletion.value }}</label> -->
            </div>
          </div>
        </div>
      </p-fieldset>
      <p-fieldset [legend]="t('systemSettings.fileSettings')">
        <p-checkbox
          class="w-300"
          [label]="t('systemSettings.fileNameWithCandidateId')"
          inputId="fileNameWithCandidateId"
          [binary]="true"
          formControlName="fileNameWithCandidateId"
        ></p-checkbox>
        <p-checkbox
          class="w-300"
          [label]="t('systemSettings.fileNameWithFileFormat')"
          inputId="fileNameWithFileFormat"
          [binary]="true"
          formControlName="fileNameWithFileFormat"
        ></p-checkbox>
      </p-fieldset>
      <div class="field grid mt-4" *ngIf="settings.isMasterUser">
        <p-button
          [label]="t('processes.export')"
          icon="pi pi-file-excel"
          type="submit"
          class="mx-2 mb-3"
          (onClick)="$event.preventDefault(); generateAndDownloadTemplate(); $event.stopPropagation()"
        ></p-button>
      </div>

      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
          <p-button
            [disabled]="
              !form.valid ||
              !form.dirty ||
              (form.value.isSystemLogoutEnabled && form.value.logoutPeriodMinutes == 0) ||
              submitDisabled
            "
            [label]="t('common.save')"
            icon="pi pi-check"
            type="submit"
            class="mr-2"
            data-testid="btn-save-settings"
          ></p-button>
          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            (onClick)="cancel()"
            data-testid="btn-cancel-settings"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  </form>
</ng-container>
