<ng-container *appTranslate="let t">
  <p-card [header]="t('educationExams.addToExam.title')">
    <div class="p-fluid formgrid">
      <p-fieldset [toggleable]="true">
        <ng-template pTemplate="header">
          <span class="pi pi-filter"></span>
        </ng-template>

        <div class="field">
          <label for="examType"
            >{{ t("examStatus.title") }} <i class="ml-1 pi pi-info-circle" [pTooltip]="t('examStatus.tooltip')"></i
          ></label>
          <p-dropdown
            inputId="examType"
            [formControl]="selectedStatus"
            [options]="examStatus"
            [placeholder]="t('examStatus.placeholder')"
            [showClear]="true"
          />
        </div>

        <div class="field">
          <label for="examType">{{ t("examType.title") }}</label>
          <p-dropdown
            inputId="examType"
            [formControl]="selectedExamType"
            [options]="examTypes"
            [placeholder]="t('examType.placeholder')"
            [showClear]="true"
          />
        </div>

        <div class="field">
          <label for="examInstitution">{{ t("examInstitution.title") }}</label>
          <p-dropdown
            inputId="examInstitution"
            [formControl]="selectedExamInstitution"
            [options]="examInstitutions$ | async"
            [placeholder]="t('examInstitution.placeholder')"
            [showClear]="true"
          />
        </div>

        <div class="field">
          <label></label>

          <p-button
            [label]="t('educationExams.addToExam.clearFilters')"
            icon="pi pi-trash"
            [disabled]="areAllFiltersDeSelected"
            class="mr-2"
            (onClick)="clearAllFilters()"
          ></p-button>
        </div>
      </p-fieldset>

      <div class="field mt-5">
        <label for="name">{{ t("educationExam.title") }}</label>
        <p-dropdown
          inputId="relation"
          [(ngModel)]="exam"
          [options]="options$ | async"
          [placeholder]="t('educationExam.placeholder')"
          filterBy="filterText"
          optionLabel="name"
        >
          <ng-template pTemplate="selectedItem">
            <ng-container
              *ngTemplateOutlet="
                shownData;
                context: {
                  exam: exam,
                }
              "
            ></ng-container>
          </ng-template>

          <ng-template let-exam pTemplate="item">
            <ng-container
              *ngTemplateOutlet="
                shownData;
                context: {
                  exam: exam,
                }
              "
            ></ng-container>
          </ng-template>
        </p-dropdown>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <app-edit-tool-bar
        class="flex flex-row justify-content-end"
        [showBtn]="{ cancel: true, save: true }"
        [saveBtn]="{ disabled: !exam }"
        (canceled)="cancel()"
        (saved)="save()"
      ></app-edit-tool-bar>
    </ng-template>
  </p-card>
</ng-container>

<ng-template #shownData let-exam="exam">
  <div>
    {{ exam.name }}

    <ng-container
      *ngTemplateOutlet="
        infoData;
        context: {
          data: exam.examDate,
          icon: 'pi pi-calendar',
        }
      "
    />

    <ng-container *ngTemplateOutlet="infoData; context: { data: exam.examLocation, icon: 'pi pi-map-marker' }" />
  </div>
</ng-template>

<ng-template #infoData let-data="data" let-icon="icon">
  <p-chip *ngIf="data" label="{{ data }}" icon="{{ icon }}" class="ml-1 small-chip" />
</ng-template>
