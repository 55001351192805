import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { LanguageSelectorComponent } from "./language-selector/language-selector.component";
import { LocalizedRichTextComponent } from "./localized-rich-text/localized-rich-text.component";
import { LocalizedStringComponent } from "./localized-string/localized-string.component";
import { LanguageDropdownComponent } from "./language-dropdown/language-dropdown.component";

@NgModule({
  imports: [SharedModule],
  declarations: [
    LanguageSelectorComponent,
    LocalizedStringComponent,
    LocalizedRichTextComponent,
    LanguageDropdownComponent,
  ],
  exports: [LanguageSelectorComponent, LocalizedStringComponent, LocalizedRichTextComponent, LanguageDropdownComponent],
})
export class LanguageModule {}
