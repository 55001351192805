import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LicensePrice, SupportedBillingCurrency } from "@ankaadia/graphql";
import { PriceComponent } from "../price/price.component";
import { CommonModule } from "@angular/common";
import { TranslateDirective } from "../../../../../shared/transloco/translate.directive";

@Component({
  standalone: true,
  imports: [CommonModule, PriceComponent, TranslateDirective],
  selector: "app-plan-license-price-summary",
  templateUrl: "./plan-license-price-summary.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanLicensePriceSummaryComponent {
  @Input({ required: true })
  price: LicensePrice;

  @Input({ required: true })
  currency: SupportedBillingCurrency;
}
