<ng-container *appTranslate="let t">
  <form [formGroup]="form">
    <app-candidate-responsible-representative-group
      [form]="form"
      [readonly]="readonly"
      [candidateOrganizationId]="candidateOrganizationId"
      [candidateId]="candidateId"
      [canHaveADeputyOrRepresentative]="canHaveADeputyOrRepresentative"
      [responsibleRolePurpose]="ResponsiblePurpose.Recognition"
      [purposeData]="recognitionPath"
      [alwaysShownRoles]="recognitionRoles"
      styleRoleClass="field col-12 lg:col-{{ canHaveADeputyOrRepresentative ? 3 : 6 }}"
      styleOrganizationClass="field col-12 lg:col-3"
      styleRepresentativeClass="field col-12 lg:col-3"
      styleDeputyClass="field col-12 lg:col-3"
      class="p-fluid formgrid grid"
    ></app-candidate-responsible-representative-group>

    <div class="p-fluid formgrid grid">
      <div class="field col-12 lg:col-3">
        <app-candidate-target-recognition
          [form]="form"
          class="field"
          [immigrationCountry]="staticDataContext.immigrationCountry"
          [candidateOrganizationId]="candidateOrganizationId"
          [readonly]="readonly"
        ></app-candidate-target-recognition>
      </div>

      <div class="field col-12 lg:col-3">
        <app-candidate-path-of-recognition
          [form]="form"
          [staticDataContext]="staticDataContext"
          [renderLabelUsingAlignmentCheckBox]="true"
          [readonly]="readonly"
          class="field"
        ></app-candidate-path-of-recognition>
      </div>

      <div class="field col-12 lg:col-3">
        <app-candidate-federal-state
          [form]="form"
          [staticDataContext]="staticDataContext"
          [hidden]="!isUniversity && !isGovernment"
          [readonly]="readonly"
          class="field"
        ></app-candidate-federal-state>
      </div>

      <div class="field col-12 lg:col-3">
        <app-candidate-recognition-authority
          class="field"
          [form]="form"
          [federalStateControl]="federalState?.control"
          [hideControl]="!isUniversity"
          [readonly]="readonly || !isUniversity"
          [staticDataContext]="staticDataContext"
        ></app-candidate-recognition-authority>

        <app-candidate-legal-basis
          [form]="form"
          [readonly]="readonly || !isGovernment"
          [hideControl]="!isGovernment"
          [isRelevant]="isGovernment"
          class="field"
          [immigrationCountry]="staticDataContext.immigrationCountry"
          [candidateOrganizationId]="candidateOrganizationId"
        ></app-candidate-legal-basis>
      </div>

      <div class="field col-12 lg:col-3">
        <div class="field-checkbox">
          <p-checkbox
            inputId="{{ formId }}-recognitionStarted"
            [binary]="true"
            [readonly]="readonly"
            formControlName="recognitionStarted"
          ></p-checkbox>
          <label for="{{ formId }}-recognitionStarted">{{ t("recognitionStarted.title") }}</label>
        </div>

        <p-calendar formControlName="recognitionStartDate" [maxDate]="today" [readonly]="readonly"></p-calendar>
      </div>

      <app-candidate-document-date
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [label]="t('recognition.application.arrived')"
        [readonly]="readonly"
        documentType="CONFACCEPTAGENCY"
        selectedSet="Default"
        dateField="issueDate"
        class="field col-12 lg:col-6"
      ></app-candidate-document-date>

      <div class="field col-12 lg:col-3">
        <app-candidate-recognition-file-number
          [form]="form"
          [readonly]="readonly"
        ></app-candidate-recognition-file-number>
      </div>

      <div class="field col-12 lg:col-3">
        <div class="field-checkbox">
          <p-checkbox
            inputId="{{ formId }}-recognitionReceived"
            [binary]="true"
            [readonly]="readonly"
            formControlName="recognitionReceived"
          ></p-checkbox>
          <label for="{{ formId }}-recognitionReceived">{{ t("recognitionReceived.title") }}</label>
        </div>

        <p-calendar formControlName="recognitionReceiveDate" [maxDate]="today" [readonly]="readonly"></p-calendar>
      </div>
    </div>
  </form>
</ng-container>
