<div class="p-fluid" *appTranslate="let t">
  <div class="field">
    <div class="flex">
      <textarea
        #box
        commentBox
        class="comment-box noresize"
        rows="1"
        [disabled]="readonly"
        pInputTextarea
        [placeholder]="t('comment.title')"
      ></textarea>
      <button
        *ngIf="!readonly"
        type="button"
        icon="pi pi-play"
        class="p-button-outlined align-self-end"
        pButton
        (click)="addComment(box.value); box.value = ''; box.style.height = 'auto'"
      ></button>
      <button
        *ngIf="feedbackData.id && !readonly"
        type="button"
        icon="pi pi-refresh"
        class="p-button-outlined align-self-end"
        pButton
        (click)="refreshComments()"
      ></button>
    </div>
  </div>
</div>

<div class="p-fluid comments">
  <div class="field" *ngFor="let comment of feedbackData.comments">
    <app-candidate-comment-box
      [comment]="comment"
      [readonly]="readonly"
      (commentChange)="updateComment($event)"
    ></app-candidate-comment-box>
  </div>
</div>
