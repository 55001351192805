import { Component, Input } from "@angular/core";
import { getAllComplementaryMeasureRoles } from "@ankaadia/ankaadia-shared";
import { SupportedImmigrationCountry } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { ResponsibleRolePurpose } from "../../../../candidate-reusables/candidate-responsible-representative-group/candidate-responsible-representative-group.service";
import { QualificationMeasureFormAt } from "../../../candidate-form.model";

@Component({
  selector: "app-candidate-qualification-measure-at",
  templateUrl: "./candidate-qualification-measure-at.component.html",
  standalone: false,
})
export class CandidateQualificationMeasureATComponent {
  readonly ImmigrationCountry = SupportedImmigrationCountry.At;
  readonly ResponsibleRolePurposeType = ResponsibleRolePurpose;
  readonly passedOptions = [
    { value: true, label: translate("passed.title") },
    { value: false, label: translate("failed.title") },
  ];

  @Input()
  form: QualificationMeasureFormAt;

  @Input()
  candidateId: string;

  @Input()
  candidateOrganizationId: string;

  @Input()
  readonly: boolean;

  protected readonly responsibleRoles = getAllComplementaryMeasureRoles();
}
