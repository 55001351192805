import { Injectable } from "@angular/core";
import {
  GenerateFeedbackReportGQL,
  GenerateProcessStatusReportGQL,
  GenerateProcessTaskListReportGQL,
  GenerateSummaryReportOfAllProcessesGQL,
  ProcessReportResult,
} from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { map, Observable } from "rxjs";
import { DownloadService } from "../../shared/services/download.service";

@Injectable({ providedIn: "root" })
export class ReportService {
  readonly language = this.transloco.getActiveLang();

  constructor(
    private readonly feedbackReport: GenerateFeedbackReportGQL,
    private readonly processesStatusReport: GenerateSummaryReportOfAllProcessesGQL,
    private readonly processStatusReport: GenerateProcessStatusReportGQL,
    private readonly transloco: TranslocoService,
    private readonly downloadService: DownloadService,
    private readonly taskListReport: GenerateProcessTaskListReportGQL
  ) {}

  generateAndDownloadFeedbackReport(sharingId: string, organizationId: string): void {
    this.feedbackReport
      .fetch({
        input: {
          organizationId: organizationId,
          sharingId: sharingId,
          language: this.language,
        },
      })
      .subscribe((x) =>
        this.downloadService.downloadFile(
          x.data.generateFeedbackReport.filename,
          x.data.generateFeedbackReport.urlToReport
        )
      );
  }

  generateProcessStatusReport(
    organizationId: string,
    processId: string,
    candidateId?: string,
    processLanguage?: string
  ): Observable<ProcessReportResult> {
    return this.processStatusReport
      .fetch({
        input: {
          organizationId: organizationId,
          processId: processId,
          candidateId: candidateId,
          language: processLanguage ?? this.language,
        },
      })
      .pipe(map((x) => x.data.generateProcessStatusReport));
  }

  generateProcessTaskListReport(
    organizationId: string,
    includeHistory: boolean,
    processLanguage: string
  ): Observable<ProcessReportResult> {
    return this.taskListReport
      .fetch({
        input: {
          organizationId: organizationId,
          language: processLanguage ?? this.language,
          includeHistory: includeHistory,
        },
      })
      .pipe(map((x) => x.data.generateProcessTaskListReport));
  }

  generateProcessesStatusReport(organizationId: string): Observable<ProcessReportResult> {
    return this.processesStatusReport
      .fetch({
        input: { organizationId: organizationId, language: this.language },
      })
      .pipe(map((x) => x.data.generateSummaryReportOfAllProcesses));
  }
}
