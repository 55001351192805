<ng-container *appTranslate="let t">
  <form [formGroup]="form">
    <p-fieldset [legend]="t('currentRecognitionPath.title')">
      <app-candidate-document-upload
        [fileTypes]="['RECDOC', 'REJECTREC', 'DEFICITNOTE', 'ACCEPTDOC', 'CONFACCEPTAGENCY', 'CONFIMMIGRAGENCY', 'CURRICULUMFRAMEWORK']"
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
        class="mt-3"
      ></app-candidate-document-upload>

      <app-candidate-recognition-path-entry-de
        [form]="form.controls.current"
        [candidateId]="candidateId"
        [candidateProfession]="candidateProfession"
        [candidateOrganizationId]="candidateOrganizationId"
        [recognitionPath]="currentRecognitionPath"
        documentSetType="CurrentRecognitionPath"
        [readonly]="readonly"
      ></app-candidate-recognition-path-entry-de>
    </p-fieldset>

    <p-fieldset [legend]="t('alternativeRecognitionPath.title')">
      <app-candidate-document-upload
        [fileTypes]="['RECDOC', 'REJECTREC', 'DEFICITNOTE', 'ACCEPTDOC', 'CONFACCEPTAGENCY', 'CONFIMMIGRAGENCY', 'CURRICULUMFRAMEWORK']"
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
        class="mt-3"
      ></app-candidate-document-upload>

      <app-candidate-recognition-path-entry-de
        [form]="form.controls.alternative"
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateProfession]="candidateProfession"
        [recognitionPath]="alternativeRecognitionPath"
        documentSetType="AlternativeRecognitionPath"
        [readonly]="readonly"
      ></app-candidate-recognition-path-entry-de>
    </p-fieldset>
  </form>
</ng-container>
