import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import {
  OrganizationProfileDescription,
  OrganizationProfileFields,
  OrganizationProfileFragment,
  UserGroup,
} from "@ankaadia/graphql";
import { SettingsService } from "../../../shared/services/settings.service";
import { UserGroupsService } from "../../user-groups/user-groups.service";

@Component({
  selector: "app-organization-profile",
  templateUrl: "./organization-profile.component.html",
  styleUrl: "./organization-profile.component.scss",
  standalone: false,
})
export class OrganizationProfileComponent implements OnInit {
  @Input()
  profile: OrganizationProfileFragment;

  @Input()
  fields: OrganizationProfileFields;

  @Input()
  title: string;

  @Input()
  disableSubmit: boolean;

  @Input()
  displayBackButton: boolean;

  @Output()
  readonly saved = new EventEmitter<OrganizationProfileFragment>();

  @Output()
  readonly canceled = new EventEmitter<void>();

  userGroups: Partial<UserGroup>[];
  form: FormGroup;
  description: FormArray;
  locations: FormArray;

  get myOrganizationId(): string {
    return this.settings.organizationId;
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly userGroupService: UserGroupsService,
    private readonly settings: SettingsService
  ) {}

  ngOnInit(): void {
    this.description = this.formBuilder.array([], this.descriptionValidator.bind(this));
    this.locations = this.formBuilder.array([], Validators.required);
    this.form = this.formBuilder.group({
      id: [this.profile.id],
      _etag: [this.profile._etag],
      organizationId: [this.profile.organizationId],
      images: [this.profile.images ?? []],
      description: this.description,
      locations: this.locations,
      contactUserGroups: [this.profile.contactUserGroups ?? []],
      isMatchingEnabled: [this.profile.isMatchingEnabled ?? false],
    });

    this.userGroupService.getAllUserGroups(this.profile.organizationId).subscribe((x) => {
      this.userGroups = x;
      if (!this.form.controls.contactUserGroups.value?.length) {
        this.form.controls.contactUserGroups.setValue([this.getAllUsersGroupId()]);
      }
    });
  }

  updateUserGroupSelection(userGroupIds: string[], toggledUserGroupId: string): void {
    const allUsersGroupId = this.getAllUsersGroupId();
    if (toggledUserGroupId === allUsersGroupId || userGroupIds.length === 0) {
      this.form.controls.contactUserGroups.setValue([allUsersGroupId]);
    } else {
      this.form.controls.contactUserGroups.setValue(userGroupIds.filter((id) => id !== allUsersGroupId));
    }
  }

  submit(): void {
    const value = this.form.value as OrganizationProfileFragment;
    value.description = value.description.filter((d) => d.contents);
    this.saved.emit(value);
  }

  private descriptionValidator(control: AbstractControl<OrganizationProfileDescription[]>): ValidationErrors | null {
    if (!control.value || control.value.every((x) => !x.contents?.length)) {
      return { required: true };
    }
    return null;
  }

  private getAllUsersGroupId(): string {
    return this.userGroups?.[0]?.id;
  }
}
