import { Injectable } from "@angular/core";
import {
  CandidateProfileMaskSetId,
  ExperienceType,
  getCityState,
  ICandidateOSModel,
  ICustomFieldsModel,
  IExperienceModel,
  IFurtherEducation,
  MedHealthCareCoreProfessions,
  Property,
} from "@ankaadia/ankaadia-shared";
import { CandidateProfileConfigTab, CandidateStatus, ProfileDataEditStatus, TabField } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { FormlyFieldConfig, FormlyFieldProps } from "@ngx-formly/core";
import { flatMapDeep, noop } from "lodash";
import { forkJoin, map, Observable, of, tap } from "rxjs";
import { TemplateOptionsService } from "../../formly/template-options/template-options.service";
import { CandidateProfileTab, OrganizationSpecific } from "../../organization-specific/organization-specific";
import { OrganizationFactoryService } from "../../organization-specific/organization-specific-factory.service";
import { IgnoredPropertyValues } from "../../shared/services/form-data-initialization-guard";
import { SettingsService } from "../../shared/services/settings.service";
import { CandidateProfileConfigService } from "../candidate-profile-config/candidate-profile-config.service";
import { OrganizationSystemSettingsService, OwnerSettings } from "../organizations/system-settings.service";

@Injectable({ providedIn: "root" })
export class CandidateProfileService {
  constructor(
    private readonly settings: SettingsService,
    private readonly specificsFactory: OrganizationFactoryService,
    private readonly systemSettingsService: OrganizationSystemSettingsService,
    private readonly templateOptionsService: TemplateOptionsService,
    private readonly candidateProfileConfigService: CandidateProfileConfigService
  ) {}

  getTabs(
    candidateStatus?: CandidateStatus,
    candidateFunction?: string
  ): Observable<[CandidateProfileTab[], FormlyFieldConfig[]]> {
    return forkJoin([
      this.specificsFactory.getOrganizationSpecifics(this.settings.organizationId),
      this.systemSettingsService.getCandidateOwnerSpecificSettings(this.settings.organizationId),
      candidateStatus && candidateFunction
        ? this.candidateProfileConfigService
            .getBySetIdStatusAndFunction(
              CandidateProfileMaskSetId,
              this.settings.organizationId,
              candidateStatus,
              candidateFunction
            )
            .pipe(map((x) => x?.tabs?.filter((t) => t.tabFields?.length > 0)))
        : of(this.settings.menu?.profile?.tabs ?? []),
    ]).pipe(
      map(([specifics, ownerSettings, tabs]) => {
        return this.getFormlyFields(specifics, ownerSettings, tabs);
      }),
      tap((tabs) => {
        if (this.settings.menu?.employerSearch?.enabled) {
          tabs.find((x) => x.id === "preferences")?.fields.unshift(this.getEmployerSearchHint());
        }
      }),
      map((tabs) => [tabs, this.toFormlyFields(tabs)])
    );
  }

  getIgnoredPaths(candidateOs: ICandidateOSModel, immigrationCountry: string, profession: string): string[] {
    const notMutableFields: Property<ICandidateOSModel>[] = ["totalExperienceInMonth"];

    const noExperienceAvailable: Property<ICandidateOSModel>[] = candidateOs.noExperienceAvailable
      ? ["experiences"]
      : [];

    const noFurtherEducationAvailable: Property<ICandidateOSModel>[] = candidateOs.noFurtherEducationAvailable
      ? ["furtherEducations"]
      : [];

    // these fields depend on the candidate's profession and will be removed if they don't match the profession
    const ignoredPreferences: Property<ICandidateOSModel>[] = !MedHealthCareCoreProfessions.includes(profession)
      ? ["preferredCareFacility", "preferredWorkingField"]
      : [];

    const locationPreferences = candidateOs.preferredLocationState
      ?.map((x) => getCityState(immigrationCountry, x))
      .some((x) => candidateOs.preferredCity?.includes(x))
      ? []
      : ["preferredCity"];

    return [
      ...notMutableFields,
      ...ignoredPreferences,
      ...locationPreferences,
      ...noExperienceAvailable,
      ...noFurtherEducationAvailable,
      ...this.getUnusedRemovedCustomFields(),
    ];
  }

  private getUnusedRemovedCustomFields(): string[] {
    const _dummy: ICustomFieldsModel = null;
    const customDefaFields: Property<typeof _dummy.defa>[] = [
      "basicCalculationScore",
      "percentageCalculationScore",
      "analysisScore",
      "spatialThinkingScore",
      "communicationSkillScore",
      "assertivenessScore",
      "teamworkSkillScore",
      "stressHandlungSkillScore",
    ];

    return customDefaFields.map((x) => `customFields.${x}`);
  }

  getIgnoredProfileArrayFields(
    candidateOs: ICandidateOSModel,
    profession: string
  ): { pathToArray: Property<ICandidateOSModel>; propertyNames: string[] }[] {
    return [
      {
        pathToArray: "experiences",
        propertyNames: getIgnoredExperiences(),
      },
      {
        pathToArray: "furtherEducations",
        propertyNames: getIgnoredFurtherEducations(),
      },
    ];

    function getIgnoredExperiences(): Property<IExperienceModel>[] {
      const experienceModels = candidateOs.experiences ?? [];

      const hasNotMedHealthCareCoreProfessions = experienceModels.some(
        (x) => !MedHealthCareCoreProfessions.includes(x.occupation)
      );
      const hasGYExperienceField = experienceModels.some((x) =>
        x.experienceFields?.map((field) => field.experienceField === "GY")
      );
      const hasOtherExperiences = experienceModels.some((x) => x.experienceType === ExperienceType.OtherActivities);
      const isExperienceFacilityDOCOFFICE = experienceModels.some((x) => x.experienceFacility == "DOCOFFICE");
      const isIntern = experienceModels.some((x) => x.experienceFunction === "INTERN");

      const ignoreExperiences: Partial<Record<Property<IExperienceModel>, boolean>> = {
        experienceMonth: true,
        experienceYears: true,
        experienceFacility: hasNotMedHealthCareCoreProfessions || hasOtherExperiences,
        experienceFields: hasNotMedHealthCareCoreProfessions || hasOtherExperiences,
        experienceWorkArea: hasNotMedHealthCareCoreProfessions || hasOtherExperiences || hasGYExperienceField,
        experienceBedCount: hasNotMedHealthCareCoreProfessions || hasOtherExperiences || isExperienceFacilityDOCOFFICE,
        experienceCount: hasNotMedHealthCareCoreProfessions || hasOtherExperiences || !hasGYExperienceField,
        occupation: hasOtherExperiences,
        experienceFunction: hasOtherExperiences,
        mandatoryInternship: hasOtherExperiences || !isIntern,
        asFreelancer: hasOtherExperiences || isIntern,
        experienceInstitution: hasOtherExperiences,
        city: hasOtherExperiences,
        country: hasOtherExperiences,
      };

      return Object.entries(ignoreExperiences)
        .filter(([_, value]) => value)
        .map(([key, _]) => key as Property<IExperienceModel>);
    }

    function getIgnoredFurtherEducations(): Property<IFurtherEducation>[] {
      const furtherEducations = candidateOs.furtherEducations ?? [];

      const hasNotMedHealthCareCoreProfession = !MedHealthCareCoreProfessions.includes(profession);
      const notProfessionRelated = furtherEducations.some((x) => x.notProfessionRelated);

      const ignoreFurtherEducations: Partial<Record<Property<IFurtherEducation>, boolean>> = {
        fieldOfEducation: notProfessionRelated || hasNotMedHealthCareCoreProfession,
      };

      return Object.entries(ignoreFurtherEducations)
        .filter(([_, value]) => value)
        .map(([key, _]) => key as Property<IFurtherEducation>);
    }
  }

  getIgnoredPropertyValues(): IgnoredPropertyValues[] {
    const currentLanguageLevels = [
      "A1",
      "A2",
      "B1",
      "B2",
      "B1_NURSE",
      "B2_DOCTOR",
      "B2_NURSE",
      "C1",
      "C1_DOCTOR",
      "C2",
      "NV",
    ];

    return [
      {
        pathToProperty: "preferredLanguageLevelForTransfer",
        values: currentLanguageLevels.filter((x) => !["B1", "B2"].includes(x)),
        type: "ignoreValues",
      },
      {
        pathToProperty: "preferredLanguageLevelForInterview",
        values: currentLanguageLevels.filter((x) => !["A2", "B1"].includes(x)),
        type: "ignoreValues",
      },
    ];
  }

  //one step back
  private getFormlyFields(
    specifics: OrganizationSpecific,
    ownerSettings: OwnerSettings,
    profileTabs: CandidateProfileConfigTab[]
  ): CandidateProfileTab[] {
    const tabs = specifics.getCandidateProfileTabs();
    const fields = this.getAvailableFields(specifics, tabs, ownerSettings);
    return profileTabs
      .map((tab) => this.getDependantFields(tab))
      .map((tab) => {
        const definition = tabs.find((g) => g.id === tab.key);
        return definition
          ? <CandidateProfileTab>{
              id: tab.key,
              key: definition.key,
              label: definition.label,
              icon: definition.icon,
              fields: this.getTabFields(tab, fields, definition),
            }
          : null;
      })
      .filter((t) => t)
      .sort((a, b) => tabs.findIndex((x) => x.id === a.id) - tabs.findIndex((x) => x.id === b.id));
  }

  private getDependantFields(tab: CandidateProfileConfigTab): CandidateProfileConfigTab {
    if (tab.key === "vaccinations" && tab.tabFields.some((field) => field.name === "vaccinations")) {
      return {
        ...tab,
        tabFields: [...tab.tabFields, { name: "vaccinationInformation" }],
      };
    }

    return tab;
  }

  //here shit goes
  private getAvailableFields(
    specifics: OrganizationSpecific,
    tabs: CandidateProfileTab[],
    ownerSettings: OwnerSettings
  ): FormlyFieldConfig[] {
    const flatten = (t: FormlyFieldConfig): FormlyFieldConfig[] | any[] => [t, flatMapDeep(t.fieldGroup, flatten)];
    return [
      ...specifics
        .getCandidateProfileFormlyFields(
          ownerSettings.requiredCandidateFields,
          ownerSettings.workingExperiencePrecision,
          ownerSettings.educationPrecision
        )[0]
        .fieldGroup.flatMap((t) => flatMapDeep(t.fieldGroup, flatten)),
      ...tabs.flatMap((t) => t.fields).filter((f) => f["type"]),
    ];
  }

  private getTabFields(
    tab: CandidateProfileConfigTab,
    fields: FormlyFieldConfig[],
    definition: CandidateProfileTab
  ): FormlyFieldConfig[] {
    const doNotRestyle = {
      types: ["repeat", "repeat-tabs"],
      keys: [
        "wayToUs",
        "hobbies",
        "socialSkills",
        "itSkills",
        "familiarEquipment",
        "vaccinationInformation",
        "languageLevelCV",
        "languageLevelByPlacementTest",
        "knowledges",
        "skills",
        "customKnowledges",
        "customSkills",
        "certifiedEducationExams",
      ],
    };

    const fieldGroup = tab.tabFields
      .map((f) => {
        const field = fields.find((x) => x.key === f.name);
        if (!doNotRestyle.types.includes(field.type as string) && !doNotRestyle.keys.includes(<string>field.key)) {
          field.className = [
            "field",
            "col-12",
            "lg:col-4",
            "xl:col-3",
            ...(field.className ?? "").split(" ").filter((x) => !/^field|col|xs:|md:|lg:|xl:/.test(x)),
          ].join(" ");
        }

        if (this.isFieldReadOnly(f)) this.templateOptionsService.makeFieldReadOnly(field);
        else if (f.isMandatory) {
          this.templateOptionsService.makeFieldMandatory(field);
          (field.fieldArray as FormlyFieldConfig<FormlyFieldProps>)?.fieldGroup?.forEach((fg) =>
            fg?.props?.isRequiredInCandidateEditModeIfParentIsRequired
              ? this.templateOptionsService.makeFieldMandatory(fg)
              : noop()
          );
        }

        return field;
      })
      .sort(
        (a, b) =>
          definition.fields.findIndex((x) => x.key === a.key) - definition.fields.findIndex((x) => x.key === b.key)
      );

    if (!this.settings.readonlyProfile) {
      const documentField = fields.find((x) => x.id === `${tab.key}Documents`);
      if (documentField) {
        fieldGroup.push(documentField);
      }
    }
    const wrapperFieldGroup: FormlyFieldConfig[] = [
      {
        id: definition.id,
        fieldGroup: fieldGroup,
        fieldGroupClassName: "p-fluid formgrid grid pt-4 pb-4",
      },
    ];

    return wrapperFieldGroup;
  }

  private isFieldReadOnly(field: TabField): boolean {
    if (this.settings.profileDataEditStatus === ProfileDataEditStatus.Editable) return false;
    return this.settings.readonlyProfile || !field.isEnabled;
  }

  private getEmployerSearchHint(): FormlyFieldConfig {
    return {
      template: `<div aria-live="polite" class="p-inline-message p-component block text-center mb-4 p-inline-message-info">
        <span class="p-inline-message-icon pi pi-info-circle"></span>
        <span class="p-inline-message-text">${translate("employerSearch.hint")}</span>
      </div>`,
    };
  }

  private toFormlyFields(tabs: CandidateProfileTab[]): FormlyFieldConfig[] {
    return [
      {
        type: "tabs",
        props: { showIcons: true },
        fieldGroup: tabs.map((x) => ({
          id: x.id,
          key: x.key,
          props: { label: x.label, icon: x.icon },
          fieldGroup: x.fields,
        })),
      },
    ];
  }
}
