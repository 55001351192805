<ng-container *appTranslate="let t">
  <p-tree
    [value]="treeNodes"
    [filter]="true"
    [filterPlaceholder]="t('common.search')"
    [selection]="selectedNode"
    scrollHeight="225px"
    [draggableNodes]="!mode && isAdmin"
    [droppableNodes]="!mode && isAdmin"
    (onFilter)="onFilter($event)"
    (onNodeDrop)="onNodeDrop($event)"
    selectionMode="single"
    draggableScope="self"
    droppableScope="self"
    styleClass="p-tree-filter-presets"
  >
    <ng-template pTemplate="header">
      <div class="flex align-items-center justify-content-between gap-2">
        <span class="font-medium flex-grow-1">{{ t("filter.preset.title") }}</span>
        <span>{{ t("filter.preset.order") }}:</span>
        <p-button
          [icon]="sortCategoriesAscending ? 'pi pi-sort-alpha-down' : ' pi pi-sort-alpha-up'"
          [label]="t('filter.preset.category')"
          (click)="sortCategoriesAscending = !sortCategoriesAscending; sortCategories()"
          styleClass="p-button-text p-button-small"
          iconPos="right"
        ></p-button>
        <p-button
          [icon]="sortPresetsAscending ? 'pi pi-sort-alpha-down' : ' pi pi-sort-alpha-up'"
          [label]="t('filter.preset.name')"
          (click)="sortPresetsAscending = !sortPresetsAscending; sortPresets()"
          styleClass="p-button-text p-button-small"
          iconPos="right"
        ></p-button>
        <p-divider layout="vertical" [style.height.rem]="3" styleClass="p-divider-margin-0"></p-divider>
        <ng-container *ngIf="expanded; else collapsed">
          <p-button
            [label]="t('common.collapseAll')"
            [disabled]="!!mode"
            (click)="collapseAll()"
            styleClass="p-button-text"
          ></p-button>
        </ng-container>
        <ng-template #collapsed>
          <p-button
            [label]="t('common.expandAll')"
            [disabled]="!!mode"
            (click)="expandAll()"
            styleClass="p-button-text"
          ></p-button>
        </ng-template>
      </div>
    </ng-template>
    <ng-template let-node pTemplate="preset">
      <div class="flex align-items-center justify-content-between gap-1">
        <i *ngIf="!mode && isAdmin" class="pi pi-bars mr-1" style="cursor: move"></i>
        <div class="flex-grow-1 ellipsis">
          <input
            *ngIf="selectedNode?.data === node.data && isBeingEdited; else nodeLabel"
            pInputText
            type="text"
            [ngModel]="node.label"
            (ngModelChange)="node.label = trimLabel($event)"
            [ngModelOptions]="{ standalone: true }"
            [placeholder]="t('name.placeholder')"
            class="name-input"
          />
          <ng-template #nodeLabel>{{ node.label }}</ng-template>
        </div>

        <ng-container *ngIf="selectedNode?.data === node.data && mode === 'delete'; else notBeingDeleted">
          <p-button
            icon="pi pi-check"
            styleClass="p-button-small"
            (click)="deletePreset()"
            [label]="t('common.delete')"
          ></p-button>
          <p-button
            icon="pi pi-times"
            styleClass="p-button-small"
            (click)="cancelAction()"
            [label]="t('common.cancel')"
          ></p-button>
        </ng-container>
        <ng-template #notBeingDeleted>
          <ng-container *ngIf="selectedNode?.data === node.data && isBeingEdited; else notBeingEdited">
            <p-button
              icon="pi pi-save"
              styleClass="p-button-small"
              (click)="save()"
              [label]="t('common.save')"
              [disabled]="!isValid"
            ></p-button>
            <p-button
              icon="pi pi-times"
              styleClass="p-button-small"
              (click)="cancelAction()"
              [label]="t('common.cancel')"
            ></p-button>
          </ng-container>
          <ng-template #notBeingEdited>
            <p-button icon="pi pi-search" size="small" (click)="search(node)" [disabled]="!!mode"></p-button>
            <p-button
              *ngIf="node.data.userId || isAdmin"
              icon="pi pi-pencil"
              size="small"
              (click)="edit(node)"
              [disabled]="!!mode"
            ></p-button>
            <p-button
              *ngIf="node.data.userId || isAdmin"
              icon="pi pi-trash"
              size="small"
              (click)="delete(node)"
              [disabled]="!!mode"
            ></p-button>
          </ng-template>
        </ng-template>
      </div>
    </ng-template>
  </p-tree>
</ng-container>
