<app-education-exam-table
  [modules]="modules"
  [exams]="exams"
  [isReadOnly]="isReadOnly"
  [search]="true"
  [shownColumns]="[
    'name',
    'examType',
    'examInstitution',
    'examModules',
    'plannedExamDateAndTime',
    'examDateAndTime',
    'examLocation',
  ]"
  [shownNewOperations]="'ad-hoc'"
  [shownTableOperations]="['edit']"
  [additionalTableOperations]="examTableOperations"
  [additionalNewOperations]="newExamTableOperations"
  [createAdHocOrganizationId]="candidateData.candidateOrganizationId"
  (examCreated)="addCandidateToExam($event)"
  (examUpdated)="updated.emit($event)"
>
</app-education-exam-table>

<p-sidebar [visible]="showSidebar" styleClass="p-sidebar-md">
  <app-candidate-education-exams-add-to-exam-dialog
    *ngIf="showSidebar"
    [currentExams]="exams"
    [candidateData]="candidateData"
    (saved)="addCandidateToExam($event)"
    (closed)="closeSideBar()"
  ></app-candidate-education-exams-add-to-exam-dialog>
</p-sidebar>
