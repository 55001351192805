import {
  buildLaborAgreementData,
  IntersectionKey,
  KeyOf,
  LaborContractDetails,
  MergeInstruction,
  MergeResult,
  MergeSource,
  mergeWithInstructions,
} from "@ankaadia/ankaadia-shared";
import {
  ContractTemplateAllowance,
  ContractTemplateBenefit,
  LaborAgreement,
  LaborAgreementAllowance,
  LaborAgreementBenefit,
} from "@ankaadia/graphql";
import { isEmpty, isNil, omitBy } from "lodash";
import {
  CleanContractTemplateSetInput,
  FormContractTemplate,
  toCleanContractTemplate,
} from "./contract-template-form.model";

const allowanceMergeInstructions: MergeInstruction<Required<LaborAgreementAllowance>, ContractTemplateAllowance> = {
  id: "Target",
  type: "Target",
  customType: "Target",
  calculationBasis: clearTargetIfEqual,
  amount: clearTargetIfEqual,
  comment: "Target",
  __typename: "Target",
};

const benefitMergeInstructions: MergeInstruction<Required<LaborAgreementBenefit>, ContractTemplateBenefit> = {
  id: "Target",
  type: "Target",
  calculationBasis: clearTargetIfEqual,
  timeInterval: clearTargetIfEqual,
  amount: clearTargetIfEqual,
  __typename: "Target",
};

const contractMergeInstructions: MergeInstruction<Required<LaborContractDetails>, FormContractTemplate> = {
  id: "Target",
  name: "Target",
  organizationId: "Target",
  country: "Target",
  noticePeriod: clearTargetIfEqual,
  noticePeriodUnit: clearTargetIfEqual,
  probationPeriod: clearTargetIfEqual,
  probationPeriodUnit: clearTargetIfEqual,
  compensationType: clearTargetIfEqual,
  compensationRate: clearTargetIfEqual,
  workingHoursPerWeek: clearTargetIfEqual,
  allowances: (source, target) => mergeItems(source, target, ["type", "customType"], allowanceMergeInstructions),
  benefits: (source, target) => mergeItems(source, target, ["type"], benefitMergeInstructions),
  validFrom: "Target",
  validUntil: "Target",
  changedAt: "Target",
  changedBy: "Target",
};

function clearTargetIfEqual<TValue>(source: TValue, target: TValue): TValue {
  return !isNil(target) && source !== target ? target : null;
}

function mergeItems<TIn extends MergeSource<TIn, TOut>, TOut>(
  sources: TIn[],
  targets: TOut[],
  uniqueKeys: IntersectionKey<TIn, TOut>[],
  mergeInstructions: MergeInstruction<TIn, TOut>
): MergeResult<TIn, TOut>[] {
  if (isEmpty(sources)) {
    return targets;
  }

  if (isEmpty(targets)) {
    return sources;
  }

  return targets.map((target) => {
    const sourceMatch = sources.find((source) => {
      return getIdentifier(source, uniqueKeys) === getIdentifier(target, uniqueKeys);
    });

    return isNil(sourceMatch) ? target : mergeWithInstructions(mergeInstructions, sourceMatch, target);
  });
}

export function purgeUnchangedFields(
  contractTemplate: FormContractTemplate,
  laborAgreements: LaborAgreement[]
): CleanContractTemplateSetInput {
  const laborAgreementData = buildLaborAgreementData(contractTemplate, laborAgreements);
  const mergedContractData = mergeWithInstructions(contractMergeInstructions, laborAgreementData, contractTemplate);
  return toCleanContractTemplate(mergedContractData);
}

function getIdentifier<T>(object: T, keys: KeyOf<T>[]): string {
  const uniqueValues = keys.reduce((acc, key) => ({ ...acc, [key]: object[key] }), {});
  return JSON.stringify(omitBy(uniqueValues, isNil));
}
