import { Injectable } from "@angular/core";
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { Filter, FilterForm, FiltersForm } from "./candidate-filter-form.model";

@Injectable({ providedIn: "root" })
export class CandidateFilterFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createFilterForm(filter: Filter | null): FilterForm {
    return this.formBuilder.group<FilterForm["controls"]>({
      field: this.formBuilder.control(
        filter?.field ?? null,
        Validators.compose([Validators.required, this.maxFieldNameLength(200)])
      ),
      condition: this.formBuilder.control(filter?.condition ?? null, Validators.required),
      value: this.formBuilder.control(filter?.value ?? null),
      dataSource: this.formBuilder.control(filter?.dataSource ?? null),
    });
  }

  createFiltersForm(): FiltersForm {
    return this.formBuilder.group<FiltersForm["controls"]>({
      filters: this.formBuilder.array<FilterForm>([], Validators.required),
    });
  }

  private maxFieldNameLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const fieldControl = control as FilterForm["controls"]["field"];
      const value = fieldControl.value?.data?.value;

      return this.hasValidLength(value) && value.length > maxLength
        ? { maxlength: { requiredLength: maxLength, actualLength: control.value.length } }
        : null;
    };
  }

  private hasValidLength(value: any): boolean {
    // non-strict comparison is intentional, to check for both `null` and `undefined` values
    return value != null && typeof value.length === "number";
  }
}
