import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";

import { Observable } from "rxjs";

@Component({
  selector: "app-organization-profile-benefits",
  templateUrl: "./organization-profile-benefits.component.html",
  standalone: false,
})
export class OrganizationProfileBenefitsComponent implements OnInit {
  readonly StaticDataType = StaticDataType;
  protected benefits: Observable<StaticDataModel[]>;

  @Input()
  form: FormControl<string[]>;

  showSidebar: boolean;

  constructor(
    private readonly statcDataService: StaticDataService,
    private readonly transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this.benefits = this.statcDataService.getStaticData(StaticDataType.Benefits, this.transloco.getActiveLang());
  }
}
