import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "app-formly-checkbox",
  templateUrl: "./formly-checkbox.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FormlyCheckboxComponent extends FieldType {
  override defaultOptions = {
    props: {
      hideLabel: true,
    },
  };
}
