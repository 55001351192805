<ng-container *appTranslate="let t">
  <form [formGroup]="form">
    <app-collection-auto-filter-info
      [filter]="filter"
      [fields]="fields"
      [includeSharedCandidates]="includeSharedCandidates"
    ></app-collection-auto-filter-info>
    <div class="p-fluid">
      <div class="field">
        <label for="templateName">{{ t("autoCollectionTemplate.templateName.title") }}</label>
        <input
          id="templateName"
          pInputText
          formControlName="templateName"
          maxlength="300"
          [placeholder]="t('autoCollectionTemplate.templateName.placeholder')"
          [readOnly]="true"
        />
        <small class="p-error" *ngIf="form.controls.templateName.errors?.required">
          {{ t("autoCollectionTemplate.templateName.required") }}
        </small>
      </div>
    </div>
  </form>
</ng-container>
