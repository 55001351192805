<ng-container *appTranslate="let t">
  <form [formGroup]="form">
    <p-fieldset [legend]="t('complementaryMeasure.title')" class="relative">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 lg:col-6">
          <app-candidate-responsible-role
            [form]="form"
            [responsibleRolePurpose]="ResponsibleRolePurposeType.ComplementaryMeasure"
            [candidateId]="candidateId"
            [readonly]="readonly"
            [alwaysShownRoles]="responsibleRoles"
            class="field"
          ></app-candidate-responsible-role>
        </div>

        <div class="field col-0 lg:col-6"></div>

        <div class="field col-12 lg:col-3">
          <label for="theoryInstitution">{{ t("institutionName.title") }}</label>
          <input pInputText id="theoryInstitution" formControlName="theoryInstitution" [readonly]="readonly" />
        </div>

        <div class="field col-12 lg:col-3">
          <app-candidate-location-of-complementary-measure
            class="field"
            [form]="form"
            [readonly]="readonly"
          ></app-candidate-location-of-complementary-measure>
        </div>

        <div class="field col-12 lg:col-3">
          <app-candidate-start-of-further-education
            class="field"
            [form]="form"
            [readonly]="readonly"
            [immigrationCountry]="ImmigrationCountry"
          ></app-candidate-start-of-further-education>
        </div>

        <div class="field col-12 lg:col-3">
          <app-candidate-end-of-further-education
            class="field"
            [form]="form"
            [readonly]="readonly"
            [immigrationCountry]="ImmigrationCountry"
          ></app-candidate-end-of-further-education>
        </div>
      </div>
    </p-fieldset>
  </form>
</ng-container>
