<ng-container *appTranslate="let t">
  <p-card styleClass="overflow-card">
    <div class="flex flex-column app-form p-card-content">
      <p-toolbar styleClass="mb-4">
        <ng-template pTemplate="left">
          <h3>{{ t("documentRequirements.title") }}</h3>
        </ng-template>
        <ng-template pTemplate="right">
          <app-document-general-requirement></app-document-general-requirement>
        </ng-template>
      </p-toolbar>
      <app-document-requirement-table [form]="form" class="flex-1" />
    </div>
    <ng-template pTemplate="footer">
      <div class="flex flex-row justify-content-end">
        <p-button
          [disabled]="!form.valid || !form.dirty"
          [label]="t('common.save')"
          icon="pi pi-check"
          (click)="submit()"
          class="mr-2"
        />
        <p-button
          closeOnEscape
          [label]="t('common.cancel')"
          icon="pi pi-times"
          class="p-button-secondary"
          type="reset"
          (onClick)="onCloseClick()"
        />
      </div>
    </ng-template>
  </p-card>
</ng-container>
