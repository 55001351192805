import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "concat", standalone: false })
export class ConcatPipe implements PipeTransform {
  transform(value1: any, value2: any): any[] {
    if (Array.isArray(value1)) {
      return value1.concat(value2);
    }

    if (Array.isArray(value2)) {
      return [value1].concat(value2);
    }

    return [value1, value2];
  }
}
