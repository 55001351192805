import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { CandidatePrice, SupportedBillingCurrency } from "@ankaadia/graphql";
import { PlanCandidatePriceRowComponent } from "../candidate-price-row/plan-candidate-price-row.component";
import { PlanPriceCapFloorComponent } from "../price-cap-floor/plan-price-cap-floor.component";
import { CommonModule } from "@angular/common";
import { TranslateDirective } from "../../../../../shared/transloco/translate.directive";

@Component({
  standalone: true,
  selector: "app-plan-candidate-price-summary",
  templateUrl: "./plan-candidate-price-summary.component.html",
  imports: [CommonModule, PlanCandidatePriceRowComponent, PlanPriceCapFloorComponent, TranslateDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanCandidatePriceSummaryComponent {
  price = input.required<CandidatePrice>();

  currency = input.required<SupportedBillingCurrency>();
}
