import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { ENABLE_BILLING, UserPermission } from "@ankaadia/ankaadia-shared";
import { SettingsService } from "../../../shared/services/settings.service";
import { OrganizationEditDialogComponent } from "../organization-edit-dialog/organization-edit-dialog.component";
import { MyOrganizationService } from "./my-organization.service";

@Component({
  selector: "app-my-organization",
  templateUrl: "./my-organization.component.html",
  styleUrl: "./my-organization.component.scss",
  standalone: false,
})
export class MyOrganizationEditComponent implements AfterViewInit {
  @ViewChild(OrganizationEditDialogComponent)
  editDialog: OrganizationEditDialogComponent;

  get organizationId(): string {
    return this.settings.organizationId;
  }

  get canEditOrganizationProfile(): boolean {
    return this.settings.canEditOrganizationProfile;
  }

  get canSeeBillingSettings(): boolean {
    return (
      this.settings.isLicensed &&
      this.settings.hasAnyPermission([UserPermission.BillingAdministrator]) &&
      ENABLE_BILLING
    );
  }

  constructor(
    private readonly settings: SettingsService,
    private readonly myOrgService: MyOrganizationService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.editDialog.initEdit(this.organizationId, this.settings.creatorOrganizationId).subscribe();
    }, 0);
  }

  onCloseClick(): void {
    this.myOrgService.changed.next();
    this.settings.navigateToMain();
  }
}
