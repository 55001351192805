import { Injectable } from "@angular/core";
import {
  CreateEducationCourseGQL,
  DeleteEducationCourseGQL,
  EditEducationCourseGQL,
  EducationCourseCreateInput,
  EducationCourseDeleteInput,
  EducationCourseForCandidateListFragment,
  EducationCourseForCandidateListToAddFragment,
  EducationCourseForEditFragment,
  EducationCourseForListAndViewFragment,
  EducationCourseForListAndViewFragmentDoc,
  EducationCourseForListFragment,
  EducationCourseForViewFragment,
  EducationCourseUpdateInput,
  ListEducationCoursesForCandidateGQL,
  ListEducationCoursesForCandidateToAddToGQL,
  ListEducationCoursesGQL,
  UpdateEducationCourseGQL,
  ViewEducationCourseGQL,
} from "@ankaadia/graphql";
import { FetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { omit, pick } from "lodash";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class EducationCourseService {
  constructor(
    private readonly courseList: ListEducationCoursesGQL,
    private readonly courseListForCandidate: ListEducationCoursesForCandidateGQL,
    private readonly courseListForCandidateToAddTo: ListEducationCoursesForCandidateToAddToGQL,
    private readonly courseView: ViewEducationCourseGQL,
    private readonly courseEdit: EditEducationCourseGQL,
    private readonly courseCreate: CreateEducationCourseGQL,
    private readonly courseUpdate: UpdateEducationCourseGQL,
    private readonly courseDelete: DeleteEducationCourseGQL
  ) {}

  list(organizationId: string): Observable<EducationCourseForListFragment[]> {
    return this.courseList
      .watch({ input: { organizationId } })
      .valueChanges.pipe(map((x) => x.data.getEducationCourses));
  }

  listForCandidate(
    candidateId: string,
    candidateOrganizationId: string,
    fetchPolicy: FetchPolicy = "cache-first"
  ): Observable<EducationCourseForCandidateListFragment[]> {
    return this.courseListForCandidate
      .fetch({ input: { candidateId, candidateOrganizationId } }, { fetchPolicy })
      .pipe(map((x) => x.data.getEducationCoursesForCandidate));
  }

  listForCandidateToAdd(): Observable<EducationCourseForCandidateListToAddFragment[]> {
    return this.courseListForCandidateToAddTo
      .fetch({}, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getEducationCoursesForCandidateToAddTo));
  }

  fetch(organizationId: string): Observable<EducationCourseForListFragment[]> {
    return this.courseList.fetch({ input: { organizationId } }).pipe(map((x) => x.data.getEducationCourses));
  }

  view(id: string, organizationId: string): Observable<EducationCourseForViewFragment> {
    return this.courseView.fetch({ input: { id, organizationId } }).pipe(map((x) => x.data.getEducationCourse));
  }

  edit(id: string, organizationId: string): Observable<EducationCourseForEditFragment> {
    return this.courseEdit.fetch({ input: { id, organizationId } }).pipe(map((x) => x.data.getEducationCourse));
  }

  create(module: EducationCourseCreateInput): Observable<EducationCourseForListAndViewFragment> {
    return this.courseCreate
      .mutate(
        { input: omit(module, "id", "_etag", "changedAt", "changedAt") },
        {
          update: (cache, x) =>
            cache.modify({
              fields: {
                getEducationCourses: (refs) => {
                  const ref = cache.writeFragment({
                    data: x.data.createEducationCourse,
                    fragment: EducationCourseForListAndViewFragmentDoc,
                    fragmentName: "EducationCourseForListAndView",
                  });
                  return [...(refs ?? []), ref];
                },
              },
            }),
        }
      )
      .pipe(map((x) => x.data.createEducationCourse));
  }

  update(module: EducationCourseUpdateInput): Observable<EducationCourseForListAndViewFragment> {
    return this.courseUpdate
      .mutate({ input: omit(module, "changedAt", "changedAt") })
      .pipe(map((x) => x.data.updateEducationCourse));
  }

  delete(module: EducationCourseDeleteInput): Observable<void> {
    return this.courseDelete
      .mutate(
        { input: pick(module, "id", "_etag", "organizationId") },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getEducationCourses: (refs, helper) => {
                  return refs.filter((ref) => helper.readField("id", ref) !== module.id);
                },
              },
            }),
        }
      )
      .pipe(map((x) => x.data.deleteEducationCourse));
  }
}
