import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { isCollectionAutoTemplateVariable } from "@ankaadia/ankaadia-shared";
import { CollectionAutoFilterTemplate, CollectionAutoFilterTemplateSetInput } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { uniq } from "lodash";
import { BehaviorSubject, combineLatest, finalize } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { SettingsService } from "../../../../shared/services/settings.service";
import { FilterForm } from "../../../candidate-filter/candidate-filter-form.model";
import { CandidateFilterService } from "../../../candidate-filter/candidate-filter.service";
import { MessageService } from "../../../message/message.service";
import { FilterMetadataMap } from "../../collection-edit-assigned-candidates/custom-filter.model";
import { CollectionAutoFilterTemplateService } from "../collection-auto-filter-template.service";

type SaveBehaviorType = "standard" | "detachAll" | "saveAs";

@Component({
  selector: "app-collection-auto-template-edit-dialog",
  templateUrl: "./collection-auto-template-edit-dialog.component.html",
  styleUrl: "./collection-auto-template-edit-dialog.component.scss",
  standalone: false,
})
export class CollectionAutoTemplateEditDialogComponent implements OnChanges, OnInit {
  disableSave: boolean;
  @Output() readonly closed = new EventEmitter<CollectionAutoFilterTemplate>();
  @Input({ required: true }) collectionAutoTemplate: CollectionAutoFilterTemplateSetInput;
  @Input({ required: true }) isEdit: boolean;
  @Input() checkVariables = false;

  private templateNames: string[] = [];
  private readonly collectionAutoTemplateBehaviorSubject$ = new BehaviorSubject<CollectionAutoFilterTemplateSetInput>(
    null
  );

  private readonly filterFormArray = this.formBuilder.array<FilterForm>([], Validators.required);

  protected readonly form: FormGroup = new FormGroup({
    organizationId: new FormControl(null, Validators.required),
    id: new FormControl(null, Validators.required),
    name: new FormControl(null, Validators.compose([Validators.required, this.uniqueNameValidator.bind(this)])),
    description: new FormControl(),
    filters: this.filterFormArray,
    templateName: new FormControl(null, Validators.required),
    autoSync: new FormControl(true),
    includeSharedCandidates: new FormControl(null),
    specialPurpose: new FormControl(null),
  });

  protected showSaveBehaviorSelector = false;
  protected variablesHaveChanged = false;
  protected saveAsName = "";
  protected saveBehavior: SaveBehaviorType;

  protected filter: FilterMetadataMap;

  constructor(
    private readonly settingsService: SettingsService,
    private readonly templateService: CollectionAutoFilterTemplateService,
    private readonly messageService: MessageService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly candidateFilterService: CandidateFilterService
  ) {}

  get header(): string {
    return this.isEdit ? translate("autoCollectionTemplate.edit") : translate("autoCollectionTemplate.new");
  }

  ngOnInit(): void {
    this.disableSave = false;
    combineLatest([
      this.templateService.getAll({ organizationId: this.settingsService.organizationId }),
      this.collectionAutoTemplateBehaviorSubject$.asObservable(),
    ]).subscribe(([templates, collectionAutoTemplate]) => {
      const usedNames = templates.filter((x) => x.id !== collectionAutoTemplate?.id).map((x) => x.name);
      this.templateNames = usedNames;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.collectionAutoTemplate) {
      this.updateForm();
      this.collectionAutoTemplateBehaviorSubject$.next(this.collectionAutoTemplate);
    }
  }

  updateForm(): void {
    if (this.collectionAutoTemplate != null) {
      const { filters, ...patchValue } = this.collectionAutoTemplate;
      this.form.patchValue(patchValue);
      this.filter = filters;
    } else {
      this.form.reset();
    }
    this.changeDetector.detectChanges();
  }

  cancel(): void {
    this.closed.emit(null);
  }

  openSaveBehaviorDialog(): void {
    if (!this.isEdit) {
      this.save("standard");
      return;
    }

    this.variablesHaveChanged = this.areVariablesChanged();
    this.saveBehavior = !this.variablesHaveChanged ? "standard" : "saveAs";
    this.showSaveBehaviorSelector = true;
  }

  save(saveBehavior: SaveBehaviorType = null): void {
    saveBehavior = saveBehavior ?? this.saveBehavior;
    if (saveBehavior === "standard" && this.areVariablesChanged()) {
      throw new Error("Variables have changed");
    }

    this.disableSave = true;
    const { autoSync, ...input } = this.form.value;

    if (saveBehavior === "saveAs") {
      input.id = uuidv4();
      input.name = this.saveAsName;
    } else if (saveBehavior === "detachAll") {
      this.templateService.detachAllCollectionsFromTemplate(this.collectionAutoTemplate).subscribe(() => {
        this.messageService.add({
          severity: "success",
          summary: translate("autoCollectionTemplate.detachedAllSuccessfully"),
        });
      });
    }

    const filters = this.candidateFilterService.buildFilterMetadataMap(this.form);
    input.filters = filters;
    this.templateService
      .set(input)
      .pipe(finalize(() => (this.disableSave = false)))
      .subscribe((col) => {
        this.messageService.add({
          severity: "success",
          summary: translate("autoCollectionTemplate.saved.title"),
          detail: translate("autoCollectionTemplate.saved.message", col),
        });
        this.closed.emit(col);
        if (this.isEdit && autoSync) {
          this.templateService.syncAllCollectionsWithTemplate(col).subscribe(() => {
            this.messageService.add({
              severity: "success",
              summary: translate("autoCollectionTemplate.syncedAllSuccessfully"),
            });
          });
        }
      });
  }

  protected isTemplateNameValid(name: string): boolean {
    const result = !this.templateNames.includes(name);
    return result;
  }

  protected isBehaviorValid(): boolean {
    if (!this.saveBehavior) {
      return false;
    }
    if (this.saveBehavior === "saveAs") {
      return this.saveAsName && this.isTemplateNameValid(this.saveAsName);
    }
    return true;
  }

  private areVariablesChanged(): boolean {
    if (!this.checkVariables) {
      return false;
    }
    const usedVariables = getUsedVariables(this.form.value.filters);
    const requiredVariables = getUsedVariables(this.collectionAutoTemplate.filters);
    if (equalArrays(usedVariables, requiredVariables)) {
      return false;
    }
    return true;

    function getUsedVariables(filters: FilterMetadataMap): string[] {
      const variables = Object.values(filters)
        .filter((x) => x)
        .flatMap((x) => x)
        .flatMap((x) => x.value)
        .filter((x) => x && typeof x === "string")
        .filter((x) => isCollectionAutoTemplateVariable(x));
      return uniq(variables ?? []);
    }

    function equalArrays(a: string[], b: string[]): boolean {
      return a?.length === b?.length && a.every((x) => b.includes(x));
    }
  }

  private uniqueNameValidator(control: AbstractControl<string>): ValidationErrors | null {
    if (control.value && this.templateNames?.length > 0) {
      const value = control.value?.trim();
      if (this.templateNames.includes(value)) {
        return { unique: true };
      }
    }
    return null;
  }
}
