<form *appTranslate="let t">
  <p-card [header]="getHeader()">
    <p-fieldset [legend]="t('contractConditions.head')">
      <div class="formgrid grid">
        <div class="field col">
          <div class="block mb-2">
            <label for="contractSettings">{{ t("contractConditions.detail") }}</label>
            <label [pTooltip]="t('contractConditions.explanation')" class="ml-2 pi pi-info-circle"></label>
          </div>
          <p-dropdown
            class="w-full"
            id="contractSettings"
            [placeholder]="t('contractConditions.placeholder')"
            [options]="contractSettings"
            [showClear]="true"
            [(ngModel)]="contractSetting"
            name="contractSettings"
            (onChange)="onChange()"
          >
          </p-dropdown>
        </div>
      </div>
    </p-fieldset>

    <div class="flex flex-row justify-content-end mt-3">
      <p-button
        [disabled]="!hasChanged"
        [label]="t('common.save')"
        icon="pi pi-check"
        (onClick)="submit()"
        class="mr-2"
      ></p-button>
      <p-button
        closeOnEscape
        [label]="t('common.cancel')"
        icon="pi pi-times"
        class="p-button-secondary"
        type="reset"
        (onClick)="cancel()"
      ></p-button>
    </div>
  </p-card>
</form>
