<ng-container *appTranslate="let t">
  <form [formGroup]="form" (ngSubmit)="save()">
    <p-card [header]="form.controls.id.value ? t('educationCourse.edit') : t('educationCourse.create')">
      <div class="p-fluid formgrid">
        <div class="field">
          <label for="name">{{ t("name.title") }}</label>
          <input type="text" pInputText id="name" formControlName="name" />
          <small class="p-error" *ngIf="form.controls.name.errors?.required">{{ t("name.required") }}</small>
          <small class="p-error" *ngIf="form.controls.name.errors?.maxlength">
            {{ t("common.validation.maxLength", { length: form.controls.name.errors.maxlength.requiredLength }) }}
          </small>
        </div>

        <div class="field">
          <label for="description">{{ t("description.title") }}</label>
          <input type="text" pInputText id="description" formControlName="description" />
          <small class="p-error" *ngIf="form.controls.description.errors?.maxlength">
            {{
              t("common.validation.maxLength", { length: form.controls.description.errors.maxlength.requiredLength })
            }}
          </small>
        </div>

        <div class="field">
          <label for="moduleId">{{ t("educationModule.title") }}</label>
          <app-education-module-selector
            [educationModuleIdControl]="form.controls.moduleId"
            [modulePlaceholder]="t('educationModule.placeholder')"
            [educationModules]="educationModules$ | async"
          ></app-education-module-selector>
          <small class="p-error" *ngIf="form.controls.moduleId.errors?.required">
            {{ t("educationModule.required") }}
          </small>
        </div>

        <div class="field">
          <label for="providerOrganizationId">{{ t("educationProvider.title") }}</label>
          <input
            type="text"
            pInputText
            id="providerOrganizationId"
            [value]="providerOrganizationName$ | async"
            readonly
          />
        </div>

        <div class="field">
          <label for="teacherUserIds">{{ t("teachers.title") }}</label>
          <p-multiSelect
            inputId="teacherUserIds"
            formControlName="teacherUserIds"
            [options]="providerOrganizationTeachers$ | async"
            optionLabel="name"
            optionValue="id"
            [placeholder]="t('teachers.placeholder')"
          ></p-multiSelect>
          <small class="p-error" *ngIf="form.controls.teacherUserIds.errors?.required">
            {{ t("teachers.required") }}
          </small>
        </div>

        <div class="field">
          <label for="maxParticipants">{{ t("maxParticipants.title") }}</label>
          <p-inputNumber inputId="maxParticipants" formControlName="maxParticipants" [min]="1"></p-inputNumber>
          <small class="p-error" *ngIf="form.controls.maxParticipants.errors?.required">
            {{ t("maxParticipants.required") }}
          </small>
        </div>

        <div class="field">
          <label for="participantCollectionId">{{ t("participantCollection.title") }}</label>
          <p-dropdown
            inputId="participantCollectionId"
            formControlName="participantCollectionId"
            [options]="participantCollections$ | async"
            optionLabel="name"
            optionValue="id"
            [placeholder]="t('participantCollection.placeholder')"
          ></p-dropdown>
          <small class="p-error" *ngIf="form.controls.participantCollectionId.errors?.required">
            {{ t("participantCollection.required") }}
          </small>
        </div>

        <div class="field">
          <label for="startDate">{{ t("startDate.title") }}</label>
          <p-calendar inputId="startDate" formControlName="startDate"></p-calendar>
          <small class="p-error" *ngIf="form.controls.startDate.errors?.required">{{ t("startDate.required") }}</small>
        </div>

        <div class="field">
          <label for="endDate">{{ t("endDate.title") }}</label>
          <p-calendar
            inputId="endDate"
            formControlName="endDate"
            [minDate]="form.controls.startDate.value"
          ></p-calendar>
          <small class="p-error" *ngIf="form.controls.endDate.errors?.invalid">{{ t("endDate.invalid") }}</small>
          <small class="p-error" *ngIf="form.controls.endDate.errors?.required">{{ t("endDate.required") }}</small>
        </div>
      </div>

      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
          <p-button
            [disabled]="!form.valid || !form.dirty"
            [label]="t('common.save')"
            icon="pi pi-check"
            type="submit"
            class="mr-2"
          ></p-button>

          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            type="reset"
            (onClick)="close()"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  </form>
</ng-container>
