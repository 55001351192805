import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewContainerRef } from "@angular/core";
import { CandidateTabConfiguration } from "@ankaadia/ankaadia-shared";
import { Candidate, SharedField, SharedTab } from "@ankaadia/graphql";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { compact, join } from "lodash";
import { BehaviorSubject, forkJoin, startWith } from "rxjs";
import { TemplateOptionsService } from "../../../formly/template-options/template-options.service";
import { OrganizationFactoryService } from "../../../organization-specific/organization-specific-factory.service";
import { ProfileForm } from "../../candidate-form/candidate-form.model";
import { OrganizationSystemSettingsService } from "../../organizations/system-settings.service";
import { CandidateProfileComponent as CandidateProfileComponentInterface } from "./candidate-profile.model";

@Component({
  selector: "app-candidate-profile",
  templateUrl: "./candidate-profile.component.html",
  standalone: false,
})
export class CandidateProfileComponent implements OnInit, OnChanges {
  private dynamicComponent: CandidateProfileComponentInterface;

  @Input()
  form: ProfileForm;

  @Input()
  readonly: boolean;

  @Input()
  candidate: Candidate;

  @Input()
  tabs: CandidateTabConfiguration;

  @Input()
  tabConfiguration: SharedTab[];

  @Input()
  profession: BehaviorSubject<string>;

  @Input()
  immigrationCountry: BehaviorSubject<string>;

  @Input()
  isMultiEditMode: boolean;

  @Input()
  enabledFields: string[];

  @Input()
  isLoading: boolean;

  @ViewChild("host", { static: true, read: ViewContainerRef })
  host: ViewContainerRef;

  constructor(
    private readonly orgFactory: OrganizationFactoryService,
    private readonly systemSettingsService: OrganizationSystemSettingsService,
    private readonly templateOptionsService: TemplateOptionsService
  ) {}

  ngOnInit(): void {
    this.orgFactory.getOrganizationSpecifics(this.candidate.organizationId).subscribe((specific) => {
      this.host.clear();
      const componentRef = this.host.createComponent(specific.getCandidateProfileComponent());
      this.dynamicComponent = componentRef.instance;
      this.dynamicComponent.form = this.form;
      this.dynamicComponent.readonly = this.readonly;
      this.dynamicComponent.isLoading = this.isLoading;
      this.dynamicComponent.candidate = this.candidate;
      this.dynamicComponent.tabs = this.tabs;
      this.dynamicComponent.profession = this.profession;
      this.dynamicComponent.immigrationCountry = this.immigrationCountry;
      this.dynamicComponent.updateFields$.pipe(startWith(this.candidate.organizationId)).subscribe((organizationId) => {
        this.updateFields(organizationId);
      });
      componentRef.changeDetectorRef.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dynamicComponent) {
      if (changes.form) {
        this.dynamicComponent.form = changes.form.currentValue;
      }
      if (changes.readonly) {
        this.dynamicComponent.readonly = changes.readonly.currentValue;
      }
      if (changes.isLoading) {
        this.dynamicComponent.isLoading = changes.isLoading.currentValue;
      }
      if (changes.candidate) {
        this.dynamicComponent.candidate = changes.candidate.currentValue;
      }
      if (changes.tabs) {
        this.dynamicComponent.tabs = changes.tabs.currentValue;
        this.updateFields(this.candidate.organizationId);
      }
      if (changes.tabConfiguration) {
        this.updateFields(this.candidate.organizationId);
      }
    }
  }

  private updateFields(organizationId: string): void {
    forkJoin([
      this.orgFactory.getOrganizationSpecifics(organizationId),
      this.systemSettingsService.getCandidateOwnerSpecificSettings(organizationId),
    ]).subscribe(([specifics, settings]) => {
      const fields = specifics.getCandidateProfileFormlyFields(
        this.isMultiEditMode ? null : settings.requiredCandidateFields,
        settings.workingExperiencePrecision,
        settings.educationPrecision
      );
      this.handleEnabledFields(fields, this.enabledFields);
      this.dynamicComponent.fields = this.handleProfileSetConfiguration(fields);
    });
  }

  /**
   * Formly could not care less about our normal (1) attempt to mark the fields as disabled
   * because it populates the form controls much later. It also does not seem to provide
   * an event (2) when the form is populated, so we'll disable the fields by patching
   * the formly definition.
   * (1) {@link CandidateFormComponent.handleEnabledFields}
   * (2) {@link https://github.com/ngx-formly/ngx-formly/issues/1912}
   */
  private handleEnabledFields(fields: FormlyFieldConfig[], enabledFields: string[]): void {
    const process = (fields: FormlyFieldConfig[], prefix: string): void => {
      for (const field of fields) {
        const fieldPath = join(compact([prefix, field.key]), ".");
        if (field.fieldGroup) {
          process(field.fieldGroup, fieldPath);
        } else if (field.key && field.props?.label) {
          if (!enabledFields.includes(fieldPath)) {
            field.props.disabled = true;
          }
        }
      }
    };
    if (enabledFields) {
      process(fields[0].fieldGroup, "os.profile");
    }
  }

  private handleProfileSetConfiguration(fields: FormlyFieldConfig[]): FormlyFieldConfig[] {
    if (!this.tabConfiguration.some((x) => x.sharedFields)) return fields;

    return fields.map((rootFields) => ({
      ...rootFields,
      fieldGroup: rootFields.fieldGroup
        ?.filter((x) => this.findSharedTab(x.id))
        .map((tab) => ({
          ...tab,
          fieldGroup: tab.fieldGroup
            ?.filter(
              (fieldGroup) =>
                this.isFormlyTabFullyShared(tab) ||
                this.findSharedField(fieldGroup.key?.toString()) ||
                fieldGroup.fieldGroup
            )
            ?.map((fieldGroup) => {
              const sharedTab = this.tabConfiguration.find((x) => x.key === tab.id);

              if (this.isSharedTabFullyShared(sharedTab)) {
                return this.adjustField(fieldGroup, {
                  name: fieldGroup.key?.toString(),
                  isMandatory: false,
                  isEnabled: true,
                });
              }

              const sharedFieldGroup = sharedTab?.sharedFields?.find((x) => x.name === fieldGroup.key);

              if (sharedFieldGroup) return this.adjustField(fieldGroup, sharedFieldGroup);
              return {
                ...fieldGroup,
                fieldGroup: fieldGroup.fieldGroup
                  ?.flatMap((x) => x.fieldGroup ?? x)
                  ?.filter(
                    (fieldGroup) =>
                      this.isSharedTabFullyShared(sharedTab) || this.findSharedField(fieldGroup?.key?.toString())
                  )
                  ?.map((field) => this.mapFormlyField(tab, field))
                  ?.filter((x) => x),
                fieldArray: (fieldGroup.fieldArray as any)?.fieldGroup
                  ?.filter((fieldGroup) => this.findSharedField(fieldGroup.key))
                  ?.map((field: FormlyFieldConfig) => this.mapFormlyField(tab, field))
                  ?.filter((x: FormlyFieldConfig) => x),
              };
            }),
        })),
    }));
  }

  private findSharedField(name: string): boolean {
    const dependantFields = [{ key: "vaccinations", dependant: ["vaccinationInformation"] }];
    return this.tabConfiguration.flatMap((x) => x.sharedFields).some((x) => x?.name === name || isDependantField(x));

    function isDependantField(sharedField: SharedField): boolean {
      return dependantFields.some(
        (dependantField) => sharedField?.name === dependantField.key && dependantField.dependant.includes(name)
      );
    }
  }

  private findSharedTab(key: string): boolean {
    return this.tabConfiguration.some((y) => y.key === key);
  }

  private mapFormlyField(tab: FormlyFieldConfig, field: FormlyFieldConfig): FormlyFieldConfig {
    const pTab = this.tabConfiguration.find((x) => x.key === tab.id);

    if (!pTab) return undefined;

    if (this.isSharedTabFullyShared(pTab)) return this.adjustField(field, this.fullySharedField(field.key?.toString()));

    const pField = pTab.sharedFields.find((x) => x.name === field.key);

    if (!pField) return undefined;
    else return this.adjustField(field, pField);
  }

  private fullySharedField(name: string): SharedField {
    return { name: name, isMandatory: false, isEnabled: true };
  }

  private adjustField(field: FormlyFieldConfig, sharedField: SharedField): FormlyFieldConfig {
    const doNotRestyle = {
      types: ["repeat", "repeat-tabs"],
      keys: [
        "wayToUs",
        "hobbies",
        "socialSkills",
        "itSkills",
        "familiarEquipment",
        "certifiedEducationExams",
        "languageLevelCV",
        "languageLevelByPlacementTest",
      ],
    };

    if (!doNotRestyle.types.includes(field.type as string) && !doNotRestyle.keys.includes(<string>field.key)) {
      field.className = [
        "field",
        "col-12",
        "lg:col-4",
        "xl:col-3",
        ...(field.className ?? "").split(" ").filter((x) => !/^field|col|xs:|md:|lg:|xl:/.test(x)),
      ].join(" ");
    }

    if (this.isFieldReadOnly(sharedField)) this.templateOptionsService.makeFieldReadOnly(field);
    else if (sharedField.isMandatory) this.templateOptionsService.makeFieldMandatory(field);

    return field;
  }

  private isFieldReadOnly(field: SharedField): boolean {
    return !field.isEnabled;
  }

  private isFormlyTabFullyShared(tab: FormlyFieldConfig): boolean {
    const sharedTab = this.tabConfiguration.find((x) => x.key === tab?.id);
    return this.isSharedTabFullyShared(sharedTab);
  }

  private isSharedTabFullyShared(sharedTab: SharedTab): boolean {
    return sharedTab && sharedTab.sharedFields === null;
  }
}
