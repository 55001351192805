import { DestroyRef, Injectable } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import {
  ContractCreateInput,
  DayOfYear,
  DiscountType,
  PaymentPeriod,
  PaymentTime,
  PhaseType,
  SupportedBillingCurrency,
} from "@ankaadia/graphql";
import { GraphQLFormModel } from "../../../shared/services/form.helper";
import { FormElementGroup } from "ngx-mf";
import { v4 as uuidv4 } from "uuid";
import { CustomValidators } from "../../../shared/validation/custom.validators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

export type ContractCreateForm = GraphQLFormModel<
  ContractCreateInput,
  {
    phases: [
      {
        candidateDiscounts: [FormElementGroup];
        licenseDiscounts: [FormElementGroup];
      },
    ];
    candidatePaymentSettings: [
      {
        paymentTranches: [FormElementGroup];
      },
    ];
    licensePaymentSettings: [
      {
        paymentTranches: [FormElementGroup];
      },
    ];
  }
>;

export type PaymentSettingsForm = ContractCreateForm["controls"]["licensePaymentSettings"]["controls"][number];

export type PhaseForm = ContractCreateForm["controls"]["phases"]["controls"][number];

export type DiscountForm = PhaseForm["controls"]["candidateDiscounts"]["controls"][number];

export type PaymentTrancheForm = PaymentSettingsForm["controls"]["paymentTranches"]["controls"][number];

@Injectable({ providedIn: "root" })
export class ContractFormService {
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly destroyRef: DestroyRef
  ) {}

  createContractCreateForm(): ContractCreateForm {
    const contractStartDate = this.formBuilder.control<Date>(null, Validators.required);

    const form = this.formBuilder.group<ContractCreateForm["controls"]>({
      startDate: contractStartDate,
      currency: this.formBuilder.control<SupportedBillingCurrency>(SupportedBillingCurrency.Eur, Validators.required),
      phases: this.formBuilder.array<PhaseForm>([this.createPhaseForm(contractStartDate)], Validators.required),
      candidatePaymentSettings: this.formBuilder.array<PaymentSettingsForm>(
        [this.createPaymentSettingsForm("candidate", contractStartDate)],
        Validators.required
      ),
      licensePaymentSettings: this.formBuilder.array<PaymentSettingsForm>(
        [this.createPaymentSettingsForm("license", contractStartDate)],
        Validators.required
      ),
    });

    form.get("phases").addValidators(CustomValidators.notEmptyArray);
    form.get("candidatePaymentSettings").addValidators(CustomValidators.notEmptyArray);
    form.get("licensePaymentSettings").addValidators(CustomValidators.notEmptyArray);

    return form;
  }

  createDiscountForm(): DiscountForm {
    const form = this.formBuilder.group<DiscountForm["controls"]>({
      id: this.formBuilder.control<string>(uuidv4(), Validators.required),
      type: this.formBuilder.control<DiscountType>(null, Validators.required),
      value: this.formBuilder.control<number>(null, Validators.required),
      startDate: this.formBuilder.control<Date>(null, Validators.required),
      endDate: this.formBuilder.control<Date>(null),
      maxUses: this.formBuilder.control<number>(null),
    });

    form.get("endDate").addValidators(CustomValidators.sameOrAfter(form.get("startDate")));

    return form;
  }

  createPhaseForm(contractStartDate: AbstractControl<Date>): PhaseForm {
    const form = this.formBuilder.group<PhaseForm["controls"]>({
      startDate: this.formBuilder.control<Date>(null, Validators.required),
      type: this.formBuilder.control<PhaseType>(PhaseType.Normal, Validators.required),
      planId: this.formBuilder.control<string>(null, Validators.required),
      candidateDiscounts: this.formBuilder.array<DiscountForm>([]),
      licenseDiscounts: this.formBuilder.array<DiscountForm>([]),
    });

    form.get("startDate").addValidators(CustomValidators.sameOrAfter(contractStartDate));

    return form;
  }

  createPaymentSettingsForm(
    target: "candidate" | "license",
    contractStartDate: AbstractControl<Date>
  ): PaymentSettingsForm {
    const defaultPeriod = target === "candidate" ? PaymentPeriod["1M"] : PaymentPeriod["3M"];
    const defaultTime = target === "candidate" ? PaymentTime.InArrears : PaymentTime.InAdvance;

    const form = this.formBuilder.group<PaymentSettingsForm["controls"]>({
      startDate: this.formBuilder.control<Date>(null, Validators.required),
      paymentTime: this.formBuilder.control<PaymentTime>(defaultTime, Validators.required),
      paymentTranches: this.formBuilder.array<PaymentTrancheForm>([]),
      period: this.formBuilder.control<PaymentPeriod>(defaultPeriod, Validators.required),
    });

    form.get("startDate").addValidators(CustomValidators.sameOrAfter(contractStartDate));

    form.get("paymentTranches").addValidators((tranches: FormArray<PaymentTrancheForm>) => {
      if (tranches.length === 0) {
        return null;
      }

      const sumPaymentTranches = tranches.value.reduce((a, b) => a + b.percentage, 0);
      if (sumPaymentTranches !== 100) {
        return { sumPaymentTranches: true };
      }

      return null;
    });

    form
      .get("period")
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((period) => {
        if (period !== PaymentPeriod["1Y"]) {
          form.get("paymentTranches").setValue([]);
        }
      });

    return form;
  }

  createPaymentTrancheForm(): PaymentTrancheForm {
    return this.formBuilder.group<PaymentTrancheForm["controls"]>({
      dueDate: this.formBuilder.control<DayOfYear>(null, Validators.required),
      percentage: this.formBuilder.control<number>(null, Validators.required),
    });
  }
}
