import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { RecognitionProfessionalExperience } from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { catchError, throwError } from "rxjs";
import { OrganizationsService } from "../../organizations/organizations.service";

@Component({
  selector: "app-contract-conditions-dialog",
  templateUrl: "./contract-conditions.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ContractConditionsComponent implements OnInit {
  readonly language = this.transloco.getActiveLang();

  @Output()
  readonly closed = new EventEmitter<void>();

  @Output()
  readonly saved = new EventEmitter<void>();

  @Input()
  organizationId: string;

  @Input()
  organizationName: string;

  contractSetting: any;

  hasChanged = false;

  contractSettings = [
    {
      label: translate("enum.RecognitionProfessionalExperience.FullRecognition"),
      value: RecognitionProfessionalExperience.FullRecognition,
    },
    {
      label: translate("enum.RecognitionProfessionalExperience.PartialRecognition"),
      value: RecognitionProfessionalExperience.PartialRecognition,
    },
    {
      label: translate("enum.RecognitionProfessionalExperience.NoRecognition"),
      value: RecognitionProfessionalExperience.NoRecognition,
    },
  ];

  constructor(
    private readonly organizationsService: OrganizationsService,
    private readonly transloco: TranslocoService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.organizationsService.getContractSettings(this.organizationId).subscribe((x) => {
      if (x.recognitionProfessionalExperience !== null) {
        this.contractSetting = x.recognitionProfessionalExperience;
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  getHeader(): string {
    const conditions = translate("contractConditions.title");
    return this.organizationName ? [conditions, this.organizationName].join(" ") : conditions;
  }

  onChange(): void {
    this.hasChanged = true;
  }

  submit(): void {
    this.organizationsService
      .updateContractSettings({
        id: this.organizationId,
        recognitionProfessionalExperience: this.contractSetting,
      })
      .pipe(
        catchError((error) => {
          this.closed.emit();
          return throwError(() => error);
        })
      )
      .subscribe(() => {
        this.saved.emit();
      });
  }

  cancel(): void {
    this.closed.emit();
  }
}
