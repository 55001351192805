import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { DocumentTypeOverwrite } from "@ankaadia/graphql";

@Component({
  selector: "app-custom-document-type-dialog",
  templateUrl: "./custom-document-type-dialog.component.html",
  standalone: false,
})
export class CustomDocumentTypeDialogComponent implements OnInit {
  @Input() isEdit: boolean;

  @Input()
  document: DocumentTypeOverwrite;

  @Input()
  availableFileTypes: { value: string; label: string }[];

  @Input()
  documentSets: DocumentTypeOverwrite[];

  @Input()
  staticTypeNames: string[];

  @Input()
  staticFileNames: string[];

  @Output()
  readonly saved = new EventEmitter<DocumentTypeOverwrite>();

  @Output()
  readonly closed = new EventEmitter<void>();

  form: FormGroup;
  disableSubmit = false;
  submitDisabled: boolean;
  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit(): void {
    const fileNameValidators = Validators.compose([
      Validators.required,
      this.forbiddenFileNameValidator.bind(this),
      this.uniqueFileNameValidator.bind(this),
    ]);
    const nameValidators = Validators.compose([Validators.required, this.uniqueNameValidator.bind(this)]);
    this.form = this.formBuilder.group({
      id: [this.document.id],
      organizationId: [this.document.organizationId],
      description: [this.document?.description],
      name: [this.document.name, nameValidators],
      fileName: [this.document.fileName, fileNameValidators],
      type: [this.document.type, Validators.required],
    });

    this.form.markAsDirty();
  }

  submit(): void {
    this.submitDisabled = true;
    this.saved.emit(this.form.value);
  }

  isValid(fileName: string): boolean {
    const rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
    const rg2 = /^(other[0-9])(\.|$)/i; // forbidden file names
    return rg1.test(fileName) && !rg2.test(fileName) && !fileName.startsWith(".");
  }

  private forbiddenFileNameValidator(control: AbstractControl<string>): ValidationErrors | null {
    if (control.value && !this.isValid(control.value)) {
      return { valid: true };
    }
    return null;
  }

  private uniqueFileNameValidator(control: AbstractControl<string>): ValidationErrors | null {
    if ((control.value && this.documentSets?.length > 0) || this.staticFileNames?.length > 0) {
      const value = control.value?.trim();

      const doc = this.getByKey("fileName", value);
      if ((doc && this.document.fileName !== doc.fileName) || this.staticFileNames?.includes(value)) {
        return { unique: true };
      }
    }
    return null;
  }

  private uniqueNameValidator(control: AbstractControl<string>): ValidationErrors | null {
    if ((control.value && this.documentSets?.length > 0) || this.staticTypeNames?.length > 0) {
      const value = control.value?.trim();
      const doc = this.getByKey("name", value);

      if ((doc && this.document.name !== doc.name) || this.staticTypeNames?.includes(value)) {
        return { unique: true };
      }
    }
    return null;
  }

  getByKey(key: string, value: string): DocumentTypeOverwrite {
    return this.documentSets?.find((item) => item[key] == value);
  }
}
