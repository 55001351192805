import { Injectable } from "@angular/core";
import {
  GetOrganizationProfileFieldsGQL,
  GetOrganizationProfileGQL,
  OrganizationProfile,
  OrganizationProfileFields,
  OrganizationProfileFragment,
  SetOrganizationProfileGQL,
} from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class OrganizationProfileService {
  constructor(
    private readonly profileFieldsGet: GetOrganizationProfileFieldsGQL,
    private readonly profileGet: GetOrganizationProfileGQL,
    private readonly profileSet: SetOrganizationProfileGQL
  ) {}

  getFields(organizationId: string): Observable<OrganizationProfileFields> {
    return this.profileFieldsGet
      .fetch({ input: { organizationId } })
      .pipe(map((x) => x.data.getOrganizationProfileFields));
  }

  get(organizationId: string): Observable<OrganizationProfileFragment> {
    return this.profileGet
      .fetch({ input: { organizationId } })
      .pipe(map((x) => x.data.getOrganizationProfile ?? this.newProfile(organizationId)));
  }

  set(profile: OrganizationProfileFragment): Observable<OrganizationProfileFragment> {
    return this.profileSet.mutate({ input: profile }).pipe(map((x) => x.data.setOrganizationProfile));
  }

  private newProfile(organizationId: string): OrganizationProfileFragment {
    const profile = new OrganizationProfile();
    profile.organizationId = organizationId;
    profile.isMatchingEnabled = false;
    return profile;
  }
}
