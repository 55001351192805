<form *transloco="let t">
  <p-table styleClass="p-datatable" [value]="form.controls" [sortOrder]="-1">
    <ng-template pTemplate="header">
      <tr>
        <th>{{ t("laborContract.allowance.type.title") }}</th>
        <th>{{ t("laborContract.allowance.calculationBasis.title") }}</th>
        <th>{{ t("laborContract.allowance.amount.title") }}</th>
        <th>{{ t("laborContract.allowance.comment.title") }}</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="100" [style.padding.rem]="0.75">
          {{ t("laborContract.allowances.emptyMessage") }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      <tr [ngClass]="{ 'has-errors': !rowData.valid || !form.valid }">
        <td>{{ getAutoCompleteLabel(rowData) }}</td>
        <td>
          <p-selectButton
            [options]="calculationBases"
            [formControl]="rowData.controls.calculationBasis"
            [allowEmpty]="false"
            optionLabel="label"
            optionValue="value"
          />
          <small class="p-info" *ngIf="getOverride(rowData, 'calculationBasis') as override">
            <i class="pi pi-link"></i> {{ getOverrideLabel(override, { staticData: calculationBases }) }}
          </small>
        </td>
        <td>
          <p-inputNumber
            inputId="amount"
            [formControl]="rowData.controls.amount"
            [placeholder]="t('laborContract.allowance.amount.placeholder')"
            [minFractionDigits]="2"
            [maxFractionDigits]="2"
            [min]="0"
            [max]="rowData.controls.calculationBasis.value === CalculationBasis.Percentage ? 1000 : null"
          />
          <small class="p-info" *ngIf="getOverride(rowData, 'amount') as override">
            <i class="pi pi-link"></i>
            {{ getOverrideLabel(override) }}
          </small>
        </td>
        <td>
          <input
            inputId="comment"
            [formControl]="rowData.controls.comment"
            [placeholder]="t('laborContract.allowance.comment.placeholder')"
            type="text"
            pInputText
          />
        </td>
        <td>
          <p-button *ngIf="!rowData.controls.isOverride.value" icon="pi pi-trash" (onClick)="delete(rowData)" />
        </td>
      </tr>
      <tr *ngIf="!rowData.valid || !form.valid" class="error-detail">
        <ng-container *ngIf="rowData.errors?.typeRequired; else noRequiredValuesMissing">
          <td>
            <small class="p-error">
              {{ t("required") }}
            </small>
          </td>
          <td colspan="100"></td>
        </ng-container>
        <ng-template #noRequiredValuesMissing>
          <td colspan="100">
            <small class="p-error" *ngIf="rowData.errors?.corruptType">
              {{ t("laborContract.errors.corruptAllowanceType") }}
            </small>
            <small class="p-error" *ngIf="form.errors?.unique?.includes(rowIndex)">
              {{ t("laborContract.errors.duplicateAllowance") }}
            </small>
            <small class="p-error" *ngIf="rowData.errors?.percentage">
              {{ t("laborContract.errors.allowancePercentage") }}
            </small>
          </td>
        </ng-template>
      </tr>
    </ng-template>
  </p-table>
  <div class="flex" [style.padding]="'0.5rem 0.75rem 0 0.75rem'">
    <p-button [label]="t('laborContract.allowances.add.title')" icon="pi pi-plus" (onClick)="add($event)" />
  </div>
  <p-overlayPanel #addOverlay>
    <ng-template pTemplate>
      <div [formGroup]="newAllowance">
        <div class="flex gap-2 align-items-stretch">
          <p-autoComplete
            inputId="type"
            [dropdown]="true"
            [suggestions]="selectableAllowanceTypes"
            [placeholder]="t('laborContract.allowance.type.placeholder')"
            [ngModel]="{
              value: newAllowance.controls.type.value,
              label: getAutoCompleteLabel(newAllowance),
            }"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="updateType(newAllowance, $event)"
            (completeMethod)="cloneAllowanceTypes()"
            dataKey="value"
            optionLabel="label"
          />
          <button
            submitOnEnter
            type="button"
            pButton
            [label]="t('common.ok')"
            [disabled]="!canAdd"
            (click)="create(newAllowance); addOverlay.hide()"
          ></button>
        </div>
      </div>
    </ng-template>
  </p-overlayPanel>
</form>
