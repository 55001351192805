<ng-container *appTranslate="let t">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <p-card [header]="t('organization.linkFull')">
      <div class="p-fluid">
        <div class="field">
          <label for="linkedOrganizationId">{{ t("organization.title") }}</label>
          <p-dropdown
            inputId="linkedOrganizationId"
            formControlName="linkedOrganizationId"
            [options]="organizations"
            optionValue="id"
            filterBy="code,name"
            [placeholder]="t('organization.placeholder')"
            [class.p-disabled]="!!form.value.id"
            [readonly]="!!form.value.id"
          >
            <ng-template *ngFor="let template of ['item', 'selectedItem']" let-organization [pTemplate]="template">
              <span>{{ organization.name }}</span>
              <span class="p-text-secondary ml-1">{{ organization.code }}</span>
            </ng-template>
          </p-dropdown>

          <small class="p-error" *ngIf="form.controls.linkedOrganizationId.errors?.required">
            {{ t("organization.required") }}
          </small>
        </div>

        <h6 class="mt-4">{{ t("shareData.title") }}</h6>

        <p-fieldset [legend]="t('shareContacts.title')">
          <div class="field-radiobutton">
            <p-radioButton
              inputId="shareContacts_Null"
              name="shareContacts"
              formControlName="shareContacts"
              [value]="null"
            ></p-radioButton>
            <label for="shareContacts_Null">{{ t("notShared.title") }}</label>
          </div>

          <div class="field-radiobutton">
            <p-radioButton
              inputId="shareContacts_ShareAll"
              name="shareContacts"
              formControlName="shareContacts"
              [value]="ContactSharingMode.ShareAll"
            ></p-radioButton>
            <label for="shareContacts_ShareAll">{{ t("enum.ContactSharingMode.ShareAll") }}</label>
          </div>

          <div class="field-radiobutton">
            <p-radioButton
              inputId="shareContacts_ShareSelected"
              name="shareContacts"
              formControlName="shareContacts"
              [value]="ContactSharingMode.ShareSelected"
            ></p-radioButton>
            <label for="shareContacts_ShareSelected">{{ t("enum.ContactSharingMode.ShareSelected") }}</label>
          </div>

          <div *ngIf="form.controls.shareContacts.value === ContactSharingMode.ShareSelected" class="field mb-3">
            <p-multiSelect
              inputId="selectedContacts"
              formControlName="selectedContacts"
              [options]="contacts$ | async"
              optionLabel="name"
              optionValue="id"
              [placeholder]="t('contact.placeholder')"
              [showClear]="true"
              styleClass="ml-5"
            ></p-multiSelect>

            <small class="p-error ml-5" *ngIf="form.controls.selectedContacts.errors?.required">
              {{ t("contact.required") }}
            </small>
          </div>

          <div class="field-radiobutton">
            <p-radioButton
              inputId="shareContacts_ShareDefault"
              name="shareContacts"
              formControlName="shareContacts"
              [value]="ContactSharingMode.ShareDefault"
            ></p-radioButton>
            <label for="shareContacts_ShareDefault">{{ t("enum.ContactSharingMode.ShareDefault") }}</label>
          </div>
        </p-fieldset>

        <p-fieldset [legend]="t('shareContractTemplates.title')">
          <div class="field-radiobutton">
            <p-radioButton
              inputId="shareContractTemplates_Null"
              name="shareContractTemplates"
              formControlName="shareContractTemplates"
              [value]="null"
            ></p-radioButton>
            <label for="shareContractTemplates_Null">{{ t("notShared.title") }}</label>
          </div>

          <div class="field-radiobutton">
            <p-radioButton
              inputId="shareContractTemplates_ShareAll"
              name="shareContractTemplates"
              formControlName="shareContractTemplates"
              [value]="ContractTemplateSharingMode.ShareAll"
            ></p-radioButton>
            <label for="shareContractTemplates_ShareAll">{{ t("enum.ContractTemplateSharingMode.ShareAll") }}</label>
          </div>

          <div class="field-radiobutton">
            <p-radioButton
              inputId="shareContractTemplates_ShareSelected"
              name="shareContractTemplates"
              formControlName="shareContractTemplates"
              [value]="ContractTemplateSharingMode.ShareSelected"
            ></p-radioButton>
            <label for="shareContractTemplates_ShareSelected">
              {{ t("enum.ContractTemplateSharingMode.ShareSelected") }}
            </label>
          </div>

          <div
            *ngIf="form.controls.shareContractTemplates.value === ContractTemplateSharingMode.ShareSelected"
            class="field mb-3"
          >
            <p-multiSelect
              inputId="selectedContractTemplates"
              formControlName="selectedContractTemplates"
              [options]="contractTemplates$ | async"
              optionLabel="name"
              optionValue="id"
              [placeholder]="t('contractTemplate.placeholder')"
              [showClear]="true"
              styleClass="ml-5"
            ></p-multiSelect>

            <small class="p-error ml-5" *ngIf="form.controls.selectedContractTemplates.errors?.required">
              {{ t("contractTemplate.required") }}
            </small>
          </div>

          <p-message
            *ngIf="
              form.controls.shareContractTemplates.value === ContractTemplateSharingMode.ShareSelected ||
              form.controls.shareContractTemplates.value === ContractTemplateSharingMode.ShareAll
            "
            class="block mt-3"
            severity="info"
            [text]="t('laborAgreements.sharingReason')"
          />
        </p-fieldset>

        <p-fieldset [legend]="t('shareEmploymentRelationship.title')">
          <div class="field-radiobutton">
            <p-radioButton
              inputId="shareEmploymentRelationship_Null"
              name="shareEZBData"
              formControlName="shareEZBData"
              [value]="null"
            ></p-radioButton>
            <label for="shareEmploymentRelationship_Null">{{ t("notShared.title") }}</label>
          </div>

          <div class="field-radiobutton">
            <p-radioButton
              inputId="shareEmploymentRelationship_Share"
              name="shareEZBData"
              formControlName="shareEZBData"
              [value]="EZBDataSharingMode.Share"
            ></p-radioButton>
            <label for="shareEmploymentRelationship_Share">{{
              t("enum.EmploymentRelationshipSharingMode.Share")
            }}</label>
          </div>
        </p-fieldset>

        <p-fieldset [legend]="t('sharePartners.title')">
          <div class="field-radiobutton">
            <p-radioButton
              inputId="sharePartners_Null"
              name="sharePartners"
              formControlName="sharePartners"
              [value]="null"
            ></p-radioButton>
            <label for="sharePartners_Null">{{ t("notShared.title") }}</label>
          </div>

          <div class="field-radiobutton">
            <p-radioButton
              inputId="sharePartners_ShareAll"
              name="sharePartners"
              formControlName="sharePartners"
              [value]="PartnerSharingMode.ShareAll"
            ></p-radioButton>
            <label for="sharePartners_ShareAll">{{ t("enum.PartnerSharingMode.ShareAll") }}</label>
          </div>

          <div
            *ngIf="form.controls.sharePartners.value === PartnerSharingMode.ShareAll"
            class="field grid ml-5"
            formGroupName="partnerSharing"
          >
            <div class="field-checkbox col-6">
              <p-checkbox
                inputId="shareContactsOfMyPartners"
                formControlName="shareContacts"
                [binary]="true"
              ></p-checkbox>
              <label for="shareContactsOfMyPartners">{{ t("shareContactsOfMyPartners.title") }}</label>
            </div>

            <div class="field-checkbox col-6">
              <p-checkbox
                inputId="shareContractTemplatesOfMyPartners"
                formControlName="shareContractTemplates"
                [binary]="true"
              ></p-checkbox>
              <label for="shareContractTemplatesOfMyPartners">{{
                t("shareContractTemplatesOfMyPartners.title")
              }}</label>
            </div>

            <div class="field-checkbox col-6">
              <p-checkbox
                inputId="shareEmploymentRelationshipOfMyPartners"
                formControlName="shareEmploymentRelationship"
                [binary]="true"
              ></p-checkbox>
              <label for="shareEmploymentRelationshipOfMyPartners">{{
                t("shareEmploymentRelationshipOfMyPartners.title")
              }}</label>
            </div>

            <div class="field-checkbox col-6">
              <p-checkbox
                inputId="sharePartnersOfMyPartners"
                formControlName="sharePartners"
                [binary]="true"
              ></p-checkbox>
              <label for="sharePartnersOfMyPartners">{{ t("sharePartnersOfMyPartners.title") }}</label>
            </div>
            <div class="field-checkbox col-12">
              <p-checkbox
                inputId="allowLinkingToManagedAndSharedOrganisation"
                formControlName="allowLinkingInCaseItIsManaged"
                [binary]="true"
              ></p-checkbox>
              <label for="allowLinkingToManagedAndSharedOrganisation">{{
                t("allowLinkingToManagedAndSharedOrganisation.title")
              }}</label>
            </div>
          </div>

          <div class="field-radiobutton">
            <p-radioButton
              inputId="sharePartners_ShareSelected"
              name="sharePartners"
              formControlName="sharePartners"
              [value]="PartnerSharingMode.ShareSelected"
            ></p-radioButton>
            <label for="sharePartners_ShareSelected">{{ t("enum.PartnerSharingMode.ShareSelected") }}</label>
          </div>

          <div *ngIf="form.controls.sharePartners.value === PartnerSharingMode.ShareSelected" class="field mb-3">
            <p-table
              [value]="form.controls.selectedPartners.controls"
              dataKey="organizationId"
              formArrayName="selectedPartners"
              styleClass="ml-5"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>{{ t("organization.title") }}</th>
                  <th>{{ t("shareContactsOfMyPartners.titleShort") }}</th>
                  <th>{{ t("shareContractTemplatesOfMyPartners.titleShort") }}</th>
                  <th>{{ t("shareEmploymentRelationshipOfMyPartners.titleShort") }}</th>
                  <th>{{ t("sharePartnersOfMyPartners.titleShort") }}</th>
                  <th>{{ t("allowLinkingToManagedAndSharedOrganisation.titleShort") }}</th>
                  <th class="justify-content-end text-right" style="width: 4rem">
                    <p-button
                      icon="pi pi-plus"
                      styleClass="p-button-rounded p-button-text"
                      (click)="addSelectedPartner()"
                    ></p-button>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-partner let-i="rowIndex">
                <tr [formGroupName]="i">
                  <td>
                    <p-dropdown
                      formControlName="organizationId"
                      [options]="partners$ | async"
                      optionValue="id"
                      optionLabel="name"
                      [placeholder]="t('organization.placeholder')"
                    ></p-dropdown>

                    <small class="p-error" *ngIf="partner.controls.organizationId.errors?.required">
                      {{ t("organization.required") }}
                    </small>

                    <small class="p-error" *ngIf="partner.controls.organizationId.errors?.unique">
                      {{ t("organization.unique") }}
                    </small>
                  </td>

                  <td>
                    <p-checkbox formControlName="shareContacts" [binary]="true"></p-checkbox>
                  </td>

                  <td>
                    <p-checkbox formControlName="shareContractTemplates" [binary]="true"></p-checkbox>
                  </td>

                  <td>
                    <p-checkbox formControlName="shareEZBData" [binary]="true"></p-checkbox>
                  </td>

                  <td>
                    <p-checkbox formControlName="sharePartners" [binary]="true"></p-checkbox>
                  </td>
                  <td>
                    <p-checkbox formControlName="allowLinkingInCaseItIsManaged" [binary]="true"></p-checkbox>
                  </td>

                  <td class="justify-content-end text-right">
                    <p-button
                      icon="pi pi-trash"
                      styleClass="p-button-rounded p-button-text p-button-danger"
                      (click)="removeSelectedPartner(i)"
                    ></p-button>
                  </td>
                </tr>
              </ng-template>
            </p-table>

            <small class="p-error ml-5" *ngIf="form.controls.selectedPartners.errors?.required">
              {{ t("organizations.required") }}
            </small>
          </div>
        </p-fieldset>
      </div>

      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
          <p-button
            [disabled]="!form.valid || !form.dirty || isBusy"
            [label]="t('common.save')"
            icon="pi pi-check"
            type="submit"
            class="mr-2"
          ></p-button>
          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            type="reset"
            (onClick)="close()"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  </form>
</ng-container>
