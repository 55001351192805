<ng-container *appTranslate="let t">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <p-card
      [header]="isEdit ? t('requiredDocument.edit', immigrationCountry) : t('requiredDocument.add', immigrationCountry)"
    >
      <ng-template pTemplate="header"> </ng-template>

      <div class="p-fluid">
        <div class="field">
          <label for="name">{{ t("requiredDocument.name") }}</label>
          <input id="name" type="text" pInputText formControlName="name" required />
          <small class="p-error" *ngIf="form.controls.name.errors?.required">
            {{ t("name.required") }}
          </small>
          <small class="p-error" *ngIf="form.controls.name.errors?.unique">
            {{ t("name.unique") }}
          </small>
        </div>

        <div class="field">
          <label for="description">{{ t("comment.title") }}</label>
          <textarea rows="5" id="description" formControlName="description" pInputTextarea></textarea>
        </div>

        <div class="field-checkbox mt-1">
          <p-checkbox
            formControlName="setsAvailableForCandidates"
            [binary]="true"
            inputId="setsAvailableForCandidates"
          ></p-checkbox>
          <label for="setsAvailableForCandidates">{{ t("setsAvailableForCandidates.title") }}</label>
        </div>

        <div class="field">
          <label for="functions">{{ t("function.title") }}</label>
          <p-multiSelect
            inputId="functions"
            [options]="functions$ | async"
            [placeholder]="t('function.placeholder')"
            formControlName="functions"
          ></p-multiSelect>
        </div>
      </div>

      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
          <p-button
            [disabled]="!form.valid || !form.dirty || disableSubmit"
            [label]="t('common.save')"
            icon="pi pi-check"
            type="submit"
            class="mr-2"
          ></p-button>
          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            type="reset"
            (onClick)="closed.emit()"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  </form>
</ng-container>
