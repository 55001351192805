<ng-container *appTranslate="let t">
  <form [formGroup]="form" (ngSubmit)="save()">
    <p-card formArrayName="documents">
      <p-toolbar styleClass="mb-4">
        <ng-template pTemplate="left">
          <h5 class="mr-4">{{ requiredDocuments?.name }}</h5>
          <p-message *ngIf="!form.valid && errorMessage?.length > 0" severity="error" [text]="errorMessage"></p-message>
        </ng-template>
        <ng-template pTemplate="right">
          <div class="flex justify-content-between">
            <button
              pButton
              [label]="t('common.saveAndClose')"
              icon="pi pi-check"
              class="mr-2"
              [disabled]="!form.valid || !form.dirty"
              (click)="saveAndClose()"
            ></button>
            <button
              pButton
              type="submit"
              [label]="t('common.save')"
              icon="pi pi-check"
              [disabled]="!form.valid || !form.dirty"
              class="mr-2"
            ></button>
            <p-button [label]="t('common.cancel')" icon="pi pi-times" class="mr-2" (click)="cancel()"> </p-button>
            <button
              pButton
              type="button"
              [label]="t('common.clear')"
              class="p-button-outlined"
              icon="pi pi-filter-slash"
              (click)="clear(table)"
            ></button>
          </div>
        </ng-template>
      </p-toolbar>

      <p-table
        #table
        [columns]="cols"
        [value]="rows"
        responsiveLayout="scroll"
        styleClass="p-datatable-sm p-datatable-striped document-table"
        dataKey="id.value"
        (sortFunction)="customSort($event)"
        [customSort]="true"
      >
        <ng-template pTemplate="header" let-rowData>
          <tr>
            <th
              *ngFor="let col of cols; let index = index"
              pFrozenColumn
              [frozen]="col.frozen"
              [pSortableColumn]="col.sortable ? col.fieldname : undefined"
            >
              <span class="white-space-nowrap">
                {{ col.header }}
              </span>
              <span *ngIf="index != 0 && form.valid">
                <p-sortIcon *ngIf="col.sortable" [field]="col.fieldname"></p-sortIcon>
                <p-columnFilter
                  *ngIf="col.type !== 'boolean' && col.sortable"
                  [field]="col.fieldname"
                  [matchMode]="customFilterName"
                  display="menu"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                  [showApplyButton]="false"
                  class="ml-auto"
                >
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-listbox
                      [options]="col.options"
                      [metaKeySelection]="false"
                      [checkbox]="true"
                      [filter]="true"
                      [multiple]="true"
                      [formControl]="getFilterControl(col.filterFormControl, value)"
                      [listStyle]="{ 'max-height': '250px' }"
                      [style]="{ width: '30rem' }"
                      (onChange)="filter($event.value)"
                    >
                      <ng-template pTemplate="item" let-option>{{ option.label }}</ng-template>
                    </p-listbox>
                  </ng-template>
                </p-columnFilter>
                <p-columnFilter
                  *ngIf="col.type === 'boolean'"
                  type="boolean"
                  [field]="col.fieldname"
                  display="menu"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                  [showApplyButton]="false"
                  class="ml-auto"
                >
                </p-columnFilter>
              </span>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-editing="editing">
          <tr [formGroup]="rowData" [pSelectableRow]="rowData" [class.duplicatedRows]="rowData.errors?.duplicate">
            <td pFrozenColumn class="button-cell">
              <button
                pButton
                type="button"
                icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text p-button-sm"
                (click)="removeRowDocument($event, rowData)"
              ></button>
              <button
                pButton
                type="button"
                icon="pi pi-copy"
                class="p-button-rounded p-button-text p-button-sm"
                (click)="duplicateRow(rowData)"
              ></button>
            </td>
            <td pFrozenColumn pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-dropdown
                    [options]="staticDataMap[StaticDataTypeEnum.AllowedUploadFileTypes]"
                    formControlName="documentType"
                    filterBy="label"
                    [showClear]="true"
                    placeholder="Select a Document Type"
                  >
                  </p-dropdown>
                </ng-template>
                <ng-template pTemplate="output">
                  {{
                    rowData.controls.documentType.value
                      | staticData: StaticDataTypeEnum.AllowedUploadFileTypes : null : staticDataContext : true
                      | async
                  }}
                  <small class="p-error" *ngIf="rowData.controls.documentType.errors?.required">
                    {{ t("common.validation.requiredShort") }}
                  </small>
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-dropdown
                    *ngIf="!rowData.controls.documentSetType.disabled; else defaultSet"
                    [options]="getDocumentSetTypes(rowData.controls.documentType.value)"
                    formControlName="documentSetType"
                    filterBy="label"
                    [showClear]="true"
                    placeholder="Select a Document Set Type"
                  >
                  </p-dropdown>
                  <ng-template #defaultSet>{{ t("documentSet.default") }}</ng-template>
                </ng-template>
                <ng-template pTemplate="output">
                  <ng-container *ngIf="rowData.controls.documentSetType.value as documentSetType; else defaultSet">
                    {{ t($any("documentSet.types." + documentSetType)) }}
                  </ng-container>
                  <ng-template #defaultSet>{{ t("documentSet.default") }}</ng-template>
                  <small class="p-error" *ngIf="rowData.controls.documentSetType.errors?.required">
                    {{ t("common.validation.requiredShort") }}
                  </small>
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-multiSelect
                    [options]="staticDataMap[StaticDataTypeEnum.Countries]"
                    formControlName="countryOfOrigin"
                    filterBy="label"
                    [showClear]="true"
                    placeholder="Select a Country"
                  ></p-multiSelect>
                </ng-template>
                <ng-template pTemplate="output">
                  {{
                    rowData.controls.countryOfOrigin.value
                      | staticData: StaticDataTypeEnum.Countries : null : staticDataContext : true
                      | async
                  }}
                  <small class="p-error" *ngIf="rowData.controls.countryOfOrigin.errors?.required">
                    {{ t("common.validation.requiredShort") }}
                  </small>
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-multiSelect
                    [options]="staticDataMap[StaticDataTypeEnum.FederalStates]"
                    formControlName="federalStateDestination"
                    filterBy="label"
                    [showClear]="true"
                    placeholder="Select a State"
                  ></p-multiSelect>
                </ng-template>
                <ng-template pTemplate="output">
                  {{
                    rowData.controls.federalStateDestination.value
                      | staticData: StaticDataTypeEnum.FederalStates : null : staticDataContext : true
                      | async
                  }}
                  <small class="p-error" *ngIf="rowData.controls.federalStateDestination.errors?.required">
                    {{ t("common.validation.requiredShort") }}
                  </small>
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-multiSelect
                    [options]="staticDataMap[StaticDataTypeEnum.Profession]"
                    formControlName="profession"
                    filterBy="label"
                    [showClear]="true"
                    placeholder="Select a Profession"
                  ></p-multiSelect>
                </ng-template>
                <ng-template pTemplate="output">
                  {{
                    rowData.controls.profession.value
                      | staticData: StaticDataTypeEnum.Profession : null : staticDataContext : true
                      | async
                  }}
                  <small class="p-error" *ngIf="rowData.controls.profession.errors?.required">
                    {{ t("common.validation.requiredShort") }}
                  </small>
                </ng-template>
              </p-cellEditor>
            </td>

            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-multiSelect
                    [options]="linkedOrganizations"
                    formControlName="employerBeforeRecognitionIds"
                    filterBy="label"
                    [showClear]="true"
                    placeholder="Select Employers"
                  ></p-multiSelect>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ getEmployers(rowData.controls.employerBeforeRecognitionIds.value) }}
                  <small class="p-error" *ngIf="rowData.controls.employerBeforeRecognitionIds.errors?.required">
                    {{ t("common.validation.requiredShort") }}
                  </small>
                </ng-template>
              </p-cellEditor>
            </td>

            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-multiSelect
                    [options]="linkedOrganizations"
                    formControlName="employerAfterRecognitionIds"
                    filterBy="label"
                    [showClear]="true"
                    placeholder="Select Employers"
                  ></p-multiSelect>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ getEmployers(rowData.controls.employerAfterRecognitionIds.value) }}
                  <small class="p-error" *ngIf="rowData.controls.employerAfterRecognitionIds.errors?.required">
                    {{ t("common.validation.requiredShort") }}
                  </small>
                </ng-template>
              </p-cellEditor>
            </td>

            <td pEditableColumn *ngFor="let field of immigrationCountrySpecificFields">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-multiSelect
                    [options]="staticDataMap[field.staticDataType]"
                    [formControlName]="field.name"
                    filterBy="label"
                    [showClear]="true"
                  ></p-multiSelect>
                </ng-template>
                <ng-template pTemplate="output">
                  {{
                    rowData.controls[field.name].value
                      | staticData: field.staticDataType : null : staticDataContext
                      | async
                  }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <p-checkbox formControlName="notNecessary" [binary]="true" inputId="binary"> </p-checkbox>
            </td>
            <td pEditableColumn class="white-space-nowrap">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <button
                    type="button"
                    class="p-button-outlined"
                    pButton
                    [label]="t('requiredDocument.selectFormat')"
                    icon="pi pi-angle-down"
                    iconPos="right"
                    (click)="op.toggle($event)"
                    [disabled]="rowData.controls.notNecessary.value"
                  ></button>
                </ng-template>
                <ng-template pTemplate="output">
                  <ng-container *ngIf="!rowData.controls.notNecessary.value">
                    <span *ngFor="let format of rowData.controls.documentFormat.controls">
                      <span *ngIf="format.value.requiredDeliveryFormats?.length > 0">
                        {{
                          format.value.value
                            | staticData: StaticDataTypeEnum.DocumentFormatsAppreviation : null : staticDataContext
                            | async
                        }}({{ format.value.requiredDeliveryFormats | enum: "DocumentDeliveryFormat" : null : "short" }})
                      </span>
                    </span>
                  </ng-container>
                </ng-template>
              </p-cellEditor>

              <p-overlayPanel #op formArrayName="documentFormat">
                <ng-template pTemplate="content">
                  <p-table [value]="rowData.controls.documentFormat.controls" [rows]="10" scrollHeight="500px">
                    <ng-template pTemplate="header">
                      <tr>
                        <th>{{ t("documentFormat.title") }}</th>
                        <th>{{ t("requiredDocument.requiredFormat") }}</th>
                        <th>{{ t("requiredDocument.numberOfCopies") }}</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-document let-y="rowIndex">
                      <tr [formGroupName]="y">
                        <td>
                          {{
                            document.value.value
                              | staticData: StaticDataTypeEnum.DocumentFormats : null : staticDataContext
                              | async
                          }}
                        </td>
                        <td>
                          <p-multiSelect
                            formControlName="requiredDeliveryFormats"
                            [options]="knownDeliveryFormats"
                            [noAutoFocus]="true"
                            [showClear]="true"
                          ></p-multiSelect>
                        </td>
                        <td>
                          <p-inputNumber
                            formControlName="count"
                            [min]="1"
                            [size]="1"
                            buttonLayout="horizontal"
                          ></p-inputNumber>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </ng-template>
              </p-overlayPanel>
            </td>
            <td pEditableColumn class="white-space-nowrap">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <button
                    type="button"
                    class="p-button-outlined"
                    pButton
                    [label]="t('requiredDocument.selectFormat')"
                    icon="pi pi-angle-down"
                    iconPos="right"
                    (click)="opOther.toggle($event)"
                    [disabled]="rowData.controls.notNecessary.value"
                  ></button>
                </ng-template>
                <ng-template pTemplate="output">
                  <ng-container *ngIf="!rowData.controls.notNecessary.value">
                    <span *ngFor="let format of rowData.controls.documentFormatOther.controls">
                      <span *ngIf="format.value.requiredDeliveryFormats?.length > 0">
                        {{
                          format.value.value
                            | staticData: StaticDataTypeEnum.DocumentFormatsAppreviation : null : staticDataContext
                            | async
                        }}({{ format.value.requiredDeliveryFormats | enum: "DocumentDeliveryFormat" : null : "short" }})
                      </span>
                    </span>
                  </ng-container>
                </ng-template>
              </p-cellEditor>

              <p-overlayPanel #opOther formArrayName="documentFormatOther">
                <ng-template pTemplate="content">
                  <p-table [value]="rowData.controls.documentFormatOther.controls" [rows]="10" scrollHeight="500px">
                    <ng-template pTemplate="header">
                      <tr>
                        <th>{{ t("documentFormat.title") }}</th>
                        <th>{{ t("requiredDocument.requiredFormat") }}</th>
                        <th>{{ t("requiredDocument.numberOfCopies") }}</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-document let-y="rowIndex">
                      <tr [formGroupName]="y">
                        <td>
                          {{
                            document.value.value
                              | staticData: StaticDataTypeEnum.DocumentFormats : null : staticDataContext
                              | async
                          }}
                        </td>
                        <td>
                          <p-multiSelect
                            formControlName="requiredDeliveryFormats"
                            [options]="knownDeliveryFormats"
                            [noAutoFocus]="true"
                            [showClear]="true"
                          ></p-multiSelect>
                        </td>
                        <td>
                          <p-inputNumber
                            formControlName="count"
                            [min]="1"
                            [size]="1"
                            buttonLayout="horizontal"
                          ></p-inputNumber>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </ng-template>
              </p-overlayPanel>
            </td>
            <td>
              <p-checkbox formControlName="isOptional" [binary]="true" inputId="binary"></p-checkbox>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <tr class="p-3">
            <p-button
              icon="pi pi-plus"
              (click)="addNewDocument()"
              styleClass="add-row-btn"
              [disabled]="!canAddNewItem()"
            ></p-button>
          </tr>
        </ng-template>
      </p-table>
    </p-card>
  </form>
</ng-container>
