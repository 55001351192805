import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidatorFn, FormArray, ValidationErrors } from "@angular/forms";
import { OrganizationProfileLocationVacancy, StaticDataModel } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { Observable, map, of } from "rxjs";
import { SettingsService } from "../../../../shared/services/settings.service";
import { ProfessionService } from "../../../profession/profession.service";

class ValidationResult {
  readonly isValid = this.duplicatedStaff.length === 0;

  constructor(readonly duplicatedStaff: string[]) {}

  labelDuplicatedStaff(professions: StaticDataModel[]): string[] {
    return this.duplicatedStaff.map((professionId) => {
      return professions.find((profession) => profession.value === professionId)?.label ?? professionId;
    });
  }
}

@Injectable({ providedIn: "root" })
export class OrganizationProfileLocationsValidator {
  private readonly language = this.transloco.getActiveLang();

  constructor(
    private readonly settings: SettingsService,
    private readonly transloco: TranslocoService,
    private readonly professionService: ProfessionService
  ) {}

  build(organizationId: string): AsyncValidatorFn {
    const professionsObservable = this.fetchProfessions(organizationId);
    return (control) => this.validateControl(control, professionsObservable);
  }

  private validateControl(
    control: AbstractControl,
    professionsObservable: Observable<StaticDataModel[]>
  ): Observable<ValidationErrors | null> {
    const vacancies = ((control as FormArray).value as OrganizationProfileLocationVacancy[]) ?? [];
    const validationResults = this.validateVacancies(vacancies);
    return validationResults.every((result) => result.isValid)
      ? of(null)
      : professionsObservable.pipe(
          map((professions) => ({
            duplicates: validationResults.map((result) =>
              result.isValid ? null : result.labelDuplicatedStaff(professions)
            ),
          }))
        );
  }

  private validateVacancies(vacancies: OrganizationProfileLocationVacancy[]): ValidationResult[] {
    return vacancies.map((vacancy, currentIndex) => {
      const requiredStaff = vacancy.requiredStaff ?? [];
      const otherRequiredStaff = new Set(
        vacancies.filter((_, index) => index != currentIndex).flatMap((vacancy) => vacancy.requiredStaff ?? [])
      );

      return new ValidationResult(requiredStaff.filter((staff) => otherRequiredStaff.has(staff)));
    });
  }

  private fetchProfessions(organizationId: string): Observable<StaticDataModel[]> {
    const all = false;
    return this.professionService.getProfessions(
      null,
      null,
      this.language,
      organizationId ?? this.settings.organizationId,
      all
    );
  }
}
