import { isNil } from "lodash";
import { BehaviorSubject, filter, map, Observable, OperatorFunction } from "rxjs";

/**
 * A constant representing `undefined`, using the JavaScript expression `void 0`.
 *
 * This constant is equivalent to `undefined` and is provided to explicitly signify that
 * a value is `undefined`. It is primarily used to support `Observable<void>` emissions
 * in RxJS operators like `mapToVoid`.
 *
 * @constant
 * @type {undefined}
 *
 * @example
 * ```typescript
 * console.log(VOID); // Output: undefined
 *
 * const result: Observable<void> = someObservable.pipe(map(() => VOID));
 * result.subscribe(value => console.log(value)); // Output: undefined
 * ```
 */
export const VOID = void 0;

/**
 * An RxJS operator that maps each emission of the source observable to `void`.
 *
 * This operator is intended for use when you need to create or transform an observable
 * into `Observable<void>`, ensuring that all emissions are replaced by `void` (i.e., `undefined`).
 *
 * @template T - The type of values emitted by the source observable.
 *
 * @returns An `OperatorFunction` that transforms an observable of type `T`
 * into an observable that emits `void` (i.e., `undefined`) for each value.
 *
 * @example
 * ```typescript
 * import { of } from 'rxjs';
 * import { mapToVoid } from './mapToVoid';
 *
 * of(1, 2, 3)
 *   .pipe(mapToVoid())
 *   .subscribe(value => console.log(value));
 *
 * // Output: undefined, undefined, undefined
 * ```
 */
export function mapToVoid<T>(): OperatorFunction<T, void> {
  return (source: Observable<T>) => source.pipe(map(() => VOID));
}

/**
 * An RxJS operator that filters out `null` and `undefined` values from an observable stream.
 *
 * This operator ensures that only non-nullish (`T`) values are emitted,
 * effectively removing any emissions that are `null` or `undefined`.
 *
 * @template T - The type of values emitted by the observable.
 *
 * @returns An `OperatorFunction` that transforms an observable of type `T | null | undefined`
 * into an observable of type `T`, filtering out `null` and `undefined` values.
 *
 * @example
 * ```typescript
 * import { of } from 'rxjs';
 * import { notNil } from './notNil';
 *
 * of(1, null, undefined, 2, 3)
 *   .pipe(notNil())
 *   .subscribe(value => console.log(value));
 *
 * // Output: 1, 2, 3
 * ```
 */
export function notNil<T>(): OperatorFunction<T | null | undefined, T> {
  return (source: Observable<T | null | undefined>) => source.pipe(filter((value): value is T => !isNil(value)));
}

/**
 * An RxJS operator that casts each emission of the source observable to a specific type.
 *
 * This operator is intended for use when you need to cast the values emitted by an observable to a specific type.
 * It is similar to the TypeScript `as` keyword, but it is used within an observable stream to cast the values emitted by the source observable to a specific type.
 *
 * @template T - The type to cast the values emitted by the source observable to.
 *
 * @returns An `OperatorFunction` that transforms an observable of any type into an observable
 * that emits values of type `T`, casting each value to the specified type.
 */
export function as<T>(): OperatorFunction<any, T> {
  return (source: Observable<any>) => source.pipe(map((value) => value as T));
}

/**
 * Converts an observable to a `BehaviorSubject` that replays the last emitted value to new subscribers.
 *
 * This function subscribes to the source observable and replays the last emitted value to new subscribers.
 * It returns a `BehaviorSubject` that emits the last value emitted by the source observable.
 *
 * @template T - The type of values emitted by the source observable.
 *
 * @param source - The source observable to convert to a `BehaviorSubject`.
 *
 * @returns A `BehaviorSubject` that replays the last emitted value to new subscribers.
 *
 * @example
 * ```typescript
 * import { of } from 'rxjs';
 * import { toSubject } from './toSubject';
 *
 * const source = of(1, 2, 3);
 * const subject = toSubject(source);
 *
 * subject.subscribe(value => console.log(value));
 *
 * // Output: 3
 * ```
 */
export function toSubject<T>(source: Observable<T>, initialValue: T): BehaviorSubject<T> {
  const subject = new BehaviorSubject<T>(initialValue);
  source.subscribe(subject);
  return subject;
}
