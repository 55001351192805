<ng-container *appTranslate="let t">
  <ng-container *ngIf="menuItems?.length">
    <ng-container *ngIf="menuItems.length === 1">
      <button
        pButton
        type="button"
        icon="pi pi-upload"
        class="p-button-rounded p-button-text"
        [disabled]="readonly"
        [pTooltip]="t('file.upload')"
        tooltipPosition="left"
        (click)="openDialog(fileTypes[0])"
      ></button>
    </ng-container>

    <ng-container *ngIf="menuItems.length > 1">
      <p-menu #menu [popup]="true" [model]="menuItems" styleClass="p-menu-wide"></p-menu>
      <button
        pButton
        type="button"
        icon="pi pi-upload"
        class="p-button-rounded p-button-text"
        [pTooltip]="t('file.upload')"
        [disabled]="readonly"
        tooltipPosition="left"
        (click)="menu.toggle($event)"
      ></button>
    </ng-container>
  </ng-container>
</ng-container>

<p-sidebar styleClass="p-sidebar-md" [(visible)]="showSidebar">
  <app-document-edit-dialog
    *ngIf="showSidebar && selectedDocument"
    [document]="selectedDocument"
    [mode]="DocumentMode.Candidate"
    [processLanguage]="language"
    [candidateForm]="candidateForm"
    (closed)="closeDialog()"
    (saved)="closeDialog()"
  ></app-document-edit-dialog>
</p-sidebar>
