import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { DocumentsModule } from "../documents/documents.module";
import { DocumentRequirementFormService } from "./document-requirement-form.service";
import { DocumentRequirementTableComponent } from "./document-requirement-table.component";
import { DocumentRequirementService } from "./document-requirement.service";
import { DocumentRequirementsComponent } from "./document-requirements.component";
import { DocumentSetsPipe } from "./document-sets.pipe";
import { DocumentGeneralRequirementComponent } from "./general-document-requirements/document-general-requirement.component";
import { DocumentGeneralRequirementService } from "./general-document-requirements/document-general-requirement.service";

@NgModule({
  imports: [SharedModule, DocumentsModule],
  declarations: [
    DocumentRequirementsComponent,
    DocumentRequirementTableComponent,
    DocumentGeneralRequirementComponent,
    DocumentSetsPipe,
  ],
  providers: [DocumentGeneralRequirementService, DocumentRequirementService, DocumentRequirementFormService],
  exports: [DocumentRequirementsComponent],
})
export class DocumentRequirementModule {}
