<app-table
  *appTranslate="let t"
  [items]="documentSets"
  [columns]="columns"
  [title]="t('requiredDocument.title')"
  [newOperations]="newOperations"
  [tableOperations]="tableOperations"
  [(showSidebar)]="showDialog"
  [scrollable]="true"
>
  <app-required-document-dialog
    *ngIf="showDialog"
    [template]="selectedTemplate"
    [allDocumentSets]="documentSets"
    [isEdit]="isEdit"
    (saved)="save($event)"
    (closed)="closeSidebar()"
  ></app-required-document-dialog>
</app-table>
