import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from "@angular/core";
import { Router } from "@angular/router";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { OrganizationContactForListFragment, OrganizationContactFragment, StaticDataType } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { clone } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { Dropdown } from "primeng/dropdown";
import { finalize, forkJoin, switchMap, take } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";
import { StaticDataPipe } from "../../shared/static-data/static-data.pipe";
import { PipeDescription, TableColumn, TableOperation, TableOperationMode } from "../../shared/table/table.model";
import { MessageService } from "../message/message.service";
import { OrganizationContactSettingsService } from "./organization-contact-settings.service";
import { OrganizationContactsService } from "./organization-contacts.service";

const nameOf = nameofFactory<OrganizationContactForListFragment>();

@Component({
  selector: "app-organization-contacts",
  templateUrl: "./organization-contacts.component.html",
  styleUrl: "./organization-contacts.component.scss",
  standalone: false,
})
export class OrganizationContactsComponent implements OnInit, AfterViewInit, OnChanges {
  readonly captionOperations: TableOperation[] = [
    {
      label: translate("common.new"),
      icon: PrimeIcons.PLUS,
      mode: TableOperationMode.SplitButton,
      operation: (): void => this.create(),
      items: [
        {
          label: translate("representative.addFromUsers"),
          icon: PrimeIcons.PLUS,
          operation: (): void => this.create(true),
        },
      ],
    },
  ];

  readonly tableOperations: TableOperation[] = [
    {
      label: translate("common.edit"),
      icon: PrimeIcons.PENCIL,
      operation: (x: OrganizationContactForListFragment): void => this.edit(x),
    },
    {
      label: translate("common.delete"),
      icon: PrimeIcons.TRASH,
      operation: (x: OrganizationContactForListFragment, e: Event): void => this.delete(x, e),
    },
  ];

  readonly columns: TableColumn[] = [
    {
      header: translate("title.title"),
      fieldname: nameOf("title"),
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(StaticDataPipe, StaticDataType.Title),
    },
    {
      header: translate("salutation.title"),
      fieldname: nameOf("salutation"),
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(StaticDataPipe, StaticDataType.Salutation),
    },
    {
      header: translate("firstName.title"),
      fieldname: nameOf("firstName"),
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("lastName.title"),
      fieldname: nameOf("lastName"),
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("email.title"),
      fieldname: nameOf("email"),
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("phone.title"),
      fieldname: nameOf("phone"),
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("mobile.title"),
      fieldname: nameOf("mobilePhone"),
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("position.title"),
      fieldname: nameOf("position"),
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("signatureAddition.title"),
      fieldname: nameOf("signatureAddition"),
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(StaticDataPipe, StaticDataType.SignatureAddition),
    },
  ];

  @Input()
  organizationId: string;

  @Input()
  title: string;

  @Input()
  displayBackButton: boolean;

  @ViewChildren("defaultRepresentative")
  defaultRepresentativeSub: QueryList<Dropdown>;

  @ViewChildren("defaultFirstDeputy")
  defaultFirstDeputySub: QueryList<Dropdown>;

  @ViewChildren("defaultRecognitionContactPerson")
  defaultRecognitionContactPersonSub: QueryList<Dropdown>;

  private defaultRepresentative: Dropdown;
  private defaultFirstDeputy: Dropdown;
  private defaultRecognitionContactPerson: Dropdown;

  footerOperations: TableOperation[];
  contacts: OrganizationContactForListFragment[];
  selectedContact: OrganizationContactFragment;
  showSidebar: boolean;
  isBusy: boolean;
  isNewFromUsers = false;

  constructor(
    private readonly contactService: OrganizationContactsService,
    private readonly contactSettingsService: OrganizationContactSettingsService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly settings: SettingsService,
    private readonly router: Router,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.contactService.getAll(this.organizationId).subscribe((x) => {
      this.contacts = clone(x);
    });
  }

  ngAfterViewInit(): void {
    forkJoin([
      this.contactSettingsService.get(this.organizationId),
      this.defaultRepresentativeSub.changes.pipe(take(1)),
      this.defaultFirstDeputySub.changes.pipe(take(1)),
      this.defaultRecognitionContactPersonSub.changes.pipe(take(1)),
    ]).subscribe(([x]) => {
      if (x) {
        this.defaultRepresentative = this.defaultRepresentativeSub.first;
        this.defaultFirstDeputy = this.defaultFirstDeputySub.first;
        this.defaultRecognitionContactPerson = this.defaultRecognitionContactPersonSub.first;
        this.defaultRepresentative.writeValue(x.defaultRepresentative);
        this.defaultFirstDeputy.writeValue(x.defaultFirstDeputy);
        this.defaultRecognitionContactPerson.writeValue(x.defaultRecognitionContactPerson);
        this.changeDetector.detectChanges();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.displayBackButton) {
      this.footerOperations = changes.displayBackButton.currentValue
        ? [
            {
              label: translate("common.back"),
              icon: PrimeIcons.ANGLE_LEFT,
              operation: (): void => this.back(),
            },
          ]
        : [];
    }
  }

  create(fromUsers = false): void {
    this.selectedContact = {
      id: null,
      function: null,
      title: null,
      salutation: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      mobilePhone: null,
      position: null,
      organizationId: this.organizationId,
    };
    this.showSidebar = true;
    this.isNewFromUsers = fromUsers;
  }

  edit(contact: OrganizationContactForListFragment): void {
    this.contactService.get(contact.id, contact.organizationId).subscribe((x) => {
      this.selectedContact = x;
      this.showSidebar = true;
    });
  }

  delete(contact: OrganizationContactForListFragment, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("contact.confirmDelete"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.contactService.delete(contact).subscribe((x) => {
          if (x) {
            this.messageService.add({ severity: "success", summary: translate("contact.deleted") });
          }
        });
      },
    });
  }

  save(contact: OrganizationContactFragment): void {
    this.isBusy = true;
    if (contact.id) {
      this.contactService
        .update(contact)
        .pipe(finalize(() => (this.isBusy = false)))
        .subscribe((x) => {
          if (x) {
            this.messageService.add({ severity: "success", summary: translate("contact.updated") });
            this.close();
          }
        });
    } else {
      this.contactService
        .create(contact)
        .pipe(finalize(() => (this.isBusy = false)))
        .subscribe((x) => {
          if (x) {
            this.messageService.add({ severity: "success", summary: translate("contact.created") });
            this.close();
          }
        });
    }
  }

  updateDefaults(representative: string, firstDeputy: string, defaultRecognitionContactPerson: string): void {
    this.defaultRepresentative.readonly = true;
    this.defaultFirstDeputy.readonly = true;
    this.defaultRecognitionContactPerson.readonly = true;
    this.contactSettingsService
      .get(this.organizationId)
      .pipe(
        switchMap((x) =>
          this.contactSettingsService.set({
            organizationId: x.organizationId,
            _etag: x._etag,
            defaultRepresentative: representative,
            defaultFirstDeputy: firstDeputy,
            defaultRecognitionContactPerson: defaultRecognitionContactPerson,
          })
        ),
        finalize(() => {
          this.defaultRepresentative.readonly = false;
          this.defaultFirstDeputy.readonly = false;
          this.defaultRecognitionContactPerson.readonly = false;
        })
      )
      .subscribe((x) => {
        if (x) {
          this.messageService.add({ severity: "success", summary: translate("contacts.configured") });
        }
      });
  }

  close(): void {
    this.selectedContact = null;
    this.showSidebar = false;
    this.isNewFromUsers = false;
  }

  back(): void {
    void this.router.navigate(["/app/settings/organizations", this.settings.organizationId]);
  }
}
