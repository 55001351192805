<app-table
  [columns]="columns"
  [items]="collections"
  [captionOperations]="captionOperations"
  [tableOperations]="tableOperations"
  [(showSidebar)]="showSidebar"
  [numberOfRows]="20"
  data-testid="table-app-assigned-collection"
>
  <app-collection-edit-assigned-collection-dialog
    *ngIf="showSidebar"
    [collection]="selectedCollection"
    [disabled]="isAdding"
    (saved)="addCollection($event)"
    (closed)="closeSidebar()"
  ></app-collection-edit-assigned-collection-dialog>
</app-table>
