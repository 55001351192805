import { Injectable } from "@angular/core";
import {
  ENABLE_BILLING,
  ensure2LetterIsoLanguage,
  getLanguageConfiguration,
  Language,
  mapIso2CodeToIso2CodeLanguage,
  mapLanguageToIso2CodeLanguage,
  supportedUserInterfaceLanguages4LetterISOForUser,
} from "@ankaadia/ankaadia-shared";
import {
  BlockUserGQL,
  CreateUserGQL,
  DeleteUserInput,
  GetAvailableLanguagesGQL,
  GetLastLoginDateGQL,
  GetMessagesReceptionGQL,
  GetUserGQL,
  GetUserQuery,
  GetUsersGQL,
  GetUsersQuery,
  GetUsersWithRolesGQL,
  GetUsersWithRolesQuery,
  MessagesReception,
  MutationResult,
  ReInviteUserGQL,
  RemoveUserGQL,
  SetCommunicationLanguageGQL,
  StaticDataModel,
  StaticDataType,
  ToggleMessagesReceptionGQL,
  UnBlockUserGQL,
  UpdateUserGQL,
  User,
  UserFragmentDoc,
  UserInput,
  UserRole,
} from "@ankaadia/graphql";
import { InMemoryCache } from "@apollo/client/core";
import { orderBy } from "lodash";
import { combineLatest, map, Observable, of, Subject } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";
import { StaticDataService } from "../../shared/static-data/static-data.service";

@Injectable({ providedIn: "root" })
export class UsersService {
  messagesReceptionChanged = new Subject<void>();

  constructor(
    private readonly gUser: GetUserGQL,
    private readonly gUsers: GetUsersGQL,
    private readonly gLastLoginDate: GetLastLoginDateGQL,
    private readonly cUser: CreateUserGQL,
    private readonly uUser: UpdateUserGQL,
    private readonly dUser: RemoveUserGQL,
    private readonly reInvite: ReInviteUserGQL,
    private readonly blockUser: BlockUserGQL,
    private readonly unBlockUser: UnBlockUserGQL,
    private readonly usersWithRoles: GetUsersWithRolesGQL,
    private readonly settings: SettingsService,
    private readonly toggleMsgReception: ToggleMessagesReceptionGQL,
    private readonly getMsgReception: GetMessagesReceptionGQL,
    private readonly getCommunicationLanguageGQL: GetAvailableLanguagesGQL,
    private readonly setCommunicationLanguageGQL: SetCommunicationLanguageGQL,
    private readonly staticDataService: StaticDataService
  ) {}

  blockUnBlockUser(userId: string, organizationId: string, currentBlockStatus: boolean): Observable<User> {
    if (currentBlockStatus) {
      return this.unBlockUser
        .mutate({ input: { userId: userId, organizationId: organizationId } })
        .pipe(map((x) => x.data.unBlockUser));
    } else {
      return this.blockUser
        .mutate({ input: { userId: userId, organizationId: organizationId } })
        .pipe(map((x) => x.data.blockUser));
    }
  }

  getPossibleUserRoles(): UserRole[] {
    const userRoles = [
      UserRole.Administrator,
      UserRole.PartnerAdministrator,
      UserRole.ContentAdministrator,
      UserRole.ProcessAdministrator,
      UserRole.ProcessObserver,
      UserRole.CandidateManager,
      UserRole.CourseTeacher,
      UserRole.CourseAdministrator,
      UserRole.User,
      UserRole.EmailReceiverOnly,
      UserRole.CrmContributor,
    ];

    if (ENABLE_BILLING) {
      userRoles.push(UserRole.BillingAdministrator);
    }

    return userRoles;
  }

  getUser(id: string, organizationId: string): Observable<GetUserQuery["user"]> {
    return this.gUser.fetch({ id: id, organizationId: organizationId }).pipe(map((result) => result.data.user));
  }

  getUsers(organizationId: string): Observable<GetUsersQuery["users"]> {
    return this.gUsers.watch({ organizationId: organizationId }).valueChanges.pipe(map((result) => result.data.users));
  }

  getLastLoginDate(auth0Id: string, organizationId: string): Observable<Date> {
    return this.gLastLoginDate
      .fetch({ input: { organizationId, auth0Id } })
      .pipe(map((result) => result.data.lastLoginDate.lastLoginDate));
  }

  addUser(user: UserInput, organizationId: string): Observable<User> {
    user.organizationId = organizationId;
    delete user["__typename"]; // remove because it does not work with mutation
    return this.cUser
      .mutate(
        { input: user, creatorOrgId: this.settings.organizationId },
        {
          update: (cache, mutationResult) =>
            cache.modify({
              fields: {
                users(refs, helper) {
                  if (!helper.storeFieldName.includes(user.organizationId)) return refs;
                  const ref = cache.writeFragment({
                    data: mutationResult.data.createUser,
                    fragment: UserFragmentDoc,
                  });
                  if (refs != null && refs.length > 0) {
                    return [...refs, ref];
                  } else {
                    return [ref];
                  }
                },
              },
            }),
        }
      )
      .pipe(map((result) => result.data?.createUser));
  }

  reInviteUser(userId: string, userOrgId: string): Observable<boolean> {
    return this.reInvite
      .mutate({ userId: userId, invitationOrgId: this.settings.organizationId, userOrgId: userOrgId })
      .pipe(map((x) => x.data.reInviteUser.status));
  }

  updateUser(user: UserInput): Observable<User> {
    delete user["__typename"]; // remove because it does not work with mutation
    return this.uUser.mutate({ input: user }).pipe(map((result) => result.data?.updateUser));
  }

  deleteUser(delUser: DeleteUserInput): Observable<boolean> {
    const dummy = new User(); // just to make sure to get typename in a safe mode and not to hardcode a constant here
    dummy.__typename = "User";
    const proxy = { id: delUser.id, __typename: dummy.__typename }; // creating the dummy object here in ordet to create id with cache.identiy method
    return this.dUser
      .mutate(
        { input: delUser },
        {
          update: (cache: InMemoryCache, result: { data: { removeUser: MutationResult } }) => {
            if (!result?.data?.removeUser.status) {
              return;
            }
            const id = cache.identify(proxy);
            cache.evict({ id: id });
            cache.gc();
          },
        }
      )
      .pipe(map((x) => x.data.removeUser.status));
  }

  getUsersWithRoles(organizationId: string, roles: UserRole[]): Observable<GetUsersWithRolesQuery["usersWithRoles"]> {
    return this.usersWithRoles
      .fetch({ input: { organizationId: organizationId, roles: roles } }, { fetchPolicy: "cache-first" })
      .pipe(map((result) => result.data.usersWithRoles));
  }

  toggleMessagesReception(
    isCandidate: boolean,
    userId?: string,
    orgId?: string,
    enabled?: boolean
  ): Observable<boolean> {
    return this.toggleMsgReception
      .mutate({ userId: userId, orgId: orgId, isCandidate: isCandidate, enabled: enabled })
      .pipe(map((x) => x.data.toggleMessagesReception.enabled));
  }

  getMessagesReception(
    isCandidate: boolean,
    candidateOrUserId?: string,
    organizationId?: string
  ): Observable<MessagesReception> {
    candidateOrUserId = candidateOrUserId ?? this.settings.userOrCandidateId;
    organizationId = organizationId ?? this.settings.organizationId;
    return this.getMsgReception
      .fetch({ candidateOrUserId, organizationId, isCandidate: isCandidate })
      .pipe(map((x) => x.data.getMessagesReception));
  }

  getAvailableCommunicationLanguages(isCandidate: boolean, organizationId?: string): Observable<Language[]> {
    const availableLanguages$ = isCandidate
      ? this.getCandidateCommunicationLanguages(organizationId)
      : of(this.getUserCommunicationLanguages());

    return availableLanguages$.pipe(map((languages) => orderBy(languages, (language) => language.label)));
  }

  // Languages are saved as Iso2 --> replace code prop of ISO4 by a ISO2
  getAvailableCommunicationLanguagesTranslated(
    isCandidate: boolean,
    language: string,
    organizationId?: string
  ): Observable<Language[]> {
    const languages$ = this.staticDataService.getStaticData(StaticDataType.Languages, language);
    const availableLanguages$ = this.getAvailableCommunicationLanguages(isCandidate, organizationId);

    return combineLatest([languages$, availableLanguages$]).pipe(
      map(([translations, languages]) =>
        languages.map((lng) => this.mapLanguageToTranslatedIso2CodeLanguage(lng, translations))
      )
    );
  }

  setCommunicationLanguage(language?: string): Observable<boolean> {
    return this.setCommunicationLanguageGQL
      .mutate({ communicationLanguage: language })
      .pipe(map((x) => x.data?.setCommunicationLanguage?.status));
  }

  mapLanguageToTranslatedIso2CodeLanguage(language: Language, translations: StaticDataModel[]): Language {
    const iso2Code = ensure2LetterIsoLanguage(language.code);
    const trans = translations.filter((x) => x.value == iso2Code).map((x) => x.label);
    return {
      ...language,
      code: iso2Code,
      label: trans[0],
    };
  }

  private getCandidateCommunicationLanguages(organizationId?: string): Observable<Language[]> {
    return this.getCommunicationLanguageGQL
      .fetch({ organizationId: organizationId }, { fetchPolicy: "cache-first" })
      .pipe(
        map((x) => x.data.getAvailableLanguages),
        map((x) => x.flatMap(mapIso2CodeToIso2CodeLanguage))
      );
  }

  private getUserCommunicationLanguages(): Language[] {
    return getLanguageConfiguration(supportedUserInterfaceLanguages4LetterISOForUser()).map((x) =>
      mapLanguageToIso2CodeLanguage(x)
    );
  }
}
