<ng-container *appTranslate="let t">
  <p-tabView class="vertical" styleClass="app-form" rememberTab stateKey="candidate-vertical-tabs-migration-at">
    <p-tabPanel [header]="t('employerData.title')" *ngIf="tabs?.employers">
      <div>
        <p-panel>
          <app-candidate-employment-relationship
            [form]="form.controls.at.controls.employmentRelationship"
            [readonly]="readonly"
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [employmentRelationship]="employmentRelationship"
            [immigrationCountry]="SupportedImmigrationCountry.At"
            [isMultiEditMode]="isMultiEditMode"
          ></app-candidate-employment-relationship>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="t('recognitionPath.title')" *ngIf="tabs?.recognitionPath">
      <div>
        <p-panel>
          <app-candidate-recognition-path-entry-at
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [recognitionPath]="recognitionPath"
            [readonly]="readonly"
            [form]="form.controls.at.controls.recognitionPath"
          ></app-candidate-recognition-path-entry-at>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="t('complementaryMeasure.title')" *ngIf="tabs?.complementaryMeasure">
      <div>
        <p-panel>
          <app-candidate-qualification-measure-at
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [form]="form.controls.at.controls.qualificationMeasure"
            [readonly]="readonly"
          ></app-candidate-qualification-measure-at>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel *ngIf="tabs?.vocationalSchool" [header]="t('vocationalSchool.title')">
      <ng-template pTemplate="header">
        <span>{{ t("vocationalSchool.title") }} </span>
        <i class="ml-1 pi pi-info-circle" [pTooltip]="t('vocationalSchool.description')"></i>
      </ng-template>
      <div>
        <p-panel>
          <app-candidate-vocational-school-at
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [form]="form.controls.at.controls.vocationalSchool"
            [readonly]="readonly"
            [isMultiEditMode]="isMultiEditMode"
          ></app-candidate-vocational-school-at>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="t('housingAcquisition.title')" *ngIf="tabs?.housingAcquisition">
      <div>
        <p-panel>
          <app-candidate-housing-acquisition
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [form]="form.controls.housingAcquisition"
            [housingAcquisition]="housingAcquisition"
            [readonly]="readonly"
          ></app-candidate-housing-acquisition>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="t('rwrCard.title')" *ngIf="tabs?.rwrCard">
      <div>
        <p-panel>
          <app-candidate-visa-at
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [form]="form.controls.at.controls.visa"
            [readonly]="readonly"
          ></app-candidate-visa-at>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="t('residenceAndEntry.title')" *ngIf="tabs?.residence">
      <div>
        <p-panel>
          <app-candidate-residence-at
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [form]="form.controls.at.controls.residence"
            [residence]="residence"
            [readonly]="readonly"
          ></app-candidate-residence-at>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="t('healthProfessionsRegister.title')" *ngIf="tabs?.healthProfessionsRegister">
      <div>
        <p-panel>
          <app-candidate-health-professions-register-at
            [form]="form.controls.at.controls.healthProfessionsRegister"
            [readonly]="readonly"
          ></app-candidate-health-professions-register-at>
        </p-panel>
      </div>
    </p-tabPanel>
  </p-tabView>
</ng-container>
