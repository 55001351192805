import { Component } from "@angular/core";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { noop } from "lodash";
import { DropdownModule } from "primeng/dropdown";
import { TranslateDirective } from "../../../../shared/transloco/translate.directive";
import { TranslocoService } from "@jsverse/transloco";
import { CalendarUtcFixDirective } from "../../../../shared/primeng/calendar-utc-fix/calendar-utc-fix.directive";
import { InputGroupModule } from "primeng/inputgroup";

interface ContractDate {
  month: number;
  year: number;
}

@Component({
  standalone: true,
  selector: "app-contract-date",
  templateUrl: "./contract-date.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ContractDateComponent,
      multi: true,
    },
  ],
  imports: [DropdownModule, InputGroupModule, FormsModule, TranslateDirective],
})
export class ContractDateComponent implements ControlValueAccessor {
  readonly date: ContractDate = { year: null, month: null };

  onChange = (_: Date): void => noop();

  onTouched = (): void => noop();

  touched = false;

  disabled = false;

  readonly years: { label: string; value: number }[];

  readonly months: { label: string; value: number }[];

  constructor(transloco: TranslocoService) {
    const monthNames = transloco.translateObject("primeng.monthNamesShort");

    this.years = Array.from({ length: 10 }, (_, i) => 2022 + i).map((year) => ({
      label: year.toString(),
      value: year,
    }));
    this.months = Array.from({ length: 12 }, (_, i) => i).map((month) => ({
      label: monthNames.at(month) ?? `${month + 1}`,
      value: month,
    }));
  }

  writeValue(date: Date): void {
    const localDate: Date = date ? (CalendarUtcFixDirective.localDateTimeToUTCDateInLocalDateTime(date) as Date) : null;

    this.date.year = localDate?.getFullYear();
    this.date.month = localDate?.getMonth();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setMonth(month: number): void {
    this.date.month = month;
    this.markAsTouched();
    this.triggerOnChange();
  }

  setYear(year: number): void {
    this.date.year = year;
    this.markAsTouched();
    this.triggerOnChange();
  }

  private triggerOnChange(): void {
    if (typeof this.date.year !== "number" || typeof this.date.month !== "number") {
      this.onChange(null);
      return;
    }

    const utcDate = CalendarUtcFixDirective.localDateTimeWhichIsUTCDateValueToLocalDateTimeWhichRepresentsUTCDate(
      new Date(this.date.year, this.date.month, 1)
    ) as Date;

    this.onChange(utcDate);
  }
}
