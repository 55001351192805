import { keys } from "lodash";
import { Merge, PickProperties, Primitive } from "ts-essentials";
import { Property } from "./nameofFactory";

// compile time error if not all type names are provided
/*ProcessTask: RequiredTypeNames<ProcessTaskFragment>()([
    "ProcessTaskInformationSendOut",
    "ProcessTaskInviteToPlatform",
    "ProcessTaskReminder",
    "ProcessTaskUpload",
    "ProcessTaskWork",
    "ProcessTaskDocumentCheck",
    "ProcessTaskOrganizationAudit",
    "ProcessTaskRoleSetup",
    "ProcessTaskAutoOperation",
    "ProcessTaskTriggerEvent",
    "ProcessTaskAssignmentException",
    "ProcessTaskEmailSend",
    "ProcessTaskInformationCollection",
    "ProcessTaskUpdateCandidateProfile",
    "ProcessTaskPdfFormGeneration",
    "ProcessTaskEnterMissingInformation",
    "ProcessTaskEnterMissingInformationV2",
    "ProcessTaskDeactivateProcess",
  ]),*/
export const RequiredTypeNames =
  <T extends { __typename: string }>() =>
  <U extends T["__typename"][]>(array: U & ([T["__typename"]] extends [U[number]] ? unknown : "Invalid")): U =>
    array;

export type Predicate<T> = (value: T) => boolean;
export type Require<T, TRequired extends keyof T> = Omit<T, TRequired> & Required<Pick<T, TRequired>>;
export type RequireOnly<T, TRequired extends keyof T> = Partial<Omit<T, TRequired>> & Required<Pick<T, TRequired>>;
export type Optional<T, TOptional extends keyof T> = Omit<T, TOptional> & Partial<Pick<T, TOptional>>;
export type Never<T> = { [K in keyof T]?: never };
export type ArrayProperties<T> = { [K in keyof T]: T[K] extends any[] ? K : never }[keyof T];

export type Union<T extends readonly string[]> = T[number];
export type PickPropertyKeys<T, TFieldType> = keyof PickProperties<T, TFieldType>;
export type RequireKeys<T extends keyof any> = { [K in T]: K };
export type PrefixKeys<T extends Record<string, any>, Prefix extends string> = {
  [K in keyof T as `${Prefix}${string & K}`]: T[K];
};
export type KeyOf<T> = keyof T;
export type KeysOf<T> = KeyOf<T>[];

export type Intersect<T, U> = Pick<T | U, keyof T & keyof U>;
export type IntersectionKey<T, U> = {
  [K in keyof Intersect<T, U>]: T[K] extends U[K] ? K : never;
}[keyof Intersect<U, T>];

export type Constructor<T> = new (...args: any) => T;
export type HeadParameters<T extends (...args: any) => any> = T extends (h: infer P, ...t: any[]) => any ? P : never;
export type TailParameters<T extends (...args: any) => any> = T extends (h: any, ...t: infer R) => void ? R : never;

export type DerivedProperties<T, Replacements> = {
  [K in keyof Replacements]: K extends keyof T ? (Replacements[K] extends T[K] ? Replacements[K] : never) : never;
};

export type SafeMerge<T, Replacements extends DerivedProperties<T, Replacements>> = Merge<T, Replacements>;

export type SplitProperty<S extends string> = S extends `${infer T}.${infer U}` ? [T, ...SplitProperty<U>] : [S];
export type GetPropertyTypeViaArrayPath<T, Path extends readonly string[]> = Path extends [infer Head, ...infer Tail]
  ? Head extends keyof T
    ? Tail extends readonly string[]
      ? GetPropertyTypeViaArrayPath<T[Head], Tail>
      : never
    : never
  : T;
export type GetPropertyType<T, P extends Property<T>> = GetPropertyTypeViaArrayPath<T, SplitProperty<P>>;

/**
 * Recursively removes `TField` from `TModel`.
 */
export type WithoutField<TModel, TField extends string> = TModel extends Primitive | Primitive[] | Date
  ? TModel
  : {
      [key in keyof Omit<TModel, TField>]: TModel[key] extends (infer TInferredArrayValueType)[]
        ? WithoutField<TInferredArrayValueType, TField>[]
        : TModel[key] extends Record<string, any>
          ? WithoutField<TModel[key], TField>
          : TModel[key];
    };

export function requireKeys<T extends string>(values: RequireKeys<T>): T[] {
  return keys(values).map((key) => values[key]);
}

export function collectKeys<T extends string | number | symbol>(obj: Record<T, any>): T[] {
  return keys(obj) as T[];
}

export function prefixKeys<Prefix extends string, T extends Record<string, any>>(
  prefix: Prefix,
  obj: T
): PrefixKeys<T, Prefix> {
  const result = {} as PrefixKeys<T, Prefix>;

  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      const newLocal = `${prefix}${key}`;
      result[newLocal] = obj[key];
    }
  }

  return result;
}
