<form [formGroup]="form" (ngSubmit)="onSubmit()" class="user-form" *appTranslate="let t">
  <p-card [header]="isEdit ? '' : t('EZBData.create')">
    <div class="p-fluid formgrid grid mt-3">
      <div class="field col-12 xl:col-2">
        <label for="yearOfEstablishment">{{ t("yearOfEstablishment.title") }}</label>
        <p-calendar
          inputId="yearOfEstablishment"
          formControlName="yearOfEstablishment"
          [placeholder]="t('yearOfEstablishment.placeholder')"
          view="year"
          dataType="string"
          [maxDate]="maxEstablishmentDate"
        ></p-calendar>
      </div>
      <div class="field col-12 xl:col-2">
        <label for="operatingNumber">{{ t("operatingNumber.title") }}</label>
        <input id="operatingNumber" pInputText formControlName="operatingNumber" maxlength="50" />
      </div>
      <div class="field col-12 xl:col-2">
        <label for="companyRegistrationAuthority">{{ t("EZBData.companyRegistrationAuthority") }}</label>
        <input
          name="companyRegistrationAuthority"
          id="companyRegistrationAuthority"
          pInputText
          formControlName="companyRegistrationAuthority"
          maxlength="50"
        />
      </div>
      <div class="field col-12 xl:col-2">
        <label for="registerNo">{{ t("EZBData.registerNo") }}</label>
        <input id="registerNo" pInputText formControlName="registerNo" maxlength="150" />
      </div>
      <div class="field col-12 xl:col-3">
        <label for="numberOfEmployers">{{ t("EZBData.numberOfEmployers") }}</label>
        <input id="numberOfEmployers" pInputText type="number" formControlName="numberOfEmployers" min="0" />
      </div>
    </div>
    <div class="p-fluid formgrid grid mt-3">
      <div class="field col-12">
        <label for="hasPendingSecurityPayments">{{ t("EZBData.hasPendingSecurityPayments.title") }}</label>
        <div class="field formgroup-inline">
          <div class="field-radiobutton">
            <p-radioButton
              name="hasPendingSecurityPayments"
              [value]="true"
              formControlName="hasPendingSecurityPayments"
              inputId="hasPendingSecurity"
            ></p-radioButton>
            <label for="hasPendingSecurity">{{ t("primeng.accept") }}</label>
          </div>
          <div class="field-radiobutton">
            <p-radioButton
              name="hasPendingSecurityPayments"
              [value]="false"
              formControlName="hasPendingSecurityPayments"
              inputId="hasNoPendingSecurity"
            ></p-radioButton>
            <label for="hasNoPendingSecurity">{{ t("primeng.reject") }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="p-fluid formgrid grid mt-3">
      <div class="field col-12">
        <label for="hasSocialSecurityIssuesInLast5Years">{{
          t("EZBData.hasSocialSecurityIssuesInLast5Years.title")
        }}</label>
        <div class="field formgroup-inline">
          <div class="field-radiobutton">
            <p-radioButton
              [value]="true"
              formControlName="hasSocialSecurityIssuesInLast5Years"
              inputId="hasSocialSecurityIssues"
            ></p-radioButton>
            <label for="hasSocialSecurityIssues">{{ t("primeng.accept") }}</label>
          </div>
          <div class="field-radiobutton">
            <p-radioButton
              [value]="false"
              formControlName="hasSocialSecurityIssuesInLast5Years"
              inputId="hasNoSocialSecurityIssues"
            ></p-radioButton>
            <label for="hasNoSocialSecurityIssues">{{ t("primeng.reject") }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="p-fluid formgrid grid mt-3">
      <div class="field col-12">
        <label for="detailsOfSocialSecurityIssues">{{ t("EZBData.detailsOfSocialSecurityIssues.title") }}</label>
      </div>
      <div class="field col-12">
        <textarea
          id="detailsOfSocialSecurity"
          pInputTextarea
          rows="6"
          formControlName="detailsOfSocialSecurityIssues"
          maxlength="500"
        ></textarea>
      </div>
    </div>

    <div class="p-fluid formgrid grid mt-3">
      <div class="field col-12">
        <label for="hasInsolvencyProceedingInLast5Years">{{
          t("EZBData.hasInsolvencyProceedingInLast5Years.title")
        }}</label>
        <div class="field formgroup-inline">
          <div class="field-radiobutton">
            <p-radioButton
              [value]="true"
              formControlName="hasInsolvencyProceedingInLast5Years"
              inputId="hasInsolvencyProceeding"
            ></p-radioButton>
            <label for="hasInsolvencyProceeding">{{ t("primeng.accept") }}</label>
          </div>
          <div class="field-radiobutton">
            <p-radioButton
              [value]="false"
              formControlName="hasInsolvencyProceedingInLast5Years"
              inputId="hasNoInsolvencyProceeding"
            ></p-radioButton>
            <label for="hasNoInsolvencyProceeding">{{ t("primeng.reject") }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="p-fluid formgrid grid mt-3">
      <div class="field col-12">
        <label for="hasInsolvencyProceedingLackedAssets">{{
          t("EZBData.hasInsolvencyProceedingLackedAssets.title")
        }}</label>
        <div class="field formgroup-inline">
          <div class="field-radiobutton">
            <p-radioButton
              [value]="true"
              formControlName="hasInsolvencyProceedingLackedAssets"
              inputId="hasInsolvencyProceeding"
            ></p-radioButton>
            <label for="hasInsolvencyProceeding">{{ t("primeng.accept") }}</label>
          </div>
          <div class="field-radiobutton">
            <p-radioButton
              [value]="false"
              formControlName="hasInsolvencyProceedingLackedAssets"
              inputId="hasNoInsolvencyProceeding"
            ></p-radioButton>
            <label for="hasNoInsolvencyProceeding">{{ t("primeng.reject") }}</label>
          </div>
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div
        class="flex"
        [class.justify-content-end]="!displayBackButton"
        [class.justify-content-between]="displayBackButton"
      >
        <p-button
          *ngIf="displayBackButton"
          [routerLink]="['/app/settings/organizations', myOrganizationId]"
          [label]="t('common.back')"
          icon="pi pi-angle-left"
        ></p-button>
        <div class="flex">
          <p-button
            [disabled]="!form.valid || !form.dirty"
            [label]="t('common.save')"
            icon="pi pi-check"
            type="submit"
            class="p-button-outlined"
          ></p-button>
          <p-button
            *ngIf="!displayBackButton"
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary ml-2"
            type="reset"
            (onClick)="closed.emit()"
          ></p-button>
        </div>
      </div>
    </ng-template>
  </p-card>
</form>
