import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  AttachableAutoCollectionFragment,
  CollectionAutoFilterTemplateFragment,
  CollectionForCandidateItemFragment,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { ConfirmationService } from "primeng/api";
import { Message } from "primeng/api/message";
import { MessageDialogService } from "../../../../shared/message-dialog/message-dialog.service";
import { MessageService } from "../../../message/message.service";
import { CollectionAutoFilterTemplateService } from "../collection-auto-filter-template.service";

@Component({
  selector: "app-collection-auto-template-attach-dialog",
  templateUrl: "./collection-auto-template-attach-dialog.component.html",
  standalone: false,
})
export class CollectionAutoTemplateAttachDialogComponent implements OnInit, OnChanges {
  @Input() visible: boolean;
  @Input() template: CollectionAutoFilterTemplateFragment;

  @Output() readonly closed = new EventEmitter<CollectionForCandidateItemFragment[]>();
  collections: (AttachableAutoCollectionFragment & { disabled: boolean })[];
  disableSubmit: boolean;
  form: FormGroup;

  hints: Message[] = [
    {
      severity: "info",
      summary: translate("common.info"),
      detail: translate("autoCollectionTemplate.attach.info"),
    },
  ];

  constructor(
    private readonly autoFilterService: CollectionAutoFilterTemplateService,
    private readonly messageService: MessageService,
    private readonly confirmationService: ConfirmationService,
    private readonly errorService: MessageDialogService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({ collections: [] });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visible && this.visible && this.template) {
      this.form?.reset();
      this.disableSubmit = false;
      this.autoFilterService
        .getAttachableAutoCollections(this.template.id, this.template.organizationId)
        .subscribe((result) => {
          this.collections = result.map((x) => ({ ...x, disabled: !x.isAttachable }));
        });
    }
  }

  close(): void {
    this.closed.emit(null);
  }

  submit(collections: AttachableAutoCollectionFragment[], event: Event): void {
    if (collections?.length > 0) {
      this.disableSubmit = true;
      const names = collections.map((x) => x.name).join(", ");
      this.confirmationService.confirm({
        target: event.target,
        message: translate("autoCollectionTemplate.attach.confirm", { names }),
        accept: () => {
          this.autoFilterService
            .attachAutoCollectionsToTemplate(
              this.template.id,
              this.template.organizationId,
              collections.map((x) => x.id)
            )
            .subscribe((_result) => {
              if (new Date().getTime() > 0) {
                this.messageService.add({
                  severity: "success",
                  summary: translate("autoCollectionTemplate.attach.success"),
                });
                this.closed.emit(null);
              } else {
                this.errorService.showMessage(translate("autoCollectionTemplate.attach.error"), "blah");
                this.closed.emit(null);
              }
            });
        },
      });
    }
  }
}
