import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  EducationCourseForCandidateListToAddFragment,
  EducationCourseForViewFragment,
  StaticDataType,
} from "@ankaadia/graphql";
import { orderBy } from "lodash";
import { PrimeIcons } from "primeng/api";
import { BehaviorSubject, combineLatest, map } from "rxjs";
import { DateFormatterService } from "../../../../shared/services/date-formatter.service";
import { SettingsService } from "../../../../shared/services/settings.service";
import { EducationCourseService } from "../../../education/education-courses/education-course.service";

@Component({
  selector: "app-candidate-education-course-performance-dialog",
  templateUrl: "./candidate-education-course-performance-dialog.component.html",
  styleUrl: "./candidate-education-course-performance-dialog.component.scss",
  standalone: false,
})
export class CandidateEducationCoursePerformanceDialogComponent implements OnChanges {
  protected readonly StaticDataType = StaticDataType;
  protected readonly PrimeIcons = PrimeIcons;

  @Input({ required: true }) candidateCourses: EducationCourseForViewFragment[] = [];
  @Input({ required: true }) saveDisabled = false;
  @Input({ required: true }) candidateOrganizationId: string;
  @Output() readonly saved = new EventEmitter<EducationCourseForCandidateListToAddFragment>();
  @Output() readonly closed = new EventEmitter<void>();

  candidateCourses$ = new BehaviorSubject<EducationCourseForViewFragment[]>([]);
  courses$ = this.courseService.listForCandidateToAdd();

  selectableCourses: SelectableCourse[] = [];
  selectedCourse: SelectableCourse | null = null;

  constructor(
    private readonly courseService: EducationCourseService,
    private readonly dateFormatterService: DateFormatterService,
    private readonly settingsService: SettingsService
  ) {
    combineLatest([this.candidateCourses$, this.courses$])
      .pipe(
        takeUntilDestroyed(),
        map(([candidateCourses, allCourses]) =>
          allCourses
            .filter((x) => this.isOwnerCourseOrMyCourse(x))
            .filter((course) => !this.isCandidateAlreadyAssignedToCourse(candidateCourses, course))
        ),
        map((x) => x.map((x) => ({ ...x, filterText: this.getFilterText(x) }))),
        map((x) => orderBy(x, (x) => x.startDate, "desc"))
      )
      .subscribe((courses) => (this.selectableCourses = courses));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateCourses) {
      this.candidateCourses$.next(this.candidateCourses);
    }
  }

  save(): void {
    this.saved.emit(this.selectableCourses.find((course) => course.id === this.selectedCourse?.id));
  }

  cancel(): void {
    this.closed.emit();
  }

  private getFilterText(course: EducationCourseForCandidateListToAddFragment): string {
    const startDate = this.dateFormatterService.transformDate(course.startDate, { dateStyle: "short" });
    const endDate = this.dateFormatterService.transformDate(course.endDate, { dateStyle: "short" });

    return `${course.name} ${course.languageLevel} ${startDate} ${endDate}`;
  }

  private isOwnerCourseOrMyCourse(course: EducationCourseForCandidateListToAddFragment): boolean {
    return (
      course.organizationId === this.candidateOrganizationId ||
      course.organizationId === this.settingsService.organizationId
    );
  }

  private isCandidateAlreadyAssignedToCourse(
    candidateCourses: EducationCourseForViewFragment[],
    course: EducationCourseForCandidateListToAddFragment
  ): boolean {
    return candidateCourses.some((candidateCourse) => candidateCourse.id === course.id);
  }
}

type SelectableCourse = EducationCourseForCandidateListToAddFragment & { filterText: string };
