import { isNil, uniq, uniqBy } from "lodash";
import { CalculationBasis, ILaborContractAllowance, ILaborContractBenefit } from "./model/contract-template.model";
import { TranslatableValidationIssue } from "./model/translatable-validation-issue";
import { PickPropertyKeys } from "./type-utils";

export function validateAllowances(allowances: ILaborContractAllowance[]): TranslatableValidationIssue[] {
  const issues: TranslatableValidationIssue[] = [];

  const types = allowances.map(({ type }) => type).filter((type) => !isNil(type));
  const uniqueTypes = uniq(types);
  if (uniqueTypes.length !== types.length) {
    issues.push(new TranslatableValidationIssue("allowance.type", "laborContract.errors.duplicateAllowance"));
  }

  const customTypes = allowances.map(({ customType }) => customType).filter((customType) => !isNil(customType));
  const uniqueCustomTypes = uniq(customTypes);
  if (uniqueCustomTypes.length !== customTypes.length) {
    issues.push(new TranslatableValidationIssue("allowance.customType", "laborContract.errors.duplicateAllowance"));
  }

  if (allowances.length !== types.length + customTypes.length) {
    issues.push(new TranslatableValidationIssue("allowance.type", "laborContract.errors.corruptAllowanceType"));
  }

  for (const allowance of allowances) {
    issues.push(...validateNumbers(allowance, ["amount"]));
    if (allowance.calculationBasis === CalculationBasis.Percentage && allowance.amount > 1000) {
      issues.push(
        new TranslatableValidationIssue("allowance.calculationBasis", "laborContract.errors.allowancePercentage")
      );
    }
  }

  return issues;
}

export function validateBenefits(benefits: ILaborContractBenefit[]): TranslatableValidationIssue[] {
  const issues: TranslatableValidationIssue[] = [];

  const uniqueBenefits = uniqBy(benefits, (benefit) => benefit.type);
  if (uniqueBenefits.length !== benefits.length) {
    issues.push(new TranslatableValidationIssue("benefits", "laborContract.errors.duplicateBenefit"));
  }

  for (const benefit of benefits) {
    const { calculationBasis, amount } = benefit;
    issues.push(...validateNumbers(benefit, ["amount"]));
    if (calculationBasis === CalculationBasis.Percentage && amount > 1000) {
      issues.push(new TranslatableValidationIssue("benefits", "laborContract.errors.benefitPercentage"));
    }
  }

  return issues;
}

type NumberProperty<T extends Record<string, number>> = PickPropertyKeys<T, number> & string;

export function validateNumbers<T extends Record<string, any>>(
  template: T,
  properties: NumberProperty<T>[]
): TranslatableValidationIssue[] {
  return properties.reduce((issues, property) => {
    const value = template[property];
    const isValid = isNil(value) || value >= 0;
    return isValid ? issues : [...issues, new TranslatableValidationIssue(property, "class-validator.isPositive")];
  }, []);
}
