import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  AdHocEducationExamFragment,
  EducationExamFragment,
  EducationModuleForSelectionFragment,
  ExamStatus,
  ExamType,
  StaticDataType,
} from "@ankaadia/graphql";
import { isEmpty, sortBy } from "lodash";
import { PrimeIcons } from "primeng/api";
import { combineLatest, map, Observable, startWith, switchMap } from "rxjs";
import { valuesOf } from "../../../shared/services/form.helper";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { EducationCourseService } from "../education-courses/education-course.service";
import { EducationModuleService } from "../education-modules/education-module.service";
import { EducationExamService } from "./education-exam.service";

@Component({
  selector: "app-education-exams",
  templateUrl: "./education-exams.component.html",
  styleUrl: "./education-exams.component.scss",
  standalone: false,
})
export class EducationExamsComponent {
  protected selectedCourses: FormControl<string[]> = new FormControl<string[]>([]);
  protected selectedExamTypes: FormControl<ExamType[]> = new FormControl<ExamType[]>([]);
  protected selectedExamInstitution = new FormControl<string[]>([]);
  protected selectedStatus = new FormControl<ExamStatus>(ExamStatus.Active);

  protected readonly modules$ = this.getModules();
  protected readonly courses$ = this.courseService.fetch(this.settingsService.organizationId);
  protected exams$ = this.getFilteredAllExams();

  protected readonly examTypes = this.staticDataService.transformEnumToStaticDataModel("ExamType", ExamType);
  protected readonly examStatus = this.staticDataService.transformEnumToStaticDataModel("ExamStatus", ExamStatus);
  protected readonly examInstitutions$ = this.staticDataService
    .getStaticData(StaticDataType.ExamInstitutions)
    .pipe(map((data) => sortBy(data, (entry) => (entry.value === "OTHER" ? 1 : 0))));

  constructor(
    private readonly moduleService: EducationModuleService,
    private readonly courseService: EducationCourseService,
    private readonly educationExamService: EducationExamService,
    private readonly settingsService: SettingsService,
    private readonly staticDataService: StaticDataService
  ) {}

  private getModules(): Observable<EducationModuleForSelectionFragment[]> {
    return this.moduleService.listSelectable(this.settingsService.organizationId);
  }

  private getFilteredAllExams(): Observable<(EducationExamFragment | AdHocEducationExamFragment)[]> {
    return combineLatest([
      this.getAllExams(),
      valuesOf(this.selectedCourses),
      valuesOf(this.selectedExamTypes),
      valuesOf(this.selectedExamInstitution),
    ]).pipe(
      map(([exams, courses, examTypes, institutions]) =>
        exams
          .filter((exam) => isEmpty(institutions) || institutions.includes(exam.examInstitution))
          .filter((exam) => isEmpty(examTypes) || examTypes.includes(exam.examType))
          .filter(
            (exam) =>
              isEmpty(courses) ||
              (exam.__typename === "EducationExam" &&
                exam.educationCourseIds?.some((examCourse) => courses.includes(examCourse)))
          )
      )
    );
  }

  private getAllExams(): Observable<(EducationExamFragment | AdHocEducationExamFragment)[]> {
    return combineLatest([this.getEducationExams(), this.getAdHocExams()]).pipe(
      map(([exams, adHocExams]) => [...exams, ...adHocExams])
    );
  }

  private getEducationExams(): Observable<EducationExamFragment[]> {
    return this.selectedStatus.valueChanges.pipe(
      startWith(this.selectedStatus.value),
      switchMap((status) => this.educationExamService.getAll(this.settingsService.organizationId, status))
    );
  }

  private getAdHocExams(): Observable<AdHocEducationExamFragment[]> {
    return this.selectedStatus.valueChanges.pipe(
      startWith(this.selectedStatus.value),
      switchMap((status) => this.educationExamService.getAllAdHoc(this.settingsService.organizationId, status))
    );
  }

  protected readonly PrimeIcons = PrimeIcons;
}
