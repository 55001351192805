<div class="app-form" *appTranslate="let t">
  <p-table
    #table
    [value]="collections | async"
    dataKey="id"
    [globalFilterFields]="['name']"
    sortField="name"
    selectionMode="single"
    [selection]="selectedCollection"
    (onRowSelect)="onRowSelect($event)"
    styleClass="p-datatable-with-overflows"
    [resizableColumns]="true"
  >
    <ng-template pTemplate="caption">
      <div class="flex align-items-center">
        <span class="p-input-icon-left flex-1">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="table.filterGlobal($any($event.target).value, 'contains')"
            [placeholder]="t('common.search')"
          />
        </span>
        <button
          pButton
          type="button"
          icon="pi pi-star{{ isFavoriteSelected ? '-fill' : '' }}"
          tabIndex="-1"
          class="toggle p-button-rounded p-button-text ml-2"
          [class.p-button-active]="isFavoriteSelected"
          (click)="toggle()"
        ></button>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name" pResizableColumn style="width: 75%">
          <div>
            {{ t("name.title") }}
            <p-sortIcon field="name"></p-sortIcon>
          </div>
        </th>
        <th pSortableColumn="type" pResizableColumn>
          <div>
            {{ t("type.title") }}
            <p-sortIcon field="type"></p-sortIcon>
          </div>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-collection>
      <tr [pSelectableRow]="collection" [attr.data-testid]="'candidate-collection-' + collection.name">
        <td>
          <div class="white-space-nowrap text-overflow-ellipsis overflow-hidden">
            {{ collection.name }}
          </div>
        </td>
        <td>
          <span class="white-space-nowrap text-overflow-ellipsis overflow-hidden">
            {{ collection.type | enum: "CollectionType" : null : "normal" : "Standard" }}
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
