import { Injectable } from "@angular/core";
import { translate } from "@jsverse/transloco";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { BehaviorSubject, Observable, of, switchMap, take } from "rxjs";
import { UpdatedConfirmation } from "../../../shared/primeng/confirm-popup-fix/confirm-popup-fix.directive";
import { CandidateFormComponent } from "./candidate-form.component";

/**
 * Intercepts profession changes on the candidate form and displays a confirmation popup.
 * If the operation is confirmed, carries on; otherwise resets the profession.
 *
 * Synchronization of competencies is done in the formly definition. Would have done it here but screw formly, seriously.
 *
 * Keeping the form component smaller, y'know.
 */
@Injectable({ providedIn: "root" })
export class CandidateFormProfessionChangeService {
  constructor(private readonly confirmationService: ConfirmationService) {}

  setup(component: CandidateFormComponent): BehaviorSubject<string> {
    const subject = new BehaviorSubject<string>(null);
    component.form.controls.profession.valueChanges
      .pipe(
        switchMap((profession) => {
          const previous = component.candidate.profession;
          const current = profession;
          // double equal sign so that it treats `null` and `undefined` as equivalent values
          return previous == current || component.isMultiEditMode ? of(current) : this.confirm(profession, component);
        })
      )
      .subscribe(subject);
    return subject;
  }

  private confirm(profession: string, component: CandidateFormComponent): Observable<string> {
    return new Observable<string>((observer) => {
      const accept = (): void => {
        component.candidate.profession = profession;
        observer.next(profession);
        observer.complete();
      };

      const reject = (): void => {
        component.form.controls.profession.setValue(component.candidate.profession);
        observer.next(component.candidate.profession);
        observer.complete();
      };

      this.confirmationService.confirm({
        target: component.tagsComponent.professionContainer.nativeElement,
        message: translate("candidate.professionWarning"),
        icon: PrimeIcons.EXCLAMATION_TRIANGLE,
        accept: () => accept(),
        reject: () => reject(),
        close: () => reject(),
      } as UpdatedConfirmation);
    }).pipe(take(1));
  }
}
