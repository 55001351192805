import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Comment, CommentCreateInput, CommentUpdateInput, Feedback } from "@ankaadia/graphql";

@Component({
  selector: "app-candidate-comments",
  templateUrl: "./candidate-comments.component.html",
  styleUrl: "./candidate-comments.component.scss",
  standalone: false,
})
export class CandidateCommentsComponent {
  @Input()
  feedbackData: Feedback;

  @Input()
  readonly: boolean;

  @Output()
  readonly commentAdd = new EventEmitter<CommentCreateInput>();

  @Output()
  readonly commentChange = new EventEmitter<CommentUpdateInput>();

  @Output()
  readonly commentRefresh = new EventEmitter<Feedback>();

  addComment(content: string): void {
    if (!this.readonly && content !== "") {
      const comment = {
        feedbackId: this.feedbackData.id,
        organizationId: this.feedbackData.organizationId,
        comment: content,
      };
      this.commentAdd.emit(comment);
    }
  }

  updateComment(comment: Comment): void {
    if (!this.readonly) {
      this.commentChange.emit(comment);
    }
  }

  refreshComments(): void {
    this.commentRefresh.emit(this.feedbackData);
  }
}
