import { Component, DestroyRef, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormArray } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import {
  CalculationBasis,
  LaborAgreement,
  LaborAgreementAllowance,
  StaticDataModel,
  StaticDataType,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { clone, isNil } from "lodash";
import { OverlayPanel } from "primeng/overlaypanel";
import { Observable } from "rxjs";
import { removeControls } from "../../../shared/services/form.helper";
import { StaticDataContextEntry, StaticDataService } from "../../../shared/static-data/static-data.service";
import {
  ContractTemplateAllowanceForm,
  FormContractTemplateAllowance,
} from "../contract-template-dialog/contract-template-form.model";
import { ContractTemplateFormService } from "../contract-template-dialog/contract-template-form.service";
import {
  Override,
  OverrideIntersectionKey,
  OverrideLabelOptions,
  OverrideService,
} from "../contract-template-override.service";

type OverrideKey = OverrideIntersectionKey<LaborAgreementAllowance, FormContractTemplateAllowance>;
type OverrideModel<TKey extends OverrideKey> = Override<LaborAgreementAllowance, FormContractTemplateAllowance, TKey>;
type Options<TKey extends OverrideKey> = OverrideLabelOptions<
  LaborAgreementAllowance,
  FormContractTemplateAllowance,
  TKey
>;
const nameOf = nameofFactory<ContractTemplateAllowancesComponent>();

@Component({
  selector: "app-contract-template-allowances",
  templateUrl: "./contract-template-allowances.component.html",
  styleUrl: "./contract-template-allowances.component.scss",
  standalone: false,
})
export class ContractTemplateAllowancesComponent implements OnChanges {
  private readonly language = this.transloco.getActiveLang();

  protected readonly CalculationBasis = CalculationBasis;
  protected readonly calculationBases = this.staticData.transformEnumToStaticDataModel(
    "CalculationBasis",
    CalculationBasis
  );

  protected allowanceTypes: StaticDataModel[] = [];
  protected newAllowance?: ContractTemplateAllowanceForm = null;

  @Input({ required: true })
  organizationId: string;

  @Input({ required: true })
  immigrationCountry: SupportedImmigrationCountry;

  @Input({ required: true })
  form: FormArray<ContractTemplateAllowanceForm>;

  @Input({ required: false })
  laborAgreement?: LaborAgreement = null;

  @ViewChild(OverlayPanel)
  protected overlayPanel: OverlayPanel;

  get selectableAllowanceTypes(): StaticDataModel[] {
    const allowances = this.form.getRawValue();
    return this.allowanceTypes.filter(({ value }) => {
      return !allowances.some((allowance) => allowance.type === value);
    });
  }

  get canAdd(): boolean {
    if (isNil(this.newAllowance)) {
      return false;
    }

    const { type, customType } = this.newAllowance.getRawValue();
    if (isNil(type) && isNil(customType)) {
      return false;
    }

    const allowances = this.form.getRawValue();
    if (!isNil(type) && allowances.some((allowance) => allowance.type === type)) {
      return false;
    }

    if (!isNil(customType) && allowances.some((allowance) => allowance.customType === customType)) {
      return false;
    }

    return true;
  }

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly transloco: TranslocoService,
    private readonly staticData: StaticDataService,
    private readonly formService: ContractTemplateFormService,
    private readonly overrideService: OverrideService<LaborAgreementAllowance, FormContractTemplateAllowance>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes[nameOf("organizationId")] || changes[nameOf("immigrationCountry")]) {
      const { organizationId } = this;
      const immigrationCountry = this.immigrationCountry ?? SupportedImmigrationCountry.Unknown;
      const context = { organizationId, immigrationCountry };
      this.getStaticData(StaticDataType.LaborContractAllowance, context)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((allowanceTypes) => (this.allowanceTypes = allowanceTypes));
    }
  }

  protected add(event: Event): void {
    this.newAllowance = this.formService.createAllowanceForm({
      calculationBasis: CalculationBasis.AbsoluteAmount,
      amount: 0,
    });
    this.overlayPanel.show(event);
  }

  protected create(allowance: ContractTemplateAllowanceForm): void {
    if (!isNil(allowance)) {
      this.form.push(allowance);
      this.form.markAsDirty();
    }
  }

  protected delete(row: ContractTemplateAllowanceForm): void {
    removeControls(this.form, row);
    this.form.markAsDirty();
  }

  protected updateType(row: ContractTemplateAllowanceForm, selection: string | StaticDataModel): void {
    const { type, customType } = row.controls;
    if (typeof selection === "string") {
      customType.setValue(selection);
      customType.markAsDirty();
      type.setValue(null);
      type.markAsDirty();
    } else {
      customType.setValue(null);
      customType.markAsDirty();
      type.setValue(selection.value);
      type.markAsDirty();
    }
  }

  protected getAutoCompleteLabel(row: ContractTemplateAllowanceForm): string {
    const allowanceType = this.allowanceTypes.find(({ value }) => value === row.controls.type.value);
    const label = allowanceType ? allowanceType.label : row.controls.customType.value;
    return label ?? "";
  }

  protected getOverride<TKey extends OverrideKey>(
    row: ContractTemplateAllowanceForm,
    key: TKey
  ): OverrideModel<TKey> | null {
    if (isNil(this.laborAgreement)) {
      return null;
    }

    const allowance = row.getRawValue();
    const allowances = this.laborAgreement.allowances ?? [];
    const original = allowances.find(({ type, customType }) => {
      return allowance.type === type && allowance.customType === customType;
    });

    return isNil(original) ? null : this.overrideService.getOverride(original, allowance, key);
  }

  protected getOverrideLabel<TKey extends OverrideKey>(override: OverrideModel<TKey>, options?: Options<TKey>): string {
    return this.overrideService.getOverrideLabel(override, options);
  }

  protected cloneAllowanceTypes(): void {
    this.allowanceTypes = clone(this.allowanceTypes);
  }

  private getStaticData(type: StaticDataType, context: StaticDataContextEntry): Observable<StaticDataModel[]> {
    return this.staticData.getStaticData(type, this.language, context);
  }
}
