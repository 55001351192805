import { Component, Input, OnChanges } from "@angular/core";
import { StaticDataModel } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { isBoolean, isDate, isNil, keyBy } from "lodash";
import { Message, TreeNode } from "primeng/api";
import { forkJoin, map, Observable, of } from "rxjs";
import { Dictionary } from "ts-essentials";
import {
  CandidateFilterConditionOptions,
  CandidateFilterField,
  StaticDataCountryGroup,
} from "../../../candidate-filter/candidate-filter.model";
import { FilterMetadataMap } from "../../collection-edit-assigned-candidates/custom-filter.model";

@Component({
  selector: "app-collection-auto-filter-info",
  templateUrl: "./collection-auto-filter-info.component.html",
  standalone: false,
})
export class CollectionAutoFilterInfoComponent implements OnChanges {
  @Input({ required: true })
  fields: TreeNode<CandidateFilterField>[] = [];

  @Input({ required: true })
  filter: FilterMetadataMap = {};

  @Input({ required: true })
  includeSharedCandidates: boolean;

  protected messages: Message[] = [
    {
      severity: "info",
      summary: translate("severity.info"),
      detail: translate("loading"),
    },
  ];

  ngOnChanges(): void {
    this.setMessages(this.filter, this.fields);
  }

  setMessages(filter: FilterMetadataMap, fields: TreeNode<CandidateFilterField>[]): void {
    if (!fields?.length) {
      return;
    }
    const keys = filter ? Object.keys(filter) : [];
    if (!keys.length) {
      this.messages = [
        {
          severity: "info",
          summary: translate("severity.info"),
          detail: translate(
            this.includeSharedCandidates
              ? "autoCollection.filterNotSet.allCandidates"
              : "autoCollection.filterNotSet.ownCandidates"
          ),
        },
      ];
      return;
    }
    const flattenedFields: CandidateFilterField[] = flattenFields(fields);
    const fieldsLkp: Dictionary<CandidateFilterField> = keyBy(flattenedFields, (x) => x.value);

    if (keys?.length) {
      const allCriteria = keys.flatMap((key) => {
        const criteria = filter[key];
        return (Array.isArray(criteria) ? criteria : [criteria]).map((c) => ({ key, criteria: c }));
      });
      const allLocalizedCriteriaObservables = allCriteria.map((c) => {
        const field = fieldsLkp[c.key];
        const fieldName = field?.label ?? c.key;
        const op = c.criteria.matchMode ?? c.criteria.operator;
        const condition = field?.conditions?.find((x) => x.value === op);
        const operator = condition?.label ?? op;
        const value = c.criteria.value;
        const data = { fieldName, operator, value };

        const optionsObservable =
          (condition?.options as Observable<StaticDataModel[] | StaticDataCountryGroup[]>) ?? of(null);
        return optionsObservable.pipe(map((staticData) => Object.assign({ staticData }, data)));
      });

      forkJoin(allLocalizedCriteriaObservables)
        .pipe(
          map((list) => {
            const allLocalizedCriteria = list.map((criteria) =>
              isUnaryOperation(criteria)
                ? `'${criteria.fieldName}' ${criteria.operator}`
                : `'${criteria.fieldName}' ${criteria.operator} '${formatValue(criteria.value, criteria.staticData)}'`
            );
            const last = allLocalizedCriteria.pop();
            const joinedLocalizedCriteria = allLocalizedCriteria.length
              ? `${allLocalizedCriteria.join(", ")} ${translate("common.and")} ${last}.`
              : `${last}.`;
            return translate(
              this.includeSharedCandidates
                ? "autoCollection.filterDetails.allCandidates"
                : "autoCollection.filterDetails.ownCandidates",
              { criteria: joinedLocalizedCriteria }
            );
          })
        )
        .subscribe(
          (detail) =>
            (this.messages = [
              {
                severity: "info",
                summary: translate("severity.info"),
                detail: detail,
              },
            ])
        );
    }
  }
}

function flattenFields(fields: TreeNode<CandidateFilterField>[]): CandidateFilterField[] {
  return fields.flatMap((field) => (field.children ? flattenFields(field.children) : [field.data]));
}
function formatValue(value: any, staticData: CandidateFilterConditionOptions): string {
  if (isNil(value)) return "";
  if (Array.isArray(value)) return value.map((x) => formatValue(x, staticData)).join(", ");
  if (isBoolean(value)) return translate(value ? "boolean.true" : "boolean.false");
  if (isDate(value)) return value.toDateString();
  if (!staticData) return value?.toString();

  const sd = staticData as StaticDataModel[];
  if (sd) {
    const item = sd.find((x) => x.value == value);
    if (item) {
      return item.label;
    }
  }
  const sdcg = staticData as StaticDataCountryGroup[];
  if (sdcg) {
    const item = sdcg.flatMap((x) => x.items ?? []).find((x) => x.value == value);
    if (item) {
      return item.label;
    }
  }
  return value;
}

function isUnaryOperation(criteria: { fieldName: string; operator: string; value: any }): boolean {
  return criteria?.value === undefined;
}
