<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <ng-container *ngIf="form">
    <label for="companyDoctorAppointmentDate">{{ t("companyDoctorAppointmentDate.title") }}</label>
    <app-date-time
      [form]="control"
      inputId="companyDoctorAppointmentDate"
      [readonly]="readonly"
      [showClear]="showClear"
    ></app-date-time>
    <small class="p-error" *ngIf="control.controls.date.errors?.required">
      {{ t("companyDoctorAppointmentDate.required") }}
    </small>
  </ng-container>
</ng-container>
