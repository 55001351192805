<ng-container *appTranslate="let t">
  <p-blockUI [target]="table" [blocked]="!model?.id">
    <span class="block-ui-message">{{ t("candidate.formsWarning") }}</span>
  </p-blockUI>

  <app-document-templates
    #table
    class="candidate-files app-form"
    [templates]="templates"
    [candidateId]="model?.id"
    [organizationId]="model?.organizationId"
    [collectionId]="collectionId"
    [collectionOrganizationId]="collectionOrganizationId"
    [readonly]="readonly"
    mode="candidate"
    (reload)="loadTemplates()"
  ></app-document-templates>
</ng-container>
