import { Observable, ReplaySubject } from "rxjs";

export const pixoEditorApiKey = "38nrw8aqbd40";

export function loadPixoEditorSDK(): Observable<void> {
  const subject = new ReplaySubject<void>(1);
  function loaded(): void {
    subject.next();
  }
  if (document.getElementById("pixoeditor-jssdk")) {
    subject.next();
    return subject;
  }
  const js = document.createElement("script");
  js.id = "pixoeditor-jssdk";
  js.src = "https://pixoeditor.com/editor/scripts/bridge.m.js";
  js.onload = loaded;
  const head = document.getElementsByTagName("head").item(0);
  head.appendChild(js);
  return subject;
}
