<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <ng-container *ngIf="form" [formGroup]="form">
    <ng-container *ngIf="users$ | async as users">
      <label for="responsibleEmployees">{{ t("candidate.responsibleEmployees.title") }}</label>
      <p-multiSelect
        #multiSelect
        inputId="responsibleEmployees"
        display="chip"
        optionValue="value"
        optionLabel="label"
        formControlName="responsibleEmployees"
        [options]="users"
        [showClear]="true"
        [showToggleAll]="false"
        [placeholder]="t('candidate.responsibleEmployees.placeholder')"
        [readonly]="readonly"
        [tooltip]="multiSelect.valuesAsString"
      ></p-multiSelect>

      <small class="p-error" *ngIf="control.errors?.required">
        {{ t("candidate.responsibleEmployees.required") }}
      </small>
    </ng-container>
  </ng-container>
</ng-container>
