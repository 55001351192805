import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { VoteType } from "@ankaadia/graphql";

@Component({
  selector: "app-feedback-voting",
  templateUrl: "./feedback-voting.component.html",
  standalone: false,
})
export class FeedbackVotingComponent {
  VoteType = VoteType;

  @Input()
  type?: VoteType;

  @Input()
  readonly = true;

  @Input()
  control: FormControl;

  toggle(vote: VoteType): void {
    this.control.setValue(vote);
    this.control.markAsDirty();
  }
}
