<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <ng-container *ngIf="!hideControl">
    <label for="recognitionCenter">{{ t("recognitionCenter.title") }}</label>
    <p-dropdown
      inputId="recognitionCenter"
      [options]="recognitionCenters"
      [formControl]="control"
      [showClear]="true"
      [readonly]="readonly"
      [placeholder]="t('recognitionCenter.placeholder')"
    ></p-dropdown>

    <small class="p-error" *ngIf="control.errors?.required">
      {{ t("recognitionCenter.required") }}
    </small>
  </ng-container>
</ng-container>
