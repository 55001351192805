import { Component, Input } from "@angular/core";
import { SelectedDocumentField } from "@ankaadia/ankaadia-shared";
import { StaticDataType } from "@ankaadia/graphql";
import { StaticDataService } from "../../shared/static-data/static-data.service";

@Component({
  selector: "app-metadata-wrapper-debug-view",
  templateUrl: "./metadata-wrapper-debug-view.component.html",
  styleUrl: "./metadata-wrapper-debug-view.component.scss",
  standalone: false,
})
export class MetadataWrapperDebugViewComponent {
  protected readonly StaticDataType = StaticDataType;

  @Input({ required: true })
  documentType: string;

  @Input({ required: true })
  selectedField: SelectedDocumentField;

  constructor(protected readonly staticData: StaticDataService) {}

  protected isString(value: unknown): value is string {
    return typeof value === "string";
  }
}
