<ng-container *appTranslate="let t">
  <form class="scholarship p-fluid formgrid grid align-items-center mb-5" [formGroup]="form">
    <div class="field col-12 lg:col-2">
      <label [for]="'startDate' + inputId">{{ t("candidate.scholarship.startDate") }}</label>
      <p-calendar [inputId]="'startDate' + inputId" formControlName="startDate" [readonly]="readonly"></p-calendar>
    </div>

    <div class="field col-12 lg:col-2">
      <label [for]="'endDate' + inputId">{{ t("candidate.scholarship.endDate") }}</label>
      <p-calendar
        [inputId]="'endDate' + inputId"
        formControlName="endDate"
        [minDate]="form.controls.startDate.value"
        [readonly]="readonly"
      ></p-calendar>
    </div>

    <div class="field col-12 lg:col-2">
      <label [for]="'amountOfMoney' + inputId">{{ t("candidate.scholarship.amountOfMoney.title") }}</label>
      <p-inputNumber
        [inputId]="'amountOfMoney' + inputId"
        formControlName="amountOfMoney"
        [min]="0"
        [minFractionDigits]="2"
        [placeholder]="t('candidate.scholarship.amountOfMoney.placeholder')"
        [readonly]="readonly"
      ></p-inputNumber>
    </div>

    <div class="field col-12 lg:col-3">
      <label [for]="'awardFrequency' + inputId">{{ t("candidate.scholarship.awardFrequency.title") }}</label>
      <p-dropdown
        [inputId]="'awardFrequency' + inputId"
        [options]="awardFrequencies$ | async"
        formControlName="awardFrequency"
        [showClear]="true"
        [placeholder]="t('candidate.scholarship.awardFrequency.placeholder')"
        [readonly]="readonly"
      ></p-dropdown>
    </div>

    <div class="field col-12 lg:col-2">
      <app-candidate-responsible-role
        [inputId]="'responsibleRoleScholarship' + inputId"
        [form]="form"
        [responsibleRolePurpose]="ResponsibleRolePurposeType.Scholarship"
        [candidateId]="candidateId"
        [readonly]="readonly"
        [alwaysShownRoles]="responsibleRoles"
        class="field"
      ></app-candidate-responsible-role>
    </div>

    <div *ngIf="isOverlapping" class="field col-12 mb-3">
      <small class="p-error">{{ t("candidate.scholarships.errors.timeframeOverlap") }}</small>
    </div>

    <div class="field col-12 lg:col-11">
      <label [for]="'comment' + inputId">{{ t("comment.title") }}</label>
      <input [id]="'comment' + inputId" pInputText formControlName="comment" [readonly]="readonly" />
    </div>

    <div class="field col-12 lg:col-1 mt-5">
      <p-button icon="pi pi-times" [disabled]="readonly" (onClick)="removeScholarship()"></p-button>
    </div>
  </form>
</ng-container>
