import { Injectable } from "@angular/core";
import {
  CanAccessCandidateEditGQL,
  CanAccessCandidateViewGQL,
  CanAccessCollectionEditGQL,
  CanAccessCollectionViewGQL,
  CanAccessProcessViewGQL,
  CanAccessTaskActGQL,
  CandidateAccess,
  CollectionAccess,
  ProcessAccess,
  TaskAccess,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class DeepLinkAccessService {
  constructor(
    private readonly candidateEdit: CanAccessCandidateEditGQL,
    private readonly candidateView: CanAccessCandidateViewGQL,
    private readonly collectionEdit: CanAccessCollectionEditGQL,
    private readonly collectionView: CanAccessCollectionViewGQL,
    private readonly processView: CanAccessProcessViewGQL,
    private readonly taskAct: CanAccessTaskActGQL
  ) {}

  canAccessCandidateEdit(candidateId: string, organizationId: string): Observable<CandidateAccess> {
    return this.candidateEdit
      .fetch({ input: { candidateId, organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.canAccessCandidateEdit));
  }

  canAccessCandidateView(candidateId: string, organizationId: string): Observable<CandidateAccess> {
    return this.candidateView
      .fetch({ input: { candidateId, organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.canAccessCandidateView));
  }

  canAccessCollectionEdit(collectionId: string, organizationId: string): Observable<CollectionAccess> {
    return this.collectionEdit
      .fetch({ input: { collectionId, organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.canAccessCollectionEdit));
  }

  canAccessCollectionView(collectionId: string, organizationId: string): Observable<CollectionAccess> {
    return this.collectionView
      .fetch({ input: { collectionId, organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.canAccessCollectionView));
  }

  canAccessProcessView(processId: string, organizationId: string): Observable<ProcessAccess> {
    return this.processView
      .fetch({ input: { processId, organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.canAccessProcessView));
  }

  canAccessTaskAct(taskId: string, processId: string, organizationId: string): Observable<TaskAccess> {
    return this.taskAct
      .fetch({ input: { taskId, processId, organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.canAccessTaskAct));
  }
}
