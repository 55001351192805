<ng-container *appTranslate="let t">
  <p-card>
    <div class="flex flex-column p-card-content">
      <p-toolbar styleClass="mb-4">
        <ng-template pTemplate="left">
          <h3>{{ t("educationCourses.title") }}</h3>
        </ng-template>

        <ng-template pTemplate="right">
          <p-button [label]="t('common.new')" icon="pi pi-plus" (onClick)="add()"></p-button>
        </ng-template>
      </p-toolbar>

      <p-splitter
        class="h-full min-h-0"
        styleClass="h-full"
        [panelSizes]="[20, 80]"
        [minSizes]="[10, 50]"
        stateKey="ankaadia_course_splitter"
      >
        <ng-template pTemplate>
          <app-education-course-selector
            class="w-full"
            [courses]="courses$ | async"
            [selectedCourse]="selectedCourse.list"
            (selectedCourseChange)="view($event)"
          ></app-education-course-selector>
        </ng-template>

        <ng-template pTemplate>
          <app-education-course-view
            class="w-full"
            [course]="selectedCourse.view"
            [performance]="selectedCoursePerformance$ | async"
            (edited)="edit($event)"
            (deleted)="delete($event[0], $event[1])"
            (candidateStateUpdated)="updateCandidateState($event)"
            (attendanceUpdated)="updateAttendance($event)"
            (performanceUpdated)="updatePerformance($event)"
            (commentUpdated)="updateComment($event)"
            (weekPrefilled)="prefillWeek($event)"
          ></app-education-course-view>
        </ng-template>
      </p-splitter>
    </div>
  </p-card>

  <p-sidebar [(visible)]="showSidebar" styleClass="p-sidebar-md">
    <app-education-course-dialog
      *ngIf="showSidebar"
      [course]="selectedCourse.edit"
      (created)="create($event)"
      (updated)="update($event)"
      (closed)="close()"
    ></app-education-course-dialog>
  </p-sidebar>
</ng-container>
