import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { GetSelectableCollectionsQuery, GetSelectableOrganizationsQuery } from "@ankaadia/graphql";
import { CandidatePageEntry } from "../candidates/candidates.service";

export type Organization = GetSelectableOrganizationsQuery["getSelectableOrganizations"][number];
export type Collection = GetSelectableCollectionsQuery["getSelectableCollections"][number]["collection"];
export type Sharing = GetSelectableCollectionsQuery["getSelectableCollections"][number]["sharing"];

export interface CollectionId {
  collectionId: string;
  organizationId: string;
  name?: string;
  sharing?: Sharing;
  isFavorite?: boolean;
}

export interface CourseId {
  courseId: string;
}

export type EventTableEntityId = CandidatePageEntry | CollectionId | CourseId;

export function isCandidateId(id: EventTableEntityId): id is CandidatePageEntry {
  return id && !isCollectionId(id) && !isCourseId(id);
}

export function isCollectionId(id: EventTableEntityId): id is CollectionId {
  const nameofCollectionId = nameofFactory<CollectionId>();
  return id && nameofCollectionId("collectionId") in id;
}

export function isCourseId(id: EventTableEntityId): id is CourseId {
  const nameofCourseId = nameofFactory<CourseId>();
  return id && nameofCourseId("courseId") in id;
}
