import { ChangeDetectionStrategy, Component } from "@angular/core";
import { SettingsService } from "../../shared/services/settings.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrl: "./footer.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FooterComponent {
  readonly isLicensed = this.settings.isLicensed;
  readonly organizationId = this.settings.organizationId;
  readonly creatorOrganizationId = this.settings.creatorOrganizationId;

  constructor(private readonly settings: SettingsService) {}
}
