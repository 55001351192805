import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { LaborMarketAdmissionCandidateDataDe, StaticDataModel, SupportedImmigrationCountry } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { EmploymentApprovalAuthorityService } from "../../employment-approval-authority/employment-approval-authority.service";

const nameOf = nameofFactory<LaborMarketAdmissionCandidateDataDe>();

@Component({
  selector: "app-candidate-employment-approval-authority",
  templateUrl: "./candidate-employment-approval-authority.component.html",
  standalone: false,
})
export class CandidateEmploymentApprovalAuthorityComponent implements OnInit, OnDestroy {
  private readonly language = this.transloco.getActiveLang();
  protected employmentApprovalAuthorities: StaticDataModel[];

  @Input()
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  candidateOrganizationId: string;

  @Input()
  immigrationCountry: SupportedImmigrationCountry;

  @Input()
  readonly: boolean;

  @Output()
  readonly valueChanged = new EventEmitter<string>();

  get control(): FormControl<string> {
    return this.form.get(nameOf("employmentApprovalAuthority")) as FormControl<string>;
  }

  set control(value: FormControl<string>) {
    this.form.setControl(nameOf("employmentApprovalAuthority"), value);
  }

  constructor(
    private readonly transloco: TranslocoService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly employmentApprovalAuthorityService: EmploymentApprovalAuthorityService,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<string>(undefined);
    this.control.valueChanges.subscribe((value) => this.valueChanged.emit(value));

    this.employmentApprovalAuthorityService
      .getEmploymentApprovalAuthorities(this.candidateOrganizationId, this.immigrationCountry)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        const value = this.control.value;
        this.employmentApprovalAuthorities = data;
        this.control.setValue(value);
        this.changeDetector.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("employmentApprovalAuthority"));
  }
}
