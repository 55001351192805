import { Component } from "@angular/core";
import {
  AdHocEducationExam,
  AdHocEducationExamForCandidateFragment,
  EducationCourseForCandidateListFragment,
  EducationExam,
  EducationExamForCandidateFragment,
  EducationExamResultFullDataFragment,
} from "@ankaadia/graphql";
import { Observable, combineLatest, map } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { EducationCourseService } from "../education-courses/education-course.service";
import { EducationExamResultTableColumn } from "../education-exam-results/education-exam-results-table/education-exam-results-table.model";
import { EducationExamTableColumnName } from "../education-exams/education-exam-table/education-exam-table.component";
import { EducationExamService } from "../education-exams/education-exam.service";

@Component({
  selector: "app-education-overview",
  templateUrl: "./education-overview.component.html",
  styleUrl: "./education-overview.component.scss",
  standalone: false,
})
export class EducationOverviewComponent {
  readonly examColumns = this.getExamColumns();
  readonly examResultColumns = this.getExamResultColumns();
  readonly courses$ = this.getCourses();
  readonly exams$ = this.getAllExams();
  readonly examResults$ = this.getExamResults();

  constructor(
    private readonly settings: SettingsService,
    private readonly educationCourseService: EducationCourseService,
    private readonly educationExamService: EducationExamService
  ) {}

  private getCourses(): Observable<EducationCourseForCandidateListFragment[]> {
    return this.educationCourseService.listForCandidate(this.settings.userOrCandidateId, this.settings.organizationId);
  }

  private getAllExams(): Observable<(EducationExam | AdHocEducationExam)[]> {
    return this.getAllCandidateExams().pipe(
      map((candidateExams) => candidateExams.flatMap((candidateExam) => candidateExam.exam))
    );
  }

  private getExamResults(): Observable<EducationExamResultFullDataFragment[]> {
    return this.getAllCandidateExams().pipe(
      map((candidateExams) => candidateExams.flatMap((candidateExam) => candidateExam.result).filter((x) => !!x))
    );
  }

  private getAllCandidateExams(): Observable<
    (EducationExamForCandidateFragment | AdHocEducationExamForCandidateFragment)[]
  > {
    return combineLatest([this.getCandidateExams(), this.getCandidateAdHocExams()]).pipe(
      map(([exams, adHocExams]) => [...exams, ...adHocExams])
    );
  }

  private getCandidateExams(): Observable<EducationExamForCandidateFragment[]> {
    return this.educationExamService.getByCandidateId(this.settings.userOrCandidateId, this.settings.organizationId);
  }

  private getCandidateAdHocExams(): Observable<AdHocEducationExamForCandidateFragment[]> {
    return this.educationExamService.getAdHocByCandidateId(
      this.settings.userOrCandidateId,
      this.settings.organizationId
    );
  }

  private getExamColumns(): EducationExamTableColumnName[] {
    return [
      "name",
      "examType",
      "examInstitution",
      "examType",
      "plannedExamDateAndTime",
      "examDateAndTime",
      "examLocation",
    ];
  }

  private getExamResultColumns(): EducationExamResultTableColumn[] {
    return ["name", "examDateAndTime", "languageLevel", "examResult", "passedExamModules", "examModuleInformation"];
  }
}
