import { Injectable } from "@angular/core";
import { IsMatchingEnabled, IsMatchingEnabledGQL, SetMatchingEnabledGQL } from "@ankaadia/graphql";
import { map, Observable } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";

@Injectable({ providedIn: "root" })
export class OrganizationDataService {
  constructor(
    private readonly settings: SettingsService,
    private readonly matchingEnabledGet: IsMatchingEnabledGQL,
    private readonly matchingEnabledSet: SetMatchingEnabledGQL
  ) {}

  isMatchingEnabled(organizationIds: string[]): Observable<IsMatchingEnabled[]> {
    return this.matchingEnabledGet
      .fetch({ input: { organizationId: this.settings.organizationId, destinationOrganizationIds: organizationIds } })
      .pipe(map((value) => value.data.isMatchingEnabled));
  }

  setMatchingEnabled(organizationId: string, enabled: boolean): Observable<boolean> {
    return this.matchingEnabledSet
      .mutate({
        input: {
          organizationId: this.settings.organizationId,
          destinationOrganizationId: organizationId,
          enabled: enabled,
        },
      })
      .pipe(map((value) => value.data.setMatchingEnabled.status));
  }
}
