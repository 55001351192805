import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormArray } from "@angular/forms";
import { Document, DocumentMode } from "@ankaadia/graphql";
import { FileUpload } from "primeng/fileupload";
import { of } from "rxjs";
import { DownloadService } from "../../../shared/services/download.service";
import { FileUploadService } from "../../../shared/services/file-upload.service";
import { DocumentForm, FreeFormatFileForm } from "../document-form.model";
import { DocumentFormBuilder, DocumentFormService } from "../document-form.service";
import { DocumentsService } from "../documents.service";

@Component({
  selector: "app-free-format-document-selector",
  templateUrl: "./free-format-document-selector.component.html",
  styleUrl: "./free-format-document-selector.component.scss",
  providers: [FileUploadService],
  standalone: false,
})
export class FreeFormatDocumentSelectorComponent implements OnChanges, AfterViewInit {
  @Input({ required: true })
  form: DocumentForm;

  @Input({ required: true })
  mode: DocumentMode;

  @Input({ required: true })
  readonly: boolean;

  @Input()
  processLanguage?: string;

  @ViewChild("fileUpload")
  fileUpload: FileUpload;

  @ContentChild("toolbar", { read: TemplateRef })
  toolbarTemplate?: TemplateRef<any>;

  get document(): Document {
    return this.form.getRawValue();
  }

  get freeFormatFiles(): FormArray<FreeFormatFileForm> {
    return this.form.controls.freeFormatFiles;
  }

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly documentService: DocumentsService,
    private readonly fileUploadService: FileUploadService,
    private readonly downloadService: DownloadService,
    private readonly documentFormService: DocumentFormService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form) {
      this.initFiles();
    }
    if (changes.document) {
      this.initFiles();
    }
  }

  ngAfterViewInit(): void {
    this.initFiles();
  }

  selectFiles(selectedFiles: File[]): void {
    // Filter Files with same file name
    const finallySelectedFiles = this.fileUploadService.ensureValidFiles(this.fileUpload, selectedFiles);
    const builder = this.createDocumentFormBuilder();
    for (const file of finallySelectedFiles) {
      if (this.findIndexInFiles(file) === -1) {
        const control = builder.createFreeFormatFileForm(file);
        this.freeFormatFiles.push(control);
        control.markAsDirty();
      }
    }
    this.changeDetector.detectChanges();
  }

  removeFile(file: FreeFormatFileForm): void {
    const index = this.freeFormatFiles.controls.indexOf(file);
    this.freeFormatFiles.removeAt(index);
    this.freeFormatFiles.markAsDirty();
    this.changeDetector.detectChanges();
  }

  downloadFile(fileForm: FreeFormatFileForm): void {
    const { file, blob, name } = fileForm.getRawValue();
    const { organizationId, candidateId } = this.document;
    const url$ = blob
      ? this.documentService.getDownloadUrl(blob, organizationId, candidateId)
      : of(URL.createObjectURL(file));

    url$.subscribe((url) => this.downloadService.downloadFile(name, url));
  }

  private initFiles(): void {
    if (this.fileUpload) {
      this.fileUpload.files = [];
      this.fileUpload.progress = 100;
      this.fileUpload.cd.detectChanges();
      this.changeDetector.detectChanges();
    }
  }

  private findIndexInFiles(file: File): number {
    return this.freeFormatFiles.value.findIndex(
      (x) => x.name === file.name && x.size === file.size && x.type === file.type
    );
  }

  private createDocumentFormBuilder(): DocumentFormBuilder {
    return this.documentFormService.createDocumentFormBuilder(this.document, {
      documentMode: this.mode,
      isReadonly: this.readonly,
    });
  }
}
