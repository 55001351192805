import { Injectable } from "@angular/core";
import {
  CreateSharingsGQL,
  GetEnabledSharingGQL,
  RemoveSharingGQL,
  Sharing,
  SharingCreateInput,
  SharingFragmentDoc,
  SharingUpdateInput,
  SubmitSharingGQL,
  UnsubmitSharingGQL,
  UpdateSharingGQL,
} from "@ankaadia/graphql";
import { ApolloCache } from "@apollo/client/cache";
import { TranslocoService } from "@jsverse/transloco";
import { clone } from "lodash";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class SharingsService {
  readonly language = this.transloco.getActiveLang();
  constructor(
    private readonly removeSharing: RemoveSharingGQL,
    private readonly upSharing: UpdateSharingGQL,
    private readonly createSharing: CreateSharingsGQL,
    private readonly sharingSubmit: SubmitSharingGQL,
    private readonly sharingUnsubmit: UnsubmitSharingGQL,
    private readonly transloco: TranslocoService,
    private readonly getSharing: GetEnabledSharingGQL
  ) {}

  deleteSharing(sharing: Sharing): Observable<boolean> {
    return this.removeSharing
      .mutate({ input: { sharingId: sharing.id, organizationId: sharing.organizationId, _etag: sharing._etag } })
      .pipe(map((x) => x.data.deleteSharing.status));
  }

  updateSharing(sharing: SharingUpdateInput): Observable<Sharing> {
    const updateSharing = new SharingUpdateInput();
    updateSharing._etag = sharing._etag;
    updateSharing.creationDate = sharing.creationDate;
    updateSharing.enabled = sharing.enabled;
    updateSharing.id = sharing.id;
    updateSharing.organizationId = sharing.organizationId;
    updateSharing.sharedCollectionId = sharing.sharedCollectionId;
    updateSharing.sharingType = sharing.sharingType;
    updateSharing.autoCVRequested = sharing.autoCVRequested;
    updateSharing.providedTemplates = sharing.providedTemplates;
    updateSharing.sharedFileTypes = sharing.sharedFileTypes;
    updateSharing.disableSubmitFeedback = sharing.disableSubmitFeedback;
    updateSharing.isAnonymous = sharing.isAnonymous;
    updateSharing.anonymizationOptions = sharing.anonymizationOptions;
    updateSharing.sharedTabs = sharing.sharedTabs;
    updateSharing.expiryDate = sharing.expiryDate;
    updateSharing.isCandidateEditable = sharing.isCandidateEditable;
    updateSharing.shareInternalDocuments = sharing.shareInternalDocuments;
    updateSharing.showCompletionStateAtDocuments = sharing.showCompletionStateAtDocuments;
    updateSharing.enableNotificationEmails = sharing.enableNotificationEmails;
    updateSharing.notificationEmailUserGroupIds = sharing.notificationEmailUserGroupIds;
    updateSharing.profileAccessId = sharing.profileAccessId;

    return this.upSharing.mutate({ input: updateSharing }).pipe(map((x) => x.data.updateSharing as Sharing));
  }

  submitSharing(id: string, orgId: string): Observable<Sharing> {
    return this.sharingSubmit
      .mutate({ input: { sharingId: id, organizationId: orgId } }, { update: (cache) => updateSharingRef(id, cache) })
      .pipe(map((x) => x.data.submitSharing));
  }

  unsubmitSharing(id: string, orgId: string): Observable<Sharing> {
    return this.sharingUnsubmit
      .mutate({ input: { sharingId: id, organizationId: orgId } }, { update: (cache) => updateSharingRef(id, cache) })
      .pipe(map((x) => x.data.unsubmitSharing));
  }

  createSharings(createInput: SharingCreateInput): Observable<Sharing[]> {
    return this.createSharing.mutate({ input: createInput }).pipe(map((x) => x.data.createSharings));
  }

  getEnabledSharing(collectionId: string, organizationId: string): Observable<Sharing> {
    return this.getSharing
      .fetch({ input: { collectionId: collectionId, organizationId: organizationId } })
      .pipe(map((x) => x.data.getEnabledSharing));
  }
}

function updateSharingRef(sharingId: string, cache: ApolloCache<unknown>): void {
  const sharingRef = cache.readFragment<Sharing>({
    fragment: SharingFragmentDoc,
    fragmentName: "Sharing",
    id: `Sharing:${sharingId}`,
  });
  const sharingRefCopy = clone(sharingRef);
  sharingRefCopy.submitted = false;
  cache.writeFragment({
    data: sharingRefCopy,
    fragment: SharingFragmentDoc,
    fragmentName: "Sharing",
  });
}
