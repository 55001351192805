<div [controlRef]="formControl" [fieldConfig]="this">
  <p-messages
    *ngIf="props.messages"
    [(value)]="props.messages"
    [enableService]="false"
    [closable]="props.closeable"
  ></p-messages>

  <div *ngFor="let field of field.fieldGroup; let i = index">
    <div class="flex flex-row">
      <formly-group [field]="$any(field)">
        <small *ngIf="showError" class="p-error">
          <formly-validation-message class="ui-message-text" [field]="field"></formly-validation-message>
        </small>
      </formly-group>
      <p-button
        [removeBtnRef]="formControl"
        *ngIf="!props.readonly && formControl.enabled"
        [label]="props.removeLabel"
        icon="pi pi-times"
        iconPos="left"
        type="button"
        class="align-self-center ml-2"
        (onClick)="remove(i)"
        [disabled]="props.disabledRemoveButton"
      ></p-button>
    </div>
    <hr class="mt-0" />
  </div>

  <div class="flex justify-content-start">
    <p-button
      [addBtnRef]="formControl"
      [disabled]="props.disabledAddButton || props.readonly"
      [attr.data-testid]="'btn-add-' + props.id"
      [label]="props.addLabel"
      icon="pi pi-plus"
      iconPos="left"
      type="button"
      (onClick)="add(null, props?.defaultValue?.())"
    ></p-button>
    <p-button
      [addBtnRef]="formControl"
      class="ml-2"
      *ngIf="props.additionalLabel"
      [disabled]="props.disableAdditionalAddButton || props.readonly"
      [label]="props.additionalLabel"
      icon="pi pi-plus"
      type="button"
      (onClick)="add(null, to?.additionalDefaultValue?.())"
    ></p-button>
  </div>
</div>
