<ng-container *appTranslate="let t">
  <ng-container *ngFor="let language of languages | async">
    <p-editor
      spellcheck="true"
      [lang]="selectedLanguage"
      *ngIf="selectedLanguage === language.value"
      (onInit)="initialized($event.editor)"
      (onTextChange)="changed($event.htmlValue, 'user')"
      [style]="{ height: '338px' }"
    >
      <ng-template pTemplate="header">
        <span class="ql-formats">
          <select class="ql-size"></select>
        </span>
        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
        </span>
        <span class="ql-formats">
          <select class="ql-color"></select>
          <select class="ql-background"></select>
        </span>
        <span class="ql-formats">
          <button class="ql-header" value="1"></button>
          <button class="ql-header" value="2"></button>
          <button class="ql-blockquote"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
          <button class="ql-indent" value="-1"></button>
          <button class="ql-indent" value="+1"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-direction" value="rtl"></button>
          <select class="ql-align"></select>
        </span>
        <span class="ql-formats">
          <button class="ql-link"></button>
        </span>
        <p-dropdown
          styleClass="inline-flex minimalistic"
          [options]="languages | async"
          [(ngModel)]="selectedLanguage"
          (onChange)="updateSelectedDescription($event.value)"
        >
          <ng-template *ngFor="let template of ['item', 'selectedItem']" let-language [pTemplate]="template">
            <div class="flag-container">
              <span class="flag flag-small flag-{{ flags[language.value] }}"></span>
              <span *ngIf="template === 'item'" class="ml-2">{{ language.label }}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </ng-template>
    </p-editor>
  </ng-container>

  <small class="p-error" *ngIf="form.errors?.required">
    {{ t("description.required") }}
  </small>
</ng-container>
