<ng-container *appTranslate="let t">
  <p-table
    class="app-form"
    styleClass="p-datatable app-form p-datatable-sm"
    sortField="type"
    [value]="filteredRequirementForms"
    [sortOrder]="1"
    [customSort]="true"
    (sortFunction)="customSort($event)"
    [virtualScroll]="true"
    [virtualScrollItemSize]="scollItemSize"
    dataKey="value.type"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-column md:flex-row justify-content-between md:align-items-center table-header">
        <div class="flex-1 p-input-icon-left mr-2 table-search">
          <i class="pi pi-search"></i>
          <input
            pInputText
            [ngModel]="''"
            (ngModelChange)="searchInput$.next($event)"
            type="text"
            [placeholder]="t('common.search')"
          />
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr style="white-space: nowrap">
        <th pSortableColumn="type" class="fixed-width">
          {{ t("documentType.title") }}
          <p-sortIcon field="type" />
        </th>
        <th>{{ t("documentSet.checkSpecificSets.title") }}</th>
        <th>{{ t("documentSet.type.title") }}</th>
        <th *ngFor="let key of keys(columns)" [pSortableColumn]="key">
          {{ columns[key] }}
          <p-sortIcon [field]="key" />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-editing="editing" let-index="rowIndex">
      <tr [formGroup]="rowData" [pSelectableRow]="rowData" [style.height.px]="scollItemSize" class="white-space-nowrap">
        <td class="fixed-width" [pTooltip]="allowedUploadFileTypes[rowData.controls.type.value]" showDelay="500">
          {{ allowedUploadFileTypes[rowData.controls.type.value] }}
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-selectButton
                [options]="checkSpecificSetsOptions"
                [unselectable]="true"
                formControlName="checkSpecificSets"
                optionLabel="label"
                optionValue="value"
              />
            </ng-template>
            <ng-template pTemplate="output">
              <ng-container *ngIf="rowData.controls.checkSpecificSets.value; else checkAllSets">
                {{ t("documentSet.checkSpecificSets.options.yes") }}
              </ng-container>
              <ng-template #checkAllSets>
                {{ t("documentSet.checkSpecificSets.options.no") }}
              </ng-template>
              <small class="p-error" *ngIf="rowData.controls.checkSpecificSets.errors?.required">
                {{ t("common.validation.requiredShort") }}
              </small>
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn class="white-space-nowrap">
          <p-cellEditor *ngIf="rowData.controls.checkSpecificSets.value; else checkAllDocumentSets">
            <ng-template pTemplate="input">
              <p-selectButton
                *ngIf="!rowData.controls.documentSetTypes.disabled; else defaultSet"
                [options]="rowData.controls.type.value | documentSets"
                [multiple]="true"
                formControlName="documentSetTypes"
                optionLabel="label"
                optionValue="value"
              />
              <ng-template #defaultSet>{{ t("documentSet.default") }}</ng-template>
            </ng-template>
            <ng-template pTemplate="output">
              <ng-container *ngIf="rowData.controls.documentSetTypes.value as documentSetTypes; else defaultSet">
                <div *ngFor="let documentSetType of documentSetTypes">
                  {{ t($any("documentSet.types." + documentSetType)) }}
                </div>
              </ng-container>
              <ng-template #defaultSet>{{ t("documentSet.default") }}</ng-template>
              <small class="p-error" *ngIf="rowData.controls.documentSetTypes.errors?.required">
                {{ t("common.validation.requiredShort") }}
              </small>
            </ng-template>
          </p-cellEditor>
          <ng-template #checkAllDocumentSets>---</ng-template>
        </td>
        <td *ngFor="let key of keys(columns)">
          <p-checkbox [inputId]="key + index" [binary]="true" [formControl]="rowData.controls[key]" />
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
