<ng-container *appTranslate="let t">
  <p-blockUI [target]="table" [blocked]="!model?.id">
    <span class="block-ui-message">{{ t("candidate.collectionWarning") }}</span>
  </p-blockUI>

  <app-table
    #table
    [columns]="columns"
    [items]="collectionsForTable"
    [captionOperations]="captionOperations"
    [tableOperations]="tableOperations"
    [(showSidebar)]="showDialog"
    sidebarSize="medium"
  >
    <app-candidate-assign-collections
      [model]="model"
      [visible]="showDialog"
      (closed)="closed($event)"
    ></app-candidate-assign-collections>
  </app-table>
</ng-container>
