<ng-container *appTranslate="let t; lang: language + '|static'">
  <div class="flex flex-column h-full">
    <app-table
      *ngIf="documents && staticDataDocFormats"
      [title]="title"
      [items]="documents"
      [columns]="columns"
      [newOperations]="newOperations"
      [captionOperations]="captionOperations"
      [tableOperations]="tableOperations"
      [scrollable]="true"
      [paginator]="false"
      [language]="processLanguage"
      [(showSidebar)]="showSidebar"
      [sidebarSize]="isDocumentSelectionMode(mode) ? 'large' : 'medium'"
      [showArrows]="showArrows"
      [sortField]="sortField"
      [warningMessage]="warningMessage"
      [styleClass]="styleClass"
      class="max-h-full"
    >
      <ng-template #caption *ngIf="captionTemplate">
        <ng-container *ngTemplateOutlet="captionTemplate"></ng-container>
      </ng-template>

      <app-document-edit-dialog
        *ngIf="showSidebar && selectedDocument"
        [document]="selectedDocument"
        [existingDocuments]="documents"
        [creatableDocumentTypes]="creatableDocumentTypes"
        [candidateForm]="candidateForm"
        [readonly]="readonly"
        [mode]="mode"
        [processLanguage]="processLanguage"
        [confirmCreation]="confirmCreation"
        [personName]="personName"
        [showInternalDoc]="showInternalDoc"
        [showDocumentCompletion]="showDocumentCompletion"
        (closed)="close()"
        (saved)="save()"
      ></app-document-edit-dialog>

      <app-document-preview-dialog
        *ngIf="showSidebar && selectedFile"
        [document]="selectedFile.document"
        [file]="selectedFile.file"
        [fileName]="selectedFile.file.name"
        [url]="selectedFile.url"
        [processLanguage]="processLanguage"
        (closed)="close()"
      ></app-document-preview-dialog>
    </app-table>
    <app-document-dropzone
      *ngIf="candidate && !settings.isCandidate"
      poolId="single-candidate"
      visibility="always"
      [candidateOrCollection]="candidate"
      style="flex: 1 1 auto"
    />
  </div>
</ng-container>
