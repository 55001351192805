import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { CandidateAlignedLabelComponent } from "./candidate-aligned-label/candidate-aligned-label.component";
import { CandidateArrivalDateComponent } from "./candidate-arrival-date/candidate-arrival-date.component";
import { CandidateChamberOfCraftsComponent } from "./candidate-chamber-of-crafts/candidate-chamber-of-crafts.component";
import { CandidateEmployerEndOfProbationDateComponent } from "./candidate-employer-end-of-probation-date/candidate-employer-end-of-probation-date.component";
import { CandidateEmployerFirstDeputyComponent } from "./candidate-employer-first-deputy/candidate-employer-first-deputy.component";
import { CandidateEmployerOrganizationComponent } from "./candidate-employer-organization/candidate-employer-organization.component";
import { CandidateEmployerRepresentativeComponent } from "./candidate-employer-representative/candidate-employer-representative.component";
import { CandidateEmployerTypeOfWorkLocationComponent } from "./candidate-employer-type-of-work-location/candidate-employer-type-of-work-location.component";
import { CandidateEmployerWorkContractSignDateComponent } from "./candidate-employer-work-contract-sign-date/candidate-employer-work-contract-sign-date.component";
import { CandidateEmployerWorkLocationComponent } from "./candidate-employer-work-location/candidate-employer-work-location.component";
import { CandidateEmployerWorkStartDateComponent } from "./candidate-employer-work-start-date/candidate-employer-work-start-date.component";
import { CandidateEmploymentApprovalAuthorityComponent } from "./candidate-employment-approval-authority/candidate-employment-approval-authority.component";
import { CandidateEndOfFurtherEducationComponent } from "./candidate-end-of-further-education/candidate-end-of-further-education.component";
import { CandidateEquivalenceTestComponent } from "./candidate-equivalence-test/candidate-equivalence-test.component";
import { CandidateEstimatedArrivalDateComponent } from "./candidate-estimated-arrival-date/candidate-estimated-arrival-date.component";
import { CandidateEstimatedWorkStartDateComponent } from "./candidate-estimated-work-start-date/candidate-estimated-work-start-date.component";
import { CandidateFederalStateComponent } from "./candidate-federal-state/candidate-federal-state.component";
import { CandidateFingerPrintDateComponent } from "./candidate-finger-print-date/candidate-finger-print-date.component";
import { CandidateFlightDateComponent } from "./candidate-flight-date/candidate-flight-date.component";
import { CandidateHealthProfessionsRegisterAppointmentDateComponent } from "./candidate-health-professions-register-appointment-date/candidate-health-professions-register-appointment-date.component";
import { CandidateHealthProfessionsRegisterSubmissionComponent } from "./candidate-health-professions-register-submission/candidate-health-professions-register-submission.component";
import { CandidateHealthProfessionsRegistrationComponent } from "./candidate-health-professions-registration/candidate-health-professions-registration.component";
import { CandidateImmigrationAuthorityComponent } from "./candidate-immigration-authority/candidate-immigration-authority.component";
import { CandidateLegalBasisComponent } from "./candidate-legal-basis/candidate-legal-basis.component";
import { CandidateLocationOfComplementaryMeasureComponent } from "./candidate-location-of-complementary-measure/candidate-location-of-complementary-measure.component";
import { CandidateNoQualificationEvaluationComponent } from "./candidate-no-qualification-evaluation/candidate-no-qualification-evaluation.component";
import { CandidatePathOfRecognitionComponent } from "./candidate-path-of-recognition/candidate-path-of-recognition.component";
import { CandidatePickUpDetailsComponent } from "./candidate-pick-up-details/candidate-pick-up-details.component";
import { CandidatePlannedEntryDateComponent } from "./candidate-planned-entry-date/candidate-planned-entry-date.component";
import { CandidateQualificationMeasureComponent } from "./candidate-qualification-measure/candidate-qualification-measure.component";
import { CandidateQualificationTypeComponent } from "./candidate-qualification-type/candidate-qualification-type.component";
import { CandidateRecognitionAuthorityComponent } from "./candidate-recognition-authority/candidate-recognition-authority.component";
import { CandidateRecognitionCenterComponent } from "./candidate-recognition-center/candidate-recognition-center.component";
import { CandidateRecognitionFileNumberAcceleratedProcedureComponent } from "./candidate-recognition-file-number-accelerated-procedure/candidate-recognition-file-number-accelerated-procedure.component";
import { CandidateRecognitionFileNumberComponent } from "./candidate-recognition-file-number/candidate-recognition-file-number.component";
import { CandidateRecognitionPaymentRoleAcceleratedComponent } from "./candidate-recognition-payment-role-accelerated/candidate-recognition-payment-role-accelerated.component";
import { CandidateRecognitionPaymentRoleComponent } from "./candidate-recognition-payment-role/candidate-recognition-payment-role.component";
import { CandidateRecognitionTypeComponent } from "./candidate-recognition-type/candidate-recognition-type.component";
import { CandidateResidenceBankAppointmentDateComponent } from "./candidate-residence-bank-appointment-date/candidate-residence-bank-appointment-date.component";
import { CandidateResidenceCompanyDoctorDateComponent } from "./candidate-residence-company-doctor-date/candidate-residence-company-doctor-date.component";
import { CandidateResidenceMedicalExaminationDateComponent } from "./candidate-residence-medical-examination-date/candidate-residence-medical-examination-date.component";
import { CandidateResidencePermitWarningsComponent } from "./candidate-residence-permit-warnings/candidate-residence-permit-warnings.component";
import { CandidateResidencePermitComponent } from "./candidate-residence-permit/candidate-residence-permit.component";
import { CandidateResidencePoliceRecordDateComponent } from "./candidate-residence-police-record-date/candidate-residence-police-record-date.component";
import { CandidateResidenceRegistrationOfficeDateComponent } from "./candidate-residence-registration-office-date/candidate-residence-registration-office-date.component";
import { CandidateResponsibleEmployeeComponent } from "./candidate-responsible-employee/candidate-responsible-employee.component";
import { CandidateResponsibleOrganizationComponent } from "./candidate-responsible-organization/candidate-responsible-organization.component";
import { CandidateResponsibleRepresentativeGroupComponent } from "./candidate-responsible-representative-group/candidate-responsible-representative-group.component";
import { CandidateResponsibleRepresentativeComponent } from "./candidate-responsible-representative/candidate-responsible-representative.component";
import { CandidateResponsibleRoleComponent } from "./candidate-responsible-role/candidate-responsible-role.component";
import { CandidateRwrFileNumberComponent } from "./candidate-rwr-file-number/candidate-rwr-file-number.component";
import { CandidateStartOfFurtherEducationComponent } from "./candidate-start-of-further-education/candidate-start-of-further-education.component";
import { CandidateTargetRecognitionComponent } from "./candidate-target-recognition/candidate-target-recognition.component";
import { CandidateWorkStartDateComponent } from "./candidate-work-start-date/candidate-work-start-date.component";
import { CandidateEmployerWorkConformationComponent } from "./candidate-workConformationDate/candidate-employer-workConformation.component";
import { CandidateQualificationExamsComponent } from "./candidate-qualification-exams/candidate-qualification-exams.component";
import { QualificationExamComponent } from "./candidate-qualification-exams/qualification-exam/qualification-exam.component";

const components = [
  CandidateEmployerEndOfProbationDateComponent,
  CandidateQualificationTypeComponent,
  CandidateRecognitionAuthorityComponent,
  CandidateEmploymentApprovalAuthorityComponent,
  CandidateFederalStateComponent,
  CandidateRecognitionTypeComponent,
  CandidateEquivalenceTestComponent,
  CandidateTargetRecognitionComponent,
  CandidatePathOfRecognitionComponent,
  CandidateLegalBasisComponent,
  CandidateQualificationMeasureComponent,
  CandidateResponsibleEmployeeComponent,
  CandidateResponsibleRoleComponent,
  CandidateStartOfFurtherEducationComponent,
  CandidateEndOfFurtherEducationComponent,
  CandidateFlightDateComponent,
  CandidateArrivalDateComponent,
  CandidateResidencePermitComponent,
  CandidateResidencePermitWarningsComponent,
  CandidateImmigrationAuthorityComponent,
  CandidateEmployerRepresentativeComponent,
  CandidateEmployerFirstDeputyComponent,
  CandidateWorkStartDateComponent,
  CandidateEmployerWorkContractSignDateComponent,
  CandidateEmployerTypeOfWorkLocationComponent,
  CandidateEmployerWorkStartDateComponent,
  CandidateEmployerWorkLocationComponent,
  CandidateAlignedLabelComponent,
  CandidateAlignedLabelComponent,
  CandidateEstimatedWorkStartDateComponent,
  CandidateEstimatedArrivalDateComponent,
  CandidateRecognitionFileNumberComponent,
  CandidateEmployerOrganizationComponent,
  CandidateRecognitionPaymentRoleComponent,
  CandidateRecognitionPaymentRoleAcceleratedComponent,
  CandidateRecognitionFileNumberAcceleratedProcedureComponent,
  CandidateResponsibleOrganizationComponent,
  CandidateResponsibleRepresentativeComponent,
  CandidateResponsibleRepresentativeGroupComponent,
  CandidatePlannedEntryDateComponent,
  CandidateFingerPrintDateComponent,
  CandidatePickUpDetailsComponent,
  CandidateHealthProfessionsRegisterAppointmentDateComponent,
  CandidateHealthProfessionsRegistrationComponent,
  CandidateHealthProfessionsRegisterSubmissionComponent,
  CandidateLocationOfComplementaryMeasureComponent,
  CandidateRwrFileNumberComponent,
  CandidateNoQualificationEvaluationComponent,
  CandidateEmployerWorkConformationComponent,
  CandidateRecognitionCenterComponent,
  CandidateChamberOfCraftsComponent,
  CandidateResidenceBankAppointmentDateComponent,
  CandidateResidenceCompanyDoctorDateComponent,
  CandidateResidenceMedicalExaminationDateComponent,
  CandidateResidencePoliceRecordDateComponent,
  CandidateResidenceRegistrationOfficeDateComponent,
  CandidateQualificationExamsComponent,
  QualificationExamComponent,
];

@NgModule({
  imports: [SharedModule],
  declarations: components,
  exports: components,
})
export class CandidateReusablesModule {}
