import { AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from "@angular/core";
import {
  INDIVIDUAL_CONTRACT_ID,
  areRepresentativesAllowedForRecognitionRole,
  getAllRecognitionRoles,
} from "@ankaadia/ankaadia-shared";
import {
  CascadedOrganizationFragment,
  ContractTemplate,
  RecognitionPathEntryAt,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { Subject, Subscription, startWith } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { StaticDataContextEntry } from "../../../../../shared/static-data/static-data.service";
import { CandidateFederalStateComponent } from "../../../../candidate-reusables/candidate-federal-state/candidate-federal-state.component";
import { CandidateLegalBasisComponent } from "../../../../candidate-reusables/candidate-legal-basis/candidate-legal-basis.component";
import { CandidatePathOfRecognitionComponent } from "../../../../candidate-reusables/candidate-path-of-recognition/candidate-path-of-recognition.component";
import { ResponsibleRolePurpose } from "../../../../candidate-reusables/candidate-responsible-representative-group/candidate-responsible-representative-group.service";
import { CandidateResponsibleRoleComponent } from "../../../../candidate-reusables/candidate-responsible-role/candidate-responsible-role.component";
import { CandidateTargetRecognitionComponent } from "../../../../candidate-reusables/candidate-target-recognition/candidate-target-recognition.component";
import { OrganizationLinkService } from "../../../../organizations/organization-link.service";
import { RecognitionPathEntryFormAt } from "../../../candidate-form.model";

@Component({
  selector: "app-candidate-recognition-path-entry-at",
  templateUrl: "./candidate-recognition-path-entry-at.component.html",
  styleUrl: "./candidate-recognition-path-entry-at.component.scss",
  standalone: false,
})
export class CandidateRecognitionPathEntryATComponent implements OnChanges, AfterViewInit, OnDestroy {
  readonly ResponsibleRolePurposeType = ResponsibleRolePurpose;
  readonly IndividualContract = { id: INDIVIDUAL_CONTRACT_ID, name: translate("contractTemplate.individual") };
  readonly today = new Date();
  readonly formId = uuidv4();

  protected readonly recognitionRoles = getAllRecognitionRoles();

  @Input()
  form: RecognitionPathEntryFormAt;

  @Input()
  candidateId: string;

  @Input()
  candidateOrganizationId: string;

  @Input()
  recognitionPath: RecognitionPathEntryAt;

  @Input()
  readonly: boolean;

  protected areOrganizationsLinked: boolean;

  get canHaveADeputyOrRepresentative(): boolean {
    return areRepresentativesAllowedForRecognitionRole(this.form.controls?.responsibleRoleRecognition?.value);
  }

  get hasResponsibleOrganization(): boolean {
    return !!this.form.controls.responsibleRecognitionOrganizationId?.value;
  }

  get isUniversity(): boolean {
    return this.pathOfRecognition?.control.value === "UNIVERSITY";
  }

  get isGovernment(): boolean {
    return this.pathOfRecognition?.control.value === "GOVERNMENT";
  }

  protected displayHint = false;
  protected employers: CascadedOrganizationFragment[];
  protected federalStateOfEmployer: Subject<string> = new Subject<string>();

  @ViewChild(CandidateFederalStateComponent)
  protected federalState: CandidateFederalStateComponent;

  @ViewChild(CandidateTargetRecognitionComponent)
  protected targetRecognition: CandidateTargetRecognitionComponent;

  @ViewChild(CandidatePathOfRecognitionComponent)
  protected pathOfRecognition: CandidatePathOfRecognitionComponent;

  @ViewChild(CandidateLegalBasisComponent)
  protected legalBasis: CandidateLegalBasisComponent;

  @ViewChild(CandidateResponsibleRoleComponent)
  protected responsibleRole: CandidateResponsibleRoleComponent;

  selectedContractTemplate: Partial<ContractTemplate>;
  staticDataContext: StaticDataContextEntry;
  protected readonly ResponsiblePurpose = ResponsibleRolePurpose;
  private subscriptions: Subscription[] = [];

  constructor(private readonly linkService: OrganizationLinkService) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
    this.subscriptions = [];
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.form.controls.recognitionStarted.valueChanges
        .pipe(startWith(this.form.controls.recognitionStarted.value))
        .subscribe((x) =>
          x ? this.form.controls.recognitionStartDate.enable() : this.form.controls.recognitionStartDate.disable()
        )
    );
    this.subscriptions.push(
      this.form.controls.recognitionReceived.valueChanges
        .pipe(startWith(this.form.controls.recognitionReceived.value))
        .subscribe((x) =>
          x ? this.form.controls.recognitionReceiveDate.enable() : this.form.controls.recognitionReceiveDate.disable()
        )
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateOrganizationId) {
      this.staticDataContext = {
        immigrationCountry: SupportedImmigrationCountry.At,
        organizationId: this.candidateOrganizationId,
      };
      this.linkService
        .isOrganizationLinked(this.candidateOrganizationId)
        .subscribe((x) => (this.areOrganizationsLinked = x));
    }
  }
}
