import { Component, input, model } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { CandidateDataSource } from "@ankaadia/ankaadia-shared";

@Component({
  selector: "app-candidate-license-owner-specific-data",
  standalone: false,
  templateUrl: "./candidate-license-owner-specific-data.component.html",
  styleUrl: "./candidate-license-owner-specific-data.component.scss",
})
export class CandidateLicenseOwnerSpecificDataComponent {
  isOriginalTabShared = input.required<boolean>();
  isLicenseOwnerSpecificDataEnabled = input.required<boolean>();
  dataSource = model<CandidateDataSource>("owner");

  candidateDataSourceOptions: candidateDataSourceoption[] = [
    { value: "owner", label: this.transloco.translate("candidate.dataSource.owner") },
    { value: "userOrg", label: this.transloco.translate("candidate.dataSource.userOrg") },
  ];

  constructor(private readonly transloco: TranslocoService) {}
}

interface candidateDataSourceoption {
  label: string;
  value: CandidateDataSource;
}
