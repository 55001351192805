<ng-container *appTranslate="let t">
  <form [formGroup]="form" (ngSubmit)="openSaveBehaviorDialog()">
    <p-card [header]="header">
      <app-collection-auto-template-edit [form]="form" [filter]="filter"></app-collection-auto-template-edit>

      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
          <p-checkbox
            *ngIf="isEdit"
            formControlName="autoSync"
            [binary]="true"
            label="{{ t('autoCollectionTemplate.syncAll') }}"
            class="mr-4"
          >
          </p-checkbox>
          <p-button
            [disabled]="!form.valid || !form.dirty || disableSave"
            [label]="t('common.save')"
            icon="pi pi-check"
            type="submit"
            class="mr-2"
          ></p-button>
          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            type="reset"
            (onClick)="cancel()"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  </form>

  <p-dialog
    [header]="t('autoCollectionTemplate.saveBehavior.title')"
    [position]="'center'"
    [(visible)]="showSaveBehaviorSelector"
    [maximizable]="false"
  >
    <p-message
      *ngIf="variablesHaveChanged"
      severity="warn"
      class="hidden lg:block text-center"
      styleClass="mb-2 text-left"
      [text]="t('autoCollectionTemplate.saveBehavior.messageVariableChanged')"
    ></p-message>

    <p>{{ t("autoCollectionTemplate.saveBehavior.message") }}</p>
    <div class="flex flex-column gap-4">
      <div class="flex align-items-center">
        <p-radioButton
          [(ngModel)]="saveBehavior"
          value="standard"
          name="saveBehavior"
          [inputId]="saveBehavior"
          [disabled]="variablesHaveChanged"
        ></p-radioButton>
        <label class="ml-2">{{ t("autoCollectionTemplate.saveBehavior.standard") }}</label>
      </div>

      <div class="flex align-items-center">
        <p-radioButton
          [(ngModel)]="saveBehavior"
          value="detachAll"
          name="saveBehavior"
          [inputId]="saveBehavior"
        ></p-radioButton>
        <label class="ml-2">{{ t("autoCollectionTemplate.saveBehavior.detachAll") }}</label>
      </div>
      <div class="flex align-items-center">
        <p-radioButton
          [(ngModel)]="saveBehavior"
          value="saveAs"
          name="saveBehavior"
          [inputId]="saveBehavior"
        ></p-radioButton>
        <label class="ml-2">{{ t("autoCollectionTemplate.saveBehavior.saveAsNew") }}</label>
      </div>
      <div class="field w-10 ml-5">
        <label for="saveAsName">{{ t("autoCollectionTemplate.saveBehavior.saveAsName.title") }}</label>
        <input
          pInputText
          id="saveAsName"
          class="w-12"
          [(ngModel)]="saveAsName"
          [placeholder]="t('autoCollectionTemplate.saveBehavior.saveAsName.placeholder')"
          [required]="saveBehavior === 'saveAs'"
          [disabled]="saveBehavior !== 'saveAs'"
        />
        <small class="p-error" *ngIf="saveBehavior === 'saveAs' && !saveAsName">
          {{ t("autoCollectionTemplate.saveBehavior.saveAsName.required") }}
        </small>
        <small class="p-error" *ngIf="saveBehavior === 'saveAs' && saveAsName && !isTemplateNameValid(saveAsName)">
          {{ t("autoCollectionTemplate.saveBehavior.saveAsName.alreadyUsed") }}
        </small>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <p-button
        icon="pi pi-check"
        styleClass="p-button-small"
        (click)="save()"
        [label]="t('common.save')"
        [disabled]="!isBehaviorValid()"
      ></p-button>
      <p-button
        icon="pi pi-times"
        styleClass="p-button-small"
        (click)="showSaveBehaviorSelector = false"
        [label]="t('common.cancel')"
      ></p-button>
    </ng-template>
  </p-dialog>
</ng-container>
