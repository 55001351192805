<ng-container *appTranslate="let t">
  <app-education-exam-table
    [exams]="exams$ | async"
    [modules]="modules$ | async"
    [isReadOnly]="false"
    [title]="t('educationExams.title')"
    [shownNewOperations]="'all'"
    [shownTableOperations]="['edit', 'users', 'results', 'delete']"
    [shownColumns]="[
      'name',
      'examType',
      'examInstitution',
      'examModules',
      'examLocation',
      'plannedExamDateAndTime',
      'examDateAndTime',
      'plannedExamResultDate',
    ]"
    [virtualScroll]="true"
  >
    <ng-template #caption>
      <p-dropdown
        [formControl]="selectedStatus"
        [options]="examStatus"
        [placeholder]="t('examStatus.placeholder')"
        [showClear]="true"
        [dropdownIcon]="PrimeIcons.INFO_CIRCLE"
        [pTooltip]="t('examStatus.tooltip')"
        [tooltipPosition]="'top'"
        styleClass="mw-500 mr-2"
      />
      <p-multiSelect
        [showToggleAll]="true"
        [formControl]="selectedCourses"
        [options]="courses$ | async"
        [placeholder]="t('educationCourses.placeholder')"
        optionLabel="name"
        optionValue="id"
        styleClass="mw-500 mr-2"
      ></p-multiSelect>

      <p-multiSelect
        [showToggleAll]="true"
        [formControl]="selectedExamTypes"
        [options]="examTypes"
        [placeholder]="t('examType.placeholder')"
        styleClass="mw-500 mr-2"
      ></p-multiSelect>

      <p-multiSelect
        [showToggleAll]="true"
        [formControl]="selectedExamInstitution"
        [options]="examInstitutions$ | async"
        [placeholder]="t('examInstitution.placeholder')"
        styleClass="mw-500 mr-2"
      ></p-multiSelect>
    </ng-template>
  </app-education-exam-table>
</ng-container>
