import { Directive, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { SettingsService } from "../services/settings.service";

@Directive({ selector: "[licensed]", standalone: false })
export class LicensedDirective implements OnInit {
  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly settings: SettingsService
  ) {}

  ngOnInit(): void {
    if (this.settings.isLicensed) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
