import { Injectable } from "@angular/core";
import {
  CreateTalentPoolGQL,
  DeleteTalentPoolGQL,
  EditTalentPoolGQL,
  GetTalentPoolGQL,
  ListReadableTalentPoolsGQL,
  ListTalentPoolsGQL,
  ListWritableTalentPoolsGQL,
  TalentPoolCreateInput,
  TalentPoolDeleteInput,
  TalentPoolForEditFragment,
  TalentPoolForListFragment,
  TalentPoolForListFragmentDoc,
  TalentPoolForSelectionFragment,
  TalentPoolUpdateInput,
  UpdateTalentPoolGQL,
} from "@ankaadia/graphql";
import { omit, pick } from "lodash";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class TalentPoolService {
  constructor(
    private readonly poolList: ListTalentPoolsGQL,
    private readonly poolReadableList: ListReadableTalentPoolsGQL,
    private readonly poolWritableList: ListWritableTalentPoolsGQL,
    private readonly poolGet: GetTalentPoolGQL,
    private readonly poolEdit: EditTalentPoolGQL,
    private readonly poolCreate: CreateTalentPoolGQL,
    private readonly poolUpdate: UpdateTalentPoolGQL,
    private readonly poolDelete: DeleteTalentPoolGQL
  ) {}

  list(organizationId: string): Observable<TalentPoolForListFragment[]> {
    return this.poolList.watch({ input: { organizationId } }).valueChanges.pipe(map((x) => x.data.getTalentPools));
  }

  getReadable(organizationId: string): Observable<TalentPoolForSelectionFragment[]> {
    return this.poolReadableList.fetch({ input: { organizationId } }).pipe(map((x) => x.data.getReadableTalentPools));
  }

  getWritable(organizationId: string): Observable<TalentPoolForSelectionFragment[]> {
    return this.poolWritableList.fetch({ input: { organizationId } }).pipe(map((x) => x.data.getWritableTalentPools));
  }

  get(id: string, organizationId: string): Observable<TalentPoolForListFragment> {
    return this.poolGet.fetch({ input: { id, organizationId } }).pipe(map((x) => x.data.getTalentPool));
  }

  edit(id: string, organizationId: string): Observable<TalentPoolForEditFragment> {
    return this.poolEdit.fetch({ input: { id, organizationId } }).pipe(map((x) => x.data.getTalentPool));
  }

  create(pool: TalentPoolCreateInput): Observable<TalentPoolForListFragment> {
    return this.poolCreate
      .mutate(
        { input: omit(pool, "id", "_etag", "changedAt", "changedBy") },
        {
          update: (cache, x) =>
            cache.modify({
              fields: {
                getTalentPools: (refs) => {
                  const ref = cache.writeFragment({
                    data: x.data.createTalentPool,
                    fragment: TalentPoolForListFragmentDoc,
                  });
                  return [...(refs ?? []), ref];
                },
              },
            }),
        }
      )
      .pipe(map((x) => x.data.createTalentPool));
  }

  update(pool: TalentPoolUpdateInput): Observable<TalentPoolForListFragment> {
    return this.poolUpdate
      .mutate({ input: omit(pool, "changedAt", "changedBy") })
      .pipe(map((x) => x.data.updateTalentPool));
  }

  delete(pool: TalentPoolDeleteInput): Observable<void> {
    return this.poolDelete
      .mutate(
        { input: pick(pool, "id", "_etag", "organizationId") },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getTalentPools: (refs, helper) => {
                  return refs.filter((ref) => helper.readField("id", ref) !== pool.id);
                },
              },
            }),
        }
      )
      .pipe(map((x) => x.data.deleteTalentPool));
  }
}
