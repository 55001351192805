import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormArray, FormGroup, Validators } from "@angular/forms";
import {
  CandidateDataSource,
  CandidateTabConfiguration,
  ENABLE_TALENT_POOL,
  getAllComplementaryMeasureRoles,
  getAllEducationVoucherRoles,
  getAllHousingAcquisitionRoles,
  getAllLaborMarketRoles,
  getAllQualificationEvaluationPaymentRoles,
  getAllQualificationEvaluationRoles,
  getAllQualificationMeasureRoles,
  getAllRecognitionRoles,
  getAllResidenceRoles,
  getAllRwrRoles,
  getAllVisaRoles,
  Property,
  RegulatedProfessions,
  toRealImmigrationCountry,
  UserPermission,
} from "@ankaadia/ankaadia-shared";
import {
  Activity,
  Candidate,
  CandidateDeletionRequest,
  CandidateStatus,
  CandidateTagFragment,
  EffectiveSharingOptionsInput,
  EffectiveSharingOutput,
  FavoriteFragment,
  MessagesReception,
  PropertyTranslation,
  SharedTab,
  Sharing,
  SharingTypeEnum,
  StaticDataModel,
  StaticDataType,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { cloneDeep, entries, unset, values } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { TableLazyLoadEvent } from "primeng/table";
import { TabPanel, TabView, TabViewChangeEvent } from "primeng/tabview";
import { debounceTime, map, noop, Observable, of } from "rxjs";
import { OrganizationFactoryService } from "../../../organization-specific/organization-specific-factory.service";
import { FormElementMapService } from "../../../shared/from-element-map/form-element-map.service";
import { HasChanges } from "../../../shared/guards/confirm-deactivate.guard";
import {
  FormDataGuardConfiguration,
  IgnoredPropertyValues,
  MissingFormDataGuardService,
} from "../../../shared/services/form-data-initialization-guard";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { ActivityService } from "../../activity/activity.service";
import { CandidateFormService } from "../../candidate-form/candidate-form.service";
import { CandidateTagsComponent } from "../../candidate-form/candidate-tags/candidate-tags.component";
import { CandidateNotesComponent } from "../../candidate-notes/candidate-notes.component";
import { CandidateProfileService } from "../../candidate-profile/candidate-profile.service";
import { CollectionService } from "../../collections/collection.service";
import { EmailsComponent } from "../../emails/emails.component";
import { TranslationMapping } from "../../metered-translations/metered-translations.model";
import { MeteredTranslationsService } from "../../metered-translations/metered-translations.service";
import { UsersService } from "../../users/users.service";
import { FilePreview } from "../candidate-card-component.model";
import { CandidateCollectionsComponent } from "../candidate-collections/candidate-collections.component";
import { CandidateEducationComponent } from "../candidate-education/candidate-education.component";
import { CandidateFieldDiffService } from "../candidate-field-diff/candidate-field-diff.service";
import { CandidateTranslationDialogComponent } from "../candidate-translation-dialog/candidate-translation-dialog.component";
import { CandidatesService } from "../candidates.service";
import { CurrentCollectionService } from "../current-collection.service";
import { CandidateFormImmigrationCountryChangeService } from "./candidate-form-immigration-country-change.service";
import { CandidateFormProfessionChangeService } from "./candidate-form-profession-change.service";
import { CandidateFormStatusAndFunctionChangeService } from "./candidate-form-status-and-function-change.service";
import { CandidateInternalComponent } from "../../candidate-form/candidate-internal/candidate-internal.component";

export interface CandidateData {
  candidate: Candidate;
  favorite?: FavoriteFragment;
  messagesReception?: MessagesReception;
  deletionRequest?: CandidateDeletionRequest;
  customTags?: CandidateTagFragment[];
  enabledTabs: StaticDataModel[];
  enabledFields?: string[];
  effectiveSharing: EffectiveSharingOutput;
  selectedSharing: Sharing;
  propertyTranslations?: PropertyTranslation[];
}

@Component({
  selector: "app-candidate-form",
  templateUrl: "./candidate-form.component.html",
  styleUrl: "./candidate-form.component.scss",
  standalone: false,
})
export class CandidateFormComponent implements OnInit, HasChanges, OnChanges {
  protected readonly CandidateStatus = CandidateStatus;
  protected readonly StaticDataType = StaticDataType;
  protected readonly isLicensed = this.settings.isLicensed;
  protected readonly isAdmin = this.settings.hasAnyPermission([
    UserPermission.Administrator,
    UserPermission.CandidateManager,
  ]);

  private translationMappings: TranslationMapping[] = [];

  readonly form = this.candidateFormService.createCandidateGroup();

  @Input({ required: true })
  set candidateData(data: CandidateData) {
    this.setCandidate(data);
  }

  @Input({ required: true })
  isMultiEditMode = false;

  @Input({ required: true })
  selectedCollectionId: string;

  @Input({ required: true })
  selectedCollectionOrganizationId: string;

  @Output()
  readonly candidateChange = new EventEmitter<Candidate>();

  isGeneralTabVisible = false;

  @Output()
  readonly isGeneralTabVisibleChange = new EventEmitter<boolean>();

  isPreparingCandidate = false;

  @Output()
  readonly isPreparingCandidateChange = new EventEmitter<boolean>();

  @Output()
  readonly isValidChange = new EventEmitter<boolean>();

  tabHash: string;

  @Output()
  readonly tabHashChange = new EventEmitter<string>();

  isCreator = false;

  @Output()
  readonly isCreatorChange = new EventEmitter<boolean>();

  @Input()
  isEditing = false;

  @Input()
  isBusy = false;

  @Input({ required: true })
  sharings: readonly Sharing[];

  @Output()
  readonly multiInvite = new EventEmitter<void>();

  @Output()
  readonly multiUninvite = new EventEmitter<void>();

  @ViewChild(TabView)
  tabView: TabView;

  @ViewChild("immigrationCountryContainer")
  immigrationCountryContainer: ElementRef;

  @ViewChild(CandidateTagsComponent)
  tagsComponent: CandidateTagsComponent;

  @ViewChild(EmailsComponent)
  emailComponent: EmailsComponent;

  @ViewChild(CandidateCollectionsComponent)
  collectionComponent: CandidateCollectionsComponent;

  @ViewChild(CandidateNotesComponent)
  notesComponent: CandidateNotesComponent;

  @ViewChild(CandidateEducationComponent)
  educationComponent: CandidateEducationComponent;

  @ViewChildren(CandidateInternalComponent)
  internalComponents: CandidateInternalComponent[];

  candidate: Candidate;
  sharing: Sharing;
  tabConfiguration: SharedTab[];
  enabledTabs: StaticDataModel[];

  candidateInternalTabDataSource: CandidateDataSource = "owner";
  enableLicenseOwnerSpecificData = false;

  protected activitiesSource: (pageFilter: TableLazyLoadEvent) => Observable<Activity[]>;

  protected isEditable: boolean;
  protected deletionRequest: CandidateDeletionRequest;
  protected updatedStatus: CandidateStatus;
  protected favorite: FavoriteFragment;
  protected tabs: CandidateTabConfiguration;
  protected showMigrationTab: boolean;
  protected enabledFields?: string[];
  protected messagesReception: MessagesReception;
  protected customTags?: CandidateTagFragment[];
  protected filePreview: FilePreview;
  protected canSeeInternalDocuments = false;
  protected canSeeCompletionAtDocuments = false;
  protected hasEmailErrors = false;
  protected activities: Activity[] = null;
  protected totalActivities = 0;
  protected targetDisplayId: string;
  protected currentTabIndex = 0;
  protected isUneditableCollaboration = false;
  protected isReadOnlyCollaboration = false;
  readonly profession = this.professionChangeService.setup(this);
  readonly immigrationCountry = this.immigrationCountryChangeService.setup(this);
  readonly statusAndFunction = this.statusAndFunctionChangeService.setup(this);

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly candidateService: CandidatesService,
    private readonly candidateFormService: CandidateFormService,
    protected readonly currentCollectionService: CurrentCollectionService,
    private readonly settings: SettingsService,
    private readonly userService: UsersService,
    private readonly confirmationService: ConfirmationService,
    private readonly transloco: TranslocoService,
    private readonly missingFormDataGuardService: MissingFormDataGuardService,
    protected readonly fieldDiffService: CandidateFieldDiffService,
    private readonly profileService: CandidateProfileService,
    private readonly staticDataService: StaticDataService,
    private readonly collectionService: CollectionService,
    private readonly specificsFactory: OrganizationFactoryService,
    private readonly immigrationCountryChangeService: CandidateFormImmigrationCountryChangeService,
    private readonly professionChangeService: CandidateFormProfessionChangeService,
    private readonly statusAndFunctionChangeService: CandidateFormStatusAndFunctionChangeService,
    private readonly elementMapService: FormElementMapService,
    private readonly dialogService: DialogService,
    private readonly activityService: ActivityService,
    private readonly translationsService: MeteredTranslationsService
  ) {
    this.fieldDiffService.candidateFormComponent = this;
    this.activitiesSource = (pageFilter: TableLazyLoadEvent): Observable<Activity[]> =>
      this.candidate ? this.activityService.getCandidateActivities(this.candidate.id, pageFilter) : of([]);
  }

  synchronizeInternalTabs(index: number): void {
    this.internalComponents.forEach((x) => x.tabView.open(null, x.tabView.tabs[index]));
  }

  ngOnInit(): void {
    this.form.statusChanges.pipe(debounceTime(100)).subscribe(() => {
      this.isValidChange.emit(this.form.valid);
      this.updateTabView();
    });
    this.enableLicenseOwnerSpecificData = this.isCandidateDataSourceSelectionVisible();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isEditing) {
      this.toggleEdit(changes.isEditing.currentValue);
    }
  }

  hasChanges(): boolean {
    return this.form.dirty || this.notesComponent?.hasChanges() || this.educationComponent?.hasChanges();
  }

  saveEducationData(): void {
    if (this.educationComponent?.hasChanges()) {
      this.educationComponent?.saveEducationData();
    }
  }

  saveNotes(): void {
    const notesComponentState = this.notesComponent?.editState;
    if (this.notesComponent?.hasChanges() && notesComponentState.state != "read") {
      this.notesComponent.saveNote(notesComponentState.editedNote);
    }
  }

  protected updateEmailErrors(hasEmailErrors: boolean): void {
    this.hasEmailErrors = hasEmailErrors;
    this.updateTabView();
  }

  protected openFilePreview(filePreview: FilePreview): void {
    this.filePreview = filePreview;
  }

  protected closeFilePreview(): void {
    this.filePreview = null;
  }

  protected reloadEmails(): void {
    this.emailComponent?.reload();
  }

  protected toggleMessagesReception(): void {
    this.userService
      .toggleMessagesReception(true, this.candidate.id, this.candidate.organizationId)
      .subscribe((flag) => (this.messagesReception = { ...this.messagesReception, enabled: flag }));
  }

  protected removeDeletionRequest(event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("candidate.deletionRequestWarning"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.candidateService.removeDeletionRequest(this.deletionRequest).subscribe(() => {
          this.deletionRequest = null;
        });
      },
    });
  }

  private updateTabView(): void {
    this.changeDetector.detectChanges();
    this.tabView?.cd.detectChanges();
  }

  private getTabs(enabledTabs: StaticDataModel[], sharedTabs: string[]): CandidateTabConfiguration {
    const config = enabledTabs.reduce<CandidateTabConfiguration>(
      (acc, cur) => ((acc[cur.value] = sharedTabs?.includes(cur.value)), acc),
      {}
    );
    if (!this.isMultiEditMode) {
      config.collections = true;
    }
    return config;
  }

  getFormValue(): Candidate {
    const candidate = <Candidate>this.form.getRawValue();

    unset(candidate, "migration.de.recognitionPath.current.contractTemplate.__typename");
    unset(candidate, "migration.at.recognitionPath.current.contractTemplate.__typename");

    unset(candidate, "migration.de.recognitionPath.alternative.contractTemplate.__typename");
    unset(candidate, "migration.at.recognitionPath.alternative.contractTemplate.__typename");

    unset(candidate, "migration.de.employmentRelationship.beforeRecognition.contractTemplate.__typename");
    unset(candidate, "migration.at.employmentRelationship.beforeRecognition.contractTemplate.__typename");

    unset(candidate, "migration.de.employmentRelationship.afterRecognition.contractTemplate.__typename");
    unset(candidate, "migration.at.employmentRelationship.afterRecognition.contractTemplate.__typename");

    unset(candidate, "presentation.recommendationNote.__typename");
    if (candidate.os.profile.email === "") {
      //See: //github.com/typestack/class-validator/issues/1136
      candidate.os.profile.email = null;
    }

    const immigrationCountry = toRealImmigrationCountry(candidate.immigrationCountry);
    if (immigrationCountry !== SupportedImmigrationCountry.De) {
      unset(candidate, "migration.de");
    }
    if (immigrationCountry !== SupportedImmigrationCountry.At) {
      unset(candidate, "migration.at");
    }
    return candidate;
  }

  private resetForm(candidate: Candidate): void {
    this.candidateFormService.setFormValue(this.form, candidate);

    // trigger formly model update
    this.candidate = cloneDeep(candidate);
    this.changeDetector.detectChanges();
  }

  validateCandidateAndForm(): boolean {
    type CandidateProperty = Property<Candidate>;

    //get ignored profile fields...
    const ignoredArrayFields = this.profileService
      .getIgnoredProfileArrayFields(this.candidate.os.profile, this.candidate.profession)
      .map((x) => ({ ...x, pathToArray: `os.profile.${x.pathToArray}` }));

    const ignoredProfileFields = this.profileService
      .getIgnoredPaths(this.candidate.os.profile, this.candidate.immigrationCountry, this.candidate.profession)
      .map((x) => `os.profile.${x}`);

    const ignoredPropertyValues = this.profileService
      .getIgnoredPropertyValues()
      .map((x) => ({ ...x, pathToProperty: `os.profile.${x.pathToProperty}` }));

    const guardConfig: FormDataGuardConfiguration = {
      ignoredPaths: [
        ...getIgnoredFields(this.candidate),
        ...getIgnoredFieldsThatDoNotExistAnyMore(),
        ...ignoredProfileFields,
      ], // e.g. not mutable/computed fields
      ignoredArrayPaths: ignoredArrayFields,
      ignoredProperties: ["__typename", "id"], //database properties
      additionalInformation: {
        candidateId: this.candidate?.id,
        candidateOrganizationId: this.candidate?.organizationId,
        sharingId: this.sharing?.id,
      },
      ignoredPropertyValues: [...ignoredPropertyValues, ...getIgnoredPropertyFields()],
    };
    if (
      this.sharing?.sharingType === SharingTypeEnum.ReadOnlyCollaboration ||
      this.sharing?.sharingType === SharingTypeEnum.View
    )
      this.isReadOnlyCollaboration = true;

    this.missingFormDataGuardService.guardAgainstMissingFormData(this.candidate, this.form, guardConfig);

    type IgnoredCandidatePropertyValues = Omit<IgnoredPropertyValues, "pathToProperty"> & {
      pathToProperty: CandidateProperty;
    };

    function getIgnoredPropertyFields(): IgnoredCandidatePropertyValues[] {
      return [
        {
          type: "ignoreNotInValues",
          values: getAllRecognitionRoles(),
          pathToProperty: "migration.de.recognitionPath.current.responsibleRoleRecognition",
        },
        {
          type: "ignoreNotInValues",
          values: getAllRecognitionRoles(),
          pathToProperty: "migration.de.recognitionPath.alternative.responsibleRoleRecognition",
        },
        {
          type: "ignoreNotInValues",
          values: getAllRecognitionRoles(),
          pathToProperty: "migration.at.recognitionPath.responsibleRoleRecognition",
        },
        {
          type: "ignoreNotInValues",
          values: getAllLaborMarketRoles(),
          pathToProperty: "migration.de.laborMarketAdmission.responsibleRoleLaborMarket",
        },

        {
          type: "ignoreNotInValues",
          values: getAllQualificationMeasureRoles(),
          pathToProperty: "migration.de.qualificationMeasure.responsibleRoleQualification",
        },
        {
          type: "ignoreNotInValues",
          values: getAllEducationVoucherRoles(),
          pathToProperty: "migration.de.qualificationMeasure.responsibleRoleEducationVoucher",
        },
        {
          type: "ignoreNotInValues",
          values: getAllVisaRoles(),
          pathToProperty: "migration.de.visa.responsibleRoleVisa",
        },
        {
          type: "ignoreNotInValues",
          values: getAllRwrRoles(),
          pathToProperty: "migration.at.visa.responsibleRoleVisa",
        },
        {
          type: "ignoreNotInValues",
          values: getAllResidenceRoles(),
          pathToProperty: "migration.de.residence.responsibleRoleResidence",
        },
        {
          type: "ignoreNotInValues",
          values: getAllQualificationEvaluationRoles(),
          pathToProperty: "migration.de.qualificationEvaluation.responsibleRoleQualificationEvaluation",
        },
        {
          type: "ignoreNotInValues",
          values: getAllQualificationEvaluationPaymentRoles(),
          pathToProperty: "migration.de.qualificationEvaluation.responsibleRoleQualificationEvaluationPayment",
        },
        {
          type: "ignoreNotInValues",
          values: getAllHousingAcquisitionRoles(),
          pathToProperty: "migration.housingAcquisition.responsibleRoleHousingAcquisition",
        },
        {
          type: "ignoreNotInValues",
          values: getAllComplementaryMeasureRoles(),
          pathToProperty: "migration.at.qualificationMeasure.responsibleRoleQualification",
        },
      ];
    }

    function getIgnoredFields(candidate: Candidate): CandidateProperty[] {
      const recognitionAuthority: CandidateProperty[] = RegulatedProfessions.includes(candidate?.profession)
        ? []
        : [
            "migration.at.recognitionPath.recognitionAuthority",
            "migration.de.recognitionPath.current.recognitionAuthority",
            "migration.de.recognitionPath.alternative.recognitionAuthority",
          ];

      return [
        "email",
        "technicalSource",
        "deletionDate",
        "lastLoginDate",
        "creationDate",
        "displayName",
        "systemOnboarding",
        "country",
        "governance.communicationLanguage", //field depends on configuration of the organization (email-templates)
        "transferData",
        ...recognitionAuthority,
      ];
    }

    function getIgnoredFieldsThatDoNotExistAnyMore(): string[] {
      return ["migration.at.employmentRelationship.changedAt", "migration.de.employmentRelationship.changedAt"];
    }

    return this.form.valid;
  }

  private setIsCreator(candidate: Candidate): void {
    this.isCreator = this.settings.organizationId === candidate.organizationId && !this.settings.isCandidate;
    this.isCreatorChange.emit(this.isCreator);
  }

  private setCandidate(data: CandidateData): void {
    if (!data) return;

    this.isPreparingCandidate = true;
    this.isPreparingCandidateChange.emit(this.isPreparingCandidate);
    this.customTags = data.customTags;

    // cloneDeep to make sure the candidate is rendered again #10262
    this.candidate = cloneDeep(data.candidate);

    this.changeDetector.detectChanges();
    this.favorite = data.favorite;
    this.messagesReception = data.messagesReception;
    this.deletionRequest = data.deletionRequest;
    this.enabledTabs = data.enabledTabs;
    this.tabConfiguration = data.effectiveSharing?.sharedTabs;
    this.tabHash = data.effectiveSharing?.tabHash;
    this.tabHashChange.emit(this.tabHash);
    this.enabledFields = data.enabledFields;
    this.canSeeInternalDocuments = data.effectiveSharing.canSeeInternalDocuments;
    this.canSeeCompletionAtDocuments = data.effectiveSharing.showCompletionStateAtDocuments;
    this.targetDisplayId = data.candidate?.transferData?.targetDisplayId;
    this.activities = [];
    this.totalActivities = 0;
    this.setIsCreator(data.candidate);
    this.fieldDiffService.disable();
    this.changeDetector.detectChanges();
    this.isEditable = data?.effectiveSharing?.sharingType === SharingTypeEnum.Collaboration;

    this.sharing = data.selectedSharing;

    this.isUneditableCollaboration =
      this.settings.organizationId !== data.selectedSharing?.organizationId &&
      data.selectedSharing?.sharingType === SharingTypeEnum.Collaboration &&
      !data.selectedSharing?.isCandidateEditable;

    this.isReadOnlyCollaboration =
      this.settings.organizationId !== data.selectedSharing?.organizationId &&
      (data.selectedSharing?.sharingType === SharingTypeEnum.ReadOnlyCollaboration ||
        data.selectedSharing?.sharingType === SharingTypeEnum.View);

    // delayed so that formly has a chance to initialize the fields
    setTimeout(() => {
      this.handleEnabledFields(data.enabledFields);
      const sharedTabs = data.effectiveSharing?.sharedTabs.map((x) => x.key);
      this.setCandidateTabConfiguration(data.enabledTabs, sharedTabs, data.candidate.immigrationCountry);

      this.isGeneralTabVisible = this.tabs.general;
      this.isGeneralTabVisibleChange.emit(this.isGeneralTabVisible);

      this.changeDetector.detectChanges();
      this.resetForm(data.candidate);

      this.clearValidatorsOfUnusedControls(this.form.controls.os.controls.profile);
      this.isPreparingCandidate = false;
      this.isPreparingCandidateChange.emit(this.isPreparingCandidate);
    }, 200);
    //mapping the translations needs the whole form control to be finished which takes more than 200ms
    if (data.candidate.id && data.candidate.organizationId) {
      setTimeout(() => {
        this.translationsService
          .loadCandidateFieldTranslations({
            entityId: data.candidate.id,
            organizationId: data.candidate.organizationId,
          })
          .subscribe((translations) => {
            this.mapTranslations(translations);
            this.changeDetector.detectChanges();
          });
      }, 300);
    }
    if (this.isMultiEditMode) {
      this.markAllControlsAsOptional(this.form);
    }
  }

  setCandidateTabConfiguration(
    enabledTabs: StaticDataModel[],
    sharedTabs: string[],
    immigrationCountry: SupportedImmigrationCountry
  ): void {
    this.tabs = this.getTabs(enabledTabs, sharedTabs);
    this.setShowMigrationTab(immigrationCountry);
    this.setTabDataSources();
  }

  private setTabDataSources(): void {
    if (!this.enableLicenseOwnerSpecificData) return;
    if (!this.tabs.internal) this.candidateInternalTabDataSource = "userOrg";
  }

  // set if the migration tab is shown or not
  // the migration tab is shown if a tab is enabled that requires the migration tab (depending on the candidate's immigration country)
  setShowMigrationTab(immigrationCountry: SupportedImmigrationCountry): void {
    const migrationTabMapping = this.getMigrationTabMapping();

    this.showMigrationTab = Object.entries(migrationTabMapping)
      .filter(([_name, countries]) => (countries ?? []).some((x) => x == immigrationCountry))
      .some(([name]) => this.tabs[name] === true);
  }

  /**
   * This method makes it possible to edit only some fields in the multi edit mode.
   * It basically remembers the fields that have already been disabled, disables the whole form,
   * and then enables the allowed fields one by one. Originally disabled fields are not getting enabled.
   * This approach ensures that the logic that changes state of the form controls is working properly.
   * Configuration is explained in the notes to {@link CandidateMultiEditService.getConfiguration} on the backend.
   * Any misconfiguration is frowned upon but still tolerated by simply ignoring it,
   * so if you try to enable a field that is not supposed to be there, you're to blame.
   */
  private handleEnabledFields(enabledFields: string[]): void {
    if (enabledFields) {
      const disabledFields = this.getDisabledControlFields(this.form);
      this.form.disable();
      for (const field of enabledFields) {
        if (!disabledFields.includes(field)) {
          this.form.get(field)?.enable();
        }
      }
    }
  }

  /**
   * Returns a flat list of field paths of controls that are disabled, e.g.:
   * [ "os.profile.firstname", "migration.alternative.federalState" ]
   */
  private getDisabledControlFields(form: FormGroup | FormArray): string[] {
    const disabledFields = [];
    entries(form.controls).forEach(([key, control]) => {
      if (control instanceof FormGroup || control instanceof FormArray) {
        const disabledSubFields = this.getDisabledControlFields(control);
        disabledFields.push(...disabledSubFields.map((x) => [key, x].join(".")));
      } else {
        if (control.disabled) {
          disabledFields.push(key);
        }
      }
    });
    return disabledFields;
  }

  /** Necessary in the multi edit mode because only a part of the candidate model is being edited. */
  private markAllControlsAsOptional(form: FormGroup | FormArray): void {
    values(form.controls).forEach((control) => {
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.markAllControlsAsOptional(control);
      } else {
        if (control.hasValidator(Validators.required)) {
          control.removeValidators(Validators.required);
          control.updateValueAndValidity();
        }
      }
    });
  }

  /**
   * When changing the formly fields, mandatory fields are not correctly reset, if they dont exist in the new field config
   * ...resulting in not visible but required fields --> clear those validators
   */
  clearValidatorsOfUnusedControls(form: FormGroup | FormArray): void {
    const configuredProfileFields = this.tabConfiguration
      .flatMap((x) => x.sharedFields)
      .filter((x) => x)
      .map((x) => x.name);

    [...entries(form.controls), ...values(form.controls).flatMap((x: FormArray) => entries(x.controls))]
      .filter(([key, _control]) => _control && !configuredProfileFields.includes(key))
      .forEach(([_key, control]) => {
        control.clearValidators();
        control.updateValueAndValidity();
      });
  }

  protected tabChanges(event: TabViewChangeEvent): void {
    if (!this.tabView?.tabs) return;
    const currentTab = this.tabView.tabs[this.currentTabIndex];
    const newTab = this.tabView.tabs[event.index];

    if (currentTab.header === this.transloco.translate("candidate.notes") && this.notesComponent) {
      this.handleLeavingNotesTab(event, currentTab, newTab);
    } else if (currentTab.header === this.transloco.translate("education.title") && this.educationComponent) {
      this.handleLeavingEducationTab(event, currentTab, newTab);
    } else this.currentTabIndex = event.index;
  }

  private handleLeavingEducationTab(event: TabViewChangeEvent, currentTab: TabPanel, newTab: TabPanel): void {
    if (!this.educationComponent.hasChanges()) return;
    this.navigateToTab(event, currentTab, this.currentTabIndex); //stay at the current tab --> move away after confirmation

    this.confirmationService.confirm({
      target: event.originalEvent.target,
      message: translate("education.confirmCancel"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => this.navigateToTab(event, newTab, event.index), //move to the new tab
      reject: () => noop(),
    });
  }

  getEffectiveSharing(
    candidateId: string,
    candidateOrganizationId: string,
    options?: EffectiveSharingOptionsInput
  ): Observable<EffectiveSharingOutput> {
    if (candidateOrganizationId === this.settings.organizationId) {
      return this.staticDataService.getStaticData(StaticDataType.AllowedSharedProfileTabs).pipe(
        map((xs) => ({
          sharedTabs: xs.map((x) => ({ key: x.value })),
          canSeeInternalDocuments: true,
          showCompletionStateAtDocuments: true,
          tabHash: null,
        }))
      );
    }

    if (candidateId) {
      return this.collectionService.getEffectiveSharing(candidateId, candidateOrganizationId, options);
    }

    if (this.currentCollectionService.hasCollection) {
      const sharing = this.sharings?.find(({ sharedCollectionId }) => {
        return sharedCollectionId === this.currentCollectionService.collectionId;
      });

      if (sharing.profileAccessId) {
        return this.specificsFactory.getOrganizationSpecifics(candidateOrganizationId).pipe(
          map((specifics) => ({
            sharedTabs: sharing?.sharedTabs
              .concat(specifics.getCandidateProfileTabs().map((x) => x.id))
              .map((x) => ({ key: x })),
            canSeeInternalDocuments: true,
            showCompletionStateAtDocuments: true,
            tabHash: null,
          }))
        );
      }

      return of({
        sharedTabs: sharing?.sharedTabs.map((x) => ({ key: x })),
        canSeeInternalDocuments: sharing?.shareInternalDocuments ?? false,
        showCompletionStateAtDocuments: sharing?.showCompletionStateAtDocuments ?? false,
        tabHash: null,
      });
    }

    return of(null);
  }

  private handleLeavingNotesTab(event: TabViewChangeEvent, currentTab: TabPanel, newTab: TabPanel): void {
    const editState = this.notesComponent.editState;

    if (editState.state === "read") return;

    this.notesComponent?.cancelNote(
      event.originalEvent,
      editState.editedNote,
      () => this.navigateToTab(event, currentTab, this.currentTabIndex), //stay at the current tab --> move away after confirmation
      () => this.navigateToTab(event, newTab, event.index) //move to the new tab
    );
  }

  private navigateToTab(event: TabViewChangeEvent, newTab: TabPanel, index: number): void {
    this.tabView.open(event.originalEvent, newTab);
    this.currentTabIndex = index;
  }

  //Mapping to describe which tabs are contained in the respective migration tab
  private getMigrationTabMapping(): Record<keyof CandidateTabConfiguration, SupportedImmigrationCountry[] | null> {
    return {
      employers: [SupportedImmigrationCountry.De, SupportedImmigrationCountry.At],
      recognitionPath: [SupportedImmigrationCountry.De, SupportedImmigrationCountry.At],
      residence: [SupportedImmigrationCountry.De, SupportedImmigrationCountry.At],
      vocationalSchool: [SupportedImmigrationCountry.De, SupportedImmigrationCountry.At],
      housingAcquisition: [SupportedImmigrationCountry.De, SupportedImmigrationCountry.At],
      laborMarketAdmission: [SupportedImmigrationCountry.De],
      qualificationMeasure: [SupportedImmigrationCountry.De],
      visa: [SupportedImmigrationCountry.De],
      qualificationEvaluation: [SupportedImmigrationCountry.De],
      rwrCard: [SupportedImmigrationCountry.At],
      complementaryMeasure: [SupportedImmigrationCountry.At],
      healthProfessionsRegister: [SupportedImmigrationCountry.At],
      requiredDocument: null,
      qualifications: null,
      processStatus: null,
      presentation: null,
      preferences: null,
      other: null,
      notes: null,
      languageSkills: null,
      interviews: null,
      internal: null,
      insuranceAndTax: null,
      governance: null,
      general: null,
      furtherEducations: null,
      family: null,
      experiences: null,
      events: null,
      emails: null,
      customFields: null,
      collections: null,
      cardView: null,
      bankDetails: null,
      attachments: null,
      templates: null,
      vaccinations: null,
      education: null,
      competencies: null,
    };
  }

  private mapTranslations(fields: PropertyTranslation[]): void {
    if (this.isEditing) {
      return null; //during editing translations do not need to be mapped again
    }
    this.translationMappings.forEach((x) => {
      x.translationIcon.remove();
    });
    this.translationMappings = fields
      .map((field) => {
        const formControl = this.getFormControl(this.form.controls, cloneDeep(field.propertyPath));

        let element = this.elementMapService.getElementByFormControl(formControl);
        if (!element) {
          if (field.propertyPath.join(".") === "presentation.recommendationNote.contents") {
            element = document.querySelector("#recommendationNote");
          }
        }
        if (element) {
          const label: HTMLElement = document.querySelector(`label[for="${element.id}"]`);
          if (label) {
            const icon = document.createElement("span");
            icon.className = "pi pi-language ml-1";
            icon.title =
              this.translationsService.getFieldTranslationById(field.id)?.targetText ??
              translate("translation.noTranslation");
            icon.style.color = "lightgrey";
            const translationItem: TranslationMapping = {
              id: field.id,
              formControl: formControl,
              translationIcon: icon,
            };
            label.parentNode.insertBefore(icon, label.nextSibling);
            return translationItem;
          }
        }
        return null;
      })
      .filter((x) => x != null);
  }

  getFormControl(formControl: any, pathOperation: string[], iteration = 0): any {
    try {
      if (pathOperation.length == 1) return formControl[pathOperation[0]]; //destination reached

      if (Array.isArray(formControl)) {
        const identifier = pathOperation[0];
        const rightOne = formControl.find((x) => x.value.id === identifier).controls;
        pathOperation.shift();
        return this.getFormControl(rightOne, pathOperation, ++iteration);
      }
      const childControl = formControl[pathOperation[0]];
      const childControls = childControl.controls;
      if (!childControls && childControl) {
        pathOperation.shift();
        return childControl; //destination reached early
      }
      pathOperation.shift();
      return this.getFormControl(childControls, pathOperation, ++iteration);
    } catch (_e) {
      // console.log("error", e);
      return null;
    }
  }

  openDialog(sourceControl: any, itemId: string, element: any): void {
    if (sourceControl.value != null) {
      this.dialogService
        .open(CandidateTranslationDialogComponent, {
          closeOnEscape: false,
          dismissableMask: false,
          styleClass: "p-dialog-compact",
          modal: true,
          width: "50%",
          appendTo: this.getElementToAppend(element),
          data: { sourceText: sourceControl.value, itemId: itemId },
        })
        .onClose.subscribe((result) => {
          const resultItem = result?.translationItem;
          if (resultItem) {
            element.title = resultItem?.targetText;
          }
        });
    }
  }

  toggleEdit(isEditing: boolean): void {
    if (isEditing) {
      this.translationMappings.forEach((x) => {
        x.translationIcon.style.color = "black";
        x.translationIcon.addEventListener("click", () => this.openDialog(x.formControl, x.id, x.translationIcon));
      });
    } else {
      this.translationMappings.forEach((x) => {
        x.translationIcon.style.color = "lightgrey";
        x.translationIcon.removeEventListener("click", () => this.openDialog(x.formControl, x.id, x.translationIcon));
      });
    }
  }

  private getElementToAppend(element: Element): Element {
    let lastElement: Element = element;
    while (element && !element.className.includes("layout-wrapper")) {
      lastElement = element;
      element = element.parentElement;
    }
    return element ?? lastElement;
  }

  readonly ENABLE_TALENT_POOL = ENABLE_TALENT_POOL;

  private isCandidateDataSourceSelectionVisible(): boolean {
    return !this.isOwner() && this.isLicensed;
  }

  private isOwner(): boolean {
    return this.candidate.organizationId == this.settings.organizationId;
  }
}
