import { EnterMissingInformationAdditionalData } from "@ankaadia/ankaadia-shared";

import {
  AfterRecognition,
  BeforeRecognition,
  EnterMissingInformation,
  HealthProfessionsRegisterCandidateDataAt,
  LaborMarketAdmissionCandidateDataDe,
  ProcessStatusCandidateData,
  QualificationEvaluationDataDe,
  QualificationMeasureCandidateDataAt,
  QualificationMeasureCandidateDataDe,
  RecognitionPathEntryAt,
  RecognitionPathEntryDe,
  ResidenceCandidateDataAt,
  ResidenceCandidateDataDe,
  VisaCandidateDataAt,
  VisaCandidateDataDe,
} from "@ankaadia/graphql";

type MissingInformationFieldName =
  | keyof RecognitionPathEntryDe
  | keyof RecognitionPathEntryAt
  | keyof QualificationMeasureCandidateDataDe
  | keyof QualificationMeasureCandidateDataAt
  | keyof LaborMarketAdmissionCandidateDataDe
  | keyof VisaCandidateDataDe
  | keyof VisaCandidateDataAt
  | keyof ResidenceCandidateDataAt
  | keyof ResidenceCandidateDataDe
  | keyof BeforeRecognition
  | keyof AfterRecognition
  | keyof ProcessStatusCandidateData
  | keyof HealthProfessionsRegisterCandidateDataAt
  | keyof QualificationEvaluationDataDe;

export const fieldMapping: Record<EnterMissingInformation, MissingInformationFieldName> = {
  ResponsibleRoleRecognition: "responsibleRoleRecognition",
  ResponsibleRecognitionOrganization: "responsibleRecognitionOrganizationId",
  EquivalenceTest: "equivalenceTest",
  FederalState: "federalState",
  FederalStateAt: "federalState",
  PathOfRecognition: "pathOfRecognition",
  PathOfRecognitionAt: "pathOfRecognition",
  RecognitionAuthority: "recognitionAuthority",
  RecognitionAuthorityAt: "recognitionAuthority",
  RecognitionType: "recognitionType",
  ResponsibleRoleQualification: "responsibleRoleQualification",
  QualificationMeasure: "qualificationMeasure",
  QualificationType: "qualificationType",
  StartOfFurtherEducation: "startOfFurtherEducation",
  EndOfFurtherEducation: "endOfFurtherEducation",
  FirstFinalInterviewDate: "qualificationExams",
  FirstExamDate: "qualificationExams",
  ResponsibleRoleLaborMarket: "responsibleRoleLaborMarket",
  ResponsibleRoleVisa: "responsibleRoleVisa",
  ResponsibleRoleResidence: "responsibleRoleResidence",
  FlightDate: "flightDate",
  ArrivalDate: "arrivalDate",
  ResidencePermit: "residencePermit",
  ImmigrationAuthority: "immigrationAuthority",
  RecognitionPaymentRole: "recognitionPaymentRole",
  RecognitionPaymentRoleAccelerated: "recognitionPaymentRoleAccelerated",
  FirstExamPassed: "qualificationExams",
  FirstFinalInterviewPassed: "qualificationExams",
  PsaRepresentative: "psaRepresentativeId",
  PsaFirstDeputy: "psaFirstDeputyId",
  WorkEmployer: "employerId",
  WorkEmployerAt: "employerId",
  WorkStartDate: "workStartDate",
  EndOfProbationDate: "endOfProbationDate",
  WorkConfirmationSignedDate: "confirmationSignedDate",
  WorkContractSignDate: "contractSignDate",
  WorkContractStartDate: "startDate",
  WorkContractTypeOfWorkLocation: "typeOfWorkLocation",
  WorkContractWorkLocation: "workLocation",
  EstimatedArrivalDate: "estimatedArrivalDate",
  EstimatedWorkStartDate: "estimatedWorkStartDate",
  RecognitionFileNumber: "fileNumber",
  RecognitionFileNumberAcceleratedProcedure: "fileNumberAcceleratedProcedure",
  TargetRecognition: "targetRecognition",
  LegalBasis: "legalBasis",
  StartOfComplementaryMeasure: "startOfFurtherEducation",
  EndOfComplementaryMeasure: "endOfFurtherEducation",
  LocationOfComplementaryMeasure: "locationOfComplementaryMeasure",
  PlannedEntryDate: "plannedEntryDate",
  FingerPrintDate: "fingerPrintDate",
  PickUpDetails: "pickUpDetails",
  HealthProfessionRegisterAppointmentDate: "appointmentDate",
  HealthProfessionRegistrationDate: "registrationDate",
  HealthProfessionRegisterSubmission: "submission",
  RwrCardFileNumber: "fileNumber",
  NoQualificationEvaluation: "noQualificationEvaluation",
  ResponsibleRoleQualificationEvaluation: "responsibleRoleQualificationEvaluation",
  ResponsibleRoleQualificationEvaluationPayment: "responsibleRoleQualificationEvaluationPayment",
  MedicalExaminationAppointmentDate: "medicalExaminationAppointmentDate",
  PoliceRecordAppointmentDate: "policeRecordAppointmentDate",
  RegistrationOfficeDate: "registrationOfficeDate",
  CompanyDoctorAppointmentDate: "companyDoctorAppointmentDate",
  BankAppointmentDate: "bankAppointmentDate",
} as const;

export const fieldToAdditionalFieldMapping: Record<EnterMissingInformation, EnterMissingInformationAdditionalData[]> = {
  ResponsibleRoleRecognition: [],
  ResponsibleRecognitionOrganization: [],
  EquivalenceTest: [],
  FederalState: [],
  FederalStateAt: [],
  PathOfRecognition: [],
  PathOfRecognitionAt: [],
  RecognitionAuthority: [],
  RecognitionAuthorityAt: [EnterMissingInformationAdditionalData.FederalState],
  RecognitionType: [],
  ResponsibleRoleQualification: [],
  QualificationMeasure: [],
  QualificationType: [],
  StartOfFurtherEducation: [],
  EndOfFurtherEducation: [],
  FirstFinalInterviewDate: [],
  FirstExamDate: [],
  ResponsibleRoleLaborMarket: [],
  ResponsibleRoleVisa: [],
  ResponsibleRoleResidence: [],
  FlightDate: [],
  ArrivalDate: [],
  ResidencePermit: [],
  ImmigrationAuthority: [
    EnterMissingInformationAdditionalData.FederalState,
    EnterMissingInformationAdditionalData.RecognitionType,
  ],
  RecognitionPaymentRole: [EnterMissingInformationAdditionalData.RecognitionType],
  RecognitionPaymentRoleAccelerated: [EnterMissingInformationAdditionalData.RecognitionType],
  FirstExamPassed: [],
  FirstFinalInterviewPassed: [],
  PsaRepresentative: [
    EnterMissingInformationAdditionalData.PsaRepresentativeFirstName,
    EnterMissingInformationAdditionalData.PsaRepresentativeLastName,
  ],
  PsaFirstDeputy: [
    EnterMissingInformationAdditionalData.PsaFirstDeputyFirstName,
    EnterMissingInformationAdditionalData.PsaFirstDeputyLastName,
  ],
  WorkEmployer: [],
  WorkEmployerAt: [],
  WorkStartDate: [],
  EndOfProbationDate: [],
  WorkConfirmationSignedDate: [],
  WorkContractSignDate: [],
  WorkContractStartDate: [],
  WorkContractTypeOfWorkLocation: [],
  WorkContractWorkLocation: [],
  EstimatedArrivalDate: [],
  EstimatedWorkStartDate: [],
  RecognitionFileNumber: [],
  RecognitionFileNumberAcceleratedProcedure: [],
  TargetRecognition: [],
  LegalBasis: [],
  StartOfComplementaryMeasure: [],
  EndOfComplementaryMeasure: [],
  LocationOfComplementaryMeasure: [],
  MedicalExaminationAppointmentDate: [],
  PoliceRecordAppointmentDate: [],
  RegistrationOfficeDate: [],
  CompanyDoctorAppointmentDate: [],
  BankAppointmentDate: [],
  PlannedEntryDate: [],
  FingerPrintDate: [],
  PickUpDetails: [],
  HealthProfessionRegisterAppointmentDate: [],
  HealthProfessionRegistrationDate: [],
  HealthProfessionRegisterSubmission: [],
  RwrCardFileNumber: [],
  NoQualificationEvaluation: [],
  ResponsibleRoleQualificationEvaluation: [
    EnterMissingInformationAdditionalData.NoQualificationEvaluation,
    EnterMissingInformationAdditionalData.ResponsibleQualificationEvaluationOrganizationId,
    EnterMissingInformationAdditionalData.QualificationEvaluationRepresentativeId,
    EnterMissingInformationAdditionalData.QualificationEvaluationRepresentativeFirstName,
    EnterMissingInformationAdditionalData.QualificationEvaluationRepresentativeLastName,
    EnterMissingInformationAdditionalData.QualificationEvaluationFirstDeputyId,
    EnterMissingInformationAdditionalData.QualificationEvaluationFirstDeputyFirstName,
    EnterMissingInformationAdditionalData.QualificationEvaluationFirstDeputyLastName,
  ],
  ResponsibleRoleQualificationEvaluationPayment: [EnterMissingInformationAdditionalData.NoQualificationEvaluation],
};

export const additionalFieldMapping: Record<EnterMissingInformationAdditionalData, MissingInformationFieldName> = {
  PsaRepresentativeFirstName: "psaRepresentativeFirstName",
  PsaRepresentativeLastName: "psaRepresentativeLastName",
  PsaFirstDeputyFirstName: "psaFirstDeputyFirstName",
  PsaFirstDeputyLastName: "psaFirstDeputyLastName",
  NoQualificationEvaluation: "noQualificationEvaluation",
  ResponsibleQualificationEvaluationOrganizationId: "responsibleQualificationEvaluationOrganizationId",
  QualificationEvaluationRepresentativeId: "qualificationEvaluationRepresentativeId",
  QualificationEvaluationRepresentativeFirstName: "qualificationEvaluationRepresentativeFirstName",
  QualificationEvaluationRepresentativeLastName: "qualificationEvaluationRepresentativeLastName",
  QualificationEvaluationFirstDeputyId: "qualificationEvaluationFirstDeputyId",
  QualificationEvaluationFirstDeputyFirstName: "qualificationEvaluationFirstDeputyFirstName",
  QualificationEvaluationFirstDeputyLastName: "qualificationEvaluationFirstDeputyLastName",
  RecognitionType: "recognitionType",
  FederalState: "federalState",
};
