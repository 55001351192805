import { Directive, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { Button } from "primeng/button";

@Directive({ selector: "p-button[closeOnEscape]", standalone: false })
export class EscapeButtonDirective implements OnInit, OnDestroy {
  static buttons: { element: HTMLElement; button: Button }[] = [];
  static isListening = false;

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly button: Button
  ) {}

  ngOnInit(): void {
    if (EscapeButtonDirective.buttons.every((b) => b.element !== this.elementRef.nativeElement)) {
      EscapeButtonDirective.buttons.push({ element: this.elementRef.nativeElement, button: this.button });
    }

    if (!EscapeButtonDirective.isListening) {
      window.addEventListener("keydown", (e) => {
        if (EscapeButtonDirective.buttons.length > 0 && e.code === "Escape") {
          let index = -1;
          let maxZIndex = -1;
          for (let i = 0; i < EscapeButtonDirective.buttons.length; i++) {
            let element = EscapeButtonDirective.buttons[i].element;
            while (element && element !== <EventTarget>window) {
              if (element.style?.zIndex) {
                if (+element.style.zIndex > maxZIndex) {
                  maxZIndex = +element.style.zIndex;
                  index = i;
                }
                break;
              }
              element = element.parentElement;
            }
          }
          if (index > -1) {
            const { button, element } = EscapeButtonDirective.buttons[index];
            if (!button.disabled) {
              const event = new MouseEvent("click", { view: window, bubbles: true, cancelable: true });
              Object.defineProperty(event, "target", { value: element, enumerable: true });
              button.onClick.emit(event);
            }
          }
        }
      });
      EscapeButtonDirective.isListening = true;
    }
  }

  ngOnDestroy(): void {
    const index = EscapeButtonDirective.buttons.findIndex((b) => b.element === this.elementRef.nativeElement);
    if (index > -1) {
      EscapeButtonDirective.buttons.splice(index, 1);
    }
  }
}
