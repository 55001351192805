import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CandidateMultiEditPreviewFragment } from "@ankaadia/graphql";

@Component({
  selector: "app-candidate-multi-edit-preview",
  templateUrl: "./candidate-multi-edit-preview.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CandidateMultiEditPreviewComponent {
  @Input()
  previews: CandidateMultiEditPreviewFragment[];

  @Input()
  totalCandidates: number;
}
