import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { CandidateImportComponent } from "./candidate-import/candidate-import.component";
import { ImportResultDialogComponent } from "./import-result-dialog/import-result-dialog";
import { ImportComponent } from "./import.component";
import { LanguageModule } from "../language/language.module";

@NgModule({
  imports: [SharedModule, LanguageModule],
  declarations: [ImportComponent, CandidateImportComponent, ImportResultDialogComponent],
  exports: [CandidateImportComponent, ImportResultDialogComponent, ImportComponent],
})
export class ImportModule {}
