import { NgModule } from "@angular/core";
import { PlanService } from "./plan.service";
import { PlanSummaryComponent } from "./plan-summary/plan-summary.component";

@NgModule({
  declarations: [],
  imports: [PlanSummaryComponent],
  providers: [PlanService],
  exports: [],
})
export class PlanModule {}
