<app-table
  *appTranslate="let t"
  [items]="candidates"
  [columns]="columns"
  [tableOperations]="tableOperations"
  [newOperations]="newOperations"
  [showSearch]="true"
  [showSidebar]="showSidebar"
  [title]="title$ | async"
>
  <ng-container>
    <app-education-exam-participants-dialog
      *ngIf="showSidebar"
      [exam]="examId$ | async"
      [currentParticipants]="candidates"
      (saved)="save($event)"
      (closed)="showSidebar = false"
    ></app-education-exam-participants-dialog>
  </ng-container>
</app-table>
