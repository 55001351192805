import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import {
  CandidateRole,
  getAllQualificationEvaluationPaymentRoles,
  getAllQualificationEvaluationRoles,
} from "@ankaadia/ankaadia-shared";
import {
  QualificationEvaluationDataDe,
  QualificationEvaluationsDe,
  StaticDataModel,
  StaticDataType,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { assign } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { Observable, Subject, filter, map, takeUntil, tap } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { StaticDataContextEntry, StaticDataService } from "../../../../../shared/static-data/static-data.service";
import { ResponsibleRolePurpose } from "../../../../candidate-reusables/candidate-responsible-representative-group/candidate-responsible-representative-group.service";
import { OrganizationLinkService } from "../../../../organizations/organization-link.service";
import { QualificationEvaluationFormDe } from "../../../candidate-form.model";
import { CandidateFormService } from "../../../candidate-form.service";

@Component({
  selector: "app-candidate-qualification-evaluation",
  templateUrl: "./candidate-qualification-evaluation.component.html",
  standalone: false,
})
export class CandidateQualificationEvaluationComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input({ required: true })
  form: QualificationEvaluationFormDe;

  @Input({ required: true })
  qualificationEvaluation: QualificationEvaluationDataDe;

  @Input({ required: true })
  candidateId: string;

  @Input({ required: true })
  candidateOrganizationId: string;

  @Input({ required: true })
  isMultiEditMode: boolean;

  @Input()
  readonly = false;

  private readonly destroy$: Subject<any> = new Subject<any>();

  protected readonly ResponsibleRolePurposeType = ResponsibleRolePurpose;
  protected readonly responsibleRoles = getAllQualificationEvaluationRoles();
  protected readonly responsiblePaymentRoles = getAllQualificationEvaluationPaymentRoles();

  protected areOrganizationsLinked: boolean;
  protected staticDataContext: StaticDataContextEntry;

  protected readonly language = this.transloco.getActiveLang();
  protected paymentRoles$: Observable<StaticDataModel[]> = this.staticDataService
    .getStaticData(StaticDataType.ProcessRole, this.language)
    .pipe(map((x) => x.filter((y) => this.responsiblePaymentRoles.includes(y.value))));

  constructor(
    private readonly linkService: OrganizationLinkService,
    private readonly candidateFormService: CandidateFormService,
    private readonly confirmationService: ConfirmationService,
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService
  ) {}

  ngAfterViewInit(): void {
    if (this.isMultiEditMode) {
      this.form.controls.responsibleRoleQualificationEvaluation.valueChanges
        .pipe(
          takeUntil(this.destroy$),
          tap((x) => {
            if (x) this.form.controls.noQualificationEvaluation.setValue(false, { emitEvent: false });
            else this.form.controls.noQualificationEvaluation.setValue(undefined, { emitEvent: false });
          })
        )
        .subscribe();
    }
  }

  ngOnInit(): void {
    const controls = this.form.controls;

    controls.noQualificationEvaluation.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((x) => this.setDefaultValueForNoEvaluation(x)),
        filter((x) => x !== null),
        tap((noEvaluation) => {
          this.enableOrDisableFields(noEvaluation);
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateOrganizationId) {
      this.staticDataContext = {
        immigrationCountry: SupportedImmigrationCountry.De,
        organizationId: this.candidateOrganizationId,
      };
      this.linkService
        .isOrganizationLinked(this.candidateOrganizationId)
        .subscribe((x) => (this.areOrganizationsLinked = x));
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

  protected get canHaveADeputyOrRepresentative(): boolean {
    const selectedRole = this.form.controls?.responsibleRoleQualificationEvaluation?.value;
    return selectedRole && selectedRole !== CandidateRole;
  }

  protected get evaluationsDisabled(): boolean {
    return (
      this.isMultiEditMode ||
      this.form.controls.noQualificationEvaluation?.value ||
      this.form.controls.qualificationEvaluations?.disabled
    );
  }

  protected addQualificationEvaluation(): void {
    this.form.controls.qualificationEvaluations.push(
      this.candidateFormService.createQualificationEvaluationGroup(
        assign(new QualificationEvaluationsDe(), { id: uuidv4() })
      )
    );
  }

  protected removeQualificationEvaluation(index: number): void {
    this.form.controls.qualificationEvaluations.removeAt(index);
  }

  protected confirmNoQualificationEvaluation(checked: boolean, target: EventTarget): void {
    if (checked && this.hasEvaluationData() && !this.isMultiEditMode) {
      this.confirmationService.confirm({
        target: target,
        message: translate("qualificationEvaluation.confirm"),
        icon: PrimeIcons.EXCLAMATION_TRIANGLE,
        accept: () => this.removeFields(),
        reject: () => this.form.controls.noQualificationEvaluation.setValue(false),
      });
    }
  }

  protected copyToResponsibleForPayment(role: string): void {
    const blackListRoles = this.responsibleRoles.filter((x) => !this.responsiblePaymentRoles.includes(x));
    if (blackListRoles.includes(role)) return;

    this.form.controls.responsibleRoleQualificationEvaluationPayment.setValue(role);
  }

  protected readonly ResponsiblePurpose = ResponsibleRolePurpose;
  protected readonly SupportedImmigrationCountry = SupportedImmigrationCountry;

  private enableOrDisableFields(noEvaluation: boolean): void {
    const controls = this.form.controls;
    const enabledOrDisabledControls = [
      controls.responsibleRoleQualificationEvaluation,
      controls.responsibleQualificationEvaluationOrganizationId,
      controls.qualificationEvaluationRepresentativeId,
      controls.qualificationEvaluationFirstDeputyId,
      controls.responsibleRoleQualificationEvaluationPayment,
    ];

    const enabledOrDisabledControlsOnlyInSingleEditMode = [controls.qualificationEvaluations];

    enabledOrDisabledControls.forEach((x) => (noEvaluation ? x?.disable() : x?.enable()));
    if (!this.isMultiEditMode)
      enabledOrDisabledControlsOnlyInSingleEditMode.forEach((x) => (noEvaluation ? x?.disable() : x?.enable()));
  }

  private removeFields(): void {
    const controls = this.form.controls;
    const setNullValueControls = [
      controls.responsibleRoleQualificationEvaluation,
      controls.responsibleQualificationEvaluationOrganizationId,
      controls.qualificationEvaluationRepresentativeId,
      controls.qualificationEvaluationRepresentativeFirstName,
      controls.qualificationEvaluationRepresentativeLastName,
      controls.qualificationEvaluationFirstDeputyId,
      controls.qualificationEvaluationFirstDeputyFirstName,
      controls.qualificationEvaluationFirstDeputyLastName,
      controls.responsibleRoleQualificationEvaluationPayment,
    ];

    setNullValueControls.forEach((x) => x.setValue(undefined));
    while (controls.qualificationEvaluations.length > 0) controls.qualificationEvaluations.removeAt(0);
  }

  private hasEvaluationData(): boolean {
    const controls = this.form.controls;
    return (
      [
        controls.responsibleRoleQualificationEvaluation,
        controls.responsibleQualificationEvaluationOrganizationId,
        controls.qualificationEvaluationRepresentativeId,
        controls.qualificationEvaluationFirstDeputyId,
        controls.responsibleRoleQualificationEvaluationPayment,
      ].some((x) => x.value) || controls.qualificationEvaluations?.length > 0
    );
  }

  private setDefaultValueForNoEvaluation(x: boolean): void {
    if (!this.isMultiEditMode && x === null) this.form.controls.noQualificationEvaluation.setValue(true);
  }
}
