<ng-container *appTranslate="let t">
  <p-card *ngIf="settings">
    <div class="grid">
      <p-fieldset [legend]="t('billing.settings.title')" class="col-12 xl:col-3 lg:col-4 md:col-6">
        <ng-container *ngIf="!settings.isLicensed">
          TODO: Orga hat keine Lizenz
        </ng-container>

        <ng-container *ngIf="settings.stripeCustomerId; else StripeCustomerNotYetCreated">
          <div class="p-fluid formgrid grid">
            <div class="field col-12">
              <label for="company-name">{{ t("billing.companyName.title") }}</label>
              <input pInputText id="company-name" [value]="settings?.customer?.name" [readOnly]="true" />
            </div>

            <div class="field col-12">
              <label for="email">{{ t("billing.email.title") }}</label>
              <input pInputText id="email" [value]="settings?.customer?.email" [readOnly]="true" />
            </div>

            <div class="field col-12">
              <label for="address">{{ t("billing.address.title") }}</label>
              <textarea pInputTextarea id="address" [ngModel]="getFormattedBillingAddress()" [rows]="3"
                        [readOnly]="true"></textarea>
            </div>
          </div>
          <div class="col-12 text-center">
            <p-button (onClick)="$event.preventDefault(); openCustomerPortal()">
              {{ t("billing.settings.openCustomerPortal") }}
            </p-button>
          </div>
        </ng-container>

        <ng-template #StripeCustomerNotYetCreated>
          <div class="col-12 text-center">
            <p-button (onClick)="$event.preventDefault(); createStripeCustomer($event)">
              {{ t("billing.createStripeCustomer.title") }}
            </p-button>
          </div>
        </ng-template>
      </p-fieldset>
      <p-fieldset legend="TODO: Vertragsinformationen" class="col-12 xl:col-9 lg:col-8 md:col-6">
        <div>
          <p-dropdown [options]="plans | async" optionLabel="id" #planSelect></p-dropdown>
          <app-plan-summary [planId]="planSelect.selectedOption?.id"></app-plan-summary>
        </div>

      </p-fieldset>

      <app-create-contract (save)="saveContract($event)" class="col-6 lg:col-4 xl:col-3"></app-create-contract>

    </div>
  </p-card>
</ng-container>
