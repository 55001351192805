<ng-container *appTranslate="let t">
  <p-treeSelect
    *ngIf="fields"
    [inputId]="inputId"
    filterMode="strict"
    panelStyleClass="p-treeselect-leafs-only"
    [filter]="true"
    [required]="true"
    [placeholder]="t('field.placeholder')"
    [disabled]="disabled"
    [options]="fields"
    [ngModel]="field"
    (onNodeSelect)="onItemSelected($event)"
    (toggleClick)="onItemToggled($event)"
  >
    <ng-template pTemplate="value" let-item let-placeholder="placeholder">
      <span *ngIf="group?.label as group" class="group-name p-text-secondary">{{ group }}</span>
      <span class="field-name">{{ item?.label ?? placeholder }}</span>
    </ng-template>
  </p-treeSelect>
</ng-container>
