import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  EducationCourseForListFragment,
  EducationExamCreateInput,
  EducationExamFragment,
  EducationExamUpdateInput,
  EducationModuleForSelectionFragment,
} from "@ankaadia/graphql";
import { isEmpty } from "lodash";
import { EducationExamForm } from "./education-exam-form.model";
import { EducationExamFormService } from "./education-exam-form.service";

@Component({
  selector: "app-education-exam-dialog",
  templateUrl: "./education-exam-dialog.component.html",
  styleUrl: "./education-exam-dialog.component.scss",
  standalone: false,
})
export class EducationExamDialogComponent implements OnInit, OnChanges {
  @Input({ required: true })
  exam: Partial<EducationExamFragment>;

  @Input({ required: true })
  courses: EducationCourseForListFragment[];

  @Input({ required: true })
  modules: EducationModuleForSelectionFragment[];

  protected form: EducationExamForm;

  @Output()
  readonly saved = new EventEmitter<EducationExamCreateInput | EducationExamUpdateInput>();

  @Output()
  readonly closed = new EventEmitter<void>();

  constructor(
    private readonly formService: EducationExamFormService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form = this.formService.createForm();
    this.patchForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.exam) this.patchForm();
  }

  private patchForm(): void {
    if (!this.form) return;
    this.form.patchValue(this.exam, { emitEvent: true });
    this.form.markAsPristine();
    this.changeDetector.detectChanges();
  }

  save(): void {
    this.saved.emit(this.form.getRawValue());
  }

  cancel(): void {
    this.closed.emit();
  }

  protected readonly isEmpty = isEmpty;
}
