<span>
  @if (candidateId && showFlag) {
    <app-flag class="mr-2" [country]="country" [small]="true"></app-flag>
  }
  @if (showLink) {
    <a href="javascript:void(0)" [routerLink]="link">
      {{ label }}
    </a>
  } @else {
    {{ label }}
  }
</span>
@if (candidateId && (isDialogClosed$ | async)) {
  <a href="javascript:void(0)" (click)="showCandidateInDialog()" [class]="'ml-2'">
    <i class="text-2xl vertical-align-middle" [ngClass]="'pi pi-user-edit edit-candidate-icon'"></i>
  </a>
}
