import { Injectable } from "@angular/core";
import { FormBuilder, ValidatorFn, Validators } from "@angular/forms";
import { setError } from "../../../../shared/services/form.helper";
import { EducationCourseForm } from "./education-course-form.model";

@Injectable({ providedIn: "root" })
export class EducationCourseFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createForm(): EducationCourseForm {
    return this.formBuilder.group<EducationCourseForm["controls"]>(
      {
        id: this.formBuilder.control(null),
        organizationId: this.formBuilder.control(null),
        _etag: this.formBuilder.control(null),
        name: this.formBuilder.control(null, [Validators.required, Validators.maxLength(200)]),
        description: this.formBuilder.control(null, [Validators.maxLength(200)]),
        moduleId: this.formBuilder.control(null, [Validators.required]),
        teacherUserIds: this.formBuilder.control(null, [Validators.required]),
        maxParticipants: this.formBuilder.control(null, [Validators.required, Validators.min(1)]),
        participantCollectionId: this.formBuilder.control(null, [Validators.required]),
        startDate: this.formBuilder.control(null, [Validators.required]),
        endDate: this.formBuilder.control(null, [Validators.required]),
      },
      { validators: [this.endAfterStartValidatorFactory()] }
    );
  }

  private endAfterStartValidatorFactory(): ValidatorFn {
    return (form: EducationCourseForm) => {
      const startDate = form.controls.startDate.value;
      const endDate = form.controls.endDate.value;
      const invalid = startDate && endDate && startDate > endDate;
      setError(form.controls.endDate, "invalid", invalid);
      return null;
    };
  }
}
