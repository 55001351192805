import { Directive, Optional } from "@angular/core";
import { Sidebar } from "primeng/sidebar";
import { CandidateFlexibleEditService } from "../../../features/candidates/candidate-flexible-edit/candidate-flexible-edit.service";

@Directive({ selector: "p-sidebar", standalone: false })
export class SidebarOptionsDirective {
  constructor(
    private readonly sidebar: Sidebar,
    @Optional() private readonly candidateFlexibleEditService: CandidateFlexibleEditService
  ) {
    this.sidebar.closeOnEscape = false;
    this.sidebar.dismissible = false;
    this.sidebar.showCloseIcon = false;
    this.sidebar.position = "right";
    if (this.candidateFlexibleEditService?.showCandidate$?.value) {
      this.sidebar.modal = false;
    }
  }
}
