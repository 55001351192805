import { Injectable } from "@angular/core";
import { IntersectionKey } from "@ankaadia/ankaadia-shared";
import { StaticDataModel } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { NumberFormatOptions } from "@jsverse/transloco-locale";
import { AppDecimalPipe } from "apps/frontend/src/app/shared/pipes/decimal.pipe";
import { isEqual, isNil } from "lodash";

interface StaticDataOptions {
  staticData: StaticDataModel[];
}

export type OverrideIntersectionKey<TIn, TOut> = string & IntersectionKey<TIn, TOut>;

export interface Override<TIn, TOut, TKey extends OverrideIntersectionKey<TIn, TOut>> {
  originalValue: TIn[TKey];
  currentValue: TOut[TKey];
  key: TKey;
}

export type OverrideLabelOptions<TIn, TOut, TKey extends OverrideIntersectionKey<TIn, TOut>> = TIn[TKey] extends string
  ? StaticDataOptions
  : TIn[TKey] extends number
    ? NumberFormatOptions
    : never;

@Injectable({ providedIn: "root" })
export class OverrideService<TIn, TOut> {
  constructor(private readonly decimalPipe: AppDecimalPipe) {}

  getOverride<TKey extends OverrideIntersectionKey<TIn, TOut>>(
    source: TIn,
    target: TOut,
    key: TKey
  ): Override<TIn, TOut, TKey> | null {
    const originalValue: TIn[TKey] = source?.[key] ?? null;
    const currentValue: TOut[TKey] = target?.[key] ?? null;
    if (isNil(originalValue) || isEqual(originalValue, currentValue)) {
      return null;
    }

    return { originalValue, currentValue, key };
  }

  getOverrideLabel<TIn, TOut, TKey extends OverrideIntersectionKey<TIn, TOut>>(
    override: Override<TIn, TOut, TKey>,
    options?: OverrideLabelOptions<TIn, TOut, TKey>
  ): string {
    if (isNil(override)) {
      return null;
    }

    const { originalValue, currentValue } = override;
    if (isStaticDataOptions(options)) {
      const { staticData } = options;
      return translate(`contractTemplate.overrides.${override.key}`, {
        currentValue: staticData?.find(({ value }) => value === currentValue)?.label ?? currentValue,
        originalValue: staticData?.find(({ value }) => value === originalValue)?.label ?? originalValue,
      });
    }

    return translate(`contractTemplate.overrides.${override.key}`, {
      currentValue: this.format(currentValue, options),
      originalValue: this.format(originalValue, options),
    });
  }

  private format<TIn, TOut, TKey extends OverrideIntersectionKey<TIn, TOut>>(
    value: TOut[TKey],
    options: OverrideLabelOptions<TIn, TOut, TKey>
  ): string | TOut[TKey] {
    return typeof value === "number"
      ? isNumberFormatOptions(options)
        ? this.decimalPipe.transform(value, options)
        : this.decimalPipe.transform(value, {
            minimumIntegerDigits: 1,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
      : value;
  }
}

function isStaticDataOptions<TIn, TOut, TKey extends OverrideIntersectionKey<TIn, TOut>>(
  options: OverrideLabelOptions<TIn, TOut, TKey>
): options is StaticDataOptions & OverrideLabelOptions<TIn, TOut, TKey> {
  return typeof (options as StaticDataOptions)?.staticData === "object";
}

function isNumberFormatOptions<TIn, TOut, TKey extends OverrideIntersectionKey<TIn, TOut>>(
  options: OverrideLabelOptions<TIn, TOut, TKey>
): options is NumberFormatOptions & OverrideLabelOptions<TIn, TOut, TKey> {
  return !isNil(options) && !isStaticDataOptions(options);
}
