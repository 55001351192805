import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { noop } from "rxjs";
import { CandidateProfileField, CandidateProfileFieldGroup } from "../candidate-profile-field-configuration.model";
import { CandidateProfileFieldConfigurationService } from "../candidate-profile-field-configuration.service";

@Component({
  selector: "app-candidate-profile-value",
  templateUrl: "./candidate-profile-value.component.html",
  styleUrl: "./candidate-profile-value.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CandidateProfileValueComponent,
    },
  ],
  standalone: false,
})
export class CandidateProfileValueComponent implements ControlValueAccessor, OnChanges, OnInit {
  constructor(private readonly fieldConfigurationService: CandidateProfileFieldConfigurationService) {}

  @Input()
  inputId: string | null = null;

  @Input()
  placeholder: string | null = null;

  @Input()
  path: string | null = null;

  definition: CandidateProfileField | null = null;

  value: Date | string | number | boolean | null = null;

  fields: CandidateProfileFieldGroup[];

  onChange: (value: Date | string | number | boolean | null) => void = () => {
    noop();
  };

  onTouched = (): void => {
    noop();
  };

  touched = false;

  disabled = false;

  onValueChanged(value: Date | string | number | boolean | null): void {
    this.markAsTouched();
    if (!this.disabled) {
      this.value = value;
      this.onChange(this.value);
    }
  }

  writeValue(value: string | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: Date | string | number | boolean | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  markAsTouched(): void {
    this.touched = true;
    this.onTouched();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.fieldConfigurationService.getFields().subscribe((fields) => {
      this.fields = fields;
      this.updateDefinition();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.path) {
      this.updateDefinition();
    }
  }

  private updateDefinition(): void {
    this.definition = this.fields?.flatMap((t) => t.fields).find((t) => t.path === this.path) ?? null;
  }
}
