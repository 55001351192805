import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CollectionAndSharingInput,
  CollectionType,
  FavoriteFragment,
  GetSelectableCollectionsQuery,
  Organization,
  Sharing,
  SharingTypeEnum,
  StaticDataModel,
  StaticDataType,
  SupportedImmigrationCountry,
  XlsxCandidateImportInput,
  XlsxImportResult,
} from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import {
  concatMap,
  delay,
  forkJoin,
  map,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription,
  switchMap,
  take,
} from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { OrganizationFactoryService } from "../../organization-specific/organization-specific-factory.service";
import { HasChanges } from "../../shared/guards/confirm-deactivate.guard";
import { SettingsService } from "../../shared/services/settings.service";
import { StaticDataService } from "../../shared/static-data/static-data.service";
import { CustomLazyLoadEvent } from "../collections/collection-edit-assigned-candidates/custom-filter.model";
import { CollectionService } from "../collections/collection.service";
import { FavoriteService } from "../favorite/favorite.service";
import { XlsxImportExportService } from "../import/xlsx-import-export.service";
import { MessageService } from "../message/message.service";
import { OrganizationsService } from "../organizations/organizations.service";
import { OrganizationSystemSettingsService } from "../organizations/system-settings.service";
import { CandidateEditComponent } from "./candidate-edit/candidate-edit.component";
import { CandidateSelectionComponent } from "./candidate-selection/candidate-selection.component";
import { CandidatePageEntry, CandidatesService } from "./candidates.service";
import { CurrentCollectionService } from "./current-collection.service";

type Collection = GetSelectableCollectionsQuery["getSelectableCollections"][0]["collection"];
type CollectionForList = Collection & { isFavorite: boolean };

interface SelectedCandidate {
  id?: string;
  organizationId: string;
  immigrationCountry?: SupportedImmigrationCountry;
}

@Component({
  selector: "app-candidates",
  templateUrl: "./candidates.component.html",
  styleUrl: "./candidates.component.scss",
  standalone: false,
})
export class CandidatesComponent implements OnInit, OnDestroy, HasChanges {
  readonly candidatesPerPage = 200;

  private readonly candidateFavoriteReload = new Subject<void>();
  private readonly candidatesLoadedSubject = new Subject<void>();

  protected isEditing = false;
  protected isMultiEditActive = false;

  protected xlsxImportResult: XlsxImportResult;
  protected selectedOrganizationId: string;
  protected selectedCollectionId: string;
  protected collectionOrganizationId: string;
  protected selectedFavorite: FavoriteFragment;
  protected selectedSharing: Sharing;
  protected organizations: Partial<Organization>[] = [];
  protected collections: CollectionForList[];
  protected sharings: Sharing[];
  protected candidates: CandidatePageEntry[];
  protected totalCandidates: number;
  protected subscriptionColl: Subscription;
  protected subscriptionCand: Subscription;
  protected isReadOnlyCollaboration: boolean;
  protected isUneditableCollaboration: boolean;
  protected showSidebar: boolean;
  protected selectedImport: XlsxCandidateImportInput;
  protected _prevCollectionId: string;
  protected isCollectionChanged = false;
  protected topControlsDisabled = false;
  protected isGeneralTabVisible = true;
  protected deletionRequestsSet: Set<string>;
  protected selectedStandardImmigrationCountry = this.settings.standardImmigrationCountry;
  protected customLazyLoadEvent: CustomLazyLoadEvent;

  get selectedCollectionOrganizationId(): string {
    return this.collections?.find((x) => x.id == this.selectedCollectionId)?.organizationId;
  }

  get selectedCollectionName(): string {
    return this.collections?.find((x) => x.id == this.selectedCollectionId)?.name;
  }

  get selectedOrganizationName(): string {
    return this.organizations?.find((x) => x.id == this.selectedOrganizationId)?.name;
  }

  get selectedCollectionTypeIsStandard(): boolean {
    const collectionType = this.collections?.find((x) => x.id == this.selectedCollectionId)?.type;
    return (collectionType ?? CollectionType.Standard) === CollectionType.Standard;
  }

  get organizationId(): string {
    return this.settings.organizationId;
  }

  get isLicensed(): boolean {
    return this.settings.isLicensed;
  }

  private readonly selectorSelectedCandidates$ = new ReplaySubject<readonly SelectedCandidate[]>();

  selectedCandidates$: Observable<readonly SelectedCandidate[]>;

  selectedCandidateIds$: Observable<readonly string[]>;

  @Output()
  readonly collectionsUpdated = new EventEmitter<void>();

  @ViewChild(CandidateSelectionComponent)
  selector: CandidateSelectionComponent;

  @ViewChild(CandidateEditComponent)
  edit: CandidateEditComponent;

  protected showFunctionsFilter = false;
  protected availableFunctions: StaticDataModel[] = [];
  protected selectedFunction: string = null;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly settings: SettingsService,
    private readonly colService: CollectionService,
    private readonly candidateService: CandidatesService,
    private readonly xlsxImportService: XlsxImportExportService,
    private readonly orgService: OrganizationsService,
    private readonly currentCollectionService: CurrentCollectionService,
    private readonly messageService: MessageService,
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly favoriteService: FavoriteService,
    private readonly orgSettingsService: OrganizationSystemSettingsService,
    private readonly orgFactory: OrganizationFactoryService,
    private readonly confirmationService: ConfirmationService
  ) {
    this.selectedCandidates$ = this.route.params.pipe(
      takeUntilDestroyed(),
      switchMap((params) => {
        const { organizationId, candidateId } = params;

        if (!organizationId) {
          return of([] as readonly SelectedCandidate[]);
        }

        if (!candidateId) {
          void this.router.navigate(["edit"], { relativeTo: this.route.parent });
          return of([] as readonly SelectedCandidate[]);
        }

        if (
          !candidateId &&
          !this.currentCollectionService.hasCollection &&
          this.settings.organizationId !== organizationId
        ) {
          void this.router.navigate(["edit"], { relativeTo: this.route.parent });
          return of([] as readonly SelectedCandidate[]);
        }

        if (candidateId === "new") {
          return of([
            {
              organizationId: organizationId,
            },
          ] as readonly SelectedCandidate[]);
        }

        if (candidateId === "multi") {
          if (this.isMultiEditActive) {
            return this.selectorSelectedCandidates$.pipe(map((candidates) => candidates.filter((x) => !!x)));
          }

          void this.router.navigate(["edit"], { relativeTo: this.route.parent });
          return of([] as readonly SelectedCandidate[]);
        }

        return of([
          {
            id: candidateId,
            organizationId: organizationId,
          },
        ] as readonly SelectedCandidate[]);
      }),
      concatMap((x) => {
        const values = (this.isMultiEditActive ? [x] : [[], x]) as SelectedCandidate[][];
        return of(...values);
      }),
      concatMap((x) => (this.isMultiEditActive ? of(x) : of(x).pipe(delay(1))))
    );

    this.selectedCandidateIds$ = this.selectedCandidates$.pipe(map((c) => c.map((x) => x.id)));
  }

  hasChanges: () => boolean = () => this.edit?.hasChanges();

  ngOnDestroy(): void {
    this.subscriptionCand?.unsubscribe();
    this.subscriptionColl?.unsubscribe();
  }

  ngOnInit(): void {
    this.currentCollectionService.reset();
    this.prefetchCandidateStaticData().subscribe(() =>
      this.staticDataService.getStaticData(StaticDataType.Functions).subscribe((x) => {
        this.availableFunctions = x;
      })
    );
    this.orgService
      .getSelectableOrganizations([SharingTypeEnum.Collaboration, SharingTypeEnum.ReadOnlyCollaboration])
      .subscribe((x) => {
        this.organizations = x;
        if (!this.isLicensed && this.organizations.length === 1) {
          this.selectedOrganizationId = this.organizations[0].id;
          this.onOrganizationChange();
        }
      });
    this.updateSelectedSharing();
    this.updateIsReadOnlyCollaboration();
    this.updateIsUneditableCollaboration();
    this.updateCandidates();
    this.updateStandardImmigrationCountry();
    this.orgFactory.getOrganizationSpecifics(this.settings.organizationId).subscribe((orgSpecifics) => {
      this.showFunctionsFilter = orgSpecifics.showCandidateFunctionFilter();
    });
  }

  candidateSelectionChanged(selectedCandidates: readonly SelectedCandidate[]): void {
    this.selectorSelectedCandidates$.next(selectedCandidates);
  }

  updateCollections(): void {
    this.subscriptionColl?.unsubscribe();
    this.colService
      .getSelectableCollections(
        [SharingTypeEnum.Collaboration, SharingTypeEnum.ReadOnlyCollaboration],
        this.selectedOrganizationId
      )
      .pipe(
        switchMap((xs) =>
          this.favoriteService
            .areCollectionsFavorite(xs.map((x) => ({ id: x.collection.id, orgId: x.collection.organizationId })))
            .pipe(map((ys) => [xs, ys] as const))
        )
      )
      .subscribe(([collections, favorites]) => {
        this.collections = collections.map((x) => ({
          ...x.collection,
          isFavorite: favorites.some(
            (y) =>
              y.collectionId === x.collection.id &&
              y.collectionOrganizationId === x.collection.organizationId &&
              y.isFavorite
          ),
        }));
        this.sharings = collections.filter((x) => x.sharing).map((x) => x.sharing);
        this.collectionsUpdated.emit();
      });
  }

  selectedFunctionChange(): void {
    this.selector.resetFilters();
    this.updateCandidates();
    this.updateFavorite();
  }

  updateCandidates(event: CustomLazyLoadEvent = { first: 0, rows: this.candidatesPerPage }): void {
    this.customLazyLoadEvent = event;
    if (this.selectedFunction != null && event.filters?.custom?.function == null) {
      event.filters ??= {};
      event.filters.custom ??= {};
      event.filters.custom.function = { matchMode: "equals", value: this.selectedFunction };
    }
    this.subscriptionCand?.unsubscribe();
    this.isCollectionChanged = this._prevCollectionId != this.selectedCollectionId;
    this._prevCollectionId = this.selectedCollectionId;

    if (this.isCollectionChanged) {
      this.updateSelectedSharing();
      this.updateIsReadOnlyCollaboration();
      this.updateIsUneditableCollaboration();

      this.currentCollectionService.set(
        this.selectedCollectionId,
        this.selectedCollectionOrganizationId,
        this.selectedCollectionName,
        this.selectedSharing?.id,
        this.selectedSharing?.organizationId,
        this.selectedSharing?._etag,
        this.selectedOrganizationName
      );
    }

    if (event.filters?.favorite) {
      this.favoriteService
        .getFavoriteCandidates(this.selectedCollectionId, this.selectedCollectionOrganizationId)
        .subscribe((x) => {
          this.candidates = x;
          this.totalCandidates = x.length;
          this.candidatesLoadedSubject.next();
        });
    } else {
      this.subscriptionCand = this.candidateService
        .getFilteredCandidates(
          this.selectedCollectionId,
          this.selectedCollectionId ? this.selectedCollectionOrganizationId : this.selectedOrganizationId,
          event,
          this.selectedSharing?.id
        )
        .subscribe((x) => {
          this.candidates = x.nodes;
          this.totalCandidates = x.totalCount;
          this.candidatesLoadedSubject.next();
        });
    }
    this.candidateService
      .getAllCandidateDeletionRequests(this.selectedCollectionOrganizationId)
      .subscribe((deletionRequests) => {
        this.deletionRequestsSet = new Set(deletionRequests.map((item) => item.candidateId));
      });
  }

  updateFavorite(): void {
    if (this.selectedCollectionId) {
      this.favoriteService
        .isCollectionFavorite(this.selectedCollectionId, this.selectedCollectionOrganizationId)
        .subscribe((favorite) => {
          this.selectedFavorite = favorite;
        });
    } else {
      this.selectedFavorite = null;
    }
  }

  updateStandardImmigrationCountry(): void {
    this.orgSettingsService
      .getStandardImmigrationCountry(this.selectedOrganizationId ?? this.settings.organizationId)
      .subscribe((x) => {
        this.selectedStandardImmigrationCountry = x;
      });
  }

  createCollectionWithSharing(input: CollectionAndSharingInput): void {
    this.colService.createCollectionWithSharing(input).subscribe((collection) => {
      this.messageService.add({
        severity: "success",
        summary: translate("collection.created.title"),
        detail: translate("collection.created.message", collection),
      });
    });
  }

  private updateIsReadOnlyCollaboration(): void {
    this.isReadOnlyCollaboration =
      this.settings.organizationId !== this.selectedSharing?.organizationId &&
      (this.selectedSharing?.sharingType === SharingTypeEnum.ReadOnlyCollaboration ||
        this.selectedSharing?.sharingType === SharingTypeEnum.View);
  }

  private updateIsUneditableCollaboration(): void {
    this.isUneditableCollaboration =
      this.settings.organizationId !== this.selectedSharing?.organizationId &&
      this.selectedSharing?.sharingType === SharingTypeEnum.Collaboration &&
      !this.selectedSharing?.isCandidateEditable;
  }

  private updateSelectedSharing(): void {
    this.selectedSharing = this.sharings?.find((x) => x.sharedCollectionId === this.selectedCollectionId);
  }

  newClicked(event: Event): void {
    this.isMultiEditActive = false;

    if (
      this.currentCollectionService?.organizationId &&
      this.currentCollectionService?.organizationId !== this.settings?.organizationId &&
      this.settings?.isLicensed
    ) {
      this.confirmationService.confirm({
        target: event.target,
        message: translate("candidate.warningCreateNewForeign"),
        icon: PrimeIcons.EXCLAMATION_TRIANGLE,
        accept: () => {
          void this.router.navigate(
            [`edit/${this.selectedCollectionOrganizationId ?? this.settings.organizationId}/new`],
            {
              relativeTo: this.route.parent,
            }
          );
        },
      });
    } else {
      void this.router.navigate([`edit/${this.selectedCollectionOrganizationId ?? this.settings.organizationId}/new`], {
        relativeTo: this.route.parent,
      });
    }
  }

  openImport(): void {
    this.selectedImport = {
      blob: uuidv4(),
      organizationId: this.selectedCollectionOrganizationId,
      collectionId: this.selectedCollectionId,
      language: this.transloco.getActiveLang(),
      fileName: null,
    };
    this.showSidebar = true;
  }

  import(input: XlsxCandidateImportInput): void {
    this.xlsxImportService.importCandidate(input).subscribe((x) => {
      this.updateCandidates();
      this.xlsxImportResult = x;
    });
    this.close();
  }

  close(): void {
    this.selectedImport = null;
    this.showSidebar = false;
  }

  onSelectedCollectionFavoriteChange(isFavorite: boolean): void {
    this.collections.find((x) => x.id === this.selectedCollectionId).isFavorite = isFavorite;
    if (this.selector.selectedCandidates) {
      this.candidateFavoriteReload.next();
    }
  }

  onOrganizationChange(): void {
    const lastCollectionSelected = this.selectedCollectionId;
    this.selectedCollectionId = null;
    this.selector.resetFilters();
    this.updateCollections();
    this.updateStandardImmigrationCountry();
    this.collectionsUpdated.pipe(take(1)).subscribe(() => {
      if (!this.isLicensed && this.collections.length === 1) {
        this.selectedCollectionId = this.collections[0].id;
        this.onCollectionChange();
      }
    });
    if (lastCollectionSelected != null) {
      this.onCollectionChange();
    }
  }

  onCollectionChange(): void {
    this.collectionOrganizationId = this.collections.find((x) => x.id === this.selectedCollectionId)?.organizationId;

    this.unselectCandidate();
    this.selector.resetFilters();
    this.updateSelectedSharing();
    this.updateIsReadOnlyCollaboration();
    this.updateIsUneditableCollaboration();
    this.updateCandidates();
    this.updateFavorite();
  }

  private unselectCandidate(): void {
    void this.router.navigate(["/app/candidates/edit"]);
  }

  private prefetchCandidateStaticData(): Observable<StaticDataModel[][]> {
    const types = [
      StaticDataType.Title,
      StaticDataType.MartialStatus,
      StaticDataType.Salutation,
      StaticDataType.DrivingLicenses,
      StaticDataType.CareFacilities,
      StaticDataType.ProfessionalFields,
      StaticDataType.PathOfRecognition,
      StaticDataType.LanguageLevels,
      StaticDataType.RelocationRequirements,
      StaticDataType.RecognitionNoticeKind,
      StaticDataType.ProcessRole,
      StaticDataType.RecognitionType,
      StaticDataType.EquivalenceTest,
      StaticDataType.QualificationMeasures,
      StaticDataType.ResidencePermits,
      StaticDataType.Functions,
      StaticDataType.Profession,
      StaticDataType.Education,
      StaticDataType.Beds,
      StaticDataType.Positions,
      StaticDataType.WorkArea,
      StaticDataType.Languages,
      StaticDataType.ExamInstitutions,
    ];
    return forkJoin(types.map((x) => this.staticDataService.getStaticData(x, this.transloco.getActiveLang())));
  }
}
