import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { SettingsService } from "../../../shared/services/settings.service";
import { SharingForm, SharingNotificationEmailUserGroupForm } from "../sharing-edit/sharing-form.model";
import { CollectionAndSharingForm, CollectionForm } from "./collection-and-sharing-form.model";

@Injectable({ providedIn: "root" })
export class CollectionAndSharingFormService {
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly settings: SettingsService
  ) {}

  createForm(): CollectionAndSharingForm {
    return this.formBuilder.group<CollectionAndSharingForm["controls"]>({
      collection: this.formBuilder.group<CollectionForm["controls"]>({
        name: this.formBuilder.control(null, Validators.required),
        description: this.formBuilder.control(null),
        targetSize: this.formBuilder.control(null),
        organizationId: this.formBuilder.control(this.settings.organizationId),
      }),
      createSharing: this.formBuilder.control(false),
      sharing: this.formBuilder.group<SharingForm["controls"]>({
        sharedFileTypes: this.formBuilder.control(this.settings.sharingSettings.sharedDocuments),
        sharedTabs: this.formBuilder.control(this.settings.sharingSettings.sharedTabs),
        autoCVRequested: this.formBuilder.control(this.settings.sharingSettings.autoCV),
        providedTemplates: this.formBuilder.control(null),
        enabled: this.formBuilder.control(true),
        sharingType: this.formBuilder.control(null),
        destinationOrganizationId: this.formBuilder.control(null),
        disableSubmitFeedback: this.formBuilder.control(this.settings.sharingSettings.disableSubmitFeedback),
        isAnonymous: this.formBuilder.control(false),
        shareInternalDocuments: this.formBuilder.control(false),
        showCompletionStateAtDocuments: this.formBuilder.control(false),
        expiryDate: this.formBuilder.control(null),
        isCandidateEditable: this.formBuilder.control(false),
        enableNotificationEmails: this.formBuilder.control(false),
        profileAccessId: this.formBuilder.control(null),
        notificationEmailUserGroups: this.formBuilder.array<SharingNotificationEmailUserGroupForm>([]),
        anonymizationOptions: this.formBuilder.control(null),
        notificationEmailUserGroupIds: this.formBuilder.control(null),
      }),
      filters: this.formBuilder.control(null),
    });
  }
}
