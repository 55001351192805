import { Directive, OnInit } from "@angular/core";
import { Dropdown } from "primeng/dropdown";

@Directive({ selector: "p-dropdown", standalone: false })
export class DropdownHideFixDirective implements OnInit {
  constructor(private readonly dropdown: Dropdown) {}

  ngOnInit(): void {
    this.dropdown.onOptionSelect = this.onOptionSelect.bind(this.dropdown);
  }

  // copy of https://github.com/primefaces/primeng/blob/1b3584b26a1066ef280ae5b438c64c00e4589d18/src/app/components/dropdown/dropdown.ts#L1174-L1184
  onOptionSelect(this: Dropdown, event: Event, option: any, isHide = true, preventChange = false): void {
    if (!this.isSelected(option)) {
      const value = this.getOptionValue(option);
      this.updateModel(value, event);
      this.focusedOptionIndex.set(this.findSelectedOptionIndex());
      if (preventChange === false) {
        this.onChange.emit({ originalEvent: event, value: value });
      }
    }

    if (isHide) {
      // fixed just like https://github.com/primefaces/primeng/pull/14419
      setTimeout(() => this.hide(true), 1);
    }
  }
}
