import { Component, DestroyRef, Input, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormArray, FormGroup } from "@angular/forms";
import { isCollectionAutoTemplateVariable } from "@ankaadia/ankaadia-shared";
import {
  AnonymizationTypeEnum,
  AvailableDocumentTemplateInput,
  DocumentTemplateType,
  LocalPartnerProfileConfigSetFragment,
  Organization,
  SharingTypeEnum,
  StaticDataModel,
  StaticDataType,
  UserGroup,
} from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { isNil, omit } from "lodash";
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  distinctUntilChanged,
  filter,
  forkJoin,
  map,
  of,
  shareReplay,
  tap,
} from "rxjs";
import { OrganizationSpecific } from "../../../organization-specific/organization-specific";
import { OrganizationFactoryService } from "../../../organization-specific/organization-specific-factory.service";
import { EnumPipe } from "../../../shared/pipes/enum.pipe";
import { notNullValuesOf, valuesOf } from "../../../shared/services/form.helper";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { DocumentAutogenService } from "../../document-autogen/document-autogen.service";
import { EmailTemplatesService } from "../../email-templates/email-templates.service";
import { LocalPartnerProfileConfigService } from "../../local-partner-profile-config/local-partner-profile-config.service";
import { UserGroupsService } from "../../user-groups/user-groups.service";
import { CollectionService } from "../collection.service";
import { SharingProvider } from "../sharings-from/sharings-provider";
import { GroupedSelectableOrganization, SharingForm } from "./sharing-form.model";
import { SharingFormService } from "./sharing-form.service";

@Component({
  selector: "app-sharing-edit",
  templateUrl: "./sharing-edit.component.html",
  standalone: false,
})
export class SharingEditComponent implements OnInit {
  private readonly language = this.transloco.getActiveLang();
  private prevIsCandidateEditable: boolean;

  readonly today = new Date();
  readonly tomorrow = new Date(this.today.setDate(this.today.getDate() + 1));
  readonly SharingType = SharingTypeEnum;
  readonly anonymizationTypes = this.getAnonymizationTypes();
  readonly fileTypes$ = this.staticDataService
    .getStaticData(StaticDataType.AllowedUploadFileTypes, this.language)
    .pipe(shareReplay(1));

  readonly profileMaskSelected$ = new BehaviorSubject<boolean>(false);
  readonly shareableTabs$ = this.getShareableTabs();
  readonly userGroups$ = new Map<string, Observable<Partial<UserGroup>[]>>();
  readonly templates$ = this.getTemplates();
  readonly organizationNameMap = new Map<string, string>();

  readonly austrianTabs = ["rwrCard", "healthProfessionsRegister", "complementaryMeasure"];
  readonly germanTabs = ["laborMarketAdmission", "qualificationMeasure", "visa", "qualificationEvaluation"];

  @Input()
  form: SharingForm;

  @Input()
  sharingProvider: SharingProvider;

  @Input()
  showManageCollectionMembers: boolean;

  @Input()
  additionalOrganizations: GroupedSelectableOrganization[];

  @Input()
  destinationOrganizationId: string;

  @Input()
  isEditMode: boolean;

  /**
   * Do. Not. Use.
   *
   * This is for the {@link SharingPresetModule}.
   * Only a subset of fields is displayed in this mode.
   */
  @Input()
  isPresetMode: boolean;

  get autoCVConfigured(): boolean {
    return this.settings.autoCVConfigured;
  }

  userGroupsWithNoUsers$: Observable<string[]>;

  organizations$: Observable<Organization[] | GroupedSelectableOrganization[]>;
  missingEmailTemplates$ = this.emailTemplateService.getMissingSharingNotificationEmailTemplates(
    this.settings.organizationId
  );

  profileAccessOptions$: Observable<Partial<LocalPartnerProfileConfigSetFragment>[]>;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly collService: CollectionService,
    private readonly settings: SettingsService,
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly specificsFactory: OrganizationFactoryService,
    private readonly userGroupService: UserGroupsService,
    private readonly emailTemplateService: EmailTemplatesService,
    private readonly formService: SharingFormService,
    private readonly localPartnerProfileConfigService: LocalPartnerProfileConfigService,
    private readonly documentAutogenService: DocumentAutogenService,
    private readonly enumPipe: EnumPipe
  ) {}

  ngOnInit(): void {
    this.organizations$ = this.loadOrganizations();
    this.profileAccessOptions$ = this.localPartnerProfileConfigService.getAll(this.settings.organizationId);
    this.onToDestOrgChange()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((x) => this.loadUserGroups(x));

    this.form.controls.notificationEmailUserGroups.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((userGroups) => this.checkSelectedUserGroupsForUsers(userGroups));

    this.initFormThings();
  }

  getOrganizationName(): string {
    const destinationOrganization = this.form.controls.destinationOrganizationId.value;
    return this.isEditMode
      ? <string>destinationOrganization
      : (<string[]>destinationOrganization)
          ?.map((x) => this.organizationNameMap[x])
          .filter((x) => x)
          .join(", ");
  }

  private initFormThings(): void {
    this.prevIsCandidateEditable = this.form.controls.isCandidateEditable?.value || !this.isEditMode;
    const controls = this.form.controls;

    controls.disableSubmitFeedback.disable();
    controls.providedTemplates.disable();
    controls.isAnonymous?.disable();
    controls.anonymizationOptions?.disable();
    controls.shareInternalDocuments?.disable();
    controls.showCompletionStateAtDocuments.disable();
    controls.sharedTabs.disable();
    controls.sharedFileTypes.disable();
    controls.profileAccessId.disable();
    controls.isCandidateEditable.disable();
    if (this.isEditMode) {
      controls.notificationEmailUserGroupIds.disable();
    } else {
      controls.notificationEmailUserGroups.disable();
    }

    notNullValuesOf(controls.sharingType)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((sharingType) => {
        if (sharingType !== SharingTypeEnum.View) {
          controls.disableSubmitFeedback.disable();
          controls.providedTemplates.disable();
          controls.isAnonymous.disable();
          controls.anonymizationOptions?.disable();
          controls.sharedTabs.enable();
          controls.profileAccessId.enable();

          if (this.isEditMode) {
            controls.notificationEmailUserGroupIds.disable();
          } else {
            controls.notificationEmailUserGroups.disable();
          }

          if (sharingType === SharingTypeEnum.Collaboration && this.showManageCollectionMembers) {
            controls.isCandidateEditable.enable();
            controls.isCandidateEditable.setValue(this.prevIsCandidateEditable);
          } else {
            controls.isCandidateEditable.disable();
            controls.isCandidateEditable.setValue(false);
          }

          controls.isAnonymous.setValue(false);
        } else {
          controls.sharedTabs.disable();
          controls.profileAccessId.disable();
          controls.shareInternalDocuments.enable();
          controls.disableSubmitFeedback.enable();
          controls.providedTemplates.enable();
          controls.enableNotificationEmails.enable();
          controls.anonymizationOptions?.disable();
          if (this.isEditMode) {
            controls.isAnonymous.disable();
            controls.notificationEmailUserGroupIds.enable();
          } else {
            controls.notificationEmailUserGroups.enable();
            controls.isAnonymous.enable();
          }

          controls.isCandidateEditable.disable();
          controls.isCandidateEditable.setValue(false);
          controls.showCompletionStateAtDocuments.disable();
          controls.showCompletionStateAtDocuments.setValue(false);
        }
      });

    // selecting the notification email checkbox by default
    // can't do it up there because that would also select it when the user opens an existing sharing

    controls.sharingType.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((x) => x === SharingTypeEnum.View)
      )
      .subscribe(() => controls.enableNotificationEmails.setValue(true));

    valuesOf(controls.isAnonymous)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.toggleAnonymizationOptions());

    combineLatest([valuesOf(controls.sharingType), valuesOf(controls.sharedTabs), valuesOf(controls.isAnonymous)])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.toggleSharedFileTypes();
        this.toggleShareInternalDocuments();
        this.toggleshowCompletionStateAtDocuments();
      });

    combineLatest([valuesOf(controls.enableNotificationEmails), valuesOf(controls.destinationOrganizationId)])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([isEnabled, destinationOrganizationIds]) =>
        this.handleNotificationEmailsToggle(isEnabled, destinationOrganizationIds ?? [])
      );

    valuesOf(controls.profileAccessId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((x) => this.profileMaskSelected$.next(!!x));
  }

  getMissingTemplatesForSharingType(sharingType: SharingTypeEnum, templates: string[]): string[] {
    if (sharingType === SharingTypeEnum.View) {
      return templates.filter((template) => template === "Introduction_New_Profiles");
    } else {
      return templates.filter((template) => template === "Notification_Sharing_List");
    }
  }

  checkSelectedUserGroupsForUsers(userGroups: Partial<{ organizationId: string; userGroupIds: string[] }>[]): void {
    const onlyFullUserGroups = userGroups.filter(
      (x) => x.userGroupIds != null && x.userGroupIds?.length != 0 && x.organizationId
    );
    this.userGroupsWithNoUsers$ = this.userGroupService
      .checkUserGroupHasUsers({
        groups: onlyFullUserGroups
          ?.map((x) => x.userGroupIds?.map((y) => ({ groupId: y, organizationId: x.organizationId })))
          .flat(),
      })
      .pipe(map((result) => result.filter((x) => !x.hasMembers).map((x) => x.name)));
  }

  private loadOrganizations(): Observable<Organization[] | GroupedSelectableOrganization[]> {
    if (!this.isEditMode) {
      return forkJoin([
        this.collService.getAssignableOrgs(this.sharingProvider),
        of(this.additionalOrganizations ?? []),
      ]).pipe(
        tap(([organizations, additionalOrganizations]) => {
          for (const organization of [...organizations, ...additionalOrganizations.flatMap((x) => x.items)]) {
            if (!this.organizationNameMap[organization.id]) {
              this.organizationNameMap[organization.id] = organization.name;
            }
          }
        }),
        map(([organizations, additionalOrganizations]) =>
          additionalOrganizations?.length
            ? ([
                ...additionalOrganizations,
                { label: translate("values.title"), items: organizations },
              ] as GroupedSelectableOrganization[])
            : organizations
        )
      );
    }
    return null;
  }

  private onToDestOrgChange(): Observable<string[]> {
    if (!this.isEditMode) {
      return valuesOf(this.form.controls.destinationOrganizationId).pipe(
        distinctUntilChanged(),
        map((x) => <string[]>x)
      );
    } else {
      return of([this.destinationOrganizationId]);
    }
  }

  private handleNotificationEmailsToggle(isEnabled: boolean, destinationOrganizationIds: string | string[]): void {
    if (isEnabled) {
      if (this.isEditMode) {
        // trivial
        if (isCollectionAutoTemplateVariable(<string>destinationOrganizationIds)) {
          this.form.controls.notificationEmailUserGroupIds.disable();
        } else {
          this.form.controls.notificationEmailUserGroupIds.enable();
        }
      } else {
        const userGroups = this.form.controls.notificationEmailUserGroups;
        if (userGroups.enabled) {
          // already enabled so might have some values in it
          // remove deleted organizations
          for (let i = userGroups.controls.length - 1; i >= 0; i--) {
            if (!destinationOrganizationIds.includes(userGroups.at(i).controls.organizationId.value)) {
              userGroups.removeAt(i, { emitEvent: false });
            }
          }

          // add new organizations
          const newOrganizationIds = (<string[]>destinationOrganizationIds).filter(
            (x) => !userGroups.value.some((y) => y.organizationId === x)
          );
          for (const organizationId of newOrganizationIds) {
            const isVariable = isCollectionAutoTemplateVariable(organizationId);
            userGroups.push(this.formService.createUserGroupForm(organizationId, isVariable), { emitEvent: false });
          }
        } else {
          // well, enable it
          userGroups.enable();
          // and create a user group entry per organization
          for (const organizationId of destinationOrganizationIds) {
            const isVariable = isCollectionAutoTemplateVariable(organizationId);
            userGroups.push(this.formService.createUserGroupForm(organizationId, isVariable), { emitEvent: false });
          }
        }
        (<FormArray>userGroups).controls.forEach((x: FormGroup) => {
          const organizationId = x.controls.organizationId.value;
          if (isCollectionAutoTemplateVariable(organizationId)) {
            x.controls.userGroupIds.disable();
          } else {
            x.controls.userGroupIds.enable();
          }
        });

        userGroups.updateValueAndValidity();
      }
    } else {
      // this is trivial - if not already done, reset the control value and disable it
      if (this.isEditMode) {
        if (!this.form.controls.notificationEmailUserGroupIds.disabled) {
          this.form.controls.notificationEmailUserGroupIds.reset();
          this.form.controls.notificationEmailUserGroupIds.disable();
        }
      } else {
        if (!this.form.controls.notificationEmailUserGroups.disabled) {
          this.form.controls.notificationEmailUserGroups.clear();
          this.form.controls.notificationEmailUserGroups.disable();
        }
      }
    }
    this.form.updateValueAndValidity();
  }

  private loadUserGroups(organizationIds: string[]): void {
    for (const organizationId of organizationIds ?? []) {
      if (!this.userGroups$[organizationId]) {
        this.userGroups$[organizationId] = this.userGroupService.getAvailableUserGroups(organizationId);
      }
    }
  }

  private getShareableTabs(): Observable<StaticDataModel[]> {
    return combineLatest([
      this.staticDataService.getStaticData(StaticDataType.AllowedSharedProfileTabs, this.language),
      this.specificsFactory.getOrganizationSpecifics(this.settings.organizationId),
      this.profileMaskSelected$,
    ]).pipe(
      map(([tabs, specifics, profileMaskSelected]) =>
        tabs
          .filter(
            (x) =>
              this.filterProfileTabs(profileMaskSelected, specifics, x) &&
              x.value != "templates" &&
              (x.value != "customFields" ||
                (x.value == "customFields" && specifics.getCandidateProfileCustomFieldsTabName() != null))
          )
          .map((x) =>
            x.value == "customFields" ? { ...x, label: specifics.getCandidateProfileCustomFieldsTabName() } : x
          )
      ),
      tap((staticData) => this.removeNotAvailableOptionsFromSharedTabSelections(staticData))
    );
  }

  private getTemplates(): Observable<{ label: string; value: AvailableDocumentTemplateInput }[]> {
    return this.documentAutogenService.get(this.settings.organizationId).pipe(
      map((xs) =>
        xs.map((x) => ({
          label: x.type === DocumentTemplateType.Pdf ? this.enumPipe.transform(x.name, "KnownPdfFormTokens") : x.name,
          value: omit(x, "__typename"),
        }))
      )
    );
  }

  private getAnonymizationTypes(): any {
    const keys = Object.keys(AnonymizationTypeEnum);
    return keys.map((key) => ({
      label: translate(`anonymizationTypeEnum.${key}`),
      value: AnonymizationTypeEnum[key],
    }));
  }

  private filterProfileTabs(
    profileMaskSelected: boolean,
    specifics: OrganizationSpecific,
    tab: StaticDataModel
  ): boolean {
    return (
      !profileMaskSelected ||
      !specifics
        .getCandidateProfileTabs()
        .map((x) => x.id)
        .includes(tab.value)
    );
  }

  private removeNotAvailableOptionsFromSharedTabSelections(staticData: StaticDataModel[]): void {
    const sharedTabs = this.form.controls?.sharedTabs;
    const availableValues = sharedTabs?.value?.filter((selected) => staticData.some((x) => x.value === selected));
    sharedTabs?.setValue(availableValues);
  }

  private hasAttachments(): boolean {
    const { sharingType, sharedTabs, isAnonymous } = this.form.getRawValue();
    const tabs = sharedTabs ?? [];
    return sharingType === SharingTypeEnum.View ? !isAnonymous : tabs.includes("attachments");
  }

  private toggleShareInternalDocuments(): void {
    if (this.hasAttachments()) {
      this.form.controls.shareInternalDocuments.enable();
    } else {
      this.form.controls.shareInternalDocuments.disable();
      this.form.controls.shareInternalDocuments.setValue(false);
    }
  }

  private toggleshowCompletionStateAtDocuments(): void {
    if (this.hasAttachments() && this.form.controls.sharingType.value === SharingTypeEnum.Collaboration) {
      this.form.controls.showCompletionStateAtDocuments.enable();
    } else {
      this.form.controls.showCompletionStateAtDocuments.disable();
      this.form.controls.showCompletionStateAtDocuments.setValue(false);
    }
  }

  private toggleSharedFileTypes(): void {
    if (!this.hasAttachments()) {
      this.form.controls.sharedFileTypes.disable();
      this.form.controls.sharedFileTypes.setValue(null);
      return;
    }

    this.fileTypes$.subscribe((fileTypes) => {
      if (isNil(this.form.controls.sharingType.value)) {
        return;
      }

      this.form.controls.sharedFileTypes.enable();
      if (!this.form.controls.sharingType.dirty || this.form.controls.sharedFileTypes.dirty) {
        return;
      }

      if (this.form.controls.sharingType.value !== SharingTypeEnum.View) {
        this.form.controls.sharedFileTypes.setValue(fileTypes.map((x) => x.value));
      } else {
        this.form.controls.sharedFileTypes.setValue(null);
      }
    });
  }

  private toggleAnonymizationOptions(): void {
    const { isAnonymous } = this.form.getRawValue();
    if (isAnonymous) {
      if (!this.isEditMode) {
        this.form.controls.anonymizationOptions.enable();
        this.form.controls.anonymizationOptions.setValue(Object.values(AnonymizationTypeEnum));
      }
    } else {
      this.form.controls.anonymizationOptions.disable();
      this.form.controls.anonymizationOptions.setValue([]);
    }
  }
}
