import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, ValidationErrors } from "@angular/forms";
import { getLocaleDateString } from "@ankaadia/ankaadia-shared";
import { TranslocoService } from "@jsverse/transloco";
import { FieldType } from "@ngx-formly/core";
import { Calendar } from "primeng/calendar";

function validateRange(control: FormControl): ValidationErrors | null {
  return control.value?.length == 2 && control.value.every((x) => x) ? null : { range: true };
}
@Component({
  selector: "app-formly-datepicker",
  templateUrl: "./formly-datepicker.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FormlyDatepickerComponent extends FieldType implements OnInit {
  readonly language = this.transloco.getActiveLang();

  @ViewChild(Calendar)
  calendar: Calendar;

  format: string;
  placeholder: string;
  protected currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor(private readonly transloco: TranslocoService) {
    super();
  }

  ngOnInit(): void {
    this.format = getLocaleDateString(this.language, this.props.timeOnly ? "hour" : this.props.view);
    this.placeholder = this.props.timeOnly
      ? "hh:mm"
      : this.props.view == null || this.props.view == "date"
        ? getLocaleDateString(this.language, "date_Placeholder")
        : this.format;
    if (this.props.required && this.props.selectionMode == "range") this.formControl.addValidators(validateRange);
  }

  reset(event: Event): void {
    this.calendar.onClearButtonClick(event);
  }
}
