import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import {
  ContractTemplate,
  ContractTemplateForOverviewFragment,
  ContractTemplateSetInput,
  ContractTermTimePeriod,
  StaticDataModel,
  StaticDataType,
  SupportedImmigrationCountry,
  XlsxImportResult,
} from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { clone } from "lodash";
import { ConfirmationService, Message, PrimeIcons } from "primeng/api";
import { switchMap } from "rxjs";
import { AppDateTimePipe } from "../../../shared/pipes/date.pipe";
import { DownloadService } from "../../../shared/services/download.service";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataPipe } from "../../../shared/static-data/static-data.pipe";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { PipeDescription, TableColumn, TableOperation, TableOperationMode } from "../../../shared/table/table.model";
import { ImportComponentData, SaveResult, Template } from "../../import/import.component";
import { ImportType, XlsxImportExportService } from "../../import/xlsx-import-export.service";
import { MessageService } from "../../message/message.service";
import { ContractTemplateService } from "../contract-template.service";

@Component({
  selector: "app-contract-templates",
  templateUrl: "./contract-templates.component.html",
  standalone: false,
})
export class ContractTemplatesComponent implements OnInit, OnChanges {
  readonly language = this.transloco.getActiveLang();
  readonly newOperations: TableOperation[] = [
    {
      label: translate("contractConditions.title"),
      icon: PrimeIcons.COG,
      mode: TableOperationMode.Button,
      operation: (): void => this.showContractConditions(),
    },
    {
      label: translate("common.new"),
      icon: PrimeIcons.PLUS,
      mode: TableOperationMode.Menu,
      operation: (): void => null,
      items: [
        ...(this.settings.supportedImmigrationCountries.includes(SupportedImmigrationCountry.De)
          ? [
              {
                label: translate("contractTemplate.newForDE"),
                icon: PrimeIcons.PLUS,
                operation: (): void => this.add(SupportedImmigrationCountry.De),
              },
            ]
          : []),
        ...(this.settings.supportedImmigrationCountries.includes(SupportedImmigrationCountry.At)
          ? [
              {
                label: translate("contractTemplate.newForAT"),
                icon: PrimeIcons.PLUS,
                operation: (): void => this.add(SupportedImmigrationCountry.At),
              },
            ]
          : []),
      ],
    },
    {
      label: translate("common.import"),
      icon: PrimeIcons.UPLOAD,
      mode: TableOperationMode.Button,
      operation: (): void =>
        this.onImport(
          ImportType.ContractTemplates,
          translate("contractTemplates.importToMyOrganizationOnly", {
            organization: this.organizationName,
          }),
          translate("contractTemplates.confirmImport", { organization: this.organizationName }),
          [
            ...(this.isImmigrationCountrySupported(SupportedImmigrationCountry.De)
              ? [
                  {
                    template: (_, addTestData) =>
                      this.importService
                        .getContractTemplateImportTemplate(
                          this.import.organizationId,
                          SupportedImmigrationCountry.De,
                          true,
                          this.language,
                          addTestData
                        )
                        .subscribe(({ downloadUrl, fileName }) => {
                          this.downloadService.downloadFile(fileName, downloadUrl);
                        }),
                    templateName: translate("contractTemplates.downloadImportTemplate", {
                      country: this.getCountryName(SupportedImmigrationCountry.De),
                    }),
                  },
                ]
              : []),
            ...(this.isImmigrationCountrySupported(SupportedImmigrationCountry.At)
              ? [
                  {
                    template: (_, addTestData) =>
                      this.importService
                        .getContractTemplateImportTemplate(
                          this.import.organizationId,
                          SupportedImmigrationCountry.At,
                          true,
                          this.language,
                          addTestData
                        )
                        .subscribe(({ downloadUrl, fileName }) => {
                          this.downloadService.downloadFile(fileName, downloadUrl);
                        }),
                    templateName: translate("contractTemplates.downloadImportTemplate", {
                      country: this.getCountryName(SupportedImmigrationCountry.At),
                    }),
                  },
                ]
              : []),
          ]
        ),
    },
  ];

  readonly tableOperations: TableOperation[] = [
    {
      label: translate("common.edit"),
      icon: PrimeIcons.PENCIL,
      operation: (x: ContractTemplateForOverviewFragment): void => this.edit(x),
    },
    {
      label: translate("common.copy"),
      icon: PrimeIcons.CLONE,
      operation: (x: ContractTemplateForOverviewFragment): void => this.clone(x),
    },
    {
      label: translate("common.delete"),
      icon: PrimeIcons.TRASH,
      operation: (x: ContractTemplateForOverviewFragment, e: Event): void => this.delete(x, e),
      canOperate: (x: ContractTemplateForOverviewFragment): boolean => x.canBeDeleted,
    },
  ];

  readonly columns: TableColumn[] = [
    { header: translate("contractTemplate.name"), fieldname: "name", sortable: true, includeInGlobalFilter: true },
    {
      header: translate("country.title"),
      fieldname: "country",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(StaticDataPipe, StaticDataType.Countries),
      default: "DE",
    },
    { header: translate("changedBy.title"), fieldname: "changedBy", sortable: true, includeInGlobalFilter: true },
    {
      header: translate("changedAt.title"),
      fieldname: "changedAt",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(AppDateTimePipe, { dateStyle: "short", timeStyle: "short" }),
    },
    {
      header: translate("validFrom.title"),
      fieldname: "validFrom",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(AppDateTimePipe, { dateStyle: "short", timeStyle: "short" }),
    },
    {
      header: translate("validUntil.title"),
      fieldname: "validUntil",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(AppDateTimePipe, { dateStyle: "short", timeStyle: "short" }),
    },
  ];

  @Input()
  organizationId: string;

  @Input()
  organizationName: string;

  @Input()
  title: string;

  @Input()
  displayBackButton: boolean;

  footerOperations: TableOperation[];
  contractTemplates: ContractTemplateForOverviewFragment[];
  showDialog = false;
  selectedTemplate: Partial<ContractTemplate>;
  import: ImportComponentData;
  xlsxImportResult: XlsxImportResult;
  isEdit = false;
  showSidebarContractConditions = false;
  protected countries: StaticDataModel[];

  constructor(
    private readonly contractTemplateService: ContractTemplateService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly settings: SettingsService,
    private readonly router: Router,
    private readonly importService: XlsxImportExportService,
    private readonly transloco: TranslocoService,
    private readonly downloadService: DownloadService,
    private readonly staticDataService: StaticDataService
  ) {
    this.showDialog = false;
  }

  ngOnInit(): void {
    this.getAllContractTemplates();
    this.staticDataService
      .getStaticData(StaticDataType.SupportedImmigrationCountries, this.language)
      .subscribe((x) => (this.countries = x));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.displayBackButton) {
      this.footerOperations = changes.displayBackButton.currentValue
        ? [
            {
              label: translate("common.back"),
              icon: PrimeIcons.ANGLE_LEFT,
              operation: (): void => this.back(),
            },
          ]
        : [];
    }
  }

  getAllContractTemplates(): void {
    this.contractTemplateService.getAllForList(this.organizationId).subscribe((x) => {
      this.contractTemplates = clone(x);
    });
  }

  add(countryCode?: SupportedImmigrationCountry): void {
    this.selectedTemplate = {
      id: null,
      organizationId: this.organizationId,
      name: null,
      country: countryCode,
      probationPeriodUnit: ContractTermTimePeriod.Months,
      noticePeriodUnit: ContractTermTimePeriod.Months,
    };
    this.isEdit = false;
    this.showDialog = true;
  }

  edit(contractTemplate: ContractTemplateForOverviewFragment): void {
    this.isEdit = true;
    this.contractTemplateService.get(contractTemplate.id, contractTemplate.organizationId).subscribe((x) => {
      this.selectedTemplate = x;
      this.showDialog = true;
    });
  }

  clone(contractTemplate: ContractTemplateForOverviewFragment): void {
    this.contractTemplateService
      .get(contractTemplate.id, contractTemplate.organizationId)
      .pipe(
        switchMap((template) => {
          const { id: _1, changedAt: _2, changedBy: _3, _etag: _4, canBeDeleted: _5, name, ...cleaned } = template;
          const input = { ...cleaned, name: `${name} (${translate("contractTemplate.clone")})` };
          return this.contractTemplateService.set(input);
        })
      )
      .subscribe((result) => {
        this.contractTemplates = [...this.contractTemplates, result];
        this.selectedTemplate = result;
        this.isEdit = true;
        this.showDialog = true;
      });
  }

  delete(contractTemplate: ContractTemplateForOverviewFragment, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("contractTemplate.confirmDelete", contractTemplate),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () =>
        this.contractTemplateService.delete(contractTemplate).subscribe(() => {
          this.messageService.add({
            severity: "success",
            summary: translate("contractTemplate.deleted.title"),
            detail: translate("contractTemplate.deleted.message", contractTemplate),
          });
        }),
    });
  }

  save(contractTemplate: ContractTemplateSetInput): void {
    this.contractTemplateService.set(contractTemplate).subscribe((x) => {
      this.getAllContractTemplates();
      if (x) {
        this.messageService.add({
          severity: "success",
          summary: translate(contractTemplate.id ? "contractTemplate.updated.title" : "contractTemplate.created.title"),
          detail: translate(
            contractTemplate.id ? "contractTemplate.updated.message" : "contractTemplate.created.message",
            contractTemplate
          ),
        });
      }
    });
    this.closeSidebar();
  }

  closeSidebar(): void {
    this.showDialog = false;
    this.showSidebarContractConditions = false;
    this.selectedTemplate = null;
    this.import = null;
  }

  back(): void {
    void this.router.navigate(["/app/settings/organizations", this.settings.organizationId]);
  }

  onImport(
    importType: ImportType,
    header: string,
    submitMessage: string,
    templates: Template[],
    messages?: Message[]
  ): void {
    this.import = this.importService.createImportInput(
      importType,
      this.organizationId,
      header,
      submitMessage,
      templates,
      messages
    );
    this.showDialog = true;
  }

  importContractTemplates($event: SaveResult): void {
    this.importService.importContractTemplate($event).subscribe((x) => {
      this.xlsxImportResult = x;
      this.closeSidebar();
      this.getAllContractTemplates();
    });
  }

  showContractConditions(): void {
    this.showSidebarContractConditions = true;
    this.showDialog = true;
  }

  contractConditionsSaved(): void {
    this.showSidebarContractConditions = false;
    this.showDialog = false;
    this.messageService.add({
      severity: "success",
      summary: translate("contractConditions.saved"),
    });
  }

  private getCountryName(immigrationCountry: SupportedImmigrationCountry): string {
    return this.countries.find((x) => x.value === immigrationCountry)?.label ?? immigrationCountry;
  }

  private isImmigrationCountrySupported(immigrationCountry: SupportedImmigrationCountry): boolean {
    return this.settings.supportedImmigrationCountries.includes(immigrationCountry);
  }
}
