<ng-container *appTranslate="let t">
  <div class="flex justify-content-end">
    <p-fileUpload
      #fileUpload
      [maxFileSize]="4 * 1024 * 1024"
      [showUploadButton]="false"
      [showCancelButton]="false"
      [disabled]="isUploading"
      accept="image/*"
      [chooseLabel]="t('file.uploadImages')"
      [invalidFileSizeMessageSummary]="t('primeng.invalidFileSizeMessageSummary')"
      [invalidFileSizeMessageDetail]="t('primeng.invalidFileSizeMessageDetail')"
      [invalidFileTypeMessageSummary]="t('primeng.invalidFileTypeMessageSummary')"
      [invalidFileTypeMessageDetail]="t('primeng.invalidFileTypeMessageDetail')"
      [invalidFileLimitMessageDetail]="t('primeng.invalidFileLimitMessageDetail')"
      [invalidFileLimitMessageSummary]="t('primeng.invalidFileLimitMessageSummary')"
      [multiple]="true"
      [customUpload]="true"
      [auto]="true"
      mode="basic"
      styleClass="mb-2"
      (uploadHandler)="upload($event.files); fileUpload.clear()"
    ></p-fileUpload>
  </div>

  <p-galleria
    *ngIf="form.value?.length"
    [value]="form.value"
    [responsiveOptions]="responsiveOptions"
    class="organization-profile-gallery"
  >
    <ng-template pTemplate="item" let-image>
      <div class="image-wrapper">
        <img [src]="image" />
      </div>
    </ng-template>
    <ng-template pTemplate="thumbnail" let-image>
      <div class="thumbnail-wrapper">
        <img [src]="image" />
        <i class="pi pi-trash thumbnail-icon" (click)="remove(image)"></i>
      </div>
    </ng-template>
  </p-galleria>

  <div *ngIf="!form.value?.length" class="placeholder-wrapper">
    <div class="placeholder">
      <span class="placeholder-text">{{ t("file.noImages") }}</span>
    </div>
  </div>
</ng-container>
