<ng-container *appTranslate="let t">
  <form [formGroup]="form" *ngIf="form">
    <div class="p-fluid formgrid">
      <div class="field">
        <label for="educationModuleId">{{ t("educationModule.title") }}</label>
        <app-education-module-selector
          [educationModuleIdControl]="form.controls.educationModuleId"
          [modulePlaceholder]="t('educationModule.placeholder')"
          [educationModules]="selectableModules"
        ></app-education-module-selector>
        <small class="p-error" *ngIf="form.controls.educationModuleId.errors?.required">
          {{ t("educationModule.required") }}
        </small>
      </div>

      <div class="field">
        <label for="educationCourseIds">{{ t("educationCourses.title") }}</label>

        <p-multiSelect
          inputId="educationCourseIds"
          formControlName="educationCourseIds"
          [options]="selectableCourses"
          optionLabel="name"
          optionValue="id"
          [showClear]="true"
          [placeholder]="t('educationCourses.placeholder')"
        >
        </p-multiSelect>

        <small class="p-error" *ngIf="form.controls.educationCourseIds.errors?.required">
          {{ t("educationCourses.required") }}
        </small>
        <small class="p-error" *ngIf="form.controls.educationCourseIds.errors?.courseOrganizationMismatch">
          {{ t("educationCourses.courseOrganizationMismatch") }}
        </small>
      </div>
    </div>
  </form>
</ng-container>
