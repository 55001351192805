<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <ng-container *ngIf="control">
    <label for="confirmationSignedDate">{{ t("confirmationSignedDate.title") }}</label>
    <p-calendar inputId="confirmationSignedDate" [formControl]="control" [readonly]="readonly"></p-calendar>

    <small class="p-error" *ngIf="control.errors?.required">
      {{ t("common.validation.requiredShort") }}
    </small>
  </ng-container>
</ng-container>
