import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import {
  RecognitionPathEntryAt,
  StaticDataModel,
  StaticDataType,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { StaticDataService } from "../../../shared/static-data/static-data.service";

const nameOf = nameofFactory<RecognitionPathEntryAt>();

@Component({
  selector: "app-candidate-legal-basis",
  templateUrl: "./candidate-legal-basis.component.html",
  standalone: false,
})
export class CandidateLegalBasisComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private readonly language = this.transloco.getActiveLang();

  @Input({ required: true })
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  @Input({ required: true })
  hideControl: boolean;

  @Input({ required: true })
  readonly: boolean;

  @Output()
  readonly valueChanged = new EventEmitter<string>();

  @Input({ required: true }) // if not relevant anymore we clear the value of the field
  isRelevant: boolean;

  legalBases: StaticDataModel[];

  @Input({ required: true })
  immigrationCountry: SupportedImmigrationCountry;

  @Input({ required: true })
  candidateOrganizationId: string;

  get control(): FormControl<string> {
    return this.form.get(nameOf("legalBasis")) as FormControl<string>;
  }

  set control(value: FormControl<string>) {
    this.form.setControl(nameOf("legalBasis"), value);
  }

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<string>(null);
    this.control.valueChanges.subscribe((value) => this.valueChanged.emit(value));
    this.updateLegalBases(this.processLanguage);
  }

  ngAfterViewInit(): void {
    this.setEnabled(!this.readonly);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isRelevant) {
      if (!changes.isRelevant.currentValue) {
        this.control.setValue(undefined, { emitEvent: false });
      }
    }
    if (changes.processLanguage) {
      this.updateLegalBases(changes.processLanguage.currentValue);
    }

    if (changes.readonly) {
      this.setEnabled(!changes.readonly.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("legalBasis"), { emitEvent: false });
  }

  private updateLegalBases(language: string): void {
    this.staticDataService
      .getStaticData(StaticDataType.RecognitionType, language ?? this.language, [
        {
          immigrationCountry: this.immigrationCountry,
          organizationId: this.candidateOrganizationId,
        },
      ])
      .subscribe((x) => {
        this.legalBases = x;
        this.changeDetector.markForCheck();
      });
  }

  private setEnabled(enabled: boolean): void {
    if (enabled) {
      this.control.enable({ emitEvent: false });
    } else {
      this.control.disable({ emitEvent: false });
    }
  }
}
