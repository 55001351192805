<ng-container *appTranslate="let t">
  <form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
    <p-card [header]="form.value.id ? t('contact.edit') : t('contact.create')">
      <div class="p-fluid">
        <div class="field">
          <label for="function">{{ t("function.title") }}</label>
          <p-dropdown
            inputId="function"
            formControlName="function"
            [options]="functions"
            [showClear]="true"
            [placeholder]="t('function.placeholder')"
          ></p-dropdown>
        </div>
        <div class="field" *ngIf="isNewFromUsers">
          <label for="title">{{ t("user.title") }}</label>
          <p-dropdown
            [options]="usersOptions"
            formControlName="user"
            optionLabel="displayName"
            [placeholder]="t('user.placeholder')"
          ></p-dropdown>
        </div>
        <div class="field" *ngIf="!isNewFromUsers">
          <label for="title">{{ t("title.title") }}</label>
          <p-dropdown
            inputId="title"
            formControlName="title"
            [options]="titles | async"
            [showClear]="true"
            [placeholder]="t('title.placeholder')"
          ></p-dropdown>
        </div>

        <div class="field" *ngIf="!isNewFromUsers">
          <label for="salutation">{{ t("salutation.title") }}</label>
          <p-dropdown
            inputId="salutation"
            formControlName="salutation"
            [options]="salutations | async"
            [showClear]="true"
            [placeholder]="t('salutation.placeholder')"
          ></p-dropdown>
        </div>

        <div class="field" *ngIf="!isNewFromUsers">
          <label for="firstName">{{ t("firstName.title") }}</label>
          <input type="text" pInputText id="firstName" formControlName="firstName" maxlength="300" />
        </div>

        <div class="field" *ngIf="!isNewFromUsers">
          <label for="lastName">{{ t("lastName.title") }}</label>
          <input type="text" pInputText id="lastName" formControlName="lastName" maxlength="300" />
        </div>

        <div class="field" *ngIf="!isNewFromUsers">
          <label for="email">{{ t("email.title") }}</label>
          <input type="text" pInputText id="email" formControlName="email" maxlength="300" />
          <small class="p-error" *ngIf="form.controls.email.errors?.pattern">
            {{ t("email.invalid") }}
          </small>
        </div>

        <div class="field" *ngIf="!isNewFromUsers">
          <label for="phone">{{ t("phone.title") }}</label>
          <input type="text" pInputText id="phone" formControlName="phone" maxlength="100" />
        </div>

        <div class="field" *ngIf="!isNewFromUsers">
          <label for="mobilePhone">{{ t("mobile.title") }}</label>
          <input type="text" pInputText id="mobilePhone" formControlName="mobilePhone" maxlength="100" />
        </div>

        <div class="field" *ngIf="!isNewFromUsers">
          <label for="position">{{ t("position.title") }}</label>
          <input type="text" pInputText id="position" formControlName="position" maxlength="300" />
        </div>

        <div class="field" *ngIf="!isNewFromUsers">
          <label for="signatureAddition">{{ t("signatureAddition.title") }}</label>
          <p-dropdown
            inputId="signatureAddition"
            formControlName="signatureAddition"
            [options]="signatureAddition$ | async"
            [showClear]="true"
            [placeholder]="t('signatureAddition.placeholder')"
          ></p-dropdown>
        </div>
      </div>

      <div class="field">
        <p-inplace>
          <ng-template pTemplate="display">
            <div class="inline-flex align-items-center" pTooltip="{{ addressSummary }}">
              <span class="pi pi-id-card" style="vertical-align: middle"></span>
              <span class="ml-2">{{ t("address.title") }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <p-fieldset [legend]="t('address.title')">
              <div class="p-fluid">
                <div class="field">
                  <label for="address">{{ t("address.title") }}</label>
                  <textarea
                    id="address"
                    pInputTextarea
                    rows="2"
                    [placeholder]="t('address.placeholder')"
                    formControlName="address"
                    maxlength="500"
                  ></textarea>
                </div>

                <div class="field">
                  <label for="zipCode">{{ t("zipCode.title") }}</label>
                  <input
                    id="zipCode"
                    pInputText
                    [placeholder]="t('zipCode.placeholder')"
                    formControlName="zipCode"
                    maxlength="30"
                  />
                </div>

                <div class="field">
                  <label for="city">{{ t("city.title") }}</label>
                  <input
                    id="city"
                    pInputText
                    [placeholder]="t('city.placeholder')"
                    formControlName="city"
                    maxlength="100"
                  />
                </div>

                <div class="field">
                  <label for="region">{{ t("region.title") }}</label>
                  <input
                    id="region"
                    pInputText
                    [placeholder]="t('region.placeholder')"
                    formControlName="region"
                    maxlength="100"
                  />
                </div>

                <div class="field">
                  <label for="country">{{ t("country.title") }}</label>
                  <p-dropdown
                    inputId="country"
                    formControlName="country"
                    [placeholder]="t('country.placeholder')"
                    [showClear]="true"
                    [options]="countries | async"
                  ></p-dropdown>
                </div>
              </div>
            </p-fieldset>
          </ng-template>
        </p-inplace>
      </div>

      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
          <p-button
            [disabled]="!form.valid || !form.dirty || submitDisabled"
            [label]="t('common.save')"
            icon="pi pi-check"
            type="submit"
            class="mr-2"
          ></p-button>
          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            type="reset"
            (onClick)="closed.emit()"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  </form>
</ng-container>
