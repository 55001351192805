import { Injectable } from "@angular/core";
import {
  AddForeignGroupsToGroupInput,
  AddUserGroupsToUserGroupsGQL,
  AddUsersToGroupGQL,
  AddUsersToGroupInput,
  CheckUserGroupHasUsersGQL,
  CreateUserGroupGQL,
  GetAllUserGroupsDeepGQL,
  GetAllUserGroupsDeepQuery,
  GetAllUserGroupsGQL,
  GetAssignableMembersGQL,
  GetAssignableMembersQuery,
  GetAvailableUserGroupsGQL,
  GetUserGroupDeepGQL,
  GetUserGroupDeepQuery,
  GetUserGroupFlatGQL,
  GetUserGroupFlatQuery,
  GetUserGroupsGQL,
  GetUserGroupsQuery,
  RemoveForeignGroupsFromGroupInput,
  RemoveUserGroupGQL,
  RemoveUserGroupsFromUserGroupGQL,
  RemoveUsersFromGroupGQL,
  RemoveUsersFromGroupInput,
  UpdateUserGroupGQL,
  UserGroup,
  UserGroupFlatFragmentDoc,
  UserGroupForListFragment,
  UserGroupHasMembers,
  UserGroupHasMembersInput,
  UserGroupInput,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class UserGroupsService {
  constructor(
    private readonly create: CreateUserGroupGQL,
    private readonly get: GetUserGroupsGQL,
    private readonly getAll: GetAllUserGroupsGQL,
    private readonly getAllDeep: GetAllUserGroupsDeepGQL,
    private readonly getDeep: GetUserGroupDeepGQL,
    private readonly getFlat: GetUserGroupFlatGQL,
    private readonly update: UpdateUserGroupGQL,
    private readonly remove: RemoveUserGroupGQL,
    private readonly addUsers: AddUsersToGroupGQL,
    private readonly removeUsers: RemoveUsersFromGroupGQL,
    private readonly addGroups: AddUserGroupsToUserGroupsGQL,
    private readonly removeGroups: RemoveUserGroupsFromUserGroupGQL,
    private readonly aMembers: GetAssignableMembersGQL,
    private readonly availableGroups: GetAvailableUserGroupsGQL,
    private readonly checkUserGroupsHaveUsers: CheckUserGroupHasUsersGQL
  ) {}

  getGroupDeep(id: string, organizationId: string): Observable<GetUserGroupDeepQuery["userGroup"]> {
    return this.getDeep
      .fetch({ groupId: id, organizationId: organizationId })
      .pipe(map((result) => result.data.userGroup));
  }

  getGroupFlat(id: string, organizationId: string): Observable<GetUserGroupFlatQuery["userGroup"]> {
    return this.getFlat
      .fetch({ groupId: id, organizationId: organizationId })
      .pipe(map((result) => result.data.userGroup));
  }

  getUserGroups(organizationId: string): Observable<GetUserGroupsQuery["userGroups"]> {
    return this.get
      .watch({ organizationId: organizationId })
      .valueChanges.pipe(map((result) => result.data.userGroups));
  }

  getAllUserGroups(organizationId: string): Observable<UserGroupForListFragment[]> {
    return this.getAll.fetch({ organizationId: organizationId }).pipe(map((result) => result.data.getAllUserGroups));
  }

  getAllUserGroupsDeep(
    organizationId: string,
    cache = false
  ): Observable<GetAllUserGroupsDeepQuery["getAllUserGroups"]> {
    return this.getAllDeep
      .fetch({ organizationId: organizationId }, { fetchPolicy: cache ? "cache-first" : undefined })
      .pipe(map((result) => result.data.getAllUserGroups));
  }

  getAvailableUserGroups(organizationId: string): Observable<UserGroupForListFragment[]> {
    return this.availableGroups
      .fetch({ organizationId: organizationId })
      .pipe(map((result) => result.data.getAvailableUserGroups));
  }

  getAssignableMembers(
    groupdId: string,
    organizationId: string
  ): Observable<GetAssignableMembersQuery["getAssignableMembers"]> {
    return this.aMembers
      .fetch({ groupId: groupdId, organziationId: organizationId })
      .pipe(map((result) => result.data.getAssignableMembers));
  }

  addUserGroup(userGroup: UserGroupInput, organizationId: string): Observable<UserGroup> {
    userGroup.organizationId = organizationId;
    delete userGroup["__typename"]; // remove because it does not work with mutation
    return this.create
      .mutate(
        { input: userGroup },
        {
          update: (cache, mutationResult) =>
            cache.modify({
              fields: {
                userGroups(refs, helper) {
                  if (!helper.storeFieldName.includes(userGroup.organizationId)) return refs;
                  const ref = cache.writeFragment({
                    data: mutationResult.data.createUserGroup,
                    fragment: UserGroupFlatFragmentDoc,
                  });
                  if (refs != null && refs.length > 0) {
                    return [...refs, ref];
                  } else {
                    return [ref];
                  }
                },
              },
            }),
        }
      )
      .pipe(map((result) => result.data?.createUserGroup as UserGroup));
  }

  updateUserGroup(userGroup: UserGroupInput): Observable<UserGroup> {
    delete userGroup["__typename"]; // remove because it does not work with mutation
    return this.update.mutate({ input: userGroup }).pipe(map((result) => result.data?.updateUserGroup as UserGroup));
  }

  deleteUserGroup(id: string, organizationId: string, etag: string): Observable<boolean> {
    const dummy = new UserGroup();
    dummy.__typename = "UserGroup"; // just to make sure to get typename in a safe mode and not to hardcode a constant here
    const proxy = { id: id, __typename: dummy.__typename }; // creating the dummy object here in ordet to create id with cache.identiy method
    return this.remove
      .mutate(
        { etag: etag, id: id, organizationId: organizationId },
        {
          update: (cache, result) =>
            result?.data.removeUserGroup.status && (<any>cache).data.delete(cache.identify(proxy)),
        }
      )
      .pipe(map((x) => x.data.removeUserGroup.status));
  }

  addUsersToGroup(addInput: AddUsersToGroupInput): Observable<UserGroup> {
    return this.addUsers.mutate({ input: addInput }).pipe(map((val) => val.data?.addUsersToGroup as UserGroup));
  }

  removeUsersFromGroup(addInput: RemoveUsersFromGroupInput): Observable<UserGroup> {
    return this.removeUsers.mutate({ input: addInput }).pipe(map((val) => val.data?.removeUsersFromGroup as UserGroup));
  }

  addGroupsToGroup(input: AddForeignGroupsToGroupInput): Observable<UserGroup> {
    return this.addGroups
      .mutate({ input: input })
      .pipe(map((val) => val.data?.addForeignGroupsToUserGroup as UserGroup));
  }

  removeGroupsFromGroup(input: RemoveForeignGroupsFromGroupInput): Observable<UserGroup> {
    return this.removeGroups
      .mutate({ input: input })
      .pipe(map((val) => val.data?.removeForeignGroupsFromUserGroup as UserGroup));
  }

  checkUserGroupHasUsers(input: UserGroupHasMembersInput): Observable<UserGroupHasMembers[]> {
    return this.checkUserGroupsHaveUsers
      .fetch({ input: input })
      .pipe(map((result) => result.data.checkUserGroupHasUsers));
  }
}
