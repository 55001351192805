import { Component, OnInit } from "@angular/core";
import { CandidateMultiEditPreviewFragment } from "@ankaadia/graphql";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "app-candidate-multi-edit-preview-dialog",
  templateUrl: "./candidate-multi-edit-preview-dialog.component.html",
  standalone: false,
})
export class CandidateMultiEditPreviewDialogComponent implements OnInit {
  previews: CandidateMultiEditPreviewFragment[];
  totalCandidates: number;

  constructor(
    private readonly dialog: DynamicDialogRef,
    private readonly config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.previews = this.config.data.previews;
    this.totalCandidates = this.config.data.totalCandidates;
  }

  save(): void {
    this.dialog.close(true);
  }

  cancel(): void {
    this.dialog.close(false);
  }
}
