import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { EducationModuleForSelectionFragment } from "@ankaadia/graphql";
import { BehaviorSubject, combineLatest, map, Observable } from "rxjs";
import { SettingsService } from "../../../../shared/services/settings.service";
import { OrganizationsService } from "../../../organizations/organizations.service";

@Component({
  selector: "app-education-module-selector",
  templateUrl: "./education-module-selector.component.html",
  styleUrl: "./education-module-selector.component.scss",
  standalone: false,
})
export class EducationModuleSelectorComponent implements OnChanges {
  @Input({ required: true }) educationModuleIdControl: FormControl<string>;
  @Input({ required: true }) educationModules: EducationModuleForSelectionFragment[];
  @Input({ required: true }) modulePlaceholder: string;

  modules$ = new BehaviorSubject<EducationModuleForSelectionFragment[]>([]);
  modulesForSelection$ = this.getEducationModules();

  constructor(
    private readonly organizationService: OrganizationsService,
    private readonly settings: SettingsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.educationModules) this.modules$.next(this.educationModules);
  }

  private getEducationModules(): Observable<EducationModuleForSelection[]> {
    return combineLatest([
      this.modules$,
      this.organizationService.getOrganizationsLinkedTo(this.settings.organizationId, true),
    ]).pipe(
      map(([modules, organizations]) =>
        modules.map((module) => ({
          ...module,
          organizationName: organizations.find((x) => x.id === module.organizationId)?.name ?? module.organizationId,
        }))
      )
    );
  }
}

export type EducationModuleForSelection = EducationModuleForSelectionFragment & {
  organizationName: string;
};
