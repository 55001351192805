import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FavoriteFragment } from "@ankaadia/graphql";
import { clone } from "lodash";
import { Observable, finalize } from "rxjs";
import { FavoriteService } from "./favorite.service";

@Component({
  selector: "app-favorite",
  templateUrl: "./favorite.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FavoriteComponent {
  private isBusy: boolean;

  @Input()
  favorite: FavoriteFragment;

  @Input()
  candidate: { candidateId: string; organizationId: string };

  @Input()
  collection: { collectionId: string; organizationId: string };

  @Output()
  readonly favoriteChange = new EventEmitter<FavoriteFragment>();

  constructor(private readonly favoriteService: FavoriteService) {}

  toggle(): void {
    if (!this.isBusy && !this.favorite.isReadonly) {
      this.isBusy = true;
      this.runToggleOperation()
        .pipe(finalize(() => (this.isBusy = false)))
        .subscribe(() => {
          const favorite = clone(this.favorite);
          favorite.isFavorite = !favorite.isFavorite;
          this.favorite = favorite;
          this.favoriteChange.emit(this.favorite);
        });
    }
  }

  private runToggleOperation(): Observable<boolean> {
    if (this.candidate) {
      if (this.favorite.isFavorite) {
        return this.favoriteService.unsetFavoriteCandidate(this.candidate.candidateId, this.candidate.organizationId);
      } else {
        return this.favoriteService.setFavoriteCandidate(this.candidate.candidateId, this.candidate.organizationId);
      }
    }
    if (this.collection) {
      if (this.favorite.isFavorite) {
        return this.favoriteService.unsetFavoriteCollection(
          this.collection.collectionId,
          this.collection.organizationId
        );
      } else {
        return this.favoriteService.setFavoriteCollection(this.collection.collectionId, this.collection.organizationId);
      }
    }
    return null;
  }
}
