import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import {
  DefaultDocumentSetName,
  DocumentSetDateField,
  DocumentSetField,
  DocumentWriteMode,
  SelectedSetField,
  declaredDocumentSetTypes,
  getFiles,
  getForeignKey,
  getFreeFormatFiles,
  getMaxDocumentSetCount,
  getSets,
  getSynchronizedSetFields,
  hasDeclaredDocumentSetTypes,
  isSelectedSet,
  isSelectedSetField,
  isSingleDocumentSet,
  nameofFactory,
} from "@ankaadia/ankaadia-shared";
import { Candidate, Document, DocumentLockMode, DocumentMode, StaticDataModel } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { clone, isEmpty, isNil, noop, orderBy, uniq } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { Observable, Subscription, filter, forkJoin, map, merge, switchMap } from "rxjs";
import { AppDateTimePipe } from "../../../shared/pipes/date.pipe";
import { UpdatedConfirmation } from "../../../shared/primeng/confirm-popup-fix/confirm-popup-fix.directive";
import { updateValueAndValidity, valuesOf } from "../../../shared/services/form.helper";
import { SettingsService } from "../../../shared/services/settings.service";
import { AdditionalForeignKeySourceData } from "../../candidate-document-metadata/candidate-document-foreign-key-handler";
import { ForeignKeyData } from "../../candidate-document-metadata/candidate-document-metadata.model";
import { CandidateDocumentMetadataService } from "../../candidate-document-metadata/candidate-document-metadata.service";
import { extractCandidateFromForm } from "../../candidate-form/candidate-form.helper";
import { AnyCandidateForm } from "../../candidate-form/candidate-form.model";
import { DocumentRequirementService } from "../../document-requirements/document-requirement.service";
import { isSelectedSetForm } from "../document-form.helper";
import { DocumentForm, DocumentSetForm } from "../document-form.model";
import { DocumentFormBuilder, DocumentFormService } from "../document-form.service";
import { getWriteMode } from "../document-helper";
import { DocumentsService } from "../documents.service";

const nameOf = nameofFactory<DocumentSetSelectorComponent>();

@Component({
  selector: "app-document-set-selector",
  templateUrl: "./document-set-selector.component.html",
  styleUrl: "./document-set-selector.component.scss",
  standalone: false,
})
export class DocumentSetSelectorComponent implements OnChanges {
  private dateChangeSubscription: Subscription;
  private documentType: string;
  protected readonly isEmpty = isEmpty;
  protected readonly isSelectedSetForm = isSelectedSetForm;
  protected readonly isSelectedSetField = isSelectedSetField;
  protected readonly DefaultDocumentSetName = DefaultDocumentSetName;
  protected readonly DocumentMode = DocumentMode;
  protected readonly today = new Date();
  protected foreignKeyData: ForeignKeyData[] = [];
  protected selectableForeignKeyData: ForeignKeyData[] = [];
  protected maxDocumentSets = 0;
  protected hasDeclaredDocumentSetTypes = false;
  protected isSingleDocumentSet = false;
  protected canAddDocumentSet = false;
  protected newSet?: DocumentSetForm;

  @Input({ required: true })
  form: DocumentForm;

  @Input({ required: true })
  mode: DocumentMode;

  @Input({ required: true })
  readonly: boolean;

  @Input()
  preselectTags: string[];

  @Input()
  processLanguage?: string;

  @Input()
  availableTags?: string[];

  @Input()
  availablePhysicalFileTypes: StaticDataModel[] = [];

  @Input()
  selectedField?: SelectedSetField;

  @Input()
  expandMetadata = false;

  @Input()
  candidateFormOrCandidate?: AnyCandidateForm | Candidate;

  @Input()
  additionalForeignKeySourceData: AdditionalForeignKeySourceData;

  get isLocked(): boolean {
    const lockState = this.form.controls.lockState.value;
    return lockState != null && lockState != DocumentLockMode.Unlocked;
  }

  get isCandidate(): boolean {
    return this.settings.isCandidate;
  }

  get isInMetadataMode(): boolean {
    return this.form.controls.writeMode.value === DocumentWriteMode.Metadata;
  }

  constructor(
    private readonly settings: SettingsService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly confirmationService: ConfirmationService,
    private readonly documentService: DocumentsService,
    private readonly documentFormService: DocumentFormService,
    private readonly documentRequirementService: DocumentRequirementService,
    private readonly documentMetadataService: CandidateDocumentMetadataService,
    private readonly dateTimePipe: AppDateTimePipe
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes[nameOf("mode")] || changes[nameOf("form")] || changes[nameOf("candidateFormOrCandidate")]) {
      this.updateDocumentThings();
    }
  }

  updateDocumentType(type: string, target: any): void {
    if (this.documentType === type) {
      return;
    }

    const { candidateId, organizationId, isInternalDocument } = this.form.getRawValue();
    const requirement$ = this.documentRequirementService.getByType({ type, organizationId });
    const access$ = this.documentService.getEffectiveDocumentAccess({ organizationId, candidateId, type });

    forkJoin([requirement$, access$]).subscribe(([requirement, access]) => {
      const writeMode = getWriteMode(access, { isInternalDocument, isInternalByRequirement: requirement.isInternal });
      const changeType = (): void => {
        this.documentType = type;
        this.form.controls.type.setValue(type);
        this.form.controls.writeMode.setValue(writeMode);
        this.form.controls.requirement.setValue(requirement);
        this.form.controls.isInternalByRequirement.setValue(requirement.isInternal);
        this.updateDocumentSetRestrictions();
        this.updateForeignKeyData();
        this.fixupDocumentSets();
        this.fixupFreeFormatFiles();
        this.updateCanAddDocumentSet();
        updateValueAndValidity(this.form);
      };

      const reset = (): void => {
        setTimeout(() => this.form.controls.type.setValue(this.documentType), 0);
      };

      const resetAndShowWarning = (message: string): void => {
        reset();
        this.confirmationService.confirm({
          target: target,
          rejectVisible: false,
          acceptLabel: translate("common.ok"),
          message: message,
          icon: PrimeIcons.EXCLAMATION_TRIANGLE,
          close: () => noop(),
        } as UpdatedConfirmation);
      };

      const document = this.form.getRawValue();
      const files = getFiles(document);
      const freeFormatFiles = getFreeFormatFiles(document);
      if (files.length === 0 && freeFormatFiles.length === 0) {
        changeType();
        return;
      }

      if (this.form.controls.writeMode.value !== writeMode) {
        resetAndShowWarning(translate("documentType.warningWriteMode"));
        return;
      }

      if (this.isSingleDocumentSet != isSingleDocumentSet(type)) {
        resetAndShowWarning(translate("documentType.warningSingleSet"));
        return;
      }

      if (hasDeclaredDocumentSetTypes(type) || hasDeclaredDocumentSetTypes(this.documentType)) {
        resetAndShowWarning(translate("documentType.warningSetTypes"));
        return;
      }

      if (getSets(document).length > getMaxDocumentSetCount(type)) {
        resetAndShowWarning(translate("documentType.warningSetCount"));
        return;
      }

      changeType();
    });
  }

  protected createSetForm(name: string, isDefaultSet: boolean, foreignKey?: string): DocumentSetForm {
    const builder = this.createDocumentFormBuilder(this.form.getRawValue());
    return builder.createEmptySetForm({ isDefaultSet, original: false, name, foreignKey });
  }

  protected addDocumentSetForm(form: DocumentSetForm): void {
    const { isDefaultSet, name, foreignKey } = form.value;
    this.addDocumentSet(isDefaultSet, name, foreignKey);
  }

  protected addDocumentSet(isDefaultSet: boolean, name: string, foreignKey?: string): void {
    if (isDefaultSet) {
      this.form.controls.documentSets.controls.forEach((documentSet) => {
        const isDefaultSetControl = documentSet.controls.isDefaultSet;
        if (!documentSet.controls.foreignKey.value && isDefaultSetControl.value) {
          this.postfixSetNameWithDate(documentSet);
        }
        isDefaultSetControl.setValue(false);
      });
    }

    const builder = this.createDocumentFormBuilder(this.form.getRawValue());
    const newName = isDefaultSet ? (foreignKey ? name : DefaultDocumentSetName) : name;
    this.form.controls.documentSets.push(
      builder.createEmptySetForm({ name: newName, original: false, isDefaultSet, foreignKey })
    );
    this.form.controls.documentSets.markAsDirty();

    this.updateDocumentSetOrder();
    this.updateCanAddDocumentSet();
    updateValueAndValidity(this.form);
    this.changeDetector.detectChanges();
  }

  protected removeDocumentSet(index: number): void {
    this.form.controls.documentSets.removeAt(index);
    this.form.controls.documentSets.markAsDirty();
    this.updateCanAddDocumentSet();
    updateValueAndValidity(this.form);
    this.changeDetector.detectChanges();
  }

  protected makeDefaultSet(selectedIndex: number): void {
    this.form.controls.documentSets.controls.forEach((documentSet, index) => {
      const makeDefaultSet = index === selectedIndex;
      const isDefaultSetControl = documentSet.controls.isDefaultSet;
      if (!this.hasDeclaredDocumentSetTypes) {
        const foreignKeyControl = documentSet.controls.foreignKey;
        if (!foreignKeyControl.value && isDefaultSetControl.value) {
          this.postfixSetNameWithDate(documentSet);
        }

        if (!foreignKeyControl.value && makeDefaultSet) {
          documentSet.controls.name.setValue(DefaultDocumentSetName);
        }
      }
      isDefaultSetControl.setValue(makeDefaultSet);
    });
    this.form.controls.documentSets.markAsDirty();
    updateValueAndValidity(this.form);
  }

  protected isSetNameValid(name: string): boolean {
    return (
      name?.trim().length > 0 &&
      this.form.controls.documentSets.controls.every((s) => s.controls.name?.value?.trim() != name?.trim())
    );
  }

  protected takeoverDocumentFormats(form: DocumentSetForm): void {
    const tags = form.controls.files.controls.flatMap((fileForm) => fileForm.controls.tags.value ?? []);
    const physicalTypesForm = form.controls.physicalTypes;
    const physicalTypes = uniq([...(physicalTypesForm.value ?? []), ...tags]);
    physicalTypesForm.setValue(physicalTypes);
    physicalTypesForm.markAsDirty();
  }

  protected isSelectedField(form: DocumentSetForm, field: DocumentSetField): boolean {
    return this.selectedField?.field === field && this.isSelectedSet(form);
  }

  protected isSelectedSet(form: DocumentSetForm): boolean {
    return isSelectedSet(form.getRawValue(), this.selectedField?.selectedSet);
  }

  protected getAutoCompleteLabel(form: DocumentSetForm): string {
    const foreignKeyData = this.getForeignKeyData(form);
    const label = foreignKeyData ? foreignKeyData.label : form.controls.name.value;
    return label ?? "";
  }

  protected getForeignKeyData(form: DocumentSetForm): ForeignKeyData {
    return this.foreignKeyData.find((entry) => entry.id === form.controls.foreignKey.value);
  }

  protected cloneForeignKeyData(): void {
    this.foreignKeyData = clone(this.foreignKeyData);
  }

  protected writeSelectableForeignKeyData(): void {
    const sets = this.form.controls.documentSets.controls;
    this.selectableForeignKeyData = clone(this.foreignKeyData).filter(
      (entry) => !sets.some((set) => set.controls.foreignKey.value === entry.id)
    );
  }

  protected updateDocumentSetName(form: DocumentSetForm, value: string | ForeignKeyData): void {
    const nameControl = form.controls.name;
    const foreignKeyControl = form.controls.foreignKey;
    const isString = typeof value === "string";
    const { name, foreignKey } = {
      name: isString ? value : value.label,
      foreignKey: isString ? null : value.id,
    };

    nameControl.setValue(name);
    nameControl.markAsDirty();

    foreignKeyControl.setValue(foreignKey);
    foreignKeyControl.markAsDirty();
  }

  protected getRequestedLink(): ForeignKeyData {
    const foreignKey = getForeignKey(this.selectedField);
    const sets = this.form.controls.documentSets.controls;
    return foreignKey && !sets.some((set) => set.controls.foreignKey.value === foreignKey)
      ? this.foreignKeyData.find((entry) => entry.id === foreignKey)
      : null;
  }

  private updateDocumentThings(): void {
    if (!this.form) {
      return;
    }

    this.documentType = this.form.controls.type.value;
    if (this.mode === DocumentMode.Candidate) {
      this.updateDocumentSetRestrictions();
      this.updateForeignKeyData();
      this.updateCanAddDocumentSet();
      this.updateDocumentSetSynchronization();
      updateValueAndValidity(this.form);
    }
  }

  protected clearResubmissionDate(form: DocumentSetForm): void {
    if (!this.form.controls.requirement.value.resubmissionReason) {
      form.controls.resubmissionReason.setValue(null);
    }
  }

  private updateDocumentSetRestrictions(): void {
    this.isSingleDocumentSet = isSingleDocumentSet(this.documentType);
    this.hasDeclaredDocumentSetTypes = hasDeclaredDocumentSetTypes(this.documentType);
    this.maxDocumentSets = getMaxDocumentSetCount(this.documentType);
  }

  private updateForeignKeyData(): void {
    const document = this.form.getRawValue();
    const candidate = isCandidateForm(this.candidateFormOrCandidate)
      ? extractCandidateFromForm(this.candidateFormOrCandidate)
      : this.candidateFormOrCandidate;

    this.documentMetadataService
      .getMetadata(document, candidate, this.additionalForeignKeySourceData)
      .subscribe((data) => {
        const foreignKeys = getSets(document)
          .map((set) => set.foreignKey)
          .filter((key) => !isNil(key));

        this.foreignKeyData = data.filter((entry) => foreignKeys.includes(entry.id) || !entry.isSupressed);
        this.updateDocumentSetOrder();
      });
  }

  private updateDocumentSetOrder(): void {
    const documentSetsForm = this.form.controls.documentSets;
    const foreignDataMap = documentSetsForm.controls.map((set) => {
      return { set, data: this.getForeignKeyData(set) };
    });

    foreignDataMap.forEach(({ set, data }) => {
      const nameControl = set.controls.name;
      if (data && !nameControl.value) {
        nameControl.setValue(data.label);
      }
    });

    const orderedSets = orderBy(foreignDataMap, ({ data }) => data?.index ?? -1).map(({ set }) => set);
    documentSetsForm.clear();
    orderedSets.forEach((set) => documentSetsForm.push(set));
  }

  private updateCanAddDocumentSet(): void {
    this.canAddDocumentSet =
      !this.readonly &&
      !this.settings.readonlyProfile &&
      !this.hasDeclaredDocumentSetTypes &&
      this.form.controls.documentSets.controls.length < this.maxDocumentSets;
  }

  private updateDocumentSetSynchronization(): void {
    const sets = this.form.controls.documentSets;
    this.dateChangeSubscription?.unsubscribe();
    this.dateChangeSubscription = valuesOf(this.form.controls.type)
      .pipe(
        map((type) => getSynchronizedSetFields(type)),
        filter((syncedFields) => !isEmpty(syncedFields)),
        switchMap((syncedFields) =>
          valuesOf(sets).pipe(
            switchMap(() => merge(...sets.controls.flatMap((set) => this.getDateFieldChanges(set, syncedFields))))
          )
        ),
        filter(({ date }) => !isNil(date))
      )
      .subscribe(({ field, date }) =>
        sets.controls.forEach((set) => set.controls[field].setValue(date, { emitEvent: false }))
      );
  }

  private getDateFieldChanges(
    set: DocumentSetForm,
    syncedFields: DocumentSetDateField[]
  ): Observable<{ field: DocumentSetDateField; date: Date }>[] {
    return syncedFields.map((field) => {
      const dateControl = set.controls[field];
      return dateControl.valueChanges.pipe(map((date) => ({ field, date })));
    });
  }

  private fixupDocumentSets(): void {
    const documentSets = this.form.controls.documentSets;
    documentSets.controls
      .map((_, index) => index)
      .reverse()
      .filter((index) => index >= this.maxDocumentSets)
      .forEach((index) => documentSets.removeAt(index));

    if (this.form.controls.writeMode.value != DocumentWriteMode.Full) {
      documentSets.controls.forEach((documentSet) => {
        documentSet.controls.files.clear();
      });
    }

    if (!documentSets.controls.some((documentSet) => documentSet.controls.isDefaultSet.value)) {
      this.makeDefaultSet(0);
    }

    const documentSetTypes = declaredDocumentSetTypes(this.documentType);
    if (!documentSetTypes) {
      documentSets.controls.forEach((documentSetControl) => {
        documentSetControl.controls.type.setValue(null);
      });
    } else {
      const builder = this.createDocumentFormBuilder(this.form.getRawValue());
      documentSetTypes.forEach((type, index) => {
        const documentSetControl = documentSets.controls[index];
        if (documentSetControl) {
          documentSetControl.controls.name.setValue(null);
          documentSetControl.controls.foreignKey.setValue(null);
          documentSetControl.controls.type.setValue(type);
        } else {
          documentSets.push(builder.createEmptySetForm({ isDefaultSet: false, original: true, type }));
          this.form.controls.documentSets.markAsDirty();
        }
      });
    }
  }

  private fixupFreeFormatFiles(): void {
    if (this.form.controls.writeMode.value !== DocumentWriteMode.Full) {
      this.form.controls.freeFormatFiles.clear();
    }
  }

  private createDocumentFormBuilder(document: Document): DocumentFormBuilder {
    return this.documentFormService.createDocumentFormBuilder(document, {
      documentMode: this.mode,
      isReadonly: this.readonly,
    });
  }

  private postfixSetNameWithDate(documentSet: DocumentSetForm): void {
    const date = this.form.controls.changedAt.value ?? Date.now();
    const nameControl = documentSet.controls.name;
    const lastChange = this.dateTimePipe.transform(date, { dateStyle: "short", timeStyle: "short" });
    nameControl.setValue(`${DefaultDocumentSetName} (${translate("until.title")} ${lastChange})`);
  }
}

function isCandidateForm(formOrCandidate: AnyCandidateForm | Candidate): formOrCandidate is AnyCandidateForm {
  return typeof (formOrCandidate as AnyCandidateForm)?.getRawValue === "function";
}
