<ng-container *appTranslate="let t">
  <form [formGroup]="form" (ngSubmit)="save()">
    <p-card [header]="form.controls.id.value ? t('talentPoolSharing.edit') : t('talentPoolSharing.create')">
      <div class="p-fluid formgrid">
        <div class="field">
          <label for="type">{{ t("talentPool.title") }}</label>
          <p-dropdown
            inputId="type"
            formControlName="talentPoolId"
            [options]="pools$ | async"
            filterBy="name"
            optionValue="id"
            [placeholder]="t('talentPool.placeholder')"
          >
            <ng-template *ngFor="let template of ['item', 'selectedItem']" let-pool [pTemplate]="template">
              <span
                >{{ pool.localizedName }} ({{ pool.organizationName }}) ({{ pool.type | enum: "TalentPoolType" }})</span
              >
            </ng-template>
          </p-dropdown>

          <small class="p-error" *ngIf="form.controls.talentPoolId.errors?.required">
            {{ t("talentPool.required") }}
          </small>
        </div>

        <div class="field">
          <label for="expiryDate">{{ t("sharing.expiryDate") }}</label>
          <p-calendar inputId="expiryDate" formControlName="expiryDate" [minDate]="tomorrow"></p-calendar>
        </div>

        <div class="field-checkbox">
          <p-checkbox
            inputId="enabled"
            formControlName="enabled"
            [binary]="true"
            [label]="t('sharing.enabled')"
          ></p-checkbox>
        </div>

        <div class="field-checkbox">
          <p-checkbox
            inputId="useCv"
            formControlName="useCv"
            [binary]="true"
            [label]="t('talentPoolSharing.useCv')"
          ></p-checkbox>
        </div>

        <div class="formgroup-inline">
          <div class="field-radiobutton">
            <p-radioButton
              name="cvKind"
              inputId="cvKindStandard"
              formControlName="cvKind"
              [value]="TalentPoolSharingCvKind.Standard"
            ></p-radioButton>
            <label for="cvKindStandard">{{ t("enum.TalentPoolSharingCvKind.Standard") }}</label>
          </div>

          <div class="field-radiobutton">
            <p-radioButton
              name="cvKind"
              inputId="cvKindTalentPool"
              formControlName="cvKind"
              [value]="TalentPoolSharingCvKind.TalentPool"
            ></p-radioButton>
            <label for="cvKindTalentPool">{{ t("enum.TalentPoolSharingCvKind.TalentPool") }}</label>
          </div>
        </div>
      </div>

      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
          <p-button
            [disabled]="!form.valid || !form.dirty"
            [label]="t('common.save')"
            icon="pi pi-check"
            type="submit"
            class="mr-2"
          ></p-button>

          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            type="reset"
            (onClick)="close()"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  </form>
</ng-container>
