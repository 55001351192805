<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <label for="chamberOfCraft">{{ t("chamberOfCrafts.title") }}</label>
  <p-dropdown
    inputId="chamberOfCraft"
    [options]="chamberOfCrafts"
    [formControl]="control"
    [showClear]="true"
    [placeholder]="t('chamberOfCrafts.placeholder')"
    [readonly]="readonly"
  ></p-dropdown>

  <small class="p-error" *ngIf="control.errors?.required">
    {{ t("chamberOfCrafts.required") }}
  </small>
</ng-container>
