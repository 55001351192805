import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { SwitchableOrganizationFragment } from "@ankaadia/graphql";
import { FilterService } from "primeng/api";
import { Dropdown } from "primeng/dropdown";
import { Subject, debounceTime } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";

@Component({
  selector: "app-organization-switch",
  templateUrl: "./organization-switch.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OrganizationSwitchComponent implements OnInit {
  readonly isCandidate = this.settings.isCandidate;
  readonly switchableOrganizationCount = this.settings.switchableOrganizationCount;
  readonly maxOrganizationsBeforeShowingFilter = 10;
  readonly filter = OrganizationSwitchComponent.name;
  readonly filteredSub = new Subject<string>();

  @Input()
  organizations: SwitchableOrganizationFragment[];

  @Input()
  overlayOnly?: boolean;

  @Output()
  readonly filtered = new EventEmitter<string>();

  @Output()
  readonly switched = new EventEmitter<string>();

  @ViewChild(Dropdown)
  dropdown: Dropdown;

  constructor(
    private readonly settings: SettingsService,
    private readonly filterService: FilterService
  ) {}

  ngOnInit(): void {
    // always enabled because the organizations are filtered on the backend
    this.filterService.register(this.filter, () => true);

    this.filteredSub.pipe(debounceTime(100)).subscribe((x) => this.filtered.emit(x));
  }

  show(): void {
    this.dropdown.show();
  }
}
