import { Injectable } from "@angular/core";
import {
  CreateTalentPoolSharingGQL,
  DeleteTalentPoolSharingGQL,
  EditTalentPoolSharingGQL,
  ListTalentPoolSharingsGQL,
  TalentPoolSharingCreateInput,
  TalentPoolSharingDeleteInput,
  TalentPoolSharingForEditFragment,
  TalentPoolSharingForListFragment,
  TalentPoolSharingForListFragmentDoc,
  TalentPoolSharingUpdateInput,
  UpdateTalentPoolSharingGQL,
} from "@ankaadia/graphql";
import { omit, pick } from "lodash";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class TalentPoolSharingService {
  constructor(
    private readonly sharingList: ListTalentPoolSharingsGQL,
    private readonly sharingEdit: EditTalentPoolSharingGQL,
    private readonly sharingCreate: CreateTalentPoolSharingGQL,
    private readonly sharingUpdate: UpdateTalentPoolSharingGQL,
    private readonly sharingDelete: DeleteTalentPoolSharingGQL
  ) {}

  list(collectionId: string, organizationId: string): Observable<TalentPoolSharingForListFragment[]> {
    return this.sharingList
      .watch({ input: { collectionId, organizationId } })
      .valueChanges.pipe(map((x) => x.data.getTalentPoolSharings));
  }

  edit(id: string, organizationId: string): Observable<TalentPoolSharingForEditFragment> {
    return this.sharingEdit.fetch({ input: { id, organizationId } }).pipe(map((x) => x.data.getTalentPoolSharing));
  }

  create(pool: TalentPoolSharingCreateInput): Observable<TalentPoolSharingForListFragment> {
    return this.sharingCreate
      .mutate(
        { input: omit(pool, "id", "_etag", "changedAt", "changedBy") },
        {
          update: (cache, x) =>
            cache.modify({
              fields: {
                getTalentPoolSharings: (refs) => {
                  const ref = cache.writeFragment({
                    data: x.data.createTalentPoolSharing,
                    fragment: TalentPoolSharingForListFragmentDoc,
                  });
                  return [...(refs ?? []), ref];
                },
              },
            }),
        }
      )
      .pipe(map((x) => x.data.createTalentPoolSharing));
  }

  update(pool: TalentPoolSharingUpdateInput): Observable<TalentPoolSharingForListFragment> {
    return this.sharingUpdate
      .mutate({ input: omit(pool, "changedAt", "changedBy") })
      .pipe(map((x) => x.data.updateTalentPoolSharing));
  }

  delete(pool: TalentPoolSharingDeleteInput): Observable<void> {
    return this.sharingDelete
      .mutate(
        { input: pick(pool, "id", "_etag", "organizationId") },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getTalentPoolSharings: (refs, helper) => {
                  return refs.filter((ref) => helper.readField("id", ref) !== pool.id);
                },
              },
            }),
        }
      )
      .pipe(map((x) => x.data.deleteTalentPoolSharing));
  }
}
