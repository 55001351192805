import { Component, DestroyRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormArray } from "@angular/forms";
import {
  buildLaborAgreementData,
  createMergedContract,
  createRemunerationGroup,
  getAllowanceKey,
  getBenefitKey,
  IContractTemplate,
  isTechnicallyEmpty,
  LaborContractDetails,
  nameofFactory,
} from "@ankaadia/ankaadia-shared";
import {
  CompensationType,
  ContractTemplate,
  ContractTemplateForOverviewFragment,
  ContractTemplateSetInput,
  ContractTermTimePeriod,
  EmploymentRelationshipType,
  LaborAgreement,
  StaticDataModel,
  StaticDataType,
  WorkingTimeTypeEnum,
} from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { clone, isNil, keys } from "lodash";
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  Observable,
  pairwise,
  shareReplay,
  startWith,
} from "rxjs";
import { valuesOf } from "../../../shared/services/form.helper";
import { EnumType, StaticDataService } from "../../../shared/static-data/static-data.service";
import { LaborAgreementService } from "../../labor-agreements/labor-agreement.service";
import {
  Override,
  OverrideIntersectionKey,
  OverrideLabelOptions,
  OverrideService,
} from "../contract-template-override.service";
import { purgeUnchangedFields } from "./contract-template-dialog.functions";
import { ContractTemplateForm, FormContractTemplate } from "./contract-template-form.model";
import { ContractTemplateFormService } from "./contract-template-form.service";

type OverrideKey = OverrideIntersectionKey<IContractTemplate, FormContractTemplate>;
type OverrideModel<TKey extends OverrideKey> = Override<IContractTemplate, FormContractTemplate, TKey>;
type Options<TKey extends OverrideKey> = OverrideLabelOptions<IContractTemplate, FormContractTemplate, TKey>;
type LaborAgreementChoice = Required<
  Pick<ContractTemplate, "contractType" | "laborAgreementSelection" | "customLaborAgreement">
>;
const nameof = nameofFactory<ContractTemplateDialogComponent>();

@Component({
  selector: "app-contract-template-dialog",
  templateUrl: "./contract-template-dialog.component.html",
  styleUrl: "./contract-template-dialog.component.scss",
  standalone: false,
})
export class ContractTemplateDialogComponent implements OnChanges {
  private readonly language = this.transloco.getActiveLang();

  protected readonly workingTimeTypes = this.toStaticData("WorkingTimeTypeEnum", WorkingTimeTypeEnum);
  protected readonly StaticDataType = StaticDataType;

  protected readonly employmentRelationshipTypes = this.toStaticData(
    "EmploymentRelationshipType",
    EmploymentRelationshipType
  );

  protected readonly compensationTypes = this.staticData.transformEnumToStaticDataModel(
    "ShortCompensationType",
    CompensationType
  );

  protected readonly timePeriods = this.staticData.transformEnumToStaticDataModel(
    "ContractTermTimePeriod",
    ContractTermTimePeriod
  );

  protected readonly benefits$ = this.staticData
    .getStaticData(StaticDataType.Benefits, this.language)
    .pipe(shareReplay(1));

  protected readonly workSchedules$ = this.staticData
    .getStaticData(StaticDataType.LaborContractWorkSchedule, this.language)
    .pipe(shareReplay(1));

  protected form: ContractTemplateForm = null;
  protected contractTypes: StaticDataModel[] = [];
  protected unavailableNames: string[];
  protected laborAgreements?: LaborAgreement[];
  protected contractTemplateData: Partial<ContractTemplate>;

  @Input({ required: true })
  isEdit: boolean;

  @Input({ required: true })
  template: Partial<ContractTemplate>;

  @Input()
  allContractTemplates: ContractTemplateForOverviewFragment[] = [];

  @Input()
  hideName = false;

  @Output()
  readonly saved = new EventEmitter<ContractTemplateSetInput>();

  @Output()
  readonly closed = new EventEmitter<void>();

  protected get isForAustria(): boolean {
    return this.template?.country === "AT";
  }

  protected get filteredLaborAgreements(): LaborAgreement[] {
    return this.laborAgreements.filter(({ country, type }) => {
      return country === this.form.controls.country.value && type === this.form.controls.contractType.value;
    });
  }

  protected get laborAgreementId(): string | null {
    return this.form.controls.laborAgreementSelection.controls.laborAgreementId.value ?? null;
  }

  protected get laborAgreement(): LaborAgreement | null {
    return this.laborAgreements.find(({ id }) => id === this.laborAgreementId) ?? null;
  }

  protected get payGrades(): StaticDataModel[] {
    const payGrades = this.laborAgreement?.payGrades ?? [];
    return payGrades.map((payGrade) => ({ value: payGrade.id, label: createRemunerationGroup(payGrade) }));
  }

  protected get userShouldCheckCompensation(): boolean {
    const { workingTimeType, compensationType, compensationRate } = this.form.getRawValue();
    const isNotFullTimeEmployment = !isNil(workingTimeType) && workingTimeType !== WorkingTimeTypeEnum.FullTime;
    const isMonthlyCompensation = compensationType === CompensationType.PerMonth;
    const hasCompensationRate = compensationRate > 0;
    return isNotFullTimeEmployment && isMonthlyCompensation && hasCompensationRate;
  }

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly staticData: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly formService: ContractTemplateFormService,
    private readonly laborAgreementService: LaborAgreementService,
    private readonly overrideService: OverrideService<IContractTemplate, FormContractTemplate>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes[nameof("template")]) {
      const { organizationId } = this.template;

      const template = { ...this.template, unavailableNames: this.unavailableNames };
      this.form = this.formService.createForm(template, this.hideName);

      this.laborAgreementService
        .getAll({ organizationId: this.template.organizationId })
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          mergeMap((laborAgreements) =>
            this.laborAgreementChoice().pipe(map((choice) => ({ laborAgreements, choice })))
          ),
          pairwise()
        )
        .subscribe(([previous, current]) => {
          this.laborAgreements = current.laborAgreements;
          this.populateForm(previous.choice, current.choice);
        });

      const immigrationCountry = this.form.controls.country.value;
      const context = { immigrationCountry, organizationId };
      this.staticData
        .getStaticData(StaticDataType.LaborContractType, this.language, context)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((contractTypes) => (this.contractTypes = contractTypes));
    }

    if (changes[nameof("allContractTemplates")]) {
      const unavailableNames = this.allContractTemplates
        .filter(({ id }) => id !== this.template.id)
        .map(({ name }) => name);

      if (!isNil(this.form)) {
        this.form.controls.unavailableNames.setValue(unavailableNames);
        this.form.controls.name.updateValueAndValidity();
      }
    }
  }

  private laborAgreementChoice(): Observable<LaborAgreementChoice> {
    const { contractType, laborAgreementSelection, customLaborAgreement } = this.form.controls;
    const buildSelection = (): LaborAgreementChoice => ({
      contractType: contractType.getRawValue(),
      laborAgreementSelection: laborAgreementSelection.getRawValue(),
      customLaborAgreement: customLaborAgreement.getRawValue(),
    });

    const { organizationId, laborAgreementId, payGradeId } = laborAgreementSelection.controls;
    const contractType$ = valuesOf(contractType).pipe(distinctUntilChanged());
    const organizationId$ = valuesOf(organizationId).pipe(distinctUntilChanged());
    const laborAgreementId$ = valuesOf(laborAgreementId).pipe(distinctUntilChanged());
    const payGradeId$ = valuesOf(payGradeId).pipe(distinctUntilChanged());
    return combineLatest([contractType$, organizationId$, laborAgreementId$, payGradeId$]).pipe(
      startWith(buildSelection()),
      map(() => buildSelection()),
      filter(({ contractType, laborAgreementSelection, customLaborAgreement }) => {
        const laborAgreementForbidden = isNil(contractType) || contractType === "AgreementByContract";
        return laborAgreementForbidden
          ? isTechnicallyEmpty(laborAgreementSelection) && isTechnicallyEmpty(customLaborAgreement)
          : true;
      })
    );
  }

  protected updateLaborAgreement(selection: string | LaborAgreement): void {
    const { laborAgreementSelection, customLaborAgreement } = this.form.controls;
    if (typeof selection === "string") {
      const { salaryGroup } = customLaborAgreement.value;
      customLaborAgreement.setValue({ collectiveAgreement: selection, salaryGroup: salaryGroup ?? null });
      customLaborAgreement.markAsDirty();
      laborAgreementSelection.reset();
      laborAgreementSelection.markAsDirty();
      return;
    }

    const { payGradeId } = laborAgreementSelection.value;
    const laborAgreement = this.laborAgreements.find(({ id }) => id === selection.id);
    if (isNil(laborAgreement)) {
      laborAgreementSelection.setValue(null);
      return;
    }

    const payGrades = laborAgreement.payGrades ?? [];
    const payGrade = payGrades.find((payGrade) => payGrade.id === payGradeId);
    const { id: laborAgreementId, organizationId } = laborAgreement;

    laborAgreementSelection.setValue({ laborAgreementId, organizationId, payGradeId: payGrade?.id ?? null });
    laborAgreementSelection.markAsDirty();
    customLaborAgreement.reset();
    customLaborAgreement.markAsDirty();
  }

  protected getAutoCompleteLabel(): string {
    const { laborAgreementSelection, customLaborAgreement } = this.form.getRawValue();
    if (isNil(laborAgreementSelection?.laborAgreementId)) {
      return customLaborAgreement?.collectiveAgreement ?? "";
    }

    const agreement = this.laborAgreements.find(({ id }) => id === laborAgreementSelection?.laborAgreementId);
    return agreement?.name ?? "";
  }

  protected cloneLaborAgreements(): void {
    this.laborAgreements = clone(this.laborAgreements);
  }

  protected onSubmit(): void {
    const contractTemplate = this.form.getRawValue();
    const input = purgeUnchangedFields(contractTemplate, this.laborAgreements);
    this.saved.emit(input);
  }

  private populateForm(previousChoice: LaborAgreementChoice, currentChoice: LaborAgreementChoice): void {
    const contractTemplate = this.form.getRawValue();
    const previousContractTemplate = {
      ...contractTemplate,
      contractType: previousChoice.contractType,
      laborAgreementSelection: previousChoice.laborAgreementSelection,
      customLaborAgreement: previousChoice.customLaborAgreement,
    };

    const previousLaborAgreement = buildLaborAgreementData(previousContractTemplate, this.laborAgreements);
    const currentLaborAgreement = buildLaborAgreementData(contractTemplate, this.laborAgreements);
    const missingAllowanceKeys = this.getMissingAllowanceKeys(previousLaborAgreement, currentLaborAgreement);
    const missingBenefitKeys = this.getMissingBenefitKeys(previousLaborAgreement, currentLaborAgreement);

    const cleanedContractTemplate = purgeUnchangedFields(previousContractTemplate, this.laborAgreements);
    const currentContractTemplate = {
      ...cleanedContractTemplate,
      contractType: currentChoice.contractType,
      laborAgreementSelection: currentChoice.laborAgreementSelection,
      customLaborAgreement: currentChoice.customLaborAgreement,
      allowances: [
        ...(cleanedContractTemplate.allowances ?? []).filter((allowance) => {
          return !missingAllowanceKeys.includes(getAllowanceKey(allowance));
        }),
        ...(previousContractTemplate.allowances ?? [])
          .filter((allowance) => missingAllowanceKeys.includes(getAllowanceKey(allowance)))
          .map(({ isOverride: _1, isIgnored: _2, ...rest }) => rest),
      ],
      benefits: [
        ...(cleanedContractTemplate.benefits ?? []).filter((benefit) => {
          return !missingBenefitKeys.includes(getBenefitKey(benefit));
        }),
        ...(previousContractTemplate.benefits ?? [])
          .filter((benefit) => missingBenefitKeys.includes(getBenefitKey(benefit)))
          .map(({ isOverride: _1, isIgnored: _2, ...rest }) => rest),
      ],
    };

    const formModel = createMergedContract(currentContractTemplate, this.laborAgreements);
    this.form.controls.allowances.clear();
    formModel.allowances.forEach((allowance) => {
      this.form.controls.allowances.push(this.formService.createAllowanceForm(allowance));
    });

    this.form.controls.benefits.clear();
    formModel.benefits.forEach((benefit) => {
      this.form.controls.benefits.push(this.formService.createBenefitForm(benefit));
    });

    for (const key of keys(this.form.controls)) {
      const control = this.form.controls[key];
      if (isNil(control)) {
        continue;
      }

      if (control instanceof FormArray) {
        continue;
      }

      const value = formModel[key];
      if (!isNil(value)) {
        control.setValue(value);
      } else {
        control.reset();
      }
    }
  }

  private getMissingBenefitKeys(
    previousLaborAgreement?: LaborContractDetails,
    currentLaborAgreement?: LaborContractDetails
  ): string[] {
    const previousBenefitKeys = (previousLaborAgreement?.benefits ?? []).map(getBenefitKey);
    const currentBenefitKeys = (currentLaborAgreement?.benefits ?? []).map(getBenefitKey);
    const missingBenefitKeys = previousBenefitKeys.filter((key) => !currentBenefitKeys.includes(key));
    return missingBenefitKeys;
  }

  private getMissingAllowanceKeys(
    previousLaborAgreement: LaborContractDetails,
    currentLaborAgreement: LaborContractDetails
  ): string[] {
    const previousAllowanceKeys = (previousLaborAgreement?.allowances ?? []).map(getAllowanceKey);
    const currentAllowanceKeys = (currentLaborAgreement?.allowances ?? []).map(getAllowanceKey);
    const missingAllowanceKeys = previousAllowanceKeys.filter((key) => !currentAllowanceKeys.includes(key));
    return missingAllowanceKeys;
  }

  protected getOverride<TKey extends OverrideKey>(key: TKey): OverrideModel<TKey> {
    const contractTemplate = this.form.getRawValue();
    const laborAgreement = buildLaborAgreementData(contractTemplate, this.laborAgreements);
    return this.overrideService.getOverride(laborAgreement, this.form.getRawValue(), key);
  }

  protected getOverrideLabel<TKey extends OverrideKey>(override: OverrideModel<TKey>, options?: Options<TKey>): string {
    return this.overrideService.getOverrideLabel(override, options);
  }

  private toStaticData(enumName: string, enumType: EnumType): StaticDataModel[] {
    return this.staticData.transformEnumToStaticDataModel(enumName, enumType);
  }
}
