import { Injectable } from "@angular/core";
import {
  CollectionAutoFilter,
  CollectionAutoFilterGetInput,
  CollectionAutoFilterSetInput,
  GetCollectionAutoFilterGQL,
  SetCollectionAutoFilterGQL,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class CollectionAutoFilterService {
  constructor(
    private readonly getGql: GetCollectionAutoFilterGQL,
    private readonly setGql: SetCollectionAutoFilterGQL
  ) {}

  get(input: CollectionAutoFilterGetInput): Observable<CollectionAutoFilter> {
    return this.getGql.fetch({ input: input }).pipe(map((result) => result.data.getCollectionAutoFilter));
  }

  set(input: CollectionAutoFilterSetInput): Observable<CollectionAutoFilter> {
    return this.setGql.mutate({ input: input }).pipe(map((result) => result.data.setCollectionAutoFilter));
  }
}
