import { Component, Input } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { StaticDataType } from "@ankaadia/graphql";

@Component({
  selector: "app-candidate-profession",
  templateUrl: "./candidate-profession.component.html",
  standalone: false,
})
export class CandidateProfessionComponent {
  readonly StaticDataType = StaticDataType;
  readonly Validators = Validators;

  @Input()
  control: FormControl;

  @Input()
  readonly: boolean;

  @Input()
  candidateOrganizationId: string;
}
