<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <label for="fingerPrintDate">{{ t("fingerPrintDate.title") }}</label>
  <p-calendar
    inputId="fingerPrintDate"
    [formControl]="control"
    [showClear]="showClear"
    [readonly]="readonly"
  ></p-calendar>

  <small class="p-error" *ngIf="control.errors?.required">
    {{ t("fingerPrintDate.required") }}
  </small>
</ng-container>
