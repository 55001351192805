import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { OrganizationCrmDataFragment, OrganizationCrmSetInput } from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { FormElementGroup } from "ngx-mf";
import { catchError, map, of, switchMap } from "rxjs";
import { GraphQLFormModel } from "../../../shared/services/form.helper";
import { SettingsService } from "../../../shared/services/settings.service";
import { MessageService } from "../../message/message.service";
import { OrganizationCrmService } from "../../organization-crm/organization-crm.service";
import { OrganizationsService } from "../organizations.service";

export type OrganizationCrmForm = GraphQLFormModel<OrganizationCrmDataFragment, { note: FormElementGroup }>;

@Component({
  selector: "app-organization-crm",
  templateUrl: "./organization-crm.component.html",
  standalone: false,
})
export class OrganizationCrmComponent implements OnInit {
  constructor(
    private readonly settingsService: SettingsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly organizationService: OrganizationsService,
    private readonly organizationCRMService: OrganizationCrmService,
    private readonly translationService: TranslocoService,
    private readonly formBuilder: FormBuilder,
    private readonly messageService: MessageService,
    private readonly router: Router
  ) {}

  readonly language = this.translationService.getActiveLang();

  get myOrganizationId(): string {
    return this.settingsService.organizationId;
  }

  protected form: OrganizationCrmForm;

  targetOrganizationId$ = this.activatedRoute.paramMap.pipe(map((x) => x.get("orgId")));

  targetOrganizationName$ = this.targetOrganizationId$.pipe(
    switchMap((targetOrgId) => this.organizationService.getOrganization(targetOrgId)),
    map((x) => x.name),
    catchError((x) => {
      this.settingsService.navigateToNotFound();
      return of(x);
    })
  );

  ngOnInit(): void {
    this.targetOrganizationId$
      .pipe(
        switchMap((targetOrgId) =>
          this.organizationCRMService.getOrganizationCrmData(this.myOrganizationId, targetOrgId)
        )
      )
      .subscribe((data) => {
        this.form = this.initForm(data);
      });
  }

  save(): void {
    const update = Object.assign(new OrganizationCrmSetInput(), this.form.value);

    const note = update.note;
    if (!note.contents) {
      update.note = null;
    }

    this.organizationCRMService.setOrganizationCrmData(update).subscribe({
      next: () => {
        this.messageService.add({ severity: "success", summary: translate("crm.saved") });
        void this.router.navigate(["/app/settings/organizations", this.myOrganizationId]);
      },
    });
  }

  private initForm(initData: OrganizationCrmDataFragment): OrganizationCrmForm {
    return this.formBuilder.group<OrganizationCrmForm["controls"]>({
      id: this.formBuilder.control(initData.id),
      _etag: this.formBuilder.control(initData._etag),
      organizationId: this.formBuilder.control(initData.organizationId),
      destinationOrganizationId: this.formBuilder.control(initData.destinationOrganizationId),
      note: this.formBuilder.group<OrganizationCrmForm["controls"]["note"]["controls"]>({
        contents: this.formBuilder.control(initData.note?.contents),
        delta: this.formBuilder.control(initData.note?.delta),
      }),
      clientNumber: this.formBuilder.control(initData.clientNumber),
      debitorNumber: this.formBuilder.control(initData.debitorNumber),
    });
  }
}
