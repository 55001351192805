import { CommonModule } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule, Version } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouteReuseStrategy } from "@angular/router";
import { ANKAADIA_VERSION, GITSHASHORT } from "@ankaadia/ankaadia-shared";
import {
  apolloFactory,
  APP_SHA,
  APP_VERSION,
  FRONTEND_ENVIRONMENT_INJECTION_TOKEN,
} from "@ankaadia/ankaadia-shared-frontend";
import { provideApollo } from "apollo-angular";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { NgxSpinnerModule } from "ngx-spinner";
import { environment } from "../../environments/environment";
import { possibleApolloReturnTypes } from "./apollo/apollo-return-types";
import { DateTimeModule } from "./date-time/date-time.module";
import { DirectivesModule } from "./directives/directives.module";
import { EditToolBarModule } from "./edit-tool-bar/edit-tool-bar.module";
import { FlagModule } from "./flag/flag.module";
import { FormElementMapModule } from "./from-element-map/form-element-map.module";
import { ImageViewerModule } from "./image-viewer/image-viewer.module";
import { InterceptorsModule } from "./interceptors/interceptors.module";
import { MessageDialogModule } from "./message-dialog/message-dialog.module";
import { PipesModule } from "./pipes/pipes.module";
import { PrimeNGModule } from "./primeng/primeng.module";
import { AppRouteReuseStrategy } from "./services/route-reuse-strategy.service";
import { SettingsService } from "./services/settings.service";
import { ShowMoreModule } from "./show-more/show-more.module";
import { StaticDataModule } from "./static-data/static-data.module";
import { TableModule } from "./table/table.module";
import { TestIdModule } from "./test-id/test-id.module";
import { TranslocoModule } from "./transloco/transloco.module";
import { TreeTableModule } from "./tree-table/tree-table.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule.forRoot({ type: "ball-scale-multiple" }),
    NgxExtendedPdfViewerModule,
    PrimeNGModule,
    TranslocoModule,
    DirectivesModule,
    InterceptorsModule,
    PipesModule,
    StaticDataModule,
    FormElementMapModule,
    TestIdModule,
    EditToolBarModule,
    ImageViewerModule,
    TableModule,
    TreeTableModule,
    FlagModule,
    ShowMoreModule,
    MessageDialogModule,
    DateTimeModule,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideApollo(apolloFactory(environment, possibleApolloReturnTypes, SettingsService.graphQLError$)),
    { provide: FRONTEND_ENVIRONMENT_INJECTION_TOKEN, useValue: environment },
    { provide: APP_VERSION, useValue: new Version(ANKAADIA_VERSION) },
    { provide: APP_SHA, useValue: GITSHASHORT },
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxExtendedPdfViewerModule,
    PrimeNGModule,
    TranslocoModule,
    DirectivesModule,
    InterceptorsModule,
    PipesModule,
    StaticDataModule,
    FormElementMapModule,
    TestIdModule,
    EditToolBarModule,
    ImageViewerModule,
    TableModule,
    TreeTableModule,
    FlagModule,
    ShowMoreModule,
    MessageDialogModule,
    DateTimeModule,
  ],
})
export class SharedModule {}
