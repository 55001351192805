import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ensure2LetterIsoLanguage, supportedLanguageFlags2LetterISO } from "@ankaadia/ankaadia-shared";
import { OrganizationProfileDescription, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import Quill from "quill";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";

@Component({
  selector: "app-organization-profile-description",
  templateUrl: "./organization-profile-description.component.html",
  standalone: false,
})
export class OrganizationProfileDescriptionComponent implements OnInit {
  readonly flags = supportedLanguageFlags2LetterISO();
  readonly language = ensure2LetterIsoLanguage(this.transloco.getActiveLang()).toUpperCase();
  protected languages = this.staticDataService.getStaticData(StaticDataType.Languages, this.language);

  @Input()
  form: FormArray;

  @Input()
  description: OrganizationProfileDescription[];

  private selectedEditor: Quill;
  private selectedDescription: FormGroup;
  selectedLanguage: string = this.language;

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly formBuilder: FormBuilder,
    private readonly transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    for (const description of this.description ?? []) {
      this.form.push(this.createFormGroup(description), { emitEvent: false });
    }
    this.updateSelectedDescription(this.language);
  }

  initialized(editor: Quill): void {
    this.selectedEditor = editor;
    this.selectedEditor.setContents(this.selectedDescription.controls.delta.value);
  }

  changed(contents: string, source: string): void {
    if (source === "user") {
      this.selectedDescription.controls.contents.setValue(contents);
      this.selectedDescription.controls.delta.setValue(this.selectedEditor.getContents());
      this.selectedDescription.controls.contents.markAsDirty();
    }
  }

  updateSelectedDescription(language: string): void {
    this.selectedDescription = this.form.controls.find((c) => c.value.language === language) as FormGroup;
    if (!this.selectedDescription) {
      this.selectedDescription = this.createFormGroup({ language: language, contents: null, delta: null });
      this.form.push(this.selectedDescription);
      this.form.markAsDirty();
    }
  }

  private createFormGroup(description?: OrganizationProfileDescription): FormGroup {
    return this.formBuilder.group({
      language: [description?.language, Validators.required],
      contents: [description?.contents],
      delta: [description?.delta],
    });
  }
}
