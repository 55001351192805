import { Component, input } from "@angular/core";
import { PriceRowEventAndShare } from "@ankaadia/graphql";
import { CommonModule } from "@angular/common";
import { TranslateDirective } from "../../../../../shared/transloco/translate.directive";
import { TranslocoPercentPipe } from "@jsverse/transloco-locale";

@Component({
  standalone: true,
  selector: "app-plan-candidate-billing-events",
  templateUrl: "./plan-candidate-billing-events.component.html",
  imports: [CommonModule, TranslateDirective, TranslocoPercentPipe],
})
export class PlanCandidateBillingEventsComponent {
  events = input.required<PriceRowEventAndShare[]>();
}

/**
 * export enum BillingEventType {
 *   CandidateDeclarationOfEmploymentUploaded = 'CandidateDeclarationOfEmploymentUploaded',
 *   CandidatePreContractUploaded = 'CandidatePreContractUploaded',
 *   CandidateStatusSetToMatched = 'CandidateStatusSetToMatched',
 *   CandidateTrainingContractUploaded = 'CandidateTrainingContractUploaded',
 *   CandidateTransferred = 'CandidateTransferred',
 *   CandidateWorkingContractUploaded = 'CandidateWorkingContractUploaded'
 * }
 */
