import { Component, ViewChild } from "@angular/core";
import {
  CollectionAutoTemplateVariableEnum,
  getCollectionAutoTemplateVariableTemplatePlaceholder,
} from "@ankaadia/ankaadia-shared";
import { translate } from "@jsverse/transloco";
import { OverlayPanel } from "primeng/overlaypanel";

@Component({
  selector: "app-variable-description-icon",
  templateUrl: "./variable-description-icon.component.html",
  standalone: false,
})
export class VariableDescriptionIconComponent {
  @ViewChild("popup")
  popup: OverlayPanel;

  protected getVariableDescription(): string {
    const variableDescriptions = Object.keys(CollectionAutoTemplateVariableEnum).map((x) => {
      const enumValue = CollectionAutoTemplateVariableEnum[x];
      return {
        key: getCollectionAutoTemplateVariableTemplatePlaceholder(enumValue),
        description: translate(`autoCollectionTemplate.variableDescriptions.${x}`),
      };
    });
    const variableItems = `<ul>${variableDescriptions.map((x) => `<li><strong>${x.key}</strong>: ${x.description}</li>`).join("")}</ul>`;
    const header = `<h4>${translate("autoCollectionTemplate.variableDescriptions.title")}</h4>`;
    return `<div>${header}${variableItems}</div>`;
  }

  show(event: Event): void {
    this.popup.show(event);
  }
}
