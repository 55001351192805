<ng-container *appTranslate="let t" [formGroup]="form">
  <ng-container [ngSwitch]="parameter">
    <ng-container *ngSwitchCase="'text'">
      <input pInputText formControlName="value" maxlength="1000" />
    </ng-container>

    <ng-container *ngSwitchCase="'number'">
      <p-inputNumber formControlName="value" [min]="0"></p-inputNumber>
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      <p-calendar formControlName="value"></p-calendar>
    </ng-container>

    <ng-container *ngSwitchCase="'options'">
      <p-multiSelect
        #multiSelect
        [group]="useGroups"
        [options]="multiSelectOptions"
        [required]="true"
        [placeholder]="t('value.placeholder')"
        formControlName="value"
      >
        <ng-template let-group pTemplate="group">
          <div class="flex align-items-center gap-2">
            <span *ngIf="group.value" [class]="'flag flag-small flag-' + group.value.toLowerCase()"></span>
            <div>{{ group.label }}</div>
          </div>
        </ng-template>
      </p-multiSelect>
    </ng-container>

    <ng-container *ngSwitchCase="'profession'">
      <app-profession-selector
        [multi]="true"
        formControlName="value"
        [variables]="variables?.['profession']"
      ></app-profession-selector>
    </ng-container>

    <ng-container *ngSwitchCase="'autocomplete'">
      <p-autoComplete
        formControlName="value"
        [required]="true"
        [suggestions]="suggestions"
        (completeMethod)="complete($event)"
        field="label"
        optionValue="value"
      ></p-autoComplete>
    </ng-container>

    <ng-container *ngSwitchCase="'lazyChoice'">
      <p-multiSelect
        #lazyMultiSelect
        [options]="lazyMultiSelectFilterOptions"
        [required]="true"
        [placeholder]="t('value.placeholder')"
        [filterPlaceHolder]="t('value.search')"
        (onFilter)="loadOptions($event)"
        formControlName="value"
      >
      </p-multiSelect>
    </ng-container>
  </ng-container>

  <small class="p-error" *ngIf="form.controls.value.errors?.required">
    {{ t("value.required") }}
  </small>
</ng-container>
