<ng-container *appTranslate="let t">
  <form [formGroup]="form">
    <p-fieldset [legend]="t('housing.contractData.title')" class="relative">
      <div class="flex-1 p-fluid formgrid grid align-items-center">
        <div class="field col-12 lg:col-3">
          <app-candidate-aligned-label
            [labelFor]="'name'"
            [labelText]="t('housing.name')"
            [useAlignmentCheckBox]="true"
          ></app-candidate-aligned-label>
          <input pInputText id="name" formControlName="name" [readonly]="readonly" />
          <small class="p-error" *ngIf="form.controls.name.errors?.required">{{ t("required") }}</small>
        </div>
        <app-candidate-document-date
          [inputId]="'contract#' + index"
          [candidateId]="candidateId"
          [candidateOrganizationId]="candidateOrganizationId"
          [candidateForm]="form"
          [readonly]="readonly"
          [label]="t('housing.contractSignDate.title')"
          documentType="RENTALAGREEMENT"
          [selectedSet]="{ foreignKey: form.controls.id.value }"
          dateField="issueDate"
          class="field col-12 lg:col-3"
        ></app-candidate-document-date>
        <div class="field col-12 lg:col-3">
          <app-candidate-aligned-label
            [labelFor]="'readinessForOccupancyDate'"
            [labelText]="t('housing.readinessForOccupancyDate.title')"
            [useAlignmentCheckBox]="true"
          ></app-candidate-aligned-label>
          <p-calendar formControlName="readinessForOccupancyDate" [showClear]="true" [readonly]="readonly"></p-calendar>
        </div>
        <div class="field col-12 lg:col-3">
          <app-candidate-aligned-label
            [labelFor]="'movingInDate'"
            [labelText]="t('housing.movingInDate.title')"
            [useAlignmentCheckBox]="true"
          ></app-candidate-aligned-label>
          <p-calendar formControlName="movingInDate" [showClear]="true" [readonly]="readonly"></p-calendar>
        </div>
        <div class="field col-12 lg:col-3">
          <label for="cancellationOfContractSignDate">{{ t("housing.cancellationOfContractSignDate.title") }}</label>
          <p-calendar
            formControlName="cancellationOfContractSignDate"
            [showClear]="true"
            [readonly]="readonly"
          ></p-calendar>
        </div>
        <div class="field col-12 lg:col-3">
          <label for="movingOutDate">{{ t("housing.movingOutDate.title") }}</label>
          <p-calendar formControlName="movingOutDate" [showClear]="true" [readonly]="readonly"></p-calendar>
        </div>
        <div class="field col-12 lg:col-2">
          <label for="coldRent">{{ t("housing.coldRent.title") }}</label>
          <p-inputNumber
            mode="decimal"
            [minFractionDigits]="2"
            [maxFractionDigits]="2"
            inputId="coldRent"
            formControlName="coldRent"
            [min]="0"
            [readonly]="readonly"
          ></p-inputNumber>
        </div>
        <div class="field col-12 lg:col-2">
          <label for="operatingCosts">{{ t("housing.operatingCosts.title") }}</label>
          <p-inputNumber
            mode="decimal"
            [minFractionDigits]="2"
            [maxFractionDigits]="2"
            inputId="operatingCosts"
            formControlName="operatingCosts"
            [min]="0"
            [readonly]="readonly"
          ></p-inputNumber>
        </div>
        <div class="field col-12 lg:col-2">
          <label for="additionalCosts">{{ t("housing.additionalCosts.title") }}</label>
          <p-inputNumber
            mode="decimal"
            [minFractionDigits]="2"
            [maxFractionDigits]="2"
            inputId="additionalCosts"
            formControlName="additionalCosts"
            [min]="0"
            [readonly]="readonly"
          ></p-inputNumber>
        </div>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('address.title')" class="relative">
      <div class="flex-1 p-fluid formgrid grid align-items-center">
        <div class="field col-12 lg:col-3">
          <label for="address">{{ t("address.title") }}</label>
          <input pInputText id="address" formControlName="address" [readonly]="readonly" />
        </div>

        <div class="field col-12 lg:col-2">
          <label for="zipCode">{{ t("zipCode.title") }}</label>
          <input pInputText id="zipCode" formControlName="zipCode" [readonly]="readonly" />
        </div>

        <div class="field col-12 lg:col-3">
          <label for="city">{{ t("city.title") }}</label>
          <input pInputText id="city" formControlName="city" [readonly]="readonly" />
        </div>

        <div class="field col-12 lg:col-2">
          <label for="federalState">{{ t("federalState.title") }}</label>
          <input pInputText id="federalState" formControlName="federalState" [readonly]="readonly" />
        </div>

        <div class="field col-12 lg:col-2">
          <label for="country">{{ t("country.title") }}</label>
          <p-dropdown
            inputId="country"
            [options]="countries$ | async"
            formControlName="country"
            [showClear]="true"
            [placeholder]="t('country.placeholder')"
            [readonly]="readonly"
          ></p-dropdown>
        </div>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('housing.housingManagement.title')" class="relative">
      <div class="flex-1 p-fluid formgrid grid align-items-center">
        <div class="field col-12 lg:col-4">
          <label for="nameOfContactPerson">{{ t("housing.nameOfContactPerson.title") }}</label>
          <input pInputText id="nameOfContactPerson" formControlName="nameOfContactPerson" [readonly]="readonly" />
        </div>

        <div class="field col-12 lg:col-4">
          <label for="phoneOfContactPerson">{{ t("housing.phoneOfContactPerson.title") }}</label>
          <input pInputText id="phoneOfContactPerson" formControlName="phoneOfContactPerson" [readonly]="readonly" />
        </div>

        <div class="field col-12 lg:col-4">
          <label for="keyHandoverDateTime">{{ t("housing.keyHandoverDateTime.title") }}</label>
          <app-date-time
            [readonly]="readonly"
            [form]="form.controls.keyHandoverDateTime"
            [inputId]="'keyHandoverDate'"
          ></app-date-time>
        </div>

        <div class="field col-12 lg:col-12">
          <label for="comments">{{ t("housing.comments.title") }}</label>
          <textarea
            id="comments"
            pInputTextarea
            rows="2"
            formControlName="comments"
            [readonly]="readonly"
            maxlength="300"
          ></textarea>
        </div>
      </div>
    </p-fieldset>
  </form>
</ng-container>
