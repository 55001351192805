import { Component, Input, OnInit } from "@angular/core";
import {} from "viewerjs";

@Component({
  selector: "app-image-viewer",
  templateUrl: "./image-viewer.component.html",
  standalone: false,
})
export class ImageViewerComponent implements OnInit {
  @Input()
  url: string;

  viewerOptions: any;

  ngOnInit(): void {
    this.viewerOptions = {
      inline: true,
      backdrop: true,
      // minWidth: (this.elRef.nativeElement.parentElement.parentElement as HTMLElement).offsetWidth,
      //minHeight: (this.elRef.nativeElement.parentElement.parentElement as HTMLElement).offsetHeight,
      navbar: false,
      //inheritedAttributes: ["sizes"],
      toolbar: {
        zoomIn: true,
        zoomOut: true,
        oneToOne: true,
        reset: true,
        prev: false,
        play: false,
        next: false,
        rotateLeft: true,
        rotateRight: true,
        flipHorizontal: true,
        flipVertical: true,
      },
    };
  }
}
