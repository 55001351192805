<ng-container *appTranslate="let t">
  <p-card>
    <div class="flex flex-column app-form p-card-content">
      <p-toolbar styleClass="mb-4" *ngIf="title">
        <ng-template pTemplate="left">
          <h3>{{ title }}</h3>
        </ng-template>
      </p-toolbar>

      <p-table
        #table
        [value]="examResultData"
        [globalFilterFields]="[
          'exam.name',
          'data.candidateName',
          'data.candidateDisplayId',
          'localized.examResult',
          'localized.passedExamModules',
        ]"
        class="app-form"
        styleClass="p-datatable-sm app-form"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row justify-content-between md:align-items-center table-header">
            <ng-container class="flex flex-0 mr-2 mw-1" *ngIf="captionTemplate">
              <ng-container *ngTemplateOutlet="captionTemplate"></ng-container>
            </ng-container>

            <span class="flex-1 p-input-icon-left mr-2">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                (input)="table.filterGlobal($any($event.target).value, 'contains')"
                [placeholder]="t('common.search')"
              />
            </span>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th
              *ngIf="isEditable && (!shownColumns || shownColumns.includes('actions'))"
              class="white-space-nowrap actions"
            >
              &nbsp;
            </th>

            <th *ngIf="!shownColumns || shownColumns.includes('name')" class="white-space-nowrap">
              {{ t("educationExam.title") }}
            </th>

            <th *ngIf="!shownColumns || shownColumns.includes('examType')" class="white-space-nowrap">
              {{ t("examType.title") }}
            </th>

            <th *ngIf="!shownColumns || shownColumns.includes('educationProvider')" class="white-space-nowrap">
              {{ t("educationProvider.title") }}
            </th>

            <th *ngIf="!shownColumns || shownColumns.includes('examDateAndTime')" class="white-space-nowrap">
              {{ t("examDateAndTime.title") }}
            </th>

            <th
              *ngIf="!shownColumns || shownColumns.includes('candidateDisplayId')"
              pSortableColumn="data.candidateDisplayId"
              class="white-space-nowrap"
            >
              {{ t("number.title") }}
              <p-sortIcon field="data.candidateDisplayId"></p-sortIcon>
            </th>

            <th
              *ngIf="!shownColumns || shownColumns.includes('candidateName')"
              pSortableColumn="data.candidateName"
              class="white-space-nowrap"
            >
              {{ t("name.title") }}
              <p-sortIcon field="data.candidateName"></p-sortIcon>
            </th>

            <th
              *ngIf="!shownColumns || shownColumns.includes('languageLevel')"
              pSortableColumn="localized.languageLevel"
              class="white-space-nowrap"
            >
              {{ t("skillLevel.title") }}
              <p-sortIcon field="localized.languageLevel"></p-sortIcon>
            </th>

            <th
              *ngIf="!shownColumns || shownColumns.includes('examResult')"
              pSortableColumn="localized.examResult"
              class="white-space-nowrap"
            >
              {{ t("examResult.title") }}
              <p-sortIcon field="localized.examResult"></p-sortIcon>
            </th>

            <th
              *ngIf="!shownColumns || shownColumns.includes('passedExamModules')"
              pSortableColumn="localized.passedExamModules"
              class="white-space-nowrap"
            >
              {{ t("passedExamModules.title") }}
              <p-sortIcon field="localized.passedExamModules"></p-sortIcon>
            </th>

            <ng-container *ngFor="let moduleType of allExamModules">
              <th
                *ngIf="
                  displayModules.includes($any(moduleType.value)) &&
                  (!shownColumns || shownColumns.includes('examModuleInformation'))
                "
                class="white-space-nowrap"
              >
                {{ t($any("enum.ExamModuleType." + moduleType.value)) }}
              </th>
            </ng-container>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData>
          <tr [formGroup]="rowData.form">
            <td
              *ngIf="isEditable && (!shownColumns || shownColumns.includes('actions'))"
              class="white-space-nowrap actions"
            >
              <p-button
                *ngIf="rowData.form.dirty && rowData.form.valid"
                icon="pi pi-check"
                styleClass="p-button-rounded p-button-text p-button-success"
                (onClick)="saveExamResult(rowData)"
              ></p-button>

              <p-button
                *ngIf="rowData.form.dirty"
                icon="pi pi-times"
                styleClass="p-button-rounded p-button-text p-button-danger"
                (onClick)="cancelExamResult(rowData)"
              ></p-button>

              <p-button
                *ngIf="rowData.form.value._etag"
                icon="pi pi-trash"
                styleClass="p-button-rounded p-button-text p-button-danger"
                (onClick)="deleteExamResult($event, rowData)"
              ></p-button>
            </td>

            <td *ngIf="!shownColumns || shownColumns.includes('name')" class="white-space-nowrap">
              {{ rowData.exam?.name }}
            </td>

            <td *ngIf="!shownColumns || shownColumns.includes('examType')" class="white-space-nowrap">
              {{ rowData.exam?.examType | enum: "ExamType" }}
            </td>

            <td *ngIf="!shownColumns || shownColumns.includes('educationProvider')" class="white-space-nowrap">
              {{ rowData.provider?.name }}
            </td>

            <td *ngIf="!shownColumns || shownColumns.includes('examDateAndTime')" class="white-space-nowrap">
              {{
                rowData.exam?.examDateAndTime?.hasTime
                  ? (rowData.exam?.examDateAndTime?.date | appDateTime: { dateStyle: "medium", timeStyle: "short" })
                  : (rowData.exam?.examDateAndTime?.date | appDate: { dateStyle: "medium" })
              }}
            </td>

            <td *ngIf="!shownColumns || shownColumns.includes('candidateDisplayId')" class="white-space-nowrap">
              <app-candidate-flexible-edit-button
                [candidateId]="rowData.data.candidateId"
                [candidateOrganizationId]="rowData.data.candidateOrganizationId"
                [label]="rowData.data.candidateDisplayId"
                [showFlag]="false"
              ></app-candidate-flexible-edit-button>
            </td>

            <td *ngIf="!shownColumns || shownColumns.includes('candidateName')" class="white-space-nowrap">
              {{ rowData.data.candidateName }}
            </td>

            <td *ngIf="!shownColumns || shownColumns.includes('languageLevel')" class="white-space-nowrap">
              {{ rowData.data.languageLevel | staticData: StaticDataType.LanguageModules | async }}
            </td>

            <td *ngIf="!shownColumns || shownColumns.includes('examResult')" pEditableColumn class="white-space-nowrap">
              <span *ngIf="!isEditable" class="inline-block">
                {{ rowData.form.controls.examResult.value | staticData: StaticDataType.ExamResultState | async }}
              </span>

              <p-cellEditor *ngIf="isEditable">
                <ng-template pTemplate="output">
                  <span class="inline-block">
                    {{ rowData.form.controls.examResult.value | staticData: StaticDataType.ExamResultState | async }}
                  </span>
                </ng-template>

                <ng-template pTemplate="input">
                  <p-dropdown
                    styleClass="w-full compact minimalistic"
                    formControlName="examResult"
                    [options]="rowData.examResultStates$ | async"
                    [placeholder]="t('examResult.placeholder')"
                    (onChange)="updatePassedExamModules(rowData, $event.value)"
                  ></p-dropdown>
                </ng-template>
              </p-cellEditor>
            </td>

            <td
              *ngIf="!shownColumns || shownColumns.includes('passedExamModules')"
              pEditableColumn
              class="white-space-nowrap"
            >
              <span *ngIf="!isEditable" class="inline-block">
                {{ rowData.form.controls.passedExamModules.value | enum: "ExamModuleType" }}
              </span>

              <p-cellEditor *ngIf="isEditable">
                <ng-template pTemplate="output">
                  <span class="inline-block">
                    {{ rowData.form.controls.passedExamModules.value | enum: "ExamModuleType" }}
                  </span>
                </ng-template>

                <ng-template pTemplate="input">
                  <p-multiSelect
                    styleClass="w-full compact minimalistic"
                    formControlName="passedExamModules"
                    [options]="rowData.examModules$ | async"
                    [placeholder]="t('passedExamModules.placeholder')"
                    [showToggleAll]="false"
                    [showHeader]="false"
                    [resetFilterOnHide]="true"
                  ></p-multiSelect>
                </ng-template>
              </p-cellEditor>

              <small class="block p-error mt-2" *ngIf="rowData.form.controls.passedExamModules.errors?.required">
                {{ t("common.validation.requiredShort") }}
              </small>

              <small
                class="block p-error mt-2"
                *ngIf="rowData.form.controls.passedExamModules.errors?.passedExamButNotAllModulesPassed"
              >
                {{ t("educationExamResult.error.passedExamButNotAllModulesPassed") }}
              </small>

              <small
                class="block p-error mt-2"
                *ngIf="rowData.form.controls.passedExamModules.errors?.partiallyPassedExamButAllModulesPassed"
              >
                {{ t("educationExamResult.error.partiallyPassedExamButAllModulesPassed") }}
              </small>

              <small
                class="block p-error mt-2"
                *ngIf="rowData.form.controls.passedExamModules.errors?.notPassedExamButModulesPassed"
              >
                {{ t("educationExamResult.error.notPassedExamButModulesPassed") }}
              </small>
            </td>

            <ng-container *ngFor="let moduleType of allExamModules">
              <td
                *ngIf="
                  displayModules.includes($any(moduleType.value)) &&
                  (!shownColumns || shownColumns.includes('examModuleInformation'))
                "
                pEditableColumn
                class="white-space-nowrap"
              >
                <ng-container *ngIf="getDetailsControl(rowData, $any(moduleType.value)) as control">
                  <span *ngIf="!isEditable" class="inline-block">
                    {{ control.value }}
                  </span>

                  <p-cellEditor *ngIf="isEditable">
                    <ng-template pTemplate="output">
                      <span class="inline-block">
                        {{ control.value }}
                      </span>
                    </ng-template>

                    <ng-template pTemplate="input">
                      <input pInputText class="w-full compact minimalistic w-12rem" [formControl]="control" />
                    </ng-template>
                  </p-cellEditor>
                </ng-container>
              </td>
            </ng-container>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-card>
</ng-container>
