import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { DocumentsModule } from "../documents/documents.module";
import { CandidateEventsTableComponent } from "./candidate-events-table.component";
import { CandidateEventsComponent } from "./candidate-events.component";

@NgModule({
  imports: [SharedModule, DocumentsModule],
  declarations: [CandidateEventsComponent, CandidateEventsTableComponent],
  exports: [CandidateEventsComponent, CandidateEventsTableComponent],
})
export class CandidateEventsModule {}
