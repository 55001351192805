import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl } from "@angular/forms";
import { sortResidencePermits } from "@ankaadia/ankaadia-shared";
import { StaticDataModel, StaticDataType, SupportedImmigrationCountry } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { CheckboxChangeEvent } from "primeng/checkbox";
import { Observable, map, startWith, tap } from "rxjs";
import { StaticDataService } from "../../../../../../shared/static-data/static-data.service";
import { ImmigrationAuthorityService } from "../../../../../immigration-authorities/immigration-authority.service";
import { ResidencePermitFormDe } from "../../../../candidate-form.model";

@Component({
  selector: "app-candidate-residence-permit-details",
  templateUrl: "./candidate-residence-permit-details.component.html",
  standalone: false,
})
export class CandidateResidencePermitDetailsComponent implements OnInit {
  @Input({ required: true })
  form: ResidencePermitFormDe;

  @Input({ required: true })
  candidateId: string;

  @Input({ required: true })
  candidateOrganizationId: string;

  @Input({ required: true })
  readonly: boolean;

  @Input({ required: true })
  index: number;

  @Output()
  readonly deleteClicked = new EventEmitter<void>();

  readonly today = new Date();
  readonly residencePermits$ = this.staticDataService
    .getStaticData(StaticDataType.ResidencePermits, this.transloco.getActiveLang())
    .pipe(map((permits) => sortResidencePermits(permits)));

  immigrationAuthorities$: Observable<StaticDataModel[]>;

  constructor(
    private readonly transloco: TranslocoService,
    private readonly staticDataService: StaticDataService,
    private readonly immigrationAuthorityService: ImmigrationAuthorityService,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.immigrationAuthorities$ = this.immigrationAuthorityService.getImmigrationAuthorities(
      this.candidateOrganizationId,
      SupportedImmigrationCountry.De
    );
    this.form.controls.fictionalCertificateAvailable.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(this.form.controls.fictionalCertificateAvailable.value),
        tap((x) => this.enableOrDisableFictionalCertificateAvailableDependantControls(x))
      )
      .subscribe();
  }

  protected fictionalCertificateAvailableClicked($event: CheckboxChangeEvent): void {
    if (!$event.checked)
      this.getFictionalCertificateDependantControls().forEach((control) => control.setValue(undefined));
  }

  private enableOrDisableFictionalCertificateAvailableDependantControls(x: boolean): void {
    const dependentControls = this.getFictionalCertificateDependantControls();
    if (x) dependentControls.forEach((control) => control.enable());
    else dependentControls.forEach((control) => control.disable());
  }

  private getFictionalCertificateDependantControls(): FormControl[] {
    const controls = this.form.controls;
    return [controls.fictionalCertificateDoesNotAuthorisesWorking, controls.fictionalCertificateNumber];
  }
}
