<ng-container *appTranslate="let t">
  <ng-container *ngIf="filter && fields?.length">
    <form [formGroup]="form" (ngSubmit)="onSave($event)">
      <p-card [header]="t('autoCollection.editFilter')">
        <div class="flex flex-row justify-content-start p-2 field">
          <p-inputSwitch id="includeSharedCandidates" formControlName="includeSharedCandidates"></p-inputSwitch>
          <label for="includeSharedCandidates" class="ml-2">{{ t("autoCollection.includeSharedCandidates") }}</label>
        </div>
        <app-candidate-filter-set [form]="form" [filter]="filter" [fields]="fields"></app-candidate-filter-set>

        <ng-template pTemplate="footer">
          <div class="flex flex-row justify-content-end">
            <p-button
              [disabled]="!form.valid || !form.dirty"
              [label]="t('common.save')"
              icon="pi pi-check"
              type="submit"
              class="mr-2"
            ></p-button>
            <p-button
              closeOnEscape
              [label]="t('common.cancel')"
              icon="pi pi-times"
              class="p-button-secondary"
              (onClick)="onClose()"
            ></p-button>
          </div>
        </ng-template>
      </p-card>
    </form>
    <p-confirmDialog
      key="centeredDialog"
      position="center"
      [header]="t('common.hint')"
      icon="pi pi-exclamation-triangle"
    >
    </p-confirmDialog>
  </ng-container>
</ng-container>
