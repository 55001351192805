import { FilterMetadata } from "primeng/api";
import { TableLazyLoadEvent } from "primeng/table";
import { CandidateFilterMetadata } from "@ankaadia/ankaadia-shared";

export function IsSingleFilterMetaData(filter: FilterMetadata | FilterMetadata[]): filter is FilterMetadata {
  return !Array.isArray(filter);
}

export function IsFilterMetaDataArray(filter: FilterMetadata | FilterMetadata[]): filter is FilterMetadata[] {
  return Array.isArray(filter);
}

export type FilterMetadataMap = Record<string, CandidateFilterMetadata | CandidateFilterMetadata[]>;

export interface CandidateFilters {
  status?: FilterMetadata[];
  global?: FilterMetadata;
  favorite?: FilterMetadata;
  custom?: FilterMetadataMap;
}

export type CustomLazyLoadEvent = Omit<TableLazyLoadEvent, "filters"> & { filters?: CandidateFilters };
