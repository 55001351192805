<ng-container *appTranslate="let t">
  <p-card [header]="t('educationExamParticipants.addToExam')">
    <div class="p-fluid formgrid">
      <div class="field" *ngIf="!isOwner">
        <label for="participantCollectionId">{{ t("participantCollection.title") }}</label>
        <p-dropdown
          inputId="participantCollectionId"
          [(ngModel)]="selectedCollection"
          [options]="collectionOptions"
          [placeholder]="t('participantCollection.placeholder')"
          optionLabel="name"
        ></p-dropdown>
      </div>

      <div class="field">
        <label for="selectedCandidate">{{ t("educationExamParticipants.title") }}</label>
        <p-multiSelect
          inputId="selectedCandidate"
          [options]="candidateOptions"
          [(ngModel)]="selectedCandidates"
          filterBy="displayName,displayId"
          (onFilter)="searchCandidates($event.filter)"
          (onClick)="searchCandidates('')"
          [showClear]="true"
          [placeholder]="t('educationExamParticipants.placeholder')"
          [showToggleAll]="false"
          [disabled]="!isOwner && !selectedCollection"
          display="chip"
        >
          <ng-template let-candidate pTemplate="item">
            <ng-container *ngTemplateOutlet="shownData; context: { candidate: candidate }"></ng-container>
          </ng-template>

          <ng-template pTemplate="selectedItems">
            <p-chips [(ngModel)]="selectedCandidates" *ngIf="!isEmpty(selectedCandidates)">
              <ng-template let-candidate pTemplate="item">
                <ng-container *ngTemplateOutlet="shownData; context: { candidate: candidate }"></ng-container>
              </ng-template>
            </p-chips>
            <div *ngIf="isEmpty(selectedCandidates)">
              {{ t("educationExamParticipants.placeholder") }}
            </div>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <app-edit-tool-bar
        class="flex flex-row justify-content-end"
        [showBtn]="{ cancel: true, save: true }"
        [saveBtn]="{ disabled: isEmpty(candidateOptions) }"
        (canceled)="cancel()"
        (saved)="save()"
      ></app-edit-tool-bar>
    </ng-template>
  </p-card>
</ng-container>

<ng-template #shownData let-candidate="candidate">
  <div class="flex align-items-center gap-2">
    <span class="white-space-nowrap text-overflow-ellipsis overflow-hidden">
      <app-flag class="mr-2" *ngIf="candidate.country" [country]="candidate.country" [small]="true"></app-flag>
      {{ candidate.displayId }}
    </span>

    <div>{{ candidate.displayName }}</div>
  </div>
</ng-template>
