<ng-container *appTranslate="let t">
  <p-card *ngIf="course" class="no-shadow">
    <div class="flex flex-column h-full">
      <p-toolbar>
        <ng-template pTemplate="left">
          <h3>{{ course.name }}</h3>
        </ng-template>

        <ng-template pTemplate="right">
          <p-button
            [label]="t('common.edit')"
            icon="pi pi-pencil"
            styleClass="mr-2"
            (onClick)="edited.emit(course)"
          ></p-button>

          <p-button
            [label]="t('common.delete')"
            icon="pi pi-trash"
            (onClick)="deleted.emit([course, $event])"
          ></p-button>
        </ng-template>
      </p-toolbar>

      <p-tabView *ngIf="performance" class="flex-1 min-h-0" styleClass="flex flex-column">
        <p-tabPanel [header]="t('candidates.assignedFull', { total: performance.candidates.length })">
          <app-education-course-performances
            [performance]="performance"
            (candidateStateUpdated)="candidateStateUpdated.emit($event)"
            (attendanceUpdated)="attendanceUpdated.emit($event)"
            (performanceUpdated)="performanceUpdated.emit($event)"
            (commentUpdated)="commentUpdated.emit($event)"
            (weekPrefilled)="weekPrefilled.emit($event)"
          ></app-education-course-performances>
        </p-tabPanel>

        <p-tabPanel [header]="t('candidates.events')">
          <app-education-course-events [course]="course"></app-education-course-events>
        </p-tabPanel>

        <p-tabPanel [header]="t('educationExamResults.title')">
          <app-education-course-exam-results [course]="course"></app-education-course-exam-results>
        </p-tabPanel>
      </p-tabView>
    </div>
  </p-card>
</ng-container>
