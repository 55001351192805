import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-candidate-aligned-label",
  templateUrl: "./candidate-aligned-label.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CandidateAlignedLabelComponent {
  @Input()
  useAlignmentCheckBox: boolean;

  @Input()
  labelFor: string;

  @Input()
  labelText: string;
}
