<ng-container *appTranslate="let t">
  <form [formGroup]="form">
    <div class="p-fluid formgrid grid">
      <div class="field col-12 lg:col-12">
        <div class="field-checkbox">
          <p-checkbox
            inputId="noDetailedEquivalence"
            [binary]="true"
            formControlName="noDetailedEquivalence"
            [readonly]="readonly"
            (onChange)="confirmNoDetailedEquivalence($event.checked, $event.originalEvent.target)"
          ></p-checkbox>
          <label for="noDetailedEquivalence">{{ t("noDetailedEquivalence.title") }}</label>
          <i class="pi pi-info-circle ml-2" [pTooltip]="t('noDetailedEquivalence.help')" [escape]="false"></i>
        </div>
      </div>

      <div class="field col-12 lg:col-3">
        <label for="totalHours">{{ t("totalHours.title") }}</label>
        <p-inputNumber
          inputId="totalHours"
          formControlName="totalHours"
          [min]="0"
          [readonly]="readonly"
          placeholder="0"
        ></p-inputNumber>
      </div>

      <div class="field col-12 lg:col-12 mt-2">
        <hr />
        <app-candidate-qualification-modules
          [form]="form.controls.qualificationModules"
          [readonly]="readonly"
        ></app-candidate-qualification-modules>
      </div>
    </div>
  </form>
</ng-container>
