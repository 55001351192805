import { Injectable } from "@angular/core";
import {
  CandidateProfileConfigDeleteInput,
  CandidateProfileConfigFragment,
  CandidateProfileConfigFragmentDoc,
  CandidateProfileConfigUpdateInput,
  CandidateStatus,
  CreateCandidateProfileConfigGQL,
  DeleteCondidateProfileConfigGQL,
  GetCandidateProfileConfigByStatusAndFunctionGQL,
  GetCandidateProfileConfigGQL,
  GetCandidateProfileConfigsGQL,
  UpdateCandidateProfileConfigGQL,
} from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class CandidateProfileConfigService {
  constructor(
    private readonly CandidateProfileConfigsGet: GetCandidateProfileConfigsGQL,
    private readonly CandidateProfileConfigGet: GetCandidateProfileConfigGQL,
    private readonly CandidateProfileConfigGetBySetIdStatusFunction: GetCandidateProfileConfigByStatusAndFunctionGQL,
    private readonly CandidateProfileConfigCreate: CreateCandidateProfileConfigGQL,
    private readonly CandidateProfileConfigUpdate: UpdateCandidateProfileConfigGQL,
    private readonly CandidateProfileConfigDelete: DeleteCondidateProfileConfigGQL
  ) {}

  getAll(organizationId: string, setId: string): Observable<Partial<CandidateProfileConfigFragment>[]> {
    return this.CandidateProfileConfigsGet.watch({
      input: { organizationId: organizationId, setId: setId },
    }).valueChanges.pipe(map((result) => result.data.getAllConfigs));
  }

  get(profileConfigId: string, organizationId: string): Observable<CandidateProfileConfigFragment> {
    return this.CandidateProfileConfigGet.fetch({
      input: { id: profileConfigId, organizationId: organizationId },
    }).pipe(map((result) => result.data.getConfig));
  }

  getBySetIdStatusAndFunction(
    setId: string,
    organizationId: string,
    candidateStatus: CandidateStatus,
    candidateFunction: string
  ): Observable<CandidateProfileConfigFragment> {
    return this.CandidateProfileConfigGetBySetIdStatusFunction.fetch({
      input: {
        setId: setId,
        candidateFunction: candidateFunction,
        candidateStatus: candidateStatus,
        organizationId: organizationId,
      },
    }).pipe(map((result) => result.data.getConfigByStatusAndFunction));
  }

  create(input: CandidateProfileConfigFragment): Observable<CandidateProfileConfigFragment> {
    return this.CandidateProfileConfigCreate.mutate(
      { input: input },
      {
        update: (cache, result) =>
          cache.modify({
            fields: {
              getAllConfigs: (refs, helper) =>
                updateApolloCache(input, refs, helper, cache, result.data.createCandidateProfileConfig),
            },
          }),
      }
    ).pipe(map((result) => result.data.createCandidateProfileConfig));
  }

  update(template: CandidateProfileConfigFragment): Observable<CandidateProfileConfigFragment> {
    const input = new CandidateProfileConfigUpdateInput();
    input.id = template.id;
    input.setId = template.setId;
    input._etag = template._etag;
    input.candidateStatus = template.candidateStatus;
    input.candidateFunction = template.candidateFunction;
    input.tabs = template.tabs;
    input.organizationId = template.organizationId;
    return this.CandidateProfileConfigUpdate.mutate({ input: input }).pipe(
      map((result) => result.data.updateCandidateProfileConfig)
    );
  }

  delete(id: string, orgId: string, etag: string): Observable<boolean> {
    const input = new CandidateProfileConfigDeleteInput();
    input.id = id;
    input._etag = etag;
    input.organizationId = orgId;
    return this.CandidateProfileConfigDelete.mutate(
      { input: input },
      {
        update: (cache) =>
          cache.modify({
            fields: {
              getAllConfigs: (refs, { readField }) => refs.filter((ref) => readField("id", ref) !== input.id),
            },
          }),
      }
    ).pipe(map((x) => x.data.deleteCondidateProfileConfig.status));
  }
}

function updateApolloCache(input, refs, { storeFieldName }, cache, data): any {
  if (!storeFieldName.includes(input.organizationId)) return refs;
  const ref = cache.writeFragment({
    data: data,
    fragment: CandidateProfileConfigFragmentDoc,
    fragmentName: "CandidateProfileConfig",
  });
  if (refs != null && refs.length > 0) {
    return [...refs, ref];
  } else {
    return [ref];
  }
}
