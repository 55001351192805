<ng-container *appTranslate="let t">
  <app-table
    [columns]="columns"
    [title]="t('organizations.manage') + ' ' + organizationName"
    [items]="orgs"
    [newOperations]="newOperations"
    [tableOperations]="tableOperations"
    [footerOperations]="footerOperations"
    [(showSidebar)]="showDialog"
    sidebarSize="large"
  >
    <ng-template #caption>
      <p-dropdown
        [options]="modeOptions"
        [(ngModel)]="selectedModeOption"
        styleClass="mw-250 mr-2"
        (onChange)="selectedModeOptionChanged($event.value)"
      ></p-dropdown>
    </ng-template>
    <app-organization-edit-dialog [hidden]="!editOrg" (closed)="closeSidebar()"></app-organization-edit-dialog>
    <app-organization-link-dialog
      *ngIf="link"
      [link]="link"
      (created)="reload()"
      (closed)="closeSidebar()"
    ></app-organization-link-dialog>
    <app-import *ngIf="import" [import]="import" (saved)="importData($event)" (closed)="closeSidebar()"></app-import>
  </app-table>

  <p-overlayPanel #deleteConfirmation [showCloseIcon]="true" styleClass="org-delete-confirmation">
    <ng-template pTemplate>
      <div class="field">
        <label>{{ t("organizations.confirmCode") }}:</label>
        <div class="p-inputgroup">
          <input type="text" pInputText [(ngModel)]="orgCodeConfirmation" />
          <button
            submitOnEnter
            type="button"
            pButton
            [label]="t('common.ok')"
            [disabled]="orgCodeConfirmation !== orgToDelete?.code"
            (click)="delete()"
          ></button>
        </div>
      </div>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #selectCopyData class="max-w-36rem" [showCloseIcon]="true" styleClass="org-delete-confirmation">
    <ng-template pTemplate>
      <h5>{{ t("demo.dialogCaption") }}</h5>
      <div class="grid">
        <div class="field col-12">
          <label>{{ t("demo.copyDataType") }}</label>
          <div class="p-inputgroup">
            <p-dropdown
              [options]="dataToCopyOptions"
              [(ngModel)]="dataToCopy"
              [style]="{ width: '100%' }"
              [filter]="true"
              [showClear]="true"
            ></p-dropdown>
          </div>
        </div>
        <div
          class="field col-12"
          *ngIf="dataToCopy === CopyDataTypeEnum.All || dataToCopy === CopyDataTypeEnum.Templates"
        >
          <label>{{ t("demo.templatesToCopy") }}</label>
          <div class="p-inputgroup">
            <p-multiSelect
              [options]="documentTemplateOptions$ | async"
              [(ngModel)]="selectedDocumentTemplates"
              [style]="{ width: '100%' }"
              [filter]="true"
              [showClear]="true"
            ></p-multiSelect>
          </div>
        </div>
        <button
          [disabled]="
            (dataToCopy === CopyDataTypeEnum.All || dataToCopy === CopyDataTypeEnum.Templates) &&
            selectedDocumentTemplates?.length === 0
          "
          class="col-4"
          submitOnEnter
          type="button"
          pButton
          [label]="t('demo.copyData')"
          (click)="copyData()"
        ></button>
      </div>
      <p-divider></p-divider>
      <div>
        <button
          submitOnEnter
          type="button"
          pButton
          [label]="t('demo.makeDemoTenant')"
          (click)="makeDemoTenant()"
        ></button>
      </div>
    </ng-template>
  </p-overlayPanel>

  <app-import-result-dialog
    *ngIf="xlsxImportResult"
    [xlsxImportResult]="xlsxImportResult"
    (closed)="xlsxImportResult = null"
  >
  </app-import-result-dialog>
</ng-container>
