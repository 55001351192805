<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <label for="targetRecognition">
    {{ t("targetRecognition.title") }}
    <i class="pi pi-info-circle ml-2" [pTooltip]="t('targetRecognition.tooltip')"></i>
  </label>

  <p-dropdown
    inputId="targetRecognition"
    [options]="targetRecognitions"
    [formControl]="control"
    [showClear]="true"
    [readonly]="readonly"
    [placeholder]="t('targetRecognition.placeholder')"
  ></p-dropdown>

  <small class="p-error" *ngIf="control.errors?.required">
    {{ t("targetRecognition.required") }}
  </small>
</ng-container>
