import { Injectable } from "@angular/core";
import { OrganizationContactForSelectionFragment } from "@ankaadia/graphql";
import { Observable, forkJoin, map, of } from "rxjs";
import { OrganizationContactSettingsService } from "../organization-contacts/organization-contact-settings.service";
import { OrganizationContactsService } from "../organization-contacts/organization-contacts.service";

export interface CandidateOrganizationContactsData {
  contacts: OrganizationContactForSelectionFragment[];
  representative: {
    placeholder: string;
    default: OrganizationContactForSelectionFragment | null;
  };
  firstDeputy: {
    placeholder: string;
    default: OrganizationContactForSelectionFragment | null;
  };
}

@Injectable({ providedIn: "root" })
export class CandidateOrganizationContactsService {
  constructor(
    private readonly contactService: OrganizationContactsService,
    private readonly contactSettingsService: OrganizationContactSettingsService
  ) {}

  getContacts(organizationId: string): Observable<CandidateOrganizationContactsData> {
    return organizationId
      ? forkJoin([
          this.contactService.getForSelection(organizationId, true),
          this.contactSettingsService.get(organizationId, true),
        ]).pipe(
          map(([contacts, settings]) => {
            const defaultRepresentative = this.findIfNotNull(contacts, settings?.defaultRepresentative);
            const representativePlaceholder = this.toPlaceholder(defaultRepresentative);

            const defaultFirstDeputy = this.findIfNotNull(contacts, settings?.defaultFirstDeputy);
            const firstDeputyPlaceholder = this.toPlaceholder(defaultFirstDeputy);

            return {
              contacts: contacts,
              representative: {
                placeholder: representativePlaceholder,
                default: defaultRepresentative,
              },
              firstDeputy: {
                placeholder: firstDeputyPlaceholder,
                default: defaultFirstDeputy,
              },
            };
          })
        )
      : of({ contacts: [], representative: null, firstDeputy: null });
  }

  private findIfNotNull(
    contacts: OrganizationContactForSelectionFragment[],
    idOrNull: string
  ): OrganizationContactForSelectionFragment | null {
    return idOrNull ? contacts.find((x) => x.id === idOrNull) : null;
  }

  private toPlaceholder(contact: OrganizationContactForSelectionFragment): string {
    return contact ? `${contact.lastName}, ${contact.firstName}` : null;
  }
}
