<app-education-exam-results-table
  *appTranslate="let t"
  [exams]="exams"
  [examResults]="examResults$ | async"
  [isEditable]="false"
  [shownColumns]="[
    'examDateAndTime',
    'candidateDisplayId',
    'candidateName',
    'languageLevel',
    'examResult',
    'passedExamModules',
    'examModuleInformation',
  ]"
>
  <ng-template #caption>
    <p-dropdown
      [formControl]="selectedExam"
      [options]="exams"
      optionLabel="name"
      [optionValue]="null"
      styleClass="mw-500 mr-2"
      [placeholder]="t('educationExams.placeholder')"
    >
    </p-dropdown>
  </ng-template>
</app-education-exam-results-table>
