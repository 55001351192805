import { Component, computed, input } from "@angular/core";
import { ContractFormService, PaymentSettingsForm, PaymentTrancheForm } from "../contract-form.service";
import { ContractDateComponent } from "../contract-date/contract-date.component";
import { SharedModule } from "../../../../shared/shared.module";
import { DiscountType, PaymentPeriod, PaymentTime, StaticDataModel } from "@ankaadia/graphql";
import { FormArray } from "@angular/forms";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";
import { ContractDayOfYearComponent } from "../contract-day-of-year/contract-day-of-year.component";
import { AppDatePipe } from "../../../../shared/pipes/date.pipe";
import { CalendarUtcFixDirective } from "../../../../shared/primeng/calendar-utc-fix/calendar-utc-fix.directive";

@Component({
  standalone: true,
  selector: "app-payment-settings-form",
  templateUrl: "./payment-settings-form.component.html",
  imports: [ContractDateComponent, SharedModule, ContractDayOfYearComponent],
  providers: [ContractFormService],
})
export class PaymentSettingsFormComponent {
  protected readonly DiscountType = DiscountType;
  protected readonly PaymentPeriod = PaymentPeriod;

  form = input.required<PaymentSettingsForm>();

  paymentTranches = computed(() => this.form().get("paymentTranches") as FormArray<PaymentTrancheForm>);

  targetRow: PaymentTrancheForm;
  editRow: PaymentTrancheForm;
  showDialog = false;

  paymentPeriods: StaticDataModel[];
  paymentTimes: StaticDataModel[];

  constructor(
    staticDataService: StaticDataService,
    private readonly contractFormService: ContractFormService,
    private readonly datePipe: AppDatePipe
  ) {
    this.paymentPeriods = staticDataService.transformEnumToStaticDataModel("PaymentPeriod", PaymentPeriod);
    this.paymentTimes = staticDataService.transformEnumToStaticDataModel("PaymentTime", PaymentTime);
  }

  addPaymentTranche(): void {
    this.targetRow = null;
    this.editRow = this.contractFormService.createPaymentTrancheForm();

    this.showDialog = true;
  }

  editPaymentTranche(targetRow: PaymentTrancheForm): void {
    this.targetRow = targetRow;
    this.editRow = this.contractFormService.createPaymentTrancheForm();
    this.editRow.setValue(targetRow.getRawValue());

    this.showDialog = true;
  }

  savePaymentTranche(): void {
    if (!this.editRow.valid) return;

    if (this.targetRow) {
      this.targetRow.setValue(this.editRow.getRawValue());
      this.endEdit();
      return;
    }

    this.paymentTranches().push(this.editRow);
    this.endEdit();
  }

  endEdit(): void {
    this.targetRow = null;
    this.editRow = null;
    this.showDialog = false;
  }

  removePaymentTrancheAt(index: number): void {
    this.paymentTranches().removeAt(index);
  }

  formatDueDate(dueDate: { day: number; month: number }): string {
    if (!dueDate) return "";

    const utcDate = CalendarUtcFixDirective.localDateTimeWhichIsUTCDateValueToLocalDateTimeWhichRepresentsUTCDate(
      new Date(2024, dueDate.month, dueDate.day)
    ) as Date;

    return this.datePipe.transform(utcDate, { day: "2-digit", month: "long" });
  }
}
