<ng-container *appTranslate="let t">
  <form [formGroup]="form">
    <div class="flex flex-row gap-3 align-items-center mb-3">
      <div class="flex-1 p-fluid grid formgrid">
        <div class="field col-12 lg:col-3">
          <label for="examType">{{ t("qualificationExam.examType.title") }}</label>
          <p-dropdown
            [inputId]="'examType#' + index"
            [options]="$qualificationMeasures | async"
            filterBy="label"
            formControlName="examType"
            [readonly]="readonly"
            [required]="true"
            [placeholder]="t('qualificationExam.examType.placeholder')"
          ></p-dropdown>
          <small class="p-error" *ngIf="form.controls.examType.errors?.required">
            {{ t("qualificationExam.examType.required") }}
          </small>
        </div>

        <ng-container *ngIf="!isAdaptation()">
          <ng-container *ngTemplateOutlet="exam"></ng-container>
        </ng-container>
        <ng-container *ngIf="isAdaptation()">
          <ng-container *ngTemplateOutlet="adaptation"></ng-container>
        </ng-container>
      </div>
      <div>
        <p-button icon="pi pi-times" [disabled]="readonly" (onClick)="deleteClicked.emit()"></p-button>
      </div>
    </div>

    <ng-template #adaptation>
      <div class="field col-12 lg:col-3">
        <label for="firstExamDate">{{ t("qualificationExam.finalInterview.date") }}</label>
        <p-calendar
          [inputId]="'firstExamDate#' + index"
          formControlName="firstExamDate"
          [readonly]="readonly"
        ></p-calendar>
      </div>
      <div class="field col-12 lg:col-3">
        <label for="firstExamPassed">{{ t("qualificationExam.finalInterview.result") }}</label>
        <p-dropdown
          [inputId]="'firstExamPassed#' + index"
          [options]="$examResult | async"
          filterBy="label"
          formControlName="firstExamPassed"
          [readonly]="readonly"
          [placeholder]="t('qualificationExam.finalInterview.resultPlaceholder')"
        ></p-dropdown>
      </div>
      <div class="field col-12 lg:col-3">
        <label [for]="'examArea#' + index">{{ t("qualificationExam.finalInterview.area") }}</label>
        <input pInputText [id]="'examArea#' + index" formControlName="examArea" [readonly]="readonly" />
      </div>
      <div class="field col-12 lg:col-3">
        <label [for]="'moduleName#' + index">{{ t("qualificationExam.finalInterview.module") }}</label>
        <p-autoComplete
          [inputId]="'moduleName#' + index"
          formControlName="moduleName"
          [suggestions]="moduleSuggestions"
          (completeMethod)="search($event)"
        ></p-autoComplete>
      </div>
    </ng-template>
    <ng-template #exam>
      <div class="field col-12 lg:col-3">
        <label for="firstExamDate">{{ t("qualificationExam.firstExam.date") }}</label>
        <p-calendar
          [inputId]="'firstExamDate#' + index"
          formControlName="firstExamDate"
          [readonly]="readonly"
        ></p-calendar>
      </div>
      <div class="field col-12 lg:col-3">
        <label for="firstExamPassed">{{ t("qualificationExam.firstExam.result") }}</label>
        <p-dropdown
          [inputId]="'firstExamPassed#' + index"
          [options]="$examResult | async"
          filterBy="label"
          formControlName="firstExamPassed"
          [readonly]="readonly"
          [placeholder]="t('qualificationExam.firstExam.resultPlaceholder')"
        ></p-dropdown>
      </div>
      <div class="field col-12 lg:col-3">
        <label for="secondExamDate">{{ t("qualificationExam.secondExam.date") }}</label>
        <p-calendar
          [inputId]="'secondExamDate#' + index"
          formControlName="secondExamDate"
          [readonly]="readonly"
        ></p-calendar>
      </div>
      <div class="field col-12 lg:col-3">
        <label for="secondExamPassed">{{ t("qualificationExam.secondExam.result") }}</label>
        <p-dropdown
          [inputId]="'secondExamPassed#' + index"
          [options]="$examResult | async"
          filterBy="label"
          formControlName="secondExamPassed"
          [readonly]="readonly"
          [placeholder]="t('qualificationExam.secondExam.resultPlaceholder')"
        ></p-dropdown>
      </div>
    </ng-template>
  </form>
</ng-container>
