import { Component, Input } from "@angular/core";
import { HealthProfessionsRegisterFormAt } from "../../../candidate-form.model";

@Component({
  selector: "app-candidate-health-professions-register-at",
  templateUrl: "./candidate-health-professions-register-at.component.html",
  standalone: false,
})
export class CandidateHealthProfessionsRegisterAtComponent {
  @Input({ required: true })
  form: HealthProfessionsRegisterFormAt;

  @Input()
  readonly: boolean;
}
