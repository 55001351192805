<app-education-exam-results-table
  *appTranslate="let t"
  [examResults]="examResults$ | async"
  [exams]="exams$ | async"
  [isEditable]="hasFullAccess"
  [title]="title$ | async"
  [onlyClearOnDeleteIfParticipant]="true"
  [shownColumns]="[
    'actions',
    'candidateDisplayId',
    'candidateName',
    'languageLevel',
    'examResult',
    'passedExamModules',
    'examModuleInformation',
  ]"
>
  <ng-template #caption>
    <p-dropdown
      [formControl]="selectedExam"
      [options]="exams$ | async"
      optionLabel="name"
      styleClass="mw-500 mr-2"
      [placeholder]="t('educationExams.placeholder')"
    >
      <ng-template let-exam pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>
            {{ exam.name }}
            {{
              exam.examDateAndTime?.hasTime
                ? (exam.examDateAndTime?.date | appDateTime: { dateStyle: "short", timeStyle: "short" })
                : (exam.examDateAndTime?.date | appDate: { dateStyle: "short" })
            }}
          </div>
        </div>
      </ng-template>
    </p-dropdown>
  </ng-template>
</app-education-exam-results-table>
