import { Injectable } from "@angular/core";
import { groupBy, toPairs } from "lodash";
import { TreeNode } from "primeng/api";
import { forkJoin, map, Observable } from "rxjs";
import { SupportedImmigrationCountry } from "../../../../_generated/graphql";
import { CandidateFilterField } from "../../../candidate-filter/candidate-filter.model";
import { CandidateFilterService } from "../../../candidate-filter/candidate-filter.service";

@Injectable({ providedIn: "root" })
export class CollectionAutoFilterFieldsService {
  constructor(private readonly candidateFilterService: CandidateFilterService) {}

  getFields(): Observable<TreeNode<CandidateFilterField>[]> {
    return forkJoin([
      this.candidateFilterService.immigrationCountries,
      this.candidateFilterService.getFilterFields(),
    ]).pipe(
      map(([countries, groups]) => {
        return groups.map((group) => ({
          icon: group.country ? "flag flag-small flag-" + group.country.toLowerCase() : null,
          label: group.label,
          selectable: false,
          children: toPairs(groupBy(group.fields, (field) => field.country ?? SupportedImmigrationCountry.Unknown))
            .map(([country, fields]) => {
              const treeNodes = fields.map((field) => ({ label: field.label, data: field }));
              if (country === SupportedImmigrationCountry.Unknown) {
                return treeNodes;
              }

              const icon: string | undefined = country ? "flag flag-small flag-" + country.toLowerCase() : undefined;
              const label = countries.find((c) => c.value === country)?.label ?? country;
              return [{ icon, label, selectable: false, children: treeNodes }];
            })
            .flat(),
        })) as TreeNode<CandidateFilterField>[];
      })
    );
  }
}
