import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { CandidateEventsModule } from "../candidate-events/candidate-events.module";
import { EducationCourseDialogComponent } from "./education-courses/education-course-dialog/education-course-dialog.component";
import { EducationCourseEventsComponent } from "./education-courses/education-course-events/education-course-events.component";
import { EducationCourseExamResultsComponent } from "./education-courses/education-course-exam-results/education-course-exam-results.component";
import { EducationCoursePerformancesComponent } from "./education-courses/education-course-performances/education-course-performances.component";
import { EducationCourseSelectorComponent } from "./education-courses/education-course-selector/education-course-selector.component";
import { EducationCourseTableComponent } from "./education-courses/education-course-table/education-course-table.component";
import { EducationCourseViewComponent } from "./education-courses/education-course-view/education-course-view.component";
import { EducationCoursesComponent } from "./education-courses/education-courses.component";
import { EducationExamParticipantsDialogComponent } from "./education-exam-participants/education-exam-participants-dialog/education-exam-participants-dialog.component";
import { EducationExamParticipantsComponent } from "./education-exam-participants/education-exam-participants.component";
import { EducationExamResultsTableComponent } from "./education-exam-results/education-exam-results-table/education-exam-results-table.component";
import { EducationExamResultsComponent } from "./education-exam-results/education-exam-results.component";
import { EducationExamAdHocDialogComponent } from "./education-exams/education-exam-ad-hoc-dialog/education-exam-ad-hoc-dialog.component";
import { EducationExamDialogAdHocFieldsComponent } from "./education-exams/education-exam-dialog-ad-hoc-fields/education-exam-dialog-ad-hoc-fields.component";
import { EducationExamDialogEducationFieldsComponent } from "./education-exams/education-exam-dialog-education-fields/education-exam-dialog-education-fields.component";
import { EducationExamDialogGeneralFieldsComponent } from "./education-exams/education-exam-dialog-general-fields/education-exam-dialog-general-fields.component";
import { EducationExamDialogLanguageLearningFieldsComponent } from "./education-exams/education-exam-dialog-languange-learning-fields/education-exam-dialog-language-learning-fields.component";
import { EducationExamDialogComponent } from "./education-exams/education-exam-dialog/education-exam-dialog.component";
import { EducationExamTableComponent } from "./education-exams/education-exam-table/education-exam-table.component";
import { EducationExamsComponent } from "./education-exams/education-exams.component";
import { EducationModuleDialogComponent } from "./education-modules/education-module-dialog/education-module-dialog.component";
import { EducationModulesComponent } from "./education-modules/education-modules.component";
import { EducationOverviewComponent } from "./education-overview/education-overview.component";
import { EducationModuleSelectorComponent } from "./education-modules/education-module-selector/education-module-selector.component";
import { CandidateFlexibleEditButtonComponent } from "../candidates/candidate-flexible-edit/candidate-flexible-edit-button/candidate-flexible-edit-button.component";

@NgModule({
  imports: [SharedModule, CandidateEventsModule, CandidateFlexibleEditButtonComponent],
  declarations: [
    EducationModulesComponent,
    EducationModuleDialogComponent,
    EducationCoursesComponent,
    EducationCourseDialogComponent,
    EducationCourseSelectorComponent,
    EducationCourseViewComponent,
    EducationExamsComponent,
    EducationExamDialogComponent,
    EducationExamResultsComponent,
    EducationExamResultsTableComponent,
    EducationCourseExamResultsComponent,
    EducationExamTableComponent,
    EducationCoursePerformancesComponent,
    EducationOverviewComponent,
    EducationCourseTableComponent,
    EducationCourseEventsComponent,
    EducationExamDialogGeneralFieldsComponent,
    EducationExamDialogLanguageLearningFieldsComponent,
    EducationExamDialogEducationFieldsComponent,
    EducationExamDialogAdHocFieldsComponent,
    EducationExamAdHocDialogComponent,
    EducationExamParticipantsComponent,
    EducationExamParticipantsDialogComponent,
    EducationModuleSelectorComponent,
  ],
  exports: [EducationExamTableComponent, EducationExamResultsTableComponent],
})
export class EducationModule {}
