<button
  *appTranslate="let t"
  pButton
  type="button"
  icon="pi pi-star{{ isActive ? '-fill' : '' }}"
  tabIndex="-1"
  class="toggle p-button-rounded p-button-text ml-2"
  [pTooltip]="t('favoriteCandidates.title')"
  [class.p-button-active]="isActive"
  (click)="toggle()"
></button>
