<app-table
  [columns]="columns"
  [items]="sharings"
  [captionOperations]="captionOperations"
  [tableOperations]="tableOperations"
  [(showSidebar)]="showDialog"
  [sidebarSize]="sharingToShowActivitiesFor ? 'large' : 'medium'"
  data-testid="table-app-collection-sharing"
>
  <app-sharing-edit-dialog
    *ngIf="showDialog && !sharingToShowActivitiesFor"
    (closed)="saveSharing($event)"
    [sharingOrTemplate]="sharingToEdit"
    [sharingProvider]="sharingProvider"
    [showManageCollectionMembers]="showManageCollectionMembers"
  ></app-sharing-edit-dialog>

  <app-sharing-activities
    *ngIf="showDialog && sharingToShowActivitiesFor"
    [sharing]="sharingToShowActivitiesFor"
    (closed)="closeSidebar(null)"
  ></app-sharing-activities>
</app-table>
