<ng-container *appTranslate="let t">
  <form [formGroup]="form" styleClass="relative">
    <p-fieldset [legend]="t('vocationalSchoolTrainingTime.title')" class="relative">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 lg:col-3">
          <label for="trainingTimeInYearsTotal">{{ t("vocationalSchoolTrainingTimeInYearsTotal.title") }}</label>
          <p-dropdown
            id="trainingTimeInYearsTotal"
            formControlName="trainingTimeInYearsTotal"
            [options]="trainingTypeInYearsTotalOptions$ | async"
            [placeholder]="t('vocationalSchoolTrainingTimeInYearsTotal.placeholder')"
            [showClear]="true"
            [readonly]="readonly"
          ></p-dropdown>
        </div>

        <div class="field col-12 lg:col-3 pt-5">
          <div class="field-checkbox pt-2">
            <p-checkbox
              inputId="trainingTimeInPartTime"
              [binary]="true"
              [readonly]="readonly"
              formControlName="trainingTimeInPartTime"
            ></p-checkbox>
            <label for="trainingTimeInPartTime">{{ t("vocationalSchoolTrainingTimeInPartTime.title") }}</label>
          </div>
        </div>

        <div class="field col-12 lg:col-3 pt-5">
          <div class="field-checkbox pt-2">
            <p-checkbox
              inputId="trainingTimeShortened"
              [binary]="true"
              [readonly]="readonly"
              formControlName="trainingTimeShortened"
            ></p-checkbox>
            <label for="trainingTimeShortened">{{ t("vocationalSchoolTrainingTimeShortened.title") }}</label>
          </div>
        </div>

        <div class="field col-12 lg:col-3">
          <label for="currentTrainingYear">{{ t("vocationalSchoolCurrentTrainingYear.title") }}</label>
          <p-dropdown
            id="currentTrainingYear"
            formControlName="currentTrainingYear"
            [options]="currentTrainingYearOptions$ | async"
            [placeholder]="t('vocationalSchoolCurrentTrainingYear.placeholder')"
            [showClear]="true"
            [readonly]="readonly"
          ></p-dropdown>
        </div>

        <app-candidate-document-date
          [inputId]="'startDate'"
          [candidateId]="candidateId"
          [candidateOrganizationId]="candidateOrganizationId"
          [candidateForm]="form"
          [readonly]="readonly"
          [disabled]="isMultiEditMode"
          [label]="t('vocationalSchoolStartDate.title')"
          [selectedSet]="{ documentSetType: 'SignedByAllParties' }"
          documentType="TRAININGCONTRACT"
          dateField="validFrom"
          class="field col-12 lg:col-4"
        ></app-candidate-document-date>

        <div class="field col-12 lg:col-4">
          <app-candidate-aligned-label
            labelFor="expectedEndDate"
            [labelText]="t('vocationalSchoolExpectedEndDate.title')"
            [useAlignmentCheckBox]="true"
          ></app-candidate-aligned-label>
          <p-calendar id="expectedEndDate" formControlName="expectedEndDate" [readonly]="readonly"></p-calendar>
        </div>

        <div class="field col-12 lg:col-4">
          <app-candidate-aligned-label
            labelFor="endDate"
            [labelText]="t('vocationalSchoolEndDate.title')"
            [useAlignmentCheckBox]="true"
          ></app-candidate-aligned-label>
          <p-calendar id="endDate" formControlName="endDate" [readonly]="readonly"></p-calendar>
        </div>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('vocationalSchoolSchool.title')" class="relative">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 lg:col-6">
          <label for="schoolName">{{ t("vocationalSchoolSchoolName.title") }}</label>
          <input id="schoolName" pInputText formControlName="schoolName" maxlength="300" [readonly]="readonly" />
        </div>

        <div class="field col-12 lg:col-6">
          <label for="schoolCourse">{{ t("vocationalSchoolSchoolCourse.title") }}</label>
          <input id="schoolCourse" pInputText formControlName="schoolCourse" maxlength="300" [readonly]="readonly" />
        </div>

        <div class="field col-12 lg:col-12">
          <label for="address">{{ t("vocationalSchoolAddress.title") }}</label>
          <textarea
            id="address"
            pInputTextarea
            rows="6"
            formControlName="address"
            maxlength="500"
            [readonly]="readonly"
          ></textarea>

          <small class="p-error" *ngIf="form.controls.address?.errors?.required">
            {{ t("address.required") }}
          </small>
        </div>

        <div class="field col-12 lg:col-3">
          <label for="zipCode">{{ t("zipCode.title") }}</label>
          <input id="zipCode" pInputText formControlName="zipCode" maxlength="100" [readonly]="readonly" />

          <small class="p-error" *ngIf="form.controls.zipCode?.errors?.required">
            {{ t("zipCode.required") }}
          </small>
        </div>

        <div class="field col-12 lg:col-3">
          <label for="city">{{ t("city.title") }}</label>
          <input id="city" pInputText formControlName="city" maxlength="100" [readonly]="readonly" />

          <small class="p-error" *ngIf="form.controls?.city.errors?.required">
            {{ t("city.required") }}
          </small>
        </div>

        <div class="field col-12 lg:col-3">
          <label for="federalState">{{ t("federalState.title") }}</label>
          <input id="federalState" pInputText formControlName="federalState" maxlength="100" [readonly]="readonly" />

          <small class="p-error" *ngIf="form.controls?.federalState.errors?.required">
            {{ t("city.required") }}
          </small>
        </div>

        <div class="field col-12 lg:col-3">
          <label for="country">{{ t("country.title") }}</label>
          <p-dropdown
            id="country"
            formControlName="country"
            [options]="countries | async"
            [placeholder]="t('country.placeholder')"
            [showClear]="true"
            [readonly]="readonly"
          ></p-dropdown>

          <small class="p-error" *ngIf="form.controls.country?.errors?.required">
            {{ t("country.required") }}
          </small>
        </div>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('vocationalSchoolMedicalCheck.title')" class="relative">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 lg:col-6">
          <div class="field-checkbox">
            <p-checkbox
              inputId="medicalCheckAttended"
              [binary]="true"
              [readonly]="readonly"
              formControlName="medicalCheckAttended"
            ></p-checkbox>
            <label for="medicalCheckAttended">{{ t("medicalCheckAttended.title") }}</label>
          </div>

          <p-calendar formControlName="medicalCheckAttendedDate" [maxDate]="today" [readonly]="readonly"></p-calendar>
        </div>

        <div class="field col-12 lg:col-6">
          <div class="field-checkbox">
            <p-checkbox
              inputId="medicalCheckPassed"
              [binary]="true"
              formControlName="medicalCheckPassed"
              [readonly]="readonly"
            ></p-checkbox>
            <label for="medicalCheckPassed">{{ t("medicalCheckPassed.title") }}</label>
          </div>

          <p-calendar formControlName="medicalCheckPassedDate" [maxDate]="today" [readonly]="readonly"></p-calendar>
        </div>
      </div>
    </p-fieldset>
  </form>
</ng-container>
