import { AfterViewInit, Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({ selector: "textarea[commentBox]", standalone: false })
export class CommentBoxDirective implements AfterViewInit {
  @Input()
  autofocus: boolean;

  constructor(private readonly element: ElementRef<HTMLTextAreaElement>) {}

  ngAfterViewInit(): void {
    this.resize();
    if (this.autofocus) {
      this.element.nativeElement.focus();
      this.element.nativeElement.setSelectionRange(0, 0);
      setTimeout(() => this.element.nativeElement.scrollIntoView(false), 0);
    }
  }

  @HostListener("input")
  resize(): void {
    this.element.nativeElement.style.height = "auto";
    this.element.nativeElement.style.height = this.element.nativeElement.scrollHeight + 2 + "px";
  }
}
