import { ChangeDetectionStrategy, Component, HostListener } from "@angular/core";
import { map } from "rxjs";
import { DocumentTemplateStatusIcons } from "../../document-template/document-templates.model";
import { MessageService } from "../message.service";

@Component({
  selector: "app-message-success",
  templateUrl: "./message-success.component.html",
  styleUrl: "./message-success.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MessageSuccessComponent {
  readonly message$ = this.messageService.message$.pipe(
    map((x) => (!x || x.severity === "success" || x.severity === "warning" ? x : null))
  );

  readonly Icons = {
    success: DocumentTemplateStatusIcons.Success,
    warning: DocumentTemplateStatusIcons.Warning,
  };

  constructor(private readonly messageService: MessageService) {}

  @HostListener("mouseenter")
  onMouseEnter(): void {
    this.messageService.pause();
  }

  @HostListener("mouseleave")
  onMouseLeave(): void {
    this.messageService.resume();
  }
}
