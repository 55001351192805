<ng-container *appTranslate="let t">
  <app-table
    [items]="modules$ | async"
    [title]="t('educationModules.title')"
    [columns]="columns"
    [newOperations]="newOperations"
    [tableOperations]="tableOperations"
    [(showSidebar)]="showSidebar"
  >
    <ng-template #caption>
      <p-multiSelect
        [options]="statuses"
        [ngModel]="selectedStatuses$ | async"
        [placeholder]="t('status.placeholder')"
        styleClass="mw-250 mr-2"
        (onChange)="selectedStatuses$.next($event.value)"
      ></p-multiSelect>
    </ng-template>

    <app-education-module-dialog
      *ngIf="showSidebar"
      [module]="selectedModule.edit"
      (created)="create($event)"
      (updated)="update($event)"
      (closed)="close()"
    ></app-education-module-dialog>
  </app-table>
</ng-container>
