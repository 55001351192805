import { Injectable } from "@angular/core";
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { declaredDocumentSetTypes, hasDeclaredDocumentSetTypes } from "@ankaadia/ankaadia-shared";
import { DocumentRequirement } from "@ankaadia/graphql";
import { intersection, isEmpty } from "lodash";
import { valuesOf } from "../../shared/services/form.helper";
import { DocumentRequirementForm, DocumentRequirementsForm } from "./document-requirement-form.model";

@Injectable({ providedIn: "root" })
export class DocumentRequirementFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createForm(documentRequirements?: DocumentRequirement[]): DocumentRequirementsForm {
    const requirement = documentRequirements ?? [];
    return this.formBuilder.group<DocumentRequirementsForm["controls"]>({
      requirements: this.formBuilder.array<DocumentRequirementForm>(
        requirement.map((requirement) => this.createRequirementForm(requirement))
      ),
    });
  }

  createRequirementForm(requirement: DocumentRequirement): DocumentRequirementForm {
    const form = this.formBuilder.group<DocumentRequirementForm["controls"]>({
      id: this.formBuilder.control(requirement.id),
      _etag: this.formBuilder.control(requirement._etag),
      organizationId: this.formBuilder.control(requirement.organizationId, Validators.required),
      type: this.formBuilder.control(requirement.type, Validators.required),
      checkSpecificSets: this.formBuilder.control(requirement.checkSpecificSets, Validators.required),
      documentSetTypes: this.formBuilder.control(requirement.documentSetTypes, this.buildDocumentSetTypesValidator()),
      completionState: this.formBuilder.control(requirement.completionState, Validators.required),
      comment: this.formBuilder.control(requirement.comment, Validators.required),
      isInternal: this.formBuilder.control(requirement.isInternal, Validators.required),
      validFrom: this.formBuilder.control(requirement.validFrom, Validators.required),
      validUntil: this.formBuilder.control(requirement.validUntil, Validators.required),
      issueDate: this.formBuilder.control(requirement.issueDate, Validators.required),
      dateOfReceipt: this.formBuilder.control(requirement.dateOfReceipt, Validators.required),
      resubmissionDate: this.formBuilder.control(requirement.resubmissionDate, Validators.required),
      resubmissionReason: this.formBuilder.control(requirement.resubmissionReason, Validators.required),
      physicalTypes: this.formBuilder.control(requirement.physicalTypes, Validators.required),
      files: this.formBuilder.control(requirement.files, Validators.required),
      changedAt: this.formBuilder.control(requirement.changedAt),
      changedBy: this.formBuilder.control(requirement.changedBy),
    });

    valuesOf(form.controls.type).subscribe(() => {
      this.updateDocumentSetTypesControlState(form);
    });

    valuesOf(form.controls.checkSpecificSets).subscribe(() => {
      this.updateDocumentSetTypesControlState(form);
    });

    valuesOf(form.controls.documentSetTypes).subscribe((value) => {
      if (isEmpty(value)) {
        form.controls.documentSetTypes.setValue(null, { emitEvent: false });
      }
    });

    valuesOf(form.controls.resubmissionDate).subscribe((value) => {
      if (value === true) {
        form.controls.resubmissionReason.enable();
      } else {
        form.controls.resubmissionReason.setValue(false, { emitEvent: false });
        form.controls.resubmissionReason.disable();
      }
    });

    return form;
  }

  private updateDocumentSetTypesControlState(form: DocumentRequirementForm): void {
    const control = form.controls.documentSetTypes;
    const checkSpecificSets = form.controls.checkSpecificSets.value;
    if (!checkSpecificSets) {
      control.setValue(null);
      control.disable();
      return;
    }

    const documentType = form.controls.type.value;
    if (hasDeclaredDocumentSetTypes(documentType)) {
      const validTypes = intersection(declaredDocumentSetTypes(documentType), control.value ?? []);
      if (isEmpty(validTypes)) {
        control.setValue(null);
      } else {
        control.setValue(validTypes);
      }
      control.enable();
    } else {
      control.setValue(null);
      control.disable();
    }
  }

  private buildDocumentSetTypesValidator(): ValidatorFn {
    return (control: DocumentRequirementForm["controls"]["documentSetTypes"]): ValidationErrors | null => {
      const root = control.parent as DocumentRequirementForm;
      if (!root?.controls?.checkSpecificSets) {
        return Validators.nullValidator(control);
      }

      return root.controls.checkSpecificSets.value
        ? Validators.nullValidator(control)
        : this.forbiddenValidator(control);
    };
  }

  private forbiddenValidator(control: AbstractControl): ValidationErrors | null {
    return control.value ? { forbidden: true } : null;
  }
}
