import { Component, Input, OnDestroy } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { OrganizationsWithBeds } from "@ankaadia/ankaadia-shared";
import { OrganizationProfileFields, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { Subscription, debounceTime, filter, switchMap, tap } from "rxjs";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";
import { GeographyService } from "../../../geography/geography.service";

@Component({
  selector: "app-organization-profile-location",
  templateUrl: "./organization-profile-location.component.html",
  standalone: false,
})
export class OrganizationProfileLocationComponent implements OnDestroy {
  private zipcodeSub: Subscription;

  readonly StaticDataType = StaticDataType;
  readonly language = this.transloco.getActiveLang();
  readonly careFacilities = this.staticData.getStaticData(StaticDataType.CareFacilities, this.language);
  readonly experienceFields = this.staticData.getStaticData(StaticDataType.ProfessionalFields, this.language);

  private _form: FormGroup;

  @Input()
  organizationId: string;

  @Input()
  fields: OrganizationProfileFields;

  @Input()
  get form(): FormGroup {
    return this._form;
  }

  set form(f: FormGroup) {
    this.zipcodeSub?.unsubscribe();
    this._form = f;

    if (f == null) {
      return;
    }

    this.zipcodeSub = this._form.controls.zipcode.valueChanges
      .pipe(
        debounceTime(200),
        tap(() => {
          this._form.controls.city.setValue(null);
          this._form.controls.state.setValue(null);
        }),
        filter((x) => x.length === 5),
        switchMap((zipcode) => this.geographyService.detectZipCode("DE", zipcode))
      )
      .subscribe((geoPoint) => {
        this._form.controls.city.setValue(geoPoint?.city ?? null);
        this._form.controls.state.setValue(geoPoint?.state ?? null);
        if (!geoPoint) {
          this._form.controls.zipcode.setErrors({ invalid: true });
        }
      });
  }

  get showBeds(): boolean {
    const careFacilities = this._form.controls.careFacility.value as string[];
    return careFacilities?.some((facility) => OrganizationsWithBeds.includes(facility)) ?? false;
  }

  constructor(
    private readonly staticData: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly geographyService: GeographyService
  ) {}

  ngOnDestroy(): void {
    this.zipcodeSub.unsubscribe();
  }
}
