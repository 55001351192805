import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { CandidateRole, getAllHousingAcquisitionRoles } from "@ankaadia/ankaadia-shared";
import { Housing, HousingAcquisition, SupportedImmigrationCountry } from "@ankaadia/graphql";
import { assign } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { StaticDataContextEntry } from "../../../../shared/static-data/static-data.service";
import { ResponsibleRolePurpose } from "../../../candidate-reusables/candidate-responsible-representative-group/candidate-responsible-representative-group.service";
import { HousingAcquisitionForm } from "../../candidate-form.model";
import { CandidateFormService } from "../../candidate-form.service";

@Component({
  selector: "app-candidate-housing-acquisition",
  templateUrl: "./candidate-housing-acquisition.component.html",
  standalone: false,
})
export class CandidateHousingAcquisitionComponent implements OnChanges {
  protected readonly today = new Date();
  protected readonly ResponsibleRolePurpose = ResponsibleRolePurpose;
  protected readonly allowedRoles = getAllHousingAcquisitionRoles();
  protected staticDataContext: StaticDataContextEntry;

  @Input({ required: true })
  protected form: HousingAcquisitionForm;

  @Input({ required: true })
  housingAcquisition: HousingAcquisition;

  @Input({ required: true })
  protected candidateId: string;

  @Input({ required: true })
  protected candidateOrganizationId: string;

  @Input({ required: true })
  protected readonly: boolean;

  protected get canHaveADeputyOrRepresentative(): boolean {
    const selectedRole = this.form.controls?.responsibleRoleHousingAcquisition?.value;
    return selectedRole && selectedRole !== CandidateRole;
  }

  constructor(private readonly candidateFormService: CandidateFormService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateOrganizationId) {
      this.staticDataContext = {
        immigrationCountry: SupportedImmigrationCountry.De,
        organizationId: this.candidateOrganizationId,
      };
    }
  }

  protected addHousing(): void {
    this.form.controls.housings.push(
      this.candidateFormService.createHousingGroup(assign(new Housing(), { id: uuidv4() }))
    );
  }

  protected removeHousing(index: number): void {
    this.form.controls.housings.removeAt(index);
  }
}
