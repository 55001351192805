<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <ng-container *ngIf="control">
    <label for="{{ formId }}-firstDeputyId">{{ t("employerFirstDeputy.title") }}</label>
    <p-dropdown
      inputId="{{ formId }}-firstDeputyId"
      [options]="employerContacts"
      optionValue="id"
      filterBy="firstName,lastName"
      [formControl]="control"
      [showClear]="true"
      [readonly]="readonly"
      [placeholder]="employerFirstDeputyPlaceholder ?? t('employerFirstDeputy.placeholder')"
      appCandidateFieldNotShared
      formControlValueAlwaysInOptions
      [isShared]="isShared"
    >
      <ng-template *ngFor="let template of ['item', 'selectedItem']" let-item [pTemplate]="template">
        <span>{{ item.lastName }}, {{ item.firstName }}</span>
      </ng-template>
    </p-dropdown>

    <small class="p-error" *ngIf="control.errors?.required">
      {{ t("employerFirstDeputy.required") }}
    </small>
  </ng-container>
</ng-container>
