import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ActivityDiffFragment } from "@ankaadia/graphql";

@Component({
  selector: "app-activity-diff",
  templateUrl: "./activity-diff.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ActivityDiffComponent {
  @Input()
  diff: ActivityDiffFragment[];
}
