<ng-container *appTranslate="let t">
  <ng-container *ngIf="organizations$ | async as organizations">
    <p-toolbar>
      <ng-template pTemplate="left">
        <p-dropdown
          [placeholder]="t('organization.placeholder')"
          [style]="{ 'max-width': '220px' }"
          [showClear]="true"
          [options]="organizations"
          [ngModel]="selectedOrganization"
          (ngModelChange)="selectOrganization($event)"
          optionLabel="name"
          optionValue="id"
          filterBy="name"
          class="mr-2"
        ></p-dropdown>
        <p-dropdown
          [placeholder]="t('collection.placeholder')"
          [showClear]="true"
          [options]="collections$ | async"
          [(ngModel)]="selectedCollection"
          optionLabel="name"
          filterBy="name"
        >
          <ng-template pTemplate="selectedItem" let-item>
            <span>{{ item.name }}</span>
            <ng-container *ngIf="selectedOrganization !== userOrganizationId">
              <span *ngIf="item.organizationId === userOrganizationId"> (->)</span>
              <span *ngIf="item.organizationId !== userOrganizationId"> (<-)</span>
            </ng-container>
          </ng-template>

          <ng-template pTemplate="item" let-item>
            <div class="flex justify-content-between">
              <span>
                <span>{{ item.name }}</span>
                <ng-container *ngIf="selectedOrganization !== userOrganizationId">
                  <span *ngIf="item.organizationId === userOrganizationId"> (->)</span>
                  <span *ngIf="item.organizationId !== userOrganizationId"> (<-)</span>
                </ng-container>
              </span>
              <i class="pi pi-star{{ item.isFavorite ? '-fill' : '' }} text-lg ml-1"></i>
            </div>
          </ng-template>
        </p-dropdown>
      </ng-template>
    </p-toolbar>
    <app-candidate-events-table
      *ngIf="selectedOrganization && selectedCollection; else myOrganization"
      [showTitle]="true"
      [organizationId]="userOrganizationId"
      [entityId]="selectedCollection"
      styleClass="mb-5"
    ></app-candidate-events-table>
    <ng-template #myOrganization>
      <app-candidate-events-table
        [showTitle]="true"
        [organizationId]="userOrganizationId"
        styleClass="mb-5"
      ></app-candidate-events-table>
    </ng-template>
  </ng-container>
</ng-container>
