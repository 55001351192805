import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import {
  Candidate,
  Collection,
  SharingTypeEnum,
  StaticDataType,
  XlsxCandidateImportInput,
  XlsxImportResult,
} from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { clone, cloneDeep } from "lodash";
import { PrimeIcons } from "primeng/api";
import { catchError, of, switchMap } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { EnumPipe } from "../../../shared/pipes/enum.pipe";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataPipe } from "../../../shared/static-data/static-data.pipe";
import { PipeDescription, TableColumn, TableOperation } from "../../../shared/table/table.model";
import { CandidateFlexibleEditService } from "../../candidates/candidate-flexible-edit/candidate-flexible-edit.service";
import { CandidatesService } from "../../candidates/candidates.service";
import { XlsxImportExportService } from "../../import/xlsx-import-export.service";
import { MessageService } from "../../message/message.service";
import {
  CustomLazyLoadEvent,
  IsSingleFilterMetaData,
} from "../collection-edit-assigned-candidates/custom-filter.model";
import { CandidateForAssignment, CollectionService } from "../collection.service";

@Component({
  selector: "app-collection-assigned-candidates",
  templateUrl: "./collection-assigned-candidates.component.html",
  standalone: false,
})
export class CollectionAssignedCandidatesComponent implements OnChanges {
  readonly candidatesPerPage = 200;
  private allCandidatesRestore: CandidateForAssignment[];
  @Input() collection: Collection;
  @Output() readonly reload = new EventEmitter<void>();
  protected assignedCandidates: Candidate[];
  protected assignedCandidatesForEdit: CandidateForAssignment[];
  protected allCandidates: CandidateForAssignment[];
  protected allCandidatesCount: number;
  protected selectedImport: XlsxCandidateImportInput;
  protected xlsxImportResult: XlsxImportResult;
  protected captionOperations: TableOperation[] = [
    {
      label: translate("common.import"),
      testId: "import-candidate",
      icon: PrimeIcons.UPLOAD,
      operation: (): void => this.uploadCandidates(),
    },
    {
      label: translate("collection.editAssignments"),
      testId: "edit-candidate",
      icon: PrimeIcons.PENCIL,
      operation: (): void => this.editAssignedCandidates(),
    },
  ];

  protected columns: TableColumn[] = [
    {
      header: translate("number.title"),
      fieldname: "displayId",
      routeLink: (x: Candidate): any[] => this.getRouteLink(x),
      sortable: true,
      includeInGlobalFilter: true,
      includeFlag: true,
      canIconClick: () => true,
      icon: () => PrimeIcons.USER_EDIT,
      iconClick: (candidate: Candidate) =>
        this.candidateFlexibleEditService.openCandidate(candidate.id, candidate.organizationId),
      iconClass: "ml-2",
    },
    { header: translate("name.title"), fieldname: "displayName", sortable: true, includeInGlobalFilter: true },
    {
      header: translate("status.title"),
      fieldname: "status",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(EnumPipe, "CandidateStatus"),
    },
    {
      header: translate("country.title"),
      fieldname: "country",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(StaticDataPipe, StaticDataType.Countries),
    },
  ];

  protected showDialog = false;

  protected candidateOrganizationId: string;
  protected candidateId: string;
  protected showCandidate: boolean;

  constructor(
    private readonly settings: SettingsService,
    private readonly collService: CollectionService,
    private readonly candidateService: CandidatesService,
    private readonly xlsxImportService: XlsxImportExportService,
    private readonly transloco: TranslocoService,
    private readonly messageService: MessageService,
    private readonly candidateFlexibleEditService: CandidateFlexibleEditService
  ) {}

  ngOnChanges(): void {
    if (this.collection != null) {
      this.assignedCandidates = clone(this.collection.assignedCandidates);
    } else {
      this.assignedCandidates = null;
    }
  }

  editAssignedCandidates(): void {
    this.collService
      .getCollection(this.collection.organizationId, this.collection.id)
      .subscribe((x) => (this.collection = x)); // mare sure that eTag is updated

    this.collService
      .getAllAssignableCandidates(this.collection.id, this.collection.organizationId, {
        first: 0,
        rows: this.candidatesPerPage,
      })
      .subscribe((x) => {
        this.allCandidates = cloneDeep(x.nodes) as CandidateForAssignment[];
        this.allCandidatesRestore = clone(this.allCandidates);
        this.allCandidatesCount = x.totalCount;
        this.assignedCandidatesForEdit = clone(this.collection.assignedCandidates).map((c) => {
          const original = this.collection.assignedCandidateIds?.find(
            (i) => i.id === c.id && i.orgId === c.organizationId
          );
          return {
            ...c,
            originalColId: original?.originalColId,
            originalOrgId: original?.originalOrgId,
          };
        });
        this.showDialog = true;
      });
  }

  uploadCandidates(): void {
    this.selectedImport = {
      blob: uuidv4(),
      organizationId: this.collection.organizationId,
      collectionId: this.collection.id,
      fileName: null,
      language: this.transloco.getActiveLang(),
    };
    this.showDialog = true;
  }

  displayCandidateLinks(): boolean {
    if (this.settings.organizationId === this.collection.organizationId) {
      return true;
    }
    return this.collection.sharings.some(
      (s) =>
        [SharingTypeEnum.Collaboration, SharingTypeEnum.ReadOnlyCollaboration].includes(s.sharingType) &&
        s.destinationOrganizationId === this.settings.organizationId
    );
  }

  getRouteLink(candidate: Candidate): any[] | null {
    return this.displayCandidateLinks()
      ? this.candidateService.getCandidateEditLink(candidate.id, candidate.organizationId)
      : null;
  }

  updateSource(event: CustomLazyLoadEvent): void {
    this.collService
      .getAllAssignableCandidates(this.collection.id, this.collection.organizationId, event)
      .subscribe((x) => {
        const collection = event.filters?.custom?.collection;
        const { collectionId = null, organizationId = null } = IsSingleFilterMetaData(collection)
          ? (collection?.value ?? {})
          : {};

        this.allCandidates = clone(x.nodes).map((c: CandidateForAssignment) => ({
          ...c,
          originalColId: this.settings.organizationId !== organizationId ? collectionId : null,
          originalOrgId: this.settings.organizationId !== organizationId ? organizationId : null,
        }));
        this.allCandidatesRestore = clone(this.allCandidates);
        this.allCandidatesCount = x.totalCount;
      });
  }

  import(input: XlsxCandidateImportInput): void {
    this.xlsxImportService.importCandidate(input).subscribe((x) => {
      this.xlsxImportResult = x;

      this.reload.emit();
    });
    this.justCloseSidebar();
  }

  closeSidebar(event: {
    success: boolean;
    collection?: Collection;
    targetCandidateList?: CandidateForAssignment[];
  }): void {
    this.showDialog = false;
    if (!event.success) {
      this.assignedCandidatesForEdit = clone(this.assignedCandidates);
      this.allCandidates = clone(this.allCandidatesRestore);
      this.reload.emit();
    } else {
      this.collService
        .updateAssignedCandidates(this.collection, event.targetCandidateList)
        .pipe(
          switchMap((_) => {
            this.messageService.add({ severity: "success", summary: translate("candidates.assigned2") });
            this.assignedCandidates = clone(this.assignedCandidatesForEdit);
            this.allCandidatesRestore = clone(this.allCandidates);
            return of(null);
          }),
          catchError((_) => {
            this.assignedCandidatesForEdit = clone(this.assignedCandidates);
            this.allCandidates = clone(this.allCandidatesRestore);
            return of(null);
          })
        )
        .subscribe(() => {
          this.reload.emit();
        });
    }
  }

  justCloseSidebar(): void {
    this.selectedImport = null;
    this.showDialog = false;
  }
}
