import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { QualificationExam, QualificationMeasureCandidateDataDe, StaticDataType } from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { assign } from "lodash";
import { Message } from "primeng/api";
import { BehaviorSubject, map, Observable, startWith, switchMap } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { QualificationMeasureExamFormDe } from "../../candidate-form/candidate-form.model";
import { CandidateFormService } from "../../candidate-form/candidate-form.service";

const nameOf = nameofFactory<QualificationMeasureCandidateDataDe>();

@Component({
  selector: "app-candidate-qualification-exams",
  templateUrl: "./candidate-qualification-exams.component.html",
  standalone: false,
})
export class CandidateQualificationExamsComponent implements OnInit, OnChanges, OnDestroy {
  private readonly language = this.transloco.getActiveLang();

  @Input({ required: true })
  form: FormGroup;

  @Input()
  readonly: boolean;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  moduleSuggestions: string[];

  constructor(
    private readonly formService: CandidateFormService,
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly formBuilder: FormBuilder
  ) {}

  showMessage = false;

  protected readonly message: Message = {
    severity: "warn",
    summary: translate("qualificationExam.duplicateExamTypeWarning"),
  };

  readonly subject = new BehaviorSubject<string>(this.processLanguage);

  readonly $qualificationMeasures = this.subject.pipe(
    switchMap((language) => this.staticDataService.getStaticData(StaticDataType.QualificationExams, language))
  );

  readonly $examResult = this.subject.pipe(
    switchMap((language) =>
      this.staticDataService
        .getStaticData(StaticDataType.ExamResultState, language)
        .pipe(map((data) => data.filter((item) => item.value !== "PARTIALLYPASSED")))
    )
  );

  ngOnInit(): void {
    this.getExamTypeObservable().subscribe((x) => (this.showMessage = this.setShowMessage(x)));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.control) this.control = this.formBuilder.array<QualificationMeasureExamFormDe>([]);
    if (changes.processLanguage) {
      this.subject.next(this.processLanguage);
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("qualificationExams"), { emitEvent: false });
  }

  get control(): FormArray {
    return this.form.get(nameOf("qualificationExams")) as FormArray;
  }

  set control(value: FormArray) {
    this.form.setControl(nameOf("qualificationExams"), value);
  }

  add(): void {
    this.control.push(this.formService.createQualificationExamGroup(assign(new QualificationExam(), { id: uuidv4() })));
  }

  remove(index: number): void {
    this.control.removeAt(index);
  }

  setShowMessage(examTypes: string[]): boolean {
    const examTypeCounts = examTypes.reduce<Record<string, number>>((acc, examType) => {
      acc[examType] = (acc[examType] || 0) + 1;
      return acc;
    }, {});

    return Object.values(examTypeCounts).some((count) => count > 2);
  }

  private getExamTypeObservable(): Observable<string[]> {
    return this.control.valueChanges.pipe(
      startWith(this.control.value),
      map((x) => x.map((y) => y.examType))
    );
  }
}
