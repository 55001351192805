import { Component, Input, OnChanges } from "@angular/core";
import { Sharing, SharingCreateInput, SharingUpdateInput } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { clone, cloneDeep } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { BooleanPipe } from "../../../shared/pipes/boolean.pipe";
import { AppDatePipe } from "../../../shared/pipes/date.pipe";
import { EnumPipe } from "../../../shared/pipes/enum.pipe";
import { PipeDescription, TableColumn, TableOperation } from "../../../shared/table/table.model";
import { MessageService } from "../../message/message.service";
import { CloseSharingFormArguments } from "../sharing-edit-dialog/sharing-edit-dialog.component";
import { GroupedSelectableOrganization } from "../sharing-edit/sharing-form.model";
import { SharingOrTemplate } from "../sharing-templates.service";
import { SharingsService } from "../sharings.service";
import { SharingProvider } from "./sharings-provider";

@Component({
  selector: "app-sharings-form",
  templateUrl: "./sharings-form.component.html",
  standalone: false,
})
export class SharingsFromComponent implements OnChanges {
  readonly captionOperations: TableOperation[] = [
    {
      label: translate("common.new"),
      testId: "add-new",
      icon: PrimeIcons.PLUS,
      operation: (): void => this.newSharing(),
    },
  ];

  readonly tableOperations: TableOperation[] = [
    {
      label: translate("common.edit"),
      testId: "edit",
      icon: PrimeIcons.PENCIL,
      operation: (x: Sharing): void => this.editSharing(x),
    },
    {
      label: translate("task.showActivities"),
      testId: "show-activities",
      icon: PrimeIcons.CLOCK,
      operation: (x: Sharing): void => this.showSharingActivities(x),
    },
    {
      label: translate("feedback.unsubmit"),
      testId: "feedback-unsubmit",
      icon: PrimeIcons.UNLOCK,
      operation: (x: Sharing, e: Event): void => this.unsubmitSharing(x, e),
      canOperate: (x: Sharing): boolean => x.submitted,
    },
    {
      label: translate("common.delete"),
      testId: "delete",
      icon: PrimeIcons.TRASH,
      operation: (x: Sharing, e: Event): void => this.deleteSharing(x, e),
    },
  ];

  readonly columns: TableColumn[] = [
    {
      header: translate("organization.title"),
      fieldname: "destinationOrganizationName",
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("type.title"),
      fieldname: "sharingType",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(EnumPipe, "SharingTypeEnum"),
    },
    {
      header: translate("enabled.title"),
      fieldname: "enabled",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(BooleanPipe),
    },
    {
      header: translate("sharing.expiryDate"),
      fieldname: "expiryDate",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(AppDatePipe, { dateStyle: "short" }),
    },
    {
      header: translate("feedback.submitted"),
      fieldname: "submitted",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(BooleanPipe),
    },
  ];

  @Input()
  sharingProvider: SharingProvider;

  @Input()
  showManageCollectionMembers: boolean;

  @Input()
  additionalOrganizations: GroupedSelectableOrganization[];

  sharings: Sharing[];
  sharingToEdit: Sharing;
  sharingToShowActivitiesFor: Sharing;
  showDialog: boolean;

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly sharingsService: SharingsService,
    private readonly messageService: MessageService
  ) {}

  ngOnChanges(): void {
    if (this.sharingProvider != null) {
      this.sharings = clone(this.sharingProvider.sharings);
    } else {
      this.sharings = null;
    }
  }

  closeSidebar(sharingsOrTemplates: SharingOrTemplate[]): void {
    const sharings = sharingsOrTemplates as Sharing[];
    if (sharings != null) {
      if (this.sharingToEdit == null) {
        this.sharings = this.sharings.concat(...sharings);
      } else {
        for (const sharing of sharings) {
          this.sharings[this.sharings.findIndex((x) => x.id == sharing.id)] = sharing;
        }
        this.sharings = clone(this.sharings);
      }
    }
    this.sharingToEdit = null;
    this.sharingToShowActivitiesFor = null;
    this.showDialog = false;
  }

  saveSharing(args: CloseSharingFormArguments): void {
    if (!args?.data) {
      this.closeSidebar(null);
      return;
    }
    const data = args.data;
    if (this.sharingToEdit) {
      delete data.notificationEmailUserGroups;
      const input: SharingUpdateInput = { ...this.sharingToEdit, ...data };
      this.sharingsService.updateSharing(input).subscribe((x) => {
        this.closeSidebar([x]);
        this.messageService.add({
          severity: "success",
          summary: translate("sharing.created.title"),
          detail: translate("sharing.created.message", x),
        });
      });
    } else {
      delete data.notificationEmailUserGroupIds;
      const input: SharingCreateInput = {
        ...data,
        destinationOrganizationId: <string[]>data.destinationOrganizationId,
        sharedCollectionId: this.sharingProvider.id,
        organizationId: this.sharingProvider.organizationId,
      };
      this.sharingsService.createSharings(input).subscribe((x) => {
        this.closeSidebar(x);
        this.messageService.add({
          severity: "success",
          summary: translate("sharing.updated.title"),
          detail: translate("sharing.updated.message", x),
        });
      });
    }
  }

  private deleteSharing(sharing: Sharing, buttonEvent: Event): void {
    this.confirmationService.confirm({
      target: buttonEvent.target,
      message: translate("sharing.confirmDelete", sharing),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.sharingsService.deleteSharing(sharing).subscribe(() => {
          this.sharings = this.sharings.filter((x) => x.id != sharing.id);
          this.messageService.add({
            severity: "success",
            summary: translate("sharing.deleted.title"),
            detail: translate("sharing.deleted.message", sharing),
          });
        });
      },
    });
  }

  private unsubmitSharing(sharing: Sharing, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("feedback.confirmUnsubmit", sharing),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.sharingsService.unsubmitSharing(sharing.id, sharing.organizationId).subscribe(({ _etag }) => {
          const sharings = cloneDeep(this.sharings);
          sharings.find((x) => x.id === sharing.id).submitted = false;
          sharings.find((x) => x.id === sharing.id)._etag = _etag;
          this.sharings = sharings;
          this.messageService.add({
            severity: "success",
            summary: translate("feedback.unsubmitted"),
          });
        });
      },
    });
  }

  private editSharing(sharing: Sharing): void {
    this.sharingToEdit = sharing;
    this.showDialog = true;
  }

  private showSharingActivities(sharing: Sharing): void {
    this.sharingToShowActivitiesFor = sharing;
    this.showDialog = true;
  }

  private newSharing(): void {
    this.sharingToEdit = null;
    this.showDialog = true;
  }
}
