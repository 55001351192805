<ng-container *appTranslate="let t">
  <div class="grid">
    <div class="col-12 md:col-3" style="height: 500px; overflow: auto">
      <p-table
        #table
        [value]="form.value"
        [globalFilterFields]="['name']"
        tableStyleClass="w-full"
        selectionMode="single"
        [(selection)]="selectedLocation"
      >
        <ng-template pTemplate="caption">
          <div class="flex justify-content-between align-items-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                (input)="table.filterGlobal($any($event.target).value, 'contains')"
                [placeholder]="t('common.search')"
              />
            </span>
            <button
              pButton
              [pTooltip]="t('common.add')"
              type="button"
              icon="pi pi-plus"
              class="p-button-rounded p-button-text ml-2"
              [disabled]="!form.valid && table.value.length"
              (click)="addLocation()"
            ></button>
          </div>
        </ng-template>

        <ng-template pTemplate="body" let-location let-index="rowIndex">
          <tr [pSelectableRow]="location">
            <td>
              <div class="flex justify-content-between align-items-center m-0">
                <span
                  class="white-space-nowrap text-overflow-ellipsis overflow-hidden m-0"
                  [class.p-error]="!form.controls[index].valid"
                >
                  {{ location.name }}
                </span>
                <button
                  *ngIf="selectedLocation === location"
                  pButton
                  [pTooltip]="t('common.remove')"
                  type="button"
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-text p-button-danger m-0"
                  (click)="removeLocation(location)"
                ></button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class="col-12 md:col-9">
      <app-organization-profile-location
        *ngIf="selectedForm"
        [form]="selectedForm"
        [fields]="fields"
        [organizationId]="organizationId"
      ></app-organization-profile-location>
    </div>
  </div>
</ng-container>
