import { Injectable } from "@angular/core";
import { StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { forkJoin, isObservable, map, Observable, of, switchMap } from "rxjs";
import { OrganizationFactoryService } from "../../../organization-specific/organization-specific-factory.service";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { CandidateProfileField, CandidateProfileFieldGroup } from "./candidate-profile-field-configuration.model";

@Injectable({ providedIn: "root" })
export class CandidateProfileFieldConfigurationService {
  private readonly language = this.transloco.getActiveLang();

  constructor(
    private readonly transloco: TranslocoService,
    private readonly specificsFactory: OrganizationFactoryService,
    private readonly settings: SettingsService,
    private readonly staticDataService: StaticDataService
  ) {}

  getFields(): Observable<CandidateProfileFieldGroup[]> {
    return this.specificsFactory
      .getOrganizationSpecifics(this.settings.organizationId)
      .pipe(
        switchMap((specifics) =>
          forkJoin([
            this.getProfileDefinitions(specifics.getCandidateProfileFormlyFields()),
            specifics.getCustomCandidateFields(),
          ]).pipe(map(([profile, custom]) => this.mergeDefinitions([profile, custom])))
        )
      );
  }

  private getProfileDefinitions(form: FormlyFieldConfig[]): Observable<CandidateProfileFieldGroup[]> {
    const profileForm = form[0];

    const flattenedFormFields = profileForm.fieldGroup
      .map((tab) => {
        const tabFields = this.flattenWithPath(
          tab.fieldGroup ?? [],
          (f) => f.fieldGroup ?? [],
          (f) => f.key
        ).filter((field) => field.item.type !== "documentUpload" && !field.item.fieldGroup && !field.item.fieldArray);

        return { tab, tabFields };
      })
      .filter(({ tabFields }) => tabFields.length > 0);

    const groupedFieldsWithOptions = flattenedFormFields.map(({ tab, tabFields }) => {
      const fieldConfigs = tabFields.map((field) => {
        return this.getFieldTypeAndOptions(field.item).pipe(
          map((x) => {
            return {
              label: field.item.props?.label,
              path: ["os.profile", tab.key, ...field.path].filter((x) => !!x).join("."),
              options: x?.options,
              staticDataType: field.item.props?.staticDataType,
              enumType: field.item.props?.enumType,
              type: x?.type,
            } as CandidateProfileField;
          })
        );
      });

      return forkJoin(fieldConfigs).pipe(
        map((fields) => ({
          label: tab.props?.label,
          fields: fields,
        }))
      );
    });

    return forkJoin(groupedFieldsWithOptions);
  }

  private getFieldTypeAndOptions(field: FormlyFieldConfig): Observable<{ type: string; options?: [] }> {
    if (field.type === "dropdown" && field.props?.options) {
      return this.optionFactory("select", field.props.options);
    }
    if (field.type === "multiselect" && field.props?.options) {
      return this.optionFactory("multiselect", field.props.options);
    }
    if (
      (field.type === "input" && (field.props.type === "text" || field.props.type === "email")) ||
      field.type === "inputMask" ||
      field.type === "textarea"
    ) {
      return of({ type: "text" });
    }
    if (field.type === "input" && field.props.type === "number") {
      return of({ type: "integer" });
    }
    if (field.type === "datepicker") {
      return of({ type: "date" });
    }
    if (field.type === "checkbox") {
      return of({ type: "boolean" });
    }
    if (field.type === "profession") {
      return this.optionFactory(
        "profession",
        this.staticDataService.getStaticData(StaticDataType.Profession, this.language)
      );
    }
    return of(null);
  }

  private flattenWithPath<T, U>(
    xs: T[],
    childSelector: (_: T) => T[],
    pathSelector: (_: T) => U
  ): { item: T; path: U[] }[] {
    const internal = (
      xs: T[],
      childSelector: (_: T) => T[],
      pathSelector: (_: T) => U,
      pathAcc: U[]
    ): { item: T; path: U[] }[] => {
      return xs.reduce<{ item: T; path: U[] }[]>((acc, x) => {
        const path = pathSelector(x);
        const currPath = [...pathAcc, ...(path ? [path] : [])];
        acc.push(
          { item: x, path: currPath },
          ...internal(childSelector(x) ?? [], childSelector, pathSelector, currPath)
        );
        return acc;
      }, []);
    };
    return internal(xs, childSelector, pathSelector, []);
  }

  private optionFactory(
    type: "select" | "multiselect" | "profession",
    options: StaticDataModel[] | Observable<StaticDataModel[]>
  ): Observable<any> {
    return (isObservable(options) ? options : of(options)).pipe(map((x) => ({ type: type, options: x })));
  }

  private mergeDefinitions(groups: CandidateProfileFieldGroup[][]): CandidateProfileFieldGroup[] {
    return groups
      .flat()
      .reduce<CandidateProfileFieldGroup[]>((all, tab) => {
        const existing = all.find((x) => x.label === tab.label);
        if (existing) {
          existing.fields.push(...tab.fields);
        } else {
          all.push(tab);
        }
        return all;
      }, [])
      .map((t) => ({
        label: t.label,
        fields: t.fields.filter((f) => f.label && f.path).sort((a, b) => a.label.localeCompare(b.label, this.language)),
      }))
      .filter((t) => t.label && t.fields?.length);
  }
}
