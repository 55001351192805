import { Pipe, PipeTransform } from "@angular/core";
import { CompletionState, DocumentStatusEnum } from "@ankaadia/graphql";
import { PrimeIcons } from "primeng/api";

type DocumentStateColor = "Green" | "GreenYellow" | "Red" | "Brown" | "Orange";

@Pipe({ name: "documentStatus", standalone: false })
export class DocumentStatusIconPipe implements PipeTransform {
  transform(documentStatus: DocumentStatusEnum, type: "Icon" | "Color", completionState: CompletionState): string {
    switch (type) {
      case "Icon":
        return this.getIcon(documentStatus, completionState);
      case "Color":
        return this.getColor(documentStatus, completionState);
    }
  }

  private getIcon(documentStatus: DocumentStatusEnum, completionState: CompletionState): string {
    switch (completionState) {
      case CompletionState.Complete: {
        switch (documentStatus) {
          case DocumentStatusEnum.Available:
            return PrimeIcons.CHECK_CIRCLE;
          case DocumentStatusEnum.AvailableButExpired:
            return PrimeIcons.QUESTION_CIRCLE;
          case DocumentStatusEnum.Missing:
            return PrimeIcons.QUESTION_CIRCLE;
          case DocumentStatusEnum.MissingButStatusTakenOver:
            return PrimeIcons.CHECK;
          case DocumentStatusEnum.MissingNotRequired:
            return PrimeIcons.CHECK_CIRCLE;
          case DocumentStatusEnum.Partial:
            return PrimeIcons.CHECK_CIRCLE;
          case DocumentStatusEnum.NotShared:
            return PrimeIcons.BOLT;
          default:
            throw new Error("Invalid document status");
        }
      }
      case CompletionState.Incomplete: {
        switch (documentStatus) {
          case DocumentStatusEnum.Available:
            return PrimeIcons.QUESTION_CIRCLE;
          case DocumentStatusEnum.AvailableButExpired:
            return PrimeIcons.HOURGLASS;
          case DocumentStatusEnum.Missing:
            return PrimeIcons.BAN;
          case DocumentStatusEnum.MissingButStatusTakenOver:
            return PrimeIcons.BAN;
          case DocumentStatusEnum.MissingNotRequired:
            return PrimeIcons.CIRCLE;
          case DocumentStatusEnum.Partial:
            return PrimeIcons.BAN;
          case DocumentStatusEnum.NotShared:
            return PrimeIcons.BOLT;
          default:
            throw new Error("Invalid document status");
        }
      }
      default: {
        switch (documentStatus) {
          case DocumentStatusEnum.Available:
            return PrimeIcons.CHECK_CIRCLE;
          case DocumentStatusEnum.AvailableButExpired:
            return PrimeIcons.HOURGLASS;
          case DocumentStatusEnum.Missing:
            return PrimeIcons.BAN;
          case DocumentStatusEnum.MissingButStatusTakenOver:
            return PrimeIcons.CHECK;
          case DocumentStatusEnum.MissingNotRequired:
            return PrimeIcons.CIRCLE;
          case DocumentStatusEnum.Partial:
            return PrimeIcons.EXCLAMATION_CIRCLE;
          case DocumentStatusEnum.NotShared:
            return PrimeIcons.BOLT;
          default:
            throw new Error("Invalid document status");
        }
      }
    }
  }

  private getColor(documentStatus: DocumentStatusEnum, completionState: CompletionState): DocumentStateColor {
    switch (completionState) {
      case CompletionState.Complete: {
        switch (documentStatus) {
          case DocumentStatusEnum.Available:
            return "Green";
          case DocumentStatusEnum.AvailableButExpired:
            return "Green";
          case DocumentStatusEnum.Missing:
            return "Green";
          case DocumentStatusEnum.MissingButStatusTakenOver:
            return "GreenYellow";
          case DocumentStatusEnum.MissingNotRequired:
            return "Green";
          case DocumentStatusEnum.Partial:
            return "Green";
          case DocumentStatusEnum.NotShared:
            return "Red";
          default:
            throw new Error("Invalid document status");
        }
      }
      case CompletionState.Incomplete: {
        switch (documentStatus) {
          case DocumentStatusEnum.Available:
            return "Red";
          case DocumentStatusEnum.AvailableButExpired:
            return "Red";
          case DocumentStatusEnum.Missing:
            return "Red";
          case DocumentStatusEnum.MissingButStatusTakenOver:
            return "Red";
          case DocumentStatusEnum.MissingNotRequired:
            return "Brown";
          case DocumentStatusEnum.Partial:
            return "Red";
          case DocumentStatusEnum.NotShared:
            return "Red";
          default:
            throw new Error("Invalid document status");
        }
      }
      default: {
        switch (documentStatus) {
          case DocumentStatusEnum.Available:
            return "Green";
          case DocumentStatusEnum.AvailableButExpired:
            return "Red";
          case DocumentStatusEnum.Missing:
            return "Red";
          case DocumentStatusEnum.MissingButStatusTakenOver:
            return "GreenYellow";
          case DocumentStatusEnum.MissingNotRequired:
            return "Brown";
          case DocumentStatusEnum.Partial:
            return "Orange";
          case DocumentStatusEnum.NotShared:
            return "Red";
          default:
            throw new Error("Invalid document status");
        }
      }
    }
  }
}
