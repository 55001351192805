import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "app-formly-rating",
  templateUrl: "./formly-rating.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FormlyRatingComponent extends FieldType {
  override defaultOptions = {
    props: { options: [] },
  };
}
