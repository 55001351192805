import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OrganizationProfileFields, OrganizationProfileFragment } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { finalize } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { MessageService } from "../../message/message.service";
import { OrganizationProfileService } from "../organization-profile.service";

@Component({
  selector: "app-organization-profiles",
  templateUrl: "./organization-profiles.component.html",
  standalone: false,
})
export class OrganizationProfilesComponent implements OnInit {
  profile: OrganizationProfileFragment;
  fields: OrganizationProfileFields;
  disableSubmit: boolean;

  @Input()
  organizationId: string;

  @Input()
  title: string;

  @Input()
  displayBackButton: boolean;

  constructor(
    private readonly messageService: MessageService,
    private readonly profileService: OrganizationProfileService,
    private readonly router: Router,
    private readonly settings: SettingsService
  ) {}

  ngOnInit(): void {
    this.profileService.get(this.organizationId).subscribe((x) => (this.profile = x));
    this.profileService.getFields(this.organizationId).subscribe((x) => (this.fields = x));
  }

  save(profile: OrganizationProfileFragment): void {
    this.disableSubmit = true;
    this.profileService
      .set(profile)
      .pipe(finalize(() => (this.disableSubmit = false)))
      .subscribe((x) => {
        if (x) {
          this.messageService.add({
            severity: "success",
            summary: translate("organizationProfile.updated.title"),
            detail: translate("organizationProfile.updated.message"),
          });
          this.close();
        }
      });
  }

  close(): void {
    if (this.displayBackButton) {
      void this.router.navigate(["/app/settings/organizations", this.settings.organizationId]);
    } else {
      this.settings.navigateToMain();
    }
  }
}
