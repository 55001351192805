import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { tryValidateDocument } from "@ankaadia/ankaadia-shared";
import { RequiredDocumentForListFragment, RequiredDocumentFragment, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { StaticDataPipe } from "../../../shared/static-data/static-data.pipe";
import { StaticDataService } from "../../../shared/static-data/static-data.service";

@Component({
  selector: "app-required-document-dialog",
  templateUrl: "./required-document-dialog.component.html",
  standalone: false,
})
export class RequiredDocumentDialogComponent implements OnInit, OnChanges {
  readonly locale = this.transloco.getActiveLang();
  readonly functions$ = this.staticDataService.getStaticData(StaticDataType.Functions);

  form: FormGroup;
  disableSubmit = false;
  submitDisabled: boolean;
  unavailableDocSetNames: string[];
  protected immigrationCountry: { immigrationCountry: string };

  @Input() isEdit: boolean;

  @Input()
  template: RequiredDocumentFragment;

  @Input()
  allDocumentSets: RequiredDocumentForListFragment[];

  @Output()
  readonly saved = new EventEmitter<RequiredDocumentFragment>();

  @Output()
  readonly closed = new EventEmitter<void>();

  constructor(
    private readonly transloco: TranslocoService,
    private readonly staticDataPipe: StaticDataPipe,
    private readonly staticDataService: StaticDataService,
    private readonly fb: FormBuilder,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        id: [this.template.id],
        immigrationCountry: this.template.immigrationCountry,
        functions: [this.template.functions, Validators.required],
        organizationId: [this.template.organizationId],
        _etag: [this.template._etag],
        name: [this.template.name, Validators.required],
        description: [this.template.description],
        setsAvailableForCandidates: this.isEdit ? this.template.setsAvailableForCandidates : true,
      },
      { validators: [this.documentValidator.bind(this)] }
    );
    this.staticDataPipe
      .transform(this.template.immigrationCountry, StaticDataType.SupportedImmigrationCountries)
      .subscribe((x) => {
        this.immigrationCountry = { immigrationCountry: x };
      });
    this.form.markAsDirty();
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.allDocumentSets) {
      this.unavailableDocSetNames = changes.allDocumentSets.currentValue
        .filter((d) => d.id !== this.template.id)
        .map((d) => d.name);
    }
  }

  private documentValidator(control: AbstractControl<RequiredDocumentFragment>): ValidationErrors | null {
    return tryValidateDocument(<any>control.value) ? null : { document: true };
  }

  onSubmit(): void {
    this.submitDisabled = true;
    this.saved.emit(this.form.value);
  }
}
