<ng-container *appTranslate="let t">
  @if (isOriginalTabShared() && isLicenseOwnerSpecificDataEnabled()) {
    <p-dropdown
      [(ngModel)]="dataSource"
      [tooltip]="t('candidate.dataSource.tooltip')"
      [options]="candidateDataSourceOptions"
      [ngModelOptions]="{ standalone: true }"
      [tooltipPosition]="'left'"
      [filter]="false"
      styleClass="w-full compact minimalistic"
    />
  } @else if (isLicenseOwnerSpecificDataEnabled()) {
    <span [pTooltip]="t('candidate.dataSource.tooltip')" [tooltipPosition]="'left'">
      {{ t("candidate.dataSource.userOrg") }}</span
    >
  }
</ng-container>
