<div class="p-fluid" *appTranslate="let t">
  <div class="field">
    <p-selectButton
      [options]="statusPhaseOneOptions"
      [disabled]="interviewConducted || readonly"
      [ngModel]="statusPhaseOne"
      (onOptionClick)="statusPhaseOne = $event.option.value"
    ></p-selectButton>
  </div>
  <div class="field" *ngIf="secondPhaseEnabled && interviewRequested">
    <p-checkbox
      [disabled]="readonly"
      [label]="t('feedback.interviewConducted')"
      [binary]="true"
      [(ngModel)]="interviewConducted"
    ></p-checkbox>
  </div>
  <div class="field" *ngIf="secondPhaseEnabled && interviewConducted && interviewRequested">
    <p-selectButton
      [disabled]="readonly"
      [options]="statusPhaseTwoOptions"
      [ngModel]="statusPhaseTwo"
      (onOptionClick)="statusPhaseTwo = $event.option.value"
    ></p-selectButton>
  </div>
</div>
