<ng-container *appTranslate="let t" [formGroup]="form">
  <div class="grid">
    <div *ngIf="fields.requiredStaff" class="col-12 xl:col-3">
      <div class="field">
        <label for="requiredStaff">{{ t("requiredStaff.title") }}</label>
        <app-profession-selector
          inputId="requiredStaff"
          formControlName="requiredStaff"
          [placeholder]="t('requiredStaff.placeholder')"
          [errorLabel]="t('requiredStaff.required')"
          [multi]="true"
          [required]="true"
        ></app-profession-selector>
        <small
          *ngIf="requiredStaffDuplicates as duplicates"
          [title]="
            t('organizationProfile.location.validation.duplicateStaffEntries.tooltip', {
              duplicates: duplicates | bulleted | join: '\n',
            })
          "
          class="p-error"
        >
          {{ t("organizationProfile.location.validation.duplicateStaffEntries.title") }}
        </small>
      </div>
    </div>

    <div *ngIf="fields.interviewLanguageLevel" class="col-12 xl:col-3">
      <div class="field">
        <label for="interviewLanguageLevel">{{ t("interviewLanguageLevel.title") }}</label>
        <p-dropdown
          inputId="interviewLanguageLevel"
          [options]="interviewLanguageLevels | async"
          [placeholder]="t('interviewLanguageLevel.placeholder')"
          formControlName="interviewLanguageLevel"
          [showClear]="true"
        ></p-dropdown>
      </div>
    </div>

    <div *ngIf="fields.transferLanguageLevel" class="col-12 xl:col-3">
      <div class="field">
        <label for="transferLanguageLevel">{{ t("transferLanguageLevel.title") }}</label>
        <p-dropdown
          inputId="transferLanguageLevel"
          [options]="transferLanguageLevels | async"
          [placeholder]="t('transferLanguageLevel.placeholder')"
          formControlName="transferLanguageLevel"
          [showClear]="true"
        ></p-dropdown>
      </div>
    </div>

    <div *ngIf="fields.pathOfRecognition" class="col-12 xl:col-3">
      <div class="field">
        <label for="pathOfRecognition">{{ t("supportedPathOfRecognition.title") }}</label>
        <p-dropdown
          inputId="pathOfRecognition"
          [options]="pathsOfRecognition | async"
          [placeholder]="t('supportedPathOfRecognition.placeholder')"
          formControlName="pathOfRecognition"
          [showClear]="true"
        ></p-dropdown>
      </div>
    </div>

    <div *ngIf="fields.function" class="col-12 xl:col-3">
      <div class="field">
        <label for="function">{{ t("function.title") }}</label>
        <p-multiSelect
          inputId="function"
          [options]="functions | async"
          [placeholder]="t('function.placeholder')"
          formControlName="function"
        ></p-multiSelect>
      </div>
    </div>

    <div *ngIf="fields.countryOfOrigin" class="col-12 xl:col-3">
      <div class="field">
        <label for="countryOfOrigin">{{ t("countryOfOrigin.title") }}</label>
        <p-multiSelect
          inputId="countryOfOrigin"
          [options]="countries | async"
          [placeholder]="t('countryOfOrigin.placeholder')"
          formControlName="countryOfOrigin"
        ></p-multiSelect>
      </div>
    </div>

    <div *ngIf="fields.employmentType" class="col-12 xl:col-3">
      <div class="field">
        <label for="employmentType">{{ t("employmentType.title") }}</label>
        <p-multiSelect
          inputId="employmentType"
          [options]="employmentTypes"
          [placeholder]="t('employmentType.placeholder')"
          formControlName="employmentType"
        ></p-multiSelect>
      </div>
    </div>

    <div *ngIf="fields.temporalScope" class="col-12 xl:col-3">
      <div class="field">
        <label for="temporalScope">{{ t("temporalScope.title") }}</label>
        <p-multiSelect
          inputId="temporalScope"
          [options]="temporalScopes"
          [placeholder]="t('temporalScope.placeholder')"
          formControlName="temporalScope"
        ></p-multiSelect>
      </div>
    </div>

    <div class="col-12">
      <div class="field">
        <label>{{ t("benefits.title") }}</label>
        <app-organization-profile-benefits [form]="$any(form.controls.benefits)"></app-organization-profile-benefits>
      </div>
    </div>
  </div>
</ng-container>
