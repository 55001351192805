<ng-container *appTranslate="let t">
  <p-card class="app-form">
    <div class="flex flex-column app-form">
      <div class="flex flex-row justify-content-between mb-3">
        <div class="flex align-items-center gap-2">
          <h3 class="ml-3" style="margin-bottom: 0">
            <span>{{ collection?.name }}</span>
            <app-favorite
              *ngIf="collection?.id"
              [(favorite)]="favorite"
              [collection]="{ collectionId: collection.id, organizationId: collection.organizationId }"
              class="ml-2"
            ></app-favorite>
          </h3>
          <div class="p-progressbar-wrapper" style="width: 300px" *ngIf="collection?.targetSize">
            <p-progressBar
              [ngClass]="{
                'red-progressbar':
                  collection.assignedCandidateIds.length + collection.totalCandidatesFromAssignedCollections >
                  collection.targetSize,
              }"
              [showValue]="false"
              [value]="
                (100 * (collection.assignedCandidateIds.length + collection.totalCandidatesFromAssignedCollections)) /
                collection.targetSize
              "
            ></p-progressBar>
            <div class="p-progressbar-label">
              {{ collection.assignedCandidateIds.length + collection.totalCandidatesFromAssignedCollections }}/{{
                collection.targetSize
              }}
            </div>
          </div>
        </div>
        <div class="flex gap-2 align-items-center button-container">
          <p-button
            *ngIf="collection"
            [label]="t('collection.edit')"
            (onClick)="editCollection()"
            icon="pi pi-pencil"
            class="p-button-success"
            data-testid="btn-edit-collection"
          ></p-button>
          <p-button
            *ngIf="collection"
            [label]="t('common.delete')"
            (onClick)="deleteCollection($event)"
            icon="pi pi-trash"
            class="p-button-success"
            data-testid="btn-delete-collection"
          ></p-button>
          <p-button
            *ngIf="canTransfer()"
            [label]="t('collection.transfer.title')"
            (onClick)="openTransferCollection()"
            icon="pi pi-directions"
            class="p-button-success"
          ></p-button>
        </div>
      </div>

      <div class="app-form">
        <p-tabView class="p-fluid app-form" styleClass="flex flex-column app-form" [scrollable]="true">
          <p-tabPanel
            *ngIf="isStandard"
            style="min-width: 0"
            [header]="t('candidates.assignedFull', { total: totalAssignedCandidates })"
          >
            <app-collection-assigned-candidates
              [collection]="collection"
              (reload)="reloadCollection()"
            ></app-collection-assigned-candidates>
          </p-tabPanel>

          <p-tabPanel *ngIf="isAuto" [header]="t('candidates.assignedFull', { total: totalAssignedCandidates })">
            <app-collection-auto-filter
              [collection]="collection"
              (reload)="reloadCollection()"
            ></app-collection-auto-filter>
          </p-tabPanel>

          <p-tabPanel
            *ngIf="isFromTemplate"
            [header]="t('candidates.assignedFull', { total: totalAssignedCandidates })"
          >
            <app-collection-from-template
              class="block"
              [collection]="collection"
              (reload)="reloadCollection()"
            ></app-collection-from-template>
          </p-tabPanel>

          <p-tabPanel
            *ngIf="isAuto || isFromTemplate"
            style="min-width: 0"
            [header]="t('candidates.assignmentExceptionsFull', { total: totalAssignmentExceptions })"
          >
            <app-collection-assigned-exceptions
              [collection]="collection"
              (reload)="reloadCollection()"
            ></app-collection-assigned-exceptions>
          </p-tabPanel>

          <p-tabPanel
            *ngIf="isStandard"
            [header]="
              t('collections.assignedFull', {
                collections: totalAssignedCollections,
                candidates: totalAssignedCollectionCandidates,
              })
            "
          >
            <app-collection-assigned-collections
              [collection]="collection"
              (reload)="reloadCollection()"
            ></app-collection-assigned-collections>
          </p-tabPanel>

          <p-tabPanel [header]="t('sharings.title')">
            <app-sharings-form
              [sharingProvider]="collection"
              [showManageCollectionMembers]="isStandard"
            ></app-sharings-form>
          </p-tabPanel>

          <p-tabPanel [header]="t('talentPoolSharings.title')" *ngIf="ENABLE_TALENT_POOL">
            <app-talent-pool-sharings [collection]="collection"></app-talent-pool-sharings>
          </p-tabPanel>

          <p-tabPanel *ngIf="collection" [header]="t('candidates.events')" [cache]="false">
            <ng-template pTemplate="content">
              <app-candidate-events-table
                [organizationId]="collection.organizationId"
                [entityId]="{ collectionId: collection.id, organizationId: collection.organizationId }"
              ></app-candidate-events-table>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel [header]="t('requiredDocument.overview.title')" [cache]="false" *ngIf="isLicensed">
            <ng-template pTemplate="content">
              <app-document-overview
                *ngIf="totalAssignedCandidates <= maxCandidateCountForOverviews"
                [organizationId]="collection.organizationId"
                [collectionId]="{ collectionId: collection.id, organizationId: collection.organizationId }"
              ></app-document-overview>
              <div *ngIf="totalAssignedCandidates > maxCandidateCountForOverviews">
                <p-message
                  severity="warn"
                  class="hidden lg:block text-center"
                  styleClass="mb-2 text-left"
                  [text]="t('requiredDocument.overview.tooManyCandidates', { maxCount: maxCandidateCountForOverviews })"
                ></p-message>
              </div>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel [header]="t('requiredVaccines.overview.title')" [cache]="false" *ngIf="isLicensed">
            <ng-template pTemplate="content">
              <app-collection-vaccines-overview
                *ngIf="totalAssignedCandidates <= maxCandidateCountForOverviews"
                [collection]="collection"
              ></app-collection-vaccines-overview>
              <div *ngIf="totalAssignedCandidates > maxCandidateCountForOverviews">
                <p-message
                  severity="warn"
                  class="hidden lg:block text-center"
                  styleClass="mb-2 text-left"
                  [text]="t('requiredVaccines.overview.tooManyCandidates', { maxCount: maxCandidateCountForOverviews })"
                ></p-message>
              </div>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel [header]="t('templates.title')" *ngIf="isLicensed && collection" [cache]="false">
            <ng-template pTemplate="content">
              <app-collection-document-templates
                [collection]="collection"
                [isReportMode]="false"
              ></app-collection-document-templates>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel [header]="t('templates.reports')" *ngIf="isLicensed && collection" [cache]="false">
            <ng-template pTemplate="content">
              <app-collection-document-templates
                [collection]="collection"
                [isReportMode]="true"
              ></app-collection-document-templates>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel [header]="t('description.title')" *ngIf="isLicensed && collection" [cache]="false">
            <ng-template pTemplate="content">
              <p-card>
                <textarea
                  class="collection-description"
                  pInputTextarea
                  readonly
                  id="description"
                  [(ngModel)]="collection.description"
                  [autoResize]="true"
                ></textarea>
              </p-card>
            </ng-template>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </p-card>

  <p-sidebar [visible]="showSidebar" styleClass="p-sidebar-md">
    <app-collection-edit-dialog
      *ngIf="showSidebar && !collectionForTransfer"
      (closed)="closeSidebar()"
      [isEdit]="true"
      [collection]="collection"
    ></app-collection-edit-dialog>

    <app-collection-transfer-dialog
      *ngIf="showSidebar && collectionForTransfer && isLicensed"
      (saved)="transferCollection($event)"
      (canceled)="closeSidebar()"
      [collection]="collectionForTransfer"
      [isTransferring]="isTransferring"
    ></app-collection-transfer-dialog>
  </p-sidebar>
</ng-container>
