import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { QualificationMeasureCandidateDataDe, SupportedImmigrationCountry } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";

const nameOf = nameofFactory<QualificationMeasureCandidateDataDe>();

@Component({
  selector: "app-candidate-end-of-further-education",
  templateUrl: "./candidate-end-of-further-education.component.html",
  standalone: false,
})
export class CandidateEndOfFurtherEducationComponent implements OnInit, OnDestroy {
  private readonly language = this.transloco.getActiveLang();

  readonly SupportedImmigrationCountry = SupportedImmigrationCountry;

  @Input()
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  readonly: boolean;

  @Input()
  immigrationCountry: SupportedImmigrationCountry;

  @Output()
  readonly valueChanged = new EventEmitter<Date>();

  get control(): FormControl<Date> {
    return this.form.get(nameOf("endOfFurtherEducation")) as FormControl<Date>;
  }

  set control(value: FormControl<Date>) {
    this.form.setControl(nameOf("endOfFurtherEducation"), value);
  }

  constructor(private readonly transloco: TranslocoService) {}

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<Date>(undefined);
    this.control.valueChanges.subscribe((value) => this.valueChanged.emit(value));
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("endOfFurtherEducation"), { emitEvent: false });
  }
}
