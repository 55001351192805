import { AfterViewInit, Component, Input } from "@angular/core";
import { StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { startWith } from "rxjs";
import { StaticDataService } from "../../../../../shared/static-data/static-data.service";
import { VocationalSchoolShipFormAt } from "../../../candidate-form.model";

@Component({
  selector: "app-candidate-vocational-school-at",
  templateUrl: "./candidate-vocational-school-at.component.html",
  standalone: false,
})
export class CandidateVocationalSchoolAtComponent implements AfterViewInit {
  protected readonly today = new Date();

  @Input()
  form: VocationalSchoolShipFormAt;

  @Input({ required: true })
  candidateId: string;

  @Input({ required: true })
  candidateOrganizationId: string;

  @Input({ required: true })
  readonly: boolean;

  @Input({ required: true })
  isMultiEditMode: boolean;

  readonly language = this.transloco.getActiveLang();
  readonly countries = this.staticDataService.getStaticData(StaticDataType.Countries, this.language);
  readonly trainingTypeInYearsTotalOptions$ = this.staticDataService.getStaticData(
    StaticDataType.VocationalSchoolTrainingTimeTotal
  );

  readonly currentTrainingYearOptions$ = this.staticDataService.getStaticData(
    StaticDataType.VocationalSchoolTrainingTimeCurrent
  );

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService
  ) {}

  ngAfterViewInit(): void {
    this.form.controls.medicalCheckAttended.valueChanges
      .pipe(startWith(this.form.controls.medicalCheckAttended.value))
      .subscribe((x) =>
        x ? this.form.controls.medicalCheckAttendedDate.enable() : this.form.controls.medicalCheckAttendedDate.disable()
      );

    this.form.controls.medicalCheckPassed.valueChanges
      .pipe(startWith(this.form.controls.medicalCheckPassed.value))
      .subscribe((x) =>
        x ? this.form.controls.medicalCheckPassedDate.enable() : this.form.controls.medicalCheckPassedDate.disable()
      );
  }
}
