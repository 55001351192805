<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <label for="recognitionType">{{ t("recognitionType.title") }}</label>
  <p-dropdown
    inputId="recognitionType"
    [options]="recognitionTypes"
    [formControl]="control"
    [showClear]="true"
    [placeholder]="t('recognitionType.placeholder')"
    [readonly]="readonly"
    (onChange)="userChangedValue.emit($event.value)"
  ></p-dropdown>

  <small class="p-error" *ngIf="control.errors?.required">
    {{ t("recognitionType.required") }}
  </small>
</ng-container>
