<ng-container *appTranslate="let t">
  <p-card [header]="t('educationCourses.addToCourse.title')">
    <div class="p-fluid formgrid">
      <div class="field mt-5">
        <label for="name">{{ t("educationCourses.title") }}</label>
        <p-dropdown
          inputId="relation"
          [(ngModel)]="selectedCourse"
          [options]="selectableCourses"
          [placeholder]="t('educationCourses.placeholder')"
          filterBy="filterText"
          optionLabel="name"
        >
          <ng-template pTemplate="selectedItem">
            <ng-container
              *ngTemplateOutlet="
                shownData;
                context: {
                  course: selectedCourse,
                }
              "
            ></ng-container>
          </ng-template>

          <ng-template let-exam pTemplate="item">
            <ng-container
              *ngTemplateOutlet="
                shownData;
                context: {
                  course: exam,
                }
              "
            ></ng-container>
          </ng-template>
        </p-dropdown>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <app-edit-tool-bar
        class="flex flex-row justify-content-end"
        [showBtn]="{ cancel: true, save: true }"
        [saveBtn]="{ disabled: !selectedCourse || saveDisabled }"
        (canceled)="cancel()"
        (saved)="save()"
      ></app-edit-tool-bar>
    </ng-template>
  </p-card>

  <ng-template #shownData let-course="course">
    <div>
      {{ course.name }}
      <ng-container
        *ngTemplateOutlet="
          infoData;
          context: {
            data: course.languageLevel | staticData: StaticDataType.LanguageModules | async,
            icon: PrimeIcons.LANGUAGE,
            tooltip: t('skillLevel.title'),
          }
        "
      />

      <ng-container
        *ngTemplateOutlet="
          infoData;
          context: {
            data: course.startDate | appDate: { dateStyle: 'short' },
            icon: PrimeIcons.CALENDAR,
            tooltip: t('startDate.title'),
          }
        "
      />
      <ng-container
        *ngTemplateOutlet="
          infoData;
          context: {
            data: course.endDate | appDate: { dateStyle: 'short' },
            icon: PrimeIcons.CALENDAR,
            tooltip: t('endDate.title'),
          }
        "
      />
    </div>
  </ng-template>

  <ng-template #infoData let-data="data" let-icon="icon" let-tooltip="tooltip">
    <p-chip *ngIf="data" label="{{ data }}" icon="{{ icon }}" class="ml-1 small-chip" [pTooltip]="tooltip" />
  </ng-template>
</ng-container>
