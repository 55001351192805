import { Injectable } from "@angular/core";
import {
  AllCollectionsAutoFilterTemplateSyncInput,
  ApplyAutoFilterTemplateOnAllCandidatesGQL,
  ApplyAutoFilterTemplateOnAllCandidatesInput,
  AttachableAutoCollectionFragment,
  AttachAutoCollectionsToTemplateGQL,
  CollectionAutoFilterTemplate,
  CollectionAutoFilterTemplateDeleteInput,
  CollectionAutoFilterTemplateDetachAllInput,
  CollectionAutoFilterTemplateDetachInput,
  CollectionAutoFilterTemplateForListFragment,
  CollectionAutoFilterTemplateFragmentDoc,
  CollectionAutoFilterTemplateGetAllInput,
  CollectionAutoFilterTemplateGetInput,
  CollectionAutoFilterTemplateSetInput,
  CollectionAutoFilterTemplateSyncInput,
  CollectionCreatedByAutoFilterTemplateFragment,
  CollectionFragment,
  CreatedCollectionsOfAutoFilterTemplateGetInput,
  DeleteCollectionAutoFilterTemplateGQL,
  DetachAllCollectionsFromTemplateGQL,
  DetachCollectionFromTemplateGQL,
  GetAllByTemplateGeneratedCollectionsGQL,
  GetAllCollectionAutoFilterTemplatesGQL,
  GetAttachableAutoCollectionsGQL,
  GetAttachableAutoCollectionsInput,
  GetCollectionAutoFilterTemplateGQL,
  SetCollectionAutoFilterTemplateGQL,
  SyncAllCollectionsWithTemplateGQL,
  SyncCollectionWithTemplateGQL,
} from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class CollectionAutoFilterTemplateService {
  constructor(
    private readonly getAllGql: GetAllCollectionAutoFilterTemplatesGQL,
    private readonly getGql: GetCollectionAutoFilterTemplateGQL,
    private readonly setGql: SetCollectionAutoFilterTemplateGQL,
    private readonly deleteGql: DeleteCollectionAutoFilterTemplateGQL,
    private readonly getAllByTemplateGeneratedGql: GetAllByTemplateGeneratedCollectionsGQL,
    private readonly syncColWithTemplate: SyncCollectionWithTemplateGQL,
    private readonly syncAllColsWithTemplate: SyncAllCollectionsWithTemplateGQL,
    private readonly detachColFromTemplate: DetachCollectionFromTemplateGQL,
    private readonly detachAllColsFromTemplate: DetachAllCollectionsFromTemplateGQL,
    private readonly applyOnAllCandidatesGql: ApplyAutoFilterTemplateOnAllCandidatesGQL,
    private readonly getAttachableAutoCollectionsGql: GetAttachableAutoCollectionsGQL,
    private readonly attachAutoCollectionsToTemplateGql: AttachAutoCollectionsToTemplateGQL
  ) {}

  getAll(input: CollectionAutoFilterTemplateGetAllInput): Observable<CollectionAutoFilterTemplateForListFragment[]> {
    return this.getAllGql
      .watch({ input: input })
      .valueChanges.pipe(map((result) => result.data.getAllCollectionAutoFilterTemplates));
  }

  get(input: CollectionAutoFilterTemplateGetInput): Observable<CollectionAutoFilterTemplate> {
    return this.getGql.fetch({ input: input }).pipe(map((result) => result.data.getCollectionAutoFilterTemplate));
  }

  set(input: CollectionAutoFilterTemplateSetInput): Observable<CollectionAutoFilterTemplate> {
    return this.setGql
      .mutate(
        { input: input },
        {
          update: (cache, result) =>
            cache.modify({
              fields: {
                getAllCollectionAutoFilterTemplates: (refs, helper) =>
                  updateApolloCache(input, refs, helper, cache, result.data.setCollectionAutoFilterTemplate),
              },
            }),
        }
      )
      .pipe(map((result) => result.data.setCollectionAutoFilterTemplate));
  }

  delete(data: CollectionAutoFilterTemplateDeleteInput): Observable<boolean> {
    const input: CollectionAutoFilterTemplateDeleteInput = {
      id: data.id,
      organizationId: data.organizationId,
      deletionBehavior: data.deletionBehavior,
      _etag: data._etag,
    };
    return this.deleteGql
      .mutate(
        { input: input },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getAllCollectionAutoFilterTemplates: (refs, { readField }) =>
                  refs.filter((ref) => readField("id", ref) !== input.id),
              },
            }),
        }
      )
      .pipe(map((result) => result.data.deleteCollectionAutoFilterTemplate.status));
  }

  getByTemplateCreatedAutoCollections(
    input: CreatedCollectionsOfAutoFilterTemplateGetInput
  ): Observable<CollectionCreatedByAutoFilterTemplateFragment[]> {
    return this.getAllByTemplateGeneratedGql
      .fetch({ input: input })
      .pipe(map((result) => result.data.getAllByTemplateGeneratedCollections));
  }

  syncCollectionWithTemplate(
    input: CollectionAutoFilterTemplateSyncInput
  ): Observable<CollectionCreatedByAutoFilterTemplateFragment> {
    return this.syncColWithTemplate
      .mutate(
        { input: input },
        {
          update: (cache, result) =>
            cache.modify({
              fields: {
                getAllCollectionAutoFilterTemplates: (refs, helper) =>
                  updateApolloCache(input, refs, helper, cache, result.data.syncCollectionWithTemplate),
              },
            }),
        }
      )
      .pipe(map((result) => result.data.syncCollectionWithTemplate));
  }

  syncAllCollectionsWithTemplate(
    input: AllCollectionsAutoFilterTemplateSyncInput
  ): Observable<CollectionCreatedByAutoFilterTemplateFragment[]> {
    const requestData = { organizationId: input.organizationId, id: input.id };
    return this.syncAllColsWithTemplate
      .mutate(
        { input: requestData },
        {
          update: (cache, result) =>
            cache.modify({
              fields: {
                getAllCollectionAutoFilterTemplates: (refs, helper) =>
                  updateApolloCache(input, refs, helper, cache, result.data.syncAllCollectionsWithTemplate),
              },
            }),
        }
      )
      .pipe(map((result) => result.data.syncAllCollectionsWithTemplate));
  }

  detachCollectionFromTemplate(input: CollectionAutoFilterTemplateDetachInput): Observable<CollectionFragment> {
    return this.detachColFromTemplate
      .mutate({ input: input })
      .pipe(map((result) => result.data.detachCollectionFromTemplate));
  }

  detachAllCollectionsFromTemplate(
    input: CollectionAutoFilterTemplateDetachAllInput
  ): Observable<CollectionFragment[]> {
    const requestData = { organizationId: input.organizationId, id: input.id };
    return this.detachAllColsFromTemplate
      .mutate({ input: requestData })
      .pipe(map((result) => result.data.detachAllCollectionsFromTemplate));
  }

  getAttachableAutoCollections(
    templateId: string,
    organizationId: string
  ): Observable<AttachableAutoCollectionFragment[]> {
    const input: GetAttachableAutoCollectionsInput = { organizationId: organizationId, templateId: templateId };
    return this.getAttachableAutoCollectionsGql
      .fetch({ input: input })
      .pipe(map((result) => result.data.getAttachableAutoCollections));
  }

  attachAutoCollectionsToTemplate(
    templateId: string,
    organizationId: string,
    collectionIds: string[]
  ): Observable<CollectionFragment[]> {
    const input = { templateId, organizationId, collectionIds };
    return this.attachAutoCollectionsToTemplateGql
      .mutate({ input: input })
      .pipe(map((result) => result.data.attachAutoCollectionsToTemplate as CollectionFragment[]));
  }

  applyOnAllCandidates(data: ApplyAutoFilterTemplateOnAllCandidatesInput): Observable<any> {
    const input = { id: data.id, organizationId: data.organizationId };
    return this.applyOnAllCandidatesGql
      .mutate(
        { input: input },
        {
          update: (cache, result) =>
            cache.modify({
              fields: {
                getAllCollectionAutoFilterTemplates: (refs, helper) =>
                  updateApolloCache(input, refs, helper, cache, result.data.applyAutoFilterTemplateOnAllCandidates),
              },
            }),
        }
      )
      .pipe(map((result) => result.data.applyAutoFilterTemplateOnAllCandidates));
  }
}

function updateApolloCache(input, refs, { storeFieldName }, cache, data): any {
  if (!storeFieldName.includes(input.organizationId)) return refs;
  const ref = cache.writeFragment({
    data: data,
    fragment: CollectionAutoFilterTemplateFragmentDoc,
    fragmentName: "CollectionAutoFilterTemplate",
  });
  if (!refs?.length) return [ref];
  const index = refs.findIndex((x) => x.__ref == ref.__ref);
  if (index > -1) {
    return [...refs.slice(0, index), ref, ...refs.slice(index + 1)];
  } else {
    return [...refs, ref];
  }
}
