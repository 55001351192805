import { Pipe, PipeTransform } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { NumberFormatOptions, TranslocoLocaleService } from "@jsverse/transloco-locale";

@Pipe({ name: "appDecimal", standalone: false })
export class AppDecimalPipe implements PipeTransform {
  private readonly language = this.transloco.getActiveLang();

  constructor(
    private readonly localeService: TranslocoLocaleService,
    private readonly transloco: TranslocoService
  ) {}

  protected currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  transform(value: number, options: NumberFormatOptions, language?: string): string {
    // Now, this is actually wrong, but what are you gonna do?
    // Different languages default to different numbering systems. However, Transloco, PrimeNG and pretty much everything else thinks that numbers
    // are only represented using 1, 2, 3, etc. It seems way too difficult to teach e.g. PrimeNG's calendar to display Persian numbers,
    // so we'll just force our "latin" numbers everywhere.
    (<Intl.NumberFormatOptions>options).numberingSystem = "latn";

    return this.localeService.localizeNumber(value, "decimal", language ?? this.language, options);
  }
}
