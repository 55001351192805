import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { getAllVisaRoles } from "@ankaadia/ankaadia-shared";
import { StaticDataModel, SupportedImmigrationCountry } from "@ankaadia/graphql";
import { distinctUntilChanged, map, startWith, switchMap } from "rxjs";
import { CandidateResidencePermitComponent } from "../../../../candidate-reusables/candidate-residence-permit/candidate-residence-permit.component";
import { ResponsibleRolePurpose } from "../../../../candidate-reusables/candidate-responsible-representative-group/candidate-responsible-representative-group.service";
import { DiplomaticMissionService } from "../../../../diplomatic-missions/diplomatic-mission.service";
import { CandidateForm, VisaFormDe } from "../../../candidate-form.model";

@Component({
  selector: "app-candidate-visa-de",
  templateUrl: "./candidate-visa-de.component.html",
  standalone: false,
})
export class CandidateVisaDEComponent implements OnInit {
  readonly ResponsibleRolePurposeType = ResponsibleRolePurpose;
  readonly today = new Date();
  protected readonly responsibleRoles = getAllVisaRoles();

  @Input()
  form: VisaFormDe;

  @Input()
  candidateId: string;

  @Input()
  candidateOrganizationId: string;

  @Input()
  readonly: boolean;

  protected diplomaticMissionsOfCountryInGermany: StaticDataModel[];

  @ViewChild(CandidateResidencePermitComponent)
  protected residencePermitComponent: CandidateResidencePermitComponent;

  constructor(private readonly diplomaticMissionService: DiplomaticMissionService) {}

  protected visaRelocationRequirementsControl: FormControl<string[]>;

  ngOnInit(): void {
    this.visaRelocationRequirementsControl = this.form.controls.visaRelocationRequirements;

    this.form.controls.visaEnquired.valueChanges
      .pipe(startWith(this.form.controls.visaEnquired.value))
      .subscribe((x) => {
        if (x) {
          this.form.controls.visaEnquiredDate.enable();
        } else {
          this.form.controls.visaEnquiredDate.setValue(null);
          this.form.controls.visaEnquiredDate.disable();
        }
      });
    //VisaRequestDate must be always enabled. https://dev.azure.com/kleinod21/Ankaadia/_workitems/edit/3413

    // formly hasn't initialized the form by now, so we'll subscribe to the top component instead
    const profile = (<CandidateForm>this.form.root).controls.os.controls.profile;
    profile.valueChanges
      .pipe(
        startWith(profile.value),
        map((x) => x.countryOfOrigin),
        distinctUntilChanged(),
        switchMap((x) => this.diplomaticMissionService.getDiplomaticMissionsAbroad(SupportedImmigrationCountry.De, x))
      )
      .subscribe((x) => {
        const value = this.form.controls.diplomaticMissionOfGermanyInCountry.value;
        this.diplomaticMissionsOfCountryInGermany = x;
        this.form.controls.diplomaticMissionOfGermanyInCountry.setValue(value);
      });
  }
}
