import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { CandidateNotesComponent } from "./candidate-notes.component";

@NgModule({
  imports: [SharedModule],
  declarations: [CandidateNotesComponent],
  exports: [CandidateNotesComponent],
})
export class CandidateNotesModule {}
