import { CandidateStatus, StaticDataModel } from "@ankaadia/graphql";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { indexOf, orderBy } from "lodash";

const SortedCandidateStatus: CandidateStatus[] = [
  CandidateStatus.New,
  CandidateStatus.Approved,
  CandidateStatus.ForSelection,
  CandidateStatus.Associated,
  CandidateStatus.Matched,
  CandidateStatus.Transferred,
  CandidateStatus.InRecognition,
  CandidateStatus.LabourMigrationFinished,
  CandidateStatus.Archived,
  CandidateStatus.Paused,
  CandidateStatus.Rejected,
  CandidateStatus.DroppedOut,
  CandidateStatus.Unknown,
];

export const getSortedCandidateStatusList = (
  staticDataService: StaticDataService,
  language?: string
): StaticDataModel[] => {
  const candidateStatusTranslated = staticDataService.transformEnumToStaticDataModel(
    "CandidateStatus",
    CandidateStatus,
    {
      language,
      include: SortedCandidateStatus,
    }
  );
  return orderBy(candidateStatusTranslated, (x) => indexOf(SortedCandidateStatus, x.value));
};
