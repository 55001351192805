import { Injectable } from "@angular/core";
import {
  CreateSharingTemplateGQL,
  DeleteSharingTemplateGQL,
  Sharing,
  SharingTemplate,
  SharingTemplateCreateInput,
  SharingTemplateUpdateInput,
  UpdateSharingTemplateGQL,
} from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { Observable, map } from "rxjs";

export type SharingOrTemplate = Sharing | SharingTemplate;

@Injectable({ providedIn: "root" })
export class SharingTemplatesService {
  readonly language = this.transloco.getActiveLang();
  constructor(
    private readonly removeSharing: DeleteSharingTemplateGQL,
    private readonly createSharingsTemplate: CreateSharingTemplateGQL,
    private readonly upSharing: UpdateSharingTemplateGQL,
    private readonly transloco: TranslocoService
  ) {}

  deleteSharingTemplate(sharing: SharingTemplate): Observable<boolean> {
    return this.removeSharing
      .mutate({ input: { id: sharing.id, organizationId: sharing.organizationId, _etag: sharing._etag } })
      .pipe(map((x) => x.data.deleteSharingTemplate.status));
  }

  updateSharingTemplate(sharing: SharingTemplateUpdateInput): Observable<SharingTemplate> {
    const updateSharing = new SharingTemplateUpdateInput();
    updateSharing._etag = sharing._etag;
    updateSharing.enabled = sharing.enabled;
    updateSharing.id = sharing.id;
    updateSharing.organizationId = sharing.organizationId;
    updateSharing.sharedCollectionId = sharing.sharedCollectionId;
    updateSharing.sharingType = sharing.sharingType;
    updateSharing.autoCVRequested = sharing.autoCVRequested;
    updateSharing.providedTemplates = sharing.providedTemplates;
    updateSharing.sharedFileTypes = sharing.sharedFileTypes;
    updateSharing.disableSubmitFeedback = sharing.disableSubmitFeedback;
    updateSharing.isAnonymous = sharing.isAnonymous;
    updateSharing.anonymizationOptions = sharing.anonymizationOptions;
    updateSharing.sharedTabs = sharing.sharedTabs;
    updateSharing.expiryDate = sharing.expiryDate;
    updateSharing.isCandidateEditable = sharing.isCandidateEditable;
    updateSharing.shareInternalDocuments = sharing.shareInternalDocuments;
    updateSharing.enableNotificationEmails = sharing.enableNotificationEmails;
    updateSharing.notificationEmailUserGroupIds = sharing.notificationEmailUserGroupIds;
    updateSharing.profileAccessId = sharing.profileAccessId;
    updateSharing.destinationOrganizationIdOrVariable = sharing.destinationOrganizationIdOrVariable;

    return this.upSharing
      .mutate({ input: updateSharing })
      .pipe(map((x) => x.data.updateSharingTemplate as SharingTemplate));
  }

  createSharingTemplate(createInput: SharingTemplateCreateInput): Observable<SharingTemplate[]> {
    return this.createSharingsTemplate.mutate({ input: createInput }).pipe(map((x) => x.data.createSharingTemplate));
  }
}
