<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <label for="startOfFurtherEducation">
    {{
      immigrationCountry === SupportedImmigrationCountry.At
        ? t("startOfComplementaryMeasure.title")
        : t("startOfFurtherEducation.title")
    }}
  </label>
  <p-calendar inputId="startOfFurtherEducation" [formControl]="control" [readonly]="readonly"></p-calendar>

  <small class="p-error" *ngIf="control.errors?.required">
    {{
      immigrationCountry === SupportedImmigrationCountry.At
        ? t("startOfComplementaryMeasure.required")
        : t("startOfFurtherEducation.required")
    }}
  </small>
</ng-container>
