import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { AbstractControl, ValidationErrors, Validators } from "@angular/forms";
import { omitEach } from "@ankaadia/ankaadia-shared";
import {
  AnonymizationTypeEnum,
  CollectionType,
  Sharing,
  SharingPresetForSelectionFragment,
  SharingTemplate,
  SharingTypeEnum,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { ConfirmationService } from "primeng/api";
import { SettingsService } from "../../../shared/services/settings.service";
import { OrganizationSystemSettingsService } from "../../organizations/system-settings.service";
import { SharingPresetService } from "../../sharing-preset/sharing-preset.service";
import { SharingEditComponent } from "../sharing-edit/sharing-edit.component";
import { GroupedSelectableOrganization, SharingFormData } from "../sharing-edit/sharing-form.model";
import { SharingFormService } from "../sharing-edit/sharing-form.service";
import { SharingOrTemplate } from "../sharing-templates.service";
import { SharingProvider } from "../sharings-from/sharings-provider";

export interface CloseSharingFormArguments {
  save: boolean;
  data: SharingFormData;
  autoSync: boolean;
}

@Component({
  selector: "app-sharing-edit-dialog",
  templateUrl: "./sharing-edit-dialog.component.html",
  standalone: false,
})
export class SharingEditDialogComponent implements OnInit, OnChanges {
  readonly form = this.formService.createForm();
  readonly CollectionType = CollectionType;
  readonly presets$ = this.presetService.selectable(this.settings.organizationId);

  @Input()
  sharingOrTemplate: SharingOrTemplate;

  @Input()
  showManageCollectionMembers: boolean;

  @Input()
  additionalOrganizations: GroupedSelectableOrganization[];

  @Input()
  isSharingTemplate: boolean;

  @Input()
  sharingProvider: SharingProvider;

  @Output()
  readonly closed = new EventEmitter<CloseSharingFormArguments>();

  @ViewChild(SharingEditComponent)
  sharingEditComponent: SharingEditComponent;

  isEditMode: boolean;
  disableSubmit: boolean;
  autoSync = true;

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly presetService: SharingPresetService,
    private readonly settings: SettingsService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly systemSettingsService: OrganizationSystemSettingsService,
    private readonly formService: SharingFormService
  ) {}

  get destinationOrganizationId(): string {
    return !this.sharingOrTemplate
      ? null
      : isSharingTemplate(this.sharingOrTemplate)
        ? this.sharingOrTemplate.destinationOrganizationIdOrVariable
        : this.sharingOrTemplate.destinationOrganizationId;
  }

  ngOnInit(): void {
    this.systemSettingsService.get(this.settings.organizationId).subscribe((settings) => {
      const requiredFields = (settings.requiredCandidateFields ?? []).filter(
        (x) => x !== "internal.sourceOrganizationId"
      );
      const validator = requiredFields.some((x) => x.startsWith("internal."))
        ? this.internalTabValidator.bind(this)
        : Validators.nullValidator;
      this.form.controls.sharedTabs.setValidators(validator);
    });
  }

  ngOnChanges(): void {
    this.disableSubmit = false;

    if (this.sharingOrTemplate != null) {
      this.isEditMode = true;
      for (const template of this.sharingOrTemplate.providedTemplates ?? []) {
        delete template.__typename;
      }
      this.form.patchValue(this.sharingOrTemplate);
      if (isSharingTemplate(this.sharingOrTemplate)) {
        this.form.controls.destinationOrganizationId.setValue(
          this.sharingOrTemplate.destinationOrganizationIdOrVariableName
        );
      } else {
        this.form.controls.destinationOrganizationId.setValue(this.sharingOrTemplate.destinationOrganizationName);
      }
      this.form.controls.destinationOrganizationId.disable();
      this.form.controls.sharedFileTypes.disable();
      this.form.controls.notificationEmailUserGroups.disable();
      this.form.controls.notificationEmailUserGroups.clearValidators();
      this.form.controls.notificationEmailUserGroupIds.setValidators(Validators.required);
      this.form.controls.anonymizationOptions.disable();
      this.form.controls.isAnonymous.disable();
      if (this.form.controls.sharingType.value != SharingTypeEnum.View) {
        this.form.controls.sharingType.enable();
      } else {
        this.form.controls.sharingType.disable();
      }
    } else {
      this.isEditMode = false;
      this.form.reset();
      this.form.controls.enabled.setValue(true);
      this.form.controls.isAnonymous.setValue(false);
      this.form.controls.anonymizationOptions.setValue([AnonymizationTypeEnum.Name]);
      this.form.controls.anonymizationOptions.setValidators(Validators.required);
      this.form.controls.anonymizationOptions.disable();
      this.form.controls.isCandidateEditable.setValue(false);
      this.form.controls.shareInternalDocuments.setValue(false);
      this.form.controls.showCompletionStateAtDocuments.setValue(false);
      this.form.controls.sharedTabs.setValue(this.settings.sharingSettings.sharedTabs);
      this.form.controls.autoCVRequested.setValue(this.settings.sharingSettings.autoCV);
      this.form.controls.providedTemplates.setValue(null);
      this.form.controls.sharedFileTypes.setValue(this.settings.sharingSettings.sharedDocuments);
      this.form.controls.disableSubmitFeedback.setValue(this.settings.sharingSettings.disableSubmitFeedback);
      this.form.controls.enableNotificationEmails.setValue(false);
      this.form.controls.notificationEmailUserGroups.clear();
      this.form.controls.notificationEmailUserGroups.setValidators(Validators.required);
      this.form.controls.notificationEmailUserGroupIds.disable();
      this.form.controls.notificationEmailUserGroupIds.clearValidators();
      this.form.controls.destinationOrganizationId.enable();
    }
    this.form.markAsPristine();
    this.changeDetector.detectChanges();
  }

  cancel(): void {
    this.closed.emit({
      save: false,
      data: null,
      autoSync: false,
    });
  }

  save(event: Event): void {
    const exposedTabs = ["internal", "notes", "emails"];
    this.disableSubmit = true;
    if (
      this.form.controls.sharedTabs.touched &&
      this.form.controls.sharedTabs.value?.some((t) => exposedTabs.includes(t))
    ) {
      this.confirmationService.confirm({
        target: event.target,
        message: translate("sharing.confirmTabsSharing", {
          destinationOrganizationName: this.sharingEditComponent.getOrganizationName(),
        }),
        accept: () => this.internalSave(),
        reject: () => (this.disableSubmit = false),
      });
    } else {
      this.internalSave();
    }
  }

  selectPreset(preset: SharingPresetForSelectionFragment): void {
    this.presetService.prefill(preset.id, preset.organizationId).subscribe(({ sharing }) => {
      this.form.controls.sharingType.setValue(sharing.sharingType);
      this.form.controls.sharedFileTypes.setValue(sharing.sharedFileTypes);
      this.form.controls.sharedTabs.setValue(sharing.sharedTabs);
      this.form.controls.profileAccessId.setValue(sharing.profileAccessId);
      this.form.controls.expiryDate.setValue(sharing.expiryDate);
      this.form.controls.enabled.setValue(sharing.enabled);
      this.form.controls.autoCVRequested.setValue(sharing.autoCVRequested);
      this.form.controls.providedTemplates.setValue(omitEach(sharing.providedTemplates, "__typename"));
      this.form.controls.disableSubmitFeedback.setValue(sharing.disableSubmitFeedback);
      this.form.controls.isAnonymous.setValue(sharing.isAnonymous);
      this.form.controls.anonymizationOptions.setValue(sharing.anonymizationOptions);
      this.form.controls.isCandidateEditable.setValue(sharing.isCandidateEditable);
      this.form.controls.shareInternalDocuments.setValue(sharing.shareInternalDocuments);
      this.form.controls.showCompletionStateAtDocuments.setValue(sharing.showCompletionStateAtDocuments);
      this.form.controls.enableNotificationEmails.setValue(sharing.enableNotificationEmails);

      // hopefully, the setValue(null) shenanigans are done in 10 ms and we can try setting the fields again
      // gl trying to fix it differently
      setTimeout(() => {
        this.form.controls.sharedFileTypes.setValue(sharing.sharedFileTypes);
      }, 10);
    });
  }

  private internalSave(): void {
    this.disableSubmit = true;
    const data: SharingFormData = this.form.getRawValue();
    this.closed.emit({
      save: true,
      data,
      autoSync: this.autoSync,
    });
  }

  private internalTabValidator(control: AbstractControl<string[]>): ValidationErrors | null {
    if (control.value) {
      if (!control.value?.includes("internal")) {
        return { internalRequired: true };
      }
    }
    return null;
  }
}

function isSharingTemplate(sharingOrTemplate: SharingTemplate | Sharing): sharingOrTemplate is SharingTemplate {
  if (!sharingOrTemplate) return false;
  const sharingTemplate = sharingOrTemplate as SharingTemplate;
  return (
    sharingOrTemplate instanceof SharingTemplate ||
    sharingTemplate.__typename == "SharingTemplate" ||
    !!sharingTemplate.destinationOrganizationIdOrVariable
  );
}
