import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { MigrationSpecificsModule } from "./../../migration-specific/migration-specifics.module";
import { DocumentSetItemComponent } from "./document-set-item/document-set-item.component";
import { RequiredDocumentDialogComponent } from "./required-document-dialog/required-document-dialog.component";
import { RequiredDocumentsComponent } from "./required-documents.component";

@NgModule({
  imports: [SharedModule, MigrationSpecificsModule],
  declarations: [RequiredDocumentsComponent, DocumentSetItemComponent, RequiredDocumentDialogComponent],
})
export class RequiredDocumentsModule {}
