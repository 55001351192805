import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "app-formly-textarea",
  templateUrl: "./formly-textarea.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FormlyTextareaComponent extends FieldType {}
