<ng-container *appTranslate="let t; lang: language + '|static'">
  <app-candidate-aligned-label
    class="field"
    labelFor="{{ formId }}-employerId"
    [labelText]="t('employer.title')"
    [useAlignmentCheckBox]="false"
  >
  </app-candidate-aligned-label>
  <p-dropdown
    inputId="{{ formId }}-employerId"
    [formControl]="control"
    [options]="employers"
    optionLabel="name"
    optionValue="id"
    [showClear]="true"
    [placeholder]="t('employer.placeholder')"
    [readonly]="readonly"
    appCandidateFieldNotShared
    formControlValueAlwaysInOptions
    [isShared]="isFieldShared"
    (onChange)="onEmployerChange($event)"
  ></p-dropdown>
</ng-container>
