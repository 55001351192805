<ng-container *appTranslate="let t">
  <form [formGroup]="form">
    <p-fieldset [toggleable]="true" [legend]="t('qualificationModules.caption')">
      <app-candidate-document-upload
        [fileTypes]="['QUALPLAN', 'CERTIFICATEFURTHERTRAINING', 'EDUCATIONVOUCHER', 'CONFOQUAL']"
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
        class="mt-3"
      ></app-candidate-document-upload>

      <app-candidate-detailed-equivalence [form]="form" [readonly]="readonly"></app-candidate-detailed-equivalence>
    </p-fieldset>

    <p-fieldset [legend]="t('migration.de.qualificationMeasure.responsible')">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 lg:col-6">
          <app-candidate-responsible-role
            [form]="form"
            [responsibleRolePurpose]="ResponsibleRolePurposeType.Qualification"
            [candidateId]="candidateId"
            [readonly]="readonly"
            [alwaysShownRoles]="responsibleRoles"
            class="field"
          ></app-candidate-responsible-role>
        </div>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('qualificationMeasure.title')">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 lg:col-3">
          <app-candidate-qualification-measure
            class="field"
            [form]="form"
            [readonly]="readonly"
          ></app-candidate-qualification-measure>
        </div>

        <div class="field col-12 lg:col-3">
          <app-candidate-qualification-type
            class="field"
            [form]="form"
            [readonly]="readonly"
          ></app-candidate-qualification-type>
        </div>

        <div class="field col-12 lg:col-3">
          <app-candidate-start-of-further-education
            class="field"
            [form]="form"
            [readonly]="readonly"
            [immigrationCountry]="ImmigrationCountry"
          ></app-candidate-start-of-further-education>
        </div>

        <div class="field col-12 lg:col-3">
          <app-candidate-end-of-further-education
            class="field"
            [form]="form"
            [readonly]="readonly"
            [immigrationCountry]="ImmigrationCountry"
          ></app-candidate-end-of-further-education>
        </div>

        <div class="field col-12 lg:col-3">
          <label for="theoryHours">{{ t("theoryHours.title") }}</label>
          <p-inputNumber
            inputId="theoryHours"
            formControlName="theoryHours"
            [min]="0"
            [readonly]="readonly"
          ></p-inputNumber>
        </div>

        <div class="field col-12 lg:col-3">
          <label for="theoryInstitution">{{ t("theoryInstitution.title") }}</label>
          <input pInputText id="theoryInstitution" formControlName="theoryInstitution" [readonly]="readonly" />
        </div>

        <div class="field col-12 lg:col-3">
          <label for="qualificationMeasureFileNumber">{{ t("qualificationMeasureFileNumber.title") }}</label>
          <input
            pInputText
            id="qualificationMeasureFileNumber"
            formControlName="qualificationMeasureFileNumber"
            [readonly]="readonly"
          />
        </div>
        <div class="field col-12 lg:col-3">
          <label for="courseNumber">{{ t("courseNumber.title") }}</label>
          <input pInputText id="courseNumber" formControlName="courseNumber" [readonly]="readonly" />
        </div>

        <div class="field col-12 lg:col-3">
          <label for="practiceHours">{{ t("practiceHours.title") }}</label>
          <p-inputNumber
            inputId="practiceHours"
            formControlName="practiceHours"
            [min]="0"
            [readonly]="readonly"
          ></p-inputNumber>
        </div>

        <div class="field col-12 lg:col-3">
          <label for="practiceInstitution">{{ t("practiceInstitution.title") }}</label>
          <input pInputText id="practiceInstitution" formControlName="practiceInstitution" [readonly]="readonly" />
        </div>

        <div class="field col-12 lg:col-6">
          <label for="careSituations">{{ t("careSituations.title") }}</label>
          <p-inputNumber
            inputId="careSituations"
            formControlName="careSituations"
            [min]="0"
            [readonly]="readonly"
          ></p-inputNumber>
        </div>

        <div class="field col-12 lg:col-6">
          <label for="theoryExamDate">{{ t("theoryExamDate.title") }}</label>
          <p-calendar inputId="theoryExamDate" formControlName="theoryExamDate" [readonly]="readonly"></p-calendar>
        </div>

        <div class="field col-12 lg:col-6">
          <label for="practiceExamDate">{{ t("practiceExamDate.title") }}</label>
          <p-calendar inputId="practiceExamDate" formControlName="practiceExamDate" [readonly]="readonly"></p-calendar>
        </div>

        <div class="field hidden col-12 lg:block lg:col-9">&nbsp;</div>

        <app-candidate-document-date
          [candidateId]="candidateId"
          [candidateOrganizationId]="candidateOrganizationId"
          [candidateForm]="form"
          [readonly]="readonly"
          [label]="t('qualificationIsDone.title')"
          documentType="CERTIFICATEFURTHERTRAINING"
          selectedSet="Default"
          dateField="issueDate"
          class="field col-12 lg:col-6"
        ></app-candidate-document-date>

        <div class="field col-12 lg:col-6">
          <div class="field-checkbox">
            <p-checkbox
              inputId="qualificationIsSend"
              [binary]="true"
              [readonly]="readonly"
              formControlName="qualificationIsSend"
            ></p-checkbox>
            <label for="qualificationIsSend">{{ t("qualificationIsSend.title") }}</label>
          </div>

          <p-calendar formControlName="qualificationIsSendDate" [maxDate]="today" [readonly]="readonly"></p-calendar>
        </div>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('migration.de.qualificationMeasure.exams')" [toggleable]="true">
      <app-candidate-qualification-exams
        [form]="form"
        [readonly]="readonly"
        [moduleSuggestions]="selectedQualificationModules | async"
      ></app-candidate-qualification-exams>
    </p-fieldset>

    <p-fieldset [legend]="t('educationVoucher.title')" class="relative">
      <app-candidate-document-upload
        [fileTypes]="['EDUCATIONVOUCHER']"
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
      ></app-candidate-document-upload>

      <app-candidate-responsible-representative-group
        [form]="form"
        [readonly]="readonly"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateId]="candidateId"
        [alwaysShownRoles]="responsibleRolesEducationVoucher"
        [canHaveADeputyOrRepresentative]="canHaveADeputyOrRepresentative"
        [responsibleRolePurpose]="ResponsiblePurpose.EducationVoucher"
        [purposeData]="qualificationMeasureDataDe"
        styleRoleClass="field col-12 lg:col-{{ canHaveADeputyOrRepresentative ? 3 : 6 }}"
        styleOrganizationClass="field col-12 lg:col-3"
        styleRepresentativeClass="field col-12 lg:col-3"
        styleDeputyClass="field col-12 lg:col-3"
        class="p-fluid formgrid grid"
      ></app-candidate-responsible-representative-group>

      <div class="p-fluid formgrid grid">
        <div class="field col-12 lg:col-3">
          <div class="field-checkbox">
            <p-checkbox
              inputId="educationVoucherRequested"
              [binary]="true"
              [readonly]="readonly"
              formControlName="educationVoucherRequested"
            ></p-checkbox>
            <label for="educationVoucherRequested">{{ t("educationVoucherRequested.title") }}</label>
          </div>

          <p-calendar
            formControlName="educationVoucherRequestDate"
            [maxDate]="today"
            [readonly]="readonly"
          ></p-calendar>
        </div>

        <div class="field col-12 lg:col-3">
          <label for="educationVoucherFileNumber">{{ t("educationVoucherFileNumber.title") }}</label>
          <input
            pInputText
            id="educationVoucherFileNumber"
            formControlName="educationVoucherFileNumber"
            [readonly]="readonly"
          />
        </div>

        <app-candidate-document-date
          [candidateId]="candidateId"
          [candidateOrganizationId]="candidateOrganizationId"
          [candidateForm]="form"
          [readonly]="readonly"
          [label]="t('educationVoucherReceived.title')"
          documentType="EDUCATIONVOUCHER"
          selectedSet="Default"
          dateField="dateOfReceipt"
          class="field col-12 lg:col-3"
        ></app-candidate-document-date>
      </div>
    </p-fieldset>
  </form>
</ng-container>
