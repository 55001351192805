import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from "@angular/core";
import { EmailEventType, EmailForListFragment, EmailFragment, MessengerEventType } from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { clone } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { OverlayPanel } from "primeng/overlaypanel";
import { Observable, Subscription, finalize, of, tap } from "rxjs";
import { MessengerService } from "../../shared/services/messengerService.service";
import { MessageService } from "../message/message.service";
import { ProcessService } from "../process/process.service";
import { EmailsService } from "./emails.service";

@Component({
  selector: "app-emails",
  templateUrl: "./emails.component.html",
  styleUrl: "./emails.component.scss",
  standalone: false,
})
export class EmailsComponent implements OnChanges, OnDestroy {
  private emailsSubscription: Subscription;

  @Input()
  organizationId: string;

  @Input()
  processId: string;

  @Input()
  candidateId: string;

  @Input()
  userId: string;

  @Input()
  mode: "email" | "outbox" = "email";

  @Input()
  directSending: boolean;

  @Input()
  processLanguage?: string = this.transloco.getActiveLang();

  @Input()
  readOnly: boolean;

  @Output()
  readonly hasNoErrors = new EventEmitter<boolean>();

  @Output()
  readonly outdated = new EventEmitter<void>();

  @Output()
  readonly sent = new EventEmitter<void>();

  @ViewChild("eventPanel")
  eventPanel: OverlayPanel;

  @ViewChild("messengerMessagePanel")
  messengerMessagePanel: OverlayPanel;

  @ViewChild("bannedPanel")
  bannedPanel: OverlayPanel;

  emails: EmailForListFragment[];
  selectedEmailForEvents: EmailFragment;
  messengerMessage: string;
  selectedEmailForPreview: EmailFragment;
  selectedEmailForBanned: EmailForListFragment;
  openPreviewDisabled: boolean;
  showEventsDisabled: boolean;
  showMessengerMessageDisabled: boolean;
  showBannedDisabled: boolean;
  outdatedOutbox: boolean;
  nextOutboxRun: Date;
  isSending: boolean;
  protected messengerMessageType: MessengerEventType;
  protected messengerMessageLastSentDate: Date;
  protected messengerErrorMessage: string;

  constructor(
    private readonly emailService: EmailsService,
    private readonly messengersService: MessengerService,
    private readonly processService: ProcessService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly transloco: TranslocoService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateId) {
      this.updateEmails(changes.candidateId.currentValue, this.userId, this.processId, this.organizationId);
    }
    if (changes.userId) {
      this.updateEmails(this.candidateId, changes.userId.currentValue, this.processId, this.organizationId);
    }
    if (changes.processId) {
      this.updateEmails(this.candidateId, this.userId, changes.processId.currentValue, this.organizationId);
    }
    if (changes.organizationId) {
      this.updateEmails(this.candidateId, this.userId, this.processId, changes.organizationId.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.emailsSubscription?.unsubscribe();
  }

  reload(): void {
    if (this.mode === "email") {
      const selectedEmailForPreview = this.selectedEmailForPreview;
      const selectedEmailForEvents = this.selectedEmailForEvents;
      this.updateEmails(this.candidateId, this.userId, this.processId, this.organizationId);
      this.selectedEmailForPreview = selectedEmailForPreview;
      this.selectedEmailForEvents = selectedEmailForEvents;
    } else {
      this.updateEmails(this.candidateId, this.userId, this.processId, this.organizationId);
    }
  }

  openPreview(email: EmailForListFragment): void {
    if (!email) {
      this.selectedEmailForPreview = null;
      return;
    }

    if (!this.openPreviewDisabled) {
      this.openPreviewDisabled = true;
      this.getEmail(email)
        .pipe(finalize(() => (this.openPreviewDisabled = false)))
        .subscribe((email) => (this.selectedEmailForPreview = email));
    }
  }

  showEvents(email: EmailForListFragment, event: Event): void {
    if (!this.showEventsDisabled) {
      this.showEventsDisabled = true;
      this.getEmail(email)
        .pipe(finalize(() => (this.showEventsDisabled = false)))
        .subscribe((email) => {
          this.selectedEmailForEvents = email;
          this.eventPanel.show(event);
        });
    }
  }

  showMessengerMessage(email: EmailForListFragment, event: Event): void {
    if (!this.showMessengerMessageDisabled) {
      this.showMessengerMessageDisabled = true;
      this.messengersService
        .getSentMessage(email.candidateIds[0].id, email.candidateIds[0].orgId, email.messengerMessageIds[0])
        .pipe(finalize(() => (this.showMessengerMessageDisabled = false)))
        .subscribe((message) => {
          this.messengerMessage = message.message;
          if (message.events.length > 0) {
            this.messengerMessageType = message.events[message.events.length - 1].type;
            this.messengerMessageLastSentDate = message.events[message.events.length - 1].date;
            this.messengerErrorMessage = message.events[message.events.length - 1].message;
          } else {
            this.messengerMessageType = null;
            this.messengerMessageLastSentDate = null;
            this.messengerErrorMessage = null;
          }
          this.messengerMessagePanel.show(event);
        });
    }
  }

  showBanned(email: EmailForListFragment, event: Event): void {
    this.selectedEmailForBanned = email;
    this.bannedPanel.show(event);
  }

  send(subject: string, body: string): void {
    if (this.mode === "outbox") {
      this.isSending = true;
      this.processService
        .sendProcessEmail(this.selectedEmailForPreview.id, subject, body, this.organizationId, this.processId)
        .pipe(finalize(() => (this.isSending = false)))
        .subscribe(() => {
          this.selectedEmailForPreview = null;
          this.sent.emit();
          this.messageService.add({
            severity: "success",
            summary: translate("email.sent.title", null, this.processLanguage),
            detail: translate("email.sent.message", null, this.processLanguage),
          });
        });
    }
  }

  sendAll(event: Event): void {
    if (this.mode === "outbox") {
      this.confirmationService.confirm({
        target: event.target,
        message: translate("emails.confirmSend", null, this.processLanguage),
        icon: PrimeIcons.EXCLAMATION_TRIANGLE,
        accept: () => {
          this.isSending = true;
          this.processService
            .sendProcessEmails(this.organizationId, this.processId, this.candidateId)
            .pipe(finalize(() => (this.isSending = false)))
            .subscribe(() => {
              this.sent.emit();
              this.selectedEmailForPreview = null;
              this.messageService.add({
                severity: "success",
                summary: translate("emails.sent.title", null, this.processLanguage),
                detail: translate("emails.sent.message", null, this.processLanguage),
              });
            });
        },
      });
    }
  }

  private getEmail(email: EmailForListFragment): Observable<EmailFragment> {
    if (this.mode === "email") {
      return this.emailService.get(email.id, email.organizationId);
    } else if (this.mode === "outbox") {
      return this.emailService.getOutboxEmail(email.id, email.organizationId).pipe(
        tap((x) => {
          if (!x) {
            this.outdatedOutbox = true;
          }
        })
      );
    }
    return null;
  }

  private updateEmails(candidateId: string, userId: string, processId: string, organizationId: string): void {
    this.emailsSubscription?.unsubscribe();
    this.selectedEmailForPreview = null;
    this.selectedEmailForEvents = null;

    let obs: Observable<EmailForListFragment[]> = of([]);
    if (this.mode === "email") {
      if (!processId && candidateId && organizationId) {
        obs = this.emailService.getAllForCandidate(candidateId, organizationId);
      } else if (userId && organizationId) {
        obs = this.emailService.getAllForUser(userId, organizationId);
      } else if (processId && organizationId) {
        obs = this.emailService.getAllForProcess(processId, organizationId, candidateId);
      }
    } else if (this.mode === "outbox") {
      if (processId && organizationId) {
        obs = this.emailService.getOutboxForProcess(processId, organizationId, candidateId).pipe(
          tap(() => {
            this.selectedEmailForPreview = null;
            this.selectedEmailForEvents = null;
          })
        );
      }
      this.emailService.getNextOutboxRun(processId, organizationId).subscribe((x) => (this.nextOutboxRun = x));
    }

    this.emailsSubscription = obs.subscribe((x) => {
      this.emails = clone(x);
      this.outdatedOutbox = false;
      this.hasNoErrors.emit(this.isFailedRecently());
    });
  }

  private isFailedRecently(): boolean {
    const wiggleRoom = 5 * 60 * 1000; // 5 minutes
    const lastWindowRun = new Date(
      Math.min(
        ...this.emails
          .flatMap((e) => (e.events ?? []).filter((e) => e.type === EmailEventType.Sent))
          .map((e) => e.date.getTime())
      )
    );
    const minWindow = new Date(lastWindowRun.getTime() - wiggleRoom);
    const maxWindow = new Date(lastWindowRun.getTime() + wiggleRoom);
    return this.emails
      .filter((e) =>
        (e.events ?? []).some((e) => e.type === EmailEventType.Sent && e.date >= minWindow && e.date <= maxWindow)
      )
      .every((m) => m.events.every((e) => e.type !== EmailEventType.Failed));
  }
}
