import { Directive, OnInit } from "@angular/core";
import { Table } from "primeng/table";
import { merge } from "rxjs";

/**
 * Lo' and behold, typical PrimeNG.
 *
 * Baby PrimeNG provides support for the frozen columns but fails to update column sizes when the visible data set changes.
 * Here's our attempt to fix that by listening to the frozen column changes and re-rendering the whole table.
 */
@Directive({ selector: "p-table", standalone: false })
export class TableFrozenColumnFixDirective implements OnInit {
  constructor(private readonly table: Table) {}

  ngOnInit(): void {
    merge(this.table.onSort, this.table.onFilter).subscribe(() => {
      if (this.hasFrozenColumns()) {
        this.recalculateFrozenColumns();
      }
    });
  }

  private hasFrozenColumns(): boolean {
    return !!(<HTMLElement>this.table.el.nativeElement).querySelector(".p-frozen-column");
  }

  private recalculateFrozenColumns(): void {
    // extra crazy cherry on top: remove the table from DOM while we're doing the following shenanigans
    // if this is not done, the UI will flicker for a second in the frozen columns area
    const element = <HTMLElement>this.table.el.nativeElement;
    const parent = element.parentElement;
    parent.removeChild(element);

    // `virtualScroll` is used in an `*ngIf` somewhere at the top of the table component
    // so we're effectively destroying the table and re-creating it by doing this
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = !virtualScroll;
    this.table.cd.detectChanges();
    this.table.virtualScroll = virtualScroll;
    this.table.cd.detectChanges();

    parent.appendChild(element);
  }
}
