<ng-container *appTranslate="let t; lang: language + '|static'">
  <p-card>
    <div class="flex flex-column app-form p-card-content">
      <p-table
        #dt
        [value]="assignmentExceptions | tableSortable"
        [columns]="columns"
        [rows]="20"
        [paginator]="true"
        [globalFilterFields]="globalFilterFields"
        [rowHover]="true"
        [scrollable]="true"
        [sortField]="columns?.[0].fieldname"
        [sortOrder]="1"
        [customSort]="true"
        (sortFunction)="sort($event)"
        selectionMode="single"
        dataKey="id"
        class="app-form"
        editMode="row"
        styleClass="p-datatable app-form {{ 'no-focus' }}"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-column md:flex-row justify-content-between md:align-items-center table-header">
            <ng-container *ngTemplateOutlet="search"></ng-container>
            <p-button
              [pTooltip]="t('common.edit')"
              [label]="t('common.edit')"
              icon="pi pi-pencil"
              class="p-button-success mr-2"
              (onClick)="editAssignedCandidates()"
            ></p-button>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <ng-container *ngFor="let col of columns">
              <th [pSortableColumn]="col.fieldname">
                {{ col.header }} <p-sortIcon [field]="col.fieldname"></p-sortIcon>
              </th>
            </ng-container>
            <th></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
          <tr [pEditableRow]="rowData">
            <td>
              <app-candidate-flexible-edit-button
                [candidateId]="rowData.id"
                [candidateOrganizationId]="rowData.organizationId"
                [label]="rowData.displayId"
                [country]="rowData?.country ?? rowData?.candidateOriginCountry"
                [showLink]="displayCandidateLinks()"
              ></app-candidate-flexible-edit-button>
            </td>
            <td>
              <span>
                <span class="vertical-align-middle">
                  {{ rowData.displayName }}
                </span>
              </span>
            </td>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-dropdown
                    [showClear]="false"
                    styleClass="w-full compact minimalistic"
                    [options]="assignmentExceptionOptions"
                    [(ngModel)]="rowData.assignmentExceptionType"
                    appendTo="body"
                  >
                  </p-dropdown>
                </ng-template>
                <ng-template pTemplate="output">
                  <div class="white-space-nowrap cursor-pointer">
                    <span class="vertical-align-middle">
                      {{ rowData.assignmentExceptionType | enum: "CandidateAssignmentExceptionType" }}
                    </span>
                  </div>
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <span>
                <span class="vertical-align-middle">
                  {{ rowData.implicitType | enum: "CandidateAssignmentExceptionType" }}
                </span>
              </span>
            </td>
            <td>
              <span>
                <span class="vertical-align-middle">
                  {{ rowData.country | staticData: StaticDataType.Countries | async }}
                </span>
              </span>
            </td>
            <td>
              <div class="flex align-items-center justify-content-center gap-2">
                <button
                  *ngIf="!editing"
                  pButton
                  pRipple
                  type="button"
                  pInitEditableRow
                  icon="pi pi-pencil"
                  (click)="onRowEditInit(rowData)"
                  class="p-button-rounded p-button-text"
                ></button>
                <button
                  *ngIf="editing"
                  pButton
                  pRipple
                  type="button"
                  pSaveEditableRow
                  icon="pi pi-check"
                  (click)="onRowEditSave(rowData)"
                  class="p-button-rounded p-button-text p-button-success mr-2"
                ></button>
                <button
                  *ngIf="editing"
                  pButton
                  pRipple
                  type="button"
                  pCancelEditableRow
                  icon="pi pi-times"
                  (click)="onRowEditCancel(rowData, ri)"
                  class="p-button-rounded p-button-text p-button-danger"
                ></button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-card>

  <p-sidebar #sidebar [visible]="showDialog" styleClass="p-sidebar-lg" [fullScreen]="true" [closeOnEscape]="false">
    <app-collection-edit-assigned-candidates
      (closed)="closeSidebar($event)"
      [collection]="collection"
      [targetCandidateList]="assignmentExceptionsForEdit"
      [sourceCandidateList]="allCandidates"
      [sourceCandidateCount]="allCandidatesCount"
      [candidatesPerPage]="candidatesPerPage"
      (sourceUpdate)="updateSource($event)"
      [caption]="t('candidates.assignmentException.edit')"
    ></app-collection-edit-assigned-candidates>
  </p-sidebar>

  <ng-template #search>
    <div class="flex-1 p-input-icon-left mr-2 table-search" *ngIf="search">
      <i class="pi pi-search"></i>
      <input
        pInputText
        [(ngModel)]="searchInput"
        type="text"
        (input)="dt.filterGlobal($any($event.target).value, filterName)"
        [placeholder]="t('common.search')"
      />
    </div>
  </ng-template>
</ng-container>
