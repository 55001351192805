<ng-container *appTranslate="let t">
  <ng-container *ngIf="strings?.length">
    <div class="field">
      <ng-container [formGroup]="form">
        <ng-container
          *ngIf="
            showLanguageSelector === 'inline' || (showLanguageSelector === true && rows > 1);
            else inlineLanguageSelect
          "
        >
          <div class="field flex justify-content-between align-items-start">
            <label for="strings-{{ selectedLanguage }}" class="mr-3 mt-2 pt-1">
              {{ header }}
            </label>
            <app-language-selector
              class="mw-1"
              [languages]="translatedLanguages"
              [(selectedLanguage)]="selectedLanguage"
            ></app-language-selector>
          </div>
        </ng-container>

        <ng-template #inlineLanguageSelect>
          <label for="strings-{{ selectedLanguage }}">
            {{ header }}
          </label>
        </ng-template>

        <div class="p-inputgroup">
          <ng-container *ngFor="let language of translatedLanguages; index as i" formArrayName="strings">
            <ng-container [formGroupName]="i">
              <input
                *ngIf="language.value === selectedLanguage && (!rows || rows <= 1)"
                id="strings-{{ language.value }}"
                pInputText
                formControlName="value"
                [maxlength]="maxLength"
              />

              <textarea
                *ngIf="language.value === selectedLanguage && rows > 1"
                id="strings-{{ language.value }}"
                pInputText
                formControlName="value"
                [maxlength]="maxLength"
                [rows]="rows"
              ></textarea>
            </ng-container>
          </ng-container>

          <ng-container
            *ngIf="
              (showLanguageSelector === 'dropdown' || (showLanguageSelector === true && (!rows || rows <= 1))) &&
              languages?.length > 1
            "
            [ngTemplateOutlet]="languageSelect"
          ></ng-container>
        </div>
        <small *ngIf="missingLanguages?.length" [class.p-error]="requiredMissingLanguages.length">
          {{ t("localizedString.missingTranslations", { languages: missingLanguages.join(", ") }) }}
        </small>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="!strings?.length">
    <div class="field">
      <label>
        {{ header }}
      </label>
      <p-message
        [severity]="isRequired ? 'error' : 'warn'"
        [text]="t('localizedString.languages.required')"
      ></p-message>
    </div>
  </ng-container>
</ng-container>

<ng-template #languageSelect>
  <p-dropdown
    class="language-dropdown"
    [options]="translatedLanguages"
    [(ngModel)]="selectedLanguage"
    [ngModelOptions]="{ standalone: true }"
    [showClear]="false"
    [filter]="false"
  >
    <ng-template let-language pTemplate="selectedItem">
      <span
        class="flag flag-{{ flags[language.value] }}"
        [pTooltip]="language.label"
        tooltipPosition="left"
        style="margin: -5px 0"
      ></span>
    </ng-template>
    <ng-template pTemplate="dropdownicon"></ng-template>

    <ng-template let-language pTemplate="item">
      <span class="flag flag-{{ flags[language.value] }} mr-1"></span> {{ language.label }}
    </ng-template>
  </p-dropdown>
</ng-template>
