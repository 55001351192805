import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { RecognitionPathEntryDe, StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { BehaviorSubject } from "rxjs";
import { StaticDataContextEntry, StaticDataService } from "../../../shared/static-data/static-data.service";

const nameOf = nameofFactory<RecognitionPathEntryDe>();

@Component({
  selector: "app-candidate-recognition-type",
  templateUrl: "./candidate-recognition-type.component.html",
  standalone: false,
})
export class CandidateRecognitionTypeComponent implements OnInit, OnChanges, OnDestroy {
  private readonly language = this.transloco.getActiveLang();
  readonly isAccelerated = new BehaviorSubject<boolean>(false);

  @Input()
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  readonly: boolean;

  @Input()
  staticDataContext: StaticDataContextEntry;

  @Output()
  readonly valueChanged = new EventEmitter<string>();

  @Output()
  readonly userChangedValue = new EventEmitter<string>();

  get control(): FormControl<string> {
    return this.form.get(nameOf("recognitionType")) as FormControl<string>;
  }

  set control(value: FormControl<string>) {
    this.form.setControl(nameOf("recognitionType"), value);
  }

  recognitionTypes: StaticDataModel[];

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<string>(null);

    this.control.valueChanges.subscribe((value) => {
      this.isAccelerated.next(value === "ACCELERATED");
      this.valueChanged.emit(value);
    });
    this.updateRecognitionTypes(this.processLanguage);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.processLanguage) {
      this.updateRecognitionTypes(changes.processLanguage.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("recognitionType"), { emitEvent: false });
  }

  private updateRecognitionTypes(language: string): void {
    this.staticDataService
      .getStaticData(StaticDataType.RecognitionType, language ?? this.language, this.staticDataContext)
      .subscribe((x) => {
        this.recognitionTypes = x;
        this.changeDetector.markForCheck();
      });
  }
}
