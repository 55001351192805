import { Injectable } from "@angular/core";
import {
  CreateEducationModuleGQL,
  DeleteEducationModuleGQL,
  EditEducationModuleGQL,
  EducationModuleCreateInput,
  EducationModuleDeleteInput,
  EducationModuleForEditFragment,
  EducationModuleForListFragment,
  EducationModuleForListFragmentDoc,
  EducationModuleForSelectionFragment,
  EducationModuleUpdateInput,
  ListEducationModulesGQL,
  ListSelectableEducationModulesGQL,
  UpdateEducationModuleGQL,
} from "@ankaadia/graphql";
import { omit, pick } from "lodash";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class EducationModuleService {
  constructor(
    private readonly moduleList: ListEducationModulesGQL,
    private readonly moduleSelectableList: ListSelectableEducationModulesGQL,
    private readonly moduleEdit: EditEducationModuleGQL,
    private readonly moduleCreate: CreateEducationModuleGQL,
    private readonly moduleUpdate: UpdateEducationModuleGQL,
    private readonly moduleDelete: DeleteEducationModuleGQL
  ) {}

  list(organizationId: string): Observable<EducationModuleForListFragment[]> {
    return this.moduleList
      .watch({ input: { organizationId } })
      .valueChanges.pipe(map((x) => x.data.getEducationModules));
  }

  listSelectable(organizationId: string): Observable<EducationModuleForSelectionFragment[]> {
    return this.moduleSelectableList
      .fetch({ input: { organizationId } })
      .pipe(map((x) => x.data.getSelectableEducationModules));
  }

  edit(id: string, organizationId: string): Observable<EducationModuleForEditFragment> {
    return this.moduleEdit.fetch({ input: { id, organizationId } }).pipe(map((x) => x.data.getEducationModule));
  }

  create(module: EducationModuleCreateInput): Observable<EducationModuleForListFragment> {
    return this.moduleCreate
      .mutate(
        { input: omit(module, "id", "_etag", "changedAt", "changedAt") },
        {
          update: (cache, x) =>
            cache.modify({
              fields: {
                getEducationModules: (refs) => {
                  const ref = cache.writeFragment({
                    data: x.data.createEducationModule,
                    fragment: EducationModuleForListFragmentDoc,
                  });
                  return [...(refs ?? []), ref];
                },
              },
            }),
        }
      )
      .pipe(map((x) => x.data.createEducationModule));
  }

  update(module: EducationModuleUpdateInput): Observable<EducationModuleForListFragment> {
    return this.moduleUpdate
      .mutate({ input: omit(module, "changedAt", "changedAt") })
      .pipe(map((x) => x.data.updateEducationModule));
  }

  delete(module: EducationModuleDeleteInput): Observable<void> {
    return this.moduleDelete
      .mutate(
        { input: pick(module, "id", "_etag", "organizationId") },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getEducationModules: (refs, helper) => {
                  return refs.filter((ref) => helper.readField("id", ref) !== module.id);
                },
              },
            }),
        }
      )
      .pipe(map((x) => x.data.deleteEducationModule));
  }
}
