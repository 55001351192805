<ng-container *appTranslate="let t">
  <p-message
    *ngIf="isInactiveCandidate; else table"
    severity="info"
    [text]="t('candidateEvents.notAvailableForCandidate', { candidateStatus })"
  />
  <ng-template #table>
    <app-table
      [items]="rows$ | async"
      [columns]="columns"
      [tableOperations]="operations"
      [title]="showTitle ? t('candidates.events') : null"
      sortField="date"
      [showSidebar]="!!(document$ | async)"
      [styleClass]="styleClass"
    >
      <ng-template #caption *ngIf="options$ | async as options">
        <div class="flex flex-row">
          <p-dropdown
            [placeholder]="t('candidateEvent.category.title')"
            [options]="options.timeframes"
            [ngModel]="selectedEventTimeframe"
            (ngModelChange)="selectedEventTimeframe = $event; loadEvents()"
            [style]="{ maxWidth: '250px' }"
            class="mr-2"
          ></p-dropdown>
          <p-multiSelect
            *ngIf="options.categories.length > 1"
            [placeholder]="t('candidateEvent.category.title')"
            [showClear]="true"
            [options]="options.categories"
            [ngModel]="selectedEventCategories"
            (ngModelChange)="selectedEventCategories = $event ?? []; loadEvents()"
            [style]="{ maxWidth: '250px' }"
          ></p-multiSelect>
          <p-multiSelect
            *ngIf="canSelectDocumentTypes"
            [showClear]="true"
            [placeholder]="t('fileType.title')"
            [options]="options.documentTypes"
            [ngModel]="selectedDocumentTypes"
            (ngModelChange)="selectedDocumentTypes = $event; loadEvents()"
            [style]="{ maxWidth: '250px' }"
            class="ml-2"
          ></p-multiSelect>
        </div>
      </ng-template>
      <app-document-edit-dialog
        *ngIf="document$ | async as document"
        [document]="document"
        [readonly]="isReadonlyCollaboration"
        [mode]="DocumentMode.Candidate"
        [processLanguage]="language"
        (closed)="onClose()"
        (saved)="onSave()"
      ></app-document-edit-dialog>
    </app-table>
  </ng-template>
</ng-container>
