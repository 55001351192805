<ng-container *appTranslate="let t">
  <form [formGroup]="form" styleClass="relative">
    <div class="p-fluid formgrid grid">
      <p-fieldset [legend]="t('responsibilities.title')" class="col-12">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 lg:col-6">
            <app-candidate-responsible-role
              [form]="form"
              [responsibleRolePurpose]="ResponsibleRolePurposeType.RWRCard"
              [readonly]="readonly"
              [alwaysShownRoles]="responsibleRoles"
              class="field"
            ></app-candidate-responsible-role>
          </div>

          <div class="field col-0 lg:col-6"></div>
        </div>
      </p-fieldset>

      <p-fieldset [legend]="t('rwrCard.title')" class="col-12">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 lg:col-3">
            <div class="field-checkbox">
              <p-checkbox
                inputId="rwrRequested"
                [binary]="true"
                formControlName="visaEnquired"
                [readonly]="readonly"
              ></p-checkbox>
              <label for="rwrRequestedDate">{{ t("rwrRequested.title") }}</label>
            </div>

            <p-calendar id="rwrRequestedDate" formControlName="visaEnquiredDate" [readonly]="readonly"></p-calendar>
          </div>

          <div class="field col-12 lg:col-3">
            <div class="field-checkbox">
              <p-checkbox
                inputId="rwrCardReceived"
                [binary]="true"
                formControlName="visaReceived"
                [readonly]="readonly"
              ></p-checkbox>
              <label for="rwrCardReceivedDate">{{ t("rwrCardReceived.title") }}</label>
            </div>

            <p-calendar
              id="rwrCardReceivedDate"
              formControlName="visaReceiveDate"
              [maxDate]="today"
              [readonly]="readonly"
            ></p-calendar>
          </div>

          <div class="field col-12 lg:col-3">
            <div class="field-checkbox">
              <p-checkbox class="alignment-checkbox"></p-checkbox>
              <label for="visaValidFromDate">{{ t("rwrCardValidFromDate.title") }}</label>
            </div>

            <p-calendar id="visaValidFromDate" formControlName="visaValidFromDate" [readonly]="readonly"></p-calendar>
          </div>

          <div class="field col-12 lg:col-3">
            <div class="field-checkbox">
              <p-checkbox class="alignment-checkbox"></p-checkbox>
              <label for="visaValidUntilDate">{{ t("rwrCardValidUntilDate.title") }}</label>
            </div>

            <p-calendar id="visaValidUntilDate" formControlName="visaValidUntilDate" [readonly]="readonly"></p-calendar>
          </div>

          <div class="field col-12 lg:col-3">
            <app-candidate-rwr-file-number [form]="form" class="field"></app-candidate-rwr-file-number>
          </div>
        </div>
      </p-fieldset>
      <p-fieldset [legend]="t('visa.entry.title')" class="col-12 mb-2">
        <div class="p-fluid formgrid grid">
          <app-candidate-relocation-requirements
            class="field col-12 lg:col-3"
            [readonly]="readonly"
            [form]="form"
          ></app-candidate-relocation-requirements>

          <div class="field col-12 lg:col-3">
            <label for="responsibleForTransfer">{{ t("responsibleForTransfer.title") }}</label>
            <i class="pi pi-info-circle ml-2" [pTooltip]="t('responsibleForTransfer.info')"></i>
            <input
              id="responsibleForTransfer"
              pInputText
              formControlName="responsibleForTransfer"
              [readonly]="readonly"
            />
          </div>
          <div class="field col-12 lg:col-3">
            <label for="pickUpVehicle">{{ t("pickUpVehicle.title") }}</label>
            <input id="pickUpVehicle" pInputText formControlName="pickUpVehicle" [readonly]="readonly" />
          </div>
          <div class="field col-12 lg:col-3">
            <label for="contactAtArrivalLocation">{{ t("contactAtArrivalLocation.title") }}</label>
            <i class="pi pi-info-circle ml-2" [pTooltip]="t('contactAtArrivalLocation.info')"></i>
            <input
              id="contactAtArrivalLocation"
              pInputText
              formControlName="contactAtArrivalLocation"
              [readonly]="readonly"
            />
          </div>

          <div class="field col-12 lg:col-4">
            <app-candidate-planned-entry-date
              [form]="form"
              [showClear]="true"
              [readonly]="readonly"
              class="field"
            ></app-candidate-planned-entry-date>
          </div>

          <div class="field col-12 lg:col-4">
            <app-candidate-finger-print-date
              [form]="form"
              [showClear]="true"
              [readonly]="readonly"
              class="field"
            ></app-candidate-finger-print-date>
          </div>

          <div class="field col-12 lg:col-4">
            <app-candidate-pick-up-details
              [form]="form"
              [readonly]="readonly"
              class="field"
            ></app-candidate-pick-up-details>
          </div>

          <p-fieldset [legend]="t('visa.entry.plane')" class="col-12">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 lg:col-3">
                <app-candidate-flight-date
                  [form]="form"
                  class="field"
                  [readonly]="readonly"
                ></app-candidate-flight-date>
              </div>
              <div class="field col-12 lg:col-3">
                <label for="departureAirport">{{ t("departureAirport.title") }}</label>
                <input id="departureAirport" pInputText formControlName="departureAirport" [readonly]="readonly" />
              </div>
              <div class="field col-12 lg:col-3">
                <label for="flightArrivalDate">{{ t("flightArrivalDate.title") }}</label>
                <app-date-time
                  [form]="form.controls.flightArrivalDate"
                  inputId="flightArrivalDate"
                  [readonly]="readonly"
                ></app-date-time>
              </div>
              <div class="field col-12 lg:col-3">
                <label for="arrivalAirport">{{ t("arrivalAirport.title") }}</label>
                <input id="arrivalAirport" pInputText formControlName="arrivalAirport" [readonly]="readonly" />
              </div>
              <div class="field col-12 lg:col-3">
                <label for="flightNumber">{{ t("flightNumber.title") }}</label>
                <input id="flightNumber" pInputText formControlName="flightNumber" [readonly]="readonly" />
              </div>
            </div>
          </p-fieldset>

          <p-fieldset [legend]="t('visa.entry.train')" class="col-12">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 lg:col-3">
                <label for="trainDepartureDate">{{ t("trainDepartureDate.title") }}</label>
                <app-date-time
                  [form]="form.controls.trainDepartureDate"
                  inputId="trainDepartureDate"
                  [readonly]="readonly"
                ></app-date-time>
              </div>
              <div class="field col-12 lg:col-3">
                <label for="departureTrainStation">{{ t("departureTrainStation.title") }}</label>
                <input
                  id="departureTrainStation"
                  pInputText
                  formControlName="departureTrainStation"
                  [readonly]="readonly"
                />
              </div>
              <div class="field col-12 lg:col-3">
                <label for="trainArrivalDate">{{ t("trainArrivalDate.title") }}</label>
                <app-date-time
                  [form]="form.controls.trainArrivalDate"
                  inputId="trainArrivalDate"
                  [readonly]="readonly"
                ></app-date-time>
              </div>
              <div class="field col-12 lg:col-3">
                <label for="arrivalTrainStation">{{ t("arrivalTrainStation.title") }}</label>
                <input
                  id="arrivalTrainStation"
                  pInputText
                  formControlName="arrivalTrainStation"
                  [readonly]="readonly"
                />
              </div>
              <div class="field col-12 lg:col-3">
                <label for="trainNumber">{{ t("trainNumber.title") }}</label>
                <input id="trainNumber" pInputText formControlName="trainNumber" [readonly]="readonly" />
              </div>
            </div>
          </p-fieldset>
        </div>
      </p-fieldset>
    </div>
  </form>
</ng-container>
