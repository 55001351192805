<ng-container *appTranslate="let t">
  <div class="p-fluid" [formGroup]="form">
    <div class="field p-datatable">
      <table class="p-datatable-table filter-table" formArrayName="filters">
        <thead class="p-datatable-thead">
          <tr>
            <th *ngIf="filters.controls?.length > 1" [style.width.px]="1"></th>
            <th>{{ t("field.title") }}</th>
            <th>{{ t("condition.title") }}</th>
            <th>{{ t("value.title") }}</th>
            @if (anyFilterWithMultipleDataSourceOptionsExists) {
              <th>{{ t("dataSource.title") }}</th>
            }
            <th class="text-right white-space-nowrap">
              <button
                pButton
                type="button"
                [pTooltip]="t('common.add')"
                icon="pi pi-plus"
                class="p-button-rounded p-button-text"
                (click)="addFilter()"
              ></button>
            </th>
          </tr>
        </thead>

        <tbody class="p-datatable-tbody">
          <ng-container *ngFor="let filter of filters.controls; index as i">
            <tr [formGroupName]="i">
              <td
                *ngIf="i === 0 && filters.controls.length > 1"
                [attr.rowspan]="filters.controls.length"
                [style.width.px]="1"
                class="condition-connector"
              >
                <div class="condition-connector-text-wrapper">
                  <span class="condition-connector-text">{{ t("common.and") }}</span>
                </div>
              </td>
              <td>
                <p-treeSelect
                  #fieldSelector
                  filterMode="strict"
                  panelStyleClass="p-treeselect-leafs-only"
                  [filter]="true"
                  [required]="true"
                  [placeholder]="t('field.placeholder')"
                  [options]="fields"
                  formControlName="field"
                  (onNodeSelect)="onFieldChanged($event.node.data, i)"
                  (toggleClick)="onFieldToggled($event.node)"
                >
                  <ng-template pTemplate="value" let-item let-placeholder="placeholder">
                    <span *ngIf="meta[i]?.groupName as group" class="group-name p-text-secondary">{{ group }}</span>
                    <span class="field-name">{{ item?.label ?? placeholder }}</span>
                  </ng-template>
                </p-treeSelect>

                <small class="p-error" *ngIf="$any(filter).controls.field.errors?.required">
                  {{ t("field.required") }}
                </small>
              </td>

              <td>
                <p-dropdown
                  [options]="meta[i]?.conditions"
                  [required]="true"
                  [placeholder]="t('condition.placeholder')"
                  formControlName="condition"
                  (onChange)="onConditionChanged($event.value, i)"
                ></p-dropdown>
                <small class="p-error" *ngIf="$any(filter).controls.condition.errors?.required">
                  {{ t("condition.required") }}
                </small>
              </td>

              <td>
                <app-candidate-filter-value
                  [parameter]="meta[i]?.parameter"
                  [options]="meta[i]?.options"
                  [form]="$any(filter)"
                  [variables]="variables"
                ></app-candidate-filter-value>
              </td>

              @if (anyFilterWithMultipleDataSourceOptionsExists) {
                <td>
                  @if (hasMultipleDataSourceOptions(meta[i])) {
                    <app-candidate-filter-data-source
                      [form]="filter"
                      [dataSource]="meta[i]?.dataSource"
                    ></app-candidate-filter-data-source>
                  }
                </td>
              }

              <td class="text-right white-space-nowrap">
                <button
                  pButton
                  type="button"
                  [pTooltip]="t('common.delete')"
                  icon="pi pi-times"
                  class="p-button-rounded p-button-text mt-1"
                  (click)="removeFilter(i)"
                ></button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
