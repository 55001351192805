import { Component, Input, OnInit } from "@angular/core";
import { BillingSettingsFragment, ContractCreateInput, CustomerFragment, PlanListFragment } from "@ankaadia/graphql";
import { BillingSettingsService } from "./billing-settings.service";
import { translate } from "@jsverse/transloco";
import { TranslationKey } from "@ankaadia/ankaadia-shared";
import { MessageDialogService } from "../../../shared/message-dialog/message-dialog.service";
import { MessageService } from "../../message/message.service";
import { PlanService } from "../plan/plan.service";
import { Observable } from "rxjs";
import { SharedModule } from "../../../shared/shared.module";
import { PlanModule } from "../plan/plan.module";
import { PlanSummaryComponent } from "../plan/plan-summary/plan-summary.component";
import { CreateContractComponent } from "../contract/create-contract/create-contract.component";
import { ContractService } from "../contract/contract.service";

@Component({
  standalone: true,
  providers: [BillingSettingsService],
  selector: "app-billing-settings",
  templateUrl: "./billing-settings.component.html",
  imports: [SharedModule, PlanModule, PlanSummaryComponent, CreateContractComponent],
})
export class BillingSettingsComponent implements OnInit {
  protected settings: BillingSettingsFragment & {
    customer: CustomerFragment;
    isLicensed: boolean;
  };

  @Input({ required: true }) organizationId: string;

  protected plans: Observable<PlanListFragment[]>;

  constructor(
    private readonly billingSettingsService: BillingSettingsService,
    private readonly planService: PlanService,
    private readonly contractService: ContractService,
    private readonly messageDialogService: MessageDialogService,
    private readonly messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.billingSettingsService.getBillingSettings(this.organizationId).subscribe((settings) => {
      this.settings = settings;
    });

    this.plans = this.planService.getAllPlans();
  }

  protected openCustomerPortal(): void {
    this.billingSettingsService.getCustomerPortalUrl(this.organizationId).subscribe((url) => {
      window.open(url, "_blank");
    });
  }

  protected createStripeCustomer(_event: Event): void {
    if (!this.settings.isLicensed) {
      this.messageDialogService
        .showMessage(
          translate("error.title" as TranslationKey),
          translate("organization.notLicensed" as TranslationKey)
        )
        .subscribe();
    }

    this.billingSettingsService.createStripeCustomer(this.organizationId).subscribe(() => {
      this.messageService.add({
        severity: "success",
        summary: translate("billing.stripeCustomerCreated.title"),
        detail: translate("billing.stripeCustomerCreated.message"),
      });
    });
  }

  protected getFormattedBillingAddress(): string {
    if (!this.settings?.customer?.billingAddress) {
      return "";
    }

    const customer = this.settings.customer;

    const addressLines = [customer.billingAddress.line1, customer.billingAddress.line2];

    const postalCode = [customer.billingAddress.country, customer.billingAddress.postalCode].filter(Boolean).join("-");

    addressLines.push([postalCode, customer.billingAddress.city].filter((line) => line).join(" "));

    return addressLines.filter((line) => line).join("\n");
  }

  saveContract($event: ContractCreateInput): void {
    this.contractService.createContract($event, this.organizationId).subscribe(() => {
      this.messageService.add({
        severity: "success",
        summary: translate("billing.contractCreated.title" as TranslationKey),
        detail: translate("billing.contractCreated.message" as TranslationKey),
      });
    });
  }
}
