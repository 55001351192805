<ng-container *ngIf="translations$ | async as trans">
  <app-candidate-aligned-label
    [class]="useFieldLabel ? 'field' : ''"
    [labelFor]="inputId || controlName"
    [labelText]="trans.caption"
    [useAlignmentCheckBox]="renderLabelUsingAlignmentCheckBox"
  ></app-candidate-aligned-label>

  <p-dropdown
    [inputId]="inputId || controlName"
    [options]="responsibleRoles$ | async"
    [formControl]="control"
    [showClear]="true"
    [placeholder]="trans.placeholder"
    [readonly]="readonly"
    (onChange)="changedValue($event)"
  ></p-dropdown>

  <small class="p-error" *ngIf="control.errors?.required">
    {{ trans.required }}
  </small>
</ng-container>
