import { Injectable } from "@angular/core";
import {
  CandidateId,
  CandidateMultiEditPreviewFragment,
  ExecuteCandidateMultiInviteGQL,
  ExecuteCandidateMultiUninviteGQL,
  PreviewCandidateMultiInviteGQL,
  PreviewCandidateMultiUninviteGQL,
} from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class CandidateMultiInviteService {
  constructor(
    private readonly previewInviteGql: PreviewCandidateMultiInviteGQL,
    private readonly previewUninviteGql: PreviewCandidateMultiUninviteGQL,
    private readonly executeInviteGql: ExecuteCandidateMultiInviteGQL,
    private readonly executeUninviteGql: ExecuteCandidateMultiUninviteGQL
  ) {}

  previewInvite(candidateIds: CandidateId[]): Observable<CandidateMultiEditPreviewFragment[]> {
    return this.previewInviteGql
      .fetch({ input: { candidateIds } })
      .pipe(map((x) => x.data.previewCandidateMultiInvite));
  }

  previewUninvite(candidateIds: CandidateId[]): Observable<CandidateMultiEditPreviewFragment[]> {
    return this.previewUninviteGql
      .fetch({ input: { candidateIds } })
      .pipe(map((x) => x.data.previewCandidateMultiUninvite));
  }

  invite(candidateIds: CandidateId[]): Observable<void> {
    return this.executeInviteGql
      .mutate({ input: { candidateIds } })
      .pipe(map((x) => x.data.executeCandidateMultiInvite));
  }

  uninvite(candidateIds: CandidateId[]): Observable<void> {
    return this.executeUninviteGql
      .mutate({ input: { candidateIds } })
      .pipe(map((x) => x.data.executeCandidateMultiUninvite));
  }
}
