import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";

@Component({
  selector: "app-candidate-immigration-country",
  templateUrl: "./candidate-immigration-country.component.html",
  styleUrl: "./candidate-immigration-country.component.scss",
  standalone: false,
})
export class CandidateImmigrationCountryComponent implements OnInit, OnChanges {
  readonly language = this.transloco.getActiveLang();

  @Input()
  country: string;

  @Input()
  control: FormControl;

  @Input()
  candidate: { candidateId: string; organizationId: string };

  @Input()
  readonly = true;

  @Input()
  appearDisabled: boolean;

  protected options = [];
  private supportedOrgCountries = [];
  private allCountries = [];
  private organizationId: string;
  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly settings: SettingsService
  ) {}

  get immigrationCountry(): string {
    return this.country ?? this.settings.standardImmigrationCountry;
  }

  get isNewCandidate(): boolean {
    return (!this.candidate.candidateId && this.options?.length > 1) || !!this.candidate.candidateId;
  }

  ngOnInit(): void {
    this.supportedOrgCountries = this.settings.supportedImmigrationCountries;
    this.staticDataService
      .getStaticData(StaticDataType.SupportedImmigrationCountries, this.language)
      .subscribe((countries) => {
        this.allCountries = countries;
        this.updateOptions();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.candidate) {
      if (changes.candidate?.currentValue?.organizationId !== this.organizationId) {
        this.organizationId = changes.candidate?.currentValue?.organizationId ?? this.settings.organizationId;
      }
    }
    if ((changes.readonly || changes.country) && this.allCountries.length) {
      this.country = changes.country?.currentValue ?? this.country;
      this.updateOptions();
    }
  }

  updateOptions(): void {
    this.options = this.allCountries?.filter((c) => this.supportedOrgCountries.includes(c.value));
    if (this.country && !this.options.find((c) => c.value === this.country)) {
      this.options.push(this.allCountries.find((c) => c.value === this.country));
    }
  }
}
