import { Component, Input } from "@angular/core";
import { StaticDataType } from "@ankaadia/graphql";
import { StaticDataService } from "../../../../../shared/static-data/static-data.service";
import { HousingForm } from "../../../candidate-form.model";

@Component({
  selector: "app-candidate-housing-acquisition-entry",
  templateUrl: "./candidate-housing-acquisition-entry.component.html",
  standalone: false,
})
export class CandidateHousingAcquisitionEntryComponent {
  @Input({ required: true }) form: HousingForm;
  @Input({ required: true }) candidateId: string;
  @Input({ required: true }) candidateOrganizationId: string;
  @Input({ required: true }) readonly: boolean;
  @Input({ required: true }) index: number;

  countries$ = this.staticDataService.getStaticData(StaticDataType.Countries);

  constructor(private readonly staticDataService: StaticDataService) {}
}
