import { Injectable } from "@angular/core";
import {
  GetOrganizationCrmGQL,
  OrganizationCrmDataFragment,
  OrganizationCrmGetInput,
  OrganizationCrmSetInput,
  SetOrganizationCrmGQL,
} from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class OrganizationCrmService {
  constructor(
    private readonly getOrganizationCrmDataGQL: GetOrganizationCrmGQL,
    private readonly setOrganizationCrmDataGQL: SetOrganizationCrmGQL
  ) {}

  getOrganizationCrmData(
    myOrganizationId: string,
    targetOrganizationId: string
  ): Observable<OrganizationCrmDataFragment> {
    const input = new OrganizationCrmGetInput();
    input.organizationId = myOrganizationId;
    input.destinationOrganizationId = targetOrganizationId;
    return this.getOrganizationCrmDataGQL.fetch({ input: input }).pipe(map((x) => x.data.getOrganizationCrm));
  }

  setOrganizationCrmData(data: OrganizationCrmSetInput): Observable<OrganizationCrmDataFragment> {
    return this.setOrganizationCrmDataGQL.mutate({ input: data }).pipe(map((x) => x.data.setOrganizationCrm));
  }
}
