import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  Collection,
  EducationExamCandidateIdInput,
  EducationExamParticipantFragment,
  SharingTypeEnum,
} from "@ankaadia/graphql";
import { isEmpty } from "lodash";
import { BehaviorSubject, debounceTime, filter, map, Observable, switchMap } from "rxjs";
import { SettingsService } from "../../../../shared/services/settings.service";
import { CandidatePage, CandidatesService } from "../../../candidates/candidates.service";
import { CustomLazyLoadEvent } from "../../../collections/collection-edit-assigned-candidates/custom-filter.model";
import { CollectionRows, CollectionService } from "../../../collections/collection.service";

@Component({
  selector: "app-education-exam-participants-dialog",
  templateUrl: "./education-exam-participants-dialog.component.html",
  standalone: false,
})
export class EducationExamParticipantsDialogComponent implements OnInit, OnChanges {
  @Input({ required: true })
  exam: { examId: string; organizationId: string };

  @Input({ required: true })
  currentParticipants: EducationExamParticipantFragment[];

  @Output()
  readonly saved = new EventEmitter<EducationExamCandidateIdInput[]>();

  @Output()
  readonly closed = new EventEmitter<void>();

  private readonly searchString$ = new BehaviorSubject<string>(undefined);

  protected isOwner = false;

  protected collectionOptions: CollectionRows;
  protected candidateOptions: CandidateViewData[];

  protected selectedCandidates: CandidateViewData[];
  protected selectedCollection: Collection;

  protected readonly isEmpty = isEmpty;

  constructor(
    private readonly settingsService: SettingsService,
    private readonly candidateService: CandidatesService,
    private readonly collectionService: CollectionService,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.exam) this.setExamDependentData();
  }

  ngOnInit(): void {
    const searchResult$ = this.searchString$.pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(300),
      filter((search) => search != null),
      switchMap((search) => this.getSearchResult(search)),
      map((x) => this.getOptions(x))
    );

    searchResult$.subscribe((candidates) => {
      this.candidateOptions = candidates;
    });
  }

  save(): void {
    this.saved.emit(this.selectedCandidates.map((x) => this.toCreateInput(x)));
  }

  cancel(): void {
    this.closed.emit();
  }

  searchCandidates(search: string): void {
    this.searchString$.next(search ?? "");
  }

  private setExamDependentData(): void {
    this.isOwner = this.settingsService.organizationId === this.exam.organizationId;
    if (!this.isOwner) this.setParticipantCollections();
  }

  private setParticipantCollections(): void {
    this.collectionService
      .getCollectionsSharedToOrganization(this.exam.organizationId, this.settingsService.organizationId, [
        SharingTypeEnum.ReadOnlyCollaboration,
        SharingTypeEnum.Collaboration,
      ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((x) => (this.collectionOptions = x));
  }

  private getSearchResult(searchString: string): Observable<CandidatePage> {
    return this.candidateService.getFilteredCandidates(
      this.isOwner ? null : this.selectedCollection?.id,
      this.exam.organizationId,
      this.createFilterEvent(searchString)
    );
  }

  private createFilterEvent(searchString: string): CustomLazyLoadEvent {
    return {
      rows: 200,
      sortOrder: 1,
      first: 0,
      filters: {
        global: {
          matchMode: "contains",
          value: searchString,
        },
      },
      globalFilter: searchString,
    };
  }

  private getOptions(page: CandidatePage): CandidateViewData[] {
    if (!page) return [];
    return page.nodes
      .map((node) => {
        return {
          candidateId: node.id,
          displayName: node.displayName,
          displayId: node.displayId,
          organizationId: node.organizationId,
          country: node.country,
        };
      })
      .filter((option) => this.isNotParticipant(option));
  }

  private isNotParticipant(option: CandidateViewData): boolean {
    return !this.currentParticipants.some((participant) => participant.candidateId === option.candidateId);
  }

  private toCreateInput(x: CandidateViewData): EducationExamCandidateIdInput {
    return {
      id: x.candidateId,
      orgId: x.organizationId,
    };
  }
}

interface CandidateViewData {
  candidateId: string;
  displayName: string;
  displayId: string;
  organizationId: string;
  country: string;
}
