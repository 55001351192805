import { NgModule } from "@angular/core";
import { DashboardModule } from "../features/dashboard/dashboard.module";
import { MessageModule } from "../features/message/message.module";
import { NotificationsModule } from "../features/notifications/notifications.module";
import { OrganizationSwitchModule } from "../features/organization-switch/organization-switch.module";
import { TileMenuModule } from "../features/tile-menu/tile-menu.module";
import { TranslationProposalModule } from "../features/translationProposal/translationProposal.module";
import { UserFeedbackModule } from "../features/user-feedback/user-feedback.module";
import { UsersModule } from "../features/users/users.module";
import { SharedModule } from "../shared/shared.module";
import { Auth0CallbackComponent } from "./../structure/auth0Callback/auth0Callback.component";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./footer/footer.component";
import { LandingComponent } from "./landing/landing.component";
import { MainComponent } from "./main/main.component";
import { MenuItemComponent } from "./menu-item/menu-item.component";
import { MenuComponent } from "./menu/menu.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { TopbarComponent } from "./topbar/topbar.component";
import { CandidatesModule } from "../features/candidates/candidates.module";

@NgModule({
  imports: [
    SharedModule,
    UserFeedbackModule,
    TileMenuModule,
    DashboardModule,
    UsersModule,
    NotificationsModule,
    MessageModule,
    TranslationProposalModule,
    OrganizationSwitchModule,
    CandidatesModule,
  ],
  declarations: [
    Auth0CallbackComponent,
    AppComponent,
    MainComponent,
    LandingComponent,
    MenuComponent,
    MenuItemComponent,
    TopbarComponent,
    FooterComponent,
    SidebarComponent,
  ],
})
export class StructureModule {}
