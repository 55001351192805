import { Injectable } from "@angular/core";
import {
  CreateOrganizationContactGQL,
  DeleteOrganizationContactGQL,
  GetOrganizationContactGQL,
  GetOrganizationContactsForSelectionGQL,
  GetOrganizationContactsGQL,
  OrganizationContact,
  OrganizationContactCreateInput,
  OrganizationContactDeleteInput,
  OrganizationContactForListFragment,
  OrganizationContactForSelectionFragment,
  OrganizationContactFragmentDoc,
  OrganizationContactUpdateInput,
  UpdateOrganizationContactGQL,
} from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

type OrganizationContactForSelectionInclNameFragment = OrganizationContactForSelectionFragment & { name: string };

@Injectable({ providedIn: "root" })
export class OrganizationContactsService {
  constructor(
    private readonly contactsGet: GetOrganizationContactsGQL,
    private readonly contactsForSelection: GetOrganizationContactsForSelectionGQL,
    private readonly contactGet: GetOrganizationContactGQL,
    private readonly contactCreate: CreateOrganizationContactGQL,
    private readonly contactUpdate: UpdateOrganizationContactGQL,
    private readonly contactDelete: DeleteOrganizationContactGQL
  ) {}

  getAll(organizationId: string): Observable<OrganizationContactForListFragment[]> {
    return this.contactsGet
      .watch({ input: { organizationId } })
      .valueChanges.pipe(map((x) => x.data.getOrganizationContacts));
  }

  getForSelection(organizationId: string, useCache = false): Observable<OrganizationContactForSelectionFragment[]> {
    return this.contactsForSelection
      .fetch({ input: { organizationId } }, useCache ? { fetchPolicy: "cache-first" } : { fetchPolicy: "network-only" })
      .pipe(map((x) => x.data.getOrganizationContacts));
  }

  getForSelectionInclName(organizationId: string): Observable<OrganizationContactForSelectionInclNameFragment[]> {
    return this.getForSelection(organizationId).pipe(
      map((xs) => xs.map((x) => ({ ...x, name: `${x.lastName}, ${x.firstName}` })))
    );
  }

  get(id: string, organizationId: string): Observable<OrganizationContact> {
    return this.contactGet.fetch({ input: { id, organizationId } }).pipe(map((x) => x.data.getOrganizationContact));
  }

  create(contact: OrganizationContact): Observable<OrganizationContact> {
    const input = new OrganizationContactCreateInput();
    input.function = contact.function;
    input.title = contact.title;
    input.salutation = contact.salutation;
    input.firstName = contact.firstName;
    input.lastName = contact.lastName;
    input.email = contact.email;
    input.phone = contact.phone;
    input.mobilePhone = contact.mobilePhone;
    input.position = contact.position;
    input.address = contact.address;
    input.zipCode = contact.zipCode;
    input.city = contact.city;
    input.region = contact.region;
    input.country = contact.country;
    input.organizationId = contact.organizationId;
    input.signatureAddition = contact.signatureAddition;
    return this.contactCreate
      .mutate(
        { input: input },
        {
          update: (cache, result) =>
            cache.modify({
              fields: {
                getOrganizationContacts: (refs, helper) =>
                  updateApolloCache(input, refs, helper, cache, result.data.createOrganizationContact),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.createOrganizationContact));
  }

  update(contact: OrganizationContact): Observable<OrganizationContact> {
    const input = new OrganizationContactUpdateInput();
    input.id = contact.id;
    input._etag = contact._etag;
    input.function = contact.function;
    input.title = contact.title;
    input.salutation = contact.salutation;
    input.firstName = contact.firstName;
    input.lastName = contact.lastName;
    input.email = contact.email;
    input.phone = contact.phone;
    input.mobilePhone = contact.mobilePhone;
    input.position = contact.position;
    input.address = contact.address;
    input.zipCode = contact.zipCode;
    input.city = contact.city;
    input.region = contact.region;
    input.country = contact.country;
    input.organizationId = contact.organizationId;
    input.signatureAddition = contact.signatureAddition;
    return this.contactUpdate.mutate({ input: input }).pipe(map((x) => x.data.updateOrganizationContact));
  }

  delete(contact: OrganizationContactDeleteInput): Observable<boolean> {
    const input = new OrganizationContactDeleteInput();
    input.id = contact.id;
    input._etag = contact._etag;
    input.organizationId = contact.organizationId;
    return this.contactDelete
      .mutate(
        { input: input },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getOrganizationContacts: (refs, { readField }) =>
                  refs.filter((ref) => readField("id", ref) !== input.id),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.deleteOrganizationContact.status));
  }
}

function updateApolloCache(input, refs, { storeFieldName }, cache, data): any {
  if (!storeFieldName.includes(input.organizationId)) return refs;
  const ref = cache.writeFragment({
    data: data,
    fragment: OrganizationContactFragmentDoc,
    fragmentName: "OrganizationContact",
  });
  if (refs != null && refs.length > 0) {
    return [...refs, ref];
  } else {
    return [ref];
  }
}
