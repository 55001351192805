import { Component, Input, OnInit } from "@angular/core";
import { getAllRwrRoles } from "@ankaadia/ankaadia-shared";
import { startWith } from "rxjs";
import { ResponsibleRolePurpose } from "../../../../candidate-reusables/candidate-responsible-representative-group/candidate-responsible-representative-group.service";
import { VisaFormAt } from "../../../candidate-form.model";

@Component({
  selector: "app-candidate-visa-at",
  templateUrl: "./candidate-visa-at.component.html",
  standalone: false,
})
export class CandidateVisaATComponent implements OnInit {
  readonly ResponsibleRolePurposeType = ResponsibleRolePurpose;
  protected readonly responsibleRoles = getAllRwrRoles();

  readonly today = new Date();

  @Input()
  form: VisaFormAt;

  @Input()
  candidateId: string;

  @Input()
  candidateOrganizationId: string;

  @Input()
  readonly: boolean;

  ngOnInit(): void {
    this.form.controls.visaReceived.valueChanges
      .pipe(startWith(this.form.controls.visaReceived.value))
      .subscribe((x) => {
        if (x) {
          this.form.controls.visaReceiveDate.enable();
          this.form.controls.visaValidFromDate.enable();
          this.form.controls.visaValidUntilDate.enable();
        } else {
          this.form.controls.visaReceiveDate.setValue(null);
          this.form.controls.visaReceiveDate.disable();

          this.form.controls.visaValidFromDate.setValue(null);
          this.form.controls.visaValidFromDate.disable();

          this.form.controls.visaValidUntilDate.setValue(null);
          this.form.controls.visaValidUntilDate.disable();
        }
      });

    this.form.controls.visaEnquired.valueChanges
      .pipe(startWith(this.form.controls.visaEnquired.value))
      .subscribe((x) => {
        if (x) {
          this.form.controls.visaEnquiredDate.enable();
        } else {
          this.form.controls.visaEnquiredDate.setValue(null);
          this.form.controls.visaEnquiredDate.disable();
        }
      });

    //VisaRequestDate must be always enabled. https://dev.azure.com/kleinod21/Ankaadia/_workitems/edit/3413
  }
}
