<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <label for="healthProfessionsRegisterAppointmentDate">{{
    t("healthProfessionsRegisterAppointmentDate.title")
  }}</label>
  <p-calendar
    inputId="healthProfessionsRegisterAppointmentDate"
    [formControl]="control"
    [readonly]="readonly"
  ></p-calendar>

  <small class="p-error" *ngIf="control.errors?.required">
    {{ t("healthProfessionsRegisterAppointmentDate.required") }}
  </small>
</ng-container>
