import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { CandidateStatus, StaticDataModel } from "@ankaadia/graphql";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { getSortedCandidateStatusList } from "./candidate-status-enum-helper";

@Component({
  selector: "app-candidate-status",
  templateUrl: "./candidate-status.component.html",
  standalone: false,
})
export class CandidateStatusComponent implements OnChanges, OnInit {
  constructor(private readonly staticDataService: StaticDataService) {}

  @Input()
  control: FormControl;

  @Input()
  readonly: boolean;

  @Input()
  canDropOut: boolean;

  options: StaticDataModel[];
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.canDropOut) {
      this.updateOptions(changes.canDropOut.currentValue);
    }
  }

  ngOnInit(): void {
    this.updateOptions(this.canDropOut);
  }

  private updateOptions(canDropOut: boolean): void {
    this.options = getSortedCandidateStatusList(this.staticDataService).filter(
      (x) => canDropOut || x.value !== CandidateStatus.DroppedOut
    );
  }
}
