import { AfterViewInit, Component, Input, OnChanges, QueryList, SimpleChanges, ViewChildren } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { StaticDataModel } from "@ankaadia/graphql";
import { isEmpty, uniqBy } from "lodash";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { MultiSelect, MultiSelectFilterEvent } from "primeng/multiselect";
import { Observable, of, take } from "rxjs";
import { Dictionary } from "ts-essentials";
import {
  AutocompleteOptions,
  CandidateFilter,
  CandidateFilterConditionOptions,
  CandidateFilterOptions,
  LazyChoiceOptions,
  StaticDataCountryGroup,
  StaticDataModelGroup,
} from "../candidate-filter.model";

@Component({
  selector: "app-candidate-filter-value",
  templateUrl: "./candidate-filter-value.component.html",
  standalone: false,
})
export class CandidateFilterValueComponent implements AfterViewInit, OnChanges {
  private multiSelects = new QueryList<MultiSelect>();

  @Input()
  parameter: CandidateFilter["parameter"];

  @Input()
  options: CandidateFilterOptions;

  @Input()
  variables: Dictionary<StaticDataModelGroup>;

  multiSelectOptions: CandidateFilterConditionOptions;

  autocompleteOptions: AutocompleteOptions;
  suggestions: StaticDataModel[];

  lazyMultiSelectFilterOptions: StaticDataModel[];
  lazyChoiceOptions: LazyChoiceOptions;

  @Input()
  form: FormGroup;

  @ViewChildren("multiSelect")
  multiSelectComponents: QueryList<MultiSelect>;

  get useGroups(): boolean {
    if (this.parameter === "options") {
      const nameOf = nameofFactory<StaticDataCountryGroup>();
      const options = this.multiSelectOptions as StaticDataCountryGroup[];

      return options?.every((element) => nameOf("items") in element) ?? false;
    }
    return false;
  }

  ngAfterViewInit(): void {
    this.multiSelectComponents.changes.subscribe((components: QueryList<MultiSelect>) => {
      this.multiSelects = components;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.multiSelects.forEach((multiSelect) => {
        multiSelect.filterValue = null;
      });
      if (this.parameter === "options") {
        (this.options as Observable<CandidateFilterConditionOptions>).subscribe((options) => {
          this.multiSelectOptions = options;
        });
      }
      if (this.parameter === "autocomplete") {
        this.autocompleteOptions = this.options as AutocompleteOptions;
        if (this.form.get("value").value && !isNaN(this.form.get("value").value)) {
          this.autocompleteOptions
            .getFirstSuggestion(this.form.get("value").value)
            .pipe(take(1))
            .subscribe((suggestions) => {
              this.suggestions = suggestions;
            });
        }
      }
      if (this.parameter === "lazyChoice") {
        this.lazyChoiceOptions = this.options as LazyChoiceOptions;
        const values = this.form.get("value").value;
        if (values && !isEmpty(values)) {
          this.lazyChoiceOptions
            .getOptionsByValue(values)
            .pipe(take(1))
            .subscribe((options) => (this.lazyMultiSelectFilterOptions = options));
        }
      }
    }
  }

  complete($event: AutoCompleteCompleteEvent): void {
    this.autocompleteOptions.getSuggestions($event.query).subscribe((options) => {
      this.suggestions = options;
    });
  }

  loadOptions($event: MultiSelectFilterEvent): void {
    const searched = isEmpty($event.filter) ? of([]) : this.lazyChoiceOptions.getFilteredOptions($event.filter);

    searched.subscribe((searchedOptions) => {
      const selectedValues = this.form.get("value").value ?? [];
      const selectedOptions = (this.lazyMultiSelectFilterOptions ?? []).filter((x) => selectedValues.includes(x.value)); // keep selected values in the options!
      this.lazyMultiSelectFilterOptions = uniqBy([...selectedOptions, ...searchedOptions], (x) => x.value);
    });
  }
}
