import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Feedback, FeedbackStatusPhaseOne, FeedbackStatusPhaseTwo } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { clone } from "lodash";

@Component({
  selector: "app-candidate-feedback",
  templateUrl: "./candidate-feedback.component.html",
  styleUrl: "./candidate-feedback.component.scss",
  standalone: false,
})
export class CandidateFeedbackComponent {
  private model: Feedback;

  readonly statusPhaseOneOptions = [
    { label: translate("enum.FeedbackStatusPhaseOne.Rejected"), value: FeedbackStatusPhaseOne.Rejected },
    { label: translate("enum.FeedbackStatusPhaseOne.Backlogged"), value: FeedbackStatusPhaseOne.Backlogged },
    {
      label: translate("enum.FeedbackStatusPhaseOne.InterviewRequested"),
      value: FeedbackStatusPhaseOne.InterviewRequested,
    },
  ];

  readonly statusPhaseTwoOptions = [
    { label: translate("enum.FeedbackStatusPhaseTwo.Selected"), value: FeedbackStatusPhaseTwo.Selected },
    { label: translate("enum.FeedbackStatusPhaseTwo.Rejected"), value: FeedbackStatusPhaseTwo.Rejected },
  ];

  get statusPhaseOne(): FeedbackStatusPhaseOne | null {
    return this.model.statusPhaseOne;
  }

  set statusPhaseOne(statusPhaseOne: FeedbackStatusPhaseOne | null) {
    this.model.statusPhaseOne = this.model.statusPhaseOne !== statusPhaseOne ? statusPhaseOne : null;
    if (this.model.statusPhaseOne !== FeedbackStatusPhaseOne.InterviewRequested) {
      this.model.interviewConducted = null;
      this.model.statusPhaseTwo = null;
    }
    this.update(this.model, "statusPhaseOne");
  }

  get statusPhaseTwo(): FeedbackStatusPhaseTwo | null {
    return this.model.statusPhaseTwo;
  }

  set statusPhaseTwo(statusPhaseTwo: FeedbackStatusPhaseTwo | null) {
    this.model.statusPhaseTwo = this.model.statusPhaseTwo !== statusPhaseTwo ? statusPhaseTwo : null;
    this.update(this.model, "statusPhaseTwo");
  }

  get interviewConducted(): boolean | null {
    return this.model.interviewConducted;
  }

  set interviewConducted(interviewConducted: boolean | null) {
    this.model.interviewConducted = interviewConducted;
    if (!this.model.interviewConducted) {
      this.model.statusPhaseTwo = null;
    }
    this.update(this.model, "interviewConducted");
  }

  get interviewRequested(): boolean {
    return this.statusPhaseOne === FeedbackStatusPhaseOne.InterviewRequested;
  }

  @Input()
  set feedbackData(feedback: Feedback) {
    this.model = clone(feedback);
  }

  @Input()
  disabled: boolean;

  @Input()
  readonly: boolean;

  @Output()
  readonly feedbackChange = new EventEmitter<{ feedback: Feedback; field: string }>();

  @Input()
  secondPhaseEnabled: boolean;

  update(feedback: Feedback, field: string): void {
    if (!this.disabled && !this.readonly) {
      this.feedbackChange.emit({ feedback, field });
    }
  }
}
