import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import {
  DocumentTemplateStatus,
  GenerateReportTemplateMutation,
  GenerateTemplateReportInput,
  GetAvailableReportTemplatesQuery,
} from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { BlockableUI, PrimeIcons } from "primeng/api";
import { DropdownChangeEvent } from "primeng/dropdown";
import { DialogService } from "primeng/dynamicdialog";
import { Observable, finalize, tap } from "rxjs";
import { DownloadService } from "../../../shared/services/download.service";
import { FileUploadService } from "../../../shared/services/file-upload.service";
import { SettingsService } from "../../../shared/services/settings.service";
import { TableColumn, TableOperation } from "../../../shared/table/table.model";
import { CustomLazyLoadEvent } from "../../collections/collection-edit-assigned-candidates/custom-filter.model";
import { MessageService } from "../../message/message.service";
import { DocumentTemplateWarningsDialogComponent } from "../document-template-warnings-dialog/document-template-warnings-dialog.component";
import { DocumentTemplatesService } from "../document-templates.service";

const nameOf = nameofFactory<GetAvailableReportTemplatesQuery["getAvailableReportTemplates"][0]>();

@Component({
  selector: "app-report-templates",
  templateUrl: "./document-template-reports.component.html",
  styleUrl: "./document-template-reports.component.scss",
  providers: [FileUploadService],
  standalone: false,
})
export class DocumentReportTemplatesComponent implements OnInit, BlockableUI, OnChanges {
  readonly tableOperations: TableOperation[] = [
    {
      label: translate("template.generate"),
      icon: PrimeIcons.FILE,
      operation: (x: GetAvailableReportTemplatesQuery["getAvailableReportTemplates"][0]): void =>
        this.generationRequest(x),
      disabled: (): boolean => this.isBusy,
    },
    {
      label: translate("common.preview"),
      icon: PrimeIcons.SEARCH,
      operation: (x: GetAvailableReportTemplatesQuery["getAvailableReportTemplates"][0]): void => this.preview(x),
      disabled: (x: GetAvailableReportTemplatesQuery["getAvailableReportTemplates"][0]): boolean =>
        !this.isGenerated(x) || this.isBusy,
    },
    {
      label: translate("template.download"),
      icon: PrimeIcons.DOWNLOAD,
      operation: (x: GetAvailableReportTemplatesQuery["getAvailableReportTemplates"][0]): void =>
        this.downloadReport(x),
      disabled: (x: GetAvailableReportTemplatesQuery["getAvailableReportTemplates"][0]): boolean =>
        !this.isGenerated(x) || this.isBusy,
    },
  ];

  readonly reportColumns: TableColumn[] = [
    {
      header: translate("template.title"),
      fieldname: nameOf("displayName"),
      sortable: true,
      includeInGlobalFilter: true,
    },
  ];

  @Input()
  collectionId: string;

  @Input()
  collectionOrganizationId: string;

  @Input()
  selectedOrganizationId: string;

  @Input()
  sharingId: string;

  @Input()
  sharingOrganizationId: string;

  @Input()
  showSelectionSidebar = false;

  @Input()
  customLazyLoadEvent: CustomLazyLoadEvent;

  @Output()
  readonly closed = new EventEmitter<void>();

  protected isBusy = false;
  protected availableReportTemplates: GetAvailableReportTemplatesQuery["getAvailableReportTemplates"];
  protected reportCache: Record<string, GenerateReportTemplateMutation["generateReportTemplate"]> = {};
  protected isFullScreen: boolean;
  protected selectedTemplate: GenerateReportTemplateMutation["generateReportTemplate"];
  protected showSidebar: boolean;
  protected reportConfigurationForm = new FormGroup({});
  protected reportStrategyId: string;
  protected reportStrategyOrganizationId: string;
  private reportConfigurationModel: any = {};
  protected selectedReportTemplate: string;
  protected showReportSelectionDropdown = false;
  reportTemplate: GetAvailableReportTemplatesQuery["getAvailableReportTemplates"][0];

  constructor(
    private readonly templateService: DocumentTemplatesService,
    private readonly messageService: MessageService,
    private readonly downloadService: DownloadService,
    private readonly dialogService: DialogService,
    private readonly elementRef: ElementRef,
    private readonly transloco: TranslocoService,
    private readonly settings: SettingsService
  ) {}

  onReportConfigurationModelChanged(reportConfiguration: any): void {
    this.reportConfigurationModel = reportConfiguration;
  }

  onSelectedReportTemplateChanged($event: DropdownChangeEvent): void {
    this.reportConfigurationForm = new FormGroup({});
    this.generationRequest(
      this.availableReportTemplates.find((x) => x.templateId === $event.value),
      false
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.collectionId) {
      this.reportCache = {};
    }
    if (changes.showSelectionSidebar) {
      this.showSidebar = changes.showSelectionSidebar.currentValue;
      this.showReportSelectionDropdown = changes.showSelectionSidebar.currentValue;
    }
  }

  ngOnInit(): void {
    this.templateService.getAvailableReportTemplates().subscribe((xs) => {
      this.availableReportTemplates = xs?.slice();
    });
  }

  getBlockableElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  protected close(): void {
    this.selectedTemplate = null;
    this.reportTemplate = null;
    this.showSidebar = false;
    this.closed.emit();
  }

  protected onGenerateFromGenerationConfig(): void {
    const reportTemplate =
      this.reportTemplate ?? this.availableReportTemplates.find((x) => x.templateId === this.selectedReportTemplate);
    const generateParams: GenerateTemplateReportInput = {
      templateOrganizationId: reportTemplate.templateOrganizationId,
      templateId: reportTemplate.templateId,
      collectionId: this.collectionId,
      collectionOrganizationId: this.collectionOrganizationId,
      sharingId: this.sharingId,
      sharingOrganizationId: this.sharingOrganizationId,
      generationStrategy: {
        strategyId: this.reportStrategyId,
        configuration: this.reportConfigurationModel,
      },
      CandidatePageInput: {
        language: "de-DE",
        organizationId: this.collectionOrganizationId ?? this.selectedOrganizationId,
        first: 0,
        rows: 1000000,
        filters: this.customLazyLoadEvent?.filters,
        sortField: Array.isArray(this.customLazyLoadEvent?.sortField)
          ? this.customLazyLoadEvent?.sortField.join("_")
          : this.customLazyLoadEvent?.sortField,
        sortOrder: this.customLazyLoadEvent?.sortOrder,
      },
    };
    this.generateReport(generateParams, reportTemplate).subscribe(() => {
      this.reportTemplate = null;
    });
  }

  private isGenerated(template: GetAvailableReportTemplatesQuery["getAvailableReportTemplates"][0]): boolean {
    return !!this.reportCache[template.templateId];
  }

  private preview(template: GetAvailableReportTemplatesQuery["getAvailableReportTemplates"][0]): void {
    this.showReportSelectionDropdown = false;
    this.selectedTemplate = this.reportCache[template.templateId];
    this.showSidebar = true;
  }

  private downloadReport(template: GetAvailableReportTemplatesQuery["getAvailableReportTemplates"][0]): void {
    const x = this.reportCache[template.templateId];
    this.downloadService.downloadFile(x.filename, x.downloadUrl);
  }

  private generationRequest(
    template: GetAvailableReportTemplatesQuery["getAvailableReportTemplates"][0],
    generateReportWithoutParameter = true
  ): void {
    this.templateService
      .getDataStrategyGenerationFormly(
        this.settings.organizationId,
        template.templateId,
        template.generationDataStrategyId,
        this.transloco.getActiveLang()
      )
      .subscribe((config) => {
        if (config.length > 0 || !generateReportWithoutParameter) {
          this.showSidebar = true;
          this.reportStrategyId = template.generationDataStrategyId;
          this.reportStrategyOrganizationId = template.templateOrganizationId;
          this.reportTemplate = template;
          this.selectedReportTemplate = template.templateId;
        } else {
          const generateParams: GenerateTemplateReportInput = {
            templateOrganizationId: template.templateOrganizationId,
            templateId: template.templateId,
            collectionId: this.collectionId,
            sharingId: this.sharingId,
            sharingOrganizationId: this.sharingOrganizationId,
            collectionOrganizationId: this.collectionOrganizationId,
          };
          this.generateReport(generateParams, template).subscribe();
        }
      });
  }

  private generateReport(
    generateParams: GenerateTemplateReportInput,
    template: GetAvailableReportTemplatesQuery["getAvailableReportTemplates"][0]
  ): Observable<any> {
    this.isBusy = true;
    return this.templateService
      .generateReport(generateParams)
      .pipe(finalize(() => (this.isBusy = false)))
      .pipe(
        tap((x) => {
          if (x.status !== DocumentTemplateStatus.Warning && x.status !== DocumentTemplateStatus.Success) {
            this.dialogService.open(DocumentTemplateWarningsDialogComponent, {
              header: translate(`error.title`),
              data: { errors: x.warnings },
            });
          } else {
            this.messageService.add({
              severity: "success",
              summary: translate("template.generated.title"),
              detail: translate("template.generated.message", { name: template.displayName }),
            });
            this.storeDownloadInfo(template, x);
          }
        })
      );
  }

  private storeDownloadInfo(
    template: GetAvailableReportTemplatesQuery["getAvailableReportTemplates"][0],
    generatedReport: GenerateReportTemplateMutation["generateReportTemplate"]
  ): void {
    this.reportCache[template.templateId] = generatedReport;
    this.preview(template);
  }
}
