import { Component } from "@angular/core";
import { FormBuilder, ValidationErrors, Validators } from "@angular/forms";
import {
  GeneralDocumentRequirementFragment,
  GeneralDocumentRequirementInput,
  StaticDataModel,
  StaticDataType,
} from "@ankaadia/graphql";
import { isEmpty, uniqBy } from "lodash";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import {
  DocumentFormatEqualityMappingForm,
  DocumentGeneralRequirementsForm,
} from "./document-general-requirement-form.model";
import { DocumentGeneralRequirementService } from "./document-general-requirement.service";

@Component({
  selector: "app-document-general-requirement",
  templateUrl: "./document-general-requirement.component.html",
  styleUrl: "./document-general-requirement.component.scss",
  standalone: false,
})
export class DocumentGeneralRequirementComponent {
  private data: GeneralDocumentRequirementFragment;
  protected showSidebar = false;
  protected documentFormats: StaticDataModel[] = null;

  protected form: DocumentGeneralRequirementsForm = null;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly settingsService: SettingsService,
    private readonly documentGeneralRequirementService: DocumentGeneralRequirementService,
    private readonly staticDataService: StaticDataService
  ) {}

  openSidebar(): void {
    this.createForm();
    this.staticDataService.getStaticData(StaticDataType.DocumentFormats).subscribe((data) => {
      this.documentFormats = data;
    });
    this.documentGeneralRequirementService.get(this.settingsService.organizationId).subscribe((data) => {
      this.data = data ?? { _etag: null, organizationId: this.settingsService.organizationId };
      if (data) {
        (data.documentFormatEqualityMapping ?? []).forEach((mapping) => {
          this.addMapping(mapping);
        });
      }
      if (this.form.controls.documentFormatEqualityMapping.length === 0) {
        this.addMapping(null);
      }
      this.showSidebar = true;
    });
  }

  validateForm(form: DocumentGeneralRequirementsForm): ValidationErrors | null {
    // make it possible to delete the last entry, if there was some data before
    if (
      !isEmpty(this.data?.documentFormatEqualityMapping) &&
      form?.value?.documentFormatEqualityMapping?.length === 0
    ) {
      return null;
    }

    if ((form.value.documentFormatEqualityMapping ?? []).length === 0) {
      return { documentFormatEqualityMapping: "At least one mapping is required" };
    }
    if (
      uniqBy(form.value.documentFormatEqualityMapping ?? [], (d) => d.sourceDocumentFormat).length <
      (form.value.documentFormatEqualityMapping ?? []).length
    ) {
      return { documentFormatEqualityMappingEqual: "At least one mapping is required" };
    }
    return null;
  }

  addMapping(row?: GeneralDocumentRequirementFragment["documentFormatEqualityMapping"][number]): void {
    this.form.controls.documentFormatEqualityMapping.push(
      this.formBuilder.group<DocumentFormatEqualityMappingForm["controls"]>({
        sourceDocumentFormat: this.formBuilder.control<string>(row?.sourceDocumentFormat ?? null, {
          validators: [Validators.required],
        }),
        equalDocumentFormats: this.formBuilder.control<string[]>(row?.equalDocumentFormats ?? null, {
          validators: [Validators.required],
        }),
      })
    );
  }

  submit(): void {
    if (this.form.invalid && this.form.value.documentFormatEqualityMapping.length === 0) {
      return;
    }

    this.documentGeneralRequirementService
      .upsert({
        ...(this.form.value as GeneralDocumentRequirementInput),
        organizationId: this.data.organizationId,
        _etag: this.data._etag,
        id: this.data.id,
      })
      .subscribe(() => (this.showSidebar = false));
  }

  cancel(): void {
    this.showSidebar = false;
    this.form = null;
  }

  deleteMapping(mapping: DocumentFormatEqualityMappingForm): void {
    if (
      this.form.controls.documentFormatEqualityMapping.length > 1 ||
      !isEmpty(this.data?.documentFormatEqualityMapping)
    ) {
      const index = (this.form.controls.documentFormatEqualityMapping.controls ?? []).findIndex((m) => m == mapping);
      if (index >= 0) {
        this.form.controls.documentFormatEqualityMapping.removeAt(index);
      }
    }
  }

  private createForm(): void {
    this.form = this.formBuilder.group<DocumentGeneralRequirementsForm["controls"]>(
      {
        documentFormatEqualityMapping: this.formBuilder.array<DocumentFormatEqualityMappingForm>([]),
      },
      { validators: this.validateForm.bind(this) }
    );
  }
}
