<ng-container *appTranslate="let t">
  <p-card styleClass="vz-card" [header]="t('education.title')">
    <p-tabView styleClass="vz-tabview">
      <p-tabPanel [header]="t('educationCourses.title')" leftIcon="pi pi-ticket">
        <app-education-course-table [courses]="courses$ | async"></app-education-course-table>
      </p-tabPanel>

      <p-tabPanel [header]="t('educationExams.title')" leftIcon="pi pi-calendar">
        <app-education-exam-table
          [exams]="exams$ | async"
          [isReadOnly]="true"
          [shownColumns]="examColumns"
        ></app-education-exam-table>
      </p-tabPanel>

      <p-tabPanel [header]="t('educationExamResults.title')" leftIcon="pi pi-calculator">
        <app-education-exam-results-table
          [examResults]="examResults$ | async"
          [exams]="exams$ | async"
          [shownColumns]="examResultColumns"
        ></app-education-exam-results-table>
      </p-tabPanel>
    </p-tabView>
  </p-card>
</ng-container>
