<ng-container *appTranslate="let t">
  <p-card>
    <p-table
      #table
      [value]="performance.candidates | tableSortable"
      [globalFilterFields]="['candidateDisplayId', 'candidateDisplayName']"
      dataKey="candidateId"
      sortField="candidateDisplayId"
      styleClass="p-datatable-sm p-datatable-striped"
      (onEditInit)="initialized($event)"
      (onEditCancel)="canceled($event)"
      (onEditComplete)="edited($event)"
    >
      <ng-template pTemplate="caption">
        <div class="flex">
          <span class="flex-1 p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="table.filterGlobal($any($event.target).value, 'contains')"
              [placeholder]="t('common.search')"
            />
          </span>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th pFrozenColumn pSortableColumn="candidateDisplayId" class="white-space-nowrap" rowspan="2">
            {{ t("number.title") }}

            <p-sortIcon field="candidateDisplayId"></p-sortIcon>
          </th>

          <th pFrozenColumn pSortableColumn="candidateDisplayName" class="white-space-nowrap" rowspan="2">
            {{ t("name.title") }}

            <p-sortIcon field="candidateDisplayName"></p-sortIcon>
          </th>

          <th pFrozenColumn pSortableColumn="candidateState" class="white-space-nowrap" rowspan="2">
            {{ t("courseStatus.title") }}

            <p-columnFilter field="candidateState" matchMode="equals" display="menu" [showMatchModes]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown [ngModel]="value" [options]="states" (onChange)="filter($event.value)" [showClear]="true">
                  <ng-template *ngFor="let template of ['item', 'selectedItem']" [pTemplate]="template" let-state>
                    <i
                      [ngClass]="EducationCourseCandidateStatusIcons[state.value]"
                      class="vertical-align-middle mr-2"
                    ></i>
                    <span class="vertical-align-middle">{{ state.label }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>

            <p-sortIcon field="candidateState"></p-sortIcon>
          </th>

          <ng-container *ngFor="let week of performance.weeks; index as i">
            <th
              class="white-space-nowrap"
              [class.current-week]="isCurrentWeek(week)"
              [appScrollIntoView]="isCurrentWeek(week)"
              [appScrollIntoViewOptions]="{ behavior: 'smooth' }"
              colspan="3"
            >
              <div class="flex align-items-center">
                <span>{{ t("week.index", { index: i + 1, number: week.number }) }}</span>

                <p-button
                  styleClass="p-button-rounded p-button-text ml-2"
                  icon="pi pi-arrow-circle-down"
                  [pTooltip]="t('week.prefill')"
                  (onClick)="prefilled(week, $event)"
                ></p-button>
              </div>
            </th>
          </ng-container>
        </tr>

        <tr>
          <ng-container *ngFor="let week of performance.weeks; index as i">
            <th
              pSortableColumn="weeks.{{ i }}.attendance"
              class="white-space-nowrap"
              [class.current-week]="isCurrentWeek(week)"
            >
              {{ t("attendance.title") }}

              <p-columnFilter
                field="weeks.{{ i }}.attendance"
                matchMode="equals"
                display="menu"
                [showMatchModes]="false"
              >
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-dropdown
                    [ngModel]="value"
                    [options]="attendances"
                    (onChange)="filter($event.value)"
                    [showClear]="true"
                  >
                    <ng-template
                      *ngFor="let template of ['item', 'selectedItem']"
                      [pTemplate]="template"
                      let-attendance
                    >
                      <i
                        [ngClass]="EducationCourseAttendanceIcons[attendance.value]"
                        class="vertical-align-middle mr-2"
                      ></i>
                      <span class="vertical-align-middle">{{ attendance.label }}</span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>

              <p-sortIcon field="weeks.{{ i }}.attendance"></p-sortIcon>
            </th>

            <th
              pSortableColumn="weeks.{{ i }}.performance"
              class="white-space-nowrap"
              [class.current-week]="isCurrentWeek(week)"
            >
              {{ t("performance.title") }}

              <p-columnFilter
                field="weeks.{{ i }}.performance"
                matchMode="equals"
                display="menu"
                [showMatchModes]="false"
              >
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-dropdown
                    [ngModel]="value"
                    [options]="performances"
                    (onChange)="filter($event.value)"
                    [showClear]="true"
                  >
                    <ng-template
                      *ngFor="let template of ['item', 'selectedItem']"
                      [pTemplate]="template"
                      let-performance
                    >
                      <i
                        [ngClass]="EducationCoursePerformanceStatusIcons[performance.value]"
                        class="vertical-align-middle mr-2"
                      ></i>
                      <span class="vertical-align-middle">{{ performance.label }}</span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>
              </p-columnFilter>

              <p-sortIcon field="weeks.{{ i }}.performance"></p-sortIcon>
            </th>

            <th class="white-space-nowrap" [class.current-week]="isCurrentWeek(week)">
              {{ t("comment.title") }}
            </th>
          </ng-container>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-performance>
        <tr>
          <td pFrozenColumn class="white-space-nowrap">
            <app-candidate-flexible-edit-button
              [candidateId]="performance.candidateId"
              [candidateOrganizationId]="performance.candidateOrganizationId"
              [label]="performance.candidateDisplayId"
              [showFlag]="false"
            ></app-candidate-flexible-edit-button>
          </td>

          <td pFrozenColumn class="white-space-nowrap">
            {{ performance.candidateDisplayName }}
          </td>

          <td
            pFrozenColumn
            [pEditableColumn]="[performance]"
            pEditableColumnField="candidateState"
            [pEditableColumnNoKeyboardNavigation]="true"
          >
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown
                  [showClear]="true"
                  styleClass="w-full compact minimalistic"
                  [options]="states"
                  [(ngModel)]="performance.candidateState"
                >
                  <ng-template *ngFor="let template of ['item', 'selectedItem']" [pTemplate]="template" let-state>
                    <i
                      [ngClass]="EducationCourseCandidateStatusIcons[state.value]"
                      class="vertical-align-middle mr-2"
                    ></i>
                    <span class="vertical-align-middle">{{ state.label }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>

              <ng-template pTemplate="output">
                <div class="white-space-nowrap cursor-pointer">
                  <i
                    *ngIf="performance.candidateState"
                    [ngClass]="EducationCourseCandidateStatusIcons[performance.candidateState]"
                    class="vertical-align-middle mr-2"
                  ></i>
                  <span class="inline-block vertical-align-middle">
                    {{ performance.candidateState | enum: "EducationCourseCandidateStatus" }}
                  </span>
                </div>
              </ng-template>
            </p-cellEditor>
          </td>

          <ng-container *ngFor="let week of performance.weeks">
            <td
              [pEditableColumn]="[performance, week]"
              pEditableColumnField="attendance"
              [pEditableColumnNoKeyboardNavigation]="true"
              [class.current-week]="isCurrentWeek(week.week)"
            >
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-dropdown
                    styleClass="w-full compact minimalistic"
                    [options]="attendances"
                    [(ngModel)]="week.attendance"
                    [showClear]="true"
                  >
                    <ng-template
                      *ngFor="let template of ['item', 'selectedItem']"
                      [pTemplate]="template"
                      let-attendance
                    >
                      <i
                        [ngClass]="EducationCourseAttendanceIcons[attendance.value]"
                        class="vertical-align-middle mr-2"
                      ></i>
                      <span class="vertical-align-middle mr-6">{{ attendance.label }}</span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>

                <ng-template pTemplate="output">
                  <div class="white-space-nowrap cursor-pointer">
                    <i
                      *ngIf="week.attendance"
                      [ngClass]="EducationCourseAttendanceIcons[week.attendance]"
                      class="vertical-align-middle mr-2"
                    ></i>
                    <span class="inline-block vertical-align-middle">
                      {{ week.attendance | enum: "EducationCourseAttendance" }}
                    </span>
                  </div>
                </ng-template>
              </p-cellEditor>
            </td>

            <td
              [pEditableColumn]="[performance, week]"
              pEditableColumnField="performance"
              [pEditableColumnNoKeyboardNavigation]="true"
              [class.current-week]="isCurrentWeek(week.week)"
            >
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-dropdown
                    styleClass="w-full compact minimalistic"
                    [options]="performances"
                    [(ngModel)]="week.performance"
                    [showClear]="true"
                  >
                    <ng-template
                      *ngFor="let template of ['item', 'selectedItem']"
                      [pTemplate]="template"
                      let-performance
                    >
                      <i
                        [ngClass]="EducationCoursePerformanceStatusIcons[performance.value]"
                        class="vertical-align-middle mr-2"
                      ></i>
                      <span class="vertical-align-middle">{{ performance.label }}</span>
                    </ng-template>
                  </p-dropdown>
                </ng-template>

                <ng-template pTemplate="output">
                  <div class="white-space-nowrap cursor-pointer">
                    <i
                      *ngIf="week.performance"
                      [ngClass]="EducationCoursePerformanceStatusIcons[week.performance]"
                      class="vertical-align-middle mr-2"
                    ></i>
                    <span class="inline-block vertical-align-middle">
                      {{ week.performance | enum: "EducationCoursePerformanceStatus" }}
                    </span>
                  </div>
                </ng-template>
              </p-cellEditor>
            </td>

            <td
              [pEditableColumn]="[performance, week]"
              pEditableColumnField="comment"
              [pEditableColumnNoKeyboardNavigation]="true"
              [class.current-week]="isCurrentWeek(week.week)"
            >
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" class="compact minimalistic w-12rem" [(ngModel)]="week.comment" />
                </ng-template>

                <ng-template pTemplate="output">
                  <div
                    class="white-space-nowrap overflow-hidden text-overflow-ellipsis cursor-pointer w-12rem"
                    [pTooltip]="week.comment"
                  >
                    {{ week.comment }}
                  </div>
                </ng-template>
              </p-cellEditor>
            </td>
          </ng-container>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</ng-container>
