<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <label for="employmentApprovalAuthority">{{ t("employmentApprovalAuthority.title") }}</label>
  <p-dropdown
    inputId="employmentApprovalAuthority"
    [options]="employmentApprovalAuthorities"
    [formControl]="control"
    [showClear]="true"
    [placeholder]="t('employmentApprovalAuthority.placeholder')"
    [readonly]="readonly"
  ></p-dropdown>

  <small class="p-error" *ngIf="control.errors?.required">
    {{ t("employmentApprovalAuthority.required") }}
  </small>
</ng-container>
