import { Injectable } from "@angular/core";
import { getLogoDownloadURI, StaticDataTypeAllowedSharedProfileTabs } from "@ankaadia/ankaadia-shared";
import {
  CascadedOrganizationFragment,
  CopyDataToOrgGQL,
  CopyDataTypeEnum,
  CopyResult,
  CreateOrganizationGQL,
  GetAllLinkableOrganizationsGQL,
  GetAllManagedOrganizationsGQL,
  GetAllManagedOrganizationsQuery,
  GetCascadedLinkedOrganizationsFromSourceOrganizationGQL,
  GetCascadedLinkedOrganizationsGQL,
  GetCollectionOrganizationsGQL,
  GetCollectionOrganizationsQuery,
  GetLinkedOrganizationsGQL,
  GetNotifyUsersOnDeletionRequestGQL,
  GetOrganizationContractSettingsGQL,
  GetOrganizationGQL,
  GetOrganizationQuery,
  GetOrganizationsForAssignmentGQL,
  GetOrganizationsForAssignmentQuery,
  GetOrganizationsForCollectionSelectionGQL,
  GetOrganizationsForCollectionSelectionQuery,
  GetOrganizationsLinkedToGQL,
  GetOrganizationsSharingTabOfCandidateGQL,
  GetOrganizationsWithProcessesGQL,
  GetOrganizationsWithProcessesQuery,
  GetOrganizationWithLicenseInfoGQL,
  GetOrganizationWithLicenseInfoQuery,
  GetProcessesOrganizationsForCandidateGQL,
  GetProcessesOrganizationsForCandidateQuery,
  GetProcessPartiesGQL,
  GetProcessPartiesQuery,
  GetSelectableOrganizationsGQL,
  GetSelectableOrganizationsQuery,
  IsOrganizationLicensedGQL,
  MakeTenantToDemoTenantGQL,
  OrganizationContractSettingsFragment,
  OrganizationContractSettingsInput,
  OrganizationForListFragment,
  OrganizationForSelectionFragment,
  OrganizationFragment,
  OrganizationFragmentDoc,
  OrganizationInput,
  OrganizationRequireConsentInput,
  RemoveLicenseGQL,
  RemoveOrganizationGQL,
  RequireConsentOrganizationGQL,
  SharingTypeEnum,
  SupportedImmigrationCountry,
  UpdateOrganizationContractSettingsGQL,
  UpdateOrganizationGQL,
} from "@ankaadia/graphql";
import { map, Observable } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";

@Injectable({ providedIn: "root" })
export class OrganizationsService {
  constructor(
    private readonly settings: SettingsService,
    private readonly getOrg: GetOrganizationGQL,
    private readonly getOrgWithLicenseInfo: GetOrganizationWithLicenseInfoGQL,
    private readonly notifyUsersOnDeletionRequestGQL: GetNotifyUsersOnDeletionRequestGQL,
    private readonly linkedOrg: GetLinkedOrganizationsGQL,
    private readonly linkedToOrg: GetOrganizationsLinkedToGQL,
    private readonly cascadedLinkedOrgFromSource: GetCascadedLinkedOrganizationsFromSourceOrganizationGQL,
    private readonly cascadedLinkedOrgs: GetCascadedLinkedOrganizationsGQL,
    private readonly cOrg: CreateOrganizationGQL,
    private readonly uOrg: UpdateOrganizationGQL,
    private readonly rcOrg: RequireConsentOrganizationGQL,
    private readonly linkable: GetAllLinkableOrganizationsGQL,
    private readonly selectable: GetSelectableOrganizationsGQL,
    private readonly forCollectionSelection: GetOrganizationsForCollectionSelectionGQL,
    private readonly withProcesses: GetOrganizationsWithProcessesGQL,
    private readonly withProcessesForCandidates: GetProcessesOrganizationsForCandidateGQL,
    private readonly forAssignment: GetOrganizationsForAssignmentGQL,
    private readonly processParties: GetProcessPartiesGQL,
    private readonly organizationRemove: RemoveOrganizationGQL,
    private readonly colOrg: GetCollectionOrganizationsGQL,
    private readonly demoTenant: MakeTenantToDemoTenantGQL,
    private readonly copyData: CopyDataToOrgGQL,
    private readonly deleteLicense: RemoveLicenseGQL,
    private readonly getAllManagedOrganizationsQuery: GetAllManagedOrganizationsGQL,
    private readonly isLicensedQuery: IsOrganizationLicensedGQL,
    private readonly getOrganizationContractSettings: GetOrganizationContractSettingsGQL,
    private readonly updateOrganizationContractSettings: UpdateOrganizationContractSettingsGQL,
    private readonly getOrganizationsSharingTabOfCandidate: GetOrganizationsSharingTabOfCandidateGQL
  ) {}

  makeTenantToDemoTenant(organizationId: string): Observable<CopyResult> {
    return this.demoTenant.mutate({ organizationId: organizationId }).pipe(map((x) => x.data.makeTenantToDemoTenant));
  }

  copyDataToOrg(
    organizationId: string,
    dataType: CopyDataTypeEnum,
    templateDisplayNames?: string[]
  ): Observable<CopyResult> {
    return this.copyData
      .mutate({ organizationId: organizationId, dataType: dataType, templateDisplayNames: templateDisplayNames })
      .pipe(map((x) => x.data.copyDataToOrg));
  }

  removeLicense(organizationId: string): Observable<void> {
    return this.deleteLicense.mutate({ organizationId: organizationId }).pipe(map((x) => x.data.removeLicense));
  }

  isLicensed(organizationId: string): Observable<boolean> {
    return this.isLicensedQuery
      .fetch({ organizationId: organizationId })
      .pipe(map((x) => x.data.isOrganizationLicensed));
  }

  getAllManagedOrganizations(
    organizationId: string
  ): Observable<GetAllManagedOrganizationsQuery["getAllManagedOrganizations"]> {
    return this.getAllManagedOrganizationsQuery
      .fetch({ organizationId: organizationId })
      .pipe(map((result) => result.data.getAllManagedOrganizations));
  }

  getLinkableOrganizations(
    organizationId: string,
    linkedOrganizationId?: string
  ): Observable<OrganizationForSelectionFragment[]> {
    return this.linkable
      .fetch({ input: { organizationId, linkedOrganizationId } })
      .pipe(map((result) => result.data.findAllLinkableOrganizations));
  }

  getOrganizationLogoDownloadURL(organizationId: string): Observable<string> {
    return this.settings
      .getImageSasTokenAndOrigin(organizationId)
      .pipe(
        map(
          (tokenInfo) => `${tokenInfo.origin}/${getLogoDownloadURI(organizationId)}${tokenInfo.token}&ts=${Date.now()}`
        )
      );
  }

  getOrganization(id: string): Observable<GetOrganizationQuery["organization"]> {
    return this.getOrg
      .fetch({ id: id }, { fetchPolicy: "cache-first" })
      .pipe(map((result) => result.data?.organization));
  }

  getOrganizationWithLicenseInfo(id: string): Observable<GetOrganizationWithLicenseInfoQuery["organization"]> {
    return this.getOrgWithLicenseInfo
      .fetch({ id: id }, { fetchPolicy: "cache-first" })
      .pipe(map((result) => result.data?.organization));
  }

  getOrganizationsSharingTabToCandidate(
    candidateId: string,
    candidateOrganizationId: string,
    tab: StaticDataTypeAllowedSharedProfileTabs
  ): Observable<OrganizationForSelectionFragment[]> {
    return this.getOrganizationsSharingTabOfCandidate
      .fetch(
        {
          input: {
            candidateId,
            candidateOrganizationId,
            tab,
          },
        },
        { fetchPolicy: "cache-first" }
      )
      .pipe(map((result) => result.data?.getOrganizationsSharingTabOfCandidate));
  }

  getNotifyUsersOnDeletionRequest(id: string): Observable<any> {
    return this.notifyUsersOnDeletionRequestGQL
      .fetch({ id: id })
      .pipe(map((result) => result.data.getNotifyUsersOnDeletionRequest));
  }

  getSelectableOrganizations(
    sharingTypes?: SharingTypeEnum[]
  ): Observable<GetSelectableOrganizationsQuery["getSelectableOrganizations"]> {
    return this.selectable
      .fetch({ id: this.settings.organizationId, sharingTypes: sharingTypes })
      .pipe(map((result) => result.data.getSelectableOrganizations));
  }

  getOrganizationsForCollectionSelection(): Observable<
    GetOrganizationsForCollectionSelectionQuery["getOrganizationsForCollectionSelection"]
  > {
    return this.forCollectionSelection
      .fetch({ id: this.settings.organizationId })
      .pipe(map((result) => result.data.getOrganizationsForCollectionSelection));
  }

  getOrganizationsForAssignment(
    collectionId: string
  ): Observable<GetOrganizationsForAssignmentQuery["getOrganizationsForAssignment"]> {
    return this.forAssignment
      .fetch({ input: { collectionId: collectionId, organizationId: this.settings.organizationId } })
      .pipe(map((x) => x.data.getOrganizationsForAssignment));
  }

  getLinkedOrganizations(
    organizationId: string,
    includeMyself?: boolean,
    cacheFirst?: boolean
  ): Observable<OrganizationForSelectionFragment[]> {
    return this.linkedOrg
      .fetch(
        { input: { id: organizationId, includeMyself: includeMyself } },
        { fetchPolicy: cacheFirst ? "cache-first" : "network-only" }
      )
      .pipe(map((result) => result.data.getLinkedOrganizations));
  }

  getOrganizationsLinkedTo(
    organizationId: string,
    includeMyself?: boolean,
    cacheFirst?: boolean
  ): Observable<OrganizationForSelectionFragment[]> {
    return this.linkedToOrg
      .fetch(
        { input: { id: organizationId, includeMyself: includeMyself } },
        { fetchPolicy: cacheFirst ? "cache-first" : "network-only" }
      )
      .pipe(map((result) => result.data.getOrganizationsLinkedTo));
  }

  watchLinkedOrganizations(organizationId: string): Observable<OrganizationForListFragment[]> {
    return this.linkedOrg
      .watch({ input: { id: organizationId } })
      .valueChanges.pipe(map((result) => result.data.getLinkedOrganizations));
  }

  /**
   * Get all linked and managed organizations related to a candidate organization id, independent of immigration country
   */
  getAllCascadedLinkedOrganizationsFromSourceOrganization(
    sourceOrganizationId: string,
    targetOrganizationId: string
  ): Observable<CascadedOrganizationFragment[]> {
    return this.getCascadedLinkedOrganizationsFromSourceOrganization(sourceOrganizationId, targetOrganizationId, null);
  }

  /**
   * Get all linked and managed organizations related to a candidate organization id
   */
  getCascadedLinkedOrganizationsFromSourceOrganization(
    sourceOrganizationId: string,
    targetOrganizationId: string,
    immigrationCountry: SupportedImmigrationCountry
  ): Observable<CascadedOrganizationFragment[]> {
    return this.cascadedLinkedOrgFromSource
      .fetch(
        {
          input: {
            sourceOrganizationId: sourceOrganizationId,
            targetOrganizationId: targetOrganizationId,
            immigrationCountry: immigrationCountry,
          },
        },
        { fetchPolicy: "cache-first" }
      )
      .pipe(map((x) => x.data.getCascadedLinkedOrganizationsFromSourceOrganization));
  }

  /**
   * Get all linked and managed organizations related to a candidate organization id
   */
  getCascadedLinkedOrganizations(organizationId: string): Observable<CascadedOrganizationFragment[]> {
    return this.cascadedLinkedOrgs
      .fetch(
        {
          organizationId: organizationId,
        },
        { fetchPolicy: "cache-first" }
      )
      .pipe(map((x) => x.data.getCascadedLinkedOrganizations));
  }

  getCollectionOrganizations(
    collectionId: string,
    organizationId?: string
  ): Observable<GetCollectionOrganizationsQuery["getCollectionOrganizations"]> {
    return this.colOrg
      .fetch({ orgId: organizationId ?? this.settings.organizationId, colId: collectionId })
      .pipe(map((result) => result.data.getCollectionOrganizations));
  }

  getOrganizationsWithProcesses(): Observable<GetOrganizationsWithProcessesQuery["getOrganizationsWithProcesses"]> {
    return this.withProcesses
      .fetch({ id: this.settings.organizationId })
      .pipe(map((result) => result.data.getOrganizationsWithProcesses));
  }

  getOrganizationsWithProcessesForCandidates(): Observable<
    GetProcessesOrganizationsForCandidateQuery["getProcessesOrganizationsForCandidate"]
  > {
    return this.withProcessesForCandidates
      .fetch({ organizationId: this.settings.organizationId, candidateId: this.settings.userOrCandidateId })
      .pipe(map((result) => result.data.getProcessesOrganizationsForCandidate));
  }

  getProcessParties(): Observable<GetProcessPartiesQuery["getProcessParties"]> {
    return this.processParties
      .fetch({ id: this.settings.organizationId })
      .pipe(map((result) => result.data.getProcessParties));
  }

  createOrganization(orgInput: OrganizationInput): Observable<OrganizationFragment> {
    orgInput.configToken = orgInput.configToken == "" ? null : orgInput.configToken;
    orgInput.emailAdress = orgInput.emailAdress == "" ? null : orgInput.emailAdress;
    return this.cOrg
      .mutate(
        { input: orgInput },
        {
          update: (cache, mutationResult) =>
            cache.modify({
              fields: {
                getLinkedOrganizations(refs, helper) {
                  if (!helper.storeFieldName.includes(orgInput.creatorOrganizationId)) return refs;
                  const ref = cache.writeFragment({
                    data: mutationResult.data.createOrganization,
                    fragment: OrganizationFragmentDoc,
                  });
                  if (refs != null && refs.length > 0) {
                    return [...refs, ref];
                  } else {
                    return [ref];
                  }
                },
              },
            }),
        }
      )
      .pipe(map((result) => result.data?.createOrganization));
  }

  updateOrganization(orgInput: OrganizationInput): Observable<OrganizationFragment> {
    orgInput.configToken = orgInput.configToken == "" ? null : orgInput.configToken;
    orgInput.emailAdress = orgInput.emailAdress == "" ? null : orgInput.emailAdress;
    return this.uOrg.mutate({ input: orgInput }).pipe(map((result) => result.data?.updateOrganization));
  }

  requireConsentInput(input: OrganizationRequireConsentInput): Observable<OrganizationFragment> {
    return this.rcOrg.mutate({ input: input }).pipe(map((x) => x.data.requireConsentOrganization));
  }

  getContractSettings(organizationId: string): Observable<OrganizationContractSettingsFragment> {
    return this.getOrganizationContractSettings
      .fetch({ organizationId })
      .pipe(map((result) => result.data.getOrganizationContractSettings));
  }

  updateContractSettings(input: OrganizationContractSettingsInput): Observable<OrganizationContractSettingsFragment> {
    return this.updateOrganizationContractSettings
      .mutate({ input: input })
      .pipe(map((result) => result.data.updateOrganizationContractSettings));
  }

  removeOrganization(org: OrganizationForListFragment): Observable<boolean> {
    return this.organizationRemove
      .mutate(
        { input: { id: org.id, _etag: org._etag } },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getLinkedOrganizations: (refs, { readField }) => refs.filter((ref) => readField("id", ref) !== org.id),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.removeOrganization.status));
  }
}
