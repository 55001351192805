import { Pipe, PipeTransform } from "@angular/core";
import { IDateWithTime } from "@ankaadia/ankaadia-shared";
import { DateFormatOptions, Locale, ValidDate } from "@jsverse/transloco-locale";
import { DateFormatterService } from "../services/date-formatter.service";

export interface AppDateTimeFormatOptions extends DateFormatOptions {
  showTimeZone?: boolean;
}

export interface AppDateFormatOptions
  extends Omit<
    DateFormatOptions,
    "timeStyle" | "hour" | "hour12" | "minute" | "second" | "timeZone" | "timeZoneName"
  > {}

/**
 * Transforms a date to a string. The date needs to be in the form 2024-10-02T00:00:00.000Z in order to be displayed as October 2nd 2024.
 */
@Pipe({ name: "appDate", standalone: false })
export class AppDatePipe implements PipeTransform {
  constructor(private readonly dateFormatterService: DateFormatterService) {}

  transform(value: ValidDate, options?: AppDateFormatOptions, locale?: Locale): string {
    return this.dateFormatterService.transformDate(value, options, locale);
  }
}

/**
 * Transforms a datetime to a string.
 */
@Pipe({ name: "appDateTime", standalone: false })
export class AppDateTimePipe implements PipeTransform {
  constructor(private readonly dateFormatterService: DateFormatterService) {}

  transform(value: ValidDate, options?: AppDateTimeFormatOptions, locale?: Locale): string {
    return this.dateFormatterService.transformDateTime(value, options, locale);
  }
}

/**
 * Transforms a DateWithTime to a string.
 */
@Pipe({ name: "appDateWithTime", standalone: false })
export class AppDateWithTimePipe implements PipeTransform {
  constructor(private readonly dateFormatterService: DateFormatterService) {}

  transform(
    value: IDateWithTime,
    dateOptions: AppDateFormatOptions,
    dateWithTimeOptions: AppDateTimeFormatOptions,
    locale?: Locale
  ): string {
    if (value?.hasTime) return this.dateFormatterService.transformDateTime(value?.date, dateWithTimeOptions, locale);
    else return this.dateFormatterService.transformDate(value?.date, dateOptions, locale);
  }
}
