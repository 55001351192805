import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { SelectedDocumentField } from "@ankaadia/ankaadia-shared";
import { Document, DocumentLockMode, DocumentMode } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import {
  CandidateProfileDialogService,
  DocumentDialogEvent,
} from "../../../organization-specific/base-candidate-profile/candidate-profile-dialog.service";
import { AdditionalForeignKeySourceData } from "../../candidate-document-metadata/candidate-document-foreign-key-handler";
import { AnyCandidateForm } from "../../candidate-form/candidate-form.model";

@Component({
  selector: "app-candidate-document-edit-dialog",
  templateUrl: "./candidate-document-edit-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CandidateDocumentEditDialogComponent implements OnInit {
  readonly DocumentMode = DocumentMode;
  readonly language = this.transloco.getActiveLang();

  document: Document;
  field: SelectedDocumentField;
  form: AnyCandidateForm;
  additionForeignKeySourceData: AdditionalForeignKeySourceData;

  readonly: boolean;
  showSidebar: boolean;

  constructor(
    private readonly transloco: TranslocoService,
    private readonly profileDialogService: CandidateProfileDialogService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.profileDialogService.documentDialog.subscribe((x) => this.open(x));
  }

  open(event: DocumentDialogEvent): void {
    this.document = event.document;
    this.field = event.selectedField;
    this.form = event.candidateForm;
    this.additionForeignKeySourceData = event.additionalForeignKeySourceData;
    this.readonly = this.document.lockState != DocumentLockMode.Unlocked && this.document.lockState != null;
    this.showSidebar = true;
    this.changeDetector.detectChanges();
  }

  close(): void {
    this.showSidebar = false;
    this.document = null;
    this.field = null;
    this.form = null;
    this.readonly = null;
  }
}
