import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { supportedLanguageFlags4LetterISO } from "@ankaadia/ankaadia-shared";
import { StaticDataModel } from "@ankaadia/graphql";

@Component({
  selector: "app-language-selector",
  templateUrl: "./language-selector.component.html",
  styleUrl: "./language-selector.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LanguageSelectorComponent {
  readonly flags = supportedLanguageFlags4LetterISO();

  @Input()
  languages: StaticDataModel[];

  @Input()
  selectedLanguage: string;

  @Output()
  readonly selectedLanguageChange = new EventEmitter<string>();
}
