import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { emailPattern } from "@ankaadia/ankaadia-shared";
import { CandidateFamilyMember, CandidateRelation, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { map } from "rxjs";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";

@Component({
  selector: "app-candidate-family-member-edit-dialog",
  templateUrl: "./candidate-family-member-edit-dialog.component.html",
  standalone: false,
})
export class CandidateFamilyMemberEditDialogComponent implements OnInit {
  CandidateRelationEnum: typeof CandidateRelation = CandidateRelation;

  readonly language = this.transloco.getActiveLang();
  readonly salutations = this.staticDataService.getStaticData(StaticDataType.Salutation, this.language);
  readonly countries = this.staticDataService.getStaticData(StaticDataType.Countries, this.language);
  readonly languageSkills = this.staticDataService
    .getStaticData(StaticDataType.LanguageLevels, this.language)
    .pipe(map((data) => [{ label: "---", value: null }, ...data]));

  readonly relations = [
    { label: translate("enum.CandidateRelation.Partner"), value: CandidateRelation.Partner },
    { label: translate("enum.CandidateRelation.Child"), value: CandidateRelation.Child },
    { label: translate("enum.CandidateRelation.Mother"), value: CandidateRelation.Mother },
    { label: translate("enum.CandidateRelation.Father"), value: CandidateRelation.Father },
    { label: translate("enum.CandidateRelation.Other"), value: CandidateRelation.Other },
  ];

  readonly minBirthDate = new Date(1900, 0, 1);
  readonly maxBirthDate = new Date();

  displayHint = false;

  get custodians(): { label: string; value: string }[] {
    return this.allMembers
      .filter((x) => x.id !== this.member.id)
      .map((x) => ({
        label: `${x.firstName} ${x.lastName} (${this.relations.find((y) => y.value === x.relation).label})`,
        value: x.id,
      }));
  }

  @Input()
  member: CandidateFamilyMember;

  @Input()
  emergencyContactIsSet: boolean;

  @Input()
  allMembers: any[];

  @Output()
  readonly saved = new EventEmitter<CandidateFamilyMember>();

  @Output()
  readonly closed = new EventEmitter<void>();

  form: FormGroup;

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly formBuilder: FormBuilder,
    private readonly transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        id: [this.member.id],
        reunification: [this.member.reunification],
        isEmergencyContact: [this.member.isEmergencyContact],
        salutation: [this.member.salutation, Validators.required],
        firstName: [this.member.firstName, Validators.compose([Validators.required, Validators.maxLength(100)])],
        lastName: [this.member.lastName, Validators.compose([Validators.required, Validators.maxLength(100)])],
        birthName: [this.member.birthName, Validators.maxLength(100)],
        birthDate: [this.member.birthDate],
        cityOfBirth: [this.member.cityOfBirth, Validators.maxLength(100)],
        countryOfBirth: [this.member.countryOfBirth, Validators.maxLength(3)],
        email: [this.member.email, Validators.pattern(emailPattern)],
        phone: [this.member.phone, Validators.maxLength(50)],
        citizenship: [this.member.citizenship, Validators.maxLength(3)],

        relation: [this.member.relation, Validators.required],

        sameAddressAsCandidate: [this.member.sameAddressAsCandidate ?? true],
        address: [this.member.address, Validators.maxLength(500)],
        zipCode: [this.member.zipCode, Validators.maxLength(30)],
        city: [this.member.city, Validators.maxLength(100)],
        country: [this.member.country, Validators.maxLength(3)],
        germanLanguageSkill: [this.member.germanLanguageSkill, Validators.maxLength(3)],

        hasSharedCustody: [this.member.hasSharedCustody],
        otherCustodian: [this.member.otherCustodian],
      },
      {
        validators: this.validateReunification,
      }
    );
    if (this.emergencyContactIsSet && !this.member.isEmergencyContact) {
      this.form.controls.isEmergencyContact.disable();
    }
  }

  validateReunification(form: FormGroup): null | ValidationErrors {
    if (!form?.controls) return null;
    const controls = form.controls;

    const personalDataRequiredForReunification = [
      controls.birthDate,
      controls.countryOfBirth,
      controls.cityOfBirth,
      controls.citizenship,
    ];

    const addressDataRequiredReunification = [controls.address, controls.zipCode, controls.country, controls.city];

    const controlsWithErrors = personalDataRequiredForReunification
      .filter(isPersonalDataMissing)
      .concat(addressDataRequiredReunification.filter(isAddressDataMissing));

    const controlsWithOutErrors = personalDataRequiredForReunification
      .filter((x) => !isPersonalDataMissing(x))
      .concat(addressDataRequiredReunification.filter((x) => !isAddressDataMissing(x)));

    controlsWithErrors.forEach((x) => x.setErrors({ required: true }));
    controlsWithOutErrors.forEach((x) => x.setErrors(null));

    return controlsWithErrors.length > 0 ? { required: true } : null;

    function isPersonalDataMissing(x: AbstractControl): boolean {
      return controls.reunification?.value && !x.value;
    }
    function isAddressDataMissing(x: AbstractControl): boolean {
      return isPersonalDataMissing(x) && !controls.sameAddressAsCandidate?.value;
    }
  }
}
