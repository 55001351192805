import { Injectable } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, ValidationErrors, Validators } from "@angular/forms";
import { getTranslatableContractValidationIssues, TranslatedValidationIssue } from "@ankaadia/ankaadia-shared";
import {
  CalculationBasis,
  CompensationType,
  ContractTemplateAllowance,
  ContractTemplateBenefit,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { isEmpty, isNil } from "lodash";
import { combineLatest, distinctUntilChanged, map, pairwise, startWith } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import {
  syncMinMax,
  updateValueAndValidity,
  validatePropertyUniqueness,
  valuesOf,
} from "../../../shared/services/form.helper";
import {
  ContractTemplateAllowanceForm,
  ContractTemplateBenefitForm,
  ContractTemplateForm,
  CustomLaborAgreementForm,
  FormContractTemplate,
  FormContractTemplateAllowance,
  FormContractTemplateBenefit,
  LaborAgreementSelectionForm,
} from "./contract-template-form.model";

@Injectable({ providedIn: "root" })
export class ContractTemplateFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createForm(template: FormContractTemplate, hideName: boolean): ContractTemplateForm {
    const form = this.formBuilder.group<ContractTemplateForm["controls"]>(
      {
        id: this.formBuilder.control(template.id),
        organizationId: this.formBuilder.control(template.organizationId, Validators.required),
        _etag: this.formBuilder.control(template._etag),
        name: hideName
          ? this.formBuilder.control(template.name)
          : this.formBuilder.control(template.name, [
              Validators.required,
              Validators.maxLength(100),
              this.uniqueNameValidator,
            ]),
        country: this.formBuilder.control(template.country ?? SupportedImmigrationCountry.De, Validators.required),
        probationPeriod: this.formBuilder.control(template.probationPeriod, Validators.min(0)),
        probationPeriodUnit: this.formBuilder.control(template.probationPeriodUnit),
        noticePeriod: this.formBuilder.control(template.noticePeriod, Validators.min(0)),
        noticePeriodUnit: this.formBuilder.control(template.noticePeriodUnit),
        employmentRelationshipType: this.formBuilder.control(template.employmentRelationshipType),
        workingTimeType: this.formBuilder.control(template.workingTimeType),
        workSchedule: this.formBuilder.control(template.workSchedule),
        workingHoursPerWeek: this.formBuilder.control(template.workingHoursPerWeek),
        holidayEntitlement: this.formBuilder.control(template.holidayEntitlement),
        professionTitle: this.formBuilder.control(template.professionTitle),
        professionDescription: this.formBuilder.control(template.professionDescription),
        ezbAdditionalInformation: this.formBuilder.control(template.ezbAdditionalInformation),
        overtimeHours: this.formBuilder.control(template.overtimeHours),
        overtimeCompensation: this.formBuilder.control(template.overtimeCompensation),
        contractType: this.formBuilder.control(template.contractType),
        laborAgreementSelection: this.formBuilder.group<LaborAgreementSelectionForm["controls"]>({
          laborAgreementId: this.formBuilder.control(template.laborAgreementSelection?.laborAgreementId),
          organizationId: this.formBuilder.control(template.laborAgreementSelection?.organizationId),
          payGradeId: this.formBuilder.control(template.laborAgreementSelection?.payGradeId),
        }),
        customLaborAgreement: this.formBuilder.group<CustomLaborAgreementForm["controls"]>({
          collectiveAgreement: this.formBuilder.control(template.customLaborAgreement?.collectiveAgreement),
          salaryGroup: this.formBuilder.control(template.customLaborAgreement?.salaryGroup),
        }),
        compensationType: this.formBuilder.control(template.compensationType),
        compensationRate: this.formBuilder.control(template.compensationRate),
        numberOfSalaries: this.formBuilder.control(template.numberOfSalaries),
        otherPaymentRegulation: this.formBuilder.control(template.otherPaymentRegulation),
        overtimeWithinLimitsPermittedByLaw: this.formBuilder.control(template.overtimeWithinLimitsPermittedByLaw),
        validFrom: this.formBuilder.control(template.validFrom),
        validUntil: this.formBuilder.control(template.validUntil),
        changedAt: this.formBuilder.control(template.changedAt),
        changedBy: this.formBuilder.control(template.changedBy),
        allowances: this.createAllowanceFormArray(template.allowances),
        benefits: this.createBenefitFormArray(template.benefits),
        canBeDeleted: this.formBuilder.control(template.canBeDeleted),
        unavailableNames: this.formBuilder.control(template.unavailableNames),
      },
      { validators: this.validateContractTemplate }
    );

    syncMinMax(form.controls.validFrom, form.controls.validUntil);

    valuesOf(form.controls.overtimeHours).subscribe((value) => {
      if (value) {
        form.controls.overtimeWithinLimitsPermittedByLaw.disable();
        form.controls.overtimeWithinLimitsPermittedByLaw.reset();
      } else {
        form.controls.overtimeWithinLimitsPermittedByLaw.enable();
      }
    });

    const { contractType, laborAgreementSelection, customLaborAgreement } = form.controls;
    const contractType$ = valuesOf(contractType).pipe(distinctUntilChanged());
    const laborAgreementId$ = valuesOf(laborAgreementSelection.controls.laborAgreementId).pipe(distinctUntilChanged());
    combineLatest([contractType$, laborAgreementId$])
      .pipe(
        map(([contractType, _laborAgreementId]) => contractType),
        startWith(contractType.value),
        pairwise()
      )
      .subscribe(([previousContractType, currentContractType]) => {
        if (previousContractType !== currentContractType) {
          laborAgreementSelection.reset();
        }

        if (!isNil(currentContractType) && currentContractType !== "AgreementByContract") {
          form.controls.laborAgreementSelection.enable();
          form.controls.customLaborAgreement.enable();

          const { laborAgreementId } = laborAgreementSelection.value;
          if (isNil(laborAgreementId)) {
            customLaborAgreement.controls.collectiveAgreement.clearValidators();
            customLaborAgreement.controls.salaryGroup.clearValidators();

            laborAgreementSelection.reset();
            laborAgreementSelection.controls.organizationId.setValidators(this.forbiddenValidator);
            laborAgreementSelection.controls.laborAgreementId.setValidators(this.forbiddenValidator);
            laborAgreementSelection.controls.payGradeId.setValidators(this.forbiddenValidator);
          } else {
            laborAgreementSelection.controls.organizationId.clearValidators();
            laborAgreementSelection.controls.laborAgreementId.clearValidators();
            laborAgreementSelection.controls.payGradeId.clearValidators();

            customLaborAgreement.reset();
            customLaborAgreement.controls.collectiveAgreement.setValidators(this.forbiddenValidator);
            customLaborAgreement.controls.salaryGroup.setValidators(this.forbiddenValidator);
          }
        } else {
          laborAgreementSelection.disable();
          laborAgreementSelection.reset();
          laborAgreementSelection.controls.organizationId.setValidators(this.forbiddenValidator);
          laborAgreementSelection.controls.laborAgreementId.setValidators(this.forbiddenValidator);
          laborAgreementSelection.controls.payGradeId.setValidators(this.forbiddenValidator);

          customLaborAgreement.disable();
          customLaborAgreement.reset();
          customLaborAgreement.controls.collectiveAgreement.setValidators(this.forbiddenValidator);
          customLaborAgreement.controls.salaryGroup.setValidators(this.forbiddenValidator);
        }

        updateValueAndValidity(form);
      });

    valuesOf(form.controls.overtimeWithinLimitsPermittedByLaw).subscribe((value) => {
      // overtimeWithinLimitsPermittedByLaw and overtimeHours enable/disable each other
      // check if overtimeHoursControl is already enabled/disabled to avoid infinite loop of changing values
      if (!form.controls.overtimeHours.disabled && value) {
        form.controls.overtimeHours.disable();
        form.controls.overtimeHours.reset();
      }
      if (!form.controls.overtimeHours.enabled && !value) {
        form.controls.overtimeHours.enable();
      }
    });

    valuesOf(form.controls.compensationType).subscribe((value) => {
      switch (value) {
        case CompensationType.PerMonth:
          form.controls.numberOfSalaries.enable();
          break;
        case CompensationType.PerHour:
        default:
          form.controls.numberOfSalaries.disable();
          form.controls.numberOfSalaries.reset();
          break;
      }
    });

    return form;
  }

  createAllowanceFormArray(allowances?: ContractTemplateAllowance[]): FormArray<ContractTemplateAllowanceForm> {
    const array = allowances ?? [];
    return this.formBuilder.array<ContractTemplateAllowanceForm>(
      array.map((allowance) => this.createAllowanceForm(allowance)),
      Validators.compose([this.validateAllowanceTypeUniqueness, this.validateAllowanceCustomTypeUniqueness])
    );
  }

  createBenefitFormArray(benefits?: ContractTemplateBenefit[]): FormArray<ContractTemplateBenefitForm> {
    const array = benefits ?? [];
    return this.formBuilder.array<ContractTemplateBenefitForm>(
      array.map((benefit) => this.createBenefitForm(benefit)),
      this.validateBenefitUniqueness
    );
  }

  createAllowanceForm(allowance?: Partial<FormContractTemplateAllowance>): ContractTemplateAllowanceForm {
    return this.formBuilder.group<ContractTemplateAllowanceForm["controls"]>(
      {
        id: this.formBuilder.control(allowance?.id ?? uuidv4(), Validators.required),
        type: this.formBuilder.control(allowance?.type),
        customType: this.formBuilder.control(allowance?.customType, Validators.maxLength(50)),
        calculationBasis: this.formBuilder.control(allowance?.calculationBasis, Validators.required),
        amount: this.formBuilder.control(
          allowance?.amount,
          Validators.compose([Validators.required, Validators.min(0)])
        ),
        comment: this.formBuilder.control(allowance?.comment, Validators.maxLength(600)),
        isOverride: this.formBuilder.control(allowance?.isOverride ?? false),
        isIgnored: this.formBuilder.control(allowance?.isIgnored ?? false),
      },
      {
        validators: [this.validateAllowanceType, this.validateAllowanceAmount],
      }
    );
  }

  createBenefitForm(benefit?: Partial<FormContractTemplateBenefit>): ContractTemplateBenefitForm {
    return this.formBuilder.group<ContractTemplateBenefitForm["controls"]>(
      {
        id: this.formBuilder.control(benefit?.id ?? uuidv4(), Validators.required),
        type: this.formBuilder.control(benefit?.type, Validators.required),
        timeInterval: this.formBuilder.control(benefit?.timeInterval, Validators.required),
        calculationBasis: this.formBuilder.control(benefit?.calculationBasis, Validators.required),
        amount: this.formBuilder.control(benefit?.amount, Validators.compose([Validators.required, Validators.min(0)])),
        isOverride: this.formBuilder.control(benefit?.isOverride ?? false),
        isIgnored: this.formBuilder.control(benefit?.isIgnored ?? false),
      },
      {
        validators: [this.validateBenefitAmount],
      }
    );
  }

  private validateContractTemplate(control: ContractTemplateForm): ValidationErrors | null {
    const translatableIssues = getTranslatableContractValidationIssues(control.getRawValue());
    const translatedIssues = translatableIssues.map(({ propertyName, message }) => {
      return new TranslatedValidationIssue(propertyName, translate(message));
    });

    return isEmpty(translatableIssues) ? null : { generic: translatedIssues };
  }

  private validateBenefitUniqueness(formArray: FormArray<ContractTemplateBenefitForm>): ValidationErrors | null {
    return validatePropertyUniqueness(formArray, (control) => control.controls.type.value);
  }

  private validateAllowanceTypeUniqueness(
    formArray: FormArray<ContractTemplateAllowanceForm>
  ): ValidationErrors | null {
    return validatePropertyUniqueness(formArray, (control) => control.controls.type.value);
  }

  private validateAllowanceCustomTypeUniqueness(
    formArray: FormArray<ContractTemplateAllowanceForm>
  ): ValidationErrors | null {
    return validatePropertyUniqueness(formArray, (control) => control.controls.customType.value);
  }

  private validateAllowanceType(formGroup: ContractTemplateAllowanceForm): ValidationErrors | null {
    const type = formGroup.controls.type.value;
    const customType = formGroup.controls.customType.value;
    if (isNil(type) && isNil(customType)) {
      return { typeRequired: true };
    }

    return !isNil(type) && !isNil(customType) ? { corruptType: true } : null;
  }

  private validateAllowanceAmount(formGroup: ContractTemplateAllowanceForm): ValidationErrors | null {
    const calculationBasis = formGroup.controls.calculationBasis.value;
    const isPercentage = calculationBasis === CalculationBasis.Percentage;
    return isPercentage && formGroup.controls.amount.value > 1000 ? { percentage: true } : null;
  }

  private validateBenefitAmount(formGroup: ContractTemplateBenefitForm): ValidationErrors | null {
    const calculationBasis = formGroup.controls.calculationBasis.value;
    const isPercentage = calculationBasis === CalculationBasis.Percentage;
    return isPercentage && formGroup.controls.amount.value > 1000 ? { percentage: true } : null;
  }

  private uniqueNameValidator(control: ContractTemplateForm["controls"]["name"]): ValidationErrors | null {
    const parent = control.parent as ContractTemplateForm;
    if (isNil(parent)) {
      return null;
    }

    const unavailableNames = parent.controls.unavailableNames.value;
    if (isEmpty(unavailableNames)) {
      return null;
    }

    if (!isNil(control.value) && unavailableNames.includes(control.value)) {
      return { unique: true };
    }

    return null;
  }

  private forbiddenValidator(control: AbstractControl): ValidationErrors | null {
    return control.value ? { forbidden: true } : null;
  }
}
