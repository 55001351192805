import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SafeUrl } from "@angular/platform-browser";
import { GetAllManagedOrganizationsQuery, Organization, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { FileUpload } from "primeng/fileupload";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { UsersService } from "../../users/users.service";
import { OrganizationsService } from "../organizations.service";

@Component({
  selector: "app-organization-edit",
  templateUrl: "./organization-edit.component.html",
  styleUrl: "./organization-edit.component.scss",
  standalone: false,
})
export class OrganizationEditComponent implements OnInit {
  readonly language = this.transloco.getActiveLang();
  readonly organizationTypes = this.staticDataService.getStaticData(StaticDataType.OrganizationType, this.language);
  readonly countries = this.staticDataService.getStaticData(StaticDataType.Countries, this.language);
  readonly cooperationTypes = this.staticDataService.getStaticData(StaticDataType.CooperationType, this.language);
  readonly maxEstablishmentDate = new Date();
  users: { id: string; displayName: string }[];

  @Input()
  editOrg: Organization;

  @Input()
  form: FormGroup;

  @Input()
  creatorOrgName: string;

  @Input()
  logoDownloadUrl: SafeUrl;

  @Input()
  logoForceChange: string;

  @ViewChild("logoUpload")
  upload: FileUpload;

  @Input()
  myOrgMode = false;

  @Output()
  readonly logoSelect = new EventEmitter<any>();

  @Output()
  readonly logoUpload = new EventEmitter<any>();

  get isMasterUser(): boolean {
    return this.settings.isMasterUser;
  }

  protected selectableCompanies: GetAllManagedOrganizationsQuery["getAllManagedOrganizations"]; //TreeNode[];
  protected parentName: string;

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly organizationService: OrganizationsService,
    private readonly settings: SettingsService,
    private readonly transloco: TranslocoService,
    private readonly userService: UsersService
  ) {}

  selectLogo(event: unknown): void {
    this.logoSelect.emit(event);
  }

  uploadLogo(event: unknown): void {
    this.logoUpload.emit(event);
  }

  ngOnInit(): void {
    this.selectableCompanies = [];
    this.parentName = null;

    if (this.myOrgMode) {
      this.userService
        .getUsers(this.settings.organizationId)
        .subscribe((x) => (this.users = x.map((u) => ({ id: u.id, displayName: u.lastname + ", " + u.firstname }))));
    }
    this.organizationService.getAllManagedOrganizations(this.settings.organizationId).subscribe((orgs) => {
      const parent = this.editOrg ? orgs.find((x) => x.id == this.editOrg.parentOrganizationId) : null;
      if (
        parent ||
        this.editOrg?.parentOrganizationId == null ||
        this.editOrg.parentOrganizationId == this.settings.organizationId
      ) {
        this.selectableCompanies = [
          {
            id: this.settings.organizationId,
            name: this.settings.organizationName,
            parentOrganizationId: null,
            code: this.settings.organizationCode,
          },
          ...orgs,
        ].sort((a, b) => a.name.localeCompare(b.name));
        this.parentName = null;
      } else {
        this.parentName = this.editOrg.parentOrganizationName;
      }
    });
  }

  // Primeng Treenode Select does not work with Reactive Forms
  /*   setTreenodes(orgs: GetAllManagedOrganizationsQuery["getAllManagedOrganizations"]): void {
      let topLevelList = orgs;
      this.selectableCompanies = [];
      let children: TreeNode[];
      for (const org of orgs) {
        const childs = topLevelList.filter(tl => tl.parentOrganizationId === org.id);
        const node = this.selectableCompanies.find(x => x.key === org.id) ?? { key: org.id, label: org.name, data: org.id, children: [] };
        this.selectableCompanies = this.selectableCompanies.includes(node) ? this.selectableCompanies : this.selectableCompanies.concat([node]);
        const currentChildren = children;
        node.children.push(...childs.map(child => (currentChildren.find(x => x.key == child.id) ?? { key: child.id, parent: node, label: child.name, data: child.id, children: [] })));
        children = union(children, node.children);
        topLevelList = difference(topLevelList, childs);
      }
      this.selectableCompanies = uniqBy(differenceBy(this.selectableCompanies, children, "key"), "key");
    } */
}
