import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Validators } from "@angular/forms";
import { toPairs } from "lodash";
import { TreeNode } from "primeng/api";
import { Dictionary } from "ts-essentials";
import { CollectionAutoFiltersForm } from "../../collections/collection-auto/collection-auto-filter-form.model";
import {
  FilterMetadataMap,
  IsFilterMetaDataArray,
} from "../../collections/collection-edit-assigned-candidates/custom-filter.model";
import { Filter, FiltersForm } from "../candidate-filter-form.model";
import { CandidateFilterFormService } from "../candidate-filter-form.service";
import {
  CandidateFilter,
  CandidateFilterField,
  CandidateFilterFieldMeta,
  StaticDataModelGroup,
} from "../candidate-filter.model";
import { CandidateFilterMetadata } from "@ankaadia/ankaadia-shared";

@Component({
  selector: "app-candidate-filter-set",
  templateUrl: "./candidate-filter-set.component.html",
  styleUrl: "./candidate-filter-set.component.scss",
  standalone: false,
})
export class CandidateFilterSetComponent implements OnInit, OnChanges {
  protected meta: CandidateFilterFieldMeta[] = [];

  @Input({ required: true })
  form: FiltersForm | CollectionAutoFiltersForm;

  @Input({ required: true })
  fields: TreeNode<CandidateFilterField>[];

  @Input({ required: true })
  filter?: FilterMetadataMap;

  @Input()
  variables: Dictionary<StaticDataModelGroup>;

  get filters(): FiltersForm["controls"]["filters"] {
    return this.form.controls.filters;
  }

  get anyFilterWithMultipleDataSourceOptionsExists(): boolean {
    return this.meta.some((meta) => this.hasMultipleDataSourceOptions(meta));
  }

  constructor(
    private readonly formService: CandidateFilterFormService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.setFiltersFromEvent();
    this.initializeFilters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter || (changes.fields && this.filter)) {
      this.setFiltersFromEvent();
      this.initializeFilters();
    }
  }

  addFilter(): void {
    this.meta.push({ conditions: null, options: null, parameter: null, groupName: null, dataSource: null });
    this.filters.push(this.formService.createFilterForm(null));
    this.filters.markAsDirty();
  }

  removeFilter(index: number): void {
    this.meta.splice(index, 1);
    this.filters.removeAt(index);
    this.filters.markAsDirty();
  }

  initializeFilters(): void {
    if (!this.filters.length) {
      this.addFilter();
    }
  }

  onFieldChanged(field: CandidateFilterField, index: number): void {
    this.meta[index].conditions = field?.conditions;
    this.meta[index].groupName = this.findGroupLabel(field);
    this.meta[index].options = null;
    this.filters.get([index, "value"]).reset();

    this.meta[index].dataSource = field.dataSources;

    const condition = this.getDefaultCondition(field?.conditions);
    this.filters.get([index, "condition"]).setValue(condition);
    this.onConditionChanged(condition, index);

    this.changeDetector.detectChanges();
  }

  onFieldToggled(node: TreeNode): void {
    node.expanded = !node.expanded;
    this.changeDetector.markForCheck();
  }

  onConditionChanged(name: string, index: number): void {
    const oldParameter = this.meta[index].parameter;

    const condition = this.meta[index].conditions?.find((x) => x.value === name);
    this.meta[index].options = condition?.options;
    this.meta[index].parameter = condition?.parameter;

    const valueControl = this.filters.get([index, "value"]);
    if (oldParameter !== this.meta[index].parameter) {
      valueControl.reset();
    }
    valueControl.setValidators(condition?.parameter ? Validators.required : Validators.nullValidator);
    valueControl.updateValueAndValidity();

    this.changeDetector.detectChanges();
  }

  hasMultipleDataSourceOptions(meta: CandidateFilterFieldMeta): boolean {
    return meta?.dataSource?.length > 1;
  }

  private getDefaultCondition(conditions: CandidateFilter[]): string {
    return ["elementEquals", "arrayEquals", "stringEquals", "equals", "dateEqualsToElement", "true"].find((name) =>
      conditions?.some((condition) => condition.value === name)
    );
  }

  private setFiltersFromEvent(): void {
    const filters = toPairs(this.filter ?? {}).flatMap(([key, filterMetaData]) => {
      const array = IsFilterMetaDataArray(filterMetaData) ? filterMetaData : [];
      return array.map((data) => this.createFilter(key, data));
    });

    this.meta = [];
    this.filters.clear({ emitEvent: false });

    for (const filter of filters) {
      const field = filter.field?.data;
      const condition = field?.conditions?.find((condition) => condition.value === filter.condition);
      const dataSource = field?.dataSources;
      this.meta.push({
        conditions: field?.conditions,
        options: condition?.options,
        parameter: condition?.parameter,
        groupName: this.findGroupLabel(field),
        dataSource: dataSource,
      });
      this.filters.push(this.formService.createFilterForm(filter), { emitEvent: false });
    }
    this.filters.updateValueAndValidity();
  }

  private findGroupLabel(field?: CandidateFilterField): string {
    return this.fields.find((treeNode) =>
      this.flattenNodes(treeNode.children).some((fieldNode) => fieldNode.data === field)
    )?.label;
  }

  private createFilter(key: string, data: CandidateFilterMetadata): Filter {
    const treeNode = this.flattenNodes(this.fields).find((node) => node.data.value === key);
    return { field: treeNode, value: data.value, condition: data.matchMode, dataSource: data.dataSource };
  }

  private flattenNodes(nodes: TreeNode<CandidateFilterField>[]): TreeNode<CandidateFilterField>[] {
    return nodes.flatMap((node) => (node.children ? this.flattenNodes(node.children) : [node]));
  }
}
