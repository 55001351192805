<ng-container *appTranslate="let t; lang: language + '|static'">
  <app-candidate-aligned-label
    [class]="useFieldLabel ? 'field' : ''"
    labelFor="recognitionPaymentRole"
    [labelText]="t('recognitionPaymentRole.title')"
    [useAlignmentCheckBox]="renderLabelUsingAlignmentCheckBox"
  ></app-candidate-aligned-label>

  <p-dropdown
    inputId="{{ formId }}-recognitionPaymentRole"
    [options]="roles$ | async"
    [formControl]="control"
    [showClear]="true"
    [placeholder]="t('recognitionPaymentRole.placeholder')"
    [readonly]="readonly"
  ></p-dropdown>

  <small class="p-error" *ngIf="control.errors?.required">
    {{ t("recognitionPaymentRole.required") }}
  </small>
</ng-container>
