<app-table
  *appTranslate="let t"
  [items]="contractTemplates"
  [columns]="columns"
  [title]="title ?? t('contractTemplate.title')"
  [newOperations]="newOperations"
  [footerOperations]="footerOperations"
  [tableOperations]="tableOperations"
  [(showSidebar)]="showDialog"
  [sidebarSize]="selectedTemplate ? 'full' : 'medium'"
>
  <ng-container *ngIf="showDialog">
    <app-contract-template-dialog
      *ngIf="selectedTemplate"
      [template]="selectedTemplate"
      [allContractTemplates]="contractTemplates"
      [isEdit]="isEdit"
      (saved)="save($event)"
      (closed)="closeSidebar()"
    ></app-contract-template-dialog>

    <app-import
      *ngIf="import"
      [import]="import"
      (saved)="importContractTemplates($event)"
      (closed)="closeSidebar()"
    ></app-import>

    <app-contract-conditions-dialog
      *ngIf="showSidebarContractConditions"
      [organizationId]="organizationId"
      [organizationName]="organizationName"
      (saved)="contractConditionsSaved()"
      (closed)="closeSidebar()"
    >
    </app-contract-conditions-dialog>
  </ng-container>
</app-table>

<app-import-result-dialog
  *ngIf="xlsxImportResult"
  [xlsxImportResult]="xlsxImportResult"
  (closed)="xlsxImportResult = null"
>
</app-import-result-dialog>
