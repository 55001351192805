<ng-container *appTranslate="let t; lang: language + '|static'">
  <p-card>
    <div style="height: calc(100% - 65px)">
      <p-table [value]="rows$ | async" scrollDirection="both" styleClass="p-datatable-sm p-bad-datatable">
        <ng-template pTemplate="header">
          <tr>
            <th pFrozenColumn [style]="firstCellStyle$ | async">
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" [formControl]="filterText" [placeholder]="t('common.search')" />
              </span>
            </th>

            <ng-container *ngFor="let candidate of candidates$ | async">
              <th class="flex flex-column align-items-start" [style]="candidateCellStyle$ | async">
                <app-candidate-flexible-edit-button
                  [candidateId]="candidate.candidateId"
                  [candidateOrganizationId]="candidate.candidateOrgId"
                  [label]="candidate.displayId"
                  [showFlag]="false"
                ></app-candidate-flexible-edit-button>
                <small class="clamp" [pTooltip]="candidate.displayName">{{ candidate.displayName }}</small>
              </th>
            </ng-container>
          </tr>

          <tr>
            <td pFrozenColumn [style]="firstCellStyle$ | async">
              <p-multiSelect
                [options]="vaccineFunctions$ | async"
                [placeholder]="t('requiredVaccines.vaccineFunction.title')"
                [showToggleAll]="true"
                [formControl]="selectedVaccineFunctions"
                class="vaccine-functions-select"
                styleClass="width: 100%;"
              ></p-multiSelect>
            </td>
            <ng-container *ngFor="let candidate of candidates$ | async">
              <div [style]="candidateCellStyle$ | async">
                <td *ngFor="let col of candidate.icons" [style]="iconCellStyle$ | async">
                  <i [class]="col.icon" [style.cursor]="'pointer'" [pTooltip]="col.tooltip"></i>
                </td>
              </div>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td pFrozenColumn [pTooltip]="row.vaccine" [style]="firstCellStyle$ | async">
              <span
                style="
                  display: -webkit-box;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                "
              >
                {{ row.vaccine }}
              </span>
            </td>
            <ng-container *ngFor="let colEntries of row.candidatesColumns">
              <div [style]="candidateCellStyle$ | async">
                <td *ngFor="let col of colEntries" [style]="iconCellStyle$ | async">
                  <i
                    *ngIf="col.icon"
                    [class]="col.icon"
                    [style.color]="col.color"
                    [style.cursor]="'pointer'"
                    [pTooltip]="col.tooltip"
                  ></i>
                </td>
              </div>
            </ng-container>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-card>
</ng-container>
