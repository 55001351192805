import { Component } from "@angular/core";
import { UserPermission, nameofFactory } from "@ankaadia/ankaadia-shared";
import {
  EducationModuleCreateInput,
  EducationModuleForEditFragment,
  EducationModuleForListFragment,
  EducationModuleStatus,
  EducationModuleType,
  EducationModuleUpdateInput,
  StaticDataType,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { isEmpty } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { BehaviorSubject, Observable, combineLatest, finalize, map } from "rxjs";
import { AppDecimalPipe } from "../../../shared/pipes/decimal.pipe";
import { EnumPipe } from "../../../shared/pipes/enum.pipe";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataPipe } from "../../../shared/static-data/static-data.pipe";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { PipeDescription, TableColumn, TableOperation } from "../../../shared/table/table.model";
import { MessageService } from "../../message/message.service";
import { EducationModuleService } from "./education-module.service";

const nameOf = nameofFactory<EducationModuleForListFragment>();

@Component({
  selector: "app-education-modules",
  templateUrl: "./education-modules.component.html",
  standalone: false,
})
export class EducationModulesComponent {
  private readonly allModules$ = this.educationModuleService.list(this.settings.organizationId);
  private readonly hasFullAccess = this.settings.hasAnyPermission([
    UserPermission.Administrator,
    UserPermission.CourseAdministrator,
  ]);

  private isBusy: boolean;

  readonly selectedStatuses$ = new BehaviorSubject<EducationModuleStatus[]>([EducationModuleStatus.Active]);
  readonly modules$ = this.getEducationModules();
  readonly statuses = this.staticDataService.transformEnumToStaticDataModel(
    "EducationModuleStatus",
    EducationModuleStatus
  );

  readonly columns = this.getColumns();
  readonly newOperations = this.getNewOperations();
  readonly tableOperations = this.getTableOperations();
  readonly selectedModule: { edit?: EducationModuleForEditFragment } = {};

  showSidebar: boolean;

  constructor(
    private readonly educationModuleService: EducationModuleService,
    private readonly settings: SettingsService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly staticDataService: StaticDataService
  ) {}

  add(): void {
    if (this.isBusy) return;
    this.selectedModule.edit = {
      id: null,
      organizationId: this.settings.organizationId,
      _etag: null,
      name: null,
      description: null,
      providerOrganizationId: null,
      type: EducationModuleType.LanguageLearning,
      languageLevel: null,
      status: EducationModuleStatus.Active,
      mode: null,
      units: null,
    };
    this.showSidebar = true;
  }

  edit(module: EducationModuleForListFragment): void {
    if (this.isBusy) return;
    this.isBusy = true;
    this.educationModuleService
      .edit(module.id, module.organizationId)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe((module) => {
        this.selectedModule.edit = module;
        this.showSidebar = true;
      });
  }

  delete(module: EducationModuleForListFragment, event: Event): void {
    if (this.isBusy) return;
    this.confirmationService.confirm({
      target: event.target,
      message: translate("educationModule.confirmDelete", module),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () =>
        this.educationModuleService.delete(module).subscribe(() => {
          this.messageService.add({
            severity: "success",
            summary: translate("educationModule.deleted.title"),
            detail: translate("educationModule.deleted.message", module),
          });
        }),
    });
  }

  create(module: EducationModuleCreateInput): void {
    if (this.isBusy) return;
    this.isBusy = true;
    this.educationModuleService
      .create(module)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe((module) => {
        this.messageService.add({
          severity: "success",
          summary: translate("educationModule.created.title"),
          detail: translate("educationModule.created.message", module),
        });
        this.close();
      });
  }

  update(module: EducationModuleUpdateInput): void {
    if (this.isBusy) return;
    this.isBusy = true;
    this.educationModuleService
      .update(module)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe((module) => {
        this.messageService.add({
          severity: "success",
          summary: translate("educationModule.updated.title"),
          detail: translate("educationModule.updated.message", module),
        });
        this.close();
      });
  }

  close(): void {
    this.selectedModule.edit = null;
    this.showSidebar = false;
  }

  private getEducationModules(): Observable<EducationModuleForListFragment[]> {
    return combineLatest([this.allModules$, this.selectedStatuses$]).pipe(
      map(([modules, statuses]) => modules.filter((x) => isEmpty(statuses) || statuses.includes(x.status)))
    );
  }

  private getColumns(): TableColumn[] {
    return [
      {
        header: translate("name.title"),
        fieldname: nameOf("name"),
        sortable: true,
        includeInGlobalFilter: true,
      },
      {
        header: translate("description.title"),
        fieldname: nameOf("description"),
        sortable: true,
        includeInGlobalFilter: true,
      },
      {
        header: translate("status.title"),
        fieldname: nameOf("status"),
        sortable: true,
        includeInGlobalFilter: true,
        pipeDescription: new PipeDescription(EnumPipe, "EducationModuleStatus"),
      },
      {
        header: translate("type.title"),
        fieldname: nameOf("type"),
        sortable: true,
        includeInGlobalFilter: true,
        pipeDescription: new PipeDescription(EnumPipe, "EducationModuleType"),
      },
      {
        header: translate("skillLevel.title"),
        fieldname: nameOf("languageLevel"),
        sortable: true,
        includeInGlobalFilter: true,
        pipeDescription: new PipeDescription(StaticDataPipe, StaticDataType.LanguageModules),
      },
      {
        header: translate("educationUnits.title"),
        fieldname: nameOf("units"),
        sortable: true,
        includeInGlobalFilter: true,
        pipeDescription: new PipeDescription(AppDecimalPipe, {
          minimumIntegerDigits: 1,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      },
      {
        header: translate("educationMode.title"),
        fieldname: nameOf("mode"),
        sortable: true,
        includeInGlobalFilter: true,
        pipeDescription: new PipeDescription(EnumPipe, "EducationMode"),
      },
    ];
  }

  private getNewOperations(): TableOperation[] {
    return [
      {
        label: translate("common.new"),
        icon: PrimeIcons.PLUS,
        operation: (): void => this.add(),
        disabled: (): boolean => !this.hasFullAccess,
      },
    ];
  }

  private getTableOperations(): TableOperation[] {
    return [
      {
        label: translate("common.edit"),
        icon: PrimeIcons.PENCIL,
        operation: (x: EducationModuleForListFragment): void => this.edit(x),
        disabled: (): boolean => !this.hasFullAccess,
      },
      {
        label: translate("common.delete"),
        icon: PrimeIcons.TRASH,
        operation: (x: EducationModuleForListFragment, e: Event): void => this.delete(x, e),
        disabled: (): boolean => !this.hasFullAccess,
      },
    ];
  }
}
