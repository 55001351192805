import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  Candidate,
  CandidateSystemOnboarding,
  MatchingStatus,
  MessagesReception,
  ProfileDataEditStatus,
} from "@ankaadia/graphql";

import { Language } from "@ankaadia/ankaadia-shared";
import { TranslocoService, translate } from "@jsverse/transloco";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { Observable, finalize } from "rxjs";
import { MessageDialogService } from "../../../shared/message-dialog/message-dialog.service";
import { CandidatesService } from "../../candidates/candidates.service";
import { MessageService } from "../../message/message.service";
import { UsersService } from "../../users/users.service";
import { GovernanceForm } from "../candidate-form.model";

@Component({
  selector: "app-candidate-governance",
  templateUrl: "./candidate-governance.component.html",
  standalone: false,
})
export class CandidateGovernanceComponent implements OnInit {
  readonly locale = this.transLoco.getActiveLang();

  readonly matchingStates = [
    { label: translate("enum.MatchingStatus.Enabled"), value: MatchingStatus.Enabled },
    { label: translate("enum.MatchingStatus.Viewable"), value: MatchingStatus.Viewable },
    { label: translate("enum.MatchingStatus.Disabled"), value: MatchingStatus.Disabled },
  ];

  readonly profileDataEditStatus = [
    {
      label: translate("enum.ProfileDataEditStatus.Default"),
      value: ProfileDataEditStatus.Default,
    },
    {
      label: translate("enum.ProfileDataEditStatus.ReadOnly"),
      value: ProfileDataEditStatus.ReadOnly,
    },
    {
      label: translate("enum.ProfileDataEditStatus.Editable"),
      value: ProfileDataEditStatus.Editable,
    },
  ];

  @Input()
  form: GovernanceForm;

  @Input({ required: true })
  candidate: Candidate;

  @Input()
  readonly: boolean;

  @Input()
  candidateId: string;

  @Input()
  candidateOrganizationId: string;

  @Input()
  systemOnboarding: CandidateSystemOnboarding;

  @Input()
  lastLoginDate: Date;

  @Input()
  messagesReception: MessagesReception;

  @Input()
  isMultiEditMode: boolean;

  @Input()
  buttonsDisabled: boolean;

  @Output()
  readonly invited = new EventEmitter<void>();

  @Output()
  readonly toggled = new EventEmitter<boolean>();

  @Output()
  readonly multiInvite = new EventEmitter<void>();

  @Output()
  readonly multiUninvite = new EventEmitter<void>();

  buttonDisabled: boolean;
  availableLanguages$: Observable<Language[]>;

  constructor(
    private readonly candidateService: CandidatesService,
    private readonly messageService: MessageService,
    private readonly transLoco: TranslocoService,
    private readonly confirmationService: ConfirmationService,
    private readonly errorService: MessageDialogService,
    private readonly userSvc: UsersService
  ) {}

  ngOnInit(): void {
    this.availableLanguages$ = this.userSvc.getAvailableCommunicationLanguagesTranslated(
      true,
      this.locale,
      this.candidateOrganizationId
    );
  }

  blockClick(): void {
    this.buttonDisabled = true;
    this.candidateService
      .blockUnBlockCandidate(this.candidateId, this.candidateOrganizationId, !this.systemOnboarding.isBlocked)
      .pipe(finalize(() => (this.buttonDisabled = false)))
      .subscribe((x) => {
        if (x) {
          this.systemOnboarding = x;
          this.messageService.add({
            severity: "success",
            summary: x.isBlocked ? translate("candidate.blocked.title") : translate("candidate.unblocked.title"),
            detail: x.isBlocked ? translate("candidate.blocked.message") : translate("candidate.unblocked.message"),
          });
        }
      });
  }

  unBoardClick(): void {
    if (this.isMultiEditMode) {
      this.multiUninvite.emit();
    } else {
      this.buttonDisabled = true;
      this.candidateService
        .unInviteCandidate(this.candidateId, this.candidateOrganizationId)
        .pipe(finalize(() => (this.buttonDisabled = false)))
        .subscribe((x) => {
          if (x) {
            this.systemOnboarding = x;
            this.messageService.add({
              severity: "success",
              summary: translate("candidate.unboarded.title"),
              detail: translate("candidate.unboarded.message"),
            });
          }
        });
    }
  }

  onInviteClick(): void {
    if (this.isMultiEditMode) {
      this.multiInvite.emit();
    } else {
      this.buttonDisabled = true;
      this.candidateService
        .inviteCandidate(this.candidateId, this.candidateOrganizationId)
        .pipe(finalize(() => (this.buttonDisabled = false)))
        .subscribe((x) => {
          if (x) {
            this.systemOnboarding = x;
            this.invited.emit();
            this.messageService.add({
              severity: "success",
              summary: translate("candidate.invited.title"),
              detail: translate("candidate.invited.message"),
            });
          } else {
            this.errorService.showMessage(translate("user.badInput.title"), translate("candidate.invitedButNoEmail"));
          }
        });
    }
  }

  onPasswordResetClick(event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("password.confirmReset"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.buttonDisabled = true;
        this.candidateService
          .resetCandidatePassword(this.candidateId, this.candidateOrganizationId)
          .pipe(finalize(() => (this.buttonDisabled = false)))
          .subscribe((x) => {
            if (x) {
              this.systemOnboarding = x;
              this.messageService.add({ severity: "success", summary: translate("password.resetSuccess") });
            }
          });
      },
    });
  }
}
