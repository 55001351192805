import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Activity, Sharing } from "@ankaadia/graphql";
import { cloneDeep } from "lodash";
import { TableLazyLoadEvent } from "primeng/table";
import { finalize } from "rxjs";
import { ActivityService } from "../../activity/activity.service";

@Component({
  selector: "app-sharing-activities",
  templateUrl: "./sharing-activities.component.html",
  standalone: false,
})
export class SharingActivitiesComponent implements OnChanges {
  private filter: TableLazyLoadEvent;
  private reachedEnd: boolean;

  @Input()
  sharing: Sharing;

  @Output()
  readonly closed = new EventEmitter<void>();

  isLoading: boolean;
  activities: Activity[];
  totalActivities = 0;

  constructor(private readonly activityService: ActivityService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sharing) {
      this.load(changes.sharing.currentValue);
    }
  }

  onPageChange(filter: TableLazyLoadEvent): void {
    this.filter = filter;
    this.load(this.sharing);
  }

  private load(sharing: Sharing): void {
    if (!this.filter || !sharing) {
      return;
    }

    this.isLoading = true;
    this.activityService
      .getSharingActivities(sharing.id, this.filter)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((nodes) => {
        this.activities = cloneDeep(nodes);
        this.updateTotalActivities(nodes, this.filter);
      });
  }

  /** See {@link CandidateActivitiesComponent.updateTotalActivities}. */
  private updateTotalActivities(nodes: Activity[], filter: TableLazyLoadEvent): void {
    if (this.reachedEnd) {
      return;
    }

    if (nodes.length < filter.rows) {
      this.reachedEnd = true;
      this.totalActivities = filter.first + nodes.length;
    } else {
      this.totalActivities = Math.max(this.totalActivities, filter.first + 2 * filter.rows);
    }
  }
}
