<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <label for="endOfFurtherEducation">
    {{
      immigrationCountry === SupportedImmigrationCountry.At
        ? t("endOfComplementaryMeasure.title")
        : t("endOfFurtherEducation.title")
    }}
  </label>
  <p-calendar inputId="endOfFurtherEducation" [formControl]="control" [readonly]="readonly"></p-calendar>

  <small class="p-error" *ngIf="control.errors?.required">
    {{ t("endOfFurtherEducation.required") }}
  </small>
</ng-container>
