<ng-container *appTranslate="let t">
  @if (form()) {
    <ng-container [formGroup]="form()">
      <div class="p-fluid grid">
        <div class="field col-12 md:col-6">
          <label for="paymentStartDate" class="pl-2">{{ t("billing.paymentSettings.startDate.title") }}</label>
          <app-contract-date
            id="paymentStartDate"
            formControlName="startDate"></app-contract-date>
          <small class="p-error" *ngIf="form().get('startDate').errors?.required">
            {{ t("billing.paymentSettings.startDate.required") }}
          </small>
          <small class="p-error" *ngIf="form().get('startDate').errors?.sameOrAfter">
            {{ t("billing.paymentSettings.startDate.sameOrAfter") }}
          </small>
        </div>
        <div class="field col-12 md:col-6">
          <label for="period" class="pl-2">{{ t("billing.paymentSettings.paymentPeriod.title") }}</label>
          <p-dropdown
            id="period"
            formControlName="period"
            [options]="paymentPeriods"></p-dropdown>
          <small class="p-error" *ngIf="form().get('period').errors?.required">
            {{ t("billing.paymentSettings.paymentPeriod.required") }}
          </small>
        </div>
        <div class="field col-12 md:col-6">
          <label for="paymentTime" class="pl-2">{{ t("billing.paymentSettings.paymentTime.title") }}</label>
          <p-dropdown
            id="paymentTime"
            formControlName="paymentTime"
            [options]="paymentTimes"></p-dropdown>
          <small class="p-error" *ngIf="form().get('paymentTime').errors?.required">
            {{ t("billing.paymentSettings.paymentTime.required") }}
          </small>
        </div>
      </div>
      @if (form().get("period").value === PaymentPeriod["12M"]) {
        <p-fieldset [legend]="t('billing.paymentSettings.paymentTranches.title')">
          <small class="p-error" *ngIf="form().get('paymentTranches').errors?.sumPaymentTranches">
            {{ t("billing.paymentSettings.paymentTranches.sumPaymentTranches") }}
          </small>
          <p-table formArrayName="paymentTranches" [value]="paymentTranches().controls">
            <ng-template pTemplate="emptymessage">
              <td colspan="3" class="p-2">
                {{ t('billing.paymentSettings.paymentTranches.emptyMessage') }}
              </td>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>{{ t("billing.paymentSettings.paymentTranche.dueDate.title") }}</th>
                <th>{{ t("billing.paymentSettings.paymentTranche.percentage.title") }}</th>
                <th class="justify-content-end text-right" style="width: 8rem">
                  <button
                    pButton
                    type="button"
                    icon="pi pi-plus"
                    class="p-button-rounded p-button-text"
                    (click)="addPaymentTranche(); showDialog = true"
                  ></button>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-paymentTranche let-i="rowIndex">
              <tr [formGroupName]="i">
                <td>{{ formatDueDate(paymentTranche.value.dueDate) }}</td>
                <td>

                  {{
                    paymentTranche.value.percentage | translocoDecimal : {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 0
                    }
                  }} %
                </td>
                <td style="text-align: center">
                  <button
                    pButton
                    type="button"
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-text"
                    (click)="editPaymentTranche(paymentTranche); showDialog = true"
                  ></button>
                  <button
                    pButton
                    type="button"
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-text p-button-danger ml-2"
                    (click)="removePaymentTrancheAt(i)"
                  ></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <p-dialog
            [style]="{ width: '600px' }"
            [(visible)]="showDialog"
            [header]="targetRow === null ? t('billing.paymentSettings.paymentTranche.create.title') : t('billing.paymentSettings.paymentTranche.edit.title')"
          >
            <ng-template pTemplate>
              <div class="p-fluid" *ngIf="editRow" [formGroup]="editRow">
                <div class="field">
                  <label for="dueDate"
                         class="pl-2">{{ t("billing.paymentSettings.paymentTranche.dueDate.title") }}</label>
                  <app-contract-day-of-year
                    id="dueDate"
                    formControlName="dueDate"
                  ></app-contract-day-of-year>
                  <small class="p-error" *ngIf="editRow.get('dueDate').errors?.required">
                    {{ t("billing.paymentSettings.paymentTranche.dueDate.required") }}
                  </small>
                </div>
                <div class="field">
                  <label for="percentage"
                         class="pl-2">{{ t("billing.paymentSettings.paymentTranche.percentage.title") }}</label>
                  <p-inputNumber
                    [min]="0"
                    [max]="100"
                    [maxFractionDigits]="2"
                    mode="decimal"
                    inputId="percentage"
                    formControlName="percentage"
                  ></p-inputNumber>
                  <small class="p-error" *ngIf="editRow.get('percentage').errors?.required">
                    {{ t("billing.paymentSettings.paymentTranche.percentage.required") }}
                  </small>
                </div>
                <div class="flex flex-row justify-content-between mt-3">
                  <p-button
                    [disabled]="!editRow.valid || !editRow.dirty"
                    [label]="targetRow != null ? t('common.save') : t('common.add')"
                    icon="pi pi-check"
                    type="button"
                    (onClick)="savePaymentTranche()"
                  ></p-button>
                  <p-button
                    closeOnEscape
                    [label]="t('common.cancel')"
                    icon="pi pi-times"
                    class="p-button-secondary"
                    type="button"
                    (onClick)="endEdit()"
                  ></p-button>
                </div>
              </div>
            </ng-template>
          </p-dialog>
        </p-fieldset>
      }
    </ng-container>
  }
</ng-container>
