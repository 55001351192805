<p-selectButton
  *ngIf="languages?.length > 1"
  [options]="languages"
  [ngModel]="selectedLanguage"
  [unselectable]="true"
  (ngModelChange)="selectedLanguageChange.emit($event)"
>
  <ng-template let-language pTemplate>
    <span class="flag flag-{{ flags[language.value] }}" [pTooltip]="language.label" tooltipPosition="left"></span>
  </ng-template>
</p-selectButton>
