<ng-container *appTranslate="let t">
  <p-treeSelect
    [inputId]="inputId"
    [placeholder]="t('candidateTags.placeholder')"
    [options]="descriptions$ | async"
    [disabled]="disabled || readonly"
    [filter]="true"
    [showClear]="true"
    [metaKeySelection]="false"
    [propagateSelectionUp]="false"
    [propagateSelectionDown]="false"
    [containerStyleClass]="styleClass"
    panelStyleClass="app-treeselect-panel"
    scrollHeight="200px"
    selectionMode="checkbox"
    display="chip"
    [ngModel]="selectedNodes"
    (onNodeSelect)="select($event.node)"
    (onNodeUnselect)="unselect($event.node)"
    (toggleClick)="toggle($event.node)"
    (onClear)="clear()"
  >
    <ng-template pTemplate="value" let-nodes let-placeholder="placeholder">
      <ng-container *ngIf="!nodes?.length">
        {{ placeholder }}
      </ng-container>

      <div *ngFor="let node of nodes" class="p-treeselect-token" [ngClass]="CandidateTagColors[node.data.color].tag">
        <span class="p-treeselect-token-label font-bold">
          {{ node.label }}
        </span>

        <p-button
          styleClass="p-treeselect-token-icon p-button-rounded p-button-text z-1"
          icon="pi pi-palette"
          (onClick)="recolorNode = node; menu.show($event); $event.stopPropagation()"
        ></p-button>

        <p-button
          styleClass="p-treeselect-token-icon p-button-rounded p-button-text z-0"
          icon="pi pi-trash"
          (onClick)="unselect(node); $event.stopPropagation()"
        ></p-button>
      </div>
    </ng-template>
  </p-treeSelect>

  <p-menu #menu [model]="colors" [popup]="true" styleClass="app-menu-horizontal"></p-menu>
</ng-container>
