import { AfterViewInit, ChangeDetectorRef, Directive, Input, OnChanges, SimpleChanges, computed } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { Dropdown } from "primeng/dropdown";

type SharableDropdown = Dropdown & {
  oldPlaceHolder: string;
  oldOptionLabel: string;
  oldShowClear: boolean;
};

@Directive({ selector: "[appCandidateFieldNotShared]", standalone: false })
export class CandidateFieldNotSharedDirective implements OnChanges, AfterViewInit {
  @Input({ required: true })
  isShared = true;

  @Input({ required: true })
  readonly = false;

  @Input()
  placeholder; // to ensure onChanges is triggered

  private readonly dummyOptionLabel = "dummyOptionLabel";

  readonly getLabel = (dropdown: any, shared: boolean): any =>
    computed(() => {
      const options = dropdown.getAllVisibleAndNonVisibleOptions();
      const selectedOptionIndex = options.findIndex((option) => dropdown.isOptionValueEqualsModelValue(option));
      return selectedOptionIndex !== -1 && shared
        ? dropdown.getOptionLabel(options[selectedOptionIndex])
        : dropdown.placeholder() || "p-emptylabel";
    });

  constructor(
    private readonly dropdown: Dropdown,
    private readonly transloco: TranslocoService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.markFields();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.readonly) {
      this.dropdown.readonly = this.readonly;
    }

    if (changes.placeholder) {
      this.dropdown.placeholder = this.placeholder;
    }
    this.markFields();
  }

  private markFields(): void {
    if (!this.isShared) {
      this.markDropDownAsNotShared(this.dropdown as SharableDropdown);
    } else {
      this.markDropDownAsShared(this.dropdown as SharableDropdown);
    }
    this.changeDetectorRef.detectChanges();
  }

  private markDropDownAsNotShared(dropdown: SharableDropdown): void {
    const notSharedPlaceHolder = this.transloco.translate("fieldNotShared");

    //save current values to restore them when the field is marked as shared again
    if (!dropdown.oldPlaceHolder && dropdown.placeholder() !== notSharedPlaceHolder)
      dropdown.oldPlaceHolder = dropdown.placeholder();
    if (!dropdown.oldOptionLabel && dropdown.optionLabel !== this.dummyOptionLabel)
      dropdown.oldOptionLabel = dropdown.optionLabel;
    if (!dropdown.oldShowClear) dropdown.oldShowClear = dropdown.showClear;

    dropdown.placeholder = notSharedPlaceHolder;
    dropdown.optionLabel = this.dummyOptionLabel;
    this.dropdown.label = this.getLabel(this.dropdown, false);

    dropdown.showClear = false;
    dropdown.readonly = true;
  }

  private markDropDownAsShared(dropdown: SharableDropdown): void {
    this.dropdown.label = this.getLabel(this.dropdown, true);
    if (dropdown.optionLabel !== this.dummyOptionLabel) return;

    dropdown.placeholder = dropdown.oldPlaceHolder;
    dropdown.oldPlaceHolder = undefined;

    dropdown.optionLabel = dropdown.oldOptionLabel;
    dropdown.oldOptionLabel = undefined;

    dropdown.showClear = dropdown.oldShowClear;
    dropdown.oldShowClear = undefined;

    dropdown.readonly = this.readonly;
  }
}
