import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import {
  AfterRecognition,
  BeforeRecognition,
  CascadedOrganizationFragment,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { DropdownChangeEvent } from "primeng/dropdown";
import { OrganizationsService } from "../../organizations/organizations.service";
import { CandidateEmployerOrganizationService } from "./candidate-employer-organization.service";

const nameOf = nameofFactory<BeforeRecognition | AfterRecognition>();

@Component({
  selector: "app-candidate-employer-organization",
  templateUrl: "./candidate-employer-organization.component.html",
  standalone: false,
})
export class CandidateEmployerOrganizationComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  formId: string;

  @Input()
  form: FormGroup;

  @Input()
  readonly: boolean;

  @Input()
  showControl: boolean;

  @Input()
  modelData: { id: string; name: string };

  @Input()
  candidateOrgId: string;

  @Input()
  organizationId: string;

  @Input()
  immigrationCountry: SupportedImmigrationCountry;

  @Input()
  isFieldShared = true;

  @Output()
  readonly changes = new EventEmitter<string>();

  protected readonly language = this.transloco.getActiveLang();

  constructor(
    private readonly transloco: TranslocoService,
    private readonly employerService: CandidateEmployerOrganizationService,
    private readonly organizationService: OrganizationsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateOrgId && this.candidateOrgId && this.organizationId) {
      this.organizationService
        .getCascadedLinkedOrganizationsFromSourceOrganization(
          this.candidateOrgId,
          this.organizationId,
          this.immigrationCountry
        )
        .subscribe((employers) => (this.employerService.employers = employers));
    }
  }

  get employers(): CascadedOrganizationFragment[] {
    return this.employerService.employers;
  }

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<string>(undefined);
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("employerId"), { emitEvent: false });
  }

  get control(): FormControl<string> {
    return this.form.get(nameOf("employerId")) as FormControl<string>;
  }

  set control(value: FormControl<string>) {
    this.form.setControl(nameOf("employerId"), value);
  }

  onEmployerChange($event: DropdownChangeEvent): void {
    this.changes.emit($event.value);
  }
}
