import { Injectable } from "@angular/core";
import {
  AlwaysRequiredCandidateFields,
  Property,
  RequiredCandidateFieldsExclusionList,
} from "@ankaadia/ankaadia-shared";
import { Candidate } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { flatMapDeep } from "lodash";
import { map, Observable } from "rxjs";
import { OrganizationFactoryService } from "../../../organization-specific/organization-specific-factory.service";
import { SettingsService } from "../../../shared/services/settings.service";

export interface CandidateFieldModel {
  label: string;
  value: Property<Candidate>;
  disabled?: boolean;
}

@Injectable({ providedIn: "root" })
export class CandidateFieldService {
  constructor(
    private readonly settings: SettingsService,
    private readonly specificsFactory: OrganizationFactoryService
  ) {}

  getCandidateFields(): Observable<CandidateFieldModel[]> {
    return this.specificsFactory.getOrganizationSpecifics(this.settings.organizationId).pipe(
      map((specifics) => {
        const form = specifics.getCandidateProfileFormlyFields();
        return [...this.getGeneralFields(form), ...this.getInternalFields()];
      })
    );
  }

  private getGeneralFields(form: FormlyFieldConfig[]): CandidateFieldModel[] {
    const ignored: Property<Candidate> = "os.profile.addresses";
    const general = form[0].fieldGroup.find((x) => x.id === "general").fieldGroup;
    return flatMapDeep(general, (x) => (ignored.includes(<Property<Candidate>>x.key) ? [] : x.fieldGroup))
      .filter((x) => !RequiredCandidateFieldsExclusionList.includes(`os.profile.${x.key}`))
      .map((x) => ({
        label: x.props.label,
        value: `os.profile.${x.key}`,
        disabled: AlwaysRequiredCandidateFields.includes(`os.profile.${x.key}`),
      }));
  }

  private getInternalFields(): CandidateFieldModel[] {
    return [
      { label: translate("costCenter.title"), value: "internal.costCenter" },
      { label: translate("partner.languageSchool"), value: "internal.languageSchooldId" },
      { label: translate("partner.source"), value: "internal.sourceOrganizationId" },
      { label: translate("partner.category"), value: "internal.category" },
      { label: translate("candidate.notesFull"), value: "internal.notes" },
    ];
  }
}
