import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { catchError, map, Observable, of } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { OrganizationsService } from "../../organizations/organizations.service";

@Component({
  selector: "app-organization-contact-page",
  templateUrl: "./organization-contact-page.component.html",
  standalone: false,
})
export class OrganizationContactPageComponent implements OnInit {
  organizationId: string;
  organizationName: Observable<string>;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly organizationService: OrganizationsService,
    private readonly settings: SettingsService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((x) => {
      this.organizationId = x.get("orgId");
      this.organizationName = this.organizationService.getOrganization(this.organizationId).pipe(
        map((x) => x.name),
        catchError((x) => {
          this.settings.navigateToNotFound();
          return of(x);
        })
      );
    });
  }
}
