<ng-container *appTranslate="let t">
  <form [formGroup]="form">
    <div class="p-fluid formgrid grid">
      <div class="field col-12 lg:col-3">
        <div class="field-checkbox hidden lg:block">
          <p-checkbox class="alignment-checkbox"></p-checkbox>
          <label>&nbsp;</label>
        </div>
        <div class="field-checkbox">
          <p-checkbox
            inputId="{{ formId }}-isCurrent"
            [binary]="true"
            formControlName="isCurrent"
            [readonly]="readonly"
          ></p-checkbox>
          <label for="{{ formId }}-isCurrent">{{ t("activeRecognitionPath.title") }}</label>
        </div>
      </div>
    </div>
    <app-candidate-responsible-representative-group
      [form]="form"
      [readonly]="readonly || !form.controls.isCurrent?.value"
      [candidateOrganizationId]="candidateOrganizationId"
      [candidateId]="candidateId"
      [canHaveADeputyOrRepresentative]="canHaveADeputyOrRepresentative"
      [responsibleRolePurpose]="ResponsiblePurpose.Recognition"
      [purposeData]="recognitionPath"
      [alwaysShownRoles]="responsibleRoles"
      styleRoleClass="field col-12 lg:col-3"
      styleOrganizationClass="field col-12 lg:col-3"
      styleRepresentativeClass="field col-12 lg:col-3"
      styleDeputyClass="field col-12 lg:col-3"
      class="p-fluid formgrid grid"
    ></app-candidate-responsible-representative-group>

    <div class="p-fluid formgrid grid">
      <div class="field col-12 lg:col-3 pt-5">
        <div class="field-checkbox pt-2">
          <p-checkbox
            inputId="{{ formId }}-recognitionPartnership"
            [binary]="true"
            formControlName="recognitionPartnership"
            [readonly]="readonly || !form.controls.isCurrent?.value"
          ></p-checkbox>
          <label for="{{ formId }}-recognitionPartnership">{{ t("recognitionPartnership.title") }}</label>
        </div>
      </div>
      <div class="field col-12 lg:col-3">
        <app-candidate-federal-state
          [form]="form"
          [staticDataContext]="staticDataContext"
          [readonly]="readonly || !form.controls.isCurrent?.value"
          (userChangedValue)="federalStateChanged.emit($event)"
          class="field"
        ></app-candidate-federal-state>
      </div>

      <div class="field col-12 lg:col-3" *ngIf="isRegulatedProfession">
        <app-candidate-recognition-authority
          [form]="form"
          [federalStateControl]="federalState?.control"
          [staticDataContext]="staticDataContext"
          [readonly]="readonly || !form.controls.isCurrent?.value"
          [enableOnDestroy]="false"
          class="field"
        ></app-candidate-recognition-authority>
      </div>

      <div class="field col-12 lg:col-3" *ngIf="!isRegulatedProfession">
        <app-candidate-recognition-center
          [form]="form"
          [federalStateControl]="federalState?.control"
          [staticDataContext]="staticDataContext"
          [readonly]="readonly || !form.controls.isCurrent?.value"
          [enableOnDestroy]="false"
          class="field"
        ></app-candidate-recognition-center>
      </div>
      <div class="field col-12 lg:col-3" *ngIf="!isRegulatedProfession && isHwk()">
        <app-candidate-chamber-of-crafts
          [form]="form"
          [federalStateControl]="federalState?.control"
          [readonly]="readonly || !form.controls.isCurrent?.value"
          [enableOnDestroy]="false"
          class="field"
        >
        </app-candidate-chamber-of-crafts>
      </div>

      <div class="field col-12 lg:col-3">
        <app-candidate-recognition-type
          [form]="form"
          [readonly]="readonly"
          [staticDataContext]="staticDataContext"
          (userChangedValue)="recognitionTypeChanged.emit($event)"
          class="field"
        ></app-candidate-recognition-type>
      </div>

      <div class="field col-12 lg:col-3">
        <app-candidate-equivalence-test
          [form]="form"
          class="field"
          [renderLabelUsingAlignmentCheckBox]="true"
          [readonly]="readonly"
        ></app-candidate-equivalence-test>
      </div>

      <div class="field col-12 lg:col-3">
        <app-candidate-path-of-recognition
          [form]="form"
          [staticDataContext]="staticDataContext"
          class="field"
          [renderLabelUsingAlignmentCheckBox]="true"
          [readonly]="readonly"
        ></app-candidate-path-of-recognition>
      </div>

      <div class="field col-12 lg:col-3">
        <app-candidate-immigration-authority
          [form]="form"
          [federalState]="federalState?.control"
          [federalStateManuallyChanged]="federalStateChanged"
          [recognitionType]="recognitionType?.control"
          [recognitionTypeManuallyChanged]="recognitionTypeChanged"
          [staticDataContext]="staticDataContext"
          class="field"
          [renderLabelUsingAlignmentCheckBox]="true"
          [readonly]="readonly"
        ></app-candidate-immigration-authority>
      </div>

      <div class="field col-12 lg:col-3">
        <div class="field-checkbox">
          <p-checkbox
            inputId="{{ formId }}-recognitionStarted"
            [binary]="true"
            [readonly]="readonly"
            formControlName="recognitionStarted"
          ></p-checkbox>
          <label for="{{ formId }}-recognitionStarted">{{ t("recognitionStarted.title") }}</label>
        </div>

        <p-calendar formControlName="recognitionStartDate" [maxDate]="today" [readonly]="readonly"></p-calendar>
      </div>

      <app-candidate-document-date
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
        [disabled]="!form.controls.isCurrent?.value"
        [label]="t('recognition.application.arrived')"
        documentType="CONFACCEPTAGENCY"
        [selectedSet]="{ documentSetType }"
        dateField="issueDate"
        class="field col-12 lg:col-6"
      ></app-candidate-document-date>

      <app-candidate-document-date
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
        [disabled]="!form.controls.isCurrent?.value"
        [label]="t('immigration.application.arrived')"
        documentType="CONFIMMIGRAGENCY"
        [selectedSet]="{ documentSetType }"
        dateField="issueDate"
        class="field col-12 lg:col-6"
      ></app-candidate-document-date>

      <div class="field col-12 lg:col-3">
        <app-candidate-recognition-file-number
          [form]="form"
          [readonly]="readonly"
        ></app-candidate-recognition-file-number>
      </div>

      <div class="field col-12 lg:col-6">
        <app-candidate-recognition-file-number-accelerated-procedure
          [form]="form"
          [readonly]="readonly"
        ></app-candidate-recognition-file-number-accelerated-procedure>
      </div>

      <div class="field col-12 lg:col-3 field-with-info-icon">
        <div class="field-checkbox">
          <p-checkbox class="alignment-checkbox"></p-checkbox>
          <label for="{{ formId }}-recognitionNoticeKind">{{ t("recognitionNoticeKind.title") }}</label>
        </div>

        <p-dropdown
          inputId="{{ formId }}-recognitionNoticeKind"
          [options]="determinationNotices$ | async"
          formControlName="recognitionNoticeKind"
          [showClear]="true"
          [placeholder]="t('recognitionNoticeKind.placeholder')"
          [readonly]="readonly"
        ></p-dropdown>

        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-info-circle"
          class="p-button-rounded p-button-text info-icon"
          (click)="displayHint = true"
        ></button>
      </div>

      <app-candidate-document-date
        *ngIf="
          !form.controls.recognitionNoticeKind?.value ||
          form.controls.recognitionNoticeKind?.value === 'FULLRECOGNITIONNOTICE'
        "
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
        [disabled]="!form.controls.isCurrent?.value || !form.controls.recognitionNoticeKind?.value"
        [label]="t('recognitionReceived.title')"
        documentType="RECDOC"
        [selectedSet]="{ documentSetType }"
        dateField="issueDate"
        class="field col-12 lg:col-3"
      ></app-candidate-document-date>

      <app-candidate-document-date
        *ngIf="form.controls.recognitionNoticeKind?.value === 'DEFICITNOTICE'"
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
        [disabled]="!form.controls.isCurrent?.value"
        [label]="t('deficitNoteReceived.title')"
        documentType="DEFICITNOTE"
        [selectedSet]="{ documentSetType }"
        dateField="issueDate"
        class="field col-12 lg:col-3"
      ></app-candidate-document-date>

      <app-candidate-document-date
        *ngIf="form.controls.recognitionNoticeKind?.value === 'REJECTIONNOTICE'"
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
        [disabled]="!form.controls.isCurrent?.value"
        [label]="t('recognitionRejectionReceived.shortTitle')"
        documentType="REJECTREC"
        [selectedSet]="{ documentSetType }"
        dateField="issueDate"
        class="field col-12 lg:col-3"
      ></app-candidate-document-date>

      <p-dialog
        [header]="t('common.hint')"
        [(visible)]="displayHint"
        [modal]="true"
        [style]="{ width: '30vw' }"
        [draggable]="false"
        [resizable]="false"
      >
        <p class="m-0">{{ t("recognitionNoticeKind.info") }}</p>
        <ng-template pTemplate="footer">
          <p-button icon="pi pi-check" (click)="displayHint = false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
      </p-dialog>

      <div class="field col-12 lg:col-3">
        <div class="field-checkbox">
          <p-checkbox
            inputId="{{ formId }}-certificateRequested"
            [binary]="true"
            [readonly]="readonly"
            formControlName="certificateRequested"
          ></p-checkbox>
          <label for="{{ formId }}-certificateRequested">{{ t("professionalCertificateRequested.title") }}</label>
        </div>

        <p-calendar formControlName="certificateRequestDate" [maxDate]="today" [readonly]="readonly"></p-calendar>
      </div>

      <app-candidate-document-date
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
        [disabled]="!form.controls.isCurrent?.value"
        [label]="t('professionalCertificateReceived.title')"
        documentType="ACCEPTDOC"
        [selectedSet]="{ documentSetType }"
        dateField="dateOfReceipt"
        class="field col-12 lg:col-3"
      ></app-candidate-document-date>

      <div class="field col-12 lg:col-6">
        <app-candidate-recognition-payment-role
          [formId]="formId"
          [form]="form"
          [useFieldLabel]="true"
          [enabled]="form.controls?.isCurrent?.value && !!recognitionType?.control?.value"
          [readonly]="readonly"
          class="field"
        ></app-candidate-recognition-payment-role>
      </div>

      <div class="field col-12 lg:col-6">
        <app-candidate-recognition-payment-role-accelerated
          [formId]="formId"
          [form]="form"
          [useFieldLabel]="true"
          [enabled]="
            form.controls?.isCurrent?.value && !!recognitionType?.control?.value && recognitionType?.isAccelerated
              | async
          "
          [readonly]="readonly"
          class="field"
        ></app-candidate-recognition-payment-role-accelerated>
      </div>
    </div>
  </form>
</ng-container>
