import { Injectable } from "@angular/core";
import { GetChamberOfCraftsGQL, StaticDataModel } from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class ChamberOfCraftsService {
  constructor(private readonly get: GetChamberOfCraftsGQL) {}

  getChamberOfCrafts(federalStates?: string[]): Observable<StaticDataModel[]> {
    return this.get.fetch({ input: { federalStates } }, { fetchPolicy: "cache-first" }).pipe(
      map((x) => x.data.getChamberOfCrafts),
      map((authorities) =>
        authorities.map((authority) => ({
          label: authority.hwkInstitutionName,
          value: authority.hwkId.toString(),
        }))
      )
    );
  }
}
