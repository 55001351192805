<ng-container *appTranslate="let t">
  <p-sidebar [(visible)]="showSidebar" styleClass="p-sidebar-lg sidebar-with-table">
    <p-card [header]="t('common.history')">
      <app-field-diff [diff]="diff"></app-field-diff>

      <ng-template pTemplate="footer">
        <div class="flex justify-content-end">
          <p-button closeOnEscape [label]="t('common.close')" icon="pi pi-times" (onClick)="close()"></p-button>
        </div>
      </ng-template>
    </p-card>
  </p-sidebar>
</ng-container>
