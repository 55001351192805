import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { CandidateTabConfiguration } from "@ankaadia/ankaadia-shared";
import {
  EmploymentRelationship,
  HousingAcquisition,
  QualificationEvaluationDataDe,
  QualificationMeasureCandidateDataDe,
  RecognitionPathEntryDe,
  ResidenceCandidateDataDe,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { TabView } from "primeng/tabview";
import { MigrationForm } from "../../candidate-form.model";

@Component({
  selector: "app-candidate-migration-de",
  templateUrl: "./candidate-migration-de.component.html",
  standalone: false,
})
export class CandidateMigrationDEComponent implements OnInit, OnChanges {
  @Input()
  form: MigrationForm;

  @Input()
  readonly: boolean;

  @Input()
  candidateId: string;

  @Input()
  candidateProfession: string;

  @Input()
  candidateOrganizationId: string;

  @Input()
  currentRecognitionPath: RecognitionPathEntryDe;

  @Input()
  alternativeRecognitionPath: RecognitionPathEntryDe;

  @Input()
  tabs: CandidateTabConfiguration;

  @Input()
  qualificationEvaluation: QualificationEvaluationDataDe;

  @Input()
  qualificationMeasureDataDe: QualificationMeasureCandidateDataDe;

  @Input()
  residence: ResidenceCandidateDataDe;

  @Input()
  housingAcquisition: HousingAcquisition;

  @Input()
  employmentRelationship: EmploymentRelationship;

  @Input()
  candidateFunction: string;

  @Input()
  isMultiEditMode: boolean;

  protected SupportedImmigrationCountry: typeof SupportedImmigrationCountry = SupportedImmigrationCountry;

  @ViewChild(TabView)
  tabView: TabView;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    // if this is removed, `federalStateControl` is `undefined` because those components with `.addControl` have not called it yet
    this.changeDetector.detectChanges();

    this.setCandidateDataDependingTabs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateFunction) this.setCandidateDataDependingTabs();
  }

  private setCandidateDataDependingTabs(): void {
    if (!this.tabView) return;
    const isTrainee = this.candidateFunction === "TRAINEE";

    const vocationalSchoolTab = this.tabView.tabs?.find(
      (tab) => tab.header === this.transloco.translate("vocationalSchool.title")
    );
    const qualificationMeasureTab = this.tabView.tabs?.find(
      (tab) => tab.header === this.transloco.translate("qualificationMeasure.title")
    );

    if (vocationalSchoolTab) {
      vocationalSchoolTab.closed = !isTrainee;
      vocationalSchoolTab.disabled = !isTrainee;
    }
    if (qualificationMeasureTab) {
      qualificationMeasureTab.closed = isTrainee;
      qualificationMeasureTab.disabled = isTrainee;
    }

    this.tabView.updateButtonState();
  }
}
