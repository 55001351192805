<form [formGroup]="form" (ngSubmit)="onSave()" *appTranslate="let t">
  <p-card [header]="t('collection.assignFull')">
    <div class="p-fluid">
      <div class="field">
        <label for="assignedOrganizationId">{{ t("organization.title") }}</label>
        <p-dropdown
          inputId="assignedOrganizationId"
          [options]="organizations"
          formControlName="assignedOrganizationId"
          filterBy="name"
          optionLabel="name"
          optionValue="id"
          [placeholder]="t('organization.placeholder')"
          (onChange)="updateCollections($event.value)"
        ></p-dropdown>
        <small class="p-error" *ngIf="form.controls.assignedOrganizationId.errors?.required">
          {{ t("organization.required") }}
        </small>
      </div>

      <div class="field">
        <label for="assignedId">{{ t("collection.title") }}</label>
        <p-dropdown
          inputId="assignedId"
          [options]="collections"
          formControlName="assignedId"
          filterBy="name"
          optionLabel="name"
          optionValue="id"
          [placeholder]="t('collection.placeholder')"
        ></p-dropdown>
        <small class="p-error" *ngIf="form.controls.assignedId.errors?.required">
          {{ t("collection.required") }}
        </small>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <div class="flex flex-row justify-content-end">
        <p-button
          [disabled]="!form.valid || !form.dirty"
          [label]="t('common.save')"
          icon="pi pi-check"
          type="submit"
          class="mr-2"
          data-testid="btn-save-assign-collection"
        ></p-button>
        <p-button
          closeOnEscape
          [label]="t('common.cancel')"
          icon="pi pi-times"
          class="p-button-secondary"
          (onClick)="onClose()"
          data-testid="btn-cancel-assign-collection"
        ></p-button>
      </div>
    </ng-template>
  </p-card>
</form>
