<ng-container *appTranslate="let t">
  <ng-container *ngIf="readonly">
    <div
      *ngIf="control.value as value"
      class="p-tag p-component text-base app-tag-info white-space-nowrap text-overflow-ellipsis overflow-hidden"
    >
      <span class="p-tag-value">{{ value | staticData: StaticDataType.Profession | async }}</span>
    </div>
  </ng-container>
  <div class="field" *ngIf="!readonly">
    <label for="profession">{{ t("profession.title") }}</label>

    <app-profession-selector
      [formControl]="control"
      [candidateOrganizationId]="candidateOrganizationId"
      [required]="control.hasValidator(Validators.required)"
      id="profession"
    ></app-profession-selector>
  </div>
</ng-container>
