import { Directive, ElementRef, Input, OnInit, Optional } from "@angular/core";
import { AbstractControl, ControlContainer, NgControl } from "@angular/forms";
import { FieldArrayTypeConfig, FormlyField } from "@ngx-formly/core";
import { Calendar } from "primeng/calendar";
import { Checkbox } from "primeng/checkbox";
import { Dropdown } from "primeng/dropdown";
import { InputNumber } from "primeng/inputnumber";
import { MultiSelect } from "primeng/multiselect";
import { DateTimeComponent } from "../date-time/date-time.component";
import { FormElementMapService } from "./form-element-map.service";

@Directive({
  selector:
    "[formControl], [formControlName], [formArrayName], [formGroupName], [controlRef], formly-field, formly-group, app-date-time",
  standalone: false,
})
export class FormElementMapDirective implements OnInit {
  @Input()
  controlRef: AbstractControl;

  @Input() fieldConfig: NonNullable<FieldArrayTypeConfig>;

  constructor(
    @Optional() private readonly controlDir: NgControl,
    @Optional() private readonly controlContainerDir: ControlContainer,
    @Optional() private readonly formlyField: FormlyField,
    @Optional() private readonly dropdown: Dropdown,
    @Optional() private readonly inputNumber: InputNumber,
    @Optional() private readonly multiSelect: MultiSelect,
    @Optional() private readonly checkbox: Checkbox,
    @Optional() private readonly calendar: Calendar,
    @Optional() private readonly dateTimeComponent: DateTimeComponent,
    private readonly host: ElementRef<HTMLFormElement>,
    private readonly elementMapService: FormElementMapService
  ) {}

  ngOnInit(): void {
    const component =
      this.dropdown ?? this.inputNumber ?? this.multiSelect ?? this.checkbox ?? this.calendar ?? this.dateTimeComponent;

    if (this.formlyField?.field?.formControl) {
      this.elementMapService.registerFormlyFieldArrayConfiguration(
        this.formlyField.field.formControl,
        this.formlyField.field
      );
      this.elementMapService.registerFormControlWithElement(
        this.host.nativeElement,
        this.formlyField.field.formControl,
        "skip"
      );
      if (component) this.elementMapService.registerComponent(component, this.formlyField.field.formControl);
      return;
    }

    if (this.controlRef) {
      this.elementMapService.registerFormControlWithElement(this.host.nativeElement, this.controlRef, "override");
      this.elementMapService.registerFormlyFieldArrayConfiguration(this.controlRef, this.fieldConfig);
      if (component) this.elementMapService.registerComponent(component, this.controlRef);
      return;
    }

    if (this.dateTimeComponent) {
      this.elementMapService.registerFormControlWithElement(
        this.host.nativeElement,
        this.dateTimeComponent.form,
        "override"
      );
      this.elementMapService.registerComponent(this.dateTimeComponent, this.dateTimeComponent.form);
      return;
    }

    if (this.controlDir?.control) {
      this.elementMapService.registerFormControlWithElement(
        this.host.nativeElement,
        this.controlDir?.control,
        "override"
      );
      this.elementMapService.registerFormlyFieldArrayConfiguration(this.controlDir?.control, this.fieldConfig);
      if (component) this.elementMapService.registerComponent(component, this.controlDir?.control);
      return;
    }

    if (this.controlContainerDir?.control) {
      this.elementMapService.registerFormControlWithElement(
        this.host.nativeElement,
        this.controlContainerDir.control,
        "override"
      );
      this.elementMapService.registerFormlyFieldArrayConfiguration(this.controlContainerDir.control, this.fieldConfig);
    }
  }
}
