import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { switchMap } from "rxjs";
import { MyOrganizationService } from "../../features/organizations/my-organization/my-organization.service";
import { OrganizationsService } from "../../features/organizations/organizations.service";
import { SettingsService } from "../../shared/services/settings.service";
import { MenuService } from "../../structure/menu/menu.service";

@Component({
  selector: "app-tile-menu",
  templateUrl: "./tile-menu.component.html",
  styleUrl: "./tile-menu.component.scss",
  standalone: false,
})
export class TileMenuComponent implements OnInit {
  readonly sections = this.menuService.getSections();

  logo?: string;
  constructor(
    private readonly settings: SettingsService,
    private readonly orgService: OrganizationsService,
    private readonly myOrgService: MyOrganizationService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly menuService: MenuService
  ) {}

  ngOnInit(): void {
    const id = this.settings.isLicensed ? this.settings.organizationId : this.settings.creatorOrganizationId;
    if (id) {
      this.myOrgService.changed
        .pipe(switchMap(() => this.orgService.getOrganizationLogoDownloadURL(id)))
        .subscribe((x) => {
          this.logo = x;
          this.changeDetector.detectChanges();
        });
    }
  }
}
