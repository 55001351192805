import { Injectable } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  Candidate,
  CandidateFamilyMember,
  CandidateScholarship,
  EmployerHistoryEntry,
  Housing,
  InternalCandidateData,
  QualificationEvaluationsDe,
  QualificationExam,
  QualificationModule,
  ResidencePermit,
} from "@ankaadia/graphql";
import { cloneDeep, set } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { emitEvents } from "../../shared/services/form.helper";
import {
  AfterRecognitionFormAt,
  AfterRecognitionFormDe,
  BeforeRecognitionFormAt,
  BeforeRecognitionFormDe,
  CandidateForm,
  EmployerHistoryEntryFormAt,
  EmployerHistoryEntryFormDe,
  EmploymentRelationshipFormAt,
  EmploymentRelationshipFormDe,
  FamilyForm,
  FamilyMemberForm,
  GovernanceForm,
  HealthProfessionsRegisterFormAt,
  HousingAcquisitionForm,
  HousingForm,
  HrForm,
  InternalForm,
  LaborMarketAdmissionFormDe,
  LicensedOrganizationDataForm,
  MigrationForm,
  MigrationFormAt,
  MigrationFormDe,
  OsForm,
  PresentationForm,
  ProcessStatusForm,
  ProfileForm,
  QualificationEvaluationFormDe,
  QualificationEvaluationsFormDe,
  QualificationMeasureExamFormDe,
  QualificationMeasureFormAt,
  QualificationMeasureFormDe,
  QualificationMeasureModuleFormDe,
  RecognitionPathEntryFormAt,
  RecognitionPathEntryFormDe,
  RecognitionPathFormDe,
  ResidenceFormAt,
  ResidenceFormDe,
  ResidencePermitFormDe,
  ScholarshipForm,
  VisaFormAt,
  VisaFormDe,
  VocationalSchoolFormDe,
  VocationalSchoolShipFormAt,
} from "./candidate-form.model";
import { candidateScholarshipsValidator } from "./candidate-scholarship/candidate-scholarships.validator";

@Injectable({ providedIn: "root" })
export class CandidateFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createCandidateGroup(): CandidateForm {
    return this.formBuilder.group<CandidateForm["controls"]>({
      id: this.formBuilder.control(null),
      _etag: this.formBuilder.control(null),
      organizationId: this.formBuilder.control(null, Validators.required),
      displayId: this.formBuilder.control(null),
      creationDate: this.formBuilder.control(null),
      status: this.formBuilder.control(null, Validators.required),
      immigrationCountry: this.formBuilder.control(null, Validators.required),
      function: this.formBuilder.control(null, Validators.required),
      profession: this.formBuilder.control(null, Validators.required),
      os: this.formBuilder.group<OsForm["controls"]>({
        profile: this.formBuilder.group<ProfileForm["controls"]>(<any>{}),
      }),
      migration: this.createMigrationGroup(),
      presentation: this.formBuilder.group<PresentationForm["controls"]>({
        recommendationNote: this.formBuilder.control(null),
      }),
      family: this.formBuilder.group<FamilyForm["controls"]>({
        members: this.formBuilder.array<FamilyMemberForm>([]),
      }),
      governance: this.formBuilder.group<GovernanceForm["controls"]>({
        matching: this.formBuilder.control(null),
        profileDataEditStatus: this.formBuilder.control(null),
        communicationLanguage: this.formBuilder.control(null),
      }),
      internal: this.getInternalForm(),
      licensedOrganizationData: this.formBuilder.group<LicensedOrganizationDataForm["controls"]>({
        internal: this.getInternalForm(),
      }),
      processStatus: this.formBuilder.group<ProcessStatusForm["controls"]>({
        estimatedArrivalDate: this.formBuilder.control(null),
        estimatedWorkStartDate: this.formBuilder.control(null),
      }),
    });
  }

  private getInternalForm(): InternalForm {
    return this.formBuilder.group<InternalForm["controls"]>({
      category: this.formBuilder.control(null),
      costCenter: this.formBuilder.control(null),
      isReplacementForCandidate: this.formBuilder.control(null),
      replacementForCandidateId: this.formBuilder.control(null),
      replacementForOrganizationId: this.formBuilder.control(null),
      replacementForDisplayId: this.formBuilder.control(null),
      replacementForDisplayName: this.formBuilder.control(null),
      notes: this.formBuilder.control(null),
      languageSchooldId: this.formBuilder.control(null),
      sourceOrganizationId: this.formBuilder.control(null),
      scholarships: this.formBuilder.array<ScholarshipForm>([], { validators: candidateScholarshipsValidator }),
      responsibleEmployees: this.formBuilder.control(null),
      headerComment: this.formBuilder.control(null, [Validators.maxLength(800)]),
      hr: this.formBuilder.group<HrForm["controls"]>({
        personnelNumber: this.formBuilder.control(null),
        personnelArea: this.formBuilder.control(null),
        personnelDepartment: this.formBuilder.control(null),
        supervisorId: this.formBuilder.control(null),
        supervisorFirstName: this.formBuilder.control(null),
        supervisorLastName: this.formBuilder.control(null),
        contactPartnerId: this.formBuilder.control(null),
        contactPartnerFirstName: this.formBuilder.control(null),
        contactPartnerLastName: this.formBuilder.control(null),
        terminationEmploymentActor: this.formBuilder.control(null),
        terminationEmploymentDate: this.formBuilder.control(null),
      }),
    });
  }

  createMigrationGroup(): MigrationForm {
    return this.formBuilder.group<MigrationForm["controls"]>({
      de: this.createMigrationGroupDe(),
      at: this.createMigrationGroupAt(),
      housingAcquisition: this.formBuilder.group<HousingAcquisitionForm["controls"]>({
        responsibleRoleHousingAcquisition: this.formBuilder.control(null),
        responsibleOrganizationId: this.formBuilder.control(null),
        representativeId: this.formBuilder.control(null),
        representativeFirstName: this.formBuilder.control(null),
        representativeLastName: this.formBuilder.control(null),
        firstDeputyId: this.formBuilder.control(null),
        firstDeputyFirstName: this.formBuilder.control(null),
        firstDeputyLastName: this.formBuilder.control(null),
        housings: this.formBuilder.array<HousingForm>([]),
      }),
    });
  }

  createMigrationGroupDe(): MigrationFormDe {
    return this.formBuilder.group<MigrationFormDe["controls"]>({
      recognitionPath: this.formBuilder.group<RecognitionPathFormDe["controls"]>({
        current: this.formBuilder.group<RecognitionPathEntryFormDe["controls"]>({
          recognitionNoticeKind: this.formBuilder.control(null),
          isCurrent: this.formBuilder.control(true),
          recognitionStarted: this.formBuilder.control(null),
          recognitionStartDate: this.formBuilder.control(null),
          fileNumber: this.formBuilder.control(null),
          fileNumberAcceleratedProcedure: this.formBuilder.control(null),
          certificateRequested: this.formBuilder.control(null),
          certificateRequestDate: this.formBuilder.control(null),
          equivalenceTest: this.formBuilder.control(null),
          federalState: this.formBuilder.control(null),
          immigrationAuthority: this.formBuilder.control(null),
          pathOfRecognition: this.formBuilder.control(null),
          psaFirstDeputyId: this.formBuilder.control(null),
          psaFirstDeputyFirstName: this.formBuilder.control(null),
          psaFirstDeputyLastName: this.formBuilder.control(null),
          psaRepresentativeId: this.formBuilder.control(null),
          psaRepresentativeFirstName: this.formBuilder.control(null),
          psaRepresentativeLastName: this.formBuilder.control(null),
          recognitionAuthority: this.formBuilder.control(null),
          recognitionPaymentRole: this.formBuilder.control(null),
          recognitionPaymentRoleAccelerated: this.formBuilder.control(null),
          recognitionType: this.formBuilder.control(null),
          responsibleRecognitionOrganizationId: this.formBuilder.control(null),
          responsibleRoleRecognition: this.formBuilder.control(null),
          recognitionCenter: this.formBuilder.control(null),
          chamberOfCrafts: this.formBuilder.control(null),
          recognitionPartnership: this.formBuilder.control(null),
        }),
        alternative: this.formBuilder.group<RecognitionPathEntryFormDe["controls"]>({
          recognitionNoticeKind: this.formBuilder.control(null),
          isCurrent: this.formBuilder.control(false),
          recognitionStarted: this.formBuilder.control(null),
          recognitionStartDate: this.formBuilder.control(null),
          fileNumber: this.formBuilder.control(null),
          fileNumberAcceleratedProcedure: this.formBuilder.control(null),
          certificateRequested: this.formBuilder.control(null),
          certificateRequestDate: this.formBuilder.control(null),
          equivalenceTest: this.formBuilder.control(null),
          federalState: this.formBuilder.control(null),
          immigrationAuthority: this.formBuilder.control(null),
          pathOfRecognition: this.formBuilder.control(null),
          psaFirstDeputyId: this.formBuilder.control(null),
          psaFirstDeputyFirstName: this.formBuilder.control(null),
          psaFirstDeputyLastName: this.formBuilder.control(null),
          psaRepresentativeId: this.formBuilder.control(null),
          psaRepresentativeFirstName: this.formBuilder.control(null),
          psaRepresentativeLastName: this.formBuilder.control(null),
          recognitionAuthority: this.formBuilder.control(null),
          recognitionPaymentRole: this.formBuilder.control(null),
          recognitionPaymentRoleAccelerated: this.formBuilder.control(null),
          recognitionType: this.formBuilder.control(null),
          responsibleRecognitionOrganizationId: this.formBuilder.control(null),
          responsibleRoleRecognition: this.formBuilder.control(null),
          recognitionCenter: this.formBuilder.control(null),
          chamberOfCrafts: this.formBuilder.control(null),
          recognitionPartnership: this.formBuilder.control(null),
        }),
      }),
      laborMarketAdmission: this.formBuilder.group<LaborMarketAdmissionFormDe["controls"]>({
        employmentApprovalRequested: this.formBuilder.control(null),
        employmentApprovalRequestDate: this.formBuilder.control(null),
        employmentApprovalAuthority: this.formBuilder.control(null),
        responsibleRoleLaborMarket: this.formBuilder.control(null),
      }),
      qualificationMeasure: this.formBuilder.group<QualificationMeasureFormDe["controls"]>({
        theoryHours: this.formBuilder.control(null),
        theoryExamDate: this.formBuilder.control(null),
        theoryInstitution: this.formBuilder.control(null),
        qualificationMeasureFileNumber: this.formBuilder.control(null),
        courseNumber: this.formBuilder.control(null),
        practiceHours: this.formBuilder.control(null),
        practiceExamDate: this.formBuilder.control(null),
        practiceInstitution: this.formBuilder.control(null),
        totalHours: this.formBuilder.control(null),
        careSituations: this.formBuilder.control(null),
        qualificationIsSend: this.formBuilder.control(null),
        qualificationIsSendDate: this.formBuilder.control(null),
        educationVoucherRequested: this.formBuilder.control(null),
        educationVoucherRequestDate: this.formBuilder.control(null),
        educationVoucherFileNumber: this.formBuilder.control(null),
        noDetailedEquivalence: this.formBuilder.control(null),
        qualificationModules: this.formBuilder.array<QualificationMeasureModuleFormDe>([]),
        qualificationExams: this.formBuilder.array<QualificationMeasureExamFormDe>([]),
        qualificationMeasure: this.formBuilder.control(null),
        endOfFurtherEducation: this.formBuilder.control(null),
        qualificationType: this.formBuilder.control(null),
        responsibleRoleEducationVoucher: this.formBuilder.control(null),
        responsibleRoleQualification: this.formBuilder.control(null),
        startOfFurtherEducation: this.formBuilder.control(null),
        responsibleEducationVoucherOrganizationId: this.formBuilder.control(null),
        educationVoucherRepresentativeId: this.formBuilder.control(null),
        educationVoucherRepresentativeFirstName: this.formBuilder.control(null),
        educationVoucherRepresentativeLastName: this.formBuilder.control(null),
        educationVoucherFirstDeputyId: this.formBuilder.control(null),
        educationVoucherFirstDeputyFirstName: this.formBuilder.control(null),
        educationVoucherFirstDeputyLastName: this.formBuilder.control(null),
      }),
      vocationalSchool: this.formBuilder.group<VocationalSchoolFormDe["controls"]>({
        trainingTimeInYearsTotal: this.formBuilder.control(null),
        trainingTimeInPartTime: this.formBuilder.control(null),
        trainingTimeShortened: this.formBuilder.control(null),
        currentTrainingYear: this.formBuilder.control(null),
        expectedEndDate: this.formBuilder.control(null),
        endDate: this.formBuilder.control(null),
        schoolCourse: this.formBuilder.control(null),
        schoolName: this.formBuilder.control(null),
        address: this.formBuilder.control(null),
        city: this.formBuilder.control(null),
        country: this.formBuilder.control(null),
        zipCode: this.formBuilder.control(null),
        federalState: this.formBuilder.control(null),
        medicalCheckAttended: this.formBuilder.control(null),
        medicalCheckAttendedDate: this.formBuilder.control(null),
        medicalCheckPassed: this.formBuilder.control(null),
        medicalCheckPassedDate: this.formBuilder.control(null),
      }),
      qualificationEvaluation: this.formBuilder.group<QualificationEvaluationFormDe["controls"]>({
        noQualificationEvaluation: this.formBuilder.control(null),
        responsibleRoleQualificationEvaluationPayment: this.formBuilder.control(null),
        qualificationEvaluations: this.formBuilder.array<QualificationEvaluationsFormDe>([]),
        qualificationEvaluationFirstDeputyId: this.formBuilder.control(null),
        qualificationEvaluationFirstDeputyFirstName: this.formBuilder.control(null),
        qualificationEvaluationFirstDeputyLastName: this.formBuilder.control(null),
        qualificationEvaluationRepresentativeId: this.formBuilder.control(null),
        qualificationEvaluationRepresentativeFirstName: this.formBuilder.control(null),
        qualificationEvaluationRepresentativeLastName: this.formBuilder.control(null),
        responsibleQualificationEvaluationOrganizationId: this.formBuilder.control(null),
        responsibleRoleQualificationEvaluation: this.formBuilder.control(null),
      }),
      visa: this.formBuilder.group<VisaFormDe["controls"]>({
        visaEnquired: this.formBuilder.control(null),
        visaEnquiredDate: this.formBuilder.control(null),
        visaRequested: this.formBuilder.control(null),
        visaRequestDate: this.formBuilder.control(null),
        diplomaticMissionOfGermanyInCountry: this.formBuilder.control(null),
        fileNumber: this.formBuilder.control(null),
        visaRelocationRequirements: this.formBuilder.control(null),
        arrivalDate: this.getDateTimeForm(null, false),
        flightDate: this.getDateTimeForm(null, false),
        residencePermit: this.formBuilder.control(null),
        responsibleRoleVisa: this.formBuilder.control(null),
        responsibleForTransfer: this.formBuilder.control(null),
        pickUpVehicle: this.formBuilder.control(null),
        contactAtArrivalLocation: this.formBuilder.control(null),
        departureAirport: this.formBuilder.control(null),
        arrivalAirport: this.formBuilder.control(null),
        flightNumber: this.formBuilder.control(null),
        departureTrainStation: this.formBuilder.control(null),
        flightArrivalDate: this.getDateTimeForm(null, false),
        trainDepartureDate: this.getDateTimeForm(null, false),
        trainArrivalDate: this.getDateTimeForm(null, false),
        arrivalTrainStation: this.formBuilder.control(null),
        trainNumber: this.formBuilder.control(null),
      }),
      residence: this.formBuilder.group<ResidenceFormDe["controls"]>({
        appointmentHealthDepartmentDate: this.getDateTimeForm(null, false),
        appointmentHealthDepartmentKept: this.formBuilder.control(null),
        residencePermits: this.formBuilder.array<ResidencePermitFormDe>([]),
        residenceFirstDeputyId: this.formBuilder.control(null),
        residenceFirstDeputyFirstName: this.formBuilder.control(null),
        residenceFirstDeputyLastName: this.formBuilder.control(null),
        residenceRepresentativeId: this.formBuilder.control(null),
        residenceRepresentativeFirstName: this.formBuilder.control(null),
        residenceRepresentativeLastName: this.formBuilder.control(null),
        responsibleResidenceOrganizationId: this.formBuilder.control(null),
        responsibleRoleResidence: this.formBuilder.control(null),
        registrationOfficeDate: this.getDateTimeForm(null, false),
        medicalExaminationAppointmentDate: this.getDateTimeForm(null, false),
        companyDoctorAppointmentDate: this.getDateTimeForm(null, false),
        bankAppointmentDate: this.getDateTimeForm(null, false),
        policeRecordAppointmentDate: this.getDateTimeForm(null, false),
        workStartDate: this.formBuilder.control(null),
        certificateOfConduct: this.formBuilder.control(null),
        certificateOfConductRequested: this.formBuilder.control(null),
        registrationAuthorityAppointmentNumber: this.formBuilder.control(null),
        registrationAuthority: this.formBuilder.control(null),
        residenceBankContactPartner: this.formBuilder.control(null),
        residenceBank: this.formBuilder.control(null),
        recognitionMedicalCertificate: this.formBuilder.control(null),
        suitabilityForWork: this.formBuilder.control(null),
        personalOnboardingDateTime: this.getDateTimeForm(null, false),
      }),
      employmentRelationship: this.formBuilder.group<EmploymentRelationshipFormDe["controls"]>({
        hasDifferentRelationships: this.formBuilder.control(false),
        beforeRecognition: this.formBuilder.group<BeforeRecognitionFormDe["controls"]>({
          employerId: this.formBuilder.control(null),
          employerName: this.formBuilder.control(null),
          employerRegion: this.formBuilder.control(null),
          employerZipcode: this.formBuilder.control(null),

          contractTemplateId: this.formBuilder.control(null),
          contractTemplateName: this.formBuilder.control(null),
          contractTemplate: this.formBuilder.control(null),

          representativeId: this.formBuilder.control(null),
          representativeFirstName: this.formBuilder.control(null),
          representativeLastName: this.formBuilder.control(null),

          firstDeputyId: this.formBuilder.control(null),
          firstDeputyFirstName: this.formBuilder.control(null),
          firstDeputyLastName: this.formBuilder.control(null),

          isTemporaryEmployee: this.formBuilder.control(false),
          typeOfWorkLocation: this.formBuilder.control(null),
          workLocation: this.formBuilder.control(null),
          endOfProbationDate: this.formBuilder.control(null),
          contractExtension: this.formBuilder.control(null),

          employmentRelocationRequirements: this.formBuilder.control(null),
          employerHistory: this.formBuilder.array<EmployerHistoryEntryFormDe>([]),
        }),
        afterRecognition: this.formBuilder.group<AfterRecognitionFormDe["controls"]>({
          employerId: this.formBuilder.control(null),
          employerName: this.formBuilder.control(null),
          employerRegion: this.formBuilder.control(null),
          employerZipcode: this.formBuilder.control(null),

          contractTemplateId: this.formBuilder.control(null),
          contractTemplateName: this.formBuilder.control(null),
          contractTemplate: this.formBuilder.control(null),

          representativeId: this.formBuilder.control(null),
          representativeFirstName: this.formBuilder.control(null),
          representativeLastName: this.formBuilder.control(null),

          firstDeputyId: this.formBuilder.control(null),
          firstDeputyFirstName: this.formBuilder.control(null),
          firstDeputyLastName: this.formBuilder.control(null),

          contractSignDate: this.formBuilder.control(null),
          startDate: this.formBuilder.control(null),
          endDate: this.formBuilder.control(null),

          isTemporaryEmployee: this.formBuilder.control(false),
          typeOfWorkLocation: this.formBuilder.control(null),
          confirmationSignedDate: this.formBuilder.control(null),
          workLocation: this.formBuilder.control(null),
          endOfProbationDate: this.formBuilder.control(null),
          contractExtension: this.formBuilder.control(null),

          employmentRelocationRequirements: this.formBuilder.control(null),
          employerHistory: this.formBuilder.array<EmployerHistoryEntryFormDe>([]),
        }),
      }),
    });
  }

  createMigrationGroupAt(): MigrationFormAt {
    return this.formBuilder.group<MigrationFormAt["controls"]>({
      recognitionPath: this.formBuilder.group<RecognitionPathEntryFormAt["controls"]>({
        recognitionStarted: this.formBuilder.control(null),
        recognitionStartDate: this.formBuilder.control(null),
        fileNumber: this.formBuilder.control(null),
        recognitionReceived: this.formBuilder.control(null),
        recognitionReceiveDate: this.formBuilder.control(null),
        federalState: this.formBuilder.control(null),
        pathOfRecognition: this.formBuilder.control(null),
        psaFirstDeputyId: this.formBuilder.control(null),
        psaFirstDeputyFirstName: this.formBuilder.control(null),
        psaFirstDeputyLastName: this.formBuilder.control(null),
        psaRepresentativeId: this.formBuilder.control(null),
        psaRepresentativeFirstName: this.formBuilder.control(null),
        psaRepresentativeLastName: this.formBuilder.control(null),
        recognitionAuthority: this.formBuilder.control(null),
        responsibleRecognitionOrganizationId: this.formBuilder.control(null),
        responsibleRoleRecognition: this.formBuilder.control(null),
        legalBasis: this.formBuilder.control(null),
        targetRecognition: this.formBuilder.control(null),
      }),
      qualificationMeasure: this.formBuilder.group<QualificationMeasureFormAt["controls"]>({
        theoryInstitution: this.formBuilder.control(null),
        locationOfComplementaryMeasure: this.formBuilder.control(null),
        endOfFurtherEducation: this.formBuilder.control(null),
        responsibleRoleQualification: this.formBuilder.control(null),
        startOfFurtherEducation: this.formBuilder.control(null),
      }),
      vocationalSchool: this.formBuilder.group<VocationalSchoolShipFormAt["controls"]>({
        trainingTimeInYearsTotal: this.formBuilder.control(null),
        trainingTimeInPartTime: this.formBuilder.control(null),
        trainingTimeShortened: this.formBuilder.control(null),
        currentTrainingYear: this.formBuilder.control(null),
        expectedEndDate: this.formBuilder.control(null),
        endDate: this.formBuilder.control(null),
        schoolCourse: this.formBuilder.control(null),
        schoolName: this.formBuilder.control(null),
        address: this.formBuilder.control(null),
        city: this.formBuilder.control(null),
        country: this.formBuilder.control(null),
        zipCode: this.formBuilder.control(null),
        federalState: this.formBuilder.control(null),
        medicalCheckAttended: this.formBuilder.control(null),
        medicalCheckAttendedDate: this.formBuilder.control(null),
        medicalCheckPassed: this.formBuilder.control(null),
        medicalCheckPassedDate: this.formBuilder.control(null),
      }),
      visa: this.formBuilder.group<VisaFormAt["controls"]>({
        visaEnquired: this.formBuilder.control(null),
        visaEnquiredDate: this.formBuilder.control(null),
        visaReceived: this.formBuilder.control(null),
        visaReceiveDate: this.formBuilder.control(null),
        visaValidFromDate: this.formBuilder.control(null),
        visaValidUntilDate: this.formBuilder.control(null),
        fileNumber: this.formBuilder.control(null),
        responsibleRoleVisa: this.formBuilder.control(null),
        fingerPrintDate: this.formBuilder.control(null),
        pickUpDetails: this.formBuilder.control(null),
        responsibleForTransfer: this.formBuilder.control(null),
        pickUpVehicle: this.formBuilder.control(null),
        contactAtArrivalLocation: this.formBuilder.control(null),
        departureAirport: this.formBuilder.control(null),
        arrivalAirport: this.formBuilder.control(null),
        flightNumber: this.formBuilder.control(null),
        departureTrainStation: this.formBuilder.control(null),
        flightArrivalDate: this.getDateTimeForm(null, false),
        trainDepartureDate: this.getDateTimeForm(null, false),
        trainArrivalDate: this.getDateTimeForm(null, false),
        arrivalTrainStation: this.formBuilder.control(null),
        flightDate: this.getDateTimeForm(null, false),
        trainNumber: this.formBuilder.control(null),
        visaRelocationRequirements: this.formBuilder.control(null),
        plannedEntryDate: this.formBuilder.control(null),
      }),
      residence: this.formBuilder.group<ResidenceFormAt["controls"]>({
        registrationOfficeDate: this.getDateTimeForm(null, false),
        residenceFirstDeputyId: this.formBuilder.control(null),
        residenceFirstDeputyFirstName: this.formBuilder.control(null),
        residenceFirstDeputyLastName: this.formBuilder.control(null),
        residenceRepresentativeId: this.formBuilder.control(null),
        residenceRepresentativeFirstName: this.formBuilder.control(null),
        residenceRepresentativeLastName: this.formBuilder.control(null),
        responsibleResidenceOrganizationId: this.formBuilder.control(null),
        responsibleRoleResidence: this.formBuilder.control(null),
        workStartDate: this.formBuilder.control(null),
        registrationAuthorityAppointmentNumber: this.formBuilder.control(null),
        registrationAuthority: this.formBuilder.control(null),
        residenceBankContactPartner: this.formBuilder.control(null),
        residenceBank: this.formBuilder.control(null),
        recognitionMedicalCertificate: this.formBuilder.control(null),
        suitabilityForWork: this.formBuilder.control(null),
        personalOnboardingDateTime: this.getDateTimeForm(null, false),
      }),
      employmentRelationship: this.formBuilder.group<EmploymentRelationshipFormAt["controls"]>({
        hasDifferentRelationships: this.formBuilder.control(false),
        beforeRecognition: this.formBuilder.group<BeforeRecognitionFormAt["controls"]>({
          employerId: this.formBuilder.control(null),
          employerName: this.formBuilder.control(null),
          employerRegion: this.formBuilder.control(null),
          employerZipcode: this.formBuilder.control(null),

          contractTemplateId: this.formBuilder.control(null),
          contractTemplateName: this.formBuilder.control(null),
          contractTemplate: this.formBuilder.control(null),

          representativeId: this.formBuilder.control(null),
          representativeFirstName: this.formBuilder.control(null),
          representativeLastName: this.formBuilder.control(null),

          firstDeputyId: this.formBuilder.control(null),
          firstDeputyFirstName: this.formBuilder.control(null),
          firstDeputyLastName: this.formBuilder.control(null),

          isTemporaryEmployee: this.formBuilder.control(false),
          typeOfWorkLocation: this.formBuilder.control(null),
          workLocation: this.formBuilder.control(null),
          endOfProbationDate: this.formBuilder.control(null),
          contractExtension: this.formBuilder.control(null),

          employmentRelocationRequirements: this.formBuilder.control(null),
          employerHistory: this.formBuilder.array<EmployerHistoryEntryFormAt>([]),
        }),
        afterRecognition: this.formBuilder.group<AfterRecognitionFormAt["controls"]>({
          employerId: this.formBuilder.control(null),
          employerName: this.formBuilder.control(null),
          employerRegion: this.formBuilder.control(null),
          employerZipcode: this.formBuilder.control(null),

          contractTemplateId: this.formBuilder.control(null),
          contractTemplateName: this.formBuilder.control(null),
          contractTemplate: this.formBuilder.control(null),

          representativeId: this.formBuilder.control(null),
          representativeFirstName: this.formBuilder.control(null),
          representativeLastName: this.formBuilder.control(null),

          firstDeputyId: this.formBuilder.control(null),
          firstDeputyFirstName: this.formBuilder.control(null),
          firstDeputyLastName: this.formBuilder.control(null),

          contractSignDate: this.formBuilder.control(null),
          startDate: this.formBuilder.control(null),
          endDate: this.formBuilder.control(null),

          isTemporaryEmployee: this.formBuilder.control(false),
          typeOfWorkLocation: this.formBuilder.control(null),
          confirmationSignedDate: this.formBuilder.control(null),
          workLocation: this.formBuilder.control(null),
          endOfProbationDate: this.formBuilder.control(null),
          contractExtension: this.formBuilder.control(null),

          employmentRelocationRequirements: this.formBuilder.control(null),
          employerHistory: this.formBuilder.array<EmployerHistoryEntryFormAt>([]),
        }),
      }),
      healthProfessionsRegister: this.formBuilder.group<HealthProfessionsRegisterFormAt["controls"]>({
        appointmentDate: this.formBuilder.control(null),
        registrationDate: this.formBuilder.control(null),
        submission: this.formBuilder.control(null),
      }),
    });
  }

  createFamilyMemberGroup(member: CandidateFamilyMember): FamilyMemberForm {
    return this.formBuilder.group<FamilyMemberForm["controls"]>({
      id: this.formBuilder.control(member.id ?? uuidv4()),
      salutation: this.formBuilder.control(member.salutation),
      reunification: this.formBuilder.control(member.reunification),
      firstName: this.formBuilder.control(member.firstName),
      lastName: this.formBuilder.control(member.lastName),
      birthName: this.formBuilder.control(member.birthName),
      birthDate: this.formBuilder.control(member.birthDate),
      cityOfBirth: this.formBuilder.control(member.cityOfBirth),
      countryOfBirth: this.formBuilder.control(member.countryOfBirth),
      citizenship: this.formBuilder.control(member.citizenship),
      email: this.formBuilder.control(member.email),
      phone: this.formBuilder.control(member.phone),
      relation: this.formBuilder.control(member.relation),
      sameAddressAsCandidate: this.formBuilder.control(member.sameAddressAsCandidate),
      address: this.formBuilder.control(member.address),
      zipCode: this.formBuilder.control(member.zipCode),
      city: this.formBuilder.control(member.city),
      country: this.formBuilder.control(member.country),
      germanLanguageSkill: this.formBuilder.control(member.germanLanguageSkill),
      hasSharedCustody: this.formBuilder.control(member.hasSharedCustody),
      otherCustodian: this.formBuilder.control(member.otherCustodian),
      isEmergencyContact: this.formBuilder.control(member.isEmergencyContact),
    });
  }

  createResidencePermitGroup(permit: ResidencePermit): ResidencePermitFormDe {
    return this.formBuilder.group<ResidencePermitFormDe["controls"]>({
      id: this.formBuilder.control(permit.id),
      residencePermit: this.formBuilder.control(permit.residencePermit),
      residenceRequestedElectronicallyDate: this.formBuilder.control(permit.residenceRequestedElectronicallyDate),
      residenceRequestedByPostDate: this.formBuilder.control(permit.residenceRequestedByPostDate),
      fictionalCertificateDoesNotAuthorisesWorking: this.formBuilder.control(
        permit.fictionalCertificateDoesNotAuthorisesWorking
      ),
      fictionalCertificateAvailable: this.formBuilder.control(permit.fictionalCertificateAvailable),
      fictionalCertificateNumber: this.formBuilder.control(permit.fictionalCertificateNumber),
      immigrationAuthority: this.formBuilder.control(permit.immigrationAuthority),
    });
  }

  createQualificationEvaluationGroup(
    qualificationEvaluation: QualificationEvaluationsDe
  ): QualificationEvaluationsFormDe {
    return this.formBuilder.group<QualificationEvaluationsFormDe["controls"]>({
      id: this.formBuilder.control(qualificationEvaluation.id),
      evaluatedQualification: this.formBuilder.control(qualificationEvaluation.evaluatedQualification),
      responsibleAuthorityForQualification: this.formBuilder.control(
        qualificationEvaluation.responsibleAuthorityForQualification
      ),
      responsibleAuthorityForQualificationInOrigin: this.formBuilder.control(
        qualificationEvaluation.responsibleAuthorityForQualificationInOrigin
      ),
      federalState: this.formBuilder.control(qualificationEvaluation.federalState),
      fileNumber: this.formBuilder.control(qualificationEvaluation.fileNumber),
      evaluationNoticeKind: this.formBuilder.control(qualificationEvaluation.evaluationNoticeKind),
    });
  }

  createScholarshipGroup(scholarship: CandidateScholarship): ScholarshipForm {
    return this.formBuilder.group<ScholarshipForm["controls"]>({
      id: this.formBuilder.control(scholarship.id),
      startDate: this.formBuilder.control(scholarship.startDate),
      endDate: this.formBuilder.control(scholarship.endDate),
      amountOfMoney: this.formBuilder.control(scholarship.amountOfMoney),
      awardFrequency: this.formBuilder.control(scholarship.awardFrequency),
      responsibleRoleScholarship: this.formBuilder.control(scholarship.responsibleRoleScholarship),
      comment: this.formBuilder.control(scholarship.comment),
    });
  }

  createQualificationModuleGroup(module: QualificationModule): QualificationMeasureModuleFormDe {
    return this.formBuilder.group<QualificationMeasureModuleFormDe["controls"]>({
      id: this.formBuilder.control(module.id),
      name: this.formBuilder.control(module.name),
      areaOfDeployment: this.formBuilder.control(module.areaOfDeployment),
      theoryHours: this.formBuilder.control(module.theoryHours),
      practiceHours: this.formBuilder.control(module.practiceHours),
    });
  }

  createQualificationExamGroup(exam: QualificationExam): QualificationMeasureExamFormDe {
    return this.formBuilder.group<QualificationMeasureExamFormDe["controls"]>({
      id: this.formBuilder.control(exam.id),
      examType: this.formBuilder.control(exam.examType, Validators.required),
      firstExamDate: this.formBuilder.control(exam.firstExamDate),
      firstExamPassed: this.formBuilder.control(exam.firstExamPassed),
      secondExamDate: this.formBuilder.control(exam.secondExamDate),
      secondExamPassed: this.formBuilder.control(exam.secondExamPassed),
      examArea: this.formBuilder.control(exam.examArea),
      moduleName: this.formBuilder.control(exam.moduleName),
    });
  }

  createHousingGroup(housing: Housing): HousingForm {
    return this.formBuilder.group<HousingForm["controls"]>({
      id: this.formBuilder.control(housing.id),
      name: this.formBuilder.control(housing.name),
      cancellationOfContractSignDate: this.formBuilder.control(housing.cancellationOfContractSignDate),
      readinessForOccupancyDate: this.formBuilder.control(housing.readinessForOccupancyDate),
      movingInDate: this.formBuilder.control(housing.movingInDate),
      movingOutDate: this.formBuilder.control(housing.movingOutDate),
      coldRent: this.formBuilder.control(housing.coldRent),
      operatingCosts: this.formBuilder.control(housing.operatingCosts),
      additionalCosts: this.formBuilder.control(housing.additionalCosts),
      address: this.formBuilder.control(housing.address),
      city: this.formBuilder.control(housing.city),
      country: this.formBuilder.control(housing.country),
      federalState: this.formBuilder.control(housing.federalState),
      zipCode: this.formBuilder.control(housing.zipCode),
      nameOfContactPerson: this.formBuilder.control(housing.nameOfContactPerson),
      phoneOfContactPerson: this.formBuilder.control(housing.phoneOfContactPerson),
      keyHandoverDateTime: this.getDateTimeForm(
        housing.keyHandoverDateTime?.date,
        housing.keyHandoverDateTime?.hasTime
      ),
      comments: this.formBuilder.control(housing.comments),
    });
  }

  createEmployerHistoryGroup(entry: EmployerHistoryEntry): EmployerHistoryEntryFormDe {
    return this.formBuilder.group<EmployerHistoryEntryFormDe["controls"]>({
      id: this.formBuilder.control(entry.id),
      employerId: this.formBuilder.control(entry.employerId, Validators.required),
      employerName: this.formBuilder.control(entry.employerName, Validators.required),
      startDate: this.formBuilder.control(entry.startDate),
      endDate: this.formBuilder.control(entry.endDate),
      createdAt: this.formBuilder.control(entry.createdAt, Validators.required),
    });
  }

  setFormValue(form: CandidateForm, candidate: Candidate): void {
    const clone = cloneDeep(candidate);
    set(clone, "migration.de.recognitionPath.current.isCurrent", true);
    set(clone, "migration.de.recognitionPath.alternative.isCurrent", false);

    form.reset(undefined, { emitEvent: false });
    form.patchValue(clone, { emitEvent: false });

    const familyMembers = form.controls.family.controls.members;
    familyMembers.clear({ emitEvent: false });
    for (const member of clone.family?.members ?? []) {
      familyMembers.push(this.createFamilyMemberGroup(member), { emitEvent: false });
    }

    const residencePermits = form.controls.migration.controls.de.controls.residence.controls.residencePermits;
    residencePermits.clear({ emitEvent: false });
    for (const permit of clone.migration?.de?.residence?.residencePermits ?? []) {
      residencePermits.push(this.createResidencePermitGroup(permit), { emitEvent: false });
    }

    const qualificationEvaluations =
      form.controls.migration.controls.de.controls.qualificationEvaluation.controls?.qualificationEvaluations;
    qualificationEvaluations?.clear({ emitEvent: false });
    for (const qualificationEvaluation of clone.migration?.de?.qualificationEvaluation?.qualificationEvaluations ??
      []) {
      qualificationEvaluations.push(this.createQualificationEvaluationGroup(qualificationEvaluation), {
        emitEvent: false,
      });
    }

    this.resetInternalForm(form.controls.internal, candidate.internal);
    this.resetInternalForm(
      form.controls.licensedOrganizationData.controls.internal,
      candidate.licensedOrganizationData?.internal
    );

    const qualificationModulesDe =
      form.controls.migration.controls.de.controls.qualificationMeasure.controls.qualificationModules;
    qualificationModulesDe.clear({ emitEvent: false });
    for (const module of clone.migration?.de?.qualificationMeasure?.qualificationModules ?? []) {
      qualificationModulesDe.push(this.createQualificationModuleGroup(module), { emitEvent: false });
    }
    const qualificationExamsDe =
      form.controls.migration.controls.de.controls.qualificationMeasure.controls.qualificationExams;
    qualificationExamsDe.clear({ emitEvent: false });
    for (const module of clone?.migration?.de?.qualificationMeasure?.qualificationExams ?? []) {
      qualificationExamsDe.push(this.createQualificationExamGroup(module), { emitEvent: false });
    }

    const employerHistoryEntriesBeforeRecognitionDe =
      form.controls.migration.controls.de.controls.employmentRelationship.controls.beforeRecognition.controls
        .employerHistory;
    employerHistoryEntriesBeforeRecognitionDe.clear({ emitEvent: false });
    for (const entry of clone.migration?.de?.employmentRelationship?.beforeRecognition?.employerHistory ?? []) {
      employerHistoryEntriesBeforeRecognitionDe.push(this.createEmployerHistoryGroup(entry), { emitEvent: false });
    }

    const employerHistoryEntriesAfterRecognitionDe =
      form.controls.migration.controls.de.controls.employmentRelationship.controls.afterRecognition.controls
        .employerHistory;
    employerHistoryEntriesAfterRecognitionDe.clear({ emitEvent: false });
    for (const entry of clone.migration?.de?.employmentRelationship?.afterRecognition?.employerHistory ?? []) {
      employerHistoryEntriesAfterRecognitionDe.push(this.createEmployerHistoryGroup(entry), { emitEvent: false });
    }

    const employerHistoryEntriesBeforeRecognitionAt =
      form.controls.migration.controls.at.controls.employmentRelationship.controls.beforeRecognition.controls
        .employerHistory;
    employerHistoryEntriesBeforeRecognitionAt.clear({ emitEvent: false });
    for (const entry of clone.migration?.at?.employmentRelationship?.beforeRecognition?.employerHistory ?? []) {
      employerHistoryEntriesBeforeRecognitionAt.push(this.createEmployerHistoryGroup(entry), { emitEvent: false });
    }

    const employerHistoryEntriesAfterRecognitionAt =
      form.controls.migration.controls.at.controls.employmentRelationship.controls.afterRecognition.controls
        .employerHistory;
    employerHistoryEntriesAfterRecognitionAt.clear({ emitEvent: false });
    for (const entry of clone.migration?.at?.employmentRelationship?.afterRecognition?.employerHistory ?? []) {
      employerHistoryEntriesAfterRecognitionAt.push(this.createEmployerHistoryGroup(entry), { emitEvent: false });
    }

    const housings = form.controls.migration.controls.housingAcquisition.controls.housings;
    housings.clear({ emitEvent: false });
    for (const housing of clone.migration?.housingAcquisition?.housings ?? []) {
      housings.push(this.createHousingGroup(housing), { emitEvent: false });
    }

    // trigger valueChanges et al. events
    emitEvents(form);
  }

  private resetInternalForm(form: InternalForm, data: InternalCandidateData): void {
    const scholarships = form.controls.scholarships;
    scholarships.clear({ emitEvent: false });
    for (const scholarship of data?.scholarships ?? []) {
      scholarships.push(this.createScholarshipGroup(scholarship), { emitEvent: false });
    }
  }

  private getDateTimeForm(
    date: Date,
    hasTime: boolean
  ): FormGroup<{ date: FormControl<Date>; hasTime: FormControl<boolean> }> {
    return this.formBuilder.group({
      date: this.formBuilder.control(date),
      hasTime: this.formBuilder.control(hasTime),
    });
  }
}
