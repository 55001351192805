<ng-container *appTranslate="let t">
  <button
    pButton
    type="button"
    icon="pi pi-filter"
    class="p-button-rounded p-button-text ml-2"
    [class.p-button-active]="isActive"
    [disabled]="isDisabled"
    (click)="filterOverlay.show($event)"
    [pTooltip]="t('filter.title')"
  ></button>

  <p-overlayPanel #filterOverlay [style]="{ width: '800px' }" [dismissable]="false" [showCloseIcon]="true">
    <ng-template pTemplate>
      <div class="p-fluid" [formGroup]="form">
        <app-candidate-filter-preset-selector
          *appPermissions="[UserPermission.Administrator, UserPermission.User]"
          [form]="form"
          (filterChange)="onFilterChange($event)"
          class="mb-5"
        ></app-candidate-filter-preset-selector>

        <app-candidate-filter-set [form]="form" [filter]="filter" [fields]="fields"></app-candidate-filter-set>

        <div class="flex flex-row justify-content-end">
          <p-button
            closeOnEscape
            [label]="t('common.close')"
            icon="pi pi-times"
            class="p-button-secondary mr-auto"
            type="reset"
            (onClick)="filterOverlay.hide()"
          ></p-button>
          <p-button
            [disabled]="!isActive || !!presetSelector?.mode"
            [label]="t('collection.create')"
            icon="pi pi-bars"
            class="p-button-secondary mr-2"
            type="button"
            (onClick)="openCollectionCreateDialog()"
          ></p-button>
          <p-button
            [disabled]="!form.valid || !!presetSelector?.mode"
            [label]="t('filter.preset.create')"
            icon="pi pi-bookmark"
            class="p-button-secondary mr-2"
            type="button"
            (onClick)="addPreset()"
          ></p-button>
          <p-button
            [disabled]="!!presetSelector?.mode"
            [label]="t('common.clear')"
            icon="pi pi-filter-slash"
            class="p-button-secondary mr-2"
            type="button"
            (onClick)="clearFilter()"
          ></p-button>
          <p-button
            [disabled]="!form.valid"
            [label]="t('common.search')"
            icon="pi pi-search"
            type="submit"
            (onClick)="search()"
          ></p-button>
        </div>
      </div>
    </ng-template>
  </p-overlayPanel>

  <p-sidebar [visible]="showSidebar" styleClass="p-sidebar-md">
    <app-collection-and-sharing-create-dialog
      *ngIf="showSidebar"
      (saved)="saveCollection($event)"
      (closed)="closeCollectionCreateDialog()"
    ></app-collection-and-sharing-create-dialog>
  </p-sidebar>
</ng-container>
