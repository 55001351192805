import { Injectable } from "@angular/core";
import {
  AreOrganizationLinkedGQL,
  CreateOrganizationLinkGQL,
  DeleteOrganizationLinkGQL,
  GetAllOrganizationWhichLinkToGQL,
  GetOrganizationLinkGQL,
  GetSharingStateGQL,
  OrganizationLinkCreateInput,
  OrganizationLinkDeleteInput,
  OrganizationLinkFragment,
  OrganizationLinkUpdateInput,
  SharingState,
  SharingStateInput,
  UpdateOrganizationLinkGQL,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class OrganizationLinkService {
  constructor(
    private readonly linkExists: AreOrganizationLinkedGQL,
    private readonly linkGet: GetOrganizationLinkGQL,
    private readonly linkCreate: CreateOrganizationLinkGQL,
    private readonly linkUpdate: UpdateOrganizationLinkGQL,
    private readonly linkDelete: DeleteOrganizationLinkGQL,
    private readonly sharingState: GetSharingStateGQL,
    private readonly getAllOrganizationWhichLinkToGQL: GetAllOrganizationWhichLinkToGQL
  ) {}

  getAllOrganizationWhichLinkTo(organizationId: string): Observable<OrganizationLinkFragment[]> {
    return this.getAllOrganizationWhichLinkToGQL
      .fetch({ organizationId: organizationId })
      .pipe(map((x) => x.data.getAllOrganizationWhichLinkTo));
  }

  isOrganizationLinked(organizationId: string): Observable<boolean> {
    return this.linkExists
      .fetch({ input: { organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.areOrganizationLinked));
  }

  getSharingState(input: SharingStateInput): Observable<SharingState> {
    return this.sharingState.fetch({ input }, { fetchPolicy: "cache-first" }).pipe(map((x) => x.data.getSharingState));
  }

  get(organizationId: string, linkedOrganizationId: string): Observable<OrganizationLinkFragment> {
    return this.linkGet
      .fetch({ input: { organizationId, linkedOrganizationId } })
      .pipe(map((x) => x.data.getOrganizationLink));
  }

  create(link: OrganizationLinkCreateInput): Observable<OrganizationLinkFragment> {
    return this.linkCreate.mutate({ input: link }).pipe(map((x) => x.data.createOrganizationLink));
  }

  update(link: OrganizationLinkUpdateInput): Observable<OrganizationLinkFragment> {
    return this.linkUpdate.mutate({ input: link }).pipe(map((x) => x.data.updateOrganizationLink));
  }

  delete(link: OrganizationLinkDeleteInput): Observable<boolean> {
    return this.linkDelete
      .mutate(
        { input: link },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getLinkedOrganizations: (refs, { readField }) =>
                  refs.filter((ref) => readField("id", ref) !== link.linkedOrganizationId),
              },
            }),
        }
      )
      .pipe(map((x) => x.data.deleteOrganizationLink.status));
  }
}
