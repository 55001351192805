import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { EducationCourseForListFragment } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { PrimeIcons } from "primeng/api";
import { AppDatePipe } from "../../../../shared/pipes/date.pipe";
import { Color } from "../../../../shared/services/style.helper";
import { PipeDescription, TableColumn } from "../../../../shared/table/table.model";

type EducationCourseForTable = EducationCourseForListFragment & { capacity: string; overCapacity: boolean };

const nameOf = nameofFactory<EducationCourseForTable>();

@Component({
  selector: "app-education-course-selector",
  templateUrl: "./education-course-selector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class EducationCourseSelectorComponent {
  readonly columns = this.getColumns();

  coursesForTable: EducationCourseForTable[];

  @Input()
  set courses(courses: EducationCourseForListFragment[]) {
    this.coursesForTable = courses?.map((course) => ({
      ...course,
      capacity: `${course.participantCount}/${course.maxParticipants}`,
      overCapacity: course.participantCount > course.maxParticipants,
    }));
  }

  @Input()
  selectedCourse: EducationCourseForListFragment;

  @Output()
  readonly selectedCourseChange = new EventEmitter<EducationCourseForListFragment>();

  private getColumns(): TableColumn[] {
    return [
      {
        header: translate("name.title"),
        fieldname: nameOf("name"),
        sortable: true,
        includeInGlobalFilter: true,
      },
      {
        header: translate("capacity.title"),
        fieldname: nameOf("capacity"),
        className: (x: EducationCourseForTable): string => (x.overCapacity ? Color.Error : null),
        icon: (x: EducationCourseForTable): string => (x.overCapacity ? PrimeIcons.EXCLAMATION_CIRCLE : null),
        forceDisplayField: true,
      },
      {
        header: translate("startDate.title"),
        fieldname: nameOf("startDate"),
        sortable: true,
        pipeDescription: new PipeDescription(AppDatePipe, { dateStyle: "short" }),
      },
      {
        header: translate("endDate.title"),
        fieldname: nameOf("endDate"),
        sortable: true,
        pipeDescription: new PipeDescription(AppDatePipe, { dateStyle: "short" }),
      },
    ];
  }
}
