<ng-container *appTranslate="let t">
  <form [formGroup]="form">
    <p-fieldset [legend]="t('housingAcquisition.title')" class="relative">
      <app-candidate-document-upload
        [fileTypes]="['CERTOFHOUSING', 'RENTALAGREEMENT', 'REGCERT']"
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
      ></app-candidate-document-upload>
      <div class="p-fluid formgrid grid">
        <app-candidate-responsible-representative-group
          [form]="form"
          [readonly]="readonly"
          [candidateOrganizationId]="candidateOrganizationId"
          [candidateId]="candidateId"
          [alwaysShownRoles]="allowedRoles"
          [canHaveADeputyOrRepresentative]="canHaveADeputyOrRepresentative"
          [responsibleRolePurpose]="ResponsibleRolePurpose.HousingAcquisition"
          [purposeData]="housingAcquisition"
          styleRoleClass="field col-12 lg:col-12"
          styleOrganizationClass="field col-12 lg:col-12"
          styleRepresentativeClass="field col-12 lg:col-6"
          styleDeputyClass="field col-12 lg:col-6"
          class="p-fluid formgrid grid field col-12 lg:col-12"
        ></app-candidate-responsible-representative-group>
      </div>
    </p-fieldset>

    <div [controlRef]="form.controls.housings" class="emi-fullscreen">
      <ng-container *ngFor="let control of form.controls.housings.controls; index as i">
        <p-fieldset
          [legend]="t('housing.housingEntry.title', { index: i + 1 })"
          class="relative"
          formArrayName="housings"
        >
          <div class="flex flex-row gap-3 align-items-center mb-3" [formGroupName]="i">
            <app-candidate-housing-acquisition-entry
              [candidateId]="candidateId"
              [candidateOrganizationId]="candidateOrganizationId"
              [form]="control"
              [index]="i"
              [readonly]="readonly"
            ></app-candidate-housing-acquisition-entry>

            <div>
              <p-button icon="pi pi-times" [disabled]="readonly" (onClick)="removeHousing(i)"
                        [removeBtnRef]="form.controls.housings"></p-button>
            </div>
          </div>
        </p-fieldset>
      </ng-container>

      <div class="p-fluid formgrid grid align-items-center mt-3">
        <div class="field col-12 lg:col-3">
          <p-button
            icon="pi pi-plus"
            [label]="t('housing.add.title')"
            [disabled]="readonly || form.controls.housings.disabled"
            (onClick)="addHousing()"
            [addBtnRef]="form.controls.housings"
          ></p-button>
        </div>
      </div>
    </div>
  </form>
</ng-container>
