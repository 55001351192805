import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { EducationExamResultFullDataFragment } from "@ankaadia/graphql";
import { ExamFragment } from "../../../education/education-exams/education-exam-table/education-exam-table.component";

@Component({
  selector: "app-candidate-education-exam-results-dialog",
  templateUrl: "./candidate-education-exam-results-dialog.component.html",
  standalone: false,
})
export class CandidateEducationExamResultsDialogComponent implements OnChanges {
  @Input({ required: true }) exams: ExamFragment[];
  @Input({ required: true }) examResults: Partial<EducationExamResultFullDataFragment>[];

  @Output()
  readonly saved = new EventEmitter<ExamFragment>();

  @Output()
  readonly closed = new EventEmitter<void>();

  protected exam: ExamFragment;
  protected options: ExamFragment[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.exams || changes.examResults) {
      this.options = this.exams.filter((exam) => !this.examResults.some((result) => result.examId === exam.id));
    }
  }

  save(): void {
    this.saved.emit(this.exam);
  }

  cancel(): void {
    this.closed.emit();
  }
}
