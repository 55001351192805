import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { ActivityModule } from "../activity/activity.module";
import { CandidateDocumentMetadataModule } from "../candidate-document-metadata/candidate-document-metadata.module";
import { CandidateEventsModule } from "../candidate-events/candidate-events.module";
import { CandidateFilterModule } from "../candidate-filter/candidate-filter.module";
import { CandidateFormModule } from "../candidate-form/candidate-form.module";
import { CandidateNotesModule } from "../candidate-notes/candidate-notes.module";
import { CandidateReusablesModule } from "../candidate-reusables/candidate-reusables.module";
import { CollectionsModule } from "../collections/collections.module";
import { ContractTemplateModule } from "../contract-template/contract-template.module";
import { DocumentTemplateModule } from "../document-template/document-template.module";
import { DocumentsModule } from "../documents/documents.module";
import { EducationModule } from "../education/education.module";
import { EmailsModule } from "../emails/emails.module";
import { FavoriteModule } from "../favorite/favorite.module";
import { ImportModule } from "../import/import.module";
import { OrganizationsModule } from "../organizations/organizations.module";
import { ProfessionModule } from "../profession/profession.module";
import { RequiredDocumentsModule } from "../required-documents/required-documents.module";
import { UsersModule } from "../users/users.module";
import { CandidateCardComponent } from "./candidate-card/candidate-card.component";
import { CandidateAssignCollectionsComponent } from "./candidate-collections/candidate-assign-collections/candidate-assign-collections.component";
import { CandidateCollectionsComponent } from "./candidate-collections/candidate-collections.component";
import { CandidateDocumentEditDialogComponent } from "./candidate-document-edit-dialog/candidate-document-edit-dialog.component";
import { CandidateDocumentTemplatesComponent } from "./candidate-document-templates/candidate-document-templates.component";
import { CandidateEditComponent } from "./candidate-edit/candidate-edit.component";
import { CandidateEducationCoursePerformanceDialogComponent } from "./candidate-education-course-performance/candidate-education-course-performance-dialog/candidate-education-course-performance-dialog.component";
import { CandidateEducationCoursePerformanceComponent } from "./candidate-education-course-performance/candidate-education-course-performance.component";
import { CandidateEducationExamResultsDialogComponent } from "./candidate-education-exam-results/candidate-education-exam-results-dialog/candidate-education-exam-results-dialog.component";
import { CandidateEducationExamResultsComponent } from "./candidate-education-exam-results/candidate-education-exam-results.component";
import { CandidateEducationExamsAddToExamDialogComponent } from "./candidate-education-exams/candidate-education-exams-add-to-exam-dialog/candidate-education-exams-add-to-exam-dialog.component";
import { CandidateEducationExamsComponent } from "./candidate-education-exams/candidate-education-exams.component";
import { CandidateEducationComponent } from "./candidate-education/candidate-education.component";
import { CandidateFavoriteFilterComponent } from "./candidate-favorite-filter/candidate-favorite-filter.component";
import { CandidateFieldDiffComponent } from "./candidate-field-diff/candidate-field-diff.component";
import { CandidateFilterComponent } from "./candidate-filter/candidate-filter.component";
import { CandidateFormComponent } from "./candidate-form/candidate-form.component";
import { CandidateImageComponent } from "./candidate-image/candidate-image.component";
import { CandidateInterviewsFeedbackComponent } from "./candidate-interviews-feedback/candidate-interviews-feedback.component";
import { FeedbackVotingComponent } from "./candidate-interviews-feedback/feedback-voting/feedback-voting.component";
import { InterviewIterationDetailsComponent } from "./candidate-interviews-feedback/interview-iteration-details/interview-iteration-details.component";
import { InterviewIterationSelectorComponent } from "./candidate-interviews-feedback/interview-iteration-selector/interview-iteration-selector.component";
import { CandidateMultiEditPreviewDialogComponent } from "./candidate-multi-edit-preview-dialog/candidate-multi-edit-preview-dialog.component";
import { CandidateMultiEditPreviewTooltipPipe } from "./candidate-multi-edit-preview/candidate-multi-edit-preview-tooltip.pipe";
import { CandidateMultiEditPreviewComponent } from "./candidate-multi-edit-preview/candidate-multi-edit-preview.component";
import { CandidateMultiEditToggleComponent } from "./candidate-multi-edit-toggle/candidate-multi-edit-toggle.component";
import { CandidateCommentBoxComponent } from "./candidate-presentation-view/candidate-comment-box/candidate-comment-box.component";
import { CandidateCommentsComponent } from "./candidate-presentation-view/candidate-comments/candidate-comments.component";
import { CommentBoxDirective } from "./candidate-presentation-view/candidate-comments/comment-box.directive";
import { CandidateFeedbackComponent } from "./candidate-presentation-view/candidate-feedback/candidate-feedback.component";
import { CandidateFilterPipe } from "./candidate-presentation-view/candidate-filter/candidate-filter.pipe";
import { CandidatePresentationViewComponent } from "./candidate-presentation-view/candidate-presentation-view.component";
import { CandidateProfileFieldPathPipe } from "./candidate-profile-field-select/candidate-profile-field-path.pipe";
import { CandidateProfileFieldSelectComponent } from "./candidate-profile-field-select/candidate-profile-field-select.component";
import { CandidateProfileFieldValuePipe } from "./candidate-profile-field-select/candidate-profile-field-value.pipe";
import { CandidateProfileValueComponent } from "./candidate-profile-field-select/candidate-profile-value/candidate-profile-value.component";
import { CandidateSelectionComponent } from "./candidate-selection/candidate-selection.component";
import { CandidateTranslationDialogComponent } from "./candidate-translation-dialog/candidate-translation-dialog.component";
import { CandidatesComponent } from "./candidates.component";
import { CandidateFlexibleEditComponent } from "./candidate-flexible-edit/candidate-flexible-edit.component";
import { CandidateFlexibleEditButtonComponent } from "./candidate-flexible-edit/candidate-flexible-edit-button/candidate-flexible-edit-button.component";
import { CandidateLicenseOwnerSpecificDataComponent } from "./candidate-license-owner-specific-data/candidate-license-owner-specific-data.component";

@NgModule({
  imports: [
    SharedModule,
    DocumentsModule,
    EmailsModule,
    ImportModule,
    DocumentTemplateModule,
    RequiredDocumentsModule,
    OrganizationsModule,
    ProfessionModule,
    CollectionsModule,
    UsersModule,
    FavoriteModule,
    CandidateNotesModule,
    CandidateEventsModule,
    CandidateDocumentMetadataModule,
    CandidateFilterModule,
    CandidateReusablesModule,
    ActivityModule,
    EducationModule,
    CandidateFormModule,
    ContractTemplateModule,
    CandidateFlexibleEditButtonComponent,
  ],
  declarations: [
    CandidateCardComponent,
    CandidateCollectionsComponent,
    CandidateAssignCollectionsComponent,
    CandidateEditComponent,
    CandidatePresentationViewComponent,
    CandidateCommentBoxComponent,
    CandidateCommentsComponent,
    CommentBoxDirective,
    CandidateFeedbackComponent,
    CandidateFilterPipe,
    CandidateSelectionComponent,
    CandidatesComponent,
    CandidateFilterComponent,
    CandidateDocumentTemplatesComponent,
    CandidateFavoriteFilterComponent,
    CandidateImageComponent,
    CandidateInterviewsFeedbackComponent,
    InterviewIterationSelectorComponent,
    InterviewIterationDetailsComponent,
    FeedbackVotingComponent,
    CandidateProfileFieldSelectComponent,
    CandidateProfileValueComponent,
    CandidateProfileFieldPathPipe,
    CandidateProfileFieldValuePipe,
    CandidateMultiEditToggleComponent,
    CandidateMultiEditPreviewComponent,
    CandidateMultiEditPreviewTooltipPipe,
    CandidateMultiEditPreviewDialogComponent,
    CandidateFieldDiffComponent,
    CandidateEducationComponent,
    CandidateEducationCoursePerformanceComponent,
    CandidateDocumentEditDialogComponent,
    CandidateEducationExamsComponent,
    CandidateEducationExamsAddToExamDialogComponent,
    CandidateEducationExamResultsComponent,
    CandidateEducationExamResultsDialogComponent,
    CandidateTranslationDialogComponent,
    CandidateEducationCoursePerformanceDialogComponent,
    CandidateLicenseOwnerSpecificDataComponent,
    CandidateFormComponent,
    CandidateFlexibleEditComponent,
  ],
  exports: [
    CandidatesComponent,
    CandidatePresentationViewComponent,
    CandidateProfileFieldSelectComponent,
    CandidateProfileValueComponent,
    CandidateProfileFieldPathPipe,
    CandidateProfileFieldValuePipe,
    CandidateEditComponent,
    CandidateFlexibleEditComponent,
  ],
})
export class CandidatesModule {}
