import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { getAllRecognitionPaymentRoles, nameofFactory } from "@ankaadia/ankaadia-shared";
import { RecognitionPathEntryDe, StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { Observable, map } from "rxjs";
import { StaticDataService } from "../../../shared/static-data/static-data.service";

const nameOf = nameofFactory<RecognitionPathEntryDe>();

@Component({
  selector: "app-candidate-recognition-payment-role-accelerated",
  templateUrl: "./candidate-recognition-payment-role-accelerated.component.html",
  standalone: false,
})
export class CandidateRecognitionPaymentRoleAcceleratedComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    private readonly transloco: TranslocoService,
    private readonly staticDataService: StaticDataService
  ) {}

  allowedRoles: string[] = getAllRecognitionPaymentRoles();

  protected readonly language = this.transloco.getActiveLang();
  protected roles$: Observable<StaticDataModel[]> = this.staticDataService
    .getStaticData(StaticDataType.ProcessRole, this.language)
    .pipe(map((x) => x.filter((y) => this.allowedRoles.includes(y.value))));

  @Input()
  renderLabelUsingAlignmentCheckBox = false;

  @Input()
  useFieldLabel: boolean;

  @Input()
  formId: string;

  @Input()
  form: FormGroup;

  @Input()
  enabled = false;

  @Input()
  readonly: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.enabled) {
      this.initControlIfMissing();
      if (changes.enabled.currentValue) this.control.enable();
      else this.control.disable();
    }
  }

  ngOnInit(): void {
    this.initControlIfMissing();
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("recognitionPaymentRoleAccelerated"), { emitEvent: false });
  }

  get control(): FormControl<string> {
    return this.form.get(nameOf("recognitionPaymentRoleAccelerated")) as FormControl<string>;
  }

  set control(value: FormControl<string>) {
    this.form.setControl(nameOf("recognitionPaymentRoleAccelerated"), value);
  }

  private initControlIfMissing(): void {
    if (!this.control) this.control = new FormControl<string>(undefined);
  }
}
