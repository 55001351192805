import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-notification-badge",
  templateUrl: "./notification-badge.component.html",
  styleUrl: "./notification-badge.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class NotificationBadgeComponent {
  @Input()
  count: number;
}
