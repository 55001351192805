import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { PictureType, ensure4LetterIsoLanguage, getImageBlobName } from "@ankaadia/ankaadia-shared";
import { Sharing } from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { ConfirmationService, MenuItem, PrimeIcons } from "primeng/api";
import Quill from "quill";
import { Delta } from "quill/core";
import { Subscription, forkJoin, of } from "rxjs";
import { MessageDialogService } from "../../../shared/message-dialog/message-dialog.service";
import { loadPixoEditorSDK, pixoEditorApiKey } from "../../../shared/pixoeditor/pixoeditor-sdk";
import { DownloadService } from "../../../shared/services/download.service";
import { FileUploadService } from "../../../shared/services/file-upload.service";
import { SettingsService } from "../../../shared/services/settings.service";
import { CandidatesService } from "../../candidates/candidates.service";
import { DocumentsService } from "../../documents/documents.service";
import { MessageService } from "../../message/message.service";
import { OrganizationsService } from "../../organizations/organizations.service";
import { PresentationForm } from "../candidate-form.model";
import { CandidatePresentationService } from "./candidate-presentation.service";

@Component({
  selector: "app-candidate-presentation",
  templateUrl: "./candidate-presentation.component.html",
  styleUrl: "./candidate-presentation.component.scss",
  providers: [FileUploadService],
  standalone: false,
})
export class CandidatePresentationComponent implements OnInit, OnChanges, OnDestroy {
  readonly language = this.transloco.getActiveLang();

  private editor: Quill;
  private onUploadSub: Subscription;
  private onFileErrorSub: Subscription;
  private onProgressSub: Subscription;

  protected downloadUrl1: string;
  protected downloadUrl2: string;
  protected pictureType: PictureType = "picture";
  protected hasPicture: boolean;

  translation: Delta;

  @ViewChild("uploadInput")
  uploadRef: ElementRef;

  @ViewChild("image")
  imageRef: ElementRef;

  @Input()
  form: PresentationForm;

  @Input()
  readonly: boolean;

  @Input()
  sharing: Sharing;

  @Input()
  candidateId: string;

  @Input()
  candidateOrganizationId: string;

  fileMenu: MenuItem[];
  progress: number = null;

  get downloadUrl(): string {
    switch (this.pictureType) {
      case "picture":
        return this.downloadUrl1;
      case "alternative-picture":
        return this.downloadUrl2;
      default:
        throw new Error("Unsupported picture type");
    }
  }

  get autoCVConfigured(): boolean {
    return this.sharing?.autoCVRequested || this.settings.autoCVConfigured;
  }

  private get hasSharedCV(): boolean {
    return this.settings.isLicensed && this.sharing && this.settings.organizationId !== this.sharing?.organizationId;
  }

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly confirmationService: ConfirmationService,
    private readonly documentService: DocumentsService,
    private readonly candidateService: CandidatesService,
    private readonly fileUploadService: FileUploadService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly errorService: MessageDialogService,
    private readonly candidatePresentationService: CandidatePresentationService,
    private readonly transloco: TranslocoService,
    private readonly settings: SettingsService,
    private readonly messageService: MessageService,
    private readonly downloadService: DownloadService,
    private readonly orgService: OrganizationsService
  ) {}

  ngOnDestroy(): void {
    this.onFileErrorSub.unsubscribe();
    this.onProgressSub.unsubscribe();
    this.onUploadSub.unsubscribe();
  }

  ngOnInit(): void {
    this.onUploadSub = this.fileUploadService.onUpload.subscribe(() => this.reloadImages());
    this.onFileErrorSub = this.fileUploadService.onError.subscribe((x) => this.onError(x));
    this.onProgressSub = this.fileUploadService.onProgress.subscribe((x) => (this.progress = x));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.candidateId != null && this.candidateOrganizationId != null) {
      const candidateOrganizationId = this.candidateOrganizationId;
      forkJoin([
        this.candidateService.getPictureDownloadURL(candidateOrganizationId, this.candidateId, "picture"),
        this.candidateService.getPictureDownloadURL(candidateOrganizationId, this.candidateId, "alternative-picture"),
      ]).subscribe(([downloadUrl1, downloadUrl2]) => {
        this.downloadUrl1 = downloadUrl1;
        this.downloadUrl2 = downloadUrl2;
      });
    } else {
      this.downloadUrl1 = null;
      this.downloadUrl2 = null;
      this.changeDetector.detectChanges();
    }

    if (changes.form) {
      this.form.controls.recommendationNote.valueChanges.subscribe(() => this.updateEditor(this.editor));
    }

    if (changes.candidateId) {
      this.updateEditor(this.editor);
    }
    if (changes.selectedOrganization || changes.sharing) {
      this.populateFileMenu();
    }
  }

  onEdit(): void {
    loadPixoEditorSDK().subscribe(() => {
      const editor = new (<any>window).Pixo.Bridge({
        apikey: pixoEditorApiKey,
        type: "modal",
        height: "80%",
        width: "80%",
        theme: "Light",
        language: this.language,
        output: {
          format: "jpeg",
          quality: 0.6,
          optimize: true,
        },
        styles: { logosrc: "none" },
        // features: ["crop", "transform", "adjustments"],
        filterCropSizes(): [{ caption: string; value: number }] {
          return [{ caption: translate("pixo.square"), value: 1 }];
        },
        onSave: (arg): void => {
          this.documentService
            .getUploadUrl(
              getImageBlobName(this.candidateId, this.pictureType),
              this.candidateOrganizationId,
              this.candidateId,
              true
            )
            .subscribe(({ url }) => this.startUpload(arg.toBlob() as Blob, url));
        },
      });
      editor.edit(this.imageRef.nativeElement);
    });
  }

  onUploadClick(): void {
    this.uploadRef.nativeElement.click();
  }

  onDeleteClick(event: MouseEvent): void {
    const accept = (): void => {
      this.documentService
        .deleteBlob({
          blobName: getImageBlobName(this.candidateId, this.pictureType),
          organizationId: this.candidateOrganizationId,
          candidateId: this.candidateId,
          fromImageContainer: true,
        })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.reloadImages());
    };

    this.confirmationService.confirm({
      target: event.target,
      acceptLabel: translate("common.delete"),
      rejectLabel: translate("common.cancel"),
      message: translate("candidate.image.confirmDelete"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => accept(),
    });
  }

  onFilesSelected(): void {
    if (this.uploadRef.nativeElement.files.length > 0) {
      const file = this.uploadRef.nativeElement.files[0];
      this.documentService
        .getUploadUrl(
          getImageBlobName(this.candidateId, this.pictureType),
          this.candidateOrganizationId,
          this.candidateId,
          true
        )
        .subscribe(({ url }) => this.startUpload(file, url));
    }
    this.uploadRef.nativeElement.value = null;
    this.uploadRef.nativeElement.files = null;
  }

  startUpload(file: Blob | File, url: string): void {
    this.progress = 0;
    this.fileUploadService.upload(file, url, null, "image");
  }

  private onError(event: any): void {
    this.errorService.showMessage(translate("file.uploadFailed"), event.message);
  }

  updateEditor(editor: Quill): void {
    if (editor) {
      this.editor = editor;
      this.editor.setContents(this.form.controls.recommendationNote.value?.delta, "silent");
    }
  }

  updateHtml(_contents: string, source: string): void {
    if (source === "user") {
      this.form.controls.recommendationNote.setValue(
        {
          contents: this.editor.getText(),
          delta: this.editor.getContents(),
        },
        { emitEvent: false }
      );
      this.form.markAsDirty();
    }
  }

  private populateFileMenu(): void {
    if (this.hasSharedCV) {
      this.orgService.getOrganization(this.sharing?.organizationId).subscribe((organization) => {
        this.fileMenu = [
          this.downloadFor(this.settings.organizationId, this.settings.organizationName),
          this.downloadFor(this.sharing.organizationId, organization.name),
        ];
      });
    }
  }

  downloadCVasWord(organizationId: string): void {
    this.candidateService
      .generateCVAsDocx(this.candidateId, this.candidateOrganizationId, organizationId, this.language)
      .subscribe((result) => {
        const observ =
          !result && ensure4LetterIsoLanguage(this.language) != "de-DE"
            ? this.candidateService.generateCVAsDocx(
                this.candidateId,
                this.candidateOrganizationId,
                organizationId,
                "de-DE"
              )
            : of(result);
        observ.subscribe((result) => {
          if (result != null) {
            this.downloadService.downloadFile(result.fileName, result.url);
          } else {
            this.messageService.add({
              severity: "error",
              summary: this.transloco.translate("candidate.presentation.errorCV"),
            });
          }
        });
      });
  }

  private reloadImages(): void {
    const temp1 = this.downloadUrl1;
    const temp2 = this.downloadUrl2;
    setTimeout(() => {
      this.progress = null;
      this.downloadUrl1 = "";
      this.downloadUrl2 = "";
      this.imageRef.nativeElement.click(); //Trick for reloading the image
      this.changeDetector.detectChanges();
    }, 0);
    setTimeout(() => {
      this.downloadUrl1 = temp1;
      this.downloadUrl2 = temp2;
      this.imageRef.nativeElement.click();
      this.changeDetector.detectChanges();
    }, 30);
    this.candidatePresentationService.imageChanged.next();
    this.documentService.notifyProfilePictureUpdated(this.candidateOrganizationId, this.candidateId).subscribe();
  }

  private downloadFor(orgId: string, name: string): MenuItem {
    return {
      icon: PrimeIcons.FILE,
      label: translate("sharing.cvOrganizationName", { organizationName: name }),
      command: (): void => this.downloadCVasWord(orgId),
    };
  }
}
