import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { OrganizationContactForSelectionFragment } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { BehaviorSubject, Observable, ReplaySubject, Subscription, combineLatest, debounceTime, map } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { OrganizationLinkService } from "../../organizations/organization-link.service";
import {
  CandidateOrganizationContactsData,
  CandidateOrganizationContactsService,
} from "../candidate-organization-contacts.service";
import {
  CandidateResponsibleRepresentativeGroupService,
  ResponsibleRepresentativePurpose,
} from "../candidate-responsible-representative-group/candidate-responsible-representative-group.service";

export interface ResponsibleRepresentativeTranslations {
  caption: string;
  placeholder: string;
  required: string;
}

export interface ResponsibleRepresentativeControlNames {
  id: string;
  firstName: string;
  lastName: string;
}

export interface ResponsibleRepresentativeModelData {
  id: string;
  firstName: string;
  lastName: string;
}

export interface ResponsibleRepresentativePurposeConfig {
  getControlNames: () => ResponsibleRepresentativeControlNames;
  getTranslationKey: (language: string) => ResponsibleRepresentativeTranslations;
}

@Component({
  selector: "app-candidate-responsible-representative",
  templateUrl: "./candidate-responsible-representative.component.html",
  standalone: false,
})
export class CandidateResponsibleRepresentativeComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  organizationContacts: OrganizationContactForSelectionFragment[];
  representativePlaceholder: string;

  private readonly language = this.transloco.getActiveLang();
  private readonly processLanguage$ = new ReplaySubject<string>(1);
  private readonly defaultValueAsPlaceholder$ = new BehaviorSubject<string>(undefined);

  private organizationChangeSubscription?: Subscription;
  protected translations$ = this.getTranslations();

  @Input({ required: true })
  form: FormGroup;

  @Input({ required: true })
  contactOrganizationId: string;

  @Input({ required: true })
  candidateOrganizationId: string;

  @Input({ required: true })
  responsibleRepresentativePurpose: ResponsibleRepresentativePurpose;

  @Input()
  modelData?: ResponsibleRepresentativeModelData;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  readonly = false;

  @Input()
  showControl = true;

  @Input()
  candidateId: string = null;

  @Input()
  setDefaultOnChange = true;

  @Input()
  showDefaultAsPlaceholder = true;

  @Output()
  readonly valueChanged = new EventEmitter<string>();

  get control(): FormControl<string> {
    return this.form.get(this.controlNames.id) as FormControl<string>;
  }

  set control(value: FormControl<string>) {
    this.form.setControl(this.controlNames.id, value);
  }

  get firstName(): FormControl<string> {
    return this.form.get(this.controlNames.firstName) as FormControl<string>;
  }

  set firstName(value: FormControl<string>) {
    this.form.setControl(this.controlNames.firstName, value);
  }

  get lastName(): FormControl<string> {
    return this.form.get(this.controlNames.lastName) as FormControl<string>;
  }

  set lastName(value: FormControl<string>) {
    this.form.setControl(this.controlNames.lastName, value);
  }

  protected isShared: boolean;

  constructor(
    private readonly contactsService: CandidateOrganizationContactsService,
    private readonly settingsService: SettingsService,
    private readonly transloco: TranslocoService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly linkService: OrganizationLinkService,
    private readonly groupService: CandidateResponsibleRepresentativeGroupService
  ) {}

  ngOnInit(): void {
    this.initControls();
    this.control.valueChanges.subscribe((representativeId) => {
      const representative = this.organizationContacts?.find((x) => x.id === representativeId);
      if (!this.readonly) {
        this.firstName.setValue(representative?.firstName ?? null);
        this.lastName.setValue(representative?.lastName ?? null);
      }
    });

    this.processLanguage$.next(this.processLanguage);
  }

  ngAfterViewInit(): void {
    this.setEnabled(this.showControl, this.readonly);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initControls();

    if (changes.contactOrganizationId) {
      const organizationId = changes.contactOrganizationId?.currentValue ?? this.contactOrganizationId;

      if (!organizationId) {
        this.control.setValue(null);
        this.lastName.setValue(null);
        this.firstName.setValue(null);
        this.defaultValueAsPlaceholder$.next(null);
      } else this.setDropdownData(organizationId);
    }
    if (changes.showControl) {
      this.setEnabled(changes.showControl.currentValue, this.readonly);
    }
    if (changes.readonly) {
      this.setEnabled(this.showControl, changes.readonly.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.organizationChangeSubscription?.unsubscribe();
    this.form.removeControl(this.controlNames.id, { emitEvent: false });
    this.form.removeControl(this.controlNames.firstName, { emitEvent: false });
    this.form.removeControl(this.controlNames.lastName, { emitEvent: false });
  }

  get controlNames(): ResponsibleRepresentativeControlNames {
    return this.groupService
      .getResponsibleRepresentativePurposeConfig(this.responsibleRepresentativePurpose)
      .getControlNames();
  }

  private setDropdownData(organizationId: string): void {
    this.organizationChangeSubscription?.unsubscribe();

    this.organizationChangeSubscription = combineLatest([
      this.contactsService.getContacts(organizationId),
      this.linkService.getSharingState({
        candidateOwnerOrganizationId: this.candidateOrganizationId,
        userOrganizationId: this.settingsService.organizationId,
        contactIds: [this.control.value].filter((x) => x != null),
        targetOrganizationId: organizationId,
      }),
    ]).subscribe(([contacts, sharingState]) => {
      this.organizationContacts = contacts.contacts;
      if (this.showDefaultAsPlaceholder) {
        this.defaultValueAsPlaceholder$.next(this.getRepresentativeOrDeputy(contacts)?.placeholder);
      }
      if (this.getRepresentativeOrDeputy(contacts)?.default?.id && this.setDefaultOnChange)
        this.control.setValue(this.getRepresentativeOrDeputy(contacts).default.id);
      this.isShared =
        sharingState.contactsSharedByTargetOrganization &&
        (sharingState.specificContactsSharedByTargetOrganization.length == 0 ||
          (sharingState.specificContactsSharedByTargetOrganization ?? []).find((x) => x.id === this.control.value)
            ?.shared);
      this.changeDetector.detectChanges();
    });
  }

  private getTranslations(): Observable<ResponsibleRepresentativeTranslations> {
    const translations$ = this.processLanguage$.pipe(
      map((language) =>
        this.groupService
          .getResponsibleRepresentativePurposeConfig(this.responsibleRepresentativePurpose)
          .getTranslationKey(language)
      )
    );

    return combineLatest([translations$, this.defaultValueAsPlaceholder$]).pipe(
      debounceTime(50),
      map(([translation, defaultAsPlaceholder]) => ({
        ...translation,
        placeholder: defaultAsPlaceholder ?? translation.placeholder,
      }))
    );
  }

  private initControls(): void {
    if (!this.control) this.control = new FormControl<string>(this.modelData?.id);
    if (!this.firstName) this.firstName = new FormControl<string>(this.modelData?.firstName);
    if (!this.lastName) this.lastName = new FormControl<string>(this.modelData?.lastName);
  }

  private getRepresentativeOrDeputy(data: CandidateOrganizationContactsData): {
    placeholder: string;
    default: OrganizationContactForSelectionFragment;
  } {
    return this.responsibleRepresentativePurpose.function === "Representative" ? data.representative : data.firstDeputy;
  }

  private setEnabled(enable: boolean, readonly: boolean): void {
    if (enable && !readonly) {
      this.form.get(this.controlNames.id)?.enable({ emitEvent: false });
      this.form.get(this.controlNames.firstName)?.enable({ emitEvent: false });
      this.form.get(this.controlNames.lastName)?.enable({ emitEvent: false });
    } else {
      this.form.get(this.controlNames.id)?.disable({ emitEvent: false });
      this.form.get(this.controlNames.firstName)?.disable({ emitEvent: false });
      this.form.get(this.controlNames.lastName)?.disable({ emitEvent: false });
    }
  }
}
