import {
  AfterViewInit,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { RecognitionPathEntryDe, StaticDataModel } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { map, switchMap } from "rxjs";
import { StaticDataContextEntry } from "../../../shared/static-data/static-data.service";
import { RecognitionAuthorityService } from "../../recognition-authorities/recognition-authorities.service";

const nameOf = nameofFactory<RecognitionPathEntryDe>();

@Component({
  selector: "app-candidate-recognition-authority",
  templateUrl: "./candidate-recognition-authority.component.html",
  standalone: false,
})
export class CandidateRecognitionAuthorityComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private readonly language = this.transloco.getActiveLang();

  @Input()
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  protected recognitionAuthorities: StaticDataModel[];

  @Input()
  staticDataContext: StaticDataContextEntry;

  @Input()
  federalStateControl: FormControl<string>;

  @Input()
  enableOnDestroy = true;

  @Input()
  hideControl: boolean;

  @Input()
  readonly: boolean;

  @Output()
  readonly valueChanged = new EventEmitter<string>();

  get control(): FormControl<string> {
    return this.form.get(nameOf("recognitionAuthority")) as FormControl<string>;
  }

  set control(value: FormControl<string>) {
    this.form.setControl(nameOf("recognitionAuthority"), value);
  }

  constructor(
    private readonly recognitionAuthorityService: RecognitionAuthorityService,
    private readonly transloco: TranslocoService,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<string>(null);
    this.recognitionAuthorityService
      .getRecognitionAuthorities(this.staticDataContext.organizationId, this.staticDataContext.immigrationCountry)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        this.recognitionAuthorities = data;
        this.control.valueChanges.subscribe((value) => this.valueChanged.emit(value));
      });
  }

  ngAfterViewInit(): void {
    this.setEnabled(!this.readonly);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.federalStateControl?.currentValue) {
      this.initFederalStateControl();
    }

    if (changes.readonly) {
      this.setEnabled(!changes.readonly.currentValue);
    }
  }

  ngOnDestroy(): void {
    if (this.enableOnDestroy) {
      this.form.removeControl(nameOf("recognitionAuthority"), { emitEvent: false });
    }
  }

  private initFederalStateControl(): void {
    this.federalStateControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map((federalState) => (federalState == null ? null : [federalState])),
        switchMap((federalStates) =>
          this.recognitionAuthorityService.getRecognitionAuthorities(
            this.staticDataContext.organizationId,
            this.staticDataContext.immigrationCountry,
            federalStates
          )
        )
      )
      .subscribe((data) => {
        if (this.control != null) {
          const value = this.control.value;
          this.recognitionAuthorities = data;
          const newValue = data.length === 1 ? data[0].value : value;
          this.control.setValue(newValue);
        }
      });
  }

  private setEnabled(enabled: boolean): void {
    if (enabled) {
      this.control?.enable({ emitEvent: false });
    } else {
      this.control?.disable({ emitEvent: false });
    }
  }
}
