import { Directive, HostBinding, HostListener, Input } from "@angular/core";

@Directive({ selector: "img[default]", standalone: false })
export class ImagePreloadDirective {
  @HostBinding("src") @Input() src: string;
  @Input() default: string;
  @HostBinding("class") className: string;

  @HostListener("error")
  updateUrl(): void {
    this.src = this.default;
  }

  @HostListener("load")
  load(): void {
    this.className = "image-loaded";
  }
}
