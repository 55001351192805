import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { ResidencePermit, StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { toPairs } from "lodash";
import { Observable, map, startWith } from "rxjs";
import { AppDatePipe } from "../../../shared/pipes/date.pipe";
import { StaticDataService } from "../../../shared/static-data/static-data.service";

const nameOf = nameofFactory<ResidencePermit>();

interface Expiry {
  key: string;
  meta: ExpiryMeta;
}

interface ExpiryMeta {
  date: Date;
  alternatives: [string, ...string[]];
}

const residencePermitExpiryDates: Record<string, ExpiryMeta> = {
  RESIDENCEACT16D: {
    date: new Date(2024, 2, 31),
    alternatives: ["RESIDENCEACT16D-ABS1", "RESIDENCEACT16D-ABS3"],
  },
  RESIDENCEACT20: {
    date: new Date(2024, 4, 31),
    alternatives: ["RESIDENCEACT20-2"],
  },
};

@Component({
  selector: "app-candidate-residence-permit-warnings",
  templateUrl: "./candidate-residence-permit-warnings.component.html",
  standalone: false,
})
export class CandidateResidencePermitWarningsComponent implements OnInit, OnChanges {
  private readonly expiries = toPairs(residencePermitExpiryDates).map(([key, meta]) => ({ key, meta }));
  private readonly language = this.transloco.getActiveLang();

  protected residencePermits: StaticDataModel[] = [];
  protected expiry$: Observable<Expiry>;

  @Input({ required: true })
  protected form: FormGroup;

  @Input()
  protected processLanguage: string = this.language;

  @Input()
  protected styleClass = "";

  protected get control(): FormControl<string> {
    return this.form.get(nameOf("residencePermit")) as FormControl<string>;
  }

  constructor(
    private readonly transloco: TranslocoService,
    private readonly datePipe: AppDatePipe,
    private readonly staticDataService: StaticDataService
  ) {}

  ngOnInit(): void {
    this.initResidencePermits();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.processLanguage) {
      this.initResidencePermits();
    }

    if (changes.form) {
      this.expiry$ = this.control.valueChanges.pipe(
        startWith(this.control.value),
        map((value) => this.expiries.find(({ key, meta }) => value === key && new Date(Date.now()) >= meta.date))
      );
    }
  }

  protected buildWarning(expiry: Expiry, language: string): string {
    const params = {
      permit: this.getLabel(expiry.key),
      date: this.datePipe.transform(expiry.meta.date, { dateStyle: "short" }, null),
      alternatives: expiry.meta.alternatives.map((alternative) => `"${this.getLabel(alternative)}"`).join(", "),
    };

    return this.transloco.translate("residencePermit.errors.outdated", params, language);
  }

  private initResidencePermits(): void {
    this.staticDataService
      .getStaticData(StaticDataType.ResidencePermits, this.processLanguage)
      .subscribe((residencePermits) => (this.residencePermits = residencePermits));
  }

  private getLabel(value: string): string {
    return this.residencePermits.find((permit) => permit.value === value)?.label;
  }
}
