<ng-container *appTranslate="let t">
  <form [formGroup]="form">
    <p-fieldset [legend]="t('qualificationEvaluation.title')">
      <app-candidate-document-upload
        [fileTypes]="[
          'CERTZAB',
          'CERTLAB',
          'REJECTCERTAB',
          'ADDREQCERTAB',
          'APPVALEDU',
          'CONFIRMCERTZAB',
          'FEESCERTZAB',
          'FORMFINSUPPCERTZAB',
          'CONFIRMCFINSUPPCERTZAB',
        ]"
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
        class="mt-3"
      ></app-candidate-document-upload>

      <div class="field-checkbox">
        <p-checkbox
          id="noQualificationEvaluation"
          inputId="noQualificationEvaluation"
          [binary]="true"
          formControlName="noQualificationEvaluation"
          [readonly]="readonly"
          (onChange)="confirmNoQualificationEvaluation($event.checked, $event.originalEvent.target)"
        ></p-checkbox>
        <label for="noQualificationEvaluation">{{ t("noQualificationEvaluation.title") }}</label>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('qualificationEvaluationResponsible.title')" class="field col-12 lg:col-12">
      <app-candidate-responsible-representative-group
        [form]="form"
        [readonly]="readonly || form.controls.noQualificationEvaluation?.value"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateId]="candidateId"
        [alwaysShownRoles]="responsibleRoles"
        [canHaveADeputyOrRepresentative]="canHaveADeputyOrRepresentative"
        [responsibleRolePurpose]="ResponsiblePurpose.QualificationEvaluation"
        [purposeData]="qualificationEvaluation"
        (responsibleRoleChanged)="copyToResponsibleForPayment($event)"
        styleRoleClass="field col-12 lg:col-12"
        styleOrganizationClass="field col-12 lg:col-4"
        styleRepresentativeClass="field col-12 lg:col-4"
        styleDeputyClass="field col-12 lg:col-4"
        class="p-fluid formgrid grid"
      ></app-candidate-responsible-representative-group>
      <div class="p-fluid formgrid grid">
        <app-candidate-responsible-role
          [form]="form"
          [responsibleRolePurpose]="ResponsiblePurpose.QualificationEvaluationPayment"
          [candidateId]="candidateId"
          [alwaysShownRoles]="responsiblePaymentRoles"
          class="field col-12 lg:col-4"
          [readonly]="readonly || form.controls.noQualificationEvaluation?.value"
        ></app-candidate-responsible-role>
      </div>
    </p-fieldset>

    <p-fieldset
      [legend]="t('qualificationEvaluations.title')"
      class="field col-12 lg:col-12"
      formArrayName="qualificationEvaluations"
    >
      <ng-container
        *ngFor="let control of form.controls.qualificationEvaluations.controls; index as i"
        [formGroupName]="i"
      >
        <app-candidate-qualification-evaluations
          [form]="form.controls.qualificationEvaluations.controls[i]"
          [candidateId]="candidateId"
          [candidateOrganizationId]="candidateOrganizationId"
          [evaluationsDisabled]="evaluationsDisabled"
          [staticDataContext]="staticDataContext"
          [index]="i"
          [readonly]="readonly"
          (removeEvaluation)="removeQualificationEvaluation(i)"
        >
        </app-candidate-qualification-evaluations>
      </ng-container>
      <div class="field col-12 lg:col-3">
        <p-button
          icon="pi pi-plus"
          [label]="t('qualificationEvaluationAdd.title')"
          [disabled]="readonly || evaluationsDisabled"
          (onClick)="addQualificationEvaluation()"
        ></p-button>
      </div>
    </p-fieldset>
  </form>
</ng-container>
