import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { CascadedOrganizationFragment, SharingState } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";

import { Observable, ReplaySubject, Subject, map } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { OrganizationLinkService } from "../../organizations/organization-link.service";
import { OrganizationsService } from "../../organizations/organizations.service";
import {
  CandidateResponsibleRepresentativeGroupService,
  ResponsibleRolePurposeWithOrganizationMapping,
} from "../candidate-responsible-representative-group/candidate-responsible-representative-group.service";

export interface ResponsibleOrganizationPurposeTranslations {
  caption: string;
  placeholder: string;
  required: string;
}

export interface ResponsibleOrganizationModelData {
  organizationId: string;
}

export interface ResponsibleOrganizationPurposeConfig {
  getControlName: () => string;
  getTranslationKey: (language: string) => ResponsibleOrganizationPurposeTranslations;
}

@Component({
  selector: "app-candidate-responsible-organization",
  templateUrl: "./candidate-responsible-organization.component.html",
  standalone: false,
})
export class CandidateResponsibleOrganizationComponent implements OnInit, OnChanges, OnDestroy {
  private readonly language = this.transloco.getActiveLang();
  private readonly destroy: Subject<any> = new Subject<any>();
  private readonly processLanguage$ = new ReplaySubject<string>(1);
  protected translations$: Observable<ResponsibleOrganizationPurposeTranslations>;

  @Input({ required: true })
  form: FormGroup;

  @Input({ required: true })
  purpose: ResponsibleRolePurposeWithOrganizationMapping;

  @Input()
  readonly = false;

  @Input()
  showControl = true;

  @Input()
  modelData: ResponsibleOrganizationModelData = null;

  @Input({ required: true })
  candidateId: string;

  @Input({ required: true })
  candidateOrganizationId: string;

  @Input()
  processLanguage?: string = this.language;

  @Output()
  readonly valueChanged = new EventEmitter<string>();

  protected organizations: CascadedOrganizationFragment[];
  protected sharingState: SharingState;

  constructor(
    private readonly transloco: TranslocoService,
    private readonly organizationService: OrganizationsService,
    private readonly organizationLinkService: OrganizationLinkService,
    private readonly settingsService: SettingsService,
    private readonly groupService: CandidateResponsibleRepresentativeGroupService
  ) {}

  ngOnInit(): void {
    if (!this.control) this.initControl();

    this.organizationService
      .getAllCascadedLinkedOrganizationsFromSourceOrganization(
        this.candidateOrganizationId,
        this.settingsService.organizationId
      )
      .subscribe((organizations) => {
        this.organizations = organizations;
      });
    this.organizationLinkService
      .getSharingState({
        userOrganizationId: this.settingsService.organizationId,
        candidateOwnerOrganizationId: this.candidateOrganizationId,
        targetOrganizationId: this.control.value,
      })
      .subscribe((sharingState) => {
        this.sharingState = sharingState;
      });

    this.translations$ = this.getTranslations();
    this.processLanguage$.next(this.processLanguage);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.control) this.initControl();

    if (changes.readonly) {
      if (changes.readonly.currentValue) this.control.disable();
      else this.control.enable();
    }

    if (changes.processLanguage) {
      this.processLanguage$.next(changes.processLanguage.currentValue);
    }
    if (changes.modelData?.currentValue) {
      this.setOrganizationId(changes.modelData.currentValue.organizationId);
    }
  }

  private initControl(): void {
    const controlValue = this.modelData?.organizationId ?? undefined;

    this.control = new FormControl<string>(controlValue);
  }

  ngOnDestroy(): void {
    this.form.removeControl(this.controlName, { emitEvent: false });
    this.destroy.next(undefined);
    this.destroy.complete();
  }

  get controlName(): string {
    return this.groupService.getResponsibleOrganizationPurposeConfig(this.purpose).getControlName();
  }

  get control(): FormControl<string> {
    return this.form.get(this.controlName) as FormControl<string>;
  }

  set control(value: FormControl<string>) {
    this.form.setControl(this.controlName, value);
  }

  private getTranslations(): Observable<ResponsibleOrganizationPurposeTranslations> {
    return this.processLanguage$.pipe(
      map((language) =>
        this.groupService.getResponsibleOrganizationPurposeConfig(this.purpose).getTranslationKey(language)
      )
    );
  }

  private setOrganizationId(defaultOrganizationId: string): void {
    if (defaultOrganizationId) this.control.setValue(defaultOrganizationId);
  }
}
