import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { PriceRow, SupportedBillingCurrency } from "@ankaadia/graphql";
import { TranslateDirective } from "../../../../../shared/transloco/translate.directive";
import { PriceComponent } from "../price/price.component";
import { PlanCandidateSpecializationComponent } from "../candidate-sepcialization/plan-candidate-specialization.component";
import { PlanPriceCapFloorComponent } from "../price-cap-floor/plan-price-cap-floor.component";
import { PlanCandidateBillingEventsComponent } from "../candidate-billing-events/plan-candidate-billing-events.component";
import { CommonModule } from "@angular/common";
import { CardModule } from "primeng/card";
import { TableModule } from "primeng/table";

@Component({
  standalone: true,
  selector: "app-plan-candidate-price-row",
  templateUrl: "./plan-candidate-price-row.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TableModule,
    TranslateDirective,
    CardModule,
    PriceComponent,
    PlanCandidateSpecializationComponent,
    PlanPriceCapFloorComponent,
    PlanCandidateBillingEventsComponent,
  ],
})
export class PlanCandidatePriceRowComponent {
  priceRow = input.required<PriceRow>();

  currency = input.required<SupportedBillingCurrency>();
  protected readonly Number = Number;
}
