import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { emailPattern, getLogoBlobName } from "@ankaadia/ankaadia-shared";
import { Organization, OrganizationInput, User } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { clone } from "lodash";
import { Observable, finalize, forkJoin, map, of, tap } from "rxjs";
import { MessageDialogService } from "../../../shared/message-dialog/message-dialog.service";
import { FileUploadService } from "../../../shared/services/file-upload.service";
import { DocumentsService } from "../../documents/documents.service";
import { MessageService } from "../../message/message.service";
import { OrganizationEditComponent } from "../organization-edit/organization-edit.component";
import { OrganizationsService } from "../organizations.service";

@Component({
  selector: "app-organization-edit-dialog",
  templateUrl: "./organization-edit-dialog.component.html",
  providers: [FileUploadService],
  standalone: false,
})
export class OrganizationEditDialogComponent implements OnInit {
  isEditMode = false;
  form: FormGroup;
  users: User[];

  @Input()
  myOrgMode: boolean;

  @Output() readonly closed = new EventEmitter<boolean>();
  disableSubmit = false;

  @ViewChild(OrganizationEditComponent)
  editCmp: OrganizationEditComponent;

  logoDownloadUrl: SafeUrl;
  private logoSelected: boolean;
  logoForceChange: string;

  editOrg: Organization;
  private creatorOrgId: string;
  creatorOrgName: string;

  constructor(
    private readonly orgSvc: OrganizationsService,
    private readonly fb: FormBuilder,
    private readonly fileUploadService: FileUploadService,
    private readonly documentService: DocumentsService,
    private readonly errorService: MessageDialogService,
    private readonly sanitizer: DomSanitizer,
    private readonly messageService: MessageService
  ) {}

  initEdit(id: string, creatorId: string): Observable<boolean> {
    this.form.reset();
    this.logoSelected = false;
    this.isEditMode = true;
    this.orgSvc.getOrganizationLogoDownloadURL(id).subscribe((logo) => (this.logoDownloadUrl = logo));

    return forkJoin([
      this.orgSvc.getOrganization(id),
      creatorId != null ? this.orgSvc.getOrganization(creatorId) : of(null),
      this.isEditMode ? this.orgSvc.getNotifyUsersOnDeletionRequest(id) : [],
    ]).pipe(
      tap(([org, creator, notifiedUsers]) => {
        this.editOrg = org;
        this.creatorOrgId = creator?.id ?? null;
        this.creatorOrgName = creator?.name ?? null;
        this.form.setValue({
          emailAdress: org.emailAdress,
          urlToWebsite: org.urlToWebsite,
          parentOrganizationId: org.parentOrganizationId,
          code: org.code,
          name: org.name,
          type: org.type,
          cooperationType: org.cooperationType,
          address: org.address,
          zipcode: org.zipcode,
          city: org.city,
          region: org.region,
          country: org.country,
          prefix: org.prefix,
          configToken: org.configToken,
          allowOrganizationManagementbyCreator:
            org.allowOrganizationManagementbyCreator == null ? true : org.allowOrganizationManagementbyCreator,
          notifyUsersOnDeletionRequest: notifiedUsers.map((u) => ({
            id: u.id,
            displayName: u.lastname + ", " + u.firstname,
          })),
        });
      }),
      map((val) => val != null)
    );
  }

  initCreate(organizationId: string, organizationName: string): void {
    this.creatorOrgId = organizationId;
    this.creatorOrgName = organizationName;
    this.editOrg = null;
    this.logoDownloadUrl = null;
    this.form.reset();
    this.form.controls.allowOrganizationManagementbyCreator.setValue(true);
    this.form.controls.parentOrganizationId.setValue(null);
    this.isEditMode = false;
    this.logoSelected = false;
  }

  ngOnInit(): void {
    this.fileUploadService.onProgress.subscribe((x) => {
      this.editCmp.upload.progress = x;
      this.logoForceChange = x.toString();
    }); // forcing onPush change detection

    this.fileUploadService.onUpload.subscribe(() => this.onUpload());
    this.fileUploadService.onError.subscribe((x) => this.onError(x));

    this.form = this.fb.group({
      code: [null, Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z0-9]{6}$")])],
      parentOrganizationId: null,
      prefix: [null, Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z]{2}$")])],
      name: [null, Validators.compose([Validators.required, Validators.maxLength(150)])],
      type: [null, Validators.required],
      cooperationType: [null],
      address: [null, Validators.maxLength(500)],
      zipcode: [null, Validators.maxLength(30)],
      city: [null, Validators.maxLength(100)],
      region: [null, Validators.maxLength(100)],
      country: [null, Validators.maxLength(200)],
      emailAdress: [null, Validators.pattern(emailPattern)],
      urlToWebsite: [
        null,
        Validators.compose([
          Validators.maxLength(250),
          Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"),
        ]),
      ],
      configToken: [null, Validators.maxLength(20)],
      allowOrganizationManagementbyCreator: [null],
      notifyUsersOnDeletionRequest: [null],
    });
  }

  selectLogo(event: { currentFiles: File[] }): void {
    if (event.currentFiles.length > 0) {
      this.logoDownloadUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.currentFiles[0]));
      this.logoSelected = true;
      this.form.markAsDirty();
    }
  }

  uploadLogo(event: { files: File[] }): void {
    this.logoForceChange = Date.now().toString();
    this.documentService
      .getUploadUrl(getLogoBlobName(), this.editOrg.id, null, true)
      .subscribe(({ url }) => this.fileUploadService.upload(event.files[0], url));
  }

  onUpload(): void {
    this.editCmp.upload.clear();
  }

  private onError(event): void {
    this.errorService.showMessage(translate("file.uploadFailed"), event.message);
  }

  onSubmit(): void {
    if (this.form.dirty && this.form.valid) {
      const orgInput = new OrganizationInput();
      this.disableSubmit = true;

      if (!this.isEditMode) {
        this.SetModelValue(orgInput);
        orgInput.creatorOrganizationId = this.creatorOrgId;
        this.orgSvc
          .createOrganization(orgInput)
          .pipe(finalize(() => (this.disableSubmit = false)))
          .subscribe((x) => {
            if (x != null) {
              if (this.logoSelected) {
                this.editOrg = clone(x);
                this.editCmp.upload.upload();
              }
              this.closed.emit(true);
              this.messageService.add({
                severity: "success",
                summary: translate("organization.created.title"),
                detail: translate("organization.created.message", x),
              });
            }
          });
      } else {
        if (this.logoSelected) {
          this.editCmp.upload.upload();
        }
        Object.assign(orgInput, this.editOrg);
        this.SetModelValue(orgInput);

        delete orgInput["__typename"];
        delete orgInput["organizationId"];
        delete orgInput["parentOrganizationName"];
        this.orgSvc
          .updateOrganization(orgInput)
          .pipe(finalize(() => (this.disableSubmit = false)))
          .subscribe((x) => {
            if (x != null) {
              this.closed.emit(true);
              this.messageService.add({
                severity: "success",
                summary: translate("organization.updated.title"),
                detail: translate("organization.updated.message", x),
              });
            }
          });
      }
    }
  }

  private SetModelValue(orgInput: OrganizationInput): void {
    orgInput.configToken = this.form.value.configToken;
    orgInput.code = this.form.value.code;
    orgInput.name = this.form.value.name;
    orgInput.type = this.form.value.type;
    orgInput.cooperationType = this.form.value.cooperationType;

    orgInput.address = this.form.value.address;
    orgInput.zipcode = this.form.value.zipcode;
    orgInput.city = this.form.value.city;
    orgInput.region = this.form.value.region;
    orgInput.country = this.form.value.country;
    orgInput.parentOrganizationId = this.form.value.parentOrganizationId;
    orgInput.emailAdress = this.form.value.emailAdress;
    orgInput.urlToWebsite = this.form.value.urlToWebsite;
    orgInput.prefix = this.form.value.prefix.toUpperCase();
    orgInput.allowOrganizationManagementbyCreator = this.form.value.allowOrganizationManagementbyCreator;
    orgInput.notifyUsersOnDeletionRequest = this.form.value.notifyUsersOnDeletionRequest?.map((x) => x.id);
    orgInput.EZBData = orgInput.EZBData && this.remove("__typename", orgInput.EZBData);
  }

  remove(key: string, { [key]: _, ...rest }: any): any {
    return rest;
  }

  onCloseClick(): void {
    this.closed.emit(false);
  }
}
