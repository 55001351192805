import { Location } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Candidate,
  CandidateForViewFragment,
  Comment,
  CommentCreateInput,
  Feedback,
  FeedbackFragment,
  FeedbackStatusPhaseOne,
  FeedbackStatusPhaseTwo,
  GetSelectableCollectionsQuery,
  Organization,
  Sharing,
  SharingTypeEnum,
  StaticDataType,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { clone, cloneDeep } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { Carousel } from "primeng/carousel";
import { Subscription, forkJoin, of } from "rxjs";
import { OrganizationFactoryService } from "../../../organization-specific/organization-specific-factory.service";
import { SettingsService } from "../../../shared/services/settings.service";
import { CollectionService } from "../../collections/collection.service";
import { SharingsService } from "../../collections/sharings.service";
import { DeepLinkAccessService } from "../../deep-link-access/deep-link-access.service";
import { DocumentsService } from "../../documents/documents.service";
import { MessageService } from "../../message/message.service";
import { OrganizationsService } from "../../organizations/organizations.service";
import { ReportService } from "../../reports/report.service";
import { FilePreview } from "../candidate-card-component.model";
import { CandidatesService } from "../candidates.service";
import { CurrentCollectionService } from "../current-collection.service";
import { CandidateData } from "./candidate-data.model";
import { FeedbackStatusMeta } from "./candidate-filter/candidate-filter.pipe";

type Collection = GetSelectableCollectionsQuery["getSelectableCollections"][0]["collection"];

@Component({
  selector: "app-candidate-presentation-view",
  templateUrl: "./candidate-presentation-view.component.html",
  styleUrl: "./candidate-presentation-view.component.scss",
  standalone: false,
})
export class CandidatePresentationViewComponent implements OnInit, OnDestroy {
  private readonly onlyNewSharingTypes = [FeedbackStatusMeta.New];
  private readonly defaultSharingTypes = [
    FeedbackStatusMeta.New,
    FeedbackStatusPhaseOne.Backlogged,
    FeedbackStatusPhaseOne.InterviewRequested,
  ];

  readonly StaticDataType = StaticDataType;

  visibleSidebar = false;
  filterOptions = [
    { label: translate("enum.CandidateStatus.New"), value: FeedbackStatusMeta.New },
    { label: translate("enum.FeedbackStatusPhaseOne.Rejected"), value: FeedbackStatusPhaseOne.Rejected },
    { label: translate("enum.FeedbackStatusPhaseOne.Backlogged"), value: FeedbackStatusPhaseOne.Backlogged },
    {
      label: translate("enum.FeedbackStatusPhaseOne.InterviewRequested"),
      value: FeedbackStatusPhaseOne.InterviewRequested,
    },
    { label: translate("enum.FeedbackStatusPhaseTwo.Selected"), value: FeedbackStatusPhaseTwo.Selected },
  ];

  possibleViews = [
    { icon: PrimeIcons.TABLE, value: 1 },
    { icon: PrimeIcons.ELLIPSIS_H, value: 2 },
  ];

  selectedView = 2;
  data: CandidateData[];
  selectedOrganization: string;
  selectedCollection: string;
  selectedCandidate: string;
  organizations: Partial<Organization>[];
  collections: Collection[];
  sharings: Sharing[];
  isGridView = false;
  feedbackIsUpdating = false;
  feedbackSecondPhaseEnabled = false;
  search = "";
  limitSharingTypes: string[] = [];

  disableSubmitFeedback = false;
  filePreview: FilePreview;
  protected currentCandidate: Candidate;

  private collectionSub: Subscription;
  get selectedSharing(): Sharing {
    return this.sharings?.find((x) => x.sharedCollectionId === this.selectedCollection);
  }

  get feedbackProvidedToAllCandidates(): boolean {
    return this.data?.every((x) => x.feedback?.statusPhaseOne);
  }

  get organizationId(): string {
    return this.settings.organizationId;
  }

  get selectedSharingId(): string {
    return this.selectedSharing?.isAnonymous ? this.selectedSharing?.id : null;
  }

  @ViewChild(Carousel)
  carousel: Carousel;

  constructor(
    private readonly reportService: ReportService,
    private readonly sharingsService: SharingsService,
    private readonly colService: CollectionService,
    private readonly candService: CandidatesService,
    private readonly orgService: OrganizationsService,
    private readonly settings: SettingsService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly documentService: DocumentsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly location: Location,
    private readonly specificsFactory: OrganizationFactoryService,
    private readonly deepLinkAccessService: DeepLinkAccessService,
    private readonly currentCollectionService: CurrentCollectionService
  ) {}

  ngOnInit(): void {
    this.currentCollectionService.reset();
    this.orgService.getSelectableOrganizations().subscribe((x) => (this.organizations = x));

    const { organizationId, collectionId, candidateId } = this.activatedRoute.snapshot.params;
    const { checkAccess, onlyNew } = this.activatedRoute.snapshot.queryParams;
    this.limitSharingTypes = onlyNew ? this.onlyNewSharingTypes : this.defaultSharingTypes;
    if (organizationId && collectionId) {
      // it's a deep link
      if (candidateId) {
        // more specifically, a deep candidate link
        if (checkAccess) {
          // it's a link from the emails, gonna check the access
          this.deepLinkAccessService.canAccessCandidateView(candidateId, organizationId).subscribe((access) => {
            if (access.canAccess) {
              this.preloadCandidate(organizationId, collectionId, candidateId);
            } else {
              if (access.redirectToOrganization) {
                this.settings.switchOrganizationKeepingCurrentUrl(access.redirectToOrganization);
              } else {
                this.settings.navigateToAccessDenied();
              }
            }
          });
        } else {
          // carry on, it's probably just navigation within the app
          this.preloadCandidate(organizationId, collectionId, candidateId);
        }
      } else {
        // more specifically, a deep collection link
        if (checkAccess) {
          // it's a link from the emails, gonna check the access
          this.deepLinkAccessService.canAccessCollectionView(collectionId, organizationId).subscribe((access) => {
            if (access.canAccess) {
              this.preloadCandidate(organizationId, collectionId, candidateId);
            } else {
              if (access.redirectToOrganization) {
                this.settings.switchOrganizationKeepingCurrentUrl(access.redirectToOrganization);
              } else {
                this.settings.navigateToAccessDenied();
              }
            }
          });
        } else {
          // carry on, it's probably just navigation within the app
          this.preloadCandidate(organizationId, collectionId, candidateId);
        }
      }
    } else {
      this.updateCandidates();
    }
  }

  ngOnDestroy(): void {
    this.collectionSub?.unsubscribe();
  }

  updateCollections(): void {
    this.collectionSub?.unsubscribe();
    this.collectionSub = this.colService
      .getSelectableCollections(
        [SharingTypeEnum.View, SharingTypeEnum.Collaboration, SharingTypeEnum.ReadOnlyCollaboration],
        this.selectedOrganization
      )
      .subscribe((x) => {
        this.collections = x.map((y) => y.collection);
        this.sharings = x.filter((y) => y.sharing).map((y) => y.sharing);
        if (this.selectedSharing != null) this.updateCandidates();
      });
  }

  updateCandidates(): void {
    if (this.selectedOrganization && this.selectedCollection) {
      this.currentCollectionService.set(
        this.selectedCollection,
        this.selectedOrganization,
        null,
        null,
        null,
        null,
        null
      );
      forkJoin([
        this.candService.getFilteredCandidatesForView(
          this.selectedCollection,
          this.selectedSharing?.organizationId,
          this.selectedSharingId
        ),
        this.selectedSharing
          ? this.documentService.getAllForSharing(this.selectedSharing.organizationId, this.selectedSharing.id)
          : of([]),
        this.selectedSharing
          ? this.candService.getAllFeedbackForView(this.selectedSharing.organizationId, this.selectedSharing.id)
          : of([]),
        this.specificsFactory.getOrganizationSpecifics(this.selectedOrganization),
      ]).subscribe(([candidates, files, feedbacks, specifics]) => {
        this.data = candidates.map((c) => ({
          candidate: c,
          feedback: feedbacks.find((f) => f.candidateId === c.id) ?? this.createFeedback(c),
          files: files.filter((f) => f.candidateId === c.id),
        }));
        this.feedbackSecondPhaseEnabled = specifics.isSecondFeedbackPhaseEnabled();
        this.disableSubmitFeedback = this.selectedSharing?.disableSubmitFeedback;

        if (this.selectedCandidate && !this.isGridView) {
          this.carousel.page = this.data.findIndex((x) => x.candidate.id === this.selectedCandidate);
          this.carousel.cd.detectChanges();
          this.selectedCandidate = null;
        }
      });
    } else {
      this.data = [];
      this.feedbackSecondPhaseEnabled = false;
    }
  }

  createFeedback(candidate: CandidateForViewFragment): FeedbackFragment {
    return {
      sharingId: this.selectedSharing?.id,
      organizationId: candidate.organizationId,
      destinationOrganizationId: this.selectedSharing?.destinationOrganizationId,
      candidateId: candidate.id,
      candidateOrganizationId: candidate.organizationId,
      comments: [],
    };
  }

  updateFeedback(feedback: Feedback, field: string, data: CandidateData): void {
    this.feedbackIsUpdating = true;
    const key = this.getFeedbackKey(feedback, field);
    if (feedback.id) {
      this.candService.updateFeedback(feedback).subscribe((feedback) => {
        data.feedback = feedback;
        this.feedbackIsUpdating = false;
        if (key) {
          this.messageService.add({ severity: "success", summary: translate(`feedback.${key}`) });
        }
      });
    } else {
      this.candService.createFeedback(feedback).subscribe((feedback) => {
        data.feedback = feedback;
        this.feedbackIsUpdating = false;
        if (key) {
          this.messageService.add({ severity: "success", summary: translate(`feedback.${key}`) });
        }
      });
    }
  }

  addComment(comment: CommentCreateInput, data: CandidateData): void {
    if (!comment.feedbackId) {
      const feedback = this.createFeedback(data.candidate);
      this.candService.createFeedback(feedback).subscribe((feedback) => {
        data.feedback = feedback;
        this.feedbackIsUpdating = false;
        comment.feedbackId = feedback.id;
        comment.organizationId = feedback.organizationId;
        this._addComment(comment);
      });
    } else {
      this._addComment(comment);
    }
  }

  updateComment(comment: Comment, data: CandidateData): void {
    if (!comment?.comment?.trim()) {
      this.candService.deleteComment(comment).subscribe(() => {
        data.feedback = Object.assign(new Feedback(), data.feedback, {
          comments: data.feedback.comments.filter((c) => c.id !== comment.id),
        });
        this.messageService.add({ severity: "success", summary: translate("comment.deleted") });
      });
    } else {
      this.candService.updateComment(comment).subscribe((comment) => {
        const commentIndex = data.feedback.comments.findIndex((c) => c.id === comment.id);
        const comments = clone(data.feedback.comments);
        comments[commentIndex] = comment;
        data.feedback = Object.assign(new Feedback(), data.feedback, {
          comments: comments,
        });
        this.messageService.add({ severity: "success", summary: translate("comment.updated") });
      });
    }
  }

  refreshFeedback(feedback: Feedback, data: CandidateData): void {
    this.feedbackIsUpdating = true;
    this.candService.getFeedback(feedback.id, feedback.organizationId).subscribe((f) => {
      data.feedback = f;
      this.feedbackIsUpdating = false;
      this.messageService.add({ severity: "success", summary: translate("feedback.refreshed") });
    });
  }

  onSubmitFeedback(event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("feedback.confirmSubmit"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.sharingsService
          .submitSharing(this.selectedSharing.id, this.selectedSharing.organizationId)
          .subscribe(() => {
            const sharings = cloneDeep(this.sharings);
            sharings.find((x) => x.id === this.selectedSharing.id).submitted = true;
            this.sharings = sharings;
            this.messageService.add({
              severity: "success",
              summary: translate("feedback.submitted"),
            });
          });
      },
    });
  }

  onGenerateFeedbackReport(): void {
    this.reportService.generateAndDownloadFeedbackReport(
      this.selectedSharing?.id ?? "noSharing",
      this.selectedSharing?.organizationId ?? "noSharing"
    );
  }

  onViewChange(value: number): void {
    this.isGridView = value == 1;
  }

  closePreview(): void {
    this.filePreview = null;
  }

  private preloadCandidate(organizationId: string, collectionId: string, candidateId: string): void {
    this.location.replaceState(this.truncateAfter(this.router.url, "view"));
    this.selectedOrganization = organizationId;
    this.selectedCollection = collectionId;
    this.selectedCandidate = candidateId;
    this.updateCollections();
  }

  private _addComment(comment: CommentCreateInput): void {
    comment.author = this.settings.userInitials;
    this.candService.createComment(comment).subscribe((comment) => {
      const dataIndex = this.data.findIndex((d) => d.feedback.id === comment.feedbackId);
      const feedback = this.data[dataIndex].feedback;
      this.data[dataIndex].feedback = Object.assign(new Feedback(), feedback, {
        comments: [comment, ...feedback.comments],
      });
      this.messageService.add({ severity: "success", summary: translate("comment.added") });
    });
  }

  private getFeedbackKey(feedback: Feedback, field: string): string {
    switch (field) {
      case "statusPhaseOne":
        switch (feedback.statusPhaseOne) {
          case FeedbackStatusPhaseOne.Rejected:
            return "rejected";
          case FeedbackStatusPhaseOne.Backlogged:
            return "backlogged";
          case FeedbackStatusPhaseOne.InterviewRequested:
            return "interviewRequested";
          default:
            return "reset";
        }
      case "statusPhaseTwo":
        switch (feedback.statusPhaseTwo) {
          case FeedbackStatusPhaseTwo.Rejected:
            return "rejected";
          case FeedbackStatusPhaseTwo.Selected:
            return "selected";
        }
    }
    return null;
  }

  private truncateAfter(original: string, pattern: string): string {
    return original.substring(0, original.indexOf(pattern) + pattern.length);
  }
}
