import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { Candidate, CandidateStatus, CandidateTagFragment } from "@ankaadia/graphql";
import { CandidateTagColors } from "../../organizations/candidate-tag-selector/candidate-tag-selector.model";
import { CandidateForm } from "../candidate-form.model";
import { CandidateSpecialTagService } from "../candidate-special-tag/candidate-special-tag.service";

@Component({
  selector: "app-candidate-tags",
  templateUrl: "./candidate-tags.component.html",
  standalone: false,
})
export class CandidateTagsComponent {
  readonly CandidateStatus = CandidateStatus;
  readonly CandidateTagColors = CandidateTagColors;
  readonly specialTags$ = this.specialTagService.tags$;

  @Input()
  candidate: Candidate;

  @Input()
  customTags: CandidateTagFragment[];

  @Input()
  form: CandidateForm;

  @Input()
  isEditing: boolean;

  @Input()
  isAdmin: boolean;

  @ViewChild("statusContainer")
  statusContainer: ElementRef;

  @ViewChild("functionContainer")
  functionContainer: ElementRef;

  @ViewChild("professionContainer")
  professionContainer: ElementRef;

  constructor(private readonly specialTagService: CandidateSpecialTagService) {}

  toTestId(tag: CandidateTagFragment): string {
    return `tag-${tag.variable?.replace(".", "-")}`;
  }
}
