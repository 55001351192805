<p-dropdown
  inputId="educationModuleId"
  [formControl]="educationModuleIdControl"
  [options]="modulesForSelection$ | async"
  [placeholder]="modulePlaceholder"
  optionLabel="name"
  optionValue="id"
>
  <ng-template *ngFor="let template of ['item', 'selectedItem']" let-module [pTemplate]="template">
    <div class="flex flex-nowrap align-items-center w-full max-w-36rem">
      <span class="white-space-nowrap flex-1">{{ module.name }}</span>
    </div>
    <div class="flex flex-nowrap align-items-center w-full max-w-36rem">
      <div class="p-text-secondary white-space-nowrap flex-1 info-text">({{ module.organizationName }})</div>
    </div>
  </ng-template>
</p-dropdown>
