import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { flatMapSuperDeep } from "@ankaadia/ankaadia-shared";
import { CandidateTagColor, CandidateTagConfigurationInput, CandidateTagDescriptionFragment } from "@ankaadia/graphql";
import { cloneDeep, compact, entries, noop, omit } from "lodash";
import { MenuItem, PrimeIcons, TreeNode } from "primeng/api";
import { Observable, map } from "rxjs";
import { CandidateTagColors } from "./candidate-tag-selector.model";
import { CandidateTagService } from "./candidate-tag.service";

@Component({
  selector: "app-candidate-tag-selector",
  templateUrl: "./candidate-tag-selector.component.html",
  styleUrl: "./candidate-tag-selector.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: CandidateTagSelectorComponent, multi: true }],
  standalone: false,
})
export class CandidateTagSelectorComponent implements ControlValueAccessor {
  readonly CandidateTagColors = CandidateTagColors;
  readonly descriptions$ = this.getDescriptions();
  readonly colors = this.getColors();

  @Input()
  inputId: string;

  @Input()
  styleClass: string;

  /**
   * There's no TreeSelect.readonly property so we're binding this to TreeSelect.disabled.
   */
  @Input()
  readonly: boolean;

  onModelChange = noop;
  onModelTouched = noop;

  /**
   * Form model; i.e. what's stored in the form control. Synchronized to the form control.
   */
  model: CandidateTagConfigurationInput[];

  /**
   * Currently selected tree nodes. Synchronized from the form control value.
   */
  selectedNodes: TreeNode<CandidateTagConfigurationInput>[];

  /**
   * Currently active tree node that is going to change its tag color.
   */
  recolorNode: TreeNode<CandidateTagConfigurationInput>;

  /**
   * Whether the form control is disabled. Comes from the {@link ControlValueAccessor}.
   */
  disabled: boolean;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly candidateTagService: CandidateTagService
  ) {}

  registerOnChange(onModelChange: () => void): void {
    this.onModelChange = onModelChange;
  }

  registerOnTouched(onModelTouched: () => void): void {
    this.onModelTouched = onModelTouched;
  }

  writeValue(model: CandidateTagConfigurationInput[]): void {
    this.model = model;
    this.descriptions$.subscribe((descriptions) => {
      descriptions = flatMapSuperDeep(descriptions, (x) => x.children);
      this.selectedNodes = compact(
        model.map((config) => {
          const description = descriptions.find((x) => x.data?.variable === config.variable);
          if (!description) return null;
          description.data = omit(config, "__typename");
          return description;
        })
      );
      this.changeDetector.markForCheck();
    });
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.changeDetector.markForCheck();
  }

  select(node: TreeNode<CandidateTagConfigurationInput>): void {
    this.model = [...(this.model ?? []), node.data];
    this.onModelChange(this.model);

    this.selectedNodes = [...(this.selectedNodes ?? []), node];
    this.changeDetector.markForCheck();
  }

  unselect(node: TreeNode<CandidateTagConfigurationInput>): void {
    this.model = this.model?.filter((x) => x.variable !== node.data.variable);
    this.onModelChange(this.model);

    this.selectedNodes = this.selectedNodes.filter((x) => x.key !== node.key);
    this.changeDetector.markForCheck();
  }

  toggle(node: TreeNode<CandidateTagConfigurationInput>): void {
    node.expanded = !node.expanded;
    this.changeDetector.markForCheck();
  }

  clear(): void {
    this.model = null;
    this.onModelChange(this.model);

    this.selectedNodes = null;
    this.changeDetector.markForCheck();
  }

  private getDescriptions(): Observable<TreeNode<CandidateTagConfigurationInput>[]> {
    return this.candidateTagService.getDescriptions().pipe(map((xs) => this.toNodes(xs)));
  }

  private getColors(): MenuItem[] {
    return entries(CandidateTagColors).map(([color, { icon }]) => ({
      icon: PrimeIcons.CIRCLE_FILL,
      iconClass: icon,
      command: (): void => this.recolorTag(<CandidateTagColor>color),
    }));
  }

  private recolorTag(color: CandidateTagColor): void {
    const model = cloneDeep(this.model);
    model.find((x) => x.variable === this.recolorNode.data.variable).color = color;
    this.onModelChange(model);

    this.recolorNode.data.color = color;
    this.changeDetector.markForCheck();
  }

  /**
   * Converts tag descriptions into a tree-like view.
   *
   * Using PrimeNG's {@link TreeNode} type so that we're also compatible with their tree controls.
   */
  private toNodes(descriptions: CandidateTagDescriptionFragment[]): TreeNode<CandidateTagConfigurationInput>[] {
    const parents = descriptions.filter((x) => !x.parentKey);
    return parents.map((x) => this.toNode(x, descriptions));
  }

  /**
   * Converts a single tag description into a PrimeNG tree node.
   */
  private toNode(
    description: CandidateTagDescriptionFragment,
    descriptions: CandidateTagDescriptionFragment[]
  ): TreeNode<CandidateTagConfigurationInput> {
    const children = descriptions.filter((y) => y.parentKey === description.key);
    return {
      key: description.key,
      label: description.label,
      data: { variable: description.variable, color: CandidateTagColor.Primary },
      selectable: !children.length,
      children: children
        .map((x) => this.toNode(x, descriptions))
        .sort((a, b) => {
          // prioritizing single tags over categories of tags
          if (a.children.length && !b.children.length) {
            return 1;
          } else if (!a.children.length && b.children.length) {
            return -1;
          }

          // alphabetic sort if both are categories or tags
          return a.label.localeCompare(b.label);
        }),
    };
  }
}
