import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
// import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { StaticDataService } from "../../../shared/static-data/static-data.service";

// const nameOf = nameofFactory<RecognitionPathEntryDe>();

@Component({
  selector: "app-candidate-equivalence-test",
  templateUrl: "./candidate-equivalence-test.component.html",
  standalone: false,
})
export class CandidateEquivalenceTestComponent implements OnInit, OnChanges, OnDestroy {
  private readonly language = this.transloco.getActiveLang();

  @Input()
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  readonly: boolean;

  @Output()
  readonly valueChanged = new EventEmitter<string>();

  @Input()
  renderLabelUsingAlignmentCheckBox = false;

  equivalenceTests: StaticDataModel[];

  get control(): FormControl<string> {
    return this.form.get("equivalenceTest") as FormControl<string>;
  }

  set control(value: FormControl<string>) {
    this.form.setControl("equivalenceTest", value);
  }

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<string>(undefined);
    this.control.valueChanges.subscribe((value) => this.valueChanged.emit(value));
    this.updateEquivalenceTests(this.processLanguage);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.processLanguage) {
      this.updateEquivalenceTests(changes.processLanguage.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl("chamberOfCrafts", { emitEvent: false });
  }

  private updateEquivalenceTests(language: string): void {
    this.staticDataService.getStaticData(StaticDataType.EquivalenceTest, language ?? this.language).subscribe((x) => {
      this.equivalenceTests = x;
      this.changeDetector.markForCheck();
    });
  }
}
