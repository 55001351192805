import { Injectable } from "@angular/core";
import {
  ContractCreateInput,
  ContractFragment,
  ContractValidationError,
  CreateContractGQL,
  GetActiveContractGQL,
  GetContractGQL,
  ValidateContractGQL,
} from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class ContractService {
  constructor(
    private readonly getActiveContractGql: GetActiveContractGQL,
    private readonly getContractGql: GetContractGQL,
    private readonly validateContractGql: ValidateContractGQL,
    private readonly createContractGql: CreateContractGQL
  ) {}

  getActiveContract(organizationId: string): Observable<ContractFragment> {
    return this.getActiveContractGql.fetch({ organizationId }).pipe(map((x) => x.data.getActiveContract));
  }

  getContract(id: string, organizationId: string): Observable<ContractFragment> {
    return this.getContractGql.fetch({ id, organizationId }).pipe(map((x) => x.data.getContract));
  }

  validateContract(input: ContractCreateInput): Observable<ContractValidationError[]> {
    return this.validateContractGql.fetch({ input }).pipe(map((x) => x.data.validateContract));
  }

  createContract(input: ContractCreateInput, organizationId: string): Observable<ContractFragment> {
    return this.createContractGql.mutate({ input, organizationId }).pipe(map((x) => x.data.createContract));
  }
}
