import { Component, DestroyRef, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  EducationCourseForListFragment,
  EducationExamFragment,
  EducationModuleForSelectionFragment,
} from "@ankaadia/graphql";
import { startWith } from "rxjs";
import { EducationExamForm } from "../education-exam-dialog/education-exam-form.model";

@Component({
  selector: "app-education-exam-dialog-education-fields",
  templateUrl: "./education-exam-dialog-education-fields.component.html",
  standalone: false,
})
export class EducationExamDialogEducationFieldsComponent implements OnInit, OnChanges {
  @Input({ required: true })
  form: EducationExamForm;

  @Input({ required: true })
  exam: Partial<EducationExamFragment>;

  @Input({ required: true })
  courses: EducationCourseForListFragment[];

  @Input({ required: true })
  modules: EducationModuleForSelectionFragment[];

  protected selectableModules: EducationModuleForSelectionFragment[];

  protected selectableCourses: EducationCourseForListFragment[];

  constructor(private readonly destroyRef: DestroyRef) {}

  ngOnInit(): void {
    this.form.controls.educationModuleId.valueChanges
      .pipe(startWith(this.form.controls.educationModuleId.value), takeUntilDestroyed(this.destroyRef))
      .subscribe((moduleId) => {
        if (!moduleId) this.form.controls.educationCourseIds.disable();
        else this.form.controls.educationCourseIds.enable();

        this.selectableCourses = this.courses.filter((course) => course.moduleId === moduleId);
      });

    this.form.controls.educationModuleId.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((moduleId) => {
        this.form.controls.educationCourseIds.setValue([]);
        this.form.controls.organizationId.setValue(this.modules.find((m) => m.id === moduleId)?.organizationId);
      });
  }

  ngOnChanges(_: SimpleChanges): void {
    if (this.courses && this.exam && this.modules) {
      this.selectableModules = this.modules.filter(
        (module) => !this.exam._etag || module.organizationId === this.exam.organizationId
      );
    }
  }
}
