<ng-container *appTranslate="let t">
  <form [formGroup]="form" styleClass="relative">
    <app-candidate-document-upload
      [fileTypes]="['PREAPPROVAL', 'CONFEMPLOYAGENCY', 'REJECTWORK']"
      [candidateId]="candidateId"
      [candidateOrganizationId]="candidateOrganizationId"
      [candidateForm]="form"
      [readonly]="readonly"
    ></app-candidate-document-upload>

    <div class="p-fluid formgrid grid">
      <div class="field col-12 lg:col-6">
        <app-candidate-responsible-role
          [form]="form"
          [responsibleRolePurpose]="ResponsibleRolePurposeType.LaborMarket"
          [candidateId]="candidateId"
          [readonly]="readonly"
          [alwaysShownRoles]="responsibleRoles"
          class="field"
        ></app-candidate-responsible-role>
      </div>

      <div class="field col-0 lg:col-6"></div>

      <div class="field col-12 lg:col-12">
        <app-candidate-employment-approval-authority
          [form]="form"
          [candidateOrganizationId]="candidateOrganizationId"
          [immigrationCountry]="SupportedImmigrationCountry.De"
          [readonly]="readonly"
          class="field"
        ></app-candidate-employment-approval-authority>
      </div>

      <div class="field col-12 lg:col-3">
        <div class="field-checkbox">
          <p-checkbox
            inputId="employmentApprovalRequested"
            [binary]="true"
            [readonly]="readonly"
            formControlName="employmentApprovalRequested"
          ></p-checkbox>
          <label for="employmentApprovalRequested">{{ t("employmentApprovalRequested.title") }}</label>
        </div>

        <p-calendar
          formControlName="employmentApprovalRequestDate"
          [maxDate]="today"
          [readonly]="readonly"
        ></p-calendar>
      </div>

      <app-candidate-document-date
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
        [label]="t('employmentApprovalReceived.title')"
        documentType="PREAPPROVAL"
        selectedSet="Default"
        dateField="dateOfReceipt"
        class="field col-12 lg:col-3"
      ></app-candidate-document-date>
    </div>
  </form>
</ng-container>
