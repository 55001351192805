import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-candidate-multi-edit-toggle",
  templateUrl: "./candidate-multi-edit-toggle.component.html",
  styleUrl: "./candidate-multi-edit-toggle.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CandidateMultiEditToggleComponent implements OnInit {
  isActive: boolean;

  @Input()
  active: boolean;

  @Output()
  readonly activeChange = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.isActive = this.active ?? false;
  }

  toggle(): void {
    this.isActive = !this.isActive;
    this.activeChange.emit(this.isActive);
  }
}
