import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { CommentData, FeedbackData } from "@ankaadia/graphql";
import {
  CandidateFeedbackForm,
  InterviewIterationDetailsCommentForm,
  InterviewIterationDetailsFeedbackForm,
  InterviewIterationDetailsForm,
  InterviewerFeedbackForm,
} from "./interview-iteration-details-form.model";

@Injectable({ providedIn: "root" })
export class InterviewIterationDetailsFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createForm(): InterviewIterationDetailsForm {
    return this.formBuilder.group<InterviewIterationDetailsForm["controls"]>({
      id: this.formBuilder.control(null),
      employerId: this.formBuilder.control(null, Validators.required),
      createdAt: this.formBuilder.control(null),
      feedbacks: this.formBuilder.array<InterviewIterationDetailsFeedbackForm>([]),
      comments: this.formBuilder.array<InterviewIterationDetailsCommentForm>([]),
    });
  }

  createFeedbackForm(feedback: FeedbackData | null): InterviewIterationDetailsFeedbackForm {
    return this.formBuilder.group<InterviewIterationDetailsFeedbackForm["controls"]>({
      id: this.formBuilder.control(feedback?.id ?? null),
      participants: this.formBuilder.control(feedback?.participants ?? null),
      interviewDate: this.formBuilder.control(feedback?.interviewDate ?? null),
      interviewerFeedback: this.formBuilder.group<InterviewerFeedbackForm["controls"]>({
        comment: this.formBuilder.control(feedback.interviewerFeedback?.comment),
        vote: this.formBuilder.control(feedback.interviewerFeedback?.vote),
      }),
      candidateFeedback: this.formBuilder.group<CandidateFeedbackForm["controls"]>({
        comment: this.formBuilder.control(feedback.candidateFeedback?.comment),
        vote: this.formBuilder.control(feedback.candidateFeedback?.vote),
      }),
    });
  }

  createCommentForm(comment: CommentData | null): InterviewIterationDetailsCommentForm {
    return this.formBuilder.group<InterviewIterationDetailsCommentForm["controls"]>({
      id: this.formBuilder.control(comment?.id ?? null),
      changedAt: this.formBuilder.control(comment?.changedAt ?? null),
      changedBy: this.formBuilder.control(comment?.changedBy ?? null),
      comment: this.formBuilder.control(comment?.comment ?? null),
    });
  }
}
