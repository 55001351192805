import { Directive, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { translate } from "@jsverse/transloco";
import { ConfirmationService } from "primeng/api";
import { BehaviorSubject, debounceTime, distinctUntilChanged, map, switchMap } from "rxjs";
import { OverlayDetectorService, OverlayElementType } from "./overlay-detector.service";

@Directive({
  selector: "[dropzone]",
  standalone: false,
})
export class DropZoneDirective {
  private readonly isActiveSubject = new BehaviorSubject(false);
  static isShowingNoFilesMessage = false;

  @Input()
  preventBodyDrop = true;

  @Input()
  ignoredOverlayElementTypes: OverlayElementType[] = [];

  @Input()
  active = false;

  @Output()
  readonly activeChange = new EventEmitter<boolean>();

  @Output("dropzone")
  readonly filesDropped = new EventEmitter<File[]>();

  constructor(
    private readonly overlayDetector: OverlayDetectorService,
    private readonly confirmationService: ConfirmationService
  ) {
    this.isActiveSubject
      .pipe(
        distinctUntilChanged(),
        switchMap((active) =>
          this.overlayDetector
            .hasOverlay(...this.ignoredOverlayElementTypes)
            .pipe(map((hasOverlay) => !hasOverlay && active))
        ),
        distinctUntilChanged(),
        debounceTime(100)
      )
      .subscribe((active) => {
        this.active = active;
        this.activeChange.emit(active);
      });
  }

  @HostListener("drop", ["$event"])
  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isActiveSubject.next(false);

    const { dataTransfer } = event;
    if (this.active && dataTransfer?.files) {
      if (!dataTransfer.files.length) {
        if (!DropZoneDirective.isShowingNoFilesMessage) {
          DropZoneDirective.isShowingNoFilesMessage = true;
          this.confirmationService.confirm({
            target: event.target as HTMLElement,
            message: translate("documents.noFiles.message"),
            header: translate("documents.noFiles.title"),
            icon: "pi pi-exclamation-triangle",
            acceptIcon: "none",
            rejectVisible: false,
            acceptLabel: translate("common.ok"),
          });
          DropZoneDirective.isShowingNoFilesMessage = false;
        }
        return;
      }
      this.filesDropped.emit(Array.from(dataTransfer.files));
    }
  }

  @HostListener("dragover", ["$event"])
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isActiveSubject.next(true);
  }

  @HostListener("dragleave", ["$event"])
  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isActiveSubject.next(false);
  }

  @HostListener("body:dragover", ["$event"])
  onBodyDragOver(event: DragEvent): void {
    if (this.preventBodyDrop) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener("body:drop", ["$event"])
  onBodyDrop(event: DragEvent): void {
    if (this.preventBodyDrop) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
