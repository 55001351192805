import { Directive, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DocumentMode } from "@ankaadia/ankaadia-shared";
import { DocumentTemplateMode } from "@ankaadia/graphql";
import { FileUpload } from "primeng/fileupload";
import { SettingsService } from "../../services/settings.service";

@Directive({ selector: "p-fileUpload[uploadConfig]", standalone: false })
export class UploadConfigDirective implements OnChanges {
  @Input("uploadConfig")
  organizationId: string;

  @Input()
  uploadConfigMode: DocumentMode;

  @Input()
  uploadConfigTemplateMode: DocumentTemplateMode;

  constructor(
    private readonly fileUpload: FileUpload,
    private readonly settings: SettingsService
  ) {}

  ngOnChanges(_changes: SimpleChanges): void {
    this.adjustComponent();
  }

  adjustComponent(): void {
    if (!this.fileUpload.accept) {
      this.fileUpload.accept = this.settings.acceptedFileFormats;
    }
    if (!this.fileUpload.maxFileSize) {
      this.fileUpload.maxFileSize = this.settings.maxFileSize;
    }
    if (this.uploadConfigMode === DocumentMode.Template) {
      if (this.uploadConfigTemplateMode === DocumentTemplateMode.Xlsx) {
        this.fileUpload.accept = ".xlsx";
      } else {
        this.fileUpload.accept = ".docx";
      }
    }
    if (!this.fileUpload.maxFileSize) {
      this.fileUpload.maxFileSize = 2 * 1024 * 1024;
    }
  }
}
