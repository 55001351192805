import { AbstractControl, ValidatorFn } from "@angular/forms";

export class CustomValidators {
  static minDate(min: Date): ValidatorFn {
    return (control: AbstractControl): Record<string, any> | null => {
      if (control.value && control.value.valueOf() < min.valueOf()) {
        return { minDate: true };
      }
      return null;
    };
  }

  static sameOrAfter(referenceDate: AbstractControl<Date>): ValidatorFn {
    return (control: AbstractControl): Record<string, any> | null => {
      if (control.value && control.value.getTime() < referenceDate.value?.getTime()) {
        return { sameOrAfter: true };
      }
      return null;
    };
  }

  static get notEmptyArray(): ValidatorFn {
    return (control: AbstractControl): Record<string, any> | null => {
      if (Array.isArray(control.value) && control.value.length > 0) {
        return null;
      }

      return { notEmptyArray: true };
    };
  }

  static get arrayEntriesRequired(): ValidatorFn {
    const emptyValues = [null, undefined, ""];
    return (control: AbstractControl): Record<string, any> | null => {
      if (!Array.isArray(control.value)) {
        return null;
      }

      if (control.value.every((entry) => !emptyValues.includes(entry))) {
        return null;
      }

      return { arrayEntriesRequired: true };
    };
  }
}
