<ng-container *appTranslate="let t">
  <form *ngIf="form" [formGroup]="form" (ngSubmit)="save()" class="app-form">
    <p-card [header]="t('crm.crmData') + ' - ' + (targetOrganizationName$ | async)">
      <p-tabView>
        <p-tabPanel [header]="t('crm.crmData')">
          <div class="p-fluid formgrid grid">
            <div class="field lg:col-3">
              <label for="clientNumber">{{ t("crm.clientNumber.title") }}</label>
              <input id="clientNumber" pInputText formControlName="clientNumber" />
            </div>
            <div class="field lg:col-3">
              <label for="debitorNumber">{{ t("crm.debitorNumber.title") }}</label>
              <input id="debitorNumber" pInputText formControlName="debitorNumber" />
            </div>
          </div>

          <div class="mt-5">
            <h4>{{ t("crm.notes") }}</h4>
            <app-organization-crm-notes
              [notesForm]="form.controls.note"
              [language]="language"
            ></app-organization-crm-notes>
          </div>
        </p-tabPanel>
      </p-tabView>

      <ng-template pTemplate="footer">
        <div class="flex" style="justify-content: space-between">
          <p-button
            [routerLink]="['/app/settings/organizations', myOrganizationId]"
            [label]="t('common.back')"
            icon="pi pi-angle-left"
          ></p-button>
          <p-button
            [disabled]="!form.valid || !form.dirty"
            [label]="t('common.save')"
            icon="pi pi-check"
            type="submit"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  </form>
</ng-container>
