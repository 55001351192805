<ng-container *appTranslate="let t">
  <p-overlayPanel [style]="{ width: '50vw' }" #popup>
    <ng-template pTemplate>
      <p class="m-0" [innerHTML]="getVariableDescription() | safe: 'html'"></p>

      <p-button
        submitOnEnter
        [label]="t('common.ok')"
        icon="pi pi-times"
        type="submit"
        [style]="{ float: 'right' }"
        class="mb-2 mr-2"
        (onClick)="popup.hide()"
      ></p-button>
    </ng-template>
  </p-overlayPanel>
</ng-container>
