<ng-container *appTranslate="let t" [formGroup]="form">
  <div class="grid mb-4">
    <div class="col-12 xl:col-3">
      <div class="field">
        <label for="xname">{{ t("name.title") }}</label>
        <input id="xname" pInputText formControlName="name" required maxlength="1000" />
        <small class="p-error" *ngIf="form.controls.name.errors?.required">
          {{ t("name.required") }}
        </small>
      </div>
    </div>

    <div *ngIf="fields.careFacility" class="col-12 xl:col-3">
      <div class="field">
        <label for="careFacility">{{ t("careFacility.title") }}</label>
        <p-multiSelect
          inputId="careFacility"
          [options]="careFacilities | async"
          [placeholder]="t('careFacility.placeholder')"
          formControlName="careFacility"
        ></p-multiSelect>
        <small class="p-error" *ngIf="form.controls.careFacility.errors?.required">
          {{ t("careFacility.required") }}
        </small>
      </div>
    </div>

    <div *ngIf="fields.experienceField" class="col-12 xl:col-3">
      <div class="field">
        <label for="experienceField">{{ t("experienceField.title") }}</label>
        <p-multiSelect
          inputId="experienceField"
          [options]="experienceFields | async"
          [placeholder]="t('experienceFields.placeholder')"
          formControlName="experienceField"
        ></p-multiSelect>
      </div>
    </div>

    <div *ngIf="showBeds" class="col-12 xl:col-3">
      <div class="field">
        <label for="beds">{{ t("beds.title") }}</label>
        <p-inputNumber inputId="beds" formControlName="beds" [min]="0"></p-inputNumber>
      </div>
    </div>

    <div class="col-12 xl:col-3">
      <div class="field">
        <label for="address">{{ t("address.title") }}</label>
        <textarea id="address" pInputTextarea rows="3" formControlName="address" required maxlength="10000"></textarea>
        <small class="p-error" *ngIf="form.controls.address.errors?.required">
          {{ t("address.required") }}
        </small>
      </div>
    </div>

    <div class="col-12 xl:col-3">
      <div class="field">
        <label for="zipcode">{{ t("zipCode.title") }}</label>
        <input id="zipcode" pInputText formControlName="zipcode" required maxlength="5" />
        <small class="p-error" *ngIf="form.controls.zipcode.errors?.required">
          {{ t("zipCode.required") }}
        </small>
        <small class="p-error" *ngIf="form.controls.zipcode.errors?.invalid || form.controls.zipcode.errors?.minlength">
          {{ t("zipCode.invalid") }}
        </small>
      </div>
    </div>

    <div *ngIf="fields.state" class="col-12 xl:col-3">
      <div class="field">
        <label for="state">{{ t("federalState.title") }}</label>
        <input
          id="state"
          pInputText
          [ngModel]="form.controls.state.value | staticData: StaticDataType.FederalStates | async"
          [ngModelOptions]="{ standalone: true }"
          [disabled]="true"
        />
      </div>
    </div>

    <div *ngIf="fields.city" class="col-12 xl:col-3">
      <div class="field">
        <label for="city">{{ t("city.title") }}</label>
        <input
          id="city"
          pInputText
          [ngModel]="form.controls.city.value"
          [ngModelOptions]="{ standalone: true }"
          [disabled]="true"
        />
      </div>
    </div>

    <div class="col-12 xl:col-3">
      <div class="field">
        <label for="website">{{ t("website.title") }}</label>
        <input id="website" pInputText formControlName="website" maxlength="300" />
      </div>
    </div>

    <div class="col-12 xl:col-3">
      <div class="field">
        <label>{{ t("internationalExperience.title") }}</label>
        <div class="formgroup-inline mt-2 mb-3">
          <div class="field-radiobutton mb-0">
            <p-radioButton
              inputId="hasInternationalExperience"
              [value]="true"
              formControlName="hasInternationalExperience"
            ></p-radioButton>
            <label for="hasInternationalExperience">
              {{ t("enum.boolean.true") }}
            </label>
          </div>
          <div class="field-radiobutton mb-0">
            <p-radioButton
              inputId="doesNotHaveInternationalExperience"
              [value]="false"
              formControlName="hasInternationalExperience"
            ></p-radioButton>
            <label for="doesNotHaveInternationalExperience">
              {{ t("enum.boolean.false") }}
            </label>
          </div>
        </div>
        <small class="p-error" *ngIf="form.controls.hasInternationalExperience.errors?.required">
          {{ t("internationalExperience.required") }}
        </small>
      </div>
    </div>
  </div>

  <app-organization-profile-location-vacancies
    [form]="form"
    [fields]="fields"
    [organizationId]="organizationId"
  ></app-organization-profile-location-vacancies>
</ng-container>
