<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <ng-container *ngIf="control">
    <label for="workLocation">{{ t("workLocation.title") }}</label>
    <input pInputText id="workLocation" [formControl]="control" [readonly]="readonly" />

    <small class="p-error" *ngIf="control.errors?.required && control.enabled">
      {{ t("common.validation.requiredShort") }}
    </small>
  </ng-container>
</ng-container>
