import { Injectable } from "@angular/core";
import { GetAllPlansGQL, GetPlanGQL, PlanFragment, PlanListFragment } from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class PlanService {
  constructor(
    private readonly getAllPlansGQL: GetAllPlansGQL,
    private readonly getPlanGQL: GetPlanGQL
  ) {}

  getAllPlans(): Observable<PlanListFragment[]> {
    return this.getAllPlansGQL.fetch().pipe(map((x) => x.data.getPlans));
  }

  getPlan(planId: string): Observable<PlanFragment> {
    return this.getPlanGQL.fetch({ id: planId }).pipe(map((x) => x.data.getPlan));
  }
}
