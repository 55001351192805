import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { OtherRole, getAllResponsibleRoles } from "@ankaadia/ankaadia-shared";
import { StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { isEmpty } from "lodash";
import { DropdownChangeEvent } from "primeng/dropdown";
import { Observable, ReplaySubject, combineLatest, map, mergeMap, of, switchMap, tap } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { ProcessService } from "../../process/process.service";
import {
  CandidateResponsibleRepresentativeGroupService,
  ResponsibleRolePurpose,
  ResponsibleRolePurposeWithRoleMapping,
} from "../candidate-responsible-representative-group/candidate-responsible-representative-group.service";

export interface ResponsibleRoleTranslations {
  caption: string;
  placeholder: string;
  required: string;
}

export interface ResponsibleRolePurposeConfig {
  getControlName: () => string;
  getTranslationKey: (language: string) => ResponsibleRoleTranslations;
}

export interface ProcessRoleWithTranslation {
  processRole: string;
  displayName: {
    language: string;
    value: string;
  }[];
}

export interface ProcessRoleName {
  value: string;
  label: string;
}

@Component({
  selector: "app-candidate-responsible-role",
  templateUrl: "./candidate-responsible-role.component.html",
  standalone: false,
})
export class CandidateResponsibleRoleComponent implements OnInit, OnChanges, OnDestroy {
  private readonly language = this.transloco.getActiveLang();

  get control(): FormControl<string> {
    return this.form.get(this.controlName) as FormControl<string>;
  }

  set control(value: FormControl<string>) {
    this.form.setControl(this.controlName, value);
  }

  @Input()
  form: FormGroup;

  @Input()
  inputId?: string;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  processRoles?: ProcessRoleWithTranslation[];

  @Input()
  alwaysShownRoles?: string[];

  @Input({ required: true })
  responsibleRolePurpose: ResponsibleRolePurposeWithRoleMapping;

  @Input()
  modelData: string;

  @Input()
  candidateId: string;

  @Input()
  renderLabelUsingAlignmentCheckBox = false;

  @Input() useFieldLabel = true;

  @Input()
  readonly = false;

  @Output()
  readonly valueChanged = new EventEmitter<string>();

  private readonly candidateId$ = new ReplaySubject<string>(1);
  private readonly processLanguage$ = new ReplaySubject<string>(1);
  private readonly responsibleRolePurpose$ = new ReplaySubject<ResponsibleRolePurpose>(1);

  processRoles$: Observable<ProcessRoleName[]>;
  responsibleRoles$: Observable<StaticDataModel[]>;
  translations$: Observable<ResponsibleRoleTranslations>;

  private getTranslations(): Observable<ResponsibleRoleTranslations> {
    return this.processLanguage$.pipe(
      map((language) =>
        this.groupService.getResponsibleRolePurposeConfig(this.responsibleRolePurpose).getTranslationKey(language)
      )
    );
  }

  get controlName(): string {
    return this.groupService.getResponsibleRolePurposeConfig(this.responsibleRolePurpose).getControlName();
  }

  constructor(
    private readonly transloco: TranslocoService,
    private readonly staticDataService: StaticDataService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly processService: ProcessService,
    private readonly settings: SettingsService,
    private readonly groupService: CandidateResponsibleRepresentativeGroupService
  ) {}

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<string>(undefined);

    this.processRoles$ = this.getEffectiveProcessRoles(); //wait for caller NgOnInit (e.g. enter-missing-information-task)
    this.responsibleRoles$ = this.getEffectiveResponsibleRoles();
    this.translations$ = this.getTranslations();

    this.candidateId$.next(this.candidateId);
    this.processLanguage$.next(this.processLanguage);
    this.responsibleRolePurpose$.next(this.responsibleRolePurpose);

    if (this.modelData) this.control.setValue(this.modelData);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.processLanguage) {
      this.processLanguage$.next(changes.processLanguage.currentValue);
    }
    if (changes.candidateId) {
      this.candidateId$.next(changes.candidateId.currentValue);
    }
    if (changes.responsibleRolePurpose) {
      this.responsibleRolePurpose$.next(changes.responsibleRolePurpose.currentValue);
    }
    if (changes.readonly) {
      if (!this.control) this.control = new FormControl<string>(undefined);
      if (changes.readonly.currentValue) this.control.disable();
      else this.control.enable();
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl(this.controlName, { emitEvent: false });
  }

  changedValue($event: DropdownChangeEvent): void {
    this.valueChanged.emit($event.value);
  }

  private getStaticData(language: string): Observable<StaticDataModel[]> {
    return this.staticDataService.getStaticData(StaticDataType.ProcessRole, language);
  }

  private getEffectiveProcessRoles(): Observable<ProcessRoleName[]> {
    return this.getCandidateProcessRoles().pipe(map((roles) => this.translateProcessRoles(roles)));
  }

  private getCandidateProcessRoles(): Observable<ProcessRoleWithTranslation[]> {
    return this.processRoles
      ? of(this.processRoles)
      : this.candidateId$.pipe(
          mergeMap((candidateId) =>
            candidateId ? this.loadCandidateProcessRoles(candidateId) : of(<ProcessRoleWithTranslation[]>[])
          )
        );
  }

  private getEffectiveResponsibleRoles(): Observable<StaticDataModel[]> {
    return this.processLanguage$.pipe(
      switchMap((language) => this.getResponsibleRoles(language)),
      tap(() => this.changeDetector.markForCheck())
    );
  }

  private loadCandidateProcessRoles(candidateId: string): Observable<ProcessRoleWithTranslation[]> {
    return this.processService.getCandidateProcessRoles(this.settings.organizationId, candidateId);
  }

  private translateProcessRoles(roles: ProcessRoleWithTranslation[]): ProcessRoleName[] {
    return roles.map((role) => ({
      value: role.processRole,
      label: this.getLabel(role),
    }));
  }

  private getLabel(y: ProcessRoleWithTranslation): string {
    function getDisplayNameForLanguage(language: string): string {
      return y?.displayName?.find((x) => x.language === language)?.value;
    }

    return (
      getDisplayNameForLanguage(this.processLanguage) ??
      getDisplayNameForLanguage("de-DE") ?? //should always be there
      y?.displayName[0]?.value ?? //Safety backs...
      y.processRole
    );
  }

  private getResponsibleRoles(language: string): Observable<StaticDataModel[]> {
    return combineLatest([this.responsibleRolePurpose$, this.processRoles$, this.getStaticData(language)]).pipe(
      map(([responsibleRolePurpose, processRoles, staticData]) => {
        const effectiveRoles = this.getProcessRolesAndMandatoryRoles(staticData, processRoles);

        const shownRoles = isEmpty(effectiveRoles)
          ? this.mapStaticDataRoles(getAllResponsibleRoles(), staticData)
          : effectiveRoles;

        if (responsibleRolePurpose === ResponsibleRolePurpose.Scholarship) {
          return [
            ...shownRoles.filter((x) => x.value !== OtherRole),
            { value: OtherRole, label: this.transloco.translate("responsibleRoleScholarship.otherRole") },
          ];
        }

        if (responsibleRolePurpose === ResponsibleRolePurpose.EducationVoucher) {
          return [
            ...shownRoles.filter((x) => x.value !== OtherRole),
            { value: OtherRole, label: this.transloco.translate("responsibleRoleEducationVoucher.otherRole") },
          ];
        }

        return shownRoles;
      })
    );
  }

  private getProcessRolesAndMandatoryRoles(
    staticData: StaticDataModel[],
    processRoles: ProcessRoleName[]
  ): StaticDataModel[] {
    const alwaysShownRolesOrEmpty = this.alwaysShownRoles ?? [];

    const alwaysShownRoles = this.mapStaticDataRoles(alwaysShownRolesOrEmpty ?? [], staticData);
    const shownProcessRoles = processRoles
      .filter((processRole) => !alwaysShownRolesOrEmpty.includes(processRole.value))
      .map((processRole) => staticData.find((x) => x.value === processRole.value) || processRole);

    return [...alwaysShownRoles, ...shownProcessRoles];
  }

  private mapStaticDataRoles(roles: string[], staticData: StaticDataModel[]): StaticDataModel[] {
    return staticData.filter((role) => roles.includes(role.value));
  }
}
