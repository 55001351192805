import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { AlwaysRequiredCandidateFields, omitEach } from "@ankaadia/ankaadia-shared";
import { CandidateDeletionSetting, SystemSettingFragment } from "@ankaadia/graphql";
import { SettingsService } from "../../../shared/services/settings.service";
import { CandidateDeletionSettingForm, SystemSettingForm } from "./organization-system-settings-form.model";

@Injectable({ providedIn: "root" })
export class SystemSettingFormService {
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly settings: SettingsService
  ) {}

  createForm(settings: SystemSettingFragment): SystemSettingForm {
    return this.formBuilder.group<SystemSettingForm["controls"]>({
      id: this.formBuilder.control(settings.id),
      _etag: this.formBuilder.control(settings._etag),
      isSystemLogoutEnabled: this.formBuilder.control(settings.isSystemLogoutEnabled ?? false),
      logoutPeriodMinutes: this.formBuilder.control(settings.logoutPeriodMinutes ?? 0),
      requiredCandidateFields: this.formBuilder.control([
        ...AlwaysRequiredCandidateFields,
        ...(settings.requiredCandidateFields ?? []),
      ]),
      precisionOfEducation: this.formBuilder.control(settings.precisionOfEducation),
      precisionOfWorkexperience: this.formBuilder.control(settings.precisionOfWorkexperience),
      organizationId: this.formBuilder.control(this.settings.organizationId),
      standardImmigrationCountry: this.formBuilder.control(settings.standardImmigrationCountry, Validators.required),
      supportedImmigrationCountries: this.formBuilder.control(
        settings.supportedImmigrationCountries,
        Validators.required
      ),
      enableCustomCandidateTags: this.formBuilder.control(settings.enableCustomCandidateTags ?? false),
      candidateTags: this.formBuilder.control(omitEach(settings.candidateTags, "__typename") ?? null),
      isPubliclyVisible: this.formBuilder.control(settings.isPubliclyVisible ?? true, Validators.required),
      removeCandidatesFromAllListsIfDropout: this.formBuilder.control(
        settings.removeCandidatesFromAllListsIfDropout ?? true,
        Validators.required
      ),
      candidateDeletionSetting: this.formBuilder.array<CandidateDeletionSettingForm>(
        settings.candidateDeletionSetting.map((x) => this.createDeletionSettingEntry(x))
      ),
      fileNameWithCandidateId: this.formBuilder.control(settings.fileNameWithCandidateId ?? false),
      fileNameWithFileFormat: this.formBuilder.control(settings.fileNameWithFileFormat ?? true),
      automaticRejectionEmailsEnabled: this.formBuilder.control(settings.automaticRejectionEmailsEnabled ?? true),
    });
  }

  private createDeletionSettingEntry(deletionSetting: CandidateDeletionSetting): CandidateDeletionSettingForm {
    return this.formBuilder.group<CandidateDeletionSettingForm["controls"]>({
      candidateStatusForDeletion: this.formBuilder.control(deletionSetting.candidateStatusForDeletion),
      isEnabled: this.formBuilder.control(deletionSetting.isEnabled ?? true),
      daysAfterDeletion: this.formBuilder.control({
        value: deletionSetting.daysAfterDeletion ?? 180,
        disabled: !deletionSetting.isEnabled,
      }),
    });
  }
}
