import { Component, Input, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { EmploymentRelationship, SupportedImmigrationCountry } from "@ankaadia/graphql";
import { InputSwitchChangeEvent } from "primeng/inputswitch";
import { EmploymentRelationshipFormDe } from "../../candidate-form.model";
import { CandidateEmployerEntryComponent } from "./candidate-employment-relationship-entry/candidate-employment-relationship-entry.component";

@Component({
  selector: "app-candidate-employment-relationship",
  templateUrl: "./candidate-employment-relationship.component.html",
  standalone: false,
})
export class CandidateEmployerComponent {
  @Input()
  form: EmploymentRelationshipFormDe;

  @Input()
  readonly: boolean;

  @Input()
  candidateId: string;

  @Input()
  candidateOrganizationId: string;

  @Input()
  employmentRelationship: EmploymentRelationship;

  @Input()
  immigrationCountry: SupportedImmigrationCountry;

  @Input()
  isMultiEditMode: boolean;

  @ViewChild("afterRecognition")
  employmentRelationshipAfterRecognition: CandidateEmployerEntryComponent;

  get hasDifferentRelationships(): boolean {
    return this.form.controls?.hasDifferentRelationships?.value;
  }

  autoFillFieldsWhenNotSet(event: InputSwitchChangeEvent): void {
    if (event.checked) {
      const employerId = this.form.controls.beforeRecognition?.controls?.employerId?.value;
      if (this.copyEmployerIfNotSet(employerId)) {
        this.employmentRelationshipAfterRecognition.refreshEmployerData(employerId);
        this.copyRepresentativeIfNotSet(this.form.controls.beforeRecognition?.controls?.representativeId?.value);
        this.copyDeputyIfNotSet(this.form.controls.beforeRecognition?.controls?.firstDeputyId?.value);
        this.copyTypeOfWorkLocationIfNotSet(this.form.controls.beforeRecognition?.controls?.typeOfWorkLocation?.value);
        this.copyWorkLocationIfNotSet(this.form.controls.beforeRecognition?.controls?.workLocation?.value);
        this.copyEmploymentRelocationRequirementsIfNotSet(
          this.form.controls.beforeRecognition?.controls?.employmentRelocationRequirements?.value
        );
      }
    }
  }

  private copyEmployerIfNotSet(data: string): boolean {
    return this.autoFillFieldWhenNotSet(this.form.controls.afterRecognition?.controls?.employerId, data);
  }

  private copyRepresentativeIfNotSet(data: string): boolean {
    return this.autoFillFieldWhenNotSet(this.form.controls.afterRecognition?.controls?.representativeId, data);
  }

  private copyDeputyIfNotSet(data: string): boolean {
    return this.autoFillFieldWhenNotSet(this.form.controls.afterRecognition?.controls?.firstDeputyId, data);
  }

  private copyTypeOfWorkLocationIfNotSet(data: string): boolean {
    return this.autoFillFieldWhenNotSet(this.form.controls.afterRecognition?.controls?.typeOfWorkLocation, data);
  }

  private copyWorkLocationIfNotSet(data: string): boolean {
    return this.autoFillFieldWhenNotSet(this.form.controls.afterRecognition?.controls?.workLocation, data);
  }

  private autoFillFieldWhenNotSet<T>(control: FormControl<T>, value: T): boolean {
    if (this.hasDifferentRelationships) {
      return this.copyValueIfNotSet(control, value);
    }
    return false;
  }

  private copyEmploymentRelocationRequirementsIfNotSet(data: string[]): boolean {
    return this.autoFillFieldWhenNotSet(
      this.form.controls.afterRecognition?.controls?.employmentRelocationRequirements,
      data
    );
  }

  private copyValueIfNotSet<T>(control: FormControl<T>, value: T): boolean {
    if (!control?.value) {
      control?.setValue(value);
      return true;
    }
    return false;
  }
}
