<ng-container *appTranslate="let t">
  <p-tabView class="vertical" styleClass="app-form" rememberTab stateKey="candidate-vertical-tabs-migration-de">
    <p-tabPanel [header]="t('employerData.title')" *ngIf="tabs?.employers">
      <div>
        <p-panel>
          <app-candidate-employment-relationship
            [form]="form.controls.de.controls.employmentRelationship"
            [readonly]="readonly"
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [employmentRelationship]="employmentRelationship"
            [immigrationCountry]="SupportedImmigrationCountry.De"
            [isMultiEditMode]="isMultiEditMode"
          ></app-candidate-employment-relationship>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="t('qualificationEvaluation.title')" *ngIf="tabs?.qualificationEvaluation">
      <div>
        <p-panel>
          <app-candidate-qualification-evaluation
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [form]="form.controls.de.controls.qualificationEvaluation"
            [qualificationEvaluation]="qualificationEvaluation"
            [isMultiEditMode]="isMultiEditMode"
            [readonly]="readonly"
          ></app-candidate-qualification-evaluation>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel
      [header]="t('recognitionPath.title')"
      [headerStyleClass]="form.controls.de.controls.recognitionPath.invalid ? 'p-error' : ''"
      *ngIf="tabs?.recognitionPath"
    >
      <div>
        <p-panel>
          <app-candidate-recognition-path-de
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [candidateProfession]="candidateProfession"
            [currentRecognitionPath]="currentRecognitionPath"
            [alternativeRecognitionPath]="alternativeRecognitionPath"
            [readonly]="readonly"
            [form]="form.controls.de.controls.recognitionPath"
          ></app-candidate-recognition-path-de>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="t('laborMarketAdmission.title')" *ngIf="tabs?.laborMarketAdmission">
      <div>
        <p-panel>
          <app-candidate-labor-market-admission-de
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [form]="form.controls.de.controls.laborMarketAdmission"
            [readonly]="readonly"
          ></app-candidate-labor-market-admission-de>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="t('qualificationMeasure.title')" *ngIf="tabs?.qualificationMeasure">
      <div>
        <p-panel>
          <app-candidate-qualification-measure-de
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [form]="form.controls.de.controls.qualificationMeasure"
            [readonly]="readonly"
            [qualificationMeasureDataDe]="qualificationMeasureDataDe"
          ></app-candidate-qualification-measure-de>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel *ngIf="tabs?.vocationalSchool" [header]="t('vocationalSchool.title')">
      <ng-template pTemplate="header">
        <span>{{ t("vocationalSchool.title") }} </span>
        <i class="ml-1 pi pi-info-circle" [pTooltip]="t('vocationalSchool.description')"></i>
      </ng-template>
      <div>
        <p-panel>
          <app-candidate-vocational-school-de
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [form]="form.controls.de.controls.vocationalSchool"
            [readonly]="readonly"
            [isMultiEditMode]="isMultiEditMode"
          ></app-candidate-vocational-school-de>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="t('housingAcquisition.title')" *ngIf="tabs?.housingAcquisition">
      <div>
        <p-panel>
          <app-candidate-housing-acquisition
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [form]="form.controls.housingAcquisition"
            [housingAcquisition]="housingAcquisition"
            [readonly]="readonly"
          ></app-candidate-housing-acquisition>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="t('visaTransfer.title')" *ngIf="tabs?.visa">
      <div>
        <p-panel>
          <app-candidate-visa-de
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [form]="form.controls.de.controls.visa"
            [readonly]="readonly"
          ></app-candidate-visa-de>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="t('residence.title')" *ngIf="tabs?.residence">
      <div>
        <p-panel>
          <app-candidate-residence-de
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [form]="form.controls.de.controls.residence"
            [residence]="residence"
            [readonly]="readonly"
          ></app-candidate-residence-de>
        </p-panel>
      </div>
    </p-tabPanel>
  </p-tabView>
</ng-container>
