<form [formGroup]="form" *appTranslate="let t">
  <p-card [header]="t('collection.transferFull')">
    <div class="p-fluid">
      <div class="field">
        <label for="targetOrganizationId">{{ t("organization.title") }}</label>
        <p-dropdown
          inputId="targetOrganizationId"
          [options]="sharings"
          formControlName="targetOrganizationId"
          filterBy="destinationOrganizationName"
          optionLabel="destinationOrganizationName"
          optionValue="destinationOrganizationId"
          [placeholder]="t('organization.placeholder')"
        ></p-dropdown>
        <small class="p-error" *ngIf="form.controls.targetOrganizationId.errors?.required">{{
          t("organization.required")
        }}</small>
      </div>
    </div>

    <p-message severity="warn" [text]="t('collection.transferWarning')"></p-message>
    <div class="grid">
      <div class="col-12">
        <p-checkbox
          [binary]="true"
          formControlName="keepCandidateCopy"
          [label]="t('collection.transferOptions.candidateCopy')"
          class="mt-3"
        ></p-checkbox>
        <i
          class="pi pi-info-circle p-info ml-2"
          [pTooltip]="t('collection.transferOptions.copyExplanation')"
          [escape]="false"
        ></i>
      </div>

      <p-checkbox
        [binary]="true"
        formControlName="transferInternalNotes"
        [label]="t('collection.transferOptions.internalNotes')"
        class="mt-2 col-12"
      ></p-checkbox>
      <p-checkbox
        [binary]="true"
        formControlName="transferInternalDocuments"
        [label]="t('collection.transferOptions.internalDocuments')"
        class="mt-2 col-12"
      ></p-checkbox>
      <p-checkbox
        [binary]="true"
        formControlName="transferInterviewData"
        [label]="t('collection.transferOptions.interviewData')"
        class="mt-2 col-12"
      ></p-checkbox>
    </div>

    <ng-template pTemplate="footer">
      <div class="flex flex-row justify-content-end">
        <p-button
          [disabled]="!form.valid || !form.dirty || isTransferring"
          [label]="t('common.save')"
          icon="pi pi-check"
          (onClick)="onSave($event)"
          class="mr-2"
        ></p-button>
        <p-button
          closeOnEscape
          [label]="t('common.cancel')"
          icon="pi pi-times"
          class="p-button-secondary"
          (onClick)="onCancel()"
        ></p-button>
      </div>
    </ng-template>
  </p-card>
</form>
