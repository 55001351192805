import { HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthHttpInterceptor } from "@auth0/auth0-angular";
import { Mutation, Query } from "apollo-angular";
import { OperationDefinitionNode } from "graphql";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class AuthInterceptor extends AuthHttpInterceptor {
  // AuthInterceptor is not a singleton hence we must store the noLoginRequiredOperations in a static variable
  private static noLoginRequiredOperations: Record<string, boolean> = {};

  registerNoLoginOperation(operation: Query<any> | Mutation<any>): void {
    AuthInterceptor.noLoginRequiredOperations[
      (operation.document.definitions[0] as OperationDefinitionNode).name.value
    ] = true;
  }

  override intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.endsWith("api/graphql") || AuthInterceptor.noLoginRequiredOperations[req.body.operationName]) {
      return next.handle(req);
    } else {
      return super.intercept(req, next);
    }
  }
}
