<ng-container *appTranslate="let t">
  <form [formGroup]="form" (ngSubmit)="save()">
    <p-card [header]="form.controls.id.value ? t('educationModule.edit') : t('educationModule.create')">
      <div class="p-fluid formgrid">
        <div class="field">
          <label for="name">{{ t("name.title") }}</label>
          <input type="text" pInputText id="name" formControlName="name" />
          <small class="p-error" *ngIf="form.controls.name.errors?.required">{{ t("name.required") }}</small>
          <small class="p-error" *ngIf="form.controls.name.errors?.maxlength">
            {{ t("common.validation.maxLength", { length: form.controls.name.errors.maxlength.requiredLength }) }}
          </small>
        </div>

        <div class="field">
          <label for="description">{{ t("description.title") }}</label>
          <input type="text" pInputText id="description" formControlName="description" />
          <small class="p-error" *ngIf="form.controls.description.errors?.maxlength">
            {{
              t("common.validation.maxLength", { length: form.controls.description.errors.maxlength.requiredLength })
            }}
          </small>
        </div>

        <div class="field">
          <label for="providerOrganizationId">{{ t("educationProvider.title") }}</label>
          <p-dropdown
            inputId="providerOrganizationId"
            formControlName="providerOrganizationId"
            [options]="educationProviders$ | async"
            optionLabel="name"
            optionValue="id"
            [placeholder]="t('educationProvider.placeholder')"
          ></p-dropdown>
          <small class="p-error" *ngIf="form.controls.providerOrganizationId.errors?.required">
            {{ t("educationProvider.required") }}
          </small>
        </div>

        <div class="field">
          <label for="type">{{ t("type.title") }}</label>
          <p-dropdown
            inputId="type"
            formControlName="type"
            [options]="types"
            [placeholder]="t('type.placeholder')"
          ></p-dropdown>
          <small class="p-error" *ngIf="form.controls.type.errors?.required">{{ t("type.required") }}</small>
        </div>

        <div class="field" *ngIf="form.controls.type.value === EducationModuleType.LanguageLearning">
          <label for="languageLevel">{{ t("skillLevel.title") }}</label>
          <p-dropdown
            inputId="languageLevel"
            formControlName="languageLevel"
            [options]="languageLevels$ | async"
            [placeholder]="t('skillLevel.placeholder')"
          ></p-dropdown>
          <small class="p-error" *ngIf="form.controls.languageLevel.errors?.required">
            {{ t("skillLevel.required") }}
          </small>
        </div>

        <div class="field">
          <label for="status">{{ t("status.title") }}</label>
          <div>
            <p-inputSwitch
              inputId="status"
              formControlName="status"
              [trueValue]="EducationModuleStatus.Active"
              [falseValue]="EducationModuleStatus.Inactive"
            ></p-inputSwitch>
          </div>
        </div>

        <div class="field">
          <label for="mode">{{ t("educationMode.title") }}</label>
          <p-dropdown
            inputId="mode"
            formControlName="mode"
            [options]="modes"
            [placeholder]="t('educationMode.placeholder')"
          ></p-dropdown>
          <small class="p-error" *ngIf="form.controls.mode.errors?.required">{{ t("educationMode.required") }}</small>
        </div>

        <div class="field">
          <label for="units">{{ t("educationUnits.title") }}</label>
          <p-inputNumber inputId="units" formControlName="units" [min]="1"></p-inputNumber>
          <small class="p-error" *ngIf="form.controls.units.errors?.required">{{ t("educationUnits.required") }}</small>
        </div>
      </div>

      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
          <p-button
            [disabled]="!form.valid || !form.dirty"
            [label]="t('common.save')"
            icon="pi pi-check"
            type="submit"
            class="mr-2"
          ></p-button>

          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            type="reset"
            (onClick)="close()"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  </form>
</ng-container>
