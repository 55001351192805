import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-collection-edit",
  templateUrl: "./collection-edit.component.html",
  standalone: false,
})
export class CollectionEditComponent {
  @Input()
  form: FormGroup;
}
