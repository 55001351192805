import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  Collection,
  RequiredVaccinationsStatesForCollectionInput,
  StaticDataModel,
  StaticDataType,
  VaccinationStatus,
  VaccineStatusEnum,
} from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { sortBy, uniq } from "lodash";
import { PrimeIcons } from "primeng/api";
import { BehaviorSubject, combineLatest, filter, map, Observable, switchMap, tap } from "rxjs";
import { valuesOf } from "../../../shared/services/form.helper";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { RequiredVaccinationsService } from "../../required-vaccinations/required-vaccinations.service";

@Component({
  selector: "app-collection-vaccines-overview",
  templateUrl: "./collection-vaccines-overview.component.html",
  styleUrl: "./collection-vaccines-overview.component.scss",
  standalone: false,
})
export class CollectionVaccinesOverviewComponent implements OnChanges {
  @Input({ required: true })
  collection: Collection;

  protected selectedVaccineFunctions: FormControl<string[]> = new FormControl<string[]>([]);
  protected filterText: FormControl<string> = new FormControl<string>(undefined);

  protected language = this.trans.getActiveLang();

  protected readonly vaccineFunctions$ = this.staticDataService
    .getStaticData(StaticDataType.VaccineFunction, this.language)
    .pipe(
      map((x) => sortBy(x, "value")),
      tap((x) => this.setDefaultVaccineFunctions(x))
    );

  private readonly vaccines$ = this.staticDataService.getStaticData(StaticDataType.Vaccination, this.language);

  protected collection$ = new BehaviorSubject<Collection>(undefined);

  protected candidateVaccineStates$ = this.collection$.pipe(
    filter((x) => !!x),
    map((x) => this.getRequiredVaccineStateInput(x)),
    switchMap((x) => this.requiredVaccinationsService.getStatesForCandidates(x))
  );

  private readonly selectedVaccineFunctionsChanges$ = this.selectedVaccineFunctions.valueChanges.pipe(
    map((x) => sortBy(x))
  );

  private readonly vaccineStatesByVaccineFunction$ = combineLatest([
    this.candidateVaccineStates$,
    this.selectedVaccineFunctionsChanges$,
  ]).pipe(
    map(([states, selectedFunctions]) =>
      states.map((x) => ({
        ...x,
        vaccinations: x.vaccinations.filter((v) => selectedFunctions.includes(v.vaccineFunction)),
      }))
    )
  );

  protected candidates$: Observable<
    {
      displayId: string;
      displayName: string;
      candidateId: string;
      candidateOrgId: string;
      icons: ColumnDataData[];
    }[]
  > = combineLatest([this.candidateVaccineStates$, this.selectedVaccineFunctionsChanges$, this.vaccineFunctions$]).pipe(
    map(([candidateVaccineStates, selectedVaccineFunctions, vaccineFunctions]) =>
      candidateVaccineStates.map((c) => ({
        ...c,
        icons: selectedVaccineFunctions.map((selectedVaccineFunction) => ({
          icon: VaccineFunctionIconMap[selectedVaccineFunction],
          tooltip: vaccineFunctions.find((x) => x.value === selectedVaccineFunction)?.label ?? "",
        })),
      }))
    ),
    map((x) => sortBy(x, "displayName"))
  );

  protected firstCellStyle$: Observable<string> = this.collection$.pipe(
    map((x) => "min-width: 250px;max-width: 500px;width:" + 100 / ((x?.assignedCandidates?.length ?? 0) + 1) + "%")
  );

  protected candidateCellStyle$: Observable<string> = this.collection$.pipe(
    map((x) => "min-width: 80px;max-width: 160px;width:" + 100 / ((x?.assignedCandidates?.length ?? 0) + 1) + "%")
  );

  protected iconCellStyle$: Observable<string> = this.collection$.pipe(
    map((x) => "min-width: 40px;max-width: 160px;width:" + 50 / ((x?.assignedCandidates?.length ?? 0) + 1) + "%")
  );

  private readonly rawRowData$ = combineLatest([this.candidates$, this.vaccineStatesByVaccineFunction$]).pipe(
    map(([candidates, states]) => {
      const allVaccines = uniq(states.flatMap((x) => x.vaccinations.flatMap((y) => y.vaccine)));

      return allVaccines.map((vaccine) => ({
        vaccine: vaccine,
        candidatesColumns: candidates.map(
          (candidate) =>
            states
              .find((state) => candidate.candidateId === state.candidateId)
              ?.vaccinations?.filter((v) => v.vaccine === vaccine) ?? []
        ),
      }));
    })
  );

  private readonly rowsTranslated$: Observable<RowData[]> = combineLatest([
    this.rawRowData$,
    this.vaccines$,
    this.selectedVaccineFunctionsChanges$,
  ]).pipe(
    map(([rows, vaccinesStaticData, vaccinesFunctions]) => {
      return rows.map((row) => ({
        vaccine: vaccinesStaticData.find((x) => x.value === row.vaccine).label,
        candidatesColumns: row.candidatesColumns.map((vaccinationStatus) =>
          vaccinesFunctions.map((v) => this.getColumnDataData(vaccinationStatus, v))
        ),
      }));
    })
  );

  protected readonly rows$: Observable<RowData[]> = combineLatest([
    this.rowsTranslated$,
    valuesOf(this.filterText),
  ]).pipe(
    map(([rows, filterText]) =>
      rows.filter((x) => !filterText || x.vaccine.toLowerCase().includes(filterText?.toLowerCase()))
    ),
    map((x) => sortBy(x, "vaccine"))
  );

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly trans: TranslocoService,
    private readonly requiredVaccinationsService: RequiredVaccinationsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.collection) this.collection$.next(this.collection);
  }

  private getRequiredVaccineStateInput(collection: Collection): RequiredVaccinationsStatesForCollectionInput {
    return {
      id: collection.id,
      organizationId: collection.organizationId,
    };
  }

  private setDefaultVaccineFunctions(vaccineFunctions: StaticDataModel[]): void {
    this.selectedVaccineFunctions.setValue(vaccineFunctions.map((x) => x.value));
  }

  private getColumnDataData(
    vaccinationStatus: VaccinationStatus[],
    vaccinesFunctionsStaticData: string
  ): ColumnDataData {
    const foundVaccinationStatus = vaccinationStatus.find((x) => x.vaccineFunction === vaccinesFunctionsStaticData);
    return {
      icon: VaccineStateIconMap[foundVaccinationStatus?.status]?.icon,
      color: VaccineStateIconMap[foundVaccinationStatus?.status]?.color,
      tooltip: this.trans.translate(VaccineStateIconMap[foundVaccinationStatus?.status]?.tooltip),
    };
  }
}

interface RowData {
  vaccine: string;
  candidatesColumns: ColumnDataData[][];
}
type VaccineStateColor = "Green" | "GreenYellow" | "Red" | "Brown" | "Orange";

interface ColumnDataData {
  icon: PrimeIcons;
  color?: VaccineStateColor;
  tooltip: string;
}

const VaccineStateIconMap: Record<VaccineStatusEnum, ColumnDataData> = {
  [VaccineStatusEnum.Available]: {
    icon: PrimeIcons.CHECK_CIRCLE,
    color: "Green",
    tooltip: "requiredVaccines.overview.tooltips.available",
  },
  [VaccineStatusEnum.Missing]: {
    icon: PrimeIcons.BAN,
    color: "Red",
    tooltip: "requiredVaccines.overview.tooltips.missing",
  },
  [VaccineStatusEnum.MissingNotRequired]: {
    icon: PrimeIcons.CIRCLE,
    color: "Brown",
    tooltip: "requiredVaccines.overview.tooltips.missingNotRequired",
  },
  [VaccineStatusEnum.Partial]: {
    icon: PrimeIcons.CHECK_CIRCLE,
    color: "Orange",
    tooltip: "requiredVaccines.overview.tooltips.partial",
  },
  [VaccineStatusEnum.NotShared]: {
    icon: PrimeIcons.BOLT,
    color: "Red",
    tooltip: "requiredVaccines.overview.tooltips.notShared",
  },
};
const VaccineFunctionIconMap: Record<"StartOfEmployment" | "Immigration", PrimeIcons> = {
  Immigration: PrimeIcons.CAR,
  StartOfEmployment: PrimeIcons.BUILDING,
};
