import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-candidate-process-status",
  templateUrl: "./candidate-process-status.component.html",
  standalone: false,
})
export class CandidateProcessStatusComponent {
  @Input()
  form: FormGroup;

  @Input()
  readonly: boolean;
}
