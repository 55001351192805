import { ChangeDetectionStrategy, Component, computed, input } from "@angular/core";
import { SharedModule } from "../../../../shared/shared.module";
import { PlanService } from "../plan.service";
import { EMPTY } from "rxjs";
import { PlanCandidatePriceSummaryComponent } from "./candidate-price/plan-candidate-price-summary.component";
import { PlanLicensePriceSummaryComponent } from "./license-price/plan-license-price-summary.component";

@Component({
  standalone: true,
  providers: [PlanService],
  imports: [SharedModule, PlanCandidatePriceSummaryComponent, PlanLicensePriceSummaryComponent],
  selector: "app-plan-summary",
  templateUrl: "./plan-summary.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanSummaryComponent {
  planId = input.required<string>();

  plan = computed(() => (this.planId() ? this.planService.getPlan(this.planId()) : EMPTY));

  constructor(private readonly planService: PlanService) {}
}
