import { Injectable } from "@angular/core";
import { DialogService } from "primeng/dynamicdialog";
import { translate } from "@jsverse/transloco";
import { BehaviorSubject, filter, map, Observable, tap } from "rxjs";
import { CandidateFlexibleEditComponent } from "./candidate-flexible-edit.component";
import { isNil } from "lodash";

@Injectable({ providedIn: "root" })
export class CandidateFlexibleEditService {
  showCandidate$ = new BehaviorSubject<FlexibleCandidateEditInput>(undefined);
  isDialogOpen$ = this.showCandidate$.pipe(map((x) => this.isValidCandidateData(x)));

  constructor(private readonly dialogService: DialogService) {}

  openCandidate(candidateId: string, candidateOrganizationId: string): void {
    this.showCandidate$.next({ candidateId, candidateOrganizationId });
  }

  initOpenCandidateListener(appendTo: Element): Observable<FlexibleCandidateEditInput> {
    return this.showCandidate$.pipe(
      filter((x) => this.isValidCandidateData(x)),
      tap((x) => this.openDialog(x, appendTo))
    );
  }

  private openDialog(candidate: FlexibleCandidateEditInput, appendTo: Element): void {
    const ref = this.dialogService.open(CandidateFlexibleEditComponent, {
      closeOnEscape: false,
      dismissableMask: false,
      resizable: true,
      closable: true,
      draggable: true,
      header: translate("candidate.edit.title"),
      data: candidate,
      maximizable: true,
      width: "80%",
      height: "80%",
      appendTo: appendTo,
    });
    ref.onClose.subscribe(() => this.markDialogAsClosed());
  }

  isValidCandidateData(x: FlexibleCandidateEditInput): boolean {
    return !isNil(x?.candidateId) && !isNil(x?.candidateOrganizationId);
  }

  private markDialogAsClosed(): void {
    this.showCandidate$.next(undefined);
  }
}

interface FlexibleCandidateEditInput {
  candidateId: string;
  candidateOrganizationId: string;
}
