<ng-container *appTranslate="let t">
  <ng-container *ngIf="strings?.length">
    <div class="field">
      <ng-container [formGroup]="form">
        <div class="field flex justify-content-between align-items-start">
          <label for="strings-{{ selectedLanguage }}" class="mr-3 mt-2 pt-1">
            {{ header }}
          </label>
          <app-language-selector
            *ngIf="showLanguageSelector"
            class="mw-1"
            [languages]="translatedLanguages"
            [(selectedLanguage)]="selectedLanguage"
          ></app-language-selector>
        </div>
        <ng-container *ngFor="let language of translatedLanguages; index as i" formArrayName="strings">
          <ng-container [formGroupName]="i">
            <p-editor
              *ngIf="language.value === selectedLanguage"
              spellcheck="true"
              [lang]="selectedLanguage"
              [style]="{ height: '120px' }"
              id="strings-{{ language.value }}"
              (onInit)="onEditorInitialized($event.editor)"
              (onTextChange)="updateControl($event, i)"
            >
              <ng-template pTemplate="header">
                <span class="ql-formats">
                  <select class="ql-size"></select>
                </span>
                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button>
                </span>
                <span class="ql-formats">
                  <select class="ql-color"></select>
                  <select class="ql-background"></select>
                </span>
                <span class="ql-formats">
                  <button class="ql-header" value="1"></button>
                  <button class="ql-header" value="2"></button>
                  <button class="ql-blockquote"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-indent" value="-1"></button>
                  <button class="ql-indent" value="+1"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-direction" value="rtl"></button>
                  <select class="ql-align"></select>
                </span>
                <span class="ql-formats">
                  <button class="ql-link"></button>
                </span>
              </ng-template>
            </p-editor>
          </ng-container>
        </ng-container>
      </ng-container>
      <small *ngIf="missingLanguages?.length" [class.p-error]="requiredMissingLanguages.length">
        {{ t("localizedString.missingTranslations", { languages: missingLanguages.join(", ") }) }}
      </small>
    </div>
  </ng-container>
  <ng-container *ngIf="!strings?.length">
    <div class="field">
      <label>
        {{ header }}
      </label>
      <p-message
        [severity]="isRequired ? 'error' : 'warn'"
        [text]="t('localizedString.languages.required')"
      ></p-message>
    </div>
  </ng-container>
</ng-container>
