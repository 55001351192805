<ng-container [formGroup]="form" *appTranslate="let t">
  <div class="p-fluid">
    <div class="field" data-testid="input-field-collection-name">
      <label for="name">{{ t("collectionName.title") }}</label>
      <input type="text" pInputText id="name" formControlName="name" required />
      <small class="p-error" *ngIf="form.controls.name.errors?.required">
        {{ t("collectionName.required") }}
      </small>
    </div>

    <div class="field" data-testid="input-field-collection-description">
      <label for="description">{{ t("description.title") }}</label>
      <textarea
        id="description"
        type="text"
        rows="3"
        pInputTextarea
        [autoResize]="true"
        formControlName="description"
      ></textarea>
      <small class="p-error" *ngIf="form.controls.description.errors?.required">
        {{ t("description.required") }}
      </small>
    </div>

    <div class="field" data-testid="input-field-collection-target-size">
      <label for="targetSize">{{ t("targetSize.title") }}</label>
      <p-inputNumber inputId="targetSize" id="targetSize" formControlName="targetSize"></p-inputNumber>
    </div>
  </div>
</ng-container>
