import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "app-formly-input",
  templateUrl: "./formly-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FormlyInputComponent extends FieldType {}
