<ng-container *appTranslate="let t">
  <p-fieldset [formGroup]="form" [legend]="t('hr.title')">
    <div class="grid">
      <div class="field col-12 lg:col-6">
        <label for="personnelNumber">{{ t("hr.personnelNumber.title") }}</label>
        <input
          id="personnelNumber"
          type="text"
          pInputText
          formControlName="personnelNumber"
          [placeholder]="t('hr.personnelNumber.placeholder')"
          [readonly]="readonly"
        />

        <small class="p-error" *ngIf="form.controls.personnelNumber.errors?.required">
          {{ t("hr.personnelNumber.required") }}
        </small>
      </div>

      <div class="field col-12 lg:col-3">
        <label for="personnelArea">{{ t("hr.personnelArea.title") }}</label>
        <input
          id="personnelArea"
          type="text"
          pInputText
          formControlName="personnelArea"
          [placeholder]="t('hr.personnelArea.placeholder')"
          [readonly]="readonly"
        />

        <small class="p-error" *ngIf="form.controls.personnelArea.errors?.required">
          {{ t("hr.personnelArea.required") }}
        </small>
      </div>

      <div class="field col-12 lg:col-3">
        <label for="personnelDepartment">{{ t("hr.personnelDepartment.title") }}</label>
        <input
          id="personnelDepartment"
          type="text"
          pInputText
          formControlName="personnelDepartment"
          [placeholder]="t('hr.personnelDepartment.placeholder')"
          [readonly]="readonly"
        />

        <small class="p-error" *ngIf="form.controls.personnelDepartment.errors?.required">
          {{ t("hr.personnelDepartment.required") }}
        </small>
      </div>

      <app-candidate-responsible-representative
        class="field col-12 lg:col-6"
        [form]="form"
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [contactOrganizationId]="employerOrganizationId$ | async"
        [setDefaultOnChange]="false"
        [showDefaultAsPlaceholder]="false"
        [readonly]="readonly"
        [responsibleRepresentativePurpose]="{
          purpose: ResponsibleRolePurpose.Supervisor,
          function: 'Representative',
        }"
      ></app-candidate-responsible-representative>

      <app-candidate-responsible-representative
        class="field col-12 lg:col-6"
        [form]="form"
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [contactOrganizationId]="employerOrganizationId$ | async"
        [setDefaultOnChange]="false"
        [showDefaultAsPlaceholder]="false"
        [readonly]="readonly"
        [responsibleRepresentativePurpose]="{
          purpose: ResponsibleRolePurpose.ContactPartner,
          function: 'Representative',
        }"
      ></app-candidate-responsible-representative>
      <div class="field col-12 lg:col-6">
        <label for="terminationEmploymentActor">{{ t("hr.terminationEmploymentActor.title") }}</label>
        <p-dropdown
          id="terminationEmploymentActor"
          formControlName="terminationEmploymentActor"
          [readonly]="readonly"
          [options]="TerminationEmploymentActor"
          [placeholder]="t('hr.terminationEmploymentActor.placeholder')"
        >
        </p-dropdown>
      </div>
      <div class="field col-12 lg:col-6">
        <label for="terminationEmploymentDate">{{ t("hr.terminationEmploymentDate.title") }}</label>
        <p-calendar
          inputId="terminationEmploymentDate"
          formControlName="terminationEmploymentDate"
          [readonly]="readonly"
        ></p-calendar>
      </div>
    </div>
  </p-fieldset>
</ng-container>
