import { DocumentType } from "./file-type-settings";
import { FilterMetadata } from "primeng/api";

type RECDOC = Extract<DocumentType, "RECDOC">;
type DEFICITNOTE = Extract<DocumentType, "DEFICITNOTE">;
type LANGCERT = Extract<DocumentType, "LANGCERT">;
type LANGCERTGERA1 = Extract<DocumentType, "LANGCERTGERA1">;
type LANGCERTGERA2 = Extract<DocumentType, "LANGCERTGERA2">;
type LANGCERTGERB1 = Extract<DocumentType, "LANGCERTGERB1">;
type LANGCERTGERB2 = Extract<DocumentType, "LANGCERTGERB2">;
type LANGCERTGERC1 = Extract<DocumentType, "LANGCERTGERC1">;
type LANGCERTGERC2 = Extract<DocumentType, "LANGCERTGERC2">;

export type DocumentTypeKey =
  | DocumentType
  | `${RECDOC}|${DEFICITNOTE}`
  | `${LANGCERT}|${LANGCERTGERA1}|${LANGCERTGERA2}|${LANGCERTGERB1}|${LANGCERTGERB2}|${LANGCERTGERC1}|${LANGCERTGERC2}`;

export type DocumentFilterField =
  | "type"
  | "tags"
  | "changedAt"
  | "changedBy"
  | "validFrom"
  | "validUntil"
  | "issueDate"
  | "dateOfReceipt"
  | "resubmissionDate";

type DocumentFilterKeyFactory<T> = `document.${T & string}`;
type DocumentTypeFilterKeyFactory<T, U> = `document(${T & string}).${U & string}`;

export type DocumentFilterKey = DocumentFilterKeyFactory<DocumentFilterField>;
export type DocumentTypeFilterKey = DocumentTypeFilterKeyFactory<DocumentTypeKey, DocumentFilterField>;

type EducationFilterKeyFactory<T extends EducationTypeKey, U> = `education(${T & string}).${U & string}`;

export type EducationTypeKey = "module" | "course" | "totalPerformance" | "weeklyPerformance" | "exam" | "examResult";
export type EducationModuleFilterField = "name";
export type EducationCourseFilterField = "name";
export type EducationCourseTotalPerformanceFilterField = "candidateState";
export type EducationCourseWeeklyPerformanceFilterField = "attendance" | "performance";
export type EducationExamFilterField = "name" | "languageLevel";
export type EducationExamResultFilterField = "examResult" | "passedExamModules";

export type EducationFilterKey =
  | EducationFilterKeyFactory<"module", EducationModuleFilterField>
  | EducationFilterKeyFactory<"course", EducationCourseFilterField>
  | EducationFilterKeyFactory<"totalPerformance", EducationCourseTotalPerformanceFilterField>
  | EducationFilterKeyFactory<"weeklyPerformance", EducationCourseWeeklyPerformanceFilterField>
  | EducationFilterKeyFactory<"exam", EducationExamFilterField>
  | EducationFilterKeyFactory<"examResult", EducationExamResultFilterField>;

// To validate the immigration properties, comment in the below code...
// export const ImmigrationFilters: Record<string, CandidateProperty[]> = {
// but keep the plain object, else the "ImmigrationFilterKey" type will not work
export const ImmigrationFilters = {
  "migration.employmentRelationship.beforeRecognition.employerId": [
    "migration.de.employmentRelationship.beforeRecognition.employerId",
    "migration.at.employmentRelationship.beforeRecognition.employerId",
  ],
  "migration.employmentRelationship.beforeRecognition.contractTemplateId": [
    "migration.de.employmentRelationship.beforeRecognition.contractTemplateId",
    "migration.at.employmentRelationship.beforeRecognition.contractTemplateId",
  ],
  "migration.employmentRelationship.afterRecognition.employerId": [
    "migration.de.employmentRelationship.afterRecognition.employerId",
    "migration.at.employmentRelationship.afterRecognition.employerId",
  ],
  "migration.employmentRelationship.afterRecognition.contractTemplateId": [
    "migration.de.employmentRelationship.afterRecognition.contractTemplateId",
    "migration.at.employmentRelationship.afterRecognition.contractTemplateId",
  ],
  "migration.employmentRelationship.afterRecognition.contractSignDate": [
    "migration.de.employmentRelationship.afterRecognition.contractSignDate",
    "migration.at.employmentRelationship.afterRecognition.contractSignDate",
  ],
  "migration.employmentRelationship.afterRecognition.confirmationSignedDate": [
    "migration.de.employmentRelationship.afterRecognition.confirmationSignedDate",
    "migration.at.employmentRelationship.afterRecognition.confirmationSignedDate",
  ],
  "migration.employmentRelationship.beforeRecognition.employmentRelocationRequirements": [
    "migration.de.employmentRelationship.beforeRecognition.employmentRelocationRequirements",
    "migration.at.employmentRelationship.beforeRecognition.employmentRelocationRequirements",
  ],
  "migration.employmentRelationship.afterRecognition.employmentRelocationRequirements": [
    "migration.de.employmentRelationship.afterRecognition.employmentRelocationRequirements",
    "migration.at.employmentRelationship.afterRecognition.employmentRelocationRequirements",
  ],
  "migration.employmentRelationship.afterRecognition.endOfProbationDate": [
    "migration.de.employmentRelationship.afterRecognition.endOfProbationDate",
    "migration.at.employmentRelationship.afterRecognition.endOfProbationDate",
  ],
  "migration.employmentRelationship.beforeRecognition.endOfProbationDate": [
    "migration.de.employmentRelationship.beforeRecognition.endOfProbationDate",
    "migration.at.employmentRelationship.beforeRecognition.endOfProbationDate",
  ],
  "migration.employmentRelationship.beforeRecognition.contractExtension": [
    "migration.de.employmentRelationship.beforeRecognition.contractExtension",
    "migration.at.employmentRelationship.beforeRecognition.contractExtension",
  ],
  "migration.employmentRelationship.afterRecognition.contractExtension": [
    "migration.de.employmentRelationship.afterRecognition.contractExtension",
    "migration.at.employmentRelationship.afterRecognition.contractExtension",
  ],
  "migration.recognitionPath.recognitionStarted": [
    "migration.de.recognitionPath.current.recognitionStarted",
    "migration.at.recognitionPath.recognitionStarted",
  ],
  "migration.recognitionPath.recognitionStartDate": [
    "migration.de.recognitionPath.current.recognitionStartDate",
    "migration.at.recognitionPath.recognitionStartDate",
  ],
  "migration.recognitionPath.pathOfRecognition": [
    "migration.de.recognitionPath.current.pathOfRecognition",
    "migration.at.recognitionPath.pathOfRecognition",
  ],
  "migration.recognitionPath.fileNumber": [
    "migration.de.recognitionPath.current.fileNumber",
    "migration.at.recognitionPath.fileNumber",
  ],
  "migration.recognitionPath.federalState": [
    "migration.de.recognitionPath.current.federalState",
    "migration.at.recognitionPath.federalState",
  ],
  "migration.recognitionPath.recognitionAuthority": [
    "migration.de.recognitionPath.current.recognitionAuthority",
    "migration.at.recognitionPath.recognitionAuthority",
  ],

  "migration.vocationalSchool.trainingTimeInYearsTotal": [
    "migration.de.vocationalSchool.trainingTimeInYearsTotal",
    "migration.at.vocationalSchool.trainingTimeInYearsTotal",
  ],

  "migration.vocationalSchool.trainingTimeInPartTime": [
    "migration.de.vocationalSchool.trainingTimeInPartTime",
    "migration.at.vocationalSchool.trainingTimeInPartTime",
  ],

  "migration.vocationalSchool.trainingTimeShortened": [
    "migration.de.vocationalSchool.trainingTimeShortened",
    "migration.at.vocationalSchool.trainingTimeShortened",
  ],

  "migration.vocationalSchool.currentTrainingYear": [
    "migration.de.vocationalSchool.currentTrainingYear",
    "migration.at.vocationalSchool.currentTrainingYear",
  ],
  "migration.vocationalSchool.expectedEndDate": [
    "migration.de.vocationalSchool.expectedEndDate",
    "migration.at.vocationalSchool.expectedEndDate",
  ],
  "migration.vocationalSchool.endDate": [
    "migration.de.vocationalSchool.endDate",
    "migration.at.vocationalSchool.endDate",
  ],
  "migration.vocationalSchool.schoolName": [
    "migration.de.vocationalSchool.schoolName",
    "migration.at.vocationalSchool.schoolName",
  ],
  "migration.vocationalSchool.schoolCourse": [
    "migration.de.vocationalSchool.schoolCourse",
    "migration.at.vocationalSchool.schoolCourse",
  ],
  "migration.vocationalSchool.address": [
    "migration.de.vocationalSchool.address",
    "migration.at.vocationalSchool.address",
  ],
  "migration.vocationalSchool.zipCode": [
    "migration.de.vocationalSchool.zipCode",
    "migration.at.vocationalSchool.zipCode",
  ],
  "migration.vocationalSchool.city": ["migration.de.vocationalSchool.city", "migration.at.vocationalSchool.city"],
  "migration.vocationalSchool.federalState": [
    "migration.de.vocationalSchool.federalState",
    "migration.at.vocationalSchool.federalState",
  ],
  "migration.vocationalSchool.country": [
    "migration.de.vocationalSchool.country",
    "migration.at.vocationalSchool.country",
  ],
  "migration.vocationalSchool.medicalCheckAttended": [
    "migration.de.vocationalSchool.medicalCheckAttended",
    "migration.at.vocationalSchool.medicalCheckAttended",
  ],
  "migration.vocationalSchool.medicalCheckAttendedDate": [
    "migration.de.vocationalSchool.medicalCheckAttendedDate",
    "migration.at.vocationalSchool.medicalCheckAttendedDate",
  ],
  "migration.vocationalSchool.medicalCheckPassed": [
    "migration.de.vocationalSchool.medicalCheckPassed",
    "migration.at.vocationalSchool.medicalCheckPassed",
  ],
  "migration.vocationalSchool.medicalCheckPassedDate": [
    "migration.de.vocationalSchool.medicalCheckPassedDate",
    "migration.at.vocationalSchool.medicalCheckPassedDate",
  ],
  "migration.residence.responsibleRoleResidence": [
    "migration.de.residence.responsibleRoleResidence",
    "migration.at.residence.responsibleRoleResidence",
  ],
  "migration.residence.workStartDate": ["migration.de.residence.workStartDate", "migration.at.residence.workStartDate"],
  "migration.residence.registrationOfficeDate": [
    "migration.de.residence.registrationOfficeDate.date",
    "migration.at.residence.registrationOfficeDate.date",
  ],
  "migration.residence.personalOnboardingDateTime": [
    "migration.de.residence.personalOnboardingDateTime.date",
    "migration.at.residence.personalOnboardingDateTime.date",
  ],
  "migration.residence.registrationAuthorityAppointmentNumber": [
    "migration.de.residence.registrationAuthorityAppointmentNumber",
    "migration.at.residence.registrationAuthorityAppointmentNumber",
  ],
  "migration.residence.registrationAuthority": [
    "migration.de.residence.registrationAuthority",
    "migration.at.residence.registrationAuthority",
  ],
  "migration.residence.residenceBankContactPartner": [
    "migration.de.residence.residenceBankContactPartner",
    "migration.at.residence.residenceBankContactPartner",
  ],
  "migration.residence.residenceBank": ["migration.de.residence.residenceBank", "migration.at.residence.residenceBank"],
  "migration.residence.recognitionMedicalCertificate": [
    "migration.de.residence.recognitionMedicalCertificate",
    "migration.at.residence.recognitionMedicalCertificate",
  ],
  "migration.residence.suitabilityForWork": [
    "migration.de.residence.suitabilityForWork",
    "migration.at.residence.suitabilityForWork",
  ],
};

export type ImmigrationFilterKey = keyof typeof ImmigrationFilters;

export type CustomCandidateField =
  | DocumentFilterKey
  | DocumentTypeFilterKey
  | ImmigrationFilterKey
  | EducationFilterKey
  | "collections"
  | "documents"
  | "notes"
  | "feedbacks"
  | "employerApplications"
  | "collectionName"
  | "collectionOrganization"
  | "noteContents"
  | "feedbackStatusPhaseOne"
  | "feedbackStatusPhaseTwo"
  | "feedbackOrganization"
  | "employerApplicationOrganization"
  | "familyMemberReunification"
  | "familyMemberEmergencyContact"
  | "familyMemberRelation"
  | "migration.de.residence.residencePermits.immigrationAuthority"
  | "migration.de.residence.residencePermits.residencePermit"
  | "migration.de.residence.residencePermits.residenceRequestedElectronicallyDate"
  | "migration.de.residence.residencePermits.residenceRequestedByPostDate"
  | "migration.de.residence.residencePermits.fictionalCertificateAvailable"
  | "migration.de.residence.residencePermits.fictionalCertificateDoesNotAuthorisesWorking"
  | "migration.de.residence.residencePermits.fictionalCertificateNumber"
  | "migration.de.qualificationEvaluation.qualificationEvaluations.evaluatedQualification"
  | "migration.de.qualificationEvaluation.qualificationEvaluations.responsibleAuthorityForQualification"
  | "migration.de.qualificationEvaluation.qualificationEvaluations.responsibleAuthorityForQualificationInOrigin"
  | "migration.de.qualificationEvaluation.qualificationEvaluations.federalState"
  | "migration.de.qualificationEvaluation.qualificationEvaluations.fileNumber"
  | "migration.de.qualificationEvaluation.qualificationEvaluations.evaluationNoticeKind"
  | "migration.de.qualificationMeasure.qualificationModules.name"
  | "migration.de.qualificationMeasure.qualificationModules.areaOfDeployment"
  | "migration.de.qualificationMeasure.qualificationModules.theoryHours"
  | "migration.de.qualificationMeasure.qualificationModules.practiceHours"
  | "migration.de.qualificationMeasure.qualificationExams.examType"
  | "migration.de.qualificationMeasure.qualificationExams.firstExamDate"
  | "migration.de.qualificationMeasure.qualificationExams.firstExamPassed"
  | "migration.de.qualificationMeasure.qualificationExams.secondExamDate"
  | "migration.de.qualificationMeasure.qualificationExams.secondExamPassed"
  | "migration.de.qualificationMeasure.qualificationExams.examArea"
  | "migration.de.qualificationMeasure.qualificationExams.moduleName"
  | "migration.housingAcquisition.housings.name"
  | "migration.housingAcquisition.housings.cancellationOfContractSignDate"
  | "migration.housingAcquisition.housings.readinessForOccupancyDate"
  | "migration.housingAcquisition.housings.movingInDate"
  | "migration.housingAcquisition.housings.movingOutDate"
  | "migration.housingAcquisition.housings.coldRent"
  | "migration.housingAcquisition.housings.operatingCosts"
  | "migration.housingAcquisition.housings.additionalCosts"
  | "migration.housingAcquisition.housings.address"
  | "migration.housingAcquisition.housings.zipCode"
  | "migration.housingAcquisition.housings.city"
  | "migration.housingAcquisition.housings.federalState"
  | "migration.housingAcquisition.housings.country"
  | "migration.housingAcquisition.housings.nameOfContactPerson"
  | "migration.housingAcquisition.housings.phoneOfContactPerson"
  | "migration.housingAcquisition.housings.keyHandoverDateTime.date"
  | "migration.housingAcquisition.housings.comments"
  | "internal.scholarships.startDate"
  | "internal.scholarships.endDate"
  | "internal.scholarships.amountOfMoney"
  | "internal.scholarships.awardFrequency"
  | "internal.scholarships.responsibleRoleScholarship"
  | "candidatesDeletion"
  | "interviewsFeedback.employerId"
  | "interviewsFeedback.comment"
  | "interviewsFeedback.interviewDate"
  | "interviewsFeedback.interviewerFeedback"
  | "interviewsFeedback.candidateFeedback";

export type CustomCandidateMatch =
  | "collectionHasEntries"
  | "collectionHasNoEntries"
  | "empty"
  | "notEmpty"
  | "stringEmpty"
  | "stringNotEmpty"
  | "exists"
  | "notExists"
  | "existsWithContent"
  | "notExistsWithContent"
  | "equals"
  | "notEquals"
  | "elementEquals"
  | "elementNotEquals"
  | "arrayEquals"
  | "arrayNotEquals"
  | "stringEquals"
  | "stringNotEquals"
  | "startsWith"
  | "endsWith"
  | "arrayContains"
  | "arrayNotContains"
  | "stringContains"
  | "stringNotContains"
  | "lessThan"
  | "moreThan"
  | "lessThanOrEquals"
  | "moreThanOrEquals"
  | "dateEqualsToElement"
  | "dateNotEqualsToElement"
  | "lessThanElement"
  | "moreThanElement"
  | "lessThanOrEqualsToElement"
  | "moreThanOrEqualsToElement"
  | "true"
  | "notTrue"
  | "hasContentAndElementEquals"
  | "hasNoContentAndElementEquals";

export const CandidateDataSources = ["owner", "userOrg"] as const;
export type CandidateDataSource = (typeof CandidateDataSources)[number];
export interface CandidateDataSourceOption {
  label: string;
  value: CandidateDataSource;
}

export type CandidateFilterMetadata = FilterMetadata & { dataSource?: CandidateDataSource };
