@if (selectedCandidate) {
  <app-candidate-edit
    class="w-full"
    [selectedCandidates]="[selectedCandidate]"
    [selectedCollectionId]="null"
    [selectedOrganizationId]="null"
    [selectedCollectionOrganizationId]="null"
    [sharings]="[]"
    [selectedSharing]="null"
    [isEditing]="false"
    [standardImmigrationCountry]="selectedCandidate.immigrationCountry"
    [isUneditableCollaboration]="false"
    [isReadOnlyCollaboration]="false"
    [isMultiEditMode]="false"
  ></app-candidate-edit>
}
