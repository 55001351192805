import { Pipe, PipeTransform } from "@angular/core";
import { StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { castArray, without } from "lodash";
import { Observable, map, mergeMap, of } from "rxjs";
import { StaticDataContext, StaticDataRequest, StaticDataService } from "../static-data/static-data.service";

@Pipe({ name: "staticData", standalone: false })
export class StaticDataPipe implements PipeTransform {
  private readonly language = this.transloco.getActiveLang();

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService
  ) {}

  transform<T extends string | string[]>(
    value: T,
    type: StaticDataType,
    language?: string,
    staticDataRequest?: StaticDataRequest | StaticDataContext,
    includeAll?: boolean,
    useAutoContext = true
  ): Observable<string> {
    const usedLanguage = language ?? this.language;
    let values = castArray(value);
    const allIndex = includeAll ? values.indexOf("ALL") : -1;
    if (allIndex > -1) {
      values = without(values, "ALL");
    }
    return this.staticDataService
      .getStaticDataLabel(values, type, usedLanguage, staticDataRequest, useAutoContext)
      .pipe(
        mergeMap((labels) => {
          if (allIndex > -1) {
            return this.staticDataService
              .getStaticDataLabel("ALL", StaticDataType.All, usedLanguage, staticDataRequest, useAutoContext)
              .pipe(map((allLabel) => [...labels.slice(0, allIndex), allLabel, ...labels.slice(allIndex)])); // insert ALL label at the right index
          } else {
            return of(labels);
          }
        }),
        map((x) => x.join(", "))
      );
  }
}
