import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, map } from "rxjs";
import { UsersService } from "../users.service";

@Component({
  selector: "app-user-emails",
  templateUrl: "./user-emails.component.html",
  styleUrl: "./user-emails.component.scss",
  standalone: false,
})
export class UserEmailsComponent implements OnInit {
  userId: string;
  userName$: Observable<string>;
  organizationId: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly userService: UsersService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.userId = params.userId;
      this.organizationId = params.orgId;
      this.userName$ = this.userService
        .getUser(this.userId, this.organizationId)
        .pipe(map((x) => `${x.lastname}, ${x.firstname}`));
    });
  }
}
