import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
} from "@angular/core";
import { DocumentType, SelectedDocumentField, nameofFactory } from "@ankaadia/ankaadia-shared";
import { Document, DocumentMode } from "@ankaadia/graphql";
import { isNil } from "lodash";
import { Subscription, concat, filter, of } from "rxjs";
import { CandidateProfileDialogService } from "../../organization-specific/base-candidate-profile/candidate-profile-dialog.service";
import { AnyCandidateForm } from "../candidate-form/candidate-form.model";
import { DocumentNotificationService } from "../documents/document-notification.service";
import { DocumentRequestBufferService } from "../documents/document-request-buffer.service";
import { DocumentsService } from "../documents/documents.service";
import { AdditionalForeignKeySourceData } from "./candidate-document-foreign-key-handler";

const nameOf = nameofFactory<CandidateDocumentMetaDataWrapperComponent>();

@Component({
  selector: "app-candidate-document-metadata-wrapper",
  templateUrl: "./candidate-document-metadata-wrapper.component.html",
  styleUrl: "./candidate-document-metadata-wrapper.component.scss",
  standalone: false,
})
export class CandidateDocumentMetaDataWrapperComponent implements OnInit, OnChanges, OnDestroy {
  private documentReloadSubscription?: Subscription;
  private documentUpdateSubscription?: Subscription;
  private documentDeletionSubscription?: Subscription;

  protected loadedDocument: Document;
  protected readonly isNil = isNil;

  @ContentChild(TemplateRef)
  content: TemplateRef<any>;

  @Input({ required: true })
  candidateId: string;

  @Input({ required: true })
  candidateOrganizationId: string;

  @Input({ required: true })
  candidateForm: AnyCandidateForm;

  @Input({ required: true })
  additionalForeignKeySourceData: AdditionalForeignKeySourceData;

  @Input({ required: true })
  documentType: DocumentType;

  @Input({ required: true })
  selectedField: SelectedDocumentField;

  @Input()
  readonly = false;

  @Input()
  disabled = false;

  @Input()
  showDebugInfo = false;

  constructor(
    private readonly documentService: DocumentsService,
    private readonly documentRequestBufferService: DocumentRequestBufferService,
    private readonly documentNotificationService: DocumentNotificationService,
    private readonly profileDialogService: CandidateProfileDialogService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initDocument();
    this.documentReloadSubscription = this.documentNotificationService.reloads$.subscribe(() => this.initDocument());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes[nameOf("candidateId")] ||
      changes[nameOf("candidateOrganizationId")] ||
      changes[nameOf("documentType")]
    ) {
      this.initDocument();
    }
  }

  ngOnDestroy(): void {
    this.documentReloadSubscription?.unsubscribe();
    this.documentUpdateSubscription?.unsubscribe();
    this.documentDeletionSubscription?.unsubscribe();
  }

  private initDocument(): void {
    if (!this.documentType || !this.candidateOrganizationId || !this.candidateId) {
      return;
    }

    this.documentUpdateSubscription?.unsubscribe();
    this.documentDeletionSubscription?.unsubscribe();

    const document$ = this.documentRequestBufferService.performRequest({
      type: this.documentType,
      organizationId: this.candidateOrganizationId,
      candidateId: this.candidateId,
    });

    const updates$ = this.documentNotificationService.updates$.pipe(
      filter((document) => document.mode === DocumentMode.Candidate),
      filter((document) => document.type === this.documentType),
      filter((document) => !document.familyMemberId)
    );

    this.documentUpdateSubscription = concat(document$, updates$).subscribe((document) =>
      this.updateDocument(document)
    );

    this.documentDeletionSubscription = this.documentNotificationService.deletions$
      .pipe(filter((document) => document.type == this.documentType))
      .subscribe(() => this.updateDocument(null));
  }

  private updateDocument(document: Document): void {
    this.loadedDocument = document;
    this.changeDetector.detectChanges();
  }

  protected openDialog(): void {
    const document$ = this.loadedDocument
      ? of(this.loadedDocument)
      : this.documentService.createEmptyDocument(
          DocumentMode.Candidate,
          this.candidateOrganizationId,
          this.candidateId,
          null,
          this.documentType
        );

    const { selectedField, candidateForm, additionalForeignKeySourceData } = this;
    document$.subscribe((document) =>
      this.profileDialogService.documentDialog.next({
        document,
        selectedField,
        candidateForm,
        additionalForeignKeySourceData: additionalForeignKeySourceData,
      })
    );
  }
}
