<ng-container *appTranslate="let t">
  @if (form && form() && target && target()) {
    <ng-container [formGroup]="form()">
      <p-table [formArrayName]="target()" [value]="discounts().controls">
        <ng-template pTemplate="emptymessage">
          <td colspan="3" class="p-2">
            {{ t('billing.discounts.emptyMessage') }}
          </td>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>{{ t("billing.discount.title") }}</th>
            <th>{{ t("billing.discount.startDate.title") }}</th>
            <th>{{ t("billing.discount.endDate.title") }}</th>
            <th>{{ t("billing.discount.maxUses.shortTitle") }}</th>
            <th class="justify-content-end text-right" style="width: 8rem">
              <button
                pButton
                type="button"
                icon="pi pi-plus"
                class="p-button-rounded p-button-text"
                (click)="addDiscount(); showDialog = true"
              ></button>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-discount let-i="rowIndex">
          <tr [formGroupName]="i">
            <td>
              @if (discount.value.type === DiscountType.Percentage) {
                {{ discount.value.value | translocoDecimal : { maximumFractionDigits: 2, minimumFractionDigits: 0 } }} %
              } @else {
                {{ discount.value.value | translocoCurrency : 'symbol' : undefined : currency() }}
              }
            </td>
            <td>{{ discount.value.startDate | appDate }}</td>
            <td>{{ discount.value.endDate | appDate }}</td>
            <td>{{ discount.value.maxUses }}</td>
            <td style="text-align: center">
              <button
                pButton
                type="button"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-text"
                (click)="editDiscount(discount); showDialog = true"
              ></button>
              <button
                pButton
                type="button"
                icon="pi pi-trash"
                class="p-button-rounded p-button-text p-button-danger ml-2"
                (click)="removeDiscountAt(i)"
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-dialog
        [style]="{ width: '600px' }"
        [(visible)]="showDialog"
        [header]="targetRow === null ? t('billing.discount.create.title') : t('billing.discount.edit.title')"
      >
        <ng-template pTemplate>
          <div class="p-fluid" *ngIf="editRow" [formGroup]="editRow">
            <div class="field">
              <label for="type" class="pl-2">{{ t("billing.discount.type.title") }}</label>
              <p-dropdown
                inputId="type"
                formControlName="type"
                [options]="discountTypes"
              ></p-dropdown>
              <small class="p-error" *ngIf="editRow.get('type').errors?.required">
                {{ t("billing.discount.type.required") }}
              </small>
            </div>
            <div class="field">
              <label for="value" class="pl-2">{{ t("billing.discount.value.title") }}</label>
              <p-inputNumber
                [min]="0"
                [max]="editRow.get('type').value === DiscountType.Percentage ? 100 : null"
                [maxFractionDigits]="2"
                mode="decimal"
                inputId="value"
                formControlName="value"
              ></p-inputNumber>
              <small class="p-error" *ngIf="editRow.get('value').errors?.required">
                {{ t("billing.discount.value.required") }}
              </small>
            </div>
            <div class="field">
              <label for="startDate" class="pl-2">{{ t("billing.discount.startDate.title") }}</label>
              <p-calendar
                inputId="startDate"
                formControlName="startDate"
              ></p-calendar>
              <small class="p-error" *ngIf="editRow.get('startDate').errors?.required">
                {{ t("billing.discount.startDate.required") }}
              </small>
            </div>
            <div class="field">
              <label for="endDate" class="pl-2">{{ t("billing.discount.endDate.title") }}</label>
              <p-calendar
                [minDate]="editRow.get('startDate').value"
                inputId="endDate"
                formControlName="endDate"
              ></p-calendar>
              <small class="p-error" *ngIf="editRow.get('endDate').errors?.sameOrAfter">
                {{ t("billing.discount.endDate.sameOrAfter") }}
              </small>
            </div>
            <div class="field">
              <label for="maxUses" class="pl-2">{{ t("billing.discount.maxUses.title") }}</label>
              <p-inputNumber
                [min]="1"
                inputId="maxUses"
                formControlName="maxUses"
              ></p-inputNumber>
            </div>
            <div class="flex flex-row justify-content-between mt-3">
              <p-button
                [disabled]="!editRow.valid || !editRow.dirty"
                [label]="targetRow != null ? t('common.save') : t('common.add')"
                icon="pi pi-check"
                type="button"
                (onClick)="saveDiscount()"
              ></p-button>
              <p-button
                closeOnEscape
                [label]="t('common.cancel')"
                icon="pi pi-times"
                class="p-button-secondary"
                type="button"
                (onClick)="endEdit()"
              ></p-button>
            </div>
          </div>
        </ng-template>
      </p-dialog>
    </ng-container>
  }

</ng-container>
