import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { RecognitionPathEntryDe, StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { StaticDataContextEntry, StaticDataService } from "../../../shared/static-data/static-data.service";

const nameOf = nameofFactory<RecognitionPathEntryDe>();

@Component({
  selector: "app-candidate-federal-state",
  templateUrl: "./candidate-federal-state.component.html",
  standalone: false,
})
export class CandidateFederalStateComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private readonly language = this.transloco.getActiveLang();

  @Input()
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  staticDataContext: StaticDataContextEntry;

  @Input()
  readonly: boolean;

  @Input()
  hidden: boolean;

  @Output()
  readonly valueChanged = new EventEmitter<string>();

  @Output()
  readonly userChangedValue = new EventEmitter<string>();

  federalStates: StaticDataModel[];

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form.controls.federalState.valueChanges.subscribe((value) => {
      this.valueChanged.emit(value);
    });
    this.updateFederalStates();
  }

  ngAfterViewInit(): void {
    this.setEnabled(!this.readonly);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.control) this.control = new FormControl<string>(undefined);
    if (changes.processLanguage) {
      this.updateFederalStates();
    }

    if (changes.readonly) {
      this.setEnabled(!changes.readonly.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("federalState"), { emitEvent: false });
  }

  get control(): FormControl<string> {
    return this.form.get(nameOf("federalState")) as FormControl<string>;
  }

  set control(value: FormControl<string>) {
    this.form.setControl(nameOf("federalState"), value);
  }

  private updateFederalStates(): void {
    this.staticDataService
      .getStaticData(StaticDataType.FederalStates, this.processLanguage ?? this.language, this.staticDataContext)
      .subscribe((x) => {
        this.federalStates = x;
        this.changeDetector.markForCheck();
      });
  }

  private setEnabled(enabled: boolean): void {
    if (enabled) {
      this.control.enable({ emitEvent: false });
    } else {
      this.control.disable({ emitEvent: false });
    }
  }
}
