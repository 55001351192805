import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  EmploymentRelationshipType,
  OrganizationProfileFields,
  StaticDataModel,
  StaticDataType,
  WorkingTimeTypeEnum,
} from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { Observable, map } from "rxjs";
import { OrganizationFactoryService } from "../../../../organization-specific/organization-specific-factory.service";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";

@Component({
  selector: "app-organization-profile-location-vacancy",
  templateUrl: "./organization-profile-location-vacancy.component.html",
  standalone: false,
})
export class OrganizationProfileLocationVacancyComponent implements OnInit {
  readonly language = this.transloco.getActiveLang();
  readonly countries = this.staticData.getStaticData(StaticDataType.Countries, this.language);
  readonly functions = this.staticData.getStaticData(StaticDataType.Functions, this.language);

  readonly employmentTypes = this.staticData.transformEnumToStaticDataModel(
    "EmploymentRelationshipType",
    EmploymentRelationshipType,
    { language: this.language }
  );

  readonly temporalScopes = this.staticData.transformEnumToStaticDataModel("WorkingTimeTypeEnum", WorkingTimeTypeEnum, {
    language: this.language,
  });

  readonly pathsOfRecognition = this.staticData
    .getStaticData(StaticDataType.PathOfRecognition, this.language)
    .pipe(map((options) => options.filter((x) => x.value !== "RECOGNITION")));

  interviewLanguageLevels: Observable<StaticDataModel[]>;
  transferLanguageLevels: Observable<StaticDataModel[]>;

  get requiredStaffDuplicates(): string[] | null {
    const duplicates = this.form.parent.errors?.duplicates as string[][];
    return duplicates?.at(this.formIndex) ?? null;
  }

  @Input()
  organizationId: string;

  @Input()
  fields: OrganizationProfileFields;

  @Input()
  form: FormGroup;

  @Input()
  formIndex: number;

  constructor(
    private readonly transloco: TranslocoService,
    private readonly staticData: StaticDataService,
    private readonly specificsFactory: OrganizationFactoryService
  ) {}

  ngOnInit(): void {
    this.specificsFactory.getOrganizationSpecifics(this.organizationId).subscribe((specifics) => {
      this.interviewLanguageLevels = specifics.getInterviewLanguageLevels(this.language);
      this.transferLanguageLevels = specifics.getTransferLanguageLevels(this.language);
    });
  }
}
