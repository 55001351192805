import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { StaticDataService } from "../../../shared/static-data/static-data.service";

@Component({
  selector: "app-candidate-function",
  templateUrl: "./candidate-function.component.html",
  standalone: false,
})
export class CandidateFunctionComponent {
  readonly StaticDataType = StaticDataType;
  readonly functions$ = this.staticDataService.getStaticData(StaticDataType.Functions, this.transloco.getActiveLang());

  @Input()
  control: FormControl;

  @Input()
  readonly: boolean;

  constructor(
    private readonly transloco: TranslocoService,
    private readonly staticDataService: StaticDataService
  ) {}
}
