import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ValidatorFn, Validators } from "@angular/forms";
import { CandidateScholarship, InternalCandidateData, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { assign } from "lodash";
import { TabView } from "primeng/tabview";
import Quill from "quill";
import { BehaviorSubject, debounceTime, filter, map, startWith, tap } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { OrganizationsService } from "../../organizations/organizations.service";
import { OrganizationSystemSettingsService } from "../../organizations/system-settings.service";
import { CandidateForm, InternalForm } from "../candidate-form.model";
import { CandidateFormService } from "../candidate-form.service";

@Component({
  selector: "app-candidate-internal",
  templateUrl: "./candidate-internal.component.html",
  standalone: false,
})
export class CandidateInternalComponent implements OnInit, OnChanges {
  readonly language = this.transloco.getActiveLang();
  private readonly organizations$ = this.organizationService.getLinkedOrganizations(
    this.settings.organizationId,
    true,
    true
  );

  readonly sourceOrganizations$ = this.organizations$.pipe(
    map((x) => {
      if (this.internalData?.sourceOrganizationId && !x.find((o) => o.id === this.internalData.sourceOrganizationId)) {
        this.sourceOrganizationLinked$.next(false);
        return [
          ...x,
          { id: this.internalData.sourceOrganizationId, name: this.transloco.translate("dataNotViewable") },
        ];
      }
      return x;
    })
  );

  readonly languageSchoolOrganizations$ = this.organizations$.pipe(
    map((x) => {
      if (this.internalData?.languageSchooldId && !x.find((o) => o.id === this.internalData.languageSchooldId)) {
        this.languageSchoolOrganizationLinked$.next(false);
        return [...x, { id: this.internalData.languageSchooldId, name: this.transloco.translate("dataNotViewable") }];
      }
      return x;
    })
  );

  protected readonly languageSchoolOrganizationLinked$ = new BehaviorSubject<boolean>(true);
  protected readonly sourceOrganizationLinked$ = new BehaviorSubject<boolean>(true);

  readonly categories$ = this.staticData.getStaticData(StaticDataType.Category, this.transloco.getActiveLang());
  @ViewChild(TabView)
  tabView: TabView;

  @Input({ required: true })
  form: InternalForm;

  @Input({ required: true })
  candidateForm: CandidateForm;

  @Input({ required: true })
  isOwnerData: boolean;

  @Input({ required: true })
  internalData: InternalCandidateData;

  @Input({ required: true })
  candidateDisplayName: string;

  @Input({ required: true })
  candidateDisplayId: string;

  @Input()
  readonly: boolean;

  @Input()
  isMultiEditMode: boolean;

  @Input()
  candidateOrganizationId: string;

  @Input({ required: true })
  sharingId: string;

  @Input({ required: true })
  collectionId: string;

  @Input()
  candidateId: string;

  @Output()
  readonly tabChanged = new EventEmitter<number>();

  get userOrganizationId(): string {
    return this.settings.organizationId;
  }

  get isPartnerTabpanelInvalid(): boolean {
    return (
      this.form.controls.languageSchooldId.invalid ||
      this.form.controls.sourceOrganizationId.invalid ||
      this.form.controls.category.invalid
    );
  }

  private editor: Quill;

  constructor(
    private readonly organizationService: OrganizationsService,
    private readonly settings: SettingsService,
    private readonly transloco: TranslocoService,
    private readonly staticData: StaticDataService,
    private readonly systemSettingsService: OrganizationSystemSettingsService,
    private readonly candidateFormService: CandidateFormService
  ) {
    this.languageSchoolOrganizationLinked$
      .pipe(
        takeUntilDestroyed(),
        debounceTime(100),
        filter((x) => !x && !!this.internalData.languageSchooldId),
        tap((_) => this.form.controls.languageSchooldId.setValue(this.internalData.languageSchooldId))
      )
      .subscribe();
    this.sourceOrganizationLinked$
      .pipe(
        takeUntilDestroyed(),
        debounceTime(100),
        filter((x) => !x && !!this.internalData.sourceOrganizationId),
        tap((_) => this.form.controls.sourceOrganizationId.setValue(this.internalData.sourceOrganizationId))
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.form.statusChanges.pipe(startWith(this.form.status), debounceTime(100)).subscribe(() => this.updateTabView());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateOrganizationId) {
      this.updateValidators(changes.candidateOrganizationId.currentValue);
    }
    // also update editor after saving (readonly change) to make sure the content is shown after saving...
    if (changes.candidateId || changes.readonly) {
      this.onInitEditor(this.editor);
    }
  }

  private updateValidators(organizationId: string): void {
    if (this.isMultiEditMode) return;
    if (!this.isOwnerData) return;

    this.systemSettingsService.getCandidateOwnerSpecificSettings(organizationId).subscribe((ownerSettings) => {
      const additionalValidators: Record<string, ValidatorFn> = {
        notes: Validators.maxLength(10000),
      };
      const keys: (keyof typeof this.form.controls)[] = [
        "category",
        "costCenter",
        "notes",
        "languageSchooldId",
        "sourceOrganizationId",
      ];
      for (const key of keys) {
        const additionalValidator = additionalValidators[key] || Validators.nullValidator;
        this.form.controls[key].setValidators(
          ownerSettings.requiredCandidateFields.includes(`internal.${key}`) ? Validators.required : additionalValidator
        );
        this.form.controls[key].updateValueAndValidity();
      }
    });
  }

  private updateTabView(): void {
    // this.changeDetector.detectChanges();
    this.tabView?.cd.detectChanges();
  }

  onInitEditor(editor: Quill): void {
    if (editor) {
      this.editor = editor;
      setTimeout(() => this.editor.clipboard.dangerouslyPasteHTML(this.form?.controls?.notes?.value ?? null), 0);
    }
  }

  addScholarship(): void {
    this.form.controls.scholarships.push(
      this.candidateFormService.createScholarshipGroup(assign(new CandidateScholarship(), { id: uuidv4() }))
    );
  }

  deleteScholarship(index: number): void {
    this.form.controls.scholarships.removeAt(index);
  }
}
