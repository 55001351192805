import { AfterViewChecked, Directive } from "@angular/core";
import { translate } from "@jsverse/transloco";
import { MultiSelect } from "primeng/multiselect";

@Directive({ selector: "p-multiSelect:not([selectedItemsLabel])", standalone: false })
export class MultiSelectSelectedItemsLabelDirective implements AfterViewChecked {
  constructor(private readonly multiSelect: MultiSelect) {
    this.multiSelect.selectedItemsLabel = translate("primeng.selectedItemsLabel");
  }

  ngAfterViewChecked(): void {
    this.multiSelect.tooltip = this.multiSelect?.value
      ?.map((x) => this.multiSelect.options?.find((y) => x === y.value)?.label)
      .join(", ");
  }
}
