<ng-container *appTranslate="let t">
  <p-tabView styleClass="flex flex-column">
    <p-tabPanel [header]="t('general.title')">
      <app-organization-edit-dialog [myOrgMode]="true" (closed)="onCloseClick()"></app-organization-edit-dialog>
    </p-tabPanel>
    <p-tabPanel [header]="t('contacts.title')">
      <app-organization-contacts [organizationId]="organizationId"></app-organization-contacts>
    </p-tabPanel>
    <p-tabPanel [header]="t('organizationProfile.title')" *ngIf="canEditOrganizationProfile">
      <app-organization-profiles [organizationId]="organizationId"></app-organization-profiles>
    </p-tabPanel>
    <p-tabPanel [header]="t('contractTemplate.title')">
      <app-contract-templates [organizationId]="organizationId"></app-contract-templates>
    </p-tabPanel>
    <p-tabPanel [header]="t('EZBData.title')">
      <app-organization-ezb-data
        [myOrgMode]="true"
        [organizationId]="organizationId"
        (closed)="onCloseClick()"
      ></app-organization-ezb-data>
    </p-tabPanel>
    <p-tabPanel [header]="t('billing.settings.title')" *ngIf="canSeeBillingSettings">
      @defer (on immediate) {
        <app-billing-settings [organizationId]="organizationId"></app-billing-settings>
      }
    </p-tabPanel>
  </p-tabView>
</ng-container>
