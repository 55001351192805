import { Injectable } from "@angular/core";
import {
  GeneralDocumentRequirementFragment,
  GeneralDocumentRequirementInput,
  GetGeneralDocumentRequirementGQL,
  UpsertGeneralDocumentRequirementsGQL,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class DocumentGeneralRequirementService {
  constructor(
    private readonly getGql: GetGeneralDocumentRequirementGQL,
    private readonly upsertManyGql: UpsertGeneralDocumentRequirementsGQL
  ) {}

  get(organizationId: string): Observable<GeneralDocumentRequirementFragment> {
    return this.getGql
      .fetch({ organizationId: organizationId })
      .pipe(map((result) => result.data.getGeneralDocumentRequirement));
  }

  upsert(input: GeneralDocumentRequirementInput): Observable<GeneralDocumentRequirementFragment> {
    return this.upsertManyGql
      .mutate({ input: input })
      .pipe(map((result) => result.data.upsertGeneralDocumentRequirements));
  }
}
