import { Component, Input } from "@angular/core";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import {
  Collection,
  TalentPoolSharingCreateInput,
  TalentPoolSharingForEditFragment,
  TalentPoolSharingForListFragment,
  TalentPoolSharingUpdateInput,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { BehaviorSubject, finalize, Observable, of, switchMap } from "rxjs";
import { BooleanPipe } from "../../shared/pipes/boolean.pipe";
import { AppDatePipe } from "../../shared/pipes/date.pipe";
import { PipeDescription, TableColumn, TableOperation } from "../../shared/table/table.model";
import { MessageService } from "../message/message.service";
import { TalentPoolSharingService } from "./talent-pool-sharing.service";

const nameOf = nameofFactory<TalentPoolSharingForListFragment>();

/**
 * List of talent pools sharings for the given collection.
 */
@Component({
  selector: "app-talent-pool-sharings",
  templateUrl: "./talent-pool-sharings.component.html",
  standalone: false,
})
export class TalentPoolSharingsComponent {
  private readonly collection$ = new BehaviorSubject<Collection>(null);

  private isBusy: boolean;

  readonly sharings$ = this.getTalentPoolSharings();
  readonly columns = this.getColumns();
  readonly captionOperations = this.getCaptionOperations();
  readonly tableOperations = this.getTableOperations();
  readonly selectedSharing: { edit?: TalentPoolSharingForEditFragment } = {};

  @Input()
  set collection(collection: Collection) {
    this.collection$.next(collection);
  }

  showSidebar: boolean;

  constructor(
    private readonly talentPoolSharingService: TalentPoolSharingService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService
  ) {}

  add(): void {
    if (this.isBusy) return;
    this.selectedSharing.edit = {
      id: null,
      organizationId: this.collection$.value.organizationId,
      _etag: null,
      collectionId: this.collection$.value.id,
      talentPoolId: null,
      talentPoolOrganizationId: null,
      expiryDate: null,
      enabled: true,
      useCv: false,
      cvKind: null,
    };
    this.showSidebar = true;
  }

  edit(sharing: TalentPoolSharingForListFragment): void {
    if (this.isBusy) return;
    this.isBusy = true;
    this.talentPoolSharingService
      .edit(sharing.id, sharing.organizationId)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe((pool) => {
        this.selectedSharing.edit = pool;
        this.showSidebar = true;
      });
  }

  delete(sharing: TalentPoolSharingForListFragment, event: Event): void {
    if (this.isBusy) return;
    this.confirmationService.confirm({
      target: event.target,
      message: translate("talentPoolSharing.confirmDelete", sharing),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () =>
        this.talentPoolSharingService.delete(sharing).subscribe(() => {
          this.messageService.add({
            severity: "success",
            summary: translate("talentPoolSharing.deleted.title"),
            detail: translate("talentPoolSharing.deleted.message", sharing),
          });
        }),
    });
  }

  create(sharing: TalentPoolSharingCreateInput): void {
    if (this.isBusy) return;
    this.isBusy = true;
    this.talentPoolSharingService
      .create(sharing)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe((sharing) => {
        this.messageService.add({
          severity: "success",
          summary: translate("talentPoolSharing.created.title"),
          detail: translate("talentPoolSharing.created.message", sharing),
        });
        this.close();
      });
  }

  update(sharing: TalentPoolSharingUpdateInput): void {
    if (this.isBusy) return;
    this.isBusy = true;
    this.talentPoolSharingService
      .update(sharing)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe((sharing) => {
        this.messageService.add({
          severity: "success",
          summary: translate("talentPoolSharing.updated.title"),
          detail: translate("talentPoolSharing.updated.message", sharing),
        });
        this.close();
      });
  }

  close(): void {
    this.selectedSharing.edit = null;
    this.showSidebar = false;
  }

  private getTalentPoolSharings(): Observable<TalentPoolSharingForListFragment[]> {
    return this.collection$.pipe(
      switchMap((x) => (x ? this.talentPoolSharingService.list(x.id, x.organizationId) : of(null)))
    );
  }

  private getColumns(): TableColumn[] {
    return [
      {
        header: translate("talentPool.title"),
        fieldname: nameOf("talentPoolName"),
        sortable: true,
        includeInGlobalFilter: true,
      },
      {
        header: translate("enabled.title"),
        fieldname: nameOf("enabled"),
        sortable: true,
        includeInGlobalFilter: true,
        pipeDescription: new PipeDescription(BooleanPipe),
      },
      {
        header: translate("sharing.expiryDate"),
        fieldname: nameOf("expiryDate"),
        sortable: true,
        includeInGlobalFilter: false,
        pipeDescription: new PipeDescription(AppDatePipe, { dateStyle: "short" }),
      },
    ];
  }

  private getCaptionOperations(): TableOperation[] {
    return [
      {
        label: translate("common.new"),
        icon: PrimeIcons.PLUS,
        operation: (): void => this.add(),
      },
    ];
  }

  private getTableOperations(): TableOperation[] {
    return [
      {
        label: translate("common.edit"),
        icon: PrimeIcons.PENCIL,
        operation: (x: TalentPoolSharingForListFragment): void => this.edit(x),
      },
      {
        label: translate("common.delete"),
        icon: PrimeIcons.TRASH,
        operation: (x: TalentPoolSharingForListFragment, e: Event): void => this.delete(x, e),
      },
    ];
  }
}
