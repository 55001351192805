<div class="flex mw-1">
  <ng-container *ngIf="isEditing || !customTags">
    <div #statusContainer class="mr-2 mw-1">
      <app-candidate-status
        data-testid="candidate-header-status"
        [control]="form.controls.status"
        [readonly]="!isEditing"
        [canDropOut]="isAdmin || candidate.status === CandidateStatus.DroppedOut"
      ></app-candidate-status>
    </div>

    <div #functionContainer class="mr-2 mw-1">
      <app-candidate-function
        data-testid="candidate-header-function"
        [control]="form.controls.function"
        [readonly]="!isEditing"
      ></app-candidate-function>
    </div>

    <div #professionContainer class="mr-2 mw-1">
      <app-candidate-profession
        data-testid="candidate-header-profession"
        [control]="form.controls.profession"
        [readonly]="!isEditing"
        [candidateOrganizationId]="candidate.organizationId"
      ></app-candidate-profession>
    </div>
  </ng-container>

  <ng-container *ngIf="!isEditing && customTags">
    <ng-container *ngFor="let tag of customTags">
      <div
        *ngIf="!(specialTags$ | async).includes(tag.variable)"
        class="p-tag p-component text-base mr-2 mw-1 white-space-nowrap text-overflow-ellipsis overflow-hidden"
        [ngClass]="CandidateTagColors[tag.color].tag"
        [pTooltip]="tag.label"
      >
        <span class="p-tag-value" [attr.data-testid]="toTestId(tag)">{{ tag.value }}</span>
      </div>
    </ng-container>
  </ng-container>
</div>
