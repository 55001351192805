import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UserPermission } from "@ankaadia/ankaadia-shared";
import { CollectionFilterPresetUpdateInput } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { clone, groupBy, orderBy, toPairs } from "lodash";
import { TreeDragDropService, TreeNode } from "primeng/api";
import { Tree, TreeFilterEvent, TreeNodeDropEvent } from "primeng/tree";
import { of } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { FilterMetadataMap } from "../../collections/collection-edit-assigned-candidates/custom-filter.model";
import { FiltersForm } from "../candidate-filter-form.model";
import { FilterPreset } from "../candidate-filter.model";
import { CandidateFilterService } from "../candidate-filter.service";
import { CandidateFilterPresetService } from "./candidate-filter-preset.service";

type Mode = "new" | "edit" | "delete" | null;
type TreeNodePreset = Partial<Pick<FilterPreset, "id">> & Omit<FilterPreset, "id" | "name" | "changedAt" | "changedBy">;
type TreeNodeData = boolean | TreeNodePreset;
interface PresetLocation {
  parentIndex: number;
  childIndex: number;
}

@Component({
  selector: "app-candidate-filter-preset-selector",
  templateUrl: "./candidate-filter-preset-selector.component.html",
  styleUrl: "./candidate-filter-preset-selector.component.scss",
  providers: [TreeDragDropService],
  standalone: false,
})
export class CandidateFilterPresetSelectorComponent implements OnInit {
  private _mode: Mode;
  private _treeFilter?: string;
  private _filteredTreeNodes?: TreeNode<TreeNodeData>[];
  private _selectedNodeBackup?: TreeNode<TreeNodePreset>;

  protected sortCategoriesAscending = true;
  protected sortPresetsAscending = true;
  protected treeNodes: TreeNode<TreeNodeData>[] = [];
  protected selectedNode?: TreeNode<TreeNodePreset>;

  @ViewChild(Tree)
  protected readonly tree: Tree;

  @Input({ required: true })
  protected form: FiltersForm;

  @Output()
  protected readonly filterChange = new EventEmitter<FilterMetadataMap>();

  get mode(): Mode {
    return this._mode;
  }

  protected set mode(value: Mode) {
    this._mode = value;
  }

  protected get isAdmin(): boolean {
    return this.settings.hasAnyPermission([UserPermission.Administrator]);
  }

  protected get visibleTreeNodes(): TreeNode<TreeNodeData>[] {
    return this._filteredTreeNodes ?? this.treeNodes;
  }

  protected get expanded(): boolean {
    return this.visibleTreeNodes.some((node) => node.expanded);
  }

  protected get isBeingEdited(): boolean {
    return this.mode === "new" || this.mode === "edit";
  }

  protected get isValid(): boolean {
    if (!this.form.valid) {
      return false;
    }

    const nameDuplicated = this.children(this.treeNodes).some(
      (node) =>
        node.data !== this.selectedNode?.data &&
        node.label.localeCompare(this.selectedNode?.label, undefined, { sensitivity: "accent" }) === 0
    );
    const length = this.selectedNode?.label?.length ?? 0;
    return !nameDuplicated && length > 0;
  }

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly transloco: TranslocoService,
    private readonly settings: SettingsService,
    private readonly filterService: CandidateFilterService,
    private readonly presetService: CandidateFilterPresetService,
    private readonly elementRef: ElementRef<HTMLElement>
  ) {}

  ngOnInit(): void {
    const request = { organizationId: this.settings.organizationId, userId: this.settings.userOrCandidateId };
    this.presetService.getAll(request).subscribe((filterPresets) => {
      const groups = groupBy(filterPresets, (preset) => !!preset.userId);
      [true, false].forEach((key) => (groups[String(key)] = groups[String(key)] ?? []));

      this.treeNodes = toPairs(groups).map(([key, children]) => {
        const personal = Boolean(JSON.parse(key));
        const childNodes = children.map((preset) => this.createPresetNode(preset.name, preset));
        return this.createPresetCategory(personal, childNodes);
      });

      this.rerunTreeFilter();
      this.sortCategories();
      this.sortPresets();
    });
  }

  add(filters: FilterMetadataMap): void {
    this.resetFilter();
    this.expandAll();

    const newTreeNode = this.createPresetNode(null, { organizationId: this.settings.organizationId, filters });
    this.treeNodes.find((node) => node.data === true)?.children.push(newTreeNode);
    this.selectedNode = newTreeNode;
    this.mode = "new";

    this.changeDetector.detectChanges();
    const scrollArea = this.elementRef.nativeElement.querySelector(".p-tree-wrapper");
    scrollArea.scrollTop = scrollArea.scrollHeight;
  }

  protected search(node: TreeNode<TreeNodePreset>): void {
    this.selectedNode = node;
    this.filterChange.emit(node.data.filters);
  }

  protected edit(node: TreeNode<TreeNodePreset>): void {
    this._selectedNodeBackup = clone(node);
    this.selectedNode = node;
    this.filterChange.emit(node.data.filters);
    this.mode = "edit";
  }

  protected delete(node: TreeNode<TreeNodePreset>): void {
    this.selectedNode = node;
    this.mode = "delete";
  }

  protected save(): void {
    this._selectedNodeBackup = null;
    const request = this.buildRequest();
    const observable = this.selectedNode.data.id
      ? this.presetService.update(request)
      : this.presetService.create(request);

    observable.subscribe((savedPreset) => {
      this.selectedNode.data = savedPreset;
      this.resetSelection();
      this.rerunTreeFilter();
    });
  }

  protected cancelAction(): void {
    if (this.mode === "new") {
      this.deletePreset();
    } else {
      this.resetSelection();
      this.rerunTreeFilter();
    }
  }

  protected deletePreset(): void {
    const preset = this.selectedNode.data as FilterPreset;
    const observable =
      this.mode === "delete"
        ? this.presetService.delete({ id: preset.id, organizationId: preset.organizationId })
        : of(null);

    observable.subscribe(() => {
      const { parentIndex, childIndex } = this.findPreset(preset);
      this.treeNodes[parentIndex].children.splice(childIndex, 1);
      this.resetSelection();
      this.rerunTreeFilter();
    });
  }

  protected sortCategories(): void {
    const newOrder = orderBy(this.treeNodes, (node) => node.label, this.sortCategoriesAscending ? "asc" : "desc");
    this.treeNodes.splice(0, this.treeNodes.length, ...newOrder);
    this.rerunTreeFilter();
  }

  protected sortPresets(): void {
    this.treeNodes.forEach((node) => {
      const newOrder = orderBy(node.children, (child) => child.label, this.sortPresetsAscending ? "asc" : "desc");
      node.children.splice(0, node.children.length, ...newOrder);
    });
    this.rerunTreeFilter();
  }

  protected expandAll(): void {
    this.visibleTreeNodes.forEach((node) => (node.expanded = true));
  }

  protected collapseAll(): void {
    this.visibleTreeNodes.forEach((node) => (node.expanded = false));
  }

  // IMPORTANT: Do not remove! Fixes the integrity of the tree when the search is used.
  protected onFilter(event: TreeFilterEvent): void {
    this._treeFilter = event.filter;
    this._filteredTreeNodes = event.filteredValue;
    this.expandAll();

    const selectedData = this.selectedNode?.data;
    if (!selectedData) {
      return;
    }

    const selectedNode = this.children(this.visibleTreeNodes).find((node) => node.data === selectedData);
    if (selectedNode) {
      this.selectedNode = selectedNode;
    }
  }

  protected onNodeDrop(event: TreeNodeDropEvent): void {
    const dragNode = this.children(this.treeNodes).find((node) => node.data === event.dragNode.data) ?? event.dragNode;
    const destination = this.treeNodes.find((node) => node.data === event.dropNode.data);
    const preset: FilterPreset = dragNode.data;

    const request = { id: preset.id, organizationId: preset.organizationId };
    const observable = (destination.data as boolean)
      ? this.presetService.makePrivate(request)
      : this.presetService.makePublic(request);

    // IMPORTANT: Do not remove! Fixes the integrity of the tree when the search is used.
    observable.subscribe((updatedPreset) => {
      this.treeNodes.forEach((source) => {
        const index = source.children.findIndex((child) => child.data === dragNode.data);
        if (index !== -1) {
          source.children.splice(index, 1);
        }
      });

      if (destination.children.every((child) => child.data !== dragNode.data)) {
        destination.children.push(dragNode);
      }

      dragNode.data = updatedPreset;
      this.sortCategories();
      this.sortPresets();
    });
  }

  protected trimLabel(label: string): string {
    return label?.replace(/\s+/g, " ").trim();
  }

  private createPresetCategory(personal: boolean, children: TreeNode<TreeNodePreset>[]): TreeNode<TreeNodeData> {
    return {
      label: this.transloco.translate(personal ? "filter.preset.personal" : "filter.preset.organizationWide"),
      icon: "pi pi-folder",
      expanded: true,
      selectable: false,
      draggable: false,
      droppable: true,
      data: personal,
      children: children,
    };
  }

  private createPresetNode(name: string, preset: TreeNodePreset): TreeNode<TreeNodePreset> {
    return {
      label: name,
      type: "preset",
      selectable: false,
      draggable: true,
      droppable: false,
      data: preset,
    };
  }

  private children(nodes: TreeNode<TreeNodeData>[]): TreeNode<TreeNodePreset>[] {
    return nodes.flatMap((node) => (node.children ?? []).map((child) => child as TreeNode<TreeNodePreset>));
  }

  private resetSelection(): void {
    if (this._selectedNodeBackup) {
      const preset = this.selectedNode.data as FilterPreset;
      const { parentIndex, childIndex } = this.findPreset(preset);
      this.treeNodes[parentIndex].children.splice(childIndex, 1, ...[this._selectedNodeBackup]);
      this.filterChange.emit(this._selectedNodeBackup.data.filters);
      this.selectedNode = this._selectedNodeBackup;
      this._selectedNodeBackup = null;
    } else if (this.mode === "delete") {
      this.selectedNode = null;
    }

    this.mode = null;
  }

  private findPreset(preset: FilterPreset): PresetLocation {
    return this.treeNodes
      .flatMap((node, parentIndex) => node.children.map((child, childIndex) => ({ parentIndex, childIndex, child })))
      .find((node) => node.child.data === preset);
  }

  private resetFilter(): void {
    this._treeFilter = null;
    this._filteredTreeNodes = null;
    this.tree.resetFilter();
  }

  private rerunTreeFilter(): void {
    if (this._treeFilter) {
      this.tree._filter(this._treeFilter);
    }
  }

  private buildRequest(): CollectionFilterPresetUpdateInput {
    const request = {
      ...(this.selectedNode.data as FilterPreset),
      name: this.selectedNode.label,
      filters: this.filterService.buildFilterMetadataMap(this.form),
    };

    delete request.__typename;
    delete request.userId;
    delete request.changedAt;
    delete request.changedBy;

    return request;
  }
}
