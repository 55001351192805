import { Component, Input } from "@angular/core";
import { EmailEventType, EmailForListFragment } from "@ankaadia/graphql";

@Component({
  selector: "app-email-banned",
  templateUrl: "./email-banned.component.html",
  standalone: false,
})
export class EmailBannedComponent {
  readonly EmailEventType = EmailEventType;

  @Input()
  email: EmailForListFragment;

  @Input()
  processLanguage?: string;
}
