import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { UserFeedbackCreateInput } from "@ankaadia/graphql";

@Component({
  selector: "app-user-feedback-edit-dialog",
  templateUrl: "./user-feedback-edit-dialog.component.html",
  standalone: false,
})
export class UserFeedbackEditDialogComponent implements OnInit {
  @Input()
  disabled: boolean;

  @Output()
  readonly saved = new EventEmitter<UserFeedbackCreateInput>();

  @Output()
  readonly closed = new EventEmitter<void>();

  form: FormGroup;

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      overallRating: [null],
      usabilityRating: [null],
      functionalityRating: [null],
      performanceRating: [null],
      comment: [""],
    });
  }
}
