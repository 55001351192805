import { AfterViewInit, DestroyRef, Directive, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import { MultiSelect } from "primeng/multiselect";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { FormlySelectOption } from "@ngx-formly/core/select";

const nameofSelectOption = nameofFactory<FormlySelectOption>();

@Directive({ selector: "p-multiSelect[emptyValue]", standalone: false })
export class MultiSelectEmptyDirective implements AfterViewInit {
  @Input()
  emptyValue: string;

  constructor(
    private readonly multiSelect: MultiSelect,
    private readonly control: NgControl,
    private readonly destroyRef: DestroyRef
  ) {}

  ngAfterViewInit(): void {
    if (this.emptyValue) {
      this.multiSelect.onChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((e) => {
        const itemValue = typeof e.itemValue === "object" ? e.itemValue[nameofSelectOption("value")] : e.itemValue;
        if (itemValue === this.emptyValue) {
          if (this.control.control.value?.length > 0) {
            this.control.control.setValue([this.emptyValue]);
          } else {
            this.control.control.setValue([]);
          }
        } else {
          this.control.control.setValue(this.multiSelect.value.filter((v) => v !== this.emptyValue));
        }
      });
    }
  }
}
