import { Component } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { UserPermission } from "@ankaadia/ankaadia-shared";
import { EducationExamResultFullDataFragment } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { orderBy } from "lodash";
import { catchError, combineLatest, map, Observable, of, switchMap } from "rxjs";
import { EnumPipe } from "../../../shared/pipes/enum.pipe";
import { valuesOf } from "../../../shared/services/form.helper";
import { SettingsService } from "../../../shared/services/settings.service";
import { OrganizationsService } from "../../organizations/organizations.service";
import { ExamFragment } from "../education-exams/education-exam-table/education-exam-table.component";
import { EducationExamService } from "../education-exams/education-exam.service";
import { EducationModuleService } from "../education-modules/education-module.service";
import { EducationExamResultService } from "./education-exam-results.service";

@Component({
  selector: "app-education-exam-results",
  templateUrl: "./education-exam-results.component.html",
  standalone: false,
})
export class EducationExamResultsComponent {
  protected readonly hasFullAccess = this.settingsService.hasAnyPermission([
    UserPermission.Administrator,
    UserPermission.CourseAdministrator,
  ]);

  protected readonly selectedExam = new FormControl<ExamFragment>(undefined);
  readonly title$ = this.getTitle();
  protected readonly language = this.transloco.getActiveLang();

  private readonly examId$ = this.getExamIdFromRoute();
  protected readonly exams$ = this.getExams();
  protected readonly examResults$: Observable<EducationExamResultFullDataFragment[]> =
    this.selectedExam.valueChanges.pipe(switchMap((exam) => this.getExamResults(exam)));

  constructor(
    private readonly examService: EducationExamService,
    private readonly examResultService: EducationExamResultService,
    private readonly examModuleService: EducationModuleService,
    private readonly organizationService: OrganizationsService,
    private readonly enumPipe: EnumPipe,
    private readonly settingsService: SettingsService,
    private readonly transloco: TranslocoService,
    private readonly route: ActivatedRoute
  ) {
    combineLatest([this.examId$, this.exams$])
      .pipe(takeUntilDestroyed())
      .subscribe(([examId, exams]) => {
        const exam = exams.find((x) => x.id === examId);
        this.selectedExam.setValue(exam);
      });
  }

  private getExamResults(exam: ExamFragment): Observable<EducationExamResultFullDataFragment[]> {
    if (!exam) return of([]);

    return this.examResultService
      .getTable({ examId: exam.id, organizationId: exam.organizationId })
      .pipe(catchError(() => of([])));
  }

  private getExamIdFromRoute(): Observable<string> {
    return this.route.paramMap.pipe(map((x) => x.get("examId")));
  }

  private getExams(): Observable<ExamFragment[]> {
    const educationExams$ = this.examService.getAll(this.settingsService.organizationId);
    const adHocExams$ = this.examService.getAllAdHoc(this.settingsService.organizationId);

    return combineLatest([educationExams$, adHocExams$]).pipe(
      map(([x, y]) => [...x, ...y]),
      map((xs) => orderBy(xs, (x) => x.examDateAndTime?.date, "desc"))
    );
  }

  private getTitle(): Observable<string> {
    const selectedExam$ = valuesOf(this.selectedExam);
    const modules$ = this.examModuleService.listSelectable(this.settingsService.organizationId);
    const providers$ = this.organizationService.getLinkedOrganizations(this.settingsService.organizationId);
    return combineLatest([modules$, providers$, selectedExam$]).pipe(
      map(([modules, providers, exam]) => {
        // what a mess
        if (!exam) {
          return this.transloco.translate("educationExamResults.title");
        } else if (exam.__typename === "EducationExam") {
          const module = modules.find((x) => x.id === exam.educationModuleId);
          const provider = providers.find((x) => x.id === module?.providerOrganizationId);
          const params = { type: this.enumPipe.transform(exam.examType, "ExamType"), provider: provider?.name };
          if (params.provider) {
            return this.transloco.translate("educationExamResults.titleForNormalExam", params);
          } else {
            return this.transloco.translate("educationExamResults.titleForAdHocExam", params);
          }
        } else if (exam.__typename === "AdHocEducationExam") {
          const params = { type: this.enumPipe.transform(exam.examType, "ExamType") };
          return this.transloco.translate("educationExamResults.titleForAdHocExam", params);
        } else {
          return null;
        }
      })
    );
  }
}
