import { Pipe, PipeTransform } from "@angular/core";
import { cloneDeep } from "lodash";

/**
 * Avoids "TypeError: Cannot assign to read only property '0' of object '[object Array]'" on PrimeNG tables when sorting.
 */
@Pipe({ name: "tableSortable", standalone: false })
export class TableSortablePipe implements PipeTransform {
  transform<T>(value: T[]): T[] {
    return cloneDeep(value);
  }
}
