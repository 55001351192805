import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { AlwaysRequiredCandidateFields } from "@ankaadia/ankaadia-shared";
import {
  CandidateTagColor,
  CandidateTagConfiguration,
  StaticDataModel,
  StaticDataType,
  SystemSettingsUpdateInput,
  WorkExperiencePrecissionEnum,
} from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { isEmpty } from "lodash";
import { ConfirmationService } from "primeng/api";
import { forkJoin } from "rxjs";
import { DownloadService } from "../../../shared/services/download.service";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { XlsxImportExportService } from "../../import/xlsx-import-export.service";
import { MessageService } from "../../message/message.service";
import { OrganizationSystemSettingsService } from "../system-settings.service";
import { CandidateFieldService } from "./candidate-field.service";
import { SystemSettingForm } from "./organization-system-settings-form.model";
import { SystemSettingFormService } from "./organization-system-settings-form.service";

@Component({
  selector: "app-organization-system-settings",
  templateUrl: "./organization-system-settings.component.html",
  styleUrl: "./organization-system-settings.component.scss",
  standalone: false,
})
export class OrganizationSystemSettingsComponent implements OnInit {
  readonly language = this.transloco.getActiveLang();
  readonly candidateFields$ = this.candidateFieldService.getCandidateFields();
  readonly workingExperiencePrecisionsOptions = this.staticDataService.transformEnumToStaticDataModel(
    "WorkExperiencePrecissionEnum",
    WorkExperiencePrecissionEnum
  );

  protected readonly apiAccessKeyControl = new FormControl<string>(null);

  protected form: SystemSettingForm;

  submitDisabled = false;

  supportedCountries: StaticDataModel[];
  standardCountries: StaticDataModel[];

  constructor(
    private readonly formService: SystemSettingFormService,
    private readonly transloco: TranslocoService,
    protected readonly settings: SettingsService,
    private readonly messageService: MessageService,
    private readonly systemSetting: OrganizationSystemSettingsService,
    private readonly confirmationService: ConfirmationService,
    private readonly candidateFieldService: CandidateFieldService,
    private readonly staticDataService: StaticDataService,
    private readonly importService: XlsxImportExportService,
    private readonly downloadService: DownloadService
  ) {}

  ngOnInit(): void {
    forkJoin([
      this.staticDataService.getStaticData(StaticDataType.SupportedImmigrationCountries, this.language),
      this.systemSetting.get(this.settings.organizationId),
    ]).subscribe(([counties, settings]) => {
      this.supportedCountries = counties;
      this.updateStandardCountries(settings.supportedImmigrationCountries);
      if (settings) {
        this.form = this.formService.createForm(settings);
        this.setDefaultCandidateTagsWhenEnabled();
      }
    });

    this.apiAccessKeyControl.disable();
    this.systemSetting.getPublicApiAccessToken(this.settings.organizationId).subscribe((token) => {
      this.apiAccessKeyControl.setValue(token);
    });
  }

  updateStandardCountries(supportedCountries: string[]): void {
    this.standardCountries = this.supportedCountries.filter((c) => supportedCountries?.includes(c.value));
  }

  save(): void {
    this.submitDisabled = true;
    const update = new SystemSettingsUpdateInput();
    Object.assign(update, this.form.value);
    // const value = this.form.getRawValue();
    update.requiredCandidateFields = update.requiredCandidateFields.filter(
      (x) => !AlwaysRequiredCandidateFields.includes(x)
    );

    this.systemSetting.update(update).subscribe(() => {
      this.messageService.add({ severity: "success", summary: translate("systemSettings.saved") });
      this.settings.reload(false, true).subscribe();
      this.cancel();
    });
  }

  cancel(): void {
    this.settings.navigateToMain();
  }

  copyToClipboard(): void {
    void navigator.clipboard.writeText(this.apiAccessKeyControl.value);
  }

  generateAPIKey(event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      header: translate("systemSettings.regenerateApiKeyConfirmHeader"),
      message: translate("systemSettings.regenerateApiKeyConfirm"),
      accept: () => {
        this.systemSetting.createPublicApiAccessToken(this.settings.organizationId).subscribe((token) => {
          this.apiAccessKeyControl.setValue(token);
        });
      },
    });
  }

  generateAndDownloadTemplate(): void {
    this.importService
      .getProcessImportTemplate(this.settings.organizationId, this.language)
      .subscribe(({ downloadUrl, fileName }) => {
        this.downloadService.downloadFile(fileName, downloadUrl);
      });
  }

  toggleDeletion(index: number): void {
    const deletionSetting = this.form.controls.candidateDeletionSetting.controls[index];
    if (deletionSetting.controls.isEnabled.value) {
      deletionSetting.controls.daysAfterDeletion.enable();
    } else {
      deletionSetting.controls.daysAfterDeletion.disable();
    }
  }

  private setDefaultCandidateTagsWhenEnabled(): void {
    this.form.controls.enableCustomCandidateTags.valueChanges.subscribe((enabled) => {
      if (enabled && isEmpty(this.form.controls.candidateTags.value)) {
        this.form.controls.candidateTags.setValue(this.getDefaultCandidateTags());
      }
    });
  }

  private getDefaultCandidateTags(): CandidateTagConfiguration[] {
    return [
      { variable: "candidate.candidateState", color: CandidateTagColor.Warning },
      { variable: "candidate.function", color: CandidateTagColor.Success },
      { variable: "candidate.profession", color: CandidateTagColor.Info },
    ];
  }
}
