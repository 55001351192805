import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Candidate, CollectionForCandidateItemFragment, CollectionType } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { clone, groupBy, keys } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { forkJoin, of, tap } from "rxjs";
import { EnumPipe } from "../../../shared/pipes/enum.pipe";
import { SettingsService } from "../../../shared/services/settings.service";
import { PipeDescription, TableColumn, TableOperation } from "../../../shared/table/table.model";
import { MessageService } from "../../message/message.service";
import { OrganizationsService } from "../../organizations/organizations.service";
import { CandidatesService } from "../candidates.service";

@Component({
  selector: "app-candidate-collections",
  templateUrl: "./candidate-collections.component.html",
  standalone: false,
})
export class CandidateCollectionsComponent implements OnChanges {
  private _collections: CollectionForCandidateItemFragment[];
  private readonly _organizationNames: Record<string, string> = {};

  readonly captionOperations: TableOperation[] = [
    {
      label: translate("candidate.assign"),
      icon: PrimeIcons.PLUS,
      operation: (): void => this.assignToCollection(),
      disabled: (): boolean => this.readonly,
    },
  ];

  readonly tableOperations: TableOperation[] = [
    {
      label: translate("candidate.remove"),
      icon: PrimeIcons.TIMES_CIRCLE,
      operation: (x: CollectionForCandidateItemFragment, e: Event): void => this.deleteCollectionAssignment(x, e),
      disabled: (x: CollectionForCandidateItemFragment): boolean =>
        this.readonly || x.embedded || x.type === CollectionType.Auto || x.type === CollectionType.FromTemplate,
    },
  ];

  readonly columns: TableColumn[] = [
    {
      header: translate("collectionName.title"),
      fieldname: "name",
      sortable: true,
      includeInGlobalFilter: true,
      routeLink: (
        x: CollectionForCandidateItemFragment
      ): any[] =>  //users are not permitted to edit foreign collections, therefore no edit link is provided
        x.organizationId === this.settingsService.organizationId
          ? ["/app/collections/edit", x.organizationId, x.collectionId]
          : null,
    },
    {
      header: translate("organization.title"),
      fieldname: "organizationName",
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("type.title"),
      fieldname: "type",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(EnumPipe, "CollectionType", null, null, CollectionType.Standard),
    },
  ];

  inViewId = "";
  showDialog = false;
  @Input() model: Candidate;
  get collections(): CollectionForCandidateItemFragment[] {
    return this._collections;
  }

  set collections(collections: CollectionForCandidateItemFragment[]) {
    this._collections = collections;
    this.updateCollectionsForTable();
  }

  @Input() readonly: boolean;

  collectionsForTable: (CollectionForCandidateItemFragment & { organizationName: string })[];

  constructor(
    private readonly candidateService: CandidatesService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly organizationService: OrganizationsService,
    private readonly settingsService: SettingsService
  ) {}

  assignToCollection(): void {
    this.showDialog = true;
  }

  closed(event: CollectionForCandidateItemFragment[]): void {
    if (event != null && event.length > 0) {
      this.collections = this.collections.concat(event);
    }
    this.showDialog = false;
  }

  deleteCollectionAssignment(row: CollectionForCandidateItemFragment, buttonevent: Event): void {
    this.confirmationService.confirm({
      target: buttonevent.target,
      message: translate("candidate.confirmRemove", { candidate: this.model.displayName, collection: row.name }),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.candidateService
          .deleteCandidateFromCollection(this.model.id, this.model.organizationId, row.collectionId, row.organizationId)
          .subscribe((x) => {
            if (x) {
              this.collections = this.collections.filter((x) => x.collectionId != row.collectionId);
              this.messageService.add({
                severity: "success",
                summary: translate("candidate.removed.title"),
                detail: translate("candidate.removed.message", {
                  candidate: this.model.displayName,
                  collection: row.name,
                }),
              });
            }
          });
      },
    });
  }

  reload(): void {
    this.candidateService
      .getCollectionsForCandidate(this.model.id, this.model.organizationId)
      .subscribe((x) => (this.collections = clone(x)));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model) {
      if (this.model?.id != null) {
        if (this.inViewId != this.model.id) {
          this.inViewId = this.model.id;
          this.reload();
        }
      } else {
        this.collections = [];
      }
    }
  }

  private updateCollectionsForTable(): void {
    const orgsToLoad = keys(groupBy(this.collections, (c) => c.organizationId)).filter(
      (organizationId) => !this._organizationNames[organizationId]
    );
    const obs =
      orgsToLoad.length > 0
        ? forkJoin(orgsToLoad.map((organizationId) => this.organizationService.getOrganization(organizationId))).pipe(
            tap((orgs) => orgs.forEach((o) => (this._organizationNames[o.id] = o.name)))
          )
        : of(null);
    obs.subscribe(() => {
      this.collectionsForTable = this.collections.map((c) => ({
        ...c,
        organizationName: this._organizationNames[c.organizationId],
      }));
    });
  }
}
