<ng-container *appTranslate="let t">
  <p-tabView
    class="vertical"
    styleClass="app-form"
    [formGroup]="form"
    rememberTab
    stateKey="candidate-vertical-tabs"
    (activeIndexChange)="tabChanged.emit($event)"
  >
    <p-tabPanel [header]="t('partner.title')" [headerStyleClass]="isPartnerTabpanelInvalid ? 'p-error' : ''">
      <div>
        <p-panel>
          <p-fieldset [legend]="t('partner.title')">
            <div class="field">
              <label for="languageSchooldId">{{ t("partner.languageSchool") }}</label>
              <p-dropdown
                inputId="languageSchooldId"
                [options]="languageSchoolOrganizations$ | async"
                formControlName="languageSchooldId"
                optionLabel="name"
                optionValue="id"
                filterBy="name"
                [showClear]="true"
                [placeholder]="t('organization.placeholder')"
                [readonly]="readonly"
                appCandidateFieldNotShared
                [isShared]="languageSchoolOrganizationLinked$ | async"
                formControlValueAlwaysInOptions
              ></p-dropdown>
              <small class="p-error" *ngIf="form.controls.languageSchooldId.errors?.required">
                {{ t("partner.languageSchoolRequired") }}
              </small>
            </div>

            <div class="field">
              <label for="sourceOrganizationId">{{ t("partner.source") }}</label>
              <p-dropdown
                inputId="sourceOrganizationId"
                [options]="sourceOrganizations$ | async"
                formControlName="sourceOrganizationId"
                filterBy="name"
                [showClear]="true"
                optionLabel="name"
                optionValue="id"
                [placeholder]="t('organization.placeholder')"
                [readonly]="readonly"
                appCandidateFieldNotShared
                [isShared]="sourceOrganizationLinked$ | async"
                formControlValueAlwaysInOptions
              ></p-dropdown>
              <small class="p-error" *ngIf="form.controls.sourceOrganizationId.errors?.required">
                {{ t("partner.sourceRequired") }}
              </small>
            </div>

            <div class="field">
              <label for="category">{{ t("partner.category") }}</label>
              <p-dropdown
                inputId="category"
                [options]="categories$ | async"
                formControlName="category"
                [showClear]="true"
                [placeholder]="t('partner.categoryPlaceHolder')"
                [readonly]="readonly"
              ></p-dropdown>
              <small class="p-error" *ngIf="form.controls.category.errors?.required">
                {{ t("partner.categoryRequired") }}
              </small>
            </div>
          </p-fieldset>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="t('hr.title')" [headerStyleClass]="form.controls.hr.invalid ? 'p-error' : ''">
      <div>
        <p-panel>
          <app-candidate-internal-hr
            [form]="form.controls.hr"
            [candidateForm]="candidateForm"
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [readonly]="readonly"
          ></app-candidate-internal-hr>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="t('costData.title')" [headerStyleClass]="form.controls.costCenter.invalid ? 'p-error' : ''">
      <div>
        <p-panel>
          <p-fieldset [legend]="t('costData.title')">
            <div class="field">
              <label for="costCenter">{{ t("costCenter.title") }}</label>
              <input id="costCenter" type="text" pInputText formControlName="costCenter" [readonly]="readonly" />

              <small class="p-error" *ngIf="form.controls.costCenter.errors?.required">
                {{ t("costCenter.required") }}
              </small>
            </div>
            <app-candidate-dropped-out-replacement
              *ngIf="isOwnerData"
              [form]="form"
              [candidateId]="candidateId"
              [candidateOrganizationId]="candidateOrganizationId"
              [sharingId]="sharingId"
              [collectionId]="collectionId"
              [candidateInternal]="internalData"
              [readonly]="readonly"
              [candidateDisplayName]="candidateDisplayName"
              [candidateDisplayId]="candidateDisplayId"
              class="field col-12 lg:col-3 pt-6"
            ></app-candidate-dropped-out-replacement>
          </p-fieldset>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="t('candidate.notesFull')" [headerStyleClass]="form.controls.notes.invalid ? 'p-error' : ''">
      <div>
        <p-panel>
          <p-fieldset [legend]="t('candidate.notesFull')">
            <div class="field">
              <p-editor
                spellcheck="true"
                [lang]="language"
                [style]="{ height: '120px' }"
                [readonly]="readonly"
                formControlName="notes"
                (onInit)="onInitEditor($event.editor)"
              >
                <ng-template pTemplate="header">
                  <span class="ql-formats">
                    <select class="ql-size"></select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                  </span>
                  <span class="ql-formats">
                    <select class="ql-color"></select>
                    <select class="ql-background"></select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-header" value="1"></button>
                    <button class="ql-header" value="2"></button>
                    <button class="ql-blockquote"></button>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <button class="ql-indent" value="-1"></button>
                    <button class="ql-indent" value="+1"></button>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-direction" value="rtl"></button>
                    <select class="ql-align"></select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-link"></button>
                  </span>
                </ng-template>
              </p-editor>

              <small class="p-error" *ngIf="form.controls.notes.errors?.required">
                {{ t("candidate.notesRequired") }}
              </small>
              <small class="p-error" *ngIf="form.controls.notes.errors?.maxlength">
                {{ t("candidate.notesTooLong") }}
              </small>
            </div>
          </p-fieldset>
        </p-panel>
      </div>
    </p-tabPanel>

    <!-- vz: I think these tabs were not added to the sharing configuration,
             so the scholarship tab will always be displayed in the multi edit mode,
             even though all controls inside will be disabled -->
    <p-tabPanel
      [header]="t('candidate.scholarships.title')"
      [headerStyleClass]="form.controls.scholarships.invalid ? 'p-error' : ''"
    >
      <div>
        <p-panel>
          <p-fieldset [legend]="t('candidate.scholarships.title')">
            <app-candidate-scholarship
              *ngFor="let scholarship of form.controls.scholarships.controls; index as i"
              [inputId]="'scholarship#' + i"
              [candidateId]="candidateId"
              [form]="scholarship"
              [formIndex]="i"
              [readonly]="readonly"
              (delete)="deleteScholarship(i)"
            ></app-candidate-scholarship>
            <div class="p-fluid formgrid grid align-items-center">
              <div class="field col-12 lg:col-3">
                <p-button
                  icon="pi pi-plus"
                  [label]="t('candidate.scholarships.add')"
                  [disabled]="form.controls.scholarships.disabled || readonly"
                  (onClick)="addScholarship()"
                ></p-button>
              </div>
            </div>
          </p-fieldset>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel
      *ngIf="userOrganizationId === candidateOrganizationId"
      [header]="t('candidate.responsibleEmployees.shortTitle')"
      [headerStyleClass]="form.controls.responsibleEmployees.invalid ? 'p-error' : ''"
    >
      <div>
        <p-panel>
          <p-fieldset [legend]="t('candidate.responsibleEmployees.shortTitle')">
            <div class="p-fluid formgrid grid align-items-center">
              <app-candidate-responsible-employee
                [candidateOrganizationId]="candidateOrganizationId"
                [form]="form"
                [readonly]="readonly"
                class="field col-12"
              ></app-candidate-responsible-employee>
            </div>
          </p-fieldset>
        </p-panel>
      </div>
    </p-tabPanel>

    <p-tabPanel
      [header]="t('headerComment.title')"
      [headerStyleClass]="form.controls.headerComment.invalid ? 'p-error' : ''"
    >
      <div>
        <p-panel>
          <p-fieldset [legend]="t('headerComment.title')">
            <div class="field">
              <textarea
                id="headerComment"
                pInputTextarea
                formControlName="headerComment"
                [readonly]="readonly"
                rows="3"
              ></textarea>
            </div>
          </p-fieldset>
        </p-panel>
      </div>
    </p-tabPanel>
  </p-tabView>
</ng-container>
