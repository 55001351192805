import { Component, OnInit } from "@angular/core";
import {
  ActivityType,
  DashboardAnkaadiaUpdates,
  DashboardBirthdays,
  DashboardCount,
  DashboardCountPerDay,
  MetricType,
} from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { DashboardService } from "./dashboard.service";

interface DashboardMetrics {
  tasksFinished: DashboardCount;
  documentsUploaded: DashboardCount;
  candidatesCreated: DashboardCount;
  documentsExpiring: DashboardCount;
  candidatesLoggedIn: DashboardCountPerDay;
  candidateBirthdays: DashboardBirthdays;
  ankaadiaUpdates: DashboardAnkaadiaUpdates;
}

const metrics: DashboardMetrics = {
  tasksFinished: null,
  documentsUploaded: null,
  candidatesCreated: null,
  documentsExpiring: null,
  candidatesLoggedIn: null,
  candidateBirthdays: null,
  ankaadiaUpdates: null,
};

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrl: "./dashboard.component.scss",
  standalone: false,
})
export class DashboardComponent implements OnInit {
  protected readonly ActivityType = ActivityType;
  protected readonly language = this.transloco.getActiveLang();
  protected readonly dayNames = this.getDayNames();
  protected readonly metrics = metrics;

  constructor(
    private readonly dashboardService: DashboardService,
    private readonly transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this.update();
  }

  private update(): void {
    this.dashboardService.getMetrics().subscribe((metrics) => {
      this.metrics.tasksFinished = metrics.find(
        (metric): metric is DashboardCount => metric.metricType === MetricType.TasksFinished
      );
      this.metrics.documentsUploaded = metrics.find(
        (metric): metric is DashboardCount => metric.metricType === MetricType.DocumentsUploaded
      );
      this.metrics.candidatesCreated = metrics.find(
        (metric): metric is DashboardCount => metric.metricType === MetricType.CandidatesCreated
      );
      this.metrics.documentsExpiring = metrics.find(
        (metric): metric is DashboardCount => metric.metricType === MetricType.DocumentsExpiring
      );
      this.metrics.candidatesLoggedIn = metrics.find(
        (metric): metric is DashboardCountPerDay => metric.metricType === MetricType.CandidatesLoggedIn
      );
      this.metrics.candidateBirthdays = metrics.find(
        (metric): metric is DashboardBirthdays => metric.metricType === MetricType.CandidateBirthdays
      );
      this.metrics.ankaadiaUpdates = metrics.find(
        (metric): metric is DashboardAnkaadiaUpdates => metric.metricType === MetricType.AnkaadiaUpdates
      );
    });
  }

  private getDayNames(): string[] {
    const dayNames: string[] = translate("primeng.dayNamesMin");
    const dayOfWeek = new Date().getDay() + 1;
    return [...dayNames.slice(dayOfWeek, dayNames.length), ...dayNames.slice(0, dayOfWeek)];
  }

  lastDays(n: number): [Date, Date] {
    return [new Date(Date.now() - n * 24 * 60 * 60 * 1000), new Date()];
  }

  ankaadiaUpdatesSeen(updateSeenEvent: boolean): void {
    if (!updateSeenEvent && this.metrics?.ankaadiaUpdates?.releases) return;
    this.metrics.ankaadiaUpdates = {
      ...this.metrics.ankaadiaUpdates,
      releases: this.metrics.ankaadiaUpdates.releases.map((update) => ({ ...update, isSeen: true })),
    };
  }
}
