import { Injectable } from "@angular/core";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import {
  CandidateScholarship,
  HousingAcquisition,
  HumanResourcesData,
  LaborMarketAdmissionCandidateDataDe,
  QualificationEvaluationDataDe,
  QualificationMeasureCandidateDataAt,
  QualificationMeasureCandidateDataDe,
  RecognitionPathEntryAt,
  RecognitionPathEntryDe,
  ResidenceCandidateDataAt,
  ResidenceCandidateDataDe,
  VisaCandidateDataAt,
  VisaCandidateDataDe,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";

import {
  ResponsibleOrganizationPurposeConfig,
  ResponsibleOrganizationPurposeTranslations,
} from "../candidate-responsible-organization/candidate-responsible-organization.component";
import {
  ResponsibleRepresentativeControlNames,
  ResponsibleRepresentativePurposeConfig,
  ResponsibleRepresentativeTranslations,
} from "../candidate-responsible-representative/candidate-responsible-representative.component";
import {
  ResponsibleRolePurposeConfig,
  ResponsibleRoleTranslations,
} from "../candidate-responsible-role/candidate-responsible-role.component";
import { ResponsibleRepresentativeGroupPurposeConfig } from "./candidate-responsible-representative-group.component";

const nameOfRecognition = nameofFactory<RecognitionPathEntryDe>();
const nameofQualification = nameofFactory<QualificationMeasureCandidateDataDe>();
const nameofComplementaryMeasure = nameofFactory<QualificationMeasureCandidateDataAt>();
const nameOfLaborMarket = nameofFactory<LaborMarketAdmissionCandidateDataDe>();
const nameOfVisa = nameofFactory<VisaCandidateDataDe>();
const nameOfRWRCard = nameofFactory<VisaCandidateDataAt>();
const nameOfResidence = nameofFactory<ResidenceCandidateDataDe>();
const nameOfScholarship = nameofFactory<CandidateScholarship>();
const nameofQualificationEvaluation = nameofFactory<QualificationEvaluationDataDe>();
const nameOfHumanResources = nameofFactory<HumanResourcesData>();
const nameOfHousingAcquisition = nameofFactory<HousingAcquisition>();

@Injectable({ providedIn: "root" })
export class CandidateResponsibleRepresentativeGroupService {
  constructor(
    private readonly responsibleRole: ResponsibleRoleComponentInformationProvider,
    private readonly responsibleOrganization: ResponsibleOrganizationComponentInformationProvider,
    private readonly responsibleRepresentative: ResponsibleRepresentativeComponentInformationProvider
  ) {}

  getResponsibleGroupPurposeConfig(
    purpose: ResponsibleRolePurposeGroup,
    source: ResponsibleGroupInputSourceData
  ): ResponsibleRepresentativeGroupPurposeConfig {
    if (!source) return null;

    return ResponsibleGroupInputSourceDataMap[purpose](source);
  }

  getResponsibleOrganizationPurposeConfig(
    purpose: ResponsibleRolePurposeWithOrganizationMapping
  ): ResponsibleOrganizationPurposeConfig {
    return this.responsibleOrganization.getInformation(purpose);
  }

  getResponsibleRolePurposeConfig(purpose: ResponsibleRolePurposeWithRoleMapping): ResponsibleRolePurposeConfig {
    return this.responsibleRole.getInformation(purpose);
  }

  getResponsibleRepresentativePurposeConfig(
    rolePurpose: ResponsibleRepresentativePurpose
  ): ResponsibleRepresentativePurposeConfig {
    return rolePurpose.function === "Representative"
      ? this.responsibleRepresentative.getRepresentativeInformation(rolePurpose.purpose)
      : this.responsibleRepresentative.getDeputyInformation(rolePurpose.purpose);
  }
}

@Injectable({ providedIn: "root" })
export class ResponsibleRoleComponentInformationProvider {
  getInformation(purpose: ResponsibleRolePurposeWithRoleMapping): ResponsibleRolePurposeConfig {
    return {
      getControlName(): string {
        return RoleControlMap[purpose];
      },
      getTranslationKey(language: string): ResponsibleOrganizationPurposeTranslations {
        const translationKey = RoleTranslationMap[purpose];
        return {
          caption: translate(translationKey.caption, null, language),
          placeholder: translate(translationKey.placeholder, null, language),
          required: translate(translationKey.required, null, language),
        };
      },
    };
  }
}

@Injectable({ providedIn: "root" })
export class ResponsibleOrganizationComponentInformationProvider {
  getInformation(purpose: ResponsibleRolePurposeWithOrganizationMapping): ResponsibleOrganizationPurposeConfig {
    return {
      getControlName(): string {
        return OrganizationControlMap[purpose];
      },
      getTranslationKey(language: string): ResponsibleOrganizationPurposeTranslations {
        const translationKey = OrganizationTranslationMap[purpose];
        return {
          caption: translate(translationKey.caption, null, language),
          placeholder: translate(translationKey.placeholder, null, language),
          required: translate(translationKey.required, null, language),
        };
      },
    };
  }
}

@Injectable({ providedIn: "root" })
export class ResponsibleRepresentativeComponentInformationProvider {
  getRepresentativeInformation(
    purpose: ResponsibleRolePurposeWithRepresentative
  ): ResponsibleRepresentativePurposeConfig {
    return {
      getControlNames(): ResponsibleRepresentativeControlNames {
        return RepresentativeControlMap[purpose];
      },
      getTranslationKey(language: string): ResponsibleOrganizationPurposeTranslations {
        const translationKey = RepresentativeTranslationMap[purpose];
        return {
          caption: translate(translationKey.caption, null, language),
          placeholder: translate(translationKey.placeholder, null, language),
          required: translate(translationKey.required, null, language),
        };
      },
    };
  }

  getDeputyInformation(purpose: ResponsibleRolePurposeWithDeputy): ResponsibleRepresentativePurposeConfig {
    return {
      getControlNames(): ResponsibleRepresentativeControlNames {
        return DeputyControlMap[purpose];
      },
      getTranslationKey(language: string): ResponsibleOrganizationPurposeTranslations {
        const translationKey = DeputyTranslationMap[purpose];
        return {
          caption: translate(translationKey.caption, null, language),
          placeholder: translate(translationKey.placeholder, null, language),
          required: translate(translationKey.required, null, language),
        };
      },
    };
  }
}

export enum ResponsibleRolePurpose {
  Recognition = "Recognition",
  LaborMarket = "LaborMarket",
  Qualification = "Qualification",
  Visa = "Visa",
  Residence = "Residence",
  Scholarship = "Scholarship",
  EducationVoucher = "EducationVoucher",
  QualificationEvaluation = "QualificationEvaluation",
  QualificationEvaluationPayment = "QualificationEvaluationPayment",
  Supervisor = "Supervisor",
  ContactPartner = "ContactPartner",
  HousingAcquisition = "HousingAcquisition",
  ComplementaryMeasure = "ComplementaryMeasure",
  RWRCard = "RWRCard",
}

export const responsibleRolePurposeRoleMapping = [
  ResponsibleRolePurpose.Recognition,
  ResponsibleRolePurpose.Qualification,
  ResponsibleRolePurpose.EducationVoucher,
  ResponsibleRolePurpose.LaborMarket,
  ResponsibleRolePurpose.Residence,
  ResponsibleRolePurpose.Scholarship,
  ResponsibleRolePurpose.Visa,
  ResponsibleRolePurpose.QualificationEvaluation,
  ResponsibleRolePurpose.QualificationEvaluationPayment,
  ResponsibleRolePurpose.Residence,
  ResponsibleRolePurpose.HousingAcquisition,
  ResponsibleRolePurpose.ComplementaryMeasure,
  ResponsibleRolePurpose.RWRCard,
] as const;
export type ResponsibleRolePurposeWithRoleMapping = (typeof responsibleRolePurposeRoleMapping)[number];

export const responsibleRoleOrganizationMapping = [
  ResponsibleRolePurpose.Recognition,
  ResponsibleRolePurpose.QualificationEvaluation,
  ResponsibleRolePurpose.Residence,
  ResponsibleRolePurpose.HousingAcquisition,
  ResponsibleRolePurpose.EducationVoucher,
] as const;
export type ResponsibleRolePurposeWithOrganizationMapping = (typeof responsibleRoleOrganizationMapping)[number];

export const responsibleRoleRepresentativeMapping = [
  ResponsibleRolePurpose.Recognition,
  ResponsibleRolePurpose.QualificationEvaluation,
  ResponsibleRolePurpose.Supervisor,
  ResponsibleRolePurpose.ContactPartner,
  ResponsibleRolePurpose.Residence,
  ResponsibleRolePurpose.HousingAcquisition,
  ResponsibleRolePurpose.EducationVoucher,
] as const;
export type ResponsibleRolePurposeWithRepresentative = (typeof responsibleRoleRepresentativeMapping)[number];

export const responsibleRoleDeputyMapping = [
  ResponsibleRolePurpose.Recognition,
  ResponsibleRolePurpose.QualificationEvaluation,
  ResponsibleRolePurpose.Residence,
  ResponsibleRolePurpose.HousingAcquisition,
  ResponsibleRolePurpose.EducationVoucher,
] as const;
export type ResponsibleRolePurposeWithDeputy = (typeof responsibleRoleDeputyMapping)[number];

export type ResponsibleRolePurposeGroup = ResponsibleRolePurposeWithRoleMapping &
  ResponsibleRolePurposeWithOrganizationMapping &
  ResponsibleRolePurposeWithRepresentative &
  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  ResponsibleRolePurposeWithDeputy;

export type ResponsibleRepresentativePurpose =
  | {
      purpose: ResponsibleRolePurposeWithRepresentative;
      function: "Representative";
    }
  | {
      purpose: ResponsibleRolePurposeWithDeputy;
      function: "Deputy";
    };

export const RoleControlMap: Record<ResponsibleRolePurposeWithRoleMapping, string> = {
  Recognition: nameOfRecognition("responsibleRoleRecognition"),
  Qualification: nameofQualification("responsibleRoleQualification"),
  ComplementaryMeasure: nameofComplementaryMeasure("responsibleRoleQualification"),
  EducationVoucher: nameofQualification("responsibleRoleEducationVoucher"),
  LaborMarket: nameOfLaborMarket("responsibleRoleLaborMarket"),
  Residence: nameOfResidence("responsibleRoleResidence"),
  Scholarship: nameOfScholarship("responsibleRoleScholarship"),
  Visa: nameOfVisa("responsibleRoleVisa"),
  QualificationEvaluation: nameofQualificationEvaluation("responsibleRoleQualificationEvaluation"),
  HousingAcquisition: nameOfHousingAcquisition("responsibleRoleHousingAcquisition"),
  RWRCard: nameOfRWRCard("responsibleRoleVisa"),
  QualificationEvaluationPayment: nameofQualificationEvaluation("responsibleRoleQualificationEvaluationPayment"),
};
export const RoleTranslationMap: Record<ResponsibleRolePurposeWithRoleMapping, ResponsibleRoleTranslations> = {
  Recognition: {
    caption: "responsibleRoleRecognition.title",
    placeholder: "responsibleRoleRecognition.placeholder",
    required: "responsibleRoleRecognition.required",
  },
  QualificationEvaluation: {
    caption: "responsibleRoleQualificationEvaluation.title",
    placeholder: "responsibleRoleQualificationEvaluation.placeholder",
    required: "responsibleRoleQualificationEvaluation.required",
  },
  QualificationEvaluationPayment: {
    caption: "responsibleRoleQualificationEvaluationPayment.title",
    placeholder: "responsibleRoleQualificationEvaluationPayment.placeholder",
    required: "responsibleRoleQualificationEvaluationPayment.required",
  },
  EducationVoucher: {
    caption: "responsibleRoleEducationVoucher.title",
    placeholder: "responsibleRoleEducationVoucher.placeholder",
    required: "responsibleRoleEducationVoucher.required",
  },
  LaborMarket: {
    caption: "responsibleRoleLaborMarket.title",
    placeholder: "responsibleRoleLaborMarket.placeholder",
    required: "responsibleRoleLaborMarket.required",
  },
  Residence: {
    caption: "responsibleRoleResidence.title",
    placeholder: "responsibleRoleResidence.placeholder",
    required: "responsibleRoleResidence.required",
  },
  Scholarship: {
    caption: "responsibleRoleScholarship.title",
    placeholder: "responsibleRoleScholarship.placeholder",
    required: "responsibleRoleScholarship.required",
  },
  Visa: {
    caption: "responsibleRoleVisa.title",
    placeholder: "responsibleRoleVisa.placeholder",
    required: "responsibleRoleVisa.required",
  },
  RWRCard: {
    caption: "responsibleRoleRWRCard.title",
    placeholder: "responsibleRoleRWRCard.placeholder",
    required: "responsibleRoleRWRCard.required",
  },
  Qualification: {
    caption: "responsibleRoleQualification.title",
    placeholder: "responsibleRoleQualification.placeholder",
    required: "responsibleRoleQualification.required",
  },
  ComplementaryMeasure: {
    caption: "responsibleRoleComplementaryMeasure.title",
    placeholder: "responsibleRoleComplementaryMeasure.placeholder",
    required: "responsibleRoleComplementaryMeasure.required",
  },
  HousingAcquisition: {
    caption: "responsibleRoleHousingAcquisition.title",
    placeholder: "responsibleRoleHousingAcquisition.placeholder",
    required: "responsibleRoleHousingAcquisition.required",
  },
};

export const OrganizationControlMap: Record<ResponsibleRolePurposeWithOrganizationMapping, string> = {
  Recognition: nameOfRecognition("responsibleRecognitionOrganizationId"),
  QualificationEvaluation: nameofQualificationEvaluation("responsibleQualificationEvaluationOrganizationId"),
  Residence: nameOfResidence("responsibleResidenceOrganizationId"),
  HousingAcquisition: nameOfHousingAcquisition("responsibleOrganizationId"),
  EducationVoucher: nameofQualification("responsibleEducationVoucherOrganizationId"),
};

export const OrganizationTranslationMap: Record<
  ResponsibleRolePurposeWithOrganizationMapping,
  ResponsibleOrganizationPurposeTranslations
> = {
  Recognition: {
    caption: "responsibleRecognitionOrganization.title",
    placeholder: "responsibleRecognitionOrganization.placeholder",
    required: "responsibleRecognitionOrganization.required",
  },
  QualificationEvaluation: {
    caption: "responsibleQualificationEvaluationOrganization.title",
    placeholder: "responsibleQualificationEvaluationOrganization.placeholder",
    required: "responsibleQualificationEvaluationOrganization.required",
  },
  Residence: {
    caption: "responsibleResidenceOrganization.title",
    placeholder: "responsibleResidenceOrganization.placeholder",
    required: "responsibleResidenceOrganization.required",
  },
  HousingAcquisition: {
    caption: "responsibleOrganization.title",
    placeholder: "responsibleOrganization.placeholder",
    required: "responsibleOrganization.required",
  },
  EducationVoucher: {
    caption: "responsibleEducationVoucherOrganization.title",
    placeholder: "responsibleEducationVoucherOrganization.placeholder",
    required: "responsibleEducationVoucherOrganization.required",
  },
};

export const RepresentativeControlMap: Record<
  ResponsibleRolePurposeWithRepresentative,
  ResponsibleRepresentativeControlNames
> = {
  Recognition: {
    id: nameOfRecognition("psaRepresentativeId"),
    firstName: nameOfRecognition("psaRepresentativeFirstName"),
    lastName: nameOfRecognition("psaRepresentativeLastName"),
  },
  QualificationEvaluation: {
    id: nameofQualificationEvaluation("qualificationEvaluationRepresentativeId"),
    firstName: nameofQualificationEvaluation("qualificationEvaluationRepresentativeFirstName"),
    lastName: nameofQualificationEvaluation("qualificationEvaluationRepresentativeLastName"),
  },
  Supervisor: {
    id: nameOfHumanResources("supervisorId"),
    firstName: nameOfHumanResources("supervisorFirstName"),
    lastName: nameOfHumanResources("supervisorLastName"),
  },
  ContactPartner: {
    id: nameOfHumanResources("contactPartnerId"),
    firstName: nameOfHumanResources("contactPartnerFirstName"),
    lastName: nameOfHumanResources("contactPartnerLastName"),
  },
  Residence: {
    id: nameOfResidence("residenceRepresentativeId"),
    firstName: nameOfResidence("residenceRepresentativeFirstName"),
    lastName: nameOfResidence("residenceRepresentativeLastName"),
  },
  HousingAcquisition: {
    id: nameOfHousingAcquisition("representativeId"),
    firstName: nameOfHousingAcquisition("representativeFirstName"),
    lastName: nameOfHousingAcquisition("representativeLastName"),
  },
  EducationVoucher: {
    id: nameofQualification("educationVoucherRepresentativeId"),
    firstName: nameofQualification("educationVoucherRepresentativeFirstName"),
    lastName: nameofQualification("educationVoucherRepresentativeLastName"),
  },
};

export const RepresentativeTranslationMap: Record<
  ResponsibleRolePurposeWithRepresentative,
  ResponsibleOrganizationPurposeTranslations
> = {
  Recognition: {
    caption: "psaRepresentative.title",
    placeholder: "psaRepresentative.placeholder",
    required: "psaRepresentative.required",
  },
  QualificationEvaluation: {
    caption: "qualificationEvaluationRepresentative.title",
    placeholder: "qualificationEvaluationRepresentative.placeholder",
    required: "qualificationEvaluationRepresentative.required",
  },
  Supervisor: {
    caption: "hr.supervisor.title",
    placeholder: "hr.supervisor.placeholder",
    required: "hr.supervisor.required",
  },
  ContactPartner: {
    caption: "hr.contactPartner.title",
    placeholder: "hr.contactPartner.placeholder",
    required: "hr.contactPartner.required",
  },
  Residence: {
    caption: "residenceRepresentative.title",
    placeholder: "residenceRepresentative.placeholder",
    required: "residenceRepresentative.required",
  },
  HousingAcquisition: {
    caption: "representative.title",
    placeholder: "representative.placeholder",
    required: "representative.required",
  },
  EducationVoucher: {
    caption: "educationVoucherRepresentative.title",
    placeholder: "educationVoucherRepresentative.placeholder",
    required: "educationVoucherRepresentative.required",
  },
};

export const DeputyControlMap: Record<ResponsibleRolePurposeWithDeputy, ResponsibleRepresentativeControlNames> = {
  Recognition: {
    id: nameOfRecognition("psaFirstDeputyId"),
    firstName: nameOfRecognition("psaFirstDeputyFirstName"),
    lastName: nameOfRecognition("psaFirstDeputyLastName"),
  },
  QualificationEvaluation: {
    id: nameofQualificationEvaluation("qualificationEvaluationFirstDeputyId"),
    firstName: nameofQualificationEvaluation("qualificationEvaluationFirstDeputyFirstName"),
    lastName: nameofQualificationEvaluation("qualificationEvaluationFirstDeputyLastName"),
  },
  Residence: {
    id: nameOfResidence("residenceFirstDeputyId"),
    firstName: nameOfResidence("residenceFirstDeputyFirstName"),
    lastName: nameOfResidence("residenceFirstDeputyLastName"),
  },
  HousingAcquisition: {
    id: nameOfHousingAcquisition("firstDeputyId"),
    firstName: nameOfHousingAcquisition("firstDeputyFirstName"),
    lastName: nameOfHousingAcquisition("firstDeputyLastName"),
  },
  EducationVoucher: {
    id: nameofQualification("educationVoucherFirstDeputyId"),
    firstName: nameofQualification("educationVoucherFirstDeputyFirstName"),
    lastName: nameofQualification("educationVoucherFirstDeputyLastName"),
  },
};

export const DeputyTranslationMap: Record<ResponsibleRolePurposeWithDeputy, ResponsibleRepresentativeTranslations> = {
  Recognition: {
    caption: "psaFirstDeputy.title",
    placeholder: "psaFirstDeputy.placeholder",
    required: "psaFirstDeputy.required",
  },
  QualificationEvaluation: {
    caption: "qualificationEvaluationFirstDeputy.title",
    placeholder: "qualificationEvaluationFirstDeputy.placeholder",
    required: "qualificationEvaluationFirstDeputy.required",
  },
  Residence: {
    caption: "residenceFirstDeputy.title",
    placeholder: "residenceFirstDeputy.placeholder",
    required: "residenceFirstDeputy.required",
  },
  HousingAcquisition: {
    caption: "firstDeputy.title",
    placeholder: "firstDeputy.placeholder",
    required: "firstDeputy.required",
  },
  EducationVoucher: {
    caption: "educationVoucherFirstDeputy.title",
    placeholder: "educationVoucherFirstDeputy.placeholder",
    required: "educationVoucherFirstDeputy.required",
  },
};

export type ResponsibleGroupInputSourceData =
  | QualificationEvaluationDataDe
  | RecognitionPathEntryDe
  | RecognitionPathEntryAt
  | ResidenceCandidateDataDe
  | ResidenceCandidateDataAt
  | HousingAcquisition
  | QualificationMeasureCandidateDataDe;

const ResponsibleGroupInputSourceDataMap: Record<
  ResponsibleRolePurposeGroup,
  (data: ResponsibleGroupInputSourceData) => ResponsibleRepresentativeGroupPurposeConfig
> = {
  EducationVoucher: (data: QualificationMeasureCandidateDataDe) => ({
    roleModelData: data?.responsibleRoleEducationVoucher,
    organizationModelData: {
      organizationId: data?.responsibleEducationVoucherOrganizationId,
    },
    deputyModelData: {
      firstName: data?.educationVoucherFirstDeputyFirstName,
      id: data?.educationVoucherFirstDeputyId,
      lastName: data?.educationVoucherFirstDeputyLastName,
    },
    representativeModelData: {
      firstName: data?.educationVoucherRepresentativeFirstName,
      id: data?.educationVoucherRepresentativeId,
      lastName: data?.educationVoucherRepresentativeLastName,
    },
  }),
  HousingAcquisition: (data: HousingAcquisition) => ({
    roleModelData: data?.responsibleRoleHousingAcquisition,
    organizationModelData: {
      organizationId: data?.responsibleOrganizationId,
    },
    deputyModelData: {
      firstName: data?.firstDeputyFirstName,
      id: data?.firstDeputyId,
      lastName: data?.firstDeputyLastName,
    },
    representativeModelData: {
      firstName: data?.representativeFirstName,
      id: data?.representativeId,
      lastName: data?.representativeLastName,
    },
  }),
  QualificationEvaluation: (data: QualificationEvaluationDataDe) => ({
    roleModelData: data?.responsibleRoleQualificationEvaluation,
    organizationModelData: {
      organizationId: data?.responsibleQualificationEvaluationOrganizationId,
    },
    deputyModelData: {
      firstName: data?.qualificationEvaluationFirstDeputyFirstName,
      id: data?.qualificationEvaluationFirstDeputyId,
      lastName: data?.qualificationEvaluationFirstDeputyLastName,
    },
    representativeModelData: {
      firstName: data?.qualificationEvaluationRepresentativeFirstName,
      id: data?.qualificationEvaluationRepresentativeId,
      lastName: data?.qualificationEvaluationRepresentativeLastName,
    },
  }),
  Recognition: (data: RecognitionPathEntryDe | RecognitionPathEntryAt) => ({
    roleModelData: data?.responsibleRoleRecognition,
    organizationModelData: {
      organizationId: data?.responsibleRecognitionOrganizationId,
    },
    deputyModelData: {
      firstName: data?.psaFirstDeputyFirstName,
      id: data?.psaFirstDeputyId,
      lastName: data?.psaFirstDeputyLastName,
    },
    representativeModelData: {
      firstName: data?.psaRepresentativeFirstName,
      id: data?.psaRepresentativeId,
      lastName: data?.psaRepresentativeLastName,
    },
  }),
  Residence: (data: ResidenceCandidateDataDe | ResidenceCandidateDataAt) => ({
    roleModelData: data?.responsibleRoleResidence,
    organizationModelData: {
      organizationId: data?.responsibleResidenceOrganizationId,
    },
    deputyModelData: {
      firstName: data?.residenceFirstDeputyFirstName,
      id: data?.residenceFirstDeputyId,
      lastName: data?.residenceFirstDeputyLastName,
    },
    representativeModelData: {
      firstName: data?.residenceRepresentativeFirstName,
      id: data?.residenceRepresentativeId,
      lastName: data?.residenceRepresentativeLastName,
    },
  }),
};
