import { Injector, Pipe, PipeTransform, ProviderToken } from "@angular/core";
import { isObservable, Observable, of } from "rxjs";

@Pipe({ name: "dynamic", standalone: false })
export class DynamicPipe implements PipeTransform {
  constructor(private readonly injector: Injector) {}

  transform(value: unknown, pipeToken: ProviderToken<PipeTransform>, pipeArgs?: any[]): Observable<string> {
    let retValue: string | Observable<string> = null;
    if (!pipeToken) {
      retValue = value?.toString();
    } else {
      const pipe = this.injector.get<PipeTransform>(pipeToken);
      if (pipeArgs != null) {
        retValue = pipe.transform(value, ...pipeArgs);
      } else {
        retValue = pipe.transform(value);
      }
    }
    return isObservable(retValue) ? retValue : of(retValue);
  }
}
