<ng-container *appTranslate="let t">
  <form *ngIf="form" [formGroup]="form">
    <div class="flex flex-row justify-content-start p-2 field">
      <p-inputSwitch
        id="hasDifferentRelationships"
        formControlName="hasDifferentRelationships"
        [readonly]="readonly"
        (onChange)="autoFillFieldsWhenNotSet($event)"
      ></p-inputSwitch>
      <label for="hasDifferentRelationships" class="ml-2">{{ t("hasDifferentRelationships.title") }}</label>
    </div>

    <p-fieldset
      [legend]="
        form.controls.hasDifferentRelationships.value ? t('beforeRecognition.title') : t('neutralRecognition.title')
      "
      class="relative"
    >
      <app-candidate-employment-relationship-entry
        [form]="form.controls.beforeRecognition"
        [readonly]="readonly"
        [candidate]="{ id: candidateId, organizationId: candidateOrganizationId }"
        [employer]="employmentRelationship?.beforeRecognition"
        [immigrationCountry]="immigrationCountry"
        [employerSync]="true"
        [isMultiEditMode]="isMultiEditMode"
      ></app-candidate-employment-relationship-entry>
    </p-fieldset>

    <p-fieldset [legend]="t('afterRecognition.title')" class="relative">
      <app-candidate-employment-relationship-entry
        #afterRecognition
        [form]="form.controls.afterRecognition"
        [readonly]="readonly"
        [candidate]="{ id: candidateId, organizationId: candidateOrganizationId }"
        [employer]="employmentRelationship?.afterRecognition"
        [enabled]="form.controls.hasDifferentRelationships.value ?? false"
        [immigrationCountry]="immigrationCountry"
        [isMultiEditMode]="isMultiEditMode"
      ></app-candidate-employment-relationship-entry>
    </p-fieldset>
  </form>
</ng-container>
