import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DocumentMode } from "@ankaadia/graphql";
import { SettingsService } from "../../shared/services/settings.service";

@Component({
  selector: "app-documents",
  templateUrl: "./documents.component.html",
  standalone: false,
})
export class DocumentsComponent implements OnInit {
  readonly organizationId = this.settings.organizationId;

  mode: DocumentMode;

  constructor(
    private readonly settings: SettingsService,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.mode = this.route.snapshot.data.documentMode;
  }
}
