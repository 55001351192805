<ng-container *appTranslate="let t">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <p-card [header]="t('collection.create')">
      <app-collection-edit [form]="form.controls.collection"></app-collection-edit>

      <div class="field-checkbox">
        <p-checkbox [binary]="true" inputId="createSharing" formControlName="createSharing"></p-checkbox>
        <label for="createSharing">{{ t("collection.share") }}</label>
      </div>

      <app-sharing-edit
        *ngIf="form.controls.createSharing.value"
        [form]="form.controls.sharing"
        [sharingProvider]="{ organizationId: form.controls.collection.controls.organizationId.value }"
        [showManageCollectionMembers]="true"
      ></app-sharing-edit>

      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
          <p-button
            [disabled]="!form.valid || !form.dirty"
            [label]="t('common.save')"
            icon="pi pi-check"
            type="submit"
            class="mr-2"
          ></p-button>
          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            type="reset"
            (onClick)="closed.emit()"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  </form>
</ng-container>
