import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import {
  Organization,
  RequiredDocumentForListFragment,
  RequiredDocumentFragment,
  StaticDataType,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { clone } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { Observable, catchError, map, of, tap } from "rxjs";
import { AppDateTimePipe } from "../../shared/pipes/date.pipe";
import { SettingsService } from "../../shared/services/settings.service";
import { StaticDataPipe } from "../../shared/static-data/static-data.pipe";
import { PipeDescription, TableColumn, TableOperation } from "../../shared/table/table.model";
import { MessageService } from "../message/message.service";
import { OrganizationsService } from "../organizations/organizations.service";
import { RequiredDocumentService } from "./required-document.service";

const nameOf = nameofFactory<RequiredDocumentForListFragment>();
@Component({
  selector: "app-required-documents",
  templateUrl: "./required-documents.component.html",
  standalone: false,
})
export class RequiredDocumentsComponent implements OnInit {
  documentSets: RequiredDocumentForListFragment[];

  newOperations: TableOperation[] = [
    {
      label: translate("requiredDocument.titleDE"),
      icon: PrimeIcons.PLUS,
      operation: (): void => this.addDocSet(SupportedImmigrationCountry.De),
    },
    {
      label: translate("requiredDocument.titleAT"),
      icon: PrimeIcons.PLUS,
      operation: (): void => this.addDocSet(SupportedImmigrationCountry.At),
    },
  ];

  tableOperations: TableOperation[] = [
    {
      label: translate("common.edit"),
      icon: PrimeIcons.PENCIL,
      operation: (x: RequiredDocumentFragment): void => this.editDocSet(x),
    },
    {
      label: translate("common.details"),
      icon: PrimeIcons.EYE,
      operation: (x: RequiredDocumentFragment): void => this.manageDocSet(x),
    },
    {
      label: translate("common.copy"),
      icon: PrimeIcons.COPY,
      operation: (x: RequiredDocumentFragment): void => this.duplicateDocSet(x),
    },
    {
      label: translate("common.delete"),
      icon: PrimeIcons.TRASH,
      operation: (x: RequiredDocumentFragment, e: Event): void => this.deleteDocSet(x, e),
    },
  ];

  columns: TableColumn[] = [
    {
      header: translate("requiredDocument.name"),
      fieldname: nameOf("name"),
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("immigrationCountry.title"),
      fieldname: nameOf("immigrationCountry"),
      pipeDescription: new PipeDescription(StaticDataPipe, StaticDataType.SupportedImmigrationCountries),
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("changedBy.title"),
      fieldname: nameOf("changedBy"),
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("changedAt.title"),
      fieldname: nameOf("changedAt"),
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(AppDateTimePipe, { dateStyle: "short", timeStyle: "short" }),
    },
    {
      header: translate("comment.title"),
      icon: (item: RequiredDocumentFragment): string => (item?.description ? PrimeIcons.INFO_CIRCLE : null),
      fieldname: nameOf("description"),
      sortable: false,
      includeInGlobalFilter: false,
      tooltip: (x: RequiredDocumentFragment): string => x?.description,
    },
  ];

  showDialog = false;
  organizationName: Observable<string>;
  organization: Organization;
  selectedTemplate: RequiredDocumentFragment;
  isEdit = false;

  constructor(
    private readonly settings: SettingsService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly requiredDocumentService: RequiredDocumentService,
    private readonly orgService: OrganizationsService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService
  ) {
    this.showDialog = false;
  }

  ngOnInit(): void {
    this.getAllDocumentSets();
  }

  getAllDocumentSets(): void {
    this.requiredDocumentService.watchAllForOrganization(this.settings.organizationId).subscribe((x) => {
      this.documentSets = clone(x);
    });
  }

  routerParamsChanged(params: Params): void {
    const organizationId = params["orgId"];
    this.organizationName = this.orgService.getOrganization(organizationId).pipe(
      tap((x) => (this.organization = x)),
      map((x) => x.name),
      catchError((x) => {
        this.settings.navigateToNotFound();
        return of(x);
      })
    );
  }

  addDocSet(immigrationCountry: SupportedImmigrationCountry): void {
    this.selectedTemplate = {
      immigrationCountry: immigrationCountry,
      functions: ["PROFESSIONAL"],
      id: null,
      organizationId: this.settings.organizationId,
      name: null,
      description: null,
      changedBy: null,
      changedAt: null,
      configData: [],
    };
    this.isEdit = false;
    this.showDialog = true;
  }

  duplicateDocSet(requiredDocument: RequiredDocumentFragment): void {
    this.requiredDocumentService.duplicate(requiredDocument.id, requiredDocument.organizationId).subscribe((x) => {
      this.selectedTemplate = x;
    });
  }

  editDocSet(requiredDocument: RequiredDocumentFragment): void {
    this.isEdit = true;
    this.requiredDocumentService.get(requiredDocument.id, requiredDocument.organizationId).subscribe((x) => {
      this.selectedTemplate = x;
      this.showDialog = true;
    });
  }

  deleteDocSet(requiredDocument: RequiredDocumentFragment, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("requiredDocument.confirmDelete", requiredDocument),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () =>
        this.requiredDocumentService.delete(requiredDocument).subscribe(() => {
          this.messageService.add({
            severity: "success",
            summary: translate("requiredDocument.deleted.title"),
            detail: translate("requiredDocument.deleted.message", requiredDocument),
          });
        }),
    });
  }

  save(requiredDocument: RequiredDocumentFragment): void {
    if (requiredDocument.id) {
      this.requiredDocumentService.updateMeta(requiredDocument).subscribe(() => {
        this.messageService.add({
          severity: "success",
          summary: translate("requiredDocument.updated.title"),
          detail: translate("requiredDocument.updated.message", document),
        });
      });
    } else {
      this.requiredDocumentService.create(requiredDocument).subscribe(() => {
        this.getAllDocumentSets();
        this.messageService.add({
          severity: "success",
          summary: translate("requiredDocument.created.title"),
          detail: translate("requiredDocument.created.message", document),
        });
      });
    }
    this.closeSidebar();
  }

  closeSidebar(): void {
    this.showDialog = false;
  }

  manageDocSet(documentSet: RequiredDocumentForListFragment): void {
    void this.router.navigate([`../${documentSet.organizationId}/${documentSet.id}`], { relativeTo: this.route });
  }
}
