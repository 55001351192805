import { AfterViewInit, Component, Input } from "@angular/core";
import { translate } from "@jsverse/transloco";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { filter, startWith } from "rxjs";
import { QualificationMeasureFormDe } from "../../candidate-form.model";

@Component({
  selector: "app-candidate-detailed-equivalence",
  templateUrl: "./candidate-detailed-equivalence.component.html",
  standalone: false,
})
export class CandidateDetailedEquivalenceComponent implements AfterViewInit {
  @Input()
  form: QualificationMeasureFormDe;

  @Input()
  readonly: boolean;

  constructor(private readonly confirmationService: ConfirmationService) {}

  ngAfterViewInit(): void {
    this.form.controls.noDetailedEquivalence.valueChanges
      .pipe(
        startWith(this.form.controls.noDetailedEquivalence.value),
        filter(() => this.form.controls.noDetailedEquivalence.pristine)
      )
      .subscribe((noDetailedEquivalence) => {
        if (noDetailedEquivalence) {
          this.form.controls.totalHours.disable();
          this.form.controls.qualificationModules.disable();
        } else {
          this.form.controls.totalHours.enable();
          this.form.controls.qualificationModules.enable();
        }
      });
  }

  confirmNoDetailedEquivalence(checked: boolean, target: EventTarget): void {
    if (checked) {
      this.confirmationService.confirm({
        target: target,
        message: translate("noDetailedEquivalence.confirm"),
        icon: PrimeIcons.EXCLAMATION_TRIANGLE,
        accept: () => {
          this.form.controls.totalHours.setValue(null);
          this.form.controls.totalHours.disable();
          this.form.controls.qualificationModules.clear();
          this.form.controls.qualificationModules.disable();
        },
        reject: () => {
          this.form.controls.noDetailedEquivalence.setValue(false);
        },
      });
    } else {
      this.form.controls.totalHours.enable();
      this.form.controls.qualificationModules.enable();
    }
  }
}
