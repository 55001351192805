import { Component, Input } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { OrganizationProfileFields } from "@ankaadia/graphql";
import { OrganizationProfileFormsService } from "../organization-profile-forms.service";

@Component({
  selector: "app-organization-profile-location-vacancies",
  templateUrl: "./organization-profile-location-vacancies.component.html",
  standalone: false,
})
export class OrganizationProfileLocationVacanciesComponent {
  private _vacancies: FormArray;

  get forms(): FormGroup[] {
    return this._vacancies.controls.map((control) => control as FormGroup);
  }

  @Input()
  organizationId: string;

  @Input()
  fields: OrganizationProfileFields;

  @Input()
  set form(value: FormGroup) {
    this._vacancies = value.get("vacancies") as FormArray;
  }

  constructor(private readonly organizationProfileFormsService: OrganizationProfileFormsService) {}

  addVacancy(): void {
    const form = this.organizationProfileFormsService.createVacancyFormGroup();
    this._vacancies.push(form, { emitEvent: false });
    this._vacancies.markAsDirty();
  }

  removeVacancy(index: number): void {
    this._vacancies.removeAt(index);
    this._vacancies.markAsDirty();
  }
}
