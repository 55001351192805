import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router } from "@angular/router";
import { Collection, Organization, SharingTypeEnum } from "@ankaadia/graphql";
import { clone } from "lodash";
import { Table, TableRowSelectEvent } from "primeng/table";
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, filter, map, switchMap } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { CollectionRows, CollectionService } from "../collection.service";

@Component({
  selector: "app-collection-selection",
  templateUrl: "./collection-selection.component.html",
  standalone: false,
})
export class CollectionSelectionComponent implements OnInit, OnChanges {
  collections: Observable<CollectionRows>;
  isFavoriteSelected: boolean;
  selectedCollectionChange$ = new BehaviorSubject<Collection>(undefined);

  @Input() selectedCollection: Collection;
  @Input() filteredOrg: Organization;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly collService: CollectionService,
    private readonly settings: SettingsService
  ) {
    this.selectedCollectionChange$
      .pipe(
        takeUntilDestroyed(),
        debounceTime(200),
        distinctUntilChanged(),
        filter((x) => !!x),
        switchMap((col) => this.navigate(col))
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedCollection) {
      const collectionId = changes.selectedCollection.currentValue?.id;
      if (collectionId && this.route.snapshot.firstChild?.params?.collectionId !== collectionId) {
        this.selectedCollectionChange$.next(changes.selectedCollection.currentValue);
      }
    }
    if (changes.filteredOrg) {
      this.collections = this.loadCollections();
    }
  }

  ngOnInit(): void {
    this.collections = this.getAllCollections();
  }

  clearFilter(table: Table): void {
    table.clear();
  }

  onRowSelect(event: TableRowSelectEvent): void {
    this.selectedCollectionChange$.next(event.data);
  }

  toggle(): void {
    this.isFavoriteSelected = !this.isFavoriteSelected;
    this.collections = this.loadCollections();
  }

  private loadCollections(): Observable<CollectionRows> {
    if (this.isFavoriteSelected) {
      return this.getFavoriteCollections();
    }
    if (this.filteredOrg != null) {
      return this.getCollectionsFilteredByOrganization();
    }
    return this.getAllCollections();
  }

  private async navigate(col: Collection): Promise<boolean> {
    return this.router.navigate([`${col.organizationId}/${col.id}`], { relativeTo: this.route });
  }

  private getAllCollections(): Observable<CollectionRows> {
    return this.collService.getCollections().pipe(map((x) => clone(x)));
  }

  private getFavoriteCollections(): Observable<CollectionRows> {
    return this.collService.getFavoriteCollections(this.filteredOrg?.id).pipe(map((x) => clone(x)));
  }

  private getCollectionsFilteredByOrganization(): Observable<CollectionRows> {
    return this.collService
      .getCollectionsSharedToOrganization(this.settings.organizationId, this.filteredOrg.id, [
        SharingTypeEnum.Collaboration,
        SharingTypeEnum.ReadOnlyCollaboration,
        SharingTypeEnum.View,
      ])
      .pipe(map((x) => clone(x)));
  }
}
