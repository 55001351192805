import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Organization, OrganizationInput } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { MessageService } from "../../message/message.service";
import { OrganizationsService } from "../organizations.service";

@Component({
  selector: "app-organization-ezb-data",
  templateUrl: "./organization-EZB-data.component.html",
  styleUrl: "./organization-EZB-data.component.scss",
  standalone: false,
})
export class OrganizationEZBDataComponent implements OnInit {
  _organization: Organization;
  showDialog = false;

  @Input() organizationId: string;
  @Input() myOrgMode = false;
  @Input() displayBackButton?: boolean;

  @Output() readonly closed = new EventEmitter<boolean>();

  constructor(
    private readonly orgSvc: OrganizationsService,
    private readonly messageService: MessageService
  ) {
    this.showDialog = false;
  }

  ngOnInit(): void {
    this.fetchOrganization();
  }

  fetchOrganization(): void {
    this.orgSvc.getOrganization(this.organizationId).subscribe((org) => {
      this._organization = org;
      this.showDialog = true;
    });
  }

  save(orgInput: OrganizationInput): void {
    this.orgSvc.updateOrganization(orgInput).subscribe((x) => {
      if (x != null) {
        this.closed.emit(true);
        this.messageService.add({
          severity: "success",
          summary: translate("organization.updated.title"),
          detail: translate("organization.updated.message", x),
        });
      }
    });
  }

  close(): void {
    this.closed.emit(false);
  }
}
