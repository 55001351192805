<ng-container *appTranslate="let t">
  <form [formGroup]="form" *ngIf="form">
    <div class="p-fluid formgrid">
      <div class="field">
        <label for="examType">{{ t("examType.title") }}</label>
        <p-dropdown
          inputId="examType"
          formControlName="examType"
          [options]="examTypes"
          [placeholder]="t('examType.placeholder')"
        ></p-dropdown>
        <small class="p-error" *ngIf="form.controls.examType.errors?.required">{{ t("examType.required") }}</small>
      </div>

      <div class="field">
        <label for="examInstitution">{{ t("examInstitution.title") }}</label>
        <p-dropdown
          inputId="examInstitution"
          formControlName="examInstitution"
          [options]="examInstitutions$ | async"
          [placeholder]="t('examInstitution.placeholder')"
        ></p-dropdown>
        <small class="p-error" *ngIf="form.controls.examInstitution.errors?.required">{{
          t("examInstitution.required")
        }}</small>
      </div>

      <div class="field">
        <label for="examModules">{{ t("examModules.title") }}</label>

        <p-multiSelect
          inputId="examModules"
          formControlName="examModules"
          [options]="examModuleTypes$ | async"
          [placeholder]="t('examModules.placeholder')"
          [group]="true"
          [showToggleAll]="false"
        >
        </p-multiSelect>

        <small
          class="p-error"
          *ngIf="
            form.controls.examModules.errors?.required ||
            (!isEmpty(existingExamModules) && isEmpty(form.controls.examModules.value))
          "
          >{{ t("examModules.required") }}</small
        >
      </div>
    </div>
  </form>
</ng-container>
