import { Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { InternalCandidateData, ReplacementForCandidateFragment } from "@ankaadia/graphql";
import { DropdownChangeEvent } from "primeng/dropdown";
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  startWith,
  switchMap,
  tap,
} from "rxjs";
import { SettingsService } from "../../../../shared/services/settings.service";
import { CandidatePage, CandidatesService } from "../../../candidates/candidates.service";
import { CustomLazyLoadEvent } from "../../../collections/collection-edit-assigned-candidates/custom-filter.model";
import { CheckboxChangeEvent } from "primeng/checkbox";
import { InternalForm } from "../../candidate-form.model";

interface CandidateReplacementViewData {
  candidateId: string;
  displayName: string;
  displayId: string;
  organizationId: string;
  country: string;
}

@Component({
  selector: "app-candidate-dropped-out-replacement",
  templateUrl: "./candidate-dropped-out-replacement.component.html",
  styleUrl: "./candidate-dropped-out-replacement.component.scss",
  standalone: false,
})
export class CandidateDroppedOutReplacementComponent implements OnInit, OnChanges {
  @Input({ required: true })
  form: InternalForm;

  @Input({ required: true })
  candidateId: string;

  @Input({ required: true })
  candidateDisplayName: string;

  @Input({ required: true })
  candidateDisplayId: string;

  @Input({ required: true })
  candidateOrganizationId: string;

  @Input({ required: true })
  sharingId: string;

  @Input({ required: true })
  collectionId: string;

  @Input({ required: true })
  candidateInternal: InternalCandidateData;

  replacedByCandidatesJoined: string;
  replacedByCandidates: ReplacementForCandidateFragment[];
  candidateDisplayIdAndName = "";

  @Input()
  readonly: boolean;

  private readonly destroyRef = inject(DestroyRef);
  private readonly searchString$ = new BehaviorSubject<string>(undefined);
  private readonly currentValue$ = new BehaviorSubject<CandidateReplacementViewData>(undefined);
  protected readonly candidates$ = new BehaviorSubject<CandidateReplacementViewData[]>([]);

  constructor(
    private readonly candidateService: CandidatesService,
    private readonly settingsService: SettingsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateInternal) this.emitCurrentSelection();
    if (changes.candidateDisplayId || changes.candidateDisplayName) {
      this.candidateDisplayIdAndName = this.candidateDisplayId
        ? `${this.candidateDisplayId}-${this.candidateDisplayName}`
        : "";
    }
  }

  ngOnInit(): void {
    this.candidateService
      .getReplacementOfCandidate(this.candidateId, this.candidateOrganizationId)
      .subscribe((candidates) => {
        this.replacedByCandidates = candidates;
        this.replacedByCandidatesJoined = this.replacedByCandidates?.join("\n");
      });

    const searchString$ = this.searchString$.pipe(distinctUntilChanged());
    const isReplacement$ = this.form.controls.isReplacementForCandidate.valueChanges.pipe(distinctUntilChanged());

    const searchResult$ = combineLatest([searchString$, isReplacement$]).pipe(
      takeUntilDestroyed(this.destroyRef),
      distinctUntilChanged(),
      debounceTime(300),
      filter(([search, isReplacement]) => search != null && isReplacement === true),
      switchMap(([search, isReplacement]) => this.getSearchResult(search, isReplacement)),
      map((x) => this.getOptions(x))
    );

    combineLatest([searchResult$.pipe(startWith([])), this.currentValue$])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(([options, current]) =>
          this.combineOptionsAndCurrentlySelected(options, current).filter(
            (option) => option.candidateId !== this.candidateId
          )
        ),
        tap((x) => this.candidates$.next(x))
      )
      .subscribe();

    this.form.controls.isReplacementForCandidate.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        tap((x) =>
          x === true
            ? this.form.controls.replacementForCandidateId.enable()
            : this.form.controls.replacementForCandidateId.disable()
        )
      )
      .subscribe();
  }

  protected onCandidatesReplacementDropdownClick(): void {
    if (this.searchString$.value == null) this.searchString$.next("");
  }

  protected searchCandidates(search: string): void {
    this.searchString$.next(search ?? "");
  }

  protected setFormData($event: DropdownChangeEvent): void {
    const candidate = this.candidates$.value.find((x) => x.candidateId === $event.value);
    this.setReplacementData(candidate);
  }

  protected isReplacementForCandidateChange(checkBox: CheckboxChangeEvent): void {
    if (!checkBox?.checked) {
      this.form.controls.replacementForCandidateId.setValue(null);
      this.setReplacementData(null);
    }
  }

  private setReplacementData(candidate: CandidateReplacementViewData): void {
    this.form.controls.replacementForDisplayId.setValue(candidate?.displayId);
    this.form.controls.replacementForOrganizationId.setValue(candidate?.organizationId);
    this.form.controls.replacementForDisplayName.setValue(candidate?.displayName);
  }

  private getSearchResult(searchString: string, isReplacement: boolean): Observable<CandidatePage> {
    if (isReplacement !== true) return of(null);
    const isOwner = this.candidateOrganizationId === this.settingsService.organizationId;

    return isOwner
      ? this.candidateService.getFilteredCandidates(
          null,
          this.candidateOrganizationId,
          this.createFilterEvent(searchString),
          null
        )
      : this.candidateService.getFilteredCandidates(
          this.collectionId,
          this.candidateOrganizationId,
          this.createFilterEvent(searchString),
          this.sharingId
        );
  }

  private createFilterEvent(searchString: string): CustomLazyLoadEvent {
    return {
      rows: 200,
      sortOrder: 1,
      first: 0,
      filters: {
        global: {
          matchMode: "contains",
          value: searchString,
        },
      },
      globalFilter: searchString,
    };
  }

  private emitCurrentSelection(): void {
    this.currentValue$.next(
      this.candidateInternal?.replacementForCandidateId
        ? {
            candidateId: this.candidateInternal.replacementForCandidateId,
            displayName: this.candidateInternal.replacementForDisplayName,
            displayId: this.candidateInternal.replacementForDisplayId,
            organizationId: this.candidateInternal.replacementForOrganizationId,
            country: undefined,
          }
        : null
    );
  }

  private combineOptionsAndCurrentlySelected(
    options: CandidateReplacementViewData[],
    current: CandidateReplacementViewData
  ): CandidateReplacementViewData[] {
    if (current && !options.find((x) => x.candidateId === current.candidateId)) {
      return [current].concat(options);
    }
    return options;
  }

  private getOptions(page: CandidatePage): CandidateReplacementViewData[] {
    if (!page) return [];
    return page.nodes
      .filter((x) => x.organizationId === this.candidateOrganizationId)
      .map((node) => {
        return {
          candidateId: node.id,
          displayName: node.displayName,
          displayId: node.displayId,
          organizationId: node.organizationId,
          country: node.country,
        };
      });
  }
}
