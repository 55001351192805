<ng-container *appTranslate="let t">
  <ng-container *ngIf="priceRow()">
    <p-card>
      <ng-template pTemplate="title">
        <app-plan-candidate-specialization [specialization]="priceRow()"></app-plan-candidate-specialization>
      </ng-template>
      <ng-template pTemplate="body">
        <div class="m-0 flex flex-column h-full">
          <ng-container *ngIf="priceRow().degression.length === 1; else multipleDegressionRows">
            <app-price [price]="priceRow().degression.at(0).amount" unit="candidate" [currency]="currency()"
                       size="large"></app-price>
          </ng-container>
          <ng-template #multipleDegressionRows>
            <p-table class="flex" [value]="priceRow().degression" styleClass="w-full">
              <ng-template pTemplate="body" let-row>
                <tr>
                  <td>> {{ row.from }}</td>
                  <td>
                    <app-price [price]="row.amount" unit="candidate" [currency]="currency()"></app-price>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </ng-template>
          <app-plan-price-cap-floor class="flex mt-3"
                                    *ngIf="typeof priceRow().cap === 'number' || typeof priceRow().floor === 'number'"
                                    [capFloor]="priceRow()"
                                    [currency]="currency()"></app-plan-price-cap-floor>
          <div class="flex flex-1 flex-column m-0" style="justify-content: flex-end">
            <app-plan-candidate-billing-events class="flex mt-3"
                                               [events]="priceRow().events"></app-plan-candidate-billing-events>
          </div>
        </div>
      </ng-template>
    </p-card>
  </ng-container>
</ng-container>
