<ng-container *ngIf="readonly; else other" [ngSwitch]="type">
  <i *ngSwitchCase="VoteType.Positive" class="pi pi-thumbs-up"></i>
  <i *ngSwitchCase="VoteType.Negative" class="pi pi-thumbs-down"></i>
</ng-container>
<ng-template #other>
  <div class="inline-block">
    <button
      pButton
      type="button"
      icon="pi pi-thumbs-up{{ control?.value === VoteType.Positive ? '-fill' : '' }} text-lg"
      class="p-button-rounded p-button-text"
      (click)="toggle(VoteType.Positive)"
    ></button>
    <button
      pButton
      type="button"
      icon="pi pi-thumbs-down{{ control?.value === VoteType.Negative ? '-fill' : '' }} text-lg"
      class="p-button-rounded p-button-text"
      (click)="toggle(VoteType.Negative)"
    ></button>
  </div>
</ng-template>
