<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <label for="qualificationType">{{ t("qualificationType.title") }}</label>
  <p-dropdown
    id="qualificationType"
    inputId="qualificationType"
    [options]="qualificationTypes"
    [formControl]="dropdownControl"
    [showClear]="true"
    [placeholder]="t('qualificationType.placeholder')"
    [readonly]="readonly"
  ></p-dropdown>
</ng-container>
