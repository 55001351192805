import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { QualificationEvaluationDataDe } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";

const nameOf = nameofFactory<QualificationEvaluationDataDe>();

@Component({
  selector: "app-candidate-no-qualification-evaluation",
  templateUrl: "./candidate-no-qualification-evaluation.component.html",
  standalone: false,
})
export class CandidateNoQualificationEvaluationComponent implements OnInit, OnDestroy {
  private readonly language = this.transloco.getActiveLang();

  @Input()
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  readonly: boolean;

  @Input()
  hidden: boolean;

  get control(): FormControl<boolean | null> {
    return this.form.get(nameOf("noQualificationEvaluation")) as FormControl<boolean | null>;
  }

  set control(value: FormControl<boolean | null>) {
    this.form.setControl(nameOf("noQualificationEvaluation"), value);
  }

  constructor(private readonly transloco: TranslocoService) {}

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<boolean | null>(null);
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("noQualificationEvaluation"), { emitEvent: false });
  }
}
