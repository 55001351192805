import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { ActivityModule } from "../activity/activity.module";
import { CandidateEventsModule } from "../candidate-events/candidate-events.module";
import { CandidateFilterModule } from "../candidate-filter/candidate-filter.module";
import { DocumentTemplateModule } from "../document-template/document-template.module";
import { DocumentsModule } from "../documents/documents.module";
import { FavoriteModule } from "../favorite/favorite.module";
import { ImportModule } from "../import/import.module";
import { RequiredVaccinationsModule } from "../required-vaccinations/required-vaccinations.module";
import { TalentPoolSharingModule } from "../talent-pool-sharing/talent-pool-sharing.module";
import { CollectionAndSharingCreateDialogComponent } from "./collection-and-sharing-create-dialog/collection-and-sharing-create-dialog.component";
import { CollectionAssignedCandidatesComponent } from "./collection-assigned-candidates/collection-assigned-candidates.component";
import { CollectionAssignedCollectionsComponent } from "./collection-assigned-collections/collection-assigned-collections.component";
import { CollectionAssignedExceptionsComponent } from "./collection-assigned-exceptions/collection-assigned-exceptions.component";
import { CollectionAutoFilterTemplatesComponent } from "./collection-auto-template/collection-auto-filter-templates/collection-auto-filter-templates.component";
import { CollectionAutoTemplateAttachDialogComponent } from "./collection-auto-template/collection-auto-template-attach-dialog/collection-auto-template-attach-dialog.component";
import { CollectionAutoTemplateCollectionsComponent } from "./collection-auto-template/collection-auto-template-collections/collection-auto-template-collections.component";
import { CollectionAutoTemplateEditDialogComponent } from "./collection-auto-template/collection-auto-template-edit-dialog/collection-auto-template-edit-dialog.component";
import { CollectionAutoTemplateEditComponent } from "./collection-auto-template/collection-auto-template-edit/collection-auto-template-edit.component";
import { CollectionAutoTemplateFormComponent } from "./collection-auto-template/collection-auto-template-form/collection-auto-template-form.component";
import { CollectionAutoTemplateSelectionComponent } from "./collection-auto-template/collection-auto-template-selection/collection-auto-template-selection.component";
import { CollectionAutoTemplateComponent } from "./collection-auto-template/collection-auto-template/collection-auto-template.component";
import { VariableDescriptionIconComponent } from "./collection-auto-template/variable-description-icon/variable-description-icon.component";
import { CollectionAutoFilterEditComponent } from "./collection-auto/collection-auto-filter-edit/collection-auto-filter-edit.component";
import { CollectionAutoFilterInfoComponent } from "./collection-auto/collection-auto-filter-info/collection-auto-filter-info.component";
import { CollectionAutoFilterComponent } from "./collection-auto/collection-auto-filter/collection-auto-filter.component";
import { CollectionFromTemplateComponent } from "./collection-auto/collection-from-template/collection-from-template.component";
import { CollectionDocumentTemplatesComponent } from "./collection-document-templates/collection-document-templates.component";
import { CollectionEditAssignedCandidatesComponent } from "./collection-edit-assigned-candidates/collection-edit-assigned-candidates.component";
import { CollectionEditAssignedCollectionDialogComponent } from "./collection-edit-assigned-collection-dialog/collection-edit-assigned-collection-dialog.component";
import { CollectionEditDialogComponent } from "./collection-edit-dialog/collection-edit-dialog.component";
import { CollectionEditComponent } from "./collection-edit/collection-edit.component";
import { CollectionFormComponent } from "./collection-form/collection-form.component";
import { CollectionSelectionComponent } from "./collection-selection/collection-selection.component";
import { CollectionTransferDialogComponent } from "./collection-transfer-dialog/collection-transfer-dialog.component";
import { CollectionTransferResultComponent } from "./collection-transfer-dialog/collection-transfer-result/collection-transfer-result.component";
import { CollectionVaccinesOverviewComponent } from "./collection-vaccines-overview/collection-vaccines-overview.component";
import { CollectionsComponent } from "./collections.component";
import { SharingActivitiesComponent } from "./sharing-activities/sharing-activities.component";
import { SharingEditDialogComponent } from "./sharing-edit-dialog/sharing-edit-dialog.component";
import { SharingEditComponent } from "./sharing-edit/sharing-edit.component";
import { SharingTemplatesFromComponent } from "./sharing-templates-from/sharing-templates-form.component";
import { SharingsFromComponent } from "./sharings-from/sharings-form.component";
import { CandidateFlexibleEditButtonComponent } from "../candidates/candidate-flexible-edit/candidate-flexible-edit-button/candidate-flexible-edit-button.component";

@NgModule({
  imports: [
    SharedModule,
    ImportModule,
    DocumentTemplateModule,
    DocumentsModule,
    FavoriteModule,
    CandidateEventsModule,
    CandidateFilterModule,
    ActivityModule,
    RequiredVaccinationsModule,
    TalentPoolSharingModule,
    CandidateFlexibleEditButtonComponent,
  ],
  declarations: [
    CollectionAssignedCandidatesComponent,
    CollectionEditAssignedCandidatesComponent,
    CollectionEditDialogComponent,
    SharingEditDialogComponent,
    CollectionSelectionComponent,
    SharingTemplatesFromComponent,
    SharingsFromComponent,
    CollectionsComponent,
    CollectionFormComponent,
    CollectionTransferDialogComponent,
    CollectionAssignedCollectionsComponent,
    CollectionEditAssignedCollectionDialogComponent,
    CollectionDocumentTemplatesComponent,
    CollectionEditComponent,
    SharingEditComponent,
    CollectionAndSharingCreateDialogComponent,
    CollectionTransferResultComponent,
    CollectionAutoFilterComponent,
    CollectionAutoFilterEditComponent,
    CollectionAutoFilterInfoComponent,
    CollectionAutoTemplateComponent,
    CollectionAutoFilterTemplatesComponent,
    CollectionAutoTemplateSelectionComponent,
    CollectionAutoTemplateFormComponent,
    CollectionAutoTemplateEditDialogComponent,
    CollectionAutoTemplateEditComponent,
    CollectionAutoTemplateCollectionsComponent,
    CollectionAutoTemplateAttachDialogComponent,
    CollectionFromTemplateComponent,
    SharingActivitiesComponent,
    CollectionVaccinesOverviewComponent,
    VariableDescriptionIconComponent,
    CollectionAssignedExceptionsComponent,
  ],
  exports: [
    CollectionsComponent,
    CollectionFormComponent,
    CollectionAndSharingCreateDialogComponent,
    VariableDescriptionIconComponent,
    SharingEditComponent,
  ],
})
export class CollectionsModule {}
