import { Injectable } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  DefaultDocumentSetName,
  DocumentSetType,
  DocumentWriteMode,
  PickPropertyKeys,
  SelectedSet,
  declaredDocumentSetTypes,
  getSetFiles,
  getSets,
  getTags,
  hasDeclaredDocumentSetTypes,
  isDocumentFile,
  isDocumentSelectionMode,
  isRegularFile,
  isSingleDocumentSet,
  tryValidateDocument,
  tryValidateDocumentNotEmpty,
} from "@ankaadia/ankaadia-shared";
import {
  Document,
  DocumentFile,
  DocumentFreeFormatFile,
  DocumentGenerationLanguageHandling,
  DocumentMode,
  DocumentRequirement,
  DocumentSet,
  DocumentTemplateMode,
  SingleSetConfig,
} from "@ankaadia/graphql";
import { uniq } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { SettingsService } from "../../shared/services/settings.service";
import { extractDocumentForm, extractDocumentSetForm, isSelectedSetForm } from "./document-form.helper";
import {
  AnyDocumentForm,
  DocumentFileForm,
  DocumentForm,
  DocumentSetForm,
  FormDocument,
  FormDocumentFile,
  FormDocumentFreeFormatFile,
  FormDocumentSet,
  FreeFormatFileForm,
  SingleSetConfigForm,
} from "./document-form.model";

type RequirementField = PickPropertyKeys<DocumentRequirement, boolean>;

export interface DocumentFormBuilderOptions {
  documentMode: DocumentMode;
  isReadonly: boolean;
}

export interface DocumentFormOptions {
  unavailableFileNames?: string[];
  foreignKey?: string;
  selectedSets?: SelectedSet[];
}

export interface DocumentSetFormOptions {
  id?: string;
  isDefaultSet: boolean;
  original: boolean;
  name?: string;
  type?: DocumentSetType;
  foreignKey?: string;
}

@Injectable({ providedIn: "root" })
export class DocumentFormService {
  constructor(
    private readonly settingsService: SettingsService,
    private readonly formBuilder: FormBuilder
  ) {}

  createDocumentFormBuilder(document: Document | FormDocument, opts: DocumentFormBuilderOptions): DocumentFormBuilder {
    return new DocumentFormBuilder(this.settingsService, this.formBuilder, document, opts);
  }
}

export class DocumentFormBuilder {
  constructor(
    private readonly settingsService: SettingsService,
    private readonly formBuilder: FormBuilder,
    private readonly document: Document | FormDocument,
    private readonly opts: DocumentFormBuilderOptions
  ) {}

  createDocumentForm(options?: DocumentFormOptions): DocumentForm {
    const { unavailableFileNames, foreignKey, selectedSets } = options ?? {};

    const documentSets = getSets(this.document);
    return this.formBuilder.group<DocumentForm["controls"]>(
      {
        id: this.formBuilder.control(this.document.id),
        _etag: this.formBuilder.control(this.document._etag),
        availableForCandidates: this.formBuilder.control(this.document.availableForCandidates),
        hideCandidateNameInFileName: this.formBuilder.control(this.document.hideCandidateNameInFileName),
        throwErrorOnNoSelection: this.formBuilder.control(this.document.throwErrorOnNoSelection ?? true),
        candidateId: this.formBuilder.control(this.document.candidateId),
        changedAt: this.formBuilder.control(this.document.changedAt),
        changedBy: this.formBuilder.control(this.document.changedBy),
        comment: this.formBuilder.control(this.document.comment, this.buildRequirementValidator("comment")),
        displayName: this.formBuilder.control(
          this.document.displayName,
          this.getDisplayNameValidators(unavailableFileNames ?? [])
        ),
        documentSets: this.formBuilder.array<DocumentSetForm>(
          this.createDocumentSetForms(documentSets, foreignKey),
          Validators.compose([
            this.buildSingleDefaultSetValidator(),
            this.buildSetIntegrityValidator(),
            this.buildSetTypesMatchDocumentTypeValidator(),
            this.buildUniqueSetNameValidator(),
            this.buildUniqueSetForeignKeyValidator(),
            this.buildUniqueSetTypeValidator(),
            this.buildMinimumRequiredFilesValidator(),
          ])
        ),
        familyMemberId: this.formBuilder.control(this.document.familyMemberId),
        freeFormatFiles: this.formBuilder.array<FreeFormatFileForm>(
          this.document.freeFormatFiles?.map((file) => this.createFreeFormatFileForm(file)) ?? []
        ),
        generationMode: this.formBuilder.control(this.document.generationMode, this.getGenerationModeValidators()),
        isInternalDocument: this.formBuilder.control(this.document.isInternalDocument ?? null),
        isInternalByRequirement: this.formBuilder.control(this.document.isInternalByRequirement),
        lockState: this.formBuilder.control(this.document.lockState),
        lockTimeStamp: this.formBuilder.control(this.document.lockTimeStamp),
        lockingOrganizationId: this.formBuilder.control(this.document.lockingOrganizationId),
        completionState: this.formBuilder.control(
          this.document.completionState,
          this.buildRequirementValidator("completionState", true)
        ),
        mode: this.formBuilder.control(this.document.mode, Validators.required),
        writeMode: this.formBuilder.control(DocumentWriteMode.Metadata, Validators.required),
        requirement: this.formBuilder.control(new DocumentRequirement(), Validators.required),
        selectedSets: this.formBuilder.control(selectedSets),
        organizationId: this.formBuilder.control(this.document.organizationId, Validators.required),
        selectionCriteria: this.formBuilder.control(this.document.selectionCriteria),
        documentGenerationLanguageHandling: this.formBuilder.control(
          this.document.documentGenerationLanguageHandling ??
            DocumentGenerationLanguageHandling.ImmigrationCountryLanguagePlusNextBest
        ),
        generationStrategy: this.formBuilder.group({
          strategyId: this.document.generationStrategy?.strategyId,
          configuration: new FormControl(this.document.generationStrategy?.configuration ?? {}) as unknown as FormGroup, //This is a hack. This form group is dynamic but we do not the formly config here
        }),
        templateMode: this.formBuilder.control(
          this.document.templateMode ?? DocumentTemplateMode.Docx,
          this.getTemplateModeValidators()
        ),
        touchedOnlyByCandidate: this.formBuilder.control(this.document.touchedOnlyByCandidate ?? false),
        type: this.formBuilder.control(
          { value: this.document.type, disabled: this.opts.isReadonly },
          this.getTypeValidators()
        ),
        alwaysGenerate: this.formBuilder.control(this.document.alwaysGenerate),
      },
      { validators: [this.documentNotEmptyValidator, this.documentValidator] }
    );
  }

  createEmptySetForm(options: DocumentSetFormOptions): DocumentSetForm {
    const { id, name, type, isDefaultSet, original, foreignKey } = options;
    const set = { id: id ?? uuidv4(), isDefaultSet, name, type, foreignKey, files: [] };
    return this.createDocumentSetForm(set, original);
  }

  createDocumentSetForm(documentSet: DocumentSet | FormDocumentSet, original: boolean): DocumentSetForm {
    return this.formBuilder.group<DocumentSetForm["controls"]>({
      original: this.formBuilder.control(original, Validators.required),
      id: this.formBuilder.control(documentSet.id, Validators.required),
      isDefaultSet: this.formBuilder.control(
        { value: documentSet.isDefaultSet, disabled: this.opts.isReadonly },
        Validators.required
      ),
      name: this.formBuilder.control(
        { value: documentSet.name, disabled: this.opts.isReadonly },
        this.buildSetNameValidator()
      ),
      type: this.formBuilder.control(
        { value: documentSet.type, disabled: this.opts.isReadonly },
        this.buildSetTypeValidator()
      ),
      foreignKey: this.formBuilder.control({ value: documentSet.foreignKey, disabled: this.opts.isReadonly }),
      validFrom: this.formBuilder.control(
        { value: documentSet.validFrom, disabled: this.opts.isReadonly },
        this.buildRequirementValidator("validFrom")
      ),
      validUntil: this.formBuilder.control(
        { value: documentSet.validUntil, disabled: this.opts.isReadonly },
        this.buildRequirementValidator("validUntil")
      ),
      issueDate: this.formBuilder.control(
        { value: documentSet.issueDate, disabled: this.opts.isReadonly },
        this.buildRequirementValidator("issueDate")
      ),
      dateOfReceipt: this.formBuilder.control(
        { value: documentSet.dateOfReceipt, disabled: this.opts.isReadonly },
        this.buildRequirementValidator("dateOfReceipt")
      ),
      resubmissionDate: this.formBuilder.control(
        { value: documentSet.resubmissionDate, disabled: this.opts.isReadonly },
        this.buildRequirementValidator("resubmissionDate", true)
      ),
      resubmissionReason: this.formBuilder.control(
        { value: documentSet.resubmissionReason, disabled: this.opts.isReadonly },
        this.buildRequirementValidator("resubmissionReason", true)
      ),
      physicalTypes: this.formBuilder.control(
        { value: documentSet.physicalTypes, disabled: this.opts.isReadonly },
        this.buildRequirementValidator("physicalTypes", true)
      ),
      files: this.formBuilder.array<DocumentFileForm>(
        getSetFiles(documentSet).map((file) => this.createFileForm(file, true)) ?? [],
        isDocumentSelectionMode(this.document.mode)
          ? Validators.required
          : Validators.compose([this.buildUniqueSetTagsValidator(), this.buildRequirementValidator("files")])
      ),
    });
  }

  createFileForm(
    file: DocumentFile | FormDocumentFile | File,
    original: boolean,
    additionalTags?: string[]
  ): DocumentFileForm {
    return this.formBuilder.group<DocumentFileForm["controls"]>({
      original: this.formBuilder.control(original, Validators.required),
      blob: this.formBuilder.control(isDocumentFile(file) ? file.blob : uuidv4(), Validators.required),
      name: this.formBuilder.control(file.name, Validators.required),
      size: this.formBuilder.control(file.size, Validators.required),
      type: this.formBuilder.control(file.type, Validators.required),
      tags: this.formBuilder.control(
        [...(isDocumentFile(file) ? getTags(file) : []), ...(additionalTags ?? [])],
        !isSingleDocumentSet(this.document.type) && !isDocumentSelectionMode(this.opts.documentMode)
          ? Validators.required
          : Validators.nullValidator
      ),
      selectionValues: this.formBuilder.control(
        isDocumentFile(file) ? (file.selectionValues ?? []) : [],
        !isSingleDocumentSet && isDocumentSelectionMode(this.opts.documentMode)
          ? Validators.required
          : Validators.nullValidator
      ),
      singleSetConfig: this.createSingleSetConfigFormArray(file),
      file: new FormControl<File>(isRegularFile(file) ? file : ((file as FormDocumentFile).file ?? null)),
    });
  }

  createSingleSetConfigFormArray(file?: DocumentFile | File): FormArray<SingleSetConfigForm> {
    if (!isSingleDocumentSet(this.document.type)) {
      return null;
    }

    const singleSetConfig = isDocumentFile(file) ? file.singleSetConfig : null;
    const singleSetConfigs = singleSetConfig?.length > 0 ? singleSetConfig : [{ type: null, formats: null }];
    const formGroups = singleSetConfigs.map((config) => this.createSingleSetConfigForm(config));

    return this.formBuilder.array<SingleSetConfigForm>(formGroups, Validators.required);
  }

  createSingleSetConfigForm(config?: SingleSetConfig): SingleSetConfigForm {
    return this.formBuilder.group<SingleSetConfigForm["controls"]>({
      type: this.formBuilder.control(config?.type, Validators.required),
      formats: this.formBuilder.control(config?.formats, Validators.required),
    });
  }

  createFreeFormatFileForm(file: DocumentFreeFormatFile | FormDocumentFreeFormatFile | File): FreeFormatFileForm {
    return this.formBuilder.group<FreeFormatFileForm["controls"]>({
      blob: this.formBuilder.control((file as DocumentFreeFormatFile)?.blob ?? uuidv4(), Validators.required),
      name: this.formBuilder.control(file.name, Validators.required),
      size: this.formBuilder.control(file.size, Validators.required),
      type: this.formBuilder.control(file.type, Validators.required),
      createdAt: this.formBuilder.control((file as DocumentFreeFormatFile)?.createdAt),
      file: this.formBuilder.control(isRegularFile(file) ? file : ((file as FormDocumentFreeFormatFile).file ?? null)),
    });
  }

  private createDocumentSetForms(sets: (DocumentSet | FormDocumentSet)[], foreignKey?: string): DocumentSetForm[] {
    const types = declaredDocumentSetTypes(this.document.type);
    const name = DefaultDocumentSetName;
    if (sets.length === 0) {
      return types
        ? types.map((type, i) => this.createEmptySetForm({ isDefaultSet: i === 0, original: true, type }))
        : [this.createEmptySetForm({ isDefaultSet: true, original: false, name, foreignKey })];
    }

    const forms = sets.map((set) => this.createDocumentSetForm(set, true));
    return foreignKey && sets.every((set) => set.foreignKey && set.foreignKey !== foreignKey)
      ? [...forms, this.createEmptySetForm({ isDefaultSet: false, original: true, foreignKey })]
      : forms;
  }

  private documentNotEmptyValidator(control: AbstractControl<Document>): ValidationErrors | null {
    return tryValidateDocumentNotEmpty(control.getRawValue()) ? null : { empty: true };
  }

  private documentValidator(control: AbstractControl<Document>): ValidationErrors | null {
    return tryValidateDocument(control.getRawValue()) ? null : { document: true };
  }

  private getDisplayNameValidators(unavailableFileNames: string[]): ValidatorFn {
    switch (this.opts.documentMode) {
      case DocumentMode.Organization:
        return Validators.compose([
          Validators.required,
          Validators.maxLength(100),
          (control: AbstractControl<string>): ValidationErrors | null =>
            this.uniqueDocumentNameValidator(control, unavailableFileNames),
        ]);
      case DocumentMode.Template:
        return Validators.compose([
          Validators.required,
          Validators.maxLength(100),
          (control: AbstractControl<string>): ValidationErrors | null =>
            this.uniqueDocumentNameValidator(control, unavailableFileNames),
        ]);
      case DocumentMode.Candidate:
        return Validators.maxLength(100);
    }
  }

  private uniqueDocumentNameValidator(
    control: AbstractControl<string>,
    unavailableFileNames: string[]
  ): ValidationErrors | null {
    if (control.value && unavailableFileNames.length > 0) {
      if (unavailableFileNames.includes(control.value)) {
        return { unique: true };
      }
    }
    return null;
  }

  private buildRequirementValidator(field: RequirementField, ignoreForLoggedInCandidate = false): ValidatorFn {
    return (control: AnyDocumentForm): ValidationErrors | null => {
      if (ignoreForLoggedInCandidate && this.settingsService.isCandidate) {
        return Validators.nullValidator(control);
      }

      const documentForm = extractDocumentForm(control);
      if (!documentForm) {
        return Validators.nullValidator(control);
      }

      const requirementControl = documentForm.controls.requirement;
      if (!requirementControl) {
        return Validators.nullValidator(control);
      }

      const documentSetControl = extractDocumentSetForm(control);
      const requirement = documentForm.controls.requirement.value;
      if (!requirement) {
        return Validators.nullValidator(control);
      }

      const checkSpecificSets = requirement.checkSpecificSets;
      if (documentSetControl) {
        if (documentForm.controls.selectedSets.value && !isSelectedSetForm(documentSetControl)) {
          return Validators.nullValidator(control);
        }

        if (checkSpecificSets) {
          const requiredSetTypes = requirement.documentSetTypes;
          if (requiredSetTypes) {
            if (!requiredSetTypes.includes(documentSetControl.controls.type.value)) {
              return Validators.nullValidator(control);
            }
          } else if (!documentSetControl.controls.isDefaultSet.value) {
            return Validators.nullValidator(control);
          }
        }
      }

      return requirement[field] === true ? Validators.required(control) : Validators.nullValidator(control);
    };
  }

  private buildSingleDefaultSetValidator(): ValidatorFn {
    return (documentSetFormArray: AbstractControl): ValidationErrors | null => {
      const formArray = documentSetFormArray as FormArray<DocumentSetForm>;
      const hasBadDefault = formArray.controls.filter((set) => set.controls.isDefaultSet.value).length !== 1;
      return hasBadDefault ? { corruptDefault: true } : null;
    };
  }

  private buildSetNameValidator(): ValidatorFn {
    return (documentSetNameControl: AbstractControl): ValidationErrors | null => {
      const input = documentSetNameControl as FormControl<string>;
      return this.documentHasDeclaredSetTypes(input)
        ? this.forbiddenValidator(documentSetNameControl)
        : Validators.required(documentSetNameControl);
    };
  }

  private buildSetTypeValidator(): ValidatorFn {
    return (documentSetTypeControl: AbstractControl): ValidationErrors | null => {
      const input = documentSetTypeControl as FormControl<string>;
      return this.documentHasDeclaredSetTypes(input)
        ? Validators.required(documentSetTypeControl)
        : this.forbiddenValidator(documentSetTypeControl);
    };
  }

  private buildSetIntegrityValidator(): ValidatorFn {
    return (documentSetFormArray: AbstractControl): ValidationErrors | null => {
      const formArray = documentSetFormArray as FormArray<DocumentSetForm>;
      const hasSetTypes = formArray.controls.some((set) => set.controls.type.value);
      const hasSetNames = formArray.controls.some((set) => set.controls.name.value);
      const hasForeignKeys = formArray.controls.some((set) => set.controls.foreignKey.value);
      const isMalformed = hasSetTypes ? hasSetNames || hasForeignKeys : !hasSetNames;
      return isMalformed ? { malformed: true } : null;
    };
  }

  private buildSetTypesMatchDocumentTypeValidator(): ValidatorFn {
    return (documentSetFormArray: AbstractControl): ValidationErrors | null => {
      const formArray = documentSetFormArray as FormArray<DocumentSetForm>;
      const documentType = (formArray.parent as DocumentForm)?.controls.type.value;
      const declaredTypes = declaredDocumentSetTypes(documentType);
      if (!declaredTypes) {
        return null;
      }

      const existingTypes = formArray.controls.map((set) => set.controls.type.value);
      if (declaredTypes.length !== existingTypes.length) {
        return { setTypeMismatch: true };
      }

      if (!declaredTypes.every((declaredType) => existingTypes.includes(declaredType))) {
        return { setTypeMismatch: true };
      }

      return null;
    };
  }

  private buildUniqueSetNameValidator(): ValidatorFn {
    return (documentSetFormArray: AbstractControl): ValidationErrors | null => {
      return this.validateDocumentSetPropertyUniqueness(
        documentSetFormArray,
        (set) => set.controls.name,
        (set) => !!set.controls.foreignKey.value
      );
    };
  }

  private buildUniqueSetForeignKeyValidator(): ValidatorFn {
    return (documentSetFormArray: AbstractControl): ValidationErrors | null => {
      return this.validateDocumentSetPropertyUniqueness(documentSetFormArray, (set) => set.controls.foreignKey);
    };
  }

  private buildUniqueSetTypeValidator(): ValidatorFn {
    return (documentSetFormArray: AbstractControl): ValidationErrors | null => {
      return this.validateDocumentSetPropertyUniqueness(documentSetFormArray, (set) => set.controls.type);
    };
  }

  private buildMinimumRequiredFilesValidator(): ValidatorFn {
    return (documentSetFormArray: AbstractControl): ValidationErrors | null => {
      if (!this.settingsService.isCandidate) {
        return Validators.nullValidator(documentSetFormArray);
      }

      const formArray = documentSetFormArray as FormArray<DocumentSetForm>;
      const hasFiles = formArray.controls.some((set) => set.controls.files.length > 0);
      return hasFiles ? null : { requiredFiles: true };
    };
  }

  private buildUniqueSetTagsValidator(): ValidatorFn {
    return (fileForms: FormArray<DocumentFileForm>): ValidationErrors | null => {
      const values = fileForms.controls.flatMap((fileForm) => fileForm.controls.tags.value ?? []);
      return uniq(values).length !== values.length ? { duplicateTags: true } : null;
    };
  }

  private validateDocumentSetPropertyUniqueness(
    documentSetFormArray: AbstractControl,
    getControl: (documentSet: DocumentSetForm) => FormControl<string>,
    ignoreSet?: (documentSet: DocumentSetForm) => boolean
  ): ValidationErrors | null {
    const formArray = documentSetFormArray as FormArray<DocumentSetForm>;

    const indices = formArray.controls
      .map((currentSet) => {
        if (ignoreSet?.(currentSet)) {
          return false;
        }

        const currentValue = getControl(currentSet).value;
        if (!currentValue) {
          return false;
        }

        return formArray.controls
          .filter((set) => set !== currentSet)
          .some((set) => getControl(set).value === currentValue);
      })
      .map((isDuplicate, index) => (isDuplicate ? index : null))
      .filter((index) => index !== null);

    return indices.length > 0 ? { unique: indices } : null;
  }

  private documentHasDeclaredSetTypes(input: FormControl<string>): boolean {
    const documentSet = input?.parent as DocumentSetForm;
    const documentSetArray = documentSet?.parent as FormArray<DocumentSetForm>;
    const document = documentSetArray?.parent as DocumentForm;
    const documentType = document?.controls.type.value;
    return hasDeclaredDocumentSetTypes(documentType);
  }

  private forbiddenValidator(control: AbstractControl): ValidationErrors | null {
    return control.value ? { forbidden: true } : null;
  }

  private getTypeValidators(): ValidatorFn {
    switch (this.opts.documentMode) {
      case DocumentMode.Organization:
      case DocumentMode.Template:
        return Validators.maxLength(100);
      case DocumentMode.Candidate:
        return Validators.compose([Validators.required, Validators.maxLength(100)]);
    }
  }

  private getTemplateModeValidators(): ValidatorFn {
    return this.opts.documentMode === DocumentMode.Template ? Validators.required : Validators.nullValidator;
  }

  private getGenerationModeValidators(): ValidatorFn {
    return this.opts.documentMode === DocumentMode.Template && this.document.templateMode === DocumentTemplateMode.Xlsx
      ? Validators.required
      : Validators.nullValidator;
  }
}
