import { Injectable } from "@angular/core";
import { AvailableDocumentTemplateFragment, GetAvailableAutogenTemplatesGQL } from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class DocumentAutogenService {
  constructor(private readonly templateList: GetAvailableAutogenTemplatesGQL) {}

  get(organizationId: string): Observable<AvailableDocumentTemplateFragment[]> {
    return this.templateList.fetch({ input: { organizationId } }).pipe(map((x) => x.data.getAvailableAutogenTemplates));
  }
}
