import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { UserPermission } from "@ankaadia/ankaadia-shared";
import { SettingsService } from "../services/settings.service";

@Directive({ selector: "[appPermissions]", standalone: false })
export class PermissionDirective {
  private requiredPermissions: UserPermission[] = null;
  private viewRef: EmbeddedViewRef<any> | null = null;

  @Input()
  set appPermissions(permission: UserPermission | UserPermission[]) {
    if (permission) {
      this.requiredPermissions = Array.isArray(permission) ? permission : [permission];
    }
    this.viewRef?.destroy();
    this.viewRef = null;
    this.init();
  }

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly settings: SettingsService
  ) {}

  init(): void {
    const isPermitted = this.settings.hasAnyPermission(this.requiredPermissions);
    if (isPermitted) {
      this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
