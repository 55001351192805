import { Component } from "@angular/core";
import { CollectionTransferResult } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
@Component({
  selector: "app-collection-transfer-result",
  templateUrl: "./collection-transfer-result.component.html",
  styleUrl: "./collection-transfer-result.component.scss",
  standalone: false,
})
export class CollectionTransferResultComponent {
  protected result: CollectionTransferResult;

  get size(): string {
    return this.result.transferredCandidatesDisplayIds?.length > 0 &&
      this.result.notTransferredCandidatesDisplayIds?.length > 0
      ? "col-6"
      : "col-12";
  }

  constructor(
    protected readonly dialogRef: DynamicDialogRef,
    private readonly config: DynamicDialogConfig,
    private readonly trans: TranslocoService
  ) {
    this.config.height = "50vh";
    this.config.width = "50vw";
    this.config.header = this.trans.translate("collection.transfer.result");
    this.config.closeOnEscape = true;
    this.config.showHeader = true;
    this.result = this.config.data;
  }

  okClicked(): void {
    this.dialogRef.close();
  }
}
