import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import {
  EducationCourseForViewFragment,
  EducationCoursePerformanceForListFragment,
  EducationCourseTotalPerformanceStateUpdateInput,
  EducationCourseWeeklyAttendanceUpdateInput,
  EducationCourseWeeklyCommentUpdateInput,
  EducationCourseWeeklyPerformanceUpdateInput,
  EducationCourseWeekPrefillInput,
} from "@ankaadia/graphql";

@Component({
  selector: "app-education-course-view",
  templateUrl: "./education-course-view.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class EducationCourseViewComponent {
  @Input()
  course: EducationCourseForViewFragment;

  @Input()
  performance: EducationCoursePerformanceForListFragment;

  @Output()
  readonly edited = new EventEmitter<EducationCourseForViewFragment>();

  @Output()
  readonly deleted = new EventEmitter<[EducationCourseForViewFragment, Event]>();

  @Output()
  readonly candidateStateUpdated = new EventEmitter<EducationCourseTotalPerformanceStateUpdateInput>();

  @Output()
  readonly attendanceUpdated = new EventEmitter<EducationCourseWeeklyAttendanceUpdateInput>();

  @Output()
  readonly performanceUpdated = new EventEmitter<EducationCourseWeeklyPerformanceUpdateInput>();

  @Output()
  readonly commentUpdated = new EventEmitter<EducationCourseWeeklyCommentUpdateInput>();

  @Output()
  readonly weekPrefilled = new EventEmitter<EducationCourseWeekPrefillInput>();
}
