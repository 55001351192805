<app-table
  [columns]="columns"
  [items]="assignedCandidates"
  [captionOperations]="captionOperations"
  [(showSidebar)]="showDialog"
  [sidebarSize]="selectedImport ? 'medium' : 'full'"
  [numberOfRows]="20"
  *appTranslate="let t"
  data-testid="table-app-assigned-candidates"
>
  <app-collection-edit-assigned-candidates
    *ngIf="showDialog && !selectedImport"
    (closed)="closeSidebar($event)"
    [collection]="collection"
    [targetCandidateList]="assignedCandidatesForEdit"
    [sourceCandidateList]="allCandidates"
    [sourceCandidateCount]="allCandidatesCount"
    [candidatesPerPage]="candidatesPerPage"
    [caption]="t('candidates.assign')"
    (sourceUpdate)="updateSource($event)"
  ></app-collection-edit-assigned-candidates>

  <app-candidate-import
    *ngIf="showDialog && selectedImport"
    [import]="selectedImport"
    (saved)="import($event)"
    (closed)="justCloseSidebar()"
  ></app-candidate-import>
</app-table>

<app-import-result-dialog
  *ngIf="xlsxImportResult"
  [xlsxImportResult]="xlsxImportResult"
  (closed)="xlsxImportResult = null"
>
</app-import-result-dialog>
