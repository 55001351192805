<div class="flex">
  <div class="author" [class.p-disabled]="readonly">
    {{ comment.author }}
  </div>

  <div class="p-inplace">
    <div class="p-inplace-display" *ngIf="!editing || readonly" [class.p-disabled]="readonly">
      <div class="comment">
        {{ comment.comment }}
      </div>
    </div>
    <div class="p-display-content" *ngIf="editing && !readonly">
      <div class="flex">
        <textarea
          #box
          commentBox
          [autofocus]="true"
          class="comment-box noresize"
          rows="1"
          pInputTextarea
          [value]="comment.comment"
        ></textarea>
        <button
          type="button"
          icon="pi pi-play"
          class="p-button-outlined align-self-end"
          pButton
          (click)="updateComment(comment, box.value)"
        ></button>
      </div>
    </div>
  </div>
</div>
