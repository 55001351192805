import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Collection, CollectionCreateInput, CollectionType, CollectionUpdateInput } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { clone } from "lodash";
import { finalize } from "rxjs";
import { MessageService } from "../../message/message.service";
import { CollectionService } from "../collection.service";

@Component({
  selector: "app-collection-edit-dialog",
  templateUrl: "./collection-edit-dialog.component.html",
  standalone: false,
})
export class CollectionEditDialogComponent implements OnChanges {
  disableSave: boolean;
  @Output() readonly closed = new EventEmitter<Collection>();
  @Input() collection: Collection | CollectionUpdateInput | CollectionCreateInput;
  @Input() isEdit: boolean;

  form: FormGroup = new FormGroup({
    name: new FormControl(),
    description: new FormControl(),
    type: new FormControl(),
    targetSize: new FormControl(),
    organizationId: new FormControl(),
  });

  constructor(
    private readonly collService: CollectionService,
    private readonly messageService: MessageService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  get header(): string {
    switch (this.collection.type) {
      case CollectionType.Auto:
        return this.isEdit ? translate("autoCollection.edit") : translate("autoCollection.new");
      case CollectionType.Standard:
      default:
        return this.isEdit ? translate("collection.edit") : translate("collection.new");
    }
  }

  ngOnChanges(): void {
    this.disableSave = false;
    if (this.collection != null) {
      this.form.patchValue(this.collection);
    } else {
      this.form.reset();
    }
    this.changeDetector.detectChanges();
  }

  cancel(): void {
    this.closed.emit(null);
  }

  save(): void {
    this.disableSave = true;
    if (!this.isEdit) {
      const createInput = this.form.value as CollectionCreateInput;
      this.collService
        .createCollection(createInput)
        .pipe(finalize(() => (this.disableSave = false)))
        .subscribe((col) => {
          this.closed.emit(col as Collection);
          this.messageService.add({
            severity: "success",
            summary: translate("collection.created.title"),
            detail: translate("collection.created.message", col),
          });
        });
    } else {
      const updateCollection = clone(this.collection);
      updateCollection.name = this.form.value.name;
      updateCollection.description = this.form.value.description;
      updateCollection.targetSize = this.form.value.targetSize;
      const updateInput = updateCollection as CollectionUpdateInput;
      this.collService
        .updateCollection(updateInput)
        .pipe(finalize(() => (this.disableSave = false)))
        .subscribe((col) => {
          this.closed.emit(col);
          this.messageService.add({
            severity: "success",
            summary: translate("collection.updated.title"),
            detail: translate("collection.updated.message", col),
          });
        });
    }
  }
}
