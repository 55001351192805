import { Injectable } from "@angular/core";
import {
  CandidateEvent,
  CandidateEventsInput,
  CourseEventsInput,
  GetCandidateEventsGQL,
  GetCollectionEventsGQL,
  GetCourseEventsGQL,
  GetOrganizationEventsGQL,
  GetSharedCollectionEventsGQL,
  OrganizationEventsInput,
  SharedCollectionEventsInput,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class CandidateEventService {
  constructor(
    private readonly getGql: GetCandidateEventsGQL,
    private readonly getAllGql: GetOrganizationEventsGQL,
    private readonly getCollectionEventsGql: GetCollectionEventsGQL,
    private readonly getSharedEventsGql: GetSharedCollectionEventsGQL,
    private readonly getForCourseGql: GetCourseEventsGQL
  ) {}

  get(input: CandidateEventsInput): Observable<CandidateEvent[]> {
    return this.getGql.fetch({ input: input }).pipe(map((result) => result.data.getCandidateEvents));
  }

  getAll(input: OrganizationEventsInput): Observable<CandidateEvent[]> {
    return this.getAllGql.fetch({ input: input }).pipe(map((result) => result.data.getOrganizationEvents));
  }

  getAllForCollection(input: SharedCollectionEventsInput): Observable<CandidateEvent[]> {
    return input.sharingId
      ? this.getSharedEventsGql.fetch({ input: input }).pipe(map((result) => result.data.getSharedCollectionEvents))
      : this.getCollectionEventsGql.fetch({ input: input }).pipe(map((result) => result.data.getCollectionEvents));
  }

  getForCourse(input: CourseEventsInput): Observable<CandidateEvent[]> {
    return this.getForCourseGql.fetch({ input: input }).pipe(map((x) => x.data.getCourseEvents));
  }
}
