import { Pipe, PipeTransform } from "@angular/core";
import { declaredDocumentSetTypes } from "@ankaadia/ankaadia-shared";
import { StaticDataModel } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";

/**
 * Why? Because binding to function results in templates is one of the dumbest things to do.
 * Not only it's bad for performance, but you're also just asking for the funny situation when
 * clicking on an element "does not work" because the element is rendered anew as a result of clicking.
 *
 * An easy solution is extracting such function to a pure pipe. However, this is, obviously, not the only solution.
 *
 * TL;DR:
 *
 * ```html
 * <!-- bad, re-renders on e_v_e_r_y_t_h_i_n_g -->
 * <something [options]="giveOptions(parameter)">
 *
 * <!-- good, re-renders only when parameter changes -->
 * <something [options]="parameter | options">
 * ```
 */
@Pipe({ name: "documentSets", pure: true, standalone: false })
export class DocumentSetsPipe implements PipeTransform {
  transform(documentType: string): StaticDataModel[] {
    const declatedTypes = declaredDocumentSetTypes(documentType) ?? [];
    return declatedTypes.map((setType) => ({ value: setType, label: translate(`documentSet.types.${setType}`) }));
  }
}
