import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { QualificationMeasureCandidateDataDe, StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { StaticDataService } from "../../../shared/static-data/static-data.service";

const nameOf = nameofFactory<QualificationMeasureCandidateDataDe>();

@Component({
  selector: "app-candidate-qualification-type",
  templateUrl: "./candidate-qualification-type.component.html",
  standalone: false,
})
export class CandidateQualificationTypeComponent implements OnInit, OnDestroy {
  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService
  ) {}

  readonly language = this.transloco.getActiveLang();

  qualificationTypes: StaticDataModel[];

  @Input()
  processLanguage?: string = this.language;

  @Input()
  form: FormGroup;

  @Input()
  readonly: boolean;

  get dropdownControl(): FormControl<string | null> {
    return this.form.get(nameOf("qualificationType")) as FormControl<string | null>;
  }

  set dropdownControl(value: FormControl<string | null>) {
    this.form.setControl(nameOf("qualificationType"), value);
  }

  ngOnInit(): void {
    this.staticDataService.getStaticData(StaticDataType.QualificationType).subscribe((data) => {
      this.qualificationTypes = data;
    });
    if (!this.dropdownControl) this.dropdownControl = new FormControl<string | null>(null);
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("qualificationType"), { emitEvent: false });
  }
}
