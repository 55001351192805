import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { CandidateTagFragment } from "@ankaadia/graphql";
import { CandidateTagColors } from "../../organizations/candidate-tag-selector/candidate-tag-selector.model";
import { CandidateSpecialTagService } from "./candidate-special-tag.service";

/**
 * Used for rendering custom tags outside of the main tag area.
 * For example, when rendering a tag that has long content.
 */
@Component({
  selector: "app-candidate-special-tag",
  templateUrl: "./candidate-special-tag.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CandidateSpecialTagComponent implements OnChanges {
  readonly CandidateTagColors = CandidateTagColors;

  @Input()
  customTags: CandidateTagFragment[];

  @Input()
  variable: string;

  @Input()
  isEditing: boolean;

  constructor(private readonly specialTagService: CandidateSpecialTagService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.variable) {
      this.specialTagService.remove(changes.variable.previousValue);
      this.specialTagService.add(changes.variable.currentValue);
    }
  }
}
