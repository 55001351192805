import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Validators } from "@angular/forms";
import {
  ContactSharingMode,
  ContractTemplateSharingMode,
  EzbDataSharingMode,
  OrganizationForSelectionFragment,
  OrganizationLinkCreateInput,
  OrganizationLinkFragment,
  OrganizationLinkUpdateInput,
  PartnerSharingMode,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { Subscription, finalize } from "rxjs";
import { emitEvents } from "../../../shared/services/form.helper";
import { SettingsService } from "../../../shared/services/settings.service";
import { ContractTemplateService } from "../../contract-template/contract-template.service";
import { MessageService } from "../../message/message.service";
import { OrganizationContactsService } from "../../organization-contacts/organization-contacts.service";
import { OrganizationLinkService } from "../organization-link.service";
import { OrganizationsService } from "../organizations.service";
import { OrganizationLinkFormService } from "./organization-link-form.service";

@Component({
  selector: "app-organization-link-dialog",
  templateUrl: "./organization-link-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OrganizationLinkDialogComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  readonly form = this.formService.createOrganizationLinkGroup();
  readonly contractTemplates$ = this.contractTemplateService.getForSelection(this.settings.organizationId, null);
  readonly partners$ = this.organizationService.getOrganizationsLinkedTo(this.settings.organizationId);
  readonly contacts$ = this.contactService.getForSelectionInclName(this.settings.organizationId);
  readonly ContactSharingMode = ContactSharingMode;
  readonly ContractTemplateSharingMode = ContractTemplateSharingMode;
  readonly EZBDataSharingMode = EzbDataSharingMode;
  readonly PartnerSharingMode = PartnerSharingMode;

  @Input()
  set link(link: OrganizationLinkFragment) {
    this.form.reset(undefined, { emitEvent: false });
    this.form.patchValue(link, { emitEvent: false });

    this.form.controls.selectedPartners.clear({ emitEvent: false });
    for (const partner of link.selectedPartners ?? []) {
      const partnerForm = this.formService.createSelectedPartnerGroup(partner);
      this.form.controls.selectedPartners.push(partnerForm, { emitEvent: false });
    }

    emitEvents(this.form);

    this.organizationService.getLinkableOrganizations(link.organizationId, link.linkedOrganizationId).subscribe((x) => {
      this.organizations = x;
      this.changeDetector.detectChanges();
    });
  }

  @Output()
  readonly created = new EventEmitter<void>();

  @Output()
  readonly closed = new EventEmitter<boolean>();

  organizations: OrganizationForSelectionFragment[];
  isBusy: boolean;

  constructor(
    private readonly organizationService: OrganizationsService,
    private readonly contactService: OrganizationContactsService,
    private readonly contractTemplateService: ContractTemplateService,
    private readonly settings: SettingsService,
    private readonly linkService: OrganizationLinkService,
    private readonly formService: OrganizationLinkFormService,
    private readonly messageService: MessageService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.form.controls.shareContacts.valueChanges.subscribe((x) => this.onShareContactModeChange(x))
    );

    this.subscriptions.push(
      this.form.controls.shareContractTemplates.valueChanges.subscribe((x) => this.onShareContractTemplateModeChange(x))
    );

    this.subscriptions.push(
      this.form.controls.sharePartners.valueChanges.subscribe((x) => this.onSharePartnerModeChange(x))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  submit(): void {
    this.isBusy = true;
    const link = this.form.value as OrganizationLinkCreateInput & OrganizationLinkUpdateInput;
    if (link.id) {
      this.linkService
        .update(link)
        .pipe(finalize(() => (this.isBusy = false)))
        .subscribe(() => {
          const name = this.organizations.find((x) => x.id === link.linkedOrganizationId)?.name;
          this.messageService.add({
            severity: "success",
            summary: translate("organization.linkUpdated.title"),
            detail: translate("organization.linkUpdated.message", { name: name }),
          });
          this.closed.emit(true);
        });
    } else {
      delete link.id;
      delete link._etag;
      this.linkService
        .create(link)
        .pipe(finalize(() => (this.isBusy = false)))
        .subscribe(() => {
          const name = this.organizations.find((x) => x.id === link.linkedOrganizationId)?.name;
          this.messageService.add({
            severity: "success",
            summary: translate("organization.linked.title"),
            detail: translate("organization.linked.message", { name: name }),
          });

          this.created.emit();
          this.closed.emit(true);
        });
    }
  }

  close(): void {
    this.closed.emit(false);
  }

  addSelectedPartner(): void {
    this.form.controls.selectedPartners.push(this.formService.createSelectedPartnerGroup());
    this.form.controls.selectedPartners.markAsDirty();
  }

  removeSelectedPartner(index: number): void {
    this.form.controls.selectedPartners.removeAt(index);
    this.form.controls.selectedPartners.markAsDirty();
  }

  private onShareContactModeChange(shareContacts: ContactSharingMode): void {
    this.form.controls.selectedContacts.reset();

    if (shareContacts === ContactSharingMode.ShareSelected) {
      this.form.controls.selectedContacts.addValidators(Validators.required);
    } else {
      this.form.controls.selectedContacts.removeValidators(Validators.required);
    }
    this.form.controls.selectedContacts.updateValueAndValidity();
  }

  private onShareContractTemplateModeChange(shareContractTemplates: ContractTemplateSharingMode): void {
    this.form.controls.selectedContractTemplates.reset();

    if (shareContractTemplates === ContractTemplateSharingMode.ShareSelected) {
      this.form.controls.selectedContractTemplates.addValidators(Validators.required);
    } else {
      this.form.controls.selectedContractTemplates.removeValidators(Validators.required);
    }
    this.form.controls.selectedContractTemplates.updateValueAndValidity();
  }

  private onSharePartnerModeChange(sharePartners: PartnerSharingMode): void {
    this.form.controls.selectedPartners.clear();
    this.form.controls.partnerSharing.reset();

    if (sharePartners === PartnerSharingMode.ShareSelected) {
      this.addSelectedPartner();
    }

    if (sharePartners === PartnerSharingMode.ShareSelected) {
      this.form.controls.selectedPartners.addValidators(Validators.required);
    } else {
      this.form.controls.selectedPartners.removeValidators(Validators.required);
    }
    this.form.controls.selectedPartners.updateValueAndValidity();
  }
}
