import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { TalentPoolSharingDialogComponent } from "./talent-pool-sharing-dialog/talent-pool-sharing-dialog.component";
import { TalentPoolSharingsComponent } from "./talent-pool-sharings.component";

@NgModule({
  imports: [SharedModule],
  declarations: [TalentPoolSharingsComponent, TalentPoolSharingDialogComponent],
  exports: [TalentPoolSharingsComponent],
})
export class TalentPoolSharingModule {}
