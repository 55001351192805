import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { TranslocoService } from "@jsverse/transloco";
import { fieldMapping } from "../../process-task/enter-missing-information/enter-missing-information-act/enter-missing-information-act.model";

@Component({
  selector: "app-candidate-employer-work-contract-sign-date",
  templateUrl: "./candidate-employer-work-contract-sign-date.component.html",
  standalone: false,
})
export class CandidateEmployerWorkContractSignDateComponent implements OnInit, OnDestroy {
  private readonly language = this.transloco.getActiveLang();

  @Input({ required: true })
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  readonly: boolean;

  @Output()
  readonly valueChanged = new EventEmitter<Date>();

  get control(): FormControl<Date> {
    return this.form.get(fieldMapping.WorkContractSignDate) as FormControl<Date>;
  }

  set control(value: FormControl<Date>) {
    this.form.setControl(fieldMapping.WorkContractSignDate, value);
  }

  constructor(private readonly transloco: TranslocoService) {}

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<Date>(undefined);
    this.control.valueChanges.subscribe((value) => this.valueChanged.emit(value));
  }

  ngOnDestroy(): void {
    this.form.removeControl(fieldMapping.WorkContractSignDate, { emitEvent: false });
  }
}
