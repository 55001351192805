import {
  CandidateDataSourceOption,
  CustomCandidateField,
  CustomCandidateMatch,
  ScalarProperty,
} from "@ankaadia/ankaadia-shared";
import { Candidate, PresetFragment, StaticDataModel, SupportedImmigrationCountry } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { Observable } from "rxjs";
import { FilterMetadataMap } from "../collections/collection-edit-assigned-candidates/custom-filter.model";

export type ExistingImmigrationCountry = Exclude<SupportedImmigrationCountry, SupportedImmigrationCountry.Unknown>;
export const ExistingImmigrationCountries: ExistingImmigrationCountry[] = [
  SupportedImmigrationCountry.De,
  SupportedImmigrationCountry.At,
];

export type FilterPreset = Omit<PresetFragment, "filters"> & { filters: FilterMetadataMap };

export interface CandidateFilterGroup extends Pick<StaticDataModel, "label"> {
  country?: ExistingImmigrationCountry;
  fields: CandidateFilterField[];
  dataSources?: CandidateDataSourceOption[];
}

export interface CandidateFilterField extends Omit<StaticDataModel, "value"> {
  country?: ExistingImmigrationCountry;
  conditions: CandidateFilter[];
  value: ScalarProperty<Candidate> | CustomCandidateField;
  dataSources?: CandidateDataSourceOption[];
}

export class StaticDataWithCountry extends StaticDataModel {
  country?: ExistingImmigrationCountry;
}

export class StaticDataCountry {
  value: ExistingImmigrationCountry;
  label: string;
}

export class StaticDataCountryGroup extends StaticDataCountry {
  items: StaticDataModel[];
}

export interface StaticDataModelGroup {
  label: string;
  items: StaticDataModel[];
}

export type CandidateFilterConditionOptions = StaticDataModel[] | (StaticDataCountryGroup | StaticDataModelGroup)[];

export interface CandidateFilterBase extends Omit<StaticDataModel, "value"> {
  value: CustomCandidateMatch;
}

const AllCandidateFilterConditionParameters = ["text", "number", "date", "options", "profession"];
export type CandidateFilterConditionParameter = (typeof AllCandidateFilterConditionParameters)[number];

export interface CandidateFilterCondition extends CandidateFilterBase {
  parameter?: CandidateFilterConditionParameter;
  options?: Observable<CandidateFilterConditionOptions>;
}

export interface CandidateAutocompleteCondition extends CandidateFilterBase {
  parameter?: "autocomplete";
  options?: AutocompleteOptions;
}

export interface CandidateLazyChoiceCondition extends CandidateFilterBase {
  parameter?: "lazyChoice";
  options?: LazyChoiceOptions;
}

export type CandidateFilter = CandidateFilterCondition | CandidateAutocompleteCondition | CandidateLazyChoiceCondition;

export type CandidateFilterOptions =
  | Observable<CandidateFilterConditionOptions>
  | AutocompleteOptions
  | LazyChoiceOptions;

export function isCandidateFilterCondition(
  candidateFilter: CandidateFilter
): candidateFilter is CandidateFilterCondition {
  return AllCandidateFilterConditionParameters.includes(candidateFilter?.parameter);
}

export interface CandidateFilterFieldMeta {
  conditions: CandidateFilter[];
  parameter: CandidateFilter["parameter"];
  options: CandidateFilterOptions;
  groupName: string;
  dataSource: CandidateDataSourceOption[];
}

export interface AutocompleteOptions {
  getSuggestions: (query) => Observable<StaticDataModel[]>;
  getFirstSuggestion: (query) => Observable<StaticDataModel[]>;
}

export interface LazyChoiceOptions {
  getFilteredOptions: (query: string) => Observable<StaticDataModel[]>;
  getOptionsByValue: (values: string[]) => Observable<StaticDataModel[]>;
}

export function candidateConditionFactory(
  type: "choice" | "multichoice" | "document" | "profession" | "chips"
): (options: Observable<StaticDataModel[] | StaticDataCountryGroup[]>) => CandidateFilterCondition[];
export function candidateConditionFactory(
  type: "hasEntries" | "number" | "text" | "date" | "datearray" | "boolean" | "exists"
): CandidateFilterCondition[];
export function candidateConditionFactory(
  type: "autocomplete"
): (options: AutocompleteOptions) => CandidateAutocompleteCondition[];
export function candidateConditionFactory(
  type: "lazyChoice"
): (options: LazyChoiceOptions) => CandidateLazyChoiceCondition[];
export function candidateConditionFactory(
  type:
    | "hasEntries"
    | "choice"
    | "multichoice"
    | "number"
    | "text"
    | "date"
    | "datearray"
    | "boolean"
    | "exists"
    | "document"
    | "profession"
    | "autocomplete"
    | "lazyChoice"
    | "chips"
): any {
  switch (type) {
    case "hasEntries":
      return [
        { label: translate("filter.yes"), value: "collectionHasEntries" },
        { label: translate("filter.no"), value: "collectionHasNoEntries" },
      ];
    case "choice":
      return (options: Observable<StaticDataModel[] | StaticDataCountryGroup[]>) => [
        { label: translate("filter.empty"), value: "empty" },
        { label: translate("filter.notEmpty"), value: "notEmpty" },
        { label: translate("filter.containsElement"), value: "elementEquals", parameter: "options", options: options },
        {
          label: translate("filter.notContainsElement"),
          value: "elementNotEquals",
          parameter: "options",
          options: options,
        },
      ];
    case "multichoice":
      return (options: Observable<StaticDataModel[] | StaticDataCountryGroup[]>) => [
        { label: translate("filter.empty"), value: "empty" },
        { label: translate("filter.notEmpty"), value: "notEmpty" },
        { label: translate("filter.containsList"), value: "arrayEquals", parameter: "options", options: options },
        { label: translate("filter.notContainsList"), value: "arrayNotEquals", parameter: "options", options: options },
        { label: translate("filter.containsElement"), value: "arrayContains", parameter: "options", options: options },
        {
          label: translate("filter.notContainsElement"),
          value: "arrayNotContains",
          parameter: "options",
          options: options,
        },
      ];
    case "number":
      return [
        { label: translate("filter.exists"), value: "exists" },
        { label: translate("filter.notExists"), value: "notExists" },
        { label: translate("filter.contains"), value: "equals", parameter: "number" },
        { label: translate("filter.notContains"), value: "notEquals", parameter: "number" },
        { label: translate("filter.lessThan"), value: "lessThan", parameter: "number" },
        { label: translate("filter.moreThan"), value: "moreThan", parameter: "number" },
        { label: translate("filter.lessThanOrEquals"), value: "lessThanOrEquals", parameter: "number" },
        { label: translate("filter.moreThanOrEquals"), value: "moreThanOrEquals", parameter: "number" },
      ];
    case "text":
      return [
        { label: translate("filter.empty"), value: "stringEmpty" },
        { label: translate("filter.notEmpty"), value: "stringNotEmpty" },
        { label: translate("filter.containsEqualString"), value: "stringEquals", parameter: "text" },
        { label: translate("filter.notContainsEqualString"), value: "stringNotEquals", parameter: "text" },
        { label: translate("filter.containsString"), value: "stringContains", parameter: "text" },
        { label: translate("filter.notContainsString"), value: "stringNotContains", parameter: "text" },
        { label: translate("filter.startsWith"), value: "startsWith", parameter: "text" },
        { label: translate("filter.endsWith"), value: "endsWith", parameter: "text" },
      ];
    case "date":
      return [
        { label: translate("filter.exists"), value: "exists" },
        { label: translate("filter.notExists"), value: "notExists" },
        { label: translate("filter.contains"), value: "equals", parameter: "date" },
        { label: translate("filter.notContains"), value: "notEquals", parameter: "date" },
        { label: translate("filter.lessThan"), value: "lessThan", parameter: "date" },
        { label: translate("filter.moreThan"), value: "moreThan", parameter: "date" },
        { label: translate("filter.lessThanOrEquals"), value: "lessThanOrEquals", parameter: "date" },
        { label: translate("filter.moreThanOrEquals"), value: "moreThanOrEquals", parameter: "date" },
      ];
    case "datearray":
      return [
        { label: translate("filter.empty"), value: "empty" },
        { label: translate("filter.notEmpty"), value: "notEmpty" },
        { label: translate("filter.contains"), value: "dateEqualsToElement", parameter: "date" },
        { label: translate("filter.notContains"), value: "dateNotEqualsToElement", parameter: "date" },
        { label: translate("filter.lessThan"), value: "lessThanElement", parameter: "date" },
        { label: translate("filter.moreThan"), value: "moreThanElement", parameter: "date" },
        { label: translate("filter.lessThanOrEquals"), value: "lessThanOrEqualsToElement", parameter: "date" },
        { label: translate("filter.moreThanOrEquals"), value: "moreThanOrEqualsToElement", parameter: "date" },
      ];
    case "boolean":
      return [
        { label: translate("filter.selected"), value: "true" },
        { label: translate("filter.notSelected"), value: "notTrue" },
      ];
    case "exists": {
      return [
        { label: translate("filter.exists"), value: "exists" },
        { label: translate("filter.notExists"), value: "notExists" },
        { label: translate("filter.existsWithFiles"), value: "existsWithContent" },
        { label: translate("filter.notExistsWithFiles"), value: "notExistsWithContent" },
      ];
    }
    case "document": {
      return (options) => [
        {
          label: translate("filter.containsElement"),
          value: "elementEquals",
          parameter: "options",
          options: options,
        },
        {
          label: translate("filter.notContainsElement"),
          value: "elementNotEquals",
          parameter: "options",
          options: options,
        },
        {
          label: translate("filter.hasFilesAndContainsElement"),
          value: "hasContentAndElementEquals",
          parameter: "options",
          options: options,
        },
        {
          label: translate("filter.hasNoFilesAndContainsElement"),
          value: "hasNoContentAndElementEquals",
          parameter: "options",
          options: options,
        },
      ];
    }

    case "profession":
      return (options: Observable<StaticDataModel[] | StaticDataCountryGroup[]>) => [
        { label: translate("filter.empty"), value: "empty" },
        { label: translate("filter.notEmpty"), value: "notEmpty" },
        { label: translate("filter.contains"), value: "elementEquals", parameter: "profession", options: options },
        {
          label: translate("filter.notContains"),
          value: "elementNotEquals",
          parameter: "profession",
          options: options,
        },
      ];
    case "chips": {
      return (options: Observable<StaticDataModel[] | StaticDataCountryGroup[]>) => [
        { label: translate("filter.empty"), value: "empty" },
        { label: translate("filter.notEmpty"), value: "notEmpty" },
        { label: translate("filter.contains"), value: "elementEquals", parameter: "options", options: options },
        {
          label: translate("filter.notContains"),
          value: "elementNotEquals",
          parameter: "options",
          options: options,
        },
      ];
    }
    case "autocomplete": {
      return (options: (query: any) => Observable<StaticDataModel[]>) => [
        { label: translate("filter.empty"), value: "empty" },
        { label: translate("filter.notEmpty"), value: "notEmpty" },
        { label: translate("filter.contains"), value: "stringContains", parameter: "autocomplete", options: options },
        {
          label: translate("filter.notContains"),
          value: "stringNotContains",
          parameter: "autocomplete",
          options: options,
        },
      ];
    }
    case "lazyChoice": {
      return (options: (query: any) => Observable<StaticDataModel[]>) => [
        { label: translate("filter.empty"), value: "empty" },
        { label: translate("filter.notEmpty"), value: "notEmpty" },
        {
          label: translate("filter.containsElement"),
          value: "elementEquals",
          parameter: "lazyChoice",
          options: options,
        },
        {
          label: translate("filter.notContainsElement"),
          value: "elementNotEquals",
          parameter: "lazyChoice",
          options: options,
        },
      ];
    }
  }
}
