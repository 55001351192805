import {
  CompensationType,
  ContractTermTimePeriod,
  ILaborContractAllowance,
  ILaborContractBenefit,
} from "./contract-template.model";

export interface ILaborAgreementModel {
  id?: string;
  organizationId?: string;
  name?: string;
  type?: string;
  country?: string;
  institutionA?: string;
  institutionB?: string;
  federalStates?: string[];
  probationPeriod?: number;
  probationPeriodUnit?: ContractTermTimePeriod;
  noticePeriod?: number;
  noticePeriodUnit?: ContractTermTimePeriod;
  payGrades?: ILaborAgreementPayGrade[];
  allowances?: ILaborContractAllowance[];
  benefits?: ILaborContractBenefit[];
  validFrom?: Date;
  validUntil?: Date;
  changedAt?: Date;
  changedBy?: string;
}

export interface ILaborAgreementPayGrade {
  id?: string;
  salaryGroup?: string;
  salaryStep?: string;
  compensationType?: CompensationType;
  compensationRate?: number;
  workingHoursPerWeek?: number;
}

export interface ILaborAgreementId {
  laborAgreementId?: string;
  organizationId?: string;
}
