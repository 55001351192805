import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import {
  CollectionAutoTemplateVariableEnum,
  getCollectionAutoTemplateVariableValue,
  isCollectionAutoTemplateVariable,
} from "@ankaadia/ankaadia-shared";
import { SharingTemplate, SharingTemplateCreateInput, SharingTemplateUpdateInput } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { clone } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { BooleanPipe } from "../../../shared/pipes/boolean.pipe";
import { AppDatePipe } from "../../../shared/pipes/date.pipe";
import { EnumPipe } from "../../../shared/pipes/enum.pipe";
import { PipeDescription, TableColumn, TableOperation } from "../../../shared/table/table.model";
import { MessageService } from "../../message/message.service";
import { CloseSharingFormArguments } from "../sharing-edit-dialog/sharing-edit-dialog.component";
import { GroupedSelectableOrganization } from "../sharing-edit/sharing-form.model";
import { SharingOrTemplate, SharingTemplatesService } from "../sharing-templates.service";
import { SharingTemplateProvider } from "./sharings-template-provider";

@Component({
  selector: "app-sharing-templates-form",
  templateUrl: "./sharing-templates-form.component.html",
  standalone: false,
})
export class SharingTemplatesFromComponent implements OnChanges {
  readonly captionOperations: TableOperation[] = [
    {
      label: translate("common.new"),
      icon: PrimeIcons.PLUS,
      operation: (): void => this.newSharingTemplate(),
    },
  ];

  readonly tableOperations: TableOperation[] = [
    {
      label: translate("common.edit"),
      icon: PrimeIcons.PENCIL,
      operation: (x: SharingTemplate): void => this.editSharingTemplate(x),
    },
    {
      label: translate("common.delete"),
      icon: PrimeIcons.TRASH,
      operation: (x: SharingTemplate, e: Event): void => this.deleteSharingTemplate(x, e),
    },
  ];

  readonly columns: TableColumn[] = [
    {
      header: translate("organization.title"),
      fieldname: "destinationOrganizationIdOrVariableName",
      sortable: true,
      includeInGlobalFilter: true,
      icon: (template: SharingTemplate): string | null =>
        !this.isSharingAllowed(template) ? PrimeIcons.EXCLAMATION_TRIANGLE : null,
      tooltip: (template: SharingTemplate): string =>
        !this.isSharingAllowed(template)
          ? translate("sharing.variableIsNotAllowed", {
              variable: template.destinationOrganizationIdOrVariable,
            })
          : null,
      className: (template: SharingTemplate): string | null => (!this.isSharingAllowed(template) ? "p-error" : null),
      forceDisplayField: true,
    },
    {
      header: translate("type.title"),
      fieldname: "sharingType",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(EnumPipe, "SharingTypeEnum"),
    },
    {
      header: translate("enabled.title"),
      fieldname: "enabled",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(BooleanPipe),
    },
    {
      header: translate("sharing.expiryDate"),
      fieldname: "expiryDate",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(AppDatePipe, { dateStyle: "short" }),
    },
  ];

  @Input()
  sharingTemplateProvider: SharingTemplateProvider;

  @Input()
  showManageCollectionMembers: boolean;

  @Input()
  additionalOrganizations: GroupedSelectableOrganization[];

  @Input()
  allowedVariables: string[];

  @Output()
  readonly autoSyncTriggered = new EventEmitter<void>();

  sharingTemplates: SharingTemplate[];
  sharingTemplateToEdit: SharingTemplate;
  showDialog = false;

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly sharingTemplatesService: SharingTemplatesService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sharingTemplateProvider) {
      if (this.sharingTemplateProvider != null) {
        this.sharingTemplates = clone(this.sharingTemplateProvider.sharingTemplates);
      } else {
        this.sharingTemplates = null;
      }
    }
  }

  closeSidebar(sharingsOrTemplates: SharingOrTemplate[]): void {
    const sharingTemplates = sharingsOrTemplates as SharingTemplate[];
    if (sharingTemplates != null) {
      if (this.sharingTemplateToEdit == null) {
        this.sharingTemplates = this.sharingTemplates.concat(...sharingTemplates);
      } else {
        for (const sharing of sharingTemplates) {
          this.sharingTemplates[this.sharingTemplates.findIndex((x) => x.id == sharing.id)] = sharing;
        }
        this.sharingTemplates = clone(this.sharingTemplates);
      }
    }
    this.showDialog = false;
  }

  saveSharingTemplate(args: CloseSharingFormArguments): void {
    if (!args?.data) {
      this.closeSidebar(null);
      return;
    }
    const data = args.data;
    if (this.sharingTemplateToEdit) {
      const { destinationOrganizationId, notificationEmailUserGroups: _ignored, ...rest } = data;
      const input: SharingTemplateUpdateInput = {
        ...this.sharingTemplateToEdit,
        ...rest,
        destinationOrganizationIdOrVariable: <string>destinationOrganizationId,
      };
      this.sharingTemplatesService
        .updateSharingTemplate(input)
        //.pipe(finalize(() => (this.disableSubmit = false)))
        .subscribe((x) => {
          this.closeSidebar([x]);
          this.messageService.add({
            severity: "success",
            summary: translate("sharing.created.title"),
            detail: translate("sharing.created.message", x),
          });
          if (args.autoSync) {
            this.autoSyncTriggered.emit();
          }
        });
    } else {
      const { destinationOrganizationId, notificationEmailUserGroupIds: _ignored, ...rest } = data;
      const input: SharingTemplateCreateInput = {
        ...rest,
        destinationOrganizationIdOrVariables: <string[]>destinationOrganizationId,
        sharedCollectionId: this.sharingTemplateProvider.id,
        organizationId: this.sharingTemplateProvider.organizationId,
      };
      this.sharingTemplatesService.createSharingTemplate(input).subscribe((x) => {
        this.closeSidebar(x);
        this.messageService.add({
          severity: "success",
          summary: translate("sharing.updated.title"),
          detail: translate("sharing.updated.message", x),
        });
      });
    }
  }

  private isSharingAllowed(template: SharingTemplate): boolean {
    return (
      !template?.destinationOrganizationIdOrVariable ||
      !isCollectionAutoTemplateVariable(template.destinationOrganizationIdOrVariable) ||
      this.allowedVariables.includes(template.destinationOrganizationIdOrVariable) ||
      [CollectionAutoTemplateVariableEnum.EmployerApplication, CollectionAutoTemplateVariableEnum.EmployerInterview]
        .map((x) => getCollectionAutoTemplateVariableValue(x))
        .includes(template.destinationOrganizationIdOrVariable)
    );
  }

  private deleteSharingTemplate(sharingTemplate: SharingTemplate, buttonEvent: Event): void {
    this.confirmationService.confirm({
      target: buttonEvent.target,
      message: translate("sharing.confirmDelete", sharingTemplate),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.sharingTemplatesService.deleteSharingTemplate(sharingTemplate).subscribe(() => {
          this.sharingTemplates = this.sharingTemplates.filter((x) => x.id != sharingTemplate.id);
          this.messageService.add({
            severity: "success",
            summary: translate("sharing.deleted.title"),
            detail: translate("sharing.deleted.message", sharingTemplate),
          });
        });
      },
    });
  }

  private editSharingTemplate(sharingTemplate: SharingTemplate): void {
    this.sharingTemplateToEdit = sharingTemplate;
    this.showDialog = true;
  }

  private newSharingTemplate(): void {
    this.sharingTemplateToEdit = null;
    this.showDialog = true;
  }
}
