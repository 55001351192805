<div class="app-form" *appTranslate="let t">
  <p-table
    *ngIf="!turnOffTable"
    [value]="candidates"
    dataKey="id"
    [selectionMode]="isMultiEditActive ? 'multiple' : 'single'"
    [(selection)]="selectedCandidates"
    (onRowSelect)="onRowSelect($event.data)"
    (onRowUnselect)="onRowUnselect()"
    [selectAll]="isSelectAllActive"
    (selectAllChange)="onMultiAllSelect($event.checked)"
    [lazy]="true"
    [totalRecords]="totalCandidates"
    [rows]="candidatesPerPage"
    [alwaysShowPaginator]="false"
    [pageLinks]="2"
    (onLazyLoad)="onLazyLoad($event)"
    [paginator]="true"
    styleClass="p-datatable-candidate-selection p-datatable-with-overflows"
  >
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-end">
        <span *ngIf="!filterState?.filters?.favorite" class="p-input-icon-left flex-1 ml-auto mr-auto">
          <i class="pi pi-search"></i>
          <input
            pInputText
            class="pt-2 pb-2"
            type="text"
            [value]="searchFilter ?? ''"
            (input)="onSearchChanged($any($event.target).value)"
            [placeholder]="t('common.search')"
          />
        </span>
        <app-candidate-filter
          *ngIf="!filterState?.filters?.favorite"
          [filter]="filterState?.filters?.custom"
          (filterChange)="onFilterChanged($event)"
          (collectionCreate)="onCollectionCreate($event)"
        ></app-candidate-filter>
        <app-candidate-favorite-filter
          [meta]="filterState?.filters?.favorite"
          (metaChange)="onFavoriteChanged($event)"
        ></app-candidate-favorite-filter>
        <app-candidate-multi-edit-toggle
          *ngIf="!showMultiEditToggle"
          [active]="isMultiEditActive"
          (activeChange)="onMultiEditToggle($event)"
        ></app-candidate-multi-edit-toggle>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th *ngIf="isMultiEditActive" class="w-3rem max-w-3rem">
          <p-tableHeaderCheckbox [disabled]="!canSelectAllForMultiEdit()"></p-tableHeaderCheckbox>
        </th>
        <th [pSortableColumn]="'displayId'">
          <div>
            {{ t("number.title") }}
            <p-columnFilter type="text" field="displayId" display="menu"></p-columnFilter>
            <p-sortIcon field="displayId"></p-sortIcon>
          </div>
        </th>
        <th [pSortableColumn]="'displayName'">
          <div>
            {{ t("name.title") }}
            <p-columnFilter type="text" field="displayName" display="menu"></p-columnFilter>
            <p-sortIcon field="displayName"></p-sortIcon>
          </div>
        </th>
        <th [pSortableColumn]="'country'">
          <div>
            {{ t("country.title") }}
            <p-columnFilter field="country" matchMode="equals" display="menu" [showMatchModes]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown
                  [ngModel]="value"
                  [options]="filterCountryOptions | async"
                  [placeholder]="t('country.placeholder')"
                  filterBy="label"
                  (onChange)="filter($event.value)"
                  [showClear]="true"
                ></p-dropdown>
              </ng-template>
            </p-columnFilter>
            <p-sortIcon field="country"></p-sortIcon>
          </div>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-candidate>
      <!-- can't have pSelectableRow in the multi selection mode -->
      <tr *ngIf="!isMultiEditActive" [pSelectableRow]="candidate">
        <ng-container *ngTemplateOutlet="rowTemplate; context: { $implicit: candidate }"></ng-container>
      </tr>

      <tr *ngIf="isMultiEditActive">
        <ng-container *ngTemplateOutlet="rowTemplate; context: { $implicit: candidate }"></ng-container>
      </tr>
    </ng-template>

    <ng-template #rowTemplate let-candidate>
      <td *ngIf="isMultiEditActive" class="w-3rem max-w-3rem">
        <p-tableCheckbox [value]="candidate" [disabled]="!canSelectForMultiEdit(candidate)"></p-tableCheckbox>
      </td>
      <td>
        <div
          class="white-space-nowrap text-overflow-ellipsis overflow-hidden"
          [attr.data-testid]="'candidate-id-' + candidate.id"
          [attr.data-displayid]="candidate.displayId"
        >
          <app-flag class="mr-2" *ngIf="candidate.id" [country]="candidate.country" [small]="true"></app-flag>
          {{ candidate.displayId }}
        </div>
      </td>
      <td>
        <div
          class="white-space-nowrap text-overflow-ellipsis overflow-hidden"
          [class.color-error]="deletionRequestsSet?.has(candidate.id)"
        >
          {{ candidate.displayName }}
        </div>
      </td>
      <td>
        <div class="white-space-nowrap text-overflow-ellipsis overflow-hidden">
          {{ candidate.country | staticData: StaticDataType.Countries | async }}
        </div>
      </td>
    </ng-template>
  </p-table>
</div>
