import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core";
import { EmployerRelocationLanguageLevels, INDIVIDUAL_CONTRACT_ID } from "@ankaadia/ankaadia-shared";
import {
  AfterRecognition,
  BeforeRecognition,
  ContractTemplateForSelectionFragment,
  ContractTemplateSetInput,
  EmployerHistoryEntryInput,
  OrganizationContactForSelectionFragment,
  SharingState,
  StaticDataModel,
  StaticDataType,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { isNil, uniqBy } from "lodash";
import { BehaviorSubject, Subscription, combineLatest, distinctUntilChanged, map, startWith, take } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { emitEvents, valuesOf } from "../../../../../shared/services/form.helper";
import { SettingsService } from "../../../../../shared/services/settings.service";
import { StaticDataService } from "../../../../../shared/static-data/static-data.service";
import { CandidateEmployerOrganizationService } from "../../../../candidate-reusables/candidate-employer-organization/candidate-employer-organization.service";
import {
  CleanContractTemplateSetInput,
  toCleanContractTemplate,
} from "../../../../contract-template/contract-template-dialog/contract-template-form.model";
import { ContractTemplateService } from "../../../../contract-template/contract-template.service";
import { OrganizationContactsService } from "../../../../organization-contacts/organization-contacts.service";
import { OrganizationLinkService } from "../../../../organizations/organization-link.service";
import { AfterRecognitionFormDe, BeforeRecognitionFormDe } from "../../../candidate-form.model";
import { CandidateFormService } from "../../../candidate-form.service";

const changeableWorkLocation = "CHANGEABLE";

@Component({
  selector: "app-candidate-employment-relationship-entry",
  templateUrl: "./candidate-employment-relationship-entry.component.html",
  standalone: false,
})
export class CandidateEmployerEntryComponent implements AfterViewInit, OnChanges, OnDestroy {
  readonly formId = uuidv4();
  readonly today = new Date();
  readonly language = this.transloco.getActiveLang();
  readonly IndividualContract: ContractTemplateForSelectionFragment = {
    id: INDIVIDUAL_CONTRACT_ID,
    name: translate("contractTemplate.individual"),
  };

  private initControlsSubscription?: Subscription;
  private subscriptions: Subscription[] = [];

  @Input()
  form: BeforeRecognitionFormDe | AfterRecognitionFormDe;

  @Input()
  readonly: boolean;

  @Input()
  enabled = true;

  @Input()
  candidate: { id: string; organizationId: string };

  @Input()
  employer: BeforeRecognition | AfterRecognition;

  @Input()
  immigrationCountry: SupportedImmigrationCountry;

  @Input()
  employerSync: boolean;

  @Input()
  isMultiEditMode: boolean;

  @Output()
  readonly employerChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  readonly firstDeputyChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  readonly representativeChange: EventEmitter<string> = new EventEmitter<string>();

  selectedContractTemplate: ContractTemplateSetInput;
  selectedEmployerHistory: EmployerHistoryEntryInput[];

  protected contractTemplates$ = new BehaviorSubject<ContractTemplateForSelectionFragment[]>([]);
  protected individualContractTemplates$ = new BehaviorSubject<ContractTemplateForSelectionFragment[]>([]);
  protected contractTemplateOptions$ = combineLatest([
    this.contractTemplates$.pipe(distinctUntilChanged()),
    this.individualContractTemplates$.pipe(distinctUntilChanged()),
  ]).pipe(map(([x, y]) => uniqBy([...x, ...y], "id")));

  protected contacts: OrganizationContactForSelectionFragment[] = [];
  protected sharingState: SharingState;
  protected isDeputyShared = false;
  protected isRepresentativeShared = false;
  protected isContractShared = false;

  protected readonly relocationRequirements$ = this.staticDataService
    .getStaticData(StaticDataType.RelocationRequirements, this.language)
    .pipe(
      startWith(<StaticDataModel[]>[]), // multi-select does not work without this
      map((x) => x.filter((y) => EmployerRelocationLanguageLevels.includes(y.value)))
    );

  constructor(
    protected readonly settings: SettingsService,
    private readonly transloco: TranslocoService,
    private readonly linkService: OrganizationLinkService,
    private readonly organizationContacts: OrganizationContactsService,
    private readonly contractTemplateService: ContractTemplateService,
    private readonly employerService: CandidateEmployerOrganizationService,
    private readonly staticDataService: StaticDataService,
    private readonly formService: CandidateFormService
  ) {}

  ngAfterViewInit(): void {
    this.initControls();

    this.subscriptions.push(
      this.form.controls.employerId.valueChanges.pipe(distinctUntilChanged()).subscribe((employerId) => {
        if (!this.readonly) {
          const emp = this.employerService.employers?.find((x) => x.id === employerId);
          this.form.controls.employerName.setValue(emp?.name ?? null);
          this.form.controls.employerRegion.setValue(emp?.region ?? null);
          this.form.controls.employerZipcode.setValue(emp?.zipcode ?? null);
        }
      })
    );

    this.subscriptions.push(
      this.form.controls.employerId.valueChanges.pipe(distinctUntilChanged(), take(1)).subscribe((employerId) => {
        if (employerId != null) {
          this.refreshEmployerData(employerId);
        }
      })
    );

    this.subscriptions.push(
      valuesOf(this.form.controls.contractTemplateId).subscribe((contractTemplateId) => {
        this.individualContractTemplates$.next(
          contractTemplateId === this.IndividualContract.id ? [this.IndividualContract] : []
        );
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.enabled) {
      this.initControls();
    }
    if (changes.candidate) {
      this.refreshEmployerData(null);
    }
  }

  onContractTemplateChange(contractTemplateId: string): void {
    if (contractTemplateId) {
      this.contractTemplateService
        .get(contractTemplateId, this.form.controls.employerId.value)
        .subscribe((contract) => {
          this.form.controls.contractTemplateName.setValue(contract?.name);
          this.saveContractTemplate(toCleanContractTemplate(contract), false);
        });
    } else {
      this.form.controls.contractTemplateName.setValue(null);
      this.form.controls.contractTemplate.setValue({});
    }
  }

  ngOnDestroy(): void {
    this.initControlsSubscription?.unsubscribe();
    this.subscriptions.forEach((x) => x.unsubscribe());
    this.subscriptions = [];
  }

  openContractTemplate(): void {
    if (
      this.form.controls.contractTemplateId.value === this.IndividualContract.id ||
      (this.form.controls.contractTemplateId.value && this.form.controls.contractTemplate.value)
    ) {
      this.selectedContractTemplate = {
        ...this.form.controls.contractTemplate.value,
        organizationId: this.form.controls.employerId.value,
        name: this.form.controls.contractTemplateName.value,
      };
    } else {
      this.contractTemplateService
        .get(this.form.controls.contractTemplateId.value, this.form.controls.employerId.value)
        .subscribe((x) => (this.selectedContractTemplate = x));
    }
  }

  saveContractTemplate(template: CleanContractTemplateSetInput, isIndividual: boolean): void {
    const { _etag, id: _1, organizationId: _2, name: _3, country: _4, ...cleanedTemplate } = template;
    this.form.controls.contractTemplate.setValue(cleanedTemplate);
    if (isIndividual) {
      this.form.controls.contractTemplateId.setValue(this.IndividualContract.id);
      this.form.controls.contractTemplateName.setValue(this.IndividualContract.name);
    }
    this.form.markAsDirty();
    this.closeContractTemplate();
  }

  closeContractTemplate(): void {
    this.selectedContractTemplate = null;
  }

  openEmployerHistory(): void {
    this.selectedEmployerHistory = this.form.controls.employerHistory.getRawValue();
  }

  saveEmployerHistory(employerHistory: EmployerHistoryEntryInput[]): void {
    const formArray = this.form.controls.employerHistory;
    formArray.clear({ emitEvent: false });
    for (const entry of employerHistory ?? []) {
      formArray.push(this.formService.createEmployerHistoryGroup(entry), { emitEvent: false });
    }
    emitEvents(formArray);

    this.closeEmployerHistory();
  }

  closeEmployerHistory(): void {
    this.selectedEmployerHistory = null;
  }

  onEmployerChanged(employerId: string): void {
    this.form.controls.contractTemplateId.setValue(null);
    this.form.controls.firstDeputyId.setValue(null);
    this.form.controls.representativeId.setValue(null);
    this.refreshEmployerData(employerId);
  }

  isAfterRecognitionForm(form: BeforeRecognitionFormDe | AfterRecognitionFormDe): form is AfterRecognitionFormDe {
    const tested = form as AfterRecognitionFormDe;
    return !!tested.controls.contractSignDate && !!tested.controls.startDate && !!tested.controls.endDate;
  }

  private initControls(): void {
    const typeOfWorkLocation = this.form.controls.typeOfWorkLocation;
    if (!typeOfWorkLocation) return;

    const typeOfWorkLocation$ = typeOfWorkLocation?.valueChanges.pipe(
      distinctUntilChanged(),
      startWith(this.form.controls.typeOfWorkLocation.value)
    );

    this.initControlsSubscription?.unsubscribe();
    this.initControlsSubscription = typeOfWorkLocation$.subscribe((typeOfWorkLocation) => {
      if (this.enabled) {
        this.form.controls.employerId.enable();
        this.form.controls.representativeId.enable();
        this.form.controls.firstDeputyId.enable();
        this.form.controls.contractTemplateId.enable();
        this.form.controls.isTemporaryEmployee.enable();
        this.form.controls.typeOfWorkLocation.enable();
        this.form.controls.employmentRelocationRequirements.enable();
        if (this.isAfterRecognitionForm(this.form)) {
          this.form.controls.contractSignDate.enable();
          this.form.controls.startDate.enable();
          this.form.controls.endDate.enable();
        }
      } else {
        this.form.controls.employerId.disable();
        this.form.controls.representativeId.disable();
        this.form.controls.firstDeputyId.disable();
        this.form.controls.contractTemplateId.disable();

        this.form.controls.isTemporaryEmployee.disable();
        this.form.controls.typeOfWorkLocation.disable();
        this.form.controls.employmentRelocationRequirements.disable();
        if (this.isAfterRecognitionForm(this.form)) {
          this.form.controls.contractSignDate.disable();
          this.form.controls.startDate.disable();
          this.form.controls.endDate.disable();
        }
      }

      if (this.enabled && typeOfWorkLocation !== changeableWorkLocation) {
        this.form.controls.workLocation.enable();
      } else {
        this.form.controls.workLocation.disable();
        if (typeOfWorkLocation === changeableWorkLocation) {
          this.form.controls.workLocation.setValue(null);
        }
      }
    });
  }

  refreshEmployerData(employerId: string): void {
    this.linkService
      .getSharingState({
        candidateOwnerOrganizationId: this.candidate.organizationId,
        userOrganizationId: this.settings.organizationId,
        targetOrganizationId: employerId,
        contractId: this.form.controls.contractTemplateId?.value,
        contactIds: [this.form.controls.representativeId?.value, this.form.controls.firstDeputyId?.value].filter(
          (x) => x != null
        ),
      })
      .subscribe((x) => {
        this.sharingState = x;
        this.isDeputyShared =
          x.contactsSharedByTargetOrganization &&
          (this.form.controls.firstDeputyId?.value == null ||
            x.specificContactsSharedByTargetOrganization.some(
              (y) => y.id === this.form.controls.firstDeputyId?.value && y.shared
            ));

        this.isContractShared = this.getIsContractShared();
        this.isRepresentativeShared =
          (x.contactsSharedByTargetOrganization && this.form.controls.representativeId?.value == null) ||
          x.specificContactsSharedByTargetOrganization.some(
            (y) => y.id === this.form.controls.representativeId?.value && y.shared
          );
      });

    if (employerId != null) {
      this.loadContacts(employerId);
      this.loadContractTemplates(employerId);
    }
  }

  private getIsContractShared(): boolean {
    const { contractsSharedByTargetOrganization, specificContractSharedByTargetOrganization } = this.sharingState ?? {};
    const areContractsShared = contractsSharedByTargetOrganization ?? false;
    const contractTemplateId = this.form.controls.contractTemplateId.value;
    return areContractsShared && (isNil(contractTemplateId) || specificContractSharedByTargetOrganization);
  }

  private loadContractTemplates(employerId: string): void {
    //Returns shared contracts to the users organization  only
    this.contractTemplateService.getForSelection(employerId, this.immigrationCountry).subscribe((x) => {
      this.contractTemplates$.next(x);
    });
  }

  private loadContacts(employerId: string): void {
    //Returns shared contracts to the users organization  only
    this.organizationContacts.getForSelection(employerId).subscribe((x) => {
      this.contacts = x;
    });
  }
}
