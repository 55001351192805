import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { XlsxImportResult } from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { Message } from "primeng/api";

@Component({
  selector: "app-import-result-dialog",
  templateUrl: "./import-result-dialog.html",
  standalone: false,
})
export class ImportResultDialogComponent implements OnChanges {
  generalMessages: Message[] = [];

  @Input()
  xlsxImportResult: XlsxImportResult;

  @Output()
  readonly closed = new EventEmitter<void>();

  constructor(private readonly transloco: TranslocoService) {}

  get language(): string {
    return this.transloco.getActiveLang();
  }

  get caption(): string {
    const localizedType = translate(`import-result-dialog.type.${this.xlsxImportResult.importType}`);
    const data = { ...this.xlsxImportResult, localizedType };

    return translate("import-result-dialog.caption", data, this.language);
  }

  ngOnChanges(): void {
    this.generalMessages = this.xlsxImportResult?.failed
      ? [
          {
            id: "import-failed",
            severity: "error",
            summary: translate("import-result-dialog.import-failed", this.xlsxImportResult, this.language),
            detail: translate("import-result-dialog.solve-issues", this.xlsxImportResult, this.language),
          },
        ]
      : [];
  }
}
