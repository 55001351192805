import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { ContractTemplateModule } from "../contract-template/contract-template.module";
import { DocumentRequirementModule } from "../document-requirements/document-requirement.module";
import { ImportModule } from "../import/import.module";
import { OrganizationContactsModule } from "../organization-contacts/organization-contacts.module";
import { ProfessionModule } from "../profession/profession.module";
import { CandidateTagSelectorComponent } from "./candidate-tag-selector/candidate-tag-selector.component";
import { MyOrganizationEditComponent } from "./my-organization/my-organization.component";
import { OrganizationEZBDataDialogComponent } from "./organization-EZB-data/organization-EZB-data-dialog/organization-EZB-data-dialog.component";
import { OrganizationEZBDataPageComponent } from "./organization-EZB-data/organization-EZB-data-page/organization-EZB-data-page.component";
import { OrganizationEZBDataComponent } from "./organization-EZB-data/organization-EZB-data.component";
import { OrganizationCrmNotesComponent } from "./organization-crm/organization-crm-notes/organization-crm-notes.component";
import { OrganizationCrmComponent } from "./organization-crm/organization-crm.component";
import { OrganizationEditDialogComponent } from "./organization-edit-dialog/organization-edit-dialog.component";
import { OrganizationEditComponent } from "./organization-edit/organization-edit.component";
import { OrganizationLinkDialogComponent } from "./organization-link-dialog/organization-link-dialog.component";
import { OrganizationProfileBenefitsComponent } from "./organization-profile/organization-profile-benefits/organization-profile-benefits.component";
import { OrganizationProfileDescriptionComponent } from "./organization-profile/organization-profile-description/organization-profile-description.component";
import { OrganizationProfileImagesComponent } from "./organization-profile/organization-profile-images/organization-profile-images.component";
import { OrganizationProfileLocationVacanciesComponent } from "./organization-profile/organization-profile-location-vacancies/organization-profile-location-vacancies.component";
import { OrganizationProfileLocationVacancyComponent } from "./organization-profile/organization-profile-location-vacancy/organization-profile-location-vacancy.component";
import { OrganizationProfileLocationComponent } from "./organization-profile/organization-profile-location/organization-profile-location.component";
import { OrganizationProfileLocationsComponent } from "./organization-profile/organization-profile-locations/organization-profile-locations.component";
import { OrganizationProfilePageComponent } from "./organization-profile/organization-profile-page/organization-profile-page.component";
import { OrganizationProfileComponent } from "./organization-profile/organization-profile.component";
import { OrganizationProfilesComponent } from "./organization-profiles/organization-profiles.component";
import { OrganizationSystemSettingsComponent } from "./organization-system-settings/organization-system-settings.component";
import { OrganizationsComponent } from "./organizations.component";
import { BillingSettingsComponent } from "../billing/billing-settings/billing-settings.component";

@NgModule({
  imports: [
    SharedModule,
    ProfessionModule,
    OrganizationContactsModule,
    ImportModule,
    DocumentRequirementModule,
    BillingSettingsComponent,
    ContractTemplateModule,
  ],
  declarations: [
    OrganizationEditComponent,
    OrganizationEditDialogComponent,
    OrganizationLinkDialogComponent,
    MyOrganizationEditComponent,
    OrganizationsComponent,
    OrganizationProfilesComponent,
    OrganizationProfileComponent,
    OrganizationProfileImagesComponent,
    OrganizationProfileDescriptionComponent,
    OrganizationProfileLocationComponent,
    OrganizationProfileLocationsComponent,
    OrganizationProfileBenefitsComponent,
    OrganizationSystemSettingsComponent,
    OrganizationProfilePageComponent,
    OrganizationEZBDataComponent,
    OrganizationEZBDataPageComponent,
    OrganizationEZBDataDialogComponent,
    OrganizationProfileLocationVacancyComponent,
    OrganizationProfileLocationVacanciesComponent,
    CandidateTagSelectorComponent,
    OrganizationCrmComponent,
    OrganizationCrmNotesComponent,
  ],
  exports: [MyOrganizationEditComponent, OrganizationsComponent],
})
export class OrganizationsModule {}
