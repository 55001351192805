<div class="flex flex-column app-form" *appTranslate="let t">
  <p-toolbar>
    <ng-template pTemplate="left">
      <p-dropdown
        [options]="organizations"
        [(ngModel)]="selectedOrganization"
        (onChange)="selectedCollection = null; updateCollections(); updateCandidates()"
        optionLabel="name"
        optionValue="id"
        [showClear]="true"
        filterBy="name"
        [placeholder]="t('organization.placeholder')"
        class="mr-2"
        [style]="{ 'max-width': '220px' }"
        data-testid="field-select-organization"
      ></p-dropdown>
      <p-dropdown
        [options]="collections"
        [(ngModel)]="selectedCollection"
        (onChange)="updateCandidates()"
        optionLabel="name"
        optionValue="id"
        filterBy="name"
        [showClear]="true"
        [placeholder]="t('collection.placeholder')"
        data-testid="field-select-collection"
      >
        <ng-template *ngFor="let template of ['item', 'selectedItem']" let-item [pTemplate]="template">
          <span>{{ item.name }}</span>
          <ng-container *ngIf="selectedOrganization !== organizationId">
            <span *ngIf="item.organizationId === organizationId"> (->)</span>
            <span *ngIf="item.organizationId !== organizationId"> (<-)</span>
          </ng-container>
        </ng-template>
      </p-dropdown>
    </ng-template>
    <ng-template pTemplate="right">
      <ng-container *ngIf="data as total">
        <ng-container *ngIf="data | candidateFilter: search : limitSharingTypes as shown">
          <div class="mr-2" *ngIf="total.length > 0">
            {{ t("candidates.title") }}: {{ shown.length }} / {{ total.length }}
          </div>
        </ng-container>
      </ng-container>
      <p-selectButton
        [options]="possibleViews"
        optionLabel="icon"
        optionValue="value"
        [unselectable]="true"
        [(ngModel)]="selectedView"
        (onChange)="onViewChange($event.value)"
      >
        <ng-template let-item pTemplate>
          <i [class]="item.icon"></i>
        </ng-template>
      </p-selectButton>

      <p-multiSelect
        [placeholder]="t('candidate.placeholder')"
        [options]="filterOptions"
        [(ngModel)]="limitSharingTypes"
        class="mr-2"
      ></p-multiSelect>
      <span class="p-input-icon-left mr-2">
        <i class="pi pi-search"></i>
        <input type="text" pInputText [placeholder]="t('common.search')" [(ngModel)]="search" style="width: 230px" />
      </span>

      <p-button
        [disabled]="
          disableSubmitFeedback || !selectedSharing || selectedSharing.submitted || !feedbackProvidedToAllCandidates
        "
        [label]="t('feedback.submit')"
        icon="pi pi-check-square"
        class="mr-2 mb-2"
        (onClick)="onSubmitFeedback($event)"
      ></p-button>
      <p-button
        [disabled]="!selectedSharing"
        [label]="t('feedback.report')"
        icon="pi pi-file-excel"
        class="mr-2 mb-2"
        (onClick)="onGenerateFeedbackReport()"
      ></p-button>
    </ng-template>
  </p-toolbar>

  <div class="flex flex-column justify-content-center app-form" *ngIf="!isGridView">
    <p-carousel
      #carousel
      class="app-form"
      styleClass="app-form"
      [value]="data | candidateFilter: search : limitSharingTypes"
      [numVisible]="1"
      [numScroll]="1"
      [circular]="true"
    >
      <ng-template let-data pTemplate="item">
        <div class="flex justify-content-center pt-1 pb-1">
          <div class="flex flex-column shadow-2 candidate-card">
            <app-candidate-card
              [candidateData]="data.candidate"
              [candidateFiles]="data.files"
              [sharing]="selectedSharing"
              [candidateTag]="
                !data.feedback.statusPhaseOne && !data.feedback.statusPhaseTwo ? t('enum.CandidateStatus.New') : null
              "
              [isPresentation]="true"
              [collectionId]="selectedCollection"
              [collectionOrganizationId]="selectedOrganization"
              (filePreview)="currentCandidate = data.candidate; filePreview = $event"
            ></app-candidate-card>
            <ng-container *ngIf="selectedSharing">
              <app-candidate-feedback
                [readonly]="selectedSharing.submitted"
                [feedbackData]="data.feedback"
                [secondPhaseEnabled]="feedbackSecondPhaseEnabled"
                [disabled]="feedbackIsUpdating"
                (feedbackChange)="updateFeedback($event.feedback, $event.field, data)"
              ></app-candidate-feedback>
              <app-candidate-comments
                [readonly]="selectedSharing.submitted"
                [feedbackData]="data.feedback"
                (commentAdd)="addComment($event, data)"
                (commentChange)="updateComment($event, data)"
                (commentRefresh)="refreshFeedback($event, data)"
              ></app-candidate-comments>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </p-carousel>
  </div>

  <div class="app-content" *ngIf="isGridView">
    <p-dataView [value]="data | candidateFilter: search : limitSharingTypes" layout="grid">
      <ng-template let-datas pTemplate="grid">
        <div class="p-grid p-nogutter grid grid-nogutter">
          <div *ngFor="let data of datas" class="flex flex-column shadow-2 candidate-card mb-3">
            <app-candidate-card
              [candidateData]="data.candidate"
              [candidateFiles]="data.files"
              [sharing]="selectedSharing"
              [candidateTag]="
                !data.feedback.statusPhaseOne && !data.feedback.statusPhaseTwo ? t('enum.CandidateStatus.New') : null
              "
              [collectionId]="selectedCollection"
              [collectionOrganizationId]="selectedOrganization"
              [isPresentation]="true"
              (filePreview)="currentCandidate = data.candidate; filePreview = $event"
            ></app-candidate-card>
            <ng-container *ngIf="selectedSharing">
              <app-candidate-feedback
                [readonly]="selectedSharing.submitted"
                [feedbackData]="data.feedback"
                [secondPhaseEnabled]="feedbackSecondPhaseEnabled"
                [disabled]="feedbackIsUpdating"
                (feedbackChange)="updateFeedback($event.feedback, $event.field, data)"
              ></app-candidate-feedback>
              <app-candidate-comments
                [readonly]="selectedSharing.submitted"
                [feedbackData]="data.feedback"
                (commentAdd)="addComment($event, data)"
                (commentChange)="updateComment($event, data)"
                (commentRefresh)="refreshFeedback($event, data)"
              ></app-candidate-comments>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </p-dataView>
    <p-scrollTop></p-scrollTop>
  </div>

  <p-sidebar [visible]="!!filePreview" styleClass="p-sidebar-lg">
    <app-document-preview-dialog
      *ngIf="filePreview"
      [subheader]="
        filePreview.fileType
          | staticData
            : StaticDataType.AllowedUploadFileTypes
            : null
            : { candidateId: currentCandidate.id, organizationId: currentCandidate.organizationId }
          | async
      "
      [fileName]="filePreview.fileName"
      [url]="filePreview.url"
      (closed)="closePreview()"
    ></app-document-preview-dialog>
  </p-sidebar>
</div>
