import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { isCollectionAutoTemplateVariable } from "@ankaadia/ankaadia-shared";
import { CollectionAutoFilterTemplateDeletionBehavior, CollectionAutoFilterTemplateFragment } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { ConfirmationService } from "primeng/api";
import { Subscription, forkJoin } from "rxjs";
import { SettingsService } from "../../../../shared/services/settings.service";
import { DeepLinkAccessService } from "../../../deep-link-access/deep-link-access.service";
import { MessageService } from "../../../message/message.service";
import { FilterMetadataMap } from "../../collection-edit-assigned-candidates/custom-filter.model";
import { GroupedSelectableOrganization } from "../../sharing-edit/sharing-form.model";
import { CollectionAutoFilterTemplateService } from "../collection-auto-filter-template.service";
import { getOrganizationVariables } from "../collection-auto-filter-template.variables";

@Component({
  selector: "app-collection-auto-template-form",
  templateUrl: "./collection-auto-template-form.component.html",
  styleUrl: "./collection-auto-template-form.component.scss",
  standalone: false,
})
export class CollectionAutoTemplateFormComponent implements OnInit, OnDestroy {
  isDeletePromptVisible = false;
  deletionBehavior: CollectionAutoFilterTemplateDeletionBehavior = CollectionAutoFilterTemplateDeletionBehavior.Detach;
  collectionAutoTemplate: CollectionAutoFilterTemplateFragment;

  get usedVariables(): string[] {
    const filter: FilterMetadataMap = this.collectionAutoTemplate?.filters;
    if (!filter) {
      return [];
    }

    return getFilterValues(filter).filter((value) => isCollectionAutoTemplateVariable(value));

    function getFilterValues(filter: FilterMetadataMap): string[] {
      const vals = Object.values(filter)
        .flatMap((x) => (Array.isArray(x) ? x : [x]))
        .flatMap((x) => x.value)
        .filter((x) => typeof x === "string");
      return vals;
    }
  }

  additionalOrganizations: GroupedSelectableOrganization[] = [
    {
      label: translate("autoCollectionTemplate.variables"),
      items: getOrganizationVariables().map((x) => ({
        id: x.value,
        name: x.label,
      })),
    },
  ];

  showSidebar: boolean;
  private routeSubscription: Subscription;

  @Output() readonly editModeChanged = new EventEmitter<boolean>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly messageService: MessageService,
    private readonly settings: SettingsService,
    private readonly deepLinkAccessService: DeepLinkAccessService,
    private readonly templateService: CollectionAutoFilterTemplateService,
    private readonly confirmationService: ConfirmationService
  ) {}

  protected get isLicensed(): boolean {
    return this.settings.isLicensed;
  }

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe((params) => this.routeChanged(params));
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  reloadCollection(): void {
    this.templateService
      .get({ organizationId: this.collectionAutoTemplate.organizationId, id: this.collectionAutoTemplate.id })
      .subscribe((x) => this.updateTemplate(x));
  }

  editCollection(): void {
    this.templateService
      .get({ organizationId: this.collectionAutoTemplate.organizationId, id: this.collectionAutoTemplate.id })
      .subscribe((x) => {
        this.updateTemplate(x);
        this.showSidebar = true;
      });
  }

  closeSidebar(): void {
    this.showSidebar = false;
    this.templateService
      .get({ organizationId: this.collectionAutoTemplate.organizationId, id: this.collectionAutoTemplate.id })
      .subscribe((x) => {
        this.updateTemplate(x);
      });
  }

  showDeletePrompt(): void {
    this.isDeletePromptVisible = true;
  }

  deleteCollection(_event: Event): void {
    this.templateService
      .get({ organizationId: this.collectionAutoTemplate.organizationId, id: this.collectionAutoTemplate.id })
      .subscribe((x) => {
        const deletionInput = { ...x, deletionBehavior: this.deletionBehavior };
        this.templateService.delete(deletionInput).subscribe(() => {
          this.messageService.add({
            severity: "success",
            summary: translate("collection.deleted.title"),
            detail: translate("collection.deleted.message", this.collectionAutoTemplate),
          });

          void this.router.navigate(["./../"], { relativeTo: this.route });
        });
      });
  }

  triggerAutoSync(): void {
    this.templateService.syncAllCollectionsWithTemplate(this.collectionAutoTemplate).subscribe(() => {
      this.messageService.add({
        severity: "success",
        summary: translate("autoCollectionTemplate.syncedAllSuccessfully"),
      });
    });
  }

  applyOnAllCandidates(event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("autoCollectionTemplate.applyOnAllCandidates.confirm", {
        templateName: this.collectionAutoTemplate.name,
      }),
      accept: () => {
        this.templateService.applyOnAllCandidates(this.collectionAutoTemplate).subscribe(() => {
          this.messageService.add({
            severity: "success",
            summary: translate("autoCollectionTemplate.applyOnAllCandidates.success"),
          });
          this.reloadCollection();
        });
      },
    });
  }

  private routeChanged({ collectionId, orgId }: Params): void {
    const { checkAccess } = this.route.snapshot.queryParams;
    if (checkAccess) {
      // it's a link from the emails, gonna check the access
      this.deepLinkAccessService.canAccessCollectionEdit(collectionId, orgId).subscribe((access) => {
        if (access.canAccess) {
          this.loadTemplate(collectionId, orgId);
        } else {
          if (access.redirectToOrganization) {
            this.settings.switchOrganizationKeepingCurrentUrl(access.redirectToOrganization);
          } else {
            this.settings.navigateToAccessDenied();
          }
        }
      });
    } else {
      // carry on, it's probably just navigation within the app
      if (orgId === this.settings.organizationId) {
        // can only edit your own org's collections
        this.loadTemplate(collectionId, orgId);
      } else {
        this.settings.navigateToAccessDenied();
      }
    }
  }

  private loadTemplate(collectionId: string, orgId: string): void {
    forkJoin([this.templateService.get({ organizationId: orgId, id: collectionId })]).subscribe(([collection]) => {
      this.updateTemplate(collection);
    });
  }

  private updateTemplate(collectionAutoTemplate: CollectionAutoFilterTemplateFragment): void {
    this.collectionAutoTemplate = collectionAutoTemplate;
  }
}
