<ng-container *appTranslate="let t">
  <form>
    <div class="p-fluid grid formgrid emi-fullscreen" [controlRef]="form">
      <ng-container *ngFor="let group of form.controls; index as i" [formGroup]="group">
        <div class="field col-12 lg:col-3">
          <label for="name">{{ t("qualificationModule.title") }}</label>
          <input
            pInputText
            id="name"
            formControlName="name"
            [readonly]="readonly"
            [placeholder]="t('qualificationModule.placeholder')"
          />
          <small class="p-error" *ngIf="group.controls.name.errors?.required">
            {{ t("qualificationModule.required") }}
          </small>
        </div>

        <div class="field col-12 lg:col-2">
          <label for="theoryHours">{{ t("qualificationModule.theoryHours.shortTitle") }}</label>
          <p-inputNumber
            inputId="theoryHours"
            formControlName="theoryHours"
            [min]="0"
            [readonly]="readonly"
            placeholder="0"
          ></p-inputNumber>

          <small class="p-error" *ngIf="group.controls.theoryHours.errors?.required">
            {{ t("qualificationModule.theoryHours.required") }}
          </small>
        </div>

        <div class="field col-12 lg:col-2">
          <label for="practiceHours">{{ t("qualificationModule.practiceHours.shortTitle") }}</label>
          <p-inputNumber
            inputId="practiceHours"
            formControlName="practiceHours"
            [min]="0"
            [readonly]="readonly"
            placeholder="0"
          ></p-inputNumber>

          <small class="p-error" *ngIf="group.controls.practiceHours.errors?.required">
            {{ t("qualificationModule.practiceHours.required") }}
          </small>
        </div>

        <div class="field col-12 lg:col-2">
          <label for="name">{{ t("areaOfDeployment.title") }}</label>
          <input
            pInputText
            id="name"
            formControlName="areaOfDeployment"
            [readonly]="readonly"
            [placeholder]="t('areaOfDeployment.placeholder')"
          />
          <small class="p-error" *ngIf="group.controls.areaOfDeployment.errors?.required">
            {{ t("areaOfDeployment.required") }}
          </small>
        </div>

        <div class="field col-12 lg:col-2">
          <label for="totalHours">{{ t("totalHours.title") }}</label>
          <input
            pInputText
            id="totalHours"
            [value]="(group.controls.theoryHours.value ?? 0) + (group.controls.practiceHours.value ?? 0)"
            placeholder="0"
            disabled
          />
        </div>

        <div class="field col-12 lg:col-1 mt-5">
          <p-button icon="pi pi-times" [disabled]="readonly" (onClick)="remove(i)" [removeBtnRef]="form"></p-button>
        </div>
      </ng-container>

      <div class="field col-12 lg:col-3 mt-1">
        <p-button
          icon="pi pi-plus"
          [label]="t('qualificationModule.add')"
          [disabled]="readonly || form.disabled"
          (onClick)="add()"
          [addBtnRef]="form"
        ></p-button>
      </div>
    </div>
  </form>
</ng-container>
