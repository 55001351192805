import { ChangeDetectorRef, Component, Input, OnDestroy } from "@angular/core";
import { AvailableDocumentTemplate, Collection } from "@ankaadia/graphql";
import { combineLatest, Subscription } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { DocumentAutogenService } from "../../document-autogen/document-autogen.service";
import { DocumentTemplateForList } from "../../document-template/document-template-templates/document-templates.component";
import { DocumentTemplatesService } from "../../document-template/document-templates.service";

@Component({
  selector: "app-collection-document-templates",
  templateUrl: "./collection-document-templates.component.html",
  standalone: false,
})
export class CollectionDocumentTemplatesComponent implements OnDestroy {
  private subscription: Subscription;

  @Input()
  collection: Collection;

  @Input()
  isReportMode: boolean;

  templates: DocumentTemplateForList[];

  constructor(
    private readonly templateService: DocumentTemplatesService,
    private readonly documentAutogenService: DocumentAutogenService,
    private readonly settings: SettingsService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  loadTemplates(names: AvailableDocumentTemplate[]): void {
    this.subscription?.unsubscribe();
    if (this.collection && names?.length) {
      this.subscription = combineLatest([
        this.templateService.getAllForCollection(this.collection.id, this.collection.organizationId, names),
        this.documentAutogenService.get(this.settings.organizationId),
      ]).subscribe(
        ([templates, alwaysGenerated]) =>
          (this.templates = templates.map((template) => ({
            ...template,
            alwaysGenerated: alwaysGenerated.some((x) => x.name === template.name && x.type === template.type),
          })))
      );
    } else {
      this.templates = null;
      this.changeDetector.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
