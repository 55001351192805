<ng-container *appTranslate="let t">
  <form [formGroup]="form">
    <p-fieldset [legend]="t('responsibilities.title')" class="relative">
      <app-candidate-document-upload
        [fileTypes]="['RESIDENCEPERMIT', 'FICTIONALCERT', 'REGCERT', 'POLICERECORDENHANCEDIMMIGRATION']"
        [candidateId]="candidateId"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateForm]="form"
        [readonly]="readonly"
      ></app-candidate-document-upload>
      <app-candidate-responsible-representative-group
        [form]="form"
        [candidateOrganizationId]="candidateOrganizationId"
        [candidateId]="candidateId"
        [canHaveADeputyOrRepresentative]="canHaveADeputyOrRepresentative"
        [responsibleRolePurpose]="ResponsiblePurpose.Residence"
        [purposeData]="residence"
        [readonly]="readonly"
        [alwaysShownRoles]="responsibleRoles"
        styleRoleClass="field col-12 {{ canHaveADeputyOrRepresentative ? 'lg:col-3' : 'lg:col-6' }}"
        styleOrganizationClass="field col-12 lg:col-3"
        styleRepresentativeClass="field col-12 lg:col-3"
        styleDeputyClass="field col-12 lg:col-3"
        class="p-fluid formgrid grid"
      ></app-candidate-responsible-representative-group>
    </p-fieldset>

    <p-fieldset [legend]="t('workplace.title')" styleClass="relative">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 lg:col-6">
          <app-candidate-work-start-date
            [form]="form"
            class="field"
            [readonly]="readonly"
          ></app-candidate-work-start-date>
        </div>
        <div class="field col-12 lg:col-6">
          <label for="personalOnboardingDateTime">{{ t("personalOnboardingDateTime.title") }}</label>

          <app-date-time
            [form]="form.controls.personalOnboardingDateTime"
            inputId="personalOnboardingDateTime"
            [readonly]="readonly"
          >
          </app-date-time>
        </div>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('bank.title')" styleClass="relative">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 lg:col-2">
          <app-candidate-residence-bank-appointment-date
            [form]="form"
            [readonly]="readonly"
            [showClear]="true"
            class="field"
          ></app-candidate-residence-bank-appointment-date>
        </div>
        <div class="field col-12 lg:col-5">
          <label for="residenceBank">{{ t("residenceBank.title") }}</label>
          <input
            id="residenceBank"
            pInputText
            formControlName="residenceBank"
            [maxLength]="300"
            [readonly]="readonly"
          />
        </div>
        <div class="field col-12 lg:col-5">
          <label for="residenceBankContactPartner">{{ t("residenceBankContactPartner.title") }}</label>
          <input
            id="residenceBankContactPartner"
            pInputText
            formControlName="residenceBankContactPartner"
            [readonly]="readonly"
          />
        </div>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('medicalExamination.title')" styleClass="relative">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 lg:col-6">
          <label for="appointmentHealthDepartmentDate">{{ t("appointmentHealthDepartmentDate.title") }}</label>

          <app-date-time
            [form]="form.controls.appointmentHealthDepartmentDate"
            inputId="appointmentHealthDepartmentDate"
            [readonly]="readonly"
          >
          </app-date-time>
        </div>

        <div class="field col-12 lg:col-6 pt-5">
          <div class="field-checkbox pt-2">
            <p-checkbox
              id="appointmentHealthDepartmentKept"
              inputId="appointmentHealthDepartmentKept"
              [binary]="true"
              [readonly]="readonly"
              formControlName="appointmentHealthDepartmentKept"
            ></p-checkbox>
            <label for="appointmentHealthDepartmentKept">{{ t("appointmentHealthDepartmentKept.title") }}</label>
          </div>
        </div>

        <div class="field col-12 lg:col-3">
          <app-candidate-residence-medical-examination-date
            [form]="form"
            [readonly]="readonly"
            [showClear]="true"
            class="field"
          ></app-candidate-residence-medical-examination-date>
        </div>
        <div class="field col-12 lg:col-3">
          <app-candidate-residence-company-doctor-date
            [form]="form"
            [readonly]="readonly"
            [showClear]="true"
            class="field"
          ></app-candidate-residence-company-doctor-date>
        </div>
        <div class="field col-12 lg:col-3 pt-5">
          <div class="field-checkbox pt-2">
            <p-checkbox
              id="recognitionMedicalCertificate"
              inputId="recognitionMedicalCertificate"
              [binary]="true"
              [readonly]="readonly"
              formControlName="recognitionMedicalCertificate"
            ></p-checkbox>
            <label for="recognitionMedicalCertificate">{{ t("recognitionMedicalCertificate.title") }}</label>
          </div>
        </div>
        <div class="field col-12 lg:col-3">
          <label for="suitabilityForWork">{{ t("suitabilityForWork.title") }}</label>
          <p-dropdown
            inputId="suitabilityForWork"
            [options]="suitabilityForWork$ | async"
            formControlName="suitabilityForWork"
            [showClear]="true"
            [placeholder]="t('suitabilityForWork.placeholder')"
            [readonly]="readonly"
          ></p-dropdown>
        </div>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('certificateOfConduct.title')" styleClass="relative">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 lg:col-4">
          <label for="certificateOfConduct">{{ t("certificateOfConduct.title") }}</label>
          <p-multiSelect
            inputId="certificateOfConduct"
            [options]="certificateOfConducts$ | async"
            formControlName="certificateOfConduct"
            [showClear]="true"
            [placeholder]="t('certificateOfConduct.placeholder')"
            [readonly]="readonly"
            class="field"
          ></p-multiSelect>
        </div>

        <div class="field col-12 lg:col-2 pt-5">
          <div class="field-checkbox pt-2">
            <p-checkbox
              id="certificateOfConductRequested"
              inputId="certificateOfConductRequested"
              [binary]="true"
              [readonly]="readonly"
              formControlName="certificateOfConductRequested"
            ></p-checkbox>
            <label for="certificateOfConductRequested">{{ t("certificateOfConductRequested.title") }}</label>
          </div>
        </div>

        <div class="field col-12 lg:col-6">
          <app-candidate-residence-police-record-date
            [form]="form"
            [readonly]="readonly"
            [showClear]="true"
            class="field"
          ></app-candidate-residence-police-record-date>
        </div>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('registrationAuthority.title')" styleClass="relative">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 lg:col-4">
          <app-candidate-residence-registration-office-date
            [form]="form"
            [readonly]="readonly"
            [showClear]="true"
            class="field"
          ></app-candidate-residence-registration-office-date>
        </div>

        <div class="field col-12 lg:col-4">
          <label for="registrationAuthorityAppointmentNumber">{{
            t("registrationAuthorityAppointmentNumber.title")
          }}</label>
          <input
            id="registrationAuthorityAppointmentNumber"
            pInputText
            formControlName="registrationAuthorityAppointmentNumber"
            [maxLength]="300"
            [readonly]="readonly"
          />
        </div>

        <div class="field col-12 lg:col-4">
          <label for="registrationAuthority">{{ t("registrationAuthority.title") }}</label>
          <input
            id="registrationAuthority"
            pInputText
            formControlName="registrationAuthority"
            [maxLength]="300"
            [readonly]="readonly"
          />
        </div>
      </div>
    </p-fieldset>

    <p-fieldset [legend]="t('residencePermit.title')" class="relative" formArrayName="residencePermits">
      <div *ngFor="let control of form.controls.residencePermits.controls; index as i" [formGroupName]="i">
        <app-candidate-residence-permit-details
          [form]="control"
          [candidateId]="candidateId"
          [candidateOrganizationId]="candidateOrganizationId"
          [readonly]="readonly"
          [index]="i"
          (deleteClicked)="removeResidencePermit(i)"
        ></app-candidate-residence-permit-details>
      </div>

      <div class="p-fluid formgrid grid align-items-center">
        <div class="field col-12 lg:col-3">
          <p-button
            icon="pi pi-plus"
            [label]="t('residencePermitAdd.title')"
            [disabled]="readonly || form.controls.residencePermits.disabled"
            (onClick)="addResidencePermit()"
          ></p-button>
        </div>
      </div>
    </p-fieldset>
  </form>
</ng-container>
