import { Component, OnInit } from "@angular/core";
import { TranslocoService, translate } from "@jsverse/transloco";
import { omit } from "lodash";
import { SettingsService } from "../../shared/services/settings.service";
import { MessageService } from "../message/message.service";
import { DocumentRequirementsForm } from "./document-requirement-form.model";
import { DocumentRequirementFormService } from "./document-requirement-form.service";
import { DocumentRequirementService } from "./document-requirement.service";

@Component({
  selector: "app-document-requirements",
  templateUrl: "./document-requirements.component.html",
  standalone: false,
})
export class DocumentRequirementsComponent implements OnInit {
  protected readonly language = this.transloco.getActiveLang();
  protected form: DocumentRequirementsForm = this.formService.createForm();

  get organizationId(): string {
    return this.settingsService.organizationId;
  }

  constructor(
    private readonly transloco: TranslocoService,
    private readonly settingsService: SettingsService,
    private readonly messageService: MessageService,
    private readonly documentRequirementService: DocumentRequirementService,
    private readonly formService: DocumentRequirementFormService
  ) {}

  ngOnInit(): void {
    this.documentRequirementService.getAll({ organizationId: this.organizationId }).subscribe((requirements) => {
      this.form = this.formService.createForm(requirements);
    });
  }

  submit(): void {
    const requirements = this.form.controls.requirements.controls
      .filter((requirement) => requirement.dirty)
      .map((requirement) => requirement.getRawValue())
      .map((requirement) => omit(requirement, ["id", "organizationId", "changedAt", "changedBy"]));

    this.documentRequirementService.upsertMany({ organizationId: this.organizationId, requirements }).subscribe(() => {
      this.messageService.add({
        severity: "success",
        summary: translate("documentRequirements.updated.title", null, this.language),
        detail: translate("documentRequirements.updated.message", null, this.language),
      });
      this.settingsService.navigateToMain();
    });
  }

  onCloseClick(): void {
    this.settingsService.navigateToMain();
  }
}
