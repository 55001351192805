import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { Comment, CommentUpdateInput } from "@ankaadia/graphql";
import { clone } from "lodash";

@Component({
  selector: "app-candidate-comment-box",
  templateUrl: "./candidate-comment-box.component.html",
  styleUrl: "./candidate-comment-box.component.scss",
  standalone: false,
})
export class CandidateCommentBoxComponent {
  editing: boolean;

  @Input()
  comment: Comment;

  @Input()
  readonly: boolean;

  @Output()
  readonly commentChange = new EventEmitter<CommentUpdateInput>();

  private lastTarget: EventTarget;

  constructor(private readonly element: ElementRef) {}

  updateComment(comment: Comment, content: string): void {
    const newComment: CommentUpdateInput = clone(comment);
    newComment.comment = content;
    this.commentChange.emit(newComment);
  }

  @HostListener("click", ["$event"])
  onClickIn(event: Event): void {
    this.lastTarget = event.target;
    this.editing = true;
    event.preventDefault();
  }

  @HostListener("document:click", ["$event"])
  onClickOut(event: Event): void {
    if (this.lastTarget !== event.target && this.editing && !this.element.nativeElement.contains(event.target)) {
      this.editing = false;
    }
  }
}
