<p-sidebar [(visible)]="showSidebar" styleClass="p-sidebar-md">
  <app-document-edit-dialog
    *ngIf="showSidebar && document"
    [document]="document"
    [readonly]="readonly"
    [mode]="DocumentMode.Candidate"
    [processLanguage]="language"
    [selectedField]="field"
    [candidateForm]="form"
    [additionalForeignKeySourceData]="additionForeignKeySourceData"
    (closed)="close()"
    (saved)="close()"
  ></app-document-edit-dialog>
</p-sidebar>
