import { Directive } from "@angular/core";
import { TreeTable } from "primeng/treetable";

@Directive({ selector: "p-treeTable", standalone: false })
export class TreeTableOptionsDirective {
  constructor(private readonly treeTable: TreeTable) {
    this.treeTable.scrollable = true;
    this.treeTable.scrollHeight = "flex";
  }
}
