import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { VisaCandidateDataAt } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";

const nameOf = nameofFactory<VisaCandidateDataAt>();

@Component({
  selector: "app-candidate-rwr-file-number",
  templateUrl: "./candidate-rwr-file-number.component.html",
  standalone: false,
})
export class CandidateRwrFileNumberComponent implements OnInit, OnDestroy {
  private readonly language = this.transloco.getActiveLang();

  @Input({ required: true })
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  @Output()
  readonly valueChanged = new EventEmitter<string>();

  get control(): FormControl<string | null> {
    return this.form.get(nameOf("fileNumber")) as FormControl<string | null>;
  }

  set control(value: FormControl<string | null>) {
    this.form.setControl(nameOf("fileNumber"), value);
  }

  constructor(private readonly transloco: TranslocoService) {}

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<string | null>(null);
    this.control.valueChanges.subscribe((value) => this.valueChanged.emit(value));
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("fileNumber"), { emitEvent: false });
  }
}
