<ng-container *appTranslate="let t">
  <form [formGroup]="form">
    <div class="p-fluid formgrid grid">
      <div class="field col-12 xl:col-6">
        <label for="name">{{ t("name.title") }}</label>
        <input id="name" pInputText formControlName="name" maxlength="150" />
        <small class="p-error" *ngIf="form.controls.name.errors?.required">
          {{ t("name.required") }}
        </small>
      </div>

      <div class="field col-12 xl:col-3" *ngIf="!myOrgMode">
        <label for="cooperationType">{{ t("cooperationType.title") }}</label>
        <p-dropdown
          inputId="cooperationType"
          [options]="cooperationTypes | async"
          formControlName="cooperationType"
          [placeholder]="t('cooperationType.placeholder')"
          [showClear]="true"
        ></p-dropdown>
      </div>

      <div class="field col-12 xl:col-3">
        <label for="type">{{ t("organizationType.title") }}</label>
        <p-dropdown
          inputId="type"
          [options]="organizationTypes | async"
          formControlName="type"
          [placeholder]="t('organizationType.placeholder')"
          [showClear]="true"
        ></p-dropdown>
        <small class="p-error" *ngIf="form.controls.type.errors?.required">
          {{ t("organizationType.required") }}
        </small>
      </div>

      <div class="field col-12">
        <p-fieldset [legend]="t('address.title')">
          <div class="grid">
            <div class="field col-12">
              <label for="address">{{ t("address.title") }}</label>
              <textarea
                id="address"
                pInputTextarea
                rows="4"
                [placeholder]="t('address.placeholder')"
                formControlName="address"
                maxlength="500"
              ></textarea>
            </div>

            <div class="field col-12 xl:col-3">
              <label for="zipcode">{{ t("zipCode.title") }}</label>
              <input
                id="zipcode"
                pInputText
                [placeholder]="t('zipCode.placeholder')"
                formControlName="zipcode"
                maxlength="30"
              />
            </div>

            <div class="field col-12 xl:col-3">
              <label for="city">{{ t("city.title") }}</label>
              <input
                id="city"
                pInputText
                [placeholder]="t('city.placeholder')"
                formControlName="city"
                maxlength="100"
              />
            </div>

            <div class="field col-12 xl:col-3">
              <label for="region">{{ t("region.title") }}</label>
              <input
                id="region"
                pInputText
                [placeholder]="t('region.placeholder')"
                formControlName="region"
                maxlength="100"
              />
            </div>

            <div class="field col-12 xl:col-3">
              <label for="country">{{ t("country.title") }}</label>
              <p-dropdown
                inputId="country"
                formControlName="country"
                [options]="countries | async"
                [placeholder]="t('country.placeholder')"
                [showClear]="true"
              ></p-dropdown>
            </div>
          </div>
        </p-fieldset>
      </div>

      <div class="field col-12 xl:col-4">
        <label for="urlToWebsite">{{ t("website.title") }}</label>
        <input id="urlToWebsite" pInputText formControlName="urlToWebsite" />
        <small
          class="p-error"
          *ngIf="form.controls.urlToWebsite.errors?.pattern || form.controls.urlToWebsite.errors?.maxLength"
        >
          {{ t("url.invalid") }}
        </small>
      </div>

      <div class="field col-12 xl:col-4">
        <label for="emailAdress">{{ t("email.title") }}</label>
        <input id="emailAdress" pInputText formControlName="emailAdress" />
        <small class="p-error" *ngIf="form.controls.emailAdress.errors?.required">
          {{ t("email.required") }}
        </small>
        <small class="p-error" *ngIf="form.controls.emailAdress.errors?.pattern">
          {{ t("email.invalid") }}
        </small>
      </div>

      <div class="field col-12 xl:col-4">
        <label for="parentName">{{ t("organization.parent.title") }}</label>
        <p-dropdown
          inputId="parentName"
          *ngIf="parentName == null"
          [options]="selectableCompanies"
          optionLabel="name"
          optionValue="id"
          formControlName="parentOrganizationId"
          [placeholder]="t('organization.parent.placeholder')"
          filterBy="name"
          [autofocusFilter]="true"
          [showClear]="true"
        ></p-dropdown>
        <input
          *ngIf="parentName != null"
          type="text"
          id="parentName"
          pInputText
          [(ngModel)]="parentName"
          [ngModelOptions]="{ standalone: true }"
          disabled="true"
        />
      </div>
      <div class="field col-12 xl:col-3" *ngIf="myOrgMode">
        <label for="name">{{ t("systemSettings.notifiedUsersForCandidateDeletion") }}</label>
        <p-multiSelect
          [options]="users"
          formControlName="notifyUsersOnDeletionRequest"
          optionLabel="displayName"
        ></p-multiSelect>
      </div>
      <div class="field col-12 xl:col-3">
        <label for="code">{{ t("code.title") }}</label>
        <input
          id="code"
          pInputText
          formControlName="code"
          maxlength="6"
          [class.p-disabled]="editOrg?.id && !isMasterUser"
          [readonly]="editOrg?.id && !isMasterUser"
        />
        <small class="p-error" *ngIf="form.controls.code.errors?.required">
          {{ t("code.required") }}
        </small>
        <small class="p-error" *ngIf="form.controls.code.errors?.pattern">
          {{ t("code.required") }}
        </small>
      </div>

      <div class="field col-12 xl:col-3">
        <label for="prefix">{{ t("prefix.title") }}</label>
        <input id="prefix" pInputText formControlName="prefix" maxlength="2" />
        <small class="p-error" *ngIf="form.controls.prefix.errors?.required">
          {{ t("prefix.required") }}
        </small>
        <small class="p-error" *ngIf="form.controls.prefix.errors?.pattern">
          {{ t("prefix.required") }}
        </small>
      </div>

      <div class="field col-12 xl:col-3">
        <label for="configToken">{{ t("configToken.title") }}</label>
        <input
          id="configToken"
          pInputText
          formControlName="configToken"
          maxlength="20"
          [class.p-disabled]="!isMasterUser"
          [readonly]="!isMasterUser"
        />
        <small class="p-error" *ngIf="form.controls.configToken.errors?.required">
          {{ t("configToken.required") }}
        </small>
      </div>

      <div class="field-checkbox col-12" *ngIf="creatorOrgName">
        <p-checkbox
          [binary]="true"
          inputId="allowOrganizationManagementbyCreator"
          formControlName="allowOrganizationManagementbyCreator"
        ></p-checkbox>
        <label for="allowOrganizationManagementbyCreator">
          {{ t("organization.allowManagement", { name: creatorOrgName }) }}
        </label>
      </div>

      <div class="field col-12">
        <label for="logo">{{ t("logo.title") }}</label>
        <div class="flex">
          <div class="logo-preview-wrapper align-self-center mr-3" *ngIf="logoDownloadUrl">
            <img class="logo-preview" [src]="$any(logoDownloadUrl)" default="/assets/blank.jpeg" />
          </div>
          <div class="logo-upload-wrapper">
            <p-fileUpload
              #logoUpload
              inputId="logo"
              name="logo"
              [maxFileSize]="64 * 1024 * 1024"
              [showUploadButton]="false"
              [showCancelButton]="false"
              [cancelLabel]="logoForceChange"
              accept="image/*"
              [chooseLabel]="t('file.choose')"
              [invalidFileSizeMessageSummary]="t('primeng.invalidFileSizeMessageSummary')"
              [invalidFileSizeMessageDetail]="t('primeng.invalidFileSizeMessageDetail')"
              [invalidFileTypeMessageSummary]="t('primeng.invalidFileTypeMessageSummary')"
              [invalidFileTypeMessageDetail]="t('primeng.invalidFileTypeMessageDetail')"
              [invalidFileLimitMessageDetail]="t('primeng.invalidFileLimitMessageDetail')"
              [invalidFileLimitMessageSummary]="t('primeng.invalidFileLimitMessageSummary')"
              [customUpload]="true"
              (onSelect)="selectLogo($event)"
              (uploadHandler)="uploadLogo($event)"
            >
              <ng-template let-file pTemplate="file">
                <div class="flex flex-row justify-content-evenly mt-4">
                  <div>{{ file.name }}</div>
                  <div>
                    {{
                      (file.size > 1024 * 1024 ? file.size / 1024 / 1024 : file.size / 1024)
                        | translocoDecimal
                          : { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    }}
                    {{ file.size > 1024 * 1024 ? t("common.unit.mb") : t("common.unit.kb") }}
                  </div>
                </div>
              </ng-template>

              <ng-template let-files pTemplate="content">
                <div class="p-fileupload-prompt-wrapper" *ngIf="!files || files.length === 0">
                  <div class="p-fileupload-prompt">
                    {{ t("file.drop") }}
                  </div>
                </div>
              </ng-template>
            </p-fileUpload>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>
