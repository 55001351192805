import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { CollectionAutoTemplateVariableEnum, getCollectionAutoTemplateVariableValue } from "@ankaadia/ankaadia-shared";
import { CollectionAutoFilterTemplateFragment, CollectionCreatedByAutoFilterTemplateFragment } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { Subject } from "rxjs";
import { BooleanPipe } from "../../../../shared/pipes/boolean.pipe";
import { AppDateTimePipe } from "../../../../shared/pipes/date.pipe";
import { PipeDescription, TableColumn, TableOperation } from "../../../../shared/table/table.model";
import { MessageService } from "../../../message/message.service";
import { CollectionAutoFilterTemplateService } from "../collection-auto-filter-template.service";

interface TableData {
  collectionName: string;
  collectionId: string;
  organizationId: string;
  variables: string;
  inSync: boolean;
  creationDate: Date;
}

@Component({
  selector: "app-collection-auto-template-collections",
  templateUrl: "./collection-auto-template-collections.component.html",
  standalone: false,
})
export class CollectionAutoTemplateCollectionsComponent implements OnChanges, OnInit {
  readonly captionOperations: TableOperation[] = [
    {
      label: translate("autoCollectionTemplate.syncAll"),
      icon: PrimeIcons.SYNC,
      operation: (_: any, event: Event): void => this.syncAll(event),
    },
    {
      label: translate("autoCollectionTemplate.attach.title"),
      icon: PrimeIcons.LINK,
      operation: (_: any, _event: Event): void => {
        this.showDialog = true;
      },
    },
  ];

  readonly tableOperations: TableOperation[] = [
    {
      label: translate("autoCollectionTemplate.sync"),
      icon: PrimeIcons.SYNC,
      disabled: (data: TableData): boolean => data.inSync,
      operation: (x: TableData, event: Event): void => this.sync(x, event),
    },
    {
      label: translate("autoCollectionTemplate.detach"),
      icon: PrimeIcons.BAN,
      operation: (x: TableData, event: Event): void => this.detach(x, event),
    },
  ];

  readonly columns: TableColumn[] = [
    {
      header: translate("autoCollectionTemplate.collections.collection"),
      fieldname: "collectionName",
      sortable: true,
      includeInGlobalFilter: true,
      routeLink: (x: TableData): any[] => ["/app/collections/edit", x.organizationId, x.collectionId],
    },
    {
      header: translate("autoCollectionTemplate.collections.variables"),
      fieldname: "variables",
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("autoCollectionTemplate.collections.inSync"),
      fieldname: "inSync",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(BooleanPipe),
    },
    {
      header: translate("autoCollectionTemplate.collections.creationDate"),
      fieldname: "creationDate",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(AppDateTimePipe),
    },
  ];

  @Input({ required: true }) collectionAutoTemplate: CollectionAutoFilterTemplateFragment;

  collectionAutoTemplate$: Subject<CollectionAutoFilterTemplateFragment> =
    new Subject<CollectionAutoFilterTemplateFragment>();

  collections: TableData[] = [];

  showDialog = false;

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly autoFilterTemplateService: CollectionAutoFilterTemplateService
  ) {}

  ngOnInit(): void {
    this.collectionAutoTemplate$.subscribe((collectionAutoTemplate) => {
      this.autoFilterTemplateService
        .getByTemplateCreatedAutoCollections({
          id: collectionAutoTemplate.id,
          organizationId: collectionAutoTemplate.organizationId,
        })
        .subscribe((collections) => {
          this.collections = collections.map((collection) => this.mapToTableData(collection, collectionAutoTemplate));
        });
    });
  }

  private mapToTableData(
    collection: CollectionCreatedByAutoFilterTemplateFragment,
    collectionAutoTemplate: CollectionAutoFilterTemplateFragment
  ): {
    collectionName: string;
    collectionId: string;
    organizationId: string;
    variables: string;
    inSync: boolean;
    creationDate: Date;
  } {
    const result = {
      collectionName: collection.name,
      collectionId: collection.id,
      organizationId: collection.organizationId,
      variables: collection.autoCollectionSettings?.templateMapping?.variableMappings
        ?.map(
          (variable) =>
            `${getCollectionAutoTemplateVariableValue(CollectionAutoTemplateVariableEnum[variable.variable])}='${variable.label}'`
        )
        .join(", "),
      inSync:
        !!collectionAutoTemplate.latestChangedAtWithChildren &&
        collectionAutoTemplate.latestChangedAtWithChildren?.getTime() ===
          collection.autoCollectionSettings?.templateMapping?.templateLatestChangedAtWithChildren?.getTime(),
      creationDate: collection.creationDate,
    };
    return result;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.collectionAutoTemplate) {
      this.collectionAutoTemplate$.next(changes.collectionAutoTemplate.currentValue);
    }
  }

  dialogClosed(): void {
    this.showDialog = false;
    this.collectionAutoTemplate$.next(this.collectionAutoTemplate);
  }

  sync(x: TableData, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("autoCollectionTemplate.confirmSync"),
      accept: () => {
        this.autoFilterTemplateService
          .syncCollectionWithTemplate({
            organizationId: x.organizationId,
            templateId: this.collectionAutoTemplate.id,
            collectionId: x.collectionId,
          })
          .subscribe((update) => {
            const index = this.collections.findIndex((c) => c.collectionId === update.id);
            this.collections.splice(index, 1, this.mapToTableData(update, this.collectionAutoTemplate));
            this.messageService.add({
              severity: "success",
              summary: translate("autoCollectionTemplate.syncedSuccessfully"),
            });
          });
      },
    });
  }

  syncAll(event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("autoCollectionTemplate.confirmSyncAll"),
      accept: () => {
        this.autoFilterTemplateService
          .syncAllCollectionsWithTemplate({
            organizationId: this.collectionAutoTemplate.organizationId,
            id: this.collectionAutoTemplate.id,
          })
          .subscribe((update) => {
            this.collections.splice(
              0,
              this.collections.length,
              ...update.map((c) => this.mapToTableData(c, this.collectionAutoTemplate))
            );
            this.messageService.add({
              severity: "success",
              summary: translate("autoCollectionTemplate.syncedAllSuccessfully"),
            });
          });
      },
    });
  }

  detach(x: TableData, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("autoCollectionTemplate.confirmDetach"),
      accept: () => {
        this.autoFilterTemplateService
          .detachCollectionFromTemplate({
            organizationId: x.organizationId,
            templateId: this.collectionAutoTemplate.id,
            collectionId: x.collectionId,
          })
          .subscribe((update) => {
            this.collections = this.collections.filter((x) => x.collectionId !== update.id);
            this.messageService.add({
              severity: "success",
              summary: translate("autoCollectionTemplate.detachedSuccessfully"),
            });
          });
      },
    });
  }
}
