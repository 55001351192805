import { Component, DestroyRef, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormArray } from "@angular/forms";
import { BenefitTimeInterval, nameofFactory } from "@ankaadia/ankaadia-shared";
import {
  CalculationBasis,
  LaborAgreement,
  LaborAgreementBenefit,
  StaticDataModel,
  StaticDataType,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { isNil, TranslocoService } from "@jsverse/transloco";
import { OverlayPanel } from "primeng/overlaypanel";
import { Observable } from "rxjs";
import { removeControls } from "../../../shared/services/form.helper";
import { StaticDataContextEntry, StaticDataService } from "../../../shared/static-data/static-data.service";
import {
  ContractTemplateBenefitForm,
  FormContractTemplateBenefit,
} from "../contract-template-dialog/contract-template-form.model";
import { ContractTemplateFormService } from "../contract-template-dialog/contract-template-form.service";
import {
  Override,
  OverrideIntersectionKey,
  OverrideLabelOptions,
  OverrideService,
} from "../contract-template-override.service";

type OverrideKey = OverrideIntersectionKey<LaborAgreementBenefit, FormContractTemplateBenefit>;
type OverrideModel<TKey extends OverrideKey> = Override<LaborAgreementBenefit, FormContractTemplateBenefit, TKey>;
type Options<TKey extends OverrideKey> = OverrideLabelOptions<LaborAgreementBenefit, FormContractTemplateBenefit, TKey>;
const nameOf = nameofFactory<ContractTemplateBenefitsComponent>();

@Component({
  selector: "app-contract-template-benefits",
  templateUrl: "./contract-template-benefits.component.html",
  styleUrl: "./contract-template-benefits.component.scss",
  standalone: false,
})
export class ContractTemplateBenefitsComponent implements OnChanges {
  private readonly language = this.transloco.getActiveLang();

  protected readonly CalculationBasis = CalculationBasis;
  protected readonly calculationBases = this.staticData.transformEnumToStaticDataModel(
    "CalculationBasis",
    CalculationBasis
  );

  protected readonly timeIntervals = this.staticData.transformEnumToStaticDataModel(
    "BenefitTimeInterval",
    BenefitTimeInterval
  );

  protected benefitTypes: StaticDataModel[] = [];
  protected newBenefit?: ContractTemplateBenefitForm = null;

  @Input({ required: true })
  organizationId: string;

  @Input({ required: true })
  immigrationCountry?: SupportedImmigrationCountry;

  @Input({ required: true })
  form: FormArray<ContractTemplateBenefitForm>;

  @Input({ required: false })
  laborAgreement?: LaborAgreement = null;

  @ViewChild(OverlayPanel)
  protected overlayPanel: OverlayPanel;

  get selectableBenefitTypes(): StaticDataModel[] {
    const benefits = this.form.getRawValue();
    return this.benefitTypes.filter(({ value }) => {
      return !benefits.some((benefit) => benefit.type === value);
    });
  }

  get canAdd(): boolean {
    if (isNil(this.newBenefit)) {
      return false;
    }

    const { type } = this.newBenefit.getRawValue();
    if (isNil(type)) {
      return false;
    }

    const benefits = this.form.getRawValue();
    if (!isNil(type) && benefits.some((benefit) => benefit.type === type)) {
      return false;
    }

    return true;
  }

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly transloco: TranslocoService,
    private readonly staticData: StaticDataService,
    private readonly formService: ContractTemplateFormService,
    private readonly overrideService: OverrideService<LaborAgreementBenefit, FormContractTemplateBenefit>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes[nameOf("organizationId")] || changes[nameOf("immigrationCountry")]) {
      const { organizationId } = this;
      const immigrationCountry = this.immigrationCountry ?? SupportedImmigrationCountry.Unknown;
      const context = { organizationId, immigrationCountry };
      this.getStaticData(StaticDataType.Benefits, context)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((benefits) => (this.benefitTypes = benefits));
    }
  }

  protected add(event: Event): void {
    this.newBenefit = this.formService.createBenefitForm({
      timeInterval: BenefitTimeInterval.Monthly,
      calculationBasis: CalculationBasis.AbsoluteAmount,
      amount: 0,
    });
    this.overlayPanel.show(event);
  }

  protected create(benefit: ContractTemplateBenefitForm): void {
    if (!isNil(benefit)) {
      this.form.push(benefit);
      this.form.markAsDirty();
    }
  }

  protected delete(row: ContractTemplateBenefitForm): void {
    removeControls(this.form, row);
    this.form.markAsDirty();
  }

  protected typeLabel(benefit: ContractTemplateBenefitForm): string {
    const type = benefit.controls.type.value;
    return this.benefitTypes.find(({ value }) => value === type)?.label ?? type;
  }

  protected getOverride<TKey extends OverrideKey>(
    row: ContractTemplateBenefitForm,
    key: TKey
  ): OverrideModel<TKey> | null {
    if (isNil(this.laborAgreement)) {
      return null;
    }

    const benefit = row.getRawValue();
    const benefits = this.laborAgreement.benefits ?? [];
    const original = benefits.find(({ type }) => benefit.type === type);
    return isNil(original) ? null : this.overrideService.getOverride(original, benefit, key);
  }

  protected getOverrideLabel<TKey extends OverrideKey>(override: OverrideModel<TKey>, options?: Options<TKey>): string {
    return this.overrideService.getOverrideLabel(override, options);
  }

  private getStaticData(type: StaticDataType, context: StaticDataContextEntry): Observable<StaticDataModel[]> {
    return this.staticData.getStaticData(type, this.language, context);
  }
}
