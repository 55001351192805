import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { EmployerHistoryEntryInput } from "@ankaadia/graphql";
import { assign, cloneDeep, concat, without } from "lodash";
import { Table } from "primeng/table";
import { v4 as uuidv4 } from "uuid";
import { CandidateEmployerOrganizationService } from "../../candidate-reusables/candidate-employer-organization/candidate-employer-organization.service";

@Component({
  selector: "app-candidate-employer-history-dialog",
  templateUrl: "./candidate-employer-history-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CandidateEmployerHistoryDialogComponent {
  private readonly itemsBeingEdited: Record<string, EmployerHistoryEntryInput> = {};

  readonly employers = this.employerService.employers;

  @Input()
  items: EmployerHistoryEntryInput[];

  @Output()
  readonly saved = new EventEmitter<EmployerHistoryEntryInput[]>();

  @Output()
  readonly closed = new EventEmitter<void>();

  @ViewChild(Table)
  table: Table;

  constructor(
    private readonly employerService: CandidateEmployerOrganizationService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  add(): void {
    const item = assign(new EmployerHistoryEntryInput(), { id: uuidv4(), createdAt: new Date() });
    this.items = concat(this.items, item);
    this.table.initRowEdit(item);
    this.changeDetector.markForCheck();
  }

  delete(item: EmployerHistoryEntryInput): void {
    this.items = without(this.items, item);
    this.changeDetector.markForCheck();
  }

  init(item: EmployerHistoryEntryInput): void {
    this.itemsBeingEdited[item.id] = cloneDeep(item);
  }

  save(item: EmployerHistoryEntryInput): void {
    delete this.itemsBeingEdited[item.id];
  }

  cancel(item: EmployerHistoryEntryInput): void {
    if (!item.employerId) {
      this.delete(item);
      return;
    }

    const index = this.items.findIndex((x) => x.id === item.id);
    this.items[index] = this.itemsBeingEdited[item.id];
    this.changeDetector.markForCheck();
    delete this.itemsBeingEdited[item.id];
  }

  submit(): void {
    const items = this.items.filter((x) => x?.employerId);
    this.saved.emit(items);
  }

  updateEmployerName(item: EmployerHistoryEntryInput, employerId: string): void {
    const employer = this.employers.find((x) => x.id === employerId);
    item.employerName = employer.name;
  }
}
