import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { supportedLanguageFlags2LetterISO } from "@ankaadia/ankaadia-shared";
import { StaticDataModel } from "@ankaadia/graphql";

@Component({
  selector: "app-language-dropdown",
  templateUrl: "./language-dropdown.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LanguageDropdownComponent {
  readonly flags = supportedLanguageFlags2LetterISO();

  @Input()
  options: StaticDataModel[];

  @Input()
  selectedLanguage: string;

  @Output()
  readonly selectedLanguageChange = new EventEmitter<string>();
}
