import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { VisaCandidateDataAt } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";

const nameOf = nameofFactory<VisaCandidateDataAt>();

@Component({
  selector: "app-candidate-finger-print-date",
  templateUrl: "./candidate-finger-print-date.component.html",
  standalone: false,
})
export class CandidateFingerPrintDateComponent implements OnDestroy, OnInit {
  private readonly language = this.transloco.getActiveLang();

  @Input({ required: true })
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  showClear = false;

  @Input()
  readonly: boolean;

  get control(): FormControl<Date | null> {
    return this.form.get(nameOf("fingerPrintDate")) as FormControl<Date | null>;
  }

  set control(value: FormControl<Date | null>) {
    this.form.setControl(nameOf("fingerPrintDate"), value);
  }

  constructor(private readonly transloco: TranslocoService) {}

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<Date | null>(null);
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("fingerPrintDate"), { emitEvent: false });
  }
}
