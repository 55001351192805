import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { RecognitionPathEntryDe, StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { StaticDataContextEntry, StaticDataService } from "../../../shared/static-data/static-data.service";

const nameOf = nameofFactory<RecognitionPathEntryDe>();
@Component({
  selector: "app-candidate-recognition-center",
  templateUrl: "./candidate-recognition-center.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CandidateRecognitionCenterComponent implements OnInit, OnChanges, OnDestroy {
  private readonly language = this.transloco.getActiveLang();

  @Input()
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  protected recognitionCenters: StaticDataModel[];

  @Input()
  staticDataContext: StaticDataContextEntry;

  @Input()
  federalStateControl: FormControl<string>;

  @Input()
  hideControl: boolean;

  @Input()
  readonly: boolean;

  @Input()
  enableOnDestroy = true;

  @Output()
  readonly valueChanged = new EventEmitter<string>();

  get control(): FormControl<string> {
    return this.form.get(nameOf("recognitionCenter")) as FormControl<string>;
  }

  set control(value: FormControl<string>) {
    this.form.setControl(nameOf("recognitionCenter"), value);
  }

  constructor(
    private readonly transloco: TranslocoService,
    private readonly staticDataService: StaticDataService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<string>(null);

    this.updateRecognitionCenter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.processLanguage) {
      this.updateRecognitionCenter();
    }
  }

  ngOnDestroy(): void {
    if (this.enableOnDestroy) {
      this.form.removeControl(nameOf("recognitionCenter"), { emitEvent: false });
    }
  }

  private updateRecognitionCenter(): void {
    this.staticDataService
      .getStaticData(StaticDataType.RecognitionCenter, this.processLanguage ?? this.language, this.staticDataContext)
      .subscribe((x) => {
        this.recognitionCenters = x;
        this.control?.valueChanges?.subscribe((value) => this.valueChanged.emit(value));
        this.changeDetector.markForCheck();
      });
  }
}
