import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import {
  EducationCourseAttendance,
  EducationCourseCandidatePerformanceForListFragment,
  EducationCourseCandidateStatus,
  EducationCourseCandidateWeeklyPerformanceForListFragment,
  EducationCoursePerformanceForListFragment,
  EducationCoursePerformanceStatus,
  EducationCourseTotalPerformanceStateUpdateInput,
  EducationCourseWeek,
  EducationCourseWeeklyAttendanceUpdateInput,
  EducationCourseWeeklyCommentUpdateInput,
  EducationCourseWeeklyPerformanceUpdateInput,
  EducationCourseWeekPrefillInput,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { cloneDeep, pick } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { TableEditCancelEvent, TableEditCompleteEvent, TableEditInitEvent } from "primeng/table";
import { StaticDataService } from "../../../../shared/static-data/static-data.service";
import {
  EducationCourseAttendanceIcons,
  EducationCourseCandidateStatusIcons,
  EducationCoursePerformanceStatusIcons,
} from "./education-course-performances.model";

const nameOf = nameofFactory<
  EducationCourseCandidatePerformanceForListFragment & EducationCourseCandidateWeeklyPerformanceForListFragment
>();

@Component({
  selector: "app-education-course-performances",
  templateUrl: "./education-course-performances.component.html",
  styleUrl: "./education-course-performances.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class EducationCoursePerformancesComponent {
  private originalValue: unknown;

  readonly EducationCourseCandidateStatusIcons = EducationCourseCandidateStatusIcons;
  readonly EducationCourseAttendanceIcons = EducationCourseAttendanceIcons;
  readonly EducationCoursePerformanceStatusIcons = EducationCoursePerformanceStatusIcons;
  readonly states = this.staticDataService.transformEnumToStaticDataModel(
    "EducationCourseCandidateStatus",
    EducationCourseCandidateStatus
  );

  readonly attendances = this.staticDataService.transformEnumToStaticDataModel(
    "EducationCourseAttendance",
    EducationCourseAttendance
  );

  readonly performances = this.staticDataService.transformEnumToStaticDataModel(
    "EducationCoursePerformanceStatus",
    EducationCoursePerformanceStatus
  );

  @Input()
  performance: EducationCoursePerformanceForListFragment;

  @Output()
  readonly candidateStateUpdated = new EventEmitter<EducationCourseTotalPerformanceStateUpdateInput>();

  @Output()
  readonly attendanceUpdated = new EventEmitter<EducationCourseWeeklyAttendanceUpdateInput>();

  @Output()
  readonly performanceUpdated = new EventEmitter<EducationCourseWeeklyPerformanceUpdateInput>();

  @Output()
  readonly commentUpdated = new EventEmitter<EducationCourseWeeklyCommentUpdateInput>();

  @Output()
  readonly weekPrefilled = new EventEmitter<EducationCourseWeekPrefillInput>();

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly confirmationService: ConfirmationService
  ) {}

  initialized(event: TableEditInitEvent): void {
    const [performance, week] = event.data as [
      EducationCourseCandidatePerformanceForListFragment,
      EducationCourseCandidateWeeklyPerformanceForListFragment,
    ];
    switch (event.field) {
      case nameOf("candidateState"):
        this.originalValue = performance.candidateState;
        break;
      case nameOf("attendance"):
        this.originalValue = week.attendance;
        break;
      case nameOf("performance"):
        this.originalValue = week.performance;
        break;
      case nameOf("comment"):
        this.originalValue = week.comment;
        break;
    }
  }

  canceled(event: TableEditCancelEvent): void {
    const [performance, week] = event.data as [
      EducationCourseCandidatePerformanceForListFragment,
      EducationCourseCandidateWeeklyPerformanceForListFragment,
    ];
    switch (event.field) {
      case nameOf("candidateState"):
        performance.candidateState = <EducationCourseCandidateStatus>this.originalValue;
        break;
      case nameOf("attendance"):
        week.attendance = <EducationCourseAttendance>this.originalValue;
        break;
      case nameOf("performance"):
        week.performance = <EducationCoursePerformanceStatus>this.originalValue;
        break;
      case nameOf("comment"):
        week.comment = <string>this.originalValue;
        break;
    }
  }

  edited(event: TableEditCompleteEvent): void {
    const [performance, week] = event.data as [
      EducationCourseCandidatePerformanceForListFragment,
      EducationCourseCandidateWeeklyPerformanceForListFragment,
    ];
    switch (event.field) {
      case nameOf("candidateState"):
        if (performance.candidateState !== this.originalValue) {
          this.candidateStateUpdated.emit({
            ...pick(this.performance, ["courseId", "organizationId"]),
            ...pick(performance, ["candidateId", "candidateOrganizationId", "candidateState"]),
          });
        }
        break;
      case nameOf("attendance"):
        if (week.attendance !== this.originalValue) {
          this.attendanceUpdated.emit({
            ...pick(this.performance, ["courseId", "organizationId"]),
            ...pick(performance, ["candidateId", "candidateOrganizationId"]),
            ...pick(week, ["week", "attendance"]),
          });
        }
        break;
      case nameOf("performance"):
        if (week.performance !== this.originalValue) {
          this.performanceUpdated.emit({
            ...pick(this.performance, ["courseId", "organizationId"]),
            ...pick(performance, ["candidateId", "candidateOrganizationId"]),
            ...pick(week, ["week", "performance"]),
          });
        }
        break;
      case nameOf("comment"):
        if (week.comment !== this.originalValue) {
          this.commentUpdated.emit({
            ...pick(this.performance, ["courseId", "organizationId"]),
            ...pick(performance, ["candidateId", "candidateOrganizationId"]),
            ...pick(week, ["week", "comment"]),
          });
        }
        break;
    }
  }

  prefilled(week: EducationCourseWeek, event: Event): void {
    const attendanceValue = EducationCourseAttendance.Present;
    const performanceValue = EducationCoursePerformanceStatus.Good;

    this.confirmationService.confirm({
      target: event.target,
      message: translate("week.prefillWarning", {
        attendance: this.attendances.find((x) => x.value === attendanceValue).label,
        performance: this.performances.find((x) => x.value === performanceValue).label,
      }),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        // avoiding the reload by patching the values manually
        const performance = cloneDeep(this.performance);
        const weekIndex = performance.weeks.findIndex((x) => x.number === week.number && x.year === week.year);
        for (const candidate of performance.candidates) {
          candidate.weeks[weekIndex].attendance = attendanceValue;
          candidate.weeks[weekIndex].performance = performanceValue;
        }
        this.performance = performance;

        this.weekPrefilled.emit({
          ...pick(this.performance, ["courseId", "organizationId"]),
          week: week,
        });
      },
    });
  }

  isCurrentWeek(week: EducationCourseWeek): boolean {
    return this.areWeeksEqual(this.performance.currentWeek, week);
  }

  private areWeeksEqual(week1: EducationCourseWeek, week2: EducationCourseWeek): boolean {
    return week1?.number === week2?.number && week1?.year === week2?.year;
  }

  protected readonly console = console;
}
