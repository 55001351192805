import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import { ProcessStatusCandidateData } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";

const nameOf = nameofFactory<ProcessStatusCandidateData>();

@Component({
  selector: "app-candidate-estimated-arrival-date",
  templateUrl: "./candidate-estimated-arrival-date.component.html",
  standalone: false,
})
export class CandidateEstimatedArrivalDateComponent implements OnInit, OnDestroy {
  private readonly language = this.transloco.getActiveLang();

  @Input()
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  readonly: boolean;

  @Output()
  readonly valueChanged = new EventEmitter<Date>();

  get control(): FormControl<Date | null> {
    return this.form.get(nameOf("estimatedArrivalDate")) as FormControl<Date | null>;
  }

  set control(value: FormControl<Date | null>) {
    this.form.setControl(nameOf("estimatedArrivalDate"), value);
  }

  constructor(private readonly transloco: TranslocoService) {}

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<Date | null>(null);
    this.control.valueChanges.subscribe((value) => this.valueChanged.emit(value));
  }

  ngOnDestroy(): void {
    this.form.removeControl(nameOf("estimatedArrivalDate"), { emitEvent: false });
  }
}
