import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import {
  TalentPoolForSelectionFragment,
  TalentPoolSharingCreateInput,
  TalentPoolSharingCvKind,
  TalentPoolSharingForEditFragment,
  TalentPoolSharingForListFragment,
  TalentPoolSharingUpdateInput,
} from "@ankaadia/graphql";
import moment from "moment";
import { BehaviorSubject, combineLatest, filter, map, Observable, Subscription } from "rxjs";
import { notNullValuesOf, valuesOf } from "../../../shared/services/form.helper";
import { SettingsService } from "../../../shared/services/settings.service";
import { TalentPoolService } from "../../talent-pool-config/talent-pools/talent-pool.service";
import { TalentPoolSharingFormService } from "./talent-pool-sharing-form.service";

/**
 * Creation and update of a talent pool sharing.
 */
@Component({
  selector: "app-talent-pool-sharing-dialog",
  templateUrl: "./talent-pool-sharing-dialog.component.html",
  standalone: false,
})
export class TalentPoolSharingDialogComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  private readonly allTalentPools$ = this.talentPoolService.getWritable(this.settings.organizationId);
  private readonly sharing$ = new BehaviorSubject<TalentPoolSharingForEditFragment>(null);
  private readonly sharings$ = new BehaviorSubject<TalentPoolSharingForListFragment[]>(null);

  readonly TalentPoolSharingCvKind = TalentPoolSharingCvKind;
  readonly form = this.formService.createForm();
  readonly tomorrow = moment().startOf("day").add(1, "day").toDate();
  readonly pools$ = this.getTalentPools();

  @Input()
  set sharing(sharing: TalentPoolSharingForEditFragment) {
    this.sharing$.next(sharing);

    this.form.reset();
    this.form.patchValue(sharing);
    this.form.markAsPristine();
    this.changeDetector.detectChanges();
  }

  @Input()
  set sharings(sharings: TalentPoolSharingForListFragment[]) {
    this.sharings$.next(sharings);
  }

  @Output()
  readonly created = new EventEmitter<TalentPoolSharingCreateInput>();

  @Output()
  readonly updated = new EventEmitter<TalentPoolSharingUpdateInput>();

  @Output()
  readonly closed = new EventEmitter<void>();

  constructor(
    private readonly formService: TalentPoolSharingFormService,
    private readonly settings: SettingsService,
    private readonly talentPoolService: TalentPoolService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(this.onTalentPoolChange());
    this.subscriptions.push(this.onUseCvChange());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  save(): void {
    const pool = this.form.getRawValue();
    if (pool.id) {
      this.updated.emit(pool);
    } else {
      this.created.emit(pool);
    }
  }

  close(): void {
    this.closed.emit();
  }

  private onTalentPoolChange(): Subscription {
    return combineLatest([notNullValuesOf(this.form.controls.talentPoolId), this.pools$])
      .pipe(filter(() => this.form.dirty))
      .subscribe(([talentPoolId, talentPools]) => {
        const pool = talentPools.find((x) => x.id === talentPoolId);
        this.form.controls.talentPoolOrganizationId.setValue(pool.organizationId);
      });
  }

  private onUseCvChange(): Subscription {
    return valuesOf(this.form.controls.useCv).subscribe((useCv) => {
      if (useCv) {
        this.form.controls.cvKind.enable();
        if (!this.form.controls.cvKind.value) {
          this.form.controls.cvKind.setValue(TalentPoolSharingCvKind.Standard);
        }
      } else {
        this.form.controls.cvKind.disable();
      }
    });
  }

  private getTalentPools(): Observable<TalentPoolForSelectionFragment[]> {
    return combineLatest([this.allTalentPools$, this.sharings$, this.sharing$]).pipe(
      map(([pools, allSharings, currentSharing]) =>
        pools.filter(
          (x) =>
            // either it's the sharing being edited, or there's no sharing for the pool
            allSharings.some((y) => y.id === currentSharing.id && y.talentPoolId === x.id) ||
            !allSharings.some((y) => y.talentPoolId === x.id)
        )
      )
    );
  }
}
