import { Directive, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { TreeNode } from "primeng/api";
import { Tree } from "primeng/tree";
import { TreeSelect } from "primeng/treeselect";
import { Subject, Subscription } from "rxjs";

export interface TreeNodeToggleClickEvent {
  originalEvent: Event;
  node: TreeNode;
}

// this is how we're accumulating the clicks
const treeNodeToggleClick$ = new Subject<TreeNodeToggleClickEvent>();

const onNodeClickSource = Tree.prototype.onNodeClick;
Tree.prototype.onNodeClick = function (this: Tree, event, node): void {
  // this is how they do the comparison in PrimeNG
  if (node.selectable === false) {
    treeNodeToggleClick$.next({ originalEvent: event, node: node });
  }
  return onNodeClickSource.call(this, event, node);
};

/**
 * There's no way to know if a branch (= non-leaf) node was clicked in PrimeNG, which could be useful
 * when e.g. trying to implement expanding/collapsing branch nodes on click.
 *
 * This directive implements that by intercepting clicks to the inner {@link Tree} component and
 * passing them to the outer {@link TreeSelect} component.
 */
@Directive({ selector: "p-treeSelect", standalone: false })
export class TreeSelectNodeExpandFixDirective implements OnInit, OnDestroy {
  private subscription: Subscription;

  @Output()
  readonly toggleClick = new EventEmitter<TreeNodeToggleClickEvent>();

  constructor(private readonly treeSelect: TreeSelect) {}

  ngOnInit(): void {
    this.subscription = treeNodeToggleClick$.subscribe((e) => {
      const containerElement = <HTMLElement>this.treeSelect.containerEl?.nativeElement;
      const panelElement = <HTMLElement>this.treeSelect.panelEl?.nativeElement;
      const target = <Element>e.originalEvent.target;
      // and this is how we're distributing the clicks
      if (containerElement?.contains(target) || panelElement?.contains(target)) {
        this.toggleClick.emit(e);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
