import { ChangeDetectionStrategy, Component, computed, input } from "@angular/core";
import { CandidateSource, PriceRow, StaticDataType } from "@ankaadia/graphql";
import { TranslateDirective } from "../../../../../shared/transloco/translate.directive";
import { SharedModule } from "../../../../../shared/shared.module";

type CandidateSpecialization = Pick<PriceRow, "candidateFunction" | "candidateSource" | "professionCategory">;

@Component({
  standalone: true,
  selector: "app-plan-candidate-specialization",
  templateUrl: "./plan-candidate-specialization.component.html",
  imports: [SharedModule, TranslateDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanCandidateSpecializationComponent {
  specialization = input.required<CandidateSpecialization>();

  isDefault = computed(
    () =>
      !this.specialization()?.candidateFunction &&
      !this.specialization()?.candidateSource &&
      !this.specialization()?.professionCategory
  );

  protected readonly CandidateSource = CandidateSource;
  protected readonly StaticDataType = StaticDataType;
}
