<ng-container *appTranslate="let t">
  <p-card [formGroup]="form">
    <div class="p-fluid formgrid grid">
      <div class="field col-12 lg:col-3">
        <app-candidate-estimated-arrival-date
          [form]="form"
          [readonly]="readonly"
        ></app-candidate-estimated-arrival-date>
      </div>
      <div class="field col-12 lg:col-3">
        <app-candidate-estimated-work-start-date
          [form]="form"
          [readonly]="readonly"
        ></app-candidate-estimated-work-start-date>
      </div>
    </div>
  </p-card>
</ng-container>
