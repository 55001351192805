import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivityFragment, NotificationSettingsFragment } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";

@Component({
  selector: "app-notification-list-dialog",
  templateUrl: "./notification-list-dialog.component.html",
  styleUrl: "./notification-list-dialog.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class NotificationListDialogComponent {
  readonly language = this.transloco.getActiveLang();

  @Input()
  notifications: ActivityFragment[];

  @Input()
  since: Date;

  @Input()
  settings: NotificationSettingsFragment;

  @Input()
  isBusy: boolean;

  @Output()
  readonly settingsChanged = new EventEmitter<NotificationSettingsFragment>();

  @Output()
  readonly notificationCleared = new EventEmitter<ActivityFragment>();

  @Output()
  readonly allNotificationsCleared = new EventEmitter<void>();

  @Output()
  readonly activityLogOpened = new EventEmitter<void>();

  @Output()
  readonly closed = new EventEmitter<void>();

  constructor(private readonly transloco: TranslocoService) {}
}
