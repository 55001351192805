import { Injectable } from "@angular/core";
import { FormlyFieldConfig } from "@ngx-formly/core";

@Injectable({
  providedIn: "root",
})
export class TemplateOptionsService {
  makeFieldReadOnly(field: FormlyFieldConfig): void {
    if (!field) return;

    this.clearExpressionProperties(field, [
      "props.readonly",
      "props.disabledAddButton",
      "props.disableAdditionalAddButton",
    ]);

    field.expressions = {
      "props.readonly": "true",
      "props.disabledAddButton": "true",
      "props.disableAdditionalAddButton": "true",
    };
    //Propagate readonly to nested fields
    field?.fieldGroup?.forEach((y) => this.makeFieldReadOnly(y));
    const fieldGroups = (field.fieldArray as FormlyFieldConfig)?.fieldGroup ?? [];
    fieldGroups.forEach((y) => this.makeFieldReadOnly(y));
  }

  /**
   * @param field Field to make mandatory
   * Field is not mandatory if it is hidden or if the field has a dynamic expression to determine if it is mandatory
   */
  makeFieldMandatory(field: FormlyFieldConfig): void {
    this.clearExpressionProperties(field, ["props.required"]);

    field.expressions = {
      ...field.expressions,
      "props.required": function (field: FormlyFieldConfig): boolean {
        const isHidden = (<(field: FormlyFieldConfig) => boolean>field.expressions.hide)?.(field);

        if (isHidden) return false;

        return field.props.isRequiredExpressionInCandidateEditMode
          ? field.props.isRequiredExpressionInCandidateEditMode(field.model, field.options.formState, field)
          : true;
      },
    };
  }

  private clearExpressionProperties(field: FormlyFieldConfig, properties: string[]): void {
    // if a property is set via expressionProperties and expressions, only the expressionProperties is used by formly
    // hence we need to remove the expressionProperties entry to make the field mandatory
    // can be removed, as soon as we get rid of the expressionProperties...
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    properties.forEach((property) => delete field.expressionProperties?.[property]);
  }
}
