<div class="flex flex-column w-full">
  <div class="flex w-full">
    <app-collection-auto-filter-info
      class="w-full filter-summary"
      [filter]="filter"
      [fields]="fields"
      [includeSharedCandidates]="includeSharedCandidates"
    ></app-collection-auto-filter-info>
  </div>
  <div class="flex w-full">
    <app-table
      class="w-full"
      [columns]="columns"
      [items]="assignedCandidates"
      [captionOperations]="captionOperations"
      [(showSidebar)]="showDialog"
      sidebarSize="large"
      [numberOfRows]="28"
      [paginator]="true"
    >
      <app-collection-auto-filter-edit
        *ngIf="showDialog"
        [includeSharedCandidates]="includeSharedCandidates"
        [filter]="filter"
        [fields]="fields"
        (closed)="closeSidebar(true)"
        (saved)="saveFilter($event)"
      ></app-collection-auto-filter-edit>
    </app-table>
  </div>
</div>
