import { AfterViewInit, Directive, DoCheck, EventEmitter, Input, OnInit, Optional, Output } from "@angular/core";
import { FormControl, NgControl } from "@angular/forms";
import { castArray, isArray, isEmpty, isEqual } from "lodash";
import { Dropdown } from "primeng/dropdown";
import { MultiSelect } from "primeng/multiselect";
import { SettingsService } from "../../services/settings.service";

//Works for dropdowns only but can be extended to other controls
@Directive({ selector: "p-dropdown[rememberState],p-multiSelect[rememberState]", standalone: false })
export class DropdownRememberStateDirective implements AfterViewInit, DoCheck, OnInit {
  @Input({ required: true })
  stateKey: string;

  @Input()
  afterFirstValueSet = false;

  @Output()
  readonly noStateRemembered: EventEmitter<void> = new EventEmitter<void>();

  private setOldValue: NgControl["control"]["setValue"];
  private firstValueWritten = false;
  private doCheckDone = false;
  private lastValueWritten: any;
  private lastValueWhichShouldHaveBeenWritten: any;
  private setValueOptions: any;

  constructor(
    @Optional()
    private readonly dropDown: Dropdown,
    @Optional()
    private readonly multiSelect: MultiSelect,
    private readonly control: NgControl,
    private readonly settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.setOldValue = this.control.control.setValue;
    if (this.setOldValue != null) {
      this.control.control.setValue = (value: any, options: any): void => this.setValue(value, options);
    }
  }

  ngDoCheck(): void {
    if (this.firstValueWritten && !this.doCheckDone) {
      const guiControl = this.multiSelect ?? this.dropDown;
      if (guiControl.options != null && !isEqual(this.lastValueWhichShouldHaveBeenWritten, this.lastValueWritten)) {
        const valueToSet = this.checkValueToSet(
          guiControl,
          this.lastValueWritten,
          this.lastValueWhichShouldHaveBeenWritten
        );
        if (!isEqual(valueToSet, this.lastValueWritten)) {
          this.setOldValue.call(this.control.control, valueToSet, this.setValueOptions);
        } else {
          this.setOldValue.call(this.control.control, this.lastValueWritten, this.setValueOptions);
        }
      }
      if (guiControl.options != null) {
        this.doCheckDone = true;
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.control.control) {
      this.control.control.valueChanges.subscribe((value) => {
        localStorage.setItem(this.getKey(), !isEmpty(value) ? JSON.stringify(value) : null);
      });
      const storageValue = localStorage.getItem(this.getKey());
      if (!this.afterFirstValueSet && storageValue != null && storageValue != "undefined" && storageValue != "null") {
        const valueToSet = JSON.parse(storageValue);
        this.setControlValue(valueToSet, 50);
      } else {
        this.noStateRemembered.emit();
      }
    }
  }

  private setValue(value: any, options: any): void {
    let valueToSet = value;
    const guiControl = this.multiSelect ?? this.dropDown;
    if (!this.firstValueWritten && this.afterFirstValueSet) {
      this.firstValueWritten = true;
      const storageValue = localStorage.getItem(this.getKey());
      if (storageValue != null && storageValue != "undefined" && storageValue != "null") {
        valueToSet = JSON.parse(storageValue);
        valueToSet = this.checkValueToSet(guiControl, valueToSet, value);
      }
      this.lastValueWritten = valueToSet;
      this.lastValueWhichShouldHaveBeenWritten = value;
      this.setValueOptions = options;
      if (isEqual(valueToSet, value)) {
        this.setOldValue.call(this.control.control, valueToSet, options);
      }
    } else {
      this.setOldValue.call(this.control.control, valueToSet, options);
    }
  }

  private checkValueToSet(guiControl: Dropdown | MultiSelect, valueToSet: any, oldValue: any): any {
    if (isArray(valueToSet)) {
      if (guiControl.options?.length > 0) {
        const optionProperty = (guiControl.optionValue ?? guiControl.options[0].id != null) ? "id" : "value";
        valueToSet = valueToSet.filter((v) => guiControl.options.find((o) => o[optionProperty] == v) != null);
        return valueToSet.length > 0 ? valueToSet : oldValue;
      }
    } else {
      if (guiControl.options?.length > 0) {
        const optionProperty = (guiControl.optionValue ?? guiControl.options[0].id != null) ? "id" : "value";
        return guiControl.options.find((o) => o[optionProperty] == valueToSet) != null ? valueToSet : oldValue;
      }
    }
    return valueToSet;
  }

  private setControlValue(valueToSet: any, times: number): void {
    if (times > 0) {
      setTimeout(() => {
        if (
          (this.dropDown?.options != null &&
            this.dropDown?.options.length > 0 &&
            this.isIncluded(this.dropDown?.options, valueToSet, this.dropDown.optionValue)) ||
          (this.multiSelect?.options != null &&
            this.multiSelect?.options.length > 0 &&
            this.isIncluded(this.multiSelect?.options, valueToSet, this.multiSelect.optionValue))
        ) {
          (<FormControl>this.control.control).setValue(valueToSet);
          this.dropDown?.onChange.emit({ originalEvent: null, value: valueToSet });
          this.multiSelect?.onChange.emit({ originalEvent: null, value: valueToSet });
        } else {
          this.setControlValue(valueToSet, --times);
        }
      }, 10);
    } else {
      this.noStateRemembered.emit();
    }
  }

  private isIncluded(options: any[], value: any, keyPropertyName: string): boolean {
    return castArray(value).every((v) => options.some((o) => o[keyPropertyName] == v));
  }

  private getKey(): string {
    return `${this.settingsService.userOrCandidateId}_${this.stateKey}`;
  }
}
