import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { emailPattern } from "@ankaadia/ankaadia-shared";
import { OrganizationContactFragment, OrganizationContactFunction, StaticDataType, User } from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { UsersService } from "../../users/users.service";

@Component({
  selector: "app-organization-contact-edit-dialog",
  templateUrl: "./organization-contact-edit-dialog.component.html",
  standalone: false,
})
export class OrganizationContactEditDialogComponent implements OnInit {
  private readonly language = this.transloco.getActiveLang();
  readonly functions = [
    {
      label: translate("enum.OrganizationContactFunction.Representative"),
      value: OrganizationContactFunction.Representative,
    },
    { label: translate("enum.OrganizationContactFunction.Contact"), value: OrganizationContactFunction.Contact },
  ];

  readonly titles = this.staticDataService.getStaticData(StaticDataType.Title, this.language);
  readonly salutations = this.staticDataService.getStaticData(StaticDataType.Salutation, this.language);
  readonly signatureAddition$ = this.staticDataService.getStaticData(StaticDataType.SignatureAddition, this.language);
  readonly countries = this.staticDataService.getStaticData(StaticDataType.Countries, this.language);

  @Input()
  contact: OrganizationContactFragment;

  @Input()
  submitDisabled: boolean;

  @Input()
  organizationId: string;

  @Input()
  isNewFromUsers = false;

  @Output()
  readonly saved = new EventEmitter<OrganizationContactFragment>();

  @Output()
  readonly closed = new EventEmitter<void>();

  form: FormGroup;
  users: User[];
  usersOptions;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly userService: UsersService
  ) {}

  ngOnInit(): void {
    this.userService.getUsers(this.organizationId).subscribe((users) => {
      this.users = users;
      this.usersOptions = users.map((u) => ({ displayName: u.lastname + ", " + u.firstname, ...u }));
    });
    this.form = this.formBuilder.group({
      id: [this.contact.id],
      _etag: [this.contact._etag],
      function: [this.contact.function],
      title: [this.contact.title],
      salutation: [this.contact.salutation],
      firstName: [this.contact.firstName, Validators.maxLength(300)],
      lastName: [this.contact.lastName, Validators.maxLength(300)],
      email: [this.contact.email, Validators.compose([Validators.pattern(emailPattern), Validators.maxLength(300)])],
      phone: [this.contact.phone, Validators.maxLength(100)],
      mobilePhone: [this.contact.mobilePhone, Validators.maxLength(100)],
      position: [this.contact.position, Validators.maxLength(300)],
      signatureAddition: [this.contact.signatureAddition],
      address: [this.contact.address, Validators.maxLength(500)],
      zipCode: [this.contact.zipCode, Validators.maxLength(30)],
      city: [this.contact.city, Validators.maxLength(100)],
      country: [this.contact.country, Validators.maxLength(200)],
      region: [this.contact.region, Validators.maxLength(100)],
      organizationId: [this.contact.organizationId],
      user: [null, this.isNewFromUsers ? Validators.required : null],
    });
  }

  submit(): void {
    this.saved.emit(this.getFormValues());
  }

  getFormValues(): OrganizationContactFragment {
    const { user, ...values } = this.form.value;
    if (this.isNewFromUsers) {
      values.email = user.email;
      values.firstName = user.firstname;
      values.lastName = user.lastname;

      values.position = user.position;
      values.signatureAddition = user.signatureAddition;
      values.salutation = user.salutation;
      values.mobilePhone = user.phoneNumber;
      values.title = user.title;
    }
    return values as OrganizationContactFragment;
  }

  get addressSummary(): string {
    if (this.form.value.address || this.form.value.zipCode || this.form.value.city || this.form.value.country) {
      const address = `${this.form.value.address ?? ""}
${this.form.value.zipCode ?? ""} ${this.form.value.city ?? ""}
${this.form.value.country ?? ""} ${this.form.value.region ? `(${this.form.value.region})` : ""}`;
      return address;
    }
    return null;
  }
}
