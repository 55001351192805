import { CandidateTagColor } from "@ankaadia/graphql";
import { TagColor, TagIconColor } from "../../../shared/services/style.helper";

export const CandidateTagColors: Record<CandidateTagColor, { tag: string; icon: string }> = {
  [CandidateTagColor.Primary]: { tag: TagColor.Primary, icon: TagIconColor.Primary },
  [CandidateTagColor.Secondary]: { tag: TagColor.Secondary, icon: TagIconColor.Secondary },
  [CandidateTagColor.Success]: { tag: TagColor.Success, icon: TagIconColor.Success },
  [CandidateTagColor.Info]: { tag: TagColor.Info, icon: TagIconColor.Info },
  [CandidateTagColor.Warning]: { tag: TagColor.Warning, icon: TagIconColor.Warning },
  [CandidateTagColor.Danger]: { tag: TagColor.Danger, icon: TagIconColor.Danger },
};
