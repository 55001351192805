<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <label for="qualificationMeasure">{{ t("qualificationMeasure.title") }}</label>
  <p-dropdown
    inputId="qualificationMeasure"
    [options]="qualificationMeasures"
    [formControl]="control"
    [showClear]="true"
    [placeholder]="t('qualificationMeasure.placeholder')"
    [readonly]="readonly"
  ></p-dropdown>

  <small class="p-error" *ngIf="control.errors?.required">
    {{ t("qualificationMeasure.required") }}
  </small>
</ng-container>
