import { Component, Input } from "@angular/core";
import { CandidateFamilyMember, StaticDataType } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { v4 as uuidv4 } from "uuid";
import { BooleanPipe } from "../../../shared/pipes/boolean.pipe";
import { AppDatePipe } from "../../../shared/pipes/date.pipe";
import { EnumPipe } from "../../../shared/pipes/enum.pipe";
import { StaticDataPipe } from "../../../shared/static-data/static-data.pipe";
import { PipeDescription, TableColumn, TableOperation } from "../../../shared/table/table.model";
import { FamilyForm } from "../candidate-form.model";
import { CandidateFormService } from "../candidate-form.service";

@Component({
  selector: "app-candidate-family",
  templateUrl: "./candidate-family.component.html",
  styleUrl: "./candidate-family.component.scss",
  standalone: false,
})
export class CandidateFamilyComponent {
  readonly captionOperations: TableOperation[] = [
    {
      label: translate("common.new"),
      icon: PrimeIcons.PLUS,
      operation: (): void => this.create(),
      disabled: (): boolean => this.readonly,
    },
  ];

  readonly tableOperations: TableOperation[] = [
    {
      label: translate("common.edit"),
      icon: PrimeIcons.PENCIL,
      operation: (x: CandidateFamilyMember): void => this.edit(x),
      disabled: (): boolean => this.readonly,
    },
    {
      label: translate("common.delete"),
      icon: PrimeIcons.TRASH,
      operation: (x: CandidateFamilyMember, e: Event): void => this.delete(x, e),
      disabled: (): boolean => this.readonly,
    },
  ];

  readonly columns: TableColumn[] = [
    {
      header: translate("salutation.title"),
      fieldname: "salutation",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(StaticDataPipe, StaticDataType.Salutation),
    },
    {
      header: translate("firstName.title"),
      fieldname: "firstName",
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("lastName.title"),
      fieldname: "lastName",
      sortable: true,
      includeInGlobalFilter: true,
    },
    {
      header: translate("birthDate.title"),
      fieldname: "birthDate",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(AppDatePipe, { dateStyle: "short" }),
    },
    {
      header: translate("relation.title"),
      fieldname: "relation",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(EnumPipe, "CandidateRelation"),
    },
    {
      header: translate("reunification.shortTitle"),
      fieldname: "reunification",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(BooleanPipe),
    },
    {
      header: translate("emergencyContact.title"),
      fieldname: "isEmergencyContact",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(BooleanPipe),
    },
  ];

  @Input()
  form: FamilyForm;

  @Input()
  readonly: boolean;

  showDialog = false;
  selectedMember: CandidateFamilyMember;

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly candidateFormService: CandidateFormService
  ) {}

  create(): void {
    this.selectedMember = {
      id: uuidv4(),
      firstName: null,
      lastName: null,
      relation: null,
      salutation: null,
      reunification: false,
      isEmergencyContact: false,
    };
    this.showDialog = true;
  }

  edit(member: CandidateFamilyMember): void {
    this.selectedMember = member;
    this.showDialog = true;
  }

  delete(member: CandidateFamilyMember, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("familyMember.confirmDelete", member),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        const index = this.form.controls.members.controls.findIndex((m) => m.value.id === member.id);
        if (index > -1) {
          this.form.controls.members.removeAt(index);
          this.form.controls.members.markAsDirty();
        }
      },
    });
  }

  onSave(member: CandidateFamilyMember): void {
    const existing = this.form.controls.members.controls.find((m) => m.value.id === member.id);
    if (existing) {
      existing.patchValue(member);
    } else {
      this.form.controls.members.push(this.candidateFormService.createFamilyMemberGroup(member));
    }
    this.form.controls.members.markAsDirty();
    this.close();
  }

  close(): void {
    this.selectedMember = null;
    this.showDialog = false;
  }

  checkIfEmergencyContactIsSet(): boolean {
    return this.form.controls.members.controls.some((m) => m.value.isEmergencyContact);
  }
}
