<div class="app-form" *appTranslate="let t">
  <p-table
    #table
    [value]="templates"
    dataKey="id"
    [globalFilterFields]="['name']"
    sortField="name"
    selectionMode="single"
    [selection]="selectedTemplate"
    [scrollable]="true"
    scrollHeight="flex"
    (onRowSelect)="onRowSelect($event)"
  >
    <ng-template pTemplate="caption">
      <div class="flex align-items-center">
        <span class="p-input-icon-left flex-1">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="table.filterGlobal($any($event.target).value, 'contains')"
            [placeholder]="t('common.search')"
          />
        </span>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th [pSortableColumn]="'name'">
          <div class="flex justify-content-between align-items-center">
            {{ t("name.title") }}
            <p-sortIcon field="name"></p-sortIcon>
          </div>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-collection>
      <tr [pSelectableRow]="collection">
        <td>
          <span class="white-space-nowrap text-overflow-ellipsis overflow-hidden">
            {{ collection.name }}
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
