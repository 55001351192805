import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DocumentType } from "@ankaadia/ankaadia-shared";
import { Document, DocumentMode, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { MenuItem } from "primeng/api";
import { Observable, of, switchMap } from "rxjs";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { DocumentsService } from "../../documents/documents.service";
import { AnyCandidateForm } from "../candidate-form.model";

@Component({
  selector: "app-candidate-document-upload",
  templateUrl: "./candidate-document-upload.component.html",
  styleUrl: "./candidate-document-upload.component.scss",
  standalone: false,
})
export class CandidateDocumentUploadComponent implements OnChanges {
  protected readonly language = this.transloco.getActiveLang();
  protected readonly DocumentMode = DocumentMode;

  protected menuItems: MenuItem[];
  protected showSidebar: boolean;
  protected selectedDocument: Document;

  @Input({ required: true })
  fileTypes: DocumentType[];

  @Input({ required: true })
  candidateId: string;

  @Input({ required: true })
  candidateOrganizationId: string;

  @Input()
  candidateForm?: AnyCandidateForm;

  @Input()
  readonly = false;

  constructor(
    private readonly transloco: TranslocoService,
    private readonly documentService: DocumentsService,
    private readonly staticDataService: StaticDataService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateId || changes.candidateOrganizationId) {
      if (this.candidateId) {
        this.staticDataService
          .getStaticData(StaticDataType.AllowedUploadFileTypes, this.language, {
            candidateId: this.candidateId,
            organizationId: this.candidateOrganizationId,
          })
          .subscribe((data) => {
            this.menuItems = this.fileTypes?.map((type) => ({
              label: data.find((x) => x.value == type)?.label,
              command: (): void => this.openDialog(type),
            }));
          });
      } else {
        this.menuItems = null;
      }
    }
  }

  openDialog(type: string): void {
    this.documentService
      .getByType(type, this.candidateOrganizationId, this.candidateId)
      .pipe(switchMap((document) => (document ? of(document) : this.createDocument(type))))
      .subscribe((document) => {
        this.selectedDocument = document;
        this.showSidebar = true;
      });
  }

  closeDialog(): void {
    this.showSidebar = false;
    this.selectedDocument = null;
  }

  private createDocument(type: string): Observable<Document> {
    return this.documentService.createEmptyDocument(
      DocumentMode.Candidate,
      this.candidateOrganizationId,
      this.candidateId,
      null,
      type
    );
  }
}
