import { AfterViewInit, Component, EventEmitter, Input, Output } from "@angular/core";
import { getAllScholarshipRoles } from "@ankaadia/ankaadia-shared";
import { StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { filter, startWith } from "rxjs";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { ResponsibleRolePurpose } from "../../candidate-reusables/candidate-responsible-representative-group/candidate-responsible-representative-group.service";
import { ScholarshipForm } from "../candidate-form.model";

@Component({
  selector: "app-candidate-scholarship",
  templateUrl: "./candidate-scholarship.component.html",
  standalone: false,
})
export class CandidateScholarshipComponent implements AfterViewInit {
  readonly ResponsibleRolePurposeType = ResponsibleRolePurpose;
  readonly responsibleRoles = getAllScholarshipRoles();
  readonly awardFrequencies$ = this.staticData.getStaticData(
    StaticDataType.AwardFrequency,
    this.transloco.getActiveLang()
  );

  @Input()
  candidateId: string;

  @Input()
  form: ScholarshipForm;

  @Input()
  inputId?: string;

  @Input()
  formIndex?: number;

  @Input()
  readonly: boolean;

  @Output()
  readonly delete = new EventEmitter<void>();

  get isOverlapping(): boolean {
    if (this.formIndex == null) {
      return false;
    }

    const indices = this.form.parent.errors?.overlappingIndices as number[];
    return indices?.includes(this.formIndex);
  }

  constructor(
    private readonly transloco: TranslocoService,
    private readonly staticData: StaticDataService
  ) {}

  ngAfterViewInit(): void {
    this.form.controls.startDate.valueChanges
      .pipe(
        startWith(this.form.controls.startDate.value),
        filter((startDate: Date) => {
          const endDate = this.form.controls.endDate.value;
          return endDate == null ? false : startDate > endDate;
        })
      )
      .subscribe((startDate) => this.form.controls.endDate.setValue(startDate));
  }

  removeScholarship(): void {
    this.delete.emit();
  }
}
