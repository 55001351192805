import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { translate } from "@jsverse/transloco";
import { concat, finalize, switchMap, toArray } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { MessageDialogService } from "../../../../shared/message-dialog/message-dialog.service";
import { FileUploadService } from "../../../../shared/services/file-upload.service";
import { DocumentsService } from "../../../documents/documents.service";

@Component({
  selector: "app-organization-profile-images",
  templateUrl: "./organization-profile-images.component.html",
  styleUrl: "./organization-profile-images.component.scss",
  providers: [FileUploadService],
  standalone: false,
})
export class OrganizationProfileImagesComponent {
  readonly responsiveOptions = [
    { breakpoint: "1024px", numVisible: 5 },
    { breakpoint: "560px", numVisible: 3 },
  ];

  @Input()
  form: FormGroup;

  @Input()
  organizationId: string;

  isUploading: boolean;

  constructor(
    private readonly fileUploadService: FileUploadService,
    private readonly errorService: MessageDialogService,
    private readonly documentService: DocumentsService
  ) {}

  upload(files: File[]): void {
    this.isUploading = true;
    concat(
      ...files.map((file) => {
        const blob = uuidv4();
        return this.documentService.getUploadUrl(blob, this.organizationId, null, true).pipe(
          switchMap(({ url }) => this.fileUploadService.uploadAsObservable(url, file)),
          switchMap(() => this.documentService.getDownloadUrl(blob, this.organizationId, null, 24 * 365 * 10, true))
        );
      })
    )
      .pipe(
        toArray(),
        finalize(() => (this.isUploading = false))
      )
      .subscribe({
        next: (urls) => {
          this.form.setValue([...this.form.value, ...urls]);
          this.form.markAsDirty();
        },
        error: (e) => this.errorService.showMessage(translate("file.uploadFailed"), e.message),
      });
  }

  remove(url: string): void {
    this.form.setValue(this.form.value.filter((x) => x !== url));
    this.form.markAsDirty();
  }
}
