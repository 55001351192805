<ng-container *appTranslate="let t">
<span *ngIf="isDefault(); else notDefault">
  <div class="flex flex-wrap gap-1">
    <p-tag class="flex" [value]="t('billing.specialization.tags.base')" severity="success"></p-tag>
  </div>
</span>
  <ng-template #notDefault>
    <div class="flex flex-wrap gap-1">
      <p-tag class="flex" *ngIf="specialization().candidateFunction"
             [value]="specialization().candidateFunction | staticData : StaticDataType.Functions | async"
             severity="secondary"></p-tag>
      <p-tag class="flex" *ngIf="specialization().candidateSource"
             [value]="specialization().candidateSource | enum : 'CandidateSource'"
             severity="info"></p-tag>
      <p-tag class="flex" *ngIf="specialization().professionCategory"
             [value]="specialization().professionCategory | enum : 'ProfessionCategory'"
             severity="warning"></p-tag>
    </div>
  </ng-template>
</ng-container>
