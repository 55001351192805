import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class CandidateSpecialTagService {
  private readonly tagSubject = new BehaviorSubject<string[]>([]);

  readonly tags$ = this.tagSubject.asObservable();

  add(tag: string): void {
    if (tag) {
      const tags = [...this.tagSubject.value, tag];
      this.tagSubject.next(tags);
    }
  }

  remove(tag: string): void {
    if (tag) {
      const tags = this.removeLastInstanceOf(this.tagSubject.value, tag);
      this.tagSubject.next(tags);
    }
  }

  private removeLastInstanceOf<T>(xs: T[], x: T): T[] {
    return xs.filter((_, i) => i !== xs.lastIndexOf(x));
  }
}
