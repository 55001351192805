import { Pipe, PipeTransform } from "@angular/core";
import { ProcessOptionalString, ProcessRequiredString } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";

@Pipe({ name: "processString", standalone: false })
export class ProcessStringPipe implements PipeTransform {
  constructor(private readonly transloco: TranslocoService) {}

  transform(
    str: (ProcessOptionalString | ProcessRequiredString)[],
    language: string = this.transloco.getActiveLang()
  ): string {
    return str?.find((n) => n.language === language)?.value;
  }
}
