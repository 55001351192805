<ng-container *appTranslate="let t">
  <form [formGroup]="form">
    <div class="p-fluid formgrid grid">
      <div class="field col-12 lg:col-6">
        <div class="flex align-items-end">
          <app-candidate-employer-organization
            class="flex-1"
            [formId]="formId"
            [form]="form"
            [readonly]="readonly"
            [modelData]="{ id: employer?.employerId, name: employer?.employerName }"
            [isFieldShared]="
              (sharingState?.targetOrganizationShared && sharingState?.organizationsSharedByCandidateOwner) ?? false
            "
            (changes)="onEmployerChanged($event)"
            [candidateOrgId]="candidate.organizationId"
            [organizationId]="settings.organizationId"
            [immigrationCountry]="immigrationCountry"
          ></app-candidate-employer-organization>

          <p-button
            *ngIf="settings.organizationId === candidate.organizationId"
            class="mb-1"
            [pTooltip]="t('employerHistory.title')"
            [disabled]="readonly || isMultiEditMode"
            icon="pi pi-history"
            styleClass="p-button-rounded p-button-text"
            (onClick)="openEmployerHistory()"
          ></p-button>
        </div>
      </div>

      <div class="field col-12 lg:col-3">
        <app-candidate-employer-representative
          [formId]="formId"
          [form]="form"
          [readonly]="readonly"
          [employerId]="form.controls.employerId.value"
          [candidateId]="candidate.id"
          [modelData]="{
            organizationId: employer?.employerId,
            id: employer?.representativeId,
            firstName: employer?.representativeFirstName,
            lastName: employer?.representativeLastName,
          }"
          (valueChanged)="representativeChange.emit($event)"
          [isShared]="isRepresentativeShared"
          class="field"
        />
      </div>

      <div class="field col-12 lg:col-3">
        <app-candidate-employer-first-deputy
          [formId]="formId"
          [form]="form"
          [readonly]="readonly"
          [employerId]="form.controls.employerId.value"
          [candidateId]="candidate.id"
          [modelData]="{
            organizationId: employer?.employerId,
            id: employer?.firstDeputyId,
            firstName: employer?.firstDeputyFirstName,
            lastName: employer?.firstDeputyLastName,
          }"
          (valueChanged)="firstDeputyChange.emit($event)"
          class="field"
          [isShared]="isDeputyShared"
        />
      </div>
      <ng-container *ngIf="isAfterRecognitionForm(form); else noConfirmationSignedDateControl">
        <div class="field col-12 lg:col-2">
          <label for="{{ formId }}-confirmationSignedDate">{{ t("confirmationSignedDate.title") }}</label>
          <p-calendar
            inputId="{{ formId }}-confirmationSignedDate"
            formControlName="confirmationSignedDate"
            [maxDate]="today"
            [readonly]="readonly"
          ></p-calendar>
        </div>
      </ng-container>
      <ng-template #noConfirmationSignedDateControl>
        <app-candidate-document-date
          [inputId]="'preContract#' + formId"
          [candidateId]="candidate.id"
          [candidateOrganizationId]="candidate.organizationId"
          [candidateForm]="form"
          [readonly]="readonly"
          [disabled]="isMultiEditMode || !enabled"
          [label]="t('confirmationSignedDate.title')"
          documentType="PRECONTRACT"
          selectedSet="Default"
          dateField="issueDate"
          class="field col-12 lg:col-3"
        ></app-candidate-document-date>
      </ng-template>
      <div class="field col-12 lg:col-4">
        <label for="{{ formId }}-contractTemplateId">{{ t("contractModel.title") }}</label>
        <div class="flex align-items-center">
          <p-dropdown
            inputId="{{ formId }}-contractTemplateId"
            class="flex-1 mw-1"
            [options]="contractTemplateOptions$ | async"
            optionLabel="name"
            optionValue="id"
            formControlName="contractTemplateId"
            [showClear]="true"
            [placeholder]="t('contractModel.placeholder')"
            [readonly]="readonly"
            (onChange)="onContractTemplateChange($event.value)"
            appCandidateFieldNotShared
            [isShared]="isContractShared"
            formControlValueAlwaysInOptions
          ></p-dropdown>
          <p-button
            *ngIf="form.controls.contractTemplateId.value && sharingState?.specificContractSharedByTargetOrganization"
            [pTooltip]="t('common.edit')"
            [disabled]="readonly"
            icon="pi pi-pencil"
            styleClass="p-button-rounded p-button-text"
            (onClick)="openContractTemplate()"
          ></p-button>
        </div>
      </div>

      <ng-container *ngIf="isAfterRecognitionForm(form); else noContractSignDateControl">
        <div class="field col-12 lg:col-3">
          <label for="{{ formId }}-contractSignDate">{{ t("contractSignDate.title") }}</label>
          <p-calendar
            inputId="{{ formId }}-contractSignDate"
            formControlName="contractSignDate"
            [readonly]="readonly"
          ></p-calendar>
        </div>
      </ng-container>
      <ng-template #noContractSignDateControl>
        <app-candidate-document-date
          [inputId]="'contractWork#' + formId"
          [candidateId]="candidate.id"
          [candidateOrganizationId]="candidate.organizationId"
          [candidateForm]="form"
          [readonly]="readonly"
          [disabled]="isMultiEditMode || !enabled"
          [label]="t('contractSignDate.title')"
          documentType="CONTRACTWORK"
          selectedSet="Default"
          dateField="issueDate"
          class="field col-12 lg:col-3"
        ></app-candidate-document-date>
      </ng-template>

      <div class="field col-12 lg:col-3">
        <label for="{{ formId }}-employmentRelocationRequirements">{{ t("relocationRequirements.title") }}</label>
        <p-multiSelect
          inputId="{{ formId }}-employmentRelocationRequirements"
          display="chip"
          formControlName="employmentRelocationRequirements"
          [options]="relocationRequirements$ | async"
          [showClear]="true"
          [showToggleAll]="false"
          [placeholder]="t('relocationRequirements.placeholder')"
          [tooltip]="t('relocationRequirements.placeholder')"
          [readonly]="readonly"
        ></p-multiSelect>
      </div>

      <ng-container *ngIf="isAfterRecognitionForm(form); else noContractDateControls">
        <div class="field col-12 lg:col-2">
          <label for="{{ formId }}-startDate">{{ t("startDate.title") }}</label>
          <p-calendar inputId="{{ formId }}-startDate" formControlName="startDate" [readonly]="readonly"></p-calendar>
        </div>
        <div class="field col-12 lg:col-2">
          <label for="{{ formId }}-endDate">{{ t("endDate.title") }}</label>
          <p-calendar inputId="{{ formId }}-endDate" formControlName="endDate" [readonly]="readonly"></p-calendar>
        </div>
      </ng-container>
      <ng-template #noContractDateControls>
        <app-candidate-document-date
          [inputId]="'contractWork#' + formId"
          [candidateId]="candidate.id"
          [candidateOrganizationId]="candidate.organizationId"
          [candidateForm]="form"
          [readonly]="readonly"
          [disabled]="isMultiEditMode || !enabled"
          [label]="t('startDate.title')"
          documentType="CONTRACTWORK"
          selectedSet="Default"
          dateField="validFrom"
          class="field col-12 lg:col-2"
        ></app-candidate-document-date>
        <app-candidate-document-date
          [inputId]="'contractWork#' + formId"
          [candidateId]="candidate.id"
          [candidateOrganizationId]="candidate.organizationId"
          [candidateForm]="form"
          [readonly]="readonly"
          [disabled]="isMultiEditMode || !enabled"
          [label]="t('endDate.title')"
          documentType="CONTRACTWORK"
          selectedSet="Default"
          dateField="validUntil"
          class="field col-12 lg:col-2"
        ></app-candidate-document-date>
      </ng-template>

      <div class="field col-12 lg:col-2 pt-5">
        <div class="field-checkbox pt-2">
          <p-checkbox
            inputId="{{ formId }}-isTemporaryEmployee"
            [binary]="true"
            formControlName="isTemporaryEmployee"
            [readonly]="readonly"
          ></p-checkbox>
          <label for="{{ formId }}-isTemporaryEmployee">{{ t("isTemporaryEmployee.title") }}</label>
        </div>
      </div>
      <div class="field col-12 lg:col-3">
        <app-candidate-employer-type-of-work-location
          [form]="form"
          class="field"
          [readonly]="readonly"
        ></app-candidate-employer-type-of-work-location>
      </div>
      <div class="field col-12 lg:col-3">
        <app-candidate-employer-work-location
          [form]="form"
          class="field"
          [readonly]="readonly"
        ></app-candidate-employer-work-location>
      </div>
      <div class="field col-12 lg:col-2">
        <app-candidate-employer-end-of-probation-date [form]="form" class="field" [readonly]="readonly">
        </app-candidate-employer-end-of-probation-date>
      </div>
      <div class="field col-12 lg:col-3 pt-5">
        <div class="field-checkbox pt-2">
          <p-checkbox
            inputId="{{ formId }}-contractExtension"
            [binary]="true"
            formControlName="contractExtension"
            [readonly]="readonly"
          ></p-checkbox>
          <label for="{{ formId }}-contractExtension">{{ t("contractExtension.title") }}</label>
        </div>
      </div>
      <ng-container *ngIf="!isAfterRecognitionForm(form); else contractExtensionAfter">
        <app-candidate-document-date
          [inputId]="'firstContractExtensionPre#' + formId"
          [candidateId]="candidate.id"
          [candidateOrganizationId]="candidate.organizationId"
          [candidateForm]="form"
          [readonly]="readonly || !form.controls.contractExtension?.value"
          [disabled]="isMultiEditMode || !enabled"
          [label]="t('firstContractExtension.title')"
          documentType="FIRSTCONTRACTEXTENSIONPRE"
          selectedSet="Default"
          dateField="validUntil"
          class="field col-12 lg:col-2"
        ></app-candidate-document-date>
        <app-candidate-document-date
          [inputId]="'secondContractExtensionPre#' + formId"
          [candidateId]="candidate.id"
          [candidateOrganizationId]="candidate.organizationId"
          [candidateForm]="form"
          [readonly]="readonly || !form.controls.contractExtension?.value"
          [disabled]="isMultiEditMode || !enabled"
          [label]="t('secondContractExtension.title')"
          documentType="SECONDCONTRACTEXTENSIONPRE"
          selectedSet="Default"
          dateField="validUntil"
          class="field col-12 lg:col-2"
        ></app-candidate-document-date>
      </ng-container>
      <ng-template #contractExtensionAfter>
        <app-candidate-document-date
          [inputId]="'firstContractExtensionAfter#' + formId"
          [candidateId]="candidate.id"
          [candidateOrganizationId]="candidate.organizationId"
          [candidateForm]="form"
          [readonly]="readonly || !form.controls.contractExtension?.value"
          [disabled]="isMultiEditMode || !enabled"
          [label]="t('firstContractExtension.title')"
          documentType="FIRSTCONTRACTEXTENSIONAFTER"
          selectedSet="Default"
          dateField="validUntil"
          class="field col-12 lg:col-2"
        ></app-candidate-document-date>
        <app-candidate-document-date
          [inputId]="'secondContractExtensionAfter#' + formId"
          [candidateId]="candidate.id"
          [candidateOrganizationId]="candidate.organizationId"
          [candidateForm]="form"
          [readonly]="readonly || !form.controls.contractExtension?.value"
          [disabled]="isMultiEditMode || !enabled"
          [label]="t('secondContractExtension.title')"
          documentType="SECONDCONTRACTEXTENSIONAFTER"
          selectedSet="Default"
          dateField="validUntil"
          class="field col-12 lg:col-2"
        ></app-candidate-document-date>
      </ng-template>
    </div>
  </form>
</ng-container>

<p-sidebar [fullScreen]="true" [visible]="!!selectedContractTemplate">
  <app-contract-template-dialog
    *ngIf="selectedContractTemplate"
    [template]="selectedContractTemplate"
    [isEdit]="true"
    [hideName]="true"
    (saved)="saveContractTemplate($event, true)"
    (closed)="closeContractTemplate()"
  ></app-contract-template-dialog>
</p-sidebar>

<p-sidebar *ngIf="selectedEmployerHistory" [visible]="true" styleClass="p-sidebar-lg">
  <app-candidate-employer-history-dialog
    [items]="selectedEmployerHistory"
    (saved)="saveEmployerHistory($event)"
    (closed)="closeEmployerHistory()"
  ></app-candidate-employer-history-dialog>
</p-sidebar>
