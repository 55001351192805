import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { Candidate, Collection, CollectionUpdateInput, SharingTypeEnum, StaticDataType } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { clone } from "lodash";
import { Message, PrimeIcons, TreeNode } from "primeng/api";
import { Subscription } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { SettingsService } from "../../../../shared/services/settings.service";
import { StaticDataPipe } from "../../../../shared/static-data/static-data.pipe";
import { PipeDescription, TableColumn, TableOperation } from "../../../../shared/table/table.model";
import { CandidateFilterField } from "../../../candidate-filter/candidate-filter.model";
import { CandidateFilterService } from "../../../candidate-filter/candidate-filter.service";
import { CandidatesService } from "../../../candidates/candidates.service";
import { FilterMetadataMap } from "../../collection-edit-assigned-candidates/custom-filter.model";
import { CollectionService } from "../../collection.service";
import { CollectionAutoFiltersForm } from "../collection-auto-filter-form.model";
import { CollectionAutoFilterService } from "../collection-auto-filter.service";
import { CollectionAutoFilterFieldsService } from "./collection-auto-filter-fields.service";
import { CandidateFlexibleEditService } from "../../../candidates/candidate-flexible-edit/candidate-flexible-edit.service";

@Component({
  selector: "app-collection-auto-filter",
  templateUrl: "./collection-auto-filter.component.html",
  styleUrl: "./collection-auto-filter.component.scss",
  standalone: false,
})
export class CollectionAutoFilterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() collection: Collection;
  @Input() isEditable: boolean;
  @Output() readonly reload = new EventEmitter<void>();

  protected assignedCandidates: Candidate[];
  private subscription?: Subscription;

  protected captionOperations: TableOperation[] = [
    {
      label: translate("autoCollection.editFilter"),
      icon: PrimeIcons.FILTER,
      operation: (): void => this.editAutoFilter(),
    },
  ];

  protected columns: TableColumn[] = [
    {
      header: translate("number.title"),
      fieldname: "displayId",
      routeLink: (x: Candidate): any[] => this.getRouteLink(x),
      sortable: true,
      includeInGlobalFilter: true,
      includeFlag: true,
      canIconClick: () => true,
      icon: () => PrimeIcons.USER_EDIT,
      iconClick: (candidate: Candidate) =>
        this.candidateFlexibleEditService.openCandidate(candidate.id, candidate.organizationId),
      iconClass: "ml-2",
    },
    { header: translate("name.title"), fieldname: "displayName", sortable: true, includeInGlobalFilter: true },
    {
      header: translate("country.title"),
      fieldname: "country",
      sortable: true,
      includeInGlobalFilter: true,
      pipeDescription: new PipeDescription(StaticDataPipe, StaticDataType.Countries),
    },
  ];

  protected showDialog = false;
  protected messages: Message[] | undefined;
  private _filter: FilterMetadataMap;
  protected fields: TreeNode<CandidateFilterField>[] = [];
  private _includeSharedCandidates: boolean;

  constructor(
    private readonly settings: SettingsService,
    private readonly collService: CollectionService,
    private readonly candidateService: CandidatesService,
    private readonly candidateFilterService: CandidateFilterService,
    private readonly collectionAutoFilterService: CollectionAutoFilterService,
    private readonly collectionAutoFilterFieldsService: CollectionAutoFilterFieldsService,
    private readonly candidateFlexibleEditService: CandidateFlexibleEditService
  ) {}

  protected get filter(): FilterMetadataMap {
    return this._filter;
  }

  protected set filter(value: FilterMetadataMap) {
    this._filter = value;
  }

  protected get includeSharedCandidates(): boolean {
    return this._includeSharedCandidates;
  }

  ngOnInit(): void {
    this.subscription = this.collectionAutoFilterFieldsService.getFields().subscribe((fields) => {
      this.fields = fields;
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnChanges(): void {
    if (this.collection != null) {
      if (this.collection.autoCollectionSettings?.filterId) {
        this.collectionAutoFilterService
          .get({
            id: this.collection.autoCollectionSettings.filterId,
            organizationId: this.collection.organizationId,
          })
          .subscribe((x) => {
            this.filter = x.filters;
            this._includeSharedCandidates = x.includeSharedCandidates;
          });
      } else {
        this.filter = {};
        this._includeSharedCandidates = false;
      }

      this.assignedCandidates = clone(this.collection.assignedCandidates);
    } else {
      this.assignedCandidates = null;
    }
  }

  editAutoFilter(): void {
    this.showDialog = true;
  }

  displayCandidateLinks(): boolean {
    if (this.settings.organizationId === this.collection.organizationId) {
      return true;
    }
    return this.collection.sharings.some(
      (s) =>
        [SharingTypeEnum.Collaboration, SharingTypeEnum.ReadOnlyCollaboration].includes(s.sharingType) &&
        s.destinationOrganizationId === this.settings.organizationId
    );
  }

  getRouteLink(candidate: Candidate): any[] | null {
    return this.displayCandidateLinks()
      ? this.candidateService.getCandidateEditLink(candidate.id, candidate.organizationId)
      : null;
  }

  closeSidebar(_event: boolean): void {
    this.reload.emit();
    this.showDialog = false;
  }

  saveFilter(filtersForm: CollectionAutoFiltersForm): void {
    const filters = this.candidateFilterService.buildFilterMetadataMapFromFilters(filtersForm.value.filters);
    const filterId = this.collection.autoCollectionSettings?.filterId ?? uuidv4();
    this.collectionAutoFilterService
      .set({
        id: filterId,
        organizationId: this.collection.organizationId,
        includeSharedCandidates: filtersForm.value.includeSharedCandidates ?? false,
        filters: filters,
      })
      .subscribe((x) => {
        const updatedCollection: CollectionUpdateInput = {
          ...this.collection,
          autoCollectionSettings: {
            filterId: x.id,
          },
        };
        this.collService.updateCollection(updatedCollection).subscribe(() => {
          this.showDialog = false;
          this.reload.emit();
        });
      });
  }
}
