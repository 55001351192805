<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <ng-container *ngIf="control">
    <label for="bankAppointmentDate">{{ t("bankAppointmentDate.title") }}</label>
    <i class="pi pi-info-circle ml-2" [pTooltip]="t('bankAppointmentDate.help')" [escape]="false"></i>
    <app-date-time [form]="control" inputId="bankAppointmentDate" [readonly]="readonly" [showClear]="showClear">
    </app-date-time>
    <small class="p-error" *ngIf="control.controls.date.errors?.required">
      {{ t("bankAppointmentDate.required") }}
    </small>
  </ng-container>
</ng-container>
