<p-card *appTranslate="let t">
  <ng-template pTemplate="header">
    <div class="p-card-body pb-0">
      <div class="flex align-items-center">
        <div class="mr-4">
          <div class="p-card-title mb-0">{{ caption }}</div>
        </div>
        <div>
          <div class="flex">
            <div class="formgroup-inline">
              <div class="field mb-0">
                <p-dropdown
                  *ngIf="organizations.length > 1"
                  [placeholder]="t('organization.placeholder')"
                  [options]="organizations"
                  optionLabel="name"
                  [(ngModel)]="selectedOrganization"
                  (onChange)="updateCollections($event.value)"
                ></p-dropdown>
              </div>
              <div class="field mb-0">
                <p-dropdown
                  [disabled]="!collections.length"
                  [placeholder]="t('collection.placeholder')"
                  [showClear]="true"
                  [options]="collections"
                  optionLabel="name"
                  [(ngModel)]="selectedCollection"
                  (onChange)="
                    updateFeedbacks($event.value); filterCandidates(selectedStatus, $event.value, selectedFeedback)
                  "
                ></p-dropdown>
              </div>
              <div class="field mb-0">
                <p-cascadeSelect
                  [disabled]="!feedbacks.length"
                  styleClass="p-cascadeselect-clearable"
                  [placeholder]="t('feedback.placeholder')"
                  [options]="$any(feedbacks)"
                  optionLabel="label"
                  optionGroupLabel="organizationName"
                  [optionGroupChildren]="['states']"
                  [(ngModel)]="selectedFeedback"
                  (onChange)="filterCandidates(selectedStatus, selectedCollection, $any($event).value)"
                >
                  <ng-template pTemplate="value" let-feedback let-placeholder="placeholder">
                    <ng-container *ngIf="feedback">
                      {{ feedback.organizationName }} - {{ feedback.label }}
                      <i
                        class="p-cascadeselect-clear-icon pi pi-times"
                        (click)="
                          selectedFeedback = null;
                          filterCandidates(selectedStatus, selectedCollection, selectedFeedback)
                        "
                      ></i>
                    </ng-container>
                    <ng-container *ngIf="!feedback">
                      {{ placeholder }}
                    </ng-container>
                  </ng-template>
                </p-cascadeSelect>
              </div>
              <div class="field mb-0">
                <p-multiSelect
                  [placeholder]="t('status.placeholder')"
                  [filter]="false"
                  [options]="statusOptions"
                  [(ngModel)]="selectedStatus"
                  (onChange)="filterCandidates($event.value, selectedCollection, selectedFeedback)"
                ></p-multiSelect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <p-pickList
    #list
    [source]="sourceCandidateList"
    [target]="targetCandidateList"
    [dragdrop]="true"
    [responsive]="true"
    filterBy="displayId,displayName"
    [sourceStyle]="{ height: 'calc(100vh - 350px)' }"
    [targetStyle]="{ height: 'calc(100vh - 350px)' }"
    [showSourceControls]="false"
    [showTargetControls]="true"
    [showSourceFilter]="false"
    [showTargetFilter]="false"
  >
    <ng-template pTemplate="sourceHeader">
      <div class="p-picklist-title">{{ t("candidates.available") }}</div>
      <span class="p-input-icon-left ml-auto mr-auto mt-3">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          (input)="searchSubject.next($any($event.target).value)"
          [placeholder]="t('name.searchBy')"
          data-testid="input-source-search-field"
        />
      </span>
    </ng-template>
    <ng-template pTemplate="targetHeader">
      <div class="p-picklist-title">{{ t("candidates.assigned") }}</div>
      <span class="p-input-icon-left ml-auto mr-auto mt-3">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          (keyup)="list.onFilter($event, list.TARGET_LIST)"
          [placeholder]="t('name.searchBy')"
          data-testid="input-target-search-field"
        />
      </span>
    </ng-template>
    <ng-template let-candidate pTemplate="item">
      <div
        class="flex flex-row justify-content-start align-items-center candidate-item"
        [attr.data-testid]="'candidate-display-id-' + candidate.displayId"
      >
        <div>
          <img [src]="candidate.isAnonymous ? '' : (candidate | thumbnail | async)" default="/assets/user.jpeg" />
        </div>
        <div class="candidate-detail">
          <app-flag *ngIf="candidate.id" class="mr-1" [country]="candidate.country" [small]="true"></app-flag>
          <p class="inline-block">{{ candidate.displayId }} - {{ candidate.displayName }}</p>
        </div>
      </div>
    </ng-template>
  </p-pickList>
  <div class="flex flex-row">
    <p-paginator
      #sourcePgr
      [rows]="candidatesPerPage"
      [totalRecords]="sourceCandidateCount"
      class="half-width"
      styleClass="p-paginator-bottom p-picklist-paginator"
      [alwaysShow]="false"
      (onPageChange)="updateSource($event)"
    ></p-paginator>
  </div>
  <div class="flex flex-row justify-content-end mt-4">
    <p-button
      class="mr-2"
      [label]="t('common.save')"
      icon="pi pi-check"
      (onClick)="save()"
      [disabled]="disableSave"
      data-testid="btn-save-assigned-candidate"
    ></p-button>
    <p-button
      closeOnEscape
      [label]="t('common.cancel')"
      icon="pi pi-times"
      (onClick)="cancel()"
      data-testid="btn-cancel-assigned-candidate"
    ></p-button>
  </div>
</p-card>
