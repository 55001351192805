import { Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { FormlySelectOption } from "@ngx-formly/core/select";

@Component({
  selector: "app-formly-multiselect",
  templateUrl: "./formly-multiselect.component.html",
  styleUrl: "./formly-multiselect.component.scss",
  standalone: false,
})
export class FormlyMultiSelectComponent extends FieldType {
  getText(value: string[], options: FormlySelectOption[]): string {
    if (value != null && Array.isArray(value)) {
      return (options ?? [])
        .filter((x) => value.includes(x.value))
        .map((y) => y.label)
        ?.join(", ");
    } else {
      return "";
    }
  }
}
