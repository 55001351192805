import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AsyncValidatorFn, FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OrganizationProfileFields, OrganizationProfileLocation } from "@ankaadia/graphql";
import { Subscription } from "rxjs";
import { OrganizationProfileFormsService } from "../organization-profile-forms.service";
import { OrganizationProfileLocationsValidator } from "./organization-profile-locations.validator";

@Component({
  selector: "app-organization-profile-locations",
  templateUrl: "./organization-profile-locations.component.html",
  standalone: false,
})
export class OrganizationProfileLocationsComponent implements OnInit, OnDestroy {
  private vacanciesValidator: AsyncValidatorFn;
  private selectedLocationInternal: OrganizationProfileLocation;
  private subscription: Subscription;

  @Input()
  form: FormArray;

  @Input()
  locations: OrganizationProfileLocation[];

  @Input()
  fields: OrganizationProfileFields;

  @Input()
  organizationId: string;

  selectedForm: FormGroup;
  selectedIndex: number;

  get selectedLocation(): OrganizationProfileLocation {
    return this.selectedLocationInternal;
  }

  set selectedLocation(selectedLocation: OrganizationProfileLocation) {
    this.selectedLocationInternal = selectedLocation;
    this.selectedIndex = this.form.controls.findIndex((c) => c.value.name === selectedLocation?.name);
    this.selectedForm = this.form.controls[this.selectedIndex] as FormGroup;
    this.changeDetector.detectChanges();
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly organizationProfileFormsService: OrganizationProfileFormsService,
    private readonly validator: OrganizationProfileLocationsValidator
  ) {}

  ngOnInit(): void {
    this.vacanciesValidator = this.validator.build(this.organizationId);
    for (const location of this.locations ?? []) {
      this.form.push(this.createFormGroup(location), { emitEvent: false });
    }
    this.subscription = this.form.valueChanges.subscribe((value) => {
      if (this.selectedIndex > -1) {
        this.selectedLocationInternal = value[this.selectedIndex];
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  addLocation(): void {
    const form = this.createFormGroup();
    this.form.push(form);
    this.form.markAsDirty();
    this.selectedLocation = form.value;
  }

  removeLocation(location: OrganizationProfileLocation): void {
    this.selectedLocation = null;
    this.form.removeAt(this.form.value.findIndex((x) => x.name === location.name));
    this.form.markAsDirty();
  }

  private createFormGroup(location?: OrganizationProfileLocation): FormGroup {
    const vacancies = (location?.vacancies ?? []).map((vacancy) =>
      this.organizationProfileFormsService.createVacancyFormGroup(vacancy)
    );
    return this.formBuilder.group({
      name: [location?.name, Validators.compose([Validators.required, Validators.maxLength(1000)])],
      beds: [location?.beds, Validators.min(0)],
      careFacility: [location?.careFacility, Validators.required],
      hasInternationalExperience: [location?.hasInternationalExperience, Validators.required],
      experienceField: [location?.experienceField],
      address: [location?.address, Validators.compose([Validators.required, Validators.maxLength(10000)])],
      zipcode: [
        location?.zipcode,
        {
          validators: Validators.compose([Validators.required, Validators.maxLength(5), Validators.minLength(5)]),
          updateOn: "blur",
        },
      ],
      state: [location?.state, Validators.required],
      city: [location?.city, Validators.required],
      website: [location?.website, Validators.maxLength(300)],
      vacancies: this.formBuilder.array(
        vacancies,
        Validators.compose([Validators.required, Validators.minLength(1)]),
        this.vacanciesValidator
      ),
    });
  }
}
