<ng-container *appTranslate="let t">
  <p-blockUI [target]="panel" [blocked]="!candidateId">
    <span class="block-ui-message">{{ t("file.imageWarning") }}</span>
  </p-blockUI>

  <p-panel #panel [formGroup]="form">
    <div class="flex">
      <div class="field flex-grow-0">
        <label>
          <ng-container *ngIf="pictureType === 'picture'; else alternativePicture">
            {{ t("candidate.image.title") }}
          </ng-container>
          <ng-template #alternativePicture>
            {{ t("candidate.alternativeImage.title") }}
          </ng-template>
        </label>

        <div class="flex flex-column justify-content-center align-items-center">
          <div class="pictureBox flex flex-row gap-2 align-items-start">
            <div class="flex flex-column gap-1">
              <div
                class="thumbnail"
                (click)="pictureType = 'picture'"
                [ngClass]="{ selected: pictureType === 'picture' }"
              >
                <img class="thumbnailPicture" id="pictureThumbnail" [src]="downloadUrl1" default="/assets/user.jpeg" />
              </div>

              <div
                class="thumbnail"
                (click)="pictureType = 'alternative-picture'"
                [ngClass]="{ selected: pictureType === 'alternative-picture' }"
              >
                <img
                  class="thumbnailPicture"
                  id="alternativePictureThumbnail"
                  [src]="downloadUrl2"
                  default="/assets/user.jpeg"
                />
              </div>
            </div>
            <img
              #image
              class="pictureInBox"
              id="myimage"
              [src]="downloadUrl"
              (load)="hasPicture = true"
              (error)="hasPicture = false"
              [style.display]="hasPicture ? 'block' : 'none'"
            />
            <img *ngIf="!hasPicture" class="pictureInBox" src="/assets/user.jpeg" />
          </div>

          <div class="flex flex-row justify-content-evenly gap-2 mt-3">
            <p-button
              icon="pi pi-upload"
              [label]="t('file.upload')"
              [disabled]="readonly"
              (onClick)="onUploadClick()"
            ></p-button>
            <ng-container *ngIf="hasPicture">
              <p-button
                [label]="t('common.edit')"
                icon="pi pi-pencil"
                [disabled]="readonly"
                (onClick)="onEdit()"
              ></p-button>
              <p-button
                [label]="t('common.delete')"
                icon="pi pi-trash"
                [disabled]="readonly"
                (onClick)="onDeleteClick($event)"
              ></p-button>
            </ng-container>
          </div>

          <input
            #uploadInput
            type="file"
            id="avatar"
            accept="image/*"
            multiple="false"
            hidden
            (input)="onFilesSelected()"
          />
          <p></p>
          <div *ngIf="progress != null" style="width: 300px">
            <p-progressBar class="mt-6" [showValue]="false" [value]="progress"></p-progressBar>
          </div>
        </div>
      </div>

      <div class="field flex-1 ml-6" style="position: relative">
        <label for="recommendationNote">{{ t("candidate.recommendationNote") }}</label>
        <button
          pButton
          type="button"
          [pTooltip]="t('cv.download')"
          tooltipPosition="left"
          icon="pi pi-file"
          class="p-button-rounded p-button-text"
          style="top: -7px; inset-inline-end: 0px; position: absolute"
          (click)="
            fileMenu?.length
              ? menu.toggle($event)
              : downloadCVasWord(sharing?.organizationId ?? candidateOrganizationId)
          "
          *ngIf="autoCVConfigured"
        ></button>
        <p-tieredMenu #menu [popup]="true" [model]="fileMenu"></p-tieredMenu>
        <p-editor
          id="recommendationNote"
          spellcheck="true"
          [lang]="language"
          [readonly]="readonly"
          (onInit)="updateEditor($event.editor)"
          (onTextChange)="updateHtml($event.htmlValue, 'user')"
          [style]="{ height: '200px' }"
        >
          <ng-template pTemplate="header">
            <span class="ql-formats">
              <select class="ql-size"></select>
            </span>
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
            </span>
            <span class="ql-formats">
              <select class="ql-color"></select>
              <select class="ql-background"></select>
            </span>
            <span class="ql-formats">
              <button class="ql-header" value="1"></button>
              <button class="ql-header" value="2"></button>
              <button class="ql-blockquote"></button>
            </span>
            <span class="ql-formats">
              <button class="ql-list" value="ordered"></button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-indent" value="-1"></button>
              <button class="ql-indent" value="+1"></button>
            </span>
            <span class="ql-formats">
              <button class="ql-direction" value="rtl"></button>
              <select class="ql-align"></select>
            </span>
            <span class="ql-formats">
              <button class="ql-link"></button>
            </span>
          </ng-template>
        </p-editor>
      </div>
    </div>
  </p-panel>
</ng-container>
