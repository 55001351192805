import { Directive } from "@angular/core";
import { PrimeIcons } from "primeng/api";
import { InputNumber } from "primeng/inputnumber";

@Directive({ selector: "p-inputNumber", standalone: false })
export class InputNumberOptionsDirective {
  constructor(private readonly inputNumber: InputNumber) {
    this.inputNumber.showButtons = true;
    this.inputNumber.decrementButtonClass = "p-button-sm";
    this.inputNumber.incrementButtonClass = "p-button-sm";
    if (this.inputNumber.buttonLayout === "horizontal") {
      this.inputNumber.incrementButtonIcon = PrimeIcons.PLUS;
      this.inputNumber.decrementButtonIcon = PrimeIcons.MINUS;
    }
  }
}
