import { NgModule } from "@angular/core";
import { FormlyModule } from "../../formly/formly.module";
import { SharedModule } from "../../shared/shared.module";
import { ProfessionModule } from "../profession/profession.module";
import { CandidateFilterPresetSelectorComponent } from "./candidate-filter-preset-selector/candidate-filter-preset-selector.component";
import { CandidateFilterSetComponent } from "./candidate-filter-set/candidate-filter-set.component";
import { CandidateFilterValueComponent } from "./candidate-filter-value/candidate-filter-value.component";
import { CandidateFilterDataSourceComponent } from "./candidate-filter-data-source/candidate-filter-data-source.component";

@NgModule({
  imports: [SharedModule, ProfessionModule, FormlyModule],
  declarations: [
    CandidateFilterPresetSelectorComponent,
    CandidateFilterSetComponent,
    CandidateFilterValueComponent,
    CandidateFilterDataSourceComponent,
  ],
  exports: [CandidateFilterPresetSelectorComponent, CandidateFilterSetComponent, CandidateFilterValueComponent],
})
export class CandidateFilterModule {}
