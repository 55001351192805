<app-table
  [columns]="columns"
  [items]="sharingTemplates"
  [captionOperations]="captionOperations"
  [tableOperations]="tableOperations"
  [(showSidebar)]="showDialog"
  sidebarSize="medium"
>
  <app-sharing-edit-dialog
    *ngIf="showDialog"
    (closed)="saveSharingTemplate($event)"
    [sharingOrTemplate]="sharingTemplateToEdit"
    [sharingProvider]="sharingTemplateProvider"
    [showManageCollectionMembers]="showManageCollectionMembers"
    [additionalOrganizations]="additionalOrganizations"
    [isSharingTemplate]="true"
  ></app-sharing-edit-dialog>
</app-table>
