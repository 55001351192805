import { Injectable } from "@angular/core";
import { isNil } from "lodash";
import { combineLatest, debounceTime, filter, forkJoin, map, switchMap, tap } from "rxjs";
import { CandidateFormModule } from "../../candidate-form/candidate-form.module";
import { CandidatesService } from "../candidates.service";
import { CurrentCollectionService } from "../current-collection.service";
import { CandidateFormComponent } from "./candidate-form.component";

/**
 * Does something on status or function changes.
 *
 * I don't know what exactly is being done here. I'm just keeping the form component smaller.
 */
@Injectable({ providedIn: CandidateFormModule })
export class CandidateFormStatusAndFunctionChangeService {
  constructor(
    private readonly candidateService: CandidatesService,
    private readonly currentCollectionService: CurrentCollectionService
  ) {}

  setup(component: CandidateFormComponent): void {
    combineLatest([component.form.controls.status.valueChanges, component.form.controls.function.valueChanges])
      .pipe(
        filter(
          () =>
            !isNil(component.form.controls.id.value) &&
            !isNil(component.sharing?.profileAccessId) &&
            !component.isPreparingCandidate
        ),
        switchMap(([candidateStatus, candidateFunction]) => {
          const { id, organizationId: orgId } = component.candidate;
          const collectionOrganizationId = this.currentCollectionService.organizationId;
          const { sharingId, sharingOrganizationId, collectionId } = this.currentCollectionService;

          const options = { collectionId, collectionOrganizationId, candidateStatus, candidateFunction };
          const candidate$ = this.candidateService.getCandidate(id, orgId, sharingId, sharingOrganizationId, options);
          const effectiveSharing$ = component
            .getEffectiveSharing(id, orgId, options)
            .pipe(map((sharing) => ({ ...sharing, sharedTabs: sharing.sharedTabs ?? [] })));

          return forkJoin([candidate$, effectiveSharing$]);
        }),
        tap(([candidate, { sharedTabs, tabHash }]) => {
          component.candidate.os = candidate.os;
          component.tabConfiguration = sharedTabs;
          component.tabHash = tabHash;
        }),
        debounceTime(50) // Give Formly some time.
      )
      .subscribe(([candidate, { sharedTabs }]) => {
        const tabKeys = sharedTabs.map((x) => x.key);
        component.clearValidatorsOfUnusedControls(component.form.controls.os.controls.profile);
        component.setCandidateTabConfiguration(component.enabledTabs, tabKeys, candidate.immigrationCountry);
      });
  }
}
