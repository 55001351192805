<app-organization-profile
  *ngIf="profile && fields"
  [profile]="profile"
  [fields]="fields"
  [disableSubmit]="disableSubmit"
  [title]="title"
  [displayBackButton]="displayBackButton"
  (saved)="save($event)"
  (canceled)="close()"
></app-organization-profile>
