import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Collection, CollectionAutoFilterTemplateForListFragment } from "@ankaadia/graphql";
import { clone } from "lodash";
import { TableRowSelectEvent } from "primeng/table";
import { CollectionAutoFilterTemplateService } from "../collection-auto-filter-template.service";

@Component({
  selector: "app-collection-auto-template-selection",
  templateUrl: "./collection-auto-template-selection.component.html",
  styleUrl: "./collection-auto-template-selection.component.scss",
  standalone: false,
})
export class CollectionAutoTemplateSelectionComponent implements OnInit, OnChanges {
  templates: CollectionAutoFilterTemplateForListFragment[];

  @Input() selectedTemplate: CollectionAutoFilterTemplateForListFragment;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly templateService: CollectionAutoFilterTemplateService
  ) {}

  get organizationId(): string {
    return this.route.snapshot.params.orgId;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedTemplate) {
      const templateId = changes.selectedTemplate.currentValue?.id;
      if (templateId && this.route.snapshot.firstChild?.params?.collectionId !== templateId) {
        this.navigate(changes.selectedTemplate.currentValue);
      }
    }
    if (!this.templates?.find((x) => x.id === this.selectedTemplate.id)) {
      this.getAllTemplates();
    }
  }

  ngOnInit(): void {
    this.getAllTemplates();
  }

  reload(): void {
    this.getAllTemplates();
  }

  onRowSelect(event: TableRowSelectEvent): void {
    this.navigate(event.data);
  }

  private navigate(col: Collection): void {
    void this.router.navigate([col.id], { relativeTo: this.route });
  }

  private getAllTemplates(): void {
    this.templateService
      .getAll({
        organizationId: this.organizationId,
      })
      .subscribe((x) => {
        this.templates = clone(x);
      });
  }
}
