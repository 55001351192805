import { Component, DestroyRef, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { PickPropertyKeys, nameofFactory } from "@ankaadia/ankaadia-shared";
import { DocumentRequirement, StaticDataType, SupportedImmigrationCountry } from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { isEmpty, isNil, keys } from "lodash";
import { SortEvent } from "primeng/api";
import { BehaviorSubject, debounceTime, distinctUntilChanged, map } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";
import { StaticDataContext, StaticDataService } from "../../shared/static-data/static-data.service";
import { DocumentRequirementForm, DocumentRequirementsForm } from "./document-requirement-form.model";
import { DocumentRequirementService } from "./document-requirement.service";

const nameOf = nameofFactory<DocumentRequirementTableComponent>();
type RequirementField = Exclude<PickPropertyKeys<DocumentRequirement, boolean>, "checkSpecificSets">;

@Component({
  selector: "app-document-requirement-table",
  templateUrl: "./document-requirement-table.component.html",
  styleUrl: "./document-requirement-table.component.scss",
  standalone: false,
})
export class DocumentRequirementTableComponent implements OnInit, OnChanges {
  protected allowedUploadFileTypes: Record<string, string> = {};
  protected filteredRequirementForms: DocumentRequirementForm[] = [];
  protected searchInput$ = new BehaviorSubject<string>(null);

  protected readonly scollItemSize = 54;
  protected readonly language = this.transloco.getActiveLang();
  protected readonly StaticDataType = StaticDataType;
  protected readonly keys = keys;

  protected readonly context: StaticDataContext = this.settings.supportedImmigrationCountries.map((country) => ({
    immigrationCountry: country as SupportedImmigrationCountry,
    organizationId: this.settings.organizationId,
  }));

  protected readonly columns: Record<RequirementField, string> = {
    isInternal: translate("isInternalDocument.title"),
    issueDate: translate("issueDate.title"),
    dateOfReceipt: translate("dateOfReceipt.title"),
    validFrom: translate("validFrom.title"),
    validUntil: translate("validUntil.title"),
    resubmissionDate: translate("resubmissionDate.title"),
    resubmissionReason: translate("resubmissionReason.title"),
    comment: translate("comment.title"),
    completionState: translate("completionState.title"),
    physicalTypes: translate("paperForm.title"),
    files: translate("documentSet.files.title"),
  };

  protected readonly checkSpecificSetsOptions = [
    { value: false, label: translate("documentSet.checkSpecificSets.options.no") },
    { value: true, label: translate("documentSet.checkSpecificSets.options.yes") },
  ];

  @Input({ required: true })
  form: DocumentRequirementsForm;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly settings: SettingsService,
    private readonly transloco: TranslocoService,
    private readonly staticDataService: StaticDataService,
    protected readonly service: DocumentRequirementService
  ) {}

  ngOnInit(): void {
    this.staticDataService
      .getStaticData(StaticDataType.AllowedUploadFileTypes, this.language, this.context)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map((x) => x.reduce((result, item) => ({ ...result, [item.value]: item.label }), {}))
      )
      .subscribe((result) => (this.allowedUploadFileTypes = result));

    this.searchInput$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        debounceTime(200),
        map((searchInput) => this.filterRequirementForms(searchInput))
      )
      .subscribe((forms) => (this.filteredRequirementForms = forms));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes[nameOf("form")]) {
      this.filteredRequirementForms = this.filterRequirementForms(this.searchInput$.value);
    }
  }

  customSort(event: SortEvent): void {
    const order = event.order === 1 ? 1 : -1;
    const field = event.field as keyof DocumentRequirement;

    event.data.sort((data1: DocumentRequirementForm, data2: DocumentRequirementForm) => {
      switch (field) {
        case "type": {
          const label1 = this.allowedUploadFileTypes[data1.value.type] ?? data1.value.type;
          const label2 = this.allowedUploadFileTypes[data2.value.type] ?? data2.value.type;
          return label1.localeCompare(label2) * order;
        }
        default: {
          return (data1.value[field] > data2.value[field] ? 1 : -1) * order;
        }
      }
    });
  }

  private filterRequirementForms(searchInput: string): DocumentRequirementForm[] {
    const controls = this.form.controls.requirements.controls;
    if (isNil(searchInput) || isEmpty(searchInput)) {
      return controls;
    }

    return controls.filter((form) => {
      const translation = this.allowedUploadFileTypes[form.controls.type.value] ?? form.controls.type.value;
      return translation.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase());
    });
  }
}
