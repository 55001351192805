<ng-container *appTranslate="let t">
  <form [formGroup]="form" styleClass="relative">
    <div class="p-fluid formgrid grid">
      <p-fieldset [legend]="t('responsibilities.title')" class="col-12 relative">
        <app-candidate-document-upload
          [fileTypes]="['VISA', 'REJECTVISA', 'CONFVISA']"
          [candidateId]="candidateId"
          [candidateOrganizationId]="candidateOrganizationId"
          [candidateForm]="form"
          [readonly]="readonly"
        ></app-candidate-document-upload>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 lg:col-6 mb-3">
            <app-candidate-responsible-role
              [form]="form"
              [responsibleRolePurpose]="ResponsibleRolePurposeType.Visa"
              [candidateId]="candidateId"
              [readonly]="readonly"
              [alwaysShownRoles]="responsibleRoles"
              class="field"
            ></app-candidate-responsible-role>
          </div>
        </div>
      </p-fieldset>

      <p-fieldset [legend]="t('visa.title')" class="col-12">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 lg:col-3">
            <app-candidate-residence-permit
              [form]="form"
              class="field"
              [renderLabelUsingAlignmentCheckBox]="true"
              [readonly]="readonly"
            ></app-candidate-residence-permit>
          </div>
          <div class="field col-12 lg:col-3">
            <div class="field-checkbox">
              <p-checkbox
                inputId="visaEnquired"
                [binary]="true"
                formControlName="visaEnquired"
                [readonly]="readonly"
              ></p-checkbox>
              <label for="visaEnquired">{{ t("visaEnquired.title") }}</label>
            </div>

            <p-calendar formControlName="visaEnquiredDate" [readonly]="readonly"></p-calendar>
          </div>
          <div class="field col-12 lg:col-3">
            <div class="field-checkbox">
              <p-checkbox class="alignment-checkbox" [readonly]="readonly"></p-checkbox>
              <label for="visaRequestDate">{{ t("visaRequestDate.title") }}</label>
            </div>

            <p-calendar formControlName="visaRequestDate" [readonly]="readonly"></p-calendar>
          </div>
          <div class="field col-12 lg:col-3 pt-6">
            <div class="field-checkbox">
              <p-checkbox
                inputId="visaRequestDate"
                [binary]="true"
                formControlName="visaRequested"
                [readonly]="readonly"
              ></p-checkbox>
              <label for="visaRequestDate">{{ t("visaRequested.title") }}</label>
            </div>
          </div>

          <div class="field col-12 lg:col-3">
            <label for="fileNumberVisa">{{ t("fileNumberVisa.title") }}</label>
            <input id="fileNumberVisa" pInputText formControlName="fileNumber" [readonly]="readonly" />
          </div>
          <div class="field col-12 lg:col-9"></div>

          <div class="field col-12 lg:col-3">
            <app-candidate-aligned-label
              labelFor="diplomaticMission"
              [labelText]="t('diplomaticMission.title')"
              [useAlignmentCheckBox]="true"
            ></app-candidate-aligned-label>

            <p-dropdown
              inputId="diplomaticMission"
              [options]="diplomaticMissionsOfCountryInGermany"
              formControlName="diplomaticMissionOfGermanyInCountry"
              [showClear]="true"
              [placeholder]="t('diplomaticMission.placeholder')"
              [readonly]="readonly"
            ></p-dropdown>
          </div>
          <app-candidate-document-date
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [candidateForm]="form"
            [readonly]="readonly"
            [label]="t('visaReceived.title')"
            documentType="VISA"
            selectedSet="Default"
            dateField="dateOfReceipt"
            class="field col-12 lg:col-3"
          ></app-candidate-document-date>
          <app-candidate-document-date
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [candidateForm]="form"
            [readonly]="readonly"
            [label]="t('visaValidFromDate.title')"
            documentType="VISA"
            selectedSet="Default"
            dateField="validFrom"
            class="field col-12 lg:col-3"
          ></app-candidate-document-date>
          <app-candidate-document-date
            [candidateId]="candidateId"
            [candidateOrganizationId]="candidateOrganizationId"
            [candidateForm]="form"
            [readonly]="readonly"
            [label]="t('visaValidUntilDate.title')"
            documentType="VISA"
            selectedSet="Default"
            dateField="validUntil"
            class="field col-12 lg:col-3"
          ></app-candidate-document-date>
        </div>
      </p-fieldset>

      <app-candidate-residence-permit-warnings
        class="col-12"
        styleClass="block mt-2 mb-3"
        [form]="form"
      ></app-candidate-residence-permit-warnings>

      <p-fieldset [legend]="t('visa.entry.title')" class="col-12 mb-2">
        <div class="p-fluid formgrid grid">
          <app-candidate-relocation-requirements
            class="field col-12 lg:col-3"
            [readonly]="readonly"
            [form]="form"
          ></app-candidate-relocation-requirements>
          <div class="field col-12 lg:col-3">
            <label for="responsibleForTransfer">{{ t("responsibleForTransfer.title") }}</label>
            <i class="pi pi-info-circle ml-2" [pTooltip]="t('responsibleForTransfer.info')"></i>
            <input
              id="responsibleForTransfer"
              pInputText
              formControlName="responsibleForTransfer"
              [readonly]="readonly"
            />
          </div>
          <div class="field col-12 lg:col-3">
            <label for="pickUpVehicle">{{ t("pickUpVehicle.title") }}</label>
            <input id="pickUpVehicle" pInputText formControlName="pickUpVehicle" [readonly]="readonly" />
          </div>
          <div class="field col-12 lg:col-3">
            <label for="contactAtArrivalLocation">{{ t("contactAtArrivalLocation.title") }}</label>
            <i class="pi pi-info-circle ml-2" [pTooltip]="t('contactAtArrivalLocation.info')"></i>
            <input
              id="contactAtArrivalLocation"
              pInputText
              formControlName="contactAtArrivalLocation"
              [readonly]="readonly"
            />
          </div>

          <div class="field col-12 lg:col-3">
            <app-candidate-arrival-date [form]="form" class="field" [readonly]="readonly"></app-candidate-arrival-date>
          </div>

          <p-fieldset [legend]="t('visa.entry.plane')" class="col-12">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 lg:col-3">
                <app-candidate-flight-date
                  [form]="form"
                  class="field"
                  [readonly]="readonly"
                ></app-candidate-flight-date>
              </div>
              <div class="field col-12 lg:col-3">
                <label for="departureAirport">{{ t("departureAirport.title") }}</label>
                <input id="departureAirport" pInputText formControlName="departureAirport" [readonly]="readonly" />
              </div>
              <div class="field col-12 lg:col-3">
                <label for="flightArrivalDate">{{ t("flightArrivalDate.title") }}</label>
                <app-date-time
                  [form]="form.controls.flightArrivalDate"
                  inputId="flightArrivalDate"
                  [readonly]="readonly"
                ></app-date-time>
              </div>
              <div class="field col-12 lg:col-3">
                <label for="arrivalAirport">{{ t("arrivalAirport.title") }}</label>
                <input id="arrivalAirport" pInputText formControlName="arrivalAirport" [readonly]="readonly" />
              </div>
              <div class="field col-12 lg:col-3">
                <label for="flightNumber">{{ t("flightNumber.title") }}</label>
                <input id="flightNumber" pInputText formControlName="flightNumber" [readonly]="readonly" />
              </div>
            </div>
          </p-fieldset>

          <p-fieldset [legend]="t('visa.entry.train')" class="col-12">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 lg:col-3">
                <label for="trainDepartureDate">{{ t("trainDepartureDate.title") }}</label>
                <app-date-time
                  [form]="form.controls.trainDepartureDate"
                  inputId="trainDepartureDate"
                  [readonly]="readonly"
                ></app-date-time>
              </div>
              <div class="field col-12 lg:col-3">
                <label for="departureTrainStation">{{ t("departureTrainStation.title") }}</label>
                <input
                  id="departureTrainStation"
                  pInputText
                  formControlName="departureTrainStation"
                  [readonly]="readonly"
                />
              </div>
              <div class="field col-12 lg:col-3">
                <label for="trainArrivalDate">{{ t("trainArrivalDate.title") }}</label>
                <app-date-time
                  [form]="form.controls.trainArrivalDate"
                  inputId="trainArrivalDate"
                  [readonly]="readonly"
                ></app-date-time>
              </div>
              <div class="field col-12 lg:col-3">
                <label for="arrivalTrainStation">{{ t("arrivalTrainStation.title") }}</label>
                <input
                  id="arrivalTrainStation"
                  pInputText
                  formControlName="arrivalTrainStation"
                  [readonly]="readonly"
                />
              </div>
              <div class="field col-12 lg:col-3">
                <label for="trainNumber">{{ t("trainNumber.title") }}</label>
                <input id="trainNumber" pInputText formControlName="trainNumber" [readonly]="readonly" />
              </div>
            </div>
          </p-fieldset>
        </div>
      </p-fieldset>
    </div>
  </form>
</ng-container>
