import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CollectionAutoFilterTemplateFragment } from "@ankaadia/graphql";
import { Message, TreeNode } from "primeng/api";
import { Subscription } from "rxjs";
import { CandidateFilterField } from "../../../candidate-filter/candidate-filter.model";
import { CollectionAutoFilterFieldsService } from "../../collection-auto/collection-auto-filter/collection-auto-filter-fields.service";
import { FilterMetadataMap } from "../../collection-edit-assigned-candidates/custom-filter.model";

@Component({
  selector: "app-collection-auto-template",
  templateUrl: "./collection-auto-template.component.html",
  standalone: false,
})
export class CollectionAutoTemplateComponent implements OnInit, OnDestroy, OnChanges {
  @Input() collectionAutoTemplate: CollectionAutoFilterTemplateFragment;
  @Input() isEditable: boolean;
  @Output() readonly reload = new EventEmitter<void>();

  private subscription?: Subscription;

  protected showDialog = false;
  protected messages: Message[] | undefined;
  private _filter: FilterMetadataMap;
  protected fields: TreeNode<CandidateFilterField>[] = [];

  protected readonly form: FormGroup = this.formBuilder.group({
    templateName: this.formBuilder.control(null, Validators.required),
  });

  constructor(
    private readonly collectionAutoFilterFieldService: CollectionAutoFilterFieldsService,
    private readonly formBuilder: FormBuilder
  ) {}

  protected get filter(): FilterMetadataMap {
    return this._filter;
  }

  protected set filter(value: FilterMetadataMap) {
    this._filter = value;
  }

  protected get includeSharedCandidates(): boolean {
    return this.collectionAutoTemplate?.includeSharedCandidates ?? false;
  }

  ngOnInit(): void {
    this.subscription = this.collectionAutoFilterFieldService.getFields().subscribe((fields) => {
      this.fields = fields;
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnChanges(): void {
    if (this.collectionAutoTemplate != null) {
      this.filter = this.collectionAutoTemplate.filters ?? {};
      this.form.patchValue(this.collectionAutoTemplate);
    }
  }

  editAutoFilter(): void {
    this.showDialog = true;
  }

  closeSidebar(_event: boolean): void {
    this.reload.emit();
    this.showDialog = false;
  }
}
