import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeValue } from "@angular/platform-browser";

@Pipe({ name: "safe", standalone: false })
export class SafePipe implements PipeTransform {
  constructor(private readonly domSanitizer: DomSanitizer) {}

  transform(value: string, mode: "html" | "resourceUrl" | "script" | "style" | "url"): SafeValue {
    switch (mode) {
      case "html":
        return this.domSanitizer.bypassSecurityTrustHtml(value);
      case "resourceUrl":
        return this.domSanitizer.bypassSecurityTrustResourceUrl(value);
      case "script":
        return this.domSanitizer.bypassSecurityTrustScript(value);
      case "style":
        return this.domSanitizer.bypassSecurityTrustStyle(value);
      case "url":
        return this.domSanitizer.bypassSecurityTrustUrl(value);
    }
  }
}
