<ng-container *appTranslate="let t">
  <form [formGroup]="form">
    <p-fieldset [legend]="t('healthProfessionsRegister.title')" class="relative">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 lg:col-6">
          <app-candidate-health-professions-register-appointment-date
            [form]="form"
            [readonly]="readonly"
            class="field"
          ></app-candidate-health-professions-register-appointment-date>
        </div>

        <div class="field col-12 lg:col-6">
          <app-candidate-health-professions-registration
            [form]="form"
            [readonly]="readonly"
            class="field"
          ></app-candidate-health-professions-registration>
        </div>

        <div class="field col-12 lg:col-6">
          <app-candidate-health-professions-register-submission
            [form]="form"
            [readonly]="readonly"
            class="field"
          ></app-candidate-health-professions-register-submission>
        </div>
      </div>
    </p-fieldset>
  </form>
</ng-container>
