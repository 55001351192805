import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { CandidateRole, getAllEducationVoucherRoles, getAllQualificationMeasureRoles } from "@ankaadia/ankaadia-shared";
import { QualificationMeasureCandidateDataDe, SupportedImmigrationCountry } from "@ankaadia/graphql";
import { Observable, map, startWith } from "rxjs";
import { CandidateEndOfFurtherEducationComponent } from "../../../../candidate-reusables/candidate-end-of-further-education/candidate-end-of-further-education.component";
import { CandidateQualificationMeasureComponent } from "../../../../candidate-reusables/candidate-qualification-measure/candidate-qualification-measure.component";
import { ResponsibleRolePurpose } from "../../../../candidate-reusables/candidate-responsible-representative-group/candidate-responsible-representative-group.service";
import { CandidateStartOfFurtherEducationComponent } from "../../../../candidate-reusables/candidate-start-of-further-education/candidate-start-of-further-education.component";
import { QualificationMeasureFormDe } from "../../../candidate-form.model";

@Component({
  selector: "app-candidate-qualification-measure-de",
  templateUrl: "./candidate-qualification-measure-de.component.html",
  standalone: false,
})
export class CandidateQualificationMeasureDEComponent implements AfterViewInit {
  readonly ImmigrationCountry = SupportedImmigrationCountry.De;
  readonly ResponsibleRolePurposeType = ResponsibleRolePurpose;

  protected readonly responsibleRoles = getAllQualificationMeasureRoles();
  protected readonly responsibleRolesEducationVoucher = getAllEducationVoucherRoles();

  @Input()
  form: QualificationMeasureFormDe;

  @Input()
  candidateId: string;

  @Input()
  candidateOrganizationId: string;

  @Input()
  readonly: boolean;

  @Input({ required: true })
  qualificationMeasureDataDe: QualificationMeasureCandidateDataDe;

  @ViewChild(CandidateQualificationMeasureComponent)
  protected qualificationMeasure: CandidateQualificationMeasureComponent;

  @ViewChild(CandidateStartOfFurtherEducationComponent)
  protected startOfFurtherEducation: CandidateStartOfFurtherEducationComponent;

  @ViewChild(CandidateEndOfFurtherEducationComponent)
  protected endOfFurtherEducation: CandidateEndOfFurtherEducationComponent;

  readonly today = new Date();

  selectedQualificationModules: Observable<string[]>;

  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.qualificationMeasure.valueChanged.pipe(startWith(this.qualificationMeasure.control.value)).subscribe((x) => {
      switch (x) {
        case "ADAPTATION":
          this.startOfFurtherEducation?.control.enable();
          this.endOfFurtherEducation?.control.enable();
          this.form.controls.theoryHours.enable();
          this.form.controls.theoryExamDate.enable();
          this.form.controls.theoryInstitution.enable();
          this.form.controls.courseNumber.enable();
          this.form.controls.practiceHours.enable();
          this.form.controls.practiceExamDate.enable();
          this.form.controls.practiceInstitution.enable();
          this.form.controls.careSituations.disable();
          break;
        case "KNOWLEDGE":
        case "ELIGIBILITY":
          this.startOfFurtherEducation?.control.enable();
          this.endOfFurtherEducation?.control.enable();
          this.form.controls.careSituations.enable();
          this.form.controls.theoryHours.disable();
          this.form.controls.theoryExamDate.disable();
          this.form.controls.theoryInstitution.enable();
          this.form.controls.courseNumber.enable();
          this.form.controls.practiceHours.disable();
          this.form.controls.practiceExamDate.disable();
          this.form.controls.practiceInstitution.enable();
          break;
        default:
          this.startOfFurtherEducation?.control.disable();
          this.endOfFurtherEducation?.control.disable();
          this.form.controls.theoryHours.disable();
          this.form.controls.theoryExamDate.disable();
          this.form.controls.theoryInstitution.disable();
          this.form.controls.courseNumber.disable();
          this.form.controls.practiceHours.disable();
          this.form.controls.practiceExamDate.disable();
          this.form.controls.practiceInstitution.disable();
          this.form.controls.careSituations.disable();
          break;
      }
      this.changeDetector.detectChanges();
    });

    this.form.controls.qualificationIsSend.valueChanges
      .pipe(startWith(this.form.controls.qualificationIsSend.value))
      .subscribe((x) =>
        x ? this.form.controls.qualificationIsSendDate.enable() : this.form.controls.qualificationIsSendDate.disable()
      );

    this.form.controls.educationVoucherRequested.valueChanges
      .pipe(startWith(this.form.controls.educationVoucherRequested.value))
      .subscribe((isTrue) => {
        if (isTrue) {
          this.form.controls.educationVoucherRequestDate.enable();
        } else {
          this.form.controls.educationVoucherRequestDate.setValue(null);
          this.form.controls.educationVoucherRequestDate.disable();
        }
      });

    this.selectedQualificationModules = this.form.controls.qualificationModules.valueChanges.pipe(
      startWith(this.form.controls.qualificationModules.value),
      map((x) => x.map((y) => y.name))
    );
  }

  protected get canHaveADeputyOrRepresentative(): boolean {
    const selectedRole = this.form.controls?.responsibleRoleEducationVoucher?.value;
    return selectedRole && ![CandidateRole].includes(selectedRole);
  }

  protected readonly ResponsiblePurpose = ResponsibleRolePurpose;
}
