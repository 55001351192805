<ng-container *appTranslate="let t">
  <form [formGroup]="form">
    <p-card [header]="isEditMode ? t('sharing.edit') : t('sharing.create')">
      <div class="p-fluid formgrid">
        <ng-container *ngIf="!isEditMode && sharingProvider?.type !== CollectionType.Auto">
          <div class="field">
            <label for="presetId">{{ t("sharingPreset.title") }}</label>
            <p-dropdown
              #presetSelector
              inputId="presetId"
              filterBy="name"
              [options]="presets$ | async | orderBy: ['name']"
              [placeholder]="t('sharingPreset.placeholder')"
              (onChange)="selectPreset($event.value)"
            >
              <ng-template *ngFor="let template of ['item', 'selectedItem']" let-preset [pTemplate]="template">
                <span>{{ preset.name }}</span>
                <span class="p-text-secondary ml-1">({{ preset.sharing.sharingType | enum: "SharingTypeEnum" }})</span>
              </ng-template>
            </p-dropdown>
          </div>

          <div class="field">
            <p-message
              *ngIf="presetSelector.selectedOption?.description as description"
              severity="info"
              [text]="description"
            ></p-message>
          </div>
        </ng-container>
      </div>

      <app-sharing-edit
        [form]="form"
        [sharingProvider]="sharingProvider"
        [showManageCollectionMembers]="showManageCollectionMembers"
        [additionalOrganizations]="additionalOrganizations"
        [destinationOrganizationId]="destinationOrganizationId"
        [isEditMode]="isEditMode"
      ></app-sharing-edit>

      <ng-template pTemplate="footer">
        <div class="flex flex-row justify-content-end">
          <p-checkbox
            [(ngModel)]="autoSync"
            [ngModelOptions]="{ standalone: true }"
            *ngIf="isEditMode && isSharingTemplate"
            [binary]="true"
            label="{{ t('autoCollectionTemplate.syncAll') }}"
            class="mr-4"
          >
          </p-checkbox>
          <p-button
            [disabled]="!form.valid || !form.dirty || disableSubmit"
            [label]="t('common.save')"
            icon="pi pi-check"
            (onClick)="save($event)"
            class="mr-2"
            data-testid="btn-save-sharing"
          ></p-button>
          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            type="reset"
            (onClick)="cancel()"
            data-testid="btn-cancel-sharing"
          ></p-button>
        </div>
      </ng-template>
    </p-card>
  </form>
</ng-container>
