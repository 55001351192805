import { Component, Input } from "@angular/core";
import { DocumentTemplateWarning } from "@ankaadia/graphql";

@Component({
  selector: "app-document-template-warnings",
  templateUrl: "./document-template-warnings.component.html",
  standalone: false,
})
export class DocumentTemplateWarningsComponent {
  @Input()
  warnings: DocumentTemplateWarning[];
}
