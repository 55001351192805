<ng-container *appTranslate="let t">
  <div *ngIf="favorite" class="inline-block" [pTooltip]="favorite.isReadonly ? t('implicitFavorite.title') : null">
    <button
      pButton
      type="button"
      icon="pi pi-star{{ favorite.isFavorite ? '-fill' : '' }} text-lg"
      [disabled]="favorite.isReadonly"
      class="p-button-rounded p-button-text"
      (click)="toggle()"
    ></button>
  </div>
</ng-container>
