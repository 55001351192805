import { Component, DestroyRef } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from "@angular/router";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import {
  EducationExamCandidateIdInput,
  EducationExamParticipant,
  EducationExamParticipantFragment,
} from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { BehaviorSubject, map, Observable, switchMap, tap } from "rxjs";
import { TableColumn, TableOperation } from "../../../shared/table/table.model";
import { CandidatesService } from "../../candidates/candidates.service";
import { MessageService } from "../../message/message.service";
import { EducationExamService } from "../education-exams/education-exam.service";
import { EducationExamParticipantsService } from "./education-exam-participants.service";
import { CandidateFlexibleEditService } from "../../candidates/candidate-flexible-edit/candidate-flexible-edit.service";

const nameOf = nameofFactory<EducationExamParticipantFragment>();

@Component({
  selector: "app-education-exam-participants",
  templateUrl: "./education-exam-participants.component.html",
  styleUrl: "./education-exam-participants.component.scss",
  standalone: false,
})
export class EducationExamParticipantsComponent {
  protected readonly examId$ = new BehaviorSubject<{ examId: string; organizationId: string }>(undefined);
  protected candidates: EducationExamParticipantFragment[];

  protected readonly title$ = this.getExamTitle();
  protected readonly newOperations: TableOperation[] = this.getNewOperations();
  protected readonly tableOperations: TableOperation[] = this.getTableOperations();
  protected readonly columns: TableColumn[] = this.getTableColumns();

  protected showSidebar = false;

  constructor(
    private readonly examService: EducationExamService,
    private readonly examParticipantsService: EducationExamParticipantsService,
    private readonly candidateService: CandidatesService,
    private readonly candidateFlexibleEditService: CandidateFlexibleEditService,
    private readonly route: ActivatedRoute,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly transloco: TranslocoService,
    private readonly destroyRef: DestroyRef
  ) {
    this.route.paramMap.pipe(takeUntilDestroyed()).subscribe((x) => {
      this.examId$.next({
        examId: x.get("examId"),
        organizationId: x.get("orgId"),
      });
    });

    this.examId$
      .pipe(
        takeUntilDestroyed(),
        switchMap((x) => this.examParticipantsService.getAll(x))
      )
      .subscribe((x) => (this.candidates = x));
  }

  save(input: EducationExamCandidateIdInput[]): void {
    this.examParticipantsService
      .createManyExamParticipants({
        participants: input,
        organizationId: this.examId$.value.organizationId,
        examId: this.examId$.value.examId,
      })
      .subscribe((x) => {
        this.candidates = [...this.candidates, ...x];
        this.showSidebar = false;
      });
  }

  private getNewOperations(): TableOperation[] {
    return [
      {
        label: translate("educationExamParticipants.addToExam"),
        icon: PrimeIcons.PLUS,
        operation: (): void => {
          this.showSidebar = true;
        },
        disabled: (): boolean => false,
      },
    ];
  }

  private getTableOperations(): TableOperation[] {
    return [
      {
        label: translate("common.delete"),
        icon: PrimeIcons.TRASH,

        operation: (exam: EducationExamParticipantFragment, e: Event): void => this.deleteOperation(exam, e),
        disabled: (): boolean => false,
      },
    ];
  }

  private getTableColumns(): TableColumn[] {
    return [
      {
        header: translate("number.title"),
        fieldname: nameOf("candidateDisplayId"),
        routeLink: (x: EducationExamParticipant): any[] => this.getRouteLink(x),
        sortable: true,
        includeInGlobalFilter: true,
        includeFlag: true,
        canIconClick: () => true,
        icon: () => PrimeIcons.USER_EDIT,
        iconClick: (examParticipant: EducationExamParticipant) =>
          this.candidateFlexibleEditService.openCandidate(
            examParticipant.candidateId,
            examParticipant.candidateOrganizationId
          ),
        iconClass: "ml-2",
      },
      {
        header: translate("name.title"),
        fieldname: nameOf("candidateDisplayName"),
        sortable: true,
        includeInGlobalFilter: true,
      },
    ];
  }

  private getRouteLink(x: EducationExamParticipant): string[] {
    return this.candidateService.getCandidateEditLink(x.candidateId, x.candidateOrganizationId);
  }

  private deleteOperation(exam: EducationExamParticipantFragment, event: Event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("educationExamParticipants.confirmDelete"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.examParticipantsService
          .deleteExamParticipant(exam, exam.candidateId)
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            tap(() =>
              this.messageService.add({
                detail: translate("educationExamParticipants.deleted.message"),
                severity: "success",
                summary: translate("educationExamParticipants.deleted.title"),
              })
            )
          )
          .subscribe(() => (this.candidates = this.candidates.filter((x) => x.id !== exam.id)));
      },
    });
  }

  private getExamTitle(): Observable<string> {
    return this.examId$.pipe(
      switchMap((x) => this.examService.getSingle(x.organizationId, x.examId)),
      map((x) => this.transloco.translate("educationExamParticipants.title", x))
    );
  }
}
