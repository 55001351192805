<ng-container *appTranslate="let t">
  <app-table
    *ngIf="contacts"
    [title]="title"
    [items]="contacts"
    [columns]="columns"
    [captionOperations]="captionOperations"
    [footerOperations]="footerOperations"
    [tableOperations]="tableOperations"
    [(showSidebar)]="showSidebar"
  >
    <ng-template #caption>
      <div class="formgrid grid">
        <div class="field col-fixed relative">
          <label for="defaultRepresentative" class="default-selector-label">
            {{ t("representative.default") }}
          </label>

          <p-dropdown
            #defaultRepresentative
            styleClass="default-selector"
            inputId="defaultRepresentative"
            [options]="contacts"
            optionValue="id"
            filterBy="firstName,lastName,email,phone,mobilePhone,position"
            [showClear]="true"
            (onChange)="updateDefaults($event.value, defaultFirstDeputy.value, defaultRecognitionContactPerson.value)"
          >
            <ng-template *ngFor="let template of ['item', 'selectedItem']" let-item [pTemplate]="template">
              <span>{{ item.lastName }}, {{ item.firstName }}</span>
            </ng-template>
          </p-dropdown>
        </div>

        <div class="field col-fixed relative">
          <label for="defaultFirstDeputy" class="default-selector-label">
            {{ t("firstDeputy.default") }}
          </label>

          <p-dropdown
            #defaultFirstDeputy
            styleClass="default-selector"
            inputId="defaultFirstDeputy"
            [options]="contacts"
            optionValue="id"
            filterBy="firstName,lastName,email,phone"
            [showClear]="true"
            (onChange)="
              updateDefaults(defaultRepresentative.value, $event.value, defaultRecognitionContactPerson.value)
            "
          >
            <ng-template *ngFor="let template of ['item', 'selectedItem']" let-item [pTemplate]="template">
              <span>{{ item.lastName }}, {{ item.firstName }}</span>
            </ng-template>
          </p-dropdown>
        </div>

        <div class="field col-fixed relative">
          <label for="defaultRecognitionContactPerson" class="default-selector-label">
            {{ t("recognitionContactPerson.default") }}
          </label>

          <p-dropdown
            #defaultRecognitionContactPerson
            styleClass="default-selector"
            inputId="defaultRecognitionContactPerson"
            [options]="contacts"
            optionValue="id"
            filterBy="firstName,lastName,email,phone"
            [showClear]="true"
            (onChange)="updateDefaults(defaultRepresentative.value, defaultFirstDeputy.value, $event.value)"
          >
            <ng-template *ngFor="let template of ['item', 'selectedItem']" let-item [pTemplate]="template">
              <span>{{ item.lastName }}, {{ item.firstName }}</span>
            </ng-template>
          </p-dropdown>
        </div>

        <div class="field col-fixed">
          <i class="pi pi-info-circle mt-3" [pTooltip]="t('contacts.help')"></i>
        </div>
      </div>
    </ng-template>

    <app-organization-contact-edit-dialog
      *ngIf="selectedContact"
      [contact]="selectedContact"
      [submitDisabled]="isBusy"
      [organizationId]="organizationId"
      [isNewFromUsers]="isNewFromUsers"
      (saved)="save($event)"
      (closed)="close()"
    ></app-organization-contact-edit-dialog>
  </app-table>
</ng-container>
