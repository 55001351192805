import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { EducationCourseForViewFragment } from "@ankaadia/graphql";

@Component({
  selector: "app-education-course-events",
  templateUrl: "./education-course-events.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class EducationCourseEventsComponent {
  @Input()
  course: EducationCourseForViewFragment;
}
