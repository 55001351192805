import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup } from "@angular/forms";
import { TranslocoService } from "@jsverse/transloco";
import {
  CandidateResponsibleOrganizationComponent,
  ResponsibleOrganizationModelData,
} from "../candidate-responsible-organization/candidate-responsible-organization.component";
import {
  CandidateResponsibleRepresentativeComponent,
  ResponsibleRepresentativeModelData,
} from "../candidate-responsible-representative/candidate-responsible-representative.component";
import {
  CandidateResponsibleRepresentativeGroupService,
  ResponsibleGroupInputSourceData,
  ResponsibleRepresentativePurpose,
  ResponsibleRolePurpose,
  ResponsibleRolePurposeGroup,
  ResponsibleRolePurposeWithOrganizationMapping,
  ResponsibleRolePurposeWithRoleMapping,
} from "./candidate-responsible-representative-group.service";

export interface ResponsibleRepresentativeGroupPurposeConfig {
  roleModelData: string;
  organizationModelData: ResponsibleOrganizationModelData;
  representativeModelData: ResponsibleRepresentativeModelData;
  deputyModelData: ResponsibleRepresentativeModelData;
}

@Component({
  selector: "app-candidate-responsible-representative-group",
  templateUrl: "./candidate-responsible-representative-group.component.html",
  standalone: false,
})
export class CandidateResponsibleRepresentativeGroupComponent implements OnInit, OnChanges {
  private readonly language = this.transloco.getActiveLang();
  protected readonly ResponsibleRolePurpose = ResponsibleRolePurpose;

  @Input({ required: true })
  form: FormGroup;

  @Input({ required: true })
  responsibleRolePurpose: ResponsibleRolePurposeGroup;

  @Input({ required: true })
  candidateOrganizationId: string;

  @Input()
  candidateId: string;

  @Input({ required: true })
  purposeData: ResponsibleGroupInputSourceData;

  @Input()
  processLanguage?: string = this.language;

  @Input()
  readonly = false;

  @Input()
  alwaysShownRoles: string[] = [];

  @Input()
  canHaveADeputyOrRepresentative = true;

  @Input() styleRoleClass: string;
  @Input() styleOrganizationClass: string;
  @Input() styleRepresentativeClass: string;
  @Input() styleDeputyClass: string;

  @Output()
  readonly responsibleRoleChanged = new EventEmitter<string>();

  @Output()
  readonly responsibleOrganizationChanged = new EventEmitter<string>();

  protected roleModelData: string;
  protected organizationModelData: ResponsibleOrganizationModelData;
  protected representativeModelData: ResponsibleRepresentativeModelData;
  protected deputyModelData: ResponsibleRepresentativeModelData;

  //shut up the linter (not able to detect the type of the purpose correctly)
  protected rolePurpose: ResponsibleRolePurposeWithRoleMapping;
  protected organizationPurpose: ResponsibleRolePurposeWithOrganizationMapping;
  protected representativePurpose: ResponsibleRepresentativePurpose;
  protected deputyPurpose: ResponsibleRepresentativePurpose;

  @ViewChild("responsibleOrganization")
  organizationComponent: CandidateResponsibleOrganizationComponent;

  @ViewChild("representative")
  representativeComponent: CandidateResponsibleRepresentativeComponent;

  @ViewChild("deputy")
  firstDeputyComponent: CandidateResponsibleRepresentativeComponent;

  constructor(
    private readonly transloco: TranslocoService,
    private readonly groupService: CandidateResponsibleRepresentativeGroupService
  ) {
    this.responsibleRoleChanged.pipe(takeUntilDestroyed()).subscribe(() => this.resetOrganizationAndRepresentatives());
    this.responsibleOrganizationChanged.pipe(takeUntilDestroyed()).subscribe(() => this.resetRepresentatives());
  }

  ngOnInit(): void {
    this.rolePurpose = this.responsibleRolePurpose;
    this.organizationPurpose = this.responsibleRolePurpose;
    this.representativePurpose = {
      purpose: this.responsibleRolePurpose,
      function: "Representative",
    };
    this.deputyPurpose = {
      purpose: this.responsibleRolePurpose,
      function: "Deputy",
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.purposeData || changes.purpose) {
      this.setPurposeData();
    }
  }

  protected get disabledRepresentatives(): boolean {
    return this.readonly || !this.organizationComponent?.control?.value;
  }

  private getConfig(): ResponsibleRepresentativeGroupPurposeConfig {
    return this.groupService.getResponsibleGroupPurposeConfig(this.responsibleRolePurpose, this.purposeData);
  }

  private setPurposeData(): void {
    const config = this.getConfig();
    this.representativeModelData = config?.representativeModelData;
    this.deputyModelData = config?.deputyModelData;
    this.organizationModelData = config?.organizationModelData;
    this.roleModelData = config?.roleModelData;
  }

  private resetOrganizationAndRepresentatives(): void {
    this.organizationComponent?.control?.setValue(null);
    this.resetRepresentatives();
  }

  private resetRepresentatives(): void {
    this.representativeComponent?.control?.setValue(null);
    this.firstDeputyComponent?.control?.setValue(null);
  }
}
