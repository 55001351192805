<ng-container *ngIf="translations$ | async as trans">
  <ng-container *ngIf="showControl">
    <app-candidate-aligned-label
      class="field"
      [labelFor]="controlNames.id"
      [labelText]="trans.caption"
      [useAlignmentCheckBox]="false"
    ></app-candidate-aligned-label>

    <p-dropdown
      [inputId]="controlNames.id"
      [options]="organizationContacts"
      optionValue="id"
      filterBy="firstName,lastName"
      [formControl]="control"
      [showClear]="true"
      [placeholder]="trans.placeholder"
      [readonly]="readonly"
      formControlValueAlwaysInOptions
      appCandidateFieldNotShared
      [isShared]="isShared"
      (onChange)="valueChanged.emit($event.value)"
    >
      <ng-template *ngFor="let template of ['item', 'selectedItem']" let-item [pTemplate]="template">
        <span>{{ item.lastName }}, {{ item.firstName }}</span>
      </ng-template>
    </p-dropdown>

    <small class="p-error" *ngIf="control.errors?.required">
      {{ trans.required }}
    </small>
  </ng-container>
</ng-container>
