import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { translate } from "@jsverse/transloco";
import { ConfirmationService, PrimeIcons, TreeNode } from "primeng/api";
import { BehaviorSubject } from "rxjs";
import { CandidateFilterField } from "../../../candidate-filter/candidate-filter.model";
import { FilterMetadataMap } from "../../collection-edit-assigned-candidates/custom-filter.model";
import { CollectionAutoFiltersForm } from "../collection-auto-filter-form.model";
import { CollectionAutoFilterFormService } from "../collection-auto-filter-form.service";

@Component({
  selector: "app-collection-auto-filter-edit",
  templateUrl: "./collection-auto-filter-edit.component.html",
  styleUrl: "./collection-auto-filter-edit.component.scss",
  standalone: false,
})
export class CollectionAutoFilterEditComponent implements OnInit {
  protected readonly form: CollectionAutoFiltersForm = this.formService.createAutoCollectionFiltersForm();
  protected readonly includeSharedCandidatesSubject = new BehaviorSubject(false);

  @Input()
  fields: TreeNode<CandidateFilterField>[] = [];

  @Input()
  filter: FilterMetadataMap = {};

  @Input()
  set includeSharedCandidates(value: boolean) {
    this.includeSharedCandidatesSubject.next(value);
  }

  get includeSharedCandidates(): boolean {
    return this.includeSharedCandidatesSubject?.value ?? false;
  }

  @Output()
  readonly saved = new EventEmitter<CollectionAutoFiltersForm>();

  @Output()
  readonly closed = new EventEmitter<void>();

  constructor(
    private readonly formService: CollectionAutoFilterFormService,
    private readonly confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.includeSharedCandidatesSubject.subscribe((value) => {
      this.form.controls.includeSharedCandidates.setValue(value);
    });
  }

  onSave(event: Event): void {
    this.confirmationService.confirm({
      key: "centeredDialog",
      target: event.target,
      message: translate("autoCollection.confirmUpdateFilter"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.saved.emit(this.form);
      },
    });
  }

  onClose(): void {
    this.closed.emit();
  }
}
