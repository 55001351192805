<ng-container *appTranslate="let t">
  <form class="app-form" [formGroup]="form" (ngSubmit)="submit()">
    <p-card styleClass="overflow-card">
      <ng-template pTemplate="header" *ngIf="title">
        <div class="pt-5 pb-4 pl-3 pr-3">
          <div class="pl-1 pr-1 pt-2 mt-1">
            <h3>{{ title }}</h3>
          </div>
        </div>
      </ng-template>

      <div class="p-fluid">
        <div class="field col-12 lg:col-3">
          <label for="contactUserGroups">{{ t("contactUserGroups.title") }}</label>
          <p-multiSelect
            inputId="contactUserGroups"
            [options]="userGroups"
            [placeholder]="t('contactUserGroups.placeholder')"
            formControlName="contactUserGroups"
            optionLabel="name"
            optionValue="id"
            (onChange)="updateUserGroupSelection($event.value, $event.itemValue.id)"
          ></p-multiSelect>
        </div>

        <div class="description-row">
          <div class="left-cell">
            <div class="field">
              <app-organization-profile-images
                [form]="$any(form.controls.images)"
                [organizationId]="profile.organizationId"
              ></app-organization-profile-images>
            </div>
          </div>

          <div class="right-cell">
            <div class="field">
              <label for="description">{{ t("description.title") }}</label>
              <app-organization-profile-description
                [form]="description"
                [description]="profile.description"
              ></app-organization-profile-description>
            </div>
          </div>
        </div>

        <p-fieldset [legend]="t('locations.title')">
          <app-organization-profile-locations
            [form]="locations"
            [locations]="profile.locations"
            [fields]="fields"
            [organizationId]="profile.organizationId"
          ></app-organization-profile-locations>
        </p-fieldset>
      </div>

      <ng-template pTemplate="footer">
        <div
          class="flex"
          [class.justify-content-end]="!displayBackButton"
          [class.justify-content-between]="displayBackButton"
        >
          <p-button
            *ngIf="displayBackButton"
            [routerLink]="['/app/settings/organizations', myOrganizationId]"
            [label]="t('common.back')"
            icon="pi pi-angle-left"
          ></p-button>
          <div class="flex align-items-center">
            <div class="field-checkbox mb-0 mr-2">
              <p-checkbox formControlName="isMatchingEnabled" [binary]="true" inputId="isMatchingEnabled"></p-checkbox>
              <label for="isMatchingEnabled">{{ t("applications.receive") }}</label>
            </div>

            <p-button
              [disabled]="!form.valid || !form.dirty || disableSubmit"
              [label]="t('common.save')"
              icon="pi pi-check"
              type="submit"
              class="mr-2"
            ></p-button>

            <p-button
              *ngIf="!displayBackButton"
              [label]="t('common.cancel')"
              icon="pi pi-times"
              class="p-button-secondary mr-2"
              type="reset"
              (onClick)="canceled.emit()"
            ></p-button>
          </div>
        </div>
      </ng-template>
    </p-card>
  </form>
</ng-container>
