<div class="flex flex-column app-form" *appTranslate="let t">
  <p-toolbar>
    <ng-template pTemplate="left">
      <p-dropdown
        [options]="availableOrgs$ | async"
        optionLabel="name"
        (onChange)="orgSelected($event.value)"
        [placeholder]="t('organization.filter')"
        filterBy="name"
        [showClear]="true"
        class="mr-2"
      ></p-dropdown>
    </ng-template>

    <ng-template pTemplate="right">
      <p-splitButton
        #splitBtn
        [label]="t('collection.new')"
        icon="pi pi-plus"
        class="inline-block p-splitbutton-success mr-2 mb-2"
        styleClass="p-splitbutton-success"
        [disabled]="newButtonDisabled"
        (onClick)="newClicked()"
        (onDropdownClick)="eventTarget = splitBtn?.containerViewChild?.nativeElement"
        [model]="operations"
        data-testid="btn-new-collection"
      ></p-splitButton>
    </ng-template>
  </p-toolbar>

  <p-splitter
    class="app-form"
    styleClass="mb-5 app-form"
    [panelSizes]="[20, 80]"
    [minSizes]="[10, 50]"
    stateKey="ankaadia_candidate_splitter"
  >
    <ng-template pTemplate>
      <app-collection-selection
        class="w-full"
        [selectedCollection]="selectedCollection"
        [filteredOrg]="filteredOrg"
      ></app-collection-selection>
    </ng-template>

    <ng-template pTemplate>
      <router-outlet></router-outlet>
    </ng-template>
  </p-splitter>
</div>

<p-sidebar [visible]="showSidebar" styleClass="p-sidebar-md" data-testid="sidebar-new-collection">
  <app-collection-edit-dialog
    *ngIf="showSidebar"
    (closed)="closeSidebar($event)"
    [isEdit]="false"
    [collection]="newCollection"
  ></app-collection-edit-dialog>
</p-sidebar>
