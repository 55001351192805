<ng-container *appTranslate="let t">
  <form [formGroup]="form" *ngIf="form">
    <p-card [header]="exam?.id ? t('educationExam.edit') : t('educationExam.create')">
      <div class="p-fluid formgrid">
        <div class="field">
          <label for="name">{{ t("name.title") }}</label>
          <input type="text" pInputText id="name" formControlName="name" required />
          <small class="p-error" *ngIf="form.controls.name.errors?.required">{{ t("name.required") }}</small>
        </div>
      </div>

      <app-education-exam-dialog-education-fields [form]="form" [exam]="exam" [courses]="courses" [modules]="modules" />

      <app-education-exam-dialog-language-learning-fields [form]="form" [existingExamModules]="exam?.examModules" />

      <app-education-exam-dialog-general-fields [form]="form" />

      <ng-template pTemplate="footer">
        <app-edit-tool-bar
          class="flex flex-row justify-content-end"
          [showBtn]="{ cancel: true, save: true }"
          [saveBtn]="{
            disabled:
              !form.valid || !form.dirty || (!isEmpty(exam?.examModules) && isEmpty(form.controls.examModules.value)),
          }"
          (canceled)="cancel()"
          (saved)="save()"
        ></app-edit-tool-bar>
      </ng-template>
    </p-card>
  </form>
</ng-container>
