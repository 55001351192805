import { Injectable } from "@angular/core";
import {
  GetOrganizationContactSettingsGQL,
  OrganizationContactSettingsFragment,
  OrganizationContactSettingsSetInput,
  SetOrganizationContactSettingsGQL,
} from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class OrganizationContactSettingsService {
  constructor(
    private readonly settingsGet: GetOrganizationContactSettingsGQL,
    private readonly settingsSet: SetOrganizationContactSettingsGQL
  ) {}

  get(organizationId: string, useCache = false): Observable<OrganizationContactSettingsFragment> {
    return this.settingsGet
      .fetch(
        { input: { organizationId: organizationId } },
        useCache ? { fetchPolicy: "cache-first" } : { fetchPolicy: "network-only" }
      )
      .pipe(map((x) => x.data.getOrganizationContactSettings));
  }

  set(settings: OrganizationContactSettingsSetInput): Observable<OrganizationContactSettingsFragment> {
    return this.settingsSet.mutate({ input: settings }).pipe(map((x) => x.data.setOrganizationContactSettings));
  }
}
