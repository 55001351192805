<ng-container *appTranslate="let t">
  <ng-container *ngIf="readonly">
    <div
      *ngIf="control.value as value"
      class="p-tag p-component text-base app-tag-warning white-space-nowrap text-overflow-ellipsis overflow-hidden"
    >
      <span class="p-tag-value">{{ value | enum: "CandidateStatus" }}</span>
    </div>
  </ng-container>
  <div class="field" *ngIf="!readonly">
    <label for="status">{{ t("status.title") }}</label>
    <p-dropdown
      id="status"
      styleClass="w-full"
      [formControl]="control"
      [options]="options"
      [placeholder]="t('status.placeholder')"
    ></p-dropdown>
  </div>
</ng-container>
