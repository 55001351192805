<ng-container *appTranslate="let t">
  <p-card class="app-form">
    <div class="flex flex-column app-form">
      <div class="flex flex-row justify-content-between">
        <div>
          <h3 class="ml-3">
            <span>{{ collectionAutoTemplate?.name }}</span>
          </h3>
        </div>
        <div class="button-container">
          <p-button
            *ngIf="collectionAutoTemplate"
            [label]="t('autoCollectionTemplate.applyOnAllCandidates.title')"
            (onClick)="applyOnAllCandidates($event)"
            class="p-button-success mr-2 mb-2"
          ></p-button>
          <p-button
            *ngIf="collectionAutoTemplate"
            [label]="t('common.edit')"
            (onClick)="editCollection()"
            icon="pi pi-pencil"
            class="p-button-success mr-2 mb-2"
          ></p-button>
          <p-button
            *ngIf="collectionAutoTemplate"
            [label]="t('common.delete')"
            (onClick)="showDeletePrompt()"
            icon="pi pi-trash"
            class="p-button-success mr-2 mb-2"
          ></p-button>
          <p-dialog
            *ngIf="isDeletePromptVisible"
            [visible]="true"
            (visibleChange)="isDeletePromptVisible = false"
            [header]="t('autoCollectionTemplate.deletePrompt.title')"
          >
            <div>
              <p>{{ t("autoCollectionTemplate.deletePrompt.message", collectionAutoTemplate) }}</p>

              <p>{{ t("autoCollectionTemplate.deletePrompt.deleteBehavior") }}</p>
            </div>
            <div class="flex flex-column gap-3">
              <div class="flex align-items-center">
                <p-radioButton
                  name="deletionBehavior"
                  value="Detach"
                  [(ngModel)]="deletionBehavior"
                  inputId="deletionBehaviorDetach"
                />
                <label for="deletionBehaviorDetach" class="ml-2">
                  {{ t("autoCollectionTemplate.deletePrompt.deleteBehaviorOptions.detach") }}
                </label>
              </div>

              <div class="flex align-items-center">
                <p-radioButton
                  name="deletionBehavior"
                  value="Delete"
                  [(ngModel)]="deletionBehavior"
                  inputId="deletionBehaviorDelete"
                />
                <label for="deletionBehaviorDelete" class="ml-2">
                  {{ t("autoCollectionTemplate.deletePrompt.deleteBehaviorOptions.delete") }}
                </label>
              </div>
            </div>

            <p-footer>
              <p-button
                label="{{ t('common.cancel') }}"
                (onClick)="isDeletePromptVisible = false"
                class="p-button-text"
              ></p-button>
              <p-button
                label="{{ t('common.delete') }}"
                (onClick)="deleteCollection($event); isDeletePromptVisible = false"
                class="p-button-danger"
              ></p-button>
            </p-footer>
          </p-dialog>
        </div>
      </div>

      <div class="app-form">
        <p-tabView class="p-fluid">
          <p-tabPanel [header]="t('autoCollectionTemplate.autoTemplate')">
            <app-collection-auto-template
              [collectionAutoTemplate]="collectionAutoTemplate"
              (reload)="reloadCollection()"
            ></app-collection-auto-template>
          </p-tabPanel>
          <p-tabPanel [header]="t('autoCollectionTemplate.collections.title')">
            <app-collection-auto-template-collections [collectionAutoTemplate]="collectionAutoTemplate">
            </app-collection-auto-template-collections>
          </p-tabPanel>
          <p-tabPanel [header]="t('sharings.title')">
            <app-sharing-templates-form
              [sharingTemplateProvider]="collectionAutoTemplate"
              [allowedVariables]="usedVariables"
              [showManageCollectionMembers]="false"
              [additionalOrganizations]="additionalOrganizations"
              (autoSyncTriggered)="triggerAutoSync()"
            ></app-sharing-templates-form>
          </p-tabPanel>

          <p-tabPanel [header]="t('description.title')" [cache]="false">
            <ng-template pTemplate="content">
              <textarea
                class="collection-description"
                pInputTextarea
                readonly
                id="description"
                [(ngModel)]="collectionAutoTemplate.description"
                [autoResize]="true"
              ></textarea>
            </ng-template>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </p-card>

  <p-sidebar [visible]="showSidebar" styleClass="p-sidebar-lg">
    <app-collection-auto-template-edit-dialog
      *ngIf="showSidebar"
      (closed)="closeSidebar()"
      [isEdit]="true"
      [collectionAutoTemplate]="collectionAutoTemplate"
      [checkVariables]="collectionAutoTemplate.hasCollections"
    ></app-collection-auto-template-edit-dialog>
  </p-sidebar>
</ng-container>
