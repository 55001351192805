import { Component, Input } from "@angular/core";
import Quill from "quill";
import { OrganizationCrmForm } from "../organization-crm.component";

@Component({
  selector: "app-organization-crm-notes",
  templateUrl: "./organization-crm-notes.component.html",
  standalone: false,
})
export class OrganizationCrmNotesComponent {
  @Input({ required: true }) notesForm: OrganizationCrmForm["controls"]["note"];

  @Input() language: string;

  private editor: Quill;

  initEditor(editor: Quill): void {
    editor.setContents(this.notesForm.controls.delta.value);
    this.editor = editor;
  }

  updateHtml(): void {
    this.notesForm.controls.delta.setValue(this.editor.getContents());
    this.notesForm.controls.contents.setValue(this.editor.getText());
    this.notesForm.markAsDirty();
  }
}
