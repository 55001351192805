import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { nameofFactory, responsibleEmployeeRoles } from "@ankaadia/ankaadia-shared";
import { InternalCandidateData, StaticDataModel } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { Observable, map, of } from "rxjs";
import { UsersService } from "../../users/users.service";

const nameOf = nameofFactory<InternalCandidateData>();
const controlName = nameOf("responsibleEmployees");

@Component({
  selector: "app-candidate-responsible-employee",
  templateUrl: "./candidate-responsible-employee.component.html",
  standalone: false,
})
export class CandidateResponsibleEmployeeComponent implements OnInit, OnChanges, OnDestroy {
  private readonly language = this.transloco.getActiveLang();

  protected users$: Observable<StaticDataModel[]> = of([]);

  @Input({ required: true })
  form: FormGroup;

  @Input()
  processLanguage?: string = this.language;

  @Input({ required: true })
  candidateOrganizationId: string;

  @Input()
  readonly: boolean;

  get control(): FormControl<string> {
    return this.form.get(controlName) as FormControl<string>;
  }

  set control(value: FormControl<string>) {
    this.form.setControl(controlName, value);
  }

  constructor(
    private readonly transloco: TranslocoService,
    private readonly usersService: UsersService
  ) {}

  ngOnInit(): void {
    if (!this.control) this.control = new FormControl<string>(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.candidateId || changes.candidateOrganizationId) {
      this.users$ = this.usersService
        .getUsersWithRoles(this.candidateOrganizationId, responsibleEmployeeRoles)
        .pipe(map((users) => users.map((user) => ({ value: user.id, label: `${user.lastname}, ${user.firstname}` }))));
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl(controlName, { emitEvent: false });
  }
}
