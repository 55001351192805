import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { FilterForm } from "../../candidate-filter/candidate-filter-form.model";
import { CollectionAutoFiltersForm } from "./collection-auto-filter-form.model";

@Injectable({ providedIn: "root" })
export class CollectionAutoFilterFormService {
  constructor(private readonly formBuilder: FormBuilder) {}

  createAutoCollectionFiltersForm(): CollectionAutoFiltersForm {
    type NewType = CollectionAutoFiltersForm;

    return this.formBuilder.group<NewType["controls"]>({
      filters: this.formBuilder.array<FilterForm>([], Validators.required),
      includeSharedCandidates: this.formBuilder.control(false),
    });
  }
}
