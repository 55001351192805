<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <ng-container *ngIf="control">
    <label for="{{ formId }}-representativeId">{{ t("employerRepresentative.shortTitle") }}</label>

    <p-dropdown
      inputId="{{ formId }}-representativeId"
      [options]="employerContacts"
      optionValue="id"
      filterBy="firstName,lastName"
      [formControl]="control"
      [showClear]="true"
      [readonly]="readonly"
      [placeholder]="employerRepresentativePlaceholder ?? t('employerRepresentative.placeholder')"
      appCandidateFieldNotShared
      formControlValueAlwaysInOptions
      [isShared]="isShared"
    >
      <ng-template *ngFor="let template of ['item', 'selectedItem']" let-item [pTemplate]="template">
        <span>{{ item.lastName }}, {{ item.firstName }}</span>
      </ng-template>
    </p-dropdown>

    <small class="p-error" *ngIf="control.errors?.required">
      {{ t("employerRepresentative.required") }}
    </small>
  </ng-container>
</ng-container>
