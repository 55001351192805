<div class="flex flex-column app-form" *appTranslate="let t">
  <p-toolbar>
    <ng-template pTemplate="left">
      <h3>{{ t("autoCollectionTemplate.title") }}</h3>
    </ng-template>

    <ng-template pTemplate="right">
      <p-button
        [label]="t('autoCollectionTemplate.new')"
        icon="pi pi-plus"
        class="mr-2 mb-2"
        (onClick)="newClicked()"
      ></p-button>
    </ng-template>
  </p-toolbar>

  <p-splitter
    class="app-form"
    styleClass="mb-5 app-form"
    [panelSizes]="[20, 80]"
    [minSizes]="[10, 50]"
    stateKey="ankaadia_candidate_splitter"
  >
    <ng-template pTemplate>
      <app-collection-auto-template-selection
        [selectedTemplate]="selectedTemplate"
      ></app-collection-auto-template-selection>
    </ng-template>

    <ng-template pTemplate>
      <router-outlet></router-outlet>
    </ng-template>
  </p-splitter>
</div>

<p-sidebar [visible]="showSidebar" styleClass="p-sidebar-lg">
  <app-collection-auto-template-edit-dialog
    *ngIf="showSidebar"
    (closed)="closeSidebar($event)"
    [isEdit]="false"
    [collectionAutoTemplate]="newTemplate"
  ></app-collection-auto-template-edit-dialog>
</p-sidebar>
