import { Component } from "@angular/core";
import {
  EducationCourseCreateInput,
  EducationCourseForEditFragment,
  EducationCourseForListFragment,
  EducationCourseForViewFragment,
  EducationCoursePerformanceForListFragment,
  EducationCourseTotalPerformanceStateUpdateInput,
  EducationCourseUpdateInput,
  EducationCourseWeeklyAttendanceUpdateInput,
  EducationCourseWeeklyCommentUpdateInput,
  EducationCourseWeeklyPerformanceUpdateInput,
  EducationCourseWeekPrefillInput,
} from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { BehaviorSubject, finalize, Observable, of, switchMap } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { MessageService } from "../../message/message.service";
import { EducationCoursePerformanceService } from "./education-course-performances/education-course-performance.service";
import { EducationCourseService } from "./education-course.service";

@Component({
  selector: "app-education-courses",
  templateUrl: "./education-courses.component.html",
  standalone: false,
})
export class EducationCoursesComponent {
  private isBusy: boolean;

  readonly courses$ = this.educationCourseService.list(this.settings.organizationId);
  readonly selectedCourseForView$ = new BehaviorSubject<EducationCourseForViewFragment>(null);
  readonly selectedCoursePerformance$ = this.getSelectedCoursePerformance();
  readonly selectedCourse: {
    list?: EducationCourseForListFragment;
    view?: EducationCourseForViewFragment;
    edit?: EducationCourseForEditFragment;
  } = {};

  showSidebar: boolean;

  constructor(
    private readonly educationCourseService: EducationCourseService,
    private readonly educationCoursePerformanceService: EducationCoursePerformanceService,
    private readonly settings: SettingsService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService
  ) {}

  add(): void {
    if (this.isBusy) return;
    this.selectedCourse.edit = {
      id: null,
      organizationId: null,
      _etag: null,
      name: null,
      description: null,
      moduleId: null,
      teacherUserIds: null,
      maxParticipants: null,
      participantCollectionId: null,
      startDate: null,
      endDate: null,
    };
    this.showSidebar = true;
  }

  view(course: EducationCourseForListFragment): void {
    if (this.isBusy) return;
    if (course) {
      this.selectedCourse.list = course;
      this.isBusy = true;
      this.educationCourseService
        .view(course.id, course.organizationId)
        .pipe(finalize(() => (this.isBusy = false)))
        .subscribe((course) => {
          this.selectedCourse.view = course;
          this.selectedCourseForView$.next(course);
        });
    } else {
      this.selectedCourse.list = null;
      this.selectedCourse.view = null;
      this.selectedCourseForView$.next(null);
    }
  }

  edit(course: EducationCourseForViewFragment): void {
    if (this.isBusy) return;
    this.isBusy = true;
    this.educationCourseService
      .edit(course.id, course.organizationId)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe((course) => {
        this.selectedCourse.edit = course;
        this.showSidebar = true;
      });
  }

  delete(course: EducationCourseForViewFragment, event: Event): void {
    if (this.isBusy) return;
    this.confirmationService.confirm({
      target: event.target,
      message: translate("educationCourse.confirmDelete", course),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () =>
        this.educationCourseService.delete(course).subscribe(() => {
          this.messageService.add({
            severity: "success",
            summary: translate("educationCourse.deleted.title"),
            detail: translate("educationCourse.deleted.message", course),
          });
          this.selectedCourse.list = null;
          this.selectedCourse.view = null;
          this.selectedCourseForView$.next(null);
        }),
    });
  }

  create(course: EducationCourseCreateInput): void {
    if (this.isBusy) return;
    this.isBusy = true;
    this.educationCourseService
      .create(course)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe((course) => {
        this.messageService.add({
          severity: "success",
          summary: translate("educationCourse.created.title"),
          detail: translate("educationCourse.created.message", course),
        });
        this.close();
        this.selectedCourse.list = course;
        this.selectedCourse.view = course;
        this.selectedCourseForView$.next(course);
      });
  }

  update(course: EducationCourseUpdateInput): void {
    if (this.isBusy) return;
    this.isBusy = true;
    this.educationCourseService
      .update(course)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe((course) => {
        this.messageService.add({
          severity: "success",
          summary: translate("educationCourse.updated.title"),
          detail: translate("educationCourse.updated.message", course),
        });
        this.close();
        this.selectedCourse.list = course;
        this.selectedCourse.view = course;
        this.selectedCourseForView$.next(course);
      });
  }

  updateCandidateState(candidateState: EducationCourseTotalPerformanceStateUpdateInput): void {
    this.educationCoursePerformanceService.updateCandidateState(candidateState).subscribe(() => {
      this.messageService.add({ severity: "success", summary: translate("courseStatus.updated") });
    });
  }

  updateAttendance(attendance: EducationCourseWeeklyAttendanceUpdateInput): void {
    this.educationCoursePerformanceService.updateAttendance(attendance).subscribe(() => {
      this.messageService.add({ severity: "success", summary: translate("attendance.updated") });
    });
  }

  updatePerformance(performance: EducationCourseWeeklyPerformanceUpdateInput): void {
    this.educationCoursePerformanceService.updatePerformance(performance).subscribe(() => {
      this.messageService.add({ severity: "success", summary: translate("performance.updated") });
    });
  }

  updateComment(comment: EducationCourseWeeklyCommentUpdateInput): void {
    this.educationCoursePerformanceService.updateComment(comment).subscribe(() => {
      this.messageService.add({ severity: "success", summary: translate("comment.updated") });
    });
  }

  prefillWeek(week: EducationCourseWeekPrefillInput): void {
    this.educationCoursePerformanceService.prefillWeek(week).subscribe(() => {
      this.messageService.add({ severity: "success", summary: translate("week.prefilled") });
    });
  }

  close(): void {
    this.selectedCourse.edit = null;
    this.showSidebar = false;
  }

  private getSelectedCoursePerformance(): Observable<EducationCoursePerformanceForListFragment> {
    return this.selectedCourseForView$.pipe(
      switchMap((course) =>
        course ? this.educationCoursePerformanceService.list(course.id, course.organizationId) : of(null)
      )
    );
  }
}
