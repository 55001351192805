export enum UserPermission {
  Administrator = "role:admin",
  PartnerAdministrator = "role:partner-admin",
  ContentAdministrator = "role:content-admin",
  ProcessAdministrator = "role:process-admin",
  CandidateManager = "role:candidate-manager",
  CourseTeacher = "role:course-teacher",
  CourseAdministrator = "role:course-admin",
  User = "role:user",
  Candidate = "role:candidate",
  ProcessObserver = "role:process-observer",
  CrmContributor = "role:crm-contributor",
  NoPermissionRequired = "no-permission-required",
  BillingAdministrator = "role:billing-admin",
}

export const AllUserPermissions = Object.values(UserPermission).filter((x) => x != UserPermission.NoPermissionRequired);

export enum MachinePermission {
  SendGrid = "m2m:emailhook",
  Lingoking = "m2m:lingoking",
}

export enum ApiScope {
  Public = "api:public",
}

export enum ApiPermission {
  Read = "m2m:public:read",
  ReadCandidate = "m2m:public:candidate:read",
  WriteCandidate = "m2m:public:candidate:write",
  ReadEmailTemplate = "m2m:migration:emailtemplate:read",
  WriteEmailTemplate = "m2m:migration:emailtemplate:write",
  ReadOrganization = "m2m:public:organization:read",
  WriteOrganization = "m2m:public:organization:write",
}

export enum UserRole {
  Administrator = "Administrator",
  PartnerAdministrator = "PartnerAdministrator",
  ContentAdministrator = "ContentAdministrator",
  ProcessAdministrator = "ProcessAdministrator",
  CandidateManager = "CandidateManager",
  CourseTeacher = "CourseTeacher",
  CourseAdministrator = "CourseAdministrator",
  User = "User",
  Candidate = "Candidate",
  ProcessObserver = "ProcessObserver",
  EmailReceiverOnly = "EmailReceiverOnly",
  CrmContributor = "CrmContributor",
  BillingAdministrator = "BillingAdministrator",
}

export const ROLE_PERMISSIONS: Record<UserRole, UserPermission[]> = {
  [UserRole.Administrator]: [UserPermission.Administrator],
  [UserRole.PartnerAdministrator]: [UserPermission.PartnerAdministrator],
  [UserRole.ContentAdministrator]: [UserPermission.ContentAdministrator],
  [UserRole.ProcessAdministrator]: [UserPermission.ProcessAdministrator],
  [UserRole.BillingAdministrator]: [UserPermission.BillingAdministrator],
  [UserRole.CandidateManager]: [UserPermission.CandidateManager],
  [UserRole.CourseTeacher]: [UserPermission.CourseTeacher],
  [UserRole.CourseAdministrator]: [UserPermission.CourseAdministrator],
  [UserRole.User]: [UserPermission.User],
  [UserRole.Candidate]: [UserPermission.Candidate],
  [UserRole.ProcessObserver]: [UserPermission.ProcessObserver],
  [UserRole.CrmContributor]: [UserPermission.CrmContributor],
  [UserRole.EmailReceiverOnly]: [],
};

export const PERMISSION_EQUALITY: Partial<Record<UserPermission, UserPermission>> = {
  [UserPermission.User]: UserPermission.CandidateManager, // if UserPermission.User is requested but User has only UserPermission.CandidateManager, it should be allowed
};
